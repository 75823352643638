<template>
    <div class="stepElectric">
        <div v-for="(item, index) in strList" :key="index" class="consumption">
            <span class="title">本月区域总用电</span>
            <div class="conlist1">
                <p>{{ item.name }}</p>
                <h4>
                    {{ item.value }}
                    <span>kWh</span>
                </h4>
            </div>
            <div class="conlist2">
                <img class="conleftimg" src="@/assets/energyStatistical/con-bg-left.png" alt />
                <img class="conlefticon" src="@/assets/energyStatistical/con-bg-icon.png" alt />
                <div class="list1-con">
                    <p>环比</p>
                    <span>{{ item.huanbiValue }}</span>
                </div>
                <div class="con-border"></div>
                <div class="list2-con">
                    <span :class="
              item.huanbiRatio == 0 || item.huanbiRatio == '0.00%' || item.huanbiRatio == '-'
                ? 'ratioNone'
                : parseFloat(item.huanbiRatio) > 0
                ? 'ratioRise'
                : 'ratioDown'
            ">
                        {{ item.huanbiRatio == 0 || item.huanbiRatio == "0.00%" ? "-" : item.huanbiRatio
            }}<span v-if="item.huanbiRatio > 0 || item.huanbiRatio < 0">%</span>
                        <img v-if="item.huanbiRatio == 0 || item.huanbiRatio == '0.00%' || item.huanbiRatio == '-'" src="" alt="" />
                        <img v-if="parseFloat(item.huanbiRatio) > 0" src="@/assets/energyStatistical/rise.png" alt />
                        <img v-if="parseFloat(item.huanbiRatio) < 0" src="@/assets/energyStatistical/down.png" alt="" />
                    </span>
                </div>
            </div>
            <div class="conlist3">
                <img class="conleftimg" src="@/assets/energyStatistical/con-bg-left1.png" alt />
                <img class="conlefticon" src="@/assets/energyStatistical/con-bg-icon1.png" alt />
                <div class="list1-con">
                    <p>同比</p>
                    <span>{{ item.tongbiValue }}</span>
                </div>
                <div class="con-border"></div>
                <div class="list2-con">
                    <span :class="
              item.tongbiRatio == 0 || item.tongbiRatio == '0.00%' || item.tongbiRatio == '-'
                ? 'ratioNone'
                : parseFloat(item.tongbiRatio) > 0
                ? 'ratioRise'
                : 'ratioDown'
            ">
                        {{ item.tongbiRatio == 0 || item.tongbiRatio == "0.00%" ? "-" : item.tongbiRatio
            }}<span v-if="item.tongbiRatio > 0 || item.tongbiRatio < 0">%</span>
                        <img v-if="item.tongbiRatio == 0 || item.tongbiRatio == '0.00%' || item.tongbiRatio == '-'" src="" alt="" />
                        <img v-if="parseFloat(item.tongbiRatio) > 0" src="@/assets/energyStatistical/rise.png" alt />
                        <img v-if="parseFloat(item.tongbiRatio) < 0" src="@/assets/energyStatistical/down.png" alt="" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "StepTop",
    data () {
        return {
            dataForm: {
                customerId: "",
                //环比率
                huanbiRatio: "",
                //环比值
                huanbiValue: "",
                //名称
                name: "",
                //同比比率
                tongbiRatio: "",
                //同比值
                tongbiValue: "",
                //当前值
                value: "",
            },
            strList: [],
            flagImg: false,
        };
    },
    mounted () { },
    methods: {
        //传入用户id   监听引入方法
        queryEnerData () {
            this.dataForm.customerId = this.$store.state.storage.customerId;
            // this.$store.dispatch("getBuildCard", this.dataForm.customerId).then(() => {
                if (this.$store.state.elecManagement.getBuildCard.errorCode == 0) {
                    this.strList = this.$store.state.elecManagement.getBuildCard.data;
                    for (let i = 0; i < this.strList.length; i++) {
                        //拿到数据环比进行判断并且显示图标flagImg
                        let item = this.strList[i];
                        let huanbi = item.huanbiRatio;
                            console.log(item.huanbiRatio, 'item.huanbiRatio')
                        let tongbi = item.tongbiRatio;
                        //环比数据为null
                        if (huanbi == null || huanbi == "-") {
                            huanbi = "-";
                            item.huanbiRatio = huanbi;
                        } else {
                            item.huanbiRatio = Number(huanbi).toFixed(2);
                        }
                        //同比数据为null
                        if (tongbi == null || tongbi == "-") {
                            tongbi = "-";
                            item.tongbiRatio = tongbi;
                        } else {
                            item.tongbiRatio = Number(tongbi).toFixed(2);
                        }
                    }
                } else {
                    this.strList = [
                        {
                            huanbiRatio: 0,
                            huanbiValue: 0,
                            name: "暖通空调用电",
                            tongbiRatio: 0,
                            tongbiValue: 0,
                            value: 0,
                        },
                        {
                            huanbiRatio: 0,
                            huanbiValue: 0,
                            name: "照明系统用电",
                            tongbiRatio: 0,
                            tongbiValue: 0,
                            value: 0,
                        },
                        {
                            huanbiRatio: 0,
                            huanbiValue: 0,
                            name: "动力用电",
                            tongbiRatio: 0,
                            tongbiValue: 0,
                            value: 0,
                        },
                        {
                            huanbiRatio: 0,
                            huanbiValue: 0,
                            name: "特殊用电",
                            tongbiRatio: 0,
                            tongbiValue: 0,
                            value: 0,
                        },
                    ];
                }
            // });
        },
    },
    computed: {
        ...mapState({
            getBuildCard: (state) => {
                return state.elecManagement.getBuildCard.data;
            },
        }),
    },
    watch: {
        "getBuildCard": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                // this.dataForm.customerId = newVal;
                //监听用户id调用方法，不然报错id不存在
                setTimeout(() => {
                    // this.queryEnerData();
                }, 1)
            },
        },
    },
};
</script>

<style lang="less" scoped>
.stepElectric {
    display: flex;
    margin-left: -2.4rem;
    justify-content: space-between;
    .consumption {
        // width: 33.9rem;
        flex: 1 1 33.9rem;
        height: 37.3rem;
        background: #0c2f4f;
        position: relative;
        margin-left: 2.4rem;
        border-radius: 0.4rem;
        // margin: 0 1.2rem;
        .title {
            position: relative;
            top: 2rem;
            margin-left: 2.4rem;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: #37a5dd;
        }
        .conlist1 {
            width: 31.2rem;
            height: 10rem;
            margin-left: 1.3rem;
            margin-top: 2.2rem;
            background: url(../../../../assets/energyStatistical/con-img-bg.png)
                no-repeat center;
            background-size: 29.1rem 8rem;
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: #fffffe;
                float: left;
                margin-top: 3.9rem;
                margin-left: 2.4rem;
            }
            h4 {
                font-style: normal;
                font-weight: normal;
                font-size: 2.4rem;
                line-height: 3.4rem;
                color: #fbfbfb;
                position: absolute;
                right: 0;
                margin-top: 3.3rem;
                margin-right: 3.7rem;
                span {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1.2rem;
                    line-height: 1.7rem;

                    color: #fffffe;
                }
            }
        }
        .conlist2 {
            width: 31.2rem;
            height: 10rem;
            margin-left: 2.4rem;
            margin-top: 1.7rem;
            position: relative;
            background: url(../../../../assets/energyStatistical/con-img-bg1.png)
                no-repeat;
            background-size: 29.1rem 8rem;
            .conleftimg {
                float: left;
                height: 8rem;
            }
            .conlefticon {
                position: relative;
                top: 2.3rem;
                left: 1.5rem;
            }
            .list1-con {
                position: absolute;
                left: 6.1rem;
                top: 1.3rem;
                p {
                    font-size: 1.4rem;
                    line-height: 2rem;
                    color: #37a5dd;
                }
                span {
                    font-size: 2rem;
                    line-height: 2.8rem;
                    color: #fffffe;
                }
            }
            .con-border {
                width: 0.1rem;
                height: 3.9rem;
                background-color: #1f4a72;
                position: absolute;
                left: 16rem;
                top: 2rem;
            }
            .list2-con {
                font-size: 2rem;
                line-height: 2.8rem;
                color: #ffa82c;
                position: absolute;
                left: 17rem;
                top: 2.5rem;
                .ratioRise {
                    color: #ffa82c;
                }
                .ratioDown {
                    color: rgba(68, 241, 182, 1);
                }
                .ratioNone {
                    color: #fff;
                }
                img {
                    position: absolute;
                    width: 1.2rem;
                    height: 1.2rem;
                    right: -1.3rem;
                    top: 1rem;
                }
            }
        }
        .conlist3 {
            width: 31.2rem;
            height: 10rem;
            margin-left: 2.4rem;
            margin-top: 1.7rem;
            position: relative;
            background: url(../../../../assets/energyStatistical/con-img-bg1.png)
                no-repeat;
            background-size: 29.1rem 8rem;
            .conleftimg {
                float: left;
                height: 8rem;
            }
            .conlefticon {
                position: absolute;
                top: 2.3rem;
                left: 1.5rem;
            }
            .list1-con {
                position: absolute;
                left: 6.1rem;
                top: 1.3rem;
                p {
                    font-size: 1.4rem;
                    line-height: 2rem;
                    color: #37a5dd;
                }
                span {
                    font-size: 2rem;
                    line-height: 2.8rem;
                    color: #fffffe;
                }
            }
            .con-border {
                width: 0.1rem;
                height: 3.9rem;
                background-color: #1f4a72;
                position: absolute;
                left: 16rem;
                top: 2rem;
            }
            .list2-con {
                font-size: 2rem;
                line-height: 2.8rem;
                color: #ffa82c;
                position: absolute;
                left: 17rem;
                top: 2.5rem;
                .ratioRise {
                    color: #ffa82c;
                }
                .ratioDown {
                    color: rgba(68, 241, 182, 1);
                }
                .ratioNone {
                    color: #fff;
                }
                img {
                    position: absolute;
                    width: 1.2rem;
                    height: 1.2rem;
                    right: -1.3rem;
                    top: 1rem;
                }
            }
        }
        .conlist1,
        .conlist2,
        .conlist3 {
            background-size: 100% 80%;
            width: 85%;
            margin: 2.4rem auto 0 auto;
        }
        .conlist2,
        .conlist3 {
            margin: 1.7rem auto 0 auto;
        }
    }
    .consumption:last-child {
        // margin-right: 0;
    }
    .consumption:first-child {
        // margin-left: 0;
    }
}
</style>
