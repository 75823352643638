<template>
  <div class="CustomerPage">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <div v-if="getType == 2" slot="title">
        <div class="equip_title">新增角色</div>
      </div>
      <div v-else slot="title">
        <div class="equip_title">编辑角色</div>
      </div>
      <div style="max-height: 45rem; overflow-y: auto;">
        <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
          <el-form-item label="角色名称" prop="roleName">
            <el-input
              v-model="form.roleName"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="角色描述" prop="remark">
            <el-input
              v-model="form.remark"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-form></el-form>
      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">
          取 消
        </button>
        <button class="btn_save" @click="postDialogData('ruleForm')">
          保 存
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import { validateInput, validateSelect } from '@/utils/validate'
export default {
  name: 'CharacterEditDialog',
  mixins: [tableBg],
  data() {
    return {
      // 数据请求的类型（编辑=1、新登记=2）
      getType: 0,
      //表单中placehoder的数据
      parentNamePlaceHoder: '',
      // 当前树的信息
      treeFullNodeData: {},
      // 展示判断
      visible: false,
      // 表单数据存放处
      form: {
        id: '',
        roleName: '',
        remark: '',
      },
      rules: {
        roleName: [validateInput],
        remark: [validateInput],
      },
    }
  },
  methods: {
    // 关闭dialog的回调，el自带的

    closeDialog(ruleForm) {
      this.$refs[ruleForm].resetFields()
      this.formInit()
    },
    handleCancle(ruleName) {
      this.$refs[ruleName].resetFields()
      this.formInit()
      this.visible = false
    },
    // from的归零
    formInit() {
      this.form = {}
    },
    //父组件点击触发新增
    show() {
      this.getType = 2
      this.visible = true
    },
    // 父组件触发编辑
    showForEdit(val) {
      this.getType = 1
      this.visible = true
      Object.assign(this.form, { ...val })
    },
    // 提交发送请求（点击《确定》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          // 发送登记请求
          if (this.getType === 2) {
            this.$store.dispatch('postSystemAddNewRole', this.form).then(() => {
              if (this.ifPostAddRole === 0) {
                this.$message({ message: '添加成功！', type: 'success' })

                this.$refs[ruleName].resetFields()
                this.visible = false
                this.$parent.getDataList()
              } else if (this.ifPostAddRole === -1) {
                this.$message({
                  message: this.ifPostAddRoleError,
                  type: 'warning',
                })
              }
            })
          } else if (this.getType === 1) {
            //发送编辑请求
            this.$store
              .dispatch('postSystemNewRoleEdit', this.form)
              .then(() => {
                if (this.ifPostRoleEdit === 0) {
                  this.$message({ message: '编辑成功！', type: 'success' })

                  this.$refs[ruleName].resetFields()
                  this.visible = false
                  this.$parent.getDataList()
                } else if (this.ifPostRoleEdit === -1) {
                  this.$message({
                    message: this.ifPostRoleEditError,
                    type: 'warning',
                  })
                }
              })
          }
        } else {
          this.$message.error('有必填项未填')
        }
      })
    },
  },

  //处理state数据
  computed: {
    ...mapState({
      ifPostAddRole: (state) => {
        return state.system.ifPostAddRole.errorCode
      },
      ifPostAddRoleError: (state) => {
        return state.system.ifPostAddRole.message
      },
      ifPostRoleEdit: (state) => {
        return state.system.ifPostRoleEdit.errorCode
      },
      ifPostRoleEditError: (state) => {
        return state.system.ifPostRoleEdit.message
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}

// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
</style>

<style lang="less">
// 套壳！谨防代码污染！
.CustomerPage {
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }
  // abb7c2
  .el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem;
  }
  .el-checkbox {
    color: #abb7c2;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #027969;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
  }
}
</style>

<style lang="less">
.el-input.is-disabled .el-input__inner {
  background-color: #0b3d6b;
  border-color: #004579;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
