<template>
    <div class="week-scoll">
        <div v-if="loading" class="loading-mask">
          <el-spinner size="large"></el-spinner>
        </div>
        <!-- 周末图表 -->
        <div ref="myChart" class="c2" id="myChart19"></div>
        <div class="table-bottom">
            <div class="weekTime">
                <el-select @change="selectYear" size="mini" class="selectTime" v-model="value" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label"></el-option>
                </el-select>
            </div>
            <div class="dateChoose">
                <div :class="btnTypeDay" @click="changeTab(0)">日均能耗</div>
                <div :class="btnTypeMonth" @click="changeTab(1)">总能耗</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as echarts from "echarts";
export default {
    data () {
        return {
            btnTypeDay: "btnGreen",
            btnTypeMonth: "btnDark",
            value: new Date().getFullYear(),
            dataForm: {
                localtionId: "",
            },
            barList1: [],
            barList2: [],
            barList3: [],
            barList4: [],
            options: [
                {
                    value: "1",
                    label: "2022年",
                },
                {
                    value: "2",
                    label: "2021年",
                },
                {
                    value: "3",
                    label: "2020年",
                },
                {
                    value: "4",
                    label: "2019年",
                },
                {
                    value: "5",
                    label: "2018年",
                },
            ],
            showInterval: true,
            loading: false,
        };
    },
    created () {
        this.getYear();
    },
    mounted () {
        this.drawLineDay();
    },
    methods: {
        resizeChart () {
            setTimeout(() => {
                const myChart = this.$echarts.init(document.getElementById("myChart19"));
                const chartWidth = document.getElementById("myChart19").offsetWidth
                const chartHeight = document.getElementById("myChart19").offsetHeight
                myChart.resize({ width: chartWidth, height: chartHeight })
            }, 500)
        },
        selectYear () {
            let item = this.value;
            let str = item.slice(0, 4);
            this.value = str;
            if (this.btnTypeDay == 'btnGreen') {
                this.queryTabDay1();
            } else if (this.btnTypeMonth == 'btnGreen') {
                this.queryTabDay2();
            }
            //  this.drawLineDay()
            //  this.drawLineYear()
        },
        queryTabDay1 () {
            this.loading = true
            this.$store
                .dispatch("getEnerWorkorRest", {
                    id: this.dataForm.localtionId,
                    year: this.value,
                    type: "1",
                })
                .then(() => {
                    this.barList1 = this.getEnerWorkorRest[0].data;
                    this.barList2 = this.getEnerWorkorRest[1].data;
                    this.drawLineDay();
                }).finally(() => {
                    this.loading = false
                })
        },
        queryTabDay2 () {
            this.loading = true
            this.$store
                .dispatch("getEnerWorkorRest", {
                    id: this.dataForm.localtionId,
                    year: this.value,
                    type: "2",
                })
                .then(() => {
                    this.barList3 = this.getEnerWorkorRest[0].data;
                    this.barList4 = this.getEnerWorkorRest[1].data;
                    this.drawLineYear();
                }).finally(() => {
                    this.loading = false
                })
        },
        changeTab (index) {
            this.showInterval = false;
            if (index === 0) {
                this.btnTypeDay = "btnGreen";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnDark";
                // this.drawLineDay();
                this.queryTabDay1();
            } else if (index === 1) {
                this.btnTypeDay = "btnDark";
                this.btnTypeMonth = "btnGreen";
                this.btnTypeYear = "btnDark";
                // this.drawLineYear();
                this.queryTabDay2();
            }
        },
        dispose () {
            this.$echarts.dispose(this.$refs.myChart);
        },
        getYear () {
            let date = new Date();
            let year = date.getFullYear();
            this.value = year;
            this.options = [];
            for (let i = 0; i < 5; i++) {
                let num = year - i;
                let obj = {
                    id: i + 1,
                    label: num + '年'
                }
                this.options.push(obj);
            }
        },
        ceilNumber (number) {
            let bite = 0;
            if (number < 10) {
                return 10;
            }
            while (number >= 10) {
                number /= 10;
                bite += 1;
            }
            return Math.ceil(number) * Math.pow(10, bite);
        },
        drawLineDay () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart19"));
            // 绘制图表
            if (myChart == null) {
                myChart = this.$echarts.init(document.getElementById("myChart19"));
            }
            let workList = [];
            let restList = [];
            workList = this.barList1;
            restList = this.barList2;
            let yMax = 0;
            for (let i = 0; i < workList.length; i++) {
                const item = workList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            for (let i = 0; i < restList.length; i++) {
                const item = restList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            yMax = this.ceilNumber(yMax);
            let option = {
                title: {
                    text: "",
                    top: "0px",
                    left: "15px",
                    textStyle: {
                        color: "#37A5DD",
                        fontSize: 14,
                        lineHeight: 35,
                        fontWeight: 400,
                    },
                    subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
                },
                color: ["#1EE7E7", "#1890FF"],
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    orient: "horizontal",
                    x: "center", //可设定图例在左、右、居中
                    y: "bottom", //可设定图例在上、下、居中
                    padding: [0, 0, 10, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                    // borderColor: "#fff",
                    textStyle: { color: "rgba(255, 255, 255, 0.85)" },
                    bottom: 20,
                    data: ["周中", "周末"],
                },
                grid: {
                    x: 25,
                    x2: 20,
                    y: 85,
                    y2: 40,
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "rgba(43, 113, 176, 0.45)",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                    },
                    data: [
                        "第1周",
                        "第2周",
                        "第3周",
                        "第4周",
                        "第5周",
                        "第6周",
                        "第7周",
                        "第8周",
                        "第9周",
                        "第10周",
                        "第11周",
                        "第12周",
                        "第13周",
                        "第14周",
                        "第15周",
                        "第16周",
                        "第17周",
                        "第18周",
                        "第19周",
                        "第20周",
                        "第21周",
                        "第22周",
                        "第23周",
                        "第24周",
                        "第25周",
                        "第26周",
                        "第27周",
                        "第28周",
                        "第29周",
                        "第30周",
                        "第31周",
                        "第32周",
                        "第33周",
                        "第34周",
                        "第35周",
                        "第36周",
                        "第37周",
                        "第38周",
                        "第39周",
                        "第40周",
                        "第41周",
                        "第42周",
                        "第43周",
                        "第44周",
                        "第45周",
                        "第46周",
                        "第47周",
                        "第48周",
                        "第49周",
                        "第50周",
                        "第51周",
                        "第52周",
                    ],
                },
                yAxis: {
                    name: "单位:kWh",
                    min: 0,
                    max: yMax,
                    interval: parseInt(yMax / 4),
                    type: "value",
                    axisLabel: {
                        color: "#fff",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(230,247,255,0.2)",
                        },
                    },
                },
                series: [
                    {
                        name: "周中",
                        barWidth: 4,
                        data: workList,
                        type: "bar",
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1EE7E7" },
                                { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
                            ]),
                        },
                    },
                    {
                        name: "周末",
                        barWidth: 4,
                        data: restList,
                        type: "bar",
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1890FF" },
                                { offset: 1, color: "rgba(24, 144, 255, 0.35)" },
                            ]),
                        },
                    },
                ],
            };
            // 绘制图表
            myChart.setOption(option);
            myChart._dom.childNodes[0].childNodes[0].style.width = "116.5rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "40rem";
        },
        drawLineYear () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart19"));
            // 绘制图表
            if (myChart == null) {
                myChart = this.$echarts.init(document.getElementById("myChart19"));
            }
            let workList = [];
            let restList = [];
            workList = this.barList3;
            restList = this.barList4;
            let yMax = 0;
            for (let i = 0; i < workList.length; i++) {
                const item = workList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            for (let i = 0; i < restList.length; i++) {
                const item = restList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            yMax = this.ceilNumber(yMax);
            let option = {
                color: ["#3673E8", "#61B8FF"],
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    orient: "horizontal",
                    x: "center", //可设定图例在左、右、居中
                    y: "bottom", //可设定图例在上、下、居中
                    padding: [0, 0, 10, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                    // borderColor: "#fff",
                    textStyle: { color: "rgba(255, 255, 255, 0.85)" },
                    bottom: 20,
                    data: ["周中", "周末"],
                },
                grid: {
                    x: 25,
                    x2: 20,
                    // y:5,
                    y2: 40,
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "rgba(43, 113, 176, 0.45)",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                    },
                    data: [
                        "第1周",
                        "第2周",
                        "第3周",
                        "第4周",
                        "第5周",
                        "第6周",
                        "第7周",
                        "第8周",
                        "第9周",
                        "第10周",
                        "第11周",
                        "第12周",
                        "第13周",
                        "第14周",
                        "第15周",
                        "第16周",
                        "第17周",
                        "第18周",
                        "第19周",
                        "第20周",
                        "第21周",
                        "第22周",
                        "第23周",
                        "第24周",
                        "第25周",
                        "第26周",
                        "第27周",
                        "第28周",
                        "第29周",
                        "第30周",
                        "第31周",
                        "第32周",
                        "第33周",
                        "第34周",
                        "第35周",
                        "第36周",
                        "第37周",
                        "第38周",
                        "第39周",
                        "第40周",
                        "第41周",
                        "第42周",
                        "第43周",
                        "第44周",
                        "第45周",
                        "第46周",
                        "第47周",
                        "第48周",
                        "第49周",
                        "第50周",
                        "第51周",
                        "第52周",
                    ],
                },
                yAxis: {
                    type: "value",
                    min: 0,
                    max: yMax,
                    interval: parseInt(yMax / 4),
                    axisLabel: {
                        color: "#fff",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(230,247,255,0.2)",
                        },
                    },
                },
                series: [
                    {
                        name: "周中",
                        barWidth: 4,
                        data: workList,
                        type: "bar",
                    },
                    {
                        name: "周末",
                        barWidth: 4,
                        data: restList,
                        type: "bar",
                    },
                ],
            };
            // 绘制图表
            myChart.setOption(option);
            // myChart._dom.childNodes[0].childNodes[0].style.width = "116.5rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "42rem";
        },
    },
    computed: {
        ...mapState({
            getEnerWorkorRest: (state) => {
                return state.elecManagement.getEnerWorkorRest.data;
            },
        }),
    },
    watch: {
        "$store.state.storage.localtionId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.dataForm.localtionId = newVal;
                this.btnTypeDay = 'btnGreen'
                this.btnTypeMonth = 'btnDark'
                this.queryTabDay1();
                // this.queryTabDay2();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.week-scoll {
    .c2 {
        width: 100%;
        // width: 116.5rem;
        height: 42rem;
    }
    .table-bottom {
        position: absolute;
        right: 2.4rem;
        top: 1.5rem;
        float: right;
        display: flex;
        .weekTime {
            margin-right: 1.6rem;
            .selectTime {
                width: 12rem;
                height: 3.6rem;
            }
        }
        .dateChoose {
            height: 2.9rem;
            width: 19rem;
            border-radius: 0.4rem;
            overflow: hidden;
            border: 0.1rem solid #004579;
            box-sizing: border-box;
            display: flex;
            flex-wrap: nowrap;
            color: #fff;
            .btnDark {
                width: 9.6rem;
                height: 2.9rem;
                background-color: #0b375e;
                font-size: 1.4rem;
                padding: 0;
                margin: 0;
                cursor: pointer;
                line-height: 2.7rem;
                text-align: center;
            }
            .btnGreen {
                width: 9.6rem;
                height: 2.9rem;
                background-color: #027969;
                font-size: 1.4rem;
                padding: 0;
                margin: 0;
                cursor: pointer;
                line-height: 2.7rem;
                text-align: center;
            }
            // #btnLeft{
            //   border-top-left-radius: 0.4rem;
            //   border-bottom-left-radius: 0.4rem;
            // }
            // #btnRight{
            //   border-top-right-radius: 0.4rem;
            //   border-bottom-right-radius: 0.4rem;
            // }
        }
    }
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
</style>
<style lang="less"></style>
