<template>
    <div class="EquipmentStatistics">
        <!-- 顶部栏搜索位置############################################################## -->
        <div class="topBar">
            <!------------------------------------------------------设备类型选择框------------------------------------------------------------ -->

            <el-select size="small" @change="typeSelectChanged" placeholder="请选择设备类型" style="width:16.8rem;margin:1rem 0 0 2rem" v-model="typeData">
                <el-option label="所有设备类型" value=""></el-option>
                <el-option label="电动自行车充电桩" value="0"></el-option>
                <el-option label="汽车充电桩" value="1"></el-option>
            </el-select>

            <!-- --------------------------------------------------系统状态选择框------------------------------------------------------------ -->
            <el-select size="small" @change="systemSelectChanged" placeholder="请选择系统状态" style="width:16.8rem;margin:1rem 0 0 2rem" v-model="systemData">
                <el-option label="所有系统状态" value=""></el-option>
                <el-option label="注册" value="0"></el-option>
                <el-option label="上线" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
                <el-option label="设备故障" value="3"></el-option>
                <el-option label="已拆机" value="4"></el-option>
                <el-option label="测试设备" value="5"></el-option>
            </el-select>

            <!---------------------------------------------------在线状态选择框------------------------------------------------------------ -->
            <el-select size="small" @change="onlineSelectChanged" placeholder="请选择在线状态" style="width:16.8rem;margin:1rem 0 0 2rem" v-model="onlineData">
                <el-option label="所有在线状态" value=""></el-option>
                <el-option label="离线" value="0"></el-option>
                <el-option label="在线" value="1"></el-option>
            </el-select>

            <!----------------------------------------------------设备编号输入框------------------------------------------------------------ -->
            <el-input size="small" placeholder="请输入设备编号" v-model="equipmentValue" @keyup.enter.native="search" style="width:16.8rem;margin:1rem 0 0 2rem" clearable @clear="search"></el-input>

            <!----------------------------------------------------小区ID输入框------------------------------------------------------------ -->
            <!-- <el-input size="small" placeholder="请输入小区ID" v-model="idValue" @keyup.enter.native="search"
              style="width:16.8rem;margin:1rem 0 0 2rem" clearable @clear="search"
            ></el-input> -->

            <!----------------------------------------------------查询按钮------------------------------------------------------------ -->
            <button class="btn_save" style="margin:1rem 1.6rem;border-radius:0.2rem" @click="search">
                查 询
            </button>

            <!----------------------------------------------------导出按钮------------------------------------------------------------ -->
            <button class="btn_save" style="margin:1rem 1.6rem;border-radius:0.2rem;float:right" @click="handleExport">
                导 出
            </button>
        </div>

        <!-- 中部表格位置################################################################# -->
        <div class="contentPlace">
            <!---------------------------------------------------- 表格部分--------------------------------------------------------------------------- -->
            <div class="table">
                <el-table :data="tableData" stripe:false border:false style="width: 100%" :header-cell-style="headerClass" :cell-style="cellStyle">
                    <el-table-column prop="id" label="设备编号" min-width="8%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="simId" label="SIM卡ID" min-width="11%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="estateId" label="小区ID" min-width="10%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="name" label="设备名称" min-width="11%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="belongingUser" label="归属用户" min-width="10%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="inTime" label="录入时间" min-width="13" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="status" label="系统状态" min-width="10%" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span v-show="scope.row.status == 0">注册</span>
                            <span v-show="scope.row.status == 1">上线</span>
                            <span v-show="scope.row.status == 2">禁用</span>
                            <span v-show="scope.row.status == 3">设备故障</span>
                            <span v-show="scope.row.status == 4">已拆机</span>
                            <span v-show="scope.row.status == 5">测试设备</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceFamily" label="设备类型" min-width="11%" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span v-show="scope.row.deviceFamily == 0">电动自行车充电桩</span>
                            <span v-show="scope.row.deviceFamily == 1">汽车充电桩</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="online" label="在线状态" min-width="11%" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span v-show="scope.row.online == 0">离线</span>
                            <span v-show="scope.row.online == 1">在线</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="chargePriceName" label="消费标准" min-width="11%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="address" label="地址" min-width="19%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="mainVersion" label="设备版本号" min-width="11%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="iccid" label="ICCID" min-width="11%" :show-overflow-tooltip="true">
                    </el-table-column>
                </el-table>
            </div>
            <!----------------------------------------------------分页器部分--------------------------------------------------------------------------- -->
            <div class="pagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalNumForPagenation">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg"
import { exportChargingDeviceReport } from "@/api/repair"

export default {
    name: "EquipmentStatistics",
    mixins: [tableBg],
    data () {
        return {
            // 驴充充的token令牌
            chargeToken: "",
            // 双向绑定的数据(设备类型，系统状态，在线状态)
            typeData: "",
            systemData: "",
            onlineData: "",

            // 搜索框双向绑定数据(设备编号，小区id)
            equipmentValue: "",
            idValue: "",
            customerName: '',
            // 双向绑定--总数
            totalNumForPagenation: 0,
            // 双向绑定--每页的个数
            pageSize: 10,
            // 双向绑定--当前页
            currentPage: 1,
            // 表格数据(假数据)
            tableData: [],
        }
    },
    watch: {
        "$store.state.storage.customerData": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.customerName = newVal.name;
            },
        },
    },
    created () {
        // this.getlist()
        // 清除同名的所有时间总线，自己和其他兄弟模块的
        this.$bus.$off("chargeForTree")
    },

    mounted () {
        this.$bus.$on("chargeForTree", (val) => {
            if (val == []) {
                alert("该用户没有充电业务")
                return
            }
            this.currentPage = 1
            this.chargeToken = val
            this.getlist()
        })
    },
    beforeDestroy () {
        this.$bus.$emit("refreshTheChargeTree")
    },

    methods: {
        // 页面表格渲染
        getlist () {
            this.$store
                .dispatch("getEquipmentStatisticsPageList", {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    deviceFamily: this.typeData,
                    estateId: this.idValue,
                    id: this.equipmentValue,
                    online: this.onlineData,
                    status: this.systemData,
                    chargeToken: this.chargeToken,
                })
                .then(() => {
                    const res = this.$store.state.elecCharge.equipmentStatisticsPageList
                    if (res.errorCode == 0) {
                        this.tableData = res.data.records
                        this.totalNumForPagenation = res.data.total
                    } else {
                        this.$message(res.message)
                    }
                })
        },

        // 搜索框点击搜索
        search () {
            this.getlist()
        },

        // 切换搜索框内容(设备类型)
        typeSelectChanged () {
            // alert('切换搜索框内容(设备类型)')
            this.getlist()
        },
        // 切换搜索框内容(系统状态)
        systemSelectChanged () {
            // alert('切换搜索框内容(系统状态)')
            this.getlist()
        },
        // 切换搜索框内容(在线状态)
        onlineSelectChanged () {
            // alert('切换搜索框内容(在线状态)')
            this.getlist()
        },

        // 点击了导出
        handleExport () {
            let chargeValue = ""
            if (this.chargeToken.length > 0) {
                for (let i = 0; i < this.chargeToken.length; i++) {
                    chargeValue = chargeValue + this.chargeToken[i] + ","
                }
                chargeValue = chargeValue.slice(0, chargeValue.length - 1)
            } else {
                chargeValue = ""
            }
            var value = {
                chargeToken: chargeValue,
                deviceFamily: this.typeData,
                online: this.onlineData,
                status: this.systemData,
                id: this.equipmentValue,
                myPage: "EquipmentStatistics",
            }
            // this.$store.dispatch("getExportFileCharge",value )
            exportChargingDeviceReport(value).then((response) => {
                var blob = new Blob([response], {
                    type: "application/vnd.ms-excel,charset=utf-8",
                }) //创建一个blob对象
                var a = document.createElement("a") //创建一个<a></a>标签
                a.href = URL.createObjectURL(blob) // response is a blob
                a.download = `${this.customerName}设备统计.xls` //文件名称
                a.style.display = "none"
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        },

        // 更改分页器每页个数
        handleSizeChange (val) {
            this.pageSize = val
            this.getlist()
        },
        // 更改分页器页面
        handleCurrentChange (val) {
            this.currentPage = val
            this.getList()
        },
    },
}
</script>

<style lang="less" scoped>
.topBar {
    height: 6rem;
    width: 100%;
    background-color: #0d3050;
    border-radius: 0 0 0.4rem 0.4rem;
    //   position: absolute;
    //   left: 0;
    //   top: -2.9rem;
}

.contentPlace {
    margin-top: 2.4rem;
    height: 80rem;
    width: 100%;
    background-color: #0d3050;
    border-radius: 0.4rem;
    //   position: absolute;
    //   top: 5rem;
    //   left: 0;
}
.table {
    padding: 2.4rem;
    width: 96.5%;
    // height: 58rem;
}
.pagination {
    position: absolute;
    right: 2.4rem;
    bottom: 3.5rem;
    // margin-right: 2.4rem;
}
</style>
