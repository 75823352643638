<template>
  <div class="PageInside2">
      <div class="mainPart">
          <!-- <div v-for="(item,index) in cardData" :key="index" class="card">
              <div class="topBar">
                  <img v-show="item.deviceFamily == 0 " class="img" src="@/assets/elecCharge/iconForCardCar.png" alt="">
                  <img v-show="item.deviceFamily == 1 " class="img" src="@/assets/elecCharge/iconForCardBick.png" alt="">
                  <div class="word">{{ item.id }}</div>
                  <div class="point"></div>
              </div>
              <div class="contentPlace">
                  <div class="line">
                    <div class="left">充电桩类型：</div>
                    <div class="right" v-show="item.deviceFamily == 0 ">{{ '电动自行车充电桩' }}</div>
                    <div class="right" v-show="item.deviceFamily == 1 ">{{ '汽车充电桩' }}</div>
                  </div>
                  <div class="line">
                    <div class="left">状态：</div>
                    <div class="right" v-show="item.online == 0 ">{{ '离线' }}</div>
                    <div class="right" v-show="item.online == 1 ">{{ '在线' }}</div>
                  </div>
                  <div class="line"><div class="left">昨日电量：</div><div class="right">{{item.yesterdayElectric}}</div></div>
                  <div class="line"><div class="left">昨日次数：</div><div class="right">{{item.yesterdayCount}}</div></div>
                  <div class="line"><div class="left">昨日时长：</div><div class="right">{{item.yesterdayHours + 'h'}}</div></div>
                  <div class="line"><div class="left">位置：</div><div class="right">{{item.address}}</div></div>
                  <div class="line"><div class="left">收费标准：</div><div class="right">{{ item.chargePriceName }}</div></div>

              </div>
          </div> -->
          <div class="cards" v-for="(item,index) in cardData" :key="index">
            <img :src="item.online==0?img2:img1" /> 
            <div class="name">{{ item.deviceName }}</div>
            <div class="cont">
              <div>
                <span class="label">充电桩编号</span>
                <span class="val">{{ item.id }}</span>
              </div>
              <div>
                <span class="label">充电桩类型</span>
                <span class="val">{{ item.deviceFamily==0?'电动自行车充电桩':'汽车充电桩' }}</span>
              </div>
              <div>
                <span class="label">昨日电量</span>
                <span class="val">{{ item.yesterdayElectric }}</span>
              </div>
              <div>
                <span class="label">昨日次数</span>
                <span class="val">{{ item.yesterdayCount }}</span>
              </div>
              <div>
                <span class="label">昨日时长</span>
                <span class="val">{{ item.yesterdayHours }}h</span>
              </div>
              <div>
                <span class="label">位置</span>
                <span class="val" :title="item.address">{{ item.address }}</span>
              </div>
              <div>
                <span class="label">收费标准</span>
                <span class="val">{{ item.chargePriceName }}</span>
              </div>
            </div>
          </div>
      </div>
       <!-- 分页器部分 -->
       <div class="pagination">
         <el-pagination
           background
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="currentPage"
           :page-size="6"
           layout="total, prev, pager, next, jumper"
           :total="totalNumForPagenation"
         >
         </el-pagination>
       </div>
        
  </div>
</template>

<script>
export default {
    name:"PageInside2",
    data(){return{
        // 驴充充的token令牌
        chargeToken:'',
        // 电站的id双向绑定，也是小区ID
        estateId:'',
        // 双向绑定--总数
        totalNumForPagenation:0,
        // 双向绑定--每页的个数
        pageSize:10,
        // 双向绑定--当前页
        currentPage:1,
        
        // 卡片数据
        cardData:[],
        img1: require('@/assets/elecCharge/cdz1.png'),
        img2: require("@/assets/elecCharge/cdz2.png"),
        img3: require("@/assets/elecCharge/cdz3.png"),
    }},




    methods:{
      getCard(val){
      if(val){this.chargeToken = val}
      this.$store.dispatch('getChargeMonitoringPageList', {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        // 调用父组件data中的值
        deviceId: this.$parent.searchValue,
        online: this.$parent.statusData,
        deviceFamily: this.$parent.typeData,
        chargeToken:this.chargeToken,
        estateId:this.estateId,
      })
      .then(()=> {
        const res = this.$store.state.elecCharge.chargeMonitoringPageList
        if(res.errorCode == 0){
          this.cardData = res.data.records
          this.totalNumForPagenation = res.data.total
        }else{
          this.$message(res.message)
        }
      })
    },

      // 更改分页器每页个数
      handleSizeChange(val){
        this.pageSize = val
        this.getCard()
      },
       // 更改分页器页面
      handleCurrentChange(val){
        this.currentPage = val
        this.getCard()
      },


      //更改token并发送请求
    tokenIn(val){
      this.chargeToken = val
      this.getCard()
    },
    //更改小区ID(电站)并发送请求
    stationIn(val){
      this.currentPage =1;
      this.estateId = val
      this.getCard()
    },

    },
}
</script>

<style lang="less" scoped>
    .mainPart{
        width: 102.3rem;
        height: 58rem;
        margin: 0 0 0 2.5rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .pagination{
        float:right;
        margin-right: 2.5rem;
    }

    // .card{
    //     height: 27.4rem;
    //     width: 32.5rem;
    //     float: left;
    //     margin-left: 0.8rem;
    //     margin-right: 0.8rem;
    //     margin-bottom: 1.6rem;
    //     background: linear-gradient(180deg, #07335A 0%, #0A5282 100%);
    //     border-radius: 0.4rem;
    //     .topBar{
    //         height: 4rem;
    //         width: 32.5rem;
    //         border-radius: 0.4rem 0.4rem 0 0;
    //         background: linear-gradient(-90deg, #044482, #0D73D5);
           
    //         .img{
    //             height: 2.4rem;
    //             width: 2.4rem;
    //             margin: 0.8rem 0.8rem 0.8rem 1.6rem;
    //             float: left;
    //         }
    //         .word{
    //             height: 2rem;
    //             line-height: 2rem;
    //             margin: 1rem 0 1rem 0;
    //             font-size: 1.4rem;
    //             color: #fff;
    //             float: left;
    //         }
    //         .point{
    //             height: 0.6rem;
    //             width: 0.6rem;
    //             border-radius: 50%;
    //             margin:1.7rem 1.6rem;
    //             float: right;
    //             background-color: green;
    //         }
    //     }
    //     .contentPlace{
    //         height: 23.4rem;
    //         width: 100%;
    //         border-radius: 0 0 0.4rem 0.4rem;
    //         padding-top: 1rem;
    //         box-sizing: border-box;
    //         .line{
    //             height: 2rem;
    //             margin: 0 1.6rem 1.3rem 1.6rem;
    //             line-height: 2rem;
    //             font-size: 1.4rem;
    //             color: rgba(255,255,255,0.85);
    //         }
    //         .left{
    //             float: left;
    //         }
    //         .right{
    //             float: right;
    //         }
    //     }
    // }

    .cards{
      border: 1px solid #1f4a72;
      width: 187px;
      height: 270px;
      float: left;
      position: relative;
      margin-bottom: 20px;
      img{
        width: 100%;
        position: absolute;
        bottom: 30px;
      }
      .name{
        text-align: center;
        color: #fff;
        font-size: 14px;
        margin-top: 243px;
      }
      .cont{
        display: none;
        position: absolute;
        bottom: 0px;
        width: 100%;
        span{
          width: 58.4%;
          display: inline-block;
          line-height: 30px;
          text-align: center;
          color: #99e7e9;
          border-top: 1px solid #1f4a72;
          margin-top: -5px;
          overflow: hidden;
          padding-left: 3px;
        }
        .label{
          width: 70px;
          color: #fff;
          border-right: 1px solid #1f4a72;
        }
        .val{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      &:hover{
        .cont{
          display: block;
          background: #0d3050;
          opacity: 0.8;
        }
      }
    }
</style>