<template>
    <div class="chartAllOutsider">
        <div class="enerstion-bottom">
            <div class="ChargeAll">
                <div class="ChargeAll-left">
                    <div class="ChargeAll-left-top">
                        <!-- 板块一到板块三################################################################ -->
                        <div v-for="(item,index) in dataOneToThree" :key="index+'one'" :class="item.class">
                            <div class="wordBlockOneToThree">{{item.title}}</div>
                            <div class="cardOneOfOneToThree">
                                <img src="@/assets/elecCharge/1.png" alt="" class="imgOneOfOneToThree">
                                <div class="wordTwoOfOneToThree">预充值</div>
                                <div class="wordFiveOfOneToThree">{{item.num1}} </div>
                                <div class="wordFourOfOneToThree">元</div>
                            </div>
                            <!-- <div class="cardTwoOfOneToThree">
                                <img src="@/assets/elecCharge/2.png" alt="" class="imgOneOfOneToThree">
                                <div class="wordTwoOfOneToThree">用户消费</div>
                                <div class="wordFiveOfOneToThree">{{item.num2}}</div>
                                <div class="wordFourOfOneToThree">元</div>
                            </div> -->
                            <div class="cardThreeOfOneToThree">
                                <div class="cardInside">
                                    <div class="card1">充电桩</div>
                                    <div class="card2">电动自行车</div>
                                    <div class="card2">汽车</div>
                                </div>
                                <div class="cardInside">
                                    <div class="card1">用户消费(元)</div>
                                    <div class="card2">{{ item.num6 }}</div>
                                    <div class="card2">{{ item.num2 }}</div>
                                </div>
                                <div class="cardInside">
                                    <div class="card1">订单数量(笔)</div>
                                    <div class="card2">{{ item.num7 }}</div>
                                    <div class="card2">{{ item.num3 }}</div>
                                </div>
                                <div class="cardInside">
                                    <div class="card1">充电时长(小时)</div>
                                    <div class="card2">{{ item.num8 }}</div>
                                    <div class="card2">{{ item.num4 }}</div>
                                </div>
                                <div class="cardInside">
                                    <div class="card1">充电电量(度)</div>
                                    <div class="card2">{{ item.num9 }}</div>
                                    <div class="card2">{{ item.num5 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 板块四##################################################################### -->
                    <div class="block4">
                        <div class="block4-head">
                            <div class="title">收入总览</div>
                            <div class="filter">
                                <div class="tabChangerOne">
                                    <div :class="tabOneData === 1?'partInside':'partInside2'" @click="tabOneData = 1; tabOneChange(1)">金额</div>
                                    <div :class="tabOneData === 2?'partInside':'partInside2'" @click="tabOneData = 2; tabOneChange(2)">电量</div>
                                </div>
                                <div class="tabChangerTwo">
                                    <div :class="tabTwoData === 1?'partInside':'partInside2'" @click="tabTwoData = 1;valueTimePicker = []; tabTwoChange(1)">周</div>
                                    <div :class="tabTwoData === 2?'partInside':'partInside2'" @click="tabTwoData = 2;valueTimePicker = []; tabTwoChange(2)">月</div>
                                    <div :class="tabTwoData === 3?'partInside':'partInside2'" @click="tabTwoData = 3;valueTimePicker = []; tabTwoChange(3)">年</div>
                                </div>
                                <div class="tabChangerThree">
                                    <!--  range-separator="至" -->
                                    <el-date-picker class="myDatePicker" v-model="valueTimePicker" value-format="yyyy-MM-dd" type="daterange" align="right" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" @change="timePickerChange" :picker-options="pickerOptions">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="chartLine" ref="LineRef"></div>
                    </div>
                </div>
                <div class="ChargeAll-right">
                    <!-- 版块五######################################################################## -->
                    <div class="block5">
                        <div class="title">充电站</div>
                        <div class="block5-content-top">
                            <div class="block5-content-top-item">
                                <img src="@/assets/elecCharge/icon1.png" alt="" class="img1">
                                <div class="info">
                                    <div class="word1">充电站</div>
                                    <div class="word5">{{ dataFive.chargingCount }}<span class="word3">个</span></div>
                                </div>
                            </div>
                            <div class="block5-content-top-item">
                                <img src="@/assets/elecCharge/icon2.png" alt="" class="img1">
                                <div class="info">
                                    <div class="word1">覆盖城市</div>
                                    <div class="word5">{{ dataFive.cityCount }}<span class="word3">个</span></div>
                                </div>
                            </div>
                        </div>
                        <!-- 进度条部分 -->
                        <div class="block5-content-bottom">
                            <div class="block5-content-bottom-item">
                                <div class="info">
                                    <div class="word7">汽车充电桩</div>
                                    <div class="word8">{{ dataFive.carChargingPile }}台 / {{ (percentageOne*100 > 0 ? percentageOne*100 : 0) + '%' }}</div>
                                </div>
                                <div class="bar">
                                    <div class="bar1"></div>
                                    <div class="bar1Inside" :style="{ width:bar1 + '%'}"></div>
                                </div>
                            </div>
                            <div class="block5-content-bottom-item">
                                <div class="info">
                                    <div class="word7">电动自行车电桩</div>
                                    <div class="word8">{{ dataFive.bicycleChargingPile }}台 / {{ (percentageOne*100 > 0 ? percentageOne*100 : 0) + '%' }}</div>
                                </div>
                                <div class="bar">
                                    <div class="bar1"></div>
                                    <div class="bar1Inside" :style="{ width:bar2 + '%'}"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 板块六####################################################################### -->
                    <div class="block6">
                        <!-- 条状图 -->
                        <div class="title">资产信息</div>
                        <div class="bar">
                            <div class="bar3"></div>
                            <div class="bar3Insider1" :style="barLeft ? `width: ${barLeft}%` : ''"></div>
                            <div class="bar3Insider2" :style="{ left:barLeft+'%' }"></div>
                            <div class="bar3Insider3" :style="barRight ? `width: ${barRight}%` : ''"></div>
                        </div>
                        <div class="underBar3">
                            <div class="left">
                                <div class="pointBlue"></div>
                                <div class="wordRightPoint1">直流</div>
                                <div class="wordData1">{{dc + '台'}}</div>
                            </div>
                            <div class="right">
                                <div class="pointYellow"></div>
                                <div class="wordRightPoint2">交流</div>
                                <div class="wordData2">{{ac + '台'}}</div>
                            </div>
                        </div>
                        <!-- 三维饼状图   -->
                        <div class="titleBlock6">支付方式</div>
                        <div id="main" class="chartPie" ref="PieRef"></div>
                    </div>

                    <!-- 板块七########################################################################### -->
                    <div class="block7">
                        <div class="title">本月收入排行</div>
                        <div class="cardBlock7">
                            <div v-for="(item,index2) in rankData" :key="index2 + 'two'" class="cardInsideBlock7">
                                <div class="ball">{{ index2 + 1 }}</div>
                                <div class="word1">{{ item.deviceName }}</div>
                                <div class="word2" v-show="menuListBlock7[0].classType == 'btn2'">{{ item.counts + " 元" }}</div>
                                <div class="word2" v-show="menuListBlock7[1].classType == 'btn2'">{{ item.counts + " 度" }}</div>
                            </div>
                        </div>
                        <!-- 顶部菜单 -->
                        <div class="btnListBlock7">
                            <button :class="item.classType" v-for="(item,index3) in menuListBlock7" :key="index3 +'three'" @click="switchBlock7(item,index3)">
                                {{ item.name }}
                            </button>
                            <!-- @click="routerSwitchTo(item.router,$event)" -->
                        </div>
                    </div>
                </div>
                <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'ChargeAll',
    props: {
        isTreeShow: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            // 驴充充的token令牌
            chargeToken: [],
            // 板块四的tab切换
            tabOneData: 1,
            tabTwoData: 2,

            // 直流 交流
            dc: 0,
            ac: 0,

            // 板块7的tab栏切换
            // 路由菜单的列表
            menuListBlock7: [
                { name: "金额", router: "ChargeAll", classType: "btn2" },
                { name: "电量", router: "ChargeMonitoring", classType: "btn1" },
            ],

            // 板块一到板块三数据
            dataOneToThree: [
                { title: '总收入', class: "block1", num1: "0", num2: "0", num3: "0", num4: "0", num5: "0", num6: "0", num7: "0", num8: "0", num9: "0" },
                { title: '本月收入', class: "block2", num1: "0", num2: "0", num3: "0", num4: "0", num5: "0", num6: "0", num7: "0", num8: "0", num9: "0" },
                { title: '昨日收入', class: "block3", num1: "0", num2: "0", num3: "0", num4: "0", num5: "0", num6: "0", num7: "0", num8: "0", num9: "0" }
            ],

            // 板块五数据
            dataFive: {
                chargingCount: 0,
                cityCount: 0,
                carChargingPile: 0,
                bicycleChargingPile: 0
            },

            // 板块五 百分比数值显示
            percentageOne: 0,
            percentageTwo: 0,

            // 进度条数据(left起始值设为0，right拉满，做动画用)
            // bar1: 29.9*0.5,
            bar1: 0,
            // bar2: 29.9*0.5,
            bar2: 0,
            barLeft: null,
            barRight: null,


            // 右下角排名数据
            rankData: [],

            // 饼状图数据####################################################################################
            option: {
                color: ['#49c384', '#2b8ef3', '#93DBFF'],
                tooltip: { trigger: 'item' },
                legend: {
                    right: this.fontSize(1),
                    orient: 'vertical',
                    x: 'right',
                    y: 'middle',
                    itemHeight: 7,
                    itemWidth: 7,
                    textStyle: { color: '#fff', fontSize: this.fontSize(1.2), itemGap: 0 },
                },
                series: [
                    {
                        name: '支付方式',
                        type: 'pie',
                        radius: '70%',
                        center: ["42%", "53%"],
                        data: [
                            {
                                value: 0,
                                name: '微信',
                                itemStyle: {
                                    normal: {
                                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                            { offset: 0, color: '#1EE7E7' },
                                            { offset: 1, color: 'rgba(30, 231, 231, 0.35)' }
                                        ]),
                                    }
                                },
                            },
                            {
                                value: 0,
                                name: '支付宝',
                                itemStyle: {
                                    normal: {
                                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                            { offset: 0, color: '#48c182' },
                                            { offset: 1, color: '#53e2ab' }
                                        ]),
                                    }
                                },
                            },
                            {
                                value: 0,
                                name: '实体卡',
                                itemStyle: {
                                    normal: {
                                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                            { offset: 0, color: '#1890FF' },
                                            { offset: 1, color: 'rgba(24, 144, 255, 0.35)' }
                                        ]),
                                    }
                                },
                            },
                        ],
                        // emphasis: {
                        //     itemStyle: {
                        //         shadowBlur: 10,
                        //         shadowOffsetX: 0,
                        //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                        //     }
                        // },
                        // 去除指示线
                        label: {
                            normal: {
                                // show:false,
                                color: '#e6f7ff',
                                fontSize: this.fontSize(1.2),
                                show: true,
                                formatter: '{d}%',
                                position: 'outer',
                            }
                        },
                        labelLine: {
                            show: true,
                            length: 10,
                        },
                    }
                ],

            },

            // 直方图折线图数据####################################################################################
            optionLine: {
                color: ["#41E4BB", "#1890FF"],
                tooltip: {
                    extraCssText: "width:17rem;height:14rem",
                    backgroundColor: 'rgba(0,0,0,0.65)',
                    borderColor: 'rgba(0,0,0,0.65)',
                    color: "#E6F7FF",
                    trigger: 'axis',
                    axisPointer: {
                        type: 'line',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    formatter: ((params) => {
                        if (this.tabOneData === 1) {
                            let strArr = []
                            if (params[0] && params[0].name) {
                                strArr.push(`时间：${params[0].name}<br>`)
                            }
                            params.forEach(item => {
                                if (item.data) {
                                    strArr.push(`${item.seriesName}：<span style="color:#1ccacf">${item.data}${item.seriesName.indexOf('金额') != -1 ? '元' : 'h'}</span><br>`)
                                }
                            })
                            return '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' + strArr.join('') + '</span>'
                            // return '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">'
                            //     + '时间：' + params[0].name + '<br>'
                            //     + '电动自行车充电桩金额： ' + '<span style="color:#1ccacf">' + params[0].data + ' 元' + '</span>' + '<br>'
                            //     + '汽车充电桩金额： ' + '<span style="color:#1573ca">' + params[1].data + ' 元' + '</span>' + '<br>'
                            //     + '电动自行车充电时长： ' + '<span style="color:#41e4bb">' + params[2].data + ' h' + '</span>' + '<br>'
                            //     + '汽车充电桩充电时长： ' + '<span style="color:#1890ff">' + params[3].data + ' h' + '</span>'
                            //     + '</span>';
                        } else if (this.tabOneData === 2) {
                            let strArr = []
                            if (params[0] && params[0].name) {
                                strArr.push(`时间：${params[0].name}<br>`)
                            }
                            params.forEach(item => {
                                if (item.data) {
                                    strArr.push(`${item.seriesName}：<span style="color:#1ccacf">${item.data}${item.seriesName.indexOf('电量') != -1 ? '度' : 'h'}</span><br>`)
                                }
                            })
                            return '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' + strArr.join('') + '</span>'

                            // return '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">'
                            //     + '时间：' + params[0].name + '<br>'
                            //     + '电动自行车充电桩电量： ' + '<span style="color:#1ccacf">' + params[0].data + ' 度' + '</span>' + '<br>'
                            //     + '汽车充电桩电量 ' + '<span style="color:#1573ca">' + params[1].data + ' 度' + '</span>' + '<br>'
                            //     + '电动自行车充电时长： ' + '<span style="color:#41e4bb">' + params[2].data + ' h' + '</span>' + '<br>'
                            //     + '汽车充电桩充电时长： ' + '<span style="color:#1890ff">' + params[3].data + ' h' + '</span>'
                            //     + '</span>';
                        }
                    })
                },
                legend: {
                    x: 'center',
                    y: 'bottom',
                    // itemGap:60,
                    itemWidth: 20,
                    itemHeight: 16,
                    textStyle: {
                        color: 'rgba(255, 255, 255, 0.85)',
                        fontSize: 12,
                        itemGap: "50%",
                    },
                },
                xAxis: [
                    {
                        type: 'category',
                        // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        data: [],
                        axisPointer: { type: 'shadow' },
                        axisLabel: {
                            textStyle: {
                                color: 'rgba(230, 247, 255, 0.85)'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#BAE7FF'
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '金额(元)',
                        min: 0,
                        // max: 250,
                        interval: 50,
                        nameTextStyle: {
                            color: "rgba(230, 247, 255, 0.7)",
                            fontSize: 12,
                        },
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                show: true,
                                color: 'rgba(230, 247, 255, 0.85)'
                            },
                        },
                        // 分割线样式
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: "rgba(230, 247, 255, 0.2)"
                            }
                        }
                    },
                    {
                        type: 'value',
                        name: '充电时长(小时)',
                        min: 0,
                        interval: 5,
                        nameTextStyle: {
                            color: "rgba(230, 247, 255, 0.7)",
                            fontSize: 12,
                        },
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                color: 'rgba(230, 247, 255, 0.85)'
                            },
                        },
                        // 分割线样式
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: "rgba(230, 247, 255, 0.2)"
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '电动自行车充电桩金额',
                        barWidth: this.fontSize(1),
                        barGap: '50%',
                        type: 'bar',
                        data: [],
                        // 设置渐变色
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: '#1EE7E7' },
                                    { offset: 1, color: 'rgba(30, 231, 231, 0.35)' }
                                ]),
                            }
                        },
                    },
                    {
                        name: '汽车充电桩金额',
                        type: 'bar',
                        barWidth: this.fontSize(1),
                        barGap: '50%',
                        data: [],
                        // 设置渐变色
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: '#1890FF' },
                                    { offset: 1, color: 'rgba(24, 144, 255, 0.35)' }
                                ]),
                            }
                        },
                    },
                    {
                        name: '电动自行车充电桩充电时长',
                        type: 'line',
                        symbol: "none",
                        yAxisIndex: 1,
                        data: [],
                    },
                    {
                        name: '汽车充电桩充电时长',
                        type: 'line',
                        symbol: "none",
                        yAxisIndex: 1,
                        data: []
                    }
                ]
            },


            // 时间选择器的数据
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick (picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick (picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick (picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },

            // 日期选择器双向绑定的数据
            valueTimePicker: [],
            // 搜索框时间
            beginTime: '',
            endTime: ''
        }
    },
    watch: {
        isTreeShow (val) {
            const barWidth = document.getElementsByClassName('bar')[0].offsetWidth / 10
            // 板块五进度条渲染
            this.bar1 = ((barWidth * this.dataFive.carChargingPile / (this.dataFive.carChargingPile + this.dataFive.bicycleChargingPile)) / barWidth * 100).toFixed(2)
            this.bar1 = this.bar1 >= 0 ? this.bar1 : 100
            this.bar2 = ((barWidth * this.dataFive.bicycleChargingPile / (this.dataFive.carChargingPile + this.dataFive.bicycleChargingPile)) / barWidth * 100).toFixed(2)
            this.bar2 = this.bar2 >= 0 ? this.bar2 : 100
            this.barLeft = ((barWidth * (this.dc / (this.dc + this.ac))) / barWidth * 100).toFixed(2)
            this.barRight = ((barWidth * (this.ac / (this.dc + this.ac))) / barWidth * 100).toFixed(2)
        },
    },
    mounted () {
        this.optionCanvas()
        this.optionLineCanvas()
        this.$bus.$on('chargeForTree', (val) => {
            if (val.length == 0) {
                this.noData();
                this.optionCanvas()
                this.$message.warning('该用户未绑定充电业务！');
                return;
            }
            this.chargeToken = val
            this.cardList()
            this.tabOneChange(this.tabOneData)
            this.tabTwoChange(this.tabTwoData)
            this.optionCanvas()
        })
    },

    created () {
        // this.cardList()
        // this.tabOneChange(1)
        // this.tabTwoChange(2)
        // 清除同名的所有时间总线，自己和其他兄弟模块的
        this.$bus.$off('chargeForTree')
    },

    // 死前刷新树
    beforeDestroy () {
        this.$bus.$emit('refreshTheChargeTree')
    },

    methods: {
        // 清空数据，全部变成---
        noData () {
            this.dataOneToThree = [
                { title: '总收入', class: "block1", num1: "--", num2: "--", num3: "--", num4: "--", num5: "--" },
                { title: '本月收入', class: "block2", num1: "--", num2: "--", num3: "--", num4: "--", num5: "--" },
                { title: '昨日收入', class: "block3", num1: "--", num2: "--", num3: "--", num4: "--", num5: "--" }
            ]
            // 板块五数据
            this.dataFive = {
                chargingCount: '--',
                cityCount: '--',
                carChargingPile: '--',
                bicycleChargingPile: '--'
            }
            this.dc = '--'
            this.ac = '--'
            this.percentageOne = 0
            this.percentageTwo = 0
            this.rankData = []
            this.option.series[0].data = [{
                value: 0,
                name: '微信',
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            { offset: 0, color: '#1EE7E7' },
                            { offset: 1, color: 'rgba(30, 231, 231, 0.35)' }
                        ]),
                    }
                },
            },
            {
                value: 0,
                name: '支付宝',
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            { offset: 0, color: '#48c182' },
                            { offset: 1, color: '#53e2ab' }
                        ]),
                    }
                },
            },
            {
                value: 0,
                name: '实体卡',
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                            { offset: 0, color: '#1890FF' },
                            { offset: 1, color: 'rgba(24, 144, 255, 0.35)' }
                        ]),
                    }
                },
            },]
            this.optionCanvas()
        },

        // 触发日期选择器
        timePickerChange () {
            // 切状态
            this.tabTwoData = 4
            this.beginTime = this.valueTimePicker[0]
            this.endTime = this.valueTimePicker[1]
            this.$store.dispatch('getChargeAllChartData', {
                beginTime: this.beginTime,
                endTime: this.endTime,
                chargeToken: this.chargeToken,
            }).then(() => {
                const res = this.$store.state.elecCharge.chargeAllChartData
                if (res.errorCode == 0) {
                    if (this.valueTimePicker) {
                        this.optionLine.xAxis[0].data = res.data.myIntegerGraphUnit.time
                        if (this.tabOneData == 1) {
                            this.optionLine.series[0].data = res.data.myIntegerGraphUnit.bikeMoneyData
                            this.optionLine.series[1].data = res.data.myIntegerGraphUnit.carMoneyData
                        } else if (this.tabOneData == 2) {
                            this.optionLine.series[0].data = res.data.myIntegerGraphUnit.bikeEleData
                            this.optionLine.series[1].data = res.data.myIntegerGraphUnit.carEleData
                        }
                        this.optionLine.series[2].data = res.data.myIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.myIntegerGraphUnit.carTimeData
                        // echarts数据渲染完后必须紧跟canvas渲染函数
                        this.optionLineCanvas()
                    }

                }
            })
        },

        // 卡片渲染
        cardList () {
            this.$store.dispatch('getChargeAllData', { chargeToken: this.chargeToken }).then(() => {
                const res = this.$store.state.elecCharge.chargeAllData
                if (res.errorCode == 0) {
                    this.dataOneToThree[0].num1 = res.data.allIncome.aheadTopUp
                    this.dataOneToThree[0].num2 = res.data.allIncome.userConsumption
                    this.dataOneToThree[0].num3 = res.data.allIncome.orderNum
                    this.dataOneToThree[0].num4 = res.data.allIncome.actualTime
                    this.dataOneToThree[0].num5 = res.data.allIncome.electric
                    this.dataOneToThree[0].num6 = res.data.eleAllIncome.userConsumption
                    this.dataOneToThree[0].num7 = res.data.eleAllIncome.orderNum
                    this.dataOneToThree[0].num8 = res.data.eleAllIncome.actualTime
                    this.dataOneToThree[0].num9 = res.data.eleAllIncome.electric

                    this.dataOneToThree[1].num1 = res.data.mouthIncome.aheadTopUp
                    this.dataOneToThree[1].num2 = res.data.mouthIncome.userConsumption
                    this.dataOneToThree[1].num3 = res.data.mouthIncome.orderNum
                    this.dataOneToThree[1].num4 = res.data.mouthIncome.actualTime
                    this.dataOneToThree[1].num5 = res.data.mouthIncome.electric
                    this.dataOneToThree[1].num6 = res.data.eleMouthIncome.userConsumption
                    this.dataOneToThree[1].num7 = res.data.eleMouthIncome.orderNum
                    this.dataOneToThree[1].num8 = res.data.eleMouthIncome.actualTime
                    this.dataOneToThree[1].num9 = res.data.eleMouthIncome.electric

                    this.dataOneToThree[2].num1 = res.data.yesterdayIncome.aheadTopUp
                    this.dataOneToThree[2].num2 = res.data.yesterdayIncome.userConsumption
                    this.dataOneToThree[2].num3 = res.data.yesterdayIncome.orderNum
                    this.dataOneToThree[2].num4 = res.data.yesterdayIncome.actualTime
                    this.dataOneToThree[2].num5 = res.data.yesterdayIncome.electric
                    this.dataOneToThree[2].num6 = res.data.eleYesterdayIncome.userConsumption
                    this.dataOneToThree[2].num7 = res.data.eleYesterdayIncome.orderNum
                    this.dataOneToThree[2].num8 = res.data.eleYesterdayIncome.actualTime
                    this.dataOneToThree[2].num9 = res.data.eleYesterdayIncome.electric

                    this.dataFive.chargingCount = res.data.chargingCount
                    this.dataFive.cityCount = res.data.cityCount
                    this.dataFive.carChargingPile = res.data.carChargingPile
                    this.dataFive.bicycleChargingPile = res.data.bicycleChargingPile
                    const barWidth = document.getElementsByClassName('bar')[0].offsetWidth / 10
                    // 板块五进度条渲染
                    this.bar1 = ((barWidth * res.data.carChargingPile / (res.data.carChargingPile + res.data.bicycleChargingPile)) / barWidth * 100).toFixed(2)
                    this.bar1 = this.bar1 >= 0 ? this.bar1 : 100
                    this.bar2 = ((barWidth * res.data.bicycleChargingPile / (res.data.carChargingPile + res.data.bicycleChargingPile)) / barWidth * 100).toFixed(2)
                    this.bar2 = this.bar2 >= 0 ? this.bar2 : 100
                    this.percentageOne = res.data.carChargingPile / (res.data.carChargingPile + res.data.bicycleChargingPile)
                    this.percentageTwo = res.data.bicycleChargingPile / (res.data.carChargingPile + res.data.bicycleChargingPile)

                    this.dc = res.data.dc
                    this.ac = res.data.ac
                    // 板块六进度条渲染
                    this.barLeft = ((barWidth * (res.data.dc / (res.data.dc + res.data.ac))) / barWidth * 100).toFixed(2)
                    this.barRight = ((barWidth * (res.data.ac / (res.data.dc + res.data.ac))) / barWidth * 100).toFixed(2)

                    // 板块七默认展示 金额
                    this.rankData = res.data.moneyCount

                    // 饼状图数据渲染
                    this.option.series[0].data[0].value = res.data.wx
                    this.option.series[0].data[1].value = res.data.zfb
                    this.option.series[0].data[2].value = res.data.car
                    // echarts数据渲染完后必须紧跟canvas渲染函数
                    this.optionCanvas()
                    // echarts数据渲染完后必须紧跟canvas渲染函数
                    // this.optionLineCanvas()
                }
            })
            this.$store.dispatch('getChargeAllChartData', { chargeToken: this.chargeToken, beginTime: '', endTime: ''}).then(() => {
                const res = this.$store.state.elecCharge.chargeAllChartData
                // 折线图数据渲染(tabOne默认展示金额, tabTwo默认展示月)
                if (this.tabOneData == 1) {
                    if (this.tabTwoData == 1) {
                        this.optionLine.series[0].data = res.data.thisWeekIntegerGraphUnit.bikeMoneyData
                        this.optionLine.series[1].data = res.data.thisWeekIntegerGraphUnit.carMoneyData
                        this.optionLine.xAxis[0].data = res.data.thisWeekIntegerGraphUnit.time
                        this.optionLine.series[2].data = res.data.thisWeekIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisWeekIntegerGraphUnit.carTimeData
                    } else if (this.tabTwoData == 2) {
                        this.optionLine.series[0].data = res.data.thisMonthIntegerGraphUnit.bikeMoneyData
                        this.optionLine.series[1].data = res.data.thisMonthIntegerGraphUnit.carMoneyData
                        this.optionLine.xAxis[0].data = res.data.thisMonthIntegerGraphUnit.time
                        this.optionLine.series[2].data = res.data.thisMonthIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisMonthIntegerGraphUnit.carTimeData
                    } else if (this.tabTwoData == 3) {
                        this.optionLine.series[0].data = res.data.thisYearIntegerGraphUnit.bikeMoneyData
                        this.optionLine.series[1].data = res.data.thisYearIntegerGraphUnit.carMoneyData
                        this.optionLine.xAxis[0].data = res.data.thisYearIntegerGraphUnit.time
                        this.optionLine.series[2].data = res.data.thisYearIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisYearIntegerGraphUnit.carTimeData
                    }
                    this.optionLineCanvas()
                } else if (this.tabOneData == 2) {
                    if (this.tabTwoData == 1) {
                        this.optionLine.series[0].data = res.data.thisMonthIntegerGraphUnit.bikeEleData
                        this.optionLine.series[1].data = res.data.thisMonthIntegerGraphUnit.carEleData
                        this.optionLine.xAxis[0].data = res.data.thisMonthIntegerGraphUnit.time
                        this.optionLine.series[2].data = res.data.thisMonthIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisMonthIntegerGraphUnit.carTimeData
                    } else if (this.tabTwoData == 2) {
                        this.optionLine.series[0].data = res.data.thisMonthIntegerGraphUnit.bikeEleData
                        this.optionLine.series[1].data = res.data.thisMonthIntegerGraphUnit.carEleData
                        this.optionLine.xAxis[0].data = res.data.thisMonthIntegerGraphUnit.time
                        this.optionLine.series[2].data = res.data.thisMonthIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisMonthIntegerGraphUnit.carTimeData
                    } else if (this.tabTwoData == 3) {
                        this.optionLine.series[0].data = res.data.thisYearIntegerGraphUnit.bikeEleData
                        this.optionLine.series[1].data = res.data.thisYearIntegerGraphUnit.carEleData
                        this.optionLine.xAxis[0].data = res.data.thisYearIntegerGraphUnit.time
                        this.optionLine.series[2].data = res.data.thisYearIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisYearIntegerGraphUnit.carTimeData
                    }
                    this.optionLineCanvas()
                }
            })
        },

        // 折线图tab切换 金额及电量
        tabOneChange (valOne) {
            // this.$store.dispatch('getChargeAllData', { chargeToken: this.chargeToken }).then(() => {
                const res = this.$store.state.elecCharge.chargeAllChartData
                if (res.errorCode == 0) {
                    if (valOne === 1) {
                        this.optionLine.series[0].name = "电动自行车充电桩金额"
                        this.optionLine.series[1].name = "汽车充电桩金额"
                        this.optionLine.yAxis[0].name = "金额(元)"
                        if (this.tabTwoData == 1) {
                            this.optionLine.series[0].data = res.data.thisWeekIntegerGraphUnit.bikeMoneyData
                            this.optionLine.series[1].data = res.data.thisWeekIntegerGraphUnit.carMoneyData
                            this.optionLine.xAxis[0].data = res.data.thisWeekIntegerGraphUnit.time
                            this.optionLine.series[2].data = res.data.thisWeekIntegerGraphUnit.bikeTimeData
                            this.optionLine.series[3].data = res.data.thisWeekIntegerGraphUnit.carTimeData
                        } else if (this.tabTwoData == 2) {
                            this.optionLine.series[0].data = res.data.thisMonthIntegerGraphUnit.bikeMoneyData
                            this.optionLine.series[1].data = res.data.thisMonthIntegerGraphUnit.carMoneyData
                            this.optionLine.xAxis[0].data = res.data.thisMonthIntegerGraphUnit.time
                            this.optionLine.series[2].data = res.data.thisMonthIntegerGraphUnit.bikeTimeData
                            this.optionLine.series[3].data = res.data.thisMonthIntegerGraphUnit.carTimeData
                        } else if (this.tabTwoData == 3) {
                            this.optionLine.series[0].data = res.data.thisYearIntegerGraphUnit.bikeMoneyData
                            this.optionLine.series[1].data = res.data.thisYearIntegerGraphUnit.carMoneyData
                            this.optionLine.xAxis[0].data = res.data.thisYearIntegerGraphUnit.time
                            this.optionLine.series[2].data = res.data.thisYearIntegerGraphUnit.bikeTimeData
                            this.optionLine.series[3].data = res.data.thisYearIntegerGraphUnit.carTimeData
                        }
                        this.optionLineCanvas()
                    } else if (valOne === 2) {
                        this.optionLine.series[0].name = "电动自行车充电桩电量"
                        this.optionLine.series[1].name = "汽车充电桩电量"
                        this.optionLine.yAxis[0].name = "电量(度)"
                        if (this.tabTwoData == 1) {
                            this.optionLine.series[0].data = res.data.thisWeekIntegerGraphUnit.bikeEleData
                            this.optionLine.series[1].data = res.data.thisWeekIntegerGraphUnit.carEleData
                            this.optionLine.xAxis[0].data = res.data.thisWeekIntegerGraphUnit.time
                            this.optionLine.series[2].data = res.data.thisWeekIntegerGraphUnit.bikeTimeData
                            this.optionLine.series[3].data = res.data.thisWeekIntegerGraphUnit.carTimeData
                        } else if (this.tabTwoData == 2) {
                            this.optionLine.series[0].data = res.data.thisMonthIntegerGraphUnit.bikeEleData
                            this.optionLine.series[1].data = res.data.thisMonthIntegerGraphUnit.carEleData
                            this.optionLine.xAxis[0].data = res.data.thisMonthIntegerGraphUnit.time
                            this.optionLine.series[2].data = res.data.thisMonthIntegerGraphUnit.bikeTimeData
                            this.optionLine.series[3].data = res.data.thisMonthIntegerGraphUnit.carTimeData
                        } else if (this.tabTwoData == 3) {
                            this.optionLine.series[0].data = res.data.thisYearIntegerGraphUnit.bikeEleData
                            this.optionLine.series[1].data = res.data.thisYearIntegerGraphUnit.carEleData
                            this.optionLine.xAxis[0].data = res.data.thisYearIntegerGraphUnit.time
                            this.optionLine.series[2].data = res.data.thisYearIntegerGraphUnit.bikeTimeData
                            this.optionLine.series[3].data = res.data.thisYearIntegerGraphUnit.carTimeData
                        }
                        this.optionLineCanvas()
                    }
                }
            // })
        },

        // 折线图tab切换  周 月 年
        tabTwoChange (valTwo) {
            // this.$store.dispatch('getChargeAllData', { chargeToken: this.chargeToken }).then(() => {
                const res = this.$store.state.elecCharge.chargeAllChartData
                if (res.errorCode == 0) {
                    if (valTwo === 1) {
                        this.optionLine.xAxis[0].data = res.data.thisWeekIntegerGraphUnit.time
                        this.optionLine.series[2].data = res.data.thisWeekIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisWeekIntegerGraphUnit.carTimeData
                        if (this.tabOneData == 1) {
                            this.optionLine.series[0].data = res.data.thisWeekIntegerGraphUnit.bikeMoneyData
                            this.optionLine.series[1].data = res.data.thisWeekIntegerGraphUnit.carMoneyData
                        } else if (this.tabOneData == 2) {
                            this.optionLine.series[0].data = res.data.thisWeekIntegerGraphUnit.bikeEleData
                            this.optionLine.series[1].data = res.data.thisWeekIntegerGraphUnit.carEleData
                        }
                        this.optionLineCanvas()
                    } else if (valTwo === 2) {
                        this.optionLine.xAxis[0].data = res.data.thisMonthIntegerGraphUnit.time
                        this.optionLine.series[2].data = res.data.thisMonthIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisMonthIntegerGraphUnit.carTimeData
                        if (this.tabOneData == 1) {
                            this.optionLine.series[0].data = res.data.thisMonthIntegerGraphUnit.bikeMoneyData
                            this.optionLine.series[1].data = res.data.thisMonthIntegerGraphUnit.carMoneyData
                        } else if (this.tabOneData == 2) {
                            this.optionLine.series[0].data = res.data.thisMonthIntegerGraphUnit.bikeEleData
                            this.optionLine.series[1].data = res.data.thisMonthIntegerGraphUnit.carEleData
                        }
                        this.optionLineCanvas()
                    } else if (valTwo === 3) {
                        this.optionLine.xAxis[0].data = res.data.thisYearIntegerGraphUnit.time
                        // this.optionLine.series[0].data = res.data.thisYearIntegerGraphUnit.bikeMoneyData
                        // this.optionLine.series[1].data = res.data.thisYearIntegerGraphUnit.carMoneyData
                        this.optionLine.series[2].data = res.data.thisYearIntegerGraphUnit.bikeTimeData
                        this.optionLine.series[3].data = res.data.thisYearIntegerGraphUnit.carTimeData
                        if (this.tabOneData == 1) {
                            this.optionLine.series[0].data = res.data.thisYearIntegerGraphUnit.bikeMoneyData
                            this.optionLine.series[1].data = res.data.thisYearIntegerGraphUnit.carMoneyData
                        } else if (this.tabOneData == 2) {
                            this.optionLine.series[0].data = res.data.thisYearIntegerGraphUnit.bikeEleData
                            this.optionLine.series[1].data = res.data.thisYearIntegerGraphUnit.carEleData
                        }
                        this.optionLineCanvas()
                    }
                }
            // })
        },

        // 点击板块7的切换tab
        switchBlock7 (item, index) {
            // this.$store.dispatch('getChargeAllData', { chargeToken: this.chargeToken }).then(() => {
                const res = this.$store.state.elecCharge.chargeAllData
                if (res.errorCode == 0) {
                    // 表现层
                    if (index == 0) {
                        this.menuListBlock7[0].classType = "btn2"
                        this.menuListBlock7[1].classType = "btn1"
                        this.rankData = res.data.moneyCount
                    } else {
                        this.menuListBlock7[0].classType = "btn1"
                        this.menuListBlock7[1].classType = "btn2"
                        this.rankData = res.data.electricCount
                    }
                }
            // })
        },

        // 获取屏幕比例，使echarts自适应
        fontSize (res) {
            let docEl = document.documentElement,
                clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (!clientWidth) return;
            let fontSize = (clientWidth / 192);
            return res * fontSize;
        },

        // 饼状图canvas渲染函数
        optionCanvas () {
            const myChart = this.$echarts.init(this.$refs.PieRef);
            myChart.setOption(this.option);
            // 找到echarts中的canvas节点，并对其宽高做自适应
            // myChart._dom.childNodes[0].childNodes[0].style.height="14rem"
            // myChart._dom.childNodes[0].childNodes[0].style.width="32.4rem"
            // myChart._dom.childNodes[0].childNodes[0].style.height = "14rem"
            // myChart._dom.childNodes[0].childNodes[0].style.width = "32.4rem"
        },
        // 折线图canvas渲染函数
        optionLineCanvas () {
            const myChartLine = this.$echarts.init(this.$refs.LineRef);
            myChartLine.setOption(this.optionLine);
            myChartLine._dom.childNodes[0].childNodes[0].style.height = "52rem"
            // myChartLine._dom.childNodes[0].childNodes[0].style.width = "115rem"
        },
        isNumber (num) {
            return typeof num === 'number' && !isNaN(num)
        }
    }
}
</script>

<style lang="less" scoped>
.chartAllOutsider {
    overflow: hidden;
    width: 100%;
    height: 88.8rem;
    .enerstion-bottom{
        position: relative;
        overflow: scroll;
        overflow-x: hidden;
        width: calc(100% + 0.6rem);
        height: calc(100vh - 16.2rem);
        .ChargeAll {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: nowrap;
            .ChargeAll-left {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1 1 107rem;
                margin-right: 2rem;
                .ChargeAll-left-top {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.5rem;
                    width: 100%;
                    .block1,
                    .block2,
                    .block3 {
                        flex: 1;
                        margin-right: 1.5rem;
                        height: 29.6rem;
                        padding: 2.4rem;
                        background-color: #0c2f4f;
                        border-radius: 0.4rem;
                        box-sizing: border-box;
                        .wordBlockOneToThree {
                            margin-bottom: 1.4rem;
                            line-height: 2.2rem;
                            font-size: 1.6rem;
                            color: #37a5dd;
                        }
                        .cardOneOfOneToThree,
                        .cardTwoOfOneToThree {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 0.8rem;
                            height: 4.6rem;
                            padding: 1.25rem 1.751rem;
                            background: linear-gradient(
                                180deg,
                                rgba(28, 92, 135, 0.5),
                                rgba(0, 40, 77, 0.5)
                            );
                            border-radius: 0.4rem;
                            box-sizing: border-box;
                            .imgOneOfOneToThree {
                                margin-right: 1rem;
                                width: 2.1rem;
                                height: 2.1rem;
                            }
                            .wordTwoOfOneToThree {
                                flex: 1;
                                line-height: 2rem;
                                font-size: 1.4rem;
                                font-weight: 900;
                                color: transparent;
                            }
                            .wordFiveOfOneToThree {
                                margin-right: 0.3rem;
                                line-height: 2.2rem;
                                font-size: 1.6rem;
                                font-weight: 900;
                                color: #fbfbfb;
                            }
                            .wordFourOfOneToThree {
                                line-height: 1.7rem;
                                font-size: 1.2rem;
                                color: hsla(0, 0%, 100%, 0.6);
                            }
                        }
                        .cardTwoOfOneToThree {
                            .wordTwoOfOneToThree {
                                background: linear-gradient(
                                    180deg,
                                    #68d1f0,
                                    #37a5dd
                                );
                                background-clip: text;
                            }
                        }
                        .cardOneOfOneToThree {
                            .wordTwoOfOneToThree {
                                background: linear-gradient(
                                    180deg,
                                    #38d9c4,
                                    #1ab292
                                );
                                background-clip: text;
                            }
                        }
                        .cardThreeOfOneToThree {
                            display: flex;
                            flex-direction: column;
                            padding: 0.8rem 1.6rem;
                            background-color: #0a365c;
                            .cardInside {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 0.8rem;
                                .card1,
                                .card2 {
                                    line-height: 2rem;
                                    font-size: 1.4rem;
                                    color: #e6f7ff;
                                    text-align: right;
                                    width: 36%;
                                }
                                .card1{
                                    width: 104px;
                                    text-align: left;
                                }
                            }
                        }
                    }
                    .block3 {
                        margin-right: 0;
                    }
                }
                .block4 {
                    padding: 2.4rem;
                    width: 100%;
                    height: 57.6rem;
                    background-color: #0c2f4f;
                    border-radius: 0.4rem;
                    box-sizing: border-box;
                    .block4-head {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .title {
                            flex: 1;
                            line-height: 2.2rem;
                            font-size: 1.6rem;
                            color: #37a5dd;
                        }
                        .filter {
                            display: flex;
                            align-items: center;
                            .tabChangerOne,
                            .tabChangerTwo {
                                display: flex;
                                align-items: center;
                                margin-right: 2.2rem;
                                line-height: 3rem;
                                font-size: 1.4rem;
                                color: #fff;
                                background-color: #0b365d;
                                border: 0.1rem solid #004579;
                                border-radius: 0.2rem;
                                cursor: pointer;
                                .partInside,
                                .partInside2 {
                                    flex: 1;
                                    flex-shrink: 0;
                                    width: 6rem;
                                    text-align: center;
                                }
                                .partInside {
                                    background-color: #027969;
                                }
                            }
                            .el-date-editor {
                                width: 25rem;
                                height: 3.2rem;
                            }
                        }
                    }
                    .chartLine {
                        width: 100%;
                        height: 52rem;
                    }
                }
            }
            .ChargeAll-right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                flex: 1 1 34.2rem;

                .block5,
                .block6,
                .block7 {
                    margin-bottom: 1.6rem;
                    width: 100%;
                    padding: 2.4rem;
                    background-color: #0c2f4f;
                    border-radius: 0.4rem;
                    box-sizing: border-box;
                    .title {
                        margin-bottom: 1.4rem;
                        line-height: 2.2rem;
                        font-size: 1.6rem;
                        color: #37a5dd;
                    }
                }
                .block5 {
                    display: flex;
                    flex-direction: column;
                    .block5-content-top {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1.6rem;
                        .block5-content-top-item {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            .img1 {
                                margin-right: 0.8rem;
                                width: 5.7rem;
                                height: 5.7rem;
                            }
                            .info {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                .word1 {
                                    line-height: 2rem;
                                    font-size: 1.4rem;
                                    font-weight: 900;
                                    color: transparent;
                                    background: linear-gradient(
                                        180deg,
                                        #68d1f0,
                                        #37a5dd
                                    );
                                    background-clip: text;
                                }
                                .word5 {
                                    line-height: 3.1rem;
                                    font-size: 2.2rem;
                                    color: #fbfbfb;
                                    .word3 {
                                        margin-left: 0.3rem;
                                        line-height: 1.7rem;
                                        font-size: 1.2rem;
                                        color: hsla(0, 0%, 100%, 0.65);
                                    }
                                }
                            }
                            &:first-child {
                                margin-right: 4rem;
                            }
                        }
                    }
                    .block5-content-bottom {
                        display: flex;
                        flex-direction: column;
                        .block5-content-bottom-item {
                            display: flex;
                            flex-direction: column;
                            .info {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 0.5rem;
                                line-height: 2rem;
                                font-size: 1.4rem;
                                .word7 {
                                    color: #fff;
                                }
                                .word8 {
                                    color: #e6f7ff;
                                }
                            }
                            .bar {
                                position: relative;
                                height: 0.8rem;
                                .bar1,
                                .bar1Inside {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    height: 100%;
                                    background-color: hsla(0, 0%, 100%, 0.1);
                                }
                                .bar1Inside {
                                    background: linear-gradient(
                                        270deg,
                                        #1890ff,
                                        #1ee7e7
                                    );
                                    transition: width 0.5s;
                                }
                            }
                            &:first-child {
                                margin-bottom: 1.4rem;
                            }
                        }
                    }
                }
                .block6 {
                    display: flex;
                    flex-direction: column;
                    .bar {
                        position: relative;
                        margin-bottom: 0.5rem;
                        width: 100%;
                        height: 1.4rem;
                        .bar3 {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                        }
                        .bar3Insider1 {
                            position: absolute;
                            top: 0.1rem;
                            left: 0;
                            width: 50%;
                            height: 1.2rem;
                            background: linear-gradient(
                                270deg,
                                #1ee7e7,
                                rgba(30, 231, 231, 0.35)
                            );
                            transition: width 0.5s;
                        }
                        .bar3Insider2 {
                            position: absolute;
                            top: 0;
                            left: calc(50% - 0.1rem);
                            width: 0.2rem;
                            height: 1.4rem;
                            background-color: #fff;
                            transition: left 0.5s;
                            z-index: 2;
                        }
                        .bar3Insider3 {
                            position: absolute;
                            top: 0.1rem;
                            right: 0;
                            width: 50%;
                            height: 1.2rem;
                            background: linear-gradient(
                                270deg,
                                rgba(255, 182, 40, 0.35),
                                #ffac5f
                            );
                            transition: width 0.5s;
                        }
                    }
                    .underBar3 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 1.5rem;
                        .left,
                        .right {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            line-height: 2rem;
                            font-size: 1.4rem;
                            color: #fff;
                            .wordData1,
                            .wordData2 {
                                margin-left: 3rem;
                                font-weight: 900;
                            }
                        }
                        .left {
                            justify-content: flex-start;
                            .pointBlue {
                                margin-right: 0.4rem;
                                width: 0.8rem;
                                height: 0.8rem;
                                background-color: #1ee1e2;
                                border-radius: 50%;
                            }
                        }
                        .right {
                            justify-content: flex-end;
                            .pointYellow {
                                margin-right: 0.4rem;
                                width: 0.8rem;
                                height: 0.8rem;
                                background-color: #f6a85c;
                                border-radius: 50%;
                            }
                        }
                    }
                    .titleBlock6 {
                        line-height: 2.2rem;
                        font-size: 1.6rem;
                        color: #37a5dd;
                    }
                    .chartPie {
                        margin: 0 auto;
                        width: 100%;
                        height: 14rem;
                    }
                }
                .block7 {
                    position: relative;
                    height: 31.9rem;
                    .cardBlock7 {
                        display: flex;
                        flex-direction: column;
                        margin-top: 1rem;
                        .cardInsideBlock7 {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 1.2rem;
                            font-size: 1.4rem;
                            color: #e6f7ff;
                            .ball {
                                margin-right: 0.6rem;
                                width: 1.6rem;
                                text-align: center;
                                line-height: 1.6rem;
                                font-size: 1.2rem;
                                background: linear-gradient(
                                    180deg,
                                    #1890ff,
                                    rgba(24, 144, 255, 0.35)
                                );
                                border-radius: 50%;
                            }
                            .word1 {
                                flex: 1;
                            }
                        }
                    }
                    .btnListBlock7 {
                        position: absolute;
                        top: 2rem;
                        right: 2.4rem;
                        border: 0.1rem solid #004579;
                        border-radius: 0.2rem;
                        .btn1,
                        .btn2 {
                            width: 6rem;
                            line-height: 3rem;
                            text-align: center;
                            font-size: 1.4rem;
                            color: #fff;
                            background-color: transparent;
                            border: none;
                            box-sizing: border-box;
                            cursor: pointer;
                        }
                        .btn2 {
                            background-color: #027969;
                        }
                    }
                }
            }
        }
    }
}
// .chartAllOutsider{
//   height: 90rem;
//   width: 143.6rem;
//   overflow: hidden;
// }

// .ChargeAll{
//   height: 77rem;
//   width: 144.6rem;
//   overflow: scroll;
//   position: relative;
//   top: 0;
//   left: 0;
//   background-color: #18191a;
// }
//  .title{
//     height: 2.2rem;
//     position: absolute;
//     top: 2.4rem;
//     left: 2.4rem;
//     color: #37A5DD;
//     font-size: 1.6rem;
//     line-height: 2.2rem;
//   }

// // 板块部分（一到三）##########################################################
// .block1{
//   width: 34.7rem;
//   height: 29.6rem;
//   background-color: #0c2f4f;
//   border-radius: 0.4rem;
//   position: absolute;
//   top: 0;
//   left: 0;
// }
// .block2{
//   width: 34.7rem;
//   height: 29.6rem;
//   background-color: #0c2f4f;
//   border-radius: 0.4rem;
//   position: absolute;
//   top: 0;
//   left: 36.3rem;
// }
// .block3{
//   width: 34.7rem;
//   height: 29.6rem;
//   background-color: #0c2f4f;
//   border-radius: 0.4rem;
//   position: absolute;
//   top: 0;
//   left: 72.6rem;
// }
// // 板块一到板块三细节########################################################
// .wordBlockOneToThree{
//   height: 2.2rem;
//   font-size: 1.6rem;
//   color: #37A5DD;
//   position: absolute;
//   left: 2.4rem;
//   top: 2.4rem;
//   line-height: 2.2rem;
// }
// .cardOneOfOneToThree{
//   width: 29.9rem;
//   height: 4.6rem;
//   background: linear-gradient(180deg, rgba(28, 92, 135, 0.5) , rgba(0, 40, 77, 0.5));
//   position: absolute;
//   top: 6rem;
//   left: 2.4rem;
//   border-radius: 0.4rem;
// }
// .cardTwoOfOneToThree{
//   width: 29.9rem;
//   height: 4.6rem;
//   background: linear-gradient(180deg, rgba(28, 92, 135, 0.5) , rgba(0, 40, 77, 0.5));
//   position: absolute;
//   top: 11.4rem;
//   left: 2.4rem;
//   border-radius: 0.4rem;
// }
// .cardThreeOfOneToThree{
//   width: 29.9rem;
//   height: 10.8rem;
//   background: #0a365c;
//   position: absolute;
//   top: 16.8rem;
//   left: 2.4rem;
//   border-radius: 0.4rem;
//   padding: 1.6rem;
//   padding-bottom: 0;
//   box-sizing: border-box;
//   .cardInside{
//     height: 2rem;
//     width: 26.7rem;
//     margin-bottom: 0.8rem;
//     .card1{
//       float: left;
//       height: 2rem;
//       color: #E6F7FF;
//       line-height: 2rem;
//       font-size: 1.4rem;
//     }
//     .card2{
//       float: right;
//       height: 2rem;
//       color: #E6F7FF;
//       line-height: 2rem;
//       font-size: 1.4rem;
//     }
//   }
// }
// // 预充值和用户消费
// .imgOneOfOneToThree{
//   height: 2.1rem;
//   width: 2.1rem;
//   position: absolute;
//   top: 1.25rem;
//   left: 1.751rem;
// }
// .wordTwoOfOneToThree{
//   height: 2rem;
//   position: absolute;
//   top: 1.3rem;
//   left: 5rem;
//   color: #42afe1;
//   background: linear-gradient(to bottom, #68D1F0, #37A5DD);
//   background-clip: text;
//   color: transparent;

//   font-size: 1.4rem;
//   line-height: 2rem;
//   font-weight: 900;
// }
// .wordThreeOfOneToThree{
//   height: 2rem;
//   position: absolute;
//   top: 1.3rem;
//   left: 5rem;
//   color: #24bfa2;
//   font-size: 1.4rem;
//   line-height: 2rem;
//   font-weight: 900;

//   background: linear-gradient(to bottom, #38D9C4, #1AB292);
//   background-clip: text;
//   color: transparent;
// }
// .wordFourOfOneToThree{
//   height: 1.7rem;
//   position: absolute;
//   top: 1.6rem;
//   right: 1.6rem;
//   font-size: 1.2rem;
//   color: rgba(255, 255, 255, 0.6);
//   line-height: 1.7rem;
// }
// .wordFiveOfOneToThree{
//   height: 2.2rem;
//   position: absolute;
//   right: 3.2rem;
//   top: 1.3rem;
//   color: #FBFBFB;
//   font-weight: 900;
//   line-height: 2.2rem;
//   font-size: 1.6rem;
// }
// .wordSixOfOneToThree{
//   height: 2rem;
//   font-size: 1.4rem;
//   line-height: 2rem;
//   color: #E6F7FF;
//   width: 26.7rem;
// }

// // 板块四#################################################################
// .block4{
//   width: 107.3rem;
//   height: 57.6rem;
//   background-color: #0c2f4f;
//   border-radius: 0.4rem;
//   position: absolute;
//   top: 31.2rem;
//   left: 0rem;
//     .chartLine{
//       width: 115rem;
//       height: 52rem;
//       position: absolute;
//       left: -4rem;
//       top: 4rem;
//     }
//     .tabChangerOne{
//       height: 3rem;
//       width: 12rem;
//       background-color: #0b365d;
//       border: 0.1rem solid #004579;
//       box-sizing: border-box;
//       position: absolute;
//       top: 2rem;
//       right: 45.8rem;
//       border-radius: 0.2rem;
//       display: flex;
//       flex-wrap: nowrap;
//       // overflow: hidden;
//       cursor: pointer;
//       font-size: 1.4rem;
//       line-height: 3rem;
//       color: #fff;
//       .partInside{
//         height: 2.8rem;
//         width: 6rem;
//         background-color: #027969;
//         text-align: center;
//       }
//       .partInside2{
//         height: 2.8rem;
//         width: 6rem;
//         text-align: center;
//       }
//     }
//     .tabChangerTwo{
//       height: 3rem;
//       width: 13.8rem;
//       background-color: #0b365d;
//       border: 0.1rem solid #004579;
//       box-sizing: border-box;
//       position: absolute;
//       top: 2rem;
//       right: 29.8rem;
//       border-radius: 0.2rem;
//       display: flex;
//       flex-wrap: nowrap;
//       cursor: pointer;
//       font-size: 1.4rem;
//       line-height: 3rem;
//       color: #fff;
//       .partInside{
//         height: 2.8rem;
//         width: 4.6rem;
//         background-color: #027969;
//         text-align: center;
//       }
//       .partInside2{
//         height: 2.8rem;
//         width: 4.6rem;
//         text-align: center;
//       }
//     }
// }

// // 板块五##########################################################################
// .block5{
//   width: 34.7rem;
//   height: 24.2rem;
//   background-color: #0c2f4f;
//   border-radius: 0.4rem;
//   position: absolute;
//   top: 0;
//   left: 108.9rem;
//   .img1{
//     height: 5.7rem;
//     width: 5.7rem;
//     position: absolute;
//     top: 6.2rem;
//     left: 2.7rem;

//   }
//   .img2{
//     height: 5.7rem;
//     width: 5.7rem;
//     position: absolute;
//     top: 6.2rem;
//     left: 18.2rem;
//   }
//   .word1{
//     height: 2rem;
//     color: #37A5DD;
//     position: absolute;
//     top: 6.2rem;
//     font-weight: 900;
//     right: 20.6rem;
//     font-size: 1.4rem;
//     background: linear-gradient(to bottom, #68D1F0, #37A5DD);
//     background-clip: text;
//     color: transparent;

//   }
//   .word2{
//     height: 2rem;
//     color: #37A5DD;
//     position: absolute;
//     top: 6.2rem;
//     font-weight: 900;
//     right: 3.7rem;
//     font-size: 1.4rem;
//     background: linear-gradient(to bottom, #68D1F0, #37A5DD);
//     background-clip: text;
//     color: transparent;
//   }
//   .word3{
//     height: 1.7rem;
//     width: 1.2rem;
//     color: rgba(255, 255, 255, 0.65);
//     font-size: 1.2rem;
//     line-height: 1.7rem;
//     position: absolute;
//     top: 9.5rem;
//     right: 20.6rem;
//   }
//   .word4{
//     height: 1.7rem;
//     width: 1.2rem;
//     color: rgba(255, 255, 255, 0.65);
//     font-size: 1.2rem;
//     line-height: 1.7rem;
//     position: absolute;
//     top: 9.5rem;
//     right: 3.7rem;
//   }
//   .word5{
//     height: 3.1rem;
//     font-size: 2.2rem;
//     color: #FBFBFB;
//     line-height: 3.1rem;
//     position: absolute;
//     top: 8.5rem;
//     right: 22.2rem;
//   }
//   .word6{
//     height: 3.1rem;
//     font-size: 2.2rem;
//     color: #FBFBFB;
//     line-height: 3.1rem;
//     position: absolute;
//     top: 8.5rem;
//     right: 5.6rem;
//   }
//   .word7{
//     height: 2rem;
//     font-size: 1.4rem;
//     color: #fff;
//     line-height: 2rem;
//     position: absolute;
//     top: 13.4rem;
//     left: 2.4rem;
//   }
//   .word8{
//     height: 2rem;
//     font-size: 1.4rem;
//     color: #E6F7FF;
//     line-height: 2rem;
//     position: absolute;
//     top: 13.4rem;
//     right: 2.4rem;
//   }
//   .word9{
//     height: 2rem;
//     font-size: 1.4rem;
//     color: #fff;
//     line-height: 2rem;
//     position: absolute;
//     top: 18.2rem;
//     left: 2.4rem;
//   }
//   .word10{
//     height: 2rem;
//     font-size: 1.4rem;
//     color: #E6F7FF;
//     line-height: 2rem;
//     position: absolute;
//     top: 18.2rem;
//     right: 2.4rem;
//   }
//   .bar1{
//     // border-radius: 0.5rem;
//     width: 29.9rem;
//     height: 0.8rem;
//     background-color: rgba(255, 255, 255, 0.1);
//     position: absolute;
//     top: 16.2rem;
//     left: 2.4rem;
//   }
//   .bar2{
//     // border-radius: 0.5rem;
//     width: 29.9rem;
//     height: 0.8rem;
//     background-color: rgba(255, 255, 255, 0.1);
//     position: absolute;
//     top: 21rem;
//     left: 2.4rem;
//   }
//   .bar1Inside{
//     height: 0.8rem;
//     background: linear-gradient(270deg, #1890FF, #1EE7E7);
//     position: absolute;
//     top: 16.2rem;
//     left: 2.4rem;
//     transition:width 0.5s;
//   }
//   .bar2Inside{
//     height: 0.8rem;
//     background: linear-gradient(270deg, #1890FF, #1EE7E7);
//     position: absolute;
//     top: 21rem;
//     left: 2.4rem;
//     transition:width 0.5s ;
//   }
// }

// // 板块六##############################################################################
// .block6{
//   width: 34.7rem;
//   height: 30.2rem;
//   background-color: #0c2f4f;
//   border-radius: 0.4rem;
//   position: absolute;
//   top: 25.8rem;
//   left: 108.9rem;
//   .bar3{
//     width: 29.9rem;
//     position: absolute;
//     left: 2.4rem;
//     top: 7.1rem;
//     height: 1.4rem;
//     // background-color: #fff;
//   }
//   .bar3Insider1{
//     position: absolute;
//     left: 2.4rem;
//     top: 7.2rem;
//     height: 1.2rem;
//     background: linear-gradient(270deg, #1EE7E7, rgba(30, 231, 231, 0.35));
//     transition: width 0.5s;
//   }
//   .bar3Insider2{
//     position: absolute;
//     width: 0.2rem;
//     top: 7.1rem;
//     height: 1.4rem;
//     background-color: #fff;
//     transition: left 0.5s;
//   }
//   .bar3Insider3{
//     position: absolute;
//     right: 2.4rem;
//     top: 7.2rem;
//     height: 1.2rem;
//     background: linear-gradient(270deg, rgba(255, 182, 40, 0.35), #FFAC5F);
//     transition: width 0.5s;
//   }
//   .underBar3{
//     height: 2rem;
//     width: 29.9rem;
//     position: absolute;
//     top: 9.1rem;
//     left: 2.4rem;
//     .left{
//       height: 2rem;
//       width: 14.95rem;
//       position: absolute;
//       left: 0;
//       top: 0;
//       .pointBlue{
//         height: 0.8rem;
//         width: 0.8rem;
//         margin: 0.6rem 0 0.6rem 0;
//         background-color: #1EE1E2;
//         border-radius: 50%;
//         position: absolute;
//         left: 0;
//         top: 0;
//       }
//       .wordRightPoint1{
//       height: 2rem;
//       line-height: 2rem;
//       font-size: 1.4rem;
//       color: #fff;
//       position: absolute;
//       left: 1.2rem;
//       top: 0;
//     }
//     .wordData1{
//       height: 2rem;
//       line-height:2rem;
//       font-size: 1.4rem;
//       color: #fff;
//       position: absolute;
//       left: 6.5rem;
//       top: 0rem;
//       font-weight: 900;
//     }

//     }
//     .right{
//       height: 2rem;
//       width: 14.95rem;
//       position: absolute;
//       right: 0;
//       top: 0;
//       .pointYellow{
//         height: 0.8rem;
//         width: 0.8rem;
//         margin: 0.6rem 0 0.6rem 0;
//         background-color: #F6A85C;
//         border-radius: 50%;
//         position: absolute;
//         right: 8.3rem;
//         top: 0;

//       }
//      .wordRightPoint2{
//       height: 2rem;
//       line-height: 2rem;
//       font-size: 1.4rem;
//       color: #fff;
//       position: absolute;
//       right: 5.0rem;
//       top: 0;
//     }
//     .wordData2{
//       height: 2rem;
//       line-height:2rem;
//       font-size: 1.4rem;
//       color: #fff;
//       position: absolute;
//       right: 0rem;
//       top: 0rem;
//       font-weight: 900;
//     }

//     }

//   }
//   .titleBlock6{
//     height: 2.2rem;
//     position: absolute;
//     top: 13.5rem;
//     left: 2.4rem;
//     color: #37A5DD;
//     font-size: 1.6rem;
//     line-height: 2.2rem;
//   }
//   .chartPie{
//     height: 14rem;
//     width: 32.4rem;
//     // height: 140px;
//     // width: 324px;
//     position: absolute;
//     top: 15.5rem;
//     left: 0;
//   }

// }
// .block7{
//   width: 34.7rem;
//   height: 31.2rem;
//   background-color: #0c2f4f;
//   border-radius: 0.4rem;
//   position: absolute;
//   top: 57.6rem;
//   left: 108.9rem;
//   .cardBlock7{
//     height: 22.4rem;
//     width: 29.9rem;
//     position: absolute;
//     top: 7rem;
//     left: 2.4rem;
//     .cardInsideBlock7{
//       height: 2rem;
//       width: 29.9rem;
//       margin-bottom: 1.2rem;
//       .ball{
//         height: 1.6rem;
//         width: 1.6rem;
//         background: linear-gradient(180deg, #1890FF, rgba(24, 144, 255, 0.35));
//         border-radius: 50%;
//         margin: 0.2rem 0.6rem 0.2rem 0;
//         float: left;
//         text-align: center;
//         line-height: 1.6rem;
//         color: #E6F7FF;
//         font-size: 1.2rem;
//       }
//       .word1{
//         height: 2rem;
//         float: left;
//         color: #E6F7FF;
//         font-size: 1.4rem;
//         line-height: 2rem;
//       }

//       .word2{
//         height: 2rem;
//         float: right;
//         color: #E6F7FF;
//         font-size: 1.4rem;
//         line-height: 2rem;
//       }
//     }
//   }
//   // 模块七********************************************************************
//   .btnListBlock7{
//       width: 12rem;
//       height: 3rem;
//       border: 0.1rem solid #004579;
//       border-radius: 0.2rem;
//       overflow: hidden;
//       position: absolute;
//       left: 20.4rem;
//       top: 2rem;
//       button {
//         height: 3rem;
//         width: 6rem;
//         cursor: pointer;
//         border: none;
//         color: #fff;
//         font-size: 1.4rem;
//       }
//       .btn1 {
//         background-color: #0c375e;
//       }
//       .btn2 {
//         background-color: #027969;
//       }
//     }

//   }

// .blank {
//   position: absolute;
//   top: 90rem;
//   height: 25rem;
//   width: 142.8rem;
//   color: grey;
//   font-size: 1.4rem;
//   text-align: center;
//   line-height: 20rem;
// }

// // 对时间选择器的样式更改#############################################################################
// .tabChangerThree{
//   position: absolute;
//   right: 2.4rem;
//   top: 1.9rem;
//   height: 3.2rem;
//   width: 25rem;
// }
</style>



<style lang="less">
// .chartAllOutsider{

// .el-date-editor{
//  height: 3.2rem;
//  width: 25rem;
// cursor: pointer;
// }
// .el-date-editor .el-range__icon {
//     font-size: 1.4rem;
//     // margin-left: -0.5rem;
//     // margin-top: -0.7rem;
//     color: #C0C4CC;
//     float: left;
//     line-height: 2.4rem;
// }

// .el-date-editor .el-range-input, .el-date-editor .el-range-separator{
//   font-size: 1.4rem;
//   color: rgba(255, 255, 255, 0.85);
// }

// .el-input__icon{
//   width: 2.4rem;
// }
// .el-date-range-picker .el-picker-panel__content {
//     margin: 0;
//     background-color: #0c375e;
//     border-color: #004579;
// }
// .el-picker-panel {
//     color: rgba(255,255,255,0.85);
//     border: 0.1rem solid #004579;
//     box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
//     background: #0c375e;
//     border-radius: 0.4rem;
//     line-height: 3rem;
//     margin: 0.5rem 0;
// }
// .el-date-table td.today span {
//     color: #027969;
//     font-weight: 700;
// }

// .el-picker-panel [slot=sidebar], .el-picker-panel__sidebar {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     width: 110px;
//     border-right: 1px solid #e4e4e4;
//     box-sizing: border-box;
//     padding-top: 6px;
//     background-color: #0d3050;
//     overflow: auto;
// }

// }
</style>