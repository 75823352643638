<template>
  <div class="DialogForEdit">
    <el-dialog 
    @close="closeDialog('ruleForm')"
    :visible.sync="dialogFormVisible" width="20%" :close-on-click-modal="false">
      <div slot="title">
        <div class="equip_title">编辑巡检任务</div>
      </div>
      <div style="max-height:55rem;overflow-y:auto;">
        <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
        
          <el-form-item label="执行类型" prop="executorType" style="width: 98%">
            <el-select v-model="form.executorType" style="width: 98%"  size="small" @change="executorTypeChange">
              <el-option label="巡检小组" value='0'></el-option>
              <el-option label="巡检人" value='1'></el-option>         
            </el-select>
          </el-form-item>

          <el-form-item v-show="form.executorType==='0'" label="巡检小组" style="width: 98%" prop="executorId">
            <el-select v-model="form.executorId" style="width: 98%"   size="small">
              <el-option v-for="(item,index) in patrolGroupList" :key="index" :label="item.label" :value="item.value"></el-option>  
            </el-select>
          </el-form-item>


          <el-form-item v-show="form.executorType==='1'" label="巡检人" style="width: 98%" prop="executorId">
            <el-select v-model="form.executorId"  style="width: 98%" size="small">
              <el-option v-for="(item,index) in patrolPersonList" :key="index" :label="item.label" :value="item.value"></el-option> 
            </el-select>
          </el-form-item>
          
          <el-form-item label="备注" style="width: 100%" prop="remark">
            <el-input type="textarea" v-model="form.remark"   style="width: 98%;min-height: 10rem;"></el-input>
          </el-form-item>


        </el-form>
      </div>

      <!-- <div >
        <el-form>
         <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="form.remark"   style="width: 98%;min-height: 10rem;"></el-input>
          </el-form-item>
        </el-form>
      </div> -->

      
      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
      </div>
    </el-dialog>


  <!-- 树的位置############### -->
    <div :class="ifTreeShow" >
      <DialogForTree  ref="treeRef"/>
    </div>
  <!-- 位置表 -->
    <DialogForLocation ref="locationRef"/>
    
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import DialogForTree from './DialogForTree.vue'
import DialogForLocation from './DialogForLocation.vue'
import { validateInput, validateSelect ,validateDate} from "@/utils/validate"
export default {
    name:"DialogForEdit",
    mixins: [tableBg],
    components:{DialogForTree,DialogForLocation},
     data() {
      return {
        // 按钮上的文字显示
        btnName:"请选择巡检位置",
        // 弹层中的树是否展示
        ifTreeShow:'treePlaceNotshow',
        // 巡检小组的list
        patrolGroupList:[],
        // 巡检人的list
        patrolPersonList:[],
        // 作业标准的list
        patrolStandList:[],   
        // 展示判断
        dialogFormVisible: false,
        // 表单数据存放处
        form:{executorType:"",executorId:"",id:"",remark:""},
        rules: {
           executorType:[validateSelect],
           executorId:[validateInput],       
        },
      };
    },
    methods: {
       

        // 关闭dialog的回调，el自带的
        closeDialog(ruleForm){
         this.formInit()
         this.ifTreeShow = "treePlaceNotshow"
         this.$refs[ruleForm].resetFields()
        }, 
         // from的归零
        formInit(){
          this.form ={executorType:"",executorId:"",id:"",remark:""}
        this.btnName="请选择巡检位置"
        },

        //父组件点击触发，显示弹窗、给当前节点数据，修改form表单中的客户单位ID
        show(val){   
            this.dialogFormVisible = true 
            this.form = {
              executorType:"",
              executorId:"",
              remark:""
            }
          // 巡检小组的下拉信息处理
          this.$store.dispatch('getRepairPatrolPlanGroupAll').then(()=>{
            this.patrolGroupList = []
            for(let i=0;i<this.patrolPlanGroupAll.length;i++){
              const patrolGroupSingel = {value:'',label:''}
              patrolGroupSingel.value = this.patrolPlanGroupAll[i].id
              patrolGroupSingel.label = this.patrolPlanGroupAll[i].name
              this.patrolGroupList.push(patrolGroupSingel)
            }})
           // 巡检人的下拉信息处理
          this.$store.dispatch('getRepairPatrolPlanPersonAll').then(()=>{
            this.patrolPersonList = []
            for(let i=0;i<this.patrolPlanPersonAll.length;i++){
              const patrolPersonSingel = {value:'',label:''}
              patrolPersonSingel.value = this.patrolPlanPersonAll[i].id
              patrolPersonSingel.label = this.patrolPlanPersonAll[i].name
              this.patrolPersonList.push(patrolPersonSingel)
           

              // 真实数据导入
              this.form.executorId = val.executorId
              this.form.executorName = val.executorName
              this.form.executorType = val.executorType
              this.form.id = val.id
              this.form.remark = val.remark
           
           
           }})
          
       

       
       },

  

        handleCancle(ruleName) {
          this.$refs[ruleName].resetFields()
          this.dialogFormVisible = false
          this.formInit()
        },
      
        // 提交发送请求（点击《确定》）
        postDialogData(ruleName){
          this.$refs[ruleName].validate(valid => {
            if (valid) {
              // 发送登记请求                   
              this.$store.dispatch('putPatrolTaskChange',this.form).then(()=>{
              if(this.ifPatrolTaskChangeSuccess===0){
                this.$message({message: '修改成功！',type: 'success'});
                this.$refs[ruleName].resetFields()
                this.dialogFormVisible = false;
                this.$parent.refreshThePage()
                this.formInit()
              }else if(this.ifPatrolTaskChangeSuccess===-1){
                this.$message({message:this.ifPatrolTaskChangeError,type: 'warning'});
              }
              })                      
              }else {
              this.$message.error('有必填项未填')
            }
          })
         
       },

      // 树的展示
      DialogTreeShow(){
        this.ifTreeShow = "treePlace"

        this.$refs.treeRef.refreshTheTree()
      },

      // 树的关闭
      dialogTreeNotShow(){
        this.ifTreeShow = "treePlaceNotshow"
      },

      // 位置弹层的展示
      dialogLocationShow(val){
        this.$refs.locationRef.show(val)
      },

      // 位置弹层选择，进行回显
      getLocation(val){
        // 表现渲染
        this.btnName = val.name
        // 真实数据渲染
        this.form.itemList[0].locationType=val.locationType
        this.form.itemList[0].locationId=val.locationId
       
      },
    
      // 执行类型切换
      executorTypeChange() {
        this.form.executorId = ''
      }
        
    },

    //处理state数据
    computed:{
    ...mapState({
      patrolPlanGroupAll:(state)=>{return state.repair.patrolPlanGroupAll.data},
      patrolPlanPersonAll:(state)=>{return state.repair.patrolPlanPersonAll.data},
      // patrolPlanStandAll:(state)=>{return state.repair.patrolPlanStandAll.data},
      ifPatrolTaskChangeSuccess:(state)=>{return state.repair.ifPatrolTaskChangeSuccess.errorCode},
      ifPatrolTaskChangeError:(state)=>{return state.repair.ifPatrolTaskChangeSuccess.message},
      // patrolTaskDetail:(state)=>{return state.repair.patrolTaskDetail.data},

    })
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}

// 按钮样式
 .btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;  
  color: #abb7c2;
}


// 树所在的区域###################
.treePlace{
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 25rem;
  left: 30rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  // border: #071828 0.2rem solid;
}
.treePlaceNotshow{
  display: none;
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 25rem;
  left: 30rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  // border: #071828 0.2rem solid;
}

// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2D79BE;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
</style>

<style lang="less">
// 套壳！谨防代码污染！
.DialogForEdit{
    .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }
// abb7c2
.el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem; 
}
.el-checkbox{
 color: #abb7c2;
}
.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #027969;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
}

.el-textarea__inner {
  color: #c0c4cc;
  background-color: #0b3d6b;
  border: 0.1rem solid #004579;
}
.el-textarea__inner:hover {
    border-color: #0b3d6b;
}

.el-textarea__inner {
    color: #fff;
    background-color: #0b3d6b;
    border: 1px solid #0b3d6b;

}

.no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }



}


</style>