<template>
  <div class="ComparisonAnalyze">
    <div class="outsideLevel2">
      <!-- 小树位置#################### -->
      <div class="treePlace">
        <TreeComparisonAnalyze />
      </div>

      <!-- 数据图区域######################################### -->
      <div class="mapPlaceOutside">
        <!-- :style="{'height':fullHeight/10+'rem'} " ref="mapPlaceOutside" -->
        <div class="mapPlace">
          <div class="mapPlaceTop">
            <div class="echartSingel">
              <MymapNo1 ref="MymapNo1" :name="customerName" />
            </div>
            <div class="echartSingel">
              <MymapNo2 ref="MymapNo2" :name="customerName" />
            </div>
          </div>
          <div class="mapPlaceBottom">
            <div class="echartSingel">
              <MymapNo3 ref="MymapNo3" :name="customerName" />
            </div>
            <div class="echartSingel">
              <MymapNo4 ref="MymapNo4" :name="customerName" />
            </div>
          </div>
          <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeComparisonAnalyze from "./ComparisonAnalyze/TreeComparisonAnalyze.vue";
import MymapNo1 from "./ComparisonAnalyze/MymapNo1.vue";
import MymapNo2 from "./ComparisonAnalyze/MymapNo2.vue";
import MymapNo3 from "./ComparisonAnalyze/MymapNo3.vue";
import MymapNo4 from "./ComparisonAnalyze/MymapNo4.vue";

export default {
  components: { TreeComparisonAnalyze, MymapNo1, MymapNo2, MymapNo3, MymapNo4 },
  name: "ComparisonAnalyze",
  props: {
    isTreeShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customerName: "",
      // 滚动状态
      // overflowStyle:'scroll',
      // 剩余高度
      // fullHeight:document.documentElement.clientHeight
    };
  },
  watch: {
    isTreeShow(val) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.MymapNo1.resizeChart();
          this.$refs.MymapNo2.resizeChart();
          this.$refs.MymapNo3.resizeChart();
          this.$refs.MymapNo4.resizeChart();
        });
      }, 0);
    },
    "$store.state.storage.customerData": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        console.log("ComparisonAnalyze", newVal);
        if (!newVal) {
          return;
        }
        this.customerName = newVal.name;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.ComparisonAnalyze{
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  width: calc(100% + 0.6rem);
  height: calc(100vh - 18.2rem);
}
.outsideLevel2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.treePlace {
    width: 22rem;
    height: 88rem;
    overflow: hidden;
    // position: absolute;
    // left: 0;
    // top: 0;
    background-color: #0d3050;
}

// 图表样式

.box_ul {
    color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-wrap: wrap;
    .box_li {
        width: 25%;
        display: flex;
        margin-bottom: 2.4rem;
        font-size: 1.4rem;
        div {
            width: 6rem;
            text-align: right;
        }
        p {
            flex: 1;
        }
    }
}

// 整个图表区域################################################################
.mapPlaceOutside {
    flex: 1;
    margin-left: 1.5rem;
    // height: 86.4rem;
    // position: absolute;
    // top: 0;
    // left: 24.4rem;
    // width: 118.4rem;
    overflow: hidden;
    height: 88rem;

    .mapPlace {
        // position: relative;
        // top: 0;
        // left: 0;
        width: 100%;
        // height: 77.8rem;
        // width: 119rem;
        overflow: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;

        .mapPlaceTop,
        .mapPlaceBottom {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        .echartSingel {
            flex: 1 1 50%;
            margin-right: 1.5rem;
            margin-bottom: 1.5rem;
            overflow: hidden;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        .blank {
            height: 20rem;
            width: 119rem;
            color: grey;
            font-size: 1.4rem;
            text-align: center;
            line-height: 10rem;
        }
    }
}
</style>
<style lang="less">
.ComparisonAnalyze {
    ::-webkit-scrollbar-thumb,
    .el-select-dropdown::-webkit-scrollbar-thumb {
        background: #0c2f4f;
    }
}
</style>
