<template>
  <div class="DrawerForDetail">
      <el-drawer
        :visible.sync="drawer"
        :with-header="false"
        direction=ltr
        size=66%    
        >

        <!-- 左侧区域######################################### -->
        <div class="leftPart">
            <div class="title">
                巡检计划名称 : {{formData.name}}
            </div>
            <!-- 板块一############### -->
            <div class="cardOne">
                <div class="wordTypeOne">巡检信息 :</div>
                <div class="wordTypeTwo">巡检周期 : <span class="wordTypeFour">{{formData.intervalTypeLabel}}</span></div>
                <div class="wordTypeTwo">计划巡检日期 : <span class="wordTypeFour">{{formData.startDate}} - {{formData.endDate}}</span></div>
                <div class="wordTypeTwo">巡检人/小组 : <span class="wordTypeFour">{{formData.executorName}}</span></div>
                <div class="wordTypeTwo">状态 : <span class="wordTypeFour">{{formData.statusForShow}}</span></div>
                <div class="wordTypeTwo">巡检位置 : <span class="wordTypeFour">{{formData.address}}</span></div>

                <div class="box">
                    <div class="wordTypeFive">备注 : </div>
                    <span class="wordTypeSix">{{formData.remark}}</span>
                </div>

                <div class="wordTypeOne">巡检任务列表 :
                    <div class="inputBlock">
                        <el-input
                        size="small"
                        placeholder="请输入巡检名称"
                        v-model="searchValue"
                        @keyup.enter.native="search"
                        style="width:22rem;"
                        clearable
                        @clear="search"
                        ></el-input>
                        <button class="btn_save" @click="search">查 询</button>
                    </div> 
                 </div>
                <!-- 列表位置 -->
                <div class="blockOne">
                <!-- 中部表格位置############################################################################################################ -->
                    <div class="userTablePlace">
                        <!-- tab栏 -->                   
                        <div class="table">
                            <el-table :data="tableData" stripe:false height="44.5rem" 
                                    border:false
                                    :header-cell-style="headerClass"                    
                                    :cell-style="cellStyle">
                            <!-- <el-table-column type="index" label="序号" min-width="19%"></el-table-column> -->
                            <el-table-column label="序号" min-width="9%">
                              <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
                            </el-table-column>
                            <el-table-column class="tab" prop="name" label="任务名称" min-width="19%"  :show-overflow-tooltip="true"> </el-table-column>
                            <el-table-column class="tab" prop="executorName" label="巡检人" min-width="12%"  :show-overflow-tooltip="true"> </el-table-column>
                            <el-table-column class="tab" prop="planTime" label="计划巡检时间" min-width="15%"  :show-overflow-tooltip="true"> </el-table-column>
                           <el-table-column class="tab" prop="planEndTime" label="计划结束巡检时间" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                            <el-table-column class="tab" prop="statusName" label="当前状态" min-width="15%"  :show-overflow-tooltip="true">
                              
                                <template slot-scope="scope">
                                <div :class="colorTagList[scope.$index]">{{tableData[scope.$index].statusName}}</div>
                                </template>
                            </el-table-column>
                            <!-- 操作部分 -->
                            <el-table-column label="操作" min-width="14%">
                                <template slot-scope="scope">
                                    <el-button v-if="tableData[scope.$index].statusName==='已完成'"  size="mini" type="text" @click="handleCheckout(scope.$index, scope.row)">详情</el-button>
                                    <el-button v-else size="mini" type="text" :disabled="formData.statusForShow == '已作废'" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                            </el-table>
                        </div>
                        <!-- 分页器部分 -->
                        <div class="pagination">
                            <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[5, 10, 20, 40]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalNumForPagenation"
                            >
                            </el-pagination>
                        </div>
                    </div>

                </div>
            </div>
           
        </div>
        <!-- 中间区域 -->
        <div class="middlePart"></div>
        <!-- 右侧区域############################################## -->
        <div class="rightPart">
            <div class="inner">
                <el-timeline>
                    <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :icon="activity.icon"
                    :type="activity.type"
                    :color="activity.color"
                    :size="activity.size"
                    :timestamp="activity.timestamp">
                    {{activity.content}}
                    </el-timeline-item>
                </el-timeline>
            </div>



        </div>
        
      </el-drawer>
      <!-- 弹层位置 -->
      <DialogPatrolPlan ref="DialogRef"/>
      <DialogForEdit ref="EditRef" />
      <DrawerForDetail2 ref="DetailRef" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import DialogPatrolPlan from "./DialogPatrolPlan.vue"
import DialogForEdit from './DialogForEdit.vue'
import DrawerForDetail2 from '../PatrolTask/components/DrawerForDetail.vue'
export default {
    mixins: [tableBg],
    name:"DrawerForDetail",
    components:{DialogPatrolPlan,DialogForEdit,DrawerForDetail2},
    data(){return {
        
        // 控制抽屉是否展开
        drawer: false,
        // 表格数据
        formData:[],
        // 流程图数据
        activities: [],

      //巡检类型的dict
      patrolTypeDict:{0:"不避开周末和节假日",1:"避开周末",2:"避开节假日",3:"避开周末和节假日"},
      // 间隔时间的dict
      intervalTypeDict:{1:"每天",2:"每周",3:"每月"},
      
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 5,
      // 双向绑定--当前页
      currentPage: 1,
      // 双向绑定的搜索数据
      searchValue: "",  
      // 表格数据
      tableData: [],
      // 标签颜色列表
      colorTagList:[],
      //  巡检计划的id
      planId:"",

      }},

    methods:{
        //控制是否展示抽屉
       show(val){
           this.drawer = true
           this.formData = val         
           if(this.formData.status===0){this.formData["statusForShow"]="已作废"}else{this.formData["statusForShow"]="进行中"}
           //渲染流程图  
           this.activities=[]
           for(let i=0;i<val.planLogs.length;i++){
               const singel = {content:"",timestamp:"",color:"#0078E8"}
               singel.content = val.planLogs[i].stageDetails
               singel.timestamp = val.planLogs[i].createTime
               this.activities.push(singel)
           }
           this.activities[0].color="#1EE7E7"
           this.planId = val.id
           this.refreshThePage()
           
       },

          // 刷新页面
    refreshThePage() {    
      this.$store
        .dispatch("getRepairPatrolTaskListFromPatrolPlan", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          planId: this.planId,
          keyword:this.searchValue
        })
        .then(() => {   
          this.tableData = this.repairPatrolTaskListFromPatrolPlan;
          this.totalNumForPagenation = this.repairPatrolTaskListFromPatrolPlanTotal;
          // 判断数据的状态，调整对应标签的颜色
          for(let i=0;i<this.tableData.length;i++){
            if(this.tableData[i].statusName==="待巡检"){this.colorTagList[i]='color0'}
            else if(this.tableData[i].statusName==="已超期"){this.colorTagList[i]='color1'}
            else if(this.tableData[i].statusName==="巡检中"){this.colorTagList[i]='color2'}
            else if(this.tableData[i].statusName==="已完成"){this.colorTagList[i]='color3'}
            else if(this.tableData[i].statusName==="已作废"){this.colorTagList[i]='color4'}
          }   
        });
    },


     // 点击搜索或者回车
    search() {
      this.currentPage = "1";
      this.refreshThePage();
    },
    // 详情页
    handleCheckout(index){
        let taskData = this.repairPatrolTaskListFromPatrolPlan[index]
        // 如果结果不为空，并且状态为已完成时，查询对应巡检结果
        if(taskData != null && taskData.status === 2) {
          this.$store.dispatch("getRepairPatrolTaskResult", taskData.id)
              .then(() => {
                let result = this.repairPatrolTaskResult;
                if (result != null) {
                  const map = new Map(result.map(item => [item.standardItemId, item]));
                  // 遍历taskData，对巡检条目明细赋值结果，使用foreach遍历
                  taskData.inspectionStandard.itemList.forEach((item) => {
                    // 遍历result，找到对应的巡检条目明细
                    item.detailList.forEach((detail) => {
                      if (map.has(detail.id)) {
                        detail.content = map.get(detail.id).content;
                        detail.remark = map.get(detail.id).remark;
                      }
                      if (detail.parentNode != null && detail.parentNode === 1) {
                        detail.detailList.forEach((detail2) => {
                          if (map.has(detail2.id)) {
                            detail2.content = map.get(detail2.id).content;
                            detail2.remark = map.get(detail2.id).remark;
                          }
                        });
                      }
                    })
                  });
                }
              });
        }
        this.$refs.DetailRef.show(taskData)
    },
    //点击新增
    ifDialogShow(){
      this.$refs.DialogRef.show();
    },
    //点击了编辑按钮
    handleEdit(index) {
      this.$refs.EditRef.show(this.repairPatrolTaskListFromPatrolPlan[index]);
    },
    // 点击了删除按钮
    handleDelete(index) {
       this.$prompt('请输入删除巡检任务的理由：', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((reason) => {
    
          const value ={id:this.repairPatrolTaskListFromPatrolPlan[index].id,remark:reason.value}
 
        this.$store.dispatch("deleteRepairPatrolTask", value).then(() => {
            if (this.ifDeleteRepairPatrolTaskSuccess === 0) {
              this.$message({ message: "删除成功！", type: "success" });
              this.refreshThePage();
            } else{
              this.$message({
                message: this.ifDeleteRepairPatrolTaskError,
                type: "warning"
              });
            }
          });
      });
    },
   
    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = "1";
      this.pageSize = val;
      this.refreshThePage();
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val;
      this.refreshThePage();
    },
    },

     computed: {
    ...mapState({
      // 分页数据
      repairPatrolTaskListFromPatrolPlan: state => { return state.repair.repairPatrolTaskListFromPatrolPlan.data.records;},
      // 页面总数
      repairPatrolTaskListFromPatrolPlanTotal: state => { return state.repair.repairPatrolTaskListFromPatrolPlan.data.total;},
      // 删除巡检任务是否成功
      ifDeleteRepairPatrolTaskSuccess:state => { return state.repair.ifDeleteRepairPatrolTaskSuccess.errorCode},
      ifDeleteRepairPatrolTaskError:state => { return state.repair.ifDeleteRepairPatrolTaskSuccess.message},
      // 巡检结果数据
      repairPatrolTaskResult: state => { return state.repair.repairPatrolTaskResult.data},
     
    })
  }


}
</script>

<style lang="less" scoped>
// 公共样式
.wordTypeOne{
    height: 4rem;
    width: 85rem;
    line-height: 5.5rem;
    font-size: 1.6rem;
    color: #37A5DD;
    font-weight: 900;
    margin-left: 3.2rem;
}
.wordTypeTwo{
    height: 3.6rem;
    width: 85rem;
    line-height: 3.6rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem;
}
.wordTypeThree{
    height: 10rem;
    width: 85rem;
    line-height: 2rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem;

}
.wordTypeFour{ 
    font-weight: 100;
    line-height: 2rem;
}
.wordTypeFive{
    height: 9rem;
    width: 7rem;
    line-height: 9rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem; 
    
}
.wordTypeSix{
    height: 9rem;
    width: 80rem;
    font-weight: 100;
    line-height: 2.2rem;   
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    overflow: hidden;
    display: table-cell;
    vertical-align:middle;
    background-color: #0e2b46;
    border-radius: 0.4rem;
    padding: 0 0.5rem 0 0.5rem;
    box-sizing: border-box;
}
.myImg{
    height: 9rem;
    width: 15rem;
    border-radius: 0.4rem;
    overflow: hidden;
    margin-right: 2rem;
}
.box{
    height: 9rem;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
}
.blockOne{
  height: 44.5rem;
  margin-top: 2rem;
  .table{
      height: 44.5rem;
      margin-left: 3.2rem;
  }
  // 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
}
.inputBlock{
    float: right;
    .btn_save{
        margin-left: 1.8rem;
        border-radius: 0.2rem;
    }
}

// 左侧区域###############################################################
.leftPart{
    width: 90.3rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
    .title{
        margin-top: 2.5rem;
        line-height: 2.5rem;
        font-size: 1.6rem;
        color: #37A5DD;
        font-weight: 900;
        margin-left: 3.2rem;
    }
    .cardOne{
        height: 42rem;
    }
    .cardTwo{
        height: 42rem;
        width: 100%;
        position: absolute;
        top: 47rem;
        left: 0;
   
    }
}
// 右侧区域
.rightPart{
    width: 37.4rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 90.4rem;
    overflow-y: auto;
    .inner{
        position: absolute;
        left: 2rem;
        top: 8rem;
        // overflow: scroll;
        height: 90rem;

    }
}


// 中间区域
.middlePart{
    width: 0.1rem;
    height: 94.8rem;
    position: absolute;
    top: 0;
    left: 90.3rem;
    background-color: rgba(22, 111, 191, 0.2);
}




// 颜色标签样式
.color0{
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(180deg, rgba(255, 172, 95, 0.1) 0%, rgba(205, 216, 118, 0.1) 100%);
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #FFAC5F;
}
.color1{
  height: 2.8rem;
  border-radius: 1.4rem;
  background:  linear-gradient(180deg, rgba(255, 95, 95, 0.1) 0%, rgba(255, 122, 0, 0.1) 100%);
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #FF5F5F;
}
.color2{
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.2) 0%, rgba(24, 89, 255, 0.2) 100%);
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #89C6FF;
}
.color3{
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(180deg, rgba(30, 231, 231, 0.15) 0%, rgba(30, 207, 231, 0.15) 100%);
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #03D9BC;
}
.color4{
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(228, 252, 255, 0.1) 100%);
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #fff;
}



</style>


<style lang="less">
.DrawerForDetail{

// 抽屉底色
.el-drawer {
    background-color: #0c375d;  
}

.equip_title {
  height: 5.4rem;
  line-height: 5.4rem;
  padding-left: 2.4rem;
  font-size: 1.6rem;
  background: linear-gradient(225deg, rgba(15, 36, 55, 0.0001) 0.03%, rgba(0, 115, 222, 0.7) 99.9%);
}

// 流程图样式


.el-timeline-item__content {
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
}

.el-timeline {
    margin: 0;
    font-size: 1.4rem;
    list-style: none;
}

.el-timeline-item__timestamp .is-bottom{
    font-size: 1.2rem;
}


.DrawerForDetail .el-timeline-item__wrapper {
    font-size: 2rem;
  
}
.el-timeline-item__wrapper {
    position: relative;
    padding-left: 2.8rem;
    top: -0.3rem;

}


.el-table, .el-table__expanded-cell {
    background-color:rgba(0,0,0,0);
    
}


.el-table__body-wrapper .is-scrolling-none{
    height: 44.5rem;
}


}



</style>