
<template>
    <div class="positionInSystem page-main">
        <!-- 左侧板块#################################################################### -->
        <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="leftBlock page-main-tree">
            <TreePosition ref="treeRef" />
        </div>
        <!-- 右侧板块######################################################################## -->
        <div :class="isTreeShow ? 'shrink-open_tree_content':'shrink-close_tree_content'" class="rightBlock page-main-content">
            <div class="shrink-btn" @click="isTreeShow = !isTreeShow">
            </div>
            <!-- 顶栏部分######################### -->
            <div class="topPlace">
                <el-input size="small" placeholder="请输入位置名称" v-model="searchValue" @keyup.enter.native="search" style="width:22rem;" clearable @clear="search"></el-input>
                <button class="btn_save" @click="search">查 询</button>
                <button :class="btnType" :disabled="btnDis" @click="ifDialogShow">新增位置</button>
            </div>

            <!-- 中间表格部分###################### -->
            <!-- <div class="middlePlace"> -->
            <div class="userTablePlace">
                <div class="bread">
                    系统管理 / 位置管理
                </div>
                <div class="table">
                    <el-table :data="tableData" stripe:false height="57.8rem" border:false style="width: 100%" :header-cell-style="headerClass" :cell-style="cellStyle">
                        <el-table-column label="序号" min-width="15%">
                            <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
                        </el-table-column>
                        <el-table-column class="tab" prop="name" label="位置名称" min-width="50%" :show-overflow-tooltip="true"> </el-table-column>
<!--                        <el-table-column prop="typeName" label="位置类型" min-width="20%" :show-overflow-tooltip="true"> </el-table-column>-->
                        <el-table-column prop="customerName" label="所属单位" min-width="50%" :show-overflow-tooltip="true"> </el-table-column>
                        <!-- 操作部分 -->
                        <el-table-column label="操作" min-width="20%">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页器部分 -->
                <div class="pagination">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalNumForPagenation">
                    </el-pagination>
                </div>
            </div>
            <!-- </div> -->

        </div>

        <!-- 弹层Dialog部分 -->
        <DialogPosition ref="DialogRef" />
    </div>
</template>

<script>
import TreePosition from '../level2Components/TreePosition.vue'
import DialogPosition from '../level2Components/DialogPosition.vue'
import { tableBg } from '@/mixin/tablebg'
import { mapState } from 'vuex'
export default {
    name: 'Position',
    mixins: [tableBg],
    components: { TreePosition, DialogPosition },
    data () {
        return {
            isTreeShow: true,
            // 按钮类型
            btnType: 'btn2Grey',
            // 新增是否可用
            btnDis: true,
            // 树的节点类型
            treeNodeType: '',
            // 树的节点Id
            treeNodeId: '',
            // 树的节点
            treeNode: '',
            // 双向绑定--总数
            totalNumForPagenation: 0,
            // 双向绑定--每页的个数
            pageSize: 10,
            // 双向绑定--当前页
            currentPage: 1,
            // 双向绑定的搜索数据
            searchValue: '',
            // 双向绑定的用户状态
            userState: '',
            // 用户状态列表
            userStateList: [
                { label: '冻结', value: '1' },
                { label: '正常', value: '2' }
            ],
            // 表格数据(假数据)
            tableData: [],
        }
    },

    methods: {
        // 发送请求刷新页面
        refreshThePage () {
            this.$store.dispatch('getSystemPositionPageData', { pageNum: this.currentPage, pageSize: this.pageSize, keyword: this.searchValue, nodeId: this.treeNodeId, nodeType: this.treeNodeType }).then(() => {
                // 做数据深拷贝
                this.tableData = []
                for (let i = 0; i < this.positionPageData.length; i++) {
                    const tableSingle = { name: "", typeName: "", customerName: "" }
                    tableSingle.name = this.positionPageData[i].name;
                    tableSingle.typeName = this.positionPageData[i].typeName;
                    tableSingle.customerName = this.positionPageData[i].customerName;
                    this.tableData.push(tableSingle)
                }
                this.totalNumForPagenation = this.positionTotal
            })
        },
        // 点击搜索或者回车
        search () {
            this.currentPage = '1'
            this.refreshThePage()
        },
        // 更改用户状态选择框的选择
        selectChanged () {
            alert('更改用户状态选择框的选择')
        },
        //点击新增
        ifDialogShow () {
            this.$refs.DialogRef.show(this.treeNodeType, this.treeNode)
        },
        //点击了编辑按钮
        handleEdit (index) {
          const datum = this.positionPageData[index]
          let parentId = datum.parentId
          let parentName = ''
          if (parentId) {
            parentName = this.getParentName(this.$refs.treeRef.treeData, parentId);
          } else {
            parentName = datum.customerName
          }
          this.$refs.DialogRef.showForEdit(datum, parentName)
        },
        // 点击了删除按钮
        handleDelete (index) {
            this.$confirm('您确定要执行删除操作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteSystemPosition', this.positionPageData[index].id).then(() => {
                    if (this.ifPositionDeleted === 0) {
                        this.$message({ message: '删除成功！', type: 'success' })
                        this.$refs.treeRef.refreshTheTree()
                        this.refreshThePage()
                    } else if (this.ifPositionDeleted === -1) {
                        this.$message({ message: '删除失败！请检查网络', type: 'warning' })
                    }
                })
            })
        },
        // 更改分页器页面
        handleCurrentChange (val) {
            this.currentPage = val
            this.refreshThePage()
        },
        // 更改分页器每页个数
        handleSizeChange (val) {
            this.currentPage = '1'
            this.pageSize = val
            this.refreshThePage()
        },
        // 更新树的节点
        changeTreeNode (val) {
            this.currentPage = 1;
            this.treeNodeId = val.id
            this.treeNode = val
            this.treeNodeType = val.type
            this.refreshThePage()
        },
        refreshTheTree () {
            this.$refs.treeRef.refreshTheTree()
        },
        getParentName(treeData, parentId) {
          for (let i = 0; i < treeData.length; i++) {
            const node = treeData[i];
            if (node.id === parentId) {
              return node.name;
            }
            if (node.children) {
              const result = this.getParentName(node.children, parentId);
              if (result) {
                return result;
              }
            }
          }
        }

    },
    mounted () {
        this.refreshThePage()
    },

    computed: {
        ...mapState({
            positionPageData: (state) => { return state.system.positionPageData.data.records },
            positionTotal: (state) => { return state.system.positionPageData.data.total },
            ifPositionDeleted: (state) => { return state.system.ifPositionDeleted.errorCode },
        })
    },

    watch: {
        // 监听控制新增按钮的颜色
        treeNodeType: function (val) {
          if (val !== 1) {
            this.btnType = 'btn2'
            this.btnDis = false
          } else {
            this.btnType = 'btn2Grey'
            this.btnDis = true
          }
        }
    },

}
</script>

<style lang="less" scoped>
// 左侧部分##########################################################
.leftBlock {
    // width: 26rem;
    // height: 96.8rem;
    // overflow: hidden;
    // position: absolute;
    // left: 0;
    // top: 0;
}

// 右侧部分#########################################################
.rightBlock {
    // width: 142.8rem;
    height: 96.8rem;
    // position: absolute;
    // left: 28.4rem;
    // top: 0;

    // 顶栏样式###############################
    .topPlace {
        display: flex;
        align-items: center;
        padding-left: 2rem;
        box-sizing: border-box;
        height: 8rem;
        // width: 142.8rem;
        width: 100%;
        background-color: #0d3050;
        border-radius: 0.4rem;
        // position: absolute;
        // top: 0;
        // left: 0;
        overflow: hidden;
        .searchInput {
            height: 3.2rem;
            width: 22.8rem;
            font-size: 1.4rem;
            position: absolute;
            left: 2.4rem;
            top: 2.4rem;
            background-color: #0c375e;
            border: 1px solid #004579;
            border-radius: 0.2rem;
            box-sizing: border-box;
            outline: 0;
            color: #fff;
            text-indent: 1.4rem;
            &:focus {
                border: 0.1rem solid #008080;
            }
            button {
                height: 3.2rem;
                width: 7.4rem;
                border-radius: 0.2rem;
                border: none;
                color: #fff;
                font-size: 1.4rem;
                cursor: pointer;
            }
        }
    }

    // 表格和分页器部分样式#################################
    .userTablePlace {
        height: 88.8rem;
        // width: 142.8rem;
        width: 100%;
        // position: absolute;
        // top: 10.4rem;
        position: relative;
        top: 0;
        left: 0;
        overflow: hidden;
        background-color: #0d3050;
        .bread {
            color: #fff;
            height: 5rem;
            // width: 142.8rem;
            line-height: 5rem;
            color: #fff;
            font-size: 1.4rem;
            text-indent: 2.4rem;
        }
    }
    // 分页器样式
    .pagination {
        float: right;
        margin-right: 5rem;
        margin-top: 1rem;
    }
}
</style>

<style lang="less" scoped>
// 全局样式--谨防代码污染
.positionInSystem {
    .table {
        height: 57.2rem;
        // width: 138rem;
        margin: 0 2.4rem 0 2.4rem;
        // overflow: hidden;
        // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
        .el-table td,
        .el-table th.is-leaf {
            border-bottom: 0.1rem solid #254561;
        }
        .el-table--border:after,
        .el-table--group:after,
        .el-table:before {
            opacity: 0;
        }
        .el-table__body-wrapper {
            background-color: #0d3050;
        }
        .el-table__cell {
            background-color: #0d3050;
        }
        .el-button--text {
            color: #03d9bc;
        }
    }
    .btn_save {
        margin-left: 2rem;
        border-radius: 0.2rem;
    }

    .btn2 {
        height: 3.2rem;
        width: 7.4rem;
        border-radius: 0.2rem;
        border: none;
        color: #fff;
        font-size: 1.4rem;
        cursor: pointer;
        background-color: #027969;
        position: absolute;
        top: 2.4rem;
        // left: 128.7rem;
        right: 2rem;
        width: 10.2rem;
        &:hover {
            background-color: #07555d;
        }
    }
    .btn2Grey {
        height: 3.2rem;
        width: 7.4rem;
        border-radius: 0.2rem;
        border: none;
        color: #fff;
        font-size: 1.4rem;
        background-color: grey;
        position: absolute;
        top: 2.4rem;
        // left: 128.7rem;
        right: 2rem;
        width: 10.2rem;
    }
}
</style>