import request from '@/utils/request';

export function pageTemplates(query) {
  return request({
    url: '/diagram/templates/page',
    method: 'get',
    params: query
  })
}

export function listTemplates(keyword) {
  return request({
    url: '/diagram/templates/list',
    method: 'get',
    params: { keyword }
  })
}

export function getTemplates(diagramid) {
  return request({
    url: '/diagram/templates/' + diagramid,
    method: 'get'
  })
}

export function addTemplates(data) {
  return request({
    url: '/diagram/templates',
    method: 'post',
    data: data
  })
}

export function updateTemplates(data) {
  return request({
    url: '/diagram/templates',
    method: 'put',
    data: data
  })
}

export function delTemplates(diagramid) {
  return request({
    url: '/diagram/templates/' + diagramid,
    method: 'delete'
  })
}

export function exportTemplates(diagramid) {
  return request({
    url: '/diagram/templates/export/'+ diagramid,
    method: 'get',
  })
}
