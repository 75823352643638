<!-- 配电监测直流屏 -->
<template>
    <div class="DCscreenInMonitoring" v-if="DCscreenData.length>0">
        <div class="flex-end report-box">
            <span class="btn-report" @click="goReport">报表统计</span>
        </div>
        <div class="scroll-box">
            <div class="cardPlace" v-loading="loading">
                <!-- 直流屏卡片########## -->
                <div class="DCscreenCard" v-for="(item,index) in DCscreenData " :key='index'>
                    <!-- 顶部################### -->
                    <div class="topPlace">
                        <img class="DCscreenIcon" src="@/assets/Monitoring/DCscreenIcon.png" alt="">
                        <div class="topPlaceWord">{{item.name}}</div>
                    </div>
                    <!-- 中部####################### -->
                    <div class="middlePlace">
                        <img class="DCscreenBackgroundImg" src="@/assets/Monitoring/DCscreenBackgroundImg.png" alt="">
                        <div class="left">
                            <div class="leftLine1">所属单位:</div>
                            <div class="leftLine2">
                                <el-tooltip :content="item.ownerName" placement="top">
                                    <span>{{item.ownerName}}</span>
                                </el-tooltip>
                            </div>
                            <div class="leftLine1">位置信息:</div>
                            <div class="leftLine2">
                                <el-tooltip :content="item.placeName" placement="top">
                                    <span>{{item.placeName}}</span>
                                </el-tooltip>
                            </div>
                            <div class="leftLine1">型号:</div>
                            <div class="leftLine2"> {{item.specification}}</div>
                            <div class="leftLine1">容量:</div>
                            <div class="leftLine2"> {{item.capacity}}</div>
                            <div class="leftLine1">厂家:</div>
                            <div class="leftLine2">
                                <el-tooltip :content="item.producer" placement="top">
                                    <span>{{item.producer}}</span>
                                </el-tooltip>
                            </div>
                            <div class="leftLine1">投运日期:</div>
                            <div class="leftLine2"> {{item.useDate}}</div>
                            <div class="leftLine1">生产日期:</div>
                            <div class="leftLine2">{{item.produceDate}}</div>
                        </div>
                        <div class="right">
                            <div class="rightLine1">控制母线电压:</div>
                            <div class="rightLine2"> {{item.control}}</div>
                            <div class="rightLine1">动力母线电压:</div>
                            <div class="rightLine2"> {{item.power}}</div>
                            <div class="rightLine1">充电电压:</div>
                            <div class="rightLine2"> {{item.charge}}</div>
                            <div class="rightLine1">蓄电池电压:</div>
                            <div class="rightLine2">{{item.battery}}</div>
                            <div class="rightLine1">充电浮充装置输出电流:</div>
                            <div class="rightLine2"> {{item.electricity}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 分页器########################## -->
            <div class="pagination">
                <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        layout="total,prev, pager, next, jumper"
                        :total="totalNumForPagenation"
                >
                </el-pagination>
            </div>
            <div style="height:16rem;"></div>
        </div>
    </div>
    <div v-else style="height:96.8rem;background:rgba(0, 115, 222, 0.35)"></div>
</template>

<script>
    import {getDiagramsByScenarioCode} from "@/api/diagram/main.js";
    import {sysMapListApi} from "@/api/diagram/kaiyuan.js";
    import {watchDevice} from "@/api/diagram/kaiyuan.js";

    export default {
        name: 'DCscreen',
        data() {
            return {
                loading: false,
                pageSize: 4,
                diagramId: this.$route.query.id,
                //直流屏数据
                DCscreenData: [],
                BindData: [],
                // 分页器当前页
                currentPage: 1,
                // 分页器总个数
                totalNumForPagenation: 0,
                time: 180,
                timer: null
            }
        },
        mounted() {
        },
        watch: {
            '$route': {
                handler(val) {
                    if (val.query.id) {
                        // 注册工具
                        this.diagramId = val.query.id;
                    }
                },
                immediate: true
            },
            diagramId: {
                handler(newVal, oldVal) {
                    console.log('newVal', newVal)
                    this.init()
                },
                immediate: true
            }
        },
        methods: {
            init() {
                this.time = 180;
                //测试id:1470994708401008641-C
                Promise.all([this.getGraphData(this.diagramId), this.getListData(this.pageSize, this.currentPage, this.diagramId)]).then(res => {
                    console.log('BindData', this.BindData)
                    if (this.BindData.length > 0) {
                        this.takeRelaData()
                    }
                })
            },
            timerFunc() {
                this.timer = setInterval(function () {
                    if (this.time === 0) {
                        this.init();
                    } else {
                        this.time--;
                    }
                }, 1000)
            },
            //获取列表右边数据
            takeRelaData() {
                let pointIdArr = this.BindData.map(item => {
                    return item.pointId
                })
                let requestID = pointIdArr.join(',');
                // console.log('pointIdArr', pointIdArr)
                watchDevice(requestID).then(res => {
                    if (res.ok) {
                        let resultData = res.data;//右侧数据合集
                        this.BindData.forEach(item => {
                            resultData.forEach(itemMeter => {
                                if (item.pointId.indexOf(itemMeter.meterId) > -1) {
                                    let arr = item.BindData.filter(list => list.pointId == itemMeter.meterId)//找出BindData中pointId相同的数据
                                    // console.log('arr-2222', arr)
                                    this.DCscreenData.forEach(element => {
                                        if (element.id == item.equipmentId) {
                                            element[arr[0].moudle] == itemMeter.points[arr[0].moudle] ? itemMeter.points[arr[0].moudle] : '';
                                        }
                                    })
                                    // console.log('DCscreenData-2222', this.DCscreenData)
                                }
                            })
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            //跳到统计报表
            goReport() {
                this.$router.push({
                    path: '/monitoring/report', query: {
                        id: this.diagramId,
                        type: 'dCscreen'
                    }
                })
            },
            // 拿到JSON数据
            async getGraphData(diagramCode) {
                const res = await getDiagramsByScenarioCode({diagramCode});
                if (res.code == 200) {
                    let result = res.data.length == 0 ? [] : res.data.filter(list => JSON.stringify(list.diagramJson) !== "null");
                    let diagramJsonArr = result.length == 0 ? [] : result.map(item => {
                        return JSON.parse(item.diagramJson)
                    })
                    console.log('diagramJsonArr', diagramJsonArr)
                    //获取绑定数据
                    this.BindData = diagramJsonArr.length == 0 ? [] : diagramJsonArr.map(list => {
                        let data = list.cells.map(item => {
                            return item.BindData ? item.BindData : {}
                        })
                        let pointIdArr = data.map(item => {
                            return item.pointId ? item.pointId : ""
                        })
                        pointIdArr = Array.from(new Set(pointIdArr))//去重
                        return {
                            equipmentId: list.cells[0].BindData.equipmentId,//匹配id
                            pointId: pointIdArr.filter(item => item !== ""),//通过此id调接口获取数据
                            BindData: data//绑定数据
                        }
                    })
                }
            },
            async getListData(pageSize, pageNum, nodeId) {
                this.loading = true;
                const res = await sysMapListApi(pageSize, pageNum, nodeId);
                if (res.ok) {
                    this.DCscreenData = [];
                    this.totalNumForPagenation = res.data.total;
                    let records = res.data.records;
                    records.forEach((element, i) => {
                        let {id, name, ownerName, placeName, specification, capacity, producer, useDate, produceDate} = element;
                        console.log('name', name)
                        this.DCscreenData.push({
                            id: id,//左侧数据id
                            name: name,
                            ownerName: ownerName,
                            placeName: placeName,
                            specification: specification,
                            capacity: capacity,
                            producer: producer,
                            useDate: useDate,
                            produceDate: produceDate
                        })
                    });
                    this.loading = false;
                }
            },
            //分页器页面切换
            handleCurrentChange() {
                alert('切换页面')
                this.init()
            },
        },
        beforeDestroy() {
            clearInterval(this.timer)
        },
        created() {
            // let diagramCode = this.$route.query.id;
            this.init()
            this.timerFunc()
        },

    }
</script>

<style lang="less" scoped>
    .DCscreenInMonitoring {
        width: 142.8rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        // overflow-y: auto;
        .scroll-box {
            flex: 1;
            overflow-y: auto;
        }

        .scroll-box::-webkit-scrollbar {
            width: 0;
        }

        // 卡片的区域######################
        .cardPlace {
            width: 142.8rem;
            height: 80rem;
            // flex: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // 卡片样式##################################
            .DCscreenCard {
                height: 38rem;
                width: 70rem;
                background-color: #0d3050;
                // margin-bottom: 2.4rem;
                position: relative;
                top: 0;
                left: 0;
                border-radius: 0.4rem;
                // 顶部#####################################
                .topPlace {
                    height: 5rem;
                    width: 70rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(225deg,
                    rgba(15, 36, 55, 0.0001) 0.03%,
                    rgba(0, 115, 222, 0.7) 99.9%);
                    border-radius: 0.4rem 0.4rem 0px 0px;

                    .DCscreenIcon {
                        height: 1.6rem;
                        width: 1.6em;
                        position: absolute;
                        top: 2rem;
                        left: 2.4rem;
                    }

                    .topPlaceWord {
                        height: 2.2rem;
                        position: absolute;
                        top: 1.7rem;
                        left: 4.8rem;
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                        color: #fff;
                    }
                }

                //中部########################################
                .middlePlace {
                    height: 33rem;
                    width: 70rem;
                    position: absolute;
                    top: 5rem;
                    left: 0;
                    border-radius: 0 0 0.4rem 0.4rem;

                    .DCscreenBackgroundImg {
                        position: absolute;
                        left: 2.6rem;
                        top: 2rem;
                        height: 29rem;
                        opacity: 0.8;
                        mix-blend-mode: overlay;
                    }

                    // 左侧板块###################
                    .left {
                        height: 25rem;
                        width: 30rem;
                        position: absolute;
                        top: 3rem;
                        left: 4rem;
                        padding-top: 1.6rem;

                        .leftLine1 {
                            height: 2.2rem;
                            width: 11rem;
                            float: left;
                            line-height: 2.2rem;
                            text-align: right;
                            color: rgba(255, 255, 255, 0.5);
                            font-size: 1.4rem;
                            margin-bottom: 1rem;
                        }

                        .leftLine2 {
                            height: 2.2rem;
                            width: 19rem;
                            float: right;
                            line-height: 2.2rem;
                            text-align: center;
                            color: rgba(255, 255, 255, 0.5);
                            font-size: 1.4rem;
                            margin-bottom: 1rem;
                            text-align: left;
                            text-indent: 1.5rem;
                            // overflow: visible;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    // 右侧板块####################
                    .right {
                        height: 25rem;
                        width: 30rem;
                        position: absolute;
                        top: 3rem;
                        left: 34rem;
                        background-color: #0e2a44;
                        padding-top: 1.6rem;

                        .rightLine1 {
                            height: 2.2rem;
                            width: 18rem;
                            float: left;
                            line-height: 2.2rem;
                            text-align: right;
                            color: rgba(255, 255, 255, 0.5);
                            font-size: 1.4rem;
                            margin-bottom: 1rem;
                        }

                        .rightLine2 {
                            height: 2.2rem;
                            width: 7.3rem;
                            float: left;
                            line-height: 2.2rem;
                            text-align: center;
                            color: rgba(3, 217, 188, 0.7);
                            font-size: 1.4rem;
                            margin-bottom: 1rem;
                            text-align: right;
                            text-indent: 1.5rem;
                        }
                    }
                }
            }
        }

        // 分页器样式##################################
        .pagination {
            // float: right;
            margin-right: 5rem;
            padding-top: 0.5rem;
            text-align: right;
            height: 4.7rem;
            box-sizing: border-box;
        }

        .report-box {
            height: 6.2rem;
            box-sizing: border-box;
            padding: 1.6rem 0;
        }
    }
</style>
