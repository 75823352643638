<template>
  <div class="DialogAddStandard2">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div class="equip_title">{{ type == 'edit' ? '修改明细' : '新增明细'}}</div>

      </div>

      
      <el-form :model="form" class="elForm" :rules="rules" ref="ruleForm" >
          <el-form-item label="条目明细" prop="name" style="width: 100%;">
            <el-input v-model="form.name" placeholder="请输入" size="small"  clearable></el-input>
          </el-form-item>
          <el-form-item v-if="type == 'edit'" label="排序" prop="rowNo" style="width: 100%;">
            <el-input-number v-model="form.rowNo" placeholder="请输入" style="width: 98%" size="small"></el-input-number>
          </el-form-item>
          <el-form-item v-if="form.parentNode !== 1" label="输入类型" prop="type" style="width: 100%;">
            <el-select v-model="form.type" :placeholder="form.type" style="width: 98%" size="small">
              <el-option label="单选" :value="0"></el-option>
              <el-option label="复选" :value="1"></el-option>
              <el-option label="文本" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="form.type != '2' && form.parentNode !== 1" label="选项值" prop="value" style="width: 100%;">
            <el-input v-model="form.value" placeholder="请输入" size="small"  clearable></el-input>
          </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer"> 
        <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
      </div>

    
   
    </el-dialog>

  
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import { validateInput,validateSelect} from "@/utils/validate";

export default {
  name: "DialogAddStandard2",
  mixins: [tableBg],
  props: {
    type: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {

      // 展示判断
      dialogFormVisible: false,
  
      // 表单数据存放处
      form: {
        standardId:"",
        rowNo: "",
        name: "",
        parentId: null,
        parentNode: "",
      },
      tableList: [],
      // 校验规则
      rules: {
        name: [validateInput],  
        type:[validateSelect],
        value:[validateInput]
      },
      
    
    };
  },

  methods: {
    // dialog关闭的回调
    closeDialog(ruleForm) {
      this.formInit();
      this.$refs[ruleForm].resetFields();
   
    },

    // 取消弹框
    handleCancle(ruleName) {
      this.$refs[ruleName].resetFields();
      this.dialogFormVisible = false;
      this.formInit();
    },

    // from归零
    formInit() {
      this.form = {standardId:"", name: "",parentId:null,rowNo:"",parentNode:"",};
    },
   
    //父组件点击触发
    show(val, data, dialogType) {
      this.dialogFormVisible = true
      if (dialogType === 'addChild') {
        this.form.parentId = data.id;
      } else if (data) {
        this.form = JSON.parse(JSON.stringify(data))
      } else {
        this.form = {
          standardId:"",
          rowNo: "",
          name: "",
          parentId: null,
          parentNode: "",
        }
      }
      this.form.standardItemId = val
    },

   


    // 提交发送请求（一级dialog点击《保存》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate(valid => {
        if (valid) {
          if (this.type == 'add' || this.type === 'addChild') {
          // 发送新增请求
           this.$store.dispatch("postRepairStandardDetailAdd", this.form).then(() => {
              if (this.ifRepairStandardDetailAddSuccess === 0) {
                this.$message({ message: "添加成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePageRight();
                  if (this.type === 'addChild') {
                      this.$parent.refreshChildren(this.form.parentId);
                  }
                this.dialogFormVisible = false;
                this.formInit()
              } else if (this.ifRepairStandardDetailAddSuccess === -1) {
                this.$message({
                  message: this.ifRepairStandardDetailAddError,
                  type: "warning"
                });
              }
            });
          } else if (this.type == 'edit') {
            this.$store.dispatch("postRepairStandardDetailEdit", this.form).then(() => {
              if (this.ifRepairStandardDetailEditSuccess === 0) {
                this.$message({ message: "修改成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePageRight();
                if (this.form.parentNode != null) {
                    this.$parent.refreshChildren(this.form.parentId, this.form);
                }
                this.dialogFormVisible = false;
                this.formInit()
              } else if (this.ifRepairStandardDetailEditSuccess === -1) { 
                this.$message({
                  message: this.ifRepairStandardDetailEditError,
                  type: "warning"
                });
              }
            });
          }
          
        } else {
          this.$message.error("有必填项未填");
        }
       
      });
    }
  },
  //处理state数据
  computed: {
    ...mapState({
      ifRepairStandardDetailAddSuccess: state => {return state.repair.ifRepairStandardDetailAddSuccess.errorCode;},
      ifRepairStandardDetailAddError:state => {return state.repair.ifRepairStandardDetailAddSuccess.message;},
      ifRepairStandardDetailEditSuccess: state => {return state.repair.ifRepairStandardDetailEditSuccess.errorCode;},
      ifRepairStandardDetailEditError:state => {return state.repair.ifRepairStandardDetailEditSuccess.message;},
   
      
    })
  },

 
};




</script>

<style lang="less" scoped>
.DialogAddStandard2{
  overflow: hidden;
  .el-dialog__body{
    height: 60rem;
  }
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
// 树所在的区域###################
.treePlace{
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 80rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  
  
  // border: #071828 0.2rem solid;
}
.treePlaceGoAway{
  // opacity: 0;
  display: none;
  // position:relative;
  // top: -54rem;
  // left: 80rem;
  height: 0;
  width: 26rem;
  overflow: hidden;
  // display: none;
  transition: height 0.3s;  
}

.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}


</style>




<style lang="less">
.DialogAddStandard2{
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  .el-table::before {
      opacity: 0;
  }

  
}

</style>



