<template>
    <div class="departmentInSystem page-main">
        <!-- 左侧板块#################################################################### -->
        <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="leftBlock page-main-tree">
            <TreeDepartment ref="treeRef" />
        </div>
        <!-- 右侧板块######################################################################## -->
        <div :class="isTreeShow ? 'shrink-open_tree_content':'shrink-close_tree_content'" class="rightBlock page-main-content">
            <div class="shrink-btn" @click="isTreeShow = !isTreeShow">
            </div>
            <!-- 顶栏部分######################### -->
            <div class="topPlace">
                <el-input size="small" placeholder="请输入部门名称" v-model="searchValue" @keyup.enter.native="search" style="width:22rem;" clearable @clear="search"></el-input>
                <button class="btn_save" @click="search">查 询</button>
                <button class="btn2" @click="ifDialogShow">新增部门</button>
            </div>
            <!-- 中间表格部分###################### -->
            <!-- <div class="middlePlace"> -->
            <div class="userTablePlace">
                <div class="bread">
                    系统管理 / 部门管理
                </div>
                <div class="table">
                    <el-table :data="tableData" stripe:false height="57.8rem" border:false style="width: 100%" :header-cell-style="headerClass" :cell-style="cellStyle">
                        <el-table-column label="序号" min-width="6%">
                            <template slot-scope="scope">{{
                  scope.$index + 1 + (currentPage - 1) * pageSize
                }}</template>
                        </el-table-column>
                        <el-table-column class="tab" prop="deptNo" label="编号" min-width="9%" :show-overflow-tooltip="true">
                        </el-table-column>
                        <el-table-column class="tab" prop="name" label="部门名称" min-width="18%" :show-overflow-tooltip="true">
                        </el-table-column>
                        <el-table-column prop="phone" label="部门电话" min-width="14%" :show-overflow-tooltip="true">
                        </el-table-column>
                        <el-table-column prop="parentName" label="上级部门名称" min-width="14%" :show-overflow-tooltip="true">
                        </el-table-column>
                        <el-table-column prop="statusName" label="启用状态" min-width="14%" :show-overflow-tooltip="true">
                        </el-table-column>
                        <!-- 操作部分 -->
                        <el-table-column label="操作" min-width="14%">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页器部分 -->
                <div class="pagination">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalNumForPagenation">
                    </el-pagination>
                </div>
            </div>
            <!-- </div> -->
        </div>

        <!-- 弹层Dialog部分########################### -->
        <DialogDepartment ref="DialogRef" />
    </div>
</template>

<script>
import TreeDepartment from "../level2Components/TreeDepartment.vue"
import DialogDepartment from "../level2Components/DialogDepartment.vue"
import { tableBg } from "@/mixin/tablebg"
import { mapState } from "vuex"
export default {
    name: "Department",
    mixins: [tableBg],
    components: { TreeDepartment, DialogDepartment },
    data () {
        return {
            isTreeShow: true,
            // 当前树节点
            treeNode: "",
            // 当前树节点ID
            treeNodeId: "",
            // 双向绑定--总数
            totalNumForPagenation: 0,
            // 双向绑定--每页的个数
            pageSize: 10,
            // 双向绑定--当前页
            currentPage: 1,
            // 双向绑定的搜索数据
            searchValue: "",
            // 双向绑定的用户状态
            userState: "",
            // 用户状态列表
            userStateList: [
                { label: "冻结", value: "1" },
                { label: "正常", value: "2" },
            ],
            // 表格数据(假数据)
            tableData: [],
        }
    },

    methods: {
        // 发送请求刷新页面
        refreshThePage () {
            this.$store
                .dispatch("getSystemDepartmentPageData", {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    keyword: this.searchValue,
                    id: this.treeNodeId,
                })
                .then(() => {
                    this.tableData = []
                    for (let i = 0; i < this.departmentPageData.length; i++) {
                        const tableSingle = {
                            deptNo: "",
                            name: "",
                            phone: "",
                            parentName: "",
                            status: "",
                            statusName: "",
                        }
                        tableSingle.deptNo = this.departmentPageData[i].deptNo
                        tableSingle.name = this.departmentPageData[i].name
                        tableSingle.phone = this.departmentPageData[i].phone
                        tableSingle.parentName = this.departmentPageData[i].parentName
                        tableSingle.status = this.departmentPageData[i].status
                        if (this.departmentPageData[i].status === "1") {
                            tableSingle.statusName = "正常"
                        } else {
                            tableSingle.statusName = "冻结"
                        }
                        this.tableData.push(tableSingle)
                    }

                    this.totalNumForPagenation = this.departmentTotal
                })
        },
        // 刷新树
        refreshTheTree () {
            this.$refs.treeRef.refreshTheTree()
        },

        // 点击搜索或者回车
        search () {
            this.currentPage = "1"
            this.refreshThePage()
        },
        // 更改用户状态选择框的选择
        selectChanged () {
            alert("更改用户状态选择框的选择")
        },
        //点击新增用户
        ifDialogShow () {
            if (this.treeNode !== "") {
                this.$refs.DialogRef.show(this.treeNode)
            } else {
                this.$refs.DialogRef.show(false)
            }
        },
        //点击了编辑按钮
        handleEdit (index) {
            this.$refs.DialogRef.showForEdit(this.departmentPageData[index])
        },
        // 点击了删除按钮
        handleDelete (index) {
            this.$confirm("您确定要执行删除操作吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$store
                    .dispatch("deleteSystemDepartment", this.departmentPageData[index].id)
                    .then(() => {
                        if (this.ifDepartmentDeleted === 0) {
                            this.$message({ message: "删除成功！", type: "success" })
                            this.$refs.treeRef.refreshTheTree()
                            this.refreshThePage()
                        } else if (this.ifDepartmentDeleted === -1) {
                            this.$message({
                                message: "删除失败！请检查网络",
                                type: "warning",
                            })
                        }
                    })
            })
        },
        // 更改分页器每页个数
        handleSizeChange (val) {
            this.currentPage = "1"
            this.pageSize = val
            this.refreshThePage()
        },
        // 更改分页器页面
        handleCurrentChange (val) {
            this.currentPage = val
            this.refreshThePage()
        },
        // 更新树的节点
        changeTreeNode (val) {
            this.currentPage = 1;
            this.treeNodeId = val.id
            this.treeNode = val
            this.refreshThePage()
        },
    },

    mounted () {
        this.refreshThePage()
    },

    computed: {
        ...mapState({
            departmentPageData: (state) => {
                return state.system.departmentPageData.data.records
            },
            departmentTotal: (state) => {
                return state.system.departmentPageData.data.total
            },
            ifDepartmentDeleted: (state) => {
                return state.system.ifDepartmentDeleted.errorCode
            },
        }),
    },
}
</script>

<style lang="less" scoped>
// 右侧部分#########################################################
.rightBlock {
    // width: 142.8rem;
    height: 96.8rem;
    // position: absolute;
    // left: 28.4rem;
    // top: 0;

    // 顶栏样式###############################
    .topPlace {
        display: flex;
        align-items: center;
        padding-left: 2rem;
        box-sizing: border-box;
        height: 8rem;
        // width: 142.8rem;
        width: 100%;
        background-color: #0d3050;
        border-radius: 0.4rem;
        // position: absolute;
        // top: 0;
        // left: 0;
        overflow: hidden;
        margin-bottom: 2.4rem;
        .searchInput {
            height: 3.2rem;
            width: 22.8rem;
            font-size: 1.4rem;
            position: absolute;
            left: 2.4rem;
            top: 2.4rem;
            background-color: #0c375e;
            border: 1px solid #004579;
            border-radius: 0.2rem;
            box-sizing: border-box;
            outline: 0;
            color: #fff;
            text-indent: 1.4rem;
            &:focus {
                border: 0.1rem solid #008080;
            }
            button {
                height: 3.2rem;
                width: 7.4rem;
                border-radius: 0.2rem;
                border: none;
                color: #fff;
                font-size: 1.4rem;
                cursor: pointer;
            }
            .btn_save {
                margin-left: 2rem;
            }
        }
    }

    // 表格和分页器部分样式#################################
    .userTablePlace {
        height: 86.4rem;
        // width: 142.8rem;
        width: 100%;
        // position: absolute;
        // top: 10.4rem;
        position: relative;
        top: 0;
        left: 0;
        overflow: hidden;
        background-color: #0d3050;
        .bread {
            color: #fff;
            height: 5rem;
            // width: 142.8rem;
            line-height: 5rem;
            color: #fff;
            font-size: 1.4rem;
            text-indent: 2.4rem;
        }
    }
    // 分页器样式
    .pagination {
        float: right;
        margin-right: 5rem;
        margin-top: 1rem;
    }
}
</style>

<style lang="less" scoped>
// 全局样式--谨防代码污染
.departmentInSystem {
    .table {
        height: 57.2rem;
        // width: 138rem;
        margin: 0 2.4rem 0 2.4rem;
        // overflow: hidden;
        // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
        .el-table td,
        .el-table th.is-leaf {
            border-bottom: 0.1rem solid #254561;
        }
        .el-table--border:after,
        .el-table--group:after,
        .el-table:before {
            opacity: 0;
        }
        .el-table__body-wrapper {
            background-color: #0d3050;
        }
        .el-table__cell {
            background-color: #0d3050;
        }
        .el-button--text {
            color: #03d9bc;
        }
    }

    .btn_save {
        margin-left: 2rem;
        border-radius: 0.2rem;
    }

    .btn2 {
        height: 3.2rem;
        width: 7.4rem;
        border-radius: 0.2rem;
        border: none;
        color: #fff;
        font-size: 1.4rem;
        cursor: pointer;
        background-color: #027969;
        position: absolute;
        top: 2.4rem;
        // left: 128.7rem;
        right: 2rem;
        width: 10.2rem;
        &:hover {
            background-color: #07555d;
        }
    }
    .btn2Grey {
        height: 3.2rem;
        width: 7.4rem;
        border-radius: 0.2rem;
        border: none;
        color: #fff;
        font-size: 1.4rem;
        background-color: grey;
        position: absolute;
        top: 2.4rem;
        // left: 128.7rem;
        right: 2rem;
        width: 10.2rem;
    }
}
</style>
