<template>
  <div class="PatrolGroupSetting">
      <!-- 穿梭框左侧################################################################################################################ -->
      <div class="leftPart">
        <!-- 顶部 -->
          <div class="topPlace">
              <div class="btnGetBack" @click="getBackPage"> &lt; 返回</div>
              <button class="btn" @click="addGroup">新增条目</button>
          </div>
          <!-- 表格部分 -->
          <div class="table">
              <el-table :data="tableLeftData" stripe:false height="57.8rem" 
                        border:false style="width: 100%"
                        :header-cell-style="headerClass"                    
                        :cell-style="cellStyle">
                <el-table-column class="tab" label="选择" min-width="19%">
                  <template slot-scope="scope">
                    <el-radio @change="handelChangeRadio(tableLeftData[scope.$index].id)" size="mini" v-model="radioValue" :label="tableLeftData[scope.$index].id" border>查看明细</el-radio>
                  </template>           
                </el-table-column>
                <el-table-column class="tab" prop="name" label="条目名称" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>

                <!-- 操作部分 -->
                <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleDeleteLeft(scope.$index, scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
          </div>
            <!-- 分页器部分 -->
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChangeLeft"
                @current-change="handleCurrentChangeLeft"
                :current-page="currentPageLeft"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageSizeLeft"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumForLeftPagenation"
              >
              </el-pagination>
            </div>
      </div>
      <!-- 线 -->
      <div class="middleLine"></div>
      <!-- 穿梭框右侧 ####################################################################################################-->
      <div class="rightPart">
        <!-- 顶部 -->
          <div class="topPlace">
              <button @click="addMember" class="btn">新增明细</button>
          </div>
         <!-- 表格部分 -->
          <div class="table">
              <el-table :data="tableRightData" stripe:false height="57.8rem"
                        row-key="id"
                        ref="rightTable"
                        lazy
                        :load="loadTreeChild"
                        :tree-props="{ hasChildren: 'parentNode'}"
                        border:false style="width: 100%"
                        :header-cell-style="headerClass"                    
                        :cell-style="cellStyle">
                <el-table-column class="tab" prop="name" label="条目明细" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="rowNo" label="排序" min-width="5%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="typeName" label="输入类型" :formatter="typeFormatter" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="value" label="选项值" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                <!-- 操作部分 -->
                <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleEditRight(scope.$index, scope.row)">编辑</el-button>
                      <el-button size="mini" type="text" @click="handleDeleteRight(scope.$index, scope.row)">删除</el-button>
                      <el-button v-if="scope.row.parentNode === null || scope.row.parentNode === 1" size="mini" type="text" @click="handleAddChild(scope.$index, scope.row)">增加子节点</el-button>
                  </template>
                </el-table-column>
              </el-table>
          </div>
            <!-- 分页器部分 -->
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChangeRight"
                @current-change="handleCurrentChangeRight"
                :current-page="currentPageRight"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageSizeRight"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumForRightPagenation"
              >
              </el-pagination>
            </div>
      </div>

      <!-- 弹层位置########################################################################################################## -->
      <DialogAddStandard2 ref="addGroupRef"/>
      <DialogAddStandard3 :type="type" ref="addMemberRef"/>
  </div>
</template>

<script>
import DialogAddStandard3 from './DialogAddStandard3.vue'
import DialogAddStandard2 from './DialogAddStandard2.vue'
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
export default {
    name:'PatrolGroupSetting',
    mixins: [tableBg],
    components:{DialogAddStandard2,DialogAddStandard3},
    data(){return{
      // 选择框的选择数据（左侧）
      radioValue:'',
      // 选择的小组id
      choosenId:'',
      //的分页数据（左侧）
      tableLeftData:[],
      // 的总数（左侧）
      totalNumForLeftPagenation:0,
      // 双向绑定--每页的个数（左侧）
      pageSizeLeft: 10,
      // 双向绑定--当前页（左侧）
      currentPageLeft: 1,
      // 标准的id
      standardId:"",



      //分页数据（右侧）
      tableRightData:[],
      // 的总数（右侧）
      totalNumForRightPagenation:0,
      // 双向绑定--每页的个数（右侧）
      pageSizeRight: 10,
      // 双向绑定--当前页（右侧）
      currentPageRight: 1,
      // 右侧radio绑定数据
      radioValueRight:'',
      type: 'add',
      maps: new Map(),
    }},


    methods:{
      // 进入到本页面
      pageShow(val){
        this.standardId = val
        this.refreshThePageLeft()
      },

      // 回到作业标准界面
      getBackPage(){
        this.$parent.getBackPage()
      },


        // 刷新页面(左侧)
      refreshThePageLeft() {
        this.$store.dispatch("getRepairStandardLeftPageData", {
            pageNum: this.currentPageLeft,
            pageSize: this.pageSizeLeft,
            id:this.standardId,
          }).then(() => {  
            this.tableLeftData = this.repairStandardLeftPageData;
            this.choosenId = this.tableLeftData[0].id
            this.radioValue = this.tableLeftData[0].id
            this.totalNumForLeftPagenation = this.repairStandardLeftPageDataTotal;     
            this.refreshThePageRight()
          });
      },
        // 更改分页器每页个数(左侧)
      handleSizeChangeLeft(val) {
        this.currentPageLeft = "1";
        this.pageSizeLeft = val;
        this.refreshThePageLeft();
      },
      // 更改分页器页面（左侧）
      handleCurrentChangeLeft(val) {
        this.currentPageLeft = val;
        this.refreshThePageLeft();
      },
      //添加条目（左侧按钮）
      addGroup(){
        this.$refs.addGroupRef.show(this.standardId)
      },
      //编辑条目（左侧按钮）
      handleEditLeft(index){
        this.$refs.addGroupRef.showForEdit(this.repairPatrolGroupSettingLeftPageData[index])
      },
      // 删除条目（左侧按钮）
      handleDeleteLeft(index){
      const deleteId = this.repairStandardLeftPageData[index].id;
      this.$confirm("您确定要执行删除操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("deleteRepairStandardLeftPageData", deleteId).then(() => {
          if (this.ifdeleteRepairStandardLeftPageDataSuccess === 0) {
            this.$message({ message: "删除成功！", type: "success" });
            this.refreshThePageLeft();
          } else if (this.ifdeleteRepairStandardLeftPageDataSuccess === -1) {
            this.$message({ message: this.ifdeleteRepairStandardLeftPageDataError, type: "warning" });
          }
        });
      });
      },
      // 切换radio
      handelChangeRadio(val){
        this.choosenId = val
        this.refreshThePageRight()
      },



//上左下右 ###################################################################################################################################

        // 刷新页面(右侧)
      refreshThePageRight(parentId) {

        this.$store.dispatch("getRepairStandardRightPageData", {
            pageNum: this.currentPageRight,
            pageSize: this.pageSizeRight,
            id:this.choosenId,
            }).then(() => {   
            this.tableRightData = this.repairStandardRightPageData;
            this.totalNumForRightPagenation = this.repairStandardRightPageDataTotal;   
          });
        this.refreshChildren(parentId)

      },
        // 更改分页器每页个数(右侧)
      handleSizeChangeRight(val) {
        this.currentPageRight = "1";
        this.pageSizeRight = val;
        this.refreshThePageRight();
      },
      // 更改分页器页面（右侧）
      handleCurrentChangeRight(val) {
        this.currentPageRight = val;
        this.refreshThePageRight();
      },
      // 新增巡检人员（右侧）
      addMember(){
        this.type = 'add'
        this.$refs.addMemberRef.show(this.choosenId)
      }, 
      // 编辑明细（右侧）
      handleEditRight(index, row){
        this.type = 'edit'
        this.$refs.addMemberRef.show(this.choosenId, row)
      },      
      // 删除作业标准明细
      handleDeleteRight(index, row){
   
      this.$confirm("确定要删除这条明细吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store   
          .dispatch("deleteRepairStandardRightPageData", row.id)
          .then(() => {
            if (this.ifdeleteRepairStandardRightPageDataSuccess === 0) {
              this.$message({ message: "删除成功！", type: "success" });
              this.refreshThePageRight();
              if (row.parentId !== null && row.parentId !== '') {
                  this.deleteLazyTableItem(row)
              }
            } else if (this.ifdeleteRepairStandardRightPageDataSuccess === -1) {
              this.$message({
                message: this.ifdeleteRepairStandardRightPageDataError,
                type: "warning"
              });
            }
          });
      });
      },
        // 新增子节点
        handleAddChild(index, row){
            this.type = 'addChild'
            this.$refs.addMemberRef.show(this.choosenId, row, this.type)
        },
        loadTreeChild(tree, treeNode, resolve) {
            this.$store.dispatch("getRepairStandardRightChild", { id: tree.id }).then(() => {
                resolve(JSON.parse(JSON.stringify(this.repairStandardRightChild)))
                // 记录treeNode节点
                this.maps.set(tree.id, { tree, treeNode, resolve })
            });
        },
        deleteLazyTableItem (item) {
            const store = this.$refs.rightTable.store;
            if (item.parent != -1) {
                const parent = store.states.lazyTreeNodeMap[item.parentId];
                const index = parent.findIndex(child => child.id == item.id);
                parent.splice(index, 1);
            }
            else {
                const parent = store.states.data;
                const index = parent.findIndex(child => child.id == item.id);
                parent.splice(index, 1);
            }
        },
        refreshChildren(parentId) {
            const store = this.$refs.rightTable.store;
            const parentRow = store.states.data.find(item => item.id === parentId);
            const parentTreeNode = store.states.treeData[parentId];
            if (parentTreeNode) {
                if (parentTreeNode.loaded) {
                    // 根据父级id取出对应节点数据
                    if (this.maps.get(parentId)) {
                        const { tree, treeNode, resolve } = this.maps.get(parentId)
                        if (tree) {
                            this.loadTreeChild(tree, treeNode, resolve)
                        }
                    }
                }
                else {
                    store.loadOrToggle(parentRow);
                }
            } else {
                setTimeout(() => {
                    store.loadOrToggle(parentRow)
                }, 200)
            }
        },
        typeFormatter(row, column, cellValue) {
          let typeText = ""
          if (row.type === 0) {
              typeText = "单选"
          } else if (row.type === 1) {
              typeText = "复选"
          } else if (row.type === 2) {
              typeText = "文本"
          }
          return typeText
        },
    },

    // mounted(){
    //   this.refreshThePageLeft() 
    // },

     computed: {
    ...mapState({
      // 分页数据(左侧)
      repairStandardLeftPageData: state => { return state.repair.repairStandardLeftPageData.data.records;},
      // 页面总数（左侧）
      repairStandardLeftPageDataTotal: state => { return state.repair.repairStandardLeftPageData.data.total;},
      // 分页数据(右侧)
      repairStandardRightPageData: state => { return state.repair.repairStandardRightPageData.data.records;},
      // 子节点数据(右侧)
      repairStandardRightChild: state => {return state.repair.repairStandardRightChild.data;},
      // 页面总数（右侧）
      repairStandardRightPageDataTotal: state => { return state.repair.repairStandardRightPageData.data.total;},
      // 删除条目是否成功（左侧）
      ifdeleteRepairStandardLeftPageDataSuccess:state => { return state.repair.ifdeleteRepairStandardLeftPageDataSuccess.errorCode},
      ifdeleteRepairStandardLeftPageDataError:state => { return state.repair.ifdeleteRepairStandardLeftPageDataSuccess.message},
      
      
      
      
  
      // 是否删除明细成功
      ifdeleteRepairStandardRightPageDataSuccess:state => { return state.repair.ifdeleteRepairStandardRightPageDataSuccess.errorCode},
      ifdeleteRepairStandardRightPageDataError:state => { return state.repair.ifdeleteRepairStandardRightPageDataSuccess.message},
      // ifDeleteRepairPatrolTaskSuccess:state => { return state.repair.ifDeleteRepairPatrolTaskSuccess.errorCode},
      // ifDeleteRepairPatrolTaskError:state => { return state.repair.ifDeleteRepairPatrolTaskSuccess.message},
     
    })
  }
}
</script>

<style lang='less' scoped>
// 返回按钮
.btnGetBack{
  height: 3rem;
  width: 8rem;
  // background-color: red;
  position: absolute;
  left: 2.4rem;
  top: 2.9rem;
  color: #027969;
  font-weight: 900;
  font-size: 1.4rem;
  line-height: 3rem;
  cursor: pointer;
 } 



// 穿梭框左侧#####################################################################
  .leftPart{
    height: 80.4rem;
    width: 52rem;
    position: absolute;
    top: 0;
    left: 0;
  // 顶栏位置
  .topPlace{
    height: 7.2rem;
    width: 52rem;
    position: relative;
    top: 0;
    left: 0;

    .btn{
      height: 3.2rem;
      width: 10.2rem;
      color: #fff;
      font-size: 1.4rem;
      border-radius: 0.2rem;
      background-color: #027969;
      line-height: 3.2rem;
      border: none;
      position: absolute;
      right: 2.4rem;
      top: 2.4rem;
      cursor: pointer;
    }
  }
  // 表格位置
 .table {
    height: 57.2rem;
    width: 47.2rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }

// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
  }
  .middleLine{
    position: absolute;
    top: 0;
    left: 52rem;
    height: 57.1rem;
    margin-top: 7.2rem;
    margin-bottom: 16.1rem;
    width: 0;
    border:0.1rem solid rgba(22, 111, 191, 0.2);
  }

// 穿梭框右侧#####################################################################
  .rightPart{
    height: 80.4rem;
    width: 119rem;

    position: absolute;
    top: 0;
    left:52.2rem;
      // 顶栏位置
  .topPlace{
    height: 7.2rem;
    width: 119rem;
    position: relative;
    top: 0;
    left: 0;

    .btn{
      height: 3.2rem;
      width: 10.2rem;
      color: #fff;
      font-size: 1.4rem;
      border-radius: 0.2rem;
      background-color: #027969;
      line-height: 3.2rem;
      border: none;
      position: absolute;
      right: 2.4rem;
      top: 2.4rem;
      cursor: pointer;
    }
    


  }
  // 表格位置
 .table {
    height: 57.2rem;
    width: 114.2rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }

// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
  }

</style>


<style lang='less'>
.PatrolGroupSetting{
.el-radio.is-bordered.is-checked {
    border-color: #027969;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #027969;
}

.el-radio__input.is-checked .el-radio__inner {
    border-color: #027969;
    background: #027969;
}

.el-radio__inner:hover {
    border-color: #027969;
    background-color: #0c375e;
}

.el-radio.is-bordered {
    border: 1px solid #606266;
    
}

.el-radio__inner {
    border: 1px solid #606266;
   
    background-color: #0c375e;
    
}
.el-radio__inner::after {
    
    background-color: #0c375e;
   
}


}
</style>


<style lang='less'>
.PatrolGroupSetting{
.el-radio.is-bordered.is-checked {
    border-color: #027969;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #027969;
}

.el-radio__input.is-checked .el-radio__inner {
    border-color: #027969;
    background: #027969;
}

.el-radio__inner:hover {
    border-color: #027969;
    background-color: #027969;
}

.el-radio.is-bordered {
    border: 1px solid #566e84;
    
}

.el-radio__inner {
    border: 1px solid #dbe0e5;
    background-color: #0c375e;
    
}
.el-radio__inner::after {
    
    background-color: #027969;
   
}
.el-radio {
    color: #dbe0e5;
    font-weight: 500;
    cursor: pointer;
    margin-right: 3rem;
}
.el-radio__inner {
    border: 0.1rem solid #dbe0e5;
    border-radius: 100%;
    width: 0.6rem;
    height: 0.6rem;
    background-color: #dbe0e5;
    cursor: pointer;
    box-sizing: border-box;

}
.el-radio--mini.is-bordered .el-radio__inner {
    height: 0.6rem;
    width: 0.6rem;
}
.el-radio, .el-radio__input {
    line-height: 1.6rem;
    white-space: nowrap;
    outline: 0;
}

.PatrolGroupSetting .el-radio__inner {
    border-color: #027969;
    background-color: #dbe0e5;
}
.PatrolGroupSetting .el-radio__inner:hover {
    border-color: #027969;
    background-color: #0c375e;
}
.el-radio--mini.is-bordered {
    padding: 0.6rem 1.5rem 0 1rem;
    border-radius: 0.3rem;
    height: 2.8rem;
    width: 9rem;
    margin-top: 0.6rem;
}
.el-radio--mini.is-bordered .el-radio__label, .el-radio--small.is-bordered .el-radio__label {
    font-size: 1.2rem;
}

.el-radio__label {
    // font-size: 1.4rem;
    padding-left: 1rem;
}

.PatrolGroupSetting .el-radio, .PatrolGroupSetting .el-radio__input {
    line-height: 1.6rem;
    white-space: nowrap;
    outline: 0;
}
.el-radio, .el-radio__inner, .el-radio__input {
    position: absolute;
    display: inline-block;
    top: 0.55rem;
    left: 0.4rem;
}
.PatrolGroupSetting .el-radio__label {
    padding-left: 1.6rem;
    
}
.PatrolGroupSetting .el-radio, .PatrolGroupSetting .el-radio__input {
    line-height: 1.3rem;
    white-space: nowrap;
    outline: 0;
}

.PatrolGroupSetting .el-radio--mini.is-bordered .el-radio__label, .PatrolGroupSetting .el-radio--small.is-bordered .el-radio__label {
    font-size: 1.2rem;
    position: absolute;
    top: 0.5rem;
    left: 1.5rem;
}
.PatrolGroupSetting .el-radio, .PatrolGroupSetting .el-radio__input {
    line-height: 1.3rem;
    white-space: nowrap;
    outline: 0;
    font-size: 1.2rem;
    position: absolute;
    top: 0.5rem;
    left: 1.5rem;
}
.el-radio__label{
   font-size: 1.2rem;
    position: absolute !important;
    top: 0.5rem !important;
    left: 1rem !important;
}

}
</style>