<template>
  <div>
    <div class="ener-bottom">
      <div class="enersta">
        <div class="fire_control">
          <div class="consumption">
            <span class="title">本月区域总用电</span>
            <div class="conlist1">
              <p
                style="width:12rem;height:2rem;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="toolTip"
                  placement="top"
                >
                  <span>{{ dataForm.name }}</span>
                </el-tooltip>
              </p>
              <h4>
                {{ dataForm.value }}
                <span>kWh</span>
              </h4>
            </div>
            <div class="conlist2">
              <img
                class="conleftimg"
                src="@/assets/energyStatistical/con-bg-left.png"
                alt
              />
              <img
                class="conlefticon"
                src="@/assets/energyStatistical/con-bg-icon.png"
                alt
              />
              <div class="list1-con">
                <p>环比</p>
                <span>{{ dataForm.huanbiValue }}</span>
              </div>
              <div class="con-border"></div>
              <div class="list2-con">
                <span
                  :class="
                    dataForm.huanbiRatio == 0 ||
                    dataForm.huanbiRatio == '0.00%' ||
                    dataForm.huanbiRatio == '-'
                      ? 'ratioNone'
                      : parseFloat(dataForm.huanbiRatio) > 0
                      ? 'ratioRise'
                      : 'ratioDown'
                  "
                >
                  {{
                    dataForm.huanbiRatio == 0 || dataForm.huanbiRatio == "0.00%"
                      ? "-"
                      : dataForm.huanbiRatio
                  }}<span
                    v-if="dataForm.huanbiRatio > 0 || dataForm.huanbiRatio < 0"
                    >%</span
                  >
                  <img
                    v-if="
                      dataForm.huanbiRatio == 0 ||
                        dataForm.huanbiRatio == '0.00%' ||
                        dataForm.huanbiRatio == '-'
                    "
                    src=""
                    alt=""
                  />
                  <img
                    v-if="parseFloat(dataForm.huanbiRatio) > 0"
                    src="@/assets/energyStatistical/rise.png"
                    alt
                  />
                  <img
                    v-if="parseFloat(dataForm.huanbiRatio) < 0"
                    src="@/assets/energyStatistical/down.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
            <div class="conlist3">
              <img
                class="conleftimg"
                src="@/assets/energyStatistical/con-bg-left1.png"
                alt
              />
              <img
                class="conlefticon"
                src="@/assets/energyStatistical/con-bg-icon1.png"
                alt
              />
              <div class="list1-con">
                <p>同比</p>
                <span>{{ dataForm.tongbiValue }}</span>
              </div>
              <div class="con-border"></div>
              <div class="list2-con">
                <span
                  :class="
                    dataForm.tongbiRatio == 0 ||
                    dataForm.tongbiRatio == '0.00%' ||
                    dataForm.tongbiRatio == '-'
                      ? 'ratioNone'
                      : parseFloat(dataForm.tongbiRatio) > 0
                      ? 'ratioRise'
                      : 'ratioDown'
                  "
                >
                  {{
                    dataForm.tongbiRatio == 0 || dataForm.tongbiRatio == "0.00%"
                      ? "-"
                      : dataForm.tongbiRatio
                  }}<span
                    v-if="dataForm.tongbiRatio > 0 || dataForm.tongbiRatio < 0"
                    >%</span
                  >
                  <img
                    v-if="
                      dataForm.tongbiRatio == 0 ||
                        dataForm.tongbiRatio == '0.00%' ||
                        dataForm.tongbiRatio == '-'
                    "
                    src=""
                    alt=""
                  />
                  <img
                    v-if="parseFloat(dataForm.tongbiRatio) > 0"
                    src="@/assets/energyStatistical/rise.png"
                    alt
                  />
                  <img
                    v-if="parseFloat(dataForm.tongbiRatio) < 0"
                    src="@/assets/energyStatistical/down.png"
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>

          <div class="consumption1">
            <EnErEcharts :name="customerName" />
          </div>
          <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EnErEcharts from "../EnergyStatistical/enerEcharts";
export default {
  name: "AreaElectric",
  components: {
    EnErEcharts,
  },
  data() {
    return {
      flagImg: false,
      dataForm: {
        customerId: "",
        //环比率
        huanbiRatio: "",
        //环比值
        huanbiValue: "",
        //名称
        name: "",
        //同比比率
        tongbiRatio: "",
        //同比值
        tongbiValue: "",
        //当前值
        value: "0",
      },
      customerName: "",
      toolTip: "",
    };
  },
  mounted() {},
  methods: {
    queryMothData() {
      this.dataForm.customerId = this.$store.state.storage.customerId;
      this.$store
        .dispatch("querySumMouth", this.dataForm.customerId)
        .then(() => {
          if (this.$store.state.elecManagement.querySumMouth.errorCode == 0) {
            this.dataForm = this.getGeneralSetting;
            if (this.dataForm.name !== null) {
              this.toolTip = this.dataForm.name.toString();
            }
            // console.log(this.getGeneralSetting);
            let huanbi = this.dataForm.huanbiRatio;
            let tongbi = this.dataForm.tongbiRatio;
            if (huanbi == null || huanbi == "-") {
              huanbi = "-";
              huanbi = huanbi;
            } else {
              this.dataForm.huanbiRatio = Number(huanbi * 100).toFixed(2);
              this.flagImg = true;
            }
            if (tongbi == null || tongbi == "-") {
              tongbi = "-";
              tongbi = tongbi;
            } else {
              this.dataForm.tongbiRatio = Number(tongbi * 100).toFixed(2);
              this.flagImg = true;
            }
          } else {
            this.dataForm = {
              huanbiRatio: "0",
              huanbiValue: 0,
              name: "",
              tongbiRatio: "0",
              tongbiValue: 0,
              value: 0,
            };
            this.toolTip = this.dataForm.name.toString();
          }
        });
    },
  },
  computed: {
    ...mapState({
      getGeneralSetting: (state) => {
        return state.elecManagement.querySumMouth.data;
      },
    }),
  },
  watch: {
    "$store.state.storage.customerData": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        this.dataForm.customerId = newVal.id;
        this.customerName = newVal.name;
        this.queryMothData();
      },
    },
  },
};
</script>
<style lang="less" scoped>
.enersta {
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  width: calc(100% + 0.6rem);
  height: calc(100vh - 21.2rem);
}
.fire_control {
  .consumption {
    // width: 37rem;
    width: 26%;
    height: 42rem;
    background: #0c2f4f;
    position: relative;
    border-radius: 0.4rem;
    .title {
      position: relative;
      top: 2rem;
      margin-left: 2.4rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #37a5dd;
    }
    .conlist1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 32.2rem;
      height: 10rem;
      margin-left: 2.4rem;
      margin-top: 3.2rem;
      background: url(../../../assets/energyStatistical/con-img-bg.png)
        no-repeat center;
      background-size: 31.2rem 10rem;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #fffffe;
        float: left;
        margin-bottom: 1rem;
        // margin-top: 3.9rem;
        // margin-left: 2.4rem;
      }
      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 2.4rem;
        line-height: 3.4rem;
        color: #fbfbfb;
        float: right;
        // margin-top: 3.3rem;
        // margin-right: 3.7rem;
        span {
          font-style: normal;
          font-weight: 300;
          font-size: 1.2rem;
          line-height: 1.7rem;

          color: #fffffe;
        }
      }
    }
    .conlist2 {
      width: 31.2rem;
      height: 10rem;
      margin-left: 3rem;
      margin-top: 1.7rem;
      position: relative;
      background: url(../../../assets/energyStatistical/con-img-bg1.png)
        no-repeat;
      background-size: 31.2rem 10rem;
      .conleftimg {
        float: left;
        height: 10rem;
      }
      .conlefticon {
        position: relative;
        top: 3.3rem;
        left: 1.5rem;
      }
      .list1-con {
        position: relative;
        left: 6.1rem;
        top: -1.3rem;
        p {
          font-size: 1.4rem;
          line-height: 2rem;
          color: #37a5dd;
        }
        span {
          font-size: 2rem;
          line-height: 2.8rem;
          color: #fffffe;
        }
      }
      .con-border {
        width: 0.1rem;
        height: 3.9rem;
        background-color: #1f4a72;
        position: absolute;
        left: 17rem;
        top: 3rem;
      }
      .list2-con {
        font-size: 2rem;
        line-height: 2.8rem;
        color: #ffa82c;
        position: absolute;
        left: 17rem;
        top: 3.5rem;
        padding-left: 3rem;
        .ratioRise {
          color: #ffa82c;
        }
        .ratioDown {
          color: rgba(68, 241, 182, 1);
        }
        .ratioNone {
          color: #fff;
        }
        img {
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          right: -1.3rem;
          top: 1rem;
        }
      }
    }
    .conlist3 {
      width: 31.2rem;
      height: 10rem;
      margin-left: 3rem;
      margin-top: 1.7rem;
      position: relative;
      background: url(../../../assets/energyStatistical/con-img-bg1.png)
        no-repeat;
      background-size: 31.2rem 10rem;
      .conleftimg {
        float: left;
        height: 10rem;
      }
      .conlefticon {
        position: relative;
        top: 3.3rem;
        left: 1.5rem;
      }
      .list1-con {
        position: relative;
        left: 6.1rem;
        top: -1.3rem;
        p {
          font-size: 1.4rem;
          line-height: 2rem;
          color: #37a5dd;
        }
        span {
          font-size: 2rem;
          line-height: 2.8rem;
          color: #fffffe;
        }
      }
      .con-border {
        width: 0.1rem;
        height: 3.9rem;
        background-color: #1f4a72;
        position: absolute;
        left: 17rem;
        top: 3rem;
      }
      .list2-con {
        font-size: 2rem;
        line-height: 2.8rem;
        color: #ffa82c;
        position: absolute;
        left: 17rem;
        top: 3.5rem;
        padding-left: 3rem;
        .ratioRise {
          color: #ffa82c;
        }
        .ratioDown {
          color: rgba(68, 241, 182, 1);
        }
        .ratioNone {
          color: #fff;
        }
        img {
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          right: -1.3rem;
          top: 1rem;
        }
      }
    }
    .conlist1,
    .conlist2,
    .conlist3 {
      background-size: 100%;
      width: 85%;
      margin: 2.4rem auto 0 auto;
    }
    .conlist2,
    .conlist3 {
      margin: 1.7rem auto 0 auto;
    }
  }
  .consumption1 {
    // width: 104.4rem;
    // width: 73%;
    // height: 42rem;
    // background: #0c2f4f;
    // position: absolute;
    // right: 0;
    // top: 0;
    background: #0c2f4f;
    position: relative;
  }
  .blank {
    position: relative;
    top: 40rem;
    height: 20rem;
    width: 142.8rem;
    color: grey;
    font-size: 1.4rem;
    text-align: center;
    line-height: 10rem;
  }
}

.ener-bottom {
  overflow: hidden;
  // width: 142.8rem;
  width: 100%;
  height: 88.8rem;
  margin-top: 1.5rem;
}
</style>
<style></style>
