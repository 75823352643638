<template>
  <div class="table-list">
    <div class="table">
      <el-table
        :data="tableData"
        stripe:false
        height="52rem"
        border:false
        :header-cell-style="headerClass"
        :cell-style="cellStyle"
      >
        <el-table-column prop="name" label="电站名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="capacity" label="组串容量(kW)" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="inverterCount" label="逆变器数量" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="enterDate" label="并网时间" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="currentCapacity" label="实时发电功率(kW)" :show-overflow-tooltip="true"></el-table-column>
      </el-table>
    </div>
    <!-- 分页器部分 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="customerTotalNum"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg";

export default {
  name: "overList",
  mixins: [tableBg],
  data() {
    return {
      tableData: [],
      // 分页器数据,当前页面
      currentPage: 1,
      // 客户总个数
      customerTotalNum: 0,
      // 每页设备数量
      pageSize: 10,
      customerId: "",
    };
  },

  methods: {
    //发送请求#####分页器改变条数/改变页面
    handleSizeChange(val) {
      this.getMyData(this.customerId, this.pageNum, this.pageSize);
    },
    handleCurrentChange(val) {
      this.getMyData(this.customerId, val, this.pageSize);
    },
    //发送请求##### 获取分页数据
    getMyData(customerId, pageNum, pageSize) {
      this.$store
        .dispatch("getGenList", {
          customerId: customerId,
          pageNum: pageNum,
          pageSize: pageSize,
        })
        .then(() => {
          let result = this.$store.state.genManagement.getGenList.data;
          this.customerTotalNum = result.total;
          this.tableData = result.records;
          // console.log(this.$store.state.genManagement.getGenList);
          // 进行数据遍历渲染

          // this.tableData = [];
          // this.customerPageData.records.forEach((item) => {
          //   const newCustomer = {};
          //   newCustomer.num = item.code;
          //   newCustomer.name = item.name;
          //   newCustomer.parentName = item.parentName;
          //   newCustomer.industry = item.industryName;
          //   newCustomer.address = item.address;
          //   newCustomer.manager = item.manager;
          //   newCustomer.managerPhone = item.managerPhone;
          //   newCustomer.urgentContact = item.urgentContact;
          //   newCustomer.urgentContactPhone = item.urgentContactPhone;
          //   this.tableData.push(newCustomer);
          // });
          // // 对分页器进行渲染
          //
        });
    },
  },
  watch: {
    "$store.state.storage.customerId": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        this.customerId = newVal;
        this.getMyData(this.customerId, 1, 10);
        // console.log(this.$store.state.storage.customerId);
      },
    },
  },
  mounted() {
    this.customerId = this.$store.state.storage.customerId;
    this.getMyData(this.customerId, this.currentPage, this.pageSize);
    // this.getMyData(this.currentPage, this.pageSize, this.searchValue, this.businessType, this.treeNode);
  },
};
</script>

<style lang="less" scoped>
.table-list {
  margin-top: 2rem;
  .table {
    height: 54.2rem;
    width: 100% !important;
    margin-left: 0;
    // margin: 0 2.4rem 0 0;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
  .pagination {
    float: right;
    margin-right: 5rem;
    margin-top: 1rem;
  }
  .el-radio__label {
    font-size: 1.4rem;
    padding-left: 0.5rem;
  }
  .el-radio {
    margin-right: 1rem;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #03d9bc;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #03d9bc;
    background: #0d3050;
  }

  .el-radio__inner {
    background-color: #0d3050;
  }

  .el-radio__inner::after {
    background-color: #03d9bc;
  }

  // 计数器样式
  .el-input-number__increase {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease {
    background-color: #0b3d6b;
  }
  .el-input-number .is-controls-right {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #0b3d6b;
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid #027969;
    border-left: 0.1rem solid #027969;
    box-shadow: none;
    border: 0.1rem solid #027969;
    box-sizing: border-box;
    border: none;
  }
  ::-webkit-scrollbar,
  .el-select-dropdown::-webkit-scrollbar {
    width: 0;
  }
  .el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 0.4rem 0 0;
    border-bottom: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }

  .el-input-number.is-controls-right .el-input-number__decrease {
    border-left: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }
  .el-table tr {
    background: rgba(0, 115, 222, 0.1);
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #0c2f4f;
  }
  .el-table .el-table__cell {
    padding: 1rem 0;
  }
}
</style>
