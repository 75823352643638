import { render, staticRenderFns } from "./powerManagement.vue?vue&type=template&id=6ba587ae&scoped=true&"
import script from "./powerManagement.vue?vue&type=script&lang=js&"
export * from "./powerManagement.vue?vue&type=script&lang=js&"
import style0 from "./powerManagement.vue?vue&type=style&index=0&id=6ba587ae&lang=less&scoped=true&"
import style1 from "./powerManagement.vue?vue&type=style&index=1&id=6ba587ae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba587ae",
  null
  
)

export default component.exports