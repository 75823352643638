<template>
  <div ref="rightDrawer">
    <el-drawer class="drawer dialogBgc"
               size="23%"
               :visible.sync="show"
               :showClose="false"
               :append-to-body="true"
               :modal-append-to-body="false"
               :modal="false"
               style="height: 84%; top: 16%;"
    >
      <el-tabs id="drawer-tab" v-model="activeName">
        <el-tab-pane label="属性" name="attribute">
          <el-form ref="form" v-if="items"
                   label-width="100px" size="small"
                   label-position="left"
                   :data="items"
          >
            <el-form-item label="名称:" prop="title">
              <el-input v-model="items.title"
                        placeholder="请输入名称"
                        @input="changeInput"
              />
            </el-form-item>
            <el-form-item label="X轴坐标:" prop="x">
              <el-input-number v-model="items.x"
                               @input="changeInput"
              />
            </el-form-item>
            <el-form-item label="Y轴坐标:" prop="y">
              <el-input-number v-model="items.y"
                               size="small"
                               @input="changeInput"
              />
            </el-form-item>
            <el-form-item label="尺寸:" prop="height">
              <el-input-number v-model="items.height"
                               :min="0"
                               @input="changeInput"
              />
            </el-form-item>
            <el-form-item v-if="items.type == 'SVG_FONT'"
                          label="宽度:"
                          prop="width"
            >
              <el-input-number v-model="items.width"
                               :min="0"
                               @input="changeInput"
              />
            </el-form-item>
            <el-form-item v-if="items.type == 'SVG_FONT' ||
                               (items.type == 'SVG_TABLE' && items.template == '1_table') ||
                                items.type == 'SVG_KEYVLAUE'"
                          label="文字大小:"
                          prop="fontSize"
            >
              <el-input-number v-model="items.fontSize"
                               :min="12"
                               :max="40"
                               @input="changeInput"
              />
            </el-form-item>
            <el-form-item v-if="(items.type == 'SVG_TABLE' && items.template == '1_table') ||
                                 items.type == 'SVG_FONT' ||
                                 items.type == 'SVG_KEYVLAUE'"
                          label="文字字体:"
                          prop="fontFamily"
            >
              <el-select v-model="items.fontFamily"
                         @change="changeSvg"
                         popper-class="selectBgc"
              >
                <el-option v-for="fontFamilyOption in fontFamily"
                           :key="fontFamilyOption.id"
                           :label="fontFamilyOption.text"
                           :value="fontFamilyOption.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-if="items.type == 'SVG_FONT' ||
                               (items.type == 'SVG_TABLE' && items.template == '1_table') ||
                                items.type == 'SVG_KEYVLAUE'"
                          label="文字宽度:"
                          value="400"
                          prop="fontWeight"
            >
              <el-select v-model="items.fontWeight"
                         @change="changeSvg"
                         popper-class="selectBgc"
              >
                <el-option v-for="fontWeightOption in fontWeightOptions"
                           :key="fontWeightOption.id"
                           :label="fontWeightOption.text"
                           :value="fontWeightOption.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="items.type == 'SVG_FONT'"
                          label="颜色:"
                          prop="color"
            >
              <el-color-picker v-model="items.color"
                               @change="changeSvgColor"
                               show-alpha
                               :predefine="predefineColors"
              ></el-color-picker>
            </el-form-item>
            <el-form-item label="旋转:">
              <el-input-number v-model="items.rotate"
                               :min="0"
                               @input="changeInput"
              ></el-input-number>
            </el-form-item>
            <el-form-item v-if="items.type == 'SVG_HTML' ||
                                items.type == 'SVG_TAG' ||
                                items.type == 'SVG_KEYVLAUE'"
                          label="颜色:"
                          prop="color"
            >
              <el-color-picker v-model="items.color"
                               @change="changeSvgColor"
                               show-alpha
                               :predefine="predefineColors"
              ></el-color-picker>
            </el-form-item>
            <el-form-item v-if="items.type == 'SVG_HTML' || items.type == 'SVG_TAG'"
                          label="线宽:"
                          prop="strokeWidth"
            >
              <el-input-number v-model="items.strokeWidth" :min="1" @input="changeInput"/>
            </el-form-item>
            <template v-if="items.template.indexOf('1_xl') != -1">
              <el-form-item label="虚线长度:"  >
                <el-input v-model="items.strokeDasharray" value="0" @input="changeInput"/>
              </el-form-item>
              <el-form-item label="虚线样式:">
                <el-select v-model="items.strokeLinecap" value="block">
                  <el-option label="圆角" value="round"></el-option>
                  <el-option label="直角" value="square"></el-option>
                </el-select>
              </el-form-item>
            </template>
            <el-form-item v-if="items.type == 'SVG_FONT'"
                          label="文字内容:"
                          prop="content"
            >
              <el-input v-model="items.content"
                        type="text"
                        size="small"
                        @input="changeInput"
              />
            </el-form-item>
            <el-form-item v-if="items.type == 'SVG_KEYVLAUE'"
                          label="数据项:"
                          prop="keys"
            >
              <el-input v-model="items.keys" type="text" size="small" />
            </el-form-item>
            <el-form-item v-if="items.type == 'SVG_KEYVLAUE'"
                          label="数据值:"
                          prop="values"
            >
              <el-input v-model="items.values" type="text" size="small" @input="changeInput"/>
            </el-form-item>
            <el-form-item label="表格行数:"
                          v-if="items.type == 'SVG_TABLE' && items.template == '1_table'"
            >
              <el-input-number v-model="items.rows"
                               :min="1"
                               :max="500"
                               size="small"
                               @input="changeInput"
              />
            </el-form-item>
            <el-form-item label="表格列数:"
                          v-if="items.type == 'SVG_TABLE' && items.template == '1_table'"
            >
              <el-input-number v-model="items.cols"
                               size="small"
                               :min="1"
                               :max="500"
                               @input="changeInput"
              />
            </el-form-item>
            <el-form-item label="类型:" hidden
                          prop="type"
            >
              <el-input v-model="items.type"
                        type="text"
                        size="small"
                        @change="changeSvg"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="数据" name="data">
          <el-form ref="form"
                   label-width="100px"
                   size="small"
                   label-position="left"
                   :data="dataStatus"
          >
            <el-form-item label="图元:" prop="title" >
              <el-input v-model="dataStatus.title" readonly/>
            </el-form-item>
            <el-form-item label="绑定设备:" prop="equipment">
              <div class="with-btn">
                <el-input size="small" v-model="dataStatus.equipment" placeholder="点击右侧按钮去绑定"/>
                <el-button type="primary" size="small" @click="goBindDevice(0)">绑定</el-button>
              </div>
            </el-form-item>
            <el-form-item label="绑定测点:" prop="moudle" >
              <div class="with-btn">
                <el-input size="small" v-model="dataStatus.moudle" placeholder="点击右侧按钮去绑定"/>
                <el-button type="primary" size="small" @click="goBindPoint(0)">绑定</el-button>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div class="cfrm-cncl-btns">
        <el-button class="cancelBtn" @click="closeRightDraw">取消 </el-button>
        <el-button class="sureBtn" @click="sureSubmit">确认 </el-button>
      </div>
    </el-drawer>

    <!-- 绑定设备dialog -->
    <el-dialog v-dialogDrag
               id="dialog-bind-device"
               class="dialogBgc"
               :close-on-click-modal="false"
               title="绑定设备"
               :visible.sync="openBind"
               width="60%"
               style="overflow: scroll"
    >
      <div class="left-box">
        <el-tree id="customer-tree"
                 node-key="id"
                 :data="customerTreePeiDian"
                 :props="defaultProps"
                 :default-expand-all="true"
                 @node-click="clickCustomer"
        />
      </div>
      <div class="right-box">
        <el-tabs v-model="activeNameTable" @tab-click="handleClick">
          <el-tab-pane label="电表列表" name="electricMeter">
            <el-table :data="tableDataDevice"
                      max-height="460px"
                      :header-cell-style="{
                        background: 'rgb(19, 63, 104)',
                        color: '#fff',
                        fontFamily: 'PingFang SC',
                        fontStyle: 'normal',
                        fontWeight: '600',
                      }"
            >
              <el-table-column label="名称" align="center" prop="value" />
              <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" plain @click="bindDevice(scope.row)">绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="仪表列表" name="instrument">
            <!-- note：这个地方产品设计不对，不可能有数据的 -->
            <el-table :data="instrumentTableData"
                      :header-cell-style="{
                        background: 'rgb(19, 63, 104)',
                        color: '#fff',
                        fontFamily: 'PingFang SC',
                        fontStyle: 'normal',
                        fontWeight: '600',
                      }"
            >
              <el-table-column label="名称" align="center" prop="value" />
              <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" plain style="
                      margin: 2px 0 0 0;
                      height: 32px;
                      border-radius: 0;
                      background: #027969;
                      border: none;
                    " @click="bindDevice(scope.row)">绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

    <!-- 绑定测点dialog -->
    <el-dialog v-dialogDrag
               id="dialog-bind-point"
               title="绑定测点"
               :close-on-click-modal="false"
               :visible.sync="openBindPoint"
               width="60%"
    >
      <div class="left-box">
        <el-tree ref="treeRef"
                 :data="meterList"
                 node-key="id"
                 :props="defaultProps"
                 :highlight-current="true"
                 @node-click="clickMeter"
        />
      </div>
      <div class="right-box">
        <div class="search-container">
          <el-input v-model="searchName"
                    placeholder="输入测点名称搜索"
                    clearable
                    @keyup.enter.native="handleQuery"
                    @clear="clearSearchName"
          />
          <el-button type="primary" plain @click="handleQuery" class="btnadd">查询</el-button
          >
        </div>
        <el-table :data="tableDataPoint"
                  max-height="460px"
                  :header-cell-style="{
                      background: 'rgb(19, 63, 104)',
                      color: '#fff',
                      fontFamily: 'PingFang SC',
                      fontStyle: 'normal',
                      fontWeight: '600',
                    }"
        >
          <el-table-column label="序号" align="center" prop="num" />
          <el-table-column label="通道" align="center" prop="channelNumber" />
          <el-table-column label="名称" align="center" prop="name" />
          <el-table-column label="点名" align="center" prop="iedDescription"/>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button type="primary" size="small" plain @click="bindPoint(scope.row)" style="
                    margin: 2px 0 0 0;
                    height: 32px;
                    border-radius: 0;
                    background: #027969;
                    border: none;
                  ">绑定</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from "../../utils/request";

export default {
  name: "RightDrawer1",
  props: ["items"],
  watch: {
    show: function (newVal, oldVal) {
      this.activeName = "attribute";
      this.dataStatus = {};
      this.dataControl = {};
      this.colorSetting();
    },
    items: function (newVal, oldVal) {
      this.dataStatus = {};
      this.dataControl = {};
      this.dataStatus.title = this._props.items.title;
      this.dataStatus.equipment = newVal.dataStatus ? newVal.dataStatus.equipment : "";
      this.dataStatus.moudle = newVal.dataStatus ? newVal.dataStatus.moudle : "";
      this.dataStatus.equipmentId = newVal.dataStatus ? newVal.dataStatus.equipmentId : "";
      this.dataStatus.moudleId = newVal.dataStatus ? newVal.dataStatus.moudleId : "";
      this.dataStatus.pointId = newVal.dataStatus ? newVal.dataStatus.pointId : "";
      this.dataStatus.type = newVal.dataStatus ? newVal.dataStatus.type : 1;
    },
  },
  data() {
    return {
      show: false,
      activeName: "attribute",
      activeNameTable: "electricMeter",
      color1: "#409EFF",
      openBind: false,
      openBindPoint: false,
      searchName: '',
      dataStatus: {
        title: null,
        type: 1,
      },
      // 控制点的数据
      dataControl: {
        title: null,
      },

      customerTreePeiDian: [],
      tableDataDevice: [],
      tableDataPoint: [],
      instrumentTableData: [],
      defaultProps: {
        children: "children",
        label: "name",
        id: "id",
      },
      meterList: [],
      fontWeightOptions: [
        { id: "normal", text: "标准" },
        { id: "bold", text: "粗体" },
        { id: "bolder", text: "更粗" },
        { id: "lighter", text: "更细" },
        { id: "100", text: "100" },
        { id: "200", text: "200" },
        { id: "300", text: "300" },
        { id: "400", text: "400" },
        { id: "500", text: "500" },
        { id: "600", text: "600" },
        { id: "700", text: "700" },
        { id: "800", text: "800" },
        { id: "900", text: "900" },
      ],
      fontFamily: [
        { id: "SimSun", text: "宋体" },
        { id: "SimHei", text: "黑体" },
        { id: "FangSong_GB2312", text: "仿宋_GB2312" },
        { id: "KaiTi_GB2312", text: "楷体_GB2312" },
        { id: "YouYuan", text: "幼圆" },
        { id: "Microsoft YaHei", text: "微软雅黑" },
        { id: "STSong", text: "华文宋体" },
        { id: "STZhongsong", text: "华文中宋" },
        { id: "STKaiti", text: "华文楷体" },
        { id: "STFangsong", text: "华文仿宋" },
        { id: "STXihei", text: "华文细黑" },
        { id: "STLiti", text: "华文隶书" },
        { id: "STXingkai", text: "华文行楷" },
        { id: "STXinwei", text: "华文新魏" },
        { id: "STHupo", text: "华文琥珀" },
        { id: "STCaiyun", text: "华文彩云" },
        { id: "FZYaoTi", text: "方正姚体简体" },
        { id: "FZShuTi", text: "方正舒体简体" },
        { id: "LiSu", text: "隶书" },
        { id: "Helvetica", text: "Helvetica" },
        { id: "Serif", text: "Serif" },
        { id: "lcddregular", text: "LcdD Regular" },
      ],
      predefineColors: [
        "rgba(255, 69, 0, 1)",
        "rgba(255, 140, 0, 1)",
        "rgba(255, 215, 0, 1)",
        "rgba(144, 238, 144, 1)",
        "rgba(0, 206, 209, 1)",
        "rgba(30, 144, 255, 1)",
        "rgba(199, 21, 133, 1)",
        "rgba(255, 69, 0, 0.68)",
        "rgba(255, 120, 0, 1)",
        "rgba(250, 212, 0, 1)",
      ],
    };
  },
  methods: {
    colorSetting() {
      let _this = this;
      if (JSON.parse(localStorage.getItem("predefineColorsArr"))) {
        const predefineColorsArrLS = JSON.parse(
          localStorage.getItem("predefineColorsArr")
        );
        // _this.predefineColors=  JSON.parse(localStorage.getItem("predefineColorsArr"));//原逻辑为 自选颜色最多为10
        //现逻辑 要求 默认颜色显示一行（10） 自选颜色显示一行（最大为10）
        let thisC = _this.predefineColors.splice(0, 10);
        _this.predefineColors = Array.from(
          new Set([...thisC, ...predefineColorsArrLS])
        );
      }
    },
    changeInput(e) {
      this.$forceUpdate();
      this.items.rotate = this.items.rotate % 360;
      this.changeSvg();
    },
    changeSvg() {
      this.$forceUpdate();
      console.log(this.items);
      this.$emit("changSvg", this.items);
      // this.$emit(
      //   "setTableAttr",
      //   this.items.id,
      //   this.items.rows,
      //   this.items.cols
      // );
    },
    //绑定设备
    goBindDevice(index) {
      // todo：默认选中第一个
      this.openBind = true;
    },
    //绑定测点
    goBindPoint() {
      // 根据设备查电表
      let deviceId = this.dataStatus.equipmentId;
      request.get(`/device/meter/${deviceId}`).then(res => {
        if(res.data){
          this.meterList = res.data;
          this.$refs.treeRef.setCurrentKey(this.meterList[0].id);
          this.currentNodeName = this.meterList[0].name;
          this.clickMeter({
            id: this.meterList.length ? this.meterList[0].id : "",
          });
        }
      });
      this.openBindPoint = true;
    },
    clickCustomer(node) {
      //根据客户获取设备列表
      request.get(`/device/bycustomer/${node.id}`).then(res => {
        if(res.data){
          this.tableDataDevice = res.data;
        }
      })
    },
    clickMeter(node) {
      // 根据电表获取电参量列表
      this.dataStatus.pointId = node.id;
      this.currentNodeName = node.name;
      request.get(`/point/all/${node.id}?keyword=${this.searchName}`).then(res => {
        if(res.data){
          this.tableDataPoint = res.data;
        }
      });
    },
    bindDevice(row) {
      this.$confirm("是否确认绑定", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dataStatus.equipment = row.value;
          this.dataStatus.equipmentId = row.label;
          this.$emit("transmitDataObj", this.dataStatus, false);
          // 清除绑定过的测点信息
          this.clearPointBind();
          this.openBind = false;
        })
        .catch(() => {});
    },
    bindPoint(row) {
      this.$confirm("是否确认绑定", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dataStatus.moudle = this.currentNodeName + ">" + row.name;
          this.dataStatus.moudleId = row.id;
          this.$emit("transmitDataObj", this.dataStatus, false);
          this.openBindPoint = false;
        })
        .catch(() => {});
    },
    handleQuery() {
      request.get(`/point/all/${this.dataStatus.pointId}?keyword=${this.searchName}`).then(res => {
        if(res.data){
          this.tableDataPoint = res.data;
        }
      });
    },
    clearSearchName(){
      this.searchName = '';
      this.handleQuery();
    },
    clearPointBind(){
      this.dataStatus.moudle = '';
      this.dataStatus.moudleId = '';
      this.$emit("transmitDataObj", this.dataStatus, false);
    },
    handleClick() {
      if (this.activeNameTable == "electricMeter") {
        this.dataStatus.type = 1;
      } else {
        this.dataStatus.type = 2;
      }
    },
    // 确认提交
    sureSubmit() {
      this.$emit("transmitDataObj", this.dataStatus, true);
      this.show = false;
    },
    //颜色更改
    changeSvgColor(preColor) {
      this.changeSvg();
      this.$nextTick(() => {
        if (!preColor) {
          return false;
        }
        //当前选择存在于默认和自选颜色中不操作
        if (this.predefineColors.includes(preColor)) {
          return false;
        }
        //默认颜色+自选颜色为最大数为20
        if (this.predefineColors.length >= 20) {
          // this.predefineColors.shift()
          this.predefineColors.splice(10, 1);
        }
        this.predefineColors.push(preColor);
        const setLSPredefineColorsArr = this.predefineColors.slice(10);
        localStorage.setItem(
          "predefineColorsArr",
          JSON.stringify(setLSPredefineColorsArr)
        );
      });
    },
    //取消事件
    closeRightDraw() {
      this.show = false;
    },
  },
  created() {
    this.colorSetting();
  },
  mounted() {
    setTimeout(() => {
      request.get(`/tree/customer/peidian`).then(res => {
        if(res.data){
          this.customerTreePeiDian = res.data;
        }
      })
    })
  },
};
</script>
<style lang="less" scoped>
#drawer-tab,.right-box{
  ::v-deep .el-tabs__nav{
    margin-left: 2rem;

    .el-tabs__item{
      color: white;
      font-size: 1.5rem;
    }
    .el-tabs__item.is-top.is-active{
      color: #03d4b9 !important;
      font-weight: bold;
    }
    .el-tabs__active-bar.is-top{
      background-color: #03d4b9 !important;
    }
  }
  ::v-deep form.el-form{
    .el-form-item{
      margin: 2rem 0;
      padding-left: 2rem;
      padding-right: 2rem;
      label.el-form-item__label{
        font-size: 16px;
        color: #f9fafb;
      }
      div.el-form-item__content{
        .el-input-number,.el-select{
          width: 100%;
        }
        .el-input-number__decrease,.el-input-number__increase{
          border: none;
          background-color: #0a3d6c;
        }
        .el-input.is-disabled .el-input__inner{
          background-color: #0c375e;
          border: 1px solid #004579;
        }
      }
      div.el-color-picker__trigger{
        border: none;
        .el-color-picker__color{
          border: none;
        }
      }

      .with-btn{
        display: flex;
        input::placeholder{
          color: gray;
        }
        button{
          background-color: #027969;
        }
      }
    }
  }
}
.drawer .cfrm-cncl-btns{
  position: fixed;
  bottom: 5%;
  right: 6%;

  button{
    background-color: #0b3d6b;
    border: none;
    color: #f9fafb;
    margin: 0 1rem;
  }
  button.sureBtn{
    background-color: #027969;
  }
}

::v-deep #dialog-bind-device,
::v-deep #dialog-bind-point{
  .el-dialog__header{
    height: 5.4rem;
    line-height: 5.4rem;
    padding-left: 2.4rem;
    font-size: 1.6rem;
    background: linear-gradient(225deg, rgba(15, 36, 55, 0.0001) 0.03%, rgba(0, 115, 222, 0.7) 99.9%);
    .el-dialog__title{
      color: white;
    }
  }

  .el-dialog__body{
    display: flex;
    height: 520px;

    .left-box{
      width: 25%;
      padding-right: 2rem;
      .el-tree{
        background-color: #0c375e;
        color: white;
      }
    }
    .right-box{
      width: 75%;
      .el-table{
        overflow-y: scroll;
      }
      .el-table__row{
        background-color: #0c375e;
        color: #dbe1e7;
        &:hover{
          background-color: #0c375e;
        }
        button{
          background-color: #027969;
          border: none;
          color: white;
        }
      }
      .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
        background-color: #0c375e;
      }
      .search-container{
        display: flex;
        margin-bottom: 1rem;

        .el-input{
          width: 30rem;
          margin-right: 1rem;
          input::placeholder{
            color: gray;
          }
        }
        .el-button{
          background-color: #027969;
          border: none;
          color: white;
        }
      }
    }
  }
}

</style>
