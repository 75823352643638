<template>
  <div class="allPage">
    <div class="logo">
      <p>凯源电力数字化运营监测平台</p>
    </div>
    <img class="loginImg" :src="imgs.url1" alt="" />
    <div class="inputPlace">
      <div class="title">用户登录入口</div>
      <input
        v-model="input1"
        @keyup.enter="goAway"
        class="input1"
        type="text"
      />
      <input
        v-model="input2"
        @keyup.enter="goAway"
        class="input2"
        type="password"
      />
      <!-- 输入框内的假Placehoder -->
      <img class="placeHoder1" :src="imgs.url2" alt="" />
      <img class="placeHoder2" :src="imgs.url3" alt="" />
      <div class="placeHoder3">用户名</div>
      <div class="placeHoder4">密码</div>

      <button class="btn1" @click="goAway">登录</button>
    </div>
    <span class="link" @click="open"
      >鄂ICP备17021027号-2</span
    >
  </div>
</template>

<script>
import { setCookie } from '../../utils/util'
export default {
  name: 'Login',
  data() {
    return {
      input1: '',
      input2: '',
      input3: '',
      imgs: {
        url1: require('@/assets/login/loginImg.png'),
        url2: require('@/assets/login/login.png'),
        url3: require('@/assets/login/password.png'),
      },
    }
  },
  methods: {
    goAway() {
      // 发送绿葱葱请求
      // this.$store.dispatch('postLoginLv',this.input3)

      this.$store
        .dispatch('postLogin', {
          principal: this.input1,
          credentials: this.input2,
          identity: 'pc',
        })
        .then(async() => {
          // 验证成功保存token
          if (this.$store.state.login.tokenData.errorCode === 0) {
            setCookie(
              'token',
              this.$store.state.login.tokenData.data.access_token
            )
            setCookie('name', this.$store.state.login.tokenData.data.name)
            setCookie('id', this.$store.state.login.tokenData.data.id)
            //判断权限
            let who = this.$store.state.login.tokenData.data.authorities
            for (let i = 0; i < who.length; i++) {
              if (who[i].authority == 'sys:assigned') {
                setCookie('whoAmI', true)
              }
            }
            //传值给顶部组件
            this.$bus.$emit(
              'getNickName',
              this.$store.state.login.tokenData.data.name
            )
            // this.$bus.$emit('getMenuList')
            await this.$store.dispatch('getPersonalMenuList')
            await this.$store.dispatch('getPersonalData')
            this.$bus.$emit('refreshTheComponents')
            //    this.$bus.$emit('goToTheMenu',24)
            this.$router.push({ path: '/workBench' })
          } else {
            // 验证失败提示
            this.$message.error(this.$store.state.login.tokenData.message)
          }
        })
    },
    open() {
      window.open('https://beian.miit.gov.cn/')
    }
  },
}
</script>

<style lang="less" scoped>
.allPage {
  background-color: white;
  height: 150rem;
  width: 192rem;
  position: absolute;
  left: -18.3rem;
  top: -8.8rem;
  /* position: relative; */
  .logo {
    position: absolute;
    top: 43px;
    left: 101px;
    padding-left: 50px;
    background: url('../../assets/login/logo.png') left center no-repeat;
    background-size: 40px auto;
    > p {
      color: #fff;
      font-size: 23px;
    }
  }
  .loginImg {
    height: 108rem;
    width: 192rem;
  }
  .inputPlace {
    height: 50rem;
    width: 48rem;
    position: absolute;
    top: 26.5rem;
    left: 112.58rem;
    border-radius: 0.8rem;
    background-color: #0a3c69;
    .title {
      height: 2.8rem;
      width: 24rem;
      font-size: 2rem;
      color: #fff;
      line-height: 2.8rem;
      position: absolute;
      left: 4.22rem;
      top: 3.5rem;
    }
    input {
      height: 6rem;
      width: 40rem;
      background-color: #0f2437;
      border: none;
      outline: 0;
      color: #fff;
      font-size: 1.8rem;
      line-height: 6rem;
      border-radius: 0.3rem;
      text-indent: 9rem;
    }
    .input1 {
      position: absolute;
      top: 12.2rem;
      left: 4rem;
    }
    .input2 {
      position: absolute;
      top: 20.2rem;
      left: 4rem;
    }

    .placeHoder1 {
      position: absolute;
      top: 14.4rem;
      left: 5.53rem;
      height: 1.6rem;
      width: 1.44rem;
    }
    .placeHoder2 {
      position: absolute;
      top: 22.2rem;
      left: 5.53rem;
      height: 1.6rem;
      width: 1.44rem;
    }
    .placeHoder3 {
      height: 2.2rem;
      width: 9.6rem;
      position: absolute;
      top: 14.1rem;
      left: 7.42rem;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #fff;
    }
    .placeHoder4 {
      height: 2.2rem;
      width: 6.4rem;
      position: absolute;
      top: 21.9rem;
      left: 7.42rem;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #fff;
    }

    .btn1 {
      height: 6rem;
      width: 40rem;
      background-color: #027969;
      border-radius: 0.3rem;
      color: #fff;
      font-size: 2rem;
      border: none;
      position: absolute;
      top: 35.65rem;
      left: 4rem;
      cursor: pointer;
    }
  }
  input:-webkit-autofill {
    /* //  注意 如果设置后没变化在input前再加几个选择器   可能因为权重不够 */
    box-shadow: 0 0 0 1000px #0f2437 inset !important; /* // 背景色 */
    -webkit-text-fill-color: #fff !important; /* // 字体色*/
  }
  .link {
    position: absolute;
    bottom: 55rem;
    left: 50%;
    color: #e0e0e0;
    text-decoration: underline;
    cursor: pointer;
  }
}

/* // 验证码 */
.verificationCode {
  position: absolute;
  top: 28rem;
  left: 4rem;
  width: 40rem;
  height: 6rem;
  .placeHoder5 {
    height: 2.2rem;
    width: 6.4rem;
    position: absolute;
    top: 1.8rem;
    left: 3.4rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #fff;
  }
  .imgVerfication {
    width: 15rem;
    height: 6rem;
    position: absolute;
    top: 0rem;
    left: 25rem;
    border-radius: 0.2rem;
  }
}
</style>
