<template>
  <div class="rankRouter">
    <div class="outsideLevel2">
      <!-- 小树位置#################### -->
      <div class="treePlace">
        <TreeComparisonRank />
      </div>

      <!-- 数据图区域######################################### -->
      <div class="mapPlaceOutside">
        <div class="mapPlace">
          <RankRouterTop />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import RankRouterTop from "./rankAnalyze/rankRouterTop";
import TreeComparisonRank from "@/pages/ElecManagement/ComparisonAnalyze/TreeComparisonRank.vue";
// import RankRouterBottom from "./rankAnalyze/rankRouterBottom"
export default {
  components: {
    TreeComparisonRank,
    RankRouterTop,
  },
  name: "RankAnalyze",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.rankRouter {
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  width: calc(100% + 0.6rem);
  height: calc(100vh - 18.2rem);
}

.outsideLevel2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.treePlace {
  width: 22rem;
  height: 85rem;
  overflow: hidden;
  // position: absolute;
  // left: 0;
  // top: 0;
  background-color: #0d3050;
}

// 整个图表区域################################################################
.mapPlaceOutside {
  flex: 1;
  margin-left: 1.5rem;
  // height: 86.4rem;
  // position: absolute;
  // top: 0;
  // left: 24.4rem;
  // width: 118.4rem;
  overflow: hidden;
  //height: 88rem;
  height: 100%;
  width: 100%;
  .mapPlace {
    // position: relative;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 85rem;
    // height: 77.8rem;
    // width: 119rem;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // flex-wrap: wrap;

    .blank {
      height: 20rem;
      width: 119rem;
      color: grey;
      font-size: 1.4rem;
      text-align: center;
      line-height: 10rem;
    }
  }
}
</style>
