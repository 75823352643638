<!-- 配电监测10kV -->
<template>
    <div class="sys_map_10 com_sys_map_tab">
        <gx-preview />
    </div>
</template>

<script>
import GxPreview from '@/components/GxPreview.vue'
export default {
    components: { GxPreview },
    name: 'SysMap10kv',
    data () {
        return {

        }
    }
}
</script>

<style lang='less' scoped>
.sys_map_10 {
    color: #fff;
    background: rgba(0, 115, 222, 0.35);
    width: 100%;
    height: 100%;
}
</style>
