//import request from '@/utils/requestMap';
import request from '@/utils/request';

export function listComponents(query) {
  return request({
    url: '/diagram/component/list-in-group',
    method: 'get',
    params: query
  })
}

export function getComponentById(componentid) {
  return request({
    url: '/diagram/component/' + componentid,
    method: 'get'
  })
}

export function addComponent(data) {
  return request({
    url: '/diagram/component',
    method: 'post',
    data: data
  })
}

export function updateComponent(data) {
  return request({
    url: '/diagram/component',
    method: 'put',
    data: data,

  })
}

export function delComponent(componentid) {
  return request({
    url: '/diagram/component/' + componentid,
    method: 'delete'
  })
}

export function exportComponent(query) {
  return request({
    url: '/diagram/component/export',
    method: 'get',
    params: query
  })
}

//左侧元件器导航栏
export function uploadSave(data) {
  return request({
    url: '/common/upload?type ="diagramPreview"',
    method: 'post',
    data: '{file:"' + data.toDataURL("image/png") + '"}'
  })
}

