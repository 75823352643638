<!-- 漏电火灾弹层 -->
<template>
  <el-dialog title="提示" :visible.sync="isShowDetail" width="55%" :close-on-click-modal="false" @close="$emit('closeDialog')">
    <div slot="title">
      <div class="equip_title">{{title}}</div>
    </div>
    <div class="detail_box">
      <ul class="XFDY-Box display-flex-between" v-if="dialogType == 'F'">
        <li v-for="(item,index) in XFDYData" :class="'li-child'+index" :key="index">
          <label>{{item.name}}</label>
          <el-tooltip class="item" effect="dark" :content="item.value + 'V'" placement="top">
              <p><span>{{item.value}}</span>&nbsp;V</p>
          </el-tooltip>
          </li>
      </ul>
      <div class="box_charts">
        <div class="displayFlexEnd">
          <div0. class="attr_tabs" v-if="dialogType == 'E'">
            <div class="attr_tab_item" :class="whichTab==index?'active_tab':''" @click="changeTab(index)" v-for="(item, index) in tabItems" :key="index">
              {{item}}
            </div>
          </div0.
          >
          <div class="time-box">
            <span>日期：</span>
            <el-date-picker
              v-model="time"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>
        <!-- 折线图 -->
       <gx-echarts ref="lineCharts" chartsType="line" :optionData="optionData" v-if="showInterval" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import GxEcharts from './GxEcharts2.vue';
export default {
  components: { GxEcharts },
  props: {
    dialogType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '2APN2'
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      XFDYData: [{
        name: 'A相电压',
        value: '238.2'
      }, {
        name: 'B相电压',
        value: '238.2'
      }, {
        name: 'C相电压',
        value: '238.2'
      }],
      time: new Date(),
      isShowDetail: this.showDialog,
      whichTab: 0,
      tabItems: ['电流', '温度'],
      optionData: {},
      optionDataXFDY: {//消防电源
        yAxisName: '单位：V',
        xAxisData: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        seriesData: [
          {
            name: 'A相电流',
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: 'rgba(255, 172, 48, 1)'
            },
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(255, 172, 48, 0.2)" },
                { offset: 1, color: "rgba(255, 172, 48, 0)" }
              ])
            },
            data: [100, 256, 182, 201, 100, 189, 300, 100, 256, 182, 201, 100, 189, 300, 100, 256, 182, 201, 100, 189, 300, 100, 256, 182]
          },
          {
            name: 'B相电流',
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: 'rgba(82, 196, 26, 1)'
            },
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(82, 196, 26, 0.2)" },
                { offset: 1, color: "rgba(82, 196, 26, 0)" }
              ])
            },
            data: [160, 240, 90, 350, 190, 205, 137]
          },
          {
            name: 'C相电流',
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: 'rgba(245, 34, 45, 1)'
            },
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(245, 34, 45, 0.2)" },
                { offset: 1, color: "rgba(245, 34, 45, 0)" }
              ])
            },
            data: [260, 94, 249, 316, 184, 264, 240]
          }
        ]
      },
      optionDataDL: {//漏电火灾 - 电流
        yAxisName: '单位：A',
        xAxisData: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        seriesData: [
          {
            name: '电流',
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: 'rgba(54, 115, 232, 1)'
            },
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                { offset: 1, color: "rgba(54, 115, 232, 0)" }
              ])
            },
            data: [100, 180, 260, 150, 330, 230, 210, 100, 180, 260, 150, 330, 230, 210, 100, 180, 260, 150, 330, 230, 210, 100, 180, 260]
          }
        ]
      },
      optionDataWD: {//漏电火灾 - 温度
        yAxisName: '单位: ℃',
        xAxisData: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        seriesData: [
          {
            name: '温度',
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: 'rgba(90, 216, 166, 1)'
            },
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(90, 216, 166, 0.2)" },
                { offset: 1, color: "rgba(90, 216, 166, 0)" }
              ])
            },
            data: [120, 240, 56, 200, 90, 230, 210, 120, 240, 56, 200, 90, 230, 210, 120, 240, 56, 200, 90, 230, 210, 120, 240, 56]
          }
        ]
      },
      showInterval: false
    }
  },
  watch: {
    // dialogType(val){

    // },
    showDialog (val) {
      this.isShowDetail = val
      this.showInterval = false;
      val && setTimeout(() => {
        this.showInterval = true
        this.$nextTick(() => {
          // this.$refs.lineCharts.init();
          if (this.dialogType == 'E') {
            this.changeTab(0)
          } else if (this.dialogType == 'F') {
            this.changeTab(2)
          }
        })
      }, 0)
    },
    whichTab: {
      immediate: true,
      handler (val) {
        let optionData = {}
        if (val == 0) {
          optionData = this.optionDataDL;
        } else if (val == 1) {
          optionData = this.optionDataWD;
        } else if (val == 2) {
          optionData = this.optionDataXFDY;
        }
        this.optionData = optionData;
      },
    },
  },
  created () {


  },
  methods: {
    changeTab (index) {
      this.whichTab = index
      this.showInterval = false
      setTimeout(() => {
        this.showInterval = true
        this.$nextTick(() => {
          this.$refs.lineCharts.init();
        })
      }, 0)
    }
  },
};
</script>

<style lang="less" scoped>
.displayFlexEnd {
  display: flex;
  justify-content: flex-end;
}
.display-flex-between {
  display: flex;
  justify-content: space-between;
}

.detail_box {
  .box_ul {
    color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-wrap: wrap;
    .box_li {
      width: 25%;
      display: flex;
      margin-bottom: 2.4rem;
      font-size: 1.4rem;
      div {
        width: 6rem;
        text-align: right;
      }
      p {
        flex: 1;
      }
    }
  }
  .box_charts {
    color: #fff;
    height: 40rem;
    .attr_tabs {
      border-bottom: none;
      .attr_tab_item {
        font-size: 14px;
        color: #fff;
        line-height: 28px;
        background: rgba(0, 115, 222, 0.1);
        border: 1px solid #004579;
        box-sizing: border-box;
        border-radius: 2px 0px 0px 2px;
        margin: 0;
        padding: 0 16px;
      }
      .attr_tab_item.active_tab {
        border: 1px solid #027969;
        background: #027969;
        border-radius: 0px 2px 2px 0px;
      }
    }
  }
  .XFDY-Box {
    margin-bottom: 22px;
    li {
      width: 31%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      padding: 0 15px;
      background: linear-gradient(180deg, #1c5c87 0%, #00284d 100%);
      border-radius: 4px;
      box-sizing: border-box;
      label {
        font-size: 16px;
        color: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      label::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: #fff;
        margin-right: 3px;
      }
      p {
        font-size: 24px;
        display: flex;
        justify-content: flex-end;
        span {
          width: 100px;
          text-align: right;
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    li.li-child0 {
      p {
        color: rgba(255, 172, 48, 1);
      }
      label::before {
        background: rgba(255, 172, 48, 1);
      }
    }
    li.li-child1 {
      p {
        color: rgba(82, 196, 26, 1);
      }
      label::before {
        background: rgba(82, 196, 26, 1);
      }
    }
    li.li-child2 {
      p {
        color: rgba(245, 34, 45, 1);
      }
      label::before {
        background: rgba(245, 34, 45, 1);
      }
    }
  }
  .time-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 16px;
    .el-date-editor.el-input {
      width: 120px;
      height: auto;
      ::v-deep.el-input__inner {
        height: 30px;
        line-height: 30px;
        padding-right: 10px;
      }
      ::v-deep.el-input__icon {
        line-height: 30px;
      }
      ::v-deep.el-input__suffix {
        display: none;
      }
    }
  }
}
</style>