export const HuBeiData = {
    "currentGenerated":'',
    "times":[],
    "loadPower":[],
    "electricPowerConsumptionTimes":[],
    "electricPowerConsumption":[],
    "todayEnergyCount":[],
    "electricPowers":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "carbonEmissions":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "standardCoals":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "rechargePowers":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "rechargeStationNumber":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "rechargeServiceCount":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "rechargePileNumber":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "generateElectricityQuantity":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "generateStationNumber":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "economizeCharcoal":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "economizeCoal":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "generateElectricityRanking":[],
    "generateElectricityTimes":[],
    "generateElectricityS":[],
    "customerUnit":'',
    "customerUnitTransformer":'',
    "customerUnit2":'',
    "monitoringPoint":'',
    "customerUnitCount":'',
    "transformerTotalCapacity":'',
    "ladingTotalCapacity":''
}

export const customerData = {
    "currentGenerated":'',
    "fzd":'',
    "hjwd":'',
    "hjsd":'',
    "fs":'',
    "times":[],
    "loadPower":[],
    "electricPowerConsumptionTimes":[],
    "electricPowerConsumption":[],
    "todayEnergyCount":[],
    "carbonEmissions":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "standardCoals":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "dayUseElectric":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "monthElectric":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "yearElectric":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "dayUseElectricMoney":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "monthElectricMoney":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "yearElectricMoney":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "dayGenerateElectricityQuantity":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "monthGenerateElectricityQuantity":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "yearGenerateElectricityQuantity":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "dayGenerateEarnings":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "monthGenerateEarnings":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "yearGenerateEarnings":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "economizeCharcoal":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "economizeCoal":{
        "useEnergy":'',
        "yesterdayEnergy":''
    },
    "generateElectricityRanking":[],
    "customerUnitTransformer":'',
    "monitoringPoint":'',
    "transformerLoadList":[],
    "ladingTotal":'',
    "inverterNumber":'',
    "generateStationNumbers":''
}