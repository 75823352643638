<template>
  <div class="PerformanceSOutSider">
    <div class="PerformanceS">
      <div class="topBlock">
        <div id="topBlock" style="width: 100%; height: 42rem;">
          <!--顶部  -->
          <div class="topBar">
            <div class="text">
              <div class="word1">响应时长统计</div>
              <div class="word2">（{{ startTime }} ～ {{ endTime }}）</div>
            </div>

            <div class="btn">
              <img
                v-show="bottomChartShow == 'line'"
                class="img3"
                src="@/assets/ElecManagement/LineChartGreen.png"
                alt
              />
              <img
                v-show="bottomChartShow != 'line'"
                @click="changeEchartType('line')"
                class="img3"
                src="@/assets/ElecManagement/LineChart.png"
                alt
              />
              <img
                v-show="bottomChartShow == 'bar'"
                class="img2"
                src="@/assets/ElecManagement/BarChartGreen.png"
                alt
              />
              <img
                v-show="bottomChartShow != 'bar'"
                @click="changeEchartType('bar')"
                class="img2"
                src="@/assets/ElecManagement/BarChart.png"
                alt
              />
              <!-- 下载的下拉框 -->
              <el-dropdown class="imgFuck" @command="downLoadPng">
                <span class="el-dropdown-link">
                  <img src="@/assets/repair/icon1.png" class="imgFuck" alt="" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="['pic', '响应时长统计']">
                    图片下载
                  </el-dropdown-item>
                  <el-dropdown-item :command="['excel', '响应时长统计']">
                    Excel下载
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <!-- 图和表 ########################################################################-->
          <div class="chart-container">
            <div
              class="TopChartBar barChart"
              ref="TopChartBarRef"
              :style="bottomChartShow == 'bar' ? '' : 'visibility: hidden;'"
            ></div>
            <div
              class="TopChartBar lineChart"
              ref="TopChartLineRef"
              :style="bottomChartShow == 'line' ? '' : 'visibility: hidden;'"
            ></div>
          </div>

          <div class="TopChartBar2">
            <!-- 头部栏 -->
            <div class="topPlaceTable">
              <div class="inner" style="flex: 68;">序号</div>
              <div class="inner" style="flex: 150;">姓名</div>
              <div class="inner" style="flex: 398;">内部报修(天)</div>
              <div class="inner" style="flex: 398;">巡检报修(天)</div>
              <div class="inner" style="flex: 398;">监测报修(天)</div>
              <div class="inner" style="flex: 398;">预防性实验(天)</div>
            </div>
            <!-- 中间表格 -->

            <div class="contentPlaceTable">
              <div
                v-for="(item, index) in topData"
                :key="'topData' + index"
                class="innerCard"
              >
                <div class="inner" style="flex: 68;">
                  {{ index + 1 + (currentPageTop - 1) * 10 }}
                </div>
                <div class="inner" style="flex: 150;">{{ item.name }}</div>
                <div class="inner" style="flex: 398;">{{ item.resTime1 }}</div>
                <div class="inner" style="flex: 398;">{{ item.resTime2 }}</div>
                <div class="inner" style="flex: 398;">{{ item.resTime3 }}</div>
                <div class="inner" style="flex: 398;">{{ item.resTime4 }}</div>
              </div>
            </div>
            <!-- 分页器 -->
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChangeTop"
                :current-page="currentPageTop"
                layout="total, prev, pager, next, jumper"
                :total="totalNumForPagenationTop"
              ></el-pagination>
            </div>
          </div>
        </div>
        <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
      </div>
    </div>

    <!-- <div class="timer">
      <el-date-picker
        class="myDatePicker"
        v-model="valueTimePicker"
        type="daterange"
        align="right"
        unlink-panels
        :clearable="false"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="timePickerChange"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
      ></el-date-picker>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import html2canvas from 'html2canvas'
import { exportResTimeReport } from '@/api/repair'
export default {
  name: 'ResTime',
  props: {
    dateList: {
      type: Array,
      default: () => [],
    },
    dateType: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      //时间段
      startTime: new Date().getFullYear() + '-01-01',
      endTime: new Date().getFullYear() + '-12-31',
      // 日期选择器的双向绑定
      valueTimePicker: '',
      // 上方的分页器页数
      currentPageTop: 1,
      // 上方的数据总数
      totalNumForPagenationTop: 0,
      // 控制显示icon以及图表的显示
      bottomChartShow: 'line',
      // ############################################################################上方直方图数据#############################################################################
      optionTopChartBar: {
        legend: {
          x: 'middle',
          y: 'bottom',
          left: this.fontSize(70),
          itemHeight: this.fontSize(1),
          itemWidth: this.fontSize(1),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: this.fontSize(1.2),
            itemGap: 0,
          },
        },
        grid: {
          top: 40,
          left: '2.8%',
          right: '1.8%',
          bottom: '16%',
        },
        xAxis: {
          type: 'category',
          // x轴刻度线
          axisTick: { show: false },
          // x轴文字
          axisLabel: {
            interval: 0,
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // x轴基线
          axisLine: {
            lineStyle: {
              color: '#bae7ff',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          name: '（天）',
          // y轴顶部文字
          nameTextStyle: {
            color: 'rgba(230, 247, 255, 0.5)',
            fontSize: this.fontSize(1.2),
            fontWeight: 100,
            padding: [0, 0, 0, -35],
          },
          // y轴文字
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // 分割线样式
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.2)',
            },
          },
        },
        series: [
          {
            name: '内部报修',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 1)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '巡检报修',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 1)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '监测报修',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 1)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '预防性实验',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 1)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
        ],
        tooltip: {
          extraCssText: 'width:18rem;height:14.5rem',
          backgroundColor: 'rgba(0,0,0,0.65)',
          borderColor: 'rgba(0,0,0,0.65)',
          color: '#E6F7FF',
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' +
              '工程师：' +
              params[0].name +
              '<br>' +
              '内部报修： ' +
              '<span style="color:#1de1e2;">' +
              (params[0].data || '--') +
              '天' +
              '</span>' +
              '<br>' +
              '巡检报修： ' +
              '<span style="color:#178dfb;">' +
              (params[1].data || '--') +
              '天' +
              '</span>' +
              '<br>' +
              '监测报修： ' +
              '<span style="color:#faa95f;">' +
              (params[2].data || '--') +
              '天' +
              '</span>' +
              '<br>' +
              '预防性实验： ' +
              '<span style="color:#066361;">' +
              (params[3].data || '--') +
              '天' +
              '</span>' +
              '</span>'
            )
          },
        },
      },

      // ############################################################################上方折线图数据#############################################################################
      optionTopChartLine: {
        legend: {
          x: 'middle',
          y: 'bottom',
          icon: 'circle',
          left: this.fontSize(70),
          itemHeight: this.fontSize(1),
          itemWidth: this.fontSize(1),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: this.fontSize(1.2),
            itemGap: 0,
          },
        },
        grid: {
          top: 40,
          left: '2.8%',
          right: '1.8%',
          bottom: '16%',
        },
        xAxis: {
          type: 'category',
          // x轴刻度线
          axisTick: { show: false },
          // x轴文字
          axisLabel: {
            interval: 0,
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // x轴基线
          axisLine: {
            lineStyle: {
              color: '#bae7ff',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          name: '（天）',
          // y轴顶部文字
          nameTextStyle: {
            color: 'rgba(230, 247, 255, 0.5)',
            fontSize: this.fontSize(1.2),
            fontWeight: 100,
            padding: [0, 0, 0, -35],
          },
          // y轴文字
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // 分割线样式
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.2)',
            },
          },
        },
        series: [
          {
            name: '内部报修',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 1)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 0.2)' },
                  { offset: 0.7, color: 'rgba(30, 231, 231, 0)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0)' },
                ]),
              },
            },
          },
          {
            name: '巡检报修',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 1)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 0.2)' },
                  { offset: 0.7, color: 'rgba(24, 144, 255, 0)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0)' },
                ]),
              },
            },
          },
          {
            name: '监测报修',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 1)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 0.2)' },
                  { offset: 0.7, color: 'rgba(255, 172, 95, 0)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0)' },
                ]),
              },
            },
          },
          {
            name: '预防性实验',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 1)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 0.2)' },
                  { offset: 0.7, color: 'rgba(2, 121, 105, 0)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0)' },
                ]),
              },
            },
          },
        ],
        tooltip: {
          extraCssText: 'width:16rem;height:14.5rem',
          backgroundColor: 'rgba(0,0,0,0.65)',
          borderColor: 'rgba(0,0,0,0.65)',
          color: '#E6F7FF',
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' +
              '工程师：' +
              params[0].name +
              '<br>' +
              '内部报修： ' +
              '<span style="color:#1de1e2;">' +
              (params[0].data || '--') +
              '天' +
              '</span>' +
              '<br>' +
              '巡检报修： ' +
              '<span style="color:#178dfb;">' +
              (params[1].data || '--') +
              '天' +
              '</span>' +
              '<br>' +
              '监测报修： ' +
              '<span style="color:#faa95f;">' +
              (params[2].data || '--') +
              '天' +
              '</span>' +
              '<br>' +
              '预防性实验： ' +
              '<span style="color:#066361;">' +
              (params[3].data || '--') +
              '天' +
              '</span>' +
              '</span>'
            )
          },
        },
      },

      // 表格的数据
      topData: [],
      topDataAll: [],

      // 时间选择器的数据
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一天月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三天月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },

  methods: {
    // 切换图表类型
    changeEchartType(val) {
      this.bottomChartShow = val
      if (val == 'bar') {
        this.optionTopChartBar.xAxis.data = this.myData.name
        this.optionTopChartBar.series[0].data = this.myData.receptionCount
        this.optionTopChartBar.series[1].data = this.myData.pendingCount
        this.optionTopChartBar.series[2].data = this.myData.processedSoCount
        this.optionTopChartBar.series[3].data = this.myData.finishCount

        var myChartTopChartLine = this.$echarts.init(this.$refs.TopChartLineRef)
        myChartTopChartLine.clear()
        var myChartTopChartBar = this.$echarts.init(this.$refs.TopChartBarRef)
        myChartTopChartBar.clear()
        myChartTopChartBar.setOption(this.optionTopChartBar)
        myChartTopChartBar._dom.childNodes[0].childNodes[0].style.height =
          '34rem'
        myChartTopChartBar._dom.childNodes[0].childNodes[0].style.width =
          '100%'
        this.inItTopChartBar()
      } else if (val == 'line') {
        this.optionTopChartLine.xAxis.data = this.myData.name
        this.optionTopChartLine.series[0].data = this.myData.receptionCount
        this.optionTopChartLine.series[1].data = this.myData.pendingCount
        this.optionTopChartLine.series[2].data = this.myData.processedSoCount
        this.optionTopChartLine.series[3].data = this.myData.finishCount

        var myChartTopChartBar = this.$echarts.init(this.$refs.TopChartBarRef)
        myChartTopChartBar.clear()
        var myChartTopChartLine = this.$echarts.init(this.$refs.TopChartLineRef)
        myChartTopChartLine.clear()
        myChartTopChartLine.setOption(this.optionTopChartLine)
        myChartTopChartLine._dom.childNodes[0].childNodes[0].style.height =
          '34rem'
        myChartTopChartLine._dom.childNodes[0].childNodes[0].style.width =
          '100%'
        this.inItTopChartLine()
      }
    },
    changeLine() {
      this.showInterval = false
      this.barChartShowGreen = false
      this.barChartShow = true
      this.lineChartShow = false
      this.lineChartShowGreen = true
      setTimeout(() => {
        this.showInterval = true
        this.$nextTick(() => {
          this.$refs.lineCharts.init()
        })
      }, 0)
    },
    // 转化dom节点为图片下载
    downLoadPng(val) {
      if (val[0] == 'excel') {
        let params = {
          endTime: this.endTime,
          startTime: this.startTime,
          myPage: val[1],
        }
        // this.$store.dispatch('getExportFileRepair', params)
        exportResTimeReport(params).then((response) => {
          var blob = new Blob([response], {
            type: 'application/vnd.ms-excel,charset=utf-8',
          }) //创建一天blob对象
          var a = document.createElement('a') //创建一天<a></a>标签
          a.href = URL.createObjectURL(blob) // response is a blob
          a.download = `${this.startTime}-${this.endTime}${val[1]}.xls` //文件名称
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
      } else {
        var node = document.getElementById('topBlock')
        window.pageYoffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        var width = node.offsetWidth
        var height = node.offsetHeight
        var scale = 4
        html2canvas(node, {
          width: width,
          heigth: height,
          backgroundColor: '#0d3050',
          dpi: window.devicePixelRatio * 4,
          scale: scale,
          X: 0,
          Y: 0,
          scrollX: -3,
          scrollY: 0,
          useCORS: true,
          allowTaint: true,
        }).then((canvas) => {
          var url = canvas.toDataURL()
          var a = document.createElement('a')
          a.download = val[1] + this.startTime + '至' + this.endTime
          var event = new MouseEvent('click')
          a.href = url
          a.dispatchEvent(event)
        })
      }
    },

    // 获取屏幕比例，使echarts自适应
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
      if (!clientWidth) return
      let fontSize = clientWidth / 192
      return res * fontSize
    },

    // 渲染上方的直方图
    inItTopChartBar() {
      this.optionTopChartBar.xAxis.data = this.myData.name
      this.optionTopChartBar.series[0].data = this.myData.resTime1
      this.optionTopChartBar.series[1].data = this.myData.resTime2
      this.optionTopChartBar.series[2].data = this.myData.resTime3
      this.optionTopChartBar.series[3].data = this.myData.resTime4
      var myChartTopChartBar = this.$echarts.init(this.$refs.TopChartBarRef)
      myChartTopChartBar.clear()
      myChartTopChartBar.setOption(this.optionTopChartBar)
      myChartTopChartBar._dom.childNodes[0].childNodes[0].style.height = '34rem'
      myChartTopChartBar._dom.childNodes[0].childNodes[0].style.width =
        '100%'
    },

    // 渲染上方的直方图
    inItTopChartLine() {
      this.optionTopChartLine.xAxis.data = this.myData.name
      this.optionTopChartLine.series[0].data = this.myData.resTime1
      this.optionTopChartLine.series[1].data = this.myData.resTime2
      this.optionTopChartLine.series[2].data = this.myData.resTime3
      this.optionTopChartLine.series[3].data = this.myData.resTime4

      var myChartTopChartLine = this.$echarts.init(this.$refs.TopChartLineRef)
      myChartTopChartLine.clear()
      myChartTopChartLine.setOption(this.optionTopChartLine)
      myChartTopChartLine._dom.childNodes[0].childNodes[0].style.height =
        '34rem'
      myChartTopChartLine._dom.childNodes[0].childNodes[0].style.width =
        '100%'
    },

    // 渲染上方表格
    inItTopChartTable() {
      const data = this.restimeTableData.records
      for (let i = 0; i < data.length; i++) {
        for (let k in data[i]) {
          data[i][k] = data[i][k] ? data[i][k] : '--'
        }
      }
      this.topData = data
      this.totalNumForPagenationTop = this.restimeTableData.total
    },

    // 上方的分页器变动
    handleCurrentChangeTop(val) {
      this.currentPageTop = val
      // this.topData = this.topDataAll.slice((val - 1) * 10, 10 + (val - 1) * 10)
      this.refreshThePage()
    },

    // 更新页面数据
    refreshThePage() {
      this.$store
        .dispatch('getStatisticsRestime', {
          startTime: this.startTime,
          endTime: this.endTime,
        })
        .then(() => {
          this.myData = this.formatData(this.restimeData)
          // this.inItTopChartBar()
          this.inItTopChartLine()
          this.inItTopChartTable()
        })
      this.$store
        .dispatch('getStatisticsRestimeTable', {
          startTime: this.startTime,
          endTime: this.endTime,
          pageNum: this.currentPageTop,
          pageSize: 10,
        })
        .then(() => {
          this.inItTopChartTable()
        })
    },
    formatData(data) {
      let nameArr = []
      let resTime1Arr = []
      let resTime2Arr = []
      let resTime3Arr = []
      let resTime4Arr = []
      data.forEach((item) => {
        nameArr.push(item.name)
        resTime1Arr.push(item.resTime1 || 0)
        resTime2Arr.push(item.resTime2 || 0)
        resTime3Arr.push(item.resTime3 || 0)
        resTime4Arr.push(item.resTime4 || 0)
      })
      return {
        name: nameArr,
        resTime1: resTime1Arr,
        resTime2: resTime2Arr,
        resTime3: resTime3Arr,
        resTime4: resTime4Arr,
      }
    },
  },
  watch: {
    dateList: {
      handler(val) {
        this.startTime = val[0] || this.startTime
        this.endTime = val[1] || this.endTime
        this.refreshThePage()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      restimeData: (state) => {
        return state.repair.restimeData.data
      },
      restimeTableData: (state) => {
        return state.repair.restimeTableData.data
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.PerformanceSOutSider {
  height: 100%;
  width: 171.2rem;
  overflow: hidden;
}

.PerformanceS {
  height: 100%;
  width: 173rem;
  background-color: #18191a;
  overflow: scroll;
}
// ##############################################################上部分板块#############################
.topBlock {
  height: 100%;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  overflow: auto;
}
// ##############################################################下部分板块#############################
.bottomBlock {
  height: 42rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  left: 0;
  top: 43.6rem;
}

// ##############################################################公用样式#############################
.topBar {
  width: 100%;
  padding: 2.4rem 2.4rem 0 2.4rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    .word1 {
      font-size: 1.6rem;
      color: #37a5dd;
      line-height: 2.2rem;
      float: left;
    }
    .word2 {
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.45);
      line-height: 2.4rem;
      float: left;
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    width: 12rem;
    img {
      height: 2.4rem;
      width: 2.4rem;
      cursor: pointer;
    }
  }
}

.TopChartBar {
  height: 34rem;
  width: 100%;
}
.chart-container {
  position: relative;
  height: 34rem;
}
.barChart,.lineChart {
  position: absolute;
}
.TopChartBar2 {
  height: 56.5rem;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
}

// 空板块
.blank {
  position: absolute;
  top: 111rem;
  height: 25rem;
  width: 172rem;
  color: grey;
  font-size: 1.4rem;
  text-align: center;
  line-height: 10rem;
}

// 定制化表格的样式
.topPlaceTable {
  height: 6.5rem;
  width: 100%;
  display: flex;
  .inner {
    height: 6.5rem;
    background-color: #133f68;
    color: #fff;
    font-size: 1.4rem;
    line-height: 6.5rem;
    text-align: center;
    margin-right: 0.1rem;
    border-bottom: 0.1rem solid #2e6395;
    box-sizing: border-box;
  }
  .inner2 {
    background-color: #133f68;
    height: 3.2rem;
    line-height: 3.2rem;
    margin-right: 0.1rem;
    float: left;
    // background-color: aqua;
  }
}
.contentPlaceTable {
  height: 40rem;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;
  // background-color: aqua;
  .innerCard {
    height: 4rem;
    width: 100%;
    // border-bottom: red 0.1rem solid;
    box-sizing: border-box;
    display: flex;
  }
  .inner {
    height: 3.9rem;
    background-color: #0d3050;
    color: #fff;
    font-size: 1.4rem;
    line-height: 3.9rem;
    text-align: center;
    margin-right: 0.1rem;
    border-bottom: 0.1rem solid #244561;
    box-sizing: border-box;
  }
  .inner2 {
    position: absolute;
    top: 0rem;
    left: 2.8rem;
  }
  .inner3 {
    height: 0.8rem;
    width: 25rem;
    background-color: #244561;
    position: absolute;
    top: 1.6rem;
    left: 7rem;
  }
  .inner4 {
    height: 0.8rem;
    background: linear-gradient(270deg, #1890ff 0%, #1ee7e7 100%);
    position: absolute;
    top: 1.6rem;
    left: 7rem;
  }
}
.pagination {
  float: right;
  margin-top: 2.4rem;
  margin-right: 2.4rem;
}

// 定制化横向表格
.transverseTable {
  height: 28rem;
  width: 166.4rem;
  position: relative;
  top: 0;
  left: 0;
  margin-left: 2.4rem;
  display: flex;
  .titlePlace {
    height: 28rem;
    width: 17.6rem;
    background-color: #133f68;
    .innerTitle {
      height: 4rem;
      width: 17.6rem;
      border-bottom: 0.1rem solid #2e6395;
      box-sizing: border-box;
      color: #fff;
      font-size: 1.4rem;
      line-height: 4rem;
      text-align: left;
      text-indent: 1.6rem;
    }
  }
  .contentCard1 {
    background-color: #0c2d4b;
    height: 28rem;
    width: 14.8rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
  }
  .contentCard2 {
    background-color: #0d3050;
    height: 28rem;
    width: 14.8rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
  }
  .contentInner {
    height: 4rem;
    width: 14.8rem;
    border-bottom: 0.1rem solid #244561;
    box-sizing: border-box;
    color: #fff;
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
    position: relative;
    top: 0;
    left: 0;
  }

  .chart1 {
    height: 0.8rem;
    width: 8rem;
    background-color: #244561;
    position: absolute;
    top: 1.6rem;
    left: 5.3rem;
  }
  .chart2 {
    height: 0.8rem;
    background: linear-gradient(270deg, #1890ff 0%, #1ee7e7 100%);
    position: absolute;
    top: 1.6rem;
    left: 5.3rem;
  }
}

.timer {
  height: 3rem;
  width: 27rem;
  position: absolute;
  top: -6rem;
  right: 2.4rem;
  .myDatePicker {
    height: 3rem;
    width: 27rem;
  }
}
</style>
