<template>
    <div class="genderPowers">
        <div class="gen-header">
            <div class="form">
                <el-form :inline="true" :model="dataForm" class="demo-form-inline">
                    <el-form-item label="电站名称" class="powerStation">
                        <el-select multiple clearable collapse-tags class="styleWid inputSelect" size="mini" v-model="dataForm.powerName" placeholder="请选择电站" @change="selectPowerStation" @remove-tag="removeStation">
                            <el-option v-for="item in powerNameList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <!-- <el-select class="styleWid" size="mini" v-model="dataForm.powerName" placeholder="请选择电站">
              <el-option v-for="item in powerNameList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select> -->
                    </el-form-item>
                    <el-form-item label="统计方式">
                        <el-select class="styleWid" size="mini" v-model="dataForm.statistics" placeholder="按时间统计" @change="changeStatistics">
                            <el-option v-for="item in statisticsList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间维度">
                        <el-select @change="changeDimenTime" class="styleWid" size="mini" v-model="dataForm.dimension" placeholder="选择维度">
                            <el-option v-for="item in dimensionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="统计时间">
                        <!-- format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" -->
                        <!-- 日 -->
                        <el-date-picker class="styleWid" size="mini" v-show="showDay" v-model="generalDay" type="date" placeholder="选择日期" @change="changeDate"></el-date-picker>
                        <!-- 月 -->
                        <el-date-picker v-show="showMouth" class="styleWid" size="mini" v-model="generalDay" type="month" placeholder="选择月" @change="changeDate"></el-date-picker>
                        <!-- 年 -->
                        <el-date-picker v-show="showYear" class="styleWid" size="mini" v-model="generalDay" type="year" placeholder="选择年" @change="changeDate"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btnColor" size="mini" type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item>
                    <el-form-item>

                        <el-button class="btnMargin" size="mini" type="primary" @click="outFile">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- 表格1 -->
        <div class="gen-content">
            <div class="powerTable">
                <div class="table">
                    <el-table :data="tableData" style="width: 100%" stripe:false height="68rem" border:false :cell-style="cellStyle" :header-cell-style="headerClass1" :default-sort="{ prop: 'date', order: 'descending' }" v-if="dataForm.statistics == 1">
                        <el-table-column prop="stationName" show-overflow-tooltip label="电站名称" width="200" :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="stationAddr" show-overflow-tooltip label="地址" width="240" :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="dateType" show-overflow-tooltip label="时间" width="120" :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="inverterAmount" label="逆变器发电量(kWh)" sortable :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="upAmount" label="上网电量(kWh)" sortable :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="equivPowerTime" label="等价发电时(kWh/kWp)" sortable :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="downAmount" label="用电量(kWh)" sortable :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="earning" label="收益(元)" :show-overflow-tip="true"></el-table-column>
                    </el-table>
                    <el-table :data="tableData" style="width: 100%" stripe:false height="68rem" border:false :cell-style="cellStyle" :header-cell-style="headerClass1" :default-sort="{ prop: 'date', order: 'descending' }" v-if="dataForm.statistics == 2">
                        <el-table-column prop="statisticTime" show-overflow-tooltip label="统计时间" width="200" :show-overflow-tip="true"></el-table-column>
                        <!-- <el-table-column
              prop="stationAddr"
              show-overflow-tooltip
              label="PV发电量(kWh)"
              width="240"
              :show-overflow-tip="true"
            ></el-table-column> -->
                        <el-table-column prop="inverterAmount" label="逆变器发电量(kWh)" sortable :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="upAmount" label="上网电量(kWh)" sortable :show-overflow-tip="true"></el-table-column>
                        <el-table-column prop="earning" label="收益(元)" :show-overflow-tip="true"></el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="customerTotalNum"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg"
import { exportGenManagementStationReport } from "@/api/repair"

export default {
    name: "generalPower",
    mixins: [tableBg],
    data () {
        return {
            dataForm: {
                user: "",
                region: "",
                powerName: [],
                statistics: "",
                dimension: "",
                staTime: "",
            },
            customerId: "",
            customerName: '',
            generalDay: "",
            showDay: true,
            showMouth: false,
            showYear: false,
            showTable1: true,
            showTable2: false,
            powerNameList: [],
            statisticsList: [
                {
                    id: "1",
                    label: "按电站统计",
                },
                {
                    id: "2",
                    label: "按时间统计",
                },
            ],
            dimensionList: [
                {
                    value: "1",
                    label: "按日统计",
                },
                {
                    value: "2",
                    label: "按月统计",
                },
                {
                    value: "3",
                    label: "按年统计",
                },
            ],
            staTimeList: [],
            tableData1: [],
            tableData: [],
            currentPage: 1,
            pageSize: 10,
            customerTotalNum: 0,
            currentPage2: 1,
            pageSize2: 10,
            customerTotalNum2: 0,
            stationIds: "",
            // 数组内套数组，每个数组就是一页数据
            totalPageData: [],
            // 共几页
            pageNum: 1,
            // 当前要显示的数据
            dataShow: [],
            flag: false,
        }
    },
    created () {
        this.dataForm.statistics = "1"
        this.dataForm.dimension = "1"
        this.generalDay = new Date()
        this.initPowerData()
    },
    mounted () {
        // this.initPowerStationData()
        setTimeout(() => {
            this.flag = true
        }, 1000)
    },
    methods: {
        // 查询
        onSubmit () {
            if (!this.dataForm.statistics) {
                this.$message({
                    message: "请选择统计方式！",
                    type: "warning",
                })
                return
            }
            if (!this.dataForm.dimension) {
                this.$message({
                    message: "请选择时间维度！",
                    type: "warning",
                })
                return
            }
            if (!this.generalDay) {
                this.$message({
                    message: "请选择统计时间！",
                    type: "warning",
                })
                return
            }
            this.currentPage = 1
            this.initPowerData()
        },
        outFile () {
            let date = this.generalDay || new Date()
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            month = month < 10 ? "0" + month : month
            let day = date.getDate()
            day = day < 10 ? "0" + day : day
            let generalDay = year + "-" + month + "-" + day
            if (this.dataForm.dimension == 1) {
                generalDay = year + "-" + month + "-" + day
            } else if (this.dataForm.dimension == 2) {
                generalDay = year + "-" + month
            } else if (this.dataForm.dimension == 3) {
                generalDay = year
            }
            let params = {
                customerId: this.customerId,
                tjmethod: this.dataForm.statistics,
                datetype: this.dataForm.dimension,
                date: generalDay,
                stationIds: this.dataForm.powerName.join(","),
                name: "电站报表",
            }
            // this.$store.dispatch('getDownloadTemplate', params)
            exportGenManagementStationReport(params).then((response) => {
                var blob = new Blob([response], {
                    type: "application/vnd.ms-excel,charset=utf-8",
                }) //创建一个blob对象
                var a = document.createElement("a") //创建一个<a></a>标签
                a.href = URL.createObjectURL(blob) // response is a blob
                a.download = `${this.customerName}${this.dataForm.dimension == 1 ? '日' : this.dataForm.dimension == 2 ? '月' : this.dataForm.dimension == 3 ? '年' : '' }电站报表.xls` //文件名称
                a.style.display = "none"
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        },
        //控制统计时间的三个时间段（日月年）
        changeDimenTime (val) {
            // this.generalDay = "";
            if (val === "1") {
                this.showDay = true
                this.showMouth = false
                this.showYear = false
            } else if (val === "2") {
                this.showDay = false
                this.showMouth = true
                this.showYear = false
            } else if (val === "3") {
                this.showDay = false
                this.showMouth = false
                this.showYear = true
            }
            this.onSubmit()
        },
        chageShowTable (val) {
            if (val === "1") {
                this.showTable1 = true
                this.showTable2 = false
            } else if (val === "2") {
                this.showTable1 = false
                this.showTable2 = true
            }
        },
        selectPowerStation () {
            if (this.dataForm.powerName.length == 1) {
                this.stationIds = this.dataForm.powerName[0].toString()
            } else if (this.dataForm.powerName.length > 1) {
                this.stationIds = this.dataForm.powerName.join(",")
            }
            this.onSubmit()
        },
        removeStation () {
            if (this.dataForm.powerName.length == 1) {
                this.stationIds = this.dataForm.powerName[0].toString()
            } else if (this.dataForm.powerName.length > 1) {
                this.stationIds = this.dataForm.powerName.join(",")
            } else if (this.dataForm.powerName.length == 0) {
                this.stationIds = []
            }
            this.onSubmit()
        },
        changeStatistics () {
            this.onSubmit()
        },
        changeDate () {
            this.onSubmit()
        },
        initPowerData () {
            this.customerId = this.$store.state.storage.customerId
            if (this.generalDay) {
                let date = this.generalDay
                let year = date.getFullYear()
                let month = date.getMonth() + 1
                let day = date.getDate()
                if (month < 10) {
                    month = "0" + month
                }
                if (day < 10) {
                    day = "0" + day
                }
                date = year + "-" + month + "-" + day
                if (this.dataForm.dimension == 1) {
                    date = date
                } else if (this.dataForm.dimension == 2) {
                    date = date.split("-")[0] + "-" + date.split("-")[1]
                } else if (this.dataForm.dimension == 3) {
                    date = date.split("-")[0]
                }
                let value = {
                    customerId: this.customerId,
                    tjmethod: this.dataForm.statistics,
                    datetype: this.dataForm.dimension,
                    date: date,
                    stationIds: this.dataForm.powerName,
                    pageNum: this.currentPage,
                    pageSize: this.pageSize
                }
                this.$store.dispatch("getPowerTable", value).then(() => {
                    this.tableData = this.$store.state.genManagement.getPowerTable.data.records
                    this.customerTotalNum = this.$store.state.genManagement.getPowerTable.data.total
                    // this.calcPageData()
                })
            }
        },
        initPowerStationData () {
            this.customerId = this.$store.state.storage.customerId
            let value = {
                customerId: this.customerId,
            }
            this.$store.dispatch("getPowerStationsAll", value).then(() => {
                let obj = {
                    id: "",
                    name: "全部",
                }
                this.powerNameList = this.$store.state.equipment.getPowerStationsAll.data
                // this.powerNameList.unshift(obj)
                this.dataForm.powerName = []
            })
        },
        handleSizeChange (newPageSize) {
            this.pageSize = newPageSize
            // 重新计算页数并分组
            this.initPowerData()
        },
        handleCurrentChange (newPageNum) {
            this.currentPage = newPageNum
            this.initPowerData()
        },
        // 对tableData进行处理，分为每一页的数组
        calcPageData () {
            // 计算页数
            if (this.tableData.length > 1) {
                this.pageNum = Math.ceil(this.tableData.length / this.pageSize) || 1
            }
            // 数据分组，循环的次数是总页数
            for (let i = 0; i < this.pageNum; i++) {
                this.totalPageData[i] = this.tableData.slice(
                    this.pageSize * i,
                    this.pageSize * (i + 1)
                )
            }
            this.dataShow = this.totalPageData[this.currentPage - 1]
        },
    },
    watch: {
        "$store.state.storage.customerData": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return
                }
                // if (!this.flag) return
                this.customerId = newVal.id
                this.customerName = newVal.name
                this.currentPage = 1
                this.initPowerStationData()
                this.initPowerData()
            },
        },
    },
}
</script>

<style lang="less" scoped>
.genderPowers {
    .btnMargin {
        margin-left: 12rem;
    }
    .styleWid {
        width: 18rem;
    }
    .btnColor {
        background: #027969;
    }
    .gen-header {
        // width: 143.6rem;
        height: 6.4rem;
        line-height: 6.4rem;
        background: #0d3050;
        border-radius: 4px;
        .form {
            width: 100%;
            height: 100%;
            display: inline-block;
            margin-left: 2rem;
            padding-top: 1.2rem;
        }
    }
    .gen-content {
        width: 100%;
        // width: 143.6rem;
        height: 75.5rem;
        background: #0d3050;
        border-radius: 4px;
        margin-top: 1.6rem;
        padding-top: 1.5rem;
        .powerTable {
            margin: 0 2.2rem 0 1.5rem;
            .table {
                height: 59rem;
            }
            .pagination {
                float: right;
                margin-top: 1rem;
            }
        }
    }
}
</style>
<style lang="less">
.el-select-dropdown__item.selected {
    background-color: #1e5587 !important;
}
.el-select-dropdown__item.selected.hover {
    background-color: #1e5587 !important;
}

.genderPowers {
    width: 100%;
    .demo-form-inline {
        .el-form-item:last-child {
            float: right;
            margin-right: 4.4rem;
        }
    }
    .el-form-item__label {
        font-size: 1.4rem !important;
    }
    .inputSelect {
        .el-input__prefix,
        .el-input__suffix {
            top: -0.3rem;
        }
    }

    .el-button--primary {
        color: #fff;
        border-color: #027969;
        background-color: #027969;
    }
    .el-tag--info {
        background-color: #1e5587 !important;
        border-color: #1e5587 !important;
        color: rgba(255, 255, 255, 0.9);
        max-width: 10rem !important;
    }
    .el-select {
        width: 18rem !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
    .el-input__inner {
        color: rgba(255, 255, 255, 0.9);
    }
    // .el-select__tags {
    //   display: inline-block !important;
    //   white-space: nowrap !important;
    // }

    margin-top: 1.5rem;
    .el-table tr {
        background: rgba(0, 115, 222, 0.1);
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
        background-color: #0c2f4f;
    }
    .el-table .el-table__cell {
        padding: 1.3rem 0;
    }
    .el-table thead {
        color: #ffffff;
        font-weight: 500;
    }
    .el-table {
        overflow: hidden;
        height: 59rem !important;
    }
    .el-table--border th.el-table__cell,
    .el-table__fixed-right-patch {
        border-bottom: 1px solid #0c2f4f;
    }
    .el-table thead.is-group th.el-table__cell {
        background: #133f68;
    }
    .el-table--border .el-table__cell,
    .el-table__body-wrapper
        .el-table--border.is-scrolling-left
        ~ .el-table__fixed {
        border-right: 1px solid #0c2f4f;
    }
    .el-table--border,
    .el-table--group {
        border: 1px solid #0c2f4f;
    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
        content: "";
        position: absolute;
        background-color: #0c2f4f;
        z-index: 1;
    }
    .el-table th.el-table__cell.is-leaf {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 1.2rem;
    }
    .el-table .caret-wrapper {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        height: 34px;
        width: 0px;
        vertical-align: middle;
        cursor: pointer;
        overflow: initial;
        position: relative;
    }
}
</style>
