<!-- 电路图编辑器页面 -->
<template>
  <div class="app-container" style="width: 100%; height: 100%">
    <!-- 画布 -->
    <!-- <div class="attr_tabs">
      <div class="attr_tab_item" :class="whichTab==index?'active_tab':''" @click="whichTab=index;handleChangeTab(item)" v-for="(item, index) in graphTotal" :key="index">{{item.diagramName}}</div>
    </div> -->
    <div
      id="container"
      class="graph-main-container"
      style="width: 100%; height: 100%"
    ></div>
    <!-- 漏电火灾弹框 -->
    <gx-LDHZ-dialog
      :showDialog="showDialog"
      :dialogType="whichTab"
      @closeDialog="showDialog = false"
    />
  </div>
</template>
<script>
import { getDiagramsByScenarioCode } from "@/api/diagram/main.js";
import registerTools from "@/packages/x6/tools/registerTools.js";
import { initGraphPreView } from "@/packages/x6/common/graph";
import { freezeGraph } from "@/packages/x6/common/trigger.js";
import { Config } from "@/packages/x6/common/enums.js";
import GxLDHZDialog from "./GxLDHZDialog.vue";
import { watchDevice } from "@/api/diagram/kaiyuan.js";
export default {
  components: { GxLDHZDialog },
  data () {
    return {
      BindData: [],
      ponintIds: [],//电表id
      graph: {},
      navTopList: [],
      tooltipsContent: null,
      resultJson: null,
      diagramObj: {},
      showDialog: false,
      graphTotal: [],
      // whichTab: 0,
      diagramId: this.$route.query.id,
      requestID: ''
    };
  },
  props: {
    whichTab: {
      type: String,
      default: 'E'
    },
    chartId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    // 可操作去插槽
    panelAreaName () {
      return Config.PANEL_AREA_SLOT;
    },
  },
  beforeDestroy () {
  },
  methods: {
    // 拿到JSON数据
    async getGraphData (diagramCode) {
      // debugger
      const res = await getDiagramsByScenarioCode({ diagramCode });
      if (res.code === 200) {
        this.graphTotal = res.data;
        //切换设计图tab
        let chartTabData = res.data.length === 0 ? [] : res.data.map((list, i) => {
          return {
            id: i,
            diagramName: list.diagramName
          }
        });
        this.$emit('getChartTabData', chartTabData)
      }
    },
    // handleChangeTab (item) {
    //   this.init(item.diagramJson)
    // },
    async init (jsonData) {
      let _this = this;
      // 销毁
      if (this.graph.dispose) {
        this.graph.dispose();
      }
      this.graph = initGraphPreView(this);
      const graphJson = JSON.parse(jsonData)
      _this.graph.fromJSON(graphJson);
      //节点绑定点击事件
      _this.graph.on('node:click', ({ e, x, y, node, view }) => {
        this.showDialog = true;
      })
      const cells = _this.graph.getCells();//返回画布中所有节点和边
      const nodeArr = _this.graph.getNodes();//返回画布中所有节点
      // console.log('nodeArr-节点', nodeArr)
      let minTop = 0;
      let maxDown = 0;
      let minLeft = 0;
      let maxRight = 0;
      let positionX = [];//节点x轴集合
      let positionY = [];//节点Y轴集合
      this.BindData = [];//绑定数据
      if (cells.length) {
        // console.log("cells", cells)
        cells.forEach((currentCell) => {
          if (currentCell.store.data.BindData) {
            let singleData = { ...currentCell.store.data.BindData, ...{ componentName: currentCell.store.data.componentName } }
            this.BindData.push(singleData)
          }
          if (currentCell.isNode()) {
            positionX.push(currentCell.store.data.position.x)
            positionY.push(currentCell.store.data.position.y)
            let width =
              Math.abs(currentCell.store.data.position.x) +
              currentCell.store.data.size.width;
            let height =
              Math.abs(currentCell.store.data.position.y) +
              currentCell.store.data.size.height;
            if (width > minLeft) {
              minLeft = width;
            }
            if (height > minTop) {
              minTop = height;
            }
            // console.log("window", window)
            if (minTop * 2 <= (window.innerHeight * 0.9) && minLeft * 2 < (window.innerWidth * 0.7)) {
              //设置画布大小
              // _this.graph.resize(window.innerWidth * 0.723, window.innerHeight * 0.9);
              _this.graph.resize('100%', '100%');
              var scrdom = document.getElementsByClassName("x6-graph-scroller");
              scrdom[0].style.overflow = "auto";
              // scrdom[0].style.overflow = "hidden";
            }
            // _this.graph.resize(minLeft*2, minTop*2)
            if (currentCell.store.data.data.actionType == "SVG_HTML") {
              let svgObjectDom = document.querySelectorAll(
                `g[data-cell-id="${currentCell.id}"] foreignObject g`
              );
              let svgElList = svgObjectDom[0] ? svgObjectDom[0].children : [];
              for (let i = 0; i < svgElList.length; i++) {
                svgElList[i].setAttribute(
                  "stroke-width",
                  currentCell.attrs?.props?.strokeWidth
                    ? currentCell.attrs?.props?.strokeWidth
                    : "1"
                );
                svgElList[i].setAttribute(
                  "stroke",
                  currentCell.attrs?.props?.stroke
                    ? currentCell.attrs?.props?.stroke
                    : "#000000"
                );
                if (svgElList[i].getAttribute("fill") != "none") {
                  svgElList[i].setAttribute(
                    "fill",
                    currentCell.attrs.props?.stroke
                  );
                }
              }
            } else if (currentCell.store.data.data.actionType == "SVG_TAG") {
              // let lineObjectDom = document.querySelectorAll(
              //   `g[data-cell-id="${currentCell.id}"] line`
              // );
              currentCell.setMarkup([
                {
                  tagName: "line",
                  selector: "line1",
                  groupSelector: "prop",
                  attrs: {
                    x1: "0",
                    y1: 19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                    x2: currentCell.store.data.size.width,
                    y2: 19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                    fill: "none",
                  },
                },
              ]);
              currentCell.attr("line", {
                stroke: currentCell.attrs.prop.stroke,
                strokeWidth: currentCell.attrs.prop.strokeWidth,
              });
            } else if (currentCell.store.data.data.actionType == "SVG_FONT") {
              // console.log(currentCell, 'currentCell')
              let svgObjectDom = document.querySelectorAll(
                `g[data-cell-id="${currentCell.id}"] foreignObject .wzbq`
              );
              svgObjectDom[0].innerText =
                currentCell.store.data.attrs.body.content;
              svgObjectDom[0].style.color =
                currentCell.store.data.attrs.body.color;
              svgObjectDom[0].style.fontSize =
                currentCell.store.data.attrs.body.fontSize + "px";
              svgObjectDom[0].style.fontWeight =
                currentCell.store.data.attrs.body.fontWeight;
              svgObjectDom[0].style.textAlign = 'left';
            }
          } else if (currentCell.isEdge()) {
            // debugger
            if (!currentCell.store.data.attrs.props) {
              currentCell.store.data.attrs.props = {
                stroke: "#000000",
                strokeWidth: 1,
              };
            }
            // 回显设置path data，如果没有target port设置。设置path data
            if (!currentCell.store.data.target.port) {
              let pathObjectDom = document.querySelectorAll(
                `g[data-cell-id="${currentCell.id}"] path`
              );
              for (var i = 0; i < pathObjectDom.length; i++) {
                let pathD = currentCell.store.data.attrs.path ? currentCell.store.data.attrs.path.d : '';
                pathObjectDom[i].setAttribute(
                  "d",
                  pathD
                );
              }
            }
            currentCell.attr("line", {
              stroke: currentCell.store.data.attrs.props.stroke,
              strokeWidth: currentCell.store.data.attrs.props.strokeWidth,
            });
          }
          currentCell.removeTools();
        });
      }
      // console.log('BindData', this.BindData)
      this.ponintIds = this.BindData.map(item => { return item.pointId });//电表id
      this.ponintIds = this.ponintIds.filter(item => item)
      this.ponintIds = Array.from(new Set(this.ponintIds))
      // let maxX = Math.max.apply(null, positionX);//x轴最大值
      // let minX = Math.min.apply(null, positionX);//x轴最小值
      // let maxY = Math.max.apply(null, positionY);//y轴最大值
      // let minY = Math.min.apply(null, positionY);//y轴最小值
      // let centerX = (maxX + minX) / 2;
      // let centerY = (maxY + minY) / 2;
      freezeGraph(_this.graph);
      // var scrdom = document.getElementsByClassName("x6-graph-scroller");
      // console.log('滚动条距离顶部距离1', scrdom[0].scrollTop)
      // console.log('滚动条横向滚动距离1', scrdom[0].scrollLeft)
      let container = document.getElementById("container");
      let appContainer = document.getElementsByClassName("app-container")
      // console.log('画布宽', container.offsetWidth);
      // console.log('画布高', container.offsetHeight);
      // console.log('容器宽', appContainer[0].offsetWidth);//固定
      // console.log('容器高', appContainer[0].offsetHeight);//固定
      // let ratio = appContainer[0].offsetWidth / appContainer[0].offsetHeight;//容器比值
      // console.log('比值', ratio);
      let zoomVal = 0;
      // if (container.offsetWidth > container.offsetHeight) {
      //   zoomVal = appContainer[0].offsetWidth / container.offsetWidth;
      // } else if (container.offsetWidth < container.offsetHeight) {
      //   zoomVal = appContainer[0].offsetHeight / container.offsetHeight;
      // } else {
      //   zoomVal = 0.5
      // }
      zoomVal = appContainer[0].offsetWidth / container.offsetWidth;
      _this.graph.zoom(-zoomVal);

      // 居中显示
      // debugger
      _this.graph.centerContent();
      // _this.graph.centerPoint(centerX, centerY);
      // if (this.whichTab == 'E') {
      //   _this.graph.zoom(-zoomVal);
      //   console.log('zoom1', _this.graph.zoom())
      // } else {
      //   var scrdom = document.getElementsByClassName("x6-graph-scroller");
      //   scrdom[0].scrollTop = 0;
      //   scrdom[0].scrollLeft = 0;
      //   _this.graph.zoom(0.7456140350877194 - 1.1);
      //   console.log('zoom1', _this.graph.zoom())
      // }
    },
    //获取列表数据
    takeRelaData () {
      let strponintIds = this.ponintIds.join(',')
      watchDevice(strponintIds).then(res => {
        let componentName = []
        if (res.ok) {
          this.BindData.forEach(item => {
            res.data.forEach(itemMeter => {
              let points = itemMeter.points;
              if (item.pointId == itemMeter.meterId) {
                if (this.whichTab == 'E') {
                  componentName = [...componentName, ...[{ name: item.componentName }]]
                } else {
                  let obj = {
                    name: item.componentName,
                    A相电压: points.A相电压,
                    B相电压: points.B相电压,
                    C相电压: points.C相电压
                  }
                  componentName = [...componentName, ...[obj]]
                }
              }

            })
          })
          // console.log(componentName, 'componentName')
          this.$emit('initTable', componentName)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //保存画布的位置
    savePosition () {
      var scrdom = document.getElementsByClassName("x6-graph-scroller");
      // console.log('滚动条距离顶部距离2', scrdom[0].scrollTop);
      // console.log('滚动条横向滚动距离2', scrdom[0].scrollLeft);
      // console.log('zoom2', this.graph.zoom())
      let graphPosition = [];
      let positionObj = {
        id: this.$route.query.id,
        scrollTop: scrdom[0].scrollTop,
        scrollLeft: scrdom[0].scrollLeft,
        zoomVal: this.graph.zoom()
      }
      graphPosition.push(positionObj)
      console.log('positionObj', positionObj);
    },
  },
  created () { },
  beforeDestroy() {
    window.clearInterval(judgeGraphDiv);
  },
  watch: {
    '$route': {
      handler (val) {
        // debugger
        if (val.query.id) {
          // 注册工具
          registerTools();
          // let currentTab = this.whichTab;
          // this.whichTab = 'E';
          this.diagramId = val.query.id;
          let requestID = this.diagramId + '-' + this.whichTab;
          Promise.all([this.getGraphData(requestID)]).then(() => {
            if (document.getElementById("container") === null) {
              const judgeGraphDiv = setInterval(() => {
                if (document.getElementById("container") !== null) {
                  window.clearInterval(judgeGraphDiv);
                  if (this.graphTotal.length > 0) {
                    this.init(this.graphTotal[0].diagramJson)
                  } else {
                    this.$emit('initTable', [])
                    this.graph.dispose();
                  }
                  // this.graphTotal[0] && this.init(this.graphTotal[0].diagramJson)
                }
              }, 100);
            } else {
              if (this.graphTotal.length > 0) {
                this.init(this.graphTotal[0].diagramJson)
              } else {
                this.$emit('initTable', [])
                this.graph.dispose();
              }
              // this.graphTotal[0] && this.init(this.graphTotal[0].diagramJson);
            }
          })
        }
      },
      immediate: true
    },
    whichTab: {
      handler (newVal, oldVal) {
        // debugger
        // this.graph = {}
        this.graph.dispose();
        let requestID = this.diagramId + '-' + newVal;
        Promise.all([this.getGraphData(requestID)]).then(() => {
          this.graphTotal[0] && this.init(this.graphTotal[0].diagramJson);
        })
      },
      immediate: false
    },
    chartId: {
      handler (newVal, oldVal) {
        this.graph.dispose();
        this.init(this.graphTotal[newVal].diagramJson);
      },
      immediate: false
    },
    ponintIds: {
      handler (newVal, oldVal) {
        if (newVal.length > 0) {
          this.takeRelaData();
        }
      },
      immediate: true
    }
  },
  mounted () {
    // 注册工具
    registerTools();
  }
};
</script>
<style scoped lang="less">
#components-layout .ant-layout-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  line-height: 50px;
  height: 50px;
  background: #fff;
  color: rgb(0, 0, 0);
  z-index: 2;
  box-shadow: 1px 1px 5px #ddd;
}

.pageMain {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  overflow: auto;
}

.leftNav {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: unset !important;
  max-width: unset !important;
  width: 260px !important;
  z-index: 1;
  overflow: auto;
  margin: 12px 20px 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 8px #888888;
  height: 96%;
  overflow: hidden;
}

.centerContain {
  position: absolute;
  left: 290px;
  right: 300px;
  top: 0;
  bottom: 0;
  z-index: 1;
  overflow: auto !important;
  overflow-x: auto !important;
  transition: all 0.3s;
  z-index: 111;
  width: 1535px;
  margin: 75px 14px 20px;

  .graph-main-container {
    width: 100%;
    height: 100%;
    flex: 1;
  }

  // tooltip
  #tooltip-container {
    // 禁止被双击||ctrl a选中
    user-select: none;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    font-size: 12px;
    color: #545454;
    width: 350px;
    word-break: break-all;
    background-color: rgba(255, 255, 255, 0.9);
    // padding: 10px 8px;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    position: absolute;
    z-index: 1;
    display: none;
    padding: 10px;
  }

  & .fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .canvas-content {
    width: 1920px;
    height: 1080px;
  }

  .btns-content {
    position: fixed;
    bottom: 10px;
    right: 320px;
    left: 280px;

    button {
      margin-left: 10px;
    }
  }
}

.rightNav {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: unset !important;
  max-width: unset !important;
  width: 300px !important;
  z-index: 1;
  overflow: auto;
}

.icon-shrink {
  position: fixed;
  right: 20px;
  top: 5px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  background: #1890ff;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border-radius: 50%;
  user-select: none;
  z-index: 1111;
}

#components-layout .ant-layout-sider {
  background: #fff;
  color: rgb(167, 164, 164);
}

#canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.ant-slider {
  margin-bottom: 16px;
}

.topo-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: left top;
  overflow: auto;

  background-image: linear-gradient(
      45deg,
      #ccc 25%,
      transparent 25%,
      transparent 75%,
      #ccc 75%,
      #ccc
    ),
    linear-gradient(
      45deg,
      #ccc 25%,
      transparent 25%,
      transparent 75%,
      #ccc 75%,
      #ccc
    );
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

.topo-layer-view-selected {
  outline: 1px solid #0cf;
}

.centerTop {
  border-radius: 15px;
  box-shadow: 0px 0px 8px #888888;
  width: 1530px;
  display: flex;
  justify-content: space-between;
  height: 50px;
  position: absolute;
  left: 295px;
  top: 5px;
  margin: 8px;
  padding-right: 29px;

  .leftTitle {
    width: 80%;
  }

  .leftTitle > span {
    width: 25%;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    /*// background: url("@/assets/images/backgroundTitle.png");*/
    border-right: 1px solid #c9d7f8;
    padding-left: 23px;
    font-size: 16px;

    [class^="el-icon-"],
    [class*=" el-icon-"] {
      float: right;
      height: 50px;
      line-height: 50px;
      margin-right: 20px;
      font-size: 16px;
      /*// display: none;*/
    }
  }

  .rightButton {
    width: 25%;
    text-align: right;
    height: 50px;
    line-height: 50px;
  }
}

.btn {
  font-size: 16px;
  width: 80px;
  left: 36px;
}

#canvas .operate {
  height: 54px;
  line-height: 54px;
  margin: 31px 0 0 33px;
  position: fixed;
  top: 127px;
  left: 366px;
  z-index: 9;
  border-radius: 10px;
  background: #fff;
}

#canvas .operate > ul {
  overflow: hidden;
  margin: 10px auto;
  padding: 0 10px;
}

#canvas .fl {
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  list-style: none;
  border: 1px dashed #ccc;
  cursor: pointer;
}

#canvas .prompt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 366px;
  height: 25px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3674ff;
  line-height: 107px;
  display: none;
}

.v-modal {
  display: none;
}

// /deep/.x6-graph-scroller .x6-graph > svg {
//   transform: scale(0.5);
// }
// /deep/.x6-graph-scroller.centerContain {
//   overflow: auto !important;
// }
</style>
