import { render, staticRenderFns } from "./AreaElectric.vue?vue&type=template&id=b96e984e&scoped=true&"
import script from "./AreaElectric.vue?vue&type=script&lang=js&"
export * from "./AreaElectric.vue?vue&type=script&lang=js&"
import style0 from "./AreaElectric.vue?vue&type=style&index=0&id=b96e984e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b96e984e",
  null
  
)

export default component.exports