<!-- 电路图编辑器页面 -->
<template>
    <div class="app-container">
        <div id="components-layout">
            <div>
                <div class="pageMain">
                    <div class="leftNav">
                        <LeftToolBar ref="LeftToolBar" :graph="graph"></LeftToolBar>
                        <LeftTemplate ref="LeftTemplate" @acceptFormChildrenTemplate="acceptTemplate"></LeftTemplate>
                    </div>
                    <div class="centerTop">
                        <!-- diagram-title -->
                        <div class="leftTitle">
                            <span class="navTop"
                                  v-for="(item, index) of navTopList"
                                  :key="index"
                                  @click="througthGroup(item, index)"
                            >
                                <span style="float: left">
                                    {{ item.diagramName.length  &lt; 30 ? item.diagramName : item.diagramName.substring(0, 30) + '...' }}
                                </span>
                                <i class="el-icon-close" @click="closeElement(index)"></i>
                            </span>
                        </div>
                        <!-- header-buttons -->
                        <div class="rightButton">
                            <el-button class="btntheme btn" @click="toBack">返回</el-button>
                            <el-button class="btntheme btn" @click="addComponent" v-if="!isTemplate">新增</el-button>
                            <el-upload class="upload-demo" multiple :limit="3"
                                       :action="uploadUrl"
                                       :on-success="importSuccess"
                                       :headers="{ Authorization: token }"
                                       :show-file-list="false"
                            >
                                <el-button class="btntheme btn">导入</el-button>
                            </el-upload>
                            <el-button class="btntheme btn" @click="download">导出</el-button>
                            <el-button class="btnadd btn" @click="save">保存</el-button>
                            <!-- hotkey-hints -->
                            <div class="hotkey-hints">
                                <el-tooltip class="item" effect="dark" placement="bottom-start">
                                    <div slot="content" style="font-size: 15px; line-height: 25px">
                                        <span v-for="(item, index) in tipsContent" :key="index">{{ item.label }}:{{ item.value }};<br /></span>
                                    </div>
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <!-- 悬浮窗 tooltip -->
                    <div id="tooltip-container">
                        {{ tooltipsContent }}
                    </div>
                    <div class="centerContain" :class="{ fixed: !shrink }">
                        <div id="canvas" class="canvas-content graphWidth bgc" >
                            <div class="operate">
                                <ul>
                                    <li class="fl" @mouseenter="toolTipShow('模板引用', $event)" @mouseleave="toolTipShow(false)" @click="templateRefe(0)" :disabled="!isTemplate">
                                        <img :src="downloadSrc" alt="" />
                                    </li>
                                    <li class="fl" @mouseenter="toolTipShow('预览', $event)" @mouseleave="toolTipShow(false)" @click="preview(1)" :disabled="!enablePreview">
                                        <img :src="watchSrc" alt="" />
                                    </li>
                                    <li class="fl" @mouseenter="toolTipShow('复制', $event)" @mouseleave="toolTipShow(false)" @click="copyComponent(2)" :disabled="!enableCopy">
                                        <img :src="copySrc" alt="" />
                                    </li>
                                    <li class="fl" @mouseenter="toolTipShow('删除', $event)" @mouseleave="toolTipShow(false)" @click="deleteComponent(3)" :disabled="!enableDelete">
                                        <img :src="deleteSrc" alt="" />
                                    </li>
                                    <li class="fl" @mouseenter="toolTipShow('配置属性', $event)" @mouseleave="toolTipShow(false)" @click="configComponent(4)" :disabled="!enableConfig">
                                        <img :src="configSrc" alt="" />
                                    </li>
                                </ul>
                            </div>
                            <div class="operate">
                                <ul>
                                    <li @mouseenter="toolTipShowAlign('左对齐', 2, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="leftAlignMent(5)" :disabled="!enableAlign">
                                        <img :src="leftAlignSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('水平居中对齐', 2, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="centerAlignMent(6)" :disabled="!enableAlign">
                                        <img :src="centerAlignSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('右对齐', 2, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="rightAlignMent(7)" :disabled="!enableAlign">
                                        <img :src="rightAlignSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('上对齐', 2, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="topAlignMent(8)" :disabled="!enableAlign">
                                        <img :src="topAlignSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('垂直居中对齐', 2, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="perpendicularAlignMent(7)" :disabled="!enableAlign">
                                        <img :src="perpendicularAlignSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('下对齐', 2, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="bootomAlignMent(9)" :disabled="!enableAlign">
                                        <img :src="bootomAlignSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('水平等距', 2, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="levelAlignMent(10)" :disabled="!enableCenter">
                                        <img :src="levelAlignSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('垂直等距', 2, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="verticalAlignMent(11)" :disabled="!enableCenter">
                                        <img :src="verticalAlignSrc" alt="" />
                                    </li>
                                </ul>
                            </div>
                            <div class="operate">
                                <ul>
                                    <li @mouseenter="toolTipShowAlign('编组', 3, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="groupComponent" :disabled="!enableGroup">
                                        <img :src="groupSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('取消编组', 3, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="cancelGroupComponent" :disabled="!enableCancelGroup">
                                        <img :src="cancelGroupSrc" alt="" />
                                    </li>
                                    <li @mouseenter="toolTipShowAlign('解绑', 3, $event)" @mouseleave="toolTipShowAlign(false)" class="fl" @click="unbind" :disabled="!enableUnbind">
                                        <img :src="unbindSrc" alt="" />
                                    </li>
                                    <li class="fl" @mouseenter="toolTipShowAlign('调色板', 3, $event)" @mouseleave="toolTipShowAlign(false)">
                                        <el-color-picker v-model="currentColor"  show-alpha
                                                         size="mini"
                                                         :predefine="predefineColors"
                                                         :disabled="!enableColor"
                                                         @change="changeColor"
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div class="prompt">请新增图形</div>
                            <!-- 画布 -->
                            <!-- 图形容器 -->
                            <div id="container" class="graph-main-container" />
                        </div>
                    </div>
                </div>
            </div>
            <RightDrawer1 ref="RightDrawer"
                          v-bind:items="item"
                          :svgInfo="selectSvgInfo"
                          @transmitDataObj="BindObj"
                          @changSvg="SvgStyle"
                          @setTableAttr="setTableAttr"
            />
            <EdgeRightDrawer1 ref="EdgeRightDrawer"
                              v-bind:items="item"
                              :svgInfo="selectSvgInfo"
                              @transmitDataObj="BindObj"
                              @changSvg="EdgeSvgStyle"
            />
        </div>
        <!-- 新增弹出框 -->
        <el-dialog title="添加图形" :visible.sync="open" width="800px" append-to-body class="dialogBgc">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="图形名称" prop="diagramName">
                    <el-input v-model="form.diagramName" placeholder="请输入图形名称" />
                </el-form-item>
                <el-form-item label="图形编码" prop="diagramcode">
                    <el-input v-model="form.diagramcode" placeholder="请输入图形编码" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel" class="cancelBtn">取 消</el-button>
                <el-button @click="submitForm" class="sureBtn">确 定</el-button>
            </div>
        </el-dialog>
        <div id="tooltip" aria-hidden="true"
             class="el-tooltip__popper is-dark"
             style="
                    transform-origin: left center;
                    z-index: 2068;
                    top: 82px;
                    display: none;
                    left: 21px;
                  "
        >
            <div><span>模板引用</span></div>
            <div x-arrow="" class="popper__arrow" style="top: 11px"></div>
        </div>
        <div id="tooltip1" aria-hidden="true" class="el-tooltip__popper is-light" style="transform-origin: left center; z-index: 2068; display: none">
            <div><span>模板引用</span></div>
            <div x-arrow="" class="popper__arrow" style="top: 11px"></div>
        </div>
        <div id="minimap" class="minimap-container minimapBgc" />
    </div>
</template>
<script>
import {
    addDiagram,
    exportDiagram,
    getDiagramById,
    updateDiagram,
    uploadSave,
} from "@/api/diagram/main.js";
import { exportTemplates, updateTemplates } from "@/api/diagram/templates";
import LeftToolBar from "@/components/diagram/LeftToolBar1.vue";
//import LeftTemplate from "@/components/diagram/LeftTemplate1.vue";
import RightDrawer1 from '@/components/diagram/RightDrawer1.vue';
import EdgeRightDrawer1 from '@/components/diagram/EdgeRightDrawer1.vue';
import html2canvas from "html2canvas";
import global from "@/global/global.js"; //全局变量
import store from "@/store";
import registerTools from "@/packages/x6/tools/registerTools.js";
import { initGraph } from "@/packages/x6/common/graph";
import { Config, CustomEventTypeEnum } from "@/packages/x6/common/enums.js";
import { deleteCells, onPaste } from "@/packages/x6/common/trigger";
import { Channel } from "@/packages/x6/common/transmit.js";
import { getSvgNodeByHtml } from "@/packages/x6/common/transform";
import { setSvgAttrs } from "@/packages/x6/common/index";

export default {
    name: 'DiagramEdit',
    components: {
        LeftToolBar,
        RightDrawer1,
        EdgeRightDrawer1,
        LeftTemplate: () => import('@/components/diagram/LeftTemplate1.vue'),
    },
    data () {
        return {
            graph: {},
            tooltipsContent: null,
            SvgComponentScale: 0,
            largeIndex: 0,
            roateIndex: 0,
            isLargrFun: false,
            isRoateFun: false,
            largeId: null,
            rotateId: null,
            isDown: false,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            w: 0,
            h: 0,
            isMouseDown: false,
            moveXY: {},
            //旋转图标路径
            rotateSrc: require("@/assets/icons/diagram/rotate.png"),
            //模板引用图标路径
            downloadSrc: require("@/assets/icons/diagram/download.png"),
            watchSrc: require("@/assets/icons/diagram/watch.png"),
            copySrc: require("@/assets/icons/diagram/copy.png"),
            deleteSrc: require("@/assets/icons/diagram/delete.png"),
            configSrc: require("@/assets/icons/diagram/save.png"),
            leftAlignSrc: require("@/assets/icons/diagram/left.png"),
            centerAlignSrc: require("@/assets/icons/diagram/center.png"),
            rightAlignSrc: require("@/assets/icons/diagram/right.png"),
            topAlignSrc: require("@/assets/icons/diagram/top.png"),
            bootomAlignSrc: require("@/assets/icons/diagram/bottom.png"),
            levelAlignSrc: require("@/assets/icons/diagram/level.png"),
            verticalAlignSrc: require("@/assets/icons/diagram/vertical.png"),
            perpendicularAlignSrc: require("@/assets/icons/diagram/perpendicular.png"),
            groupSrc: require("@/assets/icons/diagram/group.png"),
            cancelGroupSrc: require("@/assets/icons/diagram/cancelGroup.png"),
            unbindSrc: require("@/assets/icons/diagram/unbind.jpg"),
            //点击导航diagram对象
            diagramObj: {},
            //暂时保存各个diagram的svg画布
            canvasArr: [],
            uploadUrl: process.env.VUE_APP_BASE_API + "/diagram/main/import", // 上传的图片服务器地址
            //点击浏览的元件器
            svgObj: {},
            selectDom: { width: 0, height: 0 },
            selects: false,
            // 判断设计svg是否保存
            //是否能删除
            enableDelete: global.enableDelete,
            //是否能复制
            enableCopy: global.enableCopy,
            //是否能预览
            enablePreview: global.enablePreview,
            enableConfig: global.enableConfig,
            enableAlign: global.enableAlign,
            enableCenter: global.enableCenter,
            enableColor: global.enableColor,
            enableGroup: global.enableGroup,
            enableCancelGroup: global.enableCancelGroup,
            enableUnbind: global.enableUnbind,
            globals: global,
            isSave: true,
            token: "Bearer " + this.$store.getters.token,
            form: {},
            drawing: false,
            open: false,
            tipsContent: [
                {
                    label: "Ctrl+a",
                    value: "单元全选",
                },
                {
                    label: "Ctrl+c",
                    value: "单元复制",
                },
                {
                    label: "Ctrl+v",
                    value: "单元粘贴",
                },
                {
                    label: "Delete / BackSpace",
                    value: "单元删除",
                },
                {
                    label: "Ctrl+z",
                    value: "单元撤销",
                },
                {
                    label: "Ctrl+y",
                    value: "单元重做",
                },
                {
                    label: "Esc",
                    value: "取消所有选中单元",
                },
                {
                    label: "Alt+h",
                    value: "帮助信息",
                },
                {
                    label: "Alt+f",
                    value: "画布居中",
                },
                {
                    label: "Ctrl+mouseWheel",
                    value: "放大/缩小",
                },
                {
                    label: "Ctrl+leftMouseDown",
                    value: "框选",
                },
            ],
            rules: {
                diagramName: [ { required: true, message: "图形名称不能为空", trigger: "blur" },],
            },
            visible: true,
            shrink: true, //收缩状态  true收缩  false展开
            //右边弹出框的数据
            item: {
                rotate: 0,
                height: 30,
                width: 30,
                x: 380,
                y: 340,
                color: "#000000",
                strokeDasharray: 0,
                strokeWidth: 2,
                title: "手车开关",
                content: "文字标签",
                fontSize: 12,
                fontWeight: 400,
                fontFamily: "黑体",
                type: "SVG_HTML",
                template: "",
                keys: "数据项",
                values: "数据格",
            },
            resultJson: {},
            svgLists: [],
            selectSvgtLists: [],
            selectLists: [],
            rectObj: null,
            isTemplateObj: "",
            //鼠标位置
            mousePosition: {
                positionX: "",
                positiony: "",
            },
            timer: undefined,
            selectType: 1, //选中的类型1=node，2=edge
            selectSvgInfo: "",
            //导航栏的导航框
            navTopList: [],
            activeNav: 0,
            navSrc: require("@/assets/images/backgroundTitleSelect.png"),
            lineStatus: false,
            importFlag: false,
            currentColor: null,
            predefineColors: [
                "rgba(255, 69, 0, 1)",
                "rgba(255, 140, 0, 1)",
                "rgba(255, 215, 0, 1)",
                "rgba(144, 238, 144, 1)",
                "rgba(0, 206, 209, 1)",
                "rgba(30, 144, 255, 1)",
                "rgba(199, 21, 133, 1)",
                "rgba(255, 69, 0, 0.68)",
                "rgba(255, 120, 0, 1)",
                "rgba(250, 212, 0, 1)",
            ],
        };
    },
    computed: {
        AllowTake () {
            return this.$store.state.global.enableCopy;
        },
        AllowConfig () {
            return this.$store.state.global.enableConfig;
        },
        AllowAlign () {
            return this.$store.state.global.enableAlign;
        },
        AllowCenter () {
            return this.$store.state.global.enableCenter;
        },
        AllowColor () {
            return this.$store.state.global.enableColor;
        },
        AllowGroup () {
            return this.$store.state.global.enableGroup;
        },
        AllowCancelGroup () {
            return this.$store.state.global.enableCancelGroup;
        },
        AllowUnbind () {
            return this.$store.state.global.enableUnbind;
        },
        // 可操作去插槽
        panelAreaName () {
            return Config.PANEL_AREA_SLOT;
        },
    },
    methods: {
        openPrompt () {
            const cells = this.graph.getSelectedCells();
            console.log("111111");
            console.log(cells[0]);
            let componentsData = cells[0].store.data;
            let obj = {
                componentId: this.uuid(32),
                componentName: "断路器",
                componentClass: "svg_html",
                componentType: "SVG_HTML",
                template: "1_dlq",
                sourceUrl: cells[0].store.data.sourceUrl,
                initData: cells[0].store.data.initData,
                color: "#000000",
                x: cells[0].store.data.position.x,
                y: cells[0].store.data.position.y,
            };
            let json = getSvgNodeByHtml({
                shape: "html",
                tooltip: obj.componentName,
                size: { width: 40, height: 40 },
                actionType: "SVG_HTML",
                initialization: true,
                x: obj.x,
                y: obj.y,
                componentId: obj.componentId,
                componentName: obj.componentName,
                template: obj.template,
                sourceUrl: obj.sourceUrl,
                initData: obj.initData,
                color: obj.color,
            });
            let _this = this;
            // this.$nextTick(()=>{
            const node = _this.graph.createNode(json);
            _this.graph.addNode(node);
            console.log(node, "node");
            node.attrs.props.strokeWidth = componentsData.attrs.props.strokeWidth;
            // node.store.data.componentName = componentsData.componentName;
            node.attrs.props.stroke = componentsData.attrs.props.stroke;
            node.rotate(componentsData.angle, { absolute: true });
            node.position(
                parseInt(componentsData.position.x),
                parseInt(componentsData.position.y)
            );
            node.size(componentsData.size.height, componentsData.size.height);
            _this.graph.removeNode(cells[0]);
            let resultJson = eval("(" + _this.diagramObj.diagramJson + ")");
            resultJson.cells.push(node.store.data);
            _this.diagramObj.diagramJson = JSON.stringify(resultJson);
            console.log(resultJson, " _this.diagramObj.diagramJson");
            return true;
            // })
        },
        toolTipShow (param, e) {
            if (param) {
                document.getElementById("tooltip").style.top =
                    e.target.offsetTop + 40 + "px";
                document.getElementById("tooltip").style.left =
                    e.target.offsetLeft + 326 - param.length * 6 + "px";
                document.getElementById("tooltip").querySelector("span").innerText =
                    param;
                document.getElementById("tooltip").style.display = "block";
            } else {
                document.getElementById("tooltip").style.display = "none";
            }
        },
        toolTipShowAlign (param, group, e) {
            let dx = group == 2 ? 562 : 936;
            if (param) {
                document.getElementById("tooltip").style.top =
                    e.target.offsetTop + 40 + "px";
                document.getElementById("tooltip").style.left =
                    e.target.offsetLeft + dx - param.length * 6 + "px";
                document.getElementById("tooltip").querySelector("span").innerText =
                    param;
                document.getElementById("tooltip").style.display = "block";
            } else {
                document.getElementById("tooltip").style.display = "none";
            }
        },
        initListenerCustomEvent () {
            Channel.eventListener(
                CustomEventTypeEnum.TOOLTIPS_CALLBACK,
                (content) => (this.tooltipsContent = content)
            );
            // emit 节点单击
            Channel.eventListener(CustomEventTypeEnum.NODE_CLICK, (detail) =>
                this.$emit("node-click", detail)
            );
            // emit 节点双击
            Channel.eventListener(CustomEventTypeEnum.DOUBLE_NODE_CLICK, (detail) =>
                this.$emit("node-dblclick", detail)
            );
        },
        // 全屏
        fullScreen () {
            let _this = this;
            this.shrink = !this.shrink;
            let element = document.documentElement;
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            }
            // 监听全屏后esc事件
            window.onresize = function () {
                if (!checkFull()) {
                    _this.shrink = !_this.shrink;
                }
            };

            function checkFull () {
                var isFull =
                    document.fullscreenElement ||
                    document.mozFullScreenElement ||
                    document.webkitFullscreenElement;
                if (isFull === undefined) isFull = false;
                return isFull;
            }
        },
        //关闭组件
        closeElement (index) {
            this.$confirm(
                `请在关闭前对"${this.navTopList[index].diagramName}"进行保存`,
                "提示",
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "保存",
                    cancelButtonText: "不保存",
                    type: "warning",
                }
            )
                .then(() => {
                    let resultJosn = this.graph.toJSON();
                    if (resultJosn.cells.length == 0) {
                        this.$message.warning("请选择您要保存的内容");
                    } else {
                        this.save();
                        this.navTopList.splice(index, 1);
                        this.$nextTick(() => {
                            // DOM 更新了
                            let backgroundNaList = document.getElementsByClassName("navTop");
                            if (this.navTopList.length == 0) {
                                // this.graph = {};
                            } else {
                                backgroundNaList[0].click();
                            }
                        });
                    }
                })
                .catch((action) => {
                    //判断是不是点击取消还是不保存并退出页面
                    if (action === "cancel") {
                        this.graph.dispose();
                        this.navTopList.splice(index, 1);
                        this.$nextTick(() => {
                            // DOM 更新了
                            let backgroundNaList = document.getElementsByClassName("navTop");
                            if (this.navTopList.length == 0) {
                                this.graph = {};
                            } else {
                                backgroundNaList[0].click();
                            }
                        });
                    }
                });
        },
        througthGroup (item, index) {
            this.isSave = false;
            this.activeNav = index
            console.log(item, "itemssss");
            if (this.graph.dispose) {
                this.graph.dispose();
            }
            this.initGraphInPage();
            if (this.importFlag) {
                this.graph.fromJSON(this.resultJson);
            }
            if (item.diagramJson == null) {
                // this.graph = initGraph(this);
                this.isSave = true;
            } else {
                // this.graph = initGraph(this);
                // console.log(eval(item.diagramJson));
                console.log(JSON.parse(item.diagramJson));
                const graphJson = JSON.parse(item.diagramJson);
                this.graph.fromJSON(graphJson);
                this.resultJson = graphJson
                // console.log(JSON.parse(this.graph));
            }
            this.diagramObj = item;
            // console.log(this.svgLists, "thisglist");
            let backgroundNaList = document.getElementsByClassName("navTop");
            let closeList = document.getElementsByClassName("el-icon-close");
            for (const navIndex in this.navTopList) {
                if (navIndex == index) {
                    let url1 = require("@/assets/images/backgroundTitleSelect.png");
                    backgroundNaList[navIndex].style.backgroundImage =
                        "url(" + url1 + ")";
                    closeList[navIndex].style.display = "block";
                } else {
                    let url2 = require("@/assets/images/backgroundTitle.png");
                    backgroundNaList[navIndex].style.backgroundImage =
                        "url(" + url2 + ")";
                    closeList[navIndex].style.display = "none";
                }
            }

        },
        //添加组件
        addComponent () {
            this.reset();
            this.open = true;
            // console.log(JSON.stringify(this.svgLists));
            // alert(JSON.stringify(this.svgLists));
        },
        toBack () {
            // if (!this.isTemplate){
            //     this.$router.push({ path: "/diagram/main" }).catch(() => {});
            // }else {
            //     this.$router.push({ path: "/diagram/templates" }).catch(() => {});
            // }
            this.$router.go(-1);
        },
        copyComponent () {
            console.log(this);
            this.enableCopy = global.enableCopy;
            if (this.enableCopy) {
                let CopyInfo = onPaste(this.graph);
                console.log(CopyInfo, "CopyInfo");

                if (CopyInfo[0].store.data.shape == "html") {
                    let svgObjectDom = document.querySelectorAll(
                        `g[data-cell-id="${CopyInfo[0].id}"] foreignObject g`
                    );
                    // console.log('svgObjectDom', svgObjectDom[0].getAttribute('width'), svgObjectDom[0].getAttribute('height'));
                    if (svgObjectDom.length != 0) {
                        let svgElList = svgObjectDom[0].children;
                        for (let i = 0; i < svgElList.length; i++) {
                            svgElList[i].setAttribute(
                                "stroke-width",
                                CopyInfo[0].attrs.props.strokeWidth
                            );
                            svgElList[i].setAttribute(
                                "stroke",
                                CopyInfo[0].attrs.props.stroke
                            );
                            if (svgElList[i].getAttribute("fill") != "none") {
                                svgElList[i].setAttribute(
                                    "fill",
                                    CopyInfo[0].attrs.props.stroke
                                );
                            }
                        }
                    } else {
                        let svgObjectDomwzbq = document.querySelectorAll(
                            `g[data-cell-id="${CopyInfo[0].id}"] foreignObject .wzbq`
                        );
                        console.log(svgObjectDomwzbq, "svgObjectDomwzbq");
                        let svgElListwzbq = svgObjectDomwzbq[0].children;
                        for (let i = 0; i < svgElListwzbq.length; i++) {
                            svgElListwzbq[i].setAttribute(
                                "value",
                                CopyInfo[0].attrs.body.content
                            );
                            svgElListwzbq[i].setAttribute(
                                "style",
                                `width: 70px;border: none;outline: none;text-align: left;background-color: rgba(0,0,0,0);font-size:${CopyInfo[0].attrs.body.fontSize}px;font-family:${CopyInfo[0].attrs.body.fontFamily};font-weight:${CopyInfo[0].attrs.body.fontWeight};color:${CopyInfo[0].attrs.body.color}`
                            );
                        }
                    }
                }
                let resultJson = eval("(" + this.diagramObj.diagramJson + ")");
                CopyInfo[0].store.data.componentId = this.uuid(32);
                // CopyInfo[0].id=this.uuid(32)
                resultJson.cells.push(CopyInfo[0].store.data);
                this.diagramObj.diagramJson = JSON.stringify(resultJson);
            }
        },
        deleteComponent () {
            this.enableDelete = global.enableDelete;
            if (this.enableDelete) {
                deleteCells(this.graph);
                if (this.graph.getSelectedCells().length < 1) {
                    this.enableColor = false;
                    global.enableColor = false;
                    global.enableGroup = false;
                    global.enableCancelGroup = false;
                    global.enableUnbind = false;
                }
            }
        },
        preview () {
            document.getElementById("container").click();
            localStorage.setItem("diagramObj", JSON.stringify(this.diagramObj));
            window.open(
                `/diagram-front/preview1?diagramId=${this.diagramObj.diagramId}`
            );
        },
        configComponent () {
            const cells = this.graph.getSelectedCells();
            if (cells.length > 0) {
                // 多选
                this.item = {};
                if (cells[0].isNode()) {
                    if (cells[0].store.data.data.actionType == "SVG_FONT") {
                        this.item.title = cells[0].store.data.componentName;
                        this.item.x = cells[0].store.data.position.x;
                        this.item.y = cells[0].store.data.position.y;
                        this.item.width = cells[0].store.data.size.width;
                        this.item.height = cells[0].store.data.size.height;
                        this.item.rotate = cells[0].store.data.angle || 0;
                        this.item.color = cells[0].store.data.attrs.body.color;
                        this.item.content = cells[0].store.data.attrs.body.content;
                        this.item.fontSize = cells[0].store.data.attrs.body.fontSize;
                        this.item.fontWeight = cells[0].store.data.attrs.body.fontWeight;
                        this.item.fontFamily = cells[0].store.data.attrs.body.fontFamily;
                        this.item.template = cells[0].store.data.template;
                        this.item.type = cells[0].store.data.data.actionType;
                        this.item.dataStatus = cells[0].store.data.BindData;
                        this.item.id = cells[0].id;
                    } else if (cells[0].store.data.data.actionType == "SVG_TAG") {
                        this.item.title = cells[0].store.data.componentName;
                        this.item.x = cells[0].store.data.position.x;
                        this.item.y = cells[0].store.data.position.y;
                        this.item.height = cells[0].store.data.size.width;
                        this.item.rotate = cells[0].store.data.angle || 0;
                        this.item.strokeWidth =
                            cells[0].store.data.attrs.prop["stroke-width"];
                        this.item.color = cells[0].store.data.attrs.prop.stroke;
                        this.item.template = cells[0].store.data.template;
                        this.item.type = cells[0].store.data.data.actionType;
                        this.item.dataStatus = cells[0].store.data.BindData;
                        this.item.id = cells[0].id;
                        if (this.item.template.indexOf("1_xl") != -1) {
                            this.item.strokeDasharray =
                                cells[0].store.data.attrs.prop["stroke-dasharray"];
                            this.item.strokeLinecap =
                                cells[0].store.data.attrs.prop["stroke-linecap"];
                        }
                        console.log('-------------', this.item)
                    } else if (cells[0].store.data.data.actionType == "SVG_HTML") {
                        this.item.title = cells[0].store.data.componentName;
                        this.item.x = cells[0].store.data.position.x;
                        this.item.y = cells[0].store.data.position.y;
                        this.item.width = cells[0].store.data.size.width;
                        this.item.height = cells[0].store.data.size.height;
                        this.item.rotate = cells[0].store.data.angle || 0;
                        this.item.strokeWidth = cells[0].store.data.attrs.props.strokeWidth;
                        this.item.color = cells[0].store.data.attrs.props.stroke;
                        this.item.template = cells[0].store.data.template;
                        this.item.type = cells[0].store.data.data.actionType;
                        this.item.dataStatus = cells[0].store.data.BindData;
                        this.item.id = cells[0].id;
                    } else if (cells[0].store.data.data.actionType == "SVG_TABLE") {
                        this.item.id = cells[0].id;
                        this.item.title = cells[0].store.data.componentName;
                        this.item.x = cells[0].store.data.position.x;
                        this.item.y = cells[0].store.data.position.y;
                        this.item.height = cells[0].store.data.size.height;
                        this.item.rotate = cells[0].store.data.angle || 0;
                        this.item.fontSize = cells[0].store.data.attrs.text.fontSize;
                        this.item.fontWeight = cells[0].store.data.attrs.text.fontWeight;
                        this.item.fontFamily = cells[0].store.data.attrs.text.fontFamily;
                        this.item.cols = cells[0].store.data.attrs.body.cols;
                        this.item.rows = cells[0].store.data.attrs.body.rows;
                        this.item.template = cells[0].store.data.template;
                        this.item.type = cells[0].store.data.data.actionType;
                    } else if (cells[0].store.data.data.actionType == "SVG_KEYVLAUE") {
                        this.item.id = cells[0].id;
                        this.item.title = cells[0].store.data.componentName;
                        this.item.x = cells[0].store.data.position.x;
                        this.item.y = cells[0].store.data.position.y;
                        this.item.height = cells[0].store.data.size.height;
                        this.item.rotate = cells[0].store.data.angle || 0;
                        this.item.fontSize = cells[0].store.data.attrs.text.fontSize;
                        this.item.fontWeight = cells[0].store.data.attrs.text.fontWeight;
                        this.item.fontFamily = cells[0].store.data.attrs.text.fontFamily;
                        this.item.keys = cells[0].store.data.attrs.body.keys;
                        this.item.color = cells[0].store.data.attrs.body.color;
                        this.item.values = cells[0].store.data.attrs.body.values;
                        this.item.template = cells[0].store.data.template;
                        this.item.type = cells[0].store.data.data.actionType;
                        this.item.dataStatus = cells[0].store.data.BindData;
                    } else {
                        this.item.id = cells[0].id;
                        this.item.title = cells[0].store.data.componentName;
                        this.item.x = cells[0].store.data.position.x;
                        this.item.y = cells[0].store.data.position.y;
                        this.item.height = cells[0].store.data.size.width;
                        this.item.rotate = cells[0].store.data.angle || 0;
                        this.item.strokeWidth = cells[0].store.data.attrs.props.strokeWidth;
                        this.item.color = cells[0].store.data.attrs.props.stroke;
                        this.item.type = cells[0].store.data.data.actionType;
                        this.item.template = cells[0].store.data.template;
                        this.item.dataStatus = cells[0].store.data.BindData;
                    }
                } else {
                    this.item.id = cells[0].id;
                    this.item.title = cells[0].store.data.componentName
                        ? cells[0].store.data.componentName
                        : "";
                    if (!cells[0].store.data.attrs.props) {
                        cells[0].store.data.attrs.props = {
                            strokeWidth: 1,
                            stroke: "#000000",
                        };
                    }
                    this.item.strokeWidth = cells[0].store.data.attrs.props.strokeWidth;
                    this.item.color = cells[0].store.data.attrs.props.stroke;
                    this.item.strokeDasharray =
                        cells[0].store.data.attrs.line.strokeDasharray == ""
                            ? 0
                            : cells[0].store.data.attrs.line.strokeDasharray;
                    if (!cells[0].store.data.attrs.line.targetMarker) {
                        cells[0].store.data.attrs.line.targetMarker = { name: "", size: 6 };
                    }
                    this.item.targetMarker =
                        cells[0].store.data.attrs.line.targetMarker.name;
                    this.item.targetMarkerSize =
                        cells[0].store.data.attrs.line.targetMarker.size;
                }
            }
            this.enableConfig = global.enableConfig;
            if (this.enableConfig) {
                // this.item = global;
                if (this.selectType == 1) {
                    this.$refs.RightDrawer.show = true;
                } else {
                    this.$refs.EdgeRightDrawer.show = true;
                }
            } else {
                if (this.selectType == 1) {
                    this.$refs.RightDrawer.show = false;
                } else {
                    this.$refs.EdgeRightDrawer.show = false;
                }
            }
        },
        judgeSelectedDraw () {
            this.selectSvgtLists = [];
            this.selectLists.forEach((item) => {
                item.display = "none";
            });

            console.log("选中", this.selectSvgtLists);
            if (this.selectSvgtLists.length > 0) {
                this.copyComponent = false;
                this.selects = true;
                this.copySrc = require("@/assets/icons/diagram/Allowcopy.png");
                this.deleteSrc = require("@/assets/icons/diagram/Allowdelete.png");
            }
            if (this.selectSvgtLists.length == 1) {
                this.svgObj = this.selectSvgtLists[0];
                this.selects = false;
                this.copySrc = require("@/assets/icons/diagram/Allowcopy.png");
                this.deleteSrc = require("@/assets/icons/diagram/Allowdelete.png");
            }
        },
        displayDialog () {
            /* $("#dialog_drawInfo").dialog({
                                            closed : false,
                                            modal : true,
                                            title : "操作选中下列图形："
                                          });*/
        },
        //鼠标移动，更新矩形框
        importSuccess (res) {
            console.log(res.msg);
            this.resultJson = JSON.parse(res.msg);
            this.graph.fromJSON(this.resultJson);
            this.importFlag = true;
            /*for (let i = 0; i < msg.length; i++) {
                                          this.svgLists.push(msg[i]);
                                        }
                                        this.diagramObj.diagramJson = eval(this.svgLists);*/
        },
        //退出全屏
        exitFullscreen () {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        },
        testE () {
            //this.svgLists = global.AnalogData;
        },
        testH () {
            localStorage.setItem("svginfo", JSON.stringify(this.svgLists));
            this.$router.push({
                path: "/CircuitPreview",
                name: "CircuitPreview",
            });
        },
        testAA () {
            this.$router.push({
                path: "/CircuitPreview",
                name: "CircuitPreview",
            });
        },
        //设置表格属性
        setTableAttr (id, rowCount, colCount) {
            const cells = this.graph.getSelectedCells();
            //根据当前id找到当前表格的index
            // let tableIndex = cells.indexOf(cells.filter((f) => f.id == id)[0]);
            // console.log(tableIndex);
            // if (tableIndex == -1) {
            //   return;
            // }
            // let svgType = this.svgLists.filter((f) => f.id == id)[0].type;
            // if (svgType != "TableSvg") {
            //   return;
            // }
            let tableData = [];
            for (let r = 0; r < rowCount; r++) {
                let tableColDataList = [];
                for (let c = 0; c < colCount; c++) {
                    if (
                        cells[0].store.data.tableData.length >= r + 1 &&
                        cells[0].store.data.tableData[r].cols.length >= c + 1
                    ) {
                        tableColDataList.push(cells[0].store.data.tableData[r].cols[c]);
                    } else {
                        let tableColData = {
                            id: this.uuid(32),
                            val: `${r + 1}行${c + 1}列`,
                        };
                        tableColDataList.push(tableColData);
                    }
                }
                let tableRowData = {
                    cols: tableColDataList,
                };
                tableData.push(tableRowData);
            }
            cells[0].store.data.tableData = tableData;
        },
        // 导出图片
        exportPNG () {
            this.graph.toPNG((dataUri, string) => {
                // 下载
                // DataUri.downloadDataUri(dataUri, 'chart.png')
                // console.log(dataUri);
                uploadSave(dataUri, this.diagramObj.diagramId).then((response) => {
                    console.log(response, "response");
                });
            });
        },
        //保存
        save () {
            // this.exportPNG();
            let _this = this;
            let resultJosn = this.graph.toJSON();
            _this.resultJson = this.graph.toJSON();

            console.log(this.graph.toJSON());
            if (resultJosn.cells.length == 0) {
                this.$message.warning("请选择您要保存的内容");
            } else {
                const operate = document.getElementsByClassName("operate");
                operate[0].style.display = "none";
                operate[1].style.display = "none";
                operate[2].style.display = "none";
                // resultJosn = this.graph.toJSON();
                if (this.isTemplate) {
                    let obj = {};
                    obj.diagramId = this.diagramObj.diagramId;
                    obj.diagramJson = JSON.stringify(resultJosn);
                    this.navTopList[this.activeNav].diagramJson = JSON.stringify(resultJosn);
                    obj.scale =
                        this.graph.zoom() > 1
                            ? this.graph.zoom() - 1
                            : -(1 - this.graph.zoom());
                    updateTemplates(obj)
                        .then((response) => {
                            this.msgSuccess("修改成功");
                            this.isSave = true;
                            // this.exportPNG();
                            this.upload();
                        })
                        .catch((err) => {
                            operate[0].style.display = "block";
                            operate[1].style.display = "block";
                            operate[2].style.display = "block";
                        });
                } else {
                    let obj = {};
                    obj.diagramId = this.diagramObj.diagramId;
                    obj.diagramJson = JSON.stringify(resultJosn);
                    this.navTopList[this.activeNav].diagramJson = JSON.stringify(resultJosn);
                    obj.scale =
                        this.graph.zoom() > 1
                            ? this.graph.zoom() - 1
                            : -(1 - this.graph.zoom());
                    console.log(resultJosn);
                    updateDiagram(obj)
                        .then((response) => {
                            this.msgSuccess("修改成功");
                            this.isSave = true;
                            // this.exportPNG();
                            this.upload(obj.scale);
                            // if (_this.navTopList.length == 0) {
                            //     this.graph.dispose();
                            //     this.graph = {}
                            // }
                        })
                        .catch((err) => {
                            operate[0].style.display = "block";
                            operate[1].style.display = "block";
                            operate[2].style.display = "block";
                        });
                }
            }
        },
        //导入
        upload (zoom) {
            const operate = document.getElementsByClassName("operate");
            this.$nextTick(() => {
                // DOM 更新了
                let backgroundNaList = document.getElementsByClassName("navTop");
                if (this.navTopList.length == 0) {
                    this.graph.dispose();
                    this.graph = {};
                } else {
                    backgroundNaList[this.activeNav].click();
                    this.graph.zoom(zoom);
                }
            });
            const screenshot = document.getElementById("canvas");
            const opts = {
                logging: false,
                scale: 0.5,
                useCORS: true,
            };
            html2canvas(screenshot, opts).then(
                (canvas) => {
                    uploadSave(canvas, this.diagramObj.diagramId).then((response) => {
                        console.log(response, "response");
                        // this.msgSuccess("修改成功");
                        operate[0].style.display = "block";
                        operate[1].style.display = "block";
                        operate[2].style.display = "block";
                    });
                },
                (err) => {
                    operate[0].style.display = "block";
                    operate[1].style.display = "block";
                    operate[2].style.display = "block";
                    alert("缩略图保存失败，请重新尝试");
                }
            );
        },
        //导出
        download () {
            let resultJosn = this.graph.toJSON();
            if (this.isSave && resultJosn) {
                if (this.isTemplate) {
                    exportTemplates(this.diagramObj.diagramId).then((response) => {
                        var blob = new Blob([JSON.stringify(response)]); //创建一个blob对象
                        var a = document.createElement("a"); //创建一个<a></a>标签
                        a.href = URL.createObjectURL(blob); // response is a blob
                        a.download =
                            this.diagramObj.diagramName +
                            this.transformTimestamp(new Date()) +
                            ".ed"; //文件名称
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    });
                } else {
                    exportDiagram(this.diagramObj.diagramId).then((response) => {
                        var blob = new Blob([JSON.stringify(response)]); //创建一个blob对象
                        var a = document.createElement("a"); //创建一个<a></a>标签
                        a.href = URL.createObjectURL(blob); // response is a blob
                        a.download =
                            this.diagramObj.diagramName +
                            this.transformTimestamp(new Date()) +
                            ".ed"; //文件名称
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    });
                }
            } else {
                this.$confirm(
                    `请在关闭前对"${this.diagramObj.diagramName}"进行保存`,
                    "提示",
                    {
                        confirmButtonText: "保存",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                )
                    .then(() => {
                        if (this.diagramObj.diagramJson == null) {
                            this.$message.warning("请选择您要保存的内容");
                            return;
                        } else {
                            this.save();
                            exportDiagram(this.diagramObj.diagramId).then((response) => {
                                var blob = new Blob([JSON.stringify(response)]); //创建一个blob对象
                                var a = document.createElement("a"); //创建一个<a></a>标签
                                a.href = URL.createObjectURL(blob); // response is a blob
                                a.download =
                                    this.diagramObj.diagramName +
                                    this.transformTimestamp(new Date()) +
                                    ".ed"; //文件名称
                                a.style.display = "none";
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            });
                        }
                    })
                    .catch(() => { });
            }

            // window.location.href=this.process+"/system/diagram/export/"+ this.$route.query.diagramId;
        },
        //时间转化
        transformTimestamp (timestamp) {
            if (timestamp) {
                let a = new Date(timestamp).getTime();
                const date = new Date(a);
                const Y = date.getFullYear();
                const M =
                    date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1;
                const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                const h =
                    date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                const m =
                    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                // const s = date.getSeconds(); // 秒
                const dateString = Y + M + D + h + m;
                // console.log('dateString', dateString); // > dateString 2021-07-06 14:23
                return dateString;
            } else {
            }
        },
        importFile () { },
        //模板引用
        templateRefe () {
            if (!this.isTemplate) {
                console.log(this.diagramObj, "**");
                this.$refs.LeftTemplate.isTemplate = true;
                this.$refs.LeftToolBar.isLeftToolBar = false;
            }
        },
        //浏览元件
        throungh (item, index) {
            console.log("点击");
            for (let selectItem of this.selectSvgtLists) {
                if (selectItem.id == item.id) {
                    return;
                }
            }
            for (let g of this.selectLists) {
                g.display = "none";
            }
            this.selectSvgtLists = [];
            this.selectLists[index].display = "block";
            this.largeIndex = index;
            this.roateIndex = index;
            this.copyComponent = false;
            this.deleteComponentImg = false;
            this.watchComponent = false;
            this.svgObj = item;
            this.item = item;
            this.$refs.RightDrawer.show = true;
            if (!this.item.data) {
                this.item.data = {};
                this.item.data.statusModel = {};
                this.item.data.controlModel = {};
                this.item.data.statusModel.title = item.title;
                this.item.data.statusModel.moudleName = "";
                this.item.data.controlModel.moudleName = "";
            }
            this.copySrc = require("@/assets/icons/diagram/Allowcopy.png");
            this.deleteSrc = require("@/assets/icons/diagram/Allowdelete.png");
        },
        //模板引入canvas画布中
        acceptTemplate (obj) {
            console.log(obj, "obj");
            this.isTemplateObj = obj;
            if (obj.diagramJson) {
                this.graph.dispose();
                // this.graph = initGraph(this);
                this.initGraphInPage();
                this.diagramObj.diagramJson = obj.diagramJson;
                this.resultJson = eval("(" + obj.diagramJson + ")");
                console.log(this.resultJson);
                this.graph.fromJSON(this.resultJson);

                const cells = this.graph.getCells();
                if (cells.length) {
                    cells.forEach((currentCell) => {
                        if (currentCell.isNode()) {
                            let svgObjectDom = document.querySelectorAll(
                                `g[data-cell-id="${currentCell.id}"] foreignObject g`
                            );
                            // console.log('svgObjectDom', svgObjectDom[0].getAttribute('width'), svgObjectDom[0].getAttribute('height'));
                            if (svgObjectDom.length != 0) {
                                let svgElList = svgObjectDom[0].children;
                                for (let i = 0; i < svgElList.length; i++) {
                                    svgElList[i].setAttribute(
                                        "stroke-width",
                                        currentCell.attrs.props.strokeWidth
                                    );
                                    svgElList[i].setAttribute(
                                        "stroke",
                                        currentCell.attrs.props.stroke
                                    );
                                    if (svgElList[i].getAttribute("fill") != "none") {
                                        svgElList[i].setAttribute(
                                            "fill",
                                            currentCell.attrs.props.stroke
                                        );
                                    }
                                }
                            }
                        }
                    });
                }
            } else {
                // this.graph = initGraph(this);
                this.initGraphInPage();
            }
        },
        SvgStyle (item) {
            let node = this.graph.getSelectedCells();
            if (node[0].store.data.data.actionType == "SVG_HTML") {
                item.width = item.height;
            }
            console.log('SvgStyle', item)
            setSvgAttrs(this, node[0], item);
            // let _DqyqjNodes = DqyqjNodes;
            // let cells = this.graph.getSelectedCells();
            // if (item.CurrentType == "SVG_FONT") {
            //   const cells = this.graph.getSelectedCells();
            //   cells[0].position(
            //     parseInt(item.CurrentPositionX),
            //     parseInt(item.CurrentPositionY)
            //   );
            //   cells[0].rotate(item.CurrentAngle, { absolute: true });
            //   cells[0].size(item.CurrentWidth, item.CurrentHeight);
            //   cells[0].store.data.componentName = item.CurrentTitle;
            //   cells[0].store.data.attrs.body.fontSize = item.CurrentFontSize;
            //   cells[0].store.data.attrs.body.fontWeight = item.CurrentFontWeight;
            //   cells[0].store.data.attrs.body.color = item.CurrentColor;
            //   cells[0].store.data.attrs.body.content = item.CurrentContent;
            //   cells[0].store.data.attrs.body.fontFamily = item.CurrentFontFamily;
            //   let svgObjectDom = document.querySelectorAll(
            //     `g[data-cell-id="${cells[0].id}"] foreignObject .wzbq`
            //   );
            //   if (svgObjectDom.length) {
            //     let reMoveTr = svgObjectDom[0].childNodes;
            //     svgObjectDom[0].removeChild(reMoveTr[0]);
            //     let addInputs = document.createElement("input");
            //     addInputs.value = cells[0].store.data.attrs.body.content;
            //     addInputs.className = "wzbqInput";
            //     addInputs.setAttribute(
            //       "style",
            //       "width: " +
            //         item.CurrentWidth +
            //         "px;height: " +
            //         item.CurrentHeight +
            //         "px;border: none;outline: none;text-align: left;background-color: rgba(0,0,0,0);font-size: " +
            //         item.CurrentFontSize +
            //         "px;font-weight: " +
            //         item.CurrentFontWeight +
            //         ";color:" +
            //         item.CurrentColor +
            //         ";font-family:" +
            //         item.CurrentFontFamily +
            //         ";"
            //     );
            //     svgObjectDom[0].appendChild(addInputs);
            //     console.log(svgObjectDom);
            //     // svgObjectDom[0].innerText = cells[0].store.data.attrs.body.content;
            //     svgObjectDom[0].style.color = cells[0].store.data.attrs.body.color;
            //     svgObjectDom[0].style.fontSize =
            //       cells[0].store.data.attrs.body.fontSize + "px";
            //     svgObjectDom[0].style.fontWeight =
            //       cells[0].store.data.attrs.body.fontWeight;
            //   }
            //   //线路
            // } else if (item.CurrentType == "SVG_TAG") {
            //   console.log(item, "右侧改变样式");
            //   const cells = this.graph.getSelectedCells();
            //   cells[0].attrs.prop["stroke-width"] = item.CurrentStrokeWidth;
            //   cells[0].store.data.componentName = item.CurrentTitle;
            //   cells[0].attrs.prop.stroke = item.CurrentStroke;
            //   cells[0].attrs.prop.fill = item.CurrentStroke;
            //   cells[0].rotate(item.CurrentAngle, { absolute: true });
            //   cells[0].position(
            //     parseInt(item.CurrentPositionX),
            //     parseInt(item.CurrentPositionY)
            //   );
            //   cells[0].size(
            //     item.CurrentHeight,
            //     38 + cells[0].attrs.prop["stroke-width"] * 2
            //   );
            //   let svgObjectDom = document.querySelectorAll(
            //     `g[data-cell-id="${cells[0].id}"] line`
            //   );
            //   svgObjectDom[0].setAttribute(
            //     "stroke-width",
            //     cells[0].attrs.prop["stroke-width"]
            //   );
            //   svgObjectDom[0].setAttribute("stroke", cells[0].attrs.prop.stroke);
            //   svgObjectDom[0].setAttribute("x2", cells[0].store.data.size.width);
            //   svgObjectDom[0].setAttribute(
            //     "y2",
            //     19 + parseInt(cells[0].attrs.prop["stroke-width"])
            //   );
            //   svgObjectDom[0].setAttribute(
            //     "y1",
            //     19 + parseInt(cells[0].attrs.prop["stroke-width"])
            //   );
            //   if (cells[0].store.data.template == "1_dllxy") {
            //     let polygon = document.querySelectorAll(
            //       `g[data-cell-id="${cells[0].id}"] polygon`
            //     );
            //     polygon[0].setAttribute(
            //       "points",
            //       cells[0].store.data.size.width -
            //         4 -
            //         parseInt(cells[0].attrs.prop["stroke-width"]) +
            //         "," +
            //         17 +
            //         " " +
            //         cells[0].store.data.size.width +
            //         "," +
            //         (19 + parseInt(cells[0].attrs.prop["stroke-width"])) +
            //         " " +
            //         (cells[0].store.data.size.width -
            //           4 -
            //           parseInt(cells[0].attrs.prop["stroke-width"])) +
            //         "," +
            //         (21 + 2 * parseInt(cells[0].attrs.prop["stroke-width"]))
            //     );
            //     polygon[0].setAttribute("fill", cells[0].attrs.prop["stroke"]);
            //     polygon[0].setAttribute("stroke", cells[0].attrs.prop["stroke"]);
            //   }
            //   if (cells[0].store.data.template == "6_1_dllxy") {
            //     let polygon = document.querySelectorAll(
            //       `g[data-cell-id="${cells[0].id}"] polygon`
            //     );
            //     polygon[0].setAttribute(
            //       "points",
            //       cells[0].store.data.size.width -
            //         4 -
            //         parseInt(cells[0].attrs.prop["stroke-width"]) +
            //         "," +
            //         17 +
            //         " " +
            //         cells[0].store.data.size.width +
            //         "," +
            //         (19 + parseInt(cells[0].attrs.prop["stroke-width"])) +
            //         " " +
            //         (cells[0].store.data.size.width -
            //           4 -
            //           parseInt(cells[0].attrs.prop["stroke-width"])) +
            //         "," +
            //         (21 + 2 * parseInt(cells[0].attrs.prop["stroke-width"]))
            //     );
            //     polygon[0].setAttribute("fill", cells[0].attrs.prop["stroke"]);
            //     polygon[0].setAttribute("stroke", cells[0].attrs.prop["stroke"]);
            //   }

            //   let resultJson = eval("(" + this.diagramObj.diagramJson + ")");
            //   for (let i = 0; i < resultJson.cells.length; i++) {
            //     if (resultJson.cells[i].id == cells[0].id) {
            //       resultJson.cells[i] = cells[0];
            //     }
            //   }
            //   this.diagramObj.diagramJson = JSON.stringify(resultJson);
            // } else if (item.CurrentType == "SVG_HTML") {
            //   const cells = this.graph.getSelectedCells();
            //   cells[0].attrs.props["stroke-width"] = item.CurrentStrokeWidth;
            //   cells[0].store.data.componentName = item.CurrentTitle;
            //   cells[0].attrs.props.stroke = item.CurrentStroke;
            //   // cells[0].attrs.props.fill = item.CurrentStroke;
            //   cells[0].rotate(item.CurrentAngle, { absolute: true });
            //   cells[0].position(
            //     parseInt(item.CurrentPositionX),
            //     parseInt(item.CurrentPositionY)
            //   );

            //   // SvgColor(this, cells[0], item.CurrentStroke)
            //   // cells[0].size(
            //   //         item.CurrentHeight,
            //   //         38 + cells[0].attrs.props["stroke-width"] * 2
            //   // );
            //   // let svgObjectDom = document.querySelectorAll(
            //   //   `g[data-cell-id="${cells[0].id}"] svg`
            //   // )[0].children;
            //   // svgObjectDom[0].setAttribute(
            //   //   "stroke-width",
            //   //   cells[0].attrs.props["stroke-width"]
            //   // );
            //   // svgObjectDom[0].setAttribute("x2", cells[0].store.data.size.width);
            //   // svgObjectDom[0].setAttribute(
            //   //   "y2",
            //   //   19 + parseInt(cells[0].attrs.props["stroke-width"])
            //   // );
            //   // svgObjectDom[0].setAttribute(
            //   //   "y1",
            //   //   19 + parseInt(cells[0].attrs.props["stroke-width"])
            //   // );
            //   // let svgObjectDomChildren = svgObjectDom[0].children;
            //   // console.log(
            //   //   svgObjectDom,
            //   //   svgObjectDom.length,
            //   //   cells[0].attrs,
            //   //   "wwwwwwwwwwwwwwwww"
            //   // );
            //   // for (var i = 0; i < svgObjectDomChildren.length; i++) {
            //   //   svgObjectDomChildren[i].setAttribute(
            //   //     "stroke",
            //   //     cells[0].attrs.props.stroke
            //   //   );
            //   //   if (svgObjectDomChildren[i].getAttribute("fill") !== "none") {
            //   //     svgObjectDomChildren[i].setAttribute(
            //   //       "fill",
            //   //       cells[0].attrs.props.stroke
            //   //     );
            //   //   }
            //   // }

            //   // if (cells[0].store.data.template == "1_dllxy") {
            //   //     let polygon = document.querySelectorAll(
            //   //             `g[data-cell-id="${cells[0].id}"] polygon`
            //   //     );
            //   //     polygon[0].setAttribute("points", cells[0].store.data.size.width - 4 - parseInt(cells[0].attrs.props["stroke-width"]) + "," + 17 + " " + cells[0].store.data.size.width + "," + (19 + parseInt(cells[0].attrs.props["stroke-width"])) + " " + (cells[0].store.data.size.width - 4 - parseInt(cells[0].attrs.props["stroke-width"])) + "," + (21 + 2 * parseInt(cells[0].attrs.props["stroke-width"])));
            //   // }
            //   // if (cells[0].store.data.template == "6_1_dllxy") {
            //   //     let polygon = document.querySelectorAll(
            //   //             `g[data-cell-id="${cells[0].id}"] polygon`
            //   //     );
            //   //     polygon[0].setAttribute("points", cells[0].store.data.size.width - 4 - parseInt(cells[0].attrs.props["stroke-width"]) + "," + 17 + " " + cells[0].store.data.size.width + "," + (19 + parseInt(cells[0].attrs.props["stroke-width"])) + " " + (cells[0].store.data.size.width - 4 - parseInt(cells[0].attrs.props["stroke-width"])) + "," + (21 + 2 * parseInt(cells[0].attrs.props["stroke-width"])));
            //   //     polygon[0].setAttribute("fill", cells[0].attrs.props["stroke"]);
            //   //     polygon[0].setAttribute("stroke", cells[0].attrs.props["stroke"]);
            //   // }

            //   let resultJson = eval("(" + this.diagramObj.diagramJson + ")");
            //   for (let i = 0; i < resultJson.cells.length; i++) {
            //     if (resultJson.cells[i].id == cells[0].id) {
            //       resultJson.cells[i] = cells[0];
            //     }
            //   }
            //   this.diagramObj.diagramJson = JSON.stringify(resultJson);
            // } else if (item.CurrentType == "SVG_TABLE") {
            //   const cells = this.graph.getSelectedCells();
            //   cells[0].position(
            //     parseInt(item.CurrentPositionX),
            //     parseInt(item.CurrentPositionY)
            //   );
            //   cells[0].rotate(item.CurrentAngle, { absolute: true });
            //   cells[0].size(item.CurrentWidth, item.CurrentHeight);
            //   cells[0].store.data.componentName = item.CurrentTitle;
            //   cells[0].store.data.attrs.text.fontSize = item.CurrentFontSize;
            //   cells[0].store.data.attrs.text.fontWeight = item.CurrentFontWeight;
            //   cells[0].store.data.attrs.text.fontFamily = item.CurrentFontFamily;
            //   cells[0].store.data.attrs.body.cols = item.tableColCount;
            //   cells[0].store.data.attrs.body.rows = item.tableRowCount;
            //   let svgObjectDom = document.querySelectorAll(
            //     `g[data-cell-id="${cells[0].id}"] foreignObject .wzbq`
            //   );
            //   this.$refs.LeftToolBar.validateNodes(cells[0]);
            // } else if (item.CurrentType == "SVG_KEYVLAUE") {
            //   const cells = this.graph.getSelectedCells();
            //   cells[0].position(
            //     parseInt(item.CurrentPositionX),
            //     parseInt(item.CurrentPositionY)
            //   );
            //   cells[0].rotate(item.CurrentAngle, { absolute: true });
            //   cells[0].size(item.CurrentWidth, item.CurrentHeight);
            //   cells[0].store.data.componentName = item.CurrentTitle;
            //   cells[0].store.data.attrs.text.fontSize = item.CurrentFontSize;
            //   cells[0].store.data.attrs.text.fontWeight = item.CurrentFontWeight;
            //   cells[0].store.data.attrs.text.fontFamily = item.CurrentFontFamily;
            //   cells[0].store.data.attrs.body.color = item.CurrentStroke;
            //   cells[0].store.data.attrs.body.keys = item.CurrentKeys;
            //   cells[0].store.data.attrs.body.values = item.CurrentValues;
            //   this.item.CurrentTemplate = cells[0].store.data.template;
            //   let keyDom = document.querySelectorAll(
            //     `g[data-cell-id="${cells[0].id}"] foreignObject #jzkey`
            //   );
            //   let valueDom = document.querySelectorAll(
            //     `g[data-cell-id="${cells[0].id}"] foreignObject #jzvalue`
            //   );
            //   console.log(keyDom[0]);
            //   keyDom[0].innerHTML = item.CurrentKeys;
            //   keyDom[0].style.fontSize =
            //     cells[0].store.data.attrs.text.fontSize + "px";
            //   keyDom[0].style.color = cells[0].store.data.attrs.text.color;
            //   keyDom[0].style.fontWeight = cells[0].store.data.attrs.text.fontWeight;
            //   keyDom[0].style.fontFamily = cells[0].store.data.attrs.text.fontFamily;
            //   keyDom[0].style.color = cells[0].store.data.attrs.body.color;
            //   valueDom[0].innerHTML = item.CurrentValues;
            //   valueDom[0].style.fontSize =
            //     cells[0].store.data.attrs.text.fontSize + "px";
            //   valueDom[0].style.color = cells[0].store.data.attrs.text.color;
            //   valueDom[0].style.fontWeight =
            //     cells[0].store.data.attrs.text.fontWeight;
            //   valueDom[0].style.fontFamily =
            //     cells[0].store.data.attrs.text.fontFamily;
            //   valueDom[0].style.color = cells[0].store.data.attrs.body.color;
            //   // this.$refs.LeftToolBar.validateNodes(cells[0]);
            // } else {
            //   console.log(item, "右侧改变样式");
            //   const cells = this.graph.getSelectedCells();
            //   cells[0].attrs.props.strokeWidth = item.CurrentStrokeWidth;
            //   cells[0].store.data.componentName = item.CurrentTitle;
            //   cells[0].attrs.props.stroke = item.CurrentStroke;
            //   // cells[0].rotate(item.CurrentAngle, {absolute: true});
            //   cells[0].position(
            //     parseInt(item.CurrentPositionX),
            //     parseInt(item.CurrentPositionY)
            //   );
            //   cells[0].size(item.CurrentHeight, item.CurrentHeight);
            //   let svgObjectDom = document.querySelectorAll(
            //     `g[data-cell-id="${cells[0].id}"] foreignObject g`
            //   );
            //   if (svgObjectDom.length != 0) {
            //     let svgElList = svgObjectDom[0].children;
            //     for (let i = 0; i < svgElList.length; i++) {
            //       svgElList[i].setAttribute(
            //         "stroke-width",
            //         cells[0].attrs.props.strokeWidth
            //       );
            //       svgElList[i].setAttribute("stroke", cells[0].attrs.props.stroke);
            //       if (svgElList[i].getAttribute("fill") != "none") {
            //         svgElList[i].setAttribute("fill", cells[0].attrs.props.stroke);
            //       }
            //     }
            //   }

            //   console.log("cells[0].store.data", cells[0].store.data);
            //   let resultJson = eval("(" + this.diagramObj.diagramJson + ")");
            //   for (let i = 0; i < resultJson.cells.length; i++) {
            //     if (resultJson.cells[i].id == cells[0].id) {
            //       resultJson.cells[i] = cells[0];
            //     }
            //   }
            //   this.diagramObj.diagramJson = JSON.stringify(resultJson);
            // }
        },
        EdgeSvgStyle (item) {
            console.log(item, "右侧改变样式");
            console.log(global);
            const cells = this.graph.getSelectedCells();
            cells[0].attr("line", {
                stroke: item.color,
                strokeWidth: item.strokeWidth,
                strokeDasharray: item.strokeDasharray,
                targetMarker: item.targetMarker
                    ? {
                        size: item.targetMarkerSize,
                        name: item.targetMarker,
                    }
                    : null,
            });
            cells[0].store.data.attrs.props.stroke = item.color;
            cells[0].store.data.attrs.props.strokeWidth = item.strokeWidth;
            console.log(cells);
            let resultJson = eval("(" + this.diagramObj.diagramJson + ")");
            for (let i = 0; i < resultJson.cells.length; i++) {
                if (resultJson.cells[i].id == cells[0].id) {
                    resultJson.cells[i] = cells[0];
                }
            }
            this.diagramObj.diagramJson = JSON.stringify(resultJson);
            /*let svgObjectDom = document.querySelectorAll(`g[data-cell-id="${cells[0].id}"] foreignObject g`);
                                    console.log('svgObjectDom', svgObjectDom[0].getAttribute('width'), svgObjectDom[0].getAttribute('height'));
                                    let svgElList = svgObjectDom[0].children;
                                    for (let i = 0; i < svgElList.length; i++) {
                                    svgElList[i].setAttribute('stroke-width', cells[0].attrs.props.strokeWidth);
                                    svgElList[i].setAttribute('stroke', cells[0].attrs.props.stroke);
                                    }*/
            // global.CurrentStroke = item.CurrentStroke;
            // // this.globals = item;
            // console.log(global);
        },
        //右侧控件控制元件大小
        BindObj (dataObj, needSave) {
            this.svgObj.data = dataObj;
            const cells = this.graph.getSelectedCells();
            cells[0].store.data.BindData = dataObj;
            if (needSave) {
                this.save();
            }
        },
        // 取消按钮
        cancel () {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset () {
            this.form = {
                diagramId: null,
                diagramName: null,
                diagramcode: null,
                diagramtype: null,
                width: null,
                height: null,
                coverurl: null,
                publishstatus: "0",
                remark: null,
                creatorid: null,
                createtime: null,
                editorid: null,
                edittime: null,
            };
            this.resetForm("form");
        },
        //表单提交
        submitForm () {
            let _this = this;
            _this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (_this.form.diagramId != null) {
                        updateDiagram(_this.form).then((response) => {
                            _this.msgSuccess("修改成功");
                            _this.open = false;
                        });
                    } else {
                        // _this.form.creatorid = _this.$store.state.user.name;
                        _this.form.diagramId = _this.uuid(32);
                        addDiagram(_this.form).then((response) => {
                            _this.msgSuccess("新增成功");
                            _this.form.diagramJson = null;
                            _this.navTopList.push(_this.form);
                            _this.resultJson = {}
                            _this.form = {};
                            _this.open = false;
                            _this.$nextTick(() => {
                                // DOM 更新了
                                let backgroundNaList =
                                    document.getElementsByClassName("navTop");
                                let closeList =
                                    document.getElementsByClassName("el-icon-close");
                                backgroundNaList[_this.navTopList.length - 1].click();
                                console.log(_this.navTopList);
                            });
                        });
                    }
                }
            });
        },
        //关闭操作
        closeOperate (e) {
            if (e.target.tagName == "svg") {
                this.$refs.RightDrawer.show = false;
                let gAnyList = document.querySelectorAll("g");
                gAnyList.forEach((g) => {
                    g.classList.remove("topo-layer-view-selected");
                });
                this.selectSvgInfo = "";
                this.copySrc = require("@/assets/icons/diagram/copy.png");
                this.deleteSrc = require("@/assets/icons/diagram/delete.png");
                this.deleteComponentImg = true;
                this.watchComponent = true;
                this.copyComponent = true;
            }
        },
        tableChangeVal (id) {
            console.log(id);
        },
        getDomByActive (dom) {
            if (dom.tagName == "g" && dom.getAttribute("data-cell-id") != null) {
                return dom;
            } else {
                let domA = this.getDomByActive(dom["parentElement"]);
                return domA;
            }
        },
        initGraphInPage () {
            this.graph = initGraph(this);
            this.graph.center();
            this.graph.setAsync(true);
            this.graph.on("render:done", this.showResult);
        },
        showResult () {
            let _this = this;
            console.log("render:done1", _this.resultJson);
            if (_this.resultJson.cells) {
                _this.graph.fromJSON(_this.resultJson);
                _this.graph.centerContent();
            }
            const cells = _this.graph.getCells();
            if (cells.length) {
                cells.forEach((currentCell) => {
                    if (currentCell.isNode()) {
                        if (currentCell.store.data.data.actionType == "SVG_HTML") {
                            setTimeout(() => {
                                let svgObjectDom = document.getElementById('canvas').querySelectorAll(
                                    `g[data-cell-id="${currentCell.id}"] foreignObject g`
                                );
                                let mapSvgObjectDom = document.getElementById('minimap').querySelectorAll(
                                    `g[data-cell-id="${currentCell.id}"] foreignObject g`
                                );
                                if (svgObjectDom.length != 0) {
                                    // let svgElList = svgObjectDom[0].children;
                                    // let minimapSvgElList = svgObjectDom[1].children;
                                    // for (let i = 0; i < svgElList.length; i++) {
                                    //   svgElList[i].setAttribute(
                                    //     "stroke-width",
                                    //     currentCell.attrs.props?.strokeWidth
                                    //       ? currentCell.attrs.props.strokeWidth
                                    //       : "1"
                                    //   );
                                    //   svgElList[i].setAttribute(
                                    //     "stroke",
                                    //     currentCell.attrs.props?.stroke
                                    //       ? currentCell.attrs.props.stroke
                                    //       : "#000000"
                                    //   );
                                    //   minimapSvgElList[i].setAttribute(
                                    //     "stroke-width",
                                    //     currentCell.attrs.props?.strokeWidth
                                    //       ? currentCell.attrs.props.strokeWidth
                                    //       : "1"
                                    //   );
                                    //   minimapSvgElList[i].setAttribute(
                                    //     "stroke",
                                    //     currentCell.attrs.props?.stroke
                                    //       ? currentCell.attrs.props.stroke
                                    //       : "#000000"
                                    //   );
                                    //   if (svgElList[i].getAttribute("fill") != "none") {
                                    //     svgElList[i].setAttribute(
                                    //       "fill",
                                    //       currentCell.attrs.props.stroke
                                    //     );
                                    //     minimapSvgElList[i].setAttribute(
                                    //       "fill",
                                    //       currentCell.attrs.props.stroke
                                    //     );
                                    //   }
                                    // }
                                    svgObjectDom[0].setAttribute(
                                        "stroke-width",
                                        currentCell.attrs.props?.strokeWidth
                                            ? currentCell.attrs.props.strokeWidth
                                            : "1"
                                    );
                                    svgObjectDom[0].setAttribute(
                                        "stroke",
                                        currentCell.attrs.props?.stroke
                                            ? currentCell.attrs.props.stroke
                                            : "#000000"
                                    );
                                    mapSvgObjectDom[0].setAttribute(
                                        "stroke-width",
                                        currentCell.attrs.props?.strokeWidth
                                            ? currentCell.attrs.props.strokeWidth
                                            : "1"
                                    );
                                    mapSvgObjectDom[0].setAttribute(
                                        "stroke",
                                        currentCell.attrs.props?.stroke
                                            ? currentCell.attrs.props.stroke
                                            : "#000000"
                                    );
                                    if (svgObjectDom[0].getAttribute("fill") != "none") {
                                        svgObjectDom[0].setAttribute(
                                            "fill",
                                            currentCell.attrs.props.stroke
                                        );
                                        mapSvgObjectDom[0].setAttribute(
                                            "fill",
                                            currentCell.attrs.props.stroke
                                        );
                                    }
                                }
                            }, 1);
                        } else if (currentCell.store.data.data.actionType == "SVG_TAG") {
                            setTimeout(() => {
                                let lineObjectDom = document.querySelectorAll(
                                    `g[data-cell-id="${currentCell.id}"] line`
                                );
                                if (currentCell.store.data.template == "1_dllxy") {
                                    currentCell.setMarkup([
                                        {
                                            tagName: "line",
                                            selector: "line1",
                                            groupSelector: "prop",
                                            attrs: {
                                                x1: "0",
                                                y1:
                                                    19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                                x2: currentCell.store.data.size.width,
                                                y2:
                                                    19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                                fill: "none",
                                            },
                                        },
                                        {
                                            tagName: "polygon",
                                            selector: "polygon1",
                                            groupSelector: "prop",
                                            attrs: {
                                                points:
                                                    currentCell.store.data.size.width -
                                                    4 -
                                                    parseInt(currentCell.attrs.prop["stroke-width"]) +
                                                    "," +
                                                    17 +
                                                    " " +
                                                    currentCell.store.data.size.width +
                                                    "," +
                                                    (19 +
                                                        parseInt(currentCell.attrs.prop["stroke-width"])) +
                                                    " " +
                                                    (currentCell.store.data.size.width -
                                                        4 -
                                                        parseInt(currentCell.attrs.prop["stroke-width"])) +
                                                    "," +
                                                    (21 +
                                                        2 *
                                                        parseInt(currentCell.attrs.prop["stroke-width"])),
                                                fill: "#000000",
                                            },
                                        },
                                    ]);
                                    // let polygon = document.querySelectorAll(
                                    //         `g[data-cell-id="${currentCell.id}"] polygon`
                                    // );
                                    // polygon[0].setAttribute("points", (currentCell.store.data.size.width-4-parseInt(currentCell.attrs.prop.strokeWidth))+","+17+" "+currentCell.store.data.size.width+","+(19+parseInt(currentCell.attrs.prop.strokeWidth))+" "+(currentCell.store.data.size.width-4-parseInt(currentCell.attrs.prop.strokeWidth))+","+(21+2*parseInt(currentCell.attrs.prop.strokeWidth)));
                                } else if (currentCell.store.data.template == "6_1_dllxy") {
                                    currentCell.setMarkup([
                                        {
                                            tagName: "line",
                                            selector: "line1",
                                            groupSelector: "prop",
                                            attrs: {
                                                x1: "0",
                                                y1:
                                                    19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                                x2: currentCell.store.data.size.width,
                                                y2:
                                                    19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                                fill: "none",
                                            },
                                        },
                                        {
                                            tagName: "polygon",
                                            selector: "polygon1",
                                            groupSelector: "prop",
                                            attrs: {
                                                points:
                                                    currentCell.store.data.size.width -
                                                    4 -
                                                    parseInt(currentCell.attrs.prop["stroke-width"]) +
                                                    "," +
                                                    17 +
                                                    " " +
                                                    currentCell.store.data.size.width +
                                                    "," +
                                                    (19 +
                                                        parseInt(currentCell.attrs.prop["stroke-width"])) +
                                                    " " +
                                                    (currentCell.store.data.size.width -
                                                        4 -
                                                        parseInt(currentCell.attrs.prop["stroke-width"])) +
                                                    "," +
                                                    (21 +
                                                        2 *
                                                        parseInt(currentCell.attrs.prop["stroke-width"])),
                                                fill: currentCell.attrs.prop["stroke"],
                                            },
                                        },
                                    ]);
                                } else {
                                    currentCell.setMarkup([
                                        {
                                            tagName: "line",
                                            selector: "line1",
                                            groupSelector: "prop",
                                            attrs: {
                                                x1: "0",
                                                y1:
                                                    19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                                x2: currentCell.store.data.size.width,
                                                y2:
                                                    19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                                fill: "none",
                                            },
                                        },
                                    ]);
                                }
                                console.log('======================', currentCell)
                                currentCell.attr("line", {
                                    stroke: currentCell.attrs.prop.stroke,
                                    strokeWidth: currentCell.attrs.prop["stroke-width"],
                                    strokeDasharray: currentCell.attrs.prop["stroke-dasharray"],
                                    strokeLinecap: currentCell.attrs.prop["stroke-linecap"],
                                });
                            }, 1);
                        } else if (currentCell.store.data.data.actionType == "SVG_FONT") {
                            setTimeout(() => {
                                let svgObjectDom = document.querySelectorAll(
                                    `g[data-cell-id="${currentCell.id}"] foreignObject .wzbq`
                                );
                                if (svgObjectDom.length != 0) {
                                    let reMoveTr = svgObjectDom[0].childNodes;
                                    svgObjectDom[0].removeChild(reMoveTr[0]);
                                    let addInputs = document.createElement("input");
                                    addInputs.value = currentCell.store.data.attrs.body.content;
                                    addInputs.className = "wzbqInput";
                                    addInputs.setAttribute(
                                        "style",
                                        "width: " +
                                        currentCell.store.data.size.width +
                                        "px;height: " +
                                        currentCell.store.data.size.height +
                                        "px;border: none;outline: none;text-align: left;background-color: rgba(0,0,0,0);font-size: " +
                                        currentCell.store.data.attrs.body.fontSize +
                                        "px;font-weight: " +
                                        currentCell.store.data.attrs.body.fontWeight +
                                        ";color:" +
                                        currentCell.store.data.attrs.body.color +
                                        ";font-family:" +
                                        currentCell.store.data.attrs.body.fontFamily +
                                        ";"
                                    );
                                    svgObjectDom[0].appendChild(addInputs);

                                    // svgObjectDom[0].innerText =
                                    //   currentCell.store.data.attrs.body.content;
                                    svgObjectDom[0].style.color =
                                        currentCell.store.data.attrs.body.color;
                                    svgObjectDom[0].style.fontSize =
                                        currentCell.store.data.attrs.body.fontSize + "px";
                                    svgObjectDom[0].style.fontWeight =
                                        currentCell.store.data.attrs.body.fontWeight;
                                    svgObjectDom[1].children[0].style.color =
                                        currentCell.store.data.attrs.body.color;
                                }
                            }, 1);
                        } else if (currentCell.store.data.data.actionType == "SVG_TABLE") {
                            // console.log(currentCell);
                            if ("1_table" == currentCell.store.data.template) {
                                let tableData = currentCell.store.data.tableData;
                                let svgObjectDom = document.querySelectorAll(
                                    `g[data-cell-id="${currentCell.id}"] foreignObject table`
                                );
                                if (svgObjectDom.length) {
                                    // console.log(svgObjectDom);
                                    let reMoveTr = svgObjectDom[0].childNodes[1].childNodes;
                                    // console.log(reMoveTr);
                                    for (let index = 0; index < reMoveTr.length; index++) {
                                        if (reMoveTr[index].tagName == "TR") {
                                            svgObjectDom[0].childNodes[1].removeChild(
                                                reMoveTr[index]
                                            );
                                            index--;
                                        }
                                    }
                                    for (let i = 0; i < tableData.length; i++) {
                                        let addTrs = document.createElement("tr");
                                        svgObjectDom[0].childNodes[1].appendChild(addTrs);
                                        for (let j = 0; j < tableData[i].cols.length; j++) {
                                            let addTds = document.createElement("td");
                                            let addInputs = document.createElement("input");
                                            addInputs.value = tableData[i].cols[j].val;
                                            addInputs.id = tableData[i].cols[j].id;
                                            addInputs.className = "tableInput";
                                            addTds.setAttribute(
                                                "style",
                                                "width:100px;height:20px;border:1px solid orange;text-align: center;"
                                            );
                                            addInputs.setAttribute(
                                                "style",
                                                `width: 70px;border: none;outline: none;text-align: left;background-color: rgba(0,0,0,0);font-size:${currentCell.store.data.attrs.text.fontSize}px;font-family:${currentCell.store.data.attrs.text.fontFamily};font-weight:${currentCell.store.data.attrs.text.fontWeight}`
                                            );
                                            addTrs.appendChild(addTds);
                                            addTds.appendChild(addInputs);
                                        }
                                    }
                                }
                            } else {
                                for (let key in currentCell.store.data.tableData) {
                                    let td = document.querySelectorAll(
                                        `g[data-cell-id="${currentCell.id}"] foreignObject table .` +
                                        key
                                    );
                                    td[0].innerHTML = currentCell.store.data.tableData[key];
                                }
                            }
                        } else if (
                            currentCell.store.data.data.actionType == "SVG_KEYVLAUE"
                        ) {
                            _this.$nextTick(() => {
                                let keys = currentCell.store.data.attrs.body.keys;
                                let values = currentCell.store.data.attrs.body.values;
                                let keyDom = document.querySelectorAll(
                                    `g[data-cell-id="${currentCell.id}"] foreignObject #jzkey`
                                );
                                let valueDom = document.querySelectorAll(
                                    `g[data-cell-id="${currentCell.id}"] foreignObject #jzvalue`
                                );
                                console.log(keyDom[0]);
                                keyDom[0].innerHTML = keys;
                                keyDom[0].style.fontSize =
                                    currentCell.store.data.attrs.text.fontSize + "px";
                                keyDom[0].style.color = currentCell.store.data.attrs.text.color;
                                keyDom[0].style.fontWeight =
                                    currentCell.store.data.attrs.text.fontWeight;
                                keyDom[0].style.fontFamily =
                                    currentCell.store.data.attrs.text.fontFamily;
                                keyDom[0].style.color = cells[0].store.data.attrs.body.color;
                                valueDom[0].innerHTML = values;
                                valueDom[0].style.fontSize =
                                    currentCell.store.data.attrs.text.fontSize + "px";
                                valueDom[0].style.color =
                                    currentCell.store.data.attrs.text.color;
                                valueDom[0].style.fontWeight =
                                    currentCell.store.data.attrs.text.fontWeight;
                                valueDom[0].style.fontFamily =
                                    currentCell.store.data.attrs.text.fontFamily;
                                valueDom[0].style.color = cells[0].store.data.attrs.body.color;
                            });
                        }
                    } else if (currentCell.isEdge()) {
                        // let path = currentCell.store.data.attrs.path.d.split(' ');
                        // path[4] = path[1] < path[4] ? path[4] / 1 + 2 : path[4] - 2
                        // currentCell.store.data.attrs.path.d = path.join(' ')
                        // currentCell.attrs.path.d = path.join(' ')
                        // const pathDom = document.querySelectorAll(`g[data-cell-id="${currentCell.id}"] path`);
                        // pathDom.forEach(dom => {
                        //   dom.setAttribute('d', path.join(' '))
                        // })
                        // currentCell.store.data.attrs.path =
                        if (!currentCell.store.data.attrs.props) {
                            currentCell.store.data.attrs.props = {
                                stroke: "#000000",
                                strokeWidth: 1,
                            };
                        }

                        // 回显设置path data，如果没有target port设置。设置path data
                        // if (!currentCell.store.data.target.port) {
                        //     let pathObjectDom = document.querySelectorAll(
                        //             `g[data-cell-id="${currentCell.id}"] path`
                        //     );
                        //     for (var i = 0; i < pathObjectDom.length; i++) {

                        //         // console.log(currentCell.store.data.attrs.path.d); // M 525 530 L 525 315
                        //         pathObjectDom[i].setAttribute(
                        //                 "d",
                        //                 currentCell.store.data.attrs.path.d
                        //         );
                        //     }
                        // }

                        currentCell.attr("line", {
                            stroke: currentCell.store.data.attrs.props.stroke,
                            strokeWidth: currentCell.store.data.attrs.props.strokeWidth,
                        });
                    }
                    currentCell.removeTools();
                });
            }
            _this.graph.setAsync(false);
        },
        //左对齐
        leftAlignMent () {
            this.$nextTick(() => {
                let cells = this.graph.getSelectedCells();
                //定位左边起
                if (cells.length > 1) {
                    let cellPosXmin = cells[0].store.data.position.x;
                    for (let i = 0; i < cells.length; i++) {
                        if (cellPosXmin > cells[i].store.data.position.x) {
                            cellPosXmin = cells[i].store.data.position.x;
                        }
                    }
                    for (let i = 0; i < cells.length; i++) {
                        console.log(cells);
                        console.log(cells[i].store.data.size.width / 2);
                        cells[i].position(
                            parseInt(cellPosXmin),
                            parseInt(cells[i].store.data.position.y)
                        );
                    }
                }
            });
        },
        //水平居中对齐
        centerAlignMent () {
            let cells = this.graph.getSelectedCells();
            //定位中间起
            if (cells.length > 1) {
                let cellPosXmin = cells[0].store.data.position.x;
                let cellPosXmax =
                    cells[0].store.data.position.x + cells[0].store.data.size.width;
                let cellPosWmax = cells[0].store.data.size.width / 2;
                for (let i = 0; i < cells.length; i++) {
                    if (cellPosWmax < cells[i].store.data.size.width / 2) {
                        cellPosWmax = cells[i].store.data.size.width / 2;
                    }
                    if (cellPosXmin > cells[i].store.data.position.x) {
                        cellPosXmin = cells[i].store.data.position.x;
                    }
                    if (
                        cellPosXmax <
                        cells[i].store.data.position.x + cells[i].store.data.size.width
                    ) {
                        cellPosXmax =
                            cells[i].store.data.position.x + cells[i].store.data.size.width;
                    }
                }
                let cellPosXcenter =
                    cellPosXmin + Math.abs(cellPosXmax - cellPosXmin) / 2;
                for (let i = 0; i < cells.length; i++) {
                    cells[i].position(
                        parseInt(cellPosXcenter - cells[i].store.data.size.width / 2),
                        parseInt(cells[i].store.data.position.y)
                    );
                }
            }
        },
        //垂直居中对齐
        perpendicularAlignMent () {
            let cells = this.graph.getSelectedCells();
            //定位中间起
            if (cells.length > 1) {
                let cellPosYmin = cells[0].store.data.position.y;
                let cellPosYmax =
                    cells[0].store.data.position.y + cells[0].store.data.size.height;
                let cellPosHmax = cells[0].store.data.size.height / 2;
                for (let i = 0; i < cells.length; i++) {
                    if (cellPosHmax < cells[i].store.data.size.height / 2) {
                        cellPosHmax = cells[i].store.data.size.height / 2;
                    }
                    if (cellPosYmin > cells[i].store.data.position.y) {
                        cellPosYmin = cells[i].store.data.position.y;
                    }
                    if (
                        cellPosYmax <
                        cells[i].store.data.position.y + cells[i].store.data.size.height
                    ) {
                        cellPosYmax =
                            cells[i].store.data.position.y + cells[i].store.data.size.height;
                    }
                }
                let cellPosYcenter =
                    cellPosYmin + Math.abs(cellPosYmax - cellPosYmin) / 2;
                for (let i = 0; i < cells.length; i++) {
                    cells[i].position(
                        parseInt(cells[i].store.data.position.x),
                        parseInt(cellPosYcenter - cells[i].store.data.size.height / 2)
                    );
                }
            }
        },
        //右对齐
        rightAlignMent () {
            this.$nextTick(() => {
                let cells = this.graph.getSelectedCells();
                //定位右边起
                if (cells.length > 1) {
                    let cellPosXmax =
                        cells[0].store.data.position.x + cells[0].store.data.size.width;
                    let cellPosWmax = cells[0].store.data.size.width;
                    for (let i = 0; i < cells.length; i++) {
                        if (cellPosWmax < cells[i].store.data.size.width) {
                            cellPosWmax = cells[i].store.data.size.width;
                        }
                        if (
                            cellPosXmax <
                            cells[i].store.data.position.x + cells[i].store.data.size.width
                        ) {
                            cellPosXmax =
                                cells[i].store.data.position.x + cells[i].store.data.size.width;
                        }
                    }
                    for (let i = 0; i < cells.length; i++) {
                        cells[i].position(
                            parseInt(cellPosXmax - cells[i].store.data.size.width),
                            parseInt(cells[i].store.data.position.y)
                        );
                    }
                }
            });
        },
        //上对齐
        topAlignMent () {
            let cells = this.graph.getSelectedCells();
            //定位左边起
            if (cells.length > 1) {
                let cellPosYmin = cells[0].store.data.position.y;
                for (let i = 0; i < cells.length; i++) {
                    if (cellPosYmin > cells[i].store.data.position.y) {
                        cellPosYmin = cells[i].store.data.position.y;
                    }
                }
                for (let i = 0; i < cells.length; i++) {
                    cells[i].position(
                        parseInt(cells[i].store.data.position.x),
                        parseInt(cellPosYmin)
                    );
                }
            }
        },
        //下对齐
        bootomAlignMent () {
            let cells = this.graph.getSelectedCells();
            //定位右边起
            if (cells.length > 1) {
                let cellPosYmax =
                    cells[0].store.data.position.y + cells[0].store.data.size.height;
                let cellPosHmax = cells[0].store.data.size.height;
                for (let i = 0; i < cells.length; i++) {
                    if (cellPosHmax < cells[i].store.data.size.height) {
                        cellPosHmax = cells[i].store.data.size.height;
                    }
                    if (
                        cellPosYmax <
                        cells[i].store.data.position.y + cells[i].store.data.size.height
                    ) {
                        cellPosYmax =
                            cells[i].store.data.position.y + cells[i].store.data.size.height;
                    }
                }
                for (let i = 0; i < cells.length; i++) {
                    cells[i].position(
                        parseInt(cells[i].store.data.position.x),
                        parseInt(cellPosYmax - cells[i].store.data.size.height)
                    );
                }
            }
        },
        //水平等间距
        levelAlignMent (index) {
            let cells = this.graph.getSelectedCells();
            let cellX = [];
            if (cells.length > 2) {
                cells.forEach((item) => {
                    cellX.push({
                        id: item.id,
                        x: item.store.data.position.x,
                        y: item.store.data.position.y,
                        position: (x, y) => {
                            item.position(x, y);
                        },
                    });
                    console.log(item, "item");
                });
                cellX.sort(this.creatCompare("x"));
                console.log(cellX, "cellX");
                let cellPosXmin = cellX[0].x;
                let cellPosXmax = cellX[cellX.length - 1].x;
                // for (let i = 0; i < cells.length; i++) {
                //     if (cellPosXmin > cells[i].store.data.position.x) {
                //         cellPosXmin = cells[i].store.data.position.x
                //     }
                // }
                // for (let i = 0; i < cells.length; i++) {
                //     if (cellPosXmax < cells[i].store.data.position.x) {
                //         cellPosXmax = cells[i].store.data.position.x
                //     }
                //
                // }
                let cellPosAverage = (cellPosXmax - cellPosXmin) / (cellX.length - 1);
                for (let i = 1; i < cellX.length - 1; i++) {
                    let frontCellPosX = cellPosAverage - (cellX[i].x - cellX[i - 1].x);
                    console.log(cellX[i].position);
                    cellX[i].position(
                        parseInt(cellX[i].x + frontCellPosX),
                        parseInt(cellX[i].y)
                    );
                }
            }
        },
        //垂直等间距
        verticalAlignMent () {
            let cells = this.graph.getSelectedCells();
            let cellY = [];
            if (cells.length > 1) {
                cells.forEach((item) => {
                    cellY.push({
                        id: item.id,
                        x: item.store.data.position.x,
                        y: item.store.data.position.y,
                        position: (x, y) => {
                            item.position(x, y);
                        },
                    });
                    console.log(item, "item");
                });
                cellY.sort(this.creatCompare("y"));
                let cellPosYmin = cellY[0].y;
                let cellPosYmax = cellY[cellY.length - 1].y;
                // for (let i = 0; i < cells.length; i++) {
                //     if (cellPosYmin > cells[i].store.data.position.y) {
                //         cellPosYmin = cells[i].store.data.position.y
                //     }
                // }
                // for (let i = 0; i < cells.length; i++) {
                //     if (cellPosYmax < cells[i].store.data.position.y) {
                //         cellPosYmax = cells[i].store.data.position.y
                //     }
                //
                // }
                let cellPosAverage = (cellPosYmax - cellPosYmin) / (cellY.length - 1);
                for (let i = 1; i < cellY.length - 1; i++) {
                    let frontCellPosY = cellPosAverage - (cellY[i].y - cellY[i - 1].y);
                    cellY[i].position(
                        parseInt(cellY[i].x),
                        parseInt(cellY[i].y + frontCellPosY)
                    );
                }
            }
        },
        //编组
        groupComponent () {
            let groupIds = this.$store.getters.groupIds;
            const cells = this.graph.getSelectedCells();
            let hasId = false;
            let hasIdArrIndex = [];
            let ids = [];
            if (groupIds.length > 0) {
                groupIds.forEach((item, index) => {
                    cells.forEach((el) => {
                        if (item.includes(el.id)) {
                            hasId = true;
                            hasIdArrIndex.push(index);
                        }
                    });
                });
                cells.forEach((item) => {
                    ids.push(item.id);
                });
                if (!hasId) {
                    groupIds.push(ids);
                } else {
                    groupIds[hasIdArrIndex[0]] = ids;
                    if (hasIdArrIndex.length > 1) {
                        groupIds.splice(hasIdArrIndex[1], 1);
                    }
                }
            } else {
                cells.forEach((item) => {
                    ids.push(item.id);
                });
                groupIds.push(ids);
            }
            store.commit("SET_GROUPIDS", groupIds);
        },
        //取消编组
        cancelGroupComponent () {
            const cells = this.graph.getSelectedCells();
            let groupIds = this.$store.getters.groupIds;
            if (cells.length > 0) {
                for (let i = groupIds.length - 1; i >= 0; i--) {
                    for (let j = 0; j < cells.length; j++) {
                        if (groupIds[i].includes(cells[j].id)) {
                            groupIds.splice(i, 1);
                            this.graph.unselect(cells);
                            break;
                        }
                    }
                }
            }
            store.commit("SET_GROUPIDS", groupIds);
        },
        // 解绑
        unbind () {
            const cells = this.graph.getSelectedCells();
            if (cells.length > 0) {
                this.$confirm("是否确认解绑", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        for (let i = 0; i < cells.length; i++) {
                            if (cells[i].store.data.BindData) {
                                delete cells[i].store.data.BindData;
                            }
                        }
                    })
                    .catch(() => { });
            }
        },
        changeColor (preColor) {
            if (!preColor) {
                return;
            }
            this.graph.getSelectedCells().forEach(cell => {
                console.log('对比cell*************', cell)
                setSvgAttrs(this, cell, { color: preColor })
            });
            this.$nextTick(() => {
                //当前选择存在于默认和自选颜色中不操作
                if (this.predefineColors.includes(preColor)) {
                    return false;
                }
                //默认颜色+自选颜色为最大数为20
                if (this.predefineColors.length >= 20) {
                    this.predefineColors.splice(10, 1);
                }
                this.predefineColors.push(preColor);
                localStorage.setItem("predefineColorsArr", JSON.stringify(this.predefineColors.slice(10)));
            });
        },
        creatCompare (propertyName) {
            //根据哪个属性值排序
            return function (obj1, obj2) {
                //对象两两比较
                let value1 = obj1[propertyName];
                let value2 = obj2[propertyName];
                if (value1 < value2) {
                    return -1;
                } else if (value1 > value2) {
                    return 1;
                } else {
                    return 0;
                }
            };
        },
        //设置改变样式
        setAttrtyle (dom, backGround, opacity) {
            dom.style.background = backGround;
            dom.style.opacity = opacity;
        },
        //鼠标移入
        mouseEnterSetStyle (dom, backGround, opacity, take) {
            if (take) {
                dom.onmouseenter = function () {
                    dom.style.background = backGround;
                    dom.style.opacity = opacity;
                };
            } else {
                dom.onmouseenter = function () {
                    return false;
                };
            }
        },
        //鼠标离开
        mouseLeaveSetStyle (dom, backGround, opacity, take) {
            if (take) {
                dom.onmouseleave = function () {
                    dom.style.background = backGround;
                    dom.style.opacity = opacity;
                };
            } else {
                dom.onmouseleave = function () {
                    return false;
                };
            }
        },
        //鼠标按下
        mouseDownSetStyle (dom, backGround, opacity, take) {
            if (take) {
                dom.onmousedown = function () {
                    dom.style.background = backGround;
                    dom.style.opacity = opacity;
                };
            } else {
                dom.onmousedown = function () {
                    return false;
                };
            }
        },
        //鼠标放开
        mouseUpSetStyle (dom, backGround, opacity, take) {
            if (take) {
                dom.onmouseup = function () {
                    dom.style.background = backGround;
                    dom.style.opacity = opacity;
                };
            } else {
                dom.onmouseup = function () {
                    return false;
                };
            }
        },
    },
    mounted () {
        // 注册节点
        // registerNode();
        // 注册工具
        registerTools();
        // 实例化x6
        this.initGraphInPage();
        // this.graph = initGraph1(this);
        console.log(this.graph);
        // 监听
        this.initListenerCustomEvent();

        // let _this = this;
        // _this.$nextTick(() => {
        //     function getCookie(name) {
        //         var arr = document.cookie.match(
        //                 new RegExp("(^| )" + name + "=([^;]*)(;|$)")
        //         );
        //         if (arr != null) {
        //             return unescape(arr[2]);
        //         }
        //         return null;
        //     }

        //     _this.token = getCookie("Admin-Token");
        // });
    },
    watch: {
        graph: function (newVal, oldVal) {
            if (Object.keys(newVal).length == 0) {
                document.getElementsByClassName("prompt")[0].style.display = "block";
            } else {
                document.getElementsByClassName("prompt")[0].style.display = "none";
            }
        },
        navTopList: function (newVal, oldVal) {
            if (newVal.length == 0) {
                // this.initGraphInPage()
                // this.graph = initGraph1(this);
                document.getElementsByClassName("prompt")[0].style.display = "none";
            } else {
                document.getElementById("canvas").disabled = false;
            }
        },
        AllowTake: function (newVal, oldVal) {
            if (newVal) {
                let classNameFl = document.getElementsByClassName("fl");
                for (let i = 2; i <= 3; i++) {
                    this.setAttrtyle(classNameFl[i], "#1E5587", 0.85);
                    this.mouseEnterSetStyle(classNameFl[i], "#2A689F", 1, true);
                    this.mouseLeaveSetStyle(classNameFl[i], "#1E5587", 0.85, true);
                    this.mouseDownSetStyle(classNameFl[i], "#024987", 1, true);
                    this.mouseUpSetStyle(classNameFl[i], "#2A689F", 1, true);
                }
            } else {
                let classNameFl = document.getElementsByClassName("fl");
                for (let i = 2; i <= 3; i++) {
                    this.setAttrtyle(classNameFl[i], "#284967", 0.65);
                    this.mouseEnterSetStyle(classNameFl[i], "", 1, false);
                    this.mouseLeaveSetStyle(classNameFl[i], "", 0.85, false);
                    this.mouseDownSetStyle(classNameFl[i], "", 1, false);
                    this.mouseUpSetStyle(classNameFl[i], "", 0.85, false);
                }

                this.$refs.RightDrawer.show = false;
            }
        },
        AllowConfig: function (newVal, oldVal) {
            if (newVal) {
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[4], "#1E5587", 0.85);
                this.mouseEnterSetStyle(classNameFl[4], "#2A689F", 1, true);
                this.mouseLeaveSetStyle(classNameFl[4], "#1E5587", 0.85, true);
                this.mouseDownSetStyle(classNameFl[4], "#024987", 1, true);
                this.mouseUpSetStyle(classNameFl[4], "#2A689F", 1, true);
            } else {
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[4], "#284967", 0.65);
                this.mouseEnterSetStyle(classNameFl[4], "", 1, false);
                this.mouseLeaveSetStyle(classNameFl[4], "", 0.85, false);
                this.mouseDownSetStyle(classNameFl[4], "", 1, false);
                this.mouseUpSetStyle(classNameFl[4], "", 0.85, false);
            }
        },
        AllowAlign: function (newVal, oldVal) {
            if (newVal) {
                this.enableAlign = newVal;
                let classNameFl = document.getElementsByClassName("fl");
                for (let i = 5; i <= 9; i++) {
                    this.setAttrtyle(classNameFl[i], "#1E5587", 0.85);
                    this.mouseEnterSetStyle(classNameFl[i], "#2A689F", 1, true);
                    this.mouseLeaveSetStyle(classNameFl[i], "#1E5587", 0.85, true);
                    this.mouseDownSetStyle(classNameFl[i], "#024987", 1, true);
                    this.mouseUpSetStyle(classNameFl[i], "#2A689F", 1, true);
                }
            } else {
                this.enableAlign = oldVal;
                let classNameFl = document.getElementsByClassName("fl");
                for (let i = 5; i <= 9; i++) {
                    this.setAttrtyle(classNameFl[i], "#284967", 0.65);
                    this.mouseEnterSetStyle(classNameFl[i], "", 1, false);
                    this.mouseLeaveSetStyle(classNameFl[i], "", 0.85, false);
                    this.mouseDownSetStyle(classNameFl[i], "", 1, false);
                    this.mouseUpSetStyle(classNameFl[i], "", 0.85, false);
                }
            }
        },
        AllowCenter: function (newVal, oldVal) {
            if (newVal) {
                let classNameFl = document.getElementsByClassName("fl");
                for (let i = 10; i <= 12; i++) {
                    this.setAttrtyle(classNameFl[i], "#1E5587", 0.85);
                    this.mouseEnterSetStyle(classNameFl[i], "#2A689F", 1, true);
                    this.mouseLeaveSetStyle(classNameFl[i], "#1E5587", 0.85, true);
                    this.mouseDownSetStyle(classNameFl[i], "#024987", 1, true);
                    this.mouseUpSetStyle(classNameFl[i], "#2A689F", 1, true);
                }
            } else {
                let classNameFl = document.getElementsByClassName("fl");
                for (let i = 10; i <= 12; i++) {
                    this.setAttrtyle(classNameFl[i], "#284967", 0.65);
                    this.mouseEnterSetStyle(classNameFl[i], "", 1, false);
                    this.mouseLeaveSetStyle(classNameFl[i], "", 0.85, false);
                    this.mouseDownSetStyle(classNameFl[i], "", 1, false);
                    this.mouseUpSetStyle(classNameFl[i], "", 0.85, false);
                }
            }
        },
        AllowColor: function (newVal, oldVal) {
            if (newVal) {
                this.enableColor = newVal;
                let classNameFl = document.getElementsByClassName("fl");
                console.log('正在改变颜色classNameFl', classNameFl);
                this.setAttrtyle(classNameFl[13], "#1E5587", 0.85);
                this.mouseEnterSetStyle(classNameFl[13], "#2A689F", 1, true);
                this.mouseLeaveSetStyle(classNameFl[13], "#1E5587", 0.85, true);
                this.mouseDownSetStyle(classNameFl[13], "#024987", 1, true);
                this.mouseUpSetStyle(classNameFl[13], "#2A689F", 1, true);
            } else {
                this.enableColor = oldVal;
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[13], "#284967", 0.65);
                this.mouseEnterSetStyle(classNameFl[13], "", 1, false);
                this.mouseLeaveSetStyle(classNameFl[13], "", 0.85, false);
                this.mouseDownSetStyle(classNameFl[13], "", 1, false);
                this.mouseUpSetStyle(classNameFl[13], "", 0.85, false);
            }
        },
        AllowGroup: function (newVal, oldVal) {
            if (newVal) {
                this.enableGroup = newVal;
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[13], "#1E5587", 0.85);
                this.mouseEnterSetStyle(classNameFl[13], "#2A689F", 1, true);
                this.mouseLeaveSetStyle(classNameFl[13], "#1E5587", 0.85, true);
                this.mouseDownSetStyle(classNameFl[13], "#024987", 1, true);
                this.mouseUpSetStyle(classNameFl[13], "#2A689F", 1, true);
            } else {
                this.enableGroup = oldVal;
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[13], "#284967", 0.65);
                this.mouseEnterSetStyle(classNameFl[13], "", 1, false);
                this.mouseLeaveSetStyle(classNameFl[13], "", 0.85, false);
                this.mouseDownSetStyle(classNameFl[13], "", 1, false);
                this.mouseUpSetStyle(classNameFl[13], "", 0.85, false);
            }
        },
        AllowCancelGroup: function (newVal, oldVal) {
            if (newVal) {
                this.enableCancelGroup = newVal;
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[14], "#1E5587", 0.85);
                this.mouseEnterSetStyle(classNameFl[14], "#2A689F", 1, true);
                this.mouseLeaveSetStyle(classNameFl[14], "#1E5587", 0.85, true);
                this.mouseDownSetStyle(classNameFl[14], "#024987", 1, true);
                this.mouseUpSetStyle(classNameFl[14], "#2A689F", 1, true);
            } else {
                this.enableCancelGroup = oldVal;
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[14], "#284967", 0.65);
                this.mouseEnterSetStyle(classNameFl[14], "", 1, false);
                this.mouseLeaveSetStyle(classNameFl[14], "", 0.85, false);
                this.mouseDownSetStyle(classNameFl[14], "", 1, false);
                this.mouseUpSetStyle(classNameFl[14], "", 0.85, false);
            }
        },
        AllowUnbind: function (newVal, oldVal) {
            if (newVal) {
                this.enableUnbind = newVal;
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[14], "#1E5587", 0.85);
                this.mouseEnterSetStyle(classNameFl[14], "#2A689F", 1, true);
                this.mouseLeaveSetStyle(classNameFl[14], "#1E5587", 0.85, true);
                this.mouseDownSetStyle(classNameFl[14], "#024987", 1, true);
                this.mouseUpSetStyle(classNameFl[14], "#2A689F", 1, true);
            } else {
                this.enableUnbind = oldVal;
                let classNameFl = document.getElementsByClassName("fl");
                this.setAttrtyle(classNameFl[14], "#284967", 0.65);
                this.mouseEnterSetStyle(classNameFl[14], "", 1, false);
                this.mouseLeaveSetStyle(classNameFl[14], "", 0.85, false);
                this.mouseDownSetStyle(classNameFl[14], "", 1, false);
                this.mouseUpSetStyle(classNameFl[14], "", 0.85, false);
            }
        },
        isTemplateObj: function (newVal, oldVal) {
            this.$refs.LeftToolBar.isLeftToolBar = true;
            this.$refs.LeftTemplate.isTemplate = false;
        },
    },
    created () {
        let _this = this;
        _this.diagramObj.diagramId = _this.$route.query.diagramId;
        _this.isTemplate = _this.$route.query.type ? _this.$route.query.type : undefined;

        document.addEventListener("change", (e) => {
            let cells = this.graph.getSelectedCells();
            if (e.target.tagName == "INPUT") {
                if (e.target.className == "wzbqInput") {
                    let dom = e.target["parentElement"];
                    if (dom.tagName != "g" || !dom.getAttribute("data-cell-id")) {
                        dom = this.getDomByActive(dom);
                    }
                    let node = _this.graph.getCellById(dom.getAttribute("data-cell-id"));
                    let svgObjectDom = document.querySelectorAll(
                        'g[data-cell-id="' + node.id + '"] foreignObject input'
                    );
                    console.log("改变input值", svgObjectDom[0].value);
                    node.store.data.attrs.body.content = svgObjectDom[0].value;
                } else {
                    // let tableData;
                    // if (_this.$refs.LeftToolBar.tableData.length == 0) {
                    //     console.log(cells, "cells");
                    //     tableData = node.store.data.tableData;
                    // } else {
                    //     tableData = _this.$refs.LeftToolBar.tableData;
                    // }
                    // for (let itemCol of tableData) {
                    //     for (let itemRow of itemCol.cols) {
                    //         if (e.target.id == itemRow.id) {
                    //             console.log(itemRow, e.target.value);
                    //             itemRow.val = e.target.value;
                    //         }
                    //     }
                    // }
                }
            }
        });

        getDiagramById(_this.$route.query.diagramId).then((response) => {
            _this.navTopList.push(response.data);
            _this.diagramObj = response.data;
            if (response.data.diagramJson) {
                _this.resultJson = eval("(" + response.data.diagramJson + ")");
                _this.graph.fromJSON(_this.resultJson);
                this.graph.zoom(response.data.scale);
            } else {
                // _this.graph = initGraph1(_this);
            }
            // _this.graph.centerContent();
            _this.$nextTick(() => {
                console.log(document.getElementsByClassName("fl")[0]);
                let classNameFl = document.getElementsByClassName("fl");
                _this.$route.query.type
                    ? (classNameFl[0].style.background = "#284967")
                    : (classNameFl[0].style.background = "#1E5587");
                this.mouseEnterSetStyle(classNameFl[0], "#2A689F", 1, true);
                this.mouseLeaveSetStyle(classNameFl[0], "#1E5587", 0.85, true);
                this.mouseDownSetStyle(classNameFl[0], "#024987", 1, true);
                this.mouseUpSetStyle(classNameFl[0], "#2A689F", 1, true);
                this.mouseEnterSetStyle(classNameFl[1], "#2A689F", 1, true);
                this.mouseLeaveSetStyle(classNameFl[1], "#1E5587", 0.85, true);
                this.mouseDownSetStyle(classNameFl[1], "#024987", 1, true);
                this.mouseUpSetStyle(classNameFl[1], "#2A689F", 1, true);
            });
        });
        // 一般获取到是空的，但是空数组也有forEach方法啊
        Array.from(document.getElementsByTagName("g")).forEach((item) => {
            let svgNodes = item.children[0].children;
            item.removeChild(item.children[0]);
            Array.from(svgNodes).forEach((item1) => {
                item.append(item1);
            });
        });
    },
    // 权宜之计
    beforeRouteEnter(to, from, next) {
        if(window.needRefresh){
            window.needRefresh = false;
            window.location.reload();
        }
        return next();
    }
};
</script>
<style scoped lang="less">
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #c9d7f8 !important;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f2f2f2;
    box-shadow: 1px 1px 5px #333 inset;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #444;
}

.btntheme {
    color: #027969 !important;
    background-color: #fff !important;
    border-color: #027969 !important;
}

#components-layout .ant-layout-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    line-height: 50px;
    height: 50px;
    background: #fff;
    color: rgb(0, 0, 0);
    z-index: 2;
    box-shadow: 1px 1px 5px #ddd;
}

.icon-shrink {
    position: fixed;
    right: 20px;
    top: 5px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    background: #1890ff;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    user-select: none;
    z-index: 1111;
}

#components-layout .ant-layout-sider {
    background: #fff;
    color: rgb(167, 164, 164);
}

.ant-slider {
    margin-bottom: 16px;
}

.topo-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-origin: left top;
    overflow: auto;

    background-image: linear-gradient(
            45deg,
            #ccc 25%,
            transparent 25%,
            transparent 75%,
            #ccc 75%,
            #ccc
    ),
    linear-gradient(
            45deg,
            #ccc 25%,
            transparent 25%,
            transparent 75%,
            #ccc 75%,
            #ccc
    );
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
}

.topo-layer-view-selected {
    outline: 1px solid #0cf;
}



.v-modal {
    display: none;
}

// Minimap区域
.minimap-container {
    margin-top: 5px;
    position: absolute;
    right: 5px;
    bottom: 30px;
    z-index: 200;
    box-shadow: 0 0 2px #027969;
    border-radius: 5px;
}

.pageMain {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    user-select: none;

    .leftNav {
        position: absolute;
        left: -20px;
        top: -10px;
        bottom: 0;
        min-width: unset !important;
        max-width: unset !important;
        width: 260px !important;
        z-index: 1;
        margin: 12px 20px 20px;
        height: 96%;
        overflow: hidden;
        // background: rgba(0, 115, 222, 0.15);
        background: #fff;
        border-radius: 4px;
    }

    .centerTop {
        position: absolute;
        left: 270px;
        top: -5px;
        width: 87%;
        display: flex;
        justify-content: space-between;
        height: 46px;
        line-height: 46px;
        margin: 8px;
        background: #fff;

        .leftTitle {
            width: 75%;
            padding-top: 1px;
        }

        .leftTitle > span {
            min-width: 100px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            padding: 0 8px;
            margin: 6px 24px;
            font-size: 14px;
            background: #027969;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border: 1px solid #004579;
            color: #ffffff;

            i{
                cursor: pointer;
                float: right;
                line-height: 30px;
            }
        }

        .rightButton {
            width: 40%;
            line-height: 30px;
            display: flex;
            justify-content: space-evenly;
            padding-right: 2%;
            flex-wrap: wrap;
            align-content: center;
            height: 46px;
            margin-top: 2px;

            .btn {
                font-size: 14px;
                width: 80px;
                height: 30px;
                line-height: 30px;
                padding: 0;
            }
            .btnadd {
                color: #fff !important;
                border-color: rgba(0, 115, 222, 0.1) !important;
                border-radius: 2px;
                background-color: #027969 !important;
            }
            .hotkey-hints{
                height: 30px;
                margin-left: 11px;
            }
            .hotkey-hints i{
                font-size: 26px;
                color: #1890ff;
                line-height: 30px;
            }
        }
    }

    ///** 按钮菜单条 **/
    //.rightNav {
    //    position: absolute;
    //    right: 0;
    //    top: 0;
    //    bottom: 0;
    //    min-width: unset !important;
    //    max-width: unset !important;
    //    width: 300px !important;
    //    z-index: 1;
    //    overflow: auto;
    //}

    /** 画布 **/
    .centerContain {
        position: absolute;
        left: 264px;
        top: -5px;
        bottom: 0;
        z-index: 1;
        /*overflow: auto !important;*/
        /*overflow-x: auto !important;*/
        transition: all 0.3s;
        width: 86%;
        //height:100%;
        //margin: 75px 14px 20px;
        margin-top: 75px;
        margin-left: 14px;

        .graph-main-container {
            width: 80%;
            height: 73%;
            flex: 1;
        }

        #tooltip-container {
            user-select: none;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            font-size: 12px;
            color: #545454;
            width: 350px;
            word-break: break-all;
            background-color: rgba(255, 255, 255, 0.9);
            box-shadow: rgb(174, 174, 174) 0px 0px 10px;
            position: absolute;
            z-index: 1;
            display: none;
            padding: 10px;
        }

        & .fixed {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .canvas-content {
            background-color: white;
            width: 1920px;
            height: 1080px;
        }

        .btns-content {
            position: fixed;
            bottom: 10px;
            right: 320px;
            left: 280px;

            button {
                margin-left: 10px;
            }
        }
    }

    #canvas {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -khtml-user-select: none;
        user-select: none;
    }

    #canvas .operate {
        position: fixed;
        height: 54px;
        line-height: 54px;
        margin: 31px 0 0 33px;
        top: 150px;
        left: 450px;
        z-index: 9;
        background: rgba(0, 115, 222, 0.15);
        box-sizing: border-box;
        border-radius: 2px;
    }

    #canvas .operate:nth-child(2) {
        left: 685px;
    }
    #canvas .operate:nth-child(3) {
        left: 1050px;
        // padding: 10px;
        .fl {
            // margin-right: 0;
            ::v-deep .el-color-picker {
                width: 100%;
                height: 100%;
                .el-color-picker__mask,
                .el-color-picker__trigger {
                    width: 100%;
                    height: 100%;
                    border-radius: unset;
                }
            }
        }
    }
    #canvas .operate > ul {
        overflow: hidden;
        margin: 10px auto;
        padding: 0 0 0 10px;
    }

    #canvas .fl {
        float: left;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        list-style: none;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        background: #284967;
        opacity: 0.65;
    }

    #canvas .operate:nth-child(1) .fl:nth-child(2) {
        background: #1e5587;
    }

    #canvas .prompt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        width: 366px;
        height: 25px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3674ff;
        line-height: 107px;
        display: none;
    }
}
</style>
