<template>
  <div class="Alarm">
    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div class="tabBox">
        <div
          :class="ifTabChoose === 1 ? 'tabGreen' : 'tabGrey'"
          @click="
            ifTabChoose = 1;
            $router.push('/system/alarm/alarmManagement');
          "
        >
          告警信息
        </div>
        <div
          :class="ifTabChoose === 2 ? 'tabGreen' : 'tabGrey'"
          @click="
            ifTabChoose = 2;
            $router.push('/system/alarm/alarmSetting');
          "
        >
          告警配置
        </div>
        <div
          :class="ifTabChoose === 3 ? 'tabGreenLarge' : 'tabGreyLarge'"
          @click="
            ifTabChoose = 3;
            $router.push('/system/alarm/alarmGroup');
          "
        >
          告警通知人分组
        </div>
      </div>
    </div>

    <!-- 中部表格位置############################################################################################################ -->
    <div class="tablePlace">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // tab栏的数据选择
      ifTabChoose: 1,
    };
  },
};
</script>
<style lang="less" scoped>
// 顶部样式##################################################################
.topBar {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  box-sizing: border-box;
  height: 8rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  .tabBox {
    width: 34.8rem;
    height: 3.2rem;
    border: 0.2rem;
    background-color: #0c375e;
    border: 0.1rem solid #004579;
    cursor: pointer;
    display: flex;
    border-radius: 0.2rem;
    font-size: 1.4rem;
    color: #fff;
    line-height: 3.2rem;
  }
  .tabGreen {
    width: 10.2rem;
    height: 3.2rem;
    background-color: #027969;
    text-align: center;
  }
  .tabGrey {
    width: 10.2rem;
    height: 3.2rem;
    text-align: center;
  }
  .tabGreenLarge {
    width: 14.4rem;
    height: 3.2rem;
    background-color: #027969;
    text-align: center;
  }
  .tabGreyLarge {
    width: 14.4rem;
    height: 3.2rem;
    text-align: center;
  }
}

// 中部表格样式##################################################################
.tablePlace {
  height: 86.4rem;
  width: 171.2rem;
  position: absolute;
  top: 10.4rem;
  left: 0;
  overflow: hidden;
  background-color: #0d3050;
}
</style>
