<template>
  <div class="mon-ener-state">
    <div class="title">
      <span class="text1">当前逆变器:</span>
      <span class="text3">{{
        inverterBasicInfo[0].value != "0" ? inverterBasicInfo[0].value : ""
      }}</span>
      <el-select
        size="mini"
        class="search"
        v-model="inverterName"
        placeholder="请选择逆变器"
      >
        <el-option
          v-for="item in optionList"
          :key="item.label"
          :label="item.value"
          :value="item.label"
        ></el-option>
      </el-select>
    </div>
    <div class="scroll-box">
      <div class="scroll-box-top">
        <div class="ener-state">
          <div class="top">
            <span class="title">实时发电功率</span>
          </div>

          <div class="chart">
            <MapEcharts3
              ref="lineCharts1"
              class="chart1"
              :amountObj="{ amount, ymax }"
            />
          </div>
        </div>
        <div class="ener-info1">
          <span class="title">基本信息</span>
          <div class="info-content">
            <div class="infoLeft">
              <span>厂家:</span>
              <span>型号:</span>
              <span>额定交流功率:</span>
              <span>电网类型:</span>
              <span>组件峰值功率:</span>
              <span>日最大发电功率:</span>
              <span>月最大发电功率:</span>
            </div>
            <div class="infoRight">
              <span>{{ inverterBasicInfo[1].value }}</span>
              <span class="info-text">{{ inverterBasicInfo[2].value }}</span>
              <span>{{ inverterBasicInfo[3].value }}</span>
              <span>{{ inverterBasicInfo[4].value || "三相" }}</span>
              <span>{{ inverterRealtime.data[1].value }}</span>
              <span>{{ inverterRealtime.data[2].value }}</span>
              <span>{{ inverterRealtime.data[3].value }}</span>
            </div>
          </div>
        </div>
        <div class="info-list">
          <div class="info1">
            <span class="title">发电信息</span>
            <div class="enerList">
              <div class="certent">
                <div class="left">
                  <img src="@/assets/elecGenerate/enerInfo1.png" alt />
                </div>
                <div class="right">
                  <div class="top">{{ inverterInfoAndAmount[0].value }}kWh</div>
                  <div class="bottom">日发电量</div>
                </div>
                <div class="right1">
                  <div class="top">
                    同比<span>{{ inverterInfoAndAmount[6].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[6].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[6].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                  <div class="bottom">
                    环比<span>{{ inverterInfoAndAmount[9].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[9].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[9].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                </div>
              </div>
              <div class="certent">
                <div class="left">
                  <img src="@/assets/elecGenerate/enerInfo2.png" alt />
                </div>
                <div class="right">
                  <div class="top">{{ inverterInfoAndAmount[1].value }}kWh</div>
                  <div class="bottom">月发电量</div>
                </div>
                <div class="right1">
                  <div class="top">
                    同比<span>{{ inverterInfoAndAmount[7].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[7].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[7].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                  <div class="bottom">
                    环比<span>{{ inverterInfoAndAmount[10].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[10].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[10].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                </div>
              </div>
              <div class="certent">
                <div class="left">
                  <img src="@/assets/elecGenerate/enerInfo3.png" alt />
                </div>
                <div class="right">
                  <div class="top">{{ inverterInfoAndAmount[2].value }}kWh</div>
                  <div class="bottom">年发电量</div>
                </div>
                <div class="right1">
                  <!-- <div class="top">同比<span>{{ inverterInfoAndAmount[8].value }}%</span>
                      <img v-if="inverterInfoAndAmount[8].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                      <img v-if="inverterInfoAndAmount[8].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                  </div> -->
                  <div class="bottom">
                    环比<span>{{ inverterInfoAndAmount[11].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[11].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[11].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info2">
            <span class="title">收益信息</span>
            <div class="enerList">
              <div class="certent">
                <div class="left">
                  <img src="@/assets/elecGenerate/moneyInfo1.png" alt />
                </div>
                <div class="right">
                  <div class="top">{{ inverterInfoAndAmount[3].value }}元</div>
                  <div class="bottom">日收益</div>
                </div>
                <div class="right1">
                  <div class="top">
                    同比<span>{{ inverterInfoAndAmount[12].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[12].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[12].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                  <div class="bottom">
                    环比<span>{{ inverterInfoAndAmount[15].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[15].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[15].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                </div>
              </div>
              <div class="certent">
                <div class="left">
                  <img src="@/assets/elecGenerate/moneyInfo2.png" alt />
                </div>
                <div class="right">
                  <div class="top">{{ inverterInfoAndAmount[4].value }}元</div>
                  <div class="bottom">月收益</div>
                </div>
                <div class="right1">
                  <div class="top">
                    同比<span>{{ inverterInfoAndAmount[13].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[13].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[13].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                  <div class="bottom">
                    环比<span>{{ inverterInfoAndAmount[16].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[16].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[16].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                </div>
              </div>
              <div class="certent">
                <div class="left">
                  <img src="@/assets/elecGenerate/moneyInfo3.png" alt />
                </div>
                <div class="right">
                  <div class="top">{{ inverterInfoAndAmount[5].value }}元</div>
                  <div class="bottom">年收益</div>
                </div>
                <div class="right1">
                  <!-- <div class="top">同比<span>{{ inverterInfoAndAmount[14].value }}%</span>
                      <img v-if="inverterInfoAndAmount[14].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                      <img v-if="inverterInfoAndAmount[14].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                  </div> -->
                  <div class="bottom">
                    环比<span>{{ inverterInfoAndAmount[17].value }}%</span>
                    <img
                      v-if="inverterInfoAndAmount[17].value > 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowUp.png"
                      alt
                    />
                    <img
                      v-if="inverterInfoAndAmount[17].value < 0"
                      class="imgBehind"
                      src="@/assets/ElecManagement/arrowDown.png"
                      alt
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-echarts">
        <span class="title">功率及发电量趋势</span>
        <div class="chart">
          <div class="tabBarPlace1">
            <div class="tabBarTop">
              <div class="date">
                <!-- 日 -->
                <el-date-picker
                  class="styleWid"
                  size="mini"
                  v-show="datePickerIndex == 0"
                  v-model="generalDay"
                  range-separator="至"
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
                <!-- 月 -->
                <el-date-picker
                  v-show="datePickerIndex == 1"
                  class="styleWid"
                  size="mini"
                  v-model="generalDay"
                  type="month"
                  placeholder="选择月"
                ></el-date-picker>
                <!-- 年 -->
                <el-date-picker
                  v-show="datePickerIndex == 2"
                  class="styleWid"
                  size="mini"
                  v-model="generalDay"
                  type="year"
                  placeholder="选择年"
                ></el-date-picker>
              </div>
              <div class="dateChoose">
                <!-- <div :class="btnTypeBranch" @click="changeTab(0)">分</div>
              <div :class="btnTypeTime" @click="changeTab(1)">时</div> -->
                <div :class="btnTypeDay" @click="changeTab(0)">日</div>
                <div :class="btnTypeMonth" @click="changeTab(1)">月</div>
                <div :class="btnTypeYear" @click="changeTab(2)">年</div>
              </div>
            </div>
          </div>
          <div class="trend-content">
            <MapEcharts4
              ref="lineCharts"
              :whichTab="whichTab"
              :generalDay="generalDay"
              :inverterId="inverterId"
            />
          </div>
        </div>
      </div>
      <div class="info-table">
        <div class="table-left">
          <div class="header">
            <div class="headerText">直流侧</div>
            <div
              @click="changeDcDialog"
              class="headerBtn"
              style="cursor: pointer;"
            >
              <img
                class="his-icon"
                src="@/assets/elecGenerate/history.png"
                alt
              />
              <span>历史数据</span>
            </div>
          </div>
          <el-table
            :data="tableData"
            style="width: 100%;"
            stripe:false
            height="19.1rem"
            border:false
            :header-cell-style="headerClass2"
            :cell-style="cellStyle1"
          >
            <el-table-column prop="huilu" label="输入回路"></el-table-column>
            <el-table-column prop="dianya" label="电压（V）"></el-table-column>
            <el-table-column prop="dianliu" label="电流（A）"></el-table-column>
            <el-table-column prop="gonglv" label="功率（kW）"></el-table-column>
          </el-table>
        </div>
        <DcDialog
          v-if="dcDlgVisible"
          @upVisible="(val) => (dcDlgVisible = val)"
          ref="dcDlgVisible"
          :inverterId="inverterId"
        />
        <div class="table-center">
          <img src="@/assets/elecGenerate/iver1.png" alt class="tabCenter" />
          <img class="tabLeft" src="@/assets/elecGenerate/dcLeft.png" alt />
          <img class="tabRigth" src="@/assets/elecGenerate/acRigth.png" alt />
          <span class="textCenter">逆变器</span>
          <span class="textLeft">DC输入</span>
          <span class="textRight">AC输出</span>
        </div>
        <AcDialog
          v-if="acDlgVisible"
          ref="acDlgVisible"
          @upVisible="(val) => (acDlgVisible = val)"
          :inverterId="inverterId"
        />
        <div class="table-rigth">
          <div class="header">
            <div class="headerText">交流侧</div>
            <div
              @click="changeAcDiglog"
              class="headerBtn"
              style="cursor: pointer;"
            >
              <img
                class="his-icon"
                src="@/assets/elecGenerate/history.png"
                alt
              />
              <span>历史数据</span>
            </div>
          </div>
          <el-table
            :span-method="arraySpanMethod"
            :data="tableData2"
            style="width: 100%;"
            stripe:false
            height="19.1rem"
            border:false
            :header-cell-style="headerClass2"
            :cell-style="cellStyle1"
            class="rightTable"
          >
            <el-table-column
              prop="canshu"
              label="参数"
              width="150"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="azu"
              label="A相"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="bzu"
              label="B相"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="czu"
              label="C相"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg";
import MapEcharts3 from "./mapEcharts3";
import MapEcharts4 from "./mapEcharts4";
import DcDialog from "./echartsDialog/dcDialog.vue";
import AcDialog from "./echartsDialog/acDialog.vue";
export default {
  components: {
    MapEcharts3,
    MapEcharts4,
    DcDialog,
    AcDialog,
  },
  mixins: [tableBg],
  data() {
    return {
      optionData: {},
      showInterval: true,
      dcDlgVisible: false,
      acDlgVisible: false,
      whichTab: 0,
      btnTypeBranch: "btnGreen",
      btnTypeTime: "btnDark",
      btnTypeDay: "btnDark",
      btnTypeMonth: "btnDark",
      btnTypeYear: "btnDark",
      optionList: [],
      tableData: [],
      tableData2: [],
      inverterName: "",
      inverterId: "",
      customerId: "",
      inverterBasicInfo: [
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
      ],
      inverterInfoAndAmount: [
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
      ],
      inverterRealtime: {
        data: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
      },
      datePickerIndex: 0,
      generalDay: "",
      thisDay: "",
      thisMonth: "",
      thisYear: "",
      xdata: "",
      ydata: "",
      amount: 0,
      ymax: 100,
      flag: false,
    };
  },
  created() {
    this.getThisDayMonthYear();
    this.initInverterData();
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.$refs.lineCharts1.init();
        this.$refs.lineCharts.init();
        this.flag = true;
      });
    }, 500);
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 2) {
        if (columnIndex === 1) {
          return [1, 3];
        } else if (columnIndex === 2) {
          return [0, 0];
        } else if (columnIndex === 3) {
          return [0, 0];
        }
      }
      if (rowIndex === 3) {
        if (columnIndex === 1) {
          return [1, 3];
        } else if (columnIndex === 2) {
          return [0, 0];
        } else if (columnIndex === 3) {
          return [0, 0];
        }
      }
      if (rowIndex === 4) {
        if (columnIndex === 1) {
          return [1, 3];
        } else if (columnIndex === 2) {
          return [0, 0];
        } else if (columnIndex === 3) {
          return [0, 0];
        }
      }
    },
    changeTab(index) {
      this.whichTab = index;
      this.showInterval = false;
      this.datePickerIndex = index;
      // console.log(this.whichTab, this.inverterId);
      if (index == 0) {
        this.btnTypeDay = "btnGreen";
        this.btnTypeMonth = "btnDark";
        this.btnTypeYear = "btnDark";
        this.generalDay =
          this.thisYear + "-" + this.thisMonth + "-" + this.thisDay;
      } else if (index == 1) {
        this.btnTypeDay = "btnDark";
        this.btnTypeMonth = "btnGreen";
        this.btnTypeYear = "btnDark";
        this.generalDay = this.thisYear + "-" + this.thisMonth;
      } else if (index == 2) {
        this.btnTypeDay = "btnDark";
        this.btnTypeMonth = "btnDark";
        this.btnTypeYear = "btnGreen";
        this.generalDay = new Date();
      }
      setTimeout(() => {
        this.showInterval = true;
        this.$nextTick(() => {
          this.$refs.lineCharts.init();
        });
      }, 0);
    },
    getThisDayMonthYear() {
      let date = new Date();
      this.thisDay = date.getDate();
      this.thisMonth = date.getMonth() + 1;
      this.thisYear = date.getFullYear();
      if (this.thisDay < 10) {
        this.thisDay = "0" + this.thisDay;
      }
      if (this.thisMonth < 10) {
        this.thisMonth = "0" + this.thisMonth;
      }
    },
    changeDcDialog() {
      this.dcDlgVisible = true;
    },
    changeAcDiglog() {
      this.acDlgVisible = true;
    },
    initInverterData() {
      this.customerId = this.$store.state.storage.customerId;
      this.$store
        .dispatch("getInvertersByCustomer", this.customerId)
        .then(() => {
          console.log(
            "逆变器状态",
            this.$store.state.genManagement.getInvertersByCustomer
          );
          if (
            this.$store.state.genManagement.getInvertersByCustomer.errorCode ==
            0
          ) {
            this.optionList = this.$store.state.genManagement.getInvertersByCustomer.data;
            if (this.optionList.length > 0) {
              this.inverterName = this.optionList[0].label;
              this.inverterId = this.optionList[0].label;
              this.initInverterInfoAndAmount();
              this.initInverterRealtime();
              this.changeTab(0);
            } else {
              this.optionList = [];
              this.inverterName = "";
              this.inverterId = 0;
              this.initInverterInfoAndAmount();
              this.initInverterRealtime();
              this.changeTab(0);
            }
          } else {
            this.optionList = [];
            this.inverterName = "";
            this.inverterId = 0;
            this.initInverterInfoAndAmount();
            this.initInverterRealtime();
            this.changeTab(0);
          }
        });
    },
    initInverterInfoAndAmount() {
      this.$store.dispatch("getInverterBasicInfo", this.inverterId).then(() => {
        if (
          this.$store.state.genManagement.getInverterBasicInfo.errorCode == 0
        ) {
          this.inverterBasicInfo = this.$store.state.genManagement.getInverterBasicInfo.data;
        } else {
          this.inverterBasicInfo = [
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
          ];
        }
      });
      this.$store
        .dispatch("getInverterInfoAndAmount", this.inverterId)
        .then(() => {
          if (
            this.$store.state.genManagement.getInverterInfoAndAmount
              .errorCode == 0
          ) {
            this.inverterInfoAndAmount = this.$store.state.genManagement.getInverterInfoAndAmount.data;
            this.ymax = this.inverterBasicInfo[5].value;
          } else {
            this.inverterInfoAndAmount = [
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
              { value: 0 },
            ];
          }
        });
    },
    initInverterRealtime() {
      this.$store.dispatch("getInverterRealtime", this.inverterId).then(() => {
        if (
          this.$store.state.genManagement.getInverterRealtime.errorCode == 0
        ) {
          this.inverterRealtime = this.$store.state.genManagement.getInverterRealtime.data;
          this.amount = this.inverterRealtime.data[0].value;
          this.tableData2 = this.inverterRealtime.jiaoliuceList;
          this.tableData = this.inverterRealtime.zhiliuceList;
        } else {
          this.inverterRealtime = {
            data: [{ value: "" }, { value: 0 }, { value: 0 }, { value: 0 }],
          };
          this.amount = 0;
          this.tableData2 = [];
          this.tableData = [];
        }
      });
    },
  },
  watch: {
    "$store.state.storage.customerId": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        if (!this.flag) return;
        this.customerId = newVal;
        this.initInverterData();
      },
    },
    inverterName: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return;
        if (!this.flag) return;
        this.inverterId = newVal;
        console.log("inverterName==============>", this.inverterId);
        this.initInverterInfoAndAmount();
        this.initInverterRealtime();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.mon-ener-state {
  .title {
    margin-top: 1.5rem;
    .text1 {
      color: rgba(255, 255, 255, 0.85);
      opacity: 0.85;
    }
    .text2 {
      margin-left: 1.5rem;
      color: rgba(255, 255, 255, 0.85);
    }
    .text3 {
      margin-left: 1.5rem;
      color: rgba(255, 255, 255, 0.85);
    }
    .search {
      width: 22.8rem;
      float: right;
      position: relative;
      right: 1rem;
    }
  }
  .scroll-box {
    width: 100%;
    height: calc(100vh - 24rem);
    margin-top: 2.4rem;
    overflow: hidden;
    overflow-y: scroll;
    .scroll-box-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }
  }
  .ener-state {
    flex: 1 1 25%;
    margin-right: 1.5rem;
    // width: 34.7rem;
    height: 23.6rem;
    // margin-top: 2.2rem;
    background: #0d3050;
    .top {
      padding: 2rem 0 0 2.4rem;
      .title {
        font-size: 1.6rem;
        color: #37a5dd;
        float: left;
        margin-top: 0;
      }
    }
    .chart {
      width: 100%;
      height: 100%;
      margin-top: 3rem;
      .chart1 {
        margin-left: 43%;
        transform: translateX(-50%);
      }
    }
  }
  .ener-info1 {
    flex: 1 1 25%;
    margin-right: 1.5rem;
    // width: 34.7rem;
    height: 23.6rem;
    background: #0d3050;
    // position: absolute;
    // top: 5.3rem;
    // left: 35.9rem;
    padding: 2rem 0 0 2.4rem;
    box-sizing: border-box;
    .title {
      position: relative;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      color: #37a5dd;
    }
    .info-content {
      font-size: 1.2rem;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 2rem;
      .infoLeft {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-end;
        margin-right: 0.8rem;
        span {
          margin-bottom: 0.6rem;
        }
      }
      .infoRight {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        span {
          margin-bottom: 0.6rem;
        }
      }
    }
  }
  .info-list {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info1 {
      flex: 1;
      margin-right: 1.5rem;
      // width: 34.7rem;
      height: 23.6rem;
      // position: absolute;
      // top: 5.3rem;
      // left: 72rem;
      background: #0d3050;
      padding: 2rem 0 0 2.4rem;
      box-sizing: border-box;
      .title {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
      }
      .enerList {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 0.5rem;
        height: 18rem;
        .certent {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .left {
            margin-right: 0.6rem;
            img {
              width: 3.6rem;
              height: 3.6rem;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #fff;
            margin-bottom: 0.5rem;
            width: 50%;
            .top {
              font-size: 1.6rem;
            }
            .bottom {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .info2 {
      flex: 1;
      // width: 34.7rem;
      height: 23.6rem;
      background: #0d3050;
      // position: absolute;
      // top: 5.3rem;
      // right: 0;
      padding: 2rem 0 0 2.4rem;
      box-sizing: border-box;
      .title {
        position: relative;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
      }
      .enerList {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 0.5rem;
        height: 18rem;
        .certent {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .left {
            margin-right: 0.6rem;
            img {
              width: 3.6rem;
              height: 3.6rem;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #fff;
            margin-bottom: 0.5rem;
            width: 50%;
            .top {
              font-size: 1.6rem;
            }
            .bottom {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .imgBehind {
      width: 8px;
      height: 12px;
    }
    .right1 {
      color: #fff;
      width: 50%;
      span {
        margin: 0px 5px;
      }
    }
  }
  .info-echarts {
    width: 100%;
    // width: 142.6rem;
    height: 27rem;
    background: #0d3050;
    border-radius: 4px;
    margin-top: 1.5rem;
    padding: 2rem 0 0 0;
    box-sizing: border-box;
    position: relative;
    .title {
      font-size: 1.6rem;
      color: #37a5dd;
      margin-left: 2.4rem;
      margin-top: 0;
      display: inline-block;
    }
    .chart {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 1.6rem 2.4rem 0 2.4rem;
      .trend-content {
        width: 96%;
        height: 90%;
      }
      .tabBarPlace1 {
        width: 5rem;
        .tabBarTop {
          position: absolute;
          top: 1.6rem;
          right: 2.4rem;
          z-index: 99;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .date {
            width: 18rem;
            margin-right: 1rem;
          }
          .dateChoose {
            border-radius: 0.2rem;
            // border: 0.1rem solid #004579;
            box-sizing: border-box;
            color: #fff;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            z-index: 100;
            .btnDark {
              height: 3rem;
              width: 4.6rem;
              background-color: #0b375e;
              font-size: 1.4rem;
              padding: 0;
              margin: 0;
              cursor: pointer;
              line-height: 3rem;
              text-align: center;
            }
            .btnGreen {
              height: 3rem;
              width: 4.6rem;
              background-color: #027969;
              font-size: 1.4rem;
              padding: 0;
              margin: 0;
              cursor: pointer;
              line-height: 3rem;
              text-align: center;
            }
          }
          .img1 {
            height: 2.4rem;
            width: 2.4rem;
            position: absolute;
            top: 2.8rem;
            left: 53.2rem;
            cursor: pointer;
          }
        }
      }
      .trend-content {
        position: absolute;
        left: 0;
        top: 2rem;
      }
    }
  }
  .info-table {
    width: 100%;
    // width: 142.6rem;
    height: 27.3rem;
    background: #0d3050;
    border-radius: 4px;
    margin-top: 1.5rem;
    position: relative;
    padding-top: 2.4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .table-left {
      width: 54.7rem;
      margin-left: 2.4rem;
      .header {
        width: 100%;
        height: 3.2rem;
        background-color: #16518d;
        text-align: center;
        line-height: 3.2rem;
        position: relative;
        margin-bottom: 0.1rem;
        .headerText {
          font-size: 1.4rem;
          font-weight: 500;
          color: #fff;
        }
        .headerBtn {
          position: absolute;
          right: 1.6rem;
          top: 0;
          .his-icon {
            width: 1.117rem;
            height: 1.152rem;
            margin-right: 0.5rem;
          }
          span {
            color: #fff;
            opacity: 0.65;
            font-size: 1.2rem;
          }
        }
      }
    }
    .table-center {
      margin: 0 2.4rem;
      width: 24rem;
      position: relative;
      .tabLeft {
        width: 12.2rem;
        position: absolute;
        left: -6%;
        top: 41%;
      }
      .tabRigth {
        width: 12.2rem;
        position: absolute;
        left: 52%;
        top: 41%;
      }
      .tabCenter {
        width: 12.7rem;
        height: 12.8rem;
        position: absolute;
        left: 50%;
        top: 10%;
        transform: translateX(-50%);
      }
      span {
        font-size: 1.6rem;
        color: #37a5dd;
      }
      .textLeft {
        position: absolute;
        left: -6%;
        top: 65%;
      }
      .textRight {
        display: inline-block;
        width: 6rem;
        position: absolute;
        left: 81%;
        top: 65%;
      }
      .textCenter {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
    }
    .table-rigth {
      width: 54.7rem;
      margin-right: 2.4rem;
      .header {
        width: 100%;
        height: 3.2rem;
        background-color: #16518d;
        text-align: center;
        line-height: 3.2rem;
        position: relative;
        margin-bottom: 0.1rem;
        .headerText {
          font-size: 1.4rem;
          font-weight: 500;
          color: #fff;
        }
        .headerBtn {
          position: absolute;
          right: 1.6rem;
          top: 0;
          .his-icon {
            width: 1.117rem;
            height: 1.152rem;
            margin-right: 0.5rem;
          }
          span {
            color: #fff;
            opacity: 0.65;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.mon-ener-state {
  margin-top: 1.5rem;
  .el-table tr {
    background: rgba(20, 38, 55);
    opacity: 1;
  }
  .rightTable {
    tbody {
      tr {
        td:first-child {
          background-color: rgb(18, 62, 103);
        }
      }
    }
  }
  .tabBarTop {
    .el-input__inner {
      height: 3rem !important;
      line-height: 3rem !important;
      width: 18rem !important;
    }
    .el-input__icon {
      height: 3rem !important;
      line-height: 3rem !important;
    }
    .el-date-editor {
      width: 18rem !important;
    }
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #0c2f4f;
  }
  .el-table .el-table__cell {
    padding: 0rem 0;
  }
  .el-table thead {
    color: #ffffff;
    font-weight: 500;
  }
  .el-table--border th.el-table__cell,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #0c2f4f;
  }
  .el-table thead.is-group th.el-table__cell {
    background: #0c2f4f;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #0c2f4f;
  }
  .el-table--border,
  .el-table--group {
    border: 1px solid #0c2f4f;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: #0c2f4f;
    z-index: 1;
  }
}
</style>
