<template>
  <div class="components-layout-left" v-if="isLeftToolBar">
    <!-- class="inputBgc" -->
    <el-input class="search-box" clearable
              v-model="queryParams.componentName"
              suffix-icon="el-icon-search"
              placeholder="搜索组件"
              @input="onSearch"
    />
    <!-- class="dialogBgc" -->
    <!--<div style="text-align: center; margin-top: 11px">
      &lt;!&ndash; popper-class="selectBgc" &ndash;&gt;
      <el-select v-model="svgColor" @change="changeSvgColor" placeholder="请选择" style="width: 228px; height: 40px; border-radius: 2px">
        <el-option
            v-for="item in ColorOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
        </el-option>
      </el-select>
    </div>-->
    <el-collapse class="collapseTitle" v-model="activeKey" accordion >
      <el-collapse-item v-for="(item, index) in listGroup"
                        :key="index"
                        :title="item.componentName"
                        :name="item.componentId"
      >
        <ul class="svg-nav-list">
          <li v-for="(item1, index1) in item.children"
              :key="index1"
              @mousemove="toolTipShow(item1.componentName, $event)"
              @mouseleave="toolTipShow(false)"
          >
            <div class="title">{{ item1.componentName }}</div>
            <img :src="envApi + item1.sourceUrl"
                 :title="item1.componentName"
                 @mousedown="startDrag(item1, $event)"
            />
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import config from "@/config/index.js";
import global from '@/global/global.js';
import { listComponents } from "@/api/diagram/component";
import { Addon} from "@antv/x6";
import { Channel} from "@/packages/x6/common/transmit";
import {
  ActionType,
  CustomEventTypeEnum,
  FONTSizeEnum,
  SVGSizeEnum,
  TABLESizeEnum,
} from "@/packages/x6/common/enums";
import {
  getSvgFontByHtml,
  getSvgNodeByHtml,
  getSvgNodeByTag,
  getSvgTableByHtml,
} from "@/packages/x6/common/transform";

import { setSvgAttrs } from '@/packages/x6/common/index';

let timer;
export default {
  props: ["nodes", "svgNodes", "graph"],
  data() {
    return {
      //网站前缀
      envApi: config.baseURL + 'component',
      dnd: {},
      freeze: false,
      isLeftToolBar: true,
      //默认展开的collapse
      activeKey: ['1'],
      //当前激活的key
      text: `这里是预留位置.`,
      //显示数据
      listGroup: [],
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        componentName: null,
        componentClass: null,
        componentType: null,
        componentId: null,
        version: null,
        groupId: null,
        num: null,
        isData: null,
        isLinkage: null,
        sourceUrl: null,
        isEnable: null,
      },
      ColorOptions: [{
        // value: 'components_6',
        value: 'components',
        label: '默认'
      }, {
        value: 'components_2',
        label: '0.4kV'
      }, {
        value: 'components_3',
        label: '10kV'
      }, {
        value: 'components_4',
        label: '35kV'
      }, {
        value: 'components_5',
        label: '110kV'
      }],
      svgColor: '默认',
      svgTypeColor: 'components',
      initSvgTypeColor: 'components_6',
      // initSvgTypeColor: 'components',
      //元件搜索名
      componmentName: null,
    };
  },
  watch: {
    graph() {
      this.initDnd();
    },
  },
  created() {
    this.getList();
    // 冻结工具栏
    Channel.eventListener(CustomEventTypeEnum.FREEZE_GRAPH, (bool) => {
      this.freeze = bool;
    });
  },
  methods: {
    changeSvgColor(value) {
      let _this = this;
      global.SVG_COLOR = global.SVG_GROUP.get(value);
      for (let item of this.listGroup) {
        for (const itemElement of item.children) {
          if(itemElement.sourceUrl){
            let sourceUrl = itemElement.sourceUrl.split('/');
            sourceUrl[2] = value;
            itemElement.sourceUrl = sourceUrl.join('/');
          }
        }
      }
      // _this.svgTypeColor = value
      // console.log("_this.svgTypeColor",_this.svgTypeColor)
    },
    toolTipShow(param, e) {
      if (param) {
        document.getElementById("tooltip").style.top = e.clientY - 128 + "px";
        document.getElementById("tooltip").style.left =
            e.clientX - 70 - param.length * 6 + "px";
        document.getElementById("tooltip").querySelector("span").innerText =
            param;
        document.getElementById("tooltip").style.display = "block";
      } else {
        document.getElementById("tooltip").style.display = "none";
      }
    },
    initDnd() {
      let _this = this;
      this.dnd = new Addon.Dnd({
        target: _this.graph,
        getDragNode(node) {
          const attrs = { color: global.SVG_COLOR };
          _this.$nextTick(() => setSvgAttrs(_this, node, attrs));
          return node;
        },
        validateNode(node) {
          if (!_this.$parent.diagramObj.diagramJson) {
            _this.$parent.diagramObj.diagramJson = '{"cells":[]}';
          }
          const attrs = {
            color: global.SVG_COLOR
          };
          _this.$nextTick(() => {
            setSvgAttrs(_this, node, attrs)
          });
          if (node.store.data.template === "1_table") {
            _this.$nextTick(() => {
              let tableColCount = node.store.data.attrs.body.cols;
              let tableRowCount = node.store.data.attrs.body.rows;
              let tableData = node.store.data.tableData;
              _this.tableData = tableData;
              let svgObjectDom = document.querySelectorAll(
                  `g[data-cell-id="${node.id}"] foreignObject table`
              );
              console.log("table", svgObjectDom);
              // let tableText =
              //   document.getElementById("tableText")[0].childNodes[1].childNodes[1];
              let reMoveTr = svgObjectDom[0].childNodes[1].childNodes;
              console.log(reMoveTr);
              for (let index = 0; index < reMoveTr.length; index++) {
                if (reMoveTr[index].tagName === "TR") {
                  svgObjectDom[0].childNodes[1].removeChild(reMoveTr[index]);
                  index--;
                }
              }
              for (let i = 0; i < tableData.length; i++) {
                let addTrs = document.createElement("tr");
                svgObjectDom[0].childNodes[1].appendChild(addTrs);
                for (let j = 0; j < tableData[i].cols.length; j++) {
                  let addTds = document.createElement("td");
                  let addInputs = document.createElement("input");
                  addInputs.value = tableData[i].cols[j].val;
                  addInputs.id = tableData[i].cols[j].id;
                  addInputs.className = "tableInput";
                  addTds.setAttribute(
                      "style",
                      "width:100px;height:20px;border:1px solid orange;text-align: center;"
                  );
                  addInputs.setAttribute(
                      "style",
                      `width: 70px;border: none;outline: none;text-align: center;background-color: rgba(0,0,0,0);font-size:${node.store.data.attrs.text.fontSize}px;font-family:${node.store.data.attrs.text.fontFamily};font-weight:${node.store.data.attrs.text.fontWeight}`
                  );
                  addTrs.appendChild(addTds);
                  addTds.appendChild(addInputs);
                }
              }
            });
          }
          else if (node.store.data.template == "1_jzsj") {
            _this.$nextTick(() => {
              let keys = node.store.data.attrs.body.keys;
              let values = node.store.data.attrs.body.values;
              let svgObjectDom = document.querySelectorAll(
                  `g[data-cell-id="${node.id}"] foreignObject span`
              );
              for (let svgObjectDomKey in svgObjectDom) {
                if (svgObjectDomKey == 2) {
                  return
                } else {
                  console.log(svgObjectDom[svgObjectDomKey], node.store.data.attrs.text.fontSize)
                  svgObjectDom[svgObjectDomKey].innerHTML = keys
                  svgObjectDom[svgObjectDomKey].innerHTML = values
                  svgObjectDom[svgObjectDomKey].style.fontSize = node.store.data.attrs.text.fontSize + 'px'
                  svgObjectDom[svgObjectDomKey].style.color = node.store.data.attrs.text.color
                  svgObjectDom[svgObjectDomKey].style.fontWeight = node.store.data.attrs.text.fontWeight
                  svgObjectDom[svgObjectDomKey].style.fontFamily = node.store.data.attrs.text.fontFamily
                }
              }
            })
          }
          let resultJson = eval("(" + _this.$parent.diagramObj.diagramJson + ")");
          resultJson.cells.push(node.store.data);
          _this.$parent.diagramObj.diagramJson = JSON.stringify(resultJson);
          return true;
        }
      });
    },
    startDrag(item, e) {
      item.initData.stroke = global.SVG_COLOR;
      let {
        componentId,
        componentName,
        componentClass,
        componentType,
        template,
        sourceUrl,
        initData,
      } = item;
      const {
        SVG_HTML,
        SVG_EDGE,
        SVG_FONT,
        SVG_TAG,
        SVG_TABLE,
        SVG_KEYVLAUE,
        SVG_IMAGE,
        TRIGGER,
        CONDITION,
        ACTION,
      } = ActionType;
      let json;
      let actionType = componentType,
              shape = componentClass,
              label = componentName;
      template = '6_'+template;
      console.log('template=', template)

      switch (actionType) {
        case SVG_HTML:
          json = getSvgNodeByHtml({
            shape: "html",
            tooltip: label,
            size: {width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight},
            actionType: actionType,
            initialization: true,
            componentId,
            componentName,
            template,
            sourceUrl: this.envApi + sourceUrl,
            initData,
            // color
          });
          const node = this.graph.createNode(json);
          // 创建节点后添加小工具
          if (!this.freeze) {
            this.dnd.start(node, e);
          }
          break;
        case SVG_EDGE:
          if (template == "1_dllxy") {
            this.$parent.lineStatus = 2;
          } else if (template == "1_dllxz") {
            this.$parent.lineStatus = 2;
          } else {
            this.$parent.lineStatus = 1;
          }
          break;
        case SVG_FONT:
          json = getSvgFontByHtml({
            shape: "html",
            tooltip: label,
            size: {width: FONTSizeEnum.iWidth, height: FONTSizeEnum.iHeight},
            actionType: actionType,
            initialization: true,
            componentId,
            componentName,
            template,
            sourceUrl: this.envApi + sourceUrl,
            initData,
          });
          const node1 = this.graph.createNode(json);
          if (!this.freeze) {
            this.dnd.start(node1, e);
          }
          break;
        case SVG_TAG:
          json = getSvgNodeByTag({
            // shape: 'path',
            tooltip: label,
            size: {width: 40, height: 40},
            actionType: actionType,
            initialization: true,
            componentId,
            componentName,
            template,
            sourceUrl: this.envApi + sourceUrl,
            initData,
          });
          console.log(json);
          const node2 = this.graph.createNode(json);
          if (!this.freeze) {
            this.dnd.start(node2, e);
          }
          break;
        case SVG_TABLE:
          let tableWidth = TABLESizeEnum.iWidth;
          let tableHeight = TABLESizeEnum.iHeight;
          if (template == "1_dgtable") {
            tableWidth = 70;
            tableHeight = 200;
          } else if (template == "1_dbtable") {
            tableWidth = 45;
            tableHeight = 56;
          } else if (template == "1_table") {
            tableWidth = 145;
            tableHeight = 55;
          }
          json = getSvgTableByHtml({
            shape: "html",
            tooltip: label,
            size: {
              width: tableWidth,
              height: tableHeight,
            },
            actionType: actionType,
            initialization: true,
            componentId,
            componentName,
            template,
            sourceUrl: this.envApi + sourceUrl,
            initData,
          });
          const node3 = this.graph.createNode(json);
          console.log(this.freeze);
          if (!this.freeze) {
            this.dnd.start(node3, e);
          }
          break;
        case SVG_KEYVLAUE:
          tableWidth = 120;
          tableHeight = 25;
          json = getSvgTableByHtml({
            shape: "html",
            tooltip: label,
            size: {
              width: tableWidth,
              height: tableHeight,
            },
            actionType: actionType,
            initialization: true,
            componentId,
            componentName,
            template,
            sourceUrl: this.envApi + sourceUrl,
            initData,
          });
          const node4 = this.graph.createNode(json);
          console.log(this.freeze);
          if (!this.freeze) {
            this.dnd.start(node4, e);
          }
          break;
        case SVG_IMAGE:
          /*json = getSvgNodeByTag({
										  shape: 'svg-image',
										  tooltip: label,
										  size: {width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight},
										  actionType: actionType,
										  initialization: true,
										  componentId,
										  componentName,
										  template,
										  sourceUrl: this.envApi + sourceUrl,
										  initData,
									  });*/
          console.log(json);
          json = getSvgNodeByHtml({
            shape: "image",
            tooltip: label,
            size: {width: 80, height: 80},
            actionType: actionType,
            initialization: true,
            componentId,
            componentName,
            template,
            sourceUrl: this.envApi + sourceUrl,
            initData,
            // color
          });
          console.log("json", json);
          const node5 = this.graph.createNode(json);
          node5.attr({
            image: {
              opacity: 1, // 设置为透明避免闪动，图片加载完成后设置为 1
              'xlink:href': this.envApi + sourceUrl,
            },
          })
          // 创建节点后添加小工具
          if (!this.freeze) {
            this.dnd.start(node5, e);
          }
          break;
        default:
          break;
      }
    },
    //没用的方法👇
    validateNodes(node) {
      let _this = this;
      console.log("node", node);
      if (!_this.$parent.diagramObj.diagramJson) {
        _this.$parent.diagramObj.diagramJson = '{"cells":[]}';
      }
      if (node.store.data.template == "1_table") {
        let tableColCount = node.store.data.attrs.body.cols;
        let tableRowCount = node.store.data.attrs.body.rows;
        let tableData = node.store.data.tableData;
        _this.tableData = tableData;
        console.log(tableData);
        let svgObjectDom = document.querySelectorAll(
            `g[data-cell-id="${node.id}"] foreignObject table`
        );
        // let tableText =
        //   document.getElementById("tableText")[0].childNodes[1].childNodes[1];
        let reMoveTr = svgObjectDom[0].childNodes[1].childNodes;
        for (let index = 0; index < reMoveTr.length; index++) {
          if (reMoveTr[index].tagName == "TR") {
            svgObjectDom[0].childNodes[1].removeChild(reMoveTr[index]);
            index--;
          }
        }

        for (let i = 0; i < tableData.length; i++) {
          let addTrs = document.createElement("tr");
          svgObjectDom[0].childNodes[1].appendChild(addTrs);
          for (let j = 0; j < tableData[i].cols.length; j++) {
            let addTds = document.createElement("td");
            let addInputs = document.createElement("input");
            addInputs.id = tableData[i].cols[j].id;
            // console.log(tableData[i].cols[j]);
            addInputs.value = tableData[i].cols[j].val;
            addTds.setAttribute(
                "style",
                "width:100px;height:20px;border:1px solid orange;text-align: center;"
            );
            addInputs.setAttribute(
                "style",
                `width: 70px;border: none;outline: none;text-align: center;background-color: rgba(0,0,0,0);font-size:${node.store.data.attrs.text.fontSize}px;font-family:${node.store.data.attrs.text.fontFamily};font-weight:${node.store.data.attrs.text.fontWeight}`
            );
            addTrs.appendChild(addTds);
            addTds.appendChild(addInputs);
          }
        }
      } else if (node.store.data.template == "1_jzsj") {
        _this.$nextTick(() => {
          let keys = node.store.data.attrs.body.keys;
          let values = node.store.data.attrs.body.values;
                  let keyDom = document.querySelectorAll(`g[data-cell-id="${node.id}"] foreignObject #jzkey`);
                  let valueDom = document.querySelectorAll(`g[data-cell-id="${node.id}"] foreignObject #jzvalue`);
                  console.log(keyDom[0])
                  keyDom[0].innerHTML= item.keys
                  keyDom[0].style.fontSize= node.store.data.attrs.text.fontSize+'px'
                  keyDom[0].style.color= node.store.data.attrs.text.color
                  keyDom[0].style.fontWeight= node.store.data.attrs.text.fontWeight
                  keyDom[0].style.fontFamily= node.store.data.attrs.text.fontFamily
                  valueDom[0].innerHTML= item.values
                  valueDom[0].style.fontSize= node.store.data.attrs.text.fontSize+'px'
                  valueDom[0].style.color= node.store.data.attrs.text.color
                  valueDom[0].style.fontWeight= node.store.data.attrs.text.fontWeight
                  valueDom[0].style.fontFamily= node.store.data.attrs.text.fontFamily
        })
      }
      let resultJson = eval("(" + _this.$parent.diagramObj.diagramJson + ")");
      resultJson.cells.push(node.store.data);
      _this.$parent.diagramObj.diagramJson = JSON.stringify(resultJson);
      return true;
    },
    getList() {
      this.queryParams.isEnable = 1;
      listComponents(this.queryParams).then((response) => {
        this.listGroup = response.data;
        for (const item of this.listGroup) {
          for (const childrenitem of item.children) {
            //TODO: childrenitem.initData = '{width: "2", height: "40", color: "#000000"}';
            childrenitem.initData = eval("(" + childrenitem.initData + ")");
          }
        }
        // this.changeSvgColor(this.initSvgTypeColor);
      });
    },
    onSearch() {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        listComponents(this.queryParams).then((response) => {
          console.log(response, "responseresponse");
          response.rows.forEach(element => {
            element.children.forEach(item => {
              item.initData = eval("(" + item.initData + ")");
            })
          });
          this.listGroup = response.data;
          this.initDnd();
        });
        timer = undefined;
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-collapse-item__arrow.is-active {
  transform: rotate(90deg);
}

::v-deep .el-collapse-item__arrow,
.el-tabs__nav {
  transform: rotate(-90deg);
}

.components-layout-left .ant-collapse,
.components-layout-left .ant-collapse-content,
.components-layout-left .ant-collapse > .ant-collapse-item {
  border-color: #eee !important;
}

.ant-input-affix-wrapper {
  height: 45px;
  line-height: 45px;
  border: none;
}

.components-layout-left {
  ::v-deep .search-box input{
    background-color: #fff;
    border: none;
    color: #454545;
    padding-top: 10px;
    margin-left: 5px;
  }
  ::v-deep .collapseTitle {
    margin-top: 1rem;
    max-height: calc(100vh - 170px);
    overflow: hidden;

    .el-collapse{
      border: none;
    }

    .el-collapse-item{
      margin: 1rem 0;
    }

    .el-collapse-item__header {
      width: 228px;
      height: 40px;
      background: #f5f6f8;
      border-radius: 6px;
      margin: 0 auto;
      padding-left: 10px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      // color: #fff;
      color: #233333;
      border: none;
      // opacity: 0.45;
    }
  }

  .svg-nav-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 279px;
    overflow-y: scroll;
    align-content: flex-start;

    li {
      position: relative;
      width: 69px;
      height: 66px;
      padding: 19px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: #fff;
      border-radius: 2px;
      margin: 1.7px;
      box-sizing: border-box;

      &:hover {
        box-shadow: 1px 1px 5px #0090ff;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2000;

      }

      .title {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }
    }

    & .two-item {
      li {
        width: calc(50% - 30px);
        height: 100px;
        margin-bottom: 25px;
        border-radius: 10px;

        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
}

</style>

