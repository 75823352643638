<template>
    <div>
        <div class="echartSingel" id="myPNGplace2">
            <!-- 图表 -->
            <!-- <div class="echart1">
        <echart-area-map v-if="lineChartShow" ref="lineCharts" chartsType="line" :optionData="optionData" />
        <echart-bar-map v-else ref="lineCharts" chartsType="bar" :optionData="optionData" />
      </div> -->
            <div class="chart">
                <div v-if="!lineChartShow">
                    <TrendLine ref="lineCharts" />
                </div>
                <div v-else>
                    <TrendBar ref="lineCharts" />
                </div>
                <!-- <EchartBarMap ref="lineCharts" /> -->
            </div>
            <!-- 图表的顶栏 -->
            <div class="tabBarPlace1">
                <div class="tabBarTop">
                    <div class="word">能耗趋势分析</div>
                    <!-- 下载的下拉框 -->
                    <el-dropdown @command="downLoadPng" :disabled="
              this.$store.state.storage.localtionId &&
              this.$store.state.storage.localtionId != 0
                ? false
                : true
            " class="img1" style="z-index:99999">
                        <span class="el-dropdown-link">
                            <img style="width:1.8rem;height:1.8rem" src="@/assets/ElecManagement/Download.png" alt="" />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="['pic', '能耗趋势分析']">图片下载</el-dropdown-item>
                            <el-dropdown-item :command="['excel', '能耗趋势分析']">Excel下载</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <img v-show="barChartShow" @click="changeBar()" class="img2" src="@/assets/ElecManagement/BarChart.png" alt />
                    <img v-show="barChartShowGreen" class="img2" src="@/assets/ElecManagement/BarChartGreen.png" alt />
                    <img v-show="lineChartShow" @click="changeLine()" class="img3" src="@/assets/ElecManagement/LineChart.png" alt />
                    <img v-show="lineChartShowGreen" class="img3" src="@/assets/ElecManagement/LineChartGreen.png" alt />
                </div>
                <div class="tabBarBottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import TrendLine from './TrendLine.vue'
import TrendBar from './TrendBar.vue'
import { exportEnergyAnalyzeTrendReport } from "@/api/repair"
import { formatTime } from '@/utils/util.js'
export default {
    name: 'MymapNo2',
    props: {
        name: {
            type: String,
            default: () => ''
        },
    },
    components: { TrendLine, TrendBar },

    data () {
        return {
            // 按钮的样式
            btnTypeDay: 'btnGreen',
            btnTypeMonth: 'btnDark',
            btnTypeYear: 'btnDark',
            // 控制显示icon以及图表的显示
            lineChartShow: true,
            lineChartShowGreen: false,
            barChartShow: false,
            barChartShowGreen: true,
            whichTab: 0,
            optionData: {},
            showInterval: true,
            timeValue: [],
            timeType: 1,
            locationIds: '',
            dateStart: '',
            dateEnd: '',
        }
    },

    methods: {
        resizeChart () {
            setTimeout(() => {
                this.$nextTick(() => {
                    this.$refs.lineCharts.resizeChart()
                })
            }, 0)
        },
        formatDate (date) {
            if (date.getFullYear) {
                let y = date.getFullYear()
                let m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                let d = date.getDate()
                d = d < 10 ? '0' + d : d
                return y + '-' + m + '-' + d
            } else {
                return date
            }
        },
        // 转化dom节点为图片下载
        downLoadPng (val) {
            if (val[0] == 'excel') {
                this.locationIds = this.$store.state.storage.localtionId
                let value = {
                    locationId: this.locationIds,
                    type: this.timeType,
                    dateStart: this.formatDate(this.$refs.lineCharts.startDate),
                    dateEnd: this.formatDate(this.$refs.lineCharts.endDate),
                }
                // this.$store.dispatch('exportAnalysisTrend', value)
                exportEnergyAnalyzeTrendReport(value).then((response) => {
                    var blob = new Blob([response], {
                        type: "application/vnd.ms-excel,charset=utf-8",
                    }) //创建一个blob对象
                    var a = document.createElement("a") //创建一个<a></a>标签
                    a.href = URL.createObjectURL(blob) // response is a blob
                    let format = this.timeType == 1 ? 'yyyy-MM-dd' : this.timeType == 2 ? 'yyyy-MM' : 'yyyy'
                    let name = this.$refs.lineCharts.timeValue[1] ? `${formatTime(this.$refs.lineCharts.timeValue[0], format)}-${formatTime(this.$refs.lineCharts.timeValue[1], format)}${this.name}能耗趋势分析.xls` : `${formatTime(this.$refs.lineCharts.timeValue, format)}${this.name}能耗趋势分析.xls`
                    a.download = name //文件名称
                    a.style.display = "none"
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
            } else {
                var node = document.getElementById('myPNGplace2')
                window.pageYoffset = 0
                document.documentElement.scrollTop = 0
                document.body.scrollTop = 0
                var width = node.offsetWidth
                var height = node.offsetHeight
                var scale = 4
                html2canvas(node, {
                    width: width,
                    heigth: height,
                    backgroundColor: '#113455',
                    dpi: window.devicePixelRatio * 4,
                    scale: scale,
                    X: 0,
                    Y: 0,
                    scrollX: -3,
                    scrollY: 0,
                    useCORS: true,
                    allowTaint: true,
                }).then((canvas) => {
                    var url = canvas.toDataURL()
                    var a = document.createElement('a')
                    let format = this.timeType == 1 ? 'yyyy-MM-dd' : this.timeType == 2 ? 'yyyy-MM' : 'yyyy'
                    let name = this.$refs.lineCharts.timeValue[1] ? `${formatTime(this.$refs.lineCharts.timeValue[0], format)}-${formatTime(this.$refs.lineCharts.timeValue[1], format)}${this.name}${val[1]}` : `${formatTime(this.$refs.lineCharts.timeValue, format)}${this.name}${val[1]}`
                    a.download = name
                    var event = new MouseEvent('click')
                    a.href = url
                    a.dispatchEvent(event)
                })
            }
        },
        show (val) {
            console.log('val', val)
            this.timeType = val.timeType
            this.dateStart = val.dateStart
            this.dateEnd = val.dateEnd
        },

        // 切换年月日的回调
        changeTab (index) {
            this.whichTab = index
            this.showInterval = false
            if (index === 0) {
                this.btnTypeDay = 'btnGreen'
                this.btnTypeMonth = 'btnDark'
                this.btnTypeYear = 'btnDark'
            } else if (index === 1) {
                this.btnTypeDay = 'btnDark'
                this.btnTypeMonth = 'btnGreen'
                this.btnTypeYear = 'btnDark'
            } else if (index === 2) {
                this.btnTypeDay = 'btnDark'
                this.btnTypeMonth = 'btnDark'
                this.btnTypeYear = 'btnGreen'
            }
            setTimeout(() => {
                this.showInterval = true
                this.$nextTick(() => {
                    this.$refs.lineCharts.init()
                })
            }, 0)
        },
        // 切换图表类型
        changeBar () {
            this.showInterval = false
            this.barChartShowGreen = true
            this.barChartShow = false
            this.lineChartShow = true
            this.lineChartShowGreen = false
            setTimeout(() => {
                this.showInterval = true
                this.$nextTick(() => {
                    this.$refs.lineCharts.init()
                })
            }, 0)
        },
        changeLine () {
            this.showInterval = false
            this.barChartShowGreen = false
            this.barChartShow = true
            this.lineChartShow = false
            this.lineChartShowGreen = true
            setTimeout(() => {
                this.showInterval = true
                this.$nextTick(() => {
                    this.$refs.lineCharts.init()
                })
            }, 0)
        },
    },

    mounted () {
        // 加载“日”的图
        // this.changeTab(1);
    },
}
</script>

<style lang="less" scoped>
.echartSingel {
    width: 100%;
    height: 43rem;
    //   width: 58rem;
    background-color: #0d3050;
    border-radius: 0.4rem;
    position: relative;
    // 上半部分的title栏##################################################
    .tabBarPlace1 {
        width: 100%;
        height: 10rem;
        // width: 58rem;
        position: absolute;
        top: 0;
        left: 0;
        .tabBarTop {
            width: 100%;
            height: 5rem;
            // width: 58rem;
            position: absolute;
            top: 0;
            left: 0;
            img {
                z-index: 99;
            }
            .word {
                height: 2.2rem;
                font-size: 1.4rem;
                color: #37a5dd;
                line-height: 2.2rem;
                position: absolute;
                top: 2rem;
                left: 2.4rem;
            }
            .btn1 {
                height: 3rem;
                width: 11.6rem;
                position: absolute;
                top: 1.8rem;
                left: 16.8rem;
                background-color: #0b375e;
                border: 0.1rem solid #004579;
                font-size: 1.4rem;
                color: #fff;
                line-height: 3rem;
                padding: 0;
                margin: 0;
                cursor: pointer;
            }
            .dateChoose {
                height: 3rem;
                width: 13.8rem;
                border-radius: 0.2rem;

                position: absolute;
                top: 1.8rem;
                left: 30rem;
                overflow: hidden;
                border: 0.1rem solid #004579;
                box-sizing: border-box;
                display: flex;
                flex-wrap: nowrap;
                color: #fff;
                .btnDark {
                    height: 3rem;
                    width: 4.6rem;
                    background-color: #0b375e;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3rem;
                    text-align: center;
                }
                .btnGreen {
                    height: 3rem;
                    width: 4.6rem;
                    background-color: #027969;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3rem;
                    text-align: center;
                }
            }

            .img1 {
                height: 1.8rem;
                width: 1.8rem;
                position: absolute;
                top: 2.4rem;
                right: 2.2rem;
                cursor: pointer;
            }
            .img2 {
                height: 1.8rem;
                width: 1.8rem;
                position: absolute;
                top: 2.4rem;
                right: 5.8rem;
                cursor: pointer;
            }
            .img3 {
                height: 1.8rem;
                width: 1.8rem;
                position: absolute;
                top: 2.4rem;
                right: 4rem;
                cursor: pointer;
            }
        }
    }
    .echart1 {
        width: 100%;
        height: 32rem;
        // width: 58rem;
        // position: absolute;
        // top: 3rem;
        // left: 0;
    }
    .chart {
        width: 100%;
        // width: 59rem;
        height: 43rem;
        // position: absolute;
        // top: 0;
        // left: 0;
        z-index: 10;
    }
}
</style>
