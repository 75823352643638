<template>
  <div class="ener-read">
    <div class="enerstion-bottom">
      <div class="enersta">
        <div class="fire_control">
          <div class="energy-left">
            <div class="energr-today">
              <span class="title">今日能耗</span>
              <div class="center">
                <div class="cen-top">
                  <div class="cen-list cen-left">
                    <img
                      src="@/assets/energy/energy1.png"
                      alt
                      class="cen-img"
                    />
                    <div class="cen-list-center">
                      <p class="textTop">{{ this.dataForm.sum }}</p>
                      <p>电量(kWh)</p>
                    </div>
                  </div>
                  <div class="cen-list">
                    <img
                      src="@/assets/energy/energy2.png"
                      alt
                      class="cen-img"
                    />
                    <div class="cen-list-center">
                      <p class="textTop">{{ this.dataForm.fee }}</p>
                      <p>折算费用(元)</p>
                    </div>
                  </div>
                </div>
                <div class="cen-bottom">
                  <div class="cen-list cen-left">
                    <img
                      src="@/assets/energy/energy3.png"
                      alt
                      class="cen-img"
                    />
                    <div class="cen-list-center">
                      <p class="textTop">{{ this.dataForm.carbonEmission }}</p>
                      <p>碳排放量(kg)</p>
                    </div>
                  </div>
                  <div class="cen-list">
                    <img
                      src="@/assets/energy/energy4.png"
                      alt
                      class="cen-img"
                    />
                    <div class="cen-list-center">
                      <p class="textTop">{{ this.dataForm.coalEquiv }}</p>
                      <p>标准煤(kg)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="energr-calendar">
              <span class="title">能源日历</span>
              <div class="cal-center">
                <Calendar />
              </div>
            </div>
          </div>
          <div class="energy-center">
            <div class="enerMap">
              <!-- <img class="mapBack" src="@/assets/energy/adressBack.png" alt /> -->
              <img class="mapBack" :src="customerPicture || adressBack" alt />
              <div class="mapRound">
                <div class="map-text">
                  <p class="text1">
                    <el-tooltip v-if="customerName.length > 8" effect="dark" :content="this.$parent.$refs.treeRef.currentNodeName" placement="top">
                      <span class="ellipsis">{{customerName}}</span>
                    </el-tooltip>
                    <span v-if="customerName.length <= 8">{{customerName}}</span>
                    今日用电
                  </p>
                  <p class="text2">
                    {{ this.dataForm.sum }}
                    <span>kWh</span>
                  </p>
                </div>
                <img
                  class="mapPunctu1"
                  src="@/assets/energy/funRound.png"
                  alt
                />
                <img
                  class="mapPunctu2"
                  src="@/assets/energy/funBottom.png"
                  alt
                />
              </div>
            </div>
            <div class="enerLineChart">
              <span class="title">今日能耗趋势</span>
              <div id="myChart1" class="c1"></div>
            </div>
          </div>
          <div class="energy-rigth">
            <div class="rigth-top">
              <span class="title">{{ customerName }}能耗指标-年度</span>
              <div class="tbl-title">
                <el-table :data="nodeRoomList" style="width: 36rem">
                  <el-table-column
                    prop="name"
                    min-width="80"
                    show-overflow-tooltip
                    label="节点名称"
                  ></el-table-column>
                  <el-table-column
                    align="right"
                    min-width="80"
                    prop="value"
                    show-overflow-tooltip
                    label="能耗"
                  >
                    <template slot-scope="scope">{{
                      scope.row.value
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    min-width="60"
                    align="center"
                    prop="huanbiValue"
                    label="环比"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.huanbiValue.toFixed(2) }}%
                      <img
                        v-if="scope.row.huanbiValue > 0"
                        class="imgBehind"
                        src="@/assets/ElecManagement/arrowUp.png"
                        alt
                      />
                      <img
                        v-if="scope.row.huanbiValue < 0"
                        class="imgBehind"
                        src="@/assets/ElecManagement/arrowDown.png"
                        alt
                      />
                      <img
                        v-if="
                          scope.row.huanbiValue == 0 ||
                            scope.row.huanbiValue == '-'
                        "
                        class="imgBehind"
                        src=""
                        alt
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="rigth-bottom">
              <span class="title">今日能耗排名</span>
              <el-select
                class="styleWid"
                size="mini"
                v-model="energyName"
                @change="energyNameChange"
              >
                <el-option
                  v-for="item in energyNameList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <div class="bottom-box">
                <div v-for="(item, index) in strList" :key="index">
                  <div class="bottom-center">
                    <span>{{ item.name }}</span>
                    <span>{{ item.value }}kWh</span>
                  </div>
                  <div class="str-false"></div>
                  <div class="str-true" :style="{ width: item.conNum + 'rem' }">
                    <div class="rightBar"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adressBack from "@/assets/energy/adressBack.png";
import * as echarts from "echarts";
import { mapState } from "vuex";
import Calendar from "./EnergySituation/calendar";
export default {
  components: {
    Calendar,
  },
  name: "EnergySituation",

  data() {
    return {
      flagImg: false,
      // 客户图片
      adressBack,
      dataForm: {
        // 客户id
        customerId: "1448924021822775297",
        //碳排放
        carbonEmission: "",
        // 标准煤
        coalEquiv: "",
        //折算费用
        fee: "",
        //总电量
        sum: "",
        buildId: "1457664694620786690",
        energyName: "",
      },
      energyName: "",
      energyNameList: [
        {
          id: "1",
          name: "一号楼",
        },
      ],
      nodeRoomList: [],
      strList: [],
      barList: [],
      lineList: [],
      btnTyp: "rest1",
      showY: false,
      yMin: "",
      yMax: "",
      yMax1: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 获取今日能耗数据
    queryEnerData() {
      this.dataForm.customerId = this.$store.state.storage.customerId;
      this.$store
        .dispatch("queryEnergyToday", this.dataForm.customerId)
        .then(() => {
          // console.log(this.getGeneralSetting);
          if (
            this.$store.state.elecManagement.queryEnergyToday.errorCode == 0
          ) {
            if (
              this.$store.state.elecManagement.queryEnergyToday.data !== null
            ) {
              this.dataForm = this.getGeneralSetting;
            } else {
              this.dataForm = {
                sum: 0,
                fee: 0,
                carbonEmission: 0,
                coalEquiv: 0,
              };
            }
          } else {
            this.dataForm = {
              sum: 0,
              fee: 0,
              carbonEmission: 0,
              coalEquiv: 0,
            };
          }
        });
    },
    // 获取办公楼能耗指标数据
    queryBuildPaiMing() {
      this.dataForm.customerId = this.$store.state.storage.customerId;
      this.$store
        .dispatch("queryBuildingquota", this.dataForm.customerId)
        .then(() => {
          if (
            this.$store.state.elecManagement.queryBuildingquota.errorCode == 0
          ) {
            if (
              (this.$store.state.elecManagement.queryBuildingquota.data !==
                null) &
              (this.$store.state.elecManagement.queryBuildingquota.data.length >
                0)
            ) {
              this.nodeRoomList = this.queryBuildingquota;
              // console.log(this.queryBuildingquota);
              this.nodeRoomList[0].value =
                this.nodeRoomList[0].value + "kWh/㎡";
              this.nodeRoomList[1].value =
                this.nodeRoomList[1].value + "kWh/㎡";
              this.nodeRoomList[2].value = this.nodeRoomList[2].value + "kWh/A";
              this.nodeRoomList[3].value = this.nodeRoomList[3].value + "kWh/A";
              for (let i = 0; i < this.nodeRoomList.length; i++) {
                let item = this.nodeRoomList[i];
                let huanbi = item.huanbiValue;
                let value = item.huanbiValue;
                if (huanbi == null) {
                  item.huanbiValue = "-";
                } else {
                  item.huanbiValue = value;
                  // this.flagImg = true;
                }
              }
            } else {
              this.nodeRoomList = [
                { value: 0, huanbiValue: null },
                { value: 0, huanbiValue: null },
                { value: 0, huanbiValue: null },
                { value: 0, huanbiValue: null },
              ];
              this.nodeRoomList[0].value = "0kWh/㎡";
              this.nodeRoomList[1].value = "0kWh/㎡";
              this.nodeRoomList[2].value = "0kWh/A";
              this.nodeRoomList[3].value = "0kWh/A";
              for (let i = 0; i < this.nodeRoomList.length; i++) {
                let item = this.nodeRoomList[i];
                let huanbi = item.huanbiValue;
                let value = item.huanbiValue;
                if (huanbi == null) {
                  item.huanbiValue = "-";
                } else {
                  item.huanbiValue = value;
                  // this.flagImg = true;
                }
              }
            }
          } else {
            this.nodeRoomList = [
              { value: 0, huanbiValue: null },
              { value: 0, huanbiValue: null },
              { value: 0, huanbiValue: null },
              { value: 0, huanbiValue: null },
            ];
            this.nodeRoomList[0].value = "0kWh/㎡";
            this.nodeRoomList[1].value = "0kWh/㎡";
            this.nodeRoomList[2].value = "0kWh/A";
            this.nodeRoomList[3].value = "0kWh/A";
            for (let i = 0; i < this.nodeRoomList.length; i++) {
              let item = this.nodeRoomList[i];
              let huanbi = item.huanbiValue;
              let value = item.huanbiValue;
              if (huanbi == null) {
                item.huanbiValue = "-";
              } else {
                item.huanbiValue = value;
                // this.flagImg = true;
              }
            }
          }
        });
    },
    // 获取用户id和客户树id
    getBuildId() {
      // 获取用户id
      this.dataForm.customerId = this.$store.state.storage.customerId;
      // 获取客户树id
      this.$store
        .dispatch("getBuildById", this.dataForm.customerId)
        .then(() => {
          let arr = this.getBuildById;
          if ((arr !== null) & (arr.length > 0)) {
            this.energyName = this.getBuildById[0].name;
          }
          if (arr.length > 0) {
            this.dataForm.buildId = arr[0].id;
            this.energyNameList = arr;
            this.getConSuming();
          }
        });
    },
    // 获取今日能耗排名数据
    getConSuming() {
      //楼层id
      this.$store
        .dispatch("queryEnergyRank", this.dataForm.buildId)
        .then(() => {
          this.strList = this.queryEnergyRank;
          for (let i = 0; i < this.strList.length; i++) {
            let sum = this.strList[i].value / this.strList[0].value;
            this.strList[i].conNum = sum * 30.5;
          }
        });
    },
    // 今日能源趋势
    queryEchartsTrend() {
      this.dataForm.customerId = this.$store.state.storage.customerId;
      this.$store
        .dispatch("queryEnergyTrend", this.dataForm.customerId)
        .then(() => {
          // console.log(this.queryEnergyTrend);
          if (this.queryEnergyTrend[0] !== null) {
            this.barList = this.queryEnergyTrend[0].data;
            this.lineList = this.queryEnergyTrend[1].data;
            this.drawLine1();
          } else {
            this.barList = [];
            this.lineList = [];
            this.drawLine1();
          }
        });
    },
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    energyNameChange(e) {
      this.dataForm.buildId = e;
      this.getConSuming();
    },
    // 绘图
    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart1"));
      // 绘制图表
      let mapBarList = [];
      let mapLineList = [];
      mapBarList = this.barList;
      mapLineList = this.lineList;
      // console.log(mapLineList);
      if (mapLineList.length > 0) {
        let yMax = mapLineList[0];
        let yMin = mapLineList[0];
        for (let i = 0; i < mapLineList.length; i++) {
          if (mapLineList[i] > yMax) {
            yMax = mapLineList[i];
          }
          if (mapLineList[i] < yMin) {
            yMin = mapLineList[i];
          }
        }
        yMax = Number(yMax.toFixed(1)) * 100 + 10;
        yMin = Number(yMin.toFixed(1)) * 100 - 10;
        this.yMax = yMax.toFixed(2);
        this.yMin = yMin.toFixed(2);
      }
      if (mapBarList.length > 0) {
        let yMax = mapBarList[0];
        for (let i = 0; i < mapBarList.length; i++) {
          if (mapBarList[i] > yMax) {
            yMax = mapBarList[i];
          }
        }
        this.yMax1 = this.ceilNumber(yMax);
      }
      let mapLineList1 = [];
      for (let i = 0; i < mapLineList.length; i++) {
        mapLineList1.push((mapLineList[i] * 100).toFixed(2));
      }
      let options = {
        legend: {
          orient: "horizontal",
          x: "center", //可设定图例在左、右、居中
          y: "bottom", //可设定图例在上、下、居中
          icon: "circle",
          padding: [0, 0, 15, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          textStyle: { color: "rbga(255,255,255,1)" },
          data: ["总部一", "总部(环比)"],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          x: 40,
          x2: 20,
          y2: 40,
          y: 50,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          interval: 0,
          axisLabel: {
            textStyle: {
              color: "rgba(230,247,255,0.85)",
            },
          },
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
            "24:00",
          ],
        },
        yAxis: [
          {
            name: "单位:kWh",
            min: 0,
            max: this.yMax1,
            interval: this.yMax1 / 5,
            axisLabel: {
              textStyle: {
                color: "rgba(230,247,255,0.85)",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(230,247,255,0.2)",
              },
            },
          },
          {
            min: this.yMin,
            max: this.yMax,
            interval: Number(((this.yMax - this.yMin) / 5).toFixed(2)),
            type: "value",
            axisLabel: {
              formatter: "{value} %",
              textStyle: {
                color: "rgba(230,247,255,0.85)",
                align: "left",
              },
            },
            // axisTick: {
            //   show: false,
            // },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(230,247,255,0.2)",
              },
            },
          },
        ],
        series: [
          {
            name: "总部一",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#0EECE4" },
                { offset: 1, color: "#058FE7" },
              ]),
            },
            data: mapBarList,
          },
          {
            name: "总部(环比)",
            type: "line",
            smooth: true,
            showSymbol: false,
            yAxisIndex: 1,
            itemStyle: {
              color: "#FFA82C",
            },
            data: mapLineList1,
          },
        ],
      };
      myChart.setOption(options, true);
      myChart._dom.childNodes[0].childNodes[0].style.width = "70.3rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "30rem";
    },
  },
  computed: {
    ...mapState({
      getGeneralSetting: (state) => {
        return state.elecManagement.queryEnergyToday.data;
      },
      getBuildById: (state) => {
        return state.elecManagement.getBuildById.data;
      },
      queryEnergyRank: (state) => {
        return state.elecManagement.queryEnergyRank.data;
      },
      queryBuildingquota: (state) => {
        return state.elecManagement.queryBuildingquota.data;
      },
      queryEnergyTrend: (state) => {
        return state.elecManagement.queryEnergyTrend.data;
      },
      customerPicture: (state) => {
        return state.storage.customerPicture;
      },
    }),
    customerName() {
      let customerName = this.$parent.$refs.treeRef.currentNodeName
      let marginLeft = ''
      if (customerName && customerName.length > 8) {
        customerName = customerName.substring(0, 8) + "..."
        marginLeft = 'margin-left: -4rem;'
      } else if (customerName && customerName.length >= 6) {
        marginLeft = 'margin-left: -3rem;'
        if (this.dataForm.sum > 10000) {
          marginLeft = 'margin-left: -3rem;'
        }
      } else {
        marginLeft = 'margin-left: -0rem;'
        if (this.dataForm.sum > 10000) {
          marginLeft = 'margin-left: -2rem;'
        }
      }
      let text1 = document.querySelectorAll('.text1')
      for (let i = 0; i < text1.length; i++) {
        text1[i].style.cssText = marginLeft
      }
      return customerName
    }
  },
  watch: {
    "$store.state.storage.customerId": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        this.dataForm.customerId = newVal;
        this.energyName = "";
        this.energyNameList = [];
        this.strList = [];
        this.queryEnerData();
        this.queryBuildPaiMing();
        this.getBuildId();
        this.queryEchartsTrend();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.enersta {
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    // width: 143.8rem;
    // height: 75rem;
}
.fire_control {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // position: relative;
  // width: 100%;
  .enerMap {
    position: relative;
    margin-bottom: 1.5rem;
    .mapBack {
      width: 100%;
      // width: 70.4rem;
      height: 53.4rem;
      // position: absolute;
      // left: 35.5rem;
    }
    .mapRound {
      .mapPunctu1 {
        width: 19.8rem;
        height: 10.3rem;
        position: absolute;
        // left: 34.1rem;
        left: 50%;
        top: 5.5rem;
        transform: translateX(-10%);
      }
      .mapPunctu2 {
        height: 5.3rem;
        position: absolute;
        // left: 42rem;
        left: 50%;
        top: 15rem;
        transform: translateX(130%);
      }
      .map-text {
        position: absolute;
        // left: 38rem;
        left: 50%;
        top: 7rem;
        z-index: 100;
        transform: translateX(20%);
        .text1 {
          margin-left: -3rem;
          font-size: 1.4rem;
          color: #51d1ff;
          margin-bottom: 1rem;
        }
        .text2 {
          position: relative;
          right: 1rem;
          // text-align: center;
          font-size: 2.4rem;
          color: #23fdcf;
          span {
            font-size: 1.6rem;
            color: #ffffff;
          }
        }
      }
    }
  }
  .energy-left {
    position: relative;
    margin-right: 1.5rem;
    // position: absolute;
    // left: -1.5rem;
    .energr-today {
      width: 34rem;
      height: 28rem;
      position: relative;
      background: #0c2f4f;
      border-radius: 4px;
      // margin-left: 1.5rem;
      .title {
        position: relative;
        top: 2rem;
        margin-left: 2.4rem;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
      }
      .center {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 2.4rem 0 2.4rem;
        .cen-top {
          display: flex;
          flex-direction: row;
          .cen-list {
            display: flex;
            flex-direction: row;
            margin-top: 4.3rem;
            img {
              width: 5rem;
              height: 5rem;
              .cen-img {
                width: 4.8rem;
                height: 4.8rem;
              }
            }
            .cen-list-center {
              margin-left: 0.5rem;
              .textTop {
                font-family: Arial;
                font-weight: normal;
                font-size: 2.2rem;
                line-height: 2.5rem;
                color: #fbfbfb;
                margin-bottom: 0.2rem;
              }
              p {
                font-family: PingFang SC;
                font-size: 1.2rem;
                line-height: 1.7rem;
                color: #fffffe;
              }
            }
          }
          .cen-left {
            min-width: 16rem;
          }
        }
        .cen-bottom {
          display: flex;
          flex-direction: row;
          .cen-list {
            display: flex;
            flex-direction: row;
            margin-top: 4.3rem;
            img {
              width: 5rem;
              height: 5rem;
              .cen-img {
                width: 4.8rem;
                height: 4.8rem;
              }
            }
            .cen-list-center {
              margin-left: 0.5rem;
              .textTop {
                font-family: Arial;
                font-weight: normal;
                font-size: 2.2rem;
                line-height: 2.5rem;
                color: #fbfbfb;
                margin-bottom: 0.2rem;
              }
              p {
                font-family: PingFang SC;
                font-size: 1.2rem;
                line-height: 1.7rem;
                color: #fffffe;
              }
            }
          }
          .cen-left {
            min-width: 16rem;
          }
        }
      }
    }
    .energr-calendar {
      width: 34rem;
      height: 57.8rem;
      position: relative;
      top: 1.5rem;
      background: #0c2f4f;
      border-radius: 4px;
      // margin-left: 1.5rem;
      .title {
        position: relative;
        top: 2rem;
        margin-left: 2.4rem;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
      }
      .cal-center {
        .select-box {
          display: flex;
          justify-content: flex-end;
        }

        ::v-deep .el-calendar-day {
          height: 4rem;
        }
        ::v-deep
          .el-calendar-table__row
          td::v-deep
          .el-calendar-table
          tr
          td:first-child,
        ::v-deep .el-calendar-table__row td.prev {
          border: none;
        }
        .date-content {
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
        }
        .date-content .rest1 {
          position: absolute;
          top: 4.8rem;
          left: 1.8rem;
          color: #fff;
          z-index: 9999;
          border-radius: 50%;
          background: rgb(250, 124, 77);
          width: 0.7rem;
          height: 0.7rem;
          display: inline-block;
          margin-left: 0.3rem;
        }
        .date-content .rest2 {
          position: absolute;
          top: 4.8rem;
          left: 1.8rem;
          color: #fff;
          z-index: 9999;
          border-radius: 50%;
          background: #00b578;
          width: 0.7rem;
          height: 0.7rem;
          display: inline-block;
          margin-left: 0.3rem;
        }
        .date-content .rest3 {
          position: absolute;
          top: 4.8rem;
          left: 1.8rem;
          color: #fff;
          z-index: 9999;
          border-radius: 50%;
          background: #ffaf00;
          width: 0.7rem;
          height: 0.7rem;
          display: inline-block;
          margin-left: 0.3rem;
        }
        .date-content .text {
          width: 20px;
          height: 20px;
          line-height: 20px;
          display: inline-block;
        }
        ::v-deep .el-calendar-table td.is-selected .text {
          background: #409eff;
          color: #fff;
          border-radius: 50%;
        }
      }
    }
  }
  .energy-center {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    // width: 70.3rem;
    // height: 32.5rem;
    // position: absolute;
    // left: 35.5rem;
    // bottom: -12.3rem;
    border-radius: 0.4rem;
    .enerLineChart {
      background: #0c2f4f;
      .title {
        position: relative;
        top: 2rem;
        margin-left: 2.4rem;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
      }
      .c1 {
        margin: 0 auto;
        width: 70.3rem;
        height: 30rem;
      }
    }
  }
  .energy-rigth {
    position: relative;
    // position: absolute;
    // top: 0rem;
    // right: 0rem;
    .rigth-top {
      margin-bottom: 1.5rem;
      width: 34rem;
      height: 28rem;
      background: #0c2f4f;
      border-radius: 0.4rem;
      margin-left: 1.6rem;
      padding: 1rem 1rem;
      .title {
        position: relative;
        top: 0.7rem;
        margin-left: 1rem;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
      }
      .tbl-title {
        margin-top: 16px;
        margin-left: 0.5rem;
        width: 33rem;
        background: #005ab1;
        border-radius: 3px;
        .tbl-name {
          display: flex;
          margin-left: 15px;
          .tbl1 {
            margin-top: 0.4rem;
            color: #fffffe;
            font-weight: 300;
          }
          .tbl2 {
            margin-top: 0.4rem;
            color: #fffffe;
            font-weight: 300;
            margin-left: 10rem;
          }
          .tbl3 {
            margin-top: 0.4rem;
            color: #fffffe;
            font-weight: 300;
            margin-left: 6rem;
          }
        }
        .tbl-cen-list {
          margin-top: 2.5rem;
          span {
            color: #fffffe;
            font-size: 1.2rem;
            font-weight: 300;
          }
          .name2 {
            margin-left: 2.5rem;
          }
          .name3 {
            margin-left: 3.8rem;
          }
        }
      }
    }
    .rigth-bottom {
      width: 34rem;
      height: 54.1rem;
      // position: absolute;
      // right: 0;
      // top: 31.2rem;
      background: #0c2f4f;
      border-radius: 4px;
      margin-left: 1.5rem;
      padding: 1rem;
      // overflow: hidden;
      overflow-y: auto;
      .title {
        position: relative;
        top: 1.7rem;
        margin-left: 1rem;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
      }
      .bottom-box {
        // overflow-y: scroll;
        // width: 100%;
        // height: 100%;
        // margin-top: 30px;
      }
      .bottom-center {
        position: relative;
        top: 1.5rem;
        margin: 2rem 0 0.3rem 0;
        display: flex;
        justify-content: space-between;
        padding: 0 1.5rem;
        span {
          color: #fffffe;
          font-size: 1.2rem;
          font-weight: 300;
        }
      }
      .str-false {
        // width: 32.7rem;
        width: 31.5rem;
        position: relative;
        top: 1.5rem;
        margin: 0 1.5rem;
        height: 1.6rem;
        border-radius: 3px;
        background-color: #0f495c;
      }
      .str-true {
        width: 10.7rem;
        position: relative;
        // top: -0.2rem;
        margin: 0 1.5rem;
        height: 1.6rem;
        border-radius: 3px;
        background: linear-gradient(270deg, #36eaaf 0%, #4bb3d2 100%);
        .rightBar {
          position: absolute;
          height: 1.8rem;
          right: 0;
          top: -0.2rem;
          width: 0;
          border: 0.1rem solid #fff;
        }
      }
      .styleWid {
        width: 13.5rem;
        float: right;
        position: relative;
        right: 1.5rem;
        top: 1rem;
      }
    }
  }
  .blank {
    position: relative;
    top: 90rem;
    height: 20rem;
    width: 142.8rem;
    color: grey;
    font-size: 1.4rem;
    text-align: center;
    line-height: 10rem;
  }
}
.enerstion-bottom {
  overflow: hidden;
  // width: 143.4rem;
  width: 100%;
  // height: 88.8rem;
  height: 100%;
  overflow-y: auto;
  // margin-top: 1.5rem;
}
</style>
<style lang="less">
.ener-read {
  height: 100%;
  .enerstion-bottom {
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    width: calc(100% + 0.6rem);
    height: calc(100vh - 19.2rem);
    .el-table {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      max-width: 100%;
      font-size: 1.2rem;
      color: #fff;
    }
    .el-table th.el-table__cell {
      background: #005ab1;
      border-radius: 1px;
    }
    .el-table tr {
      background: #0c2f4f;
    }
    .el-table td.el-table__cell {
      border-bottom: 1px solid #0c2f4f;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: #0c2f4f;
    }
    .el-table .el-table__cell {
      padding: 0rem 0;
    }
    .el-table thead {
      color: #ffffff;
      font-weight: 500;
    }
    .el-table--border th.el-table__cell,
    .el-table__fixed-right-patch {
      border-bottom: 1px solid #0c2f4f;
    }
    .el-table thead.is-group th.el-table__cell {
      background: #0c2f4f;
    }
    .el-table--border .el-table__cell,
    .el-table__body-wrapper
      .el-table--border.is-scrolling-left
      ~ .el-table__fixed {
      border-right: 1px solid #0c2f4f;
    }
    .el-table--border,
    .el-table--group {
      border: 1px solid #0c2f4f;
    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      content: "";
      position: absolute;
      background-color: #0c2f4f;
      z-index: 1;
    }
    .el-table th.el-table__cell.is-leaf {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 1.2rem;
    }
    .el-table .caret-wrapper {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      height: 34px;
      width: 0px;
      vertical-align: middle;
      cursor: pointer;
      overflow: initial;
      position: relative;
    }
    .el-calendar__body {
      padding: 0;
      background-color: #0c2f4f;
      position: relative;
      top: 0.7rem;
    }
    .el-calendar-table thead th {
      padding: 0 0;
      color: #ffffff;
      font-weight: 400;
      position: relative;
      top: -0.7rem;
    }
    .el-calendar-table tr td:first-child {
      border-left: 1px solid #0c2f4f;
    }
    .el-calendar-table tr:first-child td {
      border-top: 1px solid #0c2f4f;
    }
    .el-calendar-table td {
      border-bottom: 1px solid #0c2f4f;
      border-right: 1px solid #0c2f4f;
      vertical-align: top;
      transition: background-color 0.2s ease;
    }
    .el-calendar-table .el-calendar-day:hover {
      position: relative;
      height: 0px !important;
      background-color: inherit;
    }
    .el-calendar-table td.is-selected {
      background-color: #0c2f4f;
    }
    .el-calendar {
      background-color: #0c2f4f;
      position: relative;
    }
    .el-button {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #409eff;
      border: 1px solid #0c2f4f;
      color: #ffffff;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      padding: 0.5rem 0.4rem;
      font-size: 14px;
      border-radius: 4px;
    }
    .el-calendar__title {
      color: #ffffff;
      align-self: center;
    }
    .fire_control
      .energy-left
      .energr-calendar
      .cal-center
      .date-content
      .text[data-v-2d7e89f3] {
      width: 20px;
      height: 20px;
      // color: #ffffff;
      line-height: 20px;
      display: inline-block;
    }

    .el-calendar__header {
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;
      border-bottom: 1px solid #0c2f4f;
    }
    .enerstion-bottom .el-calendar {
      background-color: #0c2f4f;
    }
    .el-calendar-table .el-calendar-day {
      box-sizing: border-box;
      padding: 4px;
      height: 85px;
      position: relative;
      top: -8px;
    }
  }
  .enerstion-bottom .el-calendar {
    background-color: #0c2f4f;
    position: relative;
    top: 1.4rem;
  }
  .enerstion-bottom .el-calendar-table:not(.is-range) td.next,
  .enerstion-bottom .el-calendar-table:not(.is-range) td.prev {
    color: #c0c4cc;
  }
  .el-table__header tr,
  .el-table__header th {
    padding: 0;
    height: 0px;
  }
  .el-table__body tr,
  .el-table__body td {
    height: 5rem;
  }
  .energy-rigth {
    ::-webkit-scrollbar {
      width: 0px; // 横向滚动条
      height: 0px; // 纵向滚动条 必写
    }
    ::-webkit-scrollbar-thumb {
      background-color: #606266 !important;
      border-radius: 3px;
    }
  }
}
</style>
