<template>
    <div class="office2-content">
        <span class="title">
          <el-select
              v-model="energyName"
              @change="energyNameChange"
              ref="selectTreeRef"
          >
            <el-option
                v-for="item in energyNameList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </span>
        <div class="dateChoose">
            <div :class="btnTypeDay" @click="changeTab(0)">今天</div>
            <div :class="btnTypeWeek" @click="changeTab(1)">本周</div>
            <div :class="btnTypeMonth" @click="changeTab(2)">本月</div>
            <div :class="btnTypeYear" @click="changeTab(3)">本年</div>
        </div>
        <div class="rigth-bottom">
            <div v-if="loading" class="loading-mask">
              <el-spinner size="large"></el-spinner>
            </div>
            <div v-for="(item, index) in strList" :key="index">
                <div class="bottom-center">
                    <span>{{ item.name }}</span>
                    <span>{{ item.value }}kWh</span>
                </div>
                <div class="str-false"></div>
                <!-- <div class="str-true" :style="{ width: item.conNum + 'rem' }"> -->
                <div class="str-true" :style="{ width: item.conNum + '%' }">
                    <div class="rightBar"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data () {
        return {
            btnTypeDay: "btnGreen",
            btnTypeWeek: "btnDark",
            btnTypeMonth: "btnDark",
            btnTypeYear: "btnDark",
            whichTab: 0,
            dataForm: {
              customerId: '',
              buildId: '',
            },
            strList: [],
            energyName: "",
            energyNameList: [],
            loading: false,
            firstLoad: true,
        };
    },
    mounted () { },
    methods: {
        changeTab (index) {
            this.whichTab = index;
            this.showInterval = false;
            if (index === 0) {
                this.btnTypeDay = "btnGreen";
                this.btnTypeWeek = "btnDark";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnDark";
            } else if (index === 1) {
                this.btnTypeDay = "btnDark";
                this.btnTypeWeek = "btnGreen";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnDark";
            } else if (index === 2) {
                this.btnTypeDay = "btnDark";
                this.btnTypeWeek = "btnDark";
                this.btnTypeMonth = "btnGreen";
                this.btnTypeYear = "btnDark";
            } else if (index === 3) {
                this.btnTypeDay = "btnDark";
                this.btnTypeWeek = "btnDark";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnGreen";
            }
        },
        energyNameChange(e) {
          this.dataForm.buildId = e;
          if (this.whichTab === 0) {
            this.getData(e);
          } else {
            this.loading = true
            this.strList = false
            this.$store
                .dispatch("getBuildRank", {
                  id: this.dataForm.buildId,
                  timetype: (this.whichTab + 1),
                })
                .then(() => {
                  let dataList = this.getBuildRank || [];
                  dataList = this.calcData(dataList)
                  this.strList = dataList;
                }).finally(() => {
                  this.loading = false
                });
          }

        },
        getData(locationId) {
          this.loading = true
          this.strList = []
          if (locationId) {
            this.$store
                .dispatch("querySumOverviewLocation", locationId)
                .then(() => {
                  let result = this.$parent.$store.state.elecManagement.querySumOverviewLocation
                  // this.tableData = this.$store.state.storage.querySumOverviewLocation.data.byname;
                  if (result.errorCode === 0) {
                    let dataList2 = result.data.byusage
                    dataList2 = this.calcData(dataList2);
                    this.strList = dataList2
                  } else {
                    this.strList = []
                  }
                }).finally(() => {
                  this.loading = false
                })
          }
        },
      calcData(dataList2) {
        if (dataList2 !== null) {
          dataList2.forEach((value, index) => {
            value["conNum"] = "0"
          })
          for (let i = 0; i < dataList2.length; i++) {
            let sum = dataList2[i].value / dataList2[0].value
            // dataList2[i].conNum = sum * 61.5
            dataList2[i].conNum = sum * 100
            if (!dataList2[i].value || dataList2[i].value === 0) {
              dataList2[i].conNum = 0
            }
          }
          return dataList2
        } else {
          return []
        }
      },
      getBuildId() {
        // 获取客户id
        this.dataForm.customerId = this.$store.state.storage.customerId;
        this.strList = []
        // 获取客户树id
        this.$store
            .dispatch("getBuildById", this.dataForm.customerId)
            .then(() => {
              let arr = this.getBuildById;
              if (arr && arr.length > 0) {
                if ((arr !== null) & (arr.length > 0)) {
                  this.energyName = this.getBuildById[0].name;
                  this.dataForm.buildId = arr[0].id;
                  this.energyNameList = arr;
                  // this.getData(this.dataForm.buildId)
                  if (this.whichTab === 0) {
                    this.getData(this.dataForm.buildId)
                  } else if (this.whichTab === 1) {
                    this.getSingleData("2")
                  } else if (this.whichTab === 2) {
                    this.getSingleData("3")
                  } else if (this.whichTab === 3) {
                    this.getSingleData("4")
                  }
                }
              } else {
                this.energyName = ''
                this.energyNameList = []
                this.dataForm.buildId = ''
              }
            })
      },
      getSingleData(type) {
        let dataList = []
        this.loading = true
        this.strList = false
        this.$store
            .dispatch("getBuildRank", {
              id: this.dataForm.buildId,
              timetype: type,
            })
            .then(() => {
              dataList = this.getBuildRank || [];
              this.strList = this.calcData(dataList);
            }).finally(() => {
              this.loading = false
            })
      }
    },
    watch: {
        whichTab: {
            immediate: true,
            handler (val) {
                // 直方图部分的伪造数据##################################################################################
                if (this.dataForm.buildId && this.dataForm.buildId !== '') {
                  if (val === 0) {
                    this.getData(this.dataForm.buildId)
                  } else if (val === 1) {
                    this.getSingleData("2")
                  } else if (val === 2) {
                    this.getSingleData("3")
                  } else if (val === 3) {
                    this.getSingleData("4")
                  }
                }
            },
        },
        "$store.state.storage.customerId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.dataForm.customerId = newVal;
                this.getBuildId()
            },
        },
    },
    computed: {
        ...mapState({
            getBuildRank: (state) => {
                return state.elecManagement.getBuildRank.data;
            },
            querySumOverview: (state) => {
                return state.elecManagement.querySumOverview.data;
            },
            getBuildById: (state) => {
              return state.elecManagement.getBuildById.data;
            },
        }),
    },
};
</script>

<style lang="less">
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
.office2-content {
    .title {
        position: relative;
        top: 2rem;
        margin-left: 2.4rem;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
    }
    .dateChoose {
        height: 3.5rem;
        width: 35.3rem;
        border-radius: 0.2rem;
        overflow: hidden;
        border: 0.1rem solid #004579;
        box-sizing: border-box;
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
        right: 2.4rem;
        top: 1.6rem;
        color: #fff;
        .btnDark {
            width: 9.6rem;
            height: 3.8rem;
            background-color: #0b375e;
            font-size: 1.4rem;
            padding: 0;
            margin: 0;
            cursor: pointer;
            line-height: 3.6rem;
            text-align: center;
        }
        .btnGreen {
            width: 9.6rem;
            height: 3.8rem;
            background-color: #027969;
            font-size: 1.4rem;
            padding: 0;
            margin: 0;
            cursor: pointer;
            line-height: 3.6rem;
            text-align: center;
        }
    }
    .rigth-bottom {
        // width: 64rem;
        width: 90%;
        margin: 0 auto;
        height: 54.4rem;
        height: 31rem;
        overflow-y: auto;
        position: relative;
        top: 4rem;
        border-radius: 4px;
        // margin-left: 1.5rem;
        .title {
            position: relative;
            top: 1.7rem;
            // top: 1.7rem;
            margin-left: 1rem;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: #37a5dd;
        }
        .bottom-center {
            margin-top: 0.9rem;
            display: flex;
            justify-content: space-between;
            // padding: 0 1.5rem;
            span {
                color: #fffffe;
                font-size: 1.2rem;
                font-weight: 300;
            }
        }
        .str-false {
            // width: 32.7rem;
            // width: 61.5rem;
            width: 100%;
            position: relative;
            top: 0.5rem;
            // margin: 0 1.5rem;
            height: 1.6rem;
            border-radius: 3px;
            background-color: #0f495c;
        }
        .str-true {
            // width: 10.7rem;
            width: 17%;
            position: relative;
            top: -1.2rem;
            // margin: 0 1.5rem;
            height: 1.6rem;
            border-radius: 3px;
            background: linear-gradient(270deg, #36eaaf 0%, #4bb3d2 100%);
            .rightBar {
                position: absolute;
                right: 0;
                top: -0.2rem;
                width: 0;
                height: 1.8rem;
                border: 0.1rem solid #fff;
            }
        }
    }
}
</style>
