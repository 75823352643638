<template>
    <div class="table">
        <div class="table-top" id="tableForPng">
            <span class="title">本月用电趋势</span>
            <!-- 切换图表 -->
            <div class="table-time">
                <div class="bar">
                    <img v-show="!barChartShow" @click="changeBar()" src="@/assets/ElecManagement/BarChart.png" alt />
                    <img v-show="barChartShow" @click="changeBar()" src="@/assets/ElecManagement/BarChartGreen.png" alt />
                </div>
                <div class="line">
                    <img v-show="!lineChartShow" @click="changeLine()" src="@/assets/ElecManagement/LineChart.png" alt />
                    <img v-show="lineChartShow" @click="changeLine()" src="@/assets/ElecManagement/LineChartGreen.png" alt />
                </div>
                <div class="download">
                    <!-- <img src="@/assets/ElecManagement/Download.png" alt /> -->
                    <!-- 下载的下拉框 -->
                    <el-dropdown @command="downLoadPng">
                        <span class="el-dropdown-link">
                            <img src="@/assets/ElecManagement/Download.png" alt="" />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="['pic', '本月用电趋势']">图片下载</el-dropdown-item>
                            <el-dropdown-item :command="['excel', '本月用电趋势']">Excel下载</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <!-- 图表部分 -->
            <div class="chart">
                <div v-if="barChartShow">
                    <MapEnErColumn ref="lineCharts" :barList="barList" />
                </div>
                <div v-else>
                    <MapEnErLine ref="lineCharts" :lineList="barList" />
                </div>
            </div>
        </div>
        <div class="table-center">
            <div class="tab-cen-left">
                <OfficeBuild1 ref="officeBuild1Ref"/>
            </div>
            <div class="tab-cen-rigth">
                <OfficeBuild2 ref="officeBuild2Ref"/>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from "html2canvas"
import { truncate } from "@antv/x6/lib/util/string/string"
import { mapState } from "vuex"
import MapEnErColumn from "./mapEnErColumn"
import MapEnErLine from "./mapEnErLine"
import OfficeBuild1 from "./officeBuild1"
import OfficeBuild2 from "./officeBuild2"
import { exportEnergyReport } from "@/api/repair"
export default {
    props: {
        name: {
            type: String,
            default: () => ''
        },
    },
    components: {
        MapEnErColumn,
        MapEnErLine,
        OfficeBuild1,
        OfficeBuild2,
    },
    data () {
        return {
            lineChartShow: false,
            barChartShow: true,
            optionData: {},
            showInterval: true,
            whichTab: 0,
            barList: [],
            dataForm: {
                customerId: "1448924021822775297",
            },
            // 表格和横柱状图数据
            officeData: [],
        }
    },

    mounted () { },
    methods: {
        // 转化dom节点为图片下载
        downLoadPng (val) {
            if (val[0] == "excel") {
                let date = new Date()
                let m = date.getMonth() + 1
                m = m < 10 ? "0" + m : m
                let year = date.getFullYear()
                this.dataForm.customerId = this.$store.state.storage.customerId
                let value = {
                    customerId: this.dataForm.customerId,
                    month: year + "-" + m,
                }
                // this.$store.dispatch("exportDailyElec", value);
                exportEnergyReport(value).then((response) => {
                    var blob = new Blob([response], {
                        type: "application/vnd.ms-excel,charset=utf-8",
                    }) //创建一个blob对象
                    var a = document.createElement("a") //创建一个<a></a>标签
                    a.href = URL.createObjectURL(blob) // response is a blob
                    a.download = `${this.name}能耗统计-本月用电趋势.xls` //文件名称
                    a.style.display = "none"
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
            } else {
                var node = document.getElementById("tableForPng")
                window.pageYoffset = 0
                document.documentElement.scrollTop = 0
                document.body.scrollTop = 0
                var width = node.offsetWidth
                var height = node.offsetHeight
                var scale = 4
                html2canvas(node, {
                    width: width,
                    heigth: height,
                    backgroundColor: "#0c2f4f",
                    dpi: window.devicePixelRatio * 4,
                    scale: scale,
                    X: 0,
                    Y: 0,
                    scrollX: -3,
                    scrollY: 0,
                    useCORS: true,
                    allowTaint: true,
                }).then((canvas) => {
                    var url = canvas.toDataURL()
                    var a = document.createElement("a")
                    a.download = `${this.name}能耗统计-${val[1]}`
                    var event = new MouseEvent("click")
                    a.href = url
                    a.dispatchEvent(event)
                })
            }
        },

        changeBar () {
            // this.showInterval = false;
            this.barChartShow = true
            this.lineChartShow = false
        },
        changeLine () {
            // this.showInterval = false;
            this.barChartShow = false
            this.lineChartShow = true
            setTimeout(() => {
                this.$nextTick(() => {
                    this.$refs.lineCharts.init()
                })
            }, 10)
        },
        queryEnerData () {
            this.dataForm.customerId = this.$store.state.storage.customerId
            this.$store
                .dispatch("queryTrendMouth", this.dataForm.customerId)
                .then(() => {
                    if (
                        this.$store.state.elecManagement.queryTrendMouth.data.length > 0
                    ) {
                        this.barList = this.$store.state.elecManagement.queryTrendMouth.data[0].data
                    } else {
                        this.barList = []
                    }
                })
        },
    },
    created () {
    },
    watch: {
        "$store.state.storage.customerId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return
                }
                this.dataForm.customerId = newVal
                this.queryEnerData()
            },
        },
    },
    computed: {
        ...mapState({
            queryTrendMouth: (state) => {
                return state.elecManagement.queryTrendMouth.data
            },
        }),
    },
}
</script>

<style lang="less" scoped>
.table {
    //   width: 120rem;
    // float: right;
    width: 100%;
    .table-top {
        // width: 120rem;
        // height: 42rem;
        // border-radius: 0.4rem;
        height: 42rem;
        border-radius: 0.4rem;
        width: 73%;
        position: absolute;
        top: 0;
        right: 0;
        float: right;
        background: #0c2f4f;
        margin-top: -42rem;
        .title {
            position: absolute;
            top: 2rem;
            left: 2.4rem;
            font-size: 1.6rem;
            color: #37a5dd;
        }
        .table-time {
            position: absolute;
            right: 2.4rem;
            top: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 9rem;
            z-index: 1000;

            img {
                width: 2.05rem;
                height: 1.86rem;
                cursor: pointer;
            }
        }
        .chart {
            // position: absolute;
            // left: 0;
            // top: 0;
        }
    }
    .table-center {
        // width: 100%;
        width: 100%;
        position: relative;
        height: auto;
        display: flex;
        justify-content: space-between;
        // margin-top: 2rem;
        background: #18191a;
        .tab-cen-left {
            //   width: 70.5rem;
            // width: 49.5%;
            flex: 1 1 70.5rem;
            height: 40.7rem;
            background: #0c2f4f;
            border-radius: 4px;
            // position: absolute;
            position: relative;
            // left: -38.5rem;
            margin-top: 2rem;
            overflow: hidden;
        }
        .tab-cen-rigth {
            //   width: 70.5rem;
            // width: 49.5%;
            flex: 1 1 70.5rem;
            height: 40.7rem;
            background: #0c2f4f;
            border-radius: 4px;
            // position: absolute;
            position: relative;
            right: 0;
            margin-top: 2rem;
            overflow: hidden;
            overflow-y: auto;
            margin-left: 2rem;
        }
        // .tab-cen-left1 {
        //   width: 69rem;
        //   height: 36rem;
        //   background: #0c2f4f;
        //   border-radius: 4px;
        //   position: absolute;
        //   left: -38.5rem;
        //   top: 80rem;
        //   margin-top: 2rem;
        // }
        // .tab-cen-rigth1 {
        //   width: 69rem;
        //   height: 36rem;
        //   background: #0c2f4f;
        //   border-radius: 4px;
        //   position: absolute;
        //   right: 0;
        //   top: 80rem;
        //   margin-top: 2rem;
        // }
    }
}
</style>
