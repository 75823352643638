<template>
  <div class="DialogForLocation">
    <el-dialog 
    @close="handelClose"
    :visible.sync="dialogFormVisible" width="60%" style="position:absolute;" :close-on-click-modal="false">
      <div slot="title">
        <div class="equip_title">选择巡检位置</div>
      </div>
      
      <div class="bodyPlace">

        <!-- 表格区###################################################################### -->
        <div v-if="!innerVisible" class="tablePlace">
          <div class="table" style="width:98rem">
              <el-table
                :data="tableData"
                stripe:false
                height="49rem"
                border:false
                style="width: 100%"
                :header-cell-style="headerClass"
                :cell-style="cellStyle"
              >

              
                <el-table-column
                  class="tab"
                  prop="code"
                  label="编号"
                  min-width="9%"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  class="tab"
                  prop="name"
                  label="位置名称"
                  min-width="18%"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  prop="typeName"
                  label="配电房类型"
                  min-width="17%"
                  :show-overflow-tooltip="true"
                ></el-table-column>

                <el-table-column
                  prop="type"
                  label="位置类型"
                  min-width="17%"
                  :show-overflow-tooltip="true"
                ></el-table-column>

                 <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleChoose(scope.$index, scope.row)">确认选择</el-button>
                  </template>
                </el-table-column>


              </el-table>
            </div>
        </div>

       
        
       
      </div>
    </el-dialog>
      
  </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg";
import {mapState} from 'vuex'
export default {
  name:'DialogForLocation',
  mixins: [tableBg],
  data(){return{
    // 配电房类型字典
    roomDict:{0:'配电房',1:'强电井',2:'室外配电柜',3:'其他'},
    // 是否显示弹层
    dialogFormVisible:false,
    // 内层弹层
    innerVisible:false,
    // 表格数据
    tableData:[],
    // 选中数据
    choosenData:[],
  }},


  methods:{
    // 父元素点击弹出弹层
    show(val){

      this.dialogFormVisible = true
      // 先获取下属配电房数据
      this.$store.dispatch('getOwnRoomData',val.id).then(()=>{
          this.tableData = []
          if(this.ownRoomData){
          for(let i=0;i<this.ownRoomData.length;i++){
            const tableSingel = {check:'',code:'',name:'',type:''}
            tableSingel.check = false
            tableSingel.code = this.ownRoomData[i].code
            tableSingel.name = this.ownRoomData[i].name
            // tableSingel.type =  this.ownRoomData[i].type
            tableSingel.id =  this.ownRoomData[i].id
            tableSingel.typeName = this.roomDict[this.ownRoomData[i].type]
            tableSingel.type = "配电房"
            this.tableData.push(tableSingel)
            }
          }
          // 再获取下属电站数据       
          this.$store.dispatch('getPowerStationData',val.id).then(()=>{
            if(this.powerStationData){   
            for(let i=0;i<this.powerStationData.length;i++){
              const tableSingel = {check:'',code:'',name:'',type:''}
              tableSingel.check = false
              tableSingel.code = this.powerStationData[i].code
              tableSingel.name = this.powerStationData[i].name
              tableSingel.id =  this.powerStationData[i].id
              tableSingel.typeName = "——"
              tableSingel.type = "电站"
              this.tableData.push(tableSingel)
            }
            }
          })
        
      })
    },

    // 关闭弹窗
    handelClose(){
      this.innerVisible = false
      this.dialogFormVisible=false
    },

    // 确认选择
    handleChoose(index){
      this.dialogFormVisible=false
      const dataLocation = {locationId:"",locationType:""}
      if(this.tableData[index].type==="电站"){dataLocation.locationType=2}else{dataLocation.locationType=1}
      dataLocation.locationId=this.tableData[index].id
      dataLocation.name = this.tableData[index].name
      this.$parent.getLocation(dataLocation)
    },

  },

   computed:{
    ...mapState({
      // 下属配电房数据
        ownRoomData: state => {return state.equipment.ownRoomData.data;},
      // 下属电站数据
        powerStationData: state => {return state.customer.powerStationData.data;},
    })
  },


  

}
</script>

<style lang="less" scoped>
.bodyPlace{
  position: absolute;
  top: 5.6rem;
  left: 0;
  background-color: #0d3050;
  width: 100%;
  .topPlace{
    height: 5rem;
    width: 100%;
    background-color: #0d3050;
    margin-bottom: 1rem;
    position: relative;
    .btn1{
      position: absolute;
      top: 1rem;
      right: 3rem;
      height: 3rem;
      background-color: #027969;
      border: none;
      cursor: pointer;
      border-radius: 0.2rem;
      color: #fff;
       font-size: 1.4rem;
    }
    .btn2{
      position: absolute;
      top: 1rem;
      right: 21rem;
      height: 3rem;
      background-color: #0c375e;
      border: 0.1rem solid #004579;
      cursor: pointer;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 1.4rem;
      box-sizing: border-box;
    }
    .btn3{
      position: absolute;
      top: 1rem;
      right: 28.5rem;
      height: 3rem;
      background-color: #0c375e;
      border: 0.1rem solid #004579;
      cursor: pointer;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 1.4rem;
      box-sizing: border-box;
    }
    .btn4{
      position: absolute;
      top: 1rem;
      right: 18rem;
      height: 3rem;
      background-color: #0c375e;
      border: 0.1rem solid #004579;
      cursor: pointer;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 1.4rem;
      box-sizing: border-box;
    }
}

.tablePlace{
  table{
  width:98rem;
  overflow: scroll;
  }
}


}


// 内置弹层样式##########################################
.innerDialogPlace{
  position: absolute;
  top: 5.6rem;
  left: 0;
  background-color: #0d3050;
  width: 100%;
  height: 50rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: scroll;
  .card{
        border: #000 5px solid;
        height: 250px;
        width: 200px;
        margin: 10px 5px 10px 5px;
        background-color: #027969;
        position: relative;
        top: 0;
        left: 0;
        .cardInside{
            position: absolute;
            left: 25px;
            top: 10px;
            height: 130px;
            width: 130px;
             // 二维码样式
            .qrcode{
                display: block;
                height: 150px;
                width: 150px;
                background-color: #fff;
                align-items: center;
                line-height: 150px;
                padding: 10px;
                box-sizing: border-box;
                img {
                    width: 130px;
                    height: 130px;                
                }
            }
        }
        .cardTitle{
          position: absolute;
          left: 25px;
          top: 170px;
          height: 30px;
          width: 150px;
          text-align: center;
          color: #000;
          font-size: 16px;
        }
        .cardTitle2{
           position: absolute;
          left: 25px;
          top: 200px;
          height: 30px;
          width: 150px;
          text-align: center;
          color: #000;
          font-size: 16px;
        }

     


  }


}



</style>



<style lang="less">
// 表格样式修改（全局）#############################################
.DialogForLocation {
  // .el-table__body-wrapper .is-scrolling-none{}
  .table {
    height: 50rem;
    width: 98rem;
    margin: 0 2.4rem 0 2.4rem;
    box-sizing: border-box;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }


  .el-checkbox.is-bordered.is-checked {
    border-color: #027969;
}

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #027969;
      border-color: #027969;
  }


  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #027969;
}

  .el-checkbox.is-bordered{
    border : 0.1rem solid #606266;
  }
  .el-checkbox__inner{
    background-color: #0c375e;
    border : 0.1rem solid #606266;
  }
  .el-checkbox__inner:hover {
    border-color: #606266;
}
.el-checkbox__inner:hover {
    border-color: #606266;
}
// .innerDialogPlace{
//   .el-dialog-div{
//     height: 70vh;
//   }
// }
}
</style>