<template>
  <div class="step-bottom">
    <div class="stepeltric">
      <div class="stepContent">
        <div class="step-top">
          <StepTop ref="StepTop" />
        </div>
        <div class="step-bottom-content">
          <div class="step-bottom-left">
            <StepBottomLeft ref="StepBottomLeft" />
          </div>
          <div class="step-bottom-rigth">
            <StepBottomRigth ref="StepBottomRigth" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
  </div>
</template>

<script>
import StepTop from "./stepRouter3/stepTop";
import StepBottomLeft from "./stepRouter3/stepBottomLeft";
import StepBottomRigth from "./stepRouter3/stepBottomRigth";
export default {
  components: {
    StepTop,
    StepBottomLeft,
    StepBottomRigth,
  },
  name: "StepElectric",
  data() {
    return {
      strData: [],
    };
  },
  watch: {
    "$store.state.storage.customerId": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        //监听用户id调用方法，不然报错id不存在
        this.queryEnerData(newVal);
      },
    },
  },
  methods: {
    queryEnerData(newVal) {
      this.$store.dispatch("getBuildCard", newVal).then(() => {
        this.$refs.StepTop.queryEnerData();
        this.$refs.StepBottomRigth.queryEnerData();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.step-bottom {
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    //   width: 143.8rem;
    width: 100%;
    height: 96.8rem;
}
.stepeltric {
    overflow: hidden;
    //   width: 142.8rem;
    width: calc(100% + 0.6rem);
    height: 88.8rem;
    margin-top: 1.5rem;
}
.stepContent {
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  width: calc(100% + 0.6rem);
  height: calc(100vh - 21.2rem);
    .step-bottom-content {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
    }
    .step-bottom-left {
        position: relative;
        // width: 106.5rem;
        flex: 1 1 106.5rem;
        // flex: 1 1 75%;
        height: 45.8rem;

        background: #0c2f4f;
        border-radius: 4px;
        // margin-top: 1.5rem;
    }
    .step-bottom-rigth {
        margin-left: 2.4rem;
        position: relative;
        // width: 33.9rem;
        flex: 1 1 33.9rem;
        // flex: 1 1 25%;
        height: 45.8rem;
        // position: absolute;
        // top: 38.8rem;
        // right: 0.5rem;
        background: #0c2f4f;
        border-radius: 0.4rem;
        // margin-top: 1.5rem;
    }
}
.blank {
    position: relative;
    top: 0rem;
    height: 20rem;
    //   width: 142.8rem;
    color: grey;
    font-size: 1.4rem;
    text-align: center;
    line-height: 10rem;
}
</style>
<style></style>
