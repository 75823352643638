
<template>
  <div class="userPageInSystem">
    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div>
        <el-input
          size="small"
          placeholder="请输入用户名"
          v-model="searchValue"
          @keyup.enter.native="search"
          style="width:22rem;"
          clearable
          @clear="search"
        ></el-input>
        <el-select
          size="small"
          @change="selectChanged"
          placeholder="请选择用户状态"
          class="businessSelector"
          v-model="userState"
        >
          <el-option
            v-for="(item, i) in userStateList"
            :label="item.label"
            :value="item.value"
            :key="i"
          ></el-option>
        </el-select>
        <button class="btn_save" @click="search">查 询</button>
      </div>
      <button class="btn2" @click="ifDialogShow">新增用户</button>
    </div>

    <!-- 中部表格位置############################################################################################################ -->
    <div class="userTablePlace">
          <!-- 面包屑 -->
          <div class="bread">
            系统管理 / 用户管理
          </div>
          <div class="table">
              <el-table :data="tableData" stripe:false height="57.8rem" 
                        border:false style="width: 100%"
                        :header-cell-style="headerClass"                    
                        :cell-style="cellStyle">
                <el-table-column  label="序号"  min-width="6%">
                  <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
                </el-table-column> 
                <el-table-column class="tab" prop="userNo" label="编号" min-width="10%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="name" label="姓名" min-width="10%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="username" label="账号" min-width="10%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="sex" label="性别" min-width="8%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="mobile" label="手机号" min-width="15%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="deptName" label="部门" min-width="15%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="positionName" label="职位" min-width="10%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column prop="statusName" label="状态" min-width="8%" :show-overflow-tooltip="true"> </el-table-column>
                <!-- 操作部分 -->
                <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    <el-button size="mini" type="text" @click="handleResetPassword(scope.$index, scope.row)">重置密码</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页器部分 -->
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumForPagenation"
              >
              </el-pagination>
            </div>
    </div>

    <!-- 新增用户弹层 -->
    <DialogUser ref="dialogRef" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import DialogUser from "../level2Components/DialogUser.vue";
export default {
  name: "User",
  mixins: [tableBg],
  components: { DialogUser },
  data() {
    return {
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 双向绑定的搜索数据
      searchValue: "",  
      // 双向绑定的用户状态
      userState: "",
      // 用户状态列表
      userStateList: [
        { label: "冻结", value: "0" },
        { label: "正常", value: "1" },
        { label: "全部", value: " " }
      ],
      // 表格数据
      tableData: []
    };
  },

  methods: {
    // 刷新页面
    refreshThePage() {
      this.$store
        .dispatch("getUserPageData", {
          current: this.currentPage,
          size: this.pageSize,
          username: this.searchValue,
          status: this.userState
        })
        .then(() => {       
          this.tableData=[]          
          for(let i=0;i<this.userPageData.length;i++){
            const tableSingle = {userNo:"",name:"",username:"",sex:"",mobile:"",deptName:"",positionName:"",status:"",statusName:""}
            tableSingle.userNo = this.userPageData[i].userNo;
            tableSingle.name = this.userPageData[i].name;
            tableSingle.username = this.userPageData[i].username;
            tableSingle.sex = this.userPageData[i].sex;
            tableSingle.mobile = this.userPageData[i].mobile;
            tableSingle.deptName = this.userPageData[i].deptName;
            tableSingle.positionName = this.userPageData[i].positionName;
            tableSingle.status = this.userPageData[i].status;
            if(this.userPageData[i].status==="1"){tableSingle.statusName = "正常"}else{tableSingle.statusName = "冻结"}
            this.tableData.push(tableSingle)
          }




          this.totalNumForPagenation = this.userTotal;
        });
    },
    // 点击搜索或者回车
    search() {
      this.currentPage = "1";
      this.refreshThePage();
    },
    // 更改用户状态选择框的选择
    selectChanged() {
      this.refreshThePage();
    },
    //点击新增用户
    ifDialogShow() {
      this.$refs.dialogRef.show();
    },
    //点击了编辑按钮
    handleEdit(index) {
      this.$refs.dialogRef.showForEdit(this.userPageData[index]);
    },
    // 点击了删除按钮
    handleDelete(index) {
      this.$confirm("您确定要执行删除操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store
          .dispatch("deleteSystemUser", this.userPageData[index].userId)
          .then(() => {
            if (this.ifDeleteUserSuccess === 0) {
              this.$message({ message: "删除成功！", type: "success" });
              this.refreshThePage();
            } else if (this.ifDeleteUserSuccess === -1) {
              this.$message({
                message: "删除失败！请检查网络",
                type: "warning"
              });
            }
          });
      });
    },
    // 点击了重置密码
    handleResetPassword(index) {
      this.$confirm(
        "此操作将重置 " +
          this.userPageData[index].name +
          " 的密码为ky1234, 是否确认重置密码?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        this.$store
          .dispatch(
            "getSystemUserResetPassword",
            this.userPageData[index].userId
          )
          .then(() => {
            if (this.ifResetPassword === 0) {
              this.$message({ message: "重置密码成功！", type: "success" });
              this.refreshThePage();
            } else if (this.ifResetPassword === -1) {
              this.$message({
                message: "重置失败！请检查网络",
                type: "warning"
              });
            }
          });
      });
    },
    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = "1";
      this.pageSize = val;
      this.refreshThePage();
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val;
      this.refreshThePage();
    }
  },

  mounted() {
    this.refreshThePage();
  },

  computed: {
    ...mapState({
      userPageData: state => {
        return state.system.userPageData.data.records;
      },
      userTotal: state => {
        return state.system.userPageData.data.total;
      },
      ifDeleteUserSuccess: state => {
        return state.system.ifDeleteUserSuccess.errorCode;
      },
      ifResetPassword: state => {
        return state.system.ifResetPassword.errorCode;
      }
    })
  }
};
</script>

<style lang="less" scoped>
// 顶部样式##################################################################
.topBar {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  box-sizing: border-box;
  height: 8rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  .searchInput {
    height: 3.2rem;
    width: 22.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
    background-color: #0c375e;
    border: 1px solid #004579;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    text-indent: 1.4rem;
    &:focus {
      border: 0.1rem solid #008080;
    }
  }

  button {
    height: 3.2rem;
    width: 7.4rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .btn1 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 26rem;
  }
  .btn2 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 158.7rem;
    width: 10.2rem;
  }
  .businessType {
    height: 3.2rem;
    width: 20.8rem;
    position: absolute;
    left: 36rem;
    top: 2.4rem;
  }
  .businessSelector {
    margin: 0 2rem;
  }
}

// 中部表格样式##################################################################
.userTablePlace {
  height: 86.4rem;
  width: 171.2rem;
  position: absolute;
  top: 10.4rem;
  left: 0;
  overflow: hidden;
  background-color: #0d3050;
  .bread {
    color: #fff;
    height: 5rem;
    width: 142.8rem;
    line-height: 5rem;
    color: #fff;
    font-size: 1.4rem;
    text-indent: 2.4rem;
  }
  .table {
    height: 57.2rem;
    width: 166.4rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
/deep/.el-dialog__footer {
  position: relative;
}
</style>





<style lang='less'>
// 公共样式！谨防代码污染！#########################################
.userPageInSystem {
  .table {
    height: 57.2rem;
    width: 148rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
</style>