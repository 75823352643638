<template>
  <div>
   
    <!-- 右侧内容区################################################################ -->
    <div class="blockRight">
      <router-view></router-view>
    </div>

     <!-- 左侧菜单的内容区############################################################ -->
    <div :class="memuLevel2Style" @click="changeMenuLevel2Style">
      <div class="menuLevelTwo">
          <router-link class="menuCard" :to="item.routerAdress" v-for="(item,index) in menuList" :key="index">{{item.name}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'System',
  data(){return{
    // 二级菜单是否显示
    memuLevel2Style:'blockLeft2',
    //二级菜单的数据列表
      menuList:[
        {name:'用户管理',routerAdress:'/system/user'},
        {name:'部门管理',routerAdress:'/system/department'},
        {name:'角色列表',routerAdress:'/system/character'},
        {name:'字典管理',routerAdress:'/system/dictionary'},
      ],
  
  }},

  methods:{
    // 更改二级菜单样式（隐藏）
    changeMenuLevel2Style(){
      this.memuLevel2Style = 'blockLeft2'
    },
  },

  

}
</script>

<style lang="less" scoped>
// 左侧菜单的内容#########################################################################
.blockLeft{
  transition: height 0.2s;
  width: 10.4rem;
  height: 15.6rem;
  position: absolute;
  left: -4.8rem;
  top: 56.5rem;
  background-color: #0A4377;
  box-shadow: inset 0 0 1.6rem rgba(8, 105, 177, 0.5);
  border-radius: 0.8rem;
  .menuCard{
    display: block;
    height: 3.9rem;
    width: 10.4rem;
    text-align: center;
    line-height: 3.9rem;
    font-size: 1.6rem;
    color: #cbd2da;
    text-decoration: none;
  }
 
  .menuCard:hover{
    color: rgba(3, 217, 188, 1);
  }
}
.blockLeft2{
  overflow: hidden;
  transition: height 0.2s;
  width: 10.4rem;
  height: 0rem;
  position: absolute;
  left: -4.8rem;
  top: 56.5rem;
  background-color: #0A4377;
  box-shadow: inset 0 0 1.6rem rgba(8, 105, 177, 0.5);
  border-radius: 0.8rem;
  .menuCard{
    display: block;
    height: 3.9rem;
    width: 10.4rem;
    text-align: center;
    line-height: 3.9rem;
    font-size: 1.6rem;
    color: #cbd2da;
    text-decoration: none;
  }
 
  .menuCard:hover{
    color: rgba(3, 217, 188, 1);
  }
}
// 右侧区域内容##############################################################################
.blockRight{
  width: 171.2rem;
  height: 96.8rem;
  position: absolute;
  left: 0;
  top: 0;

}
</style>
