<template>
    <div class="dialog_echarts1">
        <div v-if="loading" class="loading-mask">
          <el-spinner size="large"></el-spinner>
        </div>
        <div ref="myChart" class="charts" id="myChart13"></div>
        <div class="select-time">
            <!-- 自定义日期是选择本月的日期，tab栏的状态去掉，都不选中。type值随便，就设为1吧。 -->
            <el-date-picker v-show="btnTypeDay == 'btnGreen'" type="daterange" @change="timeChange" @blur="timeBlur" @focus="timeFocus" v-model="timeValue" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="btn1">
            </el-date-picker>
            <el-date-picker v-show="btnTypeMonth == 'btnGreen'" type="monthrange" @change="timeChange" @blur="timeBlur" @focus="timeFocus" v-model="timeValue" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" class="btn1">
            </el-date-picker>
            <el-date-picker v-show="btnTypeYear == 'btnGreen'" type="year" @change="timeChange" @blur="timeBlur" @focus="timeFocus" v-model="timeValue" placeholder="选择年份 " class="btn1">
            </el-date-picker>
            <div class="dateChoose">
                <!-- 日月年切换时，dateStart和dateEnd的值都为空即可 -->
                <div :class="btnTypeDay" @click="changeTab(0)">日</div>
                <div :class="btnTypeMonth" @click="changeTab(1)">月</div>
                <div :class="btnTypeYear" @click="changeTab(2)">年</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import * as echarts from 'echarts'
export default {
    name: 'EchartBarMap',
    props: {
        optionData: {
            type: Object,
            default: () => { },
        },
        chartsType: {
            type: String,
            default: () => '',
        },
    },
    data () {
        return {
            dateSpan: '2021-12',
            dataForm: {
                localtionId: '',
                startTime: '',
            },
            barList1: [],
            barList2: [],
            // type的值 1日 2月 3年
            timeType: '1',
            startDate: '',
            endDate: '',
            // 按钮的样式
            btnTypeDay: 'btnGreen',
            btnTypeMonth: 'btnDark',
            btnTypeYear: 'btnDark',
            xdata: [],
            timeValue: [new Date(), new Date()],
            monthData: [
                {
                    month: 1,
                    days: 31,
                },
                {
                    month: 2,
                    days: 28,
                },
                {
                    month: 3,
                    days: 31,
                },
                {
                    month: 4,
                    days: 30,
                },
                {
                    month: 5,
                    days: 31,
                },
                {
                    month: 6,
                    days: 30,
                },
                {
                    month: 7,
                    days: 31,
                },
                {
                    month: 8,
                    days: 31,
                },
                {
                    month: 9,
                    days: 30,
                },
                {
                    month: 10,
                    days: 31,
                },
                {
                    month: 11,
                    days: 30,
                },
                {
                    month: 1,
                    days: 31,
                },
            ],
            yMax: 0,
            loading: false,
        }
    },
    created () {
        if (this.$store.state.elecManagement.compareTabIndex !== '') {
            // this.timeType = this.$store.state.elecManagement.compareTabIndex
            // let index = this.timeType - 1
            // const now = new Date()
            // if (index === 0) {
            //     this.btnTypeDay = 'btnGreen'
            //     this.btnTypeMonth = 'btnDark'
            //     this.btnTypeYear = 'btnDark'
            //     // this.startDate = ''
            //     // this.endDate = ''
            //     this.timeValue = [now, now]
            // } else if (index === 1) {
            //     this.btnTypeDay = 'btnDark'
            //     this.btnTypeMonth = 'btnGreen'
            //     this.btnTypeYear = 'btnDark'
            //     this.timeValue = [now, now]
            // } else if (index === 2) {
            //     this.btnTypeDay = 'btnDark'
            //     this.btnTypeMonth = 'btnDark'
            //     this.btnTypeYear = 'btnGreen'
            //     this.timeValue = now
            // }
            
            // 默认展示日的数据
            this.btnTypeDay = 'btnGreen'
            this.btnTypeMonth = 'btnDark'
            this.btnTypeYear = 'btnDark'
            // this.startDate = ''
            // this.endDate = ''
            this.timeValue = [now, now]
        }
        // if (
        //     (this.$store.state.elecManagement.compareTrendDate !== '') &
        //     (this.$store.state.elecManagement.compareTrendDate.length > 0)
        // ) {
        //     // this.btnTypeDay = 'btnDark'
        //     this.timeValue = this.$store.state.elecManagement.compareTrendDate
        //     this.timeType == 1
        //     this.startDate = this.$store.state.elecManagement.compareTrendDate[0]
        //     this.endDate = this.$store.state.elecManagement.compareTrendDate[1]
        // }
        let value = {
            timeType: this.timeType,
            dateStart: this.startDate,
            dateEnd: this.endDate,
        }
        this.$parent.show(value)
    },
    methods: {
        resizeChart () {
            setTimeout(() => {
                const myChart = this.$echarts.init(document.getElementById("myChart13"));
                const chartWidth = document.getElementById("myChart13").offsetWidth
                const chartHeight = document.getElementById("myChart13").offsetHeight
                myChart.resize({ width: chartWidth, height: chartHeight })
            }, 500)
        },
        timeFocus () {
            // this.timeValue = "";
        },
        timeBlur () {
            // console.log('选好事件了');
        },
        timeChange () {
            // console.log('正在选时间');
            let arr = this.timeValue
            if (arr.length == 2) {
                if (arr[1] == '') {
                    this.$message({
                        type: 'warning',
                        message: '您刚才只选择了开始时间，请重新选择！',
                    })
                } else {
                    // this.timeType = 1
                    this.$store.commit('CompareTabIndex', this.timeType)
                    this.startDate = arr[0]
                    this.endDate = arr[1]
                    // this.btnTypeDay = 'btnDark'
                    // this.btnTypeMonth = 'btnDark'
                    // this.btnTypeYear = 'btnDark'
                    this.$store.commit('CompareTrendDate', arr)
                    let value = {
                        timeType: this.timeType,
                        // dateStart: this.startDate,
                        // dateEnd: this.endDate,
                        dateStart: '',
                        dateEnd: '',
                    }
                    this.$parent.show(value)
                    this.getSelectTime()
                }
            } else if (arr.length == 1) {
                this.$message({
                    type: 'warning',
                    message: '请重新选择起止时间！',
                })
            } else if (arr.length > 2) {
                this.$message({
                    type: 'warning',
                    message: '请只选择两个时间！',
                })
            } else {
                this.startDate = this.timeValue
                this.init()
            }
        },
        // 销毁echarts
        dispose () {
            this.$echarts.dispose(this.$refs.myChart)
        },
        init () {
            this.getSelectTime()
        },
        getThisMonth () {
            let date = new Date()
            let y = date.getFullYear()
            let m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            let d = date.getDate()
            d = d < 10 ? '0' + d : d
            const time = y + '-' + m
            this.dateSpan = time
        },
        selectDate (dateData) {
            let date = new Date(dateData)
            let y = date.getFullYear()
            let m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            let d = date.getDate()
            d = d < 10 ? '0' + d : d
            const time = y + '-' + m
            this.dateSpan = time
            this.getSelectTime()
        },
        formatDate (date) {
            if (date.getFullYear) {
                let y = date.getFullYear()
                let m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                let d = date.getDate()
                d = d < 10 ? '0' + d : d
                return y + '-' + m + '-' + d
            } else {
                return date
            }
        },
        getSelectTime () {
            // console.log(this.dataForm.localtionId,'bar');
            if (this.startDate && this.endDate) {
                this.startDate = this.formatDate(this.startDate)
                this.endDate = this.formatDate(this.endDate)
            }
            if (this.timeType == 2) {
                this.startDate = this.formatDate(this.startDate).substring(0, 7)
                this.endDate = this.formatDate(this.endDate).substring(0, 7)
            }
            if (this.timeType == 3) {
                this.startDate = this.formatDate(this.startDate).substring(0, 4)
            }
            this.loading = true
            this.$store
                .dispatch('getAnalysisTrend', {
                    type: this.timeType,
                    locationId: this.dataForm.localtionId,
                    dateStart: this.startDate,
                    dateEnd: this.endDate,
                })
                .then(() => {
                    // console.log(this.$store.state.elecManagement.getAnalysisTrend)
                    if (
                        this.$store.state.elecManagement.getAnalysisTrend.errorCode == 0
                    ) {
                        if (
                            this.$store.state.elecManagement.getAnalysisTrend.data.data !==
                            null
                        ) {
                            this.barList1 = this.$store.state.elecManagement.getAnalysisTrend.data.data
                            this.xdata = this.$store.state.elecManagement.getAnalysisTrend.data.xdata
                            this.yMax = 1
                            // this.getXdata(this.barList1)
                            this.drawLine1()
                        } else {
                            this.barList1 = []
                            this.xdata = [
                                '00:00',
                                '01:00',
                                '02:00',
                                '03:00',
                                '04:00',
                                '05:00',
                                '06:00',
                                '07:00',
                                '08:00',
                                '09:00',
                                '10:00',
                                '11:00',
                                '12:00',
                                '13:00',
                                '14:00',
                                '15:00',
                                '16:00',
                                '17:00',
                                '18:00',
                                '19:00',
                                '20:00',
                                '21:00',
                                '22:00',
                                '23:00',
                            ]
                            this.yMax = 0
                            this.drawLine1()
                        }
                    } else {
                        this.barList1 = []
                        this.xdata = [
                            '00:00',
                            '01:00',
                            '02:00',
                            '03:00',
                            '04:00',
                            '05:00',
                            '06:00',
                            '07:00',
                            '08:00',
                            '09:00',
                            '10:00',
                            '11:00',
                            '12:00',
                            '13:00',
                            '14:00',
                            '15:00',
                            '16:00',
                            '17:00',
                            '18:00',
                            '19:00',
                            '20:00',
                            '21:00',
                            '22:00',
                            '23:00',
                        ]
                        this.yMax = 0
                        this.drawLine1()
                    }
                }).finally(() => {
                  this.loading = false
                })
        },
        isLeap (year) {
            if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
                return 1
            }
            return 0
        },
        getXdata (data) {
            this.xdata = []
            if (this.timeType == 1) {
                if (this.startDate == '' && this.endDate == '') {
                    this.xdata = [
                        '00:00',
                        '01:00',
                        '02:00',
                        '03:00',
                        '04:00',
                        '05:00',
                        '06:00',
                        '07:00',
                        '08:00',
                        '09:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                        '19:00',
                        '20:00',
                        '21:00',
                        '22:00',
                        '23:00',
                    ]
                } else if (this.startDate !== '' && this.endDate !== '') {
                    let arr = this.timeValue
                    let start = arr[0].getDate()
                    let end = arr[1].getDate()
                    let year = arr[0].getFullYear()
                    let result = this.isLeap(year)
                    if (result == 1) {
                        this.monthData[1].days = 29
                    }
                    let startMonth = arr[0].getMonth() + 1
                    let endMonth = arr[1].getMonth() + 1
                    if (startMonth == endMonth) {
                        let date = this.timeValue || new Date()
                        let month = (date[0]?.getMonth() || date?.getMonth()) + 1
                        if (month < 10) {
                            month = '0' + month
                        }
                        for (let i = start; i < end + 1; i++) {
                            let item = i
                            if (i < 10) {
                                item = '0' + i
                            }
                            item = month + '-' + item
                            this.xdata.push(item)
                        }
                    } else {
                        for (let i = startMonth; i < endMonth + 1; i++) {
                            let month = i
                            if (month < 10) {
                                month = '0' + month
                            }
                            if (i == startMonth) {
                                for (let j = start; j < this.monthData[i - 1].days + 1; j++) {
                                    let item = j
                                    if (j < 10) {
                                        item = '0' + item
                                    }
                                    item = month + '-' + item
                                    this.xdata.push(item)
                                }
                            } else if (i > startMonth && i < endMonth) {
                                for (let j = 1; j < this.monthData[i - 1].days + 1; j++) {
                                    let item = j
                                    if (j < 10) {
                                        item = '0' + item
                                    }
                                    item = month + '-' + item
                                    this.xdata.push(item)
                                }
                            } else if (i == endMonth) {
                                for (let j = 1; j < end + 1; j++) {
                                    let item = j
                                    if (j < 10) {
                                        item = '0' + item
                                    }
                                    item = month + '-' + item
                                    this.xdata.push(item)
                                }
                            }
                        }
                    }
                    this.btnTypeDay = 'btnDark'
                }
            } else if (this.timeType == 3) {
                this.xdata = [
                    '1月',
                    '2月',
                    '3月',
                    '4月',
                    '5月',
                    '6月',
                    '7月',
                    '8月',
                    '9月',
                    '10月',
                    '11月',
                    '12月',
                ]
            } else if (this.timeType == 2) {
                if (data) {
                    if (this.startDate == '' && this.endDate == '') {
                        let date2 = new Date()
                        let month = date2.getMonth() + 1
                        if (month < 10) {
                            month = '0' + month
                        }
                        for (let i = 1; i < data.length + 1; i++) {
                            let item = month + '-' + i
                            this.xdata.push(item)
                        }
                    }
                }
            }
        },
        ceilNumber (number) {
            let bite = 0
            if (number < 10) {
                return 10
            }
            while (number >= 10) {
                number /= 10
                bite += 1
            }
            return Math.ceil(number) * Math.pow(10, bite)
        },
        drawLine1 () {
            const _this = this
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('myChart13'))
            let workList = []
            let restList = []
            let value = ''
            let month = this.dateSpan.slice(5) + '-'
            value = month
            workList = this.barList1
            restList = this.barList2
            let yMax = 0
            for (let i = 0; i < workList.length; i++) {
                const item = workList[i]
                if (item > yMax) {
                    yMax = item
                }
            }
            this.yMax = this.ceilNumber(yMax)
            // 绘制图表
            myChart.setOption(
                {
                    // color: ["#1EE7E7", "#1890FF"],
                    // legend: {
                    //   orient: "horizontal",
                    //   x: "center", //可设定图例在左、右、居中
                    //   y: "bottom", //可设定图例在上、下、居中
                    //   // padding: [0, 0, 25, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                    //   textStyle: { color: "#fff" },
                    //   // data: ["工作时段", "非工作时段"],
                    // },
                    tooltip: {
                        trigger: 'axis',
                    },
                    dataZoom: [
                        {
                            type: 'slider',
                            show: true,
                            height: 3,
                            left: '10%',
                            right: '10%',
                            bottom: 50,
                            handleSize: 12
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        interval: 0,
                        axisLabel: {
                            textStyle: {
                                color: '#fff',
                            },
                            formatter: function (val) {
                                if (_this.timeType == 1 && _this.timeValue.length && _this.formatDate(_this.timeValue[0]) == _this.formatDate(_this.timeValue[1])) {
                                    return val.substring(11, val.length)
                                }
                                return val
                            }
                        },
                        // x轴数据
                        data: this.xdata
                    },
                    yAxis: [
                        {
                            name: '单位:kWh',
                            min: 0,
                            max: this.yMax == 0 ? 10 : this.yMax,
                            interval: this.yMax / 5,
                            axisLabel: {
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed',
                                    color: 'rgba(230,247,255,0.2)',
                                },
                            },
                        },
                    ],
                    grid: {
                        x: 25,
                        x2: 40,
                        y: 75,
                        y2: 70,
                        containLabel: true,
                    },
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            barWidth: 5,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: '#1EE7E7' },
                                    { offset: 1, color: 'rgba(30, 231, 231, 0.35)' },
                                ]),
                            },
                            data: workList,
                        },
                        // {
                        //   name: "非工作时段",
                        //   type: "bar",
                        //   barWidth: 5,
                        //   itemStyle: {
                        //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //       { offset: 0, color: "#1890FF" },
                        //       { offset: 1, color: "rgba(24, 144, 255, 0.35)" },
                        //     ]),
                        //   },
                        //   data: restList,
                        // },
                    ],
                },
                true
            )
            myChart._dom.childNodes[0].childNodes[0].style.width = '59rem'
            myChart._dom.childNodes[0].childNodes[0].style.height = '43rem'
        },
        // 切换年月日的回调
        changeTab (index) {
            this.whichTab = index
            this.timeType = index + 1
            this.showInterval = false
            this.$store.commit('CompareTabIndex', this.timeType)
            this.timeValue = []
            this.$store.commit('CompareTrendDate', this.timeValue)
            this.startDate = ''
            this.endDate = ''
            let value = {
                timeType: this.timeType,
                dateStart: this.startDate,
                dateEnd: this.endDate,
                // dateStart: '',
                // dateEnd: '',
            }
            const now = new Date()
            this.$parent.show(value)
            if (index === 0) {
                this.btnTypeDay = 'btnGreen'
                this.btnTypeMonth = 'btnDark'
                this.btnTypeYear = 'btnDark'
                this.timeValue = [now, now]
                // this.startDate = ''
                // this.endDate = ''
            } else if (index === 1) {
                this.btnTypeDay = 'btnDark'
                this.btnTypeMonth = 'btnGreen'
                this.btnTypeYear = 'btnDark'
                this.timeValue = [now, now]
            } else if (index === 2) {
                this.btnTypeDay = 'btnDark'
                this.btnTypeMonth = 'btnDark'
                this.btnTypeYear = 'btnGreen'
                this.timeValue = now
            }
            setTimeout(() => {
                this.showInterval = true
                this.$nextTick(() => {
                    // this.$refs.lineCharts.init();
                    this.getSelectTime()
                })
            }, 0)
        },
    },
    mounted () {
        // this.init()
    },
    computed: {
        ...mapState({
            getAnalysisTrend: (state) => {
                return state.elecManagement.getAnalysisTrend.data
            },
        }),
    },
    watch: {
        '$store.state.storage.localtionId': {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return
                }
                this.dataForm.localtionId = newVal
                this.getSelectTime()
            },
        },
    },
}
</script>

<style lang="less" scoped>
.dialog_echarts1 {
    .charts {
        width: 100%;
        // width: 59rem;
        height: 43rem;
    }

    .select-time {
        position: absolute;
        top: 1.8rem;
        right: 9rem;
        z-index: 2;
        .time {
            font-size: 1.4rem;
            color: #fff;
        }
        .seleWid {
            width: 15rem;
        }
        .btn1 {
            height: 3rem;
            width: 24rem;
            background-color: #0b375e;
            // border: 0.1rem solid #004579;
            font-size: 1.4rem;
            color: #fff;
            // line-height: 3rem;
            padding: 0;
            margin: 0;
            cursor: pointer;
            float: left;
            margin-right: 0.8rem;
        }

        .dateChoose {
            height: 3rem;
            width: 13.8rem;
            border-radius: 0.2rem;
            overflow: hidden;
            border: 0.1rem solid #004579;
            box-sizing: border-box;
            display: flex;
            flex-wrap: nowrap;
            color: #fff;
            .btnDark {
                height: 3rem;
                width: 4.6rem;
                background-color: #0b375e;
                font-size: 1.4rem;

                padding: 0;
                margin: 0;
                cursor: pointer;
                line-height: 3rem;
                text-align: center;
            }
            .btnGreen {
                height: 3rem;
                width: 4.6rem;
                background-color: #027969;
                font-size: 1.4rem;

                padding: 0;
                margin: 0;
                cursor: pointer;
                line-height: 3rem;
                text-align: center;
            }
        }
    }
}
</style>
<style lang="less">
.dialog_echarts1 {
    .el-input__inner {
        padding: 0;
        text-align: center;
        height: 3rem !important;
        line-height: 3rem !important;
    }

    .el-input--prefix {
        height: 3rem !important;
        .el-input__icon {
            display: none;
        }
    }
    .el-range__icon {
        margin-left: 0 !important;
    }
    .el-range-separator {
        line-height: 3rem !important;
    }
    .el-range__close-icon {
        line-height: 3rem !important;
    }
}
.el-picker-panel__footer {
    background-color: #0b375e !important;
    .el-button {
        background: #0b375e !important;
        color: #fff;
        border-color: #4898dd;
    }
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
</style>
