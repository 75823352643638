<template>
  <div class="app-container">
    <el-form ref="queryForm" class="bar"
             label-width="100px"
             :model="queryParams"
             :inline="true"
    >
        <el-form-item label="元件分组名" prop="groupName">
          <el-input v-model="queryParams.groupName"
                    class="left"
                    placeholder="请输入元件分组名称"
                    clearable
                    @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <!--查询、重置-->
        <el-form-item>
          <el-button class="left" type="primary" plain @click="handleQuery">查询</el-button>
          <el-button class="left" type="primary" plain @click="resetQuery">重置</el-button>
        </el-form-item>
        <!--新增、批量删除-->
        <el-form-item>
          <el-button class="right" type="primary" plain @click="handleAdd">新增</el-button>
          <el-button class="right" type="primary" plain :disabled="multiple" @click="handleDelete">批量删除</el-button>
        </el-form-item>
    </el-form>
    <div class="table">
      <!-- table -->
      <el-table v-loading="loading"
                :data="groupList"
                @selection-change="handleSelectionChange"
      >
        <el-table-column align="center" type="selection" width="55"/>
        <el-table-column align="center" label="元件分组" prop="groupName" />
        <el-table-column align="center" label="顺序" prop="groupSort" />
        <el-table-column align="center" label="备注" prop="remark" >
          <template slot-scope="{row}">
            <span>{{row.remark ? row.remark : '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否启用" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <span v-if="scope.row.isEnable" style="color: #03d9bc; margin-right: 1rem;">启用</span>
            <span v-else style="color: #516a65; margin-right: 1rem;">停用</span>
            <el-switch
                    v-model="scope.row.isEnable"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#027969"
                    inactive-color="#6E798C"
                    class="switch"
                    @change="toggleEnable(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <div class="caozuobtn-box">
              <div class="caozuobtn" @click="handleUpdate(scope.row)">编辑</div>
              <div class="caozuobtn" @click="handleDelete(scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryParams.pageNum"
                     :page-size="queryParams.pageSize"
                     :total="total"
                     :page-sizes="[10, 20, 40, 80]"
                     layout="total, sizes, prev, pager, next, jumper"
                     style="float: right; padding: 2rem;"/>
    </div>

    <!-- 添加或修改 -->
    <el-dialog class="add-edit-dialog"
               append-to-body v-dialogDrag
               width="500px"
               :close-on-click-modal="false"
               :visible.sync="open">
      <template slot="title">
        <div class="dialog-title">{{ title }}</div>
      </template>
      <el-form ref="form" :model="form" :rules="rules" label-width="110px">
        <el-form-item label="分组名称" prop="groupName">
          <el-input v-model="form.groupName" placeholder="请输入元件分组名" />
        </el-form-item>
        <el-form-item label="分组序号" prop="groupSort">
          <el-input-number v-model="form.groupSort"
                           :min="0"
                           style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 8 }"
            placeholder="请输入备注"
          />
        </el-form-item>
        <el-form-item label="是否启用" prop="isEnable">
          <el-select v-model="form.isEnable"
                     placeholder="请选择启用状态"
                     clearable
                     style="width: 100%"
          >
            <el-option label="启用" :value="1" />
            <el-option label="不启用" :value="0" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pageGroup,
         getGroupById,
         addGroup,
         updateGroup,
         delGroup,
       } from "@/api/diagram/group";
import DiagramMixin from '../diagram-mixin';

export default {
  name: "Group",
  mixins: [ DiagramMixin ],
  data() {
    return {
      // 组件定义组表格数据
      groupList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        groupName: null,
      },
      form: {
        groupCode: null,
        groupName: null,
        groupSort: null,
        groupIcon: null,
        isEnable: null,
      },
      // 表单校验
      rules: {
        groupName: [{ required: true, message: "分组名称不能为空", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.groupId);
      this.multiple = !selection.length;
    },
    getList() {
      this.loading = true;
      pageGroup(this.queryParams).then((response) => {
        this.groupList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    handleAdd() {
      this.resetForm();
      this.form.isEnable = 1;
      this.form.groupSort = this.total + 1;
      this.open = true;
      this.title = "添加元件分组";
    },
    handleUpdate(row) {
      this.resetForm();
      const groupId = row.groupId || this.ids;
      getGroupById(groupId).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = "修改元件分组";
      });
    },
    toggleEnable(row) {
      let { groupId, isEnable} = row;
      updateGroup({ groupId, isEnable }).then(res => {
        if(res.code === 200){
          this.$message({ message: "修改成功！", type: "success" });
        }
      });
    },
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.groupId) {
            updateGroup(this.form).then((response) => {
              this.$message({ message: "修改成功！", type: "success" });
              this.open = false;
              this.getList();
            });
          } else {
            addGroup(this.form).then((response) => {
              this.$message({ message: "新增成功！", type: "success" });
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    handleDelete(row) {
      const groupIds = row.groupId || this.ids;
      this.$confirm("是否确认删除选中的分组?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
      }).then(() => {
          delGroup(groupIds);
      }).then(() => {
        this.handleQuery();
        this.$message({ message: "删除成功！", type: "success" });
      }).catch(() => {});
    },

  },
  created() {
    this.getList();
  },
}
</script>
<style lang="less" scoped>

.app-container {
  .bar {
    height: 64px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    line-height: 64px;
    background-color: #0d3050;

    button {
      height: 32px;
      width: 80px;
      border-radius: 2px;
      padding: 0;
      line-height: 32px;
      color: #fefefd;
      border: none;
      margin-top: 16px;

      &:first-child {
        background-color: #027969;
      }

      &:not(:first-child) {
        background-color: #0c3761;
      }
    }

    ::v-deep .el-form-item__label {
      color: #abb8cf !important;
      font-weight: bold;
      margin-left: 2rem;
      font-size: 15px;
    }

    ::v-deep .left.el-input {
      width: 300px;
      margin-top: 12px;
    }

    .el-form-item:last-child {
      float: right;
    }

    .right {
      float: right;
      margin-right: 1rem;
    }
  }

  .table {
    margin-top: 18px;

    .bgc {
      height: 525px;
      overflow-y: scroll;
    }

    ::v-deep .el-table__row {
      background-color: #0c375e;
      color: #dbe1e7;

      &:hover {
        background-color: #0c375e;
      }

      .el-switch__label.is-active {
        color: #03d9bc;
      }

    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: #0c375e;
    }

    .caozuobtn-box {
      display: flex;
      justify-content: center;
    }

    .caozuobtn {
      cursor: pointer;
      position: static;
      float: left;
      font-size: 14px;
      line-height: 20px;
      color: #03d9bc;
      margin: 0 1rem;
    }
  }

  /*
   * 修改el-dialog必须在非scoped环境下，否则不生效
  .dialog-title{
    height: 5.4rem;
    line-height: 5.4rem;
    padding-left: 2.4rem;
    font-size: 1.6rem;
    background: linear-gradient(225deg,
    rgba(15, 36, 55, 0.0001) 0.03%,
    rgba(0, 115, 222, 0.7) 99.9%) !important;
  }*/
}
</style>
