<template>
  <div class="TypeSetting">
    <!-- 顶部按钮############################################################ -->
      <button @click="addType" class="topBtn">新增</button>
    <!-- 表格位置########################################################## -->
         <div class="table">
              <el-table :data="tableData" stripe:false height="57.8rem" 
                        border:false style="width: 100%"
                        :header-cell-style="headerClass"                    
                        :cell-style="cellStyle">
                <el-table-column  label="序号"  min-width="9%">
                    <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
                  </el-table-column> 
                <el-table-column class="tab" prop="typeName" label="作业标准类型" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="coding" label="编码" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="remark" label="备注" min-width="15%"  :show-overflow-tooltip="true"> </el-table-column>


                <!-- 操作部分 -->
                <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
          </div>
            <!-- 分页器部分 -->
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumForPagenation"
              >
              </el-pagination>
            </div>

    <!-- 弹层 -->
    <DialogAddType ref="DialogRef"/>
  </div>
</template>

<script>
import DialogAddType from './components/DialogAddType.vue'
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";

export default {
  name: "TypeSetting",
  mixins: [tableBg],
  components:{DialogAddType},
  data() {
    return {
           
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 双向绑定的搜索数据
      searchValue: "",  
      // 表格数据
      tableData: [],
    
    };
  },

  methods: {
    // 刷新页面
    refreshThePage() {
      this.$store.dispatch("getRepairWorkStandardTypePageData", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,        
        })
        .then(() => {       
          this.tableData = this.repairWorkStandardTypePageData;
          this.totalNumForPagenation = this.repairWorkStandardTypeTotal;  
        });
    },
    


    // 点击搜索或者回车
    search() {
      this.currentPage = "1";
      this.refreshThePage();
    },
    //点击新增
    addType(){
      this.$refs.DialogRef.show();
    },
    //点击了编辑按钮
    handleEdit(index) {
      this.$refs.DialogRef.showForEdit(this.repairWorkStandardTypePageData[index]);
    },
    // 点击了删除按钮
    handleDelete(index) {
      const daleteId = this.repairWorkStandardTypePageData[index].id;
      this.$confirm("您确定要执行删除操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store.dispatch("deleteRepairWorkStandardType", daleteId).then(() => {
          if (this.ifDeletePageDataTypeSuccess === 0) {
            this.$message({ message: "删除成功！", type: "success" });
            this.refreshThePage();
          } else if (this.ifDeletePageDataTypeSuccess === -1) {
            this.$message({ message:this.ifDeletePageDataTypeError, type: "warning" });
          }
        });
      });
    },
   
    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = "1";
      this.pageSize = val;
      this.refreshThePage();
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val;
      this.refreshThePage();
    },
  
  },

  mounted() {
    this.refreshThePage();
  },

  computed: {
    ...mapState({
      // 分页数据
      repairWorkStandardTypePageData: state => { return state.repair.repairWorkStandardTypePageData.data.records;},
      // 页面总数
      repairWorkStandardTypeTotal: state => { return state.repair.repairWorkStandardTypePageData.data.total;},
      // 删除是否成功
      ifDeletePageDataTypeSuccess:state => { return state.repair.ifDeletePageDataTypeSuccess.errorCode},
      ifDeletePageDataTypeError:state => { return state.repair.ifDeletePageDataTypeSuccess.message},
     
    })
  }
};
</script>

<style lang='less' scoped>
  .TypeSetting{
    // 顶部按钮位置
    .topBtn{
      height: 3.2rem;
      width: 7.4rem;
      line-height: 3.2rem;
      border-radius: 0.2rem;
      background-color: #027969;
      border: none;
      color: #fff;
      cursor: pointer;
      margin: 1.2rem 2.4rem 1.2rem 161.4rem;
}
// 表格位置
 .table {
    height: 57.2rem;
    width: 166.4rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }

// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}


  }

</style>