<template>
  <div class="computedPart">
    <div class="radioPlace">
      <div class="title-inline">
        <span>工作时段:</span>
      </div>
      <div class="place-content">
        <el-time-select
          size="mini"
          class="morning"
          @change="selectTime1()"
          v-model="WORKTIME1START"
          :picker-options="{
            start: '08:00',
            step: '00:15',
            end: '11:00',
          }"
          placeholder="选择时间"
        ></el-time-select>
        <p class="p-inline">-</p>
        <el-time-select
          size="mini"
          class="noon"
          @change="selectTime2()"
          v-model="WORKTIME1END"
          :picker-options="{
            start: '11:00',
            step: '00:15',
            end: '12:00',
          }"
          placeholder="选择时间"
        ></el-time-select>
        <el-time-select
          size="mini"
          class="afternoon"
          @change="selectTime3()"
          v-model="WORKTIME2START"
          :picker-options="{
            start: '13:30',
            step: '00:15',
            end: '16:00',
          }"
          placeholder="选择时间"
        ></el-time-select>
        <p class="p-inline1">-</p>
        <el-time-select
          size="mini"
          class="night"
          @change="selectTime4()"
          v-model="WORKTIME2END"
          :picker-options="{
            start: '16:00',
            step: '00:15',
            end: '18:00',
          }"
          placeholder="选择时间"
        ></el-time-select>
      </div>
      <div class="rest-time">
        <div class="rest-title">
          <span>休息时段:</span>
        </div>
        <div class="rest-content">
          <el-input disabled class="rest-ipt" size="mini" v-model="input" placeholder="工作时段以外的时间"></el-input>
        </div>
      </div>
      <div class="work-or-week">
        <div class="rest-title">
          <span>周中:</span>
        </div>
        <div class="rest-content">
          <el-checkbox-group size="mini" v-model="WORKDAY">
            <el-checkbox-button  @change="changeTime(item.id)" v-for="item in interestList" :key="item.id" :label="item.id">{{
              item.course
            }}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
      <div class="week-time">
        <div class="rest-title">
          <span>周末:</span>
        </div>
        <div class="rest-content">
          <el-input disabled class="rest-ipt" size="mini" v-model="input" placeholder="周中以外的时间"></el-input>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TimePart",
  data() {
    return {
      WORKDAY: [],
      interestList: [
        {
          id: "1",
          course: "周一",
        },
        {
          id: "2",
          course: "周二",
        },
        {
          id: "3",
          course: "周三",
        },
        {
          id: "4",
          course: "周四",
        },
        {
          id: "5",
          course: "周五",
        },
        {
          id: "6",
          course: "周六",
        },
        {
          id: "7",
          course: "周日",
        },
      ],
      input: "",
      WORKTIME1START: "",
      WORKTIME1END: "",
      WORKTIME2START: "",
      WORKTIME2END: "",
      elecPiceList: {},
      timeout1: null,
    };
  },
  mounted() {
    // this.getEnergyNum();
  },
  methods: {
    //获取对应的信息
    findInfo(key){
      return this.getGeneralSetting.find((item => item.name === key))
    },
    getEnergyNum() {
      this.$store.dispatch("getGeneralSetting", {
          generator: "ENERGYMANAGEMENT",
          customerId: this.$store.state.storage.customerId,
        }).then(() => {
        this.elecPiceList = this.getGeneralSetting;
        this.WORKTIME1START = this.findInfo('WORKTIME1START').value;
        this.WORKTIME1END = this.findInfo('WORKTIME1END').value;
        this.WORKTIME2START = this.findInfo('WORKTIME2START').value;
        this.WORKTIME2END =this.findInfo('WORKTIME2END').value;
        let text = "";
        text = this.findInfo('WORKDAY').value;
        // text = text.replace(/,/g, "");
        var array = text.split("");
        this.WORKDAY = array;
      });
    },
    selectTime1() {
      let id = this.findInfo('WORKTIME1START').id;
      let value = {
        value: this.WORKTIME1START,
      };
      this.$store
        .dispatch("putGeneral", {
          id: id,
          data: value,
        })
        .then(() => {
          if (this.putGeneral) {
            this.$message({ message: "编辑成功！", type: "success" });
          } else {
            this.$message({ message: "编辑失败，请重试!", type: "warning" });
          }
        });
    },
    selectTime2() {
      let id = this.findInfo('WORKTIME1END').id;
      let value = {
        value: this.WORKTIME1END,
      };
      this.$store
        .dispatch("putGeneral", {
          id: id,
          data: value,
        })
        .then(() => {
          if (this.putGeneral) {
            this.$message({ message: "编辑成功！", type: "success" });
          } else {
            this.$message({ message: "编辑失败，请重试!", type: "warning" });
          }
        });
    },
    selectTime3() {
      let id = this.findInfo('WORKTIME2START').id;
      let value = {
        value: this.WORKTIME2START,
      };
      this.$store
        .dispatch("putGeneral", {
          id: id,
          data: value,
        })
        .then(() => {
          if (this.putGeneral) {
            this.$message({ message: "编辑成功！", type: "success" });
          } else {
            this.$message({ message: "编辑失败，请重试!", type: "warning" });
          }
        });
    },
    selectTime4() {
      let id = this.findInfo('WORKTIME2END').id;
      let value = {
        value: this.WORKTIME2END,
      };
      this.$store
        .dispatch("putGeneral", {
          id: id,
          data: value,
        })
        .then(() => {
          if (this.putGeneral) {
            this.$message({ message: "编辑成功！", type: "success" });
          } else {
            this.$message({ message: "编辑失败，请重试!", type: "warning" });
          }
        });
    },
    changeTime(e) {
      // console.log(e);
      let id = this.findInfo('WORKDAY').id;
      // console.log(this.WORKDAY);
      let value = {
        value: this.WORKDAY.join(""),
      };
      this.$store
        .dispatch("putGeneral", {
          id: id,
          data: value,
        })
        .then(() => {
          if (this.putGeneral) {
            this.$message({ message: "编辑成功！", type: "success" });
          } else {
            this.$message({ message: "编辑失败，请重试!", type: "warning" });
          }
        });
    },
  },
  computed: {
    ...mapState({
      getGeneralSetting: (state) => {
        return state.elecManagement.getGeneralSetting.data;
      },
      putGeneral: (state) => {
        return state.elecManagement.putGeneral.data;
      },
    }),
  },
  watch: {
    '$store.state.storage.customerId': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return
        }
        this.getEnergyNum();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.computedPart {
  height: 81.7rem;
  // width: 142.8rem;
  background-color: #0d3050;

  .radioPlace {
    height: 2.2rem;
    width: 26rem;
    margin: 2.8rem 0 2.8rem 0;
    position: absolute;
    left: 2.4rem;
    top: 0.8rem;
    .title-inline {
      position: absolute;
      top: 0.5rem;
      font-size: 1.4rem;
      color: #ffffff;
    }
    .place-content {
      position: relative;
      left: 1.5rem;
      .morning {
        position: absolute;
        left: 7rem;
        width: 13rem;
      }
      .p-inline {
        position: absolute;
        left: 22.5rem;
        top: 0.6rem;
        font-size: 1.4rem;
        color: #ffffff;
      }
      .noon {
        position: absolute;
        width: 13rem;
        left: 26rem;
      }

      .afternoon {
        position: absolute;
        left: 43rem;
        width: 13rem;
      }
      .p-inline1 {
        position: absolute;
        left: 58.5rem;
        top: 0.6rem;
        font-size: 1.4rem;
        color: #ffffff;
      }
      .night {
        position: absolute;
        width: 13rem;
        left: 62rem;
      }
    }
    .rest-time {
      .rest-title {
        position: absolute;
        top: 8rem;
        span {
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #ffffff;
        }
      }
      .rest-content {
        position: absolute;
        top: 7.5rem;
        left: 8.5rem;
        .rest-ipt {
          width: 19.4rem;
        }
      }
    }
    .work-or-week {
      .rest-title {
        position: absolute;
        top: 16rem;
        span {
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #ffffff;
        }
      }
      .rest-content {
        width: 94rem;
        position: absolute;
        top: 15.2rem;
        left: 8rem;
      }
    }
    .week-time {
      .rest-title {
        position: absolute;
        top: 24rem;
        span {
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: #ffffff;
        }
      }
      .rest-content {
        position: absolute;
        top: 23.5rem;
        left: 8rem;
        .rest-ipt {
          width: 19.4rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.computedPart {
  .el-checkbox-button__inner {
    border: 1px solid #03d9bc;
    transition: none;
    background: none;
  }
  .el-input.is-disabled .el-input__inner {
    background-color: #0d3050;
    border-color: #0d3050;
    color: #0d3050;
    cursor: not-allowed;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #fff;
    background-color: #03d9bc;
    border-color: #03d9bc;
    box-shadow: -1px 0 0 0 #8cc5ff;
  }
  .el-checkbox-button:first-child .el-checkbox-button__inner {
    border-left: 1px solid #03d9bc;
    border-radius: 4px 0 0 4px;
    box-shadow: none !important;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #fff;
    background-color: #03d9bc;
    border-color: #03d9bc;
    box-shadow: -1px 0 0 0 #03d9bc;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #fff;
    background-color: #03d9bc;
    border-color: #03d9bc;
    box-shadow: -1px 0 0 0 #03d9bc;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #fff;
    background-color: #03d9bc;
    border-color: #03d9bc;
    box-shadow: -1px 0 0 0 #03d9bc;
  }
  .el-checkbox-button__inner:hover {
    color: #03d9bc;
  }

  .el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: #03d9bc;
  }
}
</style>
