<template>
  <div>
    <div class="echartSingel3" id="myPNGplace">
      <div v-if="loading" class="loading-mask">
        <el-spinner size="large"></el-spinner>
      </div>
      <!-- 图表 -->
      <div ref="myChart" class="charts" id="myChart12"></div>
      <!-- 增加显示的位置 -->
      <div class="addFloor">
        <div class="buttons">
          <div class="item" v-for="(item, index) in floorBtns" :key="index">
            {{ item.name }}
            <span class="deleteItem" @click="deleteFloor(item.id)">
              <i class="el-dialog__close el-icon el-icon-close"></i>
            </span>
          </div>
        </div>
        <div class="addBtn" @click="addFloor()">+</div>
        <el-dialog
          title="分时统计"
          :visible.sync="addFloorShow"
          :width="'40rem'"
          :height="'auto'"
          class="floors"
          :modal-append-to-body="false"
          :before-close="handleClose"
          :close-on-click-modal="false"
          center
        >
          <div class="treeTable">
            <div class="scrollable-tree">
              <el-tree
                :indent="16"
                :check-strictly="true"
                :check-on-click-node="true"
                node-key="id"
                :expand-on-click-node="false"
                class="filter-tree elTree"
                :data="floorInfo"
                :props="props"
                :default-checked-keys="defaultChecked"
                default-expand-all
                ref="tree"
                show-checkbox
                :destroy-on-close="true"
                :render-content="renderContent"
              ></el-tree>
            </div>
          </div>
          <span slot="footer" >
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="confirmFloors" class="btnConfirm">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <!-- 图表的顶栏 -->
      <div class="tabBarPlace1">
        <div class="tabBarTop">
          <div class="word">分时统计</div>
          <el-date-picker
            v-show="btnTypeDay == 'btnGreen'"
            type="daterange"
            @change="timeChange"
            @blur="timeBlur"
            @focus="timeFocus"
            v-model="timeValue"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="btn1"
          ></el-date-picker>
          <el-date-picker
            v-show="btnTypeMonth == 'btnGreen'"
            type="monthrange"
            @change="timeChange"
            @blur="timeBlur"
            @focus="timeFocus"
            v-model="timeValue"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            class="btn1"
          ></el-date-picker>
          <el-date-picker
            v-show="btnTypeYear == 'btnGreen'"
            type="year"
            @change="timeChange"
            @blur="timeBlur"
            @focus="timeFocus"
            v-model="timeValue"
            placeholder="选择年份 "
            class="btn1 btnTypeYear"
          ></el-date-picker>
          <div class="dateChoose">
            <div :class="btnTypeDay" @click="changeTab(0)">日</div>
            <div :class="btnTypeMonth" @click="changeTab(1)">月</div>
            <div :class="btnTypeYear" @click="changeTab(2)">年</div>
          </div>
          <!-- 下载的下拉框 -->
          <el-dropdown
            @command="downLoadPng"
            :disabled="downLoadDisable"
            class="img1"
            style="z-index: 9;"
          >
            <span class="el-dropdown-link">
              <img
                style="width: 1.8rem; height: 1.8rem;"
                src="@/assets/ElecManagement/Download.png"
                alt=""
              />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['pic', '分时统计']">
                图片下载
              </el-dropdown-item>
              <el-dropdown-item style="width:10rem" :command="['excel', '分时统计']">
                Excel下载
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="tabBarBottom"></div>
      </div>
    </div>
  </div>
</template>

<script>
import EchartAreaMap from './EchartAreaMap.vue'
import { mapState } from 'vuex'
import html2canvas from 'html2canvas'
import * as echarts from 'echarts'
import { exportEnergyAnalyzeByTimeReport } from '@/api/repair'
import { formatTime } from '@/utils/util.js'
export default {
  name: 'MymapNo1',
  props: {
    name: {
      type: String,
      default: () => '',
    },
  },
  // components: { EchartAreaMap },

  data() {
    return {
      // 按钮的样式
      btnTypeDay: 'btnGreen',
      btnTypeMonth: 'btnDark',
      btnTypeYear: 'btnDark',

      whichTab: 0,
      optionData: {},
      showInterval: true,
      dataSpan: '2021-12',
      dataForm: {
        locationId: '',
        startTime: '',
        buildId: '',
      },
      // type的值 1日 2月 3年
      timeType: '1',
      startDate: '',
      endDate: '',
      xdata: [],
      ydata: [],
      timeValue: [new Date(), new Date()],
      floorInfo: [],
      locationIds: [],
      floorBtns: [],
      addFloorShow: false,
      props: {
        label: 'name',
        children: 'children',
        id: "id"
      },
      y: [],
      defaultChecked: [],
      monthData: [
        {
          month: 1,
          days: 31,
        },
        {
          month: 2,
          days: 28,
        },
        {
          month: 3,
          days: 31,
        },
        {
          month: 4,
          days: 30,
        },
        {
          month: 5,
          days: 31,
        },
        {
          month: 6,
          days: 30,
        },
        {
          month: 7,
          days: 31,
        },
        {
          month: 8,
          days: 31,
        },
        {
          month: 9,
          days: 30,
        },
        {
          month: 10,
          days: 31,
        },
        {
          month: 11,
          days: 30,
        },
        {
          month: 1,
          days: 31,
        },
      ],
      yMax: 0,
      downLoadDisable: false,
      loading: false,
    }
  },

  methods: {
    resizeChart() {
      setTimeout(() => {
        const myChart = this.$echarts.init(document.getElementById('myChart12'))
        const chartWidth = document.getElementById('myChart12').offsetWidth
        const chartHeight = document.getElementById('myChart12').offsetHeight
        myChart.resize({ width: chartWidth, height: chartHeight })
      }, 500)
    },
    // 转化dom节点为图片下载
    downLoadPng(val) {
      if (val[0] == 'excel') {
        if (this.startDate && this.endDate) {
          this.startDate = this.formatDate(this.startDate)
          this.endDate = this.formatDate(this.endDate)
        }
        if (this.locationIds.toString()) {
          let value = {
            type: this.timeType,
            locationIds: this.locationIds.toString(),
            dateStart: this.startDate,
            dateEnd: this.endDate,
          }
          // this.$store.dispatch("exportAnalysisByTime", value);
          exportEnergyAnalyzeByTimeReport(value).then((response) => {
            var blob = new Blob([response], {
              type: 'application/vnd.ms-excel,charset=utf-8',
            }) //创建一个blob对象
            var a = document.createElement('a') //创建一个<a></a>标签
            a.href = URL.createObjectURL(blob) // response is a blob
            let format =
              this.timeType == 1
                ? 'yyyy-MM-dd'
                : this.timeType == 2
                ? 'yyyy-MM'
                : 'yyyy'
            let name = this.timeValue[1]
              ? `${formatTime(this.timeValue[0], format)}-${formatTime(
                  this.timeValue[1],
                  format,
                )}${this.name}分时统计.xls`
              : `${formatTime(this.timeValue, format)}${this.name}分时统计.xls`
            a.download = name //文件名称
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
            a.remove()
          })
        }else{
            this.$message.warning('请选择位置')
        }
      } else {
        var node = document.getElementById('myPNGplace')
        window.pageYoffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        var width = node.offsetWidth
        var height = node.offsetHeight
        var scale = 4
        html2canvas(node, {
          width: width,
          heigth: height,
          backgroundColor: '#113455',
          dpi: window.devicePixelRatio * 4,
          scale: scale,
          X: 0,
          Y: 0,
          scrollX: -3,
          scrollY: 0,
          useCORS: true,
          allowTaint: true,
        }).then((canvas) => {
          var url = canvas.toDataURL()
          var a = document.createElement('a')
          let format =
            this.timeType == 1
              ? 'yyyy-MM-dd'
              : this.timeType == 2
              ? 'yyyy-MM'
              : 'yyyy'
          let name = this.timeValue[1]
            ? `${formatTime(this.timeValue[0], format)}-${formatTime(
                this.timeValue[1],
                format,
              )}${this.name}${val[1]}`
            : `${formatTime(this.timeValue, format)}${this.name}${val[1]}`
          a.download = name
          var event = new MouseEvent('click')
          a.href = url
          a.dispatchEvent(event)
        })
      }
    },

    changeTab(index) {
      this.whichTab = index
      this.showInterval = false
      this.timeType = index + 1
      this.timeValue = []
      this.startDate = ''
      this.endDate = ''
      const now = new Date()
      if (index === 0) {
        this.btnTypeDay = 'btnGreen'
        this.btnTypeMonth = 'btnDark'
        this.btnTypeYear = 'btnDark'
        this.startDate = ''
        this.endDate = ''
        this.timeValue = [now, now]
      } else if (index === 1) {
        this.btnTypeDay = 'btnDark'
        this.btnTypeMonth = 'btnGreen'
        this.btnTypeYear = 'btnDark'
        this.timeValue = [now, now]
      } else if (index === 2) {
        this.btnTypeDay = 'btnDark'
        this.btnTypeMonth = 'btnDark'
        this.btnTypeYear = 'btnGreen'
        this.timeValue = now
      }
      setTimeout(() => {
        this.showInterval = true
        this.$nextTick(() => {
          // this.$refs.lineCharts.init();
          this.getSelectTime()
        })
      }, 0)
    },
    timeFocus() {
      // this.timeValue = "";
    },
    timeBlur() {},
    timeChange() {
      let arr = this.timeValue
      if (arr.length == 2) {
        if (arr[1] == '') {
          this.$message({
            type: 'warning',
            message: '您刚才只选择了开始时间，请重新选择！',
          })
        } else {
          this.startDate = arr[0]
          this.endDate = arr[1]
          this.getSelectTime()
        }
      } else if (arr.length == 1) {
        this.$message({
          type: 'warning',
          message: '请重新选择起止时间！',
        })
      } else if (arr.length > 2) {
        this.$message({
          type: 'warning',
          message: '请只选择两个时间！',
        })
      } else {
        this.startDate = this.timeValue
        this.getSelectTime()
      }
    },
    isLeap(year) {
      if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
        return 1
      }
      return 0
    },
    getXdata(data) {
      this.xdata = []
      if (this.timeType == 1) {
        if (this.startDate == '' && this.endDate == '') {
          //默认为当天
          let nowDate = new Date()
          let nowHours = nowDate.getHours()
          for (let i = 0; i < nowHours; i++) {
            let item = i
            if (i < 10) {
              item = '0' + i
            }
            this.xdata.push(`${item}:00`)
          }
          // this.xdata = [
          //   "00:00",
          //   "01:00",
          //   "02:00",
          //   "03:00",
          //   "04:00",
          //   "05:00",
          //   "06:00",
          //   "07:00",
          //   "08:00",
          //   "09:00",
          //   "10:00",
          //   "11:00",
          //   "12:00",
          //   "13:00",
          //   "14:00",
          //   "15:00",
          //   "16:00",
          //   "17:00",
          //   "18:00",
          //   "19:00",
          //   "20:00",
          //   "21:00",
          //   "22:00",
          //   "23:00",
          // ];
        } else if (this.startDate !== '' && this.endDate !== '') {
          let arr = this.timeValue
          let start = arr[0].getDate()
          let end = arr[1].getDate()
          let year = arr[0].getFullYear()
          let result = this.isLeap(year)
          if (result == 1) {
            this.monthData[1].days = 29
          }
          let startMonth = arr[0].getMonth() + 1
          let endMonth = arr[1].getMonth() + 1
          //开始时间和结束时间相同
          if (this.startDate === this.endDate) {
            let nowDate = new Date()
            let nowDay = nowDate.getDate()
            let nowHours = nowDate.getHours()
            //同一天
            let arrs = 24
            if (nowDay === start) {
              arrs = nowHours
            }
            for (let i = 0; i < arrs; i++) {
              let item = i
              if (i < 10) {
                item = '0' + i
              }
              // item = month + "-" + item;
              this.xdata.push(`${item}:00`)
            }
          } else if (startMonth == endMonth) {
            //开始月份和结束月份相同
            let date = this.timeValue || new Date()
            let month = (date[0]?.getMonth() || date?.getMonth()) + 1
            if (month < 10) {
              month = '0' + month
            }

            for (let i = start; i < end + 1; i++) {
              let item = i
              if (i < 10) {
                item = '0' + i
              }
              item = month + '-' + item
              this.xdata.push(item)
            }
          } else {
            for (let i = startMonth; i < endMonth + 1; i++) {
              let month = i
              if (month < 10) {
                month = '0' + month
              }
              if (i == startMonth) {
                for (let j = start; j < this.monthData[i - 1].days + 1; j++) {
                  let item = j
                  if (j < 10) {
                    item = '0' + item
                  }
                  item = month + '-' + item
                  this.xdata.push(item)
                }
              } else if (i > startMonth && i < endMonth) {
                for (let j = 1; j < this.monthData[i - 1].days + 1; j++) {
                  let item = j
                  if (j < 10) {
                    item = '0' + item
                  }
                  item = month + '-' + item
                  this.xdata.push(item)
                }
              } else if (i == endMonth) {
                for (let j = 1; j < end + 1; j++) {
                  let item = j
                  if (j < 10) {
                    item = '0' + item
                  }
                  item = month + '-' + item
                  this.xdata.push(item)
                }
              }
            }
          }
          this.btnTypeDay = 'btnDark'
        }
      } else if (this.timeType == 3) {
        this.xdata = [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月',
        ]
      } else if (this.timeType == 2) {
        if (data) {
          if (this.startDate == '' && this.endDate == '') {
            let date = new Date()
            let month = date.getMonth() + 1
            if (month < 10) {
              month = '0' + month
            }
            for (let i = 1; i < data.length + 1; i++) {
              let item = month + '-' + i
              this.xdata.push(item)
            }
          }
        }
      }
    },
    init() {
      this.getSelectTime()
    },
    getThisMonth() {
      let date = new Date()
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      const time = y + '-' + m
      this.dateSpan = time
    },
    selectDate(dateData) {
      let date = new Date(dateData)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      const time = y + '-' + m
      this.dateSpan = time
      this.getSelectTime()
    },
    formatDate(date) {
      if (date.getFullYear) {
        let y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = date.getDate()
        d = d < 10 ? '0' + d : d
        return y + '-' + m + '-' + d
      } else {
        return date
      }
    },
    // 销毁echarts
    dispose() {
      this.$echarts.dispose(this.$refs.myChart)
    },
    getSelectTime() {
      if (this.startDate && this.endDate) {
        this.startDate = this.formatDate(this.startDate)
        this.endDate = this.formatDate(this.endDate)
      }
      if (this.timeType == 2) {
        this.startDate = this.formatDate(this.startDate).substring(0, 7)
        this.endDate = this.formatDate(this.endDate).substring(0, 7)
      }
      if (this.timeType == 3) {
        this.startDate = this.formatDate(this.startDate).substring(0, 4)
      }
      this.getAnalysis = []
      this.loading = true
      this.$store
        .dispatch('getAnalysis', {
          type: this.timeType,
          locationId: this.locationIds.toString(),
          dateStart: this.startDate,
          dateEnd: this.endDate,
        })
        .then((res) => {
          // this.y = this.getAnalysis;
          if (this.$store.state.elecManagement.getAnalysis.errorCode == 0) {
            this.ydata = this.$store.state.elecManagement.getAnalysis.data
            if (this.ydata[0].data !== null) {
              this.yMax = 1
              this.barList1 = this.ydata[0].data
              //   this.getXdata(this.barList1)
              this.xdata = this.ydata[0].xdata
              this.drawLine1()
            } else {
              this.yMax = 0
              this.xdata = [
                '00:00',
                '01:00',
                '02:00',
                '03:00',
                '04:00',
                '05:00',
                '06:00',
                '07:00',
                '08:00',
                '09:00',
                '10:00',
                '11:00',
                '12:00',
                '13:00',
                '14:00',
                '15:00',
                '16:00',
                '17:00',
                '18:00',
                '19:00',
                '20:00',
                '21:00',
                '22:00',
                '23:00',
              ]
              this.ydata = []
              this.drawLine1()
            }
          } else {
            this.yMax = 0
            this.xdata = [
              '00:00',
              '01:00',
              '02:00',
              '03:00',
              '04:00',
              '05:00',
              '06:00',
              '07:00',
              '08:00',
              '09:00',
              '10:00',
              '11:00',
              '12:00',
              '13:00',
              '14:00',
              '15:00',
              '16:00',
              '17:00',
              '18:00',
              '19:00',
              '20:00',
              '21:00',
              '22:00',
              '23:00',
            ]
            this.ydata = []
            this.drawLine1()
          }
        }).finally(() => {
          this.loading = false
        })
    },
    ceilNumber(number) {
      let bite = 0
      if (number < 10) {
        return 10
      }
      while (number >= 10) {
        number /= 10
        bite += 1
      }
      return Math.ceil(number) * Math.pow(10, bite)
    },
    drawLine1() {
      let myChart = this.$echarts.init(document.getElementById('myChart12'))
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById('myChart12'))
      }
      let workList = []
      // 当交互时，需要改变ydata的值
      let seriesData = []
      let iconData = []
      if (this.ydata.length == 1) {
        let obj = {
          name: this.ydata[0].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#2276FC',
          },
          data: this.ydata[0].data || [],
        }
        seriesData.push(obj)
        let yMax = 0
        for (let i = 0; i < seriesData.length; i++) {
          const item = seriesData[i].data
          for (let j = 0; j < item.length; j++) {
            if (item[j] > yMax) {
              yMax = item[j]
            }
          }
        }
        // console.log(seriesData, yMax);
        this.yMax = this.ceilNumber(yMax)
        iconData = ['#2276FC']
      } else if (this.ydata.length == 2) {
        let obj = {
          name: this.ydata[0].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#2276FC',
          },
          data: this.ydata[0].data || [],
        }
        let obj1 = {
          name: this.ydata[1].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#FFCF5F',
          },
          data: this.ydata[1].data || [],
        }
        seriesData.push(obj)
        seriesData.push(obj1)
        let yMax = 0
        for (let i = 0; i < seriesData.length; i++) {
          const item = seriesData[i].data
          for (let j = 0; j < item.length; j++) {
            if (item[j] > yMax) {
              yMax = item[j]
            }
          }
        }
        // console.log(seriesData, yMax);
        this.yMax = this.ceilNumber(yMax)
        iconData = ['#2276FC', '#FFCF5F']
      } else if (this.ydata.length == 3) {
        let obj = {
          name: this.ydata[0].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#2276FC',
          },
          data: this.ydata[0].data || [],
        }
        let obj1 = {
          name: this.ydata[1].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#FFCF5F',
          },
          data: this.ydata[1].data || [],
        }
        let obj2 = {
          name: this.ydata[2].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#5FD5EC',
          },
          data: this.ydata[2].data || [],
        }
        seriesData.push(obj)
        seriesData.push(obj1)
        seriesData.push(obj2)
        let yMax = 0
        for (let i = 0; i < seriesData.length; i++) {
          const item = seriesData[i].data
          for (let j = 0; j < item.length; j++) {
            if (item[j] > yMax) {
              yMax = item[j]
            }
          }
        }
        this.yMax = this.ceilNumber(yMax)
        iconData = ['#2276FC', '#FFCF5F', '#5FD5EC']
      } else if (this.ydata.length == 4) {
        let obj = {
          name: this.ydata[0].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#2276FC',
          },
          data: this.ydata[0].data || [],
        }
        let obj1 = {
          name: this.ydata[1].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#FFCF5F',
          },
          data: this.ydata[1].data || [],
        }
        let obj2 = {
          name: this.ydata[2].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#5FD5EC',
          },
          data: this.ydata[2].data || [],
        }
        let obj3 = {
          name: this.ydata[3].name,
          type: 'line',
          smooth: true,
          showSymbol: false,
          itemStyle: {
            color: '#ff7070',
          },
          data: this.ydata[3].data || [],
        }
        seriesData.push(obj)
        seriesData.push(obj1)
        seriesData.push(obj2)
        seriesData.push(obj3)
        let yMax = 0
        for (let i = 0; i < seriesData.length; i++) {
          const item = seriesData[i].data
          for (let j = 0; j < item.length; j++) {
            if (item[j] > yMax) {
              yMax = item[j]
            }
          }
        }
        this.yMax = this.ceilNumber(yMax)
        iconData = ['#2276FC', '#FFCF5F', '#5FD5EC', '#ff7070']
      } else {
        seriesData = []
        this.yMax = 10
      }

      workList = this.barList1
      let option = {
        color: iconData,
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          x: 25,
          x2: 40,
          y: 93,
          y2: 70,
          containLabel: true,
        },
        legend: {
          textStyle: {
            color: '#fff',
          },
          orient: 'horizontal',
          icon: 'circle',
          x: 'center', //可设定图例在左、右、居中
          y: 'bottom', //可设定图例在上、下、居中
          padding: [0, 0, 25, 0],
          // data:iconData,
        },
        dataZoom: [
          {
            type: 'slider',
            show: true,
            height: 3,
            left: '10%',
            right: '10%',
            bottom: 50,
            handleSize: 12,
          },
        ],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: 'rgba(43, 113, 176, 0.45)',
            },
          },
          axisLabel: {
            color: '#fff',
            formatter: function (val) {
              const dateTime = val.split(' ')
              if (dateTime.length > 1) {
                return `${dateTime[0]}\n${dateTime[1]}`
              } else {
                return val
              }
            },
          },
          data: this.xdata,
        },
        yAxis: [
          {
            name: '单位:kWh',
            type: 'value',
            axisLabel: {
              color: '#fff',
            },
            min: 0,
            max: this.yMax,
            interval: this.yMax / 5,
            smooth: true,
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'rgba(230,247,255,0.2)',
              },
            },
          },
        ],
        series: seriesData,
      }
      // 绘制图表
      myChart.setOption(option, true)
      // myChart._dom.childNodes[0].childNodes[0].style.width = "59rem"
      myChart._dom.childNodes[0].childNodes[0].style.height = '43rem'
    },
    deleteFloor(idx) {
      for (let i = 0; i < this.locationIds.length; i++) {
        if (this.locationIds[i] == idx) {
          this.locationIds.splice(i, 1)
        }
      }
      for (let i = 0; i < this.floorBtns.length; i++) {
        if (this.floorBtns[i].id == idx) {
          this.floorBtns.splice(i, 1)
        }
      }
      for (let i = 0; i < this.defaultChecked.length; i++) {
        if (this.defaultChecked[i] == idx) {
          this.defaultChecked.splice(i, 1)
        }
      }
      if (this.floorBtns.length < 1) {
        this.ydata = []
        this.drawLine1()
      } else {
        this.getSelectTime()
      }
    },
    addFloor(item) {
      this.defaultChecked = []

      for (let i = 0; i < this.locationIds.length; i++) {
        this.defaultChecked.push(this.locationIds[i])
      }
      this.addFloorShow = true
      this.$refs.tree.setCheckedNodes(this.defaultChecked)
    },
    handleClose() {
      if (this.$refs.tree.getCheckedNodes().length > 0 || this.floorInfo.length < 1) {
        this.addFloorShow = false
      } else {
        this.$message({
          message: '最少选择一个位置!',
          type: 'warning',
        })
      }
    },
    confirmFloors() {
      this.floorBtns = []
      this.locationIds = []
      console.log(this.$refs.tree.getCheckedNodes(), 'this.$refs.tree.getCheckedNodes()')
      if (this.$refs.tree.getCheckedNodes().length > 0) {
        if (this.$refs.tree.getCheckedNodes().length < 5) {
          let arr = this.$refs.tree.getCheckedNodes()
          for (let i = 0; i < arr.length; i++) {
            let obj = {
              id: arr[i].id,
              name: arr[i].name,
            }
            this.locationIds.push(arr[i].id)
            this.floorBtns.push(obj)
          }
          this.addFloorShow = false
          this.getSelectTime()
        } else {
          this.$message({
            message: '最多只能选择四个位置!',
            type: 'warning',
          })
        }
      } else {
        this.$message({
          message: '最少选择一个位置!',
          type: 'warning',
        })
        // this.addFloorShow = false
      }
    },
    getFloorInfo() {
      this.floorInfo = []

      this.dataForm.buildId = this.$store.state.storage.customerId
      this.$store
        .dispatch('getChildrenLocation', this.dataForm.buildId)
        .then(() => {
          this.floorInfo = this.$store.state.elecManagement.getChildrenLocation.data
        })
      setTimeout(() => {
        this.locationIds = []
        this.floorBtns = []
        if (this.floorInfo.length > 0) {
          this.downLoadDisable = false
          // 默认选中第一个位置下第一个节点
          if (this.floorInfo && this.floorInfo.length > 0) {
            if (this.floorInfo[0].children[0]) {
              this.locationIds.push(this.floorInfo[0].children[0].id)
              this.floorBtns.push(this.floorInfo[0].children[0])
            } else {
              this.locationIds.push(this.floorInfo[0].id)
              this.floorBtns.push(this.floorInfo[0])
            }
          }
          this.getSelectTime()
        } else {
          this.downLoadDisable = true
          this.xdata = [
            '00:00',
            '01:00',
            '02:00',
            '03:00',
            '04:00',
            '05:00',
            '06:00',
            '07:00',
            '08:00',
            '09:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00',
          ]
          this.ydata = []
          this.yMax = 0
          this.drawLine1()
        }
      }, 2000)
    },
    renderContent (h, { node, data, store }) {
      if (node.label.length > 11) {
        return (<div style="font-size: 1.4rem;">
          <el-tooltip class="item" effect="dark" placement="right">
            <div slot="content" >{node.label}</div>
            <p style="width:16rem; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;">{node.label}</p>
          </el-tooltip>
        </div>);
      } else { return (<div style="font-size: 1.4rem;"><p>{node.label}</p></div>) }
    },
  },
  creadted() {
    // this.dataForm.buildId = this.$store.state.storage.customerId;
  },
  mounted() {
    this.startDate = this.formatDate(new Date())
    this.endDate = this.formatDate(new Date())
    // this.init()
    // let arr = this.$store.state.elecManagement.getEquipmentBuilding.data;
    // this.getFloorInfo();
  },
  computed: {
    ...mapState({
      // getAnalysis: (state) => {
      //   return state.elecManagement.getAnalysis.data;
      // },
      getEquipmentBuilding: (state) => {
        return state.elecManagement.getEquipmentBuilding.data
      },
    }),
  },
  watch: {
    '$store.state.storage.customerId': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return
        this.dataForm.buildId = newVal
        this.getFloorInfo()
      },
    },
  },
}
</script>

<style lang="less" scoped>
.echartSingel3 {
  width: 100%;
  height: 42rem;
  // width: 58rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: relative;
  // 上半部分的title栏##################################################
  .tabBarPlace1 {
    width: 100%;
    height: 10rem;
    // width: 58rem;
    position: absolute;
    top: 0;
    left: 0;
    .tabBarTop {
      width: 100%;
      height: 5rem;
      // width: 58rem;
      position: absolute;
      top: 0;
      left: 0;
      .word {
        height: 2.2rem;
        font-size: 1.4rem;
        color: #37a5dd;
        line-height: 2.2rem;
        position: absolute;
        top: 2rem;
        left: 2.4rem;
      }
      .btn1 {
        height: 3rem;
        width: 24rem !important;
        background-color: #0b375e;
        // border: 0.1rem solid #004579;
        font-size: 1.4rem;
        color: #fff;
        // line-height: 3rem;
        padding: 0;
        margin: 0;
        cursor: pointer;
        margin-right: 1rem;
        position: absolute;
        left: 13rem;
        top: 1.8rem;
      }
      /deep/ .el-date-editor {
        left: unset;
        right: 19rem;
      }
      .dateChoose {
        height: 3rem;
        width: 13.8rem;
        border-radius: 0.2rem;

        position: absolute;
        top: 1.8rem;
        right: 2.2rem;
        overflow: hidden;
        border: 0.1rem solid #004579;
        box-sizing: border-box;
        display: flex;
        flex-wrap: nowrap;
        color: #fff;
        .btnDark {
          height: 3rem;
          width: 4.6rem;
          background-color: #0b375e;
          font-size: 1.4rem;
          padding: 0;
          margin: 0;
          cursor: pointer;
          line-height: 3rem;
          text-align: center;
        }
        .btnGreen {
          height: 3rem;
          width: 4.6rem;
          background-color: #027969;
          font-size: 1.4rem;
          padding: 0;
          margin: 0;
          cursor: pointer;
          line-height: 3rem;
          text-align: center;
        }
      }

      .img1 {
        height: 1.8rem;
        width: 1.8rem;
        position: absolute;
        top: 2.4rem;
        right: 17rem;
        cursor: pointer;
      }
    }
  }
  .charts {
    width: 100%;
    height: 43rem;
    // width: 59rem;
    // position: absolute;
    // top: 0;
    // left: 0;
  }
  .addFloor {
    position: absolute;
    top: 5.5rem;
    right: 2.4rem;
    z-index: 10;
    // width:10rem;
    height: 4rem;
    // background-color: #004579;
    .addBtn {
      font-size: 1.2rem;
      color: #6d8ba6;
      width: 2.4rem;
      height: 2.4rem;
      line-height: 2.4rem;
      text-align: center;
      border: 0.1rem dashed #6d8ba6;
      margin-top: 0.2rem;
      float: right;
    }
    .buttons {
      float: left;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-right: 1.6rem;

      .item {
        background-color: rgba(2, 121, 105, 0.2);
        border: 0.1rem solid #027969;
        border-radius: 0.2rem;
        // height:0.4rem;
        font-size: 1.4rem;
        color: #03d9bc;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        box-sizing: border-box;
        margin-left: 0.5rem;
        .deleteItem {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.echartSingel3 {
  .el-input__inner {
    padding: 0;
    text-align: center;
    height: 3rem !important;
    line-height: 3rem !important;
  }

  .el-input--prefix {
    height: 3rem !important;
    .el-input__icon {
      display: none;
    }
  }
  .el-range__icon {
    margin-left: 0 !important;
  }
  .el-range-separator {
    line-height: 3rem !important;
  }
  .el-range__close-icon {
    line-height: 3rem !important;
  }
}
.el-picker-panel__footer {
  background-color: #0b375e !important;
  .el-button {
    background: #0b375e !important;
    color: #fff;
    border-color: #4898dd;
  }
}
.el-tree {
  background: #0c375e;
  color: #fff;
}
.el-tree-node:focus > .el-tree-node__content {
  background-color: #1e5587;
}
.el-dialog__header {
  height: 5.4rem;
  line-height: 6.2rem;
  background: linear-gradient(
    225deg,
    rgba(15, 36, 55, 0.0001) 0.03%,
    rgba(0, 115, 222, 0.7) 99.9%
  );
}
.el-dialog__title {
  font-size: 2.4rem;
  color: #fff;
}
.el-dialog__headerbtn {
  top: 1.8rem;
}
.el-tree-node__content:hover {
  background-color: rgba(78, 134, 187, 0.5);
}
// .el-checkbox__inner::after{
//   border:0.1rem solid rgb(12, 11, 11);
// }
.btnTypeYear {
  .el-input__inner {
    width: 100% !important;
  }
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
.scrollable-tree {
  /* 为树组件设置合适的高度，例如 '30rem'，具体数值按需调整 */
  height: 30rem;
  margin-bottom: 2rem; /* 与底部按钮之间保持间距 */
}
.treeTable {
  overflow-y: auto; /* 添加滚动条 */
  flex: 1; /* 占据剩余空间 */
  padding: 2rem; /* 可根据需要调整内外边距 */
}
.btnConfirm {
  margin-left: 1rem;
  line-height: 1;
  color: white;
  border: none;
  border-radius: 2px;
  background-color: rgb(2, 121, 105);

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

}
</style>
