<template>
  <div class="huBeiBottom">
    <!-- 最下面一排盒子########################################################################## -->
      <div class="bottom1">
        <!-- 盒子分割 -->
        <div class="box" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/redLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">日用电量(kWh)</div>
          <div class="word2">{{rowOne[0]}}</div>
          <div class="line" style="width:9.9rem"></div>
           <div :class="(rowOne[0]-rowOne[1])>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{rowOne[1] != 0 ? (((rowOne[0]-rowOne[1])/rowOne[1]) * 100).toFixed(2) : 0}}%
            <i v-show="rowOne[0]-rowOne[1]>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="rowOne[0]-rowOne[1]<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="rowOne[0]-rowOne[1]==0"  style="font-size:1.2rem">--</i>
            </span></div>
          <!-- <div class="word4Red">环比：<span class="word4Inner">+233 <i class="el-icon-top-right" style="font-size:1.2rem"/></span></div> -->
        </div>
        <!-- 盒子分割 -->
        <div class="box" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/redLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">月用电量(kWh)</div>
          <div class="word2">{{rowOne[2]}}</div>
          <div class="line" style="width:9.9rem"></div>
          <div :class="(rowOne[2]-rowOne[3])>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{rowOne[3] != 0 ? (((rowOne[2]-rowOne[3])/rowOne[3]) * 100).toFixed(2) : 0}}% 
            <i v-show="rowOne[2]-rowOne[3]>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="rowOne[2]-rowOne[3]<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="rowOne[2]-rowOne[3]==0"  style="font-size:1.2rem">--</i>
          </span></div>  
          </div>

        <!-- 盒子分割 -->
        <div class="box" style="margin-right:25.6rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/redLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">年用电量(kWh)</div>
          <div class="word2">{{rowOne[4]}}</div>
          <div class="line" style="width:9.9rem"></div>
          <div :class="(rowOne[4]-rowOne[5])>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{rowOne[5] != 0 ? (((rowOne[4]-rowOne[5])/rowOne[5]) * 100).toFixed(2) : 0}}% 
            <i v-show="rowOne[4]-rowOne[5]>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="rowOne[4]-rowOne[5]<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="rowOne[4]-rowOne[5]==0"  style="font-size:1.2rem">--</i>
          </span></div>  
          </div>
        
        
      </div>

      <!-- 第二排盒子####################################################################### -->
      <div class="bottom2">
          <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/blueLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">日用电费(元)</div>
          <div class="word2">{{rowTwo[0]}}</div>
          <div class="line" style="width:9.9rem"></div>
          <div :class="(rowTwo[0]-rowTwo[1])>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{rowTwo[1] != 0 ? (((rowTwo[0]-rowTwo[1])/rowTwo[1]) * 100).toFixed(2) : 0}}%
            <i v-show="rowTwo[0]-rowTwo[1]>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="rowTwo[0]-rowTwo[1]<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="rowTwo[0]-rowTwo[1]==0"  style="font-size:1.2rem">--</i>
          </span></div>
          </div>
        <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/blueLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">月用电费(元)</div>
          <div class="word2">{{rowTwo[2]}}</div>
          <div class="line" style="width:9.9rem"></div>
          <div :class="(rowTwo[2]-rowTwo[3])>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{rowTwo[3] != 0 ? (((rowTwo[2]-rowTwo[3])/rowTwo[3]) * 100).toFixed(2) : 0}}%
            <i v-show="rowTwo[2]-rowTwo[3]>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="rowTwo[2]-rowTwo[3]<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="rowTwo[2]-rowTwo[3]==0"  style="font-size:1.2rem">--</i>
          </span></div>
          </div>

        <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:25.6rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/blueLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">年用电费(元)</div>
          <div class="word2">{{rowTwo[4]}}</div>
          <div class="line" style="width:9.9rem"></div>
          <div :class="(rowTwo[4]-rowTwo[5])>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{rowTwo[5] != 0 ? (((rowTwo[4]-rowTwo[5])/rowTwo[5]) * 100).toFixed(2) : 0}}% 
            <i v-show="rowTwo[4]-rowTwo[5]>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="rowTwo[4]-rowTwo[5]<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="rowTwo[4]-rowTwo[5]==0"  style="font-size:1.2rem">--</i>
          </span></div> 
          </div>
      </div>


      <!-- 第三排##################################################### -->
      <div class="bottom3">
         <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:1.2rem">
          <div class="word1">碳排放量(kg)</div>
           <div class="word2">{{rowThree[0]}}</div>
          <div class="line" ></div>
          <div :class="(rowThree[0]-rowThree[1])>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{rowThree[1] != 0 ? (((rowThree[0]-rowThree[1])/rowThree[1]) * 100).toFixed(2) : 0}}%
            <i v-show="rowThree[0]-rowThree[1]>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="rowThree[0]-rowThree[1]<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="rowThree[0]-rowThree[1]==0"  style="font-size:1.2rem">--</i>
          </span></div>
          </div>

         <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:52.8rem">
          <div class="word1">煤使用量(kg)</div>
          <div class="word2">{{rowThree[2]}}</div>
          <div class="line" ></div>
          <div :class="(rowThree[2]-rowThree[3])>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{rowThree[3] != 0 ? (((rowThree[2]-rowThree[3])/rowThree[3]) * 100).toFixed(2) : 0}}% 
            <i v-show="rowThree[2]-rowThree[3]>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="rowThree[2]-rowThree[3]<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="rowThree[2]-rowThree[3]==0"  style="font-size:1.2rem">--</i>
          </span></div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'PeiDianBottom',
    props: {
      myData: {
        type: Object,
        default: () => {
          return {}
        },
        require: true
      }
    },
    data(){return{
      // 第一排到第三排数据
      rowOne:[0,0,0,0,0,0],
      rowTwo:[0,0,0,0,0,0],
      rowThree:[0,0,0,0],

    }},
    watch: {
      myData: {
        handler: function(newVal, oldVal) {
          this.show(newVal)
        },
        deep: true,
        // immediate: true
      }
    },
    methods:{
      // 渲染底部数据
      show(val){  
        console.log('daini',val)
        this.rowOne = [
          val.dayUseElectric.useEnergy,
          val.dayUseElectric.yesterdayEnergy,
          val.monthElectric.useEnergy,
          val.monthElectric.yesterdayEnergy,
          val.yearElectric.useEnergy,
          val.yearElectric.yesterdayEnergy,
        ]

        this.rowTwo = [
          val.dayUseElectricMoney.useEnergy,
          val.dayUseElectricMoney.yesterdayEnergy,

          val.monthElectricMoney.useEnergy,
          val.monthElectricMoney.yesterdayEnergy,

          val.yearElectricMoney.useEnergy,
          val.yearElectricMoney.yesterdayEnergy, 
        ]

         this.rowThree = [
          val.carbonEmissions.useEnergy,
          val.carbonEmissions.yesterdayEnergy,
          
          val.standardCoals.useEnergy,
          val.standardCoals.yesterdayEnergy,
         ]
      
        //  this.rowOne = [0,0,0,0,0,0
        // ]

      },


    },
}
</script>

<style lang='less' scoped>
.huBeiBottom{
  height: 42rem;
  width: 110rem;
  position: relative;
  bottom: 0;
  left: 0;
}

.bottom1{
    height: 11.6rem;
    width: 110rem;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
    
}


.bottom2{
    height: 11.6rem;
    width: 110rem;
    position: absolute;
    bottom: 12.8rem;
    left: 0;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
  
}

.bottom3{
    height: 10.9rem;
    width: 110rem;
    position: absolute;
    bottom: 26.8rem;
    left: 0;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
  
}


.box{
  height: 10.9rem;
  width: 10.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(255, 153, 0, 0.1);
  float: left;
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #FF9900;
    text-shadow: 0 0.1rem 0.3rem rgba(255, 153, 0, 0.65);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #FF9900;
    margin-bottom: 1rem;
  }
 
}
.boxBlue{
  height: 10.9rem;
  width: 12.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(24, 249, 250, 0.1);
  float: left;
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #18FEFE;
    text-shadow: 0 0.1rem 0.3rem rgba(0, 118, 203, 0.85);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #18FEFE;
    margin-bottom: 1rem;
  }
 
}
.boxGreen{
  height: 10.9rem;
  width: 12.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(54, 234, 175, 0.1);
  float: left;
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #36EAAF;
    text-shadow: 0 0.1rem 0.3rem rgba(54, 234, 175, 0.45);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #36EAAF;
    margin-bottom: 1rem;
  }
 
}




 .word4Red{
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #fe6c2f;
    }
  }

  .word4Green{
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #39e5d0;
    }
  }
</style>