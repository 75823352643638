import request from '@/utils/request.js'

// 查询我的巡检累计饼图
export const planIntegerSimpleGraph = params => {
    return request({
        url: '/workDesk/planIntegerSimpleGraph',
        method: 'GET',
        params,
    })
}

// 查询我的工单累计饼图
export const soIntegerSimpleGraph = params => {
    return request({
        url: '/workDesk/soIntegerSimpleGraph',
        method: 'GET',
        params,
    })
}

// 查询工单任务柱状图
export const soIntegerGraph = params => {
    return request({
        url: '/workDesk/soIntegerGraph',
        method: 'GET',
        params,
    })
}

// 查询巡检任务柱状图
export const planIntegerGraph = params => {
    return request({
        url: '/workDesk/planIntegerGraph',
        method: 'GET',
        params,
    })
}

// 完成量统计
export const soPlanIntegerGraph = params => {
    return request({
        url: '/workDesk/soPlanIntegerGraph',
        method: 'GET',
        params,
    })
}

// 查询工程师排名
export const soPlanCounts = params => {
    return request({
        url: '/workDesk/soPlanCounts',
        method: 'GET',
        params,
    })
}
