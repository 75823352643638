<template>
    <div class="enerstion-bottom">
        <div class="enersta">
            <div class="overView">
                <div class="overContent">
                    <div class="overList">
                        <div class="overView1">
                            <div class="overLeft">
                                <img src="@/assets/elecGenerate/overlist1.png" alt class="overImg" />
                                <span>电站数量</span>
                            </div>
                            <div class="overRight">
                                <span class="overNum">{{ genOverviewData[0].value }}</span>
                                <span>个</span>
                            </div>
                        </div>
                        <div class="overView2">
                            <div class="overLeft">
                                <img src="@/assets/elecGenerate/overlist2.png" alt class="overImg" />
                                <span>电站装机容量</span>
                            </div>
                            <div class="overRight">
                                <span class="overNum">{{ genOverviewData[1].value }}</span>
                                <span>kW</span>
                            </div>
                        </div>
                        <div class="overView3">
                            <div class="overLeft">
                                <img src="@/assets/elecGenerate/overlist3.png" alt class="overImg" />
                                <span>当前发电功率</span>
                            </div>
                            <div class="overRight">
                                <span class="overNum">{{ currFlow[0].value }}</span>
                                <span>kW</span>
                            </div>
                        </div>
                    </div>
                    <div class="overBottom">
                        <div class="overBottomTitle">
                            <div class="dateChoose">
                                <div :class="btnTypeDay" @click="changeTab(0)">能量流</div>
                                <div :class="btnTypeMonth" @click="changeTab(1)">列表</div>
                            </div>
                            <div class="currPower" v-if="flagChange">
                                当前电站：<span style="font-size:1.4rem;">{{ powerStationName }}</span>
                            </div>
                        </div>
                        <div class="table">
                            <div class="power" v-if="flagChange">
                                <OverPower :currFlow="currFlow" :currentPowerStations="currentPowerStations" />
                            </div>
                            <div v-else class="power">
                                <OverList />
                            </div>
                        </div>
                        <!-- 切换电站能量流 -->
                        <div class="pagination" v-if="flagChange">
                            <el-pagination layout="prev,pager,next" :total="total" :current-page.sync="currentPage" @current-change="pageChange"></el-pagination>
                        </div>
                    </div>
                </div>
                <div class="overRigth">
                    <div class="rigth-top">
                        <el-tabs v-model="activeName">
                            <el-tab-pane label="发电信息" name="first">
                                <div class="info1">
                                    <div class="enerList">
                                        <div class="certent">
                                            <div class="left"><img src="@/assets/elecGenerate/enerInfo1.png" alt /></div>
                                            <div class="right">
                                                <div class="top">{{ dayMonthYearData[0].value }}kW·h</div>
                                                <div class="bottom">
                                                    日发电量
                                                </div>
                                            </div>
                                            <div class="right1">
                                                <div class="top">同比<span>{{ dayMonthYearData[10].value }}%</span>
                                                    <img v-if="dayMonthYearData[10].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[10].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                                <div class="bottom">环比<span>{{ dayMonthYearData[13].value }}%</span>
                                                    <img v-if="dayMonthYearData[13].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[13].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="certent">
                                            <div class="left"><img src="@/assets/elecGenerate/enerInfo1.png" alt /></div>
                                            <div class="right">
                                                <div class="top">{{ dayMonthYearData[1].value }}kW·h</div>
                                                <div class="bottom">
                                                    月发电量
                                                </div>
                                            </div>
                                            <div class="right1">
                                                <div class="top">同比<span>{{ dayMonthYearData[11].value }}%</span>
                                                    <img v-if="dayMonthYearData[11].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[11].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                                <div class="bottom">环比<span>{{ dayMonthYearData[14].value }}%</span>
                                                    <img v-if="dayMonthYearData[14].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[14].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="certent">
                                            <div class="left"><img src="@/assets/elecGenerate/enerInfo1.png" alt /></div>
                                            <div class="right">
                                                <div class="top">{{ dayMonthYearData[2].value }}kW·h</div>
                                                <div class="bottom">
                                                    年发电量
                                                </div>
                                            </div>
                                            <div class="right1">
                                                <!-- <div class="top">同比<span>{{ dayMonthYearData[12].value }}%</span>
                                                    <img v-if="dayMonthYearData[12].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[12].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div> -->
                                                <div class="bottom">环比<span>{{ dayMonthYearData[15].value }}%</span>
                                                    <img v-if="dayMonthYearData[15].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[15].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="收益信息" name="second">
                                <div class="info2">
                                    <div class="enerList">
                                        <div class="certent">
                                            <div class="left"><img src="@/assets/elecGenerate/moneyInfo1.png" alt /></div>
                                            <div class="right">
                                                <div class="top">{{ dayMonthYearData[3].value }}kW·h</div>
                                                <div class="bottom">
                                                    日收益
                                                </div>
                                            </div>
                                            <div class="right1">
                                                <div class="top">同比<span>{{ dayMonthYearData[16].value }}%</span>
                                                    <img v-if="dayMonthYearData[16].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[16].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                                <div class="bottom">环比<span>{{ dayMonthYearData[19].value }}%</span>
                                                    <img v-if="dayMonthYearData[19].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[19].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="certent">
                                            <div class="left"><img src="@/assets/elecGenerate/moneyInfo1.png" alt /></div>
                                            <div class="right">
                                                <div class="top">{{ dayMonthYearData[4].value }}kW·h</div>
                                                <div class="bottom">
                                                    月收益
                                                </div>
                                            </div>
                                            <div class="right1">
                                                <div class="top">同比<span>{{ dayMonthYearData[17].value }}%</span>
                                                    <img v-if="dayMonthYearData[17].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[17].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                                <div class="bottom">环比<span>{{ dayMonthYearData[20].value }}%</span>
                                                    <img v-if="dayMonthYearData[20].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[20].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="certent">
                                            <div class="left"><img src="@/assets/elecGenerate/moneyInfo1.png" alt /></div>
                                            <div class="right">
                                                <div class="top">{{ dayMonthYearData[5].value }}kW·h</div>
                                                <div class="bottom">
                                                    年收益
                                                </div>
                                            </div>
                                            <div class="right1">
                                                <!-- <div class="top">同比<span>{{ dayMonthYearData[18].value }}%</span>
                                                    <img v-if="dayMonthYearData[18].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[18].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div> -->
                                                <div class="bottom">环比<span>{{ dayMonthYearData[21].value }}%</span>
                                                    <img v-if="dayMonthYearData[21].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                                    <img v-if="dayMonthYearData[21].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div class="rigth-center">
                        <span class="title">当年社会效益</span>
                        <div class="rigth-center-content">
                            <div class="save1">
                                <img src="@/assets/elecGenerate/save1.png" alt />
                                <div class="save-text">
                                    <span v-if="dayMonthYearData[6].value < 1000">{{ dayMonthYearData[6].value }}kg</span>
                                    <span v-else>{{ (dayMonthYearData[6].value / 1000).toFixed(2) }}t</span>
                                    <p>节约煤炭</p>
                                </div>
                            </div>
                            <div class="save2">
                                <img src="@/assets/elecGenerate/save1.png" alt />
                            </div>
                            <div class="save3">
                                <img src="@/assets/elecGenerate/save1.png" alt />
                                <div class="save-text">
                                    <span v-if="dayMonthYearData[7].value < 1000">{{ dayMonthYearData[7].value }}kg</span>
                                    <span v-else>{{ (dayMonthYearData[7].value / 1000).toFixed(2) }}t</span>
                                    <p>减少二氧化碳排放</p>
                                </div>
                            </div>
                            <div class="save4">
                                <img src="@/assets/elecGenerate/save1.png" alt />
                            </div>
                            <div class="save5">
                                <img src="@/assets/elecGenerate/save1.png" alt />
                            </div>
                        </div>
                    </div>

                    <div class="rigth-bottom" id="rigth-bottom">
                        <div class="title">
                            年发电量
                        </div>
                        <!-- 线 -->
                        <div v-if="!lineChartShow">
                            <MapEnErLine ref="lineCharts" />
                        </div>
                        <!-- 柱状图 -->
                        <div v-else>
                            <MapEnErColumn ref="lineCharts" />
                        </div>
                        <div class="table-time">
                            <div>
                                <img v-show="!lineChartShow" @click="change(true)" src="@/assets/ElecManagement/BarChart.png" alt />
                                <img v-show="lineChartShow" src="@/assets/ElecManagement/BarChartGreen.png" alt />
                            </div>
                            <div>
                                <img v-show="lineChartShow" @click="change(false)" src="@/assets/ElecManagement/LineChart.png" alt />
                                <img v-show="!lineChartShow" src="@/assets/ElecManagement/LineChartGreen.png" alt />
                            </div>
                            <div>
                                <!-- <img  src="@/assets/ElecManagement/Download.png" alt /> -->
                                <!-- 下载的下拉框 -->
                                <el-dropdown @command="downLoadPng">
                                    <span class="el-dropdown-link">
                                        <img src="@/assets/ElecManagement/Download.png" alt="" />
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="['pic', '年发电量']">图片下载</el-dropdown-item>
                                        <el-dropdown-item :command="['excel', '年发电量']">Excel下载</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
        </div>
    </div>
</template>

<script>
import MapEnErColumn from "./overView/mapEnErColumn";
import MapEnErLine from "./overView/mapEnErLine";
import OverPower from "./overView/overPower";
import OverList from "./overView/overList";
import html2canvas from "html2canvas";
import { exportGenManagementCurryearbarReport } from "@/api/repair"
export default {
    components: {
        MapEnErColumn,
        MapEnErLine,
        OverPower,
        OverList,
    },
    name: "OverViewGenerate",
    data () {
        return {
            flagChange: true,
            btnTypeDay: "btnGreen",
            btnTypeMonth: "btnDark",
            lineChartShow: true,
            optionData: {},
            showInterval: true,
            whichTab: 0,
            customerId: "",
            customerName: '',
            genOverviewData: [{ value: 0 }, { value: 0 }],
            dayMonthYearData: [
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
            ],
            currFlow: [
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
            ],
            timer: null,
            powerStations: [],
            total: 10,
            currentPage: 1,
            stationId: 0,
            powerStationName: "",
            currentPowerStations: "",
            activeName: "first"
        };
    },
    created () { },
    mounted () {
        setTimeout(() => {
            this.showInterval = true;
            this.$nextTick(() => {
                // this.getGenOverview();
                // this.getDayMonthYear();
                // this.getPowerStations();
            });
        }, 500);
        this.timer = setInterval(() => {
            this.initCurrFlow();
        }, 15000);
    },
    beforeDestroy () {
        clearInterval(this.timer);
        this.timer = null;
    },
    methods: {
        change (val) {
            this.showInterval = false;
            this.lineChartShow = val;
            setTimeout(() => {
                this.showInterval = true;
                this.$nextTick(() => {
                    this.$refs.lineCharts.init();
                });
            }, 10);
        },
        changeTab (index) {
            if (index === 0) {
                this.flagChange = true;
                this.btnTypeDay = "btnGreen";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnDark";
                this.initCurrFlow();
            } else if (index === 1) {
                this.flagChange = false;
                this.btnTypeDay = "btnDark";
                this.btnTypeMonth = "btnGreen";
                this.btnTypeYear = "btnDark";
            }
        },
        getGenOverview () {
            this.customerId = this.$store.state.storage.customerId;
            this.$store.dispatch("getGenOverview", this.customerId).then(() => {
                if (this.$store.state.genManagement.getGenOverview.errorCode == 0) {
                    this.genOverviewData = this.$store.state.genManagement.getGenOverview.data;
                } else {
                    this.genOverviewData = [{ value: 0 }, { value: 0 }];
                }
            });
        },
        getDayMonthYear () {
            this.customerId = this.$store.state.storage.customerId;
            this.$store.dispatch("getDayMonthYear", this.customerId).then(() => {
                if (this.$store.state.genManagement.getDayMonthYear.errorCode == 0) {
                    this.dayMonthYearData = this.$store.state.genManagement.getDayMonthYear.data;
                    console.log(this.dayMonthYearData, 'this.dayMonthYearData')
                } else {
                    this.dayMonthYearData = [
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                    ];
                }
            });
        },
        initCurrFlow () {
            let value = {
                customerId: this.customerId,
                stationId: this.stationId,
            };
            this.$store.dispatch("getCurrFlow", value).then(() => {
                if (this.$store.state.genManagement.getCurrFlow.errorCode == 0) {
                    this.currFlow = this.$store.state.genManagement.getCurrFlow.data;
                } else {
                    this.currFlow = [
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                    ];
                }
            });
        },
        // 获取电站列表
        getPowerStations () {
            this.customerId = this.$store.state.storage.customerId;
            let data = {
                customerId: this.customerId,
            };
            this.$store.dispatch("getPowerStations", data).then(() => {
                if (this.$store.state.equipment.getPowerStations.errorCode == 0) {
                    let optionList = this.$store.state.equipment.getPowerStations.data;
                    if ((optionList !== null) & (optionList.length > 0)) {
                        this.powerStations = optionList;
                        this.stationId = this.powerStations[0].id;
                        this.powerStationName = this.powerStations[0].name;
                        this.currentPowerStations = this.powerStations[0];
                        this.currentPage = 1;
                        this.total = optionList.length * 10;
                    } else {
                        this.powerStations = [];
                        this.stationId = 0;
                        this.powerStationName = "";
                        this.currentPowerStations = "";
                        this.total = 0;
                    }
                } else {
                    this.powerStations = [];
                    this.stationId = 0;
                    this.powerStationName = "";
                    this.currentPowerStations = "";
                    this.total = 0;
                }
                this.initCurrFlow();
                this.$refs.lineCharts.init();
            });
        },
        pageChange (e) {
            this.stationId = this.powerStations[e - 1].id;
            this.powerStationName = this.powerStations[e - 1].name;
            this.currentPowerStations = this.powerStations[e - 1];
            this.initCurrFlow();
        },

        // 转化dom节点为图片下载
        downLoadPng (val) {
            if (val[0] == "excel") {
                let params = {
                    customerId: this.customerId,
                    name: val[1]
                }
                // this.$store.dispatch('getDownloadTemplate', params)
                exportGenManagementCurryearbarReport(params).then((response) => {
                    var blob = new Blob([response], {
                        type: "application/vnd.ms-excel,charset=utf-8",
                    }) //创建一个blob对象
                    var a = document.createElement("a") //创建一个<a></a>标签
                    a.href = URL.createObjectURL(blob) // response is a blob
                    a.download = `${this.customerName}发电概览-年发电量.xls` //文件名称
                    a.style.display = "none"
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
            } else {
                var node = document.getElementById("rigth-bottom");
                window.pageYoffset = 0;
                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
                var width = node.offsetWidth;
                var height = node.offsetHeight;
                var scale = 4;
                html2canvas(node, {
                    width: width,
                    heigth: height,
                    backgroundColor: null,
                    dpi: window.devicePixelRatio * 4,
                    scale: scale,
                    X: 0,
                    Y: 0,
                    scrollX: -3,
                    scrollY: 0,
                    useCORS: true,
                    allowTaint: true,
                }).then((canvas) => {
                    var url = canvas.toDataURL();
                    var a = document.createElement("a");
                    a.download = `${this.customerName}发电概览-${val[1]}`;
                    var event = new MouseEvent("click");
                    a.href = url;
                    a.dispatchEvent(event);
                });
            }
        },
    },
    watch: {
        whichTab: {
            immediate: true,
            handler (val) {
                // 折线图部分的伪造数据#############################################################
                if (this.showInterval) {
                    let optionData = {};
                    if (val === 0) {
                        let legendData = ["办公楼"];
                        optionData = {
                            legendData: legendData,
                            xAxisData: ["10-01", "10-02", "10-03", "10-04", "10-05", "10-06", "10-07"],
                            seriesData: [
                                {
                                    name: "办公楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                            { offset: 1, color: "rgba(54, 115, 232, 0)" },
                                        ]),
                                    },
                                    data: [350, 240, 101, 134, 90, 230, 210],
                                },
                            ],
                        };
                    }
                    this.optionData = optionData;
                }
            },
        },
        "$store.state.storage.customerData": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (newVal && !newVal.id) {
                    return;
                }
                this.customerId = newVal.id;
                this.$store.commit("setCustomerId", newVal.id);
                this.customerName = newVal.name;
                this.getGenOverview();
                this.getDayMonthYear();
                this.$nextTick(() => {
                    this.currentPage = 1;
                });
                this.getPowerStations();
            },
        },
    },
};
</script>

<style lang="less" scoped>
//滚动条
.enerstion-bottom {
    overflow: hidden;
    width: 100%;
    //   width: 142.8rem;
    height: 88.8rem;
    // margin-top: 1.5rem;
}
.enersta {
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    width: calc(100% + 0.6rem);
    //   width: 143.8rem;
    // height: 75rem;
    height: calc(100vh - 19.2rem);
}
.blank {
    position: relative;
    top: 87rem;
    height: 20rem;
    width: 142.8rem;
    color: grey;
    font-size: 1.4rem;
    text-align: center;
    line-height: 10rem;
}
.overView {
    display: flex;
    align-items: flex-start;
    .overContent {
        flex: 1 1 95.2rem;
        display: flex;
        flex-direction: column;
        margin-right: 2rem;
        overflow: hidden;
        .overList {
            display: flex;
            margin-bottom: 1.5rem;
            .overImg {
                width: 3.6rem;
                height: 3.6rem;
            }
            .overView1 {
                flex: 1;
                margin-right: 1.5rem;
                // position: absolute;
                height: 10rem;
                // width: 30.6rem;
                background-color: #0c375e;
                border-radius: 0.4rem;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                .overLeft {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 16.5rem;
                    img {
                        margin-right: 1rem;
                    }
                    span {
                        font-size: 1.6rem;
                        color: #fffffe;
                        font-weight: 400;
                    }
                }
                .overRight {
                    color: #fbfbfb;
                    .overNum {
                        font-size: 2.4rem;
                        margin-right: 0.3rem;
                    }
                    span {
                        font-size: 1.4rem;
                    }
                }
            }
            .overView2 {
                flex: 1;
                margin-right: 1.5rem;
                // left: 32.2rem;
                // position: absolute;
                height: 10rem;
                // width: 30.6rem;
                background-color: #0c375e;
                border-radius: 0.4rem;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                .overLeft {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 16.5rem;
                    img {
                        margin-right: 1rem;
                    }
                    span {
                        font-size: 1.6rem;
                        color: #fffffe;
                        font-weight: 400;
                    }
                }
                .overRight {
                    color: #fbfbfb;
                    .overNum {
                        font-size: 2.4rem;
                        margin-right: 0.3rem;
                    }
                    span {
                        font-size: 1.4rem;
                    }
                }
            }
            .overView3 {
                flex: 1;
                // position: absolute;
                // left: 64.4rem;
                height: 10rem;
                // width: 30.6rem;
                background-color: #0c375e;
                border-radius: 0.4rem;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                .overLeft {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 16.5rem;
                    img {
                        margin-right: 1rem;
                    }
                    span {
                        font-size: 1.6rem;
                        color: #fffffe;
                        font-weight: 400;
                    }
                }
                .overRight {
                    color: #fbfbfb;
                    .overNum {
                        font-size: 2.4rem;
                        margin-right: 0.3rem;
                    }
                    span {
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .overBottom {
            //   position: absolute;
            //   width: 95.2rem;
            position: relative;
            width: 100%;
            height: 74.8rem;
            padding: 2.4rem 2rem;
            //   top: 11.5rem;
            background: #0c2f4f;
            border-radius: 0.4rem;
            box-sizing: border-box;
            .overBottomTitle {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
                margin-bottom: 2rem;
            }
            .currPower {
                // position: absolute;
                // left: 2rem;
                // top: 2.8rem;
                color: #fff;
            }
            .dateChoose {
                // position: absolute;
                // right: 2rem;
                // top: 2.4rem;
                height: 3.8rem;
                width: 19rem;
                border-radius: 0.2rem;
                overflow: hidden;
                border: 0.1rem solid #004579;
                box-sizing: border-box;
                display: flex;
                flex-wrap: nowrap;
                color: #fff;
                .btnDark {
                    width: 9.6rem;
                    height: 3.8rem;
                    background-color: #0b375e;
                    font-size: 1.4rem;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3.6rem;
                    text-align: center;
                }
                .btnGreen {
                    width: 9.6rem;
                    height: 3.8rem;
                    background-color: #027969;
                    font-size: 1.4rem;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3.6rem;
                    text-align: center;
                }
            }
            .table {
                // margin-top: 8rem;
                // width: auto;
            }
            .power {
                // padding: 0 1.5rem;
                // padding-left: 1.5rem;
            }
            .pagination {
                position: absolute;
                bottom: 12rem;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .overRigth {
        flex: 1 1 45.8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // position: absolute;
        // left: 143.5rem;
        .rigth-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            width: 100%;
            background: #0c375e;
            height: 23.6rem;
            .infoImg {
                width: 3.4rem;
                height: 3.4rem;
                margin-right: 0.6rem;
            }
            .info1 {
                position: relative;
                flex: 1;
                margin-right: 1.5rem;
                // width: 22.1rem;
                height: 23.6rem;
                // position: absolute;
                // right: 24.6rem;
                background-color: #0c375e;
                border-radius: 0.4rem;
                .title {
                    position: relative;
                    top: 0.8rem;
                    margin-left: 2.4rem;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    color: #37a5dd;
                }
                .enerList {
                    margin-left: 2.4rem;
                    .certent {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        .left {
                        margin-right: 0.6rem;
                            img {
                                width: 3.6rem;
                                height: 3.6rem;
                            }
                        }
                        .right{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            color: #fff;
                            margin-bottom: 0.5rem;
                            width: 50%;
                            .top {
                            font-size: 1.6rem;
                            }
                            .bottom {
                                font-size: 1.2rem;
                            }
                        }
                        .right1{
                            width: 50%;
                            color:#fff;
                            span{
                                margin: 0px 5px;
                            }
                        }
                    }
                }
            }
            .info2 {
                position: relative;
                flex: 1;
                // width: 22.1rem;
                height: 23.6rem;
                background-color: #0c375e;
                // position: absolute;
                border-radius: 0.4rem;
                // right: 1rem;
                .title {
                    position: relative;
                    top: 0.8rem;
                    margin-left: 2.4rem;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    color: #37a5dd;
                }
                .enerList {
                    margin-left: 2.4rem;
                    .certent {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        .left {
                        margin-right: 0.6rem;
                            img {
                                width: 3.6rem;
                                height: 3.6rem;
                            }
                        }
                        .right{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            color: #fff;
                            margin-bottom: 0.5rem;
                            width: 50%;
                            .top {
                            font-size: 1.6rem;
                            }
                            .bottom {
                                font-size: 1.2rem;
                            }
                        }
                        .right1{
                            width: 50%;
                            color:#fff;
                            span{
                                margin: 0px 5px;
                            }
                        }
                    }
                }
            }
            /deep/.el-tabs{
                    width:100%;
                    height: 23.6rem;
                    overflow: hidden;
                
                .el-tabs__header{
                    margin: 0px 0px 17px 2.4rem;
                .el-tabs__item{
                    color:#f0f8ff;
                    font-size: 1.6rem;
                }
                .el-tabs__item.is-active {
                    color: #03d9bc;
                }
                .el-tabs__active-bar{
                    background-color: #03d9bc;
                }
                .el-tabs__nav-wrap::after{
                    background: none;
                }
            }
        }
            
        }
        .rigth-center {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;
            width: 100%;
            // width: 45.8rem;
            height: 23rem;
            // position: absolute;
            // top: 25rem;
            // right: 1rem;
            background-color: #0c375e;
            border-radius: 0.4rem;
            .title {
                position: relative;
                top: 2rem;
                margin-left: 2.4rem;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: #37a5dd;
            }
            .rigth-center-content {
                position: relative;
                margin: 0 auto;
                margin-top: -2rem;
                width: 41.1rem;
            }
            .save1 {
                img {
                    position: absolute;
                    top: 8.4rem;
                    left: 2.3rem;
                    width: 11.8rem;
                    height: 11.8rem;
                }
                .save-text {
                    position: absolute;
                    top: 12rem;
                    left: 4.5rem;
                    width: 7rem;
                    span {
                        color: #0f2437;
                        font-size: 1.6rem;
                        font-weight: 600;
                        display: block;
                        width: 7rem;
                        text-align: center;
                    }
                    p {
                        font-size: 1.4rem;
                        color: #0f2437;
                        width: 7rem;
                        text-align: center;
                    }
                }
            }
            .save2 {
                position: absolute;
                left: 14.2rem;
                top: 14.2rem;
                img {
                    width: 5.8rem;
                    height: 5.8rem;
                }
            }
            .save3 {
                position: absolute;
                left: 18.7rem;
                top: 5.4rem;
                img {
                    width: 11.8rem;
                    height: 11.8rem;
                }
                .save-text {
                    position: absolute;
                    top: 2.2rem;
                    left: 1.1rem;
                    span {
                        color: #0f2437;
                        font-size: 1.8rem;
                        font-weight: 400;
                        width: 10rem;
                        display: block;
                        text-align: center;
                    }
                    p {
                        font-size: 1.4rem;
                        color: #0f2437;
                        text-align: center;
                        width: 10rem;
                    }
                }
            }
            .save4 {
                position: absolute;
                left: 29.3rem;
                top: 15rem;
                img {
                    width: 4.4rem;
                    height: 4.4rem;
                }
            }
            .save5 {
                position: absolute;
                left: 34.7rem;
                top: 7.7rem;
                img {
                    width: 4.4rem;
                    height: 4.4rem;
                }
            }
        }
        .rigth-bottom {
            position: relative;
            width: 100%;
            // width: 45.8rem;
            height: 36.8rem;
            // position: absolute;
            // top: 49.5rem;
            // right: 1rem;
            background-color: #0c375e;
            border-radius: 0.4rem;
            .title {
                color: rgba(52, 159, 214, 1);
                font-size: 1.6rem;
                font-weight: 400;
                position: absolute;
                left: 2.4rem;
                top: 2rem;
            }
            .table-time {
                position: absolute;
                right: 1.5rem;
                top: 0rem;
                display: flex;
                .select-time {
                    padding-top: 0.8rem;
                    padding-right: 1.5rem;
                }
                img {
                    width: 2.057rem;
                    height: 1.886rem;
                    padding-right: 1.9rem;
                    padding-top: 2rem;
                    cursor: pointer;
                }
                .time {
                    width: 120px;
                }
            }
        }
    }
}
</style>
<style lang="less">
.enerstion-bottom {
    .el-pagination .btn-next {
        background-color: #0c2f4f;
        color: rgba(255, 255, 255, 0.65);
    }
    .el-pagination .btn-prev {
        background-color: #0c2f4f;
        color: rgba(255, 255, 255, 0.65);
    }
    .el-pagination button:disabled {
        background-color: #0c2f4f;
        color: rgba(255, 255, 255, 0.65);
    }
    .el-pager li {
        background-color: #0c2f4f;
        color: rgba(255, 255, 255, 0.65);
        font-size: 1.3rem;
    }
    .el-pager li.active {
        color: #02ad96 !important;
    }
}
</style>
