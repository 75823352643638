// G6转X6
import { Lang } from "@antv/x6"
import { fmtJSON, fmtLabelOverflow } from ".";
import { ActionType, CustomEventTypeEnum } from "./enums";
import ErrorClass from "./errorClass";
import { Channel } from "./transmit";
import { DqyqjNodes } from "../nodes/nodes.js";
let templateArr = ['8_dianbiao', '8_yjxfd', '8_xfjl', '8_xfdt', '8_lyyjgb', '8_bjxt', '8_plb', '8_pyfj', '8_xfsb']
for (let i = 0; i < templateArr.length; i++) {
    DqyqjNodes.set(templateArr[i], {
        html: templateArr[i],
        ports: {
            groups: {
                top: {
                    position: 'top',
                    attrs: {
                        circle: {
                            r: 2,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff',
                            style: {
                                visibility: 'hidden',
                            },
                        },
                    },
                },
                bottom: {
                    position: 'bottom',
                    attrs: {
                        circle: {
                            r: 2,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff',
                            style: {
                                visibility: 'hidden',
                            },
                        },
                    },
                },
                right: {
                    position: 'right',
                    attrs: {
                        circle: {
                            r: 2,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff',
                            style: {
                                visibility: 'hidden',
                            },
                        },
                    },
                },
                left: {
                    position: 'left',
                    attrs: {
                        circle: {
                            r: 2,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff',
                            style: {
                                visibility: 'hidden',
                            },
                        },
                    },
                },
            },
            items: [{
                group: 'right',
            }, {
                group: 'left',
            }, {
                group: 'top',
            }, {
                group: 'bottom',
            }]
        }
    })
}
/**获取不同actionType对应主题色 */
export function getActionTypeTheme (type) {
    /**@enum */
    const Theme = {
        // 透明
        TRANSPARENT: { opacity: 0, border: 'rgba(0,0,0,1)', background: 'rgba(0,0,0,0)' },
        /**默认深蓝 */
        DEFAULT: { border: '#5b8ffa', background: '#83b7ff' },
        /**浅蓝色 */
        BLUE: { border: '#A4C2FF', background: '#D0DDF9' },
        /**绿色 */
        GREEN: { border: '#A8D7CD', background: '#BFE8E2' },
        /**橘色 */
        ORANGE: { border: '#FDBE94', background: '#FBECE3' },
        /**灰色 */
        GRAY: { border: '#C4C4C4', background: '#E4E4E4' },
        /**黄色 */
        YELLOW: { border: '#CCAC55', background: '#FDF3D7' },
    }
    // 默认主题色
    const DEFAULE_THEME = Theme.DEFAULT
    if (!type) return DEFAULE_THEME
    const { SVG_IMAGE, SVG_HTML, SVG_EDGE, SVG_TAG, TRIGGER, CONDITION, ACTION, } = ActionType
    return {
        // SVG
        [SVG_HTML]: Theme.TRANSPARENT,
        [SVG_TAG]: Theme.TRANSPARENT,
        [SVG_IMAGE]: Theme.TRANSPARENT,
        [SVG_EDGE]: Theme.TRANSPARENT,
        // 触发器
        [TRIGGER]: Theme.GREEN,
        // 状态条件
        [CONDITION]: Theme.GRAY,
        // 执行动作
        [ACTION]: Theme.BLUE,
    }[type]
}

function getSvgConfig1 (node) {
    let { type, shape, tooltip, attrs, x, y, size, id, position, data, componentId, componentName, componentClass, componentType, template, sourceUrl, initData, actionType, initialization, color } = node

    let _width,
        _height,
        _x = x,
        _y = y,
        _shape = shape,
        _tooltip = tooltip,
        _actionType = actionType
    if (data && data.actionType) {
        _actionType = data.actionType
    }
    if (size) {
        // G6
        if (Lang.isArray(size)) {
            _width = size[0]
            _height = size[1]
        }
        // x6
        else if (Lang.isObject(size)) {
            _width = size.width
            _height = size.height
        }
    }
    if (Lang.isObject(position)) {
        _x = position.x
        _y = position.y
    }
    if (Lang.isObject(attrs)) {
        _tooltip = attrs.label.text
    }
    const cutLabel = fmtLabelOverflow(_tooltip)

    let json = DqyqjNodes.get(template);
    var result = {
        type: _shape,
        label: cutLabel,
        x: _x,
        y: _y,
        width: _width,
        height: _height,
        id,
        actionType: _actionType,
        data: {
            actionType: _actionType,
            initialization,
            tooltip: _tooltip,
        },
        componentId,
        componentName,
        template,
        sourceUrl,
        initData,
        color,
    }
    result = Object.assign({}, result, json); // 这样不会改变原对象
    return result
}

export function getSvgNodeByHtml (node) {
    const { type, label, x, y, width, height, id, actionType, data, componentId, componentName, componentClass, componentType, template, sourceUrl, initData } = getSvgConfig1(node)
    // 主题色
    const targetTheme = getActionTypeTheme(actionType);
    let json = DqyqjNodes.get(template);

    var result = {
        id,
        shape: type, // svg-image 指定使用何种图形，默认值为 'rect'
        width,
        height,
        x,
        y,
        zIndex: 100,
        data,
        componentId,
        componentName,
        template,
        sourceUrl,
        initData,
        attrs: {
            /*label: {
                text: label,
                fill: "#7D7671",
                strokeWidth: 0.4,
                fontSize: 12,
            },*/
            label: {
                refX: '100%',
                refX2: 4,
                refY: 0.5,
                textAnchor: 'start',
                textVerticalAnchor: 'middle',
            },
            body: {
                // stroke: targetTheme.border,
                stroke: initData.stroke,
                strokeWidth: 0,
                // fill: targetTheme.background,
                magnet: true,
            },
            props: {
                // 属性面板线条、颜色属性
                stroke: initData.stroke,
                strokeWidth: initData.strokeWidth,
            },
            data: {
                // 数据面板属性
            }

        },
    };
    result = Object.assign({}, result, json); // 这样不会改变原对象
    return result
}

export function getSvgFontByHtml (node) {
    const { type, label, x, y, width, height, id, actionType, data, componentId, componentName, componentClass, componentType, template, sourceUrl, initData } = getSvgConfig1(node)
    // 主题色
    const targetTheme = getActionTypeTheme(actionType);
    let json = DqyqjNodes.get(template);
    var result = {
        id,
        shape: type, // svg-image 指定使用何种图形，默认值为 'rect'
        width,
        height,
        x,
        y,
        zIndex: 100,
        data,
        componentId,
        componentName,
        template,
        sourceUrl,
        initData,
        attrs: {
            /*label: {
                text: label,
                fill: "#7D7671",
                strokeWidth: 0.4,
                fontSize: 12,
            },*/
            label: {
                refX: '100%',
                refX2: 4,
                refY: 0.5,
                textAnchor: 'start',
                textVerticalAnchor: 'middle',
                fontSize: 12,
            },
            style: {
                fontSize: 12,
                fontWeight: 400,
                color: initData.stroke
            },
            props: {
                // 属性面板线条、颜色属性
                stroke: initData.stroke,
                strokeWidth: initData.strokeWidth,
                rows: 2,
                cols: 2
            },
            data: {
                // 数据面板属性
            }
        },
    };
    result = Object.assign({}, result, json); // 这样不会改变原对象
    result.attrs.body.color = initData.stroke
    return result
}

export function getSvgTableByHtml (node) {
    const { type, label, x, y, width, height, id, actionType, data, componentId, componentName, componentClass, componentType, template, sourceUrl, initData } = getSvgConfig1(node)
    // 主题色
    const targetTheme = getActionTypeTheme(actionType);
    let json = DqyqjNodes.get(template);
    var result = {
        id,
        shape: type, // svg-image 指定使用何种图形，默认值为 'rect'
        width,
        height,
        x,
        y,
        zIndex: 100,
        data,
        componentId,
        componentName,
        template,
        sourceUrl,
        initData,
        attrs: {
            /*label: {
                text: label,
                fill: "#7D7671",
                strokeWidth: 0.4,
                fontSize: 12,
            },*/
            label: {
                refX: '100%',
                refX2: 4,
                refY: 0.5,
                textAnchor: 'start',
                textVerticalAnchor: 'middle',
            },
            body: {
                // // stroke: targetTheme.border,
                // strokeWidth: 0,
                // fill: targetTheme.background,
            },
            props: {
                // 属性面板线条、颜色属性
                stroke: initData.stroke,
                strokeWidth: initData.strokeWidth,
                labelText: '标签',
                tableRowCount: 2, //表格默认行数
                tableColCount: 2, //表格默认列数
                tableData: [['表格内容', '表格内容'], ['表格内容', '表格内容']]
            },
            data: {
                // 数据面板属性
            }
        },
    };
    result = Object.assign({}, result, json); // 这样不会改变原对象
    return result
}

export function getSvgNodeByTag (node) {
    const { type, label, x, y, width, height, id, actionType, data, componentId, componentName, componentClass, componentType, template, sourceUrl, initData } = getSvgConfig1(node)
    // 主题色
    // const targetTheme = getActionTypeTheme(actionType);
    let json = DqyqjNodes.get(template);
    var result = {
        id,
        shape: type, // svg-image 指定使用何种图形，默认值为 'rect'
        width,
        height,
        x,
        y,
        zIndex: 100,
        data,
        componentId,
        componentName,
        template,
        sourceUrl,
        initData,
        attrs: {
            label: {
                text: label,
                fill: "#7D7671",
                strokeWidth: 0.4,
                strokeDasharray: '',
                strokeLinecap: 'square',
                fontSize: 12,
            },
            body: {
                stroke: initData.stroke,
                strokeWidth: 0,
                fill: initData.stroke,
                magnet: true,
            }
        },
    };
    result = Object.assign({}, result, json); // 这样不会改变原对象
    result.attrs.prop.stroke = initData.stroke
    return result
}

function getSvgConfig (node) {
    let { type, shape, tooltip, attrs, x, y, size, id, position, data, componentId, componentName, componentClass, componentType, template, sourceUrl, initData, actionType, initialization } = node

    let _width,
        _height,
        _x = x,
        _y = y,
        _shape = shape,
        _tooltip = tooltip,
        _actionType = actionType
    if (data && data.actionType) {
        _actionType = data.actionType
    }
    if (size) {
        // G6
        if (Lang.isArray(size)) {
            _width = size[0]
            _height = size[1]
        }
        // x6
        else if (Lang.isObject(size)) {
            _width = size.width
            _height = size.height
        }
    }
    if (Lang.isObject(position)) {
        _x = position.x
        _y = position.y
    }
    // 形状转义
    // G6
    if (Lang.isString(type)) {
        _shape = type
        if (type === 'diamond') _shape = 'rect'
    }
    if (Lang.isObject(attrs)) {
        _tooltip = attrs.label.text
    }

    const cutLabel = fmtLabelOverflow(_tooltip)

    return {
        type: _shape,
        label: cutLabel,
        x: _x,
        y: _y,
        width: _width,
        height: _height,
        id,
        actionType: _actionType,
        data: {
            actionType: _actionType,
            initialization,
            tooltip: _tooltip,
        },
        componentId,
        componentName,
        template,
        sourceUrl,
        initData
    }
}

export function getSvgNodeByImg (node) {
    const { type, label, x, y, width, height, id, actionType, data, componentId, componentName, componentClass, componentType, template, sourceUrl, initData } = getSvgConfig(node)
    // 主题色
    const targetTheme = getActionTypeTheme(actionType)
    return {
        id,
        shape: type, // svg-image 指定使用何种图形，默认值为 'rect'
        width,
        height,
        x,
        y,
        zIndex: 100,
        data,
        componentId,
        componentName,
        template,
        sourceUrl,
        initData,
        attrs: {
            label: {
                text: label,
                fill: "#7D7671",
                strokeWidth: 0.4,
                fontSize: 12,
            },
            body: {
                stroke: targetTheme.border,
                strokeWidth: 0,
                fill: targetTheme.background,
            },
            image: {
                'xlink:href': sourceUrl,
                width: width,
                height: height,
                x: 0,
                y: 0
            }
        },
        /*ports: {
            items: [
                {group: 'port-top', id: 'p_top'},
                {group: 'port-bottom', id: 'p_bottom'},
            ],
            groups: {
                "port-top": {
                    position: 'top',
                    zIndex: 20,
                    attrs: {
                        circle: {
                            dataClass: 'choice-point',
                            r: 6,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff'
                        }
                    }
                },
                "port-bottom": {
                    position: 'bottom',
                    zIndex: 20,
                    attrs: {
                        circle: {
                            dataClass: 'choice-point',
                            r: 6,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff'
                        }
                    }
                }
            }
        },*/
    }
}

/**
 * 获取默认配置选项
 * 兼容x6/g6
 */
function getBaseConfig (node) {
    let { type, shape, tooltip, attrs, x, y, size, id, position, data, actionType, initialization } = node
    let _width,
        _height,
        _x = x,
        _y = y,
        _shape = shape,
        _tooltip = tooltip,
        _actionType = actionType
    if (data && data.actionType) {
        _actionType = data.actionType
    }
    if (size) {
        // G6
        if (Lang.isArray(size)) {
            _width = size[0]
            _height = size[1]
        }
        // x6
        else if (Lang.isObject(size)) {
            _width = size.width
            _height = size.height
        }
    }
    if (Lang.isObject(position)) {
        _x = position.x
        _y = position.y
    }
    // 形状转义
    // G6
    if (Lang.isString(type)) {
        _shape = type
        if (type === 'diamond') _shape = 'rect'
    }
    if (Lang.isObject(attrs)) {
        _tooltip = attrs.label.text
    }

    const cutLabel = fmtLabelOverflow(_tooltip)

    return {
        type: _shape,
        label: cutLabel,
        x: _x,
        y: _y,
        width: _width,
        height: _height,
        id,
        actionType: _actionType,
        data: {
            actionType: _actionType,
            initialization,
            tooltip: _tooltip
        }
    }
}

/**
 * 获取多边形node节点
 */
export function getEllipseNode (node) {
    const { type, label, x, y, width, height, id, actionType, data } = getBaseConfig(node)
    // 主题色
    const targetTheme = getActionTypeTheme(actionType)
    return {
        id,
        shape: type, // 指定使用何种图形，默认值为 'rect'
        width,
        height,
        x,
        y,
        zIndex: 100,
        data,
        attrs: {
            label: {
                text: label,
                fill: "#7D7671",
                strokeWidth: 0.4,
                fontSize: 12,
            },
            body: {
                stroke: targetTheme.border,
                strokeWidth: 1.5,
                fill: targetTheme.background,
            }
        },
        ports: {
            items: [
                { group: 'port_g', id: 'p_bottom' },
            ],
            groups: {
                port_g: {
                    attrs: {
                        circle: {
                            dataClass: 'choice-point',
                            r: 6,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff'
                        },
                    },
                    position: 'bottom'
                }
            }
        },
    }
}

//TODO 创建DEMO svg组件  2021-10-04 23:28:08

/**
 * 获取矩形node节点
 */
export function getRectNode (node) {
    const { type, label, x, y, width, height, id, actionType, data } = getBaseConfig(node)
    // 主题色
    const targetTheme = getActionTypeTheme(actionType)
    return {
        id,
        shape: type, // 指定使用何种图形，默认值为 'rect'
        width,
        height,
        x,
        y,
        zIndex: 100,
        markup: [{
            tagName: 'rect',
            selector: 'body',
        }, {
            tagName: 'text',
            selector: 'label',
        }],
        data,
        attrs: {
            label: {
                text: label,
                fill: "#7D7671",
                strokeWidth: 0.4,
                fontSize: 12,
            },
            body: {
                stroke: targetTheme.border,
                strokeWidth: 1.5,
                fill: targetTheme.background,
            }
        },
        ports: {
            items: [
                { group: 'port-top', id: 'p_top' },
                { group: 'port-bottom', id: 'p_bottom' },
            ],
            groups: {
                "port-top": {
                    position: 'top',
                    zIndex: 20,
                    attrs: {
                        circle: {
                            dataClass: 'choice-point',
                            r: 6,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff'
                        }
                    }
                },
                "port-bottom": {
                    position: 'bottom',
                    zIndex: 20,
                    attrs: {
                        circle: {
                            dataClass: 'choice-point',
                            r: 6,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff'
                        }
                    }
                }
            }
        },
    }
}

/**
 * 获取菱形node节点
 */
export function getDiamondNode (node) {
    const { type, label, x, y, id, actionType, data } = getBaseConfig(node)
    // 主题色
    const targetTheme = getActionTypeTheme(actionType)
    return {
        id,
        shape: type, // 指定使用何种图形，默认值为 'rect'
        width: 50,
        height: 50,
        x,
        y,
        zIndex: 100,
        markup: [{
            tagName: 'rect',
            selector: 'body'
        }, {
            tagName: 'text',
            selector: 'label',
        }],
        data,
        attrs: {
            label: {
                text: label,
                fill: "#7D7671",
                strokeWidth: 0.4,
                fontSize: 12,
            },
            body: {
                transform: "rotate(-45,25,25)",
                stroke: targetTheme.border,
                strokeWidth: 1.5,
                fill: targetTheme.background,
                rx: 5, // 属性用于定义水平轴向的圆角半径尺寸。
                ry: 5,
            }
        },
        ports: {
            items: [
                { group: 'in', id: 'p_top' },
                { group: 'out', id: 'p_bottom' },
            ],
            groups: {
                in: {
                    attrs: {
                        circle: {
                            dataClass: 'choice-point',
                            r: 6,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff',
                            // 上平移
                            transform: 'translate(0, -7)'
                        }
                    },
                    position: 'top'
                },
                out: {
                    attrs: {
                        circle: {
                            dataClass: 'choice-point',
                            r: 6,
                            magnet: true,
                            stroke: '#5b8ffa',
                            strokeWidth: 1,
                            fill: '#fff',
                            // 下平移
                            transform: 'translate(0, 7)'
                        }
                    },
                    position: 'bottom'
                }
            }
        },
    }
}

function getNodeJSON (nodes) {
    const nodeList = []
    for (const node of nodes) {
        const nodeJSON = fmtJSON(node)
        // 兼容G6
        const { SVG_IMAGE, TRIGGER, CONDITION, ACTION, SVG_TAG, SVG_HTML, SVG_EDGE } = ActionType;
        const actionType = nodeJSON.data.actionType
        if (!actionType) {
            Channel.dispatchEvent(CustomEventTypeEnum.RUNTIME_ERR, ErrorClass.InvalidFormatData('缺少ActionType'))
            throw new ErrorClass.InvalidFormatData('缺少ActionType')
        }
        switch (actionType) {
            case SVG_IMAGE:
                nodeList.push(getSvgNodeByTag(nodeJSON))
                break;
            // 触发器
            case TRIGGER:
                nodeList.push(getEllipseNode(nodeJSON))
                break;
            // 状态条件
            case CONDITION:
                nodeList.push(getDiamondNode(nodeJSON))
                break;
            // 执行动作
            case ACTION:
                nodeList.push(getRectNode(nodeJSON))
                break;
            default:
                break;
        }
    }
    return nodeList
}

/**
 * 反序列化
 * 按照指定的 JSON 数据渲染节点和边。
 */
export function fromJSON (graph, nodes, edges) {
    if (!Lang.isArray(nodes) || !Lang.isArray(edges)) {
        Channel.dispatchEvent(CustomEventTypeEnum.RUNTIME_ERR, ErrorClass.InvalidParameters('节点或者边数据格式不正确'))
        throw new ErrorClass.InvalidParameters('节点或者边数据格式不正确')
    }
    graph.fromJSON({
        nodes: getNodeJSON(nodes),
        edges: fmtJSON(edges)
    });
}

/**
 * 序列化
 */
export function toJSON (graph) {
    const edges = [], nodes = [], edgesJSON = [], nodesJSON = []
    const cells = graph.getCells()
    if (cells.length) {
        for (const cell of cells) {
            const json = cell.toJSON()
            if (cell.isEdge()) {
                edgesJSON.push(json)
                edges.push(JSON.stringify(json))
            }
            if (cell.isNode()) {
                // 把省略符号去掉
                json.attrs.label.text = json.data.tooltip
                nodesJSON.push(json)
                nodes.push(JSON.stringify(json))
            }
        }
    }
    return { nodes, edges, nodesJSON, edgesJSON }
}
