<template>
    <div>
        <div class="echartSingel" id="myPNGplace3">
            <!-- 图表 -->
            <div class="chart">
                <div v-if="!lineChartShow">
                    <EchartAreaMap ref="lineCharts" />
                </div>
                <div v-else>
                    <EchartBarMap ref="lineCharts" />
                </div>
                <!-- <EchartBarMap ref="lineCharts" /> -->
            </div>
            <!-- 图表的顶栏 -->
            <div class="tabBarPlace1">
                <div class="tabBarTop">
                    <div class="word">工作/非工作能耗分析</div>
                    <!-- 下载的下拉框 -->
                    <el-dropdown @command="downLoadPng" :disabled="(this.$store.state.storage.localtionId && this.$store.state.storage.localtionId != 0) ? false : true" class="img1" style="z-index:99999">
                        <span class="el-dropdown-link">
                            <img style="width:1.8rem;height:1.8rem" src="@/assets/ElecManagement/Download.png" alt="" />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="['pic', '工作/非工作能耗分析']">图片下载</el-dropdown-item>
                            <el-dropdown-item :command="['excel', '工作/非工作能耗分析']">Excel下载</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <img v-show="barChartShow" @click="changeBar()" class="img2" src="@/assets/ElecManagement/BarChart.png" alt />
                    <img v-show="barChartShowGreen" class="img2" src="@/assets/ElecManagement/BarChartGreen.png" alt />
                    <img v-show="lineChartShow" @click="changeLine()" class="img3" src="@/assets/ElecManagement/LineChart.png" alt />
                    <img v-show="lineChartShowGreen" class="img3" src="@/assets/ElecManagement/LineChartGreen.png" alt />
                </div>
                <div class="tabBarBottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from "html2canvas";
import EchartBarMap from "./EchartBarMap.vue";
import EchartAreaMap from "./EchartAreaMap.vue";
import { exportEnergyAnalyzeWorkandrestReport } from "@/api/repair"
export default {
    name: "MymapNo2",
    props: {
        name: {
            type: String,
            default: () => ''
        },
    },
    components: { EchartAreaMap, EchartBarMap },

    data () {
        return {
            // 按钮的样式
            btnTypeDay: "btnGreen",
            btnTypeMonth: "btnDark",
            btnTypeYear: "btnDark",
            // 控制显示icon以及图表的显示
            lineChartShow: true,
            lineChartShowGreen: false,
            barChartShow: false,
            barChartShowGreen: true,

            whichTab: 0,
            optionData: {},
            showInterval: true,
            dateSpan: "2021-12",
            locationId: "",
            month: "",
        };
    },
    created () {
        this.selectDate();
    },
    watch: {
        whichTab: {
            immediate: true,
            handler (val) {
                // 折线图部分的伪造数据#############################################################
                if (this.showInterval) {
                    let optionData = {};
                    if (val === 0) {
                        let legendData = ["宿舍一楼", "宿舍三楼", "办公室一楼"];
                        optionData = {
                            legendData: legendData,
                            xAxisData: ["10-01", "10-02", "10-03", "10-04", "10-05", "10-06", "10-07"],
                            seriesData: [
                                {
                                    name: "宿舍一楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                            { offset: 1, color: "rgba(54, 115, 232, 0)" },
                                        ]),
                                    },
                                    data: [350, 240, 101, 134, 90, 230, 210],
                                },
                                {
                                    name: "宿舍三楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(97, 184, 255, 0.2)" },
                                            { offset: 1, color: "rgba(97, 184, 255, 0)" },
                                        ]),
                                    },
                                    data: [220, 260, 191, 234, 290, 330, 310],
                                },
                                {
                                    name: "办公室一楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(245, 183, 99, 0.2)" },
                                            { offset: 1, color: "rgba(245, 183, 99, 0)" },
                                        ]),
                                    },
                                    data: [150, 180, 201, 154, 190, 330, 240],
                                },
                            ],
                        };
                    } else if (val === 1) {
                        let legendData = ["宿舍一楼", "宿舍三楼", "办公室一楼"];
                        optionData = {
                            legendData: legendData,
                            xAxisData: [
                                "一月",
                                "二月",
                                "三月",
                                "四月",
                                "五月",
                                "六月",
                                "七月",
                                "八月",
                                "九月",
                                "十月",
                                "十一月",
                                "十二月",
                            ],
                            seriesData: [
                                {
                                    name: "宿舍一楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                            { offset: 1, color: "rgba(54, 115, 232, 0)" },
                                        ]),
                                    },
                                    data: [350, 134, 90, 230, 210, 240, 101, 290, 330, 310, 191, 234],
                                },
                                {
                                    name: "宿舍三楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(97, 184, 255, 0.2)" },
                                            { offset: 1, color: "rgba(97, 184, 255, 0)" },
                                        ]),
                                    },
                                    data: [220, 260, 191, 234, 290, 330, 310, 90, 230, 210, 240, 101],
                                },
                                {
                                    name: "办公室一楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(245, 183, 99, 0.2)" },
                                            { offset: 1, color: "rgba(245, 183, 99, 0)" },
                                        ]),
                                    },
                                    data: [150, 180, 201, 154, 190, 330, 240, 260, 191, 90, 230, 210],
                                },
                            ],
                        };
                    } else if (val === 2) {
                        let legendData = ["宿舍一楼", "宿舍三楼", "办公室一楼"];
                        optionData = {
                            legendData: legendData,
                            xAxisData: ["2017", "2018", "2019", "2020", "2021"],
                            seriesData: [
                                {
                                    name: "宿舍一楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                            { offset: 1, color: "rgba(54, 115, 232, 0)" },
                                        ]),
                                    },
                                    data: [350, 134, 90, 230, 210],
                                },
                                {
                                    name: "宿舍三楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(97, 184, 255, 0.2)" },
                                            { offset: 1, color: "rgba(97, 184, 255, 0)" },
                                        ]),
                                    },
                                    data: [220, 260, 191, 234, 290],
                                },
                                {
                                    name: "办公室一楼",
                                    type: "line",
                                    smooth: true,
                                    areaStyle: {
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "rgba(245, 183, 99, 0.2)" },
                                            { offset: 1, color: "rgba(245, 183, 99, 0)" },
                                        ]),
                                    },
                                    data: [260, 191, 90, 230, 210],
                                },
                            ],
                        };
                    }
                    this.optionData = optionData;
                } else {
                    // 直方图部分的伪造数据##################################################################################
                    let optionData = {};
                    if (val === 0) {
                        let legendData = ["宿舍一楼", "宿舍三楼", "办公室一楼"];
                        optionData = {
                            legendData: legendData,
                            xAxisData: ["10-01", "10-02", "10-03", "10-04", "10-05", "10-06", "10-07"],
                            seriesData: [
                                {
                                    data: [120, 200, 150, 80, 70, 110, 130],
                                    type: "bar",
                                },
                                {
                                    data: [120, 200, 150, 80, 70, 110, 130],
                                    type: "bar",
                                },
                                {
                                    data: [120, 200, 150, 80, 70, 110, 130],
                                    type: "bar",
                                },
                            ],
                        };
                    } else if (val === 1) {
                        let legendData = ["宿舍一楼", "宿舍三楼", "办公室一楼"];
                        optionData = {
                            legendData: legendData,
                            xAxisData: [
                                "一月",
                                "二月",
                                "三月",
                                "四月",
                                "五月",
                                "六月",
                                "七月",
                                "八月",
                                "九月",
                                "十月",
                                "十一月",
                                "十二月",
                            ],
                            seriesData: [
                                {
                                    data: [12, 200, 150, 80, 70, 110, 130],
                                    type: "bar",
                                },
                                {
                                    data: [120, 20, 150, 80, 70, 110, 130],
                                    type: "bar",
                                },
                                {
                                    data: [120, 20, 150, 8, 70, 110, 13],
                                    type: "bar",
                                },
                            ],
                        };
                    } else if (val === 2) {
                        let legendData = ["宿舍一楼", "宿舍三楼", "办公室一楼"];
                        optionData = {
                            legendData: legendData,
                            xAxisData: ["2017", "2018", "2019", "2020", "2021"],
                            seriesData: [
                                {
                                    data: [120, 150, 8, 70, 110],
                                    type: "bar",
                                },
                                {
                                    data: [20, 150, 80, 110, 130],
                                    type: "bar",
                                },
                                {
                                    data: [20, 150, 70, 110, 13],
                                    type: "bar",
                                },
                            ],
                        };
                    }
                    this.optionData = optionData;
                }
            },
        },
    },

    methods: {
        resizeChart () {
            setTimeout(() => {
                this.$nextTick(() => {
                    this.$refs.lineCharts.resizeChart()
                })
            }, 0)
        },
        formatDate (date) {
            if (date.getFullYear) {
                let y = date.getFullYear()
                let m = date.getMonth() + 1
                m = m < 10 ? '0' + m : m
                let d = date.getDate()
                d = d < 10 ? '0' + d : d
                return y + '-' + m + '-' + d
            } else {
                return date
            }
        },
        // 转化dom节点为图片下载
        downLoadPng (val) {
            if (val[0] == "excel") {
                this.locationId = this.$store.state.storage.localtionId;
                let value = {
                    locationId: this.locationId,
                    month: this.formatDate(this.$refs.lineCharts.dateSpan),
                };
                // this.$store.dispatch("exportAnalysisWorkAndRest", value);
                exportEnergyAnalyzeWorkandrestReport(value).then((response) => {
                    var blob = new Blob([response], {
                        type: "application/vnd.ms-excel,charset=utf-8",
                    }) //创建一个blob对象
                    var a = document.createElement("a") //创建一个<a></a>标签
                    a.href = URL.createObjectURL(blob) // response is a blob
                    a.download = `${this.formatDate(this.$refs.lineCharts.dateSpan)}${this.name}工作/非工作能耗分析.xls` //文件名称
                    a.style.display = "none"
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
            } else {
                var node = document.getElementById("myPNGplace3");
                window.pageYoffset = 0;
                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
                var width = node.offsetWidth;
                var height = node.offsetHeight;
                var scale = 4;
                html2canvas(node, {
                    width: width,
                    heigth: height,
                    backgroundColor: "#113455",
                    dpi: window.devicePixelRatio * 4,
                    scale: scale,
                    X: 0,
                    Y: 0,
                    scrollX: -3,
                    scrollY: 0,
                    useCORS: true,
                    allowTaint: true,
                }).then((canvas) => {
                    var url = canvas.toDataURL();
                    var a = document.createElement("a");
                    a.download = `${this.formatDate(this.$refs.lineCharts.dateSpan)}${this.name}${val[1]}`;
                    var event = new MouseEvent("click");
                    a.href = url;
                    a.dispatchEvent(event);
                });
            }
        },
        show (val) {
            this.month = val;
        },
        // selectDate(dateData) {
        //   let date = new Date(dateData);
        //   let y = date.getFullYear();
        //   let m = date.getMonth() + 1;
        //   m = m < 10 ? "0" + m : m;
        //   let d = date.getDate();
        //   d = d < 10 ? "0" + d : d;
        //   const time = y + "-" + m;
        //   this.dateSpan = time;
        //   this.getSelectTime();
        //   this.drawLine1();
        // },
        // 切换年月日的回调
        // changeTab(index) {
        //   this.whichTab = index;
        //   this.showInterval = false;
        //   if (index === 0) {
        //     this.btnTypeDay = "btnGreen";
        //     this.btnTypeMonth = "btnDark";
        //     this.btnTypeYear = "btnDark";
        //   } else if (index === 1) {
        //     this.btnTypeDay = "btnDark";
        //     this.btnTypeMonth = "btnGreen";
        //     this.btnTypeYear = "btnDark";
        //   } else if (index === 2) {
        //     this.btnTypeDay = "btnDark";
        //     this.btnTypeMonth = "btnDark";
        //     this.btnTypeYear = "btnGreen";
        //   }
        //   setTimeout(() => {
        //     this.showInterval = true;
        //     this.$nextTick(() => {
        //       this.$refs.lineCharts.init();
        //     });
        //   }, 0);
        // },

        // 切换图表类型
        changeBar () {
            this.showInterval = false;
            this.barChartShowGreen = true;
            this.barChartShow = false;
            this.lineChartShow = true;
            this.lineChartShowGreen = false;
            setTimeout(() => {
                this.showInterval = false;
                this.$nextTick(() => {
                    this.$refs.lineCharts.init();
                });
            }, 0);
        },
        changeLine () {
            this.showInterval = false;
            this.barChartShowGreen = false;
            this.barChartShow = true;
            this.lineChartShow = false;
            this.lineChartShowGreen = true;
            setTimeout(() => {
                this.showInterval = true;
                this.$nextTick(() => {
                    this.$refs.lineCharts.init();
                });
            }, 0);
        },
        selectDate () {
            let date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = y + "-" + m;
            this.month = time;
            // this.dateSpan = time;
            // this.getSelectTime();
            this.$store.commit("CompareWorkDate", time);
        },
    },

    mounted () {
        // setTimeout(() => {
        //   this.showInterval = true;
        //   this.$nextTick(() => {
        //     this.$refs.lineCharts.init();
        //   });
        // }, 10);
    },
};
</script>

<style lang="less" scoped>
.echartSingel {
    width: 100%;
    height: 42rem;
    //   width: 58rem;
    background-color: #0d3050;
    border-radius: 0.4rem;
    position: relative;
    // 上半部分的title栏##################################################
    .tabBarPlace1 {
        width: 100%;
        height: 10rem;
        // width: 58rem;
        position: absolute;
        top: 0;
        left: 0;
        .tabBarTop {
            width: 100%;
            height: 5rem;
            // width: 58rem;
            position: absolute;
            top: 0;
            left: 0;
            .word {
                height: 2.2rem;
                font-size: 1.4rem;
                color: #37a5dd;
                line-height: 2.2rem;
                position: absolute;
                top: 2rem;
                left: 2.4rem;
            }
            .select-time {
                position: absolute;
                top: 1.2rem;
                left: 23rem;
                .time {
                    font-size: 1.4rem;
                    color: #fff;
                }
                .seleWid {
                    width: 15rem;
                }
            }
            .btn1 {
                height: 3rem;
                width: 11.6rem;
                position: absolute;
                top: 1.8rem;
                left: 16.8rem;
                background-color: #0b375e;
                border: 0.1rem solid #004579;
                font-size: 1.4rem;
                color: #fff;
                line-height: 3rem;
                padding: 0;
                margin: 0;
                cursor: pointer;
            }
            .dateChoose {
                height: 3rem;
                width: 13.8rem;
                border-radius: 0.2rem;

                position: absolute;
                top: 1.8rem;
                left: 30rem;
                overflow: hidden;
                border: 0.1rem solid #004579;
                box-sizing: border-box;
                display: flex;
                flex-wrap: nowrap;
                color: #fff;
                .btnDark {
                    height: 3rem;
                    width: 4.6rem;
                    background-color: #0b375e;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3rem;
                    text-align: center;
                }
                .btnGreen {
                    height: 3rem;
                    width: 4.6rem;
                    background-color: #027969;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3rem;
                    text-align: center;
                }
            }
            img {
                z-index: 99;
            }
            .img1 {
                height: 1.8rem;
                width: 1.8rem;
                position: absolute;
                top: 2.4rem;
                right: 2.2rem;
                cursor: pointer;
            }
            .img2 {
                height: 1.8rem;
                width: 1.8rem;
                position: absolute;
                top: 2.4rem;
                right: 6rem;
                cursor: pointer;
            }
            .img3 {
                height: 1.8rem;
                width: 1.8rem;
                position: absolute;
                top: 2.4rem;
                right: 10rem;
                cursor: pointer;
            }
        }
    }
    .echart1 {
        width: 100%;
        height: 32rem;
        // width: 58rem;
        // position: absolute;
        // top: 3rem;
        // left: 0;
    }
    .chart {
        width: 100%;
        // width: 59rem;
        height: 43rem;
        // position: absolute;
        // top: 0;
        // left: 0;
        z-index: 10;
    }
}
</style>
