<template>
  <div class="dialogImportFile">
    <el-dialog
      @close="handelClose"
      title="提示"
      :visible.sync="dialogImportFileVisible"
      :close-on-click-modal="false"
      width="22%"
    >
      <el-upload
        class="upload-demo"
        :action="postAddress"
        :on-success="handleSuccess"
        :on-error="handelError"
        :headers="myToken"
        multiple
        accept=".xls,.xlsx"
        :limit="5"
        :on-exceed="handleExceed"
        :file-list="uploadList"
      >
        <el-button size="small" class="confirmButton" type="primary"
          >点击上传</el-button
        >
        <el-button
          v-if="
            ['Customer', 'meter', 'generator'].indexOf(fatherName.name) == -1
          "
          slot="tip"
          class="cancelButton"
          @click="downLoadClick"
          >下载模板</el-button
        >
        <el-dropdown
          v-else
          slot="tip"
          class="cancelButton"
          @command="downLoadCommand"
        >
          <span class="el-dropdown-link">
            下载模板<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in dropdownMenuList[fatherName.name]"
              :key="index"
              :command="item.command"
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <div slot="tip" class="el-upload__tip">
          只能上传excel文件，且不超过100Mb
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import config from '../config/index.js';
import { getCookie } from '@/utils/util.js';

export default {
  name: 'DialogImportFile',
  data() {
    return {
      // 触发我的父组件的名字
      fatherName: '',
      // 上传列表
      uploadList: [],
      // 控制窗口是否显示
      dialogImportFileVisible: false,
      // 请求地址
      postAddress: '',
      // 令牌
      myToken: { Authorization: '没有拿到token' },
      dropdownMenuList: {
        Customer: [
          {
            command: 0,
            name: '配电类和发电类',
          },
          {
            command: 1,
            name: '配电类',
          },
          {
            command: 2,
            name: '发电类',
          },
        ],
        meter: [
          {
            command: 1,
            name: '电能表',
          },
          {
            command: 2,
            name: '电气消防电表',
          },
        ],
        generator: [
          {
            command: 1,
            name: '逆变器',
          },
          {
            command: 2,
            name: '环境监测仪',
          },
          {
            command: 3,
            name: '并网电表',
          },
        ],
      },
    }
  },
  methods: {
    // 父组件触发弹层的显示
    show(fatherName) {
      this.fatherName = fatherName
      this.dialogImportFileVisible = true
      //修改请求地址
      if (fatherName.name === 'Customer') {
        this.postAddress =
          config.baseURL + 'customer/import?ownerId=' + fatherName.ownerId
      } else if (fatherName.name === 'Equipment') {
        this.postAddress =
          config.baseURL + 'device/import?ownerId=' + fatherName.ownerId
      } else if (fatherName.name === 'meter') {
        this.postAddress =
          config.baseURL +
          'energy/meter/import?type=' +
          fatherName.type +
          '&ownerId=' +
          fatherName.ownerId
      } else if (fatherName.name === 'generator') {
        this.postAddress =
          config.baseURL +
          'generator/import?type=' +
          fatherName.type +
          '&ownerId=' +
          fatherName.ownerId
      } else if (fatherName.name === 'power') {
        this.postAddress =
          config.baseURL + 'pedian/meter/import?ownerId=' + fatherName.ownerId
      }
    },
    // 关闭弹层后做重置
    handelClose() {
      this.uploadList = []
      this.$emit('queryData')
    },
    // 点击下载模板
    downLoadClick() {
      // console.log('this.fatherName', this.fatherName)
      this.$parent.downLoad(this.fatherName)
    },
    downLoadCommand(command) {
      this.$parent.downLoad(this.fatherName, command)
    },

    // 文件超出限制数量时的钩子
    handleExceed() {
      this.$message.error('每次只能上传5个文件！')
    },

    //请求成功
    handleSuccess(res) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功！您可以选择继续上传或关闭弹窗')
        this.$emit('refreshList')
      } else {
        let msg = res.message || '发送请求失败:DialogImportFile请求发送错误'
        this.$message.error(msg)
        this.uploadList = []
      }
    },
    // 请求失败
    handelError() {
      this.$message.error('发送请求失败:DialogImportFile请求发送错误')
    },
  },
  mounted() {
    this.myToken = { Authorization: 'Bearer ' + getCookie('token') }
  },
}
</script>

<style lang="less">
// 做限制，防止代码污染
.dialogImportFile {
  // 弹层标题
  .el-dialog .el-dialog__header {
    padding: 0;
    height: 5.4rem;
    line-height: 5.4rem;
    padding-left: 2.4rem;
    font-size: 1.6rem;
    background: linear-gradient(
      225deg,
      rgba(15, 36, 55, 0.0001) 0.03%,
      rgba(0, 115, 222, 0.7) 99.9%
    );
  }
  // 弹层字体
  .el-dialog__title {
    color: #fff;
  }
  // 按钮样式
  .confirmButton {
    background: #027969;
    color: #fff;
    padding: 0;
    width: 10.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    border: 1px solid #027969;
    cursor: pointer;
  }
  .cancelButton {
    background: #0c375e;
    color: #fff;
    padding: 0;
    width: 10.2rem;
    height: 3.2rem;
    text-align: center;
    line-height: 3.2rem;
    border: 0.1rem solid #004579;
    cursor: pointer;
    margin-left: 1.5rem;
  }

  .word {
    height: 2rem;
    line-height: 5rem;
    color: #aab9c6;
    font-size: 1.2rem;
  }
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled) {
  width: auto;
}
</style>
