<template>
  <div class="myWaring">
    <div class="gen-header">
      <div class="form">
        <el-form :inline="true" :model="dataForm" class="demo-form-inline">
          <el-form-item label="电站名称">
            <el-select class="styleWid" size="mini" v-model="dataForm.powerName" placeholder="请选择电站">
              <el-option v-for="item in powerNameList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择设备">
            <el-select class="styleWid" size="mini" v-model="dataForm.equipment" placeholder="选择设备">
              <el-option v-for="item in equipmentList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="告警类型">
            <el-select class="styleWid" size="mini" v-model="dataForm.waringType" placeholder="选择设备">
              <el-option v-for="item in waringTypeList" :key="item.id" :label="item.lable" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="统计时间">
            <el-date-picker
              class="timeWid"
              v-model="waringTime"
              size="mini"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button class="btnColor" size="mini" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格1 -->
    <div v-show="showTable1" class="gen-content">
      <div class="powerTable">
        <div class="table">
          <el-table
            :data="tableData"
            style="width: 100%"
            stripe:false
            height="68rem"
            border:false
            :cell-style="cellStyle"
            :header-cell-style="headerClass"
            :default-sort="{ prop: 'date', order: 'descending' }"
          >
            <el-table-column prop="time" show-overflow-tooltip label="告警时间"></el-table-column>
            <el-table-column prop="name" show-overflow-tooltip label="电站名称"></el-table-column>
            <el-table-column prop="sbName" show-overflow-tooltip label="电站地址"></el-table-column>
            <el-table-column prop="pvPower" show-overflow-tooltip label="设备类型"></el-table-column>
            <el-table-column prop="pvPower" show-overflow-tooltip label="设备名称"></el-table-column>
            <el-table-column prop="pvPower" show-overflow-tooltip label="警告类型"></el-table-column>
            <el-table-column prop="pvPower" show-overflow-tooltip label="告警内容"></el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row.id)" type="text" size="small">确认</el-button>
                <!-- <el-button @click="handleWork(scope.row.id)" type="text" size="small">工单</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页器部分 -->
        <div class="pagination">
          <el-pagination
            background
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[10, 20, 40, 80]"
            :page-size="pageSize2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="customerTotalNum2"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg";

export default {
  name: "generalPower",
  mixins: [tableBg],
  data() {
    return {
      dataForm: {
        user: "",
        region: "",
        powerName: "",
        staTime: "",
        equipment: "",
        waringType: "",
      },
      waringTime: "",
      showTable1: true,
      showTable2: false,
      powerNameList: [
        {
          id: "1",
          label: "电站一",
        },
        {
          id: "2",
          label: "电站二",
        },
      ],
      equipmentList: [
        {
          id: "1",
          label: "设备一",
        },
        {
          id: "2",
          label: "设备二",
        },
      ],
      waringTypeList: [
        {
          id: "1",
          lable: "信号",
        },
        {
          id: "2",
          lable: "通讯",
        },
      ],
      staTimeList: [],
      tableData: [
        {
          time: "2021/11/08   22:00:10",
          name: "中南财大",
          sbName: "武汉市洪山区珞狮路205号",
          pvPower: "0.00",
          inverPower: "2.00",
          intalPower: "2.00",
          money: "2.00",
        },
        {
          time: "2021/11/08   22:00:10",
          name: "中南财大",
          sbName: "武汉市洪山区珞狮路205号",
          pvPower: "0.00",
          inverPower: "3.00",
          intalPower: "3.00",
          money: "3.00",
        },
        {
          time: "2021/11/08   22:00:10",
          name: "中南财大",
          sbName: "武汉市洪山区珞狮路205号",
          pvPower: "0.00",
          inverPower: "4.00",
          intalPower: "4.00",
          money: "4.00",
        },
        {
          time: "2021/11/08   22:00:10",
          name: "中南财大",
          sbName: "武汉市洪山区珞狮路205号",
          pvPower: "0.00",
          inverPower: "5.00",
          intalPower: "5.00",
          money: "5.00",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    onSubmit() {},
    outFile() {},
    handleClick(id) {
      this.$message({
        message: "消息提醒",
        type: "success",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myWaring {
  .btnMargin {
    margin-left: 12rem;
  }
  .styleWid {
    width: 18rem;
  }
  .btnColor {
    background: #027969;
  }
  .gen-header {
    width: 143.6rem;
    height: 6.4rem;
    line-height: 6.4rem;
    background: rgba(0, 115, 222, 0.15);
    border-radius: 4px;
    .form {
      width: 100%;
      height: 100%;
      display: inline-block;
      margin-left: 2rem;
      padding-top: 3.2rem;
      transform: translateY(-20%);
    }
  }
  .gen-content {
    width: 143.6rem;
    height: 75.5rem;
    background: rgba(0, 115, 222, 0.15);
    border-radius: 4px;
    margin-top: 1.6rem;
    padding-top: 1.5rem;
    .powerTable {
      margin: 0 2.2rem 0 1.5rem;
      .table {
        height: 68rem;
      }
      .pagination {
        float: right;
        margin-top: 1.5rem;
      }
    }
  }
}
</style>
<style lang="less">
.myWaring {
  .timeWid {
    width: 27rem;
  }
  .el-table {
    overflow: hidden;
  }
  .el-button--primary {
    color: #fff;
    border-color: #027969;
    background-color: #027969;
  }
  margin-top: 1.5rem;
  .el-table tr {
    background: rgba(0, 115, 222, 0.1);
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #0c2f4f;
  }
  .el-table .el-table__cell {
    padding: 1.3rem 0;
  }
  .el-table thead {
    color: #ffffff;
    font-weight: 500;
  }
  .el-table--border th.el-table__cell,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #0c2f4f;
  }
  .el-table thead.is-group th.el-table__cell {
    background: #0c2f4f;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
    border-right: 1px solid #0c2f4f;
  }
  .el-table--border,
  .el-table--group {
    border: 1px solid #0c2f4f;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: #0c2f4f;
    z-index: 1;
  }
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 1.2rem;
  }
  .el-table .caret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 34px;
    width: 0px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
  }
  // 滚动条的宽度
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 5px; // 纵向滚动条 必写
  }
  // 滚动条的滑块
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #606266;
    border-radius: 3px;
  }
  .el-button--text {
    color: #03d9bc;
  }
  .el-button--text:hover {
    color: #03d9bc;
  }
}
</style>
