<template>
    <div class="PageInside1">

        <!-- 表格部分###################### -->
        <div class="table">
            <el-table :data="tableData" stripe:false height="57.8rem" border:false style="width: 100%" :header-cell-style="headerClass" :cell-style="cellStyle">
                <!-- <el-table-column type="index" label="序号" width="50"></el-table-column> -->
                <el-table-column label="序号" width="50">
                    <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * 10}}</template>
                </el-table-column>
                <el-table-column prop="deviceName" label="充电桩名称" min-width="10%" :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column prop="id" label="充电桩编号" min-width="11%" :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column prop="deviceFamily" label="类型" min-width="10%" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span v-show="scope.row.deviceFamily == 0">电动自行车充电桩</span>
                        <span v-show="scope.row.deviceFamily == 1">汽车充电桩</span>
                    </template>
                </el-table-column>
                <el-table-column prop="online" label="在线状态" min-width="9%" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span v-show="scope.row.online == 0">离线</span>
                        <span v-show="scope.row.online == 1">在线</span>
                    </template>
                </el-table-column>
                <el-table-column prop="yesterdayElectric" label="昨日电量(kWh)" min-width="14%" :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column prop="yesterdayCount" label="昨日次数(次)" min-width="12%" :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column prop="yesterdayHours" label="昨日时长(小时)" min-width="14%" :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column prop="address" label="位置" min-width="19%" :show-overflow-tooltip="true"> </el-table-column>

            </el-table>
        </div>
        <!-- 分页器部分 -->
        <div class="pagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="totalNumForPagenation">
            </el-pagination>
        </div>
    </div>

</template>

<script>
import { tableBg } from '@/mixin/tablebg'
import { mapState } from 'vuex'
export default {
    name: 'PageInside1',
    mixins: [tableBg],
    data () {
        return {
            // 驴充充的token令牌
            chargeToken: '',
            // 电站的id双向绑定，也是小区ID
            estateId: '',
            // 双向绑定--总数
            totalNumForPagenation: 0,
            // 双向绑定--每页的个数
            pageSize: 10,
            // 双向绑定--当前页
            currentPage: 1,
            // 表格数据(假数据)
            tableData: [],
        }
    },



    methods: {
        // 发送请求刷新页面
        // refreshThePage(){
        //     this.$store.dispatch('getSystemDepartmentPageData',{pageNum:this.currentPage,pageSize:this.pageSize,keyword:this.searchValue,id:this.treeNodeId}).then(()=>{
        //      this.tableData=[]
        //       for(let i=0;i<this.departmentPageData.length;i++){
        //         const tableSingle = {deptNo:"",name:"",phone:"",parentName:"",status:"",statusName:""}
        //         tableSingle.deptNo = this.departmentPageData[i].deptNo;
        //         tableSingle.name = this.departmentPageData[i].name;
        //         tableSingle.phone = this.departmentPageData[i].phone;
        //         tableSingle.parentName = this.departmentPageData[i].parentName;
        //         tableSingle.status = this.departmentPageData[i].status;
        //         if(this.departmentPageData[i].status==="1"){tableSingle.statusName = "正常"}else{tableSingle.statusName = "冻结"}
        //         this.tableData.push(tableSingle)
        //       }
        //     this.totalNumForPagenation = this.departmentTotal
        //   })},
        getList (val) {
            if (val) { this.chargeToken = val }
            this.$store.dispatch('getChargeMonitoringPageList', {
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                // 调用父组件data中的值
                deviceId: this.$parent.searchValue,
                online: this.$parent.statusData,
                deviceFamily: this.$parent.typeData,
                chargeToken: this.chargeToken,
            })
                .then(() => {

                    const res = this.$store.state.elecCharge.chargeMonitoringPageList
                    if (res.errorCode == 0) {
                        this.tableData = res.data.records
                        this.totalNumForPagenation = res.data.total
                    } else {
                        this.$message(res.message)
                    }
                })
        },


        // 更改分页器每页个数
        handleSizeChange (val) {
            this.pageSize = val
            this.getList()
        },
        // 更改分页器页面
        handleCurrentChange (val) {
            this.currentPage = val
            this.getList()
        },


        //更改token并发送请求
        tokenIn (val) {
            this.currentPage = 1;
            this.chargeToken = val
            this.getList()

        },
        //更改小区ID(电站)并发送请求
        stationIn (val) {
            this.estateId = val
            this.getList()
        },


    },

    mounted () {
        // this.refreshThePage()
    },

    computed: {
        ...mapState({
            departmentPageData: (state) => { return state.system.departmentPageData.data.records },
            departmentTotal: (state) => { return state.system.departmentPageData.data.total },
            ifDepartmentDeleted: (state) => { return state.system.ifDepartmentDeleted.errorCode },
        }),

    },



}
</script>

<style lang="less" scoped>
.PageInside1 {
    // width: 100%;
    overflow: hidden;
}
.table {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    // width: 100%;
    // width: 102.5rem;
    height: 58rem;
}
.pagination {
    float: right;
    margin-right: 2.4rem;
}
</style>