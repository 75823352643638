import { render, staticRenderFns } from "./PatrolGroupSetting.vue?vue&type=template&id=88e2bb14&scoped=true&"
import script from "./PatrolGroupSetting.vue?vue&type=script&lang=js&"
export * from "./PatrolGroupSetting.vue?vue&type=script&lang=js&"
import style0 from "./PatrolGroupSetting.vue?vue&type=style&index=0&id=88e2bb14&lang=less&scoped=true&"
import style1 from "./PatrolGroupSetting.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e2bb14",
  null
  
)

export default component.exports