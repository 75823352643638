<!-- 配电监控的diagram区 -->
<!-- todo：弹层弹出时，定时器就不需要刷新了 -->
<template>
    <div class="app-container">
        <!-- 接线图名称tab -->
        <div class="attr_tabs chart-nav">
            <div class="chart-btn-box">
                <div class="attr_tab_item" :class="whichTab==index ? 'active_tab' :''"
                     v-for="(item, index) in graphTotal" :key="index"
                     @click="handleChangeTab(item, index)">
                    <el-tooltip class="item" effect="dark" :content="item.diagramName" placement="top-start">
                        <!-- 字数上限：96个（不折叠树），112（折叠树） -->
                        <span>{{ limitTabWords ? item.diagramName.substr(0, 12) + '...' : item.diagramName }}</span>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="tab-container">
            <!-- 直流屏 设备信息-->
            <div v-if="origin == '/sysMapdCscreen'" class="basic-detail">
                <el-descriptions title="" direction="vertical" :column="1">
                    <el-descriptions-item label="设备图片">
                        <img v-if="deviceData" :src="deviceData.pictureUrl">
                    </el-descriptions-item>
                    <el-descriptions-item label="型号">{{ deviceData.specification }}</el-descriptions-item>
                    <el-descriptions-item label="生产日期">{{ deviceData.produceDate }}</el-descriptions-item>
                    <el-descriptions-item label="投运日期">{{ deviceData.useDate }}</el-descriptions-item>
                    <el-descriptions-item label="保修截止">{{ deviceData.warrantyDate }}</el-descriptions-item>
                    <el-descriptions-item label="厂家">{{ deviceData.producer }}</el-descriptions-item>
                    <el-descriptions-item label="容量(kVA)">{{ deviceData.capacity }}</el-descriptions-item>
                    <el-descriptions-item label="额定负载(kW)">{{ deviceData.ratedLoad }}</el-descriptions-item>
                    <el-descriptions-item label="预防性试验执行周期">{{ maintainCycleMaps.get(deviceData.maintainCycle) }}</el-descriptions-item>
                </el-descriptions>
            </div>
            <!-- diagram 区域-->
            <div id="container" class="graph-main-container" style="width: 100%; height: calc(100vh - 23.7rem)"></div>
        </div>
        <!-- 详情弹框 -->
        <gx-detail-dialog ref="gxDetail" :showDialog="showDialog" :pointId="pointId" @closeDialog="showDialog = false" />
    </div>
</template>
<script>
import GxDetailDialog from "./GxDetailDialog.vue";
import { getDiagramsByScenarioCode } from "@/api/diagram/main.js";
import { watchDevice, sysMapListApi } from "@/api/diagram/kaiyuan.js";
import { initGraphPreView, freezeGraph } from "@/packages/x6/common/graph-preview.js";
import { Config } from "@/packages/x6/common/enums.js";
import registerTools from "@/packages/x6/tools/registerTools.js";
import { nodesList } from "@/packages/x6-preview/nodes/nodes.js";

export default {
    components: { GxDetailDialog },
    name: 'GxPreview',
    data () {
        return {
            limitTabWords: false, //tab文本过长时是否缩略显示
            showDialog: false, //详情dialog弹层
            graphTotal: [],    //场景绑定了多少个图
            whichTab: 0,       //选择哪个tab
            scale: 0, //接线图的缩放比例
            graph: {},
            navTopList: [],  //
            tooltipsContent: null, //
            resultJson: null, //
            diagramObj: {}, //
            diagramId: this.$route.query.id,
            ponintIds: [],//获取数据id
            relations: [], //?
            intervalId: 0, //定时器ID
            pointId: '',   //
            origin: '',    //路由path
            deviceData: {}, //设备信息
            maintainCycleMaps: new Map([
                [1, '一个月'], ['1', '一个月'],
                [2, '三个月'], ['2', '三个月'],
                [3, '半年'], ['3', '半年'],
                [4, '一年'], ['4', '一年'],
                [5, '两年'], ['5', '两年']
            ]),
        };
    },
    watch: {
        // todo: 它是在路由变化时就执行了init，改成组件内路由守卫来做
        // 它这个搞很容易触发重复监听死循环
        '$route': {
            handler (val) {
                this.origin = val.path;
                if (this.origin === '/sysMapdCscreen') {
                    this.getDeviceData(val.query.id)
                }
                if (val.query.id) {
                    // 注册工具
                    registerTools();
                    this.getGraphData(val.query.id).then(() => {
                        this.graphTotal[0] && this.init(this.graphTotal[0].diagramJson);
                    });
                }
            },
            immediate: true
        },
        ponintIds: {
            handler (newVal) {
                if (newVal.length > 0) {
                    this.takeRelaData();
                }
            },
            immediate: true
        }
    },
    computed: {
        // 可操作去插槽
        panelAreaName () {
            return Config.PANEL_AREA_SLOT;
        },
    },
    methods: {
        // 跳到统计报表
        goReport () {
            this.$router.push({
                path: '/monitoring/report', query: {
                    id: this.$route.query.id,
                    type: 'monitoringChart'
                }
            })
        },
        handleChangeTab (item, index) {
            this.whichTab = index;
            this.init(item.diagramJson);

        },
        // fuck: 什么东西10秒执行一次？
        takeRelaData () {
            let strponintIds = this.ponintIds.join(',');
            watchDevice(strponintIds).then(res => {
                this.relations.forEach(item => {
                    res.data.forEach(itemMeter => {
                        if (item.pointId == itemMeter.meterId) {
                            let svgObjectDom = document.querySelectorAll(
                                `g[data-cell-id="${item.id}"] foreignObject div`
                            );
                            let name = item.moudle.indexOf('>') !== -1 ? item.moudle.split('>')[1] : item.moudle
                            if (item.actionType == 'SVG_FONT') {
                                svgObjectDom[0].innerText = (itemMeter.points[name] === 0 || (itemMeter.points[name] !== '' && itemMeter.points[name] !== undefined)) ? itemMeter.points[name] : '--';
                            } else if (name.indexOf('开关状态') != -1) {
                                if (itemMeter.points[name] == 1 && item.html !== '6_1_dlq' && item.html !== '6_1_sbzt' && item.html !== '1_dlq' && item.html !== '1_sbzt' && item.html !== '6_1_dlqzt') {
                                    if (nodesList[`html_${item.html}C`]) {
                                      svgObjectDom[0].innerHTML = nodesList[`html_${item.html}C`]
                                    }
                                } else if (itemMeter.points[name] == 0) {
                                    if (nodesList[`html_${item.html}`]) {
                                      svgObjectDom[0].innerHTML = nodesList[`html_${item.html}`]
                                    }
                                }
                                let svgDom = document.querySelectorAll(
                                    `g[data-cell-id="${item.id}"] svg`
                                )[0].children;
                                svgDom[0].setAttribute(
                                    "stroke-width",
                                    item.cell.attrs.props?.strokeWidth
                                        ? item.cell.attrs.props.strokeWidth
                                        : "1"
                                );
                                // svgDom[0].setAttribute("x2", item.cell.store.data.size.width);
                                // svgDom[0].setAttribute(
                                //         "y2",
                                //         19 + parseInt(item.cell.attrs.props["stroke-width"])
                                // );
                                // svgDom[0].setAttribute(
                                //         "y1",
                                //         19 + parseInt(item.cell.attrs.props["stroke-width"])
                                // );
                                let svgObjectDomChildren = svgDom[0].children;
                                for (var i = 0; i < svgObjectDomChildren.length; i++) {
                                    svgObjectDomChildren[i].setAttribute("stroke", item.cell.attrs.props.stroke);
                                    if (svgObjectDomChildren[i].getAttribute('fill') !== 'none') {
                                        svgObjectDomChildren[i].setAttribute("fill", item.cell.attrs.props.stroke);
                                    }
                                }
                            }
                            let gObjectDom = document.querySelectorAll(
                                `g[data-cell-id="${item.id}"] svg`
                            );
                            if (gObjectDom[0]) {
                                let svgObjectDomChildren = gObjectDom[0].children[0].children;
                                let color = '';
                                if (itemMeter.points[name] == 0) {
                                    color = '#00ff00'
                                } else if (itemMeter.points[name] == 1) {
                                    color = '#ff0000'
                                }
                                if (itemMeter.points[name] != null) {
                                    for (var i = 0; i < svgObjectDomChildren.length; i++) {
                                        svgObjectDomChildren[i].setAttribute("stroke", color);
                                        if (`html_${item.html}` == 'html_6_1_dlq' && color) {
                                            svgObjectDomChildren[i].setAttribute("fill", color);
                                        } else if (svgObjectDomChildren[i].getAttribute('fill') !== 'none') {
                                            svgObjectDomChildren[i].setAttribute("fill", color);
                                        }
                                    }
                                }
                            }
                        }
                    })
                })
            })
        },
        // 遍历处理graph中的每一个cell
        processGraphCells(graph){
            let cells = graph.getCells();
            console.log('遍历处理graph中的每一个cell', cells);
            let _this = this;
            if(!cells.length){
                return;
            }
            let minTop = 0, minLeft = 0, maxDown = 0, maxRight = 0;
            this.ponintIds = [];
            this.relations = [];
            cells.forEach(currentCell => {
                const cellData = currentCell.store.data;
                if (currentCell.isNode()) {
                    // 计算画布显示位置
                    let width = Math.abs(cellData.position.x) + cellData.size.width;
                    let height = Math.abs(cellData.position.y) + cellData.size.height;
                    if (width > minLeft) {
                        minLeft = width;
                    }
                    if (height > minTop) {
                        minTop = height;
                    }
                    if (minTop * 2 <= (window.innerHeight * 0.9) && minLeft * 2 < (window.innerWidth * 0.7)) {
                        graph.resize(window.innerWidth * 0.723, window.innerHeight * 0.9);
                    }
                    // 如果有绑定数据
                    if (cellData.BindData && cellData.BindData.pointId) {
                        if (!this.ponintIds.includes(cellData.BindData.pointId)) {
                            this.ponintIds.push(cellData.BindData.pointId)
                        }
                        this.relations.push({
                            "id": currentCell.id,
                            "actionType": cellData.data.actionType,
                            "moudle": cellData.BindData.moudle,
                            "pointId": cellData.BindData.pointId,
                            "equipmentId": cellData.BindData.equipmentId,
                            "html": currentCell.html,
                            "cell": currentCell
                        })
                    }
                    //
                    if (cellData.data.actionType === "SVG_HTML") {
                        let svgObjectDom = document.querySelectorAll(`g[data-cell-id="${currentCell.id}"] svg`)[0].children;
                        svgObjectDom[0].setAttribute("stroke-width", currentCell.attrs.props?.strokeWidth ? currentCell.attrs.props.strokeWidth : "1");
                        // x1,x2,y1,y2大概是图元的两个点
                        // svgObjectDom[0].setAttribute("x2", cellData.size.width);
                        // svgObjectDom[0].setAttribute("y1", 19 + parseInt(currentCell.attrs.props["strokeWidth"]));
                        // svgObjectDom[0].setAttribute("y2", 19 + parseInt(currentCell.attrs.props["strokeWidth"]));
                        let svgObjectDomChildren = svgObjectDom[0].children;
                        for (var i = 0; i < svgObjectDomChildren.length; i++) {
                            svgObjectDomChildren[i].setAttribute("stroke", currentCell.attrs.props.stroke);
                            if (svgObjectDomChildren[i].getAttribute('fill') !== 'none') {
                                svgObjectDomChildren[i].setAttribute("fill", currentCell.attrs.props.stroke);
                            }
                        }
                    }
                    else if (cellData.data.actionType === "SVG_TAG") {
                        // let lineObjectDom = document.querySelectorAll(`g[data-cell-id="${currentCell.id}"] line`);
                        if (cellData.template == "6_1_dllxy") {
                            currentCell.setMarkup([
                                {
                                    tagName: "line",
                                    selector: "line1",
                                    groupSelector: "prop",
                                    attrs: {
                                        x1: "0",
                                        y1: 19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                        x2: cellData.size.width,
                                        y2: 19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                        fill: "none",
                                    },
                                },
                                {
                                    tagName: "polygon",
                                    selector: "polygon1",
                                    groupSelector: "prop",
                                    attrs: {
                                        points: cellData.size.width - 4 - parseInt(currentCell.attrs.prop["stroke-width"]) +
                                            "," + 17 + " " + cellData.size.width +
                                            "," + (19 + parseInt(currentCell.attrs.prop["stroke-width"])) + " " + (cellData.size.width - 4 - parseInt(currentCell.attrs.prop["stroke-width"])) +
                                            "," + (21 + 2 * parseInt(currentCell.attrs.prop["stroke-width"])),
                                        fill: "#ffffff",
                                    },
                                },
                            ]);
                        } else {
                            currentCell.setMarkup([
                                {
                                    tagName: "line",
                                    selector: "line1",
                                    groupSelector: "prop",
                                    attrs: {
                                        x1: "0",
                                        y1: 19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                        x2: cellData.size.width,
                                        y2: 19 + parseInt(currentCell.attrs.prop["stroke-width"]),
                                        fill: "none",
                                    },
                                },
                            ]);
                            currentCell.attr("line", {
                                stroke: currentCell.attrs.prop.stroke,
                                strokeWidth: currentCell.attrs.prop["stroke-width"],
                            });
                        }
                    } else if (cellData.data.actionType == "SVG_FONT") {
                        // console.log(currentCell, 'currentCell')
                        let svgObjectDom = document.querySelectorAll(`g[data-cell-id="${currentCell.id}"] foreignObject div`);
                        if(svgObjectDom[0]){
                            svgObjectDom[0].innerText = cellData.attrs.body.content;
                            svgObjectDom[0].style.color = cellData.attrs.body.color;
                            svgObjectDom[0].style.fontSize = cellData.attrs.body.fontSize + "px";
                            svgObjectDom[0].style.fontWeight = cellData.attrs.body.fontWeight;
                            svgObjectDom[0].style.lineHeight = cellData.size.height + 'px';
                            svgObjectDom[0].style.textAlign = 'left';
                            svgObjectDom[0].style.whiteSpace = 'nowrap';
                        }

                    }
                }
                else if (currentCell.isEdge()) {
                    if (!cellData.attrs.props) {
                        cellData.attrs.props = {
                            stroke: "#000000",
                            strokeWidth: 1,
                        };
                    }
                    currentCell.attr("line", {
                        stroke: cellData.attrs.props.stroke,
                        strokeWidth: cellData.attrs.props.strokeWidth,
                    });
                }
                currentCell.removeTools();
            });
        },
        async getDeviceData (nodeId) {
            const res = await sysMapListApi(1, 1, nodeId);
            this.deviceData = res.data.records[0] ? res.data.records[0] : {};
        },
        async getGraphData (diagramCode) {
            const res = await getDiagramsByScenarioCode({ diagramCode });
            if (res.code === 200 && res.data) {
                let tabWordsCount = 0;
                res.data.forEach(obj => tabWordsCount += obj.diagramName.length);
                this.limitTabWords = tabWordsCount > 100;
                this.graphTotal = res.data;
                this.scale = res.data[0]?.scale;
                if (!res.data.length) {
                    this?.graph?.dispose && this?.graph?.dispose();
                }
            }
        },
        /**
         * 初始化接线图
         * @param jsonData {"cells":[{}, {}, ...]}
         * @returns {Promise<void>}
         */
        async init (jsonData) {
            // console.log("传入的JSON数据", jsonData);
            // 先销毁旧的graph
            if (this.graph?.dispose) {
                this.graph?.dispose();
            }
            // Vue的X6对new Graph()做的封装
            this.graph = initGraphPreView(this);
            const { graph } = this;
            //节点绑定点击事件
            graph.on('node:click', ({ e, x, y, node, view }) => {
                if (node.store.data.BindData && node.store.data.BindData.pointId) {
                    this.pointId = node.store.data.BindData.pointId;
                    this.showDialog = true;
                }
            });
            graph.fromJSON(JSON.parse(jsonData));
            this.processGraphCells(graph);
            // 其他设置
            graph.centerContent();
            graph.zoom(this.scale);
            graph.enablePanning();
            graph.disableClipboard();
            graph.disableHistory();
            freezeGraph(graph);
            //todo：什么是relations
            //console.log('relations', this.relations);
        },
    },
    created () {
        let that = this;
        this.intervalId = setInterval(function() {
            that.takeRelaData()
        }, 10000);
        window.needRefresh = true;
    },
    beforeDestroy () {
        clearInterval(this.intervalId);
        //unregister不管用
        //import('@/packages/x6-preview/nodes/unregister-nodes.js');
    },

};
</script>
<style scoped lang="less">
/deep/.x6-graph-scroller {
    width: 100% !important;

  height: calc(100vh - 23.7rem) !important;
  overflow: scroll !important;
}
.app-container {
  width: 100%;
  height: calc(100% - 20rem);
  .tab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .basic-detail {
      flex-shrink: 0;
      margin-right: 1rem;
      width: 25rem;
      height: calc(100vh - 23.7rem);
      padding-top: 1.5rem;
      padding-right: 2rem;
      border-right: 0.2rem solid rgba(7, 82, 150, 0.25);
      box-sizing: border-box;
      /deep/ .el-descriptions {
        .el-descriptions__title {
          color: #03d9bc;
        }
        .el-descriptions__body {
          color: hsla(0,0%,100%,.5);
          background-color: transparent;
          .el-descriptions-item__label {
            color: #fff;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
#components-layout .ant-layout-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    line-height: 50px;
    height: 50px;
    background: #fff;
    color: rgb(0, 0, 0);
    z-index: 2;
    box-shadow: 1px 1px 5px #ddd;
}

.pageMain {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    overflow: auto;
}

.leftNav {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    min-width: unset !important;
    max-width: unset !important;
    width: 260px !important;
    z-index: 1;
    overflow: auto;
    margin: 12px 20px 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 8px #888888;
    height: 96%;
    overflow: hidden;
}

.centerContain {
    position: absolute;
    left: 290px;
    right: 300px;
    top: 0;
    bottom: 0;
    z-index: 1;
    overflow: auto !important;
    overflow-x: auto !important;
    transition: all 0.3s;
    z-index: 111;
    width: 1535px;
    margin: 75px 14px 20px;

    .graph-main-container {
        width: 100%;
        height: 100%;
        flex: 1;
    }

    // tooltip
    #tooltip-container {
        // 禁止被双击||ctrl a选中
        user-select: none;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        font-size: 12px;
        color: #545454;
        width: 350px;
        word-break: break-all;
        background-color: rgba(255, 255, 255, 0.9);
        // padding: 10px 8px;
        box-shadow: rgb(174, 174, 174) 0px 0px 10px;
        position: absolute;
        z-index: 1;
        display: none;
        padding: 10px;
    }

    & .fixed {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .canvas-content {
        width: 1920px;
        height: 1080px;
    }

    .btns-content {
        position: fixed;
        bottom: 10px;
        right: 320px;
        left: 280px;

        button {
            margin-left: 10px;
        }
    }
}

.rightNav {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    min-width: unset !important;
    max-width: unset !important;
    width: 300px !important;
    z-index: 1;
    overflow: auto;
}

.icon-shrink {
    position: fixed;
    right: 20px;
    top: 5px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    background: #1890ff;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    user-select: none;
    z-index: 1111;
}

#components-layout .ant-layout-sider {
    background: #fff;
    color: rgb(167, 164, 164);
}

#canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.ant-slider {
    margin-bottom: 16px;
}

.topo-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-origin: left top;
    overflow: auto;

    background-image: linear-gradient(45deg,
    #ccc 25%,
    transparent 25%,
    transparent 75%,
    #ccc 75%,
    #ccc),
    linear-gradient(45deg,
    #ccc 25%,
    transparent 25%,
    transparent 75%,
    #ccc 75%,
    #ccc);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
}

.topo-layer-view-selected {
    outline: 1px solid #0cf;
}

.centerTop {
    border-radius: 15px;
    box-shadow: 0px 0px 8px #888888;
    width: 1530px;
    display: flex;
    justify-content: space-between;
    height: 50px;
    position: absolute;
    left: 295px;
    top: 5px;
    margin: 8px;
    padding-right: 29px;

    .leftTitle {
        width: 80%;
    }

    .leftTitle > span {
        width: 25%;
        height: 50px;
        line-height: 50px;
        display: inline-block;
        /*// background: url("@/assets/images/backgroundTitle.png");*/
        border-right: 1px solid #c9d7f8;
        padding-left: 23px;
        font-size: 16px;

        [class^="el-icon-"],
        [class*=" el-icon-"] {
            float: right;
            height: 50px;
            line-height: 50px;
            margin-right: 20px;
            font-size: 16px;
            /*// display: none;*/
        }
    }

    .rightButton {
        width: 25%;
        text-align: right;
        height: 50px;
        line-height: 50px;
    }
}

.btn {
    font-size: 16px;
    width: 80px;
    left: 36px;
}

#canvas .operate {
    height: 54px;
    line-height: 54px;
    margin: 31px 0 0 33px;
    position: fixed;
    top: 127px;
    left: 366px;
    z-index: 9;
    border-radius: 10px;
    background: #fff;
}

#canvas .operate > ul {
    overflow: hidden;
    margin: 10px auto;
    padding: 0 10px;
}

#canvas .fl {
    float: left;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    list-style: none;
    border: 1px dashed #ccc;
    cursor: pointer;
}

#canvas .prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 366px;
    height: 25px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3674ff;
    line-height: 107px;
    display: none;
}

.v-modal {
    display: none;
}
</style>
