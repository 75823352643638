<template>
    <div class="DialogForTree">
        <!--el树形控件--输入框  -->
        <div class="inputContainer">
            <el-input size="small" placeholder="请输入搜索关键字" v-model="filterText" style="width:22.5rem;" clearable></el-input>
        </div>
        <!--el树形控件--树  -->
        <div class="treeContainer">
            <el-tree :check-strictly="true" :check-on-click-node="true" :expand-on-click-node="false" @node-click="chooseTree" highlight-current class="filter-tree elTree" :data="treeData" :props="defaultProps" default-expand-all :filter-node-method="filterNode" :render-content="renderContent" ref="tree">
            </el-tree>
            <div class="blank"></div>
        </div>
        <button @click="handelCancel" class="cancelBtn">取消</button>
        <button @click="handelConfirm" class="confirmBtn">确定</button>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'DialogForTree',
    data () {
        return {
            // 节点数据
            nodeData: "",
            // 是否显示自己
            ifDialogTreeShow: false,
            // 树的过滤词
            filterText: '',
            // 树的内容
            treeData: [],
            // 树的参数，勿动
            defaultProps: { children: 'children', label: 'name' }
        }
    },


    methods: {
        filterNode (value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },

        // 父组件或者兄弟组件要求刷新树
        refreshTheTree () {
            this.$store.dispatch('getCustomerTree').then(() => {
                this.treeData = this.customerTree
            })
        },




        // 点击确认
        handelConfirm () {
            if (this.nodeData === "") {
                this.$message.error('请选择一个树节点！')
            } else {
                this.$parent.dialogLocationShow(this.nodeData)
                this.$parent.dialogTreeNotShow()
            }

        },
        // 点击取消
        handelCancel () {
            this.$parent.dialogTreeNotShow()
        },

        // 点击树的分支
        chooseTree (val) {
            this.nodeData = val

        },
        // 树的提示语
        renderContent (h, { node, data, store }) {
            if (node.label.length > 13) {
                return (<div style="font-size: 1.4rem;">
                    <el-tooltip class="item" effect="dark" placement="right">
                        <div slot="content" >{node.label}</div>
                        <p style="width:19rem; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;">{node.label}</p>
                    </el-tooltip>
                </div>);
            } else { return (<div style="font-size: 1.4rem;"><p>{node.label}</p></div>) }
        },

    },

    //处理state数据
    computed: {
        ...mapState({
            customerTree: (state) => {
                return state.equipment.customerTree.data
            }
        })
    },

    watch: {
        // // 监听列表值的变化
        // "$store.state.equipment.equipmentDialogTree.data"(val){
        //   this.treeData = val
        // },
        // 监听对树的过滤
        filterText (val) {
            this.$refs.tree.filter(val);
        }
    },


}
</script>

<style lang="less" >
.DialogForTree {
    height: 55rem;
    width: 26rem;
    background-color: #0d3050;
    border-radius: 0.4rem;
    // 确认按钮位置
    .confirmBtn {
        height: 3.2rem;
        width: 7.4rem;
        background-color: #027969;
        color: #fff;
        font-size: 1.4rem;
        border: none;
        position: absolute;
        top: 51rem;
        right: 1rem;
        cursor: pointer;
        border-radius: 0.2rem;
    }
    .cancelBtn {
        height: 3.2rem;
        width: 7.4rem;
        background-color: #0b3d6b;
        border: 0.1rem solid #004579;
        color: #fff;
        font-size: 1.4rem;
        position: absolute;
        top: 51rem;
        right: 9rem;
        cursor: pointer;
        border-radius: 0.2rem;
    }
    // 输入框位置
    .inputContainer {
        height: 6.8rem;
        width: 26rem;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .elInput {
            height: 3.2rem;
            width: 22.8rem;
            position: absolute;
            top: 2.4rem;
            left: 1.6rem;
            font-size: 1.4rem;
            background-color: #0c375e;
            border: 0.1rem solid #004579;
            box-sizing: border-box;
            border-radius: 0.2rem;
            text-indent: 1.2rem;
            color: #fff;
            outline: 0;
            &:focus {
                border: 0.1rem solid #008080;
            }
        }
    }
    // 树的位置
    .treeContainer {
        height: 46rem;
        width: 27rem;
        // position: absolute;
        // top: 6.8rem;
        // left: 0;
        overflow: scroll;
        .blank {
            height: 10rem;
        }
        .elTree {
            background-color: #0d3050;
            color: rgba(255, 255, 255, 0.85);

            .el-tree-node__label {
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-family: PingFang SC;
                height: 2.2rem;
                // 禁止选中文字
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Chrome/Safari/Opera */
                -khtml-user-select: none; /* Konqueror */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;
            }
            .el-tree-node__content:hover {
                background-color: #204b73;
            }
            .el-tree-node:focus > .el-tree-node__content {
                // background-color: #18191a;
                background-image: linear-gradient(to right, #0d3050, #18191a);
                // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
                // box-sizing: border-box;
            }
        }
    }
    // 持续高亮(鼠标离开树以后)
    .el-tree--highlight-current
        .el-tree-node.is-current
        > .el-tree-node__content {
        background-image: linear-gradient(to right, #0d3050, #18191a);
        // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
        // box-sizing: border-box;
    }
}
</style>
