
<template>
  <div class="DialogAlarmSettingConnect">
    <el-dialog :visible.sync="dialogFormVisible" width="50.2%" :close-on-click-modal="false">
      <div slot="title">
        <div class="equip_title">关联监测点</div>
      </div>
      <!-- 顶部栏 -->
      <div class="topBar">
        <div :class="topBarPlace === 1 ? 'wordGreen' : 'wordGrey'" @click="changeTabBar(1)">全部</div>
        <div :class="topBarPlace === 2 ? 'wordGreen' : 'wordGrey'" @click="changeTabBar(2)">已关联</div>
      </div>

      <!-- 板块一######################################################################################## -->
      <div class="blockOne">
        <!-- 树 -->
        <div class="treePlace">
          <el-tree
            :indent="0"
            :check-strictly="true"
            :check-on-click-node="true"
            :expand-on-click-node="false"
            :current-node-key="currentNodeKey"
            @node-click="chooseTree"
            highlight-current
            class=" elTree tree-line"
            :data="treeData"
            node-key="id"
            default-expand-all
            :render-content="renderContent"
            ref="tree"
          >
          </el-tree>
        </div>

        <!-- 表格位置 -->
        <div class="tablePlace">
          <!-- 搜索框 -->
          <button class="btnAdd" @click="search">搜 索</button>
          <el-input
            size="small"
            placeholder="请输入搜索关键字"
            v-model="keyword"
            @keyup.enter.native="search"
            style="width:22.8rem; float:right"
            clearable
            @clear="search"
          ></el-input>

          <!-- 表格 -->
          <div class="table">
            <el-table
              :data="dataShow"
              stripe:false
              height="56.8rem"
              border:false
              style="width: 100%"
              :header-cell-style="headerClass"
              :cell-style="cellStyle"
              v-if="topBarPlace == 2"
            >
              <el-table-column label="序号" width="50">
                <template slot-scope="scope">
                  {{scope.$index + 1 + (currentPage - 1) * pageSize}}
                </template>
              </el-table-column>
              <el-table-column
                class="tab"
                prop="meterName"
                label="电表名称"
                min-width="20%"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                class="tab"
                prop="placeName"
                label="所属位置"
                min-width="16%"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                class="tab"
                prop="deivceOrFloorName"
                label="所属楼层"
                min-width="16%"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
            </el-table>

            <el-table
              :data="dataShow"
              stripe:false
              height="56.8rem"
              border:false
              style="width: 100%"
              :header-cell-style="headerClass"
              :cell-style="cellStyle"
              v-if="topBarPlace == 1"
            >
              <el-table-column class="tab" prop="idRelation" label="选择" min-width="4%" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-checkbox
                    :true-label="1"
                    :false-label="0"
                    v-model="scope.row.idRelation"
                    @change="changeConnect(scope.row)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                class="tab"
                prop="meterName"
                label="电表名称"
                min-width="20%"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                class="tab"
                prop="deivceOrFloorName"
                label="所属设备"
                min-width="16%"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页器部分 -->
          <div class="pagination">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalNumForPagenation"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- <div slot="footer" class="dialog-footer">
    <button class="btn_cancle" @click="handleCancle('ruleForm')">结束编辑</button>
    </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";

export default {
  name: "DialogOne",
  mixins: [tableBg],
  data() {
    return {
      // 搜索内容
      searchValue: "",
      // 是否展示弹层
      dialogFormVisible: false,
      // 顶部选择栏的位置
      topBarPlace: 1,
      // 分页器数据,当前页面
      currentPage: 1,
      // 数据总个数
      totalNumForPagenation: 0,
      // 每页数量
      pageSize: 10,
      // 树的数据
      treeData: [
        // { label: "配电设备", children: [{ label: "配电房1" }, { label: "配电房2" }] },
        // { label: "仪表管理", children: [{ label: "电表1" }, { label: "电表2" }] },
      ],
      currentNodeKey: '',
      // 表格1的数据
      tableData: [
        // { data1: true, data2: "1号电表", data3: "1号变压器", data4: "一号楼", data5: "21F" },
        // { data1: true, data2: "1号电表", data3: "1号变压器", data4: "一号楼", data5: "21F" },
        // { data1: true, data2: "1号电表", data3: "1号变压器", data4: "一号楼", data5: "21F" },
        // { data1: true, data2: "1号电表", data3: "1号变压器", data4: "一号楼", data5: "21F" },
        // { data1: true, data2: "1号电表", data3: "1号变压器", data4: "一号楼", data5: "21F" },
      ],
      configId: "",
      placeId: "",
      keyword: "",
      result: "",
      tableResult: "",
      // 数组内套数组，每个数组就是一页数据
      totalPageData: [],
      // 共几页
      pageNum: 1,
      // 当前要显示的数据
      dataShow: [],
      // 当前选中的
      treeInfo: {
        status: false,
        currentNodeKey: "",
        tableData: []
      }, 
    };
  },
  created() {
  },
  methods: {
    // 取消弹框
    handleCancle() {
      this.dialogFormVisible = false;
    },
    //父组件点击触发
    show(row) {
      this.configId = row.id;
      this.placeId = ''
      this.dialogFormVisible = true;
      this.keyword = ''
      this.topBarPlace = 1
      this.initAllEquipments();
    },

    changeTabBar(value) {
      this.keyword = "";
      this.topBarPlace = value;
      this.placeId = ''
      if (value == 1) {
        this.initAllEquipments();
      } else if (value == 2) {
        this.initConnectEquipments();
      }
    },

    changeConnect(row) {
      let select = row.idRelation;
      if (select) {
        let value = {
          config_id: this.configId,
          placeId: this.placeId,
          meter_id: row.meterId,
          meter_type: Number(row.meterType),
        };
        this.$store.dispatch("addEquipmentConnect", value).then(() => {
          if (this.$store.state.system.addEquipmentConnect.errorCode == 0) {
            this.$message({
              message: "关联设备成功！",
              type: "success",
            });
          } else {
            this.$message({
              message: this.$store.state.system.addEquipmentConnect.message,
              type: "warning",
            });
          }
        });
      } else {
        let value = {
          configId: this.configId,
          placeId: this.placeId,
          meterId: row.meterId,
          // meter_type: Number(row.meterType),
        };

        this.$store.dispatch("delEquipmentConnect", value).then(() => {
          if (this.$store.state.system.delEquipmentConnect.errorCode == 0) {
            this.$message({
              message: "取消关联设备成功！",
              type: "success",
            });
          } else {
            this.$message({
              message: "取消关联设备失败，请重试！",
              type: "warning",
            });
          }
        });
      }
    },

    // 树的提示语
    renderContent(h, { node, data, store }) {
      if (node.label.length > 6) {
        return (
          <div style="font-size: 1.4rem;">
            <el-tooltip class="item" effect="dark" placement="right">
              <div slot="content">{node.label}</div>
              <p style="width:10rem; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;">{node.label}</p>
            </el-tooltip>
          </div>
        );
      } else {
        return (
          <div style="font-size: 1.4rem;">
            <p>{node.label}</p>
          </div>
        );
      }
    },

    // 选择树的节点
    chooseTree(data) {
      this.placeId = data.id
      this.currentPage = 1
      this.tableData = []
      if (this.topBarPlace == 1) {
        let label = data.label;
        for (let i = 0; i < this.result.length; i++) {
          for (let j = 0; j < this.result[i].devicePlaceInfoList.length; j++) {
            if (this.result[i].devicePlaceInfoList[j].placeName == label) {
              this.tableData = this.result[i].devicePlaceInfoList[j].deviceRelationInfoList;
              this.totalNumForPagenation = this.tableData.length;
            } else if (this.placeId == this.result[i].deviceType) {
              this.result[i].devicePlaceInfoList[j].deviceRelationInfoList.forEach(item => {
                this.tableData.push(item)
              })
              this.totalNumForPagenation = this.tableData.length;
            }
          }
        }
        this.calcPageData();
      } else if (this.topBarPlace == 2) {
        let label = data.label;
        for (let i = 0; i < this.result.length; i++) {
          if (this.result[i].deviceTypeName == label) {
            this.tableData = this.result[i].relationDeviceInfoList;
            this.totalNumForPagenation = this.tableData.length;
          }
        }
        this.calcPageData();
      }
    },

    // 点击搜索
    search() {
      // console.log(this.keyword,typeof this.keyword);
      this.currentPage = 1
      if (this.keyword) {
        if (this.topBarPlace == 1) {
          this.initAllEquipments(true);
        } else if (this.topBarPlace == 2) {
          this.initConnectEquipments();
        }
      } else {
        this.keyword = "";
        if (this.topBarPlace == 1) {
          this.initAllEquipments(true);
        } else if (this.topBarPlace == 2) {
          this.initConnectEquipments();
        }
      }
    },

    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      // 重新计算页数并分组
      this.calcPageData();
    },

    handleCurrentChange(newPageNum) {
      this.currentPage = newPageNum;
      this.dataShow = this.totalPageData[newPageNum - 1];
    },

    calcPageData() {
      this.dataShow = [];
      this.totalPageData = []
      // 计算页数
      if (this.tableData.length > 1) {
        this.pageNum = Math.ceil(this.tableData.length / this.pageSize) || 1;
      }
      // 数据分组，循环的次数是总页数
      for (let i = 0; i < this.pageNum; i++) {
        this.totalPageData[i] = this.tableData.slice(this.pageSize * i, this.pageSize * (i + 1));
      }
      this.dataShow = this.totalPageData[this.currentPage - 1];
    },

    initAllEquipments(searchFlag) {
      this.tableData = [];
      let params = {
        configId: this.configId,
        placeId: this.placeId == 1 || this.placeId == 2 ? '' : this.placeId,
        keyword: this.keyword
      }
      this.$store.dispatch("getEquipmentLists", params).then(() => {
        this.tableResult = this.$store.state.system.getEquipmentLists.data;
        if (!searchFlag) {
          this.result = this.$store.state.system.getEquipmentLists.data;
          this.treeData = [];
          // console.log(this.$store.state.system.getEquipmentLists, "-------");
          for (let i = 0; i < this.result.length; i++) {
            let obj = {
              label: this.result[i].deviceTypeName,
              id: this.result[i].deviceType,
              children: [],
            };
            for (let j = 0; j < this.result[i].devicePlaceInfoList.length; j++) {
              let obj1 = {
                label: this.result[i].devicePlaceInfoList[j].placeName,
                id: this.result[i].devicePlaceInfoList[j].placeId,
              };
              obj.children.push(obj1);
              // 点树菜单并搜索时 查询当前数据
              if(this.placeId == this.result[i].devicePlaceInfoList[j].placeId){
                this.treeInfo = {
                  status: true,
                  currentNodeKey: this.result[i].devicePlaceInfoList[j].placeId,
                  tableData: this.result[i].devicePlaceInfoList[j].deviceRelationInfoList
                }
              }
            }
            this.treeData.push(obj);
          }
        }
        
        if(!this.treeInfo.status){
          this.currentNodeKey = this.placeId || this.tableResult[0].devicePlaceInfoList[0].placeId
          this.placeId = this.currentNodeKey
          this.$nextTick(() => {
            // 设置第一层默认选中
            this.$refs.tree.setCurrentKey(this.currentNodeKey);
          });
          this.tableData = (this.tableResult[0].devicePlaceInfoList[0] && this.tableResult[0].devicePlaceInfoList[0].deviceRelationInfoList) || [];
        }else{
          this.currentNodeKey = this.treeInfo.currentNodeKey
          this.$nextTick(() => {
            // 设置第一层默认选中
            this.$refs.tree.setCurrentKey(this.currentNodeKey);
          });
          this.tableData = this.treeInfo.tableData
        }
        // if (this.treeData[0].children[0]) {
        //   this.currentNodeKey = this.treeData[0].children[0].id
        //   this.$nextTick(() => {
        //     // 设置第一层默认选中
        //     this.$refs.tree.setCurrentKey(this.currentNodeKey);
        //   });
        // } else {
        //   this.tableData = [];
        // }
        // if (this.result[0].devicePlaceInfoList[0]) {
        //   this.tableData = this.result[0].devicePlaceInfoList[0].deviceRelationInfoList;
        // } else {
        //   this.tableData = [];
        // }
        this.totalNumForPagenation = this.tableData.length;
        this.calcPageData();
        
      });
    },
    initConnectEquipments() {
      this.treeData = this.$options.data().treeData;
      this.tableData = [];
      let value = {
        configId: this.configId,
        placeId: this.placeId,
        keyword: this.keyword,
      };
      this.$store.dispatch("getEquipmentConnectList", value).then(() => {
        // console.log(this.$store.state.system.getEquipmentConnectList);
        this.result = this.$store.state.system.getEquipmentConnectList.data;
        if (this.result[0]) {
          this.tableData = this.result[0].relationDeviceInfoList;
        } else {
          this.tableData = [];
        }
        this.totalNumForPagenation = this.tableData.length;
        this.calcPageData();
        for (let i = 0; i < this.result.length; i++) {
          let obj = {
            label: this.result[i].deviceTypeName,
          };
          this.treeData.push(obj);
        }
      });
    },
  },
  //处理state数据
  computed: {
    ...mapState({}),
  },
};
</script>

<style lang="less" scoped>
.DialogAlarmSettingConnect {
  overflow: hidden;
  position: relative;
  .el-dialog__body {
    height: 80rem;
  }
}

.topBar {
  height: 3rem;
  width: 100%;
  border-bottom: 0.2rem solid #0b3e6b;
  margin-bottom: 2.5rem;
  .wordGreen {
    height: 3rem;
    line-height: 3rem;
    color: #03d9bc;
    font-size: 1.4rem;
    border-bottom: 0.2rem solid #03d9bc;
    margin-right: 2.4rem;
    float: left;
    cursor: pointer;
  }
  .wordGrey {
    height: 3rem;
    line-height: 3rem;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    float: left;
    margin-right: 2.4rem;
    cursor: pointer;
  }
}

// 板块一##################################
.blockOne {
  height: 70rem;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.treePlace {
  width: 18rem;
  height: 100%;
  background-color: #0d3050;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
}
.tablePlace {
  width: 74rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 18rem;
  .btnAdd {
    height: 3.2rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
    background-color: #027969;
    float: right;
    width: 7.4rem;
    margin-left: 1.6rem;
  }

  .table {
    height: 57.2rem;
    width: 72rem;
    margin: 5rem 1.6rem 0 1.6rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
  // 分页器样式
  .pagination {
    float: right;
    // margin-right: 5rem;
    margin-top: 1rem;
  }
}
</style>

<style lang="less">
.DialogAlarmSettingConnect {
  .elTree {
    background-color: #0d3050;
    color: rgba(255, 255, 255, 0.85);
    .el-tree-node__label {
      font-size: 1.4rem;
      line-height: 2.2rem;
      font-family: PingFang SC;
      height: 2.2rem;
      // 禁止选中文字
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Chrome/Safari/Opera */
      -khtml-user-select: none; /* Konqueror */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }
    .el-tree-node__content:hover {
      background-color: #204b73;
      padding-left: 0;
    }
    .el-tree-node:focus > .el-tree-node__content {
      // background-color: #18191a;
      background-image: linear-gradient(to right, #0d3050, #18191a);
      padding-left: 0;
      margin-left: 0;
      // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
      // box-sizing: border-box;
    }
  }
  // 持续高亮(鼠标离开树以后)
  .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-image: linear-gradient(to right, #0d3050, #18191a);
    // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
    // box-sizing: border-box;
    padding-left: 0;
  }

  .el-checkbox__inner {
    background-color: #0c375e;
    border-color: #02ad96;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #02ad96;
    border-color: #02ad96;
  }
  .el-checkbox__inner::after {
    border-color: #0c375d;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #02ad96;
    border-color: #02ad96;
  }
}
</style>
