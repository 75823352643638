<template>
  <div>
       <!-- ---------------------------------------------------------------------右侧板块一（消防单位统计） ---------------------------------------------------------------------->
     <div class="rightOne">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">消防单位统计</div>
      <!-- 图表 -->
      <div class="chartRightOne">
        <img src="@/assets/FullView/icon2.png"  class="iconStyle1" alt="">
        <div class="wordPlace1">
          <div class="upper">监测电表点</div>
          <div class="downer">{{myStillData.monitoringPoint}} <span style="font-size:1.2rem;line-height:2.4rem;color:rgba(255,255,255,0.65);font-weight:400">个</span></div>
        </div>

      </div>
    </div>

    <!-- ---------------------------------------------------------------------右侧板块二（配电单位统计） ---------------------------------------------------------------------->
     <div class="rightTwo">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">配电单位统计</div>
      <!-- 图表 -->
      <div class="chartRightTwo">
        <img src="@/assets/FullView/icon3.png"  class="iconStyle1" alt="">
        <div class="wordPlace1">
          <div class="upper">监测变压器数</div>
          <div class="downer">{{myStillData.customerUnitTransformer}} <span style="font-size:1.2rem;line-height:2.4rem;color:rgba(255,255,255,0.65);font-weight:400">个</span></div>
        </div>
    
      </div>
    </div>

     <!-- ---------------------------------------------------------------------右侧板块三（分项统计） ---------------------------------------------------------------------->
     <div class="rightThree">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">今日发电排名</div>
      <!-- 图表 -->
       <!-- 图表 -->
      <div class='chartLeftFour'>
          <div class="innerCard" v-for="(item,index) in optionLeftThree" :key="'card3'+index">
              <!-- 文字部分 -->
             <div class="wordPlace">
               <span class="left">No.{{index+1}} {{item.name}}</span>
               <span class="right">{{item.useEnergy}} kWh</span>
             </div>
             <!-- 进度条部分 -->
             <div class="barPlace">
               <div class="barGet" :style="barGetStyle[index]"></div>
               <div class="barLine" :style="barLineStyle[index]"></div>              
             </div>
          </div>

      </div>
    </div>

    <!-- ---------------------------------------------------------------------右侧板块四（配电监控） ---------------------------------------------------------------------->
    <div class="leftThree">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">配电监控</div>
      <!-- 图表 -->
      <div class='chartLeftThree'>
          <div v-for="(item, index) in myStillData.transformerLoadList" :key="index" :class="`imgPeiDian${index + 1}`">
            <img v-if="index == 0" src="@/assets/FullView/icon4.png" class="imgInner" alt="">
            <img v-else src="@/assets/FullView/icon3.png" class="imgInner" alt="">
            <div class="wordInner" :style="index == 0 ? `color:#FAB811` : ''">{{ item.name }}</div>
            <div class="wordInner2">{{item.transformerALoadRate}} <span style="font-size:1.2rem;color:rgba(255,255,255,0.65)"> %</span></div>
          </div>
          <!-- <div class="imgPeiDian2">
            <img src="@/assets/FullView/icon3.png" class="imgInner" alt="">
            <div class="wordInner">变压器B负载率</div>
            <div class="wordInner2">{{myStillData[1]}} <span style="font-size:1.2rem;color:rgba(255,255,255,0.65)"> %</span></div>
          </div>
          <div class="imgPeiDian3">
            <img src="@/assets/FullView/icon3.png" class="imgInner" alt="">
            <div class="wordInner">变压器C负载率</div>
            <div class="wordInner2">{{myStillData[2]}} <span style="font-size:1.2rem;color:rgba(255,255,255,0.65)"> %</span></div>
          </div>
          <div class="imgPeiDian4">
            <img src="@/assets/FullView/icon3.png" class="imgInner" alt="">
            <div class="wordInner">变压器D负载率</div>
            <div class="wordInner2">{{myStillData[3]}} <span style="font-size:1.2rem;color:rgba(255,255,255,0.65)"> %</span></div>
          </div> -->
          
          
         
        
      </div>
    </div>

  <!-- ---------------------------------------------------------------------附加大板块 ---------------------------------------------------------------------->
     <div class="blockSup">
      <div :class="styleSup">
        <img class="supRightImg" src="@/assets/FullView/iconLarge3.png" alt="">
        <div class="wordUpper">发电站</div>
        <div class="wordDowner">{{myStillData.generateStationNumbers}}<span style="font-size:1.2rem;color: rgba(255,255,255,0.65)"> 个</span></div>
      </div>
      <div :class="styleSup2">
        <img class="supRightImg" src="@/assets/FullView/iconLarge4.png" alt="">
        <div class="wordUpper">总装机容量</div>
        <div class="wordDowner">{{myStillData.ladingTotal}}<span style="font-size:1.2rem;color: rgba(255,255,255,0.65)"> kW</span></div>
     </div>
       <div :class="styleSup3">
        <img class="supRightImg" src="@/assets/FullView/iconLarge5.png" alt="">
        <div class="wordUpper">逆变器数量</div>
        <div class="wordDowner">{{myStillData.inverterNumber}}<span style="font-size:1.2rem;color: rgba(255,255,255,0.65)"> 个</span></div>
       </div>
    </div>





  </div>
</template>

<script>
export default {
    name:"BothRight",
    props: {
      myData: {
        type: Object,
        default: () => {
          return {}
        },
        require: true
      }
    },
    data(){return{
        // 变压器A~D负载率 消防单位统计 配电单位统计 三个附属栏数据
        myStillData:{
          transformerLoadList: [],
          monitoringPoint: 0,
          customerUnitTransformer: 0,
          generateStationNumbers: 0,
          ladingTotal: 0,
          inverterNumber: 0,
        },
        
        // ############################################################################右侧第四个图数据 自定义天梯图#############################################################################
        optionLeftThree:[
            {name:'--',useEnergy:0},
            {name:'--',useEnergy:0},
            {name:'--',useEnergy:0},
            {name:'--',useEnergy:0},
            {name:'--',useEnergy:0},
            {name:'--',useEnergy:0},
        ],
        // 天梯图的样式(颜色柱)
        barGetStyle:[{width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},
                    ],
        // 天梯图的样式（白盖）
        barLineStyle:[{left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},
                    ],
  
        // 补充板块的样式
        styleSup:'blockSupInsider',
        styleSup2:'blockSupInsider2',
        styleSup3:'blockSupInsider3',
    }},
    watch: {
      myData: {
        handler: function(newVal, oldVal) {
          this.show(newVal)
        },
        deep: true,
        // immediate: true
      }
    },

    methods:{
        // 获取屏幕比例，使echarts自适应
        fontSize(res){
            let docEl = document.documentElement,
            clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
            if (!clientWidth) return;
            let fontSize = (clientWidth / 192);
            return res*fontSize;
        },


        // 展示数据
        show(val){
          // 静态数字的渲染
          this.myStillData = {
            transformerLoadList: val.transformerLoadList,
            monitoringPoint: val.monitoringPoint,
            customerUnitTransformer: val.customerUnitTransformer,
            generateStationNumbers: val.generateStationNumbers,
            ladingTotal: val.ladingTotal,
            inverterNumber: val.inverterNumber,
          }
     

        // 渲染右边第四个图（自定义天梯图） 数据渲染、有色柱表现渲染、白色盖子表现渲染
        this.optionLeftThree=val.generateElectricityRanking
        // 如果后台给的数据不足6个，就进行补充满6个
        if(val.generateElectricityRanking && val.generateElectricityRanking.length<6){
          let num = 6-val.generateElectricityRanking.length
          for(let i=0;i<num;i++){
            this.optionLeftThree.push({name:'--',useEnergy:0},)
          }
        }
        this.optionLeftThree.forEach((item, index) => {
            if (this.optionLeftThree[0].useEnergy !== 0) {
            this.barGetStyle[index] =  {width:(item.useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'}
            this.barLineStyle[index] =  {left:(item.useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'}
            } else {
            this.barGetStyle[index] =  {width:'0rem',transition:'all 1s'}
            this.barLineStyle[index] =  {left:'0rem',transition:'all 1s'}
            }
        })
        // if (this.optionLeftThree) {
        //     this.barGetStyle =[{width:'34.4rem',transition:'all 1s'},
        //     {width:(this.optionLeftThree[1].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     {width:(this.optionLeftThree[2].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     {width:(this.optionLeftThree[3].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     {width:(this.optionLeftThree[4].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     {width:(this.optionLeftThree[5].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     ],
        //     this.barLineStyle =[{left:'34.4rem',transition:'all 1s'},
        //     {left:(this.optionLeftThree[1].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     {left:(this.optionLeftThree[2].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     {left:(this.optionLeftThree[3].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     {left:(this.optionLeftThree[4].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     {left:(this.optionLeftThree[5].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        //     ]
        // }

        // this.styleSup='blockSupInsider'
        // this.styleSup2='blockSupInsider2'
        // this.styleSup3='blockSupInsider3'

        // // 补充板块的展示动画
        // setTimeout(()=>{
        //   this.styleSup='blockSupInsiderShow'
        //   setTimeout(()=>{this.styleSup2='blockSupInsiderShow2'},100)
        //   setTimeout(()=>{this.styleSup3='blockSupInsiderShow3'},200)
        // },1000)
        },
        showAnimation() {
          this.styleSup='blockSupInsider'
          this.styleSup2='blockSupInsider2'
          this.styleSup3='blockSupInsider3'
          // 补充板块的展示动画
          setTimeout(()=>{
            this.styleSup='blockSupInsiderShow'
            setTimeout(()=>{this.styleSup2='blockSupInsiderShow2'},100)
            setTimeout(()=>{this.styleSup3='blockSupInsiderShow3'},200)
          },1000)
        }
    },

    mounted(){}
}
</script>

<style lang='less' scoped>

//  ----------------------------------------------------------------------------背景图部分 ----------------------------------------------------------------------
.FullView {
  height:108rem;
  width: 192rem;
  position: absolute;
  top: -8.8rem;
  left: -18.4rem;
  background-color: #16294C;
  .bgimg{
    // height:108rem;
    // width: 192rem;
    height: 129rem;
    width: 230.4rem;

    position: absolute;
    top: 4.4rem;
    left: 0rem;
  }
  .bgimgTitle{
    height: 8rem;
    width: 192rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bgimgHubei0{
    width: 10.82rem;
    height: 6.93rem;
    position: absolute;
    top: 46rem;
    right: 90rem;
    opacity: 0;
  }
  .bgimgHubei{
    width: 108.2rem;
    height: 69.3rem;
    position: absolute;
    top: 10.4rem;
    right: 42rem;
    opacity: 1;
    transition: all 1s;
  }
  .blockTitle{
    height: 5.1rem;
    width: 37.8rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .blockTitleWord{
    position: absolute;
    top: 1.4rem;
    left: 4rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1.6rem;
    color: #fff;
  }

}


//  ----------------------------------------------------------------------------右侧板块一 ----------------------------------------------------------------------
.rightOne{
  height: 13.3rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 26.7rem;
  .chartRightOne{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 8.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}


//  ----------------------------------------------------------------------------右侧板块二 ----------------------------------------------------------------------
.rightTwo{
  height: 13.3rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 42rem;
  .chartRightTwo{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 8.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}

//  ----------------------------------------------------------------------------右侧板块二 ----------------------------------------------------------------------
.rightThree{
  height: 41.1rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 57.3rem;
  .chartRightThree{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 36rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}

//  ----------------------------------------------------------------------------右侧板块四 ----------------------------------------------------------------------
.leftThree{
  height: 22.3rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 2.4rem;
  .chartLeftThree{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 17.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
    padding-top: -0.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    box-sizing: border-box;
    .innerCard{
      height: 3.6rem;
      width: 100%;
      margin-top: 2.2rem;
      position: relative;
      top: 0;
      left: 0;
      .wordPlace{
        height: 2rem;
        color: #E6F7FF;
        font-size: 1.4rem;
        line-height: 2rem;
        .left{
          float: left;
        }
        .right{
          float: right;
        }
      }
      .barPlace{
        margin: 0.2rem 0;
        height: 1rem;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
      .barGet{
        height: 1rem;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #36EAAF 0%, #4BB3D2 100%); 
      }
      .barLine{
        width: 0.2rem;
        height: 1.4rem;
        background-color: #fff;
        position: absolute;
        top: -0.2rem;
      }
    }

    .imgPeiDian1{
      width: 18rem;
      height: 5.1rem;
      position: absolute;
      top: 2rem;
      left: 1.6rem;
    }

    .imgPeiDian2{
      width: 18rem;
      height: 5.1rem;
      position: absolute;
      top: 2rem;
      left: 19.8rem;
    }

    .imgPeiDian3{
      width: 18rem;
      height: 5.1rem;
      position: absolute;
      top: 9.15rem;
      left: 1.6rem;
    }

    .imgPeiDian4{
      width: 18rem;
      height: 5.1rem;
      position: absolute;
      top: 9.15rem;
      left: 19.8rem;
    }

    .imgInner{
      width: 5.4rem;
      height: 5.1rem;
      position: absolute;
      left: 0;
      top: 0;
    }

    .wordInner{
      height: 2rem;
      line-height: 2rem;
      font-size: 1.4rem;
      color: #18FEFE;
      position: absolute;
      left: 6.2rem;
      top: 0;   
    }

    .wordInner2{
      height: 2.4rem;
      line-height: 2.4rem;
      font-size: 2rem;
      color: #FBFBFB;
      position: absolute;
      left: 6.2rem;
      top: 2.6rem;   
      text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    }

  }
}


// 通用样式
.iconStyle1{
  width: 5.4rem;
  height: 5.1rem;
  position: absolute;
  left: 2.5rem;
  top: 1.623rem;
}
.iconStyle2{
  width: 5.4rem;
  height: 5.1rem;
  position: absolute;
  left: 19.6rem;
  top: 1.623rem;
}
.wordPlace1{
  height: 5rem;
  position: absolute;
  top: 1.6rem;
  left: 8.7rem;
  
}
.wordPlace2{
  height: 5rem;
  position: absolute;
  top: 1.6rem;
  left: 25.8rem;
}

.upper{
  height: 2rem;
  line-height: 2rem;
  color: #19dfe5;
  text-shadow: 0rem 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
}
.downer{
  height: 2.4rem;
  font-size: 2rem;
  color: #FBFBFB;
  text-shadow: 0rem 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
  line-height: 2.4rem;
  
}


  .chartLeftFour{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 35.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
    padding-top: -0.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    box-sizing: border-box;
    .innerCard{
      height: 3.6rem;
      width: 100%;
      margin-top: 2.2rem;
      position: relative;
      top: 0;
      left: 0;
      .wordPlace{
        height: 2rem;
        color: #E6F7FF;
        font-size: 1.4rem;
        line-height: 2rem;
        .left{
          float: left;
        }
        .right{
          float: right;
        }
      }
      .barPlace{
        margin: 0.2rem 0;
        height: 1rem;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
      .barGet{
        height: 1rem;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #36EAAF 0%, #4BB3D2 100%); 
      }
      .barLine{
        width: 0.2rem;
        height: 1.4rem;
        background-color: #fff;
        position: absolute;
        top: -0.2rem;
      }
    }
  }






// -----------------------------------------------------------------------------补充大板块-------------------------------------------
.blockSup{
  position: absolute;
  top: 0;
  right: 43.4rem;
  width: 30.5rem;
  height: 108rem;
  overflow: hidden;
}
// 封装的css 透明卡片
.blockSupInsider{
  position: absolute;
  top: 4.2rem;
  right: -30rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 
}

.blockSupInsiderShow{
  position: absolute;
  top: 4.2rem;
  right: 0rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 
}

.blockSupInsider2{
  position: absolute;
  top: 13.8rem;
  right: -30rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 
}

.blockSupInsiderShow2{
  position: absolute;
  top: 13.8rem;
  right: 0rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 
}

.blockSupInsider3{
  position: absolute;
  top: 23.4rem;
  right: -30rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 opacity: 0
}

.blockSupInsiderShow3{
  position: absolute;
  top: 23.4rem;
  right: 0rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 opacity: 1
}



// 右侧附加板块的卡片内部样式布局
.supRightImg{
  width: 8rem;
  height: 5.6rem;
  position: absolute;
  left: 1.7rem;
  top: 1.1rem;
}
.wordUpper{
  height: 2rem;
  color: #18FEFE;
  font-size: 1.4rem;
  line-height: 2rem;
  position: absolute;
  left: 10.1rem;
  top: 1.4rem;
  opacity: 0.85;
  text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
}
.wordDowner{
  position: absolute;
  top: 4rem;
  left: 10.1rem;
  height: 2.4rem;
  line-height: 2.4rem;
  color: #FBFBFB;
  font-size: 2rem;
  text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
}

.word4Red{
  position: absolute;
  left: 20.7rem;
  top: 4.6rem;
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #fe6c2f;
    }
  }


 .word4Green{
   position: absolute;
  left: 20.7rem;
  top: 4.6rem;
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #39e5d0;
    }
  }


// -----------------------------------------------------------------------------补充大板块-------------------------------------------
.blockSup{
  position: absolute;
  top: 0;
  right: 43.4rem;
  width: 30.5rem;
  height: 108rem;
  overflow: hidden;
}
// 封装的css 透明卡片
.blockSupInsider{
  position: absolute;
  top: 4.2rem;
  right: -30rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  opacity: 0
}

.blockSupInsiderShow{
  position: absolute;
  top: 4.2rem;
  right: 0rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 opacity: 1
}

.blockSupInsider2{
  position: absolute;
  top: 13.8rem;
  right: -30rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 opacity: 0
}

.blockSupInsiderShow2{
  position: absolute;
  top: 13.8rem;
  right: 0rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 opacity: 1
}

// 右侧附加板块的卡片内部样式布局
.supRightImg{
  width: 8rem;
  height: 5.6rem;
  position: absolute;
  left: 1.7rem;
  top: 1.1rem;
}
.wordUpper{
  height: 2rem;
  color: #18FEFE;
  font-size: 1.4rem;
  line-height: 2rem;
  position: absolute;
  left: 10.1rem;
  top: 1.4rem;
  opacity: 0.85;
  text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
}
.wordDowner{
  position: absolute;
  top: 4rem;
  left: 10.1rem;
  height: 2.4rem;
  line-height: 2.4rem;
  color: #FBFBFB;
  font-size: 2rem;
  text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
}

.word4Red{
  position: absolute;
  left: 20.7rem;
  top: 4.6rem;
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #fe6c2f;
    }
  }


 .word4Green{
   position: absolute;
  left: 20.7rem;
  top: 4.6rem;
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #39e5d0;
    }
  }


  
</style>