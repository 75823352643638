import { render, staticRenderFns } from "./DialogForChoosePerson.vue?vue&type=template&id=73da888e&scoped=true&"
import script from "./DialogForChoosePerson.vue?vue&type=script&lang=js&"
export * from "./DialogForChoosePerson.vue?vue&type=script&lang=js&"
import style0 from "./DialogForChoosePerson.vue?vue&type=style&index=0&id=73da888e&lang=less&scoped=true&"
import style1 from "./DialogForChoosePerson.vue?vue&type=style&index=1&lang=less&"
import style2 from "./DialogForChoosePerson.vue?vue&type=style&index=2&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73da888e",
  null
  
)

export default component.exports