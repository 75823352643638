<template>
    <div class="dialog_echarts">
        <div v-if="loading" class="loading-mask">
          <el-spinner size="large"></el-spinner>
        </div>
        <div ref="myChart" class="charts" id="myChart16"></div>
        <div class="select-time">
            <span class="time">时间：</span>
            <el-date-picker class="seleWid" v-model="dateSpan" @change="selectDate" type="month" size="mini" placeholder="选择月"></el-date-picker>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as echarts from "echarts";
export default {
    name: "EchartBarMap",
    props: {
        optionData: {
            type: Object,
            default: () => { },
        },
        chartsType: {
            type: String,
            default: () => "",
        },
    },
    data () {
        return {
            dateSpan: "2021-12",
            dataForm: {
                localtionId: "",
                startTime: "",
            },
            barList1: [],
            barList2: [],
            xdata: [],
            yMax: 0,
            loading: false,
        };
    },
    created () {
        this.getThisMonth();
    },
    methods: {
        resizeChart () {
            setTimeout(() => {
                const myChart = this.$echarts.init(document.getElementById("myChart16"));
                const chartWidth = document.getElementById("myChart16").offsetWidth
                const chartHeight = document.getElementById("myChart16").offsetHeight
                myChart.resize({ width: chartWidth, height: chartHeight })
            }, 500)
        },
        // 销毁echarts
        dispose () {
            this.$echarts.dispose(this.$refs.myChart);
        },
        // init() {
        //   // 基于准备好的dom，初始化echarts实例
        //   let myChart = this.$echarts.init(this.$refs.myChart);
        //   if (myChart == null) {
        //     myChart = this.$echarts.init(this.$refs.myChart);
        //   }
        //   let option = {
        //     xAxis: {
        //       type: "category",
        //       data: this.optionData.xAxisData,
        //       axisLabel: {
        //         textStyle: {
        //           color: "#fff",
        //         },
        //       },
        //     },
        //     yAxis: {
        //       type: "value",
        //       axisLabel: {
        //         textStyle: {
        //           color: "#fff",
        //         },
        //       },
        //     },
        //     legend: {
        //       textStyle: {
        //         color: "#fff",
        //       },
        //       bottom: 10,
        //       data: this.optionData.legendData,
        //     },
        //     legendData: this.optionData.legendData,
        //     // series是从上级的组件通过props传进来的数据
        //     series: this.optionData.seriesData,
        //   };
        //   // 绘制图表
        //   myChart.setOption(option);
        //   myChart._dom.childNodes[0].childNodes[0].style.width = "58rem";
        //   myChart._dom.childNodes[0].childNodes[0].style.height = "42rem";
        // },
        init () {
            this.getSelectTime();
        },
        getThisMonth () {
            let date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = y + "-" + m;
            this.dateSpan = this.$store.state.elecManagement.compareWorkDate;
        },
        selectDate (dateData) {
            let date = new Date(dateData);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = y + "-" + m;
            this.$parent.show(time);
            this.dateSpan = time;
            this.$store.commit("CompareWorkDate", time);
            this.getSelectTime();
        },
        getSelectTime () {
            this.loading = true
            this.$store
                .dispatch("getAnalysisWorkOrRest", {
                    locationId: this.dataForm.localtionId,
                    month: this.dateSpan,
                })
                .then(() => {
                    // console.log(this.$store.state.elecManagement.getAnalysisWorkOrRest);
                    if (this.$store.state.elecManagement.getAnalysisWorkOrRest.errorCode == 0) {
                        if (this.$store.state.elecManagement.getAnalysisWorkOrRest.data !== null) {
                            this.barList1 = this.$store.state.elecManagement.getAnalysisWorkOrRest.data[0].data;
                            this.barList2 = this.$store.state.elecManagement.getAnalysisWorkOrRest.data[1].data;
                            this.yMax = 1;
                            this.drawLine1();
                        } else {
                            this.barList1 = [];
                            this.barList2 = [];
                            this.xdata = [
                                "00:00",
                                "01:00",
                                "02:00",
                                "03:00",
                                "04:00",
                                "05:00",
                                "06:00",
                                "07:00",
                                "08:00",
                                "09:00",
                                "10:00",
                                "11:00",
                                "12:00",
                                "13:00",
                                "14:00",
                                "15:00",
                                "16:00",
                                "17:00",
                                "18:00",
                                "19:00",
                                "20:00",
                                "21:00",
                                "22:00",
                                "23:00",
                            ];
                            this.yMax = 0;
                            this.drawLine1();
                        }
                    } else {
                        this.barList1 = [];
                        this.barList2 = [];
                        this.xdata = [
                            "00:00",
                            "01:00",
                            "02:00",
                            "03:00",
                            "04:00",
                            "05:00",
                            "06:00",
                            "07:00",
                            "08:00",
                            "09:00",
                            "10:00",
                            "11:00",
                            "12:00",
                            "13:00",
                            "14:00",
                            "15:00",
                            "16:00",
                            "17:00",
                            "18:00",
                            "19:00",
                            "20:00",
                            "21:00",
                            "22:00",
                            "23:00",
                        ];
                        this.yMax = 0;
                        this.drawLine1();
                    }
                }).finally(() => {
                  this.loading = false
                })
        },
        ceilNumber (number) {
            let bite = 0;
            if (number < 10) {
                return 10;
            }
            while (number >= 10) {
                number /= 10;
                bite += 1;
            }
            return Math.ceil(number) * Math.pow(10, bite);
        },
        getXdata (len) {
            this.xdata = [];
            let m = this.dateSpan.split("-")[1];
            for (let i = 1; i < len + 1; i++) {
                if (i < 10) {
                    i = "0" + i;
                }
                let item = m + "-" + i;
                this.xdata.push(item);
            }
        },
        drawLine1 () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart16"));
            let workList = [];
            let restList = [];
            let value = "";
            let month = this.dateSpan.slice(5) + "-";
            value = month;
            workList = this.barList1;
            restList = this.barList2;
            let yMax = 0;
            for (let i = 0; i < workList.length; i++) {
                const item = workList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            for (let i = 0; i < restList.length; i++) {
                const item = restList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            this.yMax = this.ceilNumber(yMax);
            let length = workList.length;
            if (length > 0) {
                this.getXdata(length);
            }
            // 绘制图表
            myChart.setOption(
                {
                    color: ["#1EE7E7", "#1890FF"],
                    legend: {
                        orient: "horizontal",
                        x: "center", //可设定图例在左、右、居中
                        y: "bottom", //可设定图例在上、下、居中
                        padding: [0, 0, 45, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                        textStyle: { color: "#fff" },
                        data: ["工作时段", "非工作时段"],
                    },
                    tooltip: {
                        trigger: "axis",
                    },
                    xAxis: {
                        type: "category",
                        interval: 0,
                        axisLabel: {
                            textStyle: {
                                color: "#fff",
                            },
                        },
                        // x轴数据
                        data: this.xdata,
                    },
                    yAxis: [
                        {
                            name: "单位:kWh",
                            min: 0,
                            max: this.yMax == 0 ? 10 : this.yMax,
                            // interval: parseInt(yMax / 4),
                            axisLabel: {
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: "dashed",
                                    color: "rgba(230,247,255,0.2)",
                                },
                            },
                        },
                    ],
                    grid: {
                        x: 25,
                        x2: 40,
                        y: 75,
                        y2: 75,
                        containLabel: true,
                    },
                    series: [
                        {
                            name: "工作时段",
                            type: "bar",
                            barWidth: 5,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: "#1EE7E7" },
                                    { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
                                ]),
                            },
                            data: workList,
                        },
                        {
                            name: "非工作时段",
                            type: "bar",
                            barWidth: 5,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: "#1890FF" },
                                    { offset: 1, color: "rgba(24, 144, 255, 0.35)" },
                                ]),
                            },
                            data: restList,
                        },
                    ],
                },
                true
            );
            myChart._dom.childNodes[0].childNodes[0].style.width = "59rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "43rem";
        },
    },
    mounted () {
        // this.init()
        // setTimeout(() => {
        //   this.getSelectTime();
        // }, 100);
    },
    computed: {
        ...mapState({
            getAnalysisWorkOrRest: (state) => {
                return state.elecManagement.getAnalysisWorkOrRest.data;
            },
        }),
    },
    watch: {
        "$store.state.storage.localtionId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.dataForm.localtionId = newVal;
                this.getThisMonth();
                this.getSelectTime();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.dialog_echarts {
    .charts {
        width: 100%;
        // width: 59rem;
        height: 43rem;
    }
    .select-time {
        position: absolute;
        top: 1.8rem;
        right: 15.5rem;
        z-index: 2;
        .time {
            font-size: 1.4rem;
            color: #fff;
        }
        .seleWid {
            width: 15rem;
        }
    }
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
</style>
