<template>
  <!-- 发电管理折线图 -->
  <div class="dialog1_echarts">
    <div ref="myChart" class="charts" id="myChart24"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "mapEcharts4",
  props: ["whichTab", "inverterId", "generalDay"],
  data() {
    return {
      optionData: "",
      xdata: "",
      ydata: [{ data: [] }, { data: [] }],
      yMax: "",
      yMax1: "",
    };
  },
  watch: {},
  methods: {
    // 销毁echarts
    dispose() {
      this.$echarts.dispose(document.getElementById("myChart24"));
    },
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    initTrendData() {
      let dateInfo = this.generalDay;
      if (typeof this.generalDay == "object") {
        if (this.whichTab == 0) {
          let date = this.generalDay;
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          let day = date.getDate();
          if (day < 10) {
            day = "0" + day;
          }
          dateInfo = year + "-" + month + "-" + day;
        } else if (this.whichTab == 1) {
          let date = this.generalDay;
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          dateInfo = year + "-" + month;
        } else if (this.whichTab == 2) {
          dateInfo = this.generalDay.getFullYear();
        }
      }
      let value = {
        inverterId: this.inverterId,
        datetype: this.whichTab + 1,
        date: dateInfo,
      };
      this.$store.dispatch("getInverterTrendData", value).then(() => {
        if (this.$store.state.genManagement.getInverterTrendData.errorCode == 0) {
          this.ydata = this.$store.state.genManagement.getInverterTrendData.data;
          this.setOptionData();
          this.init();
        } else {
          this.setOptionData();
          this.init();
          // this.xdata = [
          //   "00:00",
          //   "01:00",
          //   "02:00",
          //   "03:00",
          //   "04:00",
          //   "05:00",
          //   "06:00",
          //   "07:00",
          //   "08:00",
          //   "09:00",
          //   "10:00",
          //   "11:00",
          //   "12:00",
          //   "13:00",
          //   "14:00",
          //   "15:00",
          //   "16:00",
          //   "17:00",
          //   "18:00",
          //   "19:00",
          //   "20:00",
          //   "21:00",
          //   "22:00",
          //   "23:00",
          // ];
          // this.ydata = [{ data: [] }, { data: [] }];
          // this.yMax = 10;
          // this.yMax1 = 10;
          // this.init();
        }
      });
    },
    setOptionData() {
      this.xdata = [];
      if (this.whichTab == 0) {
        this.xdata = [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
        ];
      } else if (this.whichTab == 1) {
        let date = this.generalDay ? new Date(this.generalDay) : new Date();
        let month = date.getMonth() + 1;
        month = month < 10 ? '0' + month : month
        let year = date.getFullYear();
        let indexs = 0;
        if (typeof this.generalDay == "object") {
          date = this.generalDay;
        }
        if (month > 7) {
          indexs = month % 2 == 0 ? 31 : 30
        } else if (month == 2) {
          indexs = year % 4 == 0 ? 29 : 28
        } else {
          indexs = month % 2 == 0 ? 30 : 31
        }
        for (let i = 1; i <= indexs; i++) {
          let item = i;
          if (item < 10) {
            item = "0" + item;
          }
          item = month + "-" + item;
          this.xdata.push(item);
        }
      } else if (this.whichTab == 2) {
        this.xdata = [
          "一月",
          "二月",
          "三月",
          "四月",
          "五月",
          "六月",
          "七月",
          "八月",
          "九月",
          "十月",
          "十一月",
          "十二月",
        ];
        console.log(this.xdata)
      }
    },
    init() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart24"));
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("myChart24"));
      }
      let mapLineList = this.ydata[1].data;
      if (mapLineList.length > 0) {
        let yMax = mapLineList[0];
        for (let i = 0; i < mapLineList.length; i++) {
          if (mapLineList[i] > yMax) {
            yMax = mapLineList[i];
          }
        }
        this.yMax = this.ceilNumber(yMax);
      }
      let mapLineList1 = this.ydata[0].data;
      if (mapLineList1.length > 0) {
        let yMax = mapLineList1[0];
        for (let i = 0; i < mapLineList1.length; i++) {
          if (mapLineList1[i] > yMax) {
            yMax = mapLineList1[i];
          }
        }
        this.yMax1 = this.ceilNumber(yMax);
      }
      let option = {
        title: {
          textStyle: {
            color: "#37A5DD",
            fontSize: 14,
            lineHeight: 10,
            fontWeight: 400,
          },
          subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
        },
        color: ["#FFA82C", "#1EE7E7"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
          icon: "circle",
          x: "center", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 20, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          bottom: 10,
          data: ["平均功率(kW)", "发电量(kW.h)"],
        },
        grid: {
          x: 25,
          y: 40,
          x2: 40,
          y2: 30,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "rgba(43, 113, 176, 0.45)",
            },
          },
          axisLabel: {
            color: "#fff",
          },
          data: this.xdata,
        },
        yAxis: [
          {
            min: 0,
            max: this.yMax1,
            interval: this.yMax1 / 5,
            type: "value",
            axisLabel: {
              color: "#fff",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#1f4a72",
              },
            },
          },
          {
            min: 0,
            max: this.yMax,
            interval: this.yMax / 5,
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#fff",
                align: "left",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "平均功率(kW)",
            type: "line",
            smooth: true,
            symbol: "none",
            lineStyle: {
              color: "#FFA82C",
            },
            data: this.ydata[0].data,
          },
          {
            name: "发电量(kW.h)",
            type: "bar",
            // smooth: true,
            // symbol: "none",
            yAxisIndex: 1,
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1EE7E7" },
                { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
              ]),
            },
            data: this.ydata[1].data,
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option, true);
      myChart._dom.childNodes[0].childNodes[0].style.width = "143rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "26rem";
    },
    clearEcharts(){
      this.xdata = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ];
      this.ydata = [{ data: [] }, { data: [] }];
      this.yMax = 10;
      this.yMax1 = 10;
      this.init();
    }
  },
  mounted() {
    // this.initTrendData();
  },
  watch: {
    whichTab: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return this.clearEcharts();
        this.whichTab = newVal;
        this.initTrendData();
      },
    },
    generalDay: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return this.clearEcharts();
        this.generalDay = newVal;
        console.log(this.generalDay, 'this.generalDay')
        this.initTrendData();
      },
    },
    inverterId: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return this.clearEcharts();
        this.inverterId = newVal;
        this.initTrendData();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.dialog1_echarts {
  .charts {
    margin: 0 auto;
    width: 143rem;
    height: 26rem;
  }
}
</style>
