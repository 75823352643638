<template>
    <div>
        <div class="echartSingel">
            <div v-if="loading" class="loading-mask">
              <el-spinner size="large"></el-spinner>
            </div>
            <!-- 图表 -->
            <!-- <div class="echart1">
        <echart-area-map ref="lineCharts" chartsType="line" :optionData="optionData" />
      </div> -->
            <div ref="myChart" id="myChart" class="c2"></div>
            <!-- 图表的顶栏 -->
            <div class="tabBarPlace1">
                <div class="tabBarTop">
                    <div class="word">周末周中能耗分析</div>
                    <!-- <button class="btn1">自定义日期</button>
          <div class="dateChoose">
            <div :class="btnTypeDay" @click="changeTab(0)">日均能耗</div>
            <div :class="btnTypeMonth" @click="changeTab(1)">总能耗</div>
          </div> -->
                </div>
                <div class="table-bottom">
                    <div class="weekTime">
                        <el-select @change="selectYear" size="mini" class="selectTime" v-model="value" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label"></el-option>
                        </el-select>
                    </div>
                    <div class="dateChoose">
                        <div :class="btnTypeDay" @click="changeTab(0)">日均能耗</div>
                        <div :class="btnTypeMonth" @click="changeTab(1)">总能耗</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";
export default {
    name: "MymapNo2",
    props: {
        name: {
            type: String,
            default: () => ''
        },
    },
    // components: { EchartAreaMap },

    data () {
        return {
            // 按钮的样式
            btnTypeDay: "btnGreen",
            btnTypeMonth: "btnDark",
            value: new Date().getFullYear(),
            whichTab: 1,
            optionData: {},
            showInterval: true,
            dataForm: {
                localtionId: "",
            },
            barList1: [],
            barList2: [],
            barList3: [],
            barList4: [],
            options: [],
            loading: false,
        };
    },

    methods: {
        resizeChart () {
            setTimeout(() => {
                const myChart = this.$echarts.init(document.getElementById("myChart"));
                const chartWidth = document.getElementById("myChart").offsetWidth
                const chartHeight = document.getElementById("myChart").offsetHeight
                myChart.resize({ width: chartWidth, height: chartHeight })
            }, 500)
        },
        selectYear () {
            let item = this.value;
            let str = item.slice(0, 4);
            this.value = str;
            if (this.btnTypeDay == 'btnGreen') {
                this.queryTabDay1();
            } else if (this.btnTypeMonth == 'btnGreen') {
                this.queryTabDay2();
            }
            //  this.drawLineDay()
            //  this.drawLineYear()
        },
        queryTabDay1 () {
            this.loading = true
            this.$store
                .dispatch("getAnalysisWeek", {
                    locationId: this.dataForm.localtionId,
                    year: this.value,
                    type: "1",
                })
                .then(() => {
                    if (this.$store.state.elecManagement.getAnalysisWeek.errorCode == 0) {
                        if (
                            (this.$store.state.elecManagement.getAnalysisWeek.data !== null) &
                            (this.$store.state.elecManagement.getAnalysisWeek.data.length > 0)
                        ) {
                            this.barList1 = this.getAnalysisWeek[0].data;
                            this.barList2 = this.getAnalysisWeek[1].data;
                            this.drawLineDay();
                        } else {
                            this.barList1 = [];
                            this.barList2 = [];
                            this.drawLineDay();
                        }
                    } else {
                        this.barList1 = [];
                        this.barList2 = [];
                        this.drawLineDay();
                    }
                }).finally(() => {
                  this.loading = false
                })
        },
        queryTabDay2 () {
            this.loading = true
            this.$store
                .dispatch("getAnalysisWeek", {
                    locationId: this.dataForm.localtionId,
                    year: this.value,
                    type: "2",
                })
                .then(() => {
                    if (this.$store.state.elecManagement.getAnalysisWeek.errorCode == 0) {
                        if (
                            (this.$store.state.elecManagement.getAnalysisWeek.data !== null) &
                            (this.$store.state.elecManagement.getAnalysisWeek.data.length > 0)
                        ) {
                            this.barList3 = this.getAnalysisWeek[0].data;
                            this.barList4 = this.getAnalysisWeek[1].data;
                            // this.drawLineDay();
                        } else {
                            this.barList3 = [];
                            this.barList4 = [];
                            // this.drawLineDay();
                        }
                    } else {
                        this.barList3 = [];
                        this.barList4 = [];
                        // this.drawLineDay();
                    }
                    // this.barList3 = this.getAnalysisWeek[0].data;
                    // this.barList4 = this.getAnalysisWeek[1].data;
                    this.drawLineYear();
                }).finally(() => {
                  this.loading = false
                })
        },
        changeTab (index) {
            this.whichTab = index;
            this.showInterval = false;
            if (index === 0) {
                this.btnTypeDay = "btnGreen";
                this.btnTypeMonth = "btnDark";
                this.queryTabDay1();
                // this.drawLineDay();
            } else if (index === 1) {
                this.btnTypeDay = "btnDark";
                this.btnTypeMonth = "btnGreen";
                this.queryTabDay2();
                // this.drawLineYear();
            }
        },
        dispose () {
            this.$echarts.dispose(this.$refs.myChart);
        },
        getYear () {
            let date = new Date();
            let year = date.getFullYear();
            this.value = year;
            this.options = [];
            for (let i = 0; i < 5; i++) {
                let num = year - i;
                let obj = {
                    id: i + 1,
                    label: num + "年",
                };
                this.options.push(obj);
            }
        },
        ceilNumber (number) {
            let bite = 0;
            if (number < 10) {
                return 10;
            }
            while (number >= 10) {
                number /= 10;
                bite += 1;
            }
            return Math.ceil(number) * Math.pow(10, bite);
        },
        drawLineDay () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(this.$refs.myChart);
            // 绘制图表
            if (myChart == null) {
                myChart = this.$echarts.init(this.$refs.myChart);
            }
            let workList = [];
            let restList = [];
            workList = this.barList1;
            restList = this.barList2;
            let yMax = 0;
            for (let i = 0; i < workList.length; i++) {
                const item = workList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            for (let i = 0; i < restList.length; i++) {
                const item = restList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            yMax = this.ceilNumber(yMax);
            let option = {
                color: ["#1EE7E7", "#1890FF"],
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    orient: "horizontal",
                    x: "center", //可设定图例在左、右、居中
                    y: "bottom", //可设定图例在上、下、居中
                    padding: [0, 0, 45, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                    // borderColor: "#fff",
                    textStyle: { color: "rgba(255, 255, 255, 0.85)" },
                    data: ["周中", "周末"],
                },
                grid: {
                    x: 25,
                    x2: 40,
                    y: 75,
                    y2: 75,
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "rgba(43, 113, 176, 0.45)",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                    },
                    data: [
                        "第1周",
                        "第2周",
                        "第3周",
                        "第4周",
                        "第5周",
                        "第6周",
                        "第7周",
                        "第8周",
                        "第9周",
                        "第10周",
                        "第11周",
                        "第12周",
                        "第13周",
                        "第14周",
                        "第15周",
                        "第16周",
                        "第17周",
                        "第18周",
                        "第19周",
                        "第20周",
                        "第21周",
                        "第22周",
                        "第23周",
                        "第24周",
                        "第25周",
                        "第26周",
                        "第27周",
                        "第28周",
                        "第29周",
                        "第30周",
                        "第31周",
                        "第32周",
                        "第33周",
                        "第34周",
                        "第35周",
                        "第36周",
                        "第37周",
                        "第38周",
                        "第39周",
                        "第40周",
                        "第41周",
                        "第42周",
                        "第43周",
                        "第44周",
                        "第45周",
                        "第46周",
                        "第47周",
                        "第48周",
                        "第49周",
                        "第50周",
                        "第51周",
                        "第52周",
                    ],
                },
                yAxis: {
                    name: "单位:kWh",
                    min: 0,
                    max: yMax,
                    interval: yMax / 5,
                    type: "value",
                    axisLabel: {
                        color: "#fff",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(230,247,255,0.2)",
                        },
                    },
                },
                series: [
                    {
                        name: "周中",
                        barWidth: 4,
                        data: workList,
                        type: "bar",
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1EE7E7" },
                                { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
                            ]),
                        },
                    },
                    {
                        name: "周末",
                        barWidth: 4,
                        data: restList,
                        type: "bar",
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1890FF" },
                                { offset: 1, color: "rgba(24, 144, 255, 0.35)" },
                            ]),
                        },
                    },
                ],
            };
            // 绘制图表
            myChart.setOption(option, true);
            myChart._dom.childNodes[0].childNodes[0].style.width = "58rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "42rem";
        },
        drawLineYear () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(this.$refs.myChart);
            // 绘制图表
            if (myChart == null) {
                myChart = this.$echarts.init(this.$refs.myChart);
            }
            let workList = [];
            let restList = [];
            workList = this.barList3;
            restList = this.barList4;
            let yMax = 0;
            for (let i = 0; i < workList.length; i++) {
                const item = workList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            for (let i = 0; i < restList.length; i++) {
                const item = restList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            yMax = this.ceilNumber(yMax);
            let option = {
                color: ["#1EE7E7", "#1890FF"],
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    orient: "horizontal",
                    x: "center", //可设定图例在左、右、居中
                    y: "bottom", //可设定图例在上、下、居中
                    padding: [0, 0, 15, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                    textStyle: { color: "rgba(255, 255, 255, 0.85)" },
                    data: ["周中", "周末"],
                },
                grid: {
                    x: 25,
                    x2: 40,
                    y: 65,
                    y2: 40,
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "rgba(43, 113, 176, 0.45)",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                    },
                    data: [
                        "第1周",
                        "第2周",
                        "第3周",
                        "第4周",
                        "第5周",
                        "第6周",
                        "第7周",
                        "第8周",
                        "第9周",
                        "第10周",
                        "第11周",
                        "第12周",
                        "第13周",
                        "第14周",
                        "第15周",
                        "第16周",
                        "第17周",
                        "第18周",
                        "第19周",
                        "第20周",
                        "第21周",
                        "第22周",
                        "第23周",
                        "第24周",
                        "第25周",
                        "第26周",
                        "第27周",
                        "第28周",
                        "第29周",
                        "第30周",
                        "第31周",
                        "第32周",
                        "第33周",
                        "第34周",
                        "第35周",
                        "第36周",
                        "第37周",
                        "第38周",
                        "第39周",
                        "第40周",
                        "第41周",
                        "第42周",
                        "第43周",
                        "第44周",
                        "第45周",
                        "第46周",
                        "第47周",
                        "第48周",
                        "第49周",
                        "第50周",
                        "第51周",
                        "第52周",
                    ],
                },
                yAxis: {
                    type: "value",
                    min: 0,
                    max: yMax,
                    interval: yMax / 5,
                    axisLabel: {
                        color: "#fff",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "rgba(230,247,255,0.2)",
                        },
                    },
                },
                series: [
                    {
                        name: "周中",
                        barWidth: 4,
                        data: workList,
                        type: "bar",
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1EE7E7" },
                                { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
                            ]),
                        },
                    },
                    {
                        name: "周末",
                        barWidth: 4,
                        data: restList,
                        type: "bar",
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1890FF" },
                                { offset: 1, color: "rgba(24, 144, 255, 0.35)" },
                            ]),
                        },
                    },
                ],
            };
            // 绘制图表
            myChart.setOption(option, true);
            //   myChart._dom.childNodes[0].childNodes[0].style.width = "58rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "42rem";
        },
    },

    mounted () {
        // 加载“日”的图
        // this.changeTab(1);
        // this.drawLineDay();
    },
    created () {
        this.getYear();
    },
    computed: {
        ...mapState({
            getAnalysisWeek: (state) => {
                return state.elecManagement.getAnalysisWeek.data;
            },
        }),
    },
    watch: {
        "$store.state.storage.localtionId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.dataForm.localtionId = newVal;
                this.btnTypeDay = 'btnGreen'
                this.btnTypeMonth = 'btnDark'
                this.queryTabDay1();
                // this.queryTabDay2();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.echartSingel {
    width: 100%;
    height: 42rem;
    //   width: 58rem;
    background-color: #0d3050;
    border-radius: 0.4rem;
    position: relative;
    // 上半部分的title栏##################################################
    .tabBarPlace1 {
        width: 100%;
        height: 10rem;
        // width: 58rem;
        position: absolute;
        top: 0;
        left: 0;
        .tabBarTop {
            width: 100%;
            height: 5rem;
            // width: 58rem;
            position: absolute;
            top: 0;
            left: 0;
            .word {
                height: 2.2rem;
                font-size: 1.4rem;
                color: #37a5dd;
                line-height: 2.2rem;
                position: absolute;
                top: 2rem;
                left: 2.4rem;
            }
            .btn1 {
                height: 3rem;
                width: 11.6rem;
                position: absolute;
                top: 1.8rem;
                left: 22.8rem;
                background-color: #0b375e;
                border: 0.1rem solid #004579;
                font-size: 1.4rem;
                color: #fff;
                line-height: 3rem;
                padding: 0;
                margin: 0;
                cursor: pointer;
            }
            .dateChoose {
                height: 3rem;
                width: 20rem;
                border-radius: 0.2rem;

                position: absolute;
                top: 1.8rem;
                left: 36rem;
                overflow: hidden;
                border: 0.1rem solid #004579;
                box-sizing: border-box;
                display: flex;
                flex-wrap: nowrap;
                color: #fff;
                .btnDark {
                    height: 3rem;
                    width: 10rem;
                    background-color: #0b375e;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3rem;
                    text-align: center;
                }
                .btnGreen {
                    height: 3rem;
                    width: 10rem;
                    background-color: #027969;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3rem;
                    text-align: center;
                }
            }

            .img1 {
                height: 2.4rem;
                width: 2.4rem;
                position: absolute;
                top: 2.8rem;
                left: 53.2rem;
                cursor: pointer;
            }
        }
        .table-bottom {
            position: absolute;
            right: 2.4rem;
            top: 1.5rem;
            float: right;
            display: flex;
            .weekTime {
                margin-right: 1.6rem;
                .selectTime {
                    width: 12rem;
                    height: 3.6rem;
                }
            }
            .dateChoose {
                height: 2.9rem;
                width: 19rem;
                border-radius: 0.4rem;
                overflow: hidden;
                border: 0.1rem solid #004579;
                box-sizing: border-box;
                display: flex;
                flex-wrap: nowrap;
                color: #fff;
                .btnDark {
                    width: 9.6rem;
                    height: 2.9rem;
                    background-color: #0b375e;
                    font-size: 1.4rem;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 2.7rem;
                    text-align: center;
                }
                .btnGreen {
                    width: 9.6rem;
                    height: 2.9rem;
                    background-color: #027969;
                    font-size: 1.4rem;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 2.7rem;
                    text-align: center;
                }
                // #btnLeft{
                //   border-top-left-radius: 0.4rem;
                //   border-bottom-left-radius: 0.4rem;
                // }
                // #btnRight{
                //   border-top-right-radius: 0.4rem;
                //   border-bottom-right-radius: 0.4rem;
                // }
            }
        }
    }
    .c2 {
        width: 100%;
        // width: 58rem;
        height: 42rem;
    }
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
</style>
