import {Channel} from "./transmit.js";
import {CustomEventTypeEnum} from "./enums.js";
import { setSvgAttrs } from './index.js'

// 可操作事件
let _this
export default (graph, vue) => {
    _this = vue;
    bindKey(graph, vue)
}

const State = {
    offset: 30,
    useLocalStorage: true
}


var pos
// https://x6.antv.vision/zh/docs/api/graph/keyboard/#bindkey
function bindKey(graph,vue) {

    let _this =  vue
    document.onmousemove = function (e) {
        pos = graph.pageToLocal( e.clientX,  e.clientY)
    }
    //按下ctrl启用画布平移 禁用框选事件
    graph.bindKey(['shift'], () => {
        graph.enablePanning() // 启用画布平移
        graph.disableSelection()
        // 判断一个键盘事件是否应该被处理，返回 false 时对应的键盘事件被忽略。
        return false
    },'keydown')
    //松开ctrl禁用画布平移 启用框选事件
    graph.bindKey(['shift'], () => {
        graph.disablePanning() // 禁用画布平移
        graph.enableSelection()
        // 判断一个键盘事件是否应该被处理，返回 false 时对应的键盘事件被忽略。
        return false
    },'keyup')
    // 复制
    graph.bindKey(['ctrl+c', 'command+c'], () => {
        const cells = graph.getSelectedCells()
        console.log(cells,'store.data.attrs.body')
        if (cells.length) {
            // graph.copy(cells, {
            //     useLocalStorage: true,
            // })
            graph.copy(cells)
        }
        // 判断一个键盘事件是否应该被处理，返回 false 时对应的键盘事件被忽略。
        return false
    })
    // 粘贴
    graph.bindKey(['ctrl+v', 'command+v'], () => {
        if (!graph.isClipboardEmpty()) {
            const cells = graph.paste({offset: {dx: document.getElementsByClassName('x6-widget-selection-inner')[0].clientWidth / 2 + 20, dy: 0}})

            graph.cleanSelection()
            graph.select(cells)
            let attrs = {}
            cells.forEach(cell => {
                if (cell.isNode()) {
                    if (cell.store.data.data.actionType == "SVG_FONT" || cell.store.data.data.actionType == "SVG_HTML") {
                        attrs = {
                            width: cell.store.data.size.width,
                            height: cell.store.data.size.height,
                            color: cell.store.data.data.actionType == "SVG_FONT" ? cell.store.data.attrs.body.color : cell.store.data.data.actionType == "SVG_HTML" ? cell.attrs.props.stroke : cell.attrs.prop.stroke,
                            fontSize: cell.store.data.attrs.body.fontSize,
                            fontWeight: cell.store.data.attrs.body.fontWeight,
                            fontFamily: cell.store.data.attrs.body.fontFamily,
                            content: cell.store.data.attrs.body.content,
                            strokeWidth: cell.store.data.data.actionType == "SVG_HTML" ? cell.attrs.props.strokeWidth : null,
                            rotate: cell.store.data.angle
                        }
                        setSvgAttrs(_this, cell, attrs)
                    }
                }
            })
            // if (cells.length == 1) {
            //     cells[0].position(
            //             parseInt(pos.x),
            //             parseInt(pos.y)
            //     );
            //     if (cells[0].store.data.shape == "html") {
            //         let svgObjectDom = document.querySelectorAll(
            //                 `g[data-cell-id="${cells[0].id}"] foreignObject g`
            //         );
            //         if (svgObjectDom.length != 0) {
            //             let svgElList = svgObjectDom[0].children;
            //             for (let i = 0; i < svgElList.length; i++) {
            //                 svgElList[i].setAttribute(
            //                         "stroke-width",
            //                         cells[0].attrs.props.strokeWidth
            //                 );
            //                 svgElList[i].setAttribute("stroke", cells[0].attrs.props.stroke);
            //                 if (svgElList[i].getAttribute("fill") != "none") {
            //                     svgElList[i].setAttribute("fill", cells[0].attrs.props.stroke);
            //                 }
            //             }
            //         }else {
            //             let svgObjectDomwzbq = document.querySelectorAll(
            //                     `g[data-cell-id="${cells[0].id}"] foreignObject .wzbq`
            //             );
            //             let svgElListwzbq = svgObjectDomwzbq[0].children;
            //             for (let i = 0; i < svgElListwzbq.length; i++) {
            //                 svgElListwzbq[i].setAttribute("value", cells[0].attrs.body.content);
            //                 svgElListwzbq[i].setAttribute(
            //                         "style",
            //                         `width: 70px;border: none;outline: none;text-align: left;background-color: rgba(0,0,0,0);font-size:${cells[0].attrs.body.fontSize}px;font-family:${cells[0].attrs.body.fontFamily};font-weight:${cells[0].attrs.body.fontWeight};color:${cells[0].attrs.body.color}`
            //                 );
            //             }
            //         }
            //         let resultJson = eval("(" + _this.diagramObj.diagramJson + ")");
            //         cells[0].store.data.componentId = _this.uuid(32);
            //         resultJson.cells.push(cells[0].store.data);
            //         _this.diagramObj.diagramJson = JSON.stringify(resultJson);
            //     }
            // }

            // _this.graph.on('render:done', _this.showResult)

        }
        return false
    })
    // 全选
    graph.bindKey(['ctrl+a', 'command+a'], () => {
        const cells = graph.getCells();
        if (cells.length) {
            graph.select(cells);
        }
        return false
    })
    // 删除
    graph.bindKey(['delete', 'backspace'], () => {
        const cells = graph.getSelectedCells();
        if (cells.length) {
            // 删除前移除所有包含工具
            cells.forEach(currentCell => {
                currentCell.removeTools()
            });
            graph.removeCells(cells);
        }
        return false
    }, 'keydown')
    // 撤销
    graph.bindKey(['ctrl+z', 'command+z'], () => {
        graph?.history?.undo();
        return false
    })
    // 重做
    graph.bindKey(['ctrl+y', 'shift+command+z'], () => {
        graph?.history?.redo();
        return false
    })
    // Esc 取消所有选中单元 & 关闭提示
    graph.bindKey('esc', () => {
        graph.cleanSelection()
        Channel.dispatchEvent(CustomEventTypeEnum.HELP, 'close')
        return false
    })
    // help
    graph.bindKey(['alt+h', 'option+h'], () => {
        Channel.dispatchEvent(CustomEventTypeEnum.HELP)
        return false
    })
    // 居中
    graph.bindKey(['alt+f', 'option+f'], () => {
        graph.centerContent();
        return false
    })
}

// 粘贴
export function onPaste(graph) {
    let graphCopy
    const cells = graph?.getSelectedCells()
    console.log(cells.length);
    if (cells && cells.length) {
        graph.copy(cells, State)


    }
    if (!graph.isClipboardEmpty()) {
        const cells = graph.paste(State)
        graphCopy = cells;
        graph.cleanSelection()
        graph.select(cells)
    }
    return graphCopy
}

export function deleteCells(graph) {
    const cells = graph.getSelectedCells();
    if (cells.length) {
        // 删除前移除所有包含工具
        cells.forEach(currentCell => {
            currentCell.removeTools()
        });
        graph.removeCells(cells);
    }
}

// 全选
export function selectAll(graph) {
    const cells = graph.getCells();
    if (cells.length) {
        graph.select(cells);
    }
}

/**
 * 冻结画布
 */
export function freezeGraph(graph) {
    const cells = graph.getCells();
    if (cells.length) {
        cells.forEach(cell => {
            cell.removeTools()
            cell.setData({disableMove: true})
        });
    }
    // 移除连接桩子会导致 边 移除
    // const nodes = graph.getNodes()
    // for (const node of nodes) {
    //     // 禁用所有连接桩
    //     node.removePorts()
    // }
    graph.freeze()
            // 单选框
            .disableSelection()
            // 多选框
            .disableMultipleSelection()
            // 清空选区
            .cleanSelection()
            // 剪切板
            .disableClipboard()
            // 历史记录
            .disableHistory()
            // 对齐线
            .disableSnapline()
            // 快捷键
            .disableKeyboard()
    Channel.dispatchEvent(CustomEventTypeEnum.FREEZE_GRAPH, true);
}

/**
 * 解冻
 */
export function unfreezeGraph(graph) {
    const cells = graph.getCells();
    if (cells.length) {
        cells.forEach(cell => {
            cell.setData({disableMove: false})
        });
    }
    graph.unfreeze()
            .enableSelection()
            .enableMultipleSelection()
            .enableClipboard()
            .enableHistory()
            .enableSnapline()
            .enableKeyboard()
    Channel.dispatchEvent(CustomEventTypeEnum.FREEZE_GRAPH, false);
}
