<template>
  <div class="dialog_echarts">
    <div ref="myChart" class="charts"></div>
  </div>
</template>

<script>
export default {
  props: {
    optionData: {
      type: Object,
      default: () => { }
    },
    chartsType: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {

    };
  },
  watch: {

  },
  methods: {
    // 销毁echarts
    dispose () {
      this.$echarts.dispose(this.$refs.myChart)
    },
    init () {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.myChart)
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.myChart)
      }
      let option = {
        color: this.optionData.color ? this.optionData.color : ['#3673E8', '#61B8FF', '#F5B763'],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: this.optionData.showLegend || false,
          textStyle: {
            color: '#fff'
          },
          bottom: 20,
          data: this.optionData?.legendData
        },
        grid: {
          top: 30,
          left: '3%',
          right: 15,
          bottom: '12%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.6)'
            }
          },
          axisLabel: {
            color: '#fff'
          },
          data: this.optionData.xAxisData
        },
        yAxis: {
          name: this.optionData.yAxisName,
          nameLocation: 'end',
          nameTextStyle: {
            color: 'rgba(230, 247, 255, 0.7)'
          },
          nameGap: 15,
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dotted',
              color: 'rgba(255, 255, 255, 0.15)'
            }
          },
        },
        series: this.optionData.seriesData
      }
      // 绘制图表
      myChart.setOption(option);
    },
  },
  mounted () {
    // this.init()
  },
};
</script>

<style lang="less" scoped>
.dialog_echarts {
  .charts {
    width: 100%;
    min-height: 40rem;
  }
}
</style>