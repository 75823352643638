<template>
  <div class="DialogAddStandard">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div v-show="getType===1" class="equip_title">新增作业标准</div>
        <div v-show="getType===2" class="equip_title">编辑作业标准</div>
      </div>

      
      <el-form :model="form" class="elForm" :rules="rules" ref="ruleForm" >
          <el-form-item label="作业标准名称" prop="name" style="width: 100%;">
            <el-input v-model="form.name" placeholder="请输入" size="small"  clearable></el-input>
          </el-form-item>
          <el-form-item label="作业标准类型" prop="typeId" style="width: 100%;">
            <el-select v-model="form.typeId" placeholder="请选择作业标准类型" style="width: 100%" size="small">
              <el-option
                v-for="(item,index) in typeData"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status" style="width: 100%;">
            <el-select v-model="form.status" placeholder="请选择作业标准状态" style="width: 100%" size="small">
              <el-option label="停用" value="0"></el-option>
              <el-option label="启用" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注" prop="remark" style="width: 100%;">
            <el-input v-model="form.remark" placeholder="请输入" size="small"  clearable></el-input>
          </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
      </div>

    
   
    </el-dialog>

  
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import { validateInput,validateSelect} from "@/utils/validate";

export default {
  name: "DialogAddStandard",
  mixins: [tableBg],
  data() {
    return {
      // 类型列表
      typeData:[],
      // 请求类型（编辑=2，登记=1）
      getType: 0,
      // 展示判断
      dialogFormVisible: false,
  
      // 表单数据存放处
      form: {name:"",remark:"",typeId:"",status:""},
      tableList: [],
      // 校验规则
      rules: {
        name: [validateInput],
        typeId:[validateSelect]                  
      },
      
    
    };
  },

  methods: {
    // dialog关闭的回调
    closeDialog(ruleForm) {
      this.formInit();
      this.$refs[ruleForm].resetFields();
   
    },

    // 取消弹框
    handleCancle(ruleName) {
      this.$refs[ruleName].resetFields();
      this.dialogFormVisible = false;
      this.formInit();
    },

    // from归零
    formInit() {
      this.form = {name:"",remark:"",typeId:"",status:""};
    },
   
    //父组件点击触发
    show() {
      this.dialogFormVisible = true
      this.getType = 1
      this.typeData = []
      // 获取下拉列表
      this.$store.dispatch('getRepairWorkStandardTypeAll').then(()=>{
         for(var i=0;i<this.repairWorkStandardTypeAll.length;i++){
           const standardSingel = {label:"",value:""}
           standardSingel.label = this.repairWorkStandardTypeAll[i].typeName
           standardSingel.value = this.repairWorkStandardTypeAll[i].id
           this.typeData.push(standardSingel)
         }
      })
   
    },

    //父组件触发来进行编辑
    showForEdit(val) {
      this.dialogFormVisible = true
      this.getType = 2
      this.typeData = []
      this.$store.dispatch('getRepairWorkStandardTypeAll').then(()=>{
         for(var i=0;i<this.repairWorkStandardTypeAll.length;i++){
           const standardSingel = {label:"",value:""}
           standardSingel.label = this.repairWorkStandardTypeAll[i].typeName
           standardSingel.value = this.repairWorkStandardTypeAll[i].id
           this.typeData.push(standardSingel)
         }
        this.form.name = val.name
        this.form.typeId = val.typeId 
        this.form.id= val.id
        this.form.remark = val.remark 
        this.form.status = val.status
      })
      

    },


    // 提交发送请求（一级dialog点击《保存》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate(valid => {
        if (valid) {
          // 发送新增请求
          if (this.getType === 1) {
            this.$store.dispatch("postRepairWorkStandardAdd", this.form).then(() => {
              if (this.ifAaddPageDataSuccess === 0) {
                this.$message({ message: "添加成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePage();
                this.dialogFormVisible = false;
                this.formInit()
              } else if (this.ifAaddPageDataSuccess === -1) {
                this.$message({
                  message: this.ifAaddPageDataError,
                  type: "warning"
                });
              }
            });
          } else if (this.getType === 2) {
            // 发送编辑请求
            this.$store.dispatch("putRepairWorkStandard", this.form).then(() => {
              if (this.ifEditWorkStandardSuccess === 0) {
                this.$message({ message: "编辑成功！", type: "success" });
                this.dialogFormVisible = false;
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePage();          
                this.formInit()
              } else if (this.ifEditWorkStandardSuccess === -1) {
                this.$message({
                  message: this.ifEditWorkStandardError,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$message.error("有必填项未填");
        }
       
      });
    }
  },
  //处理state数据
  computed: {
    ...mapState({
      // 获取下拉列表
      repairWorkStandardTypeAll:state=>{return state.repair.repairWorkStandardTypeAll.data},

      // 新增
      ifAaddPageDataSuccess: state => {return state.repair.ifAaddPageDataSuccess.errorCode;},
      ifAaddPageDataError:state => {return state.repair.ifAaddPageDataSuccess.message;},

      // 编辑
      ifEditWorkStandardSuccess: state => {return state.repair.ifEditWorkStandardSuccess.errorCode;},
      ifEditWorkStandardError:state => {return state.repair.ifEditWorkStandardSuccess.message;},
      
    })
  },

 
};




</script>

<style lang="less" scoped>
.DialogAddStandard{
  overflow: hidden;
  .el-dialog__body{
    height: 60rem;
  }
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
// 树所在的区域###################
.treePlace{
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 80rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  
  
  // border: #071828 0.2rem solid;
}
.treePlaceGoAway{
  // opacity: 0;
  display: none;
  // position:relative;
  // top: -54rem;
  // left: 80rem;
  height: 0;
  width: 26rem;
  overflow: hidden;
  // display: none;
  transition: height 0.3s;  
}

.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}


</style>




<style lang="less">
.DialogAddStandard{
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  .el-table::before {
      opacity: 0;
  }

  
}

</style>



