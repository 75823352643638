<template>
  <div class="ComputedPart">
    <div class="radioPlace">
      <div class="word">电费计算:</div>
      <el-radio class="radio" @change="changePrice(1)" v-model="POWERPRICE_STRATEGY" label="1">单一电价</el-radio>
      <el-radio class="radio" @change="changePrice(2)" v-model="POWERPRICE_STRATEGY" label="2">阶梯电价</el-radio>
      <el-radio class="radio" @change="changePrice(3)" v-model="POWERPRICE_STRATEGY" label="3">分时电价</el-radio>
    </div>
    <!-- 左上方区域输入框####################################### -->
    <div class="textAreaPlace">
      <!-- 单一电价 -->
      <div v-if="POWERPRICE_STRATEGY === '1'" class="innerArea">
        <InnerArea1 :radioValue="POWERPRICE_STRATEGY" />
      </div>
      <!-- 阶梯电价 -->
      <div v-if="POWERPRICE_STRATEGY === '2'" class="innerArea">
        <InnerArea2 :radioValue="POWERPRICE_STRATEGY" />
      </div>
      <!-- 分时电价 -->
      <div v-if="POWERPRICE_STRATEGY === '3'" class="innerArea">
        <InnerArea3 :radioValue="POWERPRICE_STRATEGY" />
      </div>
    </div>
    <div>
      <div class="comRigth">
        <div class="con2">能耗指标参数</div>
        <div class="con-content">
          <el-form ref="dataForm" :model="dataForm" label-width="120px">
            <el-form-item label="建筑面积：">
              <el-input
                @blur="inputNumberOnblur4()"
                size="mini"
                v-model="dataForm.COVERAREA"
                class="energyParams"
              ></el-input>
              <span>㎡</span>
            </el-form-item>
            <el-form-item label="区域人口：">
              <el-input
                @blur="inputNumberOnblur5()"
                size="mini"
                v-model="dataForm.COVERPEOPLE"
                class="energyParams"
              ></el-input>
              <span>人</span>
            </el-form-item>

            <el-form-item label="空调建筑面积：">
              <el-input
                @blur="inputNumberOnblur6()"
                size="mini"
                v-model="dataForm.COVERAREAAC"
                class="energyParams"
              ></el-input>
              <span>㎡</span>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div>
      <div class="conBottomLeft">
        <div class="con3">碳排放计算：</div>
        <div class="conputent">
          <div class="con-img-inline">
            <img class="img1" src="@/assets/energyStatistical/quantity.png" alt />
            <img class="img2" src="@/assets/energyStatistical/vector.png" alt />
            <img class="img3" src="@/assets/energyStatistical/square.png" alt />
          </div>
          <div class="con-ipt">
            <span class="ipt-left">1kWh =</span>
            <el-input-number
              v-model="dataForm.CO2"
              size="mini"
              class="btn-inp"
              @blur="inputNumberOnblur1"
              :precision="2"
              :step="0.01"
              controls-position="right"
              @change="changeElecPriceSingel1"
              :min="0"
              :max="1000000"
            ></el-input-number>
            <span class="ipt-rigth">kg</span>
          </div>
        </div>
      </div>
      <div class="conBottomRigth">
        <div class="con3">标准煤计算：</div>
        <div class="conputent">
          <div class="con-img-inline">
            <img class="img1" src="@/assets/energyStatistical/quantity.png" alt />
            <img class="img2" src="@/assets/energyStatistical/vector.png" alt />
            <img class="img3" src="@/assets/energyStatistical/buyCar.png" alt />
          </div>
          <div class="con-ipt">
            <span class="ipt-left">1kWh =</span>
            <el-input-number
              v-model="dataForm.COAL"
              size="mini"
              class="btn-inp"
              @blur="inputNumberOnblur2"
              :precision="2"
              :step="0.01"
              controls-position="right"
              @change="changeElecPriceSingel2"
              :min="0"
              :max="1000000"
            ></el-input-number>
            <span class="ipt-rigth">kg</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InnerArea1 from "./computerPart/innerArea1";
import InnerArea2 from "./computerPart/innerArea2";
import InnerArea3 from "./computerPart/innerArea3";
export default {
  components: {
    InnerArea1,
    InnerArea2,
    InnerArea3,
  },
  name: "ComputedPart",

  data() {
    return {
      dataForm: {
        CO2: "0.00",
        COAL: "0.02",
        COVERAREA: "",
        COVERPEOPLE: "",
        COVERAREAAC: "",
        eleName: "",
        value: "",
      },
      // radio的双向绑定值
      POWERPRICE_STRATEGY: "2",//启用哪个电价策略?
      timeout1: null,
      timeout2: null,
    };
  },
  mounted() {
    // this.getEnergyNum();
  },
  methods: {
    //获取对应的信息
    findInfo(key){
      return this.getGeneralSetting.find((item => item.name === key))
    },
    getEnergyNum() {
      this.$store.dispatch("getGeneralSetting", {
          generator: "ENERGYMANAGEMENT",
          customerId: this.$store.state.storage.customerId,
        }).then(() => {
        this.elecPiceList = this.getGeneralSetting;
        // console.log(this.elecPiceList);
        this.dataForm.CO2 = this.findInfo('CO2')?.value;
        this.dataForm.COAL = this.findInfo('COAL')?.value;
        this.POWERPRICE_STRATEGY = this.findInfo('POWERPRICE_STRATEGY')?.value || 1;
        // 返回值需要使用JSON.parse()进行处理。
        this.dataForm.COVERAREA = this.findInfo('COVERAREA')?.value;
        this.dataForm.COVERPEOPLE = this.findInfo('COVERPEOPLE')?.value;
        this.dataForm.COVERAREAAC = this.findInfo('COVERAREAAC')?.value;
      });
    },
    changePrice(i) {
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: i,
      };
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
      // if (i === 1) {
      //   this.$store
      //     .dispatch("putGeneral", {
      //       id: this.findInfo('POWERPRICE_STRATEGY').id,
      //       value: 1
      //     })
      //     .then(() => {
      //       this.$message({ message: "编辑成功！", type: "success" });
      //     });
      // } else if (i === 2) {
      //   this.POWERPRICE_STRATEGY = "2";
      //   this.$store
      //     .dispatch("putGeneral", {
      //       id: this.findInfo('POWERPRICE_STRATEGY').id,
      //       value: 2
      //     })
      //     .then(() => {
      //       this.$message({ message: "编辑成功！", type: "success" });
      //     });
      // } else if (i === 3) {
      //   this.POWERPRICE_STRATEGY = "3";
      //   this.$store
      //     .dispatch("putGeneral", {
      //       id: this.findInfo('POWERPRICE_STRATEGY').id,
      //       value: 3
      //     })
      //     .then(() => {
      //       this.$message({ message: "编辑成功！", type: "success" });
      //     });
      // }
    },
    // 更改计数器内部的值
    changeElecPriceSingel1() {
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      let id = this.findInfo('CO2').id;
      let value = {
        value: this.dataForm.CO2,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.POWERPRICE_STRATEGY,
      };
      this.timeout1 = setTimeout(() => {
        this.$store
          .dispatch("putGeneral", {
            id: id,
            data: value,
          })
          .then(() => {
            if (this.putGeneral) {
              this.$message({ message: "编辑成功！", type: "success" });
            } else {
              this.$message({ message: "编辑失败，请重试!", type: "warning" });
            }
          });
        this.$store
          .dispatch("putGeneral", {
            id: id1,
            data: value1,
          })
          .then(() => {
            // console.log('保存radioValue');
          });
      }, 50);
    },
    changeElecPriceSingel2() {
      if (this.timeout2) {
        clearTimeout(this.timeout2);
      }
      let id = this.findInfo('COAL').id;
      let value = {
        value: this.dataForm.COAL,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.POWERPRICE_STRATEGY,
      };
      this.timeout2 = setTimeout(() => {
        this.$store
          .dispatch("putGeneral", {
            id: id,
            data: value,
          })
          .then(() => {
            if (this.putGeneral) {
              this.$message({ message: "编辑成功！", type: "success" });
            } else {
              this.$message({ message: "编辑失败，请重试!", type: "warning" });
            }
          });
        this.$store
          .dispatch("putGeneral", {
            id: id1,
            data: value1,
          })
          .then(() => {
            // console.log('保存radioValue');
          });
      }, 50);
    },
    // input失去焦点触发函数
    inputNumberOnblur1() {
      // alert("失去焦点,发送请求");
    },
    inputNumberOnblur2() {
      // alert("失去焦点,发送请求");
    },
    inputNumberOnblur4() {
      let id = this.findInfo('COVERAREA').id;
      let value = {
        value: this.dataForm.COVERAREA,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.POWERPRICE_STRATEGY,
      };
      this.$store.dispatch("putGeneral", { id: id, data: value }).then(() => {
        if (this.putGeneral) {
          this.$message({ message: "编辑成功！", type: "success" });
        } else {
          this.$message({ message: "编辑失败，请重试!", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
    inputNumberOnblur5() {
      let id = this.findInfo('COVERPEOPLE').id;
      let value = {
        value: this.dataForm.COVERPEOPLE,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.POWERPRICE_STRATEGY,
      };
      this.$store.dispatch("putGeneral", { id: id, data: value }).then(() => {
        if (this.putGeneral) {
          this.$message({ message: "编辑成功！", type: "success" });
        } else {
          this.$message({ message: "编辑失败，请重试!", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
    inputNumberOnblur6() {
      let id = this.findInfo('COVERAREAAC').id;
      let value = {
        value: this.dataForm.COVERAREAAC,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.POWERPRICE_STRATEGY,
      };
      this.$store.dispatch("putGeneral", { id: id, data: value }).then(() => {
        if (this.putGeneral) {
          this.$message({ message: "编辑成功！", type: "success" });
        } else {
          this.$message({ message: "编辑失败，请重试!", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
  },
  computed: {
    ...mapState({
      getGeneralSetting: (state) => {
        return state.elecManagement.getGeneralSetting.data;
      },
      putGeneral: (state) => {
        return state.elecManagement.putGeneral.data;
      },
    }),
  },
  watch: {
    '$store.state.storage.customerId': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return
        }
        this.getEnergyNum();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.ComputedPart {
  height: 81.7rem;
  width: 142.8rem;
  background-color: #0d3050;
  position: relative;
  top: 0;
  left: 0;
  .radioPlace {
    height: 2.2rem;
    width: 66.2rem;
    margin: 2.8rem 0 2.8rem 0;
    position: absolute;
    left: 2.4rem;
    top: 0.8rem;

    .word {
      display: inline-block;
      height: 2.2rem;
      line-height: 2.2rem;
      font-size: 1.4rem;
      color: #fff;
      margin-right: 1rem;
    }
    .radio {
      height: 2.2rem;
      line-height: 2.2rem;
      font-size: 1.4rem;
      color: rgba(255, 255, 255, 0.85);
    }
  }

  .textAreaPlace {
    position: absolute;
    top: 8.7rem;
    left: 2.4rem;
    height: 25.4rem;
    width: 66.2rem;
    border: 0.1rem solid #004579;
    background-color: #0c375e;
  }
  .conBottomLeft {
    position: absolute;
    top: 38rem;
    left: 2.4rem;
    .con3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .conputent {
      width: 31rem;
      height: 13.6rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      margin-top: 3rem;
      // position: relative;
      .con-img-inline {
        position: absolute;

        left: 50%;
        margin-left: -7rem;
        top: 8rem;
        .img1 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
        .img2 {
          width: 1.6rem;
          height: 1.5rem;
          margin-left: 1rem;
          position: relative;
          top: -0.8rem;
        }
        .img3 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
      }
      .con-ipt {
        position: absolute;
        top: 13rem;
        left: 3rem;
        .btn-inp {
          width: 13rem;
          // height: 3.2rem;
        }
        .ipt-left {
          font-size: 1.4rem;
          color: #ffffff;
          margin-right: 1.5rem;
        }
        .ipt-rigth {
          // position: absolute;
          // left: 23.5rem;
          // top: 0.5rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 1.5rem;
        }
      }
    }
  }
  .conBottomRigth {
    position: absolute;
    top: 38rem;
    left: 38rem;
    .con3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .conputent {
      width: 31rem;
      height: 13.6rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      margin-top: 3rem;
      // position: relative;
      .con-img-inline {
        position: absolute;

        left: 50%;
        margin-left: -7rem;
        top: 8rem;
        .img1 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
        .img2 {
          width: 1.6rem;
          height: 1.5rem;
          margin-left: 1rem;
          position: relative;
          top: -0.8rem;
        }
        .img3 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
      }
      .con-ipt {
        position: absolute;
        top: 13rem;
        left: 3rem;
        .btn-inp {
          width: 13rem;
          // height: 3.2rem;
        }
        .ipt-left {
          font-size: 1.4rem;
          color: #ffffff;
          margin-right: 1.5rem;
        }
        .ipt-rigth {
          // position: absolute;
          // left: 23.5rem;
          // top: 0.5rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 1.5rem;
        }
      }
    }
  }
  .comRigth {
    position: absolute;
    left: 75rem;
    top: 1.8rem;
    width: 29.9rem;
    height: 26rem;
    padding-top: 2rem;
    .con2 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .con-content {
      margin-top: 2.6rem;
      padding-top: 2.4rem;
      width: 100%;
      height: 100%;
      background: rgba(0, 115, 222, 0.1);
      border: 0.1rem solid #004579;
      box-sizing: border-box;
      span {
        position: absolute;
        margin-left: 0.8rem;
        color: rgba(255, 255, 255, 0.85);
      }
      .energyParams {
        width: 9rem;
      }
    }
  }
}
</style>

<style lang="less">
.ComputedPart {
  .el-radio__label {
    font-size: 1.4rem;
  }
  .el-input-number__increase {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease {
    background-color: #0b3d6b;
  }
  .el-input-number .is-controls-right {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #0b3d6b;
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid #027969;
    border-left: 0.1rem solid #027969;
    box-shadow: none;
    border: 0.1rem solid #027969;
    box-sizing: border-box;
    border: none;
  }
  ::-webkit-scrollbar,
  .el-select-dropdown::-webkit-scrollbar {
    width: 0;
  }
  .el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 0.4rem 0 0;
    border-bottom: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }

  .el-input-number.is-controls-right .el-input-number__decrease {
    border-left: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 1.4rem;
    padding-right: 0;
    color: #fff;
    line-height: 4rem;
    box-sizing: border-box;
  }
}
</style>
