<template>
    <div class="genderPower">
        <div class="gen-header">
            <div class="form">
                <el-form :inline="true" :model="dataForm" class="demo-form-inline">
                    <!-- <el-form-item label="选择设备">
                        <el-select class="styleWid inputSelect" size="mini" v-model="dataForm.powerName" placeholder="请选择设备" @change="changePowerName">
                            <el-option v-for="item in powerNameList" :key="item.label" :label="item.value" :value="item.label"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="选择逆变器">
                        <el-select class="styleWid inputSelect" size="mini" v-model="dataForm.powerName" placeholder="选择逆变器" @change="changePowerName">
                            <el-option v-for="item in powerNameList" :key="item.label" :label="item.value" :value="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间维度">
                        <el-select @change="changeDimenTime" class="styleWid" size="mini" v-model="dataForm.dimension" placeholder="选择维度">
                            <el-option v-for="item in dimensionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="统计时间">
                        <!-- 日 -->
                        <el-date-picker class="styleWid" size="mini" v-show="showDay" v-model="generalDay" type="date" placeholder="选择日期" @change="changeDate"></el-date-picker>
                        <!-- 月 -->
                        <el-date-picker v-show="showMouth" class="styleWid" size="mini" v-model="generalDay" type="month" placeholder="选择月" @change="changeDate"></el-date-picker>
                        <!-- 年 -->
                        <el-date-picker v-show="showYear" class="styleWid" size="mini" v-model="generalDay" type="year" placeholder="选择年" @change="changeDate"></el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btnColor" size="mini" type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btnMargin" size="mini" type="primary" @click="outFile">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- 表格1 -->
        <div v-show="showTable1" class="gen-content">
            <div class="powerTable">
                <div class="table">
                    <el-table :data="tableData" style="width: 100%" stripe:false height="68rem" border:false :cell-style="cellStyle" :header-cell-style="headerClass" :default-sort="{ prop: 'date', order: 'descending' }">
                        <el-table-column prop="stationName" label="电站名称" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="inverterName" label="所属逆变器名称" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="batteryName" label="设备名称" sortable :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="dateType" label="时间" sortable :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="cddl" label="充电电量(kWh)" sortable :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="fddl" label="放电电量(kWh)" sortable :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="cdsc" label="充电时长(h)" sortable :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="fdsc" label="放电时长(h)" sortable :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="soc" label="电池SOC(%)" sortable :show-overflow-tooltip="true"></el-table-column>
                    </el-table>
                </div>
                <!-- 分页器部分 -->
                <div class="pagination">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="customerTotalNum"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg";
import { exportGenManagementBatteryReport } from "@/api/repair"

export default {
    name: "generalPower",
    mixins: [tableBg],
    data () {
        return {
            dataForm: {
                user: "",
                region: "",
                powerName: "",
                dimension: "",
                staTime: "",
            },
            currentPage: 1,
            pageSize: 10,
            customerTotalNum: 0,
            generalDay: "",
            customerId: "",
            customerName: '',
            showDay: true,
            showMouth: false,
            showYear: false,
            showTable1: true,
            showTable2: false,
            powerNameList: [
                {
                    id: "1",
                    label: "电站一",
                },
                {
                    id: "2",
                    label: "电站二",
                },
            ],
            dimensionList: [
                {
                    value: "1",
                    label: "按日统计",
                },
                {
                    value: "2",
                    label: "按月统计",
                },
                {
                    value: "3",
                    label: "按年统计",
                },
            ],
            staTimeList: [],
            tableData: [
                // {
                //   name: "中南财大",
                //   sbName: "HINNV93409345",
                //   time: "2016-05-01",
                //   pvPower: "0.00",
                //   inverPower: "2.00",
                //   intalPower: "2.00",
                //   money: "2.00",
                // },
                // {
                //   name: "中南财大",
                //   sbName: "HINNV93409345",
                //   time: "2016-05-02",
                //   pvPower: "0.00",
                //   inverPower: "3.00",
                //   intalPower: "3.00",
                //   money: "3.00",
                // }
            ],
            // 数组内套数组，每个数组就是一页数据
            totalPageData: [],
            // 共几页
            pageNum: 1,
            // 当前要显示的数据
            dataShow: [],
            flag: false,
        };
    },
    created () {
        this.dataForm.dimension = "1";
        this.generalDay = new Date();
        this.initBatteryData();
    },
    mounted () {
        this.initBatteryList();
        setTimeout(() => {
            this.flag = true;
        }, 1000);
    },
    methods: {
        onSubmit () {
            if (!this.dataForm.dimension) {
                this.$message({
                    message: "请选择时间维度！",
                    type: "warning",
                });
                return;
            }
            if (!this.generalDay) {
                this.$message({
                    message: "请选择统计时间！",
                    type: "warning",
                });
                return;
            }
            this.currentPage = 1
            this.initBatteryData();
        },
        outFile () {
            let date = this.generalDay || new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month
            let day = date.getDate();
            day = day < 10 ? '0' + day : day
            let generalDay = year + "-" + month + "-" + day
            if (this.dataForm.dimension == 1) {
                generalDay = year + "-" + month + "-" + day
            } else if (this.dataForm.dimension == 2) {
                generalDay = year + "-" + month
            } else if (this.dataForm.dimension == 3) {
                generalDay = year
            }
            let params = {
                customerId: this.customerId,
                datetype: this.dataForm.dimension,
                date: generalDay,
                batteryIds: this.dataForm.powerName,
                name: '储能报表'
            }
            // this.$store.dispatch('getDownloadTemplate', params)
            exportGenManagementBatteryReport(params).then((response) => {
                var blob = new Blob([response], {
                    type: "application/vnd.ms-excel,charset=utf-8",
                }) //创建一个blob对象
                var a = document.createElement("a") //创建一个<a></a>标签
                a.href = URL.createObjectURL(blob) // response is a blob
                a.download = `${this.customerName}${this.dataForm.dimension == 1 ? '日' : this.dataForm.dimension == 2 ? '月' : this.dataForm.dimension == 3 ? '年' : '' }储能报表.xls` //文件名称
                a.style.display = "none"
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        },
        handleSizeChange (newPageSize) {
            this.pageSize = newPageSize;
            // 重新计算页数并分组
            // this.calcPageData();
            this.initBatteryData()
        },
        handleCurrentChange (newPageNum) {
            this.currentPage = newPageNum;
            // this.dataShow = this.totalPageData[newPageNum - 1];
            this.initBatteryData()
        },
        // 对tableData进行处理，分为每一页的数组
        calcPageData () {
            // 计算页数
            if (this.tableData.length > 1) {
                this.pageNum = Math.ceil(this.tableData.length / this.pageSize) || 1;
            }
            // 数据分组，循环的次数是总页数
            for (let i = 0; i < this.pageNum; i++) {
                this.totalPageData[i] = this.tableData.slice(
                    this.pageSize * i,
                    this.pageSize * (i + 1)
                );
            }
            this.dataShow = this.totalPageData[this.currentPage - 1];
        },
        //控制统计时间的三个时间段（日月年）
        changeDimenTime (val) {
            // this.generalDay = "";
            if (val === "1") {
                this.showDay = true;
                this.showMouth = false;
                this.showYear = false;
            } else if (val === "2") {
                this.showDay = false;
                this.showMouth = true;
                this.showYear = false;
            } else if (val === "3") {
                this.showDay = false;
                this.showMouth = false;
                this.showYear = true;
            }
            this.onSubmit();
        },
        changePowerName () {
            this.onSubmit();
        },
        changeDate () {
            this.onSubmit();
        },
        initBatteryData () {
            this.customerId = this.$store.state.storage.customerId;
            let date = this.generalDay;
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) {
                month = "0" + month;
            }
            if (day < 10) {
                day = "0" + day;
            }
            date = year + "-" + month + "-" + day;
            if (this.dataForm.dimension == 1) {
                date = date;
            } else if (this.dataForm.dimension == 2) {
                date = date.split("-")[0] + "-" + date.split("-")[1];
            } else if (this.dataForm.dimension == 3) {
                date = date.split("-")[0];
            }
            // let value = {
            //     customerId: this.customerId,
            //     datetype: this.dataForm.dimension,
            //     date: date,
            //     batteryIds: this.dataForm.powerName,
            // };
            let value = {
                customerId: this.customerId,
                datetype: this.dataForm.dimension,
                date: date,
                inverterIds: this.dataForm.powerName,
                pageNum: this.currentPage,
                pageSize: this.pageSize
            };
            this.$store.dispatch("getInverterTable", value).then(() => {
                this.tableData = this.$store.state.genManagement.getInverterTable.data.records;
            // this.$store.dispatch("getBatteryTable", value).then(() => {
                // this.tableData = this.$store.state.genManagement.getBatteryTable.data;
                this.customerTotalNum = this.$store.state.genManagement.getInverterTable.data.total;
                // this.calcPageData();
            });
        },
        initBatteryList () {
            this.customerId = this.$store.state.storage.customerId;
            // this.$store.dispatch("getBatteryByCustomer", this.customerId).then(() => {
            //     this.powerNameList = this.$store.state.genManagement.getBatteryByCustomer.data;
            //     let obj = {
            //         label: "",
            //         value: "全部",
            //     };
            //     this.powerNameList.unshift(obj);
            //     this.dataForm.powerName = obj.label;
            // });
            this.$store
                .dispatch("getInvertersByCustomer", this.customerId)
                .then(() => {
                    // console.log(this.$store.state.genManagement.getInvertersByCustomer);
                    this.powerNameList = this.$store.state.genManagement.getInvertersByCustomer.data;
                    let obj = {
                        label: "",
                        value: "全部",
                    };
                    this.powerNameList.unshift(obj);
                    this.dataForm.powerName = obj.label;
                });
        },
    },
    watch: {
        "$store.state.storage.customerData": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                // if (!this.flag) return;
                this.customerId = newVal.id;
                this.customerName = newVal.name;
                this.currentPage = 1;
                this.initBatteryData();
                this.initBatteryList();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.genderPower {
    .btnMargin {
        margin-left: 40rem;
    }
    .styleWid {
        width: 18rem;
    }
    .btnColor {
        background: #027969;
    }
    .gen-header {
        width: 100%;
        // width: 143.6rem;
        height: 6.4rem;
        background: #0d3050;
        border-radius: 4px;
        .form {
            width: 100%;
            height: 100%;
            display: inline-block;
            margin-left: 2rem;
            padding-top: 1.2rem;
            .el-form-item:last-child {
                float: right;
                margin-right: 4.4rem;
            }
        }
    }
    .gen-content {
        width: 100%;
        // width: 143.6rem;
        height: 75.5rem;
        background: #0d3050;
        border-radius: 4px;
        margin-top: 1.6rem;
        padding-top: 1.5rem;
        .powerTable {
            margin: 0 2.2rem 0 1.5rem;
            .table {
                height: 59rem;
            }
            .pagination {
                float: right;
                margin-top: 1rem;
            }
        }
    }
}
</style>
<style lang="less">
.genderPower {
    .el-form-item__label {
        font-size: 1.4rem !important;
    }
    .inputSelect {
        .el-input__prefix,
        .el-input__suffix {
            top: -0.3rem;
        }
    }
    .el-button--primary {
        color: #fff;
        border-color: #027969;
        background-color: #027969;
    }
    .el-input__inner {
        color: rgba(255, 255, 255, 0.9);
    }
    margin-top: 1.5rem;
    .el-table tr {
        background: rgba(0, 115, 222, 0.1);
    }
    .el-table {
        overflow: hidden;
        height: 59rem !important;
    }
    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
        background-color: #0c2f4f;
    }
    .el-table .el-table__cell {
        padding: 1.3rem 0;
    }
    .el-table thead {
        color: #ffffff;
        font-weight: 500;
    }
    .el-table--border th.el-table__cell,
    .el-table__fixed-right-patch {
        border-bottom: 1px solid #0c2f4f;
    }
    .el-table thead.is-group th.el-table__cell {
        background: #0c2f4f;
    }
    .el-table--border .el-table__cell,
    .el-table__body-wrapper
        .el-table--border.is-scrolling-left
        ~ .el-table__fixed {
        border-right: 1px solid #0c2f4f;
    }
    .el-table--border,
    .el-table--group {
        border: 1px solid #0c2f4f;
    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
        content: "";
        position: absolute;
        background-color: #0c2f4f;
        z-index: 1;
    }
    .el-table th.el-table__cell.is-leaf {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 1.2rem;
    }
    .el-table .caret-wrapper {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        height: 34px;
        width: 0px;
        vertical-align: middle;
        cursor: pointer;
        overflow: initial;
        position: relative;
    }
    // 滚动条的宽度
    .el-table__body-wrapper::-webkit-scrollbar {
        width: 6px; // 横向滚动条
        height: 5px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: #606266;
        border-radius: 3px;
    }
}
</style>
