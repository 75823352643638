<template>
    <div class="DialogAlarmSettingAdd">
        <el-dialog :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
            <div v-if="getType === 1" slot="title">
                <div class="equip_title">新增</div>
            </div>
            <div v-else slot="title">
                <div class="equip_title">编辑</div>
            </div>

            <div style="overflow-y:auto;">
                <el-form :model="form" class="elForm" :rules="rules" ref="ruleForm">
                    <el-form-item v-show="getType === 1" label="告警指标：" prop="indexName">
                        <el-select v-model="form.indexName" placeholder="请选择" @change="changeWarningIndex" size="small">
                            <el-option v-for="(item, index) in warningIndexes" :key="index" :label="item.indexName" :value="item.indexName"></el-option>
                        </el-select>
                    </el-form-item>

                    <!-- 列表################################################################## -->
                    <el-form-item style="width: 100%;" class="no_line_height">
                        <el-table :data="form.meters" style="width: 100%;background:#0C375E;" :header-cell-style="headerClass" :cell-style="cellStyle" ref="tb">
                            <el-table-column prop="warningName" label="告警名称" min-width="15%">
                                <template slot-scope="scope">
                                    <el-select placeholder="请输入" v-model="form.meters[scope.$index].warningName" size="small" clearable @change="changeIndexName(scope.$index)">
                                        <el-option v-for="(item, index) in warningNames" :key="index" :label="item.warningName" :value="item.warningName"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column prop="rule" label="规则" min-width="4%">
                                <template slot-scope="scope">
                                    <div v-if="form.indexName != '开关变位'">{{ form.meters[scope.$index].rule == 0 ? "&lt;" : "&gt;" }}</div>
                                    <div v-else>-></div>
                                </template>
                            </el-table-column>

                            <el-table-column prop="value" :label="limitLabel" min-width="15%">
                                <template slot-scope="scope">
                                    <el-input placeholder="请输入" v-model="form.meters[scope.$index].value" size="small" clearable></el-input>
                                </template>
                            </el-table-column>

                            <el-table-column v-if="form.indexName != '开关变位'" prop="continueTime" label="连续动作时间" min-width="15%">
                                <template slot-scope="scope">
                                    <el-select v-model="form.meters[scope.$index].continueTime" placeholder="请选择" size="small" clearable>
                                        <el-option v-for="(item, i) in dbType" :key="i" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>

                            <el-table-column prop="warningLevel" label="告警级别" min-width="15%">
                                <template slot-scope="scope">
                                    <el-select v-model="form.meters[scope.$index].warningLevel" placeholder="请选择" size="small" clearable>
                                        <el-option v-for="(item, i) in dbType2" :key="i" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>

                            <!-- 操作部分-->
                            <el-table-column label="操作" width="80" v-if="getType == 1">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" :disabled="form.meters.length == 1" @click="handleDelete(scope.$index)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <div class="btn_add" @click="addRow()" v-if="getType == 1">➕ 添加一行数据</div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
                <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import { validateInput, validateSelect, validateDate } from "@/utils/validate";
let that;
export default {
    name: "DialogOne",
    mixins: [tableBg],
    data () {
        return {
            // 打开弹层的index
            myIndex: 0,
            // tag弹层是否展示
            dialogTagIfShow: false,
            // 在电表中的顺序
            noOftheMeter: 0,
            // 二级Dialog的外壳是否显示
            ifDialogTreeOutsiderShow: "treePlaceGoAway",
            // 请求类型（编辑=2，登记=1）
            getType: 0,
            // 表格内客户单位的展示数据
            companyFakeForShow: "",

            // 当前树的信息
            treeFullNodeData: {},
            // 展示判断
            dialogTableVisible: false,
            dialogFormVisible: false,
            tablePlusShow: false,
            // 内置dialog数据双向绑定处
            formInTable: { name: "", powerType: "" },
            // 下属电表的按钮的双向绑定内容
            buttonContent: "请选择电表位置",
            // 下属电表的真实数据ID
            buttonContentId: "",

            // 表单数据存放处
            form: {
                warningName: "", // 告警名称
                rule: "", // 规则
                ownerId: "",
                location: "",
                specification: "",
                useDate: "",
                warrantyDate: "",
                indexName: "", // 告警指标
                meters: [
                    {
                        circuits: [{ name: "" }],
                        continueTime: "",
                        id: "",
                        indexName: "",
                        rule: "",
                        status: "",
                        value: "",
                        valueUnit: "",
                        warningLevel: "",
                        warningName: "",
                    },
                ],
                type: "",
                placeId: "",
                produceDate: "",
                producer: "",
                capacity: "",
                ratedLoad: "",
            },
            tableList: [],
            rules: {
                // warningName: [validateInput],
                // rule: [validateInput],
                // type: [validateInput],
                // placeId: [validateSelect],
                // specification: [validateInput],
                // produceDate: [validateDate],
                // useDate: [validateDate],
                // warrantyDate: [validateDate],
                // producer: [validateInput],
                // capacity: [validateInput],
                // ratedLoad: [validateInput],
                // indexName: [validateSelect],
                // meters: [{ required: true, message: "不能为空" }],
            },
            // 连续动作时间字典存放
            dbType: [
                { label: "1分钟", value: 1 },
                { label: "5分钟", value: 2 },
                { label: "10分钟", value: 3 },
                { label: "30分钟", value: 4 },
                { label: "1小时", value: 5 },
                { label: "2小时", value: 6 },
            ],
            dbType2: [
                { label: "通知", value: 1 },
                { label: "一般警告", value: 2 },
                { label: "紧急警告", value: 3 },
                { label: "严重警告", value: 4 },
            ],
            warningIndexes: [],
            warningNames: [],
            limitLabel: "阈值",
            unitName: "",
        };
    },
    created () {
        that = this;
        this.initWarningIndexes();
    },
    methods: {
        // 退出弹层
        // handleCancleDialog(){
        //   this.dialogTagIfShow = false
        //   this.formInit()
        // },
        initWarningIndexes () {
            this.$store.dispatch("getWarningIndexSelectList").then(() => {
                this.warningIndexes = this.$store.state.system.getWarningIndexSelectList.data;
            });
        },

        initWarningNames () {
            let indexName = this.form.indexName;
            this.$store.dispatch("getWarningNameSelectDtoList", indexName).then(() => {
                this.warningNames = this.$store.state.system.getWarningNameSelectDtoList.data;
            });
        },

        changeWarningIndex () {
            for (let i = 0; i < this.warningIndexes.length; i++) {
                if (this.warningIndexes[i].indexName == this.form.indexName) {
                    this.unitName = this.warningIndexes[i].unit;
                    this.limitLabel = "阈值（" + this.warningIndexes[i].unit + "）";
                }
            }
            this.initWarningNames();
        },

        changeIndexName (index) {
            if (this.getType == 1) {
                for (let i = 0; i < this.warningNames.length; i++) {
                    if (this.form.meters[index].warningName == this.warningNames[i].warningName) {
                        this.form.meters[index].rule = this.warningNames[i].rule;
                    }
                }
            } else if (this.getType == 2) {
                for (let i = 0; i < this.warningNames.length; i++) {
                    if (this.form.meters[0].warningName == this.warningNames[i].warningName) {
                        this.form.meters[0].rule = this.warningNames[i].rule;
                    }
                }
            }
        },

        // 结束编辑
        postDialogDataDialog () {
            this.dialogTagIfShow = false;
        },

        // 新增tag
        addTag (index) {
            const tagSingel = { name: "" };
            this.form.meters[index].circuits.push(tagSingel);
        },

        // 弹层中新增tag
        addTag3 () {
            const tagSingel = { name: "" };
            this.form.meters[this.myIndex].circuits.push(tagSingel);
        },

        // 展示tag弹层
        addTag2 (index) {
            this.dialogTagIfShow = true;
            this.myIndex = index;
        },

        // 删除一个Tag
        closeTag (indexInside, indexOutside) {
            this.form.meters[indexOutside].circuits.splice(indexInside, 1);
        },

        // 让二级Dialog树出现
        DialogTreeShow (val) {
            this.noOftheMeter = val;
            this.ifDialogTreeOutsiderShow = "treePlace";
        },

        // dialog关闭的回调
        closeDialog (ruleForm) {
            this.formInit();
            this.$refs[ruleForm].resetFields();
            this.ifDialogTreeOutsiderShow = "treePlaceGoAway";
        },

        // 取消弹框
        handleCancle (ruleName) {
            this.$refs[ruleName].resetFields();
            this.dialogFormVisible = false;
            this.formInit();
        },

        // from归零
        formInit () {
            this.form = {
                warningName: "",
                rule: "",
                ownerId: "",
                location: "",
                specification: "",
                useDate: "",
                warrantyDate: "",
                indexName: "",
                meters: [{ circuits: [{ name: "" }] }],
                type: "",
                placeId: "",
                produceDate: "",
                producer: "",
                capacity: "",
                ratedLoad: "",
            };
        },
        // 添加一行数据
        addRow () {
            // const obj = { name: "", type: "" ,locationName:"请选择位置",locationId:"" };
            let metersLen = this.form.meters.length;
            let lastItem = this.form.meters[metersLen - 1];
            if (!lastItem.continueTime && this.form.indexName != '开关变位') {
                this.$message({
                    message: "请选择连续动作时间！",
                    type: "warning",
                });
                return;
            }
            if (!lastItem.warningLevel) {
                this.$message({
                    message: "请选择告警级别！",
                    type: "warning",
                });
                return;
            }
            if (!lastItem.value) {
                this.$message({
                    message: "请输入阈值！",
                    type: "warning",
                });
                return;
            }
            if (!lastItem.warningName) {
                this.$message({
                    message: "请选择告警名称",
                    type: "warning",
                });
                return;
            }
            const obj = {
                name: "",
                powerType: "",
                locationName: "请选择位置",
                circuits: [],
                continueTime: "",
                id: "",
                indexName: "",
                rule: "",
                status: "",
                value: "",
                valueUnit: "",
                warningLevel: "",
                warningName: "",
            };
            this.form.meters.push(obj);
        },
        handleDelete (index) {
            this.form.meters.splice(index, 1);
        },
        //父组件点击触发，显示弹窗、给当前节点数据，修改form表单中的客户单位ID
        show () {
            this.getType = 1;
            this.dialogFormVisible = true;
            this.form = this.$options.data().form
            this.warningNames = []
        },

        //父组件触发来进行编辑
        showForEdit (index, row) {
            this.getType = 2;
            this.dialogFormVisible = true;
            this.form.indexName = row.indexName;
            this.initWarningNames();
            this.limitLabel = "阈值（" + row.valueUnit + "）";
            this.form.meters = [row];
        },

        // 提交发送请求（一级dialog点击《保存》）
        postDialogData (ruleName) {
            this.$refs[ruleName].validate((valid) => {
                if (valid) {
                    // 发送新增请求
                    if (this.getType === 1) {
                        if (!this.form.indexName) {
                            this.$message({
                                message: "请选择告警指标！",
                                type: "warning",
                            });
                            return;
                        }
                        let firstItem = this.form.meters[0];
                        if (this.form.indexName !== '开关变位' && !firstItem.continueTime) {
                            this.$message({
                                message: "请选择连续动作时间！",
                                type: "warning",
                            });
                            return;
                        }
                        if (!firstItem.warningLevel) {
                            this.$message({
                                message: "请选择告警级别！",
                                type: "warning",
                            });
                            return;
                        }
                        if (firstItem.value !== 0 && !firstItem.value) {
                            this.$message({
                                message: "请输入阈值！",
                                type: "warning",
                            });
                            return;
                        }
                        if (!firstItem.warningName) {
                            this.$message({
                                message: "请选择告警名称",
                                type: "warning",
                            });
                            return;
                        }
                        let value = [];
                        for (let i = 0; i < this.form.meters.length; i++) {
                            const item = this.form.meters[i];
                            let obj = {
                                continue_time: item.continueTime,
                                // id: "", 新增时不需要传递id，id是后端生成的
                                index_name: this.form.indexName,
                                rule: item.rule,
                                status: 0,
                                value: Number(item.value),
                                value_unit: this.unitName,
                                warning_level: item.warningLevel,
                                warning_name: item.warningName,
                            };
                            value.push(obj);
                        }
                        // console.log(value);
                        this.$store.dispatch("postWarningConfig", value).then(() => {
                            if (this.$store.state.system.postWarningConfig.errorCode == 0) {
                                this.$message({ message: "添加成功！", type: "success" });
                                this.$refs[ruleName].resetFields();
                                this.$parent.refreshThePage();
                                this.dialogFormVisible = false;
                                this.formInit();
                            } else {
                                this.$message({
                                    message: "保存失败，请重试！",
                                    type: "warning",
                                });
                            }
                        });
                    } else if (this.getType === 2) {
                        // console.log(this.form.meters);
                        if (!this.form.meters[0].value) {
                            this.$message({
                                message: "请填写阈值",
                                type: "warning",
                            });
                            return;
                        }
                        let value = {
                            continue_time: this.form.meters[0].continueTime,
                            id: this.form.meters[0].id,
                            index_name: this.form.meters[0].indexName,
                            rule: this.form.meters[0].rule,
                            status: this.form.meters[0].status,
                            value: this.form.meters[0].value,
                            value_unit: this.form.meters[0].valueUnit,
                            warning_level: this.form.meters[0].warningLevel,
                            warning_name: this.form.meters[0].warningName,
                        };
                        // 发送编辑请求
                        this.$store.dispatch("putWarningConfig", value).then(() => {
                            if (this.$store.state.system.putWarningConfig.errorCode == 0) {
                                this.$message({ message: "编辑成功！", type: "success" });
                                this.$refs[ruleName].resetFields();
                                this.$parent.refreshThePage();
                                this.dialogFormVisible = false;
                                this.formInit();
                            } else {
                                this.$message({
                                    message: "保存失败，请重试！",
                                    type: "warning",
                                });
                            }
                        });
                    }
                } else {
                    this.$message.error("有必填项未填");
                }
            });
        },
    },
    //处理state数据
    computed: {},
};
</script>

<style lang="less" scoped>
.DialogAlarmSettingAdd {
    overflow: hidden;
    .el-dialog__body {
        height: 60rem;
    }
}

// 表格添加一项的按钮
.btn_add {
    border: 0.1rem dashed #2d79be;
    height: 4.2rem;
    line-height: 4.2rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.45);
    width: 100%;
    cursor: pointer;
    margin-bottom: 2rem;
    &:hover {
        border-color: #fff;
    }
}
// 树所在的区域###################
.treePlace {
    height: 55rem;
    width: 26rem;
    position: absolute;
    top: 1rem;
    left: 80rem;
    overflow: hidden;
    box-shadow: 1rem 0.5rem 2rem #071828;
    border-radius: 0.4rem;
    transition: height 0.3s;
    z-index: 20000;

    // border: #071828 0.2rem solid;
}
.treePlaceGoAway {
    // opacity: 0;
    display: none;
    // position:relative;
    // top: -54rem;
    // left: 80rem;
    height: 0;
    width: 26rem;
    overflow: hidden;
    // display: none;
    transition: height 0.3s;
}

.btnForMeters {
    height: 3.2rem;
    width: 100%;
    background-color: #0b3d6b;
    border: #004579 0.1rem solid;
    cursor: pointer;
    border-radius: 0.4rem;
    color: #abb7c2;
}

.tagInputStyle {
    height: 2.7rem;
    background-color: #124573;
    border: none;
    width: 8rem;
    color: #2cd9ff;
    &:focus {
        border: none;
        //  background-color: green;
        outline: none;
    }
}
</style>
<style lang="less">
.DialogAlarmSettingAdd {
    .no_line_height {
        .el-form-item__content {
            line-height: unset;
        }
    }

    .el-table::before {
        opacity: 0;
    }

    .tag {
        margin-right: 0.8rem;
        background-color: #124573;
        color: #2cd9ff;
        border: 0.1rem dashed rgba(108, 184, 255, 0.3);
    }
    .tag2 {
        margin-right: 0.8rem;
        background-color: #124573;
        color: #2cd9ff;
        border: 0.1rem dashed rgba(108, 184, 255, 0.3);
        cursor: pointer;
    }
    .el-tag.el-tag--success .el-tag__close {
        color: #2cd9ff;
        background-color: #124573;
        &:hover {
            color: #124573;
            background-color: #2cd9ff;
        }
    }
}
</style>
