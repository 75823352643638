<!-- 配电监测空白页 -->
<template>
  <div class="MonitoringBlankPage">
      <img src="@/assets/Monitoring/blankPage2.png" alt="">
  </div>
</template>

<script>
export default {
    name:'BlankPage',
}
</script>

<style lang='less' scoped>
    .MonitoringBlankPage{
        height: 100%;
        width: 100%;
        background-color: #0f2437;
        img{
            position: absolute;
            left: 46.7rem;
            top: 25.5rem;
        }
    }
</style>