<template>
  <div class="PatrolSetting">
    <!-- 面包屑 -->
    <div class="bread">
      运维检修 / 巡检设置 / {{routerName}}
    </div>

     <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
        <div class="btnOutSider">
            <button :class="btnTypeList[0]" @click="switchTab(0,'/repair/patrolSetting/timeSetting')">巡检任务分发时间设置</button>
            <button :class="btnTypeList[2]" @click="switchTab(2,'/repair/patrolSetting/workStandard')">作业标准</button>
            <button :class="btnTypeList[4]" @click="switchTab(4,'/repair/patrolSetting/TypeSetting')">作业标准类型设置</button>
            <button :class="btnTypeList[3]" @click="switchTab(3,'/repair/patrolSetting/patrolGroupSetting')">巡检小组设置</button>
            
        </div>
    </div>

    <!-- 路由位置 -->
    <div class="routerPlace">
       <router-view></router-view>
    </div>

  </div>
</template>

<script>
export default {
  name:"PatrolSetting",
  data(){return{
    // 按钮样式列表
    btnTypeList:['btnGreen','btnGrey','btnGrey','btnGrey','btnGrey'],
    // 路由面包屑的双向绑定
    routerName:'巡检任务分发时间设置',

  }},

  methods:{
    // 切换tab栏
    switchTab(val,routerAddress){
      this.btnTypeList=['btnGrey','btnGrey','btnGrey','btnGrey','btnGrey']
      this.btnTypeList[val] = 'btnGreen'
      this.$router.push({path: routerAddress});
      if(val===0){this.routerName='巡检任务分发时间设置'}
      else if(val===2){this.routerName='作业标准'}
      else if(val===3){this.routerName='巡检小组设置'}
      else if(val===4){this.routerName='作业标准类型设置'}
    }
  },

}
</script>

<style lang='less' scoped>
  // 面包屑
.bread {
    height: 5rem;
    width: 142.8rem;
    line-height: 5rem;
    color: #fff;
    font-size: 1.4rem;
    position: absolute;
    top: -2rem;
    left: 0;
  }
  // 顶部栏位置
.topBar {
  align-items: center;
  padding-left: 2rem;
  box-sizing: border-box;
  height: 6.4rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  top: 3rem;
  left: 0;
  overflow: hidden;
  .btnOutSider{
    height: 3.2rem;
    // width: 54.8rem;
    position: absolute;
    top: 1.6rem;
    left: 2.4rem; 
    border-radius: 0.2rem;
    overflow: hidden;
    border: 0.1rem solid #004579;
    box-sizing: border-box;
    .btnGreen{
      height: 3.2rem;
      color: #fff;
      font-size: 1.4rem;
      background-color: #027969;
      border: none;
      line-height: 3.2rem;
      padding: 0 1.5rem 0 1.5rem;
      cursor: pointer;
    }
    .btnGrey{
      height: 3.2rem;
      color: #fff;
      font-size: 1.4rem;
      background-color: #0c375e;
      border: none;
      line-height: 3.2rem;
      padding: 0 1.5rem 0 1.5rem;
      cursor: pointer;
    }
  }
}

// 路由位置
.routerPlace{
  height: 85.9em;
  width: 171.2rem;
  background-color: #0d3050;
  position: absolute;
  top: 11rem;
  left: 0;
}
</style>