<template>
  <div class="alarmSetting">
    <!-- 面包屑 -->
    <div class="bread">
      <button class="btnAdd" @click="handelAdd">新 增</button>
    </div>

    <div class="table">
      <el-table
        :data="tableData"
        stripe:false
        height="57.8rem"
        border:false
        style="width: 100%"
        :header-cell-style="headerClass"
        :cell-style="cellStyle"
      >
        <el-table-column  label="序号"  min-width="9%">
          <template slot-scope="scope">{{scope.$index + 1 + (pageNum - 1) * pageSize}}</template>
        </el-table-column> 
        <el-table-column class="tab" prop="indexName" label="告警指标" min-width="20%" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column class="tab" prop="warningName" label="告警名称" min-width="16%" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column class="tab" prop="value" label="越限值" min-width="16%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.value | filterLimitValue(scope.row.rule, scope.row.valueUnit) }}
          </template>
        </el-table-column>
        <el-table-column class="tab" prop="continueTime" label="动作时间" min-width="22%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.continueTime | filterContinueTime }}
          </template>
        </el-table-column>
        <el-table-column class="tab" prop="warningLevel" label="告警级别" min-width="22%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.warningLevel == 1" style="color: #03d9bc">{{
              scope.row.warningLevel | filterWarningLevel
            }}</span>
            <span v-if="scope.row.warningLevel == 2" style="color: #2cd9ff">{{
              scope.row.warningLevel | filterWarningLevel
            }}</span>
            <span v-if="scope.row.warningLevel == 3" style="color: #ff5f5f">{{
              scope.row.warningLevel | filterWarningLevel
            }}</span>
            <span v-if="scope.row.warningLevel == 4" style="color: #ffac5f">{{
              scope.row.warningLevel | filterWarningLevel
            }}</span>
          </template>
        </el-table-column>
        <el-table-column class="tab" prop="state" label="状态" min-width="22%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.state == '0'" style="color: #9ba4b6">禁用</span>
            <span v-if="scope.row.state == '1'" style="color: #03d9bc">启用</span>
            <div style="display:inline-block;margin-left:0.5rem;">
              <el-switch
                v-model="scope.row.state"
                active-color="#027969"
                inactive-color="#6E798C"
                :active-value="1"
                :inactive-value="0"
                active-icon-class="el-icon-check"
                inactive-icon-class="el-icon-close"
                @change="changeStatus($event, scope.row, scope.row.id)"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <!-- 操作部分 -->
        <el-table-column label="操作" min-width="14%">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleLink(scope.$index, scope.row)">关联</el-button>
            <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器部分 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNumForPagenation"
      >
      </el-pagination>
    </div>

    <!-- 弹层部分################################### -->
    <DialogAlarmSettingAdd ref="DialogRef" />
    <DialogAlarmSettingConnect ref="ConnectRef" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import DialogAlarmSettingAdd from "../level2Components/DialogAlarmSettingAdd.vue";
import DialogAlarmSettingConnect from "../level2Components/DialogAlarmSettingConnect.vue";
let that;
export default {
  name: "AlarmSetting",
  mixins: [tableBg],
  components: { DialogAlarmSettingAdd, DialogAlarmSettingConnect },
  data() {
    return {
      value: true,
      // 双向绑定--总数
      totalNumForPagenation: 0,
      pageNum: 1,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 表格数据
      tableData: [],
      continueTimeList: [
        {
          id: 1,
          value: "1分钟",
        },
        {
          id: 2,
          value: "5分钟",
        },
        {
          id: 3,
          value: "10分钟",
        },
        {
          id: 4,
          value: "30分钟",
        },
        {
          id: 5,
          value: "1小时",
        },
        {
          id: 6,
          value: "2小时",
        },
      ],
      ruleList: [
        {
          id: 0,
          value: "＜",
        },
        {
          id: 1,
          value: "＞",
        },
      ],
      warningLevelList: [
        {
          id: 1,
          value: "通知",
        },
        {
          id: 2,
          value: "一般告警",
        },
        {
          id: 3,
          value: "严重告警",
        },
        {
          id: 4,
          value: "紧急告警",
        },
      ],
    };
  },

  methods: {
    // 页面获取数据
    refreshThePage() {
      let value = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$store.dispatch("getWarningConfigs", value).then(() => {
        this.tableData = this.$store.state.system.getWarningConfigs.data.records;
        this.totalNumForPagenation = this.$store.state.system.getWarningConfigs.data.total;
      });
    },
    // 更改用户状态选择框的选择
    selectChanged() {
      this.refreshThePage();
    },
    //点击了编辑按钮
    handleEdit(index, row) {
      // console.log(index,row);
      this.$refs.DialogRef.showForEdit(index, row);
    },
    // 点击了删除按钮
    handleDelete(index, row) {
      this.$confirm("您确定要执行删除操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let id = row.id;
          // console.log(row.id);
          this.$store.dispatch("delWarningConfigById", id).then(() => {
            // console.log(this.$store.state.system.delWarningConfigById);
            if (this.$store.state.system.delWarningConfigById.errorCode == 0) {
              this.$message({ message: "删除成功！", type: "success" });
              this.refreshThePage();
            } else {
              this.$message({ message: "删除失败，请重试！", type: "warning" });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "您已取消删除",
          });
        });
    },
    // 更改分页器每页个数
    handleSizeChange(val) {
      this.pageSize = val;
      this.refreshThePage();
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.pageNum = val;
      this.refreshThePage();
    },
    // 点击了新增按钮
    handelAdd() {
      this.$refs.DialogRef.show();
    },
    // 点击了关联按钮
    handleLink(index,row) {
      this.$refs.ConnectRef.show(row);
    },
    changeStatus($event, row) {
      // console.log(row);
      let value = {
        continue_time: row.continueTime,
        id: row.id,
        index_name: row.indexName,
        rule: row.rule,
        state: row.state,
        value: row.value,
        value_unit: row.valueUnit,
        warning_level: row.warningLevel,
        warning_name: row.warningName,
      };
      // 发送编辑请求
      this.$store.dispatch("putWarningConfig", value).then(() => {
        if (this.$store.state.system.putWarningConfig.data) {
          this.$message({ message: "变更状态成功！", type: "success" });
          // this.refreshThePage();
        } else {
          this.$message({
            message: "变更状态失败，请重试！",
            type: "warning",
          });
        }
      });
    },
  },
  mounted() {
    this.refreshThePage();
  },
  computed: {
    ...mapState({
      // communicationPageData:(state) =>{ return state.system.communicationPageData.data.records},
      // communicationPageDataTotal:(state) =>{ return state.system.communicationPageData.data.total},
      // ifDeleteCommunicationSuccess:(state)=>{return state.system.ifDeleteCommunicationSuccess.errorCode},
      // ifDeleteCommunicationError:(state)=>{return state.system.ifDeleteCommunicationSuccess.message},
      // ifResetPassword:(state)=>{return state.system.ifResetPassword.errorCode},
    }),
  },
  created() {
    that = this;
  },
  filters: {
    filterContinueTime: function(value) {
      if (!value) return;
      value = parseInt(value);
      // console.log(that.continueTimeList);
      return that.continueTimeList[value - 1].value;
    },
    filterLimitValue: function(value, rule, value_unit) {
      if (!value) return;
      let a, b;
      rule = parseInt(rule);
      a = that.ruleList[rule].value;
      b = a + " " + value + value_unit;
      return b;
      // return value;
    },
    filterWarningLevel: function(value) {
      if (!value) return;
      value = parseInt(value);
      return that.warningLevelList[value - 1].value;
    },
    filterStatus: function(value) {
      console.log(value);
      if (value == 0) {
        return "禁用";
      } else if (value == 1) {
        return "启用";
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 中部表格样式##################################################################
.tablePlace {
  height: 86.4rem;
  width: 171.2rem;
  position: absolute;
  top: 10.4rem;
  left: 0;
  overflow: hidden;
  background-color: #0d3050;
  .bread {
    color: #fff;
    height: 7.2rem;
    width: 142.8rem;
    line-height: 7.2rem;
    color: #fff;
    font-size: 1.4rem;
    text-indent: 2.4rem;
  }
  .btnAdd {
    height: 3.2rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    width: 7.4rem;
  }

  .table {
    height: 57.2rem;
    width: 166.4rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
</style>

<style lang="less">
.alarmSetting {
  .el-switch__label {
    position: absolute;
    display: none;
    font-size: 1.1rem !important;
    color: #fff !important;
  }
  .el-switch__label * {
    font-size: 1.1rem !important;
  }
  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
    left: -0.5rem; // 这里是重点
    top: 0.1rem;
  }
  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
    left: 2.3rem; // 这里是重点
    top: 0.1rem;
  }
  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }
  // .el-switch__core {
  //   width: 45px;
  //   height: 22px;
  //   border: 2px solid #dcdfe6;
  //   border-radius: 13px;
  // }
}
</style>
