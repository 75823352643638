<template>
  <!-- 发电管理折线图 -->
  <div class="dialog_echarts">
    <div ref="myChart" class="charts" id="myChart22"></div>
  </div>
</template>

<script>
// import { truncate } from "@antv/x6/lib/util/string/string";
import * as echarts from "echarts";
export default {
  // props: {
  //   optionData: {
  //     type: Object,
  //     default: () => {},
  //   },
  //   chartsType: {
  //     type: String,
  //     default: () => "",
  //   },
  // },
  props: ["whichTab", "inverterName", "generalDay"],
  data() {
    return {
      optionData: "",
      xdata: "",
      ydata: [{ data: [] }, { data: [] }],
      yMax: "",
      yMin: "",
    };
  },
  watch: {},
  created() {
    // this.setOptionData();
  },
  methods: {
    // 销毁echarts
    dispose() {
      this.$echarts.dispose(document.getElementById("myChart22"));
    },
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    initTrendData() {
      let dateInfo = this.generalDay;
      if (typeof this.generalDay == "object") {
        if (this.whichTab == 0) {
          let date = this.generalDay;
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          let day = date.getDate();
          if (day < 10) {
            day = "0" + day;
          }
          dateInfo = year + "-" + month + "-" + day;
        } else if (this.whichTab == 1) {
          let date = this.generalDay;
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          dateInfo = year + "-" + month;
        } else if (this.whichTab == 2) {
          dateInfo = this.generalDay.getFullYear();
        }
      }
      if(!this.inverterName){
        this.inverterName = 0;
      }
      let value = {
        stationId: this.inverterName,
        dateType: this.whichTab + 1,
        date: dateInfo,
      };
      this.$store.dispatch("getPowerStatus", value).then(() => {
        if (this.$store.state.genManagement.getPowerStatus.errorCode == 0) {
          this.ydata = this.$store.state.genManagement.getPowerStatus.data;
          this.setOptionData();
          this.init();
        } else {
          this.xdata = [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ];
          this.ydata = [{ data: [] }, { data: [] }];
          this.yMax = 10;
          this.yMax1 = 10;
          this.init();
        }
      });
    },
    setOptionData() {
      this.xdata = [];
      if (this.whichTab == 0) {
        this.xdata = [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
          "19:00",
          "20:00",
          "21:00",
          "22:00",
          "23:00",
        ];
      } else if (this.whichTab == 1) {
        let date = new Date();
        if (typeof this.generalDay == "object") {
          date = this.generalDay;
        }
        let month = date.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        for (let i = 1; i < this.ydata[0].data.length + 1; i++) {
          let item = i;
          if (item < 10) {
            item = "0" + item;
          }
          item = month + "-" + item;
          this.xdata.push(item);
        }
      } else if (this.whichTab == 2) {
        this.xdata = [
          "一月",
          "二月",
          "三月",
          "四月",
          "五月",
          "六月",
          "七月",
          "八月",
          "九月",
          "十月",
          "十一月",
          "十二月",
        ];
      }
    },
    init() {
      // console.log(this.xdata,this.ydata);
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart22"));
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById("myChart22"));
      }
      let mapLineList = this.ydata[1].data;
      if (mapLineList.length > 0) {
        let yMax = mapLineList[0];
        for (let i = 0; i < mapLineList.length; i++) {
          if (mapLineList[i] > yMax) {
            yMax = mapLineList[i];
          }
        }
        this.yMax = this.ceilNumber(yMax);
      }
      let mapLineList1 = this.ydata[0].data;
      if (mapLineList1.length > 0) {
        let yMax = mapLineList1[0];
        for (let i = 0; i < mapLineList1.length; i++) {
          if (mapLineList1[i] > yMax) {
            yMax = mapLineList1[i];
          }
        }
        this.yMax1 = this.ceilNumber(yMax);
      }
      let option = {
        color: ["#FFA82C", "#1EE7E7"],
        tooltip: {
          trigger: "axis",
          // formatter: function (params) {
          //   var relVal = params[0].name;
          //   let unit = ['kW', 'kW.h']
          //   for (var i = 0, l = params.length; i < l; i++) {
          //     relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + unit[i]
          //   }
          //   return relVal;
          // }
        },
        legend: {
          // show: truncate,
          textStyle: {
            color: "#fff",
          },
          icon: "circle",
          x: "center", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 20, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          data: ["平均功率(kW)", "发电量(kW.h)"],
        },
        grid: {
          x: 25,
          y: 40,
          x2: 40,
          y2: 30,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "rgba(43, 113, 176, 0.45)",
            },
          },
          axisLabel: {
            color: "#f9f9f9",
          },
          data: this.xdata,
        },
        yAxis: [
          {
            min: 0,
            max: this.yMax1,
            interval: this.yMax1 / 5,
            type: "value",
            axisLabel: {
              color: "#fff",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#1f4a72",
              },
            },
          },
          {
            min: 0,
            max: this.yMax,
            interval: this.yMax / 5,
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#fff",
                align: "left",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#1f4a72",
              },
            },
          },
        ],
        series: [
          {
            name: "平均功率(kW)",
            type: "line",
            smooth: true,
            symbol: "none",
            lineStyle: {
              color: "#FFA82C",
            },
            data: this.ydata[0].data,
          },
          {
            name: "发电量(kW.h)",
            type: "bar",
            // smooth: true,
            // symbol: "none",
            yAxisIndex: 1,
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1EE7E7" },
                { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
              ]),
            },
            data: this.ydata[1].data,
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option, true);
      myChart._dom.childNodes[0].childNodes[0].style.width = "143rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "29.8rem";
    },
    clearEcharts(){
      this.xdata = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ];
      this.ydata = [{ data: [] }, { data: [] }];
      this.yMax = 10;
      this.yMax1 = 10;
      this.init();
    }
  },
  mounted() {
    // this.initTrendData();
  },
  watch: {
    whichTab: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return this.clearEcharts();
        this.whichTab = newVal;
        this.initTrendData();
      },
    },
    generalDay: {
      // immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return this.clearEcharts();
        this.generalDay = newVal;
        this.initTrendData();
      },
    },
    inverterName: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return this.clearEcharts();
        this.inverterName = newVal;
        this.initTrendData();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.dialog_echarts {
  .charts {
    margin: 0 auto;
    width: 143rem;
    height: 29.8rem;
  }
}
</style>
