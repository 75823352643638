<template>
    <div class="office-content">
        <div class="office1">
            <span class="title">
              <el-select
                  v-model="energyName"
                  @change="energyNameChange"
                  ref="selectTreeRef"
              >
                <el-option
                    v-for="item in energyNameList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </span>
            <div class="dateChoose">
                <div :class="btnTypeDay" @click="changeTab(0)">今天</div>
                <div :class="btnTypeWeek" @click="changeTab(1)">本周</div>
                <div :class="btnTypeMonth" @click="changeTab(2)">本月</div>
                <div :class="btnTypeYear" @click="changeTab(3)">本年</div>
            </div>
            <div class="off-table">
              <div v-if="loading" class="loading-mask">
                <el-spinner size="large"></el-spinner>
              </div>
                <el-table height="31rem" :data="tableData" style="width: 100%" empty-text=" ">
                    <el-table-column prop="name" label="节点名称" width="200"></el-table-column>
                    <el-table-column prop="value" label="能耗" width="180" align="right"></el-table-column>
                    <el-table-column prop="huanbiRatio" label="环比" align="right" class="huanbiItem">
                        <template slot-scope="scope">{{ scope.row.huanbiRatio }}%
                            <img v-if="scope.row.huanbiRatio == 0" src="" alt />
                            <img v-if="scope.row.huanbiRatio < 0" src="@/assets/ElecManagement/arrowDown.png" alt />
                            <img v-if="scope.row.huanbiRatio > 0" src="@/assets/ElecManagement/arrowUp.png" alt />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: {
      data: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    data () {
        return {
            btnTypeDay: "btnGreen",
            btnTypeWeek: "btnDark",
            btnTypeMonth: "btnDark",
            btnTypeYear: "btnDark",
            dataForm: {
              buildId: this.$parent.energyNameList ? this.$parent.energyNameList[0].id : '',
            },
            whichTab: 0,
            tableData: [],
            energyName: "",
            energyNameList: [],
            loading: false,
            firstLoad: true,
        };
    },
    mounted () {
        // this.changeTab(0);
    },
    methods: {
        changeTab (index) {
            this.whichTab = index;
            this.showInterval = false;
            if (index === 0) {
                this.btnTypeDay = "btnGreen";
                this.btnTypeWeek = "btnDark";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnDark";
            } else if (index === 1) {
                this.btnTypeDay = "btnDark";
                this.btnTypeWeek = "btnGreen";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnDark";
            } else if (index === 2) {
                this.btnTypeDay = "btnDark";
                this.btnTypeWeek = "btnDark";
                this.btnTypeMonth = "btnGreen";
                this.btnTypeYear = "btnDark";
            } else if (index === 3) {
                this.btnTypeDay = "btnDark";
                this.btnTypeWeek = "btnDark";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnGreen";
            }
        },
        energyNameChange(e) {
          this.dataForm.buildId = e;
          this.tableData = []
          this.loading = true
          if (this.whichTab === 0) {
            this.getData(e);
            this.loading = false
          } else {
            this.$store
                .dispatch("getBuildTime", {
                  id: this.dataForm.buildId,
                  timetype: (this.whichTab + 1),
                })
                .then(() => {
                  let dataList = this.getBuildTime || [];
                  for (let i = 0; i < dataList.length; i++) {
                    let item = dataList[i];
                    let huanbi = item.huanbiRatio;
                    dataList[i].value = item.value + " kWh";
                    if (huanbi === null) {
                      huanbi = "-";
                      item.huanbiRatio = huanbi;
                    } else {
                      item.huanbiRatio = (huanbi * 100).toFixed(2);
                    }
                  }
                  this.tableData = dataList;
                }).finally(() => {
                  this.loading = false;
            });
          }

        },
        getData(locationId) {
          if (locationId) {
            this.loading = true
            this.$store
                .dispatch("querySumOverviewLocation", locationId)
                .then(() => {
                  let result = this.$parent.$store.state.elecManagement.querySumOverviewLocation
                  // this.tableData = this.$store.state.storage.querySumOverviewLocation.data.byname;
                  if (result.errorCode === 0) {
                    let dataList1 = result.data.byname
                    if (dataList1 !== null) {
                      for (let i = 0; i < dataList1.length; i++) {
                        let huanbi = dataList1[i].huanbiRatio
                        dataList1[i].value = dataList1[i].value + " kWh"
                        if (huanbi === null) {
                          dataList1[i].huanbiRatio = "-"
                        } else {
                          dataList1[i].huanbiRatio = (huanbi * 100).toFixed(2)
                        }
                      }
                    } else {
                      dataList1 = []
                    }
                    this.tableData = dataList1
                    // let dataList2 = result.data.byusage
                    // this.$parent.$refs.officeBuild2Ref.strList = this.$parent.$refs.officeBuild2Ref.calcData(dataList2)
                  } else {
                    this.tableData = []
                  }
                }).finally(() => {
                  this.loading = false
                })
          }
        },
        getBuildId() {
          // 获取客户id
          this.dataForm.customerId = this.$store.state.storage.customerId;
          // 获取客户树id
          this.$store
              .dispatch("getBuildById", this.dataForm.customerId)
              .then(() => {
                let arr = this.getBuildById;
                if (arr && arr.length > 0) {
                  if ((arr !== null) & (arr.length > 0)) {
                    this.energyName = this.getBuildById[0].name;
                    this.dataForm.buildId = arr[0].id;
                    this.energyNameList = arr;
                    if (this.whichTab === 0) {
                      this.getData(this.dataForm.buildId)
                    } else if (this.whichTab === 1) {
                      this.getSingleData("2")
                    } else if (this.whichTab === 2) {
                      this.getSingleData("3")
                    } else if (this.whichTab === 3) {
                      this.getSingleData("4")
                    }
                  }
                } else {
                  this.energyName = ''
                  this.energyNameList = []
                  this.tableData = []
                  this.dataForm.buildId = ''
                  this.$parent.$refs.officeBuild2Ref.strList = []
                }
              })
        },
        getSingleData(type) {
          let dataList = []
          this.loading = true
          this.tableData = []
          this.$store
              .dispatch("getBuildTime", {
                id: this.dataForm.buildId,
                timetype: type,
              })
              .then(() => {
                dataList = this.getBuildTime || [];
                for (let i = 0; i < dataList.length; i++) {
                  let item = dataList[i];
                  let huanbi = item.huanbiRatio;
                  dataList[i].value = item.value + " kWh";
                  if (huanbi === null) {
                    huanbi = "-";
                    item.huanbiRatio = huanbi;
                  } else {
                    item.huanbiRatio = (huanbi * 100).toFixed(2);
                  }
                }
                this.tableData = dataList;
              }).finally(() => {
            this.loading = false;
          });
        }
    },
    watch: {
        "$store.state.storage.customerId": {
          immediate: true,
          deep: true,
          handler (newVal, oldVal) {
            if (!newVal) {
              return
            }
            this.dataForm.customerId = newVal
            this.getBuildId()
          },
        },
        whichTab: {
            immediate: true,
            handler (val) {
                // 办公楼表格部分的伪造数据##################################################################################
                if (this.dataForm.buildId && this.dataForm.buildId !== '') {
                  if (val === 0) {
                    this.getData(this.dataForm.buildId)
                  } else if (val === 1) {
                    this.getSingleData("2")
                  } else if (val === 2) {
                    this.getSingleData("3")
                  } else if (val === 3) {
                    this.getSingleData("4")
                  }
                }
            },
        },
    },
    computed: {
        ...mapState({
            // 区域用电里面的能耗概览
            getBuildTime: (state) => {
                return state.elecManagement.getBuildTime.data;
            },
            querySumOverview: (state) => {
                return state.elecManagement.querySumOverview.data;
            },
            getBuildById: (state) => {
              return state.elecManagement.getBuildById.data;
            },
        }),
    },
};
</script>
<style lang="less" scoped>
.office-content {
    .office1 {
        .title {
            position: relative;
            top: 2rem;
            margin-left: 2.4rem;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: #37a5dd;
        }
        .dateChoose {
            height: 3.5rem;
            width: 35.3rem;
            border-radius: 0.2rem;
            overflow: hidden;
            border: 0.1rem solid #004579;
            box-sizing: border-box;
            display: flex;
            flex-wrap: nowrap;
            position: absolute;
            right: 2.4rem;
            top: 1.6rem;
            color: #fff;
            .btnDark {
                width: 9.6rem;
                height: 3.8rem;
                background-color: #0b375e;
                font-size: 1.4rem;
                padding: 0;
                margin: 0;
                cursor: pointer;
                line-height: 3.6rem;
                text-align: center;
            }
            .btnGreen {
                width: 9.6rem;
                height: 3.8rem;
                background-color: #027969;
                font-size: 1.4rem;
                padding: 0;
                margin: 0;
                cursor: pointer;
                line-height: 3.6rem;
                text-align: center;
            }
        }
        .off-table {
            position: relative;
            // width: 100%;
            margin: 4rem 2.4rem 0 2.4rem;
            img {
                display: inline-block;
                width: 0.68rem;
                height: 1.1rem;
            }
        }
    }
}
</style>
<style lang="less">
.off-table {
    .el-table tr {
        background: #0c2f4f;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
        background-color: #0c2f4f;
    }
    .el-table .el-table__cell {
        padding: 0.7rem 0;
    }
    .el-table thead {
        color: #ffffff;
    }
    .el-table {
        font-size: 1.2rem;
        color: #ffffff;
        font-weight: 300;
    }
  .loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
    display: flex;
    justify-content: center;
    align-items: center; /* 居中对齐加载图标 */
    z-index: 9999; /* 确保遮罩层在最上层 */
  }
  /* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
  .loading-mask-parent {
    position: relative;
  }
}
</style>
