<!-- 配电监测变压器 -->
<template>
  <div class="DCscreenInMonitoring" v-if="DCscreenData.length>0">
    <!-- <div class="flex-end report-box">
      <span class="btn-report" @click="goReport">报表统计</span>
    </div> -->
    <div class="scroll-box">
      <div class="cardPlace" v-loading="loading">
        <!-- 变压器卡片########## -->
        <div class="DCscreenCard" v-for="(item,index) in DCscreenData " :key='index'>
          <!-- 顶部################### -->
          <div class="topPlace">
            <img class="DCscreenIcon" src="@/assets/Monitoring/TransformerIcon.png" alt="">
            <div class="topPlaceWord">{{item.name}}</div>
          </div>
          <!-- 中部####################### -->
          <div class="middlePlace">
            <img ref="tsImg" class="DCscreenBackgroundImg" :src="item.pictureUrl ? item.pictureUrl : require('@/assets/Monitoring/TransformerBackgroundImg.png')" alt="" @error="loadDefaultImg" />
            <div class="left">
              <div class="leftLine1">所属单位:</div>
              <div class="leftLine2">
                <el-tooltip :content="item.ownerName" placement="top">
                  <span>{{item.ownerName}}</span>
                </el-tooltip>
              </div>
              <div class="leftLine1">位置信息:</div>
              <div class="leftLine2">
                <el-tooltip :content="item.placeName" placement="top">
                  <span>{{item.placeName}}</span>
                </el-tooltip>
              </div>
              <div class="leftLine1">型号:</div>
              <div class="leftLine2"> {{item.specification}}</div>
              <div class="leftLine1">容量:</div>
              <div class="leftLine2"> {{item.capacity}}</div>
              <!-- <div class="leftLine1">A时:</div><div class="leftLine2"> {{item.aTime}}</div> -->
              <div class="leftLine1">厂家:</div>
              <div class="leftLine2">
                <el-tooltip :content="item.producer" placement="top">
                  <span>{{item.producer}}</span>
                </el-tooltip>
              </div>
              <div class="leftLine1">投运日期:</div>
              <div class="leftLine2"> {{item.useDate}}</div>
              <div class="leftLine1">生产日期: </div>
              <div class="leftLine2">{{item.produceDate}}</div>
            </div>
            <div class="right">
              <div class="right_content">
                <div class="text-header">
                  <span>高压侧</span>
                </div>
                <div class="text_content">
                  <div class="label">A相电流:</div>
                  <div class="text"> {{findIemsDatas(item.id,'A相电流','A')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">B相电流:</div>
                  <div class="text"> {{findIemsDatas(item.id,'B相电流','A')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">C相电流:</div>
                  <div class="text"> {{findIemsDatas(item.id,'C相电流','A')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">Uab线电压: </div>
                  <div class="text">{{findIemsDatas(item.id,'AB线电压','kV')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">Ubc线电压: </div>
                  <div class="text">{{findIemsDatas(item.id,'BC线电压','kV')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">Uca线电压: </div>
                  <div class="text">{{findIemsDatas(item.id,'CA线电压','kV')}}</div>
                </div>
              </div>
              <div class="right_content">
                <div class="text-header">
                  <span>低压侧</span>
                </div>
                <div class="text_content">
                  <div class="label">A相电流:</div>
                  <div class="text"> {{findIemsDatas(item.id,'低压-A相电流','A')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">B相电流:</div>
                  <div class="text"> {{findIemsDatas(item.id,'低压-B相电流','A')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">C相电流:</div>
                  <div class="text"> {{findIemsDatas(item.id,'低压-C相电流','A')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">Uab线电压: </div>
                  <div class="text">{{findIemsDatas(item.id,'低压-AB线电压','V')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">Ubc线电压: </div>
                  <div class="text">{{findIemsDatas(item.id,'低压-BC线电压','V')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">Uca线电压: </div>
                  <div class="text">{{findIemsDatas(item.id,'低压-CA线电压','V')}}</div>
                </div>
              </div>
              <div class="right_content">
                <div class="text-header"></div>
                <div class="text_content">
                  <div class="label">频率:</div>
                  <div class="text"> {{findIemsDatas(item.id,'频率','')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">总功率因数:</div>
                  <div class="text"> {{findIemsDatas(item.id,'总功率因数','')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">总有功功率:</div>
                  <div class="text"> {{findIemsDatas(item.id,'三相有功功率','kW')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">总无功功率:</div>
                  <div class="text"> {{findIemsDatas(item.id,'三相无功功率','kVar')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">有功电能:</div>
                  <div class="text"> {{findIemsDatas(item.id,'正向有功电能','kW.h')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">无功电能:</div>
                  <div class="text"> {{findIemsDatas(item.id,'正向无功电能','kVar.h')}}</div>
                </div>

              </div>
              <div class="right_content">
                <div class="text-header"></div>
                <div class="text_content">
                  <div class="label">当月最大需量: </div>
                  <div class="text">{{findIemsDatas(item.id,'当月最大需量','kW')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">{{ findIemsDatas(item.id,'当月最大需量时间','') }} </div>
                </div>
                <div class="text_content">
                  <div class="label">负荷率:</div>
                  <div class="text"> {{findIemsDatas(item.id,'负荷率','%')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">A相绕组温度:</div>
                  <div class="text"> {{findIemsDatas(item.id,'A相绕组温度','℃')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">B相绕组温度:</div>
                  <div class="text"> {{findIemsDatas(item.id,'B相绕组温度','℃')}}</div>
                </div>
                <div class="text_content">
                  <div class="label">C相绕组温度:</div>
                  <div class="text"> {{findIemsDatas(item.id,'C相绕组温度','℃')}}</div>
                </div>
              </div>
              <div class="right_content">
                <div class="text-header"></div>
                <div class="text_content">
                  <div class="label">风机启动:</div>
                  <div class="text"> {{ findIemsDatas(item.id,'风机状态','') === '' ? '' : findIemsDatas(item.id,'风机状态','') == 1 ? '已启动' : '未启动' }}</div>
                </div>
                <div class="text_content">
                  <div class="label">超温信号:</div>
                  <div class="text"> {{ findIemsDatas(item.id,'绕组超温故障','') === '' ? '' : findIemsDatas(item.id,'超温信号','') == 1 ? '超温异常' : '非超温状态' }}</div>
                </div>
                <div class="text_content">
                  <div class="label">高温信号:</div>
                  <div class="text"> {{ findIemsDatas(item.id,'铁芯超温故障','') === '' ? '' : findIemsDatas(item.id,'铁芯超温故障','') == 1 ? '高温报警' : '温度正常' }}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页器########################## -->
      <div class="pagination">
        <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" layout="total,prev, pager, next, jumper" :total="totalNumForPagenation">
        </el-pagination>
      </div>
      <div style="height:16rem;"></div>
    </div>
  </div>
  <div v-else style="height:96.8rem;background:rgba(0, 115, 222, 0.35)"></div>
</template>

<script>
import { getDiagramsByScenarioCode } from "@/api/diagram/main.js";
import { sysMapListApi, sysPagePress } from "@/api/diagram/kaiyuan.js";
import { watchDevice, } from "@/api/diagram/kaiyuan.js";
export default {
  name: 'Transformer',
  data() {
    return {
      loading: false,
      pageSize: 4,
      diagramId: this.$route.query.id,
      //变压器数据
      DCscreenData: [
        // {
        //   name: '海拉鲁变压器', ownerName: '武汉凯源电力', placeName: '武汉市江岸区永清街11号', specification: 'JBS90', capacity: '3000',
        //   aTime: '50h', producer: '台北市台积电', produceDate: '2021-9-13', useDate: '2021-10-21',
        //   控制母线电压: '220V', 动力母线电压: '230V', 充电电压: '110V', 蓄电池电压: '9V', 充电浮充装置输出电流: '10A'
        // },
        // {
        //   name: '卓拉变压器', ownerName: '武汉凯源电力', placeName: '武汉市武昌区沙湖路220号', specification: 'JBS75', capacity: '2500',
        //   aTime: '100h', producer: '长春市东风公司', produceDate: '2021-9-13', useDate: '2021-10-21',
        //   控制母线电压: '1000V', 动力母线电压: '550V', 充电电压: '230V', 蓄电池电压: '220V', 充电浮充装置输出电流: '50A'
        // },
        // {
        //   name: '莫力布林220版变压器', ownerName: '武汉凯源电力', placeName: '法国巴黎市查令十字街18号', specification: 'JBS66', capacity: '1500',
        //   aTime: '20h', producer: '台北台积电', produceDate: '2021-9-13', useDate: '2021-10-21',
        //   控制母线电压: '220V', 动力母线电压: '230V', 充电电压: '110V', 蓄电池电压: '9V', 充电浮充装置输出电流: '10A'
        // },
        // {
        //   name: '卡尔BJ990坚固型变压器', ownerName: '武汉凯源电力', placeName: '越南胡志明市黎明路9号', specification: 'JBS80', capacity: '8000',
        //   aTime: '120h', producer: '台北台积电', produceDate: '2021-9-13', useDate: '2021-10-21',
        //   控制母线电压: '220V', 动力母线电压: '230V', 充电电压: '110V', 蓄电池电压: '9V', 充电浮充装置输出电流: '10A'
        // },
      ],
      BindData: [],
      // 分页器当前页
      currentPage: 1,
      // 分页器总个数
      totalNumForPagenation: 100,
      intervalId: null,//计时器
      pointsDatas: []
    }
  },
  watch: {
    '$route': {
      handler(newVal, oldVal) {
        if (newVal?.query?.id !== oldVal?.query?.id) {
          this.diagramId = newVal?.query?.id;
          this.init();
        }
      },
      immediate: true
    },
  },
  methods: {
    loadDefaultImg(e){
      //console.log('图片加载错误', this.$refs.tsImg);
      this.$refs.tsImg[0].src = require('@/assets/Monitoring/TransformerBackgroundImg.png');
    },
    init() {
      //测试id:1470994708401008641-D
      Promise.all([this.getGraphData(this.diagramId), this.getListData(this.pageSize, this.currentPage, this.diagramId)]).then(res => {
        console.log('BindData', this.BindData)
        if (this.BindData.length > 0) {
          this.takeRelaData()
        }
      })
      //测试id:1470994708401008641-D
    },
    //获取列表右边数据
    takeRelaData() {
      let pointIdArr = this.BindData.map(item => {
        return item.pointId
      })
      let requestID = pointIdArr.join(',');
      watchDevice(requestID).then(res => {
        if (res.ok) {
          let resultData = res.data;//右侧数据合集
          this.BindData.forEach(item => {
            resultData.forEach(itemMeter => {
              if (item.pointId.indexOf(itemMeter.meterId) > -1) {
                let arr = item.BindData.filter(list => list.pointId == itemMeter.meterId)//找出BindData中pointId相同的数据
                this.DCscreenData.forEach(element => {
                  if (element.id == item.equipmentId) {
                    element[arr[0].moudle] == itemMeter.points[arr[0].moudle] ? itemMeter.points[arr[0].moudle] : '';
                  }
                })
                console.log('DCscreenData-2222', this.DCscreenData)
              }
            })
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //跳到统计报表
    goReport() {
      this.$router.push({
        path: '/monitoring/report', query: {
          id: this.diagramId,
          type: 'transformer'
        }
      })
    },
    // 拿到JSON数据
    async getGraphData(diagramCode) {
      const res = await getDiagramsByScenarioCode({
        diagramCode
      });
      if (res.code == 200) {
        let result = res.data.length == 0 ? [] : res.data.filter(list => JSON.stringify(list.diagramJson) !== "null")
        let diagramJsonArr = result.length == 0 ? [] : result.map(item => {
          return JSON.parse(item.diagramJson)
        })
        console.log('diagramJsonArr', diagramJsonArr)
        //获取绑定数据
        this.BindData = diagramJsonArr.length == 0 ? [] : diagramJsonArr.map(list => {
          let data = list.cells.map(item => { return item.BindData ? item.BindData : {} })
          let pointIdArr = data.map(item => { return item.pointId ? item.pointId : "" })
          return {
            equipmentId: list.cells[0].BindData?.equipmentId,//匹配id
            pointId: pointIdArr.filter(item => item !== ""),//通过此id调接口获取数据
            BindData: data//绑定数据
          }
        })
      }
    },
    async getListData(pageSize, pageNum, nodeId) {
      this.loading = true;
      const res = await sysMapListApi(pageSize, pageNum, nodeId);
      if (res.ok) {
        this.DCscreenData = [];
        this.totalNumForPagenation = res.data.total;
        let records = res.data.records;
        records.forEach((element, i) => {
          let { id, name, ownerName, placeName, specification, capacity, producer, useDate, produceDate, ratedLoad,pictureUrl } = element;
          this.DCscreenData.push({
            id: id,
            name: name,
            ownerName: ownerName,
            placeName: placeName,
            specification: specification,
            capacity: capacity,
            producer: producer,
            useDate: useDate,
            produceDate: produceDate,
            pictureUrl: pictureUrl,
            ratedLoad: ratedLoad || '',
          })
          if (this.DCscreenData.length > 0) {
            this.getSysPagePressList();
            this.sysPagePressInterval();
          }
        });
        this.loading = false;

      }
    },
    //分页器页面切换
    handleCurrentChange() {
      this.init()
    },
    /** 十秒刷新一次 */
    sysPagePressInterval() {
      console.log('计时器', this.intervalId)
      if (this.intervalId !== null) {
        console.log('存在计数器')
        return false;
      }
      this.intervalId = setInterval(() => {
        this.getSysPagePressList()
      }, 10000)
    },
    /** 根据设备id获取变压器实时指标数据 */
    async getSysPagePressList() {
      const params = {
        deviceIds: this.DCscreenData?.map(item => item.id).join()
      }
      const res = await sysPagePress(params);
      this.pointsDatas = res.data;
    },
    /**
     * 获取对应的值
     * @param {*} id 
     * @param {*} name 
     * @param {*} unit 
     */
    findIemsDatas(id, name, unit = '') {
      let text = ''
      //当前对应数据
      const res = this.pointsDatas?.find((item) => item?.meterId === id)
      //分页对应数据
      const DCItems = this.DCscreenData?.find((item) => item?.id === id)
      const points = res?.points || {};
      const keys = Object.keys(Object.assign({ ...res }, { ...points }))
      //是否存在关键字 不存在提前返回
      if (!keys.includes(name) && name !== '负荷率') {
        return text;
      }
      //负荷率 需特殊处理 计算方式  三相视在功率/rated_load 保留两位小数
      if (name === '负荷率') {
        let ratedLoad = DCItems?.ratedLoad ? DCItems.ratedLoad : ''
        let 三相视在功率 = res?.points['三相视在功率'] ? res?.points['三相视在功率'] : ''
        text = ((ratedLoad && 三相视在功率) && ((Number(三相视在功率) / Number(ratedLoad)) * 100).toFixed(2) + '%') || '--';
        return text;
      }
      text = (res[name] || res?.points[name]) ? (res[name] || `${res?.points[name]}${unit}`) : '--'
      return text
    }
  },
  beforeDestroy() {
    // if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null;
      console.log('beforeDestroy', this.intervalId)
    // }
  },
  created() {
    this.init()
    // let diagramCode = this.$route.query.id;

  },
}
</script>

<style lang="less" scoped>
.DCscreenInMonitoring {
  width: 100%;
  // height: 96.8rem;
  // height: 100rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background-color: #0f2437;
  .scroll-box {
    flex: 1;
    overflow-y: auto;
  }
  .scroll-box::-webkit-scrollbar {
    width: 0rem;
  }
  // 卡片的区域######################
  .cardPlace {
    width: 100%;
    height: 80rem;
    // flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    // 卡片样式##################################
    .DCscreenCard {
      height: 38rem;
      // width: 70rem;
      width: 100%;
      background-color: #0d3050;
      // margin-bottom: 2.4rem;
      position: relative;
      top: 0;
      left: 0;
      border-radius: 0.4rem;
      // 顶部#####################################
      .topPlace {
        height: 5rem;
        // width: 70rem;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
          225deg,
          rgba(15, 36, 55, 0.0001) 0.03%,
          rgba(0, 115, 222, 0.7) 99.9%
        );
        border-radius: 0.4rem 0.4rem 0px 0px;
        .DCscreenIcon {
          height: 1.6rem;
          width: 1.6em;
          position: absolute;
          top: 2rem;
          left: 2.4rem;
        }
        .topPlaceWord {
          height: 2.2rem;
          position: absolute;
          top: 1.7rem;
          left: 4.8rem;
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: #fff;
        }
      }

      //中部########################################
      .middlePlace {
        z-index: 1;
        display: flex;
        width: 100%;
        height: 33rem;
        // width: 70rem;
        width: 100%;
        position: absolute;
        top: 5rem;
        left: 0;
        border-radius: 0 0 0.4rem 0.4rem;
        align-items: center;
        .DCscreenBackgroundImg {
          position: absolute;
          left: 2.6rem;
          top: 10rem;
          height: 20rem;
          width: 20rem;
          // opacity: 0.8;
          opacity: 1;
          mix-blend-mode: overlay;
        }
        // 左侧板块###################
        .left {
          height: 25rem;
          // width: 30rem;
          width: 27rem;
          // position: absolute;
          // top: 3rem;
          // left: 4rem;
          // padding-top: 1.6rem;
          .leftLine1 {
            height: 2.2rem;
            // width: 11rem;
            width: 8rem;
            float: left;
            line-height: 2.2rem;
            text-align: right;
            color: rgba(255, 255, 255, 0.5);
            font-size: 1.4rem;
            margin-bottom: 1rem;
          }
          .leftLine2 {
            height: 2.2rem;
            width: 19rem;
            float: right;
            line-height: 2.2rem;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            font-size: 1.4rem;
            margin-bottom: 1rem;
            text-align: left;
            text-indent: 1.5rem;
            // overflow: visible;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        // 右侧板块####################
        .right {
          // height: 25rem;
          // width: 30rem;
          flex: 1;
          // position: absolute;
          // top: 3rem;
          // left: 34rem;
          // background-color: #0e2a44;
          // padding-top: 1.6rem;
          display: flex;
          .right_content {
            background-color: #0e2a44;
            margin: 0 5px;
            flex: 1;
            padding: 15px 10px 0;
            // display: flex;
            // flex-direction: column;
            .text-header {
              color: rgba(3, 217, 188, 0.7);
              text-align: center;
              font-size: 1.5rem;
              font-weight: bold;
              height: 2rem;
              line-height: 2rem;
              padding-bottom: 1rem;
            }
            .text_content {
              display: flex;
              height: 2.2rem;
              line-height: 2.2rem;
              margin-bottom: 1rem;
              .label {
                // width: 15rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: 1.4rem;
              }
              .text {
                flex: 1;
                color: rgba(3, 217, 188, 0.7);
                font-size: 1.4rem;
                margin-left: 1rem;
              }
            }
          }
          .rightLine1 {
            height: 2.2rem;
            width: 18rem;
            float: left;
            line-height: 2.2rem;
            text-align: right;
            color: rgba(255, 255, 255, 0.5);
            font-size: 1.4rem;
            margin-bottom: 1rem;
          }
          .rightLine2 {
            height: 2.2rem;
            width: 7.3rem;
            float: left;
            line-height: 2.2rem;
            text-align: left;
            color: rgba(3, 217, 188, 0.7);
            font-size: 1.4rem;
            margin-bottom: 1rem;
            text-align: right;
            text-indent: 1.5rem;
          }
        }
      }
    }
  }
  // 分页器样式##################################
  .pagination {
    // float: right;
    margin-right: 5rem;
    padding-top: 0.5rem;
    text-align: right;
    height: 4.7rem;
    box-sizing: border-box;
  }
  .report-box {
    height: 6.2rem;
    box-sizing: border-box;
    padding: 1.6rem 0;
  }
}
</style>
