import request from '@/utils/request.js'

// 我的巡检导出
export const exportRepairPatrolTask = params => {
    return request({
        url: '/instask/myTask/export',
        method: 'GET',
        params,
        responseType: 'blob',
    })
}

// 我的工单导出
export const exportRepairOrder = params => {
    return request({
        url: '/so/myOrder/export',
        method: 'GET',
        params,
        responseType: 'blob',
    })
}

// 配电报表统计导出
export const exportMonitoringReport = params => {
    return request({
        url: '/watch/report/export',
        method: 'GET',
        params,
        responseType: 'blob',
    })
}

// 电气消防报表统计导出
export const exportFireControlReport = params => {
    return request({
        url: '/leakage/report/export',
        method: 'GET',
        params,
        responseType: 'blob',
    })
}


// 发电管理 报表统计 电站报表导出
export const exportGenManagementStationReport = val => {
    return request({
        url: `gen-management/report/station/export/${val.customerId}/${val.tjmethod}/${val.datetype}/${val.date}?stationIds=${val.stationIds}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 发电管理 报表统计 逆变器报表导出
export const exportGenManagementInverterReport = val => {
    return request({
        url: `gen-management/report/inverter/export/${val.customerId}/${val.datetype}/${val.date}?inverterIds=${val.inverterIds}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 发电管理 报表统计 储能报表导出
export const exportGenManagementBatteryReport = val => {
    return request({
        url: `gen-management/report/battery/export/${val.customerId}/${val.datetype}/${val.date}?batteryIds=${val.batteryIds}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 发电管理 发电概览 年发电量报表导出
export const exportGenManagementCurryearbarReport = val => {
    return request({
        url: `gen-management/curryearbar/export/${val.customerId}`,
        method: 'GET',
        responseType: 'blob',
    })
}


// 能源管理 能耗统计 区域用电-本月用电趋势报表导出
export const exportEnergyReport = val => {
    return request({
        url: `energy/export?customerId=${val.customerId}&month=${val.month}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 能源管理 时段分析 分时统计报表导出
export const exportEnergyTimeReport = val => {
    return request({
        url: `energy/time/interval/export?locationId=${val.locationId}&month=${val.month}&type=${val.type}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 能源管理 对比分析 分时统计报表导出
export const exportEnergyAnalyzeByTimeReport = val => {
    return request({
        url: `energy/graph/analyze/byTime/${val.locationIds}/${val.type}/export/?dateStart=${val.dateStart}&dateEnd=${val.dateEnd}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 能源管理 对比分析 能耗趋势分析报表导出
export const exportEnergyAnalyzeTrendReport = val => {
    return request({
        url: `energy/graph/analyze/trend/${val.locationId}/${val.type}/export/?dateStart=${val.dateStart}&dateEnd=${val.dateEnd}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 能源管理 对比分析 工作/非工作能耗分析报表导出
export const exportEnergyAnalyzeWorkandrestReport = val => {
    return request({
        url: `energy/graph/analyze/workandrest/${val.locationId}/${val.month}/export/`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 能源管理 统计报表 区域用能报表导出
export const exportEnergyRegionReport = val => {
    return request({
        url: `energy/report/region`,
        data: val,
        method: 'POST',
        responseType: 'blob',
    })
}

// 能源管理 统计报表 分项用能报表导出
export const exportEnergyConsumptionReport = val => {
    return request({
        url: `energy/consumption/${val.customerId}/${val.type}/${val.datetype}/${val.date}/export/?nodeIds=${val.nodeIds}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 能源管理 统计报表 配电用能报表导出
export const exportEnergyBymeterReport = val => {
    return request({
        // url: `energy/report/bymeter/${val.customerId}/${val.type}/${val.datetype}/${val.date}/export/?nodeIds=${val.nodeIds}`,
        url: `energy/report/peidian/${val.customerId}/${val.type}/${val.datetype}/${val.date}/export/?floorIds=${val.nodeIds}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 充电运营 设备统计 报表导出
export const exportChargingDeviceReport = val => {
    return request({
        url: `charging/device/export?deviceFamily=${val.deviceFamily}&id=${val.id}&online=${val.online}&status=${val.status}&ids=${val.chargeToken}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 充电运营 充电统计 报表导出
export const exportChargingChargeReport = val => {
    return request({
        url: `charging/charge/export?deviceName=${val.deviceName}&ids=${val.chargeToken}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 充电运营 订单统计 报表导出
export const exportChargingOrderReport = val => {
    return request({
        url: `charging/order/export?ids=${val.chargeToken}&deviceId=${val.deviceId}&orderNum=${val.orderNum}&payChannel=${val.payChannel}&payType=${val.payType}&status=${val.status}`,
        method: 'GET',
        responseType: 'blob',
    })
}


// 客户管理 报表导出
export const exportCustomerReport = val => {
    return request({
        url: `customer/export?keyword=${val.keyword}&parentId=${val.parentId}&industry=${val.industry}`,
        method: 'GET',
        responseType: 'blob',
    })
}


// 运维检修 巡检计划 报表导出
export const exportInsplanReport = val => {
    return request({
        url: `insplan/export?address=${val.address}&executorName=${val.executorName}&intervalType=${val.intervalType}&keyword=${val.keyword}&status=${val.status}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 运维检修 巡检任务 报表导出
export const exportInstaskReport = val => {
    return request({
        url: `instask/export?address=${val.address}&executorName=${val.executorName}&intervalType=${val.intervalType}&keyword=${val.keyword}&status=${val.status}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 运维检修 工单管理 报表导出
export const exportOrderReport = val => {
    return request({
        url: `so/export?handler=${val.handler}&keyword=${val.keyword}&orderNo=${val.orderNo}&processingType=${val.processingType}&source=${val.source}&status=${val.status}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 运维检修 运维统计 工单概况 报表导出
export const exportOverviewOrderReport = val => {
    return request({
        url: `/statistics/overview/order/export?startTime=${val.startTime}&endTime=${val.endTime}&type=${val.type}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 运维检修 运维统计 巡检概况 报表导出
export const exportOverviewInspectionReport = val => {
    return request({
        url: `/statistics/overview/inspection/export?startTime=${val.startTime}&endTime=${val.endTime}&type=${val.type}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 运维检修 工程师统计 工程师工单统计 报表导出
export const exportEngineerOrderReport = val => {
    return request({
        url: `/statistics/engineer/order/export?startTime=${val.startTime}&endTime=${val.endTime}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 运维检修 工程师统计 工程师巡检统计 报表导出
export const exportEngineerInspectionReport = val => {
    return request({
        url: `/statistics/engineer/inspection/export?startTime=${val.startTime}&endTime=${val.endTime}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 运维检修 绩效统计 报表导出
export const exportPerformanceReport = val => {
    return request({
        url: `statistics/performance/export?startTime=${val.startTime}&endTime=${val.endTime}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 运维检修 响应时长统计 报表导出
export const exportResTimeReport = val => {
    return request({
        url: `/statistics/resTime/export?startTime=${val.startTime}&endTime=${val.endTime}`,
        method: 'GET',
        responseType: 'blob',
    })
}


// 设备管理 配电设备 报表导出
export const exportDeviceReport = val => {
    return request({
        url: `device/export?keyword=${val.keyword}&ownerId=${val.ownerId}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 设备管理 发电设备 报表导出
export const exportGeneratorReport = val => {
    return request({
        url: `generator/export?keyword=${val.keyword}&ownerId=${val.ownerId}&type=${val.type}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 设备管理 仪表管理 报表导出
export const exportEnergyMeterReport = val => {
    return request({
        url: `energy/meter/export?keyword=${val.keyword}&ownerId=${val.ownerId}&type=${val.type}`,
        method: 'GET',
        responseType: 'blob',
    })
}
// 设备管理 配电用能 报表导出
export const exportPedianReport = val => {
    return request({
        url: `pedian/meter/export?keyword=${val.keyword}&ownerId=${val.ownerId}`,
        method: 'GET',
        responseType: 'blob',
    })
}


// 系统管理 告警管理 告警信息报表导出
export const exportWarningReport = val => {
    return request({
        url: `warning-info/export?warningType=${val.warningType}&warningLevel=${val.warningLevel}&warningStatus=${val.warningStatus}&beginTime=${val.beginTime}&endTime=${val.endTime}`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 直流侧历史数据 导出
export const directCurrentReport = val => {
    return request({
        url:`gen-management/inverter/zhiliu/${val.inverterId}/${val.type}/${val.date}/export`,
        method: 'GET',
        responseType: 'blob',
    })
}

// 交流侧历史数据 导出
export const exchangeReport = val => {
    return request({
        url:`gen-management/inverter/jiaoliu/${val.inverterId}/${val.type}/${val.date}/export`,
        method: 'GET',
        responseType: 'blob',
    })
}
