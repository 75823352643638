import { Graph } from '@antv/x6'
import registerGraphListener from "../eventSystems";
import trigger from "../common/trigger";
import { SVGSizeEnum } from "../common/enums";
import { linkedGraph } from ".";

// #region 初始化图形
const rectPorts = {
	groups: {
		top: {
			position: 'top',
			attrs: {
				circle: {
					r: 6,
					magnet: true,
					stroke: '#5b8ffa',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		right: {
			position: 'right',
			attrs: {
				circle: {
					r: 6,
					magnet: true,
					stroke: '#5b8ffa',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		bottom: {
			position: 'bottom',
			attrs: {
				circle: {
					r: 6,
					magnet: true,
					stroke: '#5b8ffa',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		left: {
			position: 'left',
			attrs: {
				circle: {
					r: 6,
					magnet: true,
					stroke: '#5b8ffa',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
	},
	items: [{
		group: 'top',
	}, {
		group: 'right',
	}, {
		group: 'bottom',
	}, {
		group: 'left',
	}]
}

Graph.registerNode(
	'svg-image',
	{
		inherit: 'rect',
		width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight,
		markup: [{
			tagName: 'rect',
			selector: 'body',
		}, {
			tagName: 'image',
		}],
		attrs: {
			body: {
				strokeWidth: 1,
				stroke: '#26C160',
				fill: '#26C160',
			},
			image: {
				width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight,
				x: 0,
				y: 0
			}
		},
		// ports: {...rectPorts},
	},
	true,
);

/**
 * x6实例化
 */
let _this;
//初始化
export function initGraph(vue) {
	_this = vue;

	const graph = new Graph({
		container: document.getElementById("container"),
		async: true,
		// //滚动条
		// scroller: {
		// 	enabled: true,
		// },
		width: 1528,
		height: 791,
		//背景，默认不绘制背景。
		// background:{
		// 	color:'rgba(0, 115, 222, 0.15)'
		// },
		// 监听容器大小改变，并自动更新画布大小
		// https://x6.antv.vision/zh/docs/api/graph/graph#autoresize
		autoResize: true,
		// 键盘快捷键 Keyboard
		// https://x6.antv.vision/zh/docs/tutorial/basic/keyboard/#gatsby-focus-wrapper
		keyboard: {
			enabled: true,
			global: true,
		},
		// 点选/框选，默认禁用。
		selecting: {
			enabled: true,
			showNodeSelectionBox: true,
			showEdgeSelectionBox: false,
			// https://x6.antv.vision/zh/docs/tutorial/basic/selection
			rubberband: true, // 启用框选
			//modifiers: "ctrl", // 组合键
		},
		// 对齐线
		snapline: true,
		// 画布平移
		// https://x6.antv.vision/zh/docs/tutorial/basic/graph/#%E7%94%BB%E5%B8%83%E5%B9%B3%E7%A7%BB
		panning: {
			enabled: false,
			modifiers: 'ctrl',
			eventTypes: ["leftMouseDown"],
		},
		// 网格
		grid: {
			type: 'mesh', // 'dot' | 'fixedDot' | 'mesh'
			size: 1, // 网格大小 1px
			visible: true, // 渲染网格背景,
			args: {
				color: '#606060', // 网格线/点颜色
				thickness: 0.5,     // 网格线宽度/网格点大小
			},
		},
		// 剪切板 Clipboard
		// https://x6.antv.vision/zh/docs/tutorial/basic/clipboard
		clipboard: true,
		// 撤销/重做 Redo/Undo
		// https://x6.antv.vision/zh/docs/tutorial/basic/history/#gatsby-focus-wrapper
		history: {
			enabled: true,
			beforeAddCommand(event, args) {
				if (args.key) {
					// 禁止删除按钮添加到 Undo 队列中
					return args.key !== "tools";
				}
			},
		},
		// 是否禁用浏览器默认右键菜单。
		preventDefaultContextMenu: true,
		// 可以通过 highlighting 选项来指定触发某种交互时的高亮样式
		// https://x6.antv.vision/zh/docs/api/graph/interaction/#highlighting
		highlighting: {
			// 当链接桩可以被链接时，在链接桩外围渲染一个 2px 宽的红色矩形框
			/*magnetAvailable: {
				name: "stroke",
				args: {
					padding: 4,
					attrs: {
						"stroke-width": 4,
						stroke: "skyblue",
					},
				},
			},*/
			// 连线过程中，自动吸附到链接桩时被使用
			magnetAdsorbed: {
				name: "stroke",
				args: {
					// padding: 4,
					attrs: {
						"stroke-width": 8,
						stroke: "skyblue",
					},
				},
			},
		},
		// 滚轮缩放
		mousewheel: {
			enabled: true,
			zoomAtMousePosition: true,
			modifiers: 'ctrl',
			minScale: 0.5,
			maxScale: 3,
		},
		// 迷你地图
		minimap: {
			enabled: true,
			// width: 250,
			// height: 250,
			container: document.getElementById("minimap"),
			padding: 40,
			graphOptions: {
				async: true,
				connecting: {
					targetAnchor: "orth",
				}
			}
		},
		// 定制节点和边的交互行为
		// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#%E5%AE%9A%E5%88%B6%E4%BA%A4%E4%BA%92%E8%A1%8C%E4%B8%BA
		/*interacting: function (cellView) {
			if (cellView.cell.getData()?.disableMove) {
				return {nodeMovable: false}
			}
			return true
		},*/
		interacting: {
			// 'nodeMovable' 节点是否可以被移动。
			// 'magnetConnectable' 当在具有 'magnet' 属性的元素上按下鼠标开始拖动时，是否触发连线交互。
			// 'edgeMovable' 边是否可以被移动。
			// 'edgeLabelMovable' 边的标签是否可以被移动。
			// 'arrowheadMovable' 边的起始/终止箭头是否可以被移动。
			// 'vertexMovable' 边的路径点是否可以被移动。
			// 'vertexAddable' 是否可以添加边的路径点。
			// 'vertexDeletable' 边的路径点是否可以被删除。
			nodeMovable: true,
			magnetConnectable: true,
			edgeMovable: true,
			edgeLabelMovable: true,
			arrowheadMovable: true,
			vertexMovable: true,
			vertexAddable: true,
			vertexDeletable: true,
		},
		resizing: {
			enabled: true,
			// minWidth: 30,
			// minHeight: 30,
			maxWidth: Number.MAX_SAFE_INTEGER,
			maxHeight: Number.MAX_SAFE_INTEGER,
			orthogonal: true,
			restricted: true,
			autoScroll: true,
			preserveAspectRatio: false, // 缩放过程中是否保持节点的宽高比例，默认为 false。
			allowReverse: true,
		},
		rotating: {
			enabled: true,
			// grid: 15,
		},
		scroller: {
			enabled: true,
			pannable: true,
			modifiers: 'ctrl',
			className: "centerContain"
		},
		// 配置全局的连线规则
		// https://x6.antv.vision/zh/docs/api/graph/interaction
		connecting: {
			// 自动吸附
			snap: true,
			// 不允许连接到画布空白位置的点
			allowBlank: true,
			// 不允许创建循环连线
			allowLoop: false,
			// 不允许在相同的起始节点和终止之间创建多条边
			allowMulti: true,
			allowPort: true,
			allowEdge: true,
			allowNode: true,
			// 高亮显示所有可用的连接桩或节点
			// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#highlight
			highlight: true,
			// 当连接到节点时，通过 sourceAnchor 来指定源节点的锚点。
			sourceAnchor: {
				name: "center",
			},
			// 当连接到节点时，通过 targetAnchor 来指定目标节点的锚点。
			targetAnchor: "orth",
			// 指定连接点，默认值为 boundary。
			connectionPoint: "anchor",
			// 连接器将起点、路由返回的点、终点加工为 元素的 d 属性，决定了边渲染到画布后的样式，默认值为 normal。
			connector: {
				name: "normal", // rounded
				args: {
					radius: 20,
				},
			},
			// 路由将边的路径点 vertices 做进一步转换处理，并在必要时添加额外的点，然后返回处理后的点，默认值为 normal。
			// router: "orth",
			// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#validatemagnet
			// 判断是否新增边
			validateMagnet({ magnet }) {
				const portGroup = magnet.getAttribute("port-group");
				return portGroup !== "in";
			},
			// 连接的过程中创建新的边
			createEdge() {
				console.log("this", this)
				let color="#000000";
				switch (_this.$refs.LeftToolBar.svgTypeColor) {
					case "components":
						color="#000000";
						break;
					case "components_2":
						color="#966100";
						break;
					case "components_3":
						color="#780000";
						break;
					case "components_4":
						color="#FFFF95";
						break;
					case "components_5":
						color="#FF0000";
						break;
					case "components_6":
						color="#FFFFFF";
						break;
				}
				return this.createEdge({
					zIndex: -1,
					router: {
						name: 'orth', // 'orth',// 拐线
						args: {
							padding: 1
						}
					},
					attrs: {
						line: {
							targetMarker: {
								size: 6,
								name: ''
							},
							stroke: color,
							strokeWidth: 1,
							strokeDasharray: 0,
						},
						path: {
							isVerticalLine: true,
							d: ''
						},
						props:{
							stroke: color,
							strokeWidth: 1,
						}
					},
					/*attrs: {
						line: {
							strokeDasharray: "5 5",
							stroke: "#7c68fc",
							strokeWidth: 2,
							targetMarker: {
								name: "block",
								args: {
									size: "6",
								}
							}
						}
					}*/
				});
			},
			// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#validateconnection
			// 在移动边的时候判断连接是否有效，如果返回 false，当鼠标放开的时候，不会连接到当前元素，否则会连接到当前元素。
			validateConnection({ targetView, sourceMagnet, targetMagnet, sourceCell }) {
				/*if (!sourceMagnet || !targetMagnet) {
					return false;
				}
				if (sourceCell.getData()?.disableMove) return false
				// 判断目标链接桩是否可连接
				const portId = targetMagnet.getAttribute("port");
				const node = targetView.cell;
				const port = node.getPort(portId);
				if (!port) {
					return false;
				}*/
				return true;
			}
		},
	});

	// 注册画布监听器
	registerGraphListener(graph, _this)
	// 注入触发器
	trigger(graph, _this);
	// 缓存实例化graph引用
	linkedGraph(graph)
	// graph.zoom(1)
	// 将画布内容中心与视口中心对齐
	graph.centerContent()
	return graph
}
//初始化
export function initGraph1(vue) {
	_this = vue;

	const graph = new Graph({
		container: document.getElementById("container"),
		async: true,
		// //滚动条
		// scroller: {
		// 	enabled: true,
		// },
		// width: 1528,
		// height: 791,
		//背景，默认不绘制背景。
		background:{
			color:'rgba(0, 115, 222, 0.15)'
		},
		// 监听容器大小改变，并自动更新画布大小
		// https://x6.antv.vision/zh/docs/api/graph/graph#autoresize
		autoResize: true,
		// 键盘快捷键 Keyboard
		// https://x6.antv.vision/zh/docs/tutorial/basic/keyboard/#gatsby-focus-wrapper
		keyboard: {
			enabled: true,
			global: true,
		},
		// 点选/框选，默认禁用。
		selecting: {
			enabled: true,
			showNodeSelectionBox: true,
			showEdgeSelectionBox: false,
			// https://x6.antv.vision/zh/docs/tutorial/basic/selection
			rubberband: true, // 启用框选
			//modifiers: "ctrl", // 组合键
		},
		// 对齐线
		snapline: true,
		// 画布平移
		// https://x6.antv.vision/zh/docs/tutorial/basic/graph/#%E7%94%BB%E5%B8%83%E5%B9%B3%E7%A7%BB
		panning: {
			enabled: false,
			modifiers: 'ctrl',
			eventTypes: ["leftMouseDown"],
		},
		// 网格
		grid: {
			type: 'mesh', // 'dot' | 'fixedDot' | 'mesh'
			size: 1, // 网格大小 10px
			visible: true, // 渲染网格背景,
			args: {
				color: '#606060', // 网格线/点颜色
				thickness: 0.5,     // 网格线宽度/网格点大小
			},
		},
		// 剪切板 Clipboard
		// https://x6.antv.vision/zh/docs/tutorial/basic/clipboard
		clipboard: true,
		// 撤销/重做 Redo/Undo
		// https://x6.antv.vision/zh/docs/tutorial/basic/history/#gatsby-focus-wrapper
		history: {
			enabled: true,
			beforeAddCommand(event, args) {
				if (args.key) {
					// 禁止删除按钮添加到 Undo 队列中
					return args.key !== "tools";
				}
			},
		},
		// 是否禁用浏览器默认右键菜单。
		preventDefaultContextMenu: true,
		// 可以通过 highlighting 选项来指定触发某种交互时的高亮样式
		// https://x6.antv.vision/zh/docs/api/graph/interaction/#highlighting
		highlighting: {
			// 当链接桩可以被链接时，在链接桩外围渲染一个 2px 宽的红色矩形框
			/*magnetAvailable: {
				name: "stroke",
				args: {
					padding: 4,
					attrs: {
						"stroke-width": 4,
						stroke: "skyblue",
					},
				},
			},*/
			// 连线过程中，自动吸附到链接桩时被使用
			magnetAdsorbed: {
				name: "stroke",
				args: {
					padding: 4,
					attrs: {
						"stroke-width": 8,
						stroke: "skyblue",
					},
				},
			},
		},
		// 滚轮缩放
		mousewheel: {
			enabled: true,
			zoomAtMousePosition: true,
			modifiers: 'ctrl',
			minScale: 0.5,
			maxScale: 3,
		},
		// 迷你地图
		minimap: {
			enabled: true,
			// width: 250,
			// height: 250,
			container: document.getElementById("minimap"),
			padding: 40,
			graphOptions: {
				async: true,
				connecting: {
					targetAnchor: "orth",
				}
			}
		},
		// 定制节点和边的交互行为
		// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#%E5%AE%9A%E5%88%B6%E4%BA%A4%E4%BA%92%E8%A1%8C%E4%B8%BA
		/*interacting: function (cellView) {
			if (cellView.cell.getData()?.disableMove) {
				return {nodeMovable: false}
			}
			return true
		},*/
		interacting: {
			// 'nodeMovable' 节点是否可以被移动。
			// 'magnetConnectable' 当在具有 'magnet' 属性的元素上按下鼠标开始拖动时，是否触发连线交互。
			// 'edgeMovable' 边是否可以被移动。
			// 'edgeLabelMovable' 边的标签是否可以被移动。
			// 'arrowheadMovable' 边的起始/终止箭头是否可以被移动。
			// 'vertexMovable' 边的路径点是否可以被移动。
			// 'vertexAddable' 是否可以添加边的路径点。
			// 'vertexDeletable' 边的路径点是否可以被删除。
			nodeMovable: true,
			magnetConnectable: true,
			edgeMovable: true,
			edgeLabelMovable: true,
			arrowheadMovable: true,
			vertexMovable: true,
			vertexAddable: true,
			vertexDeletable: true,
		},
		resizing: {
			enabled: true,
			// minWidth: 30,
			// minHeight: 30,
			maxWidth: Number.MAX_SAFE_INTEGER,
			maxHeight: Number.MAX_SAFE_INTEGER,
			orthogonal: true,
			restricted: true,
			autoScroll: true,
			preserveAspectRatio: false, // 缩放过程中是否保持节点的宽高比例，默认为 false。
			allowReverse: true,
		},
		rotating: {
			enabled: true,
			// grid: 15,
		},
		scroller: {
			enabled: true,
			className: "centerContain"
		},
		// 配置全局的连线规则
		// https://x6.antv.vision/zh/docs/api/graph/interaction
		connecting: {
			// 自动吸附
			snap: true,
			// 不允许连接到画布空白位置的点
			allowBlank: true,
			// 不允许创建循环连线
			allowLoop: false,
			// 不允许在相同的起始节点和终止之间创建多条边
			allowMulti: true,
			allowPort: true,
			allowEdge: true,
			allowNode: true,
			// 高亮显示所有可用的连接桩或节点
			// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#highlight
			highlight: true,
			// 当连接到节点时，通过 sourceAnchor 来指定源节点的锚点。
			sourceAnchor: {
				name: "center",
			},
			// 当连接到节点时，通过 targetAnchor 来指定目标节点的锚点。
			targetAnchor: "orth",
			// 指定连接点，默认值为 boundary。
			connectionPoint: "anchor",
			// 连接器将起点、路由返回的点、终点加工为 元素的 d 属性，决定了边渲染到画布后的样式，默认值为 normal。
			connector: {
				name: "normal", // rounded
				args: {
					radius: 20,
				},
			},
			// 路由将边的路径点 vertices 做进一步转换处理，并在必要时添加额外的点，然后返回处理后的点，默认值为 normal。
			// router: "orth",
			// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#validatemagnet
			// 判断是否新增边
			validateMagnet({ magnet }) {
				const portGroup = magnet.getAttribute("port-group");
				return portGroup !== "in";
			},
			// 连接的过程中创建新的边
			createEdge() {
				console.log("this", this)
				let color="#000000";
				switch (_this.$refs.LeftToolBar.svgTypeColor) {
					case "components":
						color="#000000";
						break;
					case "components_2":
						color="#966100";
						break;
					case "components_3":
						color="#780000";
						break;
					case "components_4":
						color="#FFFF95";
						break;
					case "components_5":
						color="#FF0000";
						break;
				}
				return this.createEdge({
					zIndex: -1,
					router: {
						name: 'orth', // 'orth',// 拐线
						args: {
							padding: 1
						}
					},
					attrs: {
						line: {
							targetMarker: {
								size: 6,
								name: ''
							},
							stroke: color,
							strokeWidth: 1,
							strokeDasharray: 0,
						},
						path: {
							isVerticalLine: true,
							d: ''
						},
						props:{
							stroke: color,
							strokeWidth: 1,
						}
					},
					/*attrs: {
						line: {
							strokeDasharray: "5 5",
							stroke: "#7c68fc",
							strokeWidth: 2,
							targetMarker: {
								name: "block",
								args: {
									size: "6",
								}
							}
						}
					}*/
				});
			},
			// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#validateconnection
			// 在移动边的时候判断连接是否有效，如果返回 false，当鼠标放开的时候，不会连接到当前元素，否则会连接到当前元素。
			validateConnection({ targetView, sourceMagnet, targetMagnet, sourceCell }) {
				/*if (!sourceMagnet || !targetMagnet) {
					return false;
				}
				if (sourceCell.getData()?.disableMove) return false
				// 判断目标链接桩是否可连接
				const portId = targetMagnet.getAttribute("port");
				const node = targetView.cell;
				const port = node.getPort(portId);
				if (!port) {
					return false;
				}*/
				return true;
			}
		},
	});

	// 注册画布监听器
	registerGraphListener(graph, _this)
	// 注入触发器
	trigger(graph, _this);
	// 缓存实例化graph引用
	linkedGraph(graph)
	graph.zoom(1)
	// 将画布内容中心与视口中心对齐
	graph.centerContent()
	return graph
}
//预览
export function initGraphPreView(vue) {
	_this = vue;
	const graph = new Graph({
		container: document.getElementById("container"),
		async: true,
		// width: 600,
		// height: 600,
		// 监听容器大小改变，并自动更新画布大小
		// https://x6.antv.vision/zh/docs/api/graph/graph#autoresize
		autoResize: true,
		// 键盘快捷键 Keyboard
		// https://x6.antv.vision/zh/docs/tutorial/basic/keyboard/#gatsby-focus-wrapper
		keyboard: {
			enabled: true,
			global: true,
		},
		// 画布平移
		// https://x6.antv.vision/zh/docs/tutorial/basic/graph/#%E7%94%BB%E5%B8%83%E5%B9%B3%E7%A7%BB
		panning: {
			enabled: false,
			eventTypes: ["leftMouseDown", "rightMouseDown", "mouseWheel"],
		},
		scroller: {
			enabled: true,
			className: "centerContain"
		},
		// 网格
		grid: {
			type: 'mesh', // 'dot' | 'fixedDot' | 'mesh'
			size: 10, // 网格大小 10px
			visible: false, // 渲染网格背景,
			args: {
				color: '#a0a0a0', // 网格线/点颜色
				thickness: 1,     // 网格线宽度/网格点大小
			},
		},
		// 可以通过 highlighting 选项来指定触发某种交互时的高亮样式
		// https://x6.antv.vision/zh/docs/api/graph/interaction/#highlighting
		highlighting: {
			// 当链接桩可以被链接时，在链接桩外围渲染一个 2px 宽的红色矩形框
			magnetAvailable: {
				name: "stroke",
				args: {
					padding: 4,
					attrs: {
						"stroke-width": 4,
						stroke: "skyblue",
					},
				},
			},
			// 连线过程中，自动吸附到链接桩时被使用
			magnetAdsorbed: {
				name: "stroke",
				args: {
					padding: 4,
					attrs: {
						"stroke-width": 8,
						stroke: "skyblue",
					},
				},
			},
		},
		// 滚轮缩放
		mousewheel: {
			enabled: true,
			modifiers: ["ctrl", "meta"],
		},
		// 迷你地图
		minimap: {
			enabled: true,
			// width: 250,
			// height: 250,
			container: document.getElementById("minimap"),
			padding: 40,
		},
		// 是否禁用浏览器默认右键菜单。
		preventDefaultContextMenu: true,
		// 定制节点和边的交互行为
		// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#%E5%AE%9A%E5%88%B6%E4%BA%A4%E4%BA%92%E8%A1%8C%E4%B8%BA
		/*interacting: function (cellView) {
			if (cellView.cell.getData()?.disableMove) {
				return {nodeMovable: false}
			}
			return true
		},*/
		interacting: {
			nodeMovable: (view, CellView) => {
				const cell = view.cell
				return cell.prop('movable') === true
			},
			magnetConnectable: true,
			edgeMovable: true,
			edgeLabelMovable: true,
			arrowheadMovable: true,
			vertexMovable: true,
			vertexAddable: true,
			vertexDeletable: true,
		},
		resizing: {
			enabled: true,
			minWidth: 30,
			minHeight: 30,
			maxWidth: Number.MAX_SAFE_INTEGER,
			maxHeight: Number.MAX_SAFE_INTEGER,
			orthogonal: true,
			restricted: true,
			autoScroll: true,
			preserveAspectRatio: true, // 缩放过程中是否保持节点的宽高比例，默认为 false。
			allowReverse: true,
		},
		rotating: {
			enabled: true,
			// grid: 15,
		},
		// 配置全局的连线规则
		// https://x6.antv.vision/zh/docs/api/graph/interaction
		connecting: {
			// 自动吸附
			snap: true,
			// 不允许连接到画布空白位置的点
			allowBlank: true,
			// 不允许创建循环连线
			allowLoop: false,
			// 不允许在相同的起始节点和终止之间创建多条边
			allowMulti: true,
			// 高亮显示所有可用的连接桩或节点
			// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#highlight
			highlight: true,
			// 当连接到节点时，通过 sourceAnchor 来指定源节点的锚点。
			sourceAnchor: {
				name: "center",
			},
			// 当连接到节点时，通过 targetAnchor 来指定目标节点的锚点。
			targetAnchor: "orth",
			// 指定连接点，默认值为 boundary。
			connectionPoint: "anchor",
			// 连接器将起点、路由返回的点、终点加工为 元素的 d 属性，决定了边渲染到画布后的样式，默认值为 normal。
			connector: {
				name: "normal", // rounded
				args: {
					radius: 20,
				},
			},
			// 路由将边的路径点 vertices 做进一步转换处理，并在必要时添加额外的点，然后返回处理后的点，默认值为 normal。
			// router: "manhattan",
		},
	});

	// 注册画布监听器
	// registerGraphListener(graph, _this)
	// 注入触发器
	trigger(graph, _this);
	// 缓存实例化graph引用
	linkedGraph(graph)
	graph.zoom(0.1)
	graph.centerContent()
	return graph
}
