<template>
    <div class="select_tree">
        <!--伪下拉框-->
        <div class="tree_select" @click="showFlag = !showFlag;isReverse = !isReverse">
            <el-input size="small" :class="readonly ? 'pointer' : ''" :placeholder="placeholder" v-model="treeNodeName" :readonly="readonly" />
            <i class="el-icon-arrow-up" :class="isReverse ? 'is-reverse' : ''"></i>
        </div>
        <!--el树形控件--树  -->
        <div v-show="showFlag" class="treeContainer">
            <el-tree ref="selectTree" :props="defaultProps" :data="treeData" :show-checkbox="showCheckboxFlag" :default-expand-all="expandAllFlag" :check-strictly="strictlyFlag" :current-node-key="currentNode" node-key="id" :check-on-click-node="true" :expand-on-click-node="false" highlight-current :filter-node-method="filterNode" @node-click="handleNodeClick">
                <span class="custom-tree-node" slot-scope="{ node }">
                    <span :class="node.disabled == true ? 'not_allowed' : ''">{{ node.label }}</span>
                </span>
            </el-tree>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tree',
    props: {
        placeholder: {
            type: String,
            default: () => {
                return '请选择'
            }
        },
        readonly: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        defaultProps: {
            type: Object,
            default: () => {
                return {
                    children: 'children',
                    label: 'name',
                    id: 'id',
                    parentId: 'parentId',
                    disabled: 'disabled'
                }
            }
        },
        treeData: {
            type: [Array, Object],
            require: true,
            default: () => {
                return []
            }
        },
        expandAllFlag: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        showCheckboxFlag: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        strictlyFlag: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        currentNode: {
            type: [String, Number],
            default: () => {
                return ''
            }
        },
        currentNodeName: {
            type: [String, Number],
            default: () => {
                return ''
            }
        }
    },
    data () {
        return {
            treeNodeName: '',
            showFlag: false,
            isReverse: false,
        }
    },
    watch: {
        // currentNode: {
        //     handler (newVal) {
        //         if (newVal) {
        //             // this.$nextTick(() => {
        //             //     let node = this.$refs.selectTree.getCurrentNode()
        //             //     this.treeNodeName = node.name
        //             // })
        //         } else {
        //             this.treeNodeName = ''
        //         }
        //     },
        //     immediate: true
        // },
        currentNodeName: {
            handler (newVal) {
                console.log("treeNodeName",newVal);
                this.treeNodeName = newVal
            },
            immediate: true,
            deep: true
        },
    },
    methods: {
        // cleanData () {
        //     this.treeNodeName = ''
        // },
        filterNode (value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 点击树的分支
        handleNodeClick (data) {
            if (!data.disabled) {
                this.treeNodeName = data.name
                this.$emit('nodeClick', data)
                this.showFlag = false
                this.isReverse = false
            }
        },
        // recursion (val) {
        //     let str = ''
        //     console.log()
        //     this.treeData.forEach(item => {
        //         if (item.children && item.children.length > 0) {
        //             this.recursion(item.children)
        //         } else {
        //             console.log(item.id)
        //             if (item.id == val) {
        //                 str = item.name
        //             }
        //         }
        //     })
        //     this.treeNodeName = str
        // },
        }
}
</script>

<style lang="less" scoped>
.select_tree {
    position: relative;
    .tree_select {
        position: relative;
        .pointer/deep/.el-input__inner {
            cursor: pointer;
        }
        /deep/.el-icon-arrow-up {
            position: absolute;
            bottom: 1.2rem;
            right: 1rem;
            color: #c0c4cc;
            font-size: 14px;
            transition: transform 0.3s;
            transform: rotateZ(180deg);
            cursor: pointer;
            &.is-reverse {
                transform: rotateZ(0deg);
            }
        }
    }
    .treeContainer {
        // position: absolute;
        // top: 8rem;
        // left: 0;
        width: 100%;
        z-index: 3;
        /deep/.el-tree {
            max-height: 31.4rem;
            color: rgba(255, 255, 255, 0.8);
            font-size: 1.4rem;
            background-color: #0c375e;
            border: 0.1rem solid #004579;
            border-radius: 0.4rem;
            overflow-y: auto;
            .el-tree-node__content:hover {
                background-color: #004579;
            }
            .el-tree-node:focus > .el-tree-node__content {
                background-color: #004579;
            }
            .custom-tree-node {
                .not_allowed {
                    color: #606266;
                    cursor: not-allowed;
                }
            }
        }
        /deep/.el-tree--highlight-current
            .el-tree-node.is-current
            > .el-tree-node__content {
            background-color: #004579;
        }
    }
}
</style>
