<template>
  <div class="CustomerPage">
    <el-dialog 
    @close="closeDialog('ruleForm')"
    :visible.sync="dialogFormVisible" width="60%" height='80%' :close-on-click-modal="false">
      <div slot="title">
        <div class="equip_title">{{ getType == 2 ? '登记' : '编辑'}}</div>
      </div>
      <div style="max-height:65rem;overflow-y:auto;">
        <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
          <el-form-item label="编号" prop="code">
            <el-input v-model="form.code" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="单位名称" prop="name" >
            <el-input v-model="form.name" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          
          <!-- <el-form-item label="选择城市" prop="parentId" v-show="ifCitySelect" >
            <el-select v-model="form.parentId" placeholder="请选择所属城市" style="width: 98%" size="small">
              <el-option v-for="(item,index) in cityList" :key="index" :label="item.label" :value="item.value" ></el-option>
            </el-select>
          </el-form-item> -->
          <!-- v-show="ifCitySelect===false" -->
          <el-form-item label="上级单位" prop="parentId">
            <el-cascader
              v-model="form.parentId"
              :show-all-levels="false"
              :options="unitListTree"
              :props="unitProps"
              style="width: 98%;"
            ></el-cascader>
            <!-- <el-input disabled v-model="parentNamePlaceHoder" class="disabled_input" size="small" clearable></el-input> -->
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-select v-model="form.industry" :placeholder="form.industryName" style="width: 98%" size="small">
              <el-option label="学校行业" value="1"></el-option>
              <el-option label="轨道交通" value="2"></el-option>
              <el-option label="医疗行业" value="3"></el-option>
              <el-option label="工业行业" value="4"></el-option>
              <el-option label="公共建筑" value="5"></el-option>
              <el-option label="地产行业" value="6"></el-option>  
            </el-select>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="form.address" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="客户类型" prop="type">
            <el-select v-model="form.type" :placeholder="form.industryName" style="width: 98%" size="small">
              <el-option label="配电类" value="1"></el-option>
              <el-option label="发电类" value="2"></el-option>
              <el-option label="配电类和发电类" value="0"></el-option>
            </el-select>
          </el-form-item>
           <!-- <el-form-item label="充电客户令牌" prop="chargeToken" >
            <el-input v-model="form.chargeToken" placeholder="请输入" size="small" style="width: 98%" clearable></el-input>
          </el-form-item> -->

          <el-form-item label="充电绑定客户" prop="chargeToken"  >
            <el-select v-model="form.chargeToken" placeholder="请选择驴充充客户" style="width: 98%" size="small">
              <el-option v-for="(item,index) in tokenList" :key="index" :label="item.name" :value="item.estateId" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="x坐标" prop="xaxis" >
            <el-input v-model="form.xaxis" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="y坐标" prop="yaxis" >
            <el-input v-model="form.yaxis" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="是否有充电功能" prop="batteryCharge" >
            <el-select v-model="form.batteryCharge" style="width: 98%" size="small">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传图片" prop="picture" >
            <el-upload
              class="picture-uploader"
              :headers="myToken"
              :action="baseURL+'file/upload'"
              :show-file-list="false"
              :on-success="upLoadSuccess"
              :on-error="upLoadError"
              list-type="picture-card"
            >
              <div v-if="picture" class="picture-container" @mouseenter="maskVisible = true" @mouseleave="maskVisible = false">
                <img :src="picture">
                <div v-show="maskVisible" class="picture-mask">
                  <!-- 预览大图 -->
                  <span @click.stop="handlePictureCardPreview">预览</span>
                  <!-- 删除图片 -->
                  <span @click.stop="handleRemove">删除</span>
                </div>
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item></el-form-item>
          <div class="contact_title">联系人信息</div>
          <el-form-item label="项目负责人" prop="manager">
            <el-input v-model="form.manager" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="项目负责人电话" prop="managerPhone">
            <el-input v-model="form.managerPhone" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="紧急联系人" prop="urgentContact">
            <el-input v-model="form.urgentContact" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="紧急联系人电话" prop="urgentContactPhone">
            <el-input v-model="form.urgentContactPhone" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="业主联系人" prop="owner">
            <el-input v-model="form.owner" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="业主联系人电话" prop="ownerPhone">
            <el-input v-model="form.ownerPhone" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 下属配电房######################################################################### -->
      <el-form v-if="form.type==='1'||form.type==='0'">
         <!-- 列表############################################################################## -->
         <div class="contact_title">下属配电房</div>
          <el-form-item  style="width: 100%;" class="no_line_height">
            <el-table :data="form.places" style="width: 100%;background:#0C375E;"
              :header-cell-style="headerClass"
              :cell-style="cellStyle" ref="tb">
              <el-table-column prop="code" label="编号" width="120">
                <template slot-scope="scope">
                  <el-input placeholder="请输入" v-model="form.places[scope.$index].code" size="small" clearable></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="名称" width="200">
                <template slot-scope="scope">
                  <el-input placeholder="请输入" v-model="form.places[scope.$index].name" size="small" clearable></el-input>
                </template>
              </el-table-column>

              <el-table-column label="配电室类型" prop="type" width="200">
                <template slot-scope="scope">
                    <el-select v-model="form.places[scope.$index].type" placeholder="请选择" style="width: 100%" size="small">
                      <el-option v-for="(item,index) in switchRoomTypeDict" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                 </template>
             </el-table-column>

              <el-table-column prop="mix" label="监测场景" >
                <template slot-scope="scope">
                   <el-checkbox v-model="form.places[scope.$index].mix[0]" @change="checked=>checkRow(checked,scope.$index,0)">10kV系统图</el-checkbox>
                   <el-checkbox v-model="form.places[scope.$index].mix[1]" @change="checked=>checkRow(checked,scope.$index,1)">400V系统图</el-checkbox>
                   <el-checkbox v-model="form.places[scope.$index].mix[2]" @change="checked=>checkRow(checked,scope.$index,2)">直流屏</el-checkbox>
                   <el-checkbox v-model="form.places[scope.$index].mix[3]" @change="checked=>checkRow(checked,scope.$index,3)">变压器</el-checkbox>
                </template>
              </el-table-column>
              <!-- 操作部分-->
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button  
                    size="mini"
                    type="text"
                    @click="handleDelete(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <div class="btn_add" @click="addRow()">
            ➕ 添加一行数据
          </div>
      </el-form>
      <!-- 下属电站 ############################################################################-->
      <el-form v-if="form.type==='2'||form.type==='0'">
         <div class="contact_title">下属电站</div>
          <el-form-item  style="width: 100%;" class="no_line_height">
            <el-table :data="form.powerStations" style="width: 100%;background:#0C375E;"
              :header-cell-style="headerClass"
              :cell-style="cellStyle" ref="tb">
              <el-table-column prop="code" label="编号" width="110">
                <template slot-scope="scope">
                  <el-input placeholder="请输入" v-model="form.powerStations[scope.$index].code" size="small" clearable></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="名称" width="160">
                <template slot-scope="scope">
                  <el-input placeholder="请输入" v-model="form.powerStations[scope.$index].name" size="small" clearable></el-input>
                </template>
              </el-table-column>

            
          <el-table-column label="并网时间" prop="enterDate" width="155">
             <template slot-scope="scope">
              <el-date-picker
                value-format="yyyy-MM-dd" 
                size="small"
                type="date"
                placeholder="选择日期"
                v-model="form.powerStations[scope.$index].enterDate"
                style="width: 100%"
              ></el-date-picker>
               </template>
          </el-table-column>


          <el-table-column prop="capacity" label="组串容量(kW)" width="135">
                <template slot-scope="scope">
                  <el-input placeholder="请输入" v-model="form.powerStations[scope.$index].capacity" size="small" clearable></el-input>
                </template>
          </el-table-column>


           <el-table-column label="安全运行开始时间" prop="safeStartDate" width="155">
             <template slot-scope="scope">
              <el-date-picker
                value-format="yyyy-MM-dd" 
                size="small"
                type="date"
                placeholder="选择日期"
                v-model="form.powerStations[scope.$index].safeStartDate"
                style="width: 100%"
              ></el-date-picker>
              </template>
          </el-table-column>


          <el-table-column prop="address" label="电站地址" >
                <template slot-scope="scope">
                  <el-input placeholder="请输入" v-model="form.powerStations[scope.$index].address" size="small" clearable></el-input>
                </template>
          </el-table-column>

          <el-table-column label="是否储能" prop="isBattery" width="110">
                <template slot-scope="scope">
                  <el-select v-model="form.powerStations[scope.$index].isBattery" placeholder="请选择" style="width: 100%" size="small">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                  </el-select>
                 </template>
          </el-table-column>



            
              <!-- 操作部分-->
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button  
                    size="mini"
                    type="text"
                    @click="handleDelete2(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <div class="btn_add" @click="addRow2()">
            ➕ 添加一行数据
          </div>
      </el-form>
  
      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
      </div>
    </el-dialog>

    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogForPictureVisible">
      <img width="100%" :src="picture" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { getCookie } from "@/utils/util.js"
import config from '@/config/index.js'
import { tableBg } from '@/mixin/tablebg'
import { validateInput, validateSelect,validateNumber } from "@/utils/validate"
export default {
    name:"DialogCustomer",
    mixins: [tableBg],
     data() {
      return {
        // 配电房类型字典
        switchRoomTypeDict:[],
        // 打开表格是否有市级选项
        ifCitySelect:'',
        // 城市字典
        cityList:[],
        // 驴充充客户列表
        tokenList:[],
        // 数据请求的类型（编辑=1、新登记=2）
        getType:0,
        //表单中placehoder的数据
        parentNamePlaceHoder:'',
        // 当前树的信息
        treeFullNodeData:{},
        // 展示判断
        dialogFormVisible: false,
        // 上级单位下拉
        unitListTree: [],
        unitProps: {
          label: 'name',
          value: 'id',
          checkStrictly: true,
          
        },
        // 表单数据存放处
        form:{
          address: '',
          code:'',
          industry:'',
          industryName:'请选择',
          manager:'',
          managerPhone:'',
          name:'',
          parentId: '',
          urgentContact:'',
          urgentContactPhone:'',
          owner:'',
          ownerPhone:'',
          type:'',
          powerStations:[],
          places:[],
          chargeToken:"",
          xaxis:'',
          yaxis:'',
          batteryCharge: true,
          picture: ''
        },
        rules: {
          code: [validateInput],
          name: [validateInput],
          industry: [validateSelect],
          address: [validateInput],
          manager: [validateInput],
          managerPhone: [validateInput],
          urgentContact: [validateInput],
          urgentContactPhone: [validateInput],
          owner: [validateInput],
          ownerPhone: [validateInput],
          xaxis: [validateInput],
          yaxis: [validateInput],
          type:[validateSelect],
          capacity:[validateNumber],
        },
        baseURL: '',
        myToken: { Authorization: "没有拿到token" },
        picture: '',
        maskVisible: false,
        dialogForPictureVisible: false,
      };
    },
    mounted() {
      this.baseURL = config.baseURL
      this.myToken={Authorization: "Bearer "+getCookie("token")}
    },
    methods: {
        // 复选框点击
        checkRow(checked,index,num){
         if(checked){
           this.form.places[index].mix[num] = true
         }else{
           this.form.places[index].mix[num] = false
         }

        },
         // 添加一行数据
        addRow() {
          const obj = {code:'' ,name: '', mix: [false,false,false,false] }
          this.form.places.push(obj)
        },
        // 添加一行数据
        addRow2() {
          const obj = {address: "",capacity: "",code: "",customerId: 0,enterDate: "",id:null,name: "",safeStartDate: "",isBattery:"" }
          this.form.powerStations.push(obj)
        },
        // 删除下属配电房的数据
        handleDelete(index) {
          this.form.places.splice(index, 1)
        },
         // 删除下属电站的数据
        handleDelete2(index) {
          this.form.powerStations.splice(index, 1)
        },
        // 关闭dialog的回调，el自带的
        closeDialog(ruleForm){
         this.formInit()
         this.$refs[ruleForm].resetFields()
        }, 
         // from的归零
        formInit(){
          this.form ={
          address: '',
          code:'',
          industry:'',
          industryName:'请选择',
          manager:'',
          managerPhone:'',
          name:'',
          parentId: "",
          urgentContact:'',
          urgentContactPhone:'',
          owner:'',
          ownerPhone:'',
          type:'',
          places:[],
          powerStations:[],
        }
        },

        //父组件点击触发，显示弹窗、给当前节点数据，修改form表单中的客户单位ID
        show(ifFather,val){
            this.form = this.$options.data().form
            this.picture = ''
            // 获取驴充充绑定客户列表
            this.$store.dispatch('getElecChargeStationList').then(()=>{
               this.tokenList = this.stationList
            })

            // 获取上级单位下拉
            this.$store.dispatch('getUnitListTree').then(()=>{
               this.unitListTree = this.unitList
            })
             

            // 获取下属配电房类型的字典
            this.$store.dispatch('getCustomerSwitchRoomType').then(()=>{
              this.switchRoomTypeDict= []
              for(let i=0;i<this.switchRoomType.length;i++){                
                const roomTypeSingel = {label:"",value:""}
                roomTypeSingel.value = this.switchRoomType[i].dictKey
                roomTypeSingel.label = this.switchRoomType[i].dictValue
                this.switchRoomTypeDict.push(roomTypeSingel)
              }
            })

            if(ifFather){
              this.ifCitySelect = false
              this.dialogFormVisible = true
              this.form.parentId = [val.id]
              this.treeFullNodeData = val
              this.parentNamePlaceHoder = val.name
              this.getType = 2
            }else{
              this.getType = 2
              this.ifCitySelect = true
              this.dialogFormVisible = true
              this.$store.dispatch('getCustomerCityList').then(()=>{
                this.cityList = []
                for(let i=0; i<this.myGettingCityList.length;i++){
                  const singelCity = {label:'',value:''}
                  singelCity.label = this.myGettingCityList[i].name
                  singelCity.value = this.myGettingCityList[i].id
                  this.cityList.push(singelCity)
                }
                this.parentNamePlaceHoder = "请选择城市"
              })
            }
    
        },
        handleCancle(ruleName) {
          this.$refs[ruleName].resetFields()
          this.dialogFormVisible = false
          this.formInit()
        },
        // 父组件触发编辑
        showForEdit(val){
            this.form = this.$options.data().form
            this.picture = ''
          // 获取驴充充绑定客户列表
            this.$store.dispatch('getElecChargeStationList').then(()=>{
               this.tokenList = this.stationList

            })

            // 获取上级单位下拉
            this.$store.dispatch('getUnitListTree').then(()=>{
              this.unitListTree = this.unitList
            })
             
          // 获取下属配电房类型的字典
            this.$store.dispatch('getCustomerSwitchRoomType').then(()=>{
              this.switchRoomTypeDict= []
              for(let i=0;i<this.switchRoomType.length;i++){                
                const roomTypeSingel = {label:"",value:""}
                roomTypeSingel.value = this.switchRoomType[i].dictKey
                roomTypeSingel.label = this.switchRoomType[i].dictValue
                this.switchRoomTypeDict.push(roomTypeSingel)
              }
            })
          // 获取单个客户的数据
          this.$store.dispatch('getCustomerOwnData',val.id).then(()=>{
            const arrayForm = this.customerOwnData

            if(arrayForm.places){
              for(let i=0; i<arrayForm.places.length;i++){
                const arrayMix = [false,false,false,false]
                if(arrayForm.places[i].mix.indexOf('A')>-1){arrayMix[0]=true}
                if(arrayForm.places[i].mix.indexOf('B')>-1){arrayMix[1]=true}
                if(arrayForm.places[i].mix.indexOf('C')>-1){arrayMix[2]=true}
                if(arrayForm.places[i].mix.indexOf('D')>-1){arrayMix[3]=true}
                arrayForm.places[i].mix = arrayMix
              }
            }
          this.dialogFormVisible = true
          this.form = arrayForm
          this.form.id = arrayForm.id
          this.picture = arrayForm.picture
          this.form.parentId = val.parentId
          this.parentNamePlaceHoder = arrayForm.parentName
          this.getType = 1
          this.form.chargeToken = this.form.chargeToken ? parseInt(this.form.chargeToken) : ''
          })
          
        },
        // 提交发送请求（点击《确定》）
        postDialogData(ruleName){
          this.$refs[ruleName].validate(valid => {
            if (valid) {
              // 发送登记请求
              this.form.parentId = typeof this.form.parentId == 'object' ? this.form.parentId[this.form.parentId.length - 1] : this.form.parentId
              if(this.getType===2){
                // 修改mix从数组变成字符串
                for(let i=0; i<this.form.places.length;i++){
                  let stringMix = ""
                  if(this.form.places[i].mix[0]===true){stringMix+='A'}
                  if(this.form.places[i].mix[1]===true){stringMix+='B'}
                  if(this.form.places[i].mix[2]===true){stringMix+='C'}
                  if(this.form.places[i].mix[3]===true){stringMix+='D'}
                  this.form.places[i].mix = stringMix                 
                }
                this.$store.dispatch('postCustomerDialogData',this.form).then(()=>{
              if(this.ifSuccess===0){
                this.$message({message: '添加成功！',type: 'success'});
                this.$refs[ruleName].resetFields()
                this.dialogFormVisible = false;
                this.$parent.$refs.treeRef.refreshTheTree()
                this.$parent.refreshThePage()
              }else if(this.ifSuccess===-1){
                // 重置监测场景
                for(let i=0; i<this.form.places.length;i++){
                  const arrayMix = [false,false,false,false]
                  if(this.form.places[i].mix.indexOf('A')>-1){arrayMix[0]=true}
                  if(this.form.places[i].mix.indexOf('B')>-1){arrayMix[1]=true}
                  if(this.form.places[i].mix.indexOf('C')>-1){arrayMix[2]=true}
                  if(this.form.places[i].mix.indexOf('D')>-1){arrayMix[3]=true}
                  this.form.places[i].mix = arrayMix
                }
                this.$message({message:this.ifError,type: 'warning'});
                }
              })           
              }
              else if(this.getType===1){
                for(let i=0; i<this.form.places.length;i++){
                  let stringMix = ""
                  if(this.form.places[i].mix[0]===true){stringMix+='A'}
                  if(this.form.places[i].mix[1]===true){stringMix+='B'}
                  if(this.form.places[i].mix[2]===true){stringMix+='C'}
                  if(this.form.places[i].mix[3]===true){stringMix+='D'}
                  this.form.places[i].mix = stringMix                 
                }
                //发送编辑请求
                console.log("this.form", this.form)
                this.$store.dispatch('putCustomerDialogDataEdit',this.form).then(()=>{
                  if(this.ifSuccessEdit===0){
                    this.$message({message: '编辑成功！',type: 'success'});
                    this.$refs[ruleName].resetFields()
                    this.dialogFormVisible = false;
                    this.$parent.$refs.treeRef.refreshTheTree()
                    this.$parent.refreshThePage()
                  }else if(this.ifSuccessEdit===-1){
                    // 重置监测场景
                    for(let i=0; i<this.form.places.length;i++){
                      const arrayMix = [false,false,false,false]
                      if(this.form.places[i].mix.indexOf('A')>-1){arrayMix[0]=true}
                      if(this.form.places[i].mix.indexOf('B')>-1){arrayMix[1]=true}
                      if(this.form.places[i].mix.indexOf('C')>-1){arrayMix[2]=true}
                      if(this.form.places[i].mix.indexOf('D')>-1){arrayMix[3]=true}
                      this.form.places[i].mix = arrayMix
                    }
                    this.$message({message:this.ifErrorEdit,type: 'warning'});
                    }
                  })
              }

              }else {
              this.$message.error('有必填项未填')
            }
          })
         
       },

      // 上传图片成功
      upLoadSuccess(val, file){
        this.$message.success("上传图片成功！")
        this.picture = file.url
        this.form.picture = val.data
      },

      // 文件传输失败
      upLoadError(val){
        this.$message.error(val.message)
      },

      // 删除图片
      handleRemove() {
        this.form.picture = ''
        this.picture = ''
      },
      // 预览图片
      handlePictureCardPreview() {
        this.dialogForPictureVisible = true;
      },

    
         
        
    },

    //处理state数据
    computed:{
    ...mapState({
      ifSuccess:(state)=>{return state.customer.ifSuccess.errorCode},
      ifError:(state)=>{return state.customer.ifSuccess.message},
      ifSuccessEdit:(state)=>{return state.customer.ifSuccessEdit.errorCode},
      ifErrorEdit:(state)=>{return state.customer.ifSuccessEdit.message},
      customerOwnData:(state)=>{return state.customer.customerOwnData.data},
      myGettingCityList:(state)=>{return state.customer.myGettingCityList.data},
      switchRoomType:(state)=>{return state.customer.switchRoomType.data},
      stationList:(state)=>{return state.elecCharge.stationList.data},
      unitList:(state)=>{return state.elecCharge.unitList.data},
    })
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: #37A5DD;
  font-size: 1.6rem;
  width: 100%;
  margin-bottom: 1rem;
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2D79BE;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
</style>

<style lang="less">
// 套壳！谨防代码污染！
.CustomerPage{
    .no_line_height {
        color: #37A5DD;
        font-size: 1.6rem;
        .el-form-item__content {
          line-height: unset;
        }
  }
// abb7c2
.el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem; 
}
.el-checkbox{
 color: #abb7c2;
}
.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #027969;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
}

}

.picture-uploader {
  margin-top: 40px;
  .el-upload--picture-card {
    position: relative;
    color: rgba(255, 255, 255, 0.8);
    background-color: #0c375e;
    border: 0.1rem solid #004579;
    .picture-container {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .picture-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 8;
        span:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}


</style>