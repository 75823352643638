<template>
  <div class="computed1">
    <div class="comLeft">
      <div class="word1">电价:</div>
      <div class="inputNumber">
        <el-input-number
          v-model="dataForm.SINGLEPOWERPRICE"
          size="small"
          :precision="2"
          :step="0.01"
          controls-position="right"
          @change="changeElecPriceSingel1"
          :min="0"
          :max="10"
        ></el-input-number>
        <span>元/kWh</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  name: "innerArea1",
  data() {
    return {
      dataForm: {
        // 单一电价双向绑定(记得改为后台请求获得)
        enerId: "",
        SINGLEPOWERPRICE: "0.00",
        elecPriceSingel2: "0.02",
        elecPriceSingel3: "0.22",
        measure1: "",
        measure2: "",
        measure3: "",
      },
      elecPiceList: [],
      // 防抖预设值
      timeout1: null,
      timeout2: null,
      timeout3: null,
    };
  },
  props: ["radioValue"],
  mounted() {
    // this.getEnergyNum();
  },
  methods: {
    getEnergyNum() {
      this.$store.dispatch("getGeneratorSetting", {
          generator: "ENERGYGENERATOR",
          customerId: this.$store.state.storage.customerId,
        }).then(() => {
        this.elecPiceList = this.getGeneratorSetting;
        this.dataForm.SINGLEPOWERPRICE = this.findInfo('SINGLEPOWERPRICE').value;
      });
    },
    // 更改计数器内部的值
    changeElecPriceSingel1() {
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      let value = {
        value: this.dataForm.SINGLEPOWERPRICE,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.radioValue,
      };
      this.timeout1 = setTimeout(() => {
        this.$store
          .dispatch("putGenerator", {
            id: this.findInfo('SINGLEPOWERPRICE').id,
            data: value,
          })
          .then(() => {
            if (this.putGenerator) {
              this.$message({ message: "编辑成功！", type: "success" });
            } else {
              this.$message({ message: "修改失败，请重试！", type: "warning" });
            }
          });
        this.$store
          .dispatch("putGeneral", {
            id: id1,
            data: value1,
          })
          .then(() => {
            // console.log('保存radioValue');
          });
      }, 500);
    },
    //获取对应的信息
    findInfo(key){
      return this.getGeneratorSetting.find((item => item.name === key))
    },
  },
  computed: {
    ...mapState({
      getGeneratorSetting: (state) => {
        return state.genManagement.getGeneratorSetting.data;
      },
      putGenerator: (state) => {
        return state.genManagement.putGenerator.data;
      },
    }),
  },
  watch: {
    '$store.state.storage.customerId': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return
        }
        this.getEnergyNum();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.computed1 {
  .comLeft {
    .word1 {
      height: 2.2rem;
      width: 4.2rem;
      position: absolute;
      top: 2.9rem;
      left: 2.4rem;
      font-size: 1.4rem;
      color: #fff;
    }
    .inputNumber {
      position: absolute;
      top: 2.4rem;
      left: 7.4rem;
      span {
        position: relative;
        left: 1.5rem;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
  .comRigth {
    position: absolute;
    right: -50rem;
    top: -5.3rem;
    .con2 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .con-content {
      width: 39.9rem;
      height: 26rem;
      margin-top: 3rem;
      padding-top: 2rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      span {
        position: absolute;
        margin-left: 2rem;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
  .conBottomLeft {
    position: absolute;
    top: 32rem;
    .con3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .conputent {
      width: 31rem;
      height: 13.6rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      margin-top: 3rem;
      // position: relative;
      .con-img-inline {
        position: absolute;

        left: 50%;
        margin-left: -7rem;
        top: 8rem;
        .img1 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
        .img2 {
          width: 1.6rem;
          height: 1.5rem;
          margin-left: 1rem;
          position: relative;
          top: -0.8rem;
        }
        .img3 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
      }
      .con-ipt {
        position: absolute;
        top: 13rem;
        left: 3rem;
        .btn-inp {
          width: 13rem;
          // height: 3.2rem;
        }
        .ipt-left {
          font-size: 1.4rem;
          color: #ffffff;
          margin-right: 1.5rem;
        }
        .ipt-rigth {
          // position: absolute;
          // left: 23.5rem;
          // top: 0.5rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 1.5rem;
        }
      }
    }
  }
  .conBottomRigth {
    position: absolute;
    top: 32rem;
    left: 36rem;
    .con3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .conputent {
      width: 31rem;
      height: 13.6rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      margin-top: 3rem;
      // position: relative;
      .con-img-inline {
        position: absolute;

        left: 50%;
        margin-left: -7rem;
        top: 8rem;
        .img1 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
        .img2 {
          width: 1.6rem;
          height: 1.5rem;
          margin-left: 1rem;
          position: relative;
          top: -0.8rem;
        }
        .img3 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
      }
      .con-ipt {
        position: absolute;
        top: 13rem;
        left: 3rem;
        .btn-inp {
          width: 13rem;
          // height: 3.2rem;
        }
        .ipt-left {
          font-size: 1.4rem;
          color: #ffffff;
          margin-right: 1.5rem;
        }
        .ipt-rigth {
          // position: absolute;
          // left: 23.5rem;
          // top: 0.5rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 1.5rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.computed1 {
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .el-radio__label {
    font-size: 1.4rem;
    padding-left: 0.5rem;
  }
  .el-radio {
    margin-right: 1rem;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #03d9bc;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #03d9bc;
    background: #0d3050;
  }

  .el-radio__inner {
    background-color: #0d3050;
  }

  .el-radio__inner::after {
    background-color: #03d9bc;
  }

  // 计数器样式
  .el-input-number__increase {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease {
    background-color: #0b3d6b;
  }
  .el-input-number .is-controls-right {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #0b3d6b;
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid #027969;
    border-left: 0.1rem solid #027969;
    box-shadow: none;
    border: 0.1rem solid #027969;
    box-sizing: border-box;
    border: none;
  }
  ::-webkit-scrollbar,
  .el-select-dropdown::-webkit-scrollbar {
    width: 0;
  }
  .el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 0.4rem 0 0;
    border-bottom: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }

  .el-input-number.is-controls-right .el-input-number__decrease {
    border-left: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }
}
</style>
