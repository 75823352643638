import {Channel} from "../common/transmit";
import {CustomEventTypeEnum, SelectStateEnum} from "../common/enums";
import { setSvgAttrs } from '@/packages/x6/common/index'
import global from "@/global/global.js"; //全局变量

// 监听节点的鼠标移入和移出事件，然后通过样式来显示/隐藏连接桩，
function showPorts(ports, show) {
    for (let i = 0, len = ports.length; i < len; i = i + 1) {
        if (ports[i] && ports[i].style) {
            ports[i].style.visibility = show ? 'visible' : 'hidden'
        }
    }
}

const changePortsVisible = (node, visible) => {
    const ports = document.querySelectorAll(`g[data-cell-id="${node.id}"] .x6-port-body`);
    ports.forEach((port) => {
        port.style.visibility = visible ? 'visible' : 'hidden';
    });

};

function nodePositionInfoShow() {
    let tooltip1 = document.getElementById("tooltip1");
    tooltip1.style.display = "block";
}

function nodePositionInfoHide() {
    let tooltip1 = document.getElementById("tooltip1");
    tooltip1.style.display = "none";
}

function nodePositionInfo(node) {
    let selectorBox = document.getElementsByClassName("x6-widget-transform");

    let gObjectDom = document.querySelectorAll(`g[data-cell-id="${node.id}"]`);

    let tooltip1 = document.getElementById("tooltip1");

    const pos = node.position()
    // console.log(gObjectDom[0].getBoundingClientRect()) // getBBox

    if (selectorBox.length > 0) {
        nodePositionInfoShow();
        console.log(gObjectDom[0].getBoundingClientRect().x)
        console.log(gObjectDom[0].getBoundingClientRect().y)
        gObjectDom[0].getBoundingClientRect()
        let selectorBoxWidth = parseInt(gObjectDom[0].getBoundingClientRect().width);
        let selectorBoxHeight = parseInt(gObjectDom[0].getBoundingClientRect().height);
        // let selectorBoxLeft = parseInt(gObjectDom[0].getBoundingClientRect().x);
        // let selectorBoxTop = parseInt(gObjectDom[0].getBoundingClientRect().y);
        let selectorBoxLeft = gObjectDom[0].getBoundingClientRect().x;
        let selectorBoxTop = gObjectDom[0].getBoundingClientRect().y;

        // let selectorBoxWidth = parseInt(selectorBox[0].style.width);
        // let selectorBoxHeight = parseInt(selectorBox[0].style.height);
        // let selectorBoxLeft = parseInt(selectorBox[0].style.left);
        // let selectorBoxTop = parseInt(selectorBox[0].style.top);

        tooltip1.style.left = (selectorBoxLeft - 85 + selectorBoxWidth / 2) + 'px';
        tooltip1.style.top = (selectorBoxHeight + selectorBoxTop - 30) + 'px';
        tooltip1.querySelector("span").innerText = pos.x + ", " + pos.y;
    }
}

function nodeSvgAlign(node) {
    let nodeSize = node.size();
    let nodeWidth = nodeSize.width;
    let nodeHeight = nodeSize.height;
    let fitSize = nodeWidth;

    let attrs = {}
    if (node.store.data.data.actionType == "SVG_TAG") {
        attrs.height = nodeWidth
    } else {
        attrs.width = nodeWidth
        attrs.height = nodeHeight
    }
    setSvgAttrs(_this, node, attrs)
    if (node.store.data.data.actionType == "SVG_TAG" || node.store.data.data.actionType == "SVG_IMAGE" || node.store.data.data.actionType == "SVG_FONT" || node.store.data.data.actionType == "SVG_KEYVLAUE") {
        return
    }
    // if (node.store.data.data.actionType == "SVG_TAG") {
    //     let line = document.querySelectorAll(
    //             `g[data-cell-id="${node.id}"] line`
    //     );
    //     line[0].setAttribute("x2", nodeWidth)
    //     if (node.store.data.template == "1_dllxy") {
    //         let polygon = document.querySelectorAll(
    //                 `g[data-cell-id="${node.id}"] polygon`
    //         );
    //         polygon[0].setAttribute("points", (node.store.data.size.width - 4 - parseInt(node.attrs.prop["stroke-width"])) + "," + 17 + " " + node.store.data.size.width + "," + (19 + parseInt(node.attrs.prop["stroke-width"])) + " " + (node.store.data.size.width - 4 - parseInt(node.attrs.prop["stroke-width"])) + "," + (21 + 2 * parseInt(node.attrs.prop["stroke-width"])));
    //     }
    //     if (node.store.data.template == "6_1_dllxy") {
    //         let polygon = document.querySelectorAll(
    //                 `g[data-cell-id="${node.id}"] polygon`
    //         );
    //         polygon[0].setAttribute("points", node.store.data.size.width - 4 - parseInt(node.attrs.prop["stroke-width"]) + "," + 17 + " " + node.store.data.size.width + "," + (19 + parseInt(node.attrs.prop["stroke-width"])) + " " + (node.store.data.size.width - 4 - parseInt(node.attrs.prop["stroke-width"])) + "," + (21 + 2 * parseInt(node.attrs.prop["stroke-width"])));
    //         polygon[0].setAttribute("fill", node.attrs.prop["stroke"]);
    //         polygon[0].setAttribute("stroke", node.attrs.prop["stroke"]);
    //     }
    //     return;
    // }
    if (node.store.data.data.actionType == "SVG_IMAGE") {
        return;
    }
    if (node.store.data.data.actionType == "SVG_FONT") {
        let input = document.querySelectorAll(
                `g[data-cell-id="${node.id}"] input`
        );
        input[0].style.width = nodeWidth + "px";
        input[0].style.height = nodeHeight + "px";
        // if (nodeHeight != 30) {
        //     input[0].style.fontSize = nodeHeight / 2 + 'px'
        // }
        input[0].style.textAlign = 'left'
        return
    }
    if (node.store.data.data.actionType == "SVG_KEYVLAUE") {
        let span = document.querySelectorAll(
                `g[data-cell-id="${node.id}"] .jzsj`
        );
        span[0].style.width = nodeWidth + "px";
        // span[0].style.width = nodeWidth / 2 + "px";
        // span[0].style.height = nodeHeight + "px";
        // span[1].style.width = nodeWidth / 2 + "px";
        // span[1].style.height = nodeHeight + "px";
        return
    }

    let widgetProxyDom = document.getElementsByClassName("x6-widget-transform");
    console.log('widgetProxyDom', widgetProxyDom)
    if (widgetProxyDom.length > 0) {
        let proxyWidth = widgetProxyDom[0].getAttribute("width");
        let proxyHeight = widgetProxyDom[0].getAttribute("height");

        if (nodeWidth > proxyWidth) {
            nodeWidth = proxyWidth;
        }
        if (nodeHeight > proxyHeight) {
            nodeHeight = proxyHeight;
        }
        widgetProxyDom[0].style.width = nodeWidth + 'px'
        widgetProxyDom[0].style.height = nodeHeight + 'px'
    }

    // let rectDom = document.querySelectorAll(`g[data-cell-id="${node.id}"] rect`);
    // let rectWidth = parseInt(rectDom[0].getAttribute('width'));
    // let rectHeight = parseInt(rectDom[0].getAttribute('height'));

    // if (rectWidth > rectHeight) {
    //     fitSize = rectHeight;
    // } else if (rectWidth < rectHeight) {
    //     fitSize = rectWidth;
    // } else if (rectWidth == rectHeight) {
    //     fitSize = rectWidth;
    // }

    // console.log('fitSize ', fitSize, ' rectWidth ', rectWidth, ' rectHeight ', rectHeight)

    let foreignObjectDom = document.querySelectorAll(`g[data-cell-id="${node.id}"] foreignObject`);
    foreignObjectDom[0].setAttribute("width", fitSize);
    foreignObjectDom[0].setAttribute("height", fitSize);
    // rectDom[0].setAttribute("width", fitSize);
    // rectDom[0].setAttribute("height", fitSize);

    // if (rectWidth == rectHeight) {
    // 	// 水平居中
    // 	foreignObjectDom[0].setAttribute("x", 0);
    // 	foreignObjectDom[0].setAttribute("y", 0);
    // } else if (rectWidth > rectHeight) {
    // 	// 水平偏移
    // 	foreignObjectDom[0].setAttribute("x", Math.abs((rectWidth - rectHeight) / 2));
    // 	foreignObjectDom[0].setAttribute("y", 0);
    // } else if (rectWidth < rectHeight) {
    // 	// 垂直偏移
    // 	foreignObjectDom[0].setAttribute("x", 0);
    // 	foreignObjectDom[0].setAttribute("y", Math.abs((rectHeight - rectWidth) / 2));
    // }
}

let _this;
export default (graph, vue) => {
    _this = vue;

    // 编组
    const groupIds = _this.$store.getters.groupIds

    graph.on('cell:selected', ({cell}) => {
        graph.enableSnapline()
        let removeBtnCfg;
        console.log(global)
        global.enableDelete = true;
        global.enableCopy = true;
        global.enableConfig = true;
        global.enableColor = true;
        if (cell.isEdge()) {
            cell.addTools([
                // https://x6.antv.vision/zh/docs/api/registry/edge-tool#segments
                // 路径点工具，在路径点位置渲染一个小圆点，拖动小圆点修改路径点位置，双击小圆点删除路径点，在边上单击添加路径点。
                {name: 'vertices'},
                {name: 'circle-source-arrowhead'},
                {name: 'circle-target-arrowhead'},
                // 线段工具。在边的每条线段的中心渲染一个工具条，可以拖动工具条调整线段两端的路径点的位置。
                {
                    name: 'segments',
                    args: {
                        snapRadius: 20,
                        attrs: {
                            width: 20, height: 8,
                            fill: '#444',
                        },
                    },
                }], 'onhover')

            cell.attr('line', {stroke: 'skyblue', strokeWidth: 2});
            removeBtnCfg = {distance: '85%'};
            _this.selectType = 2;
            global.enableColor = false;
        }

        if (cell.isNode()) {
            const cellView = graph.findView(cell);
            cellView.addClass(`${cell.shape}-selected`);
            // 删除图标偏移量 { x: 0, y: 0, offset: { x: -5, y: -5 } }
            // https://x6.antv.vision/zh/docs/api/registry/node-tool#button-remove
            removeBtnCfg = {x: -28, y: -5}
            console.log('cell:selected')
            _this.selectType = 1;

        }
        Channel.dispatchEvent(CustomEventTypeEnum.CELL_CLICK, SelectStateEnum.SELECTED)
        _this.$refs.RightDrawer ? _this.$refs.RightDrawer.show = false : '';
        _this.$refs.EdgeRightDrawer ? _this.$refs.EdgeRightDrawer.show = false : '';
        // 多单选选中时，移除删除
        const cells = graph.getSelectedCells();
        if (cells.length > 1) {
            // 多选
            graph.disableSnapline()
            cells.forEach(currentCell => {
                currentCell.removeTools()
            });
            // global.enableConfig = false;
            if(cells.length>=2){
                global.enableAlign = true;
                if(cells.length>=3){
                    global.enableCenter = true;
                }

            }
            global.enableGroup = true;
            global.enableCancelGroup = true;
        } else {
            if (cell.isNode()) {
                global.CurrentTitle = cell.store.data.componentName
                global.CurrentType = cell.store.data.componentId
                if (cell.store.data.data.actionType == "SVG_HTML") {
                    graph.transform.options.resizing.preserveAspectRatio = true
                } else {
                    graph.transform.options.resizing.preserveAspectRatio = false
                }

            }
            // 单选
            // x6默认提供 button-remove, icon比较小, 交互体验不友好
            if (graph.getSelectedCells().length == 1) {
                cell.addTools({
                    name: 'button-remove', // 工具名称
                    args: removeBtnCfg // 工具对应的参数
                });
            }
            /*cell.addTools({
                name: ToolTypeEnum.REMOVE_BUTTON, // 工具名称
                args: removeBtnCfg // 工具对应的参数
            });*/

        }
    });

    graph.on('cell:dblclick', ({e, x, y, cell, view}) => {
        _this.configComponent();
    })

    graph.on('cell:unselected', ({cell}) => {
        _this.$refs.EdgeRightDrawer ? _this.$refs.EdgeRightDrawer.show = false : '';
        console.log(cell)
        // if (graph.getSelectedCells().length < 1) {
        //     _this.selectType = 0;
        //     _this.configComponent();
        // }
        if (cell.isEdge()) {
            if (!cell.store.data.attrs.props) {
                cell.store.data.attrs.props = {strokeWidth: 1, stroke: "#fff"}
            }else {
                cell.attr('line',
                    {
                        stroke: cell.store.data.attrs.props.stroke,
                        strokeWidth: cell.store.data.attrs.props.strokeWidth,
                        targetMarker: cell.attrs.line.targetMarker && cell.attrs.line.targetMarker.name ? {
                            size: cell.attrs.line.targetMarker.size,
                            name: cell.attrs.line.targetMarker.name
                        } : null
                    }
                );
            }
        } else {
            const cellView = graph.findView(cell);
            cellView && cellView.removeClass(`${cell.shape}-selected`);
        }
        cell.removeTools()
        global.enableDelete = false;
        global.enableCopy = false;
        global.enablePreview = false;
        global.enableConfig = false;
        global.enableAlign = false;
        global.enableCenter = false;
        global.enableGroup = false;
        global.enableCancelGroup = false;
        Channel.dispatchEvent(CustomEventTypeEnum.CELL_CLICK, SelectStateEnum.UN_SELECTED)
        // 取消 tooltip
        const tooltipDom = document.getElementById("tooltip-container")
        if (tooltipDom && tooltipDom.style) {
            tooltipDom.style.display = 'none'
        }
        let rootNodes = graph.getNodes();
        rootNodes.forEach(item => {
            changePortsVisible(item, false);
        })
    });

    // 监听节点的鼠标移入和移出事件，然后通过样式来显示/隐藏连接桩，
    graph.on('node:mouseenter', () => {
        const ports = graph.container.querySelectorAll('.x6-port-body')
        showPorts(ports, true)
    })

    graph.on('node:moving', ({e, x, y, node, view}) => {
        nodePositionInfo(node);
    })
    graph.on('node:mouseup', ({e, x, y, node, view}) => {
        console.log("放来");
        if (groupIds.length > 0) {
            let cells = []
            let cellsId = []
            groupIds.forEach(group => {
                group.forEach(cell => {
                    if(cell.includes(node.id)) {
                        cellsId = group
                    }
                })
            })
            cellsId.forEach(cell => {
                cells.push(graph.getCellById(cell))
            })
            if (cells.length > 1) {
                graph.cleanSelection()
                graph.select(cells)
            }
        }
        // if (node.store.data.data.actionType !== 'SVG_FONT') {
            nodeSvgAlign(node)
        // }

        nodePositionInfoHide()
        // _this.openPrompt();
    })
    graph.on('edge:mouseup', ({e, x, y, edge, view}) => {
        console.log("pathData", edge)
        // if (!edge.store.data.target.cell) {
        //     let target = edge.store.data.target;
        //     let paths = document.querySelectorAll(`g[data-cell-id="${edge.id}"] path`);
        //     let d = paths[0].getAttribute("d");
        //     let arr = d.split(' ');
        //     if (target.x == arr[arr.length - 5] && Math.abs(target.y - arr[arr.length - 4]) < 10) {
        //         edge.store.data.target.y = parseInt(arr[arr.length - 4]);
        //         let temp = '';
        //         for (let i = 0; i < 6; i++) {
        //             temp += arr[i] + " ";
        //         }
        //         paths[0].setAttribute("d", temp);
        //         paths[1].setAttribute("d", temp);
        //     }
        //     if (target.y == arr[arr.length - 4] && Math.abs(target.x - arr[arr.length - 5]) < 10) {
        //         edge.store.data.target.x = parseInt(arr[arr.length - 5])
        //         let temp = '';
        //         for (let i = 0; i < 6; i++) {
        //             temp += arr[i] + " ";
        //         }
        //         paths[0].setAttribute("d", temp);
        //         paths[1].setAttribute("d", temp);
        //     }
        //     if (Math.abs(target.x - arr[1]) < 10) {
        //         edge.store.data.target.x = parseInt(arr[1])
        //     }
        //     if (Math.abs(target.y - arr[2]) < 10) {
        //         edge.store.data.target.x = parseInt(arr[2])
        //     }
        // }

    })
    graph.on('node:resize', ({e, x, y, node, view}) => {
        console.log("改变大小")
        // if (node.store.data.data.actionType !== 'SVG_FONT') {
            nodeSvgAlign(node)
        // }
        _this.$refs.RightDrawer ? _this.$refs.RightDrawer.show = false : '';
        _this.$refs.EdgeRightDrawer ? _this.$refs.EdgeRightDrawer.show = false : '';

    })

    graph.on('node:resizing', ({e, x, y, node, view}) => {
        console.log("改变大小ing")
        // if (node.store.data.data.actionType !== 'SVG_FONT') {
            nodeSvgAlign(node)
        // }
        let ports = node.getPorts();
        const size = node.size()
        let index = 0;
        if (ports.length  == 3) {
            ports.forEach(item => {
                if (item.args && item.args.x) {
                    index ++;
                    if (node.store.data.template == '6_1_srzbyq1') {
                        let dx = index / 2 == 1 ? -0.1 : 0.1
                        node.setPortProp(item.id, 'args', { x: (size.width / 10) * ((index * 3) + (index - 1 + dx)), y: (size.height - 2.4) })
                    } else if (node.store.data.template == '6_1_srzbyq2') {
                        node.setPortProp(item.id, 'args', { x: (size.width / 10) * ((index * 3) + (index - 1)), y: (size.height - 2.4) })
                    } else if (node.store.data.template == '6_1_srzbyq3') {
                        node.setPortProp(item.id, 'args', { x: (size.width / 10) * ((index * 3) + (index - 1)), y: 2.4 })
                    }
                }
            })
        } else {
            ports.forEach(item => {
                if (item.args && item.args.x) {
                    index ++;
                    if (node.store.data.template == '6_1_sxdlhgq') {
                        let dy = index / 2 == 1 ? -4.7 : 4.7
                        console.log(dy)
                        node.setPortProp(item.id, 'args', { x: (size.width / 2), y: ((size.height / 10) * ((index * 3) + (index - 1 + dy)) ) })
                    }
                }
            })
        }
        _this.$refs.RightDrawer ? _this.$refs.RightDrawer.show = false : '';
        _this.$refs.EdgeRightDrawer ? _this.$refs.EdgeRightDrawer.show = false : '';
    })

    graph.on('node:resized', ({e, x, y, node, view}) => {
        // if (node.store.data.data.actionType !== 'SVG_FONT') {
            nodeSvgAlign(node)
        // }
    })

    graph.on('node:rotate', ({e, x, y, node, view}) => {
        console.log("旋转")
        _this.$refs.RightDrawer ? _this.$refs.RightDrawer.show = false : '';
        _this.$refs.EdgeRightDrawer ? _this.$refs.EdgeRightDrawer.show = false : '';
    })
    // graph.on('node:rotated', ({e, x, y, node, view}) => {
    //     console.log("旋转结束")
    //     _this.configComponent();
    //     // _this.$refs.RightDrawer ? _this.$refs.RightDrawer.show = false : '';
    //     // _this.$refs.EdgeRightDrawer ? _this.$refs.EdgeRightDrawer.show = false : '';
    // })
    graph.on('node:mousedown', ({e, x, y, node, view}) => {
        console.log("按下")
        // if (node.store.data.data.actionType !== 'SVG_FONT') {
        if (graph.getSelectedCells().length == 1) {
            nodeSvgAlign(node)
        }
        // }
        _this.$refs.RightDrawer ? _this.$refs.RightDrawer.show = false : '';
        _this.$refs.EdgeRightDrawer ? _this.$refs.EdgeRightDrawer.show = false : '';
    })

    graph.on('edge:moving', ({e, x, y, node, view}) => {
        console.log("移动")
        console.log(e)
        console.log(x)
        console.log(y)
        _this.$refs.RightDrawer ? _this.$refs.RightDrawer.show = false : '';
        _this.$refs.EdgeRightDrawer ? _this.$refs.EdgeRightDrawer.show = false : '';
    })

    graph.on('edge:mouseenter', ({cell}) => {
        // cell.addTools('vertices', 'onhover')
        cell.addTools([
            // https://x6.antv.vision/zh/docs/api/registry/edge-tool#segments
            // 路径点工具，在路径点位置渲染一个小圆点，拖动小圆点修改路径点位置，双击小圆点删除路径点，在边上单击添加路径点。
            // {name: 'vertices'},
            // 在边的起点或终点渲染一个图形(默认是箭头)，拖动该图形来修改边的起点或终点。
            // {name: 'source-arrowhead'},
            // {name: 'target-arrowhead'},
            // 线段工具。在边的每条线段的中心渲染一个工具条，可以拖动工具条调整线段两端的路径点的位置。
            {
                name: 'segments',
                args: {
                    snapRadius: 20,
                    attrs: {
                        width: 20, height: 8,
                        fill: '#444',
                    },
                },
            }], 'onhover')
    })

    graph.on('edge:mouseleave', ({cell}) => {
        if (cell.hasTools('onhover')) {
            cell.removeTools()
        }
    })

    // 双击进入线路编辑模式
    /*graph.on('edge:dblclick', ({ cell, e }) => {
        cell.addTools({
            name: 'edge-editor',
            args: {
                event: e,
            },
        })
    })*/

    graph.on('blank:mouseup', ({e, x, y}) => {
        console.log("空白")
        console.log(_this.lineStatus)
        if (graph.getSelectedCells().length < 1) {
            _this.selectType = 0;
            _this.configComponent();
            global.enableColor = false;
        }
        _this.$refs.LeftTemplate ? _this.$refs.LeftTemplate.isTemplate = false : '';
        _this.$refs.LeftToolBar ? _this.$refs.LeftToolBar.isLeftToolBar = true : '';
        if (_this.lineStatus) {
            const pos = graph.clientToLocal(e.clientX, e.clientY)
            addEdge(pos, _this.lineStatus)
            container.addEventListener('mousemove', onMouseMove)
            document.addEventListener('keydown', function (e) {
                if (e && e.keyCode == 27) {
                    if (_this.lineStatus) {
                        _this.lineStatus = false
                        _this.graph.removeEdge(edge);
                        container.removeEventListener('mousemove', onMouseMove)
                    }
                }
            })
        }
    })

    graph.on('edge:click', ({e}) => {
        _this.configComponent();
        const pos = graph.clientToLocal(e.clientX, e.clientY)
        if (edge) {
            const nodes = graph.getNodesFromPoint(pos.x, pos.y)
            if (nodes.length && nodes[0] === node) {
                finish(true)
            } else {
                addVertices(pos)
            }
        }
    })
    graph.on('edge:contextmenu', () => {
        finish(true)
    })
    let edge = null
    let node = null
    const addEdge = (pos, status) => {
        console.log("pos", pos)
        // console.log("node",node)
        if (1 == status) {
            edge = graph.addEdge({
                source: pos,
                target: pos,
                attrs: {
                    line: {
                        targetMarker: {
                            size: 6,
                            name: ''
                        },
                        stroke: '#000000',
                        strokeWidth: 1,
                        strokeDasharray: 0,
                    },
                    props: {
                        targetMarker: null,
                        stroke: '#000000',
                        strokeWidth: 1,
                        strokeDasharray: 0,
                    }
                },
                componentName: "线路"
            })
        } else if (2 == status) {
            edge = graph.addEdge({
                source: pos,
                target: pos,
                attrs: {
                    line: {
                        targetMarker: {
                            size: 6,
                            name: 'block'
                        },
                        connector: {
                            name: "normal", // rounded
                            args: {
                                radius: 20,
                            },
                        },
                        stroke: '#000000',
                        strokeWidth: 1,
                        strokeDasharray: 0,
                    },
                    props: {
                        targetMarker: {
                            size: 6,
                            name: 'block'
                        },
                        connector: {
                            name: "normal", // rounded
                            args: {
                                radius: 20,
                            },
                        },
                        stroke: '#000000',
                        strokeWidth: 1,
                        strokeDasharray: 0,
                    }
                },
                componentName: "电流流向"
            })
        }
        _this.drawing = edge;
    }

    const addVertices = (pos) => {
        if (edge) {
            edge.appendVertex(pos, false)
            graph.unselect(edge)
        }
    }

    const onMouseMove = (e) => {
        if (edge) {
            const pos = graph.clientToLocal(e.clientX, e.clientY)
            edge.setTarget(pos)
        }
    }

    const print = () => {
        if (edge) {
            const view = graph.findViewByCell(edge)
            console.log(view.path.serialize())
        }
    }

    const finish = (closed) => {
        // if (node && edge) {
        _this.lineStatus = false;
        const vertices = edge.getVertices()
        if (closed) {
            if (vertices.length >= 2) {
                // edge.setTarget(node.getBBox().center)
                graph.removeNode(node)
                node = null
                edge = null
                print()
            } else {
                graph.removeCells([node])
                node = null
                edge = null
            }
        } else {
            if (vertices.length >= 1) {
                edge.setTarget(vertices[vertices.length - 1])
                graph.removeNode(node)
                node = null
                print()
            } else {
                graph.removeCells([node, edge])
                node = null
                edge = null
            }
        }
        container.removeEventListener('mousemove', onMouseMove)
        // }
    }

}
