
<template>
  <div class="OrderSetting">
    <!-- 面包屑 -->
    <div class="bread">
      运维检修 / 工单设置 / 指派人
    </div>
    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div>
        <el-input
          size="small"
          placeholder="请输入指派人姓名"
          v-model="searchValue"
          @keyup.enter.native="search"
          style="width:22rem;"
          clearable
          @clear="search"
        ></el-input>
      
        <button class="btn_save" @click="search">查 询</button>
      </div>
      <button class="btn2" @click="ifDialogShow">新增指派人</button>
    </div>

    <!-- 中部表格位置############################################################################################################ -->
    <div class="userTablePlace">
        
          <div class="table">
              <el-table :data="tableData" stripe:false height="57.8rem" 
                        border:false style="width: 100%"
                        :header-cell-style="headerClass"                    
                        :cell-style="cellStyle">
                <!-- <el-table-column type="index" label="序号" min-width="15%"></el-table-column> -->
                <el-table-column  label="序号"  min-width="15%">
                  <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
                </el-table-column>
                <el-table-column class="tab" prop="name" label="指派人姓名" min-width="15%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="username" label="用户名" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="mobile" label="联系电话" min-width="15%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="positionName" label="职位" min-width="15%"  :show-overflow-tooltip="true"> </el-table-column>
               
                <!-- 操作部分 -->
                <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">移出</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页器部分 -->
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumForPagenation"
              >
              </el-pagination>
            </div>
    </div>

    <!-- 弹层组件################ -->
    <DialogForOrderSetting ref="DialogRef"/>
      
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import DialogForOrderSetting from "./OrderSetting/DialogForOrderSetting.vue"
export default {
  name: "PatrolPlan",
  mixins: [tableBg],
  components:{DialogForOrderSetting},
  data() {
    return {
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 双向绑定的搜索数据
      searchValue: "",  
      // 表格数据
      tableData: []
    };
  },

  methods: {
    // 刷新页面
  
    refreshThePage() {
      this.$store.dispatch("getRepairOrderSettingAssignPageData", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          keyword: this.searchValue
        }).then(() => {
         
          this.tableData = this.repairOrderSettingAssignPageData;
          this.totalNumForPagenation = this.repairOrderSettingAssignTotal;
          // 遍历data做巡检类型的字典对接
          // for(let i = 0;i<this.tableData.length;i++){
          //   this.tableData[i].holidaysAndFestivalsLabel = this.patrolTypeDict[this.tableData[i].holidaysAndFestivals]
          //   this.tableData[i].intervalTypeLabel = this.intervalTypeDict[this.tableData[i].intervalType]
          // }
          
        });
    },
    
    // 切换elSwitch
    changeSwitch(val){
      const switchData = {id:val.id,status:val.status}
      this.$store.dispatch('getOrderSettingSwitchStatus',switchData).then(()=>{
        if(this.ifSwitchSuccess===0){
          this.$message.success('修改状态成功')
        }else{
          this.$message.error(this.ifSwitchError)
        }
      })
    },


    // 点击搜索或者回车
    search() {
      this.currentPage = "1";
      this.refreshThePage();
    },
    //点击新增
    ifDialogShow(){

      this.$refs.DialogRef.show();
    },
    //点击了编辑按钮
    handleEdit(index) {
      this.$refs.dialogRef.showForEdit(this.userPageData[index]);
    },
    // 点击了删除按钮
    handleDelete(index) {
      this.$confirm("是否要将此人移出指派人队列？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
      
        this.$store.dispatch("deleteRepairOrderSettingAssignPerson", this.repairOrderSettingAssignPageData[index].id).then(() => {
            if (this.ifDeleteRepairOrderSettingAssignPersonSuccess === 0) {
              this.$message({ message: "删除成功！", type: "success" });
              this.refreshThePage();
            } else{
              this.$message({
                message: this.ifDeleteRepairOrderSettingAssignPersonError,
                type: "warning"
              });
            }
          });
      });
    },
   
    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = "1";
      this.pageSize = val;
      this.refreshThePage();
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val;
      this.refreshThePage();
    },
   
  },

  mounted() {
    this.refreshThePage();
  },

  computed: {
    ...mapState({
      // 分页数据
      repairOrderSettingAssignPageData: state => { return state.repair.repairOrderSettingAssignPageData.data.records;},
      // 页面总数
      repairOrderSettingAssignTotal: state => { return state.repair.repairOrderSettingAssignPageData.data.total;},
      // 删除巡检计划是否成功
      ifDeleteRepairOrderSettingAssignPersonSuccess:state => { return state.repair.ifDeleteRepairOrderSettingAssignPersonSuccess.errorCode},
      ifDeleteRepairOrderSettingAssignPersonError:state => { return state.repair.ifDeleteRepairOrderSettingAssignPersonSuccess.message},
   
    })
  }
};
</script>

<style lang="less" scoped>
// 面包屑
.bread {
    height: 5rem;
    width: 142.8rem;
    line-height: 5rem;
    color: #fff;
    font-size: 1.4rem;
    position: absolute;
    top: -2rem;
    left: 0;
  }
// 顶部样式##################################################################

.topBar {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  box-sizing: border-box;
  height: 6.4rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  top: 3rem;
  left: 0;
  overflow: hidden;
  .searchInput {
    height: 3.2rem;
    width: 22.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
    background-color: #0c375e;
    border: 1px solid #004579;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    text-indent: 1.4rem;
    &:focus {
      border: 0.1rem solid #008080;
    }
  }

  button {
    height: 3.2rem;
    width: 7.4rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .btn1 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 26rem;
  }
  .btn2 {
    background-color: #027969;
    position: absolute;
    top: 1.7rem;
    left: 158.7rem;
    width: 10.2rem;
  }
  .btn_save{
    margin-left: 1.5rem;
    
  }
  .businessType {
    height: 3.2rem;
    width: 20.8rem;
    position: absolute;
    left: 36rem;
    top: 2.4rem;
  }
  .businessSelector {
    margin: 0 2rem;
  }
}

// 中部表格样式##################################################################
  



.userTablePlace {
  height: 86.4rem;
  width: 171.2rem;
  position: absolute;
  top: 10.4rem;
  left: 0;
  overflow: hidden;
  background-color: #0d3050;
  .tabSwitcher{
    height: 4rem;
    border-bottom: 0.2rem solid rgba(7, 82, 150, 0.25);
    margin: 0 2.4rem 2.4rem 2.4rem;
    .btnGreen{
    display: inline-block;
    height: 4rem;
    font-size: 1.4rem;
    color: #03d9bc;
    border-bottom: 0.2rem solid #03d9bc;
    line-height: 5rem;  
    margin-right: 2.4rem;
    cursor: pointer;
  }
  .btnGrey{
    display: inline-block;
    height: 4rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    border-bottom: none;
    line-height: 5rem;  
    margin-right: 2.4rem;
    cursor: pointer;
  }
  }
  .table {
    height: 57.2rem;
    width: 166.4rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
</style>





<style lang='less'>
// 公共样式！谨防代码污染！#########################################
.OrderSetting{
  .table {
    height: 57.2rem;
    width: 148rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }

  // switch栏的样式设置
  .el-switch__label.is-active {
    color: #027969;
}
.el-switch__label{
  color: #0c375e;
}
}
</style>