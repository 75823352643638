<template>
  <el-dialog title="更多" :visible.sync="isShowMore" width="79rem" :close-on-click-modal="false" :show-close="true">
    <!-- <slot></slot> -->
    <!-- 1逆变器 2环境监测仪 3并网电表 -->
    <div class="inverterBox">
      <div class="moreTop">
        <div class="item">
          <span :class="[result.type == 3 ? 'name1' : 'name']">编号：</span>
          <span class="value">{{ result.code }}</span>
        </div>
        <div class="item">
          <span :class="[result.type == 3 ? 'name1' : 'name']">型号：</span>
          <span class="value">{{ result.specification }}</span>
        </div>
        <div class="item">
          <span :class="[result.type == 3 ? 'name1' : 'name']">SN号：</span>
          <span class="value">{{ result.serialNum }}</span>
        </div>
        <div class="item" v-show="typeData == 1">
          <span :class="[result.type == 3 ? 'name1' : 'name']">厂家：</span>
          <span class="value">{{ result.producer }}</span>
        </div>
        <div class="item">
          <span :class="[result.type == 3 ? 'name1' : 'name']">电站地址：</span>
          <span class="value">{{ result.powerStationAddr }}</span>
        </div>
        <div class="item">
          <span :class="[result.type == 3 ? 'name1' : 'name']">设备名称：</span>
          <span class="value">{{ result.name }}</span>
        </div>
        <div class="item">
          <span :class="[result.type == 3 ? 'name1' : 'name']">设备类型：</span>
          <span class="value">{{ result.type == 1 ? "逆变器" : result.type == 2 ? "环境监测仪" : "并网电表" }}</span>
        </div>
        <div class="item">
          <span :class="[result.type == 3 ? 'name1' : 'name']">所属电站：</span>
          <span class="value">{{ result.powerStationName }}</span>
        </div>
        <div class="item" v-show="typeData == 1">
          <span :class="[result.type == 3 ? 'name1' : 'name']">组串容量(kW)：</span>
          <span class="value">{{ result.fullCapacity }}</span>
        </div>
        <div class="item" v-show="typeData == 1">
          <span :class="[result.type == 3 ? 'name1' : 'name']">软件版本号：</span>
          <span class="value">{{ result.softwareVersion }}</span>
        </div>
        <!-- <div class="item" v-show="typeData == 2">
          <span :class="[result.type == 3 ? 'name1' : 'name']">所属逆变器：</span>
          <span class="value">{{ result.inverterName }}</span>
        </div> -->
        <div class="item" v-show="typeData == 1">
          <span :class="[result.type == 3 ? 'name1' : 'name']">设备IP地址：</span>
          <span class="value">{{ result.ipAddr }}</span>
        </div>
        <div class="item" v-show="typeData == 1">
          <span :class="[result.type == 3 ? 'name1' : 'name']">额定交流功率(kW)：</span>
          <span class="value">{{ result.ratedPower }}</span>
        </div>
      </div>
      <!-- 下属储能 -->
      <div v-if="typeData == 1">
        <div class="fontSize2 white fontWeight" style="color:#37A5DD;">下属储能</div>
        <div class="tableBox">
          <div class="tab">
            <div class="white fontSize2" v-for="(x, idx) in energyTabList" :key="idx">
              {{ x }}
            </div>
          </div>
          <div class="listInfo">
            <div
              class="displayFlex borderBottom white fontSize padding15"
              v-for="(x, idx) in subordinateEnergyList"
              :key="idx"
            >
              <div class="col-flex-1">{{ x.code }}</div>
              <div class="col-flex-1">{{ x.name }}</div>
              <div class="col-flex-1">{{ x.serialNum }}</div>
              <div class="col-flex-1">{{ x.specification }}</div>
              <div class="col-flex-1">
                {{ x.capacity }}
              </div>
            </div>
            <div v-if="subordinateEnergyList.length === 0" style="height: 6rem; line-height: 6rem;text-align:center;">
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <!-- 下属PV -->
      <div class="pvs" v-show="typeData == 1">
        <div class="fontSize2 white fontWeight" style="color:#37A5DD;margin-top:5rem;">下属PV</div>
        <div class="tableBox" style="width:38rem;">
          <div class="tab">
            <div class="white fontSize2" v-for="(x, idx) in pvTabList" :key="idx">
              {{ x }}
            </div>
          </div>
          <div class="listInfo">
            <div class="displayFlex borderBottom white fontSize padding15" v-for="x in subordinatePvList" :key="x.indx">
              <div class="col-flex-1">{{ x.indx }}</div>
              <div class="col-flex-1">{{ x.name }}</div>
              <div class="col-flex-1">{{ x.capacity }}</div>
            </div>
            <div v-if="subordinatePvList.length === 0" style="height: 6rem; line-height: 6rem;text-align:center;">
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <!-- 下属逆变器 -->
      <div class="inverters" v-show="typeData == 2">
        <div class="fontSize2 white fontWeight" style="color:#37A5DD;margin-top:5rem;">下属逆变器</div>
        <div class="tableBox">
          <div class="tab">
            <div class="white fontSize2" v-for="(x, idx) in energyTabList" :key="idx">
              {{ x }}
            </div>
          </div>
          <div class="listInfo">
            <div
              class="displayFlex borderBottom white fontSize padding15"
              v-for="(x, idx) in subordinateEnergyList"
              :key="idx"
            >
              <div class="col-flex-1">{{ x.code }}</div>
              <div class="col-flex-1">{{ x.name }}</div>
              <div class="col-flex-1">{{ x.ratedPower }}</div>
              <div class="col-flex-1">{{ x.serialNum }}</div>
              <div class="col-flex-1">
                {{ x.specification }}
              </div>
            </div>
            <div v-if="subordinateEnergyList.length === 0" style="height: 6rem; line-height: 6rem;text-align:center;">
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <!-- 监测回路 -->
      <div class="loops" v-show="typeData == 3">
        <div class="fontSize2 white fontWeight" style="color:#37A5DD;">监测回路</div>
        <div class="loopItems">
          <div class="item" v-for="(item, index) in loopList" :key="index">{{ index + 1 }}. {{ item.name }}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "popup",
  props: ["typeData", "width", "inverterInfoData"],
  data() {
    return {
      isShowMore: false,
      energyTabList: ["编号", "名称", "SN号", "型号", "额定容量(kVA)"],
      inverterTabList: ["编号", "名称", "额定交流功率", "SN号", "型号"],
      pvTabList: ["序号", "名称", "额定功率(kW)"],
      subordinateEnergyList: [
        {
          id: 1,
          code: "001",
          name: "储能1号",
          serialNum: "SN5625",
          specification: "X2526",
          capacity: "100",
        },
      ],
      subordinatePvList: [
        {
          id: 1,
          name: "储能1号",
          ratedPower: "100",
        },
      ],
      inverterList: [
        {
          id: 1,
          code: "001",
          name: "储能1号",
          serialNum: "SN5625",
          specification: "X2526",
          ratedPower: "100",
        },
      ],
      loopList: ["回路1"],
      result: {},
    };
  },
  created() {},
  methods: {
    isOpen() {
      this.isShowMore = true;
    },
    close() {
      this.isShowMore = false;
    },
    getDownInfoList() {
      this.$nextTick(() => {
        this.$store.dispatch("downkaiyuanGenerator", this.inverterInfoData.id).then(() => {
          this.result = this.$store.state.electricity.alldownlist.data;
          // console.log(this.result);
          this.subordinateEnergyList = this.result.batteries;
          this.subordinatePvList = this.result.pvs;
          // this.inverterList = this.result;
          this.loopList = this.result.circuits;
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
html,
body {
  height: 100%;
}
/deep/.el-dialog__header {
  display: block !important;
  background: linear-gradient(225deg, rgba(15, 36, 55, 0.0001) 0.03%, rgba(0, 115, 222, 0.7) 99.9%) !important;
  border-radius: 0.4rem 0.4rem 0rem 0rem !important;
  // padding: 1.6rem 2.4rem !important;
  height: 5.4rem;
  line-height: 5.4rem;
  padding-left: 2.4rem !important;
  .el-dialog__title {
    font-size: 1.4rem !important;
    color: #fff;
  }
}
/deep/.el-dialog__body {
  //   height: 500px;
  max-height: 63rem;
  min-height: 50rem;
  overflow-y: scroll;
  display: flex;
  background-color: #0c375e !important;
}
// global style star
.displayFlex {
  display: flex;
  justify-content: space-between;
}
.col-flex-1 {
  flex: 1;
}
.fontSize {
  font-size: 1.6rem;
}
.fontSize2 {
  font-size: 1.8rem;
}
.fontWeight {
  font-weight: 800;
}
.white {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem !important;
}
.margnTop {
  margin-top: 1.5rem;
}
.margnTop1 {
  margin-top: 5rem;
}
// .paddingRight {
//   // padding-right: 10rem;
// }
.textRIght {
  text-align: right;
}
// global style end
//逆变器star
.inverterBox {
  flex: 1;
  padding: 2rem;
  box-sizing: border-box;
  .moreTop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    .item {
      width: 37rem;
      height: 5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      span {
        display: inline-block;
        overflow: hidden;
      }
      .name {
        width: 14rem;
        text-align: right;
      }
      .name1 {
        width: 7rem;
        text-align: right;
      }
      .value {
        text-align: left;
      }
    }
  }
  .loops {
    .loopItems {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .item {
        width: 13.6rem;
        height: 3.2rem;
        line-height: 3rem;
        background: rgba(52, 150, 241, 0.15);
        border: 1px dashed rgba(108, 184, 255, 0.3);
        border-radius: 0.4rem;
        box-sizing: border-box;
        padding: 0 0.5rem;
        font-size: 1.4rem;
        color: #2cd9ff;
        margin-top: 1.6rem;
        margin-right: 0.8rem;
      }
    }
  }
  .basicInfo {
    display: flex;
    justify-content: space-between;
    div {
      flex: 1;
      text-align: center;
    }
  }
  .equipmentName {
    display: flex;
    justify-content: space-between;
  }
}
.tableBox {
  // padding: 3rem;
  .tab {
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 1.5rem;
    border-bottom: 0.1rem solid rgba(108, 184, 255, 0.3);
    background: rgba(52, 150, 241, 0.15);
    margin-top: 2.2rem;
    div {
      flex: 1;
      text-align: left;
    }
  }
  .listInfo {
    // text-align: center;
    .col-flex-1 {
      text-align: left;
    }
  }
}
.star {
  color: #ff4d4f;
  font-size: 1.4rem;
  margin-right: 0.4rem;
}
.borderBottom {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}
.padding15 {
  padding: 1.5rem;
  box-sizing: border-box;
}
//逆变器end

.margin24 {
  margin-top: 2.4rem;
}
</style>
