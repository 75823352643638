<template>
    <div class="over-power">
        <div class="power1">
            <img src="@/assets/overView/power1.png" alt />
            <p>组串：{{ currFlow[3] && currFlow[3].value }}kW</p>
        </div>
        <div class="power2">
            <img src="@/assets/overView/power2.png" alt />
            <p>逆变器：({{ currFlow[2] && currFlow[2].value }}台)</p>
        </div>
        <div class="power3">
            <img src="@/assets/overView/power3.png" alt />
            <p>电表</p>
        </div>
        <div class="power4">
            <img src="@/assets/overView/power4.png" alt />
            <p class="p1">电网：{{ currFlow[6] && Math.abs(currFlow[6].value) }}kW</p>
            <!-- <p v-show="!lineLeftShow3" class="p2">电网</p> -->
        </div>
        <el-popover :value="showPopover" popper-class="powerPopover" placement="left" title="储能信息" trigger="hover">
            <div class="info">
                <div class="info-item">
                    <span class="info-item-label">电池SOC</span>
                    <span class="info-item-value">{{ currFlow[7] && currFlow[7].value }} %</span>
                </div>
                <div class="info-item">
                    <span class="info-item-label">累计充电电量</span>
                    <span class="info-item-value">{{ currFlow[8] && currFlow[8].value }} kWh</span>
                </div>
                <div class="info-item">
                    <span class="info-item-label">累计放电电量</span>
                    <span class="info-item-value">{{ currFlow[9] && currFlow[9].value }} kWh</span>
                </div>
                <div class="info-item">
                    <span class="info-item-label">充放电功率</span>
                    <span class="info-item-value">{{ currFlow[10] && currFlow[10].value }} kW</span>
                </div>
                <div class="info-item">
                    <span class="info-item-label">温度</span>
                    <span class="info-item-value">{{ currFlow[11] && currFlow[11].value }} ℃</span>
                </div>
                <div class="info-item">
                  <span class="info-item-label">充放电次数</span>
                  <span class="info-item-value">{{ currFlow[12] && currFlow[12].value }} 次</span>
                </div>
                <div class="info-item">
                  <span class="info-item-label">SOH</span>
                  <span class="info-item-value">{{ currFlow[13] && currFlow[13].value }} %</span>
               </div>

            </div>
            <div slot="reference" class="power5" v-show="isShowBattery" @mouseenter="showPopover=true" @mouseleave="showPopover=false">
                <img src="@/assets/overView/power5.png" alt />
                <p>储能：{{ getBatteryValue }}kW</p>
            </div>
        </el-popover>
        <div class="power6">
            <img src="@/assets/overView/power6.png" alt />
            <p>用电负荷：{{ currFlow[5] && parseFloat(currFlow[5].value).toFixed(2) }}kW</p>
        </div>

        <div id="line1">
            <div class="line1-right" v-show="lineRightShow1"></div>
            <div class="line1-left" v-show="lineLeftShow1"></div>
        </div>
        <div id="line2">
            <div class="line2-right" v-show="lineRightShow2"></div>
            <div class="line2-left" v-show="lineLeftShow2"></div>
        </div>
        <div id="line3">
            <div class="line3-right" v-show="lineRightShow3"></div>
            <div class="line3-left" v-show="lineLeftShow3"></div>
        </div>
        <div id="line4" v-if="isShowBattery">
            <div class="line4-right" v-show="lineRightShow4"></div>
            <div class="line4-left" v-show="lineLeftShow4"></div>
        </div>
        <div id="line5">
            <div class="line5-right" v-show="lineRightShow5"></div>
            <div class="line5-left" v-show="lineLeftShow5"></div>
        </div>
        <div id="line6">
            <div class="line6-right" v-show="lineRightShow6"></div>
            <div class="line6-left" v-show="lineLeftShow6"></div>
        </div>
        <div id="line7">
            <div class="line7-right" v-show="lineRightShow7"></div>
            <div class="line7-left" v-show="lineLeftShow7"></div>
        </div>
        <div id="line8">
            <div class="line8-right" v-show="lineRightShow8"></div>
            <div class="line8-left" v-show="lineLeftShow8"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "overPower",
    props: ["currFlow", "currentPowerStations"],
    computed: {
        isShowBattery () {
            console.log('currFlow[4].value', this?.currFlow[4].value)
            return this?.currentPowerStations?.isBattery === '1' ? true : false;
        },
        getBatteryValue () {
          let batteryValue = 0;
          if (this?.currFlow[4]) {
              if (this?.currFlow[4].value < 0) {
                batteryValue = this?.currFlow[4].value * -1;
              } else {
                batteryValue = this?.currFlow[4].value;
              }
          }
          return batteryValue;
        }
    },
    data () {
        return {
            // 向左
            lineLeftShow1: false,
            // 向右
            lineRightShow1: false,
            lineLeftShow2: false,
            lineRightShow2: false,
            lineLeftShow3: false,
            lineRightShow3: false,
            lineLeftShow4: false,
            lineRightShow4: false,
            lineLeftShow5: false,
            lineRightShow5: false,
            // 向上
            lineLeftShow6: false,
            // 向下
            lineRightShow6: false,
            lineLeftShow7: false,
            lineRightShow7: false,
            lineLeftShow8: false,
            lineRightShow8: false,
            elecNet: "",
            showPopover: false,
            batteryValue: 0,
        };
    },
    mounted () {
        // console.log(this.currFlow);
        // console.log(this.currentPowerStations);
    },
    methods: {
        flowDirection () {
            // 组串-逆变器流向向左
            this.lineLeftShow1 = false;
            // 组串-逆变器流向向右
            this.lineRightShow1 = false;
            // 逆变器-电表流向向左
            this.lineLeftShow2 = false;
            // 逆变器-电表流向向右
            this.lineRightShow2 = false;
            // 电表-电网流向向左
            this.lineLeftShow3 = false;
            // 电表-电网流向向右
            this.lineRightShow3 = false;
            // 逆变器-储能流向向上
            this.lineLeftShow4 = false;
            // 逆变器-储能流向向下
            this.lineRightShow4 = false;
            // 用电负荷-电表流向右上
            this.lineLeftShow5 = false;
            this.lineRightShow8 = false;
            // 用电负荷-电表流向左下
            this.lineRightShow5 = false;
            this.lineLeftShow8 = false;
            // 用电负荷-逆变器流向右下
            this.lineRightShow7 = false;
            this.lineRightShow6 = false;
            // 用电负荷-逆变器流向左上
            this.lineLeftShow7 = false;
            this.lineLeftShow6 = false;
            // 组串功率
            let genEnergy = parseFloat(this.currFlow[3].value);
            // 用电负荷功率
            let costEnergy = parseFloat(this.currFlow[5].value);
            // 储能功率
            let saveEnergy = parseFloat(this.currFlow[4].value);
            // 电网电表
            let meterEnergy = parseFloat(this.currFlow[6].value);
            let costSave = costEnergy + saveEnergy;
            let genSave = genEnergy + saveEnergy;
            //   debugger
            // if (genEnergy > 0 && costEnergy > 0) {
            //     if (costEnergy == genEnergy) {
            //         // console.log("情况1");
            //         this.lineRightShow1 = true;
            //         this.lineRightShow6 = true;
            //         this.lineRightShow7 = true;
            //     } else if (genEnergy > costEnergy && genEnergy <= costSave) {
            //         // console.log("情况2");
            //         this.lineRightShow1 = true;
            //         this.lineRightShow6 = true;
            //         this.lineRightShow7 = true;
            //         if (saveEnergy < 0) {
            //             this.lineLeftShow4 = true;
            //         } else {
            //             this.lineRightShow4 = true;
            //         }
            //     } else if (genEnergy > costSave) {
            //         // console.log("情况3");
            //         this.lineRightShow1 = true;
            //         this.lineRightShow2 = true;
            //         this.lineRightShow3 = true;
            //         this.lineRightShow6 = true;
            //         this.lineRightShow7 = true;
            //         if (saveEnergy < 0) {
            //             this.lineLeftShow4 = true;
            //         } else {
            //             this.lineRightShow4 = true;
            //         }
            //     } else if (genEnergy < costEnergy && genSave >= costEnergy) {
            //         // console.log("情况4");
            //         this.lineRightShow1 = true;
            //         this.lineRightShow6 = true;
            //         this.lineRightShow7 = true;
            //         if (saveEnergy < 0) {
            //             this.lineLeftShow4 = true;
            //         } else {
            //             this.lineRightShow4 = true;
            //         }
            //     } else if (genEnergy < costEnergy && genSave < costEnergy) {
            //         // console.log("情况5");
            //         this.lineRightShow1 = true;
            //         this.lineRightShow6 = true;
            //         this.lineRightShow7 = true;
            //         if (saveEnergy < 0) {
            //             this.lineLeftShow4 = true;
            //         } else {
            //             this.lineRightShow4 = true;
            //         }
            //         this.lineLeftShow3 = true;
            //         this.lineLeftShow8 = true;
            //         this.lineRightShow5 = true;
            //         this.elecNet = (costEnergy - genSave).toFixed(2);
            //     }
            // } else if (genEnergy == 0 && costEnergy > 0) {
            //     if (saveEnergy > 0) {
            //         if (saveEnergy == costEnergy) {
            //             // console.log("情况6");
            //             this.lineRightShow6 = true;
            //             this.lineRightShow7 = true;
            //             if (saveEnergy < 0) {
            //                 this.lineLeftShow4 = true;
            //             } else {
            //                 this.lineRightShow4 = true;
            //             }
            //         } else if (costEnergy > saveEnergy) {
            //             // console.log("情况7");
            //             this.lineRightShow6 = true;
            //             this.lineRightShow7 = true;
            //             if (saveEnergy < 0) {
            //                 this.lineLeftShow4 = true;
            //             } else {
            //                 this.lineRightShow4 = true;
            //             }
            //             this.lineLeftShow3 = true;
            //             this.lineLeftShow8 = true;
            //             this.lineRightShow5 = true;
            //             this.elecNet = (costEnergy - saveEnergy).toFixed(2);
            //         }
            //     } else if (saveEnergy == 0) {
            //         // console.log("情况8");
            //         this.lineLeftShow3 = true;
            //         this.lineLeftShow8 = true;
            //         this.lineRightShow5 = true;
            //         this.elecNet = costEnergy.toFixed(2);
            //     }
            // } else if (genEnergy > 0 && costEnergy == 0) {
            //     if (genEnergy <= saveEnergy) {
            //         // console.log("情况9");
            //         this.lineRightShow1 = true;
            //         if (saveEnergy < 0) {
            //             this.lineLeftShow4 = true;
            //         } else {
            //             this.lineRightShow4 = true;
            //         }
            //     } else {
            //         // console.log("情况10");
            //         this.lineRightShow1 = true;
            //         this.lineRightShow2 = true;
            //         this.lineRightShow3 = true;
            //         if (saveEnergy < 0) {
            //             this.lineLeftShow4 = true;
            //         } else {
            //             this.lineRightShow4 = true;
            //         }
            //     }
            // } else {
            //     this.elecNet = (costEnergy - saveEnergy).toFixed(2);
            // }
            if (genEnergy > 0 && saveEnergy < 0 && meterEnergy > 0) {
                this.lineRightShow1 = true;
                this.lineLeftShow4 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
                this.lineRightShow5 = true;
                this.lineLeftShow8 = true;
                this.lineLeftShow3 = true;
            } else if (genEnergy > 0 && saveEnergy > 0 && meterEnergy > 0) {
                this.lineRightShow1 = true;
                this.lineRightShow4 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
                this.lineRightShow5 = true;
                this.lineLeftShow8 = true;
                this.lineLeftShow3 = true;
            } else if (genEnergy > 0 && saveEnergy > 0 && meterEnergy < 0) {
                this.lineRightShow1 = true;
                this.lineRightShow4 = true;
                this.lineRightShow2 = true;
                this.lineRightShow3 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
            } else if (genEnergy > 0 && saveEnergy < 0 && meterEnergy < 0) {
                this.lineRightShow1 = true;
                this.lineLeftShow4 = true;
                this.lineRightShow2 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
                this.lineRightShow3 = true;
            } else if (genEnergy == 0 && saveEnergy > 0 && meterEnergy > 0) {
                this.lineRightShow4 = true;
                this.lineLeftShow2 = true;
                this.lineRightShow5 = true;
                this.lineLeftShow8 = true;
                this.lineLeftShow3 = true;
            } else if (genEnergy == 0 && saveEnergy < 0 && meterEnergy > 0) {
                this.lineLeftShow4 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
                this.lineRightShow5 = true;
                this.lineLeftShow8 = true;
                this.lineLeftShow3 = true;
            } else if (genEnergy == 0 && saveEnergy < 0 && meterEnergy < 0) {
                this.lineLeftShow4 = true;
                this.lineRightShow2 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
                this.lineRightShow3 = true;
            } else if (genEnergy == 0 && saveEnergy == 0 && meterEnergy > 0) {
                this.lineRightShow5 = true;
                this.lineLeftShow8 = true;
                this.lineLeftShow3 = true;
            } else if (genEnergy == 0 && saveEnergy < 0 && meterEnergy == 0) {
                this.lineLeftShow4 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
            } else if (genEnergy > 0 && saveEnergy > 0 && meterEnergy == 0) {
                // 组网发电 && 储能充电 && 电网无电
                this.lineRightShow1 = true;
                this.lineRightShow4 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
            } else if (genEnergy > 0 && saveEnergy < 0 && meterEnergy == 0) {
                // 组网发电 && 储能用电 && 电网无电
                this.lineRightShow1 = true;
                this.lineLeftShow4 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
            } else if (genEnergy > 0 && saveEnergy == 0 && meterEnergy == 0) {
                this.lineRightShow1 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
            } else if (genEnergy > 0 && saveEnergy == 0 && meterEnergy > 0) {
                this.lineRightShow1 = true;
                this.lineRightShow7 = true;
                this.lineRightShow6 = true;
                this.lineRightShow5 = true;
                this.lineLeftShow8 = true;
                this.lineLeftShow3 = true;
            } else if (genEnergy > 0 && saveEnergy == 0 && meterEnergy < 0) {
                this.lineRightShow1 = true;
                this.lineRightShow2 = true;
                this.lineRightShow3 = true;
                if (costEnergy < genEnergy) {
                    // 用电小于组网发电，显示逆变到电网的流向
                    this.lineRightShow7 = true;
                    this.lineRightShow6 = true;
                }
            }
        },
    },
    watch: {
        currFlow: {
            // immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) return;
                this.currFlow = newVal;
                this.flowDirection();
            },
        }
    },
};
</script>

<style lang="less" scoped>
.over-power {
    position: relative;
    margin: 0 auto;
    width: 88.3rem;
    .power1 {
        position: absolute;
        left: 9.5rem;
        top: 2.8rem;
        z-index: 100;
        cursor: pointer;
        p {
            font-size: 1.4rem;
            color: #1ab292;
            position: relative;
            left: -2.8rem;
        }
        img {
            width: 4.5rem;
            height: 4.5rem;
        }
    }
    .power2 {
        position: absolute;
        left: 34.1rem;
        top: 2.8rem;
        z-index: 100;
        cursor: pointer;
        p {
            font-size: 1.4rem;
            color: #1ab292;
            position: relative;
            left: -2.8rem;
        }
        img {
            width: 4.5rem;
            height: 4.5rem;
        }
    }
    .power3 {
        position: absolute;
        left: 62.4rem;
        top: 2.8rem;
        z-index: 100;
        text-align: center;
        cursor: pointer;
        p {
            font-size: 1.4rem;
            color: #1ab292;
            // position: relative;
            // left: 1.4rem;
        }
        img {
            width: 4.5rem;
            height: 4.5rem;
        }
    }
    .power4 {
        position: absolute;
        right: 0.3rem;
        top: 2.8rem;
        z-index: 100;
        width: 11rem;
        text-align: center;
        cursor: pointer;
        .p1 {
            font-size: 1.4rem;
            color: #1ab292;
            // position: relative;
            // left: -1.5rem;
        }
        .p2 {
            font-size: 1.4rem;
            color: #1ab292;
            // position: relative;
            // left: 1rem;
        }
        img {
            width: 4.5rem;
            height: 4.5rem;
        }
    }
    .power5 {
        position: absolute;
        left: 32.8rem;
        top: 32.6rem;
        text-align: center;
        z-index: 100;
        cursor: pointer;
        p {
            font-size: 1.4rem;
            color: #1ab292;
            position: relative;
            // left: -2.8rem;
        }
        img {
            width: 4.5rem;
            height: 4.5rem;
        }
    }
    .power6 {
        position: absolute;
        right: 29.7rem;
        top: 32.6rem;
        text-align: center;
        z-index: 100;
        cursor: pointer;
        p {
            font-size: 1.4rem;
            color: #1ab292;
            //   position: relative;
            //   left: -4rem;
            width: 16rem;
        }
        img {
            width: 4.5rem;
            height: 4.5rem;
        }
    }
    #line1 {
        position: absolute;
        height: 0;
        width: 20rem;
        left: 14rem;
        top: 5rem;
        border-top: 0.2rem dashed #00a1db;
        overflow-x: clip;
    }
    .line1-right {
        position: absolute;
        height: 0.4rem;
        width: 40rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move 4s linear infinite;
    }
    .line1-left {
        position: absolute;
        height: 0.4rem;
        width: 40rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move1 4s linear infinite;
    }
    .line2-right {
        position: absolute;
        height: 0.4rem;
        width: 45rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move 4s linear infinite;
    }
    .line2-left {
        position: absolute;
        height: 0.4rem;
        width: 45rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move1 4s linear infinite;
    }
    .line3-right {
        position: absolute;
        height: 0.4rem;
        width: 40rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move 4s linear infinite;
    }
    .line3-left {
        position: absolute;
        height: 0.4rem;
        width: 40rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move1 4s linear infinite;
    }
    .line4-right {
        position: absolute;
        height: 54rem;
        width: 0.4rem;
        // left: -20rem;
        left: -0.2rem;
        background: -webkit-repeating-linear-gradient(
            90deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move2 4s linear infinite;
    }
    .line4-left {
        position: absolute;
        height: 54rem;
        width: 0.5rem;
        // left: -20rem;
        left: -0.2rem;
        background: -webkit-repeating-linear-gradient(
            90deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move3 4s linear infinite;
    }
    .line5-right {
        position: absolute;
        height: 54rem;
        width: 0.4rem;
        // left: -20rem;
        left: -0.2rem;
        background: -webkit-repeating-linear-gradient(
            90deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move2 4s linear infinite;
    }
    .line5-left {
        position: absolute;
        height: 54rem;
        width: 0.5rem;
        // left: -20rem;
        left: -0.2rem;
        background: -webkit-repeating-linear-gradient(
            90deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move3 4s linear infinite;
    }
    .line6-right {
        position: absolute;
        height: 54rem;
        width: 0.4rem;
        // left: -20rem;
        right: -0.2rem;
        background: -webkit-repeating-linear-gradient(
            90deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move2 4s linear infinite;
    }
    .line6-left {
        position: absolute;
        height: 54rem;
        width: 0.5rem;
        // left: -20rem;
        right: -0.2rem;
        background: -webkit-repeating-linear-gradient(
            90deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move3 4s linear infinite;
    }
    .line7-right {
        position: absolute;
        height: 0.4rem;
        width: 24rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move 4s linear infinite;
    }
    .line7-left {
        position: absolute;
        height: 0.4rem;
        width: 24rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move1 4s linear infinite;
    }
    .line8-right {
        position: absolute;
        height: 0.4rem;
        width: 24rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move 4s linear infinite;
    }
    .line8-left {
        position: absolute;
        height: 0.4rem;
        width: 24rem;
        // left: -20rem;
        top: -0.3rem;
        background: -webkit-repeating-linear-gradient(
            0deg,
            #5becd7 0,
            #61d9e0 0.3rem,
            #41bee2 0.6rem,
            #0ea6dd 1rem
        );
        animation: move1 4s linear infinite;
    }
    @keyframes move {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 20rem 0;
        }
    }
    @keyframes move1 {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: -20rem 0;
        }
    }
    @keyframes move2 {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 0 20rem;
        }
    }
    @keyframes move3 {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 0 -20rem;
        }
    }
    #line2 {
        position: absolute;
        height: 0;
        width: 25rem;
        left: 38rem;
        top: 5rem;
        border-top: 0.2rem dashed #00a1db;
        overflow-x: clip;
    }
    #line3 {
        position: absolute;
        height: 0;
        width: 20rem;
        left: 64rem;
        top: 5rem;
        border-top: 0.2rem dashed #00a1db;
        overflow-x: clip;
    }
    #line4 {
        position: absolute;
        height: 27rem;
        width: 0;
        left: 36.2rem;
        top: 5.5rem;
        border-left: 0.2rem dashed #00a1db;
        overflow-y: clip;
    }
    #line5 {
        position: absolute;
        height: 27rem;
        width: 12rem;
        left: 51.5rem;
        top: 6.5rem;
        border-left: 0.2rem dashed #00a1db;
        overflow-y: clip;
    }
    #line6 {
        position: absolute;
        height: 27rem;
        width: 12rem;
        left: 37.5rem;
        top: 6.5rem;
        border-right: 0.2rem dashed #00a1db;
        overflow-y: clip;
    }
    #line7 {
        position: absolute;
        height: 27rem;
        width: 12rem;
        left: 37.5rem;
        top: 6.5rem;
        border-top: 0.2rem dashed #00a1db;
        overflow-x: clip;
    }
    #line8 {
        position: absolute;
        height: 27rem;
        width: 12rem;
        left: 51.5rem;
        top: 6.5rem;
        border-top: 0.2rem dashed #00a1db;
        overflow-x: clip;
    }
}
</style>
<style lang="less">
.powerPopover {
    top: 32.6rem;
    left: 34.1rem;
    width: 20rem;
    color: #fff;
    background-color: rgb(12, 55, 94);
    border: 0.1rem solid #004579;
    .el-popover__title {
        color: #37a5dd;
    }
    .info {
        .info-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
            line-height: 2.2rem;
        }
    }
    .popper__arrow {
        border-left-color: #004579 !important;
        &::after {
            border-left-color: rgb(12, 55, 94) !important;
        }
    }
}
</style>
