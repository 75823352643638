<template>
  <div class="ElecManagment page-main">
    <div
      :class="isTreeShow ? 'shrink-open_tree' : 'shrink-close_tree'"
      class="treePlace page-main-tree"
    >
      <!--el树形控件  -->
      <TreeElecManagment ref="treeRef" />
    </div>

    <!-- 板块二/右侧 ##########################################################################-->
    <div
      :class="
        isTreeShow ? 'shrink-open_tree_content' : 'shrink-close_tree_content'
      "
      class="blockTwoRight page-main-content"
    >
      <div class="shrink-btn" @click="isTreeShow = !isTreeShow"></div>
      <!-- 顶部菜单###################### -->
      <div class="topBar">
        <div class="btnList">
          <button
            :class="item.classType"
            v-for="(item, index) in menuList"
            :key="index"
            @click="routerSwitchTo(item.router, $event)"
          >
            {{ item.name }}
          </button>
        </div>
      </div>

      <!-- 路由部分############################################ -->
      <div class="routerPlace">
        <router-view :isTreeShow="isTreeShow"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TreeElecManagment from "./ElecManagementComponents/TreeElecManagment.vue";

export default {
  name: "ElecManagement",

  components: {
    TreeElecManagment,
  },

  data() {
    return {
      isTreeShow: true,
      // 路由菜单的列表
      menuList: [
        { name: "能耗概况", router: "EnergySituation", classType: "btn2" },
        { name: "能耗地图", router: "EnergyMap", classType: "btn1" },
        { name: "能耗统计", router: "EnergyStatistical", classType: "btn1" },
        { name: "时段分析", router: "TimeAnalyze", classType: "btn1" },
        { name: "对比分析", router: "ComparisonAnalyze", classType: "btn1" },
        { name: "排名分析", router: "RankAnalyze", classType: "btn1" },
        { name: "能耗设置", router: "EnergySetting", classType: "btn1" },
        { name: "统计报表", router: "StatisticalReport", classType: "btn1" },
      ],
    };
  },

  methods: {
    // 点击tab进行路由跳转
    routerSwitchTo(val, event) {
      this.$router.push("/elecManagement/" + val);
      let brothers = event.target.parentNode.children;
      for (let i = 0; i < brothers.length; i++) {
        brothers[i].className = "btn1";
      }
      event.target.className = "btn2";
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
// 树的样式#####################################################
.treePlace {
    // overflow: hidden;
    // width: 26rem;
    // height: 96.8rem;
    // position: absolute;
    // left: 0;
    // top: 0;
}

// 右侧样式#########################################################
.blockTwoRight {
    // width: 142.8rem;
    height: 96.8rem;
    // position: absolute;
    // left: 28.4rem;
    // top: 0;
    overflow: hidden;
    .topBar {
        display: flex;
        align-items: center;
        padding-left: 2rem;
        box-sizing: border-box;
        height: 8rem;
        // width: 142.8rem;
        width: 100%;
        background-color: #0d3050;
        border-radius: 0.4rem;
        // position: absolute;
        // top: 0;
        // left: 0;
        overflow: hidden;
        margin-bottom: 2.4rem;
        .btnList {
            width: 81.6rem;
            height: 3.2rem;
            border: 0.1rem solid #004579;
            border-radius: 0.2rem;
            overflow: hidden;
            button {
                height: 3.2rem;
                width: 10.2rem;
                cursor: pointer;
                border: none;
                color: #fff;
                font-size: 1.4rem;
            }
            .btn1 {
                background-color: #0c375e;
            }
            .btn2 {
                background-color: #027969;
            }
        }
    }
    //路由部分########################################################################################
    .routerPlace {
        height: 86.4rem;
        // height: 86.4rem;
        // height: 60rem;
        // overflow: scroll;
        // overflow: hidden;
        // width: 142.8rem;
        // width: 100%;
        width: calc(100% + 0.6rem);
        // position: absolute;
        position: relative;
        top: 10.4rem;
        left: 0;
        // overflow: hidden;
        overflow: unset;
        background-color: #18191a;
        top: 0;
    }
}
</style>