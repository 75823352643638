<template>
  <div class="yo">
      RankAnalyzeGenerate
  </div>
</template>

<script>
export default {
    name:'RankAnalyzeGenerate',
}
</script>

<style lang='less' scoped>
.yo{
  font-size: 10rem;
  color: #fff;
}
</style>