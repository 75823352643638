<template>
    <div class="rankRouterTop">
        <div class="rigth-bottom">
          <div v-if="loading" class="loading-mask">
            <el-spinner size="large"></el-spinner>
          </div>
          <span class="title" v-if="!loading">{{ this.locationName ? this.locationName : '无数据' }}</span>
          <div class="rankListContent" v-for="(item, index) in energyList" :key="index">
            <div class="bottom-center">
              <span>{{ item.name }}</span>
              <span>{{ item.value }}kWh</span>
            </div>
            <div class="str-false"></div>
            <div class="str-true" :style="{ width: item.conNum + 'rem' }">
              <div class="rightBar"></div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "rankRouterTop",
    data () {
        return {
            dataForm: {
                customerId: "",
                locationId: '',
            },
            cardList: [
                {
                    buildingName: "凯源卓尔大楼（能耗测试）",
                    strList: [],
                },
            ],
            list: [],
            locationName:'',
            locationId:'',
            energyList: [],
            loading: false,
        };
    },
    mounted () { },
    created () {
    },
    methods: {
        getConSuming () {
            for (let i = 0; i < this.cardList.length; i++) {
                let item = this.cardList[i].strList;
                if (item) {
                    for (let j = 0; j < item.length; i++) {
                        let sum = item[j].value / item[0].value - 0.1;
                        this.cardList[i].strList[j].conNum = (sum * 100) < 0 ? 0 : sum * 100;
                    }
                }
            }
        },
        queryEnerData () {
            this.loading = true
            this.energyList = []
            this.locationName = ''
            this.dataForm.locationId = this.$store.state.storage.localtionId;
            this.$store.dispatch("getEnerRankByLocation", this.dataForm.locationId).then(() => {
                let arr = this.getEnerRankLocation;
                if (arr !== null) {
                      this.locationName = arr.buildingName
                      this.locationId = arr.buildingId
                      this.energyList = arr.byusage
                      for (let j = 0; j < this.energyList.length; j++) {
                          let sum = this.energyList[j].value / (this.energyList[0].value - 0.1);
                          this.energyList[j].conNum = (sum * 100) < 0 ? 0 : sum * 100;
                      }
                } else {
                    this.locationId = ''
                    this.locationName = ''
                    this.energyList = ''
                }
            }).finally(() => {
              this.loading = false
            });
        },
    },
    computed: {
        ...mapState({
            getEnerRankLocation: (state) => {
                return state.elecManagement.getEnerRankByLocation.data;
            },
        }),
    },
    watch: {
        "$store.state.storage.localtionId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.dataForm.locationId = newVal;
                this.queryEnerData();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.rankRouterTop {
    display: flex;
    margin-left: -2.4rem;
    width: 100%;
    height: 100%;
    flex-direction: column;
    .rigth-bottom {
        margin-left: 2.4rem;
        width: 100%;
        height: 100%;
        min-height: 42rem;
        position: relative;
        background: #0c2f4f;
        border-radius: 4px;
        // margin-left: 1.5rem;
        .title {
            padding-left: 7rem;
            position: relative;
            //   top: 2.3rem;
            display: inline-block;
            margin-top: 2.3rem;
            margin-left: 1rem;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: #37a5dd;
        }
        .rankListContent {
            padding-left: 7rem;
            padding-right: 12rem;
            position: relative;
            //   top: 2.5rem;
            .bottom-center {
                margin-top: 0.7rem;

                display: flex;
                justify-content: space-between;
                padding: 0 1.5rem;
                span {
                    color: #fffffe;
                    font-size: 1.2rem;
                    font-weight: 300;
                }
            }
            .str-false {
                // width: 32.7rem;
                width: 100rem;
                height: 1rem;
                top: 0.5rem;
                position: relative;
                margin: 0 1.5rem;
                height: 1.6rem;
                background-color: #0f495c;
            }
            .str-true {
                width: 10.7rem;
                top: -1.2rem;
                position: relative;
                margin: 0 1.5rem;
                height: 1.6rem;
                background: linear-gradient(270deg, #36eaaf 0%, #4bb3d2 100%);
                .rightBar {
                    width: 0;
                    height: 1.8rem;
                    border: 0.1rem solid #fff;
                    position: absolute;
                    top: -0.2rem;
                    right: 0;
                }
            }
        }
    }
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
</style>
