<template>
  <div>
    <slot name="title"></slot>
    <el-table :data="tableData" style="width: 100%;background:#0C375E;"
        :header-cell-style="headerClass"
        :cell-style="cellStyle" ref="tb" :max-height="height">
        <template v-for="(item,index) in columns">
          <el-table-column :prop="item.prop" :label="item.label" :key="index" :width="item.width" :show-overflow-tooltip="true"></el-table-column>
        </template>
      </el-table>
  </div>
</template>

<script>
import { tableBg } from '../mixin/tablebg'
export default {
  mixins: [tableBg],
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    height: {
      type: [String, Number],
      default: 400
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style>

</style>