<template>
  <div class="DrawerForDetail">
      <el-drawer
        :visible.sync="drawer"
        :with-header="false"
        @close = "closeDrawer"
        direction=ltr
        size=66%    
        >

        <!-- 左侧区域######################################### -->
        <div class="leftPart">
            <div class="title">
                工单编号 : {{formData.orderNo}}
            </div>
            <!-- 板块一############### -->
            <div class="cardOne">
              
                <div class="wordTypeOne">报修信息 :</div>
                <div class="wordTypeTwo">报修部门 : <span class="wordTypeFour">{{formData.deptName}}</span> </div>
                <div class="wordTypeTwo">部门电话 : <span class="wordTypeFour">{{formData.deptPhone}}</span></div>
                <div class="wordTypeTwo">报修人员 : <span class="wordTypeFour">{{formData.reportUserName}}（18928993829）</span></div>
                <div class="wordTypeTwo">报修时间 : <span class="wordTypeFour">{{formData.createTime}}</span>&nbsp;&nbsp;&nbsp;&nbsp; 计划结束时间 : <span class="wordTypeFour">{{formData.planEndTime}}</span></div>
                <div class="wordTypeTwo">报修地址 : <span class="wordTypeFour">{{formData.address}}</span></div>
                <div class="wordTypeTwo">报修设备 : <span class="wordTypeFour">{{formData.deviceName2}}&nbsp;&nbsp;&nbsp;&nbsp; 所属配电房：{{formData.placeName}}&nbsp;&nbsp;&nbsp;&nbsp; 所属客户：{{formData.company}}</span></div>
                
                <div class="box">
                    <div class="wordTypeFive">故障描述 : </div>
                    <span class="wordTypeSix">{{formData.content}}</span>
                </div>
                <div class="box">
                    <div class="wordTypeFive">附件 : </div> 
                    <img @click="handleCheckDetail(item)" v-for="(item,index) in imgList1" :key=index class="myImg" :src="item" alt="图片不在该服务器">
                   
                </div>
             
            </div>
            <!-- 板块二############### -->
            <div v-if="partTwoType===2" class="cardTwo">
                <div class="wordTypeOne">处理信息 :</div>
                <div class="wordTypeTwo">处理人员 : <span class="wordTypeFour">{{formData.handler}}({{formData.repairPhone}})</span></div>
                <div class="wordTypeTwo">处理时间 : <span class="wordTypeFour">{{formData.finishTime}}</span> </div>
                <div class="wordTypeTwo">事件原因 : <span class="wordTypeFour">{{formData.cause}}</span></div>
                <!-- <div class="wordTypeTwo">报修地址 : <span class="wordTypeFour">上海市黄浦区汤臣一品2号楼37层</span></div> -->
                <div class="box">
                    <div class="wordTypeFive">处理过程 : </div>
                    <span class="wordTypeSix">{{formData.handlerContent}}</span>
                </div>
                <div class="box">
                    <div class="wordTypeFive">处理前 : </div> 
                    <img @click="handleCheckDetail(item)" v-for="(item,index) in imgList2" :key=index class="myImg" :src="item" alt="">
                    </div>
                <div class="box">
                    <div class="wordTypeFive">处理后 : </div> 
                    <img @click="handleCheckDetail(item)" v-for="(item,index) in imgList3" :key=index class="myImg" :src="item" alt="">    
                </div>
                
            </div>
            
            <!-- 版块二（隐藏）############ -->
            <div v-else class="cardTwo">
                 <div class="wordTypeOne">该工单正在等待处理......</div>
            </div>


        </div>
        <!-- 中间区域 -->
        <div class="middlePart"></div>
        <!-- 右侧区域############################################## -->
        <div class="rightPart">
            <div class="inner">
                <el-timeline>
                    <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :icon="activity.icon"
                    :type="activity.type"
                    :color="activity.color"
                    :size="activity.size"
                    :timestamp="activity.timestamp">
                    {{activity.content}}
                    </el-timeline-item>
                </el-timeline>
            </div>



        </div>
        
      </el-drawer>


    <!-- 预览图片的弹层 -->
    <el-dialog :visible.sync="dialogForPictureVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>
export default {
    name:"DrawerForDetail",
    data(){return {
        partTwoType:2,
        // 控制抽屉是否展开
        drawer: false,
        // 表格数据
        formData:[],
        // 流程图数据
        activities: [],
        // 工单上报图片列表(工单上报:1，处理前:2，处理后:3)
        imgList1:[],
        imgList2:[],
        imgList3:[],
        // 预览图片的弹层是否显示
        dialogForPictureVisible:false,
        // 大图弹层的图片地址
        dialogImageUrl:"",
        
      }},

    methods:{
        //控制是否展示抽屉
       show(val,type){
           this.drawer = true
           this.formData = val
           this.partTwoType = type
           this.imgList1 = []
           // 处理设备的属性
           const dex = this.formData.deviceName.split('-')
           this.formData.deviceName2=dex[2]
           this.formData.placeName=dex[1]
           this.formData.company=dex[0]
           //处理展示的图片
           if(val.soReportImages){this.imgList1 = val.soReportImages.split(",")}
           if(val.soReportStartImages){this.imgList2 = val.soReportStartImages.split(",")}
           if(val.soReportFinishImages){this.imgList3 = val.soReportFinishImages.split(",")}
           //渲染流程图  
           this.activities=[]
           for(let i=0;i<val.logList.length;i++){
               const singel = {content:"",timestamp:"",color:"#0078E8"}
               singel.content = val.logList[i].stageDetails
               singel.timestamp = val.logList[i].createTime
               this.activities.push(singel)
           }
           this.activities[0].color="#1EE7E7"
       },

        //关闭抽屉
        closeDrawer(){
            this.imgList1=[]
            this.imgList2=[]
            this.imgList3=[]
            this.formData=[]
        },
        
        // 查看大图
        handleCheckDetail(val){
            this.dialogForPictureVisible = true
            this.dialogImageUrl = val
        },

    },


}
</script>

<style lang="less" scoped>
// 公共样式
.wordTypeOne{
    height: 4rem;
    width: 85rem;
    line-height: 5.5rem;
    font-size: 1.6rem;
    color: #37A5DD;
    font-weight: 900;
    margin-left: 3.2rem;
}
.wordTypeTwo{
    height: 3.6rem;
    width: 84rem;
    line-height: 3.6rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem;
}
.wordTypeThree{
    height: 10rem;
    width: 85rem;
    line-height: 2rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem;

}
.wordTypeFour{ 
    font-weight: 100;
    line-height: 2rem;
    margin-left: 0.5rem;
}
.wordTypeFive{
    height: 9rem;
    width: 8rem;
    line-height: 9rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem; 
    
}
.wordTypeSix{
    height: 9rem;
    width: 80rem;
    font-weight: 100;
    line-height: 2.2rem;   
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    overflow: auto;
    display: table-cell;
    vertical-align:middle;
    background-color: #0e2b46;
    border-radius: 0.4rem;
    padding: 0 0.5rem 0 0.5rem;
    box-sizing: border-box;
    margin-right: 20px;
}
.myImg{
    height: 9rem;
    width: 15rem;
    border-radius: 0.4rem;
    overflow: hidden;
    margin-right: 2rem;
    cursor: pointer;
    position: relative;
    &:hover{    
        opacity: 0.2;  
        // background: url("../../../../assets/repair/detail.jpg") no-repeat center center;       
    }
    // &::after {
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	color: #fff;
	// 	content: "查看大图";
    //     font-size: 2rem;
	// 	z-index: 2000 !important;
	// }


   
}
.box{
    height: 9rem;
    width: 90rem;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
 
}


// 左侧区域###############################################################
.leftPart{
    width: 90.3rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
    .title{
        margin-top: 2.5rem;
        line-height: 2.5rem;
        font-size: 1.6rem;
        color: #37A5DD;
        font-weight: 900;
        margin-left: 3.2rem;
    }
    .cardOne{
        height: 45.6rem;
    }
    .cardTwo{
        height: 49rem;
        width: 100%;
    }
}
// 右侧区域
.rightPart{
    width: 37.4rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 90.4rem;
    overflow-y: auto;
    .inner{
        position: absolute;
        left: 2rem;
        top: 8rem;
        // overflow: scroll;
        height: 90rem;

    }
}


// 中间区域
.middlePart{
    width: 0.1rem;
    height: 94.8rem;
    position: absolute;
    top: 0;
    left: 90.3rem;
    background-color: rgba(22, 111, 191, 0.2);
}




</style>


<style lang="less">
.DrawerForDetail{

// 抽屉底色
.el-drawer {
    background-color: #0c375d;  
}

.equip_title {
  height: 5.4rem;
  line-height: 5.4rem;
  padding-left: 2.4rem;
  font-size: 1.6rem;
  background: linear-gradient(225deg, rgba(15, 36, 55, 0.0001) 0.03%, rgba(0, 115, 222, 0.7) 99.9%);
}

// 流程图样式


.el-timeline-item__content {
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
}

.el-timeline {
    margin: 0;
    font-size: 1.4rem;
    list-style: none;
}

.el-timeline-item__timestamp .is-bottom{
    font-size: 1.2rem;
}


.DrawerForDetail .el-timeline-item__wrapper {
    font-size: 2rem;
  
}
.el-timeline-item__wrapper {
    position: relative;
    padding-left: 2.8rem;
    top: -0.3rem;

}








}



</style>