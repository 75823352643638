<template>
    <div>
        <!-- 板块二（主内容）######################################################################## -->
        <div class="blockTwo page-main">
            <!-- 板块二/左侧 ##########################################################################-->

            <!-- :class="blockTwoLeftType" @mouseenter="enterLeftTree" @mouseleave="leaveLeftTree" -->
            <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="blockTwoLeft2 page-main-tree">
                <!--el树形控件  -->
                <TreeCustomer ref="treeRef" />
            </div>
            <!-- 板块二/右侧 ##########################################################################-->
            <div :class="isTreeShow ? 'shrink-open_tree_content':'shrink-close_tree_content'" class="blockTwoRight2 page-main-content">
                <div class="shrink-btn" @click="isTreeShow = !isTreeShow">
                </div>
                <div class="topBar">
                    <div>
                        <el-input size="small" placeholder="请输入客户名称" v-model="searchValue" @keyup.enter.native="search" style="width:22rem;" clearable @clear="search"></el-input>
                        <el-select size="small" @change="selectChanged" placeholder="请选择行业" class="businessSelector" v-model="businessTypeFake">
                            <el-option v-for="(item, i) in jobList" :label="item.label" :value="item.value" :key="i"></el-option>
                        </el-select>
                        <button class="btn_save" @click="search">查 询</button>
                    </div>
                    <button class="btn2" :disabled="treeNode ? false : true" @click="importFile">
                        导入
                    </button>
                    <button class="btn3" @click="exportFile">导出</button>
                    <!-- :disabled="treeNode ? false : true" -->
                    <button class="btn4" @click="ifDialogShow">
                        登记
                    </button>
                </div>
                <div class="customerTablePlace">
                    <!-- 面包屑部分 -->
                    <div class="bread">{{ this.breadName }}</div>
                    <!-- 表格部分-->
                    <div class="table">
                        <el-table :data="tableData" stripe:false height="57.8rem" border:false style="width: 100%" :header-cell-style="headerClass" :cell-style="cellStyle">
                            <!-- <el-table-column type="index" label="序号" width="50"></el-table-column> -->
                            <el-table-column label="序号" width="50">
                                <template slot-scope="scope">{{
                  scope.$index + 1 + (currentPage - 1) * pageSize
                }}</template>
                            </el-table-column>
                            <el-table-column class="tab" prop="num" label="编号" min-width="9%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column class="tab" prop="name" label="单位名称" min-width="18%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="parentName" label="上级单位" min-width="17%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="industry" label="所属行业" min-width="14%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="address" label="地址" min-width="15%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="manager" label="项目负责人" min-width="13%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="managerPhone" label="项目负责人电话" min-width="16%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="urgentContact" label="紧急联系人" min-width="13%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="urgentContactPhone" label="紧急联系人电话" min-width="16%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="owner" label="业主联系人" min-width="13%" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column prop="ownerPhone" label="业主联系人电话" min-width="16%" :show-overflow-tooltip="true"></el-table-column>
                            <!-- 操作部分 -->
                            <el-table-column label="操作" min-width="18%">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" @click="handleDetail(scope.$index, scope.row)">二维码</el-button>
                                    <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页器部分 -->
                    <div class="pagination">
                        <el-pagination background
                                       @size-change="handleSizeChange"
                                       @current-change="handleCurrentChange"
                                       layout="total, sizes, prev, pager, next, jumper"
                                       :current-page="currentPage"
                                       :page-size="pageSize"
                                       :page-sizes="[10, 20, 40, 80]"
                                       :total="customerTotalNum"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- 板块三(登记表单)################################################################ -->
        <div class="diaLogPlace">
            <DialogCustomer ref="dialogRef" />
        </div>
        <!-- 导入Excel表格提示弹层 -->
        <div class="diaLogExcelPlace">
            <DialogImportFile ref="importFileRef" @queryData="getMyData" />
        </div>
        <div class="DialogCustomerDetailPlace">
            <DialogCustomerDetail ref="dialogDetailRef" />
        </div>
    </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg"
import { createLogger, mapState } from "vuex"
import TreeCustomer from "./components/TreeCustomer.vue"
import DialogCustomer from "./components/DialogCustomer.vue"
import DialogImportFile from "../../components/DialogImportFile.vue"
import DialogCustomerDetail from "./components/DialogCustomerDetail.vue"
import { exportCustomerReport } from "@/api/repair"

export default {
    name: "Customer",
    components: {
        TreeCustomer,
        DialogCustomer,
        DialogImportFile,
        DialogCustomerDetail,
    },
    mixins: [tableBg],
    data () {
        return {
            isTreeShow: true,
            // 面包屑内容
            breadName: "客户管理 /",
            // 行业选择栏的内容(传值内容)
            businessType: 0,
            // 行业选择栏的占位内容（该参数不参与请求）
            businessTypeFake: "",
            jobList: [
                { label: "所有行业", value: "0" },
                { label: "学校行业", value: "1" },
                { label: "轨道交通", value: "2" },
                { label: "医疗行业", value: "3" },
                { label: "工业行业", value: "4" },
                { label: "公共建筑", value: "5" },
                { label: "地产行业", value: "6" },
            ],
            // 左侧树的缩放
            blockTwoLeftType: "blockTwoLeft2",
            // 右侧内容的class
            blockTwoRightType: "blockTwoRight2",
            // 搜索栏内容
            searchValue: "",
            // 分页器数据,当前页面
            currentPage: 1,
            // 表格数据(里面放对象)
            tableData: [],
            // 客户总个数
            customerTotalNum: 0,
            // 每页设备数量
            pageSize: 10,
            //树的节点ID
            treeNode: "",
            // 树的节点的完整数据
            treeFullNodeData: {},
            // 登记按钮的样式
            no4class: "btn4Gray",
        }
    },

    methods: {
        // 鼠标进入左侧树
        enterLeftTree () {
            if (this.blockTwoLeftType === "blockTwoLeft") {
                this.blockTwoLeftType = "blockTwoLeft2"
                this.blockTwoRightType = "blockTwoRight2"
            }
        },
        // 鼠标移出左侧树
        leaveLeftTree () {
            if (this.blockTwoLeftType === "blockTwoLeft") {
                this.blockTwoLeftType = "blockTwoLeft2"
                this.blockTwoRightType = "blockTwoRight2"
            } else {
                this.blockTwoLeftType = "blockTwoLeft"
                this.blockTwoRightType = "blockTwoRight"
            }
        },
        // 显示弹窗
        ifDialogShow () {
            if (this.no4class === "btn4Gray") {
                this.$refs.dialogRef.show(false)
            } else {
                this.$refs.dialogRef.show(true, this.treeFullNodeData)
            }
        },
        //刷新页面（供子组件调用）
        refreshThePage () {
            this.getMyData(
                this.currentPage,
                this.pageSize,
                this.searchValue,
                this.businessType,
                this.treeNode
            )
        },
        //发送请求#####分页器改变条数/改变页面
        handleSizeChange (val) {
            this.getMyData(
                "1",
                val,
                this.searchValue,
                this.businessType,
                this.treeNode
            )
        },
        handleCurrentChange (val) {
            this.getMyData(
                val,
                this.pageSize,
                this.searchValue,
                this.businessType,
                this.treeNode
            )
        },
        //发送请求##### 搜索
        search () {
            this.getMyData(
                "1",
                this.pageSize,
                this.searchValue,
                this.businessType,
                this.treeNode
            )
        },
        //发送请求##### 获取分页数据
        getMyData (page, numPerPage, keyword, industy, parentId) {
            this.$store
                .dispatch("getCustomerPageData", {
                    page: page || this.currentPage,
                    numPerPage: numPerPage || this.pageSize,
                    keyword: keyword || this.searchValue,
                    industy: industy || this.businessType,
                    parentId: parentId || this.treeNode,
                })
                .then(() => {
                    // 进行数据遍历渲染

                    this.tableData = []
                    this.customerPageData.records.forEach((item) => {
                        const newCustomer = {}
                        newCustomer.num = item.code
                        newCustomer.name = item.name
                        newCustomer.parentName = item.parentName
                        newCustomer.industry = item.industryName
                        newCustomer.address = item.address
                        newCustomer.manager = item.manager
                        newCustomer.managerPhone = item.managerPhone
                        newCustomer.urgentContact = item.urgentContact
                        newCustomer.urgentContactPhone = item.urgentContactPhone
                        newCustomer.owner = item.owner
                        newCustomer.ownerPhone = item.ownerPhone
                        this.tableData.push(newCustomer)
                    })
                    // 对分页器进行渲染
                    this.customerTotalNum = this.customerPageData.total
                })
        },
        //发送请求##### 导出表格(临时)
        exportFile () {
            let value = {
                keyword: this.searchValue,
                parentId: this.treeNode,
                industry: this.businessType,
            }
            // this.$store.dispatch("getExportFileCustomer", value)
            exportCustomerReport(value).then((response) => {
                var blob = new Blob([response], {
                    type: "application/vnd.ms-excel,charset=utf-8",
                }) //创建一个blob对象
                var a = document.createElement("a") //创建一个<a></a>标签
                a.href = URL.createObjectURL(blob) // response is a blob
                a.download = "客户管理.xls" //文件名称
                a.style.display = "none"
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        },
        // 点击《导入》按钮触发弹窗，子组件是公用组件
        importFile () {
            this.$refs.importFileRef.show({
                name: "Customer",
                ownerId: this.treeNode,
            })
        },

        //发送请求##### 被子组件调用，下载示例模板
        downLoad (name, type) {
            let value = {
                name: "Customer",
                type: type,
            }
            this.$store.dispatch("getDownloadTemplate", value)
        },
        //发送请求##### 更改树的节点
        changeTreeNode (val) {
            this.currentPage = 1
            this.treeNode = val.id
            this.treeFullNodeData = val
            this.getMyData(
                this.currentPage,
                this.pageSize,
                this.searchValue,
                this.businessType,
                this.treeNode
            )
            if (this.nodeData <= 10000) {
                this.no4class = "btn4Gray"
            } else {
                this.no4class = "btn4"
            }

            // 进行树的级别判断，渲染面包屑
            if (val.level === 0) {
                this.breadName = "客户管理 / " + val.name
            } else if (val.level === 1) {
                this.breadName = "客户管理 / " + val.city + " / " + val.name
            } else if (val.level === 2) {
                this.$store.dispatch("getCustomerOwnData", val.parentId).then(() => {
                    this.breadName =
                        "客户管理 / " +
                        val.city +
                        " / " +
                        this.customerOwnData.name +
                        " / " +
                        val.name
                })
            } else if (val.level === 3) {
                this.$store.dispatch("getCustomerOwnData", val.parentId).then(() => {
                    const fatherOne = this.customerOwnData.name
                    const newFatherId = this.customerOwnData.parentId
                    this.$store.dispatch("getCustomerOwnData", newFatherId).then(() => {
                        this.breadName =
                            "客户管理 / " +
                            val.city +
                            " / " +
                            this.customerOwnData.name +
                            " / " +
                            fatherOne +
                            " / " +
                            val.name
                    })
                })
            } else if (val.level === 4) {
                this.$store.dispatch("getCustomerOwnData", val.parentId).then(() => {
                    const fatherOne = this.customerOwnData.name
                    const newFatherId = this.customerOwnData.parentId
                    this.$store.dispatch("getCustomerOwnData", newFatherId).then(() => {
                        const fatherTwo = this.customerOwnData.name
                        const newFatherId2 = this.customerOwnData.parentId
                        this.$store
                            .dispatch("getCustomerOwnData", newFatherId2)
                            .then(() => {
                                this.breadName =
                                    "客户管理 / " +
                                    val.city +
                                    " / " +
                                    fatherTwo +
                                    " / " +
                                    this.customerOwnData.name +
                                    " / " +
                                    fatherOne +
                                    " / " +
                                    val.name
                            })
                    })
                })
            }
        },
        //发送请求##### 更改所属行业的值
        selectChanged (val) {
            this.businessType = val
            this.getMyData(
                this.currentPage,
                this.pageSize,
                this.searchValue,
                val,
                this.treeNode
            )
        },

        // 删除表格中的节点，其中val为它在该页面的顺序(点击《删除》按钮)
        handleDelete (index) {
            const daleteId = this.customerPageData.records[index].id
            this.$confirm("是否永久删除该客户？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$store.dispatch("deleteCustomerInTable", daleteId).then(() => {
                    if (this.ifDeleted === 0) {
                        this.$message({ message: "删除成功！", type: "success" })
                        this.$refs.treeRef.refreshTheTree()
                        this.refreshThePage()
                    } else if (this.ifDeleted === -1) {
                        this.$message({ message: "删除失败！请检查网络", type: "warning" })
                    }
                })
            })
        },

        // 对该项进行详情编辑,其中val为它在该页面的顺序(点击《编辑》按钮)
        handleEdit (index) {
            // const data = JSON.parse(
            //   JSON.stringify(this.customerPageData.records[index])
            // );
            const data = this.customerPageData.records[index]
            this.$refs.dialogRef.showForEdit(data)
        },
        // 进入下属配电房详情页（点击《详情》按钮）
        handleDetail (index) {
            const data = this.customerPageData.records[index]
            this.$refs.dialogDetailRef.show(data)
        },
    },

    //处理state数据
    computed: {
        ...mapState({
            customerPageData: (state) => {
                return state.customer.customerPageData.data
            },
            ifDeleted: (state) => {
                return state.customer.ifDeleted.errorCode
            },
            ifSuccessDownLoad: (state) => {
                return state.customer
            },
            customerOwnData: (state) => {
                return state.customer.customerOwnData.data
            },
        }),
    },

    // 发送请求##### 加载页面
    mounted () {
        this.getMyData(
            this.currentPage,
            this.pageSize,
            this.searchValue,
            this.businessType,
            this.treeNode
        )
    },
}
</script>

<style lang="less" scoped>
// 板块二##############################################################
.blockTwo {
    // height: 96.8rem;
    // width: 171.2rem;
    // display: flex;
    // // position: relative;
    // background-color: #18191a;
    // transition: width 0.5s;
    // // background-color: red;
    // overflow: hidden;
}

// 板块二左侧#####################################################
.blockTwoLeft {
    // overflow: hidden;
    // width: 0rem;
    // // opacity: 0;
    // transition: width 0.5s;
    // height: 96.8rem;
    // margin-right: 1.4rem;
    // // background-color: red;
    // &::after {
    //     height: 15rem;
    //     width: 3rem;
    //     border-top-right-radius: 5rem;
    //     border-bottom-right-radius: 5rem;
    //     display: block;
    //     content: ">";
    //     line-height: 15rem;
    //     background-color: #113455;
    //     cursor: pointer;
    //     color: #fff;
    //     font-size: 3rem;
    //     position: absolute;
    //     top: 30rem;
    //     left: -2.6rem;
    //     z-index: 3;
    // }
}
.blockTwoLeft2 {
    // overflow: hidden;
    // width: 26rem;
    // height: 96.8rem;
    // position: absolute;
    // left: 0;
    // top: 0;
    // overflow: hidden;
    // width: 26rem;
    // height: 96.8rem;
    // margin-right: 2.4rem;
    // transition: width 0.5s;
    // &::after{
    //   height: 15rem;
    //   width: 3rem;
    //   border-top-right-radius: 5rem;
    //   border-bottom-right-radius: 5rem;
    //   display: block;
    //   content: ">";
    //   line-height: 15rem;
    //   background-color: #0d3050;
    //   cursor: pointer;
    //   color: #fff;
    //   font-size: 3rem;
    //   position: absolute;
    //   top: 30rem;
    //   left: -2.4rem;
    //   opacity: 0;
    // }
}

// 板块二右侧#####################################################
.blockTwoRight {
    // width: 90%;
    // z-index: 2;
    // width: 171.2rem;
    // height: 96.8rem;
    // transition: width 0.5s;
    // background-color: #18191a;
    // margin-left: -2.4rem;
    // padding-left: 2.4rem;
}
.blockTwoRight2 {
    // width: 142.8rem;
    // height: 96.8rem;
    // transition: width 0.5s;
    // background-color: #18191a;
    // width: 142.8rem;
    // height: 96.8rem;
    // position: absolute;
    // left: 28.4rem;
    // top: 0;
}

.topBar {
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    box-sizing: border-box;
    height: 8rem;
    width: 100%;
    // width: 142.8rem;
    background-color: #0d3050;
    border-radius: 0.4rem;
    // position: absolute;
    // top: 0;
    // left: 0;
    overflow: hidden;
    .searchInput {
        height: 3.2rem;
        width: 22.8rem;
        font-size: 1.4rem;
        position: absolute;
        left: 2.4rem;
        top: 2.4rem;
        background-color: #0c375e;
        border: 1px solid #004579;
        border-radius: 0.2rem;
        box-sizing: border-box;
        outline: 0;
        color: #fff;
        text-indent: 1.4rem;
        &:focus {
            border: 0.1rem solid #008080;
        }
    }

    button {
        height: 3.2rem;
        width: 7.4rem;
        border-radius: 0.2rem;
        border: none;
        color: #fff;
        font-size: 1.4rem;
        cursor: pointer;
    }
    .btn1 {
        background-color: #027969;
        position: absolute;
        top: 2.4rem;
        left: 26rem;
    }
    .btn2 {
        background-color: #0c375e;
        position: absolute;
        top: 2.4rem;
        right: 18.9rem;
        border: 0.1rem solid #004579;
    }
    .btn2[disabled] {
        color: gray;
        cursor: not-allowed;
    }
    .btn3 {
        background-color: #0c375e;
        position: absolute;
        top: 2.4rem;
        right: 10.6rem;
        border: 0.1rem solid #004579;
    }
    .btn4 {
        background-color: #027969;
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
    }
    .btn4[disabled] {
        background-color: gray;
        cursor: not-allowed;
    }
    .btn4Gray {
        background-color: gray;
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
    }
    .businessType {
        height: 3.2rem;
        width: 20.8rem;
        position: absolute;
        left: 36rem;
        top: 2.4rem;
    }
}
.businessSelector {
    margin: 0 2rem;
}
// 注意：table内部的样式有一部分是以回调函数的形式进行更改的，在methods当中，一部分在内联样式，还有一部分在css
.customerTablePlace {
    height: 86.4rem;
    // width: 142.8rem;
    width: 100%;
    // position: absolute;
    // top: 10.4rem;
    // left: 0;
    overflow: hidden;
    background-color: #0d3050;
    .bread {
        height: 5rem;
        width: 142.8rem;
        line-height: 5rem;
        color: #fff;
        // 临时
        font-size: 1.4rem;
        text-indent: 2.4rem;
    }
    .table {
        height: 57.2rem;
        // width: 138rem;
        // width: 100%-2.4rem;
        margin: 0 2.4rem 0 2.4rem;
        // overflow: hidden;
        // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
        .el-table td,
        .el-table th.is-leaf {
            border-bottom: 0.1rem solid #254561;
        }
        .el-table--border:after,
        .el-table--group:after,
        .el-table:before {
            opacity: 0;
        }
        .el-table__body-wrapper {
            background-color: #0d3050;
        }
        .el-table__cell {
            background-color: #0d3050;
        }
        .el-button--text {
            color: #03d9bc;
        }
    }
}

.pagination {
    float: right;
    margin-right: 5rem;
    margin-top: 1rem;
}

// 板块三(登记表单)####################################################
.diaLogPlace {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
}
</style>

<style lang="less" scoped>
// 表格样式修改（全局）#############################################
.customerTablePlace {
    height: 86.4rem;
    // width: 142.8rem;
    // position: absolute;
    // top: 10.4rem;
    // left: 0;
    overflow: hidden;
    background-color: #0d3050;
    .bread {
        height: 5rem;
        width: 142.8rem;
        line-height: 5rem;
        color: #fff;
        // 临时
        font-size: 1.4rem;
        text-indent: 2.4rem;
    }
    .table {
        height: 57.2rem;
        // width: 138rem;
        margin: 0 2.4rem 0 2.4rem;
        // overflow: hidden;
        // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
        .el-table td,
        .el-table th.is-leaf {
            border-bottom: 0.1rem solid #254561;
        }
        .el-table--border:after,
        .el-table--group:after,
        .el-table:before {
            opacity: 0;
        }
        .el-table__body-wrapper {
            background-color: #0d3050;
        }
        .el-table__cell {
            background-color: #0d3050;
        }
        .el-button--text {
            color: #03d9bc;
        }
    }
}
</style>
