<template>
  <div class="regionEnergy">
    <div class="gen-header">
      <div class="form">
        <el-form :inline="true" :model="dataForm" class="demo-form-inline">
          <el-form-item label="指标分类" class="powerStation">
            <el-select
              class="styleWid"
              size="mini"
              v-model="dataForm.type"
              placeholder="请选择指标"
              @change="indexChange"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="select-time">
            <!-- 日 -->
            <el-date-picker
              class="styleWid"
              size="mini"
              v-show="showDay"
              v-model="generalDay"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              @change="changeDate"
            ></el-date-picker>
            <!-- 月 -->
            <el-date-picker
              v-show="showMonth"
              class="styleWid"
              size="mini"
              v-model="generalDay"
              value-format="yyyy-MM"
              type="month"
              placeholder="选择月"
              @change="changeDate"
            ></el-date-picker>
            <!-- 年 -->
            <el-date-picker
              v-show="showYear"
              class="styleWid"
              size="mini"
              v-model="generalDay"
              value-format="yyyy"
              type="year"
              placeholder="选择年"
              @change="changeDate"
            ></el-date-picker>
            <div class="dateChoose">
              <div :class="btnTypeDay" @click="changeTab(0)">日</div>
              <div :class="btnTypeMonth" @click="changeTab(1)">月</div>
              <div :class="btnTypeYear" @click="changeTab(2)">年</div>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button
              class="btnColor"
              size="mini"
              type="primary"
              @click="onSubmit"
            >
              查询
            </el-button>
            <!-- <el-button class="btnMargin" size="mini" type="primary" @click="outFile">导出</el-button> -->
          </el-form-item>
          <el-button
            class="btnMargin"
            size="mini"
            type="primary"
            @click="outFile"
          >
            导出
          </el-button>
        </el-form>
      </div>
    </div>
    <div class="fire">
      <div class="tree">
        <TreeComParison />
      </div>
    </div>
    <div class="energyTable">
      <div v-if="loading" class="loading-mask">
        <el-spinner size="large"></el-spinner>
      </div>
      <div class="unit">
        当前统计指标：
        <span>{{ unit }}</span>
      </div>
      <div class="table">
        <el-table
          :data="dataShow"
          style="width: 100%;"
          stripe:false
          height="68rem"
          border:false
          :cell-style="cellStyle"
          :header-cell-style="headerClass"
          v-if="showDay1"
          empty-text=""
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
            prop="name"
            width="140"
            label="区域名称"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{row}">
              <el-tooltip effect="dark" :content="row.fullName" placement="top">
                <span class="ellipsis">{{row.name}}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="data[0]"
            label="0时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[1]"
            label="1时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[2]"
            label="2时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[3]"
            label="3时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[4]"
            label="4时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[5]"
            label="5时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[6]"
            label="6时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[7]"
            label="7时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[8]"
            label="8时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[9]"
            label="9时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[10]"
            label="10时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[11]"
            label="11时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[12]"
            label="12时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[13]"
            label="13时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[14]"
            label="14时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[15]"
            label="15时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[16]"
            label="16时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[17]"
            label="17时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[18]"
            label="18时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[19]"
            label="19时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[20]"
            label="20时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[21]"
            label="21时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[22]"
            label="22时"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[23]"
            label="23时"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table>
        <el-table
          :data="dataShow"
          style="width: 100%;"
          stripe:false
          height="68rem"
          border:false
          :cell-style="cellStyle"
          :header-cell-style="headerClass"
          v-if="showMonth1"
          empty-text=""
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
            prop="name"
            width="140"
            label="区域名称"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{row}">
              <el-tooltip effect="dark" :content="row.fullName" placement="top">
                <span class="ellipsis">{{row.name}}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="data[0]"
            label="1号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[1]"
            label="2号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[2]"
            label="3号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[3]"
            label="4号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[4]"
            label="5号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[5]"
            label="6号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[6]"
            label="7号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[7]"
            label="8号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[8]"
            label="9号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[9]"
            label="10号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[10]"
            label="11号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[11]"
            label="12号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[12]"
            label="13号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[13]"
            label="14号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[14]"
            label="15号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[15]"
            label="16号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[16]"
            label="17号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[17]"
            label="18号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[18]"
            label="19号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[19]"
            label="20号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[20]"
            label="21号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[21]"
            label="22号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[22]"
            label="23号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[23]"
            label="24号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[24]"
            label="25号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[25]"
            label="26号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[26]"
            label="27号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[27]"
            label="28号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[28]"
            v-if="monthLen > 28"
            label="29号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[29]"
            v-if="monthLen > 29"
            label="30号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[30]"
            v-if="monthLen > 30"
            label="31号"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table>
        <el-table
          :data="dataShow"
          style="width: 100%;"
          stripe:false
          height="68rem"
          border:false
          :cell-style="cellStyle"
          :header-cell-style="headerClass"
          v-if="showYear1"
          empty-text=""
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
            prop="name"
            width="140"
            label="区域名称"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{row}">
              <el-tooltip effect="dark" :content="row.fullName" placement="top">
                <span class="ellipsis">{{row.name}}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="data[0]"
            label="1月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[1]"
            label="2月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[2]"
            label="3月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[3]"
            label="4月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[4]"
            label="5月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[5]"
            label="6月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[6]"
            label="7月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[7]"
            label="8月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[8]"
            label="9月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[9]"
            label="10月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[10]"
            label="11月"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="data[11]"
            label="12月"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table>
      </div>
      <!-- 分页器部分 -->
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="customerTotalNum"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TreeComParison from './statisticalTreeRegion.vue'
import { tableBg } from '@/mixin/tablebg'
import { exportEnergyRegionReport } from '@/api/repair'

export default {
  name: 'TimeAnalyze',
  mixins: [tableBg],
  components: { TreeComParison },
  data() {
    return {
      value1: '',
      dataForm: {
        type: '',
        datetype: 1,
      },
      typeList: [
        {
          id: 1,
          name: '电量',
        },
        {
          id: 2,
          name: '碳排放量',
        },
        {
          id: 3,
          name: '标准煤',
        },
        {
          id: 4,
          name: '折算费用',
        },
      ],
      // 按钮的样式
      btnTypeDay: 'btnGreen',
      btnTypeMonth: 'btnDark',
      btnTypeYear: 'btnDark',
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      customerTotalNum: 0,
      // 数组内套数组，每个数组就是一页数据
      totalPageData: [],
      // 共几页
      pageNum: 1,
      // 当前要显示的数据
      dataShow: [],
      timeValue: '',
      showDay: true,
      showMonth: false,
      showYear: false,
      showDay1: true,
      showMonth1: false,
      showYear1: false,
      generalDay: '',
      unit: '电量（kWh）',
      customerId: '',
      customerName: '',
      floorIds: '',
      flag: false,
      monthLen: '',
      whichTab: 0,
      loading: false,
    }
  },
  created() {
    this.getToday()
    this.dataForm.type = 1
    this.dataForm.datetype = 1
  },
  mounted() {
    this.flag = true
    // setTimeout(() => {
    //   this.initRegionEnergy()
    // }, 1000)
  },
  methods: {
    onSubmit() {
      if (!this.dataForm.type) {
        this.$message({
          message: '请选择指标！',
          type: 'warning',
        })
        return
      }
      if (!this.generalDay) {
        this.$message({
          message: '请选择统计时间！',
          type: 'warning',
        })
        return
      }
      this.initRegionEnergy()
    },
    outFile() {
      this.customerId = this.$store.state.storage.customerId
      let date = this.generalDay
      if (this.whichTab == 1) {
        date = date.split('-')[0] + '-' + date.split('-')[1]
      } else if (this.whichTab == 2) {
        date = date.split('-')[0]
      } else if (this.whichTab == 0) {
        date = this.generalDay
      }
      let value = {
        customerId: this.customerId,
        date: date,
        datetype: this.dataForm.datetype,
        type: this.dataForm.type,
        floorIds: this.floorIds,
      }
      let type = ''
      this.typeList.forEach((item) => {
        if (item.id == this.dataForm.type) {
          type = item.name
        }
      })
      // this.$store.dispatch("exportRegion", value);
      exportEnergyRegionReport(value).then((response) => {
        var blob = new Blob([response], {
          type: 'application/vnd.ms-excel,charset=utf-8',
        }) //创建一个blob对象
        var a = document.createElement('a') //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob) // response is a blob
        a.download = `${this.customerName}${type}${date}区域用能.xls` //文件名称
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    },
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize
      // 重新计算页数并分组
      this.calcPageData()
    },
    handleCurrentChange(newPageNum) {
      this.currentPage = newPageNum
      this.dataShow = this.totalPageData[newPageNum - 1]
    },
    // 对tableData进行处理，分为每一页的数组
    calcPageData() {
      // 计算页数
      if (this.tableData.length > 1) {
        this.pageNum = Math.ceil(this.tableData.length / this.pageSize) || 1
      }
      // 数据分组，循环的次数是总页数
      for (let i = 0; i < this.pageNum; i++) {
        this.totalPageData[i] = this.tableData.slice(
          this.pageSize * i,
          this.pageSize * (i + 1),
        )
      }
      this.dataShow = this.totalPageData[this.currentPage - 1]
    },
    getToday() {
      let today = new Date()
      let day = today.getDate()
      let month = today.getMonth() + 1
      let year = today.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      if (month < 10) {
        month = '0' + month
      }
      this.generalDay = year + '-' + month + '-' + day
    },
    // 切换年月日的回调
    changeTab(index) {
      this.getToday()
      this.whichTab = index
      this.dataForm.datetype = index + 1
      this.showInterval = false
      if (index === 0) {
        this.btnTypeDay = 'btnGreen'
        this.btnTypeMonth = 'btnDark'
        this.btnTypeYear = 'btnDark'
        this.showDay = true
        this.showMonth = false
        this.showYear = false
      } else if (index === 1) {
        this.btnTypeDay = 'btnDark'
        this.btnTypeMonth = 'btnGreen'
        this.btnTypeYear = 'btnDark'
        this.showDay = false
        this.showMonth = true
        this.showYear = false
      } else if (index === 2) {
        this.btnTypeDay = 'btnDark'
        this.btnTypeMonth = 'btnDark'
        this.btnTypeYear = 'btnGreen'
        this.showDay = false
        this.showMonth = false
        this.showYear = true
      }
      this.onSubmit()
    },
    changeDate() {
      this.onSubmit()
    },
    indexChange(index) {
      this.initRegionEnergy()
      // console.log(index);
      // if (index == 1) {
      //   this.unit = "电量（kWh）";
      // } else if (index == 2) {
      //   this.unit = "碳排放量（kg）";
      // } else if (index == 3) {
      //   this.unit = "标准煤（kg）";
      // } else if (index == 4) {
      //   this.unit = "折算费用（元）";
      // }
    },
    initRegionEnergy() {
      this.customerId = this.$store.state.storage.customerId
      let date = this.generalDay
      if (this.whichTab == 1) {
        date = date.split('-')[0] + '-' + date.split('-')[1]
      } else if (this.whichTab == 2) {
        date = date.split('-')[0]
      } else if (this.whichTab == 0) {
        date = this.generalDay
      }
      let value = {
        customerId: this.customerId,
        date: date,
        datetype: this.dataForm.datetype,
        type: this.dataForm.type,
        floorIds: this.floorIds,
      }
      this.loading = true
      this.dataShow = []
      this.$store.dispatch('getEnergyReportByRegion', value).then(() => {
        // console.log(this.$store.state.elecManagement.getEnergyReportByRegion);
        if (
          this.$store.state.elecManagement.getEnergyReportByRegion.errorCode ==
          0
        ) {
          this.tableData = this.$store.state.elecManagement.getEnergyReportByRegion.data
          this.customerTotalNum = this.tableData.length
          if (this.dataForm.datetype == 2) {
            // this.monthLen = this.tableData[0].data.length;
            let dateArr = this.generalDay.split('-')
            let year = dateArr[0]
            let month = dateArr[1] / 1
            if (month > 7) {
              this.monthLen = month % 2 == 0 ? 31 : 30
            } else if (month == 2) {
              this.monthLen = year % 4 == 0 ? 29 : 28
            } else {
              this.monthLen = month % 2 == 0 ? 30 : 31
            }
          }
          if (this.dataForm.datetype === 1) {
            this.showDay1 = true
            this.showMonth1 = false
            this.showYear1 = false
          } else if (this.dataForm.datetype === 2) {
            this.showDay1 = false
            this.showMonth1 = true
            this.showYear1 = false
          } else if (this.dataForm.datetype === 3) {
            this.showDay1 = false
            this.showMonth1 = false
            this.showYear1 = true
          }
          if (this.dataForm.type == 1) {
            this.unit = '电量（kWh）'
          } else if (this.dataForm.type == 2) {
            this.unit = '碳排放量（kg）'
          } else if (this.dataForm.type == 3) {
            this.unit = '标准煤（kg）'
          } else if (this.dataForm.type == 4) {
            this.unit = '折算费用（元）'
          }
          this.calcPageData()
        } else {
          this.tableData = []
          this.dataShow = []
        }
      }).finally(() => {
        this.loading = false
      })
    },
    checkedKeys(array) {
      console.log('this.floorIds', this.floorIds)
      if (array.length > 0) {
        this.floorIds = array.join(',')
      } else {
        this.floorIds = ''
      }
      console.log('array', array)
    },
  },
  watch: {
    '$store.state.storage.customerData': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return
        // if (!this.flag) return;
        this.customerId = newVal.id
        this.customerName = newVal.name
        this.tableData = []
        // setTimeout(() => {
        //   this.initRegionEnergy()
        // }, 300)
      },
    },
  },
}
</script>

<style lang="less" scoped>
.regionEnergy {
  height: 100%;
  overflow: hidden;
  .fire {
    width: 22rem;
    height: 73rem;
    border-radius: 0.4rem;
    float: left;
    margin-top: 7rem;
    .tree {
      height: 78.5rem;
      width: 22rem;
      overflow: hidden;
      position: absolute;
      z-index: 100;
      margin-top: 8rem;
      top: 0;
      left: 0;
    }
  }
  .energyTable {
    // width: 120.5rem;
    width: calc(100% - 23rem + 0.6rem);
    height: 80rem;
    margin-top: 1rem;
    margin-left: 23rem;
    border-radius: 0.4rem;
    background-color: #0d3050;
    padding-top: 2rem;
    .unit {
      color: #ffffff;
      opacity: 0.85;
      font-size: 1.4rem;
      float: left;
      margin: 0 0 2rem 2.4rem;
      span {
        opacity: 1;
      }
    }
    .table {
      width: auto;
      height: 52rem;
      margin: 0 2.4rem;
    }
    .pagination {
      float: right;
      margin-top: 1.5rem;
      margin-right: 2rem;
    }
  }
}
.gen-header {
  // width: 143.6rem;
  width: 100%;
  height: 6.4rem;
  line-height: 6.4rem;
  background: #0d3050;
  border-radius: 4px;
  .styleWid {
    width: 17rem;
  }
  .form {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: inline-block;
    margin:1.5rem 0 0 2rem;
    ::v-deep .el-form-item__content {
      line-height: 1;
    }
    ::v-deep .el-form-item__label {
      line-height: 3.2rem;
    }
    .btnColor {
      background: #027969;
    }
    .btnMargin {
      // margin-left: 60rem;
      margin-right: 10rem;
      float: right;
    }
  }
  .select-time {
    width: 37rem;
    .styleWid {
      width: 17rem;
    }
    .dateChoose {
      height: 28px;
      width: 13.8rem;
      border-radius: 0.2rem;
      overflow: hidden;
      border: 0.1rem solid #004579;
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
      color: #fff;
      margin-left: 2rem;
      .btnDark {
        height: 28px;
        width: 4.6rem;
        background-color: #0b375e;
        font-size: 1.4rem;
        padding: 0;
        margin: 0;
        cursor: pointer;
        line-height: 28px;
        text-align: center;
      }
      .btnGreen {
        height: 28px;
        width: 4.6rem;
        background-color: #027969;
        font-size: 1.4rem;
        padding: 0;
        margin: 0;
        cursor: pointer;
        line-height: 28px;
        text-align: center;
      }
    }
  }
}
</style>
<style lang="less">
.regionEnergy {
  .el-form-item__label {
    font-size: 1.4rem !important;
  }
  .el-date-editor .el-range-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: 0;
    padding: 0;
    width: 39%;
    color: #fff;
  }
  // 滚动条的宽度
  ::-webkit-scrollbar {
    width: 0.8rem; // 横向滚动条
    height: 0.8rem; // 纵向滚动条 必写
  }
  // 滚动条的滑块
  ::-webkit-scrollbar-thumb {
    background-color: #027969 !important;
    border-radius: 3px;
  }
  .select-time {
    .el-form-item__content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  .el-input__inner {
    color: rgba(255, 255, 255, 0.9);
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #02ad96;
    border-color: #02ad96;
  }
  margin-top: 0.5rem;
  .el-table {
    overflow: hidden;
    height: 48rem !important;
  }
  .TreeStatistical .treeContainer {
    height: 54rem;
  }
  .el-table__body-wrapper {
    height: 42.7rem !important;
  }
  .el-button--primary {
    color: #fff;
    border-color: #027969;
    background-color: #027969;
  }
  .el-table tr {
    background: rgba(0, 115, 222, 0.1);
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #0c2f4f;
  }
  .el-table .el-table__cell {
    padding: 1.3rem 0;
  }
  .el-table thead {
    color: #ffffff;
    font-weight: 500;
  }
  .el-table--border th.el-table__cell,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #0c2f4f;
  }
  .el-table thead.is-group th.el-table__cell {
    background: #0c2f4f;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: 1px solid #0c2f4f;
  }
  .el-table--border,
  .el-table--group {
    border: 1px solid #0c2f4f;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: '';
    position: absolute;
    background-color: #0c2f4f;
    z-index: 1;
  }
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 1.2rem;
  }
  .el-table .caret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 34px;
    width: 0px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
  }
  // 滚动条的宽度
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 0.8rem; // 横向滚动条
    height: 0.8rem; // 纵向滚动条 必写
    background-color: #0c2f4f;
  }
  // 滚动条的滑块
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #0c2f4f;
  }
  .el-table__empty-text {
    display: none;
  }
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
</style>
