<template>
  <div class="TreeStatistical">
    <div class="checkContainer">
      <el-checkbox v-model="checkSrictly" @change="handelCheckSrictly">是否开启级联</el-checkbox>
    </div>
    <!--el树形控件--输入框  -->
    <div class="inputContainer">
      <el-input size="small" placeholder="请输入关键字" v-model="filterText" style="width:22.5rem;" clearable></el-input>
    </div>
    <!--el树形控件--树  -->
    <!-- @node-click="chooseTree"  -->
    <div class="treeContainer">
      <el-tree :show-checkbox="true" :expand-on-click-node="false" @node-click="chooseTree" class="filter-tree elTree" :data="treeData" node-key="id" :default-checked-keys="defaultCheckedKeys" current-node-key="this.currentNodeKey" highlight-current :props="defaultProps" default-expand-all :filter-node-method="filterNode" :render-content="renderContent" ref="tree" :check-strictly="!checkSrictly" @check="nodeSelect"></el-tree>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TreeStatistical",
  // 监听过滤树的搜索
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "$store.state.storage.customerId": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        this.$store.state.storage.customerId = newVal;
        this.getLocalId();
      },
    },
    "$store.state.storage.localtionId": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        this.$store.state.storage.localtionId = newVal;
        // this.getLocalId();
      },
    },
  },
  mounted() {
    // this.getLocalId()
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    getLocalId() {
      this.dataForm.buildId = this.$store.state.storage.customerId;
      // 获取时段分析左侧数据
      this.$store.dispatch("getChildrenLocation", this.dataForm.buildId).then(() => {
        // this.treeData = this.getEquipmentBuilding;
        this.treeData = this.$store.state.elecManagement.getChildrenLocation.data;
        // console.log(this.treeData);
        if (this.treeData.length == 0) {
          return;
        } else {
          // console.log(this.treeData);
          // this.currentNodeKey = this.treeData[0].children[0].id;
          this.$nextTick(() => {
            this.$refs.tree.setCheckedNodes(this.treeData);
            let keys = [];
            for (let i = 0; i < this.treeData.length; i++) {
              const item = this.treeData[i];
              if (item.children.length > 0) {
                for (let j = 0; j < item.children.length; j++) {
                  if (item.children[j].type == 6) {
                    keys.push(item.children[j].id);
                  }
                }
              }
            }
            // this.nodeSelect1(keys);
            console.log('getCheckedNodes',this.$refs.tree.getCheckedKeys());
            this.nodeSelect1(this.$refs.tree.getCheckedKeys());
            // 设置第一层默认选中
            // this.$refs.tree.setCurrentKey(this.treeData[0].children[0].id);
          });
          // this.$store.commit("setLocaltionId", this.currentNodeKey);
        }
        // console.log(this.treeData);
      });
    },
    // 点击树的分支
    chooseTree(data) {
      // console.log(data);
      this.$store.commit("setLocaltionId", data.id);
      // this.getLocalId();
    },
    nodeSelect(nodeData, checkedData) {
      //nodeData:当前操作的节点
      //checkedData:包含checkedNodes、checkedKeys、halfCheckedNodes、halfCheckedKeys
      console.log(checkedData.checkedKeys);
      this.$parent.checkedKeys(checkedData.checkedKeys);
      this.$parent.initRegionEnergy();
    },
    nodeSelect1(checkedKeys) {
      //nodeData:当前操作的节点
      //checkedData:包含checkedNodes、checkedKeys、halfCheckedNodes、halfCheckedKeys
      this.$parent.checkedKeys(checkedKeys);
      this.$parent.initRegionEnergy();
    },
    //兄弟组件或者父组件要求刷新树
    // refreshTheTree(){
    //   this.$store.dispatch('getCustomerTree').then(()=>{
    //   this.treeData = this.customerTree
    // })
    // },

    // 树的提示语
    renderContent(h, { node, data, store }) {
      if (node.label.length > 10) {
        return (
          <div style="font-size: 1.4rem;">
            <el-tooltip class="item" effect="dark" placement="right">
              <div slot="content">{node.label}</div>
              <p style="width:15rem; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;">{node.label}</p>
            </el-tooltip>
          </div>
        );
      } else {
        return (
          <div style="font-size: 1.4rem;">
            <p>{node.label}</p>
          </div>
        );
      }
    },
    //当绑定值变化时触发的事件
    handelCheckSrictly(val) {
      console.log('handelCheckSrictly', val)
      
      this.getLocalId();
    }
  },

  data() {
    return {
      dataForm: {
        buildId: "",
      },
      filterText: "",
      currentNodeKey: "",
      // 树的内容（伪数据）
      treeData: [],
      // 树的props参数，已经与后端对接，勿动
      defaultProps: { children: "children", label: "name", id: "id" },
      defaultCheckedKeys: [],
      flag: true,
      checkSrictly: true,//是否开启级联
    };
  },
  computed: {
    ...mapState({
      getChildrenLocation: (state) => {
        return state.elecManagement.getChildrenLocation.data;
      },
      customerTree: (state) => {
        return state.equipment.customerTree.data;
      },
    }),
  },
  //发送请求,获取客户树
  created() {
    this.dataForm.buildId = this.$store.state.storage.customerId;
  },
};
</script>

<style lang="less">
.TreeStatistical {
  height: 88rem;
  width: 22rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  .checkContainer {
    height: 2rem;
    width: 100%;
    position: relative;
    left: 1.6rem;
    display: flex;
    padding-top: 0.5rem;
  }
  // 输入框位置
  .inputContainer {
    height: 5rem;
    width: 18.8rem;
    position: relative;
    // top: 15px !important;
    left: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .elInput {
      height: 3.2rem;
      width: 18.8rem;
      position: absolute;
      top: 2.4rem;
      left: 1.6rem;
      font-size: 1.4rem;
      background-color: #0c375e;
      border: 0.1rem solid #004579;
      box-sizing: border-box;
      border-radius: 0.2rem;
      text-indent: 1.2rem;
      color: #fff;
      outline: 0;
      &:focus {
        border: 0.1rem solid #008080;
      }
    }
  }
  // 树的位置
  .treeContainer {
    height: 90rem;
    width: 27rem;
    overflow: scroll;
    text-overflow: ellipsis;
    word-wrap: nowrap;
    // position: absolute;
    // top: 6.8rem;
    // left: 0;

    .elTree {
      background-color: #0d3050;
      color: rgba(255, 255, 255, 0.85);

      .el-tree-node__label {
        font-size: 1.4rem;
        line-height: 2.2rem;
        height: 2.2rem;
        // 禁止选中文字
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }
      .el-tree-node__content:hover {
        background-color: #204b73;
      }
      .el-tree-node:focus > .el-tree-node__content {
        // background-color: #18191a;
        background-image: linear-gradient(to right, #0d3050, #18191a);
        // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
        // box-sizing: border-box;
      }
    }
  }
  // 持续高亮(鼠标离开树以后)
  .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-image: linear-gradient(to right, #0d3050, #18191a);
    // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
    // box-sizing: border-box;
  }
}
</style>
