<template>
  <div class="TreeComparisonAnalyze">
    <!--el树形控件--输入框  -->
    <div class="inputContainer">
      <el-input
        size="small"
        placeholder="请输入关键字"
        v-model="filterText"
        style="width:22.5rem;"
        clearable
      ></el-input>
    </div>
    <!--el树形控件--树  -->
    <!-- @node-click="chooseTree"  -->
    <div class="treeContainer">
      <el-tree
       :indent="0"
        :check-strictly="true"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        @node-click="chooseTree"
        class="filter-tree elTree tree-line"
        :data="treeData"
        node-key="id"
        :default-checked-keys="defaultCheckedKeys"
        current-node-key="this.currentNodeKey"
        highlight-current
        :props="defaultProps"
        default-expand-all
        :filter-node-method="filterNode"
        :render-content="renderContent"
        ref="tree"
      ></el-tree>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TreeComparisonAnalyze",
  // 监听过滤树的搜索
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "$store.state.storage.customerId": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        this.$store.state.storage.customerId = newVal;
        this.getLocalId();
      },
    },
    "$store.state.storage.localtionId": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        this.$store.state.storage.localtionId = newVal;
        // this.getLocalId();
      },
    },
  },
  mounted() {
    // this.getLocalId()
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    getLocalId() {

      this.dataForm.buildId = this.$store.state.storage.customerId;
      // 获取时段分析左侧数据
      this.$store.dispatch("getChildrenLocation", this.dataForm.buildId).then(() => {
        // this.treeData = this.getEquipmentBuilding;
        this.treeData = this.$store.state.elecManagement.getChildrenLocation.data;
        // console.log(this.treeData,this.treeData.length == 0);
        if (this.treeData.length == 0) {
          this.$store.commit("setLocaltionId", '0');
          return;
        } else {
          // console.log(this.treeData);
          this.currentNodeKey = (this.treeData[0].children[0] && this.treeData[0].children[0].id) || '0';
          this.$store.commit("setLocaltionId", this.currentNodeKey);
          if (this.currentNodeKey != '0') {
            this.$nextTick(() => {
              // 设置第一层默认选中
              this.$refs.tree.setCurrentKey(this.treeData[0].children[0].id);
            });
          }
        }
        // console.log(this.treeData);
      });
    },
    // 点击树的分支
    chooseTree(data) {
      this.$store.commit("setLocaltionId", data.id);
      // this.getLocalId();
    },
    //兄弟组件或者父组件要求刷新树
    // refreshTheTree(){
    //   this.$store.dispatch('getCustomerTree').then(()=>{
    //   this.treeData = this.customerTree
    // })
    // },

    // 树的提示语
    renderContent (h, { node, data, store }) {
      if (node.label.length > 11) {
        return (<div style="font-size: 1.4rem;">
          <el-tooltip class="item" effect="dark" placement="right">
            <div slot="content" >{node.label}</div>
            <p style="width:16rem; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;">{node.label}</p>
          </el-tooltip>
        </div>);
      } else { return (<div style="font-size: 1.4rem;"><p>{node.label}</p></div>) }
    },

  },

  data() {
    return {
      dataForm: {
        buildId: "",
      },
      filterText: "",
      currentNodeKey: "",
      // 树的内容（伪数据）
      treeData: [],
      // 树的props参数，已经与后端对接，勿动
      defaultProps: { children: "children", label: "name" },
      defaultCheckedKeys: [],
      flag: true,
    };
  },
  computed: {
    ...mapState({
      getEquipmentBuilding: (state) => {
        return state.elecManagement.getEquipmentBuilding.data;
      },
      customerTree: (state) => {
        return state.equipment.customerTree.data;
      },
    }),
  },
  //发送请求,获取客户树
  created() {
    this.dataForm.buildId = this.$store.state.storage.customerId;
  },
};
</script>

<style lang="less">
.TreeComparisonAnalyze {
  height: 88rem;
  width: 22rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  overflow-y: scroll;

  // 输入框位置
  .inputContainer {
    margin: 0 auto;
    height: 6.8rem;
    width: 18.8rem;
    // position: absolute;
    // top: 0;
    // left: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .elInput {
      height: 3.2rem;
      width: 18.8rem;
      position: absolute;
      top: 2.4rem;
      left: 1.6rem;
      font-size: 1.4rem;
      background-color: #0c375e;
      border: 0.1rem solid #004579;
      box-sizing: border-box;
      border-radius: 0.2rem;
      text-indent: 1.2rem;
      color: #fff;
      outline: 0;
      &:focus {
        border: 0.1rem solid #008080;
      }
    }
  }
  // 树的位置
   .treeContainer {
    // height: 90rem;
    width: 100%;
    overflow: auto;
    flex: 1;
    .blank {
      height: 25rem;
      width: 1rem;
    }
    .elTree {
      background-color: #0d3050;
      color: rgba(255, 255, 255, 0.85);

      .el-tree-node__label {
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-family: PingFang SC;
        height: 2.2rem;
        // 禁止选中文字
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }
      .el-tree-node__content:hover {
        background-color: #204b73;
      }
      .el-tree-node:focus > .el-tree-node__content {
        // background-color: #18191a;
        background-image: linear-gradient(to right, #0d3050, #18191a);
        // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
        // box-sizing: border-box;
      }
    }
  }
  // 持续高亮(鼠标离开树以后)
  .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-image: linear-gradient(to right, #0d3050, #18191a);
    // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
    // box-sizing: border-box;
  }
}
</style>
