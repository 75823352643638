<template>

  <div class="FullView" id="FullView">
    <!-- ----------------------------------------------------------------------------背景图 ---------------------------------------------------------------->
    <!-- 大背景 -->
    <img src="@/assets/FullView/bg.png" class="bgimg" :style="{position:'absolute',top:30-styleY*0.5+'px',left:-40-styleX*0.5+'px'}" alt="">
    <!-- 顶栏 -->
    <img src="@/assets/FullView/title.png" class="bgimgTitle" alt="">
    <!-- 湖北省地图 -->
    <img src="@/assets/FullView/hubei.png" class="bgimgHubei" :class="styleHubei" alt="">
    <img v-if="ifFlashShow" src="@/assets/FullView/hubeiLight.png" class="flashStyle" alt="">
    <img src="@/assets/FullView/cityHubei.png" class="cityHubei" :class="StyleCityHubei" alt="">
    <!-- 武汉市地图 -->
    <img :src="this.currentCity.bgUrl" :class="styleWuhan" alt="">
    <img :src="this.currentCity.fontBgUrl" :class="StyleCityWuhan" alt="">

    <!-- ----------------------------------------------------------------------------顶栏部分 ---------------------------------------------------------------------->
    <div class="topBar">
      <img v-show="ifCloseOver==0" @mouseenter="ifCloseOver=1" @mouseleave="ifCloseOver=0" src="@/assets/FullView/close.png" class="quitButton2" @click="quit" alt="">
      <img v-show="ifCloseOver==1" @mouseenter="ifCloseOver=1" @mouseleave="ifCloseOver=0" src="@/assets/FullView/close2.png" class="quitButton" @click="quit" alt="">
      <div class="word1">
        <span style="float:left">安全运行天数：</span>
        <span style="float:right">天</span>
        <span class="insideWord">{{dayNow}}</span>
      </div>
      <div class="word2">{{timeNow}}</div>
      <img v-show="styleWuhan=='bgimgWuhan'" src="@/assets/FullView/Rollback.png" class="Rollback" @click="getBackToHuBei" alt="">
    </div>

    <!-- 湖北省图左侧部分 -->
    <div :class="HuBeiLeftClass">
      <HuBeiLeft ref="HuBeiLeftRef" :myData="HuBeiData" />
    </div>

    <!-- 湖北省图右侧部分 -->
    <div :class="HuBeiRightClass">
      <HuBeiRight ref="HuBeiRightRef" :myData="HuBeiData" />
    </div>

    <!-- 湖北省图下侧部分 -->
    <div :class="HuBeiBottomClass">
      <HuBeiBottom ref="HuBeiBottomRef" :myData="HuBeiData" />
    </div>

    <!-- 配电类左侧部分 -->
    <div :class="PeiDianLeftClass">
      <PeiDianLeft ref="PeiDianLeftRef" :myData="customerData" />
    </div>

    <!-- 配电类右侧部分 -->
    <div :class="PeiDianRightClass">
      <PeiDianRight ref="PeiDianRightRef" :myData="customerData" />
    </div>

    <!-- 配电类底部部分 -->
    <div :class="PeiDianBottomClass">
      <PeiDianBottom ref="PeiDianBottomRef" :myData="customerData" />
    </div>

    <!-- 都有类左侧部分 -->
    <div :class="BothLeftClass">
      <BothLeft ref="BothLeftRef" :myData="customerData" />
    </div>

    <!-- 都有类右侧部分 -->
    <div :class="BothRightClass">
      <BothRight ref="BothRightRef" :myData="customerData" />
    </div>

    <!-- 都有类底部部分 -->
    <div :class="BothBottomClass">
      <BothBottom ref="BothBottomRef" :myData="customerData" />
    </div>

    <!--发电类左侧部分 -->
    <div :class="FaDianLeftClass">
      <FaDianLeft ref="FaDianLeftRef" />
    </div>
    <!-- 发电类底部部分 -->
    <div :class="FaDianBottomClass">
      <FaDianBottom ref="FaDianBottomRef" />
    </div>
    <!-- 发电类右侧部分 -->
    <div :class="FaDianRightClass">
      <FaDianRight ref="FaDianRightRef" />
    </div>

    <!-- ----------------------------------------------------------------------------地图钉 ---------------------------------------------------------------------->
    <!-- 武汉市地图钉 -->
    <div v-for="(item, index) in cityCustomerInfo" :key="item.cityCode" @click="WuhanHandelClick(item, index)" :class="pointerWuhanClass" :style="{top: item.yaxis + 'rem', left: item.xaxis + 'rem'}">
      <div v-if="item.cityCustomerNum > 0">
        <img src="@/assets/FullView/point.png" style="height: 12.9rem;width:8rem;" alt="">
        <div class="wordWuhanPoint">{{ item.cityCustomerNum }}</div>
      </div>
    </div>
    <!-- 凯源电力地图钉 -->
    <div v-for="(item, index) in cityCustomerList" :key="index" :class="pointerKiayuanClass" :style="`top: ${item.yaxis}rem;left: ${item.xaxis}rem;z-index: ${item.zindex || ''}`" @mouseenter="handleMouseEnter(item, index)" @mouseleave="mention=0" @click="peiDianHandelClick(item, index)">
      <img :src="pointPlace==index + 1 || item.zindex == 1001?require('@/assets/FullView/point.png'):require('@/assets/FullView/pointGreen.png')" style="height: 12.9rem;width:8rem;" alt="">
      <div v-show="mention!=index + 1" class="wordWuhanPoint2" :style="pointPlace==index + 1?{}:{color:'#fff'}">{{ item.name.substring(0, 5) }}</div>
      <div v-show="mention==index + 1" class="wordWuhanPoint">
        <span>{{ cityCustomerList[mention - 1] && cityCustomerList[mention - 1].name || item.name }}&nbsp;&nbsp;&nbsp;<span style="color:#fff">{{ cityCustomerList[mention - 1]&&cityCustomerList[mention - 1].address || item.address }}</span></span>
        <div v-if="nearbyList.length > 0" class="nearby">
            <div class="nearby-title">附近地点：</div>
            <div class="nearby-content">
                <!-- &nbsp;&nbsp;&nbsp;<span style="color:#fff">{{ el.address }}</span> -->
                <span v-for="(el, i) in nearbyList" :key="i" @click.stop="peiDianHandelClick(el, i)">{{ el.name }}</span>
            </div>
        </div>
    </div>
    </div>

    <!-- 白沙洲未来外国语学校地图钉  -->
    <!-- <div @click="peiDianHandelClick(2)"  :class="pointerKiayuanClass" @mouseenter="mention=2" @mouseleave="mention=0" style="top:56rem;left:87rem;white-space: nowrap;">
      <img :src="pointPlace==2?require('@/assets/FullView/point.png'):require('@/assets/FullView/pointGreen.png')"  style="height: 12.9rem;width:8rem;" alt="">
      <div v-show="mention!=2" class="wordWuhanPoint2" :style="pointPlace==2?{}:{color:'#fff'}">武汉外校</div>
      <div v-show="mention==2" class="wordWuhanPoint">武汉未来实验外国语学校&nbsp;&nbsp;&nbsp;<span style="color:#fff">武汉市洪山区夹套河路长江鑫都A区</span></div>
    </div> -->

    <!-- 东湖景园地图钉  -->
    <!-- <div @click="peiDianHandelClick(3)"  :class="pointerKiayuanClass" @mouseenter="mention=3" @mouseleave="mention=0" style="top:48rem;left:93rem;white-space: nowrap;">
      <img :src="pointPlace==3?require('@/assets/FullView/point.png'):require('@/assets/FullView/pointGreen.png')"  style="height: 12.9rem;width:8rem;" alt="">
      <div v-show="mention!=3" class="wordWuhanPoint2" :style="pointPlace==3?{}:{color:'#fff'}">东湖景园</div>
      <div v-show="mention==3" class="wordWuhanPoint">东湖景园&nbsp;&nbsp;&nbsp;<span style="color:#fff">武汉市洪山区欢乐大道38号</span></div>
    </div> -->

    <!-- 中南财大地图钉 -->
    <!-- <div  @click="ZNUniversityHandelClick" :class="pointerKiayuanClass" @mouseenter="mention=4" @mouseleave="mention=0" style="top:60rem;left:97rem">
      <img :src="pointPlace==4?require('@/assets/FullView/point.png'):require('@/assets/FullView/pointGreen.png')"  style="height: 12.9rem;width:8rem;" alt="">
      <div v-show="mention!=4" class="wordWuhanPoint2" :style="pointPlace==4?{}:{color:'#fff'}">中南财大</div>
      <div v-show="mention==4" class="wordWuhanPoint">中南财经政法大学(南湖校区)&nbsp;&nbsp;&nbsp;<span style="color:#fff">武汉市东湖高新技术开发区南湖大道182号</span></div>
    </div> -->
    <!-- 注释AMAZ -->

  </div>
</template>

<script>
import HuBeiLeft from "./HuBeiLeft.vue"
import HuBeiRight from "./HuBeiRight.vue"
import HuBeiBottom from './HuBeiBottom.vue'
import PeiDianLeft from './PeiDianLeft.vue'
import PeiDianRight from './PeiDianRight.vue'
import PeiDianBottom from './PeiDianBottom.vue'
import BothLeft from './BothLeft.vue'
import BothRight from './BothRight.vue'
import BothBottom from './BothBottom.vue'
import FaDianLeft from './FaDianLeft.vue'
import FaDianBottom from './FaDianBottom.vue'
import FaDianRight from './FaDianRight.vue'
import { mapState } from 'vuex'
import { HuBeiData, customerData } from './props.js'
import wuHanBg from '@/assets/FullView/wuhan.png'
import wuHanFontBg from '@/assets/FullView/cityWuhan.png'
import xiangYangBg from '@/assets/FullView/xiangyang.png'
import xiangYangFontBg from '@/assets/FullView/cityXiangyang.png'
import shiYanBg from '@/assets/FullView/shiyan.png'
import shiYanFontBg from '@/assets/FullView/cityShiyan.png'
import shenLongJiaBg from '@/assets/FullView/shenlongjia.png'
import shenLongJiaFontBg from '@/assets/FullView/cityShenlongjia.png'
import enShiBg from '@/assets/FullView/enshi.png'
import enShiFontBg from '@/assets/FullView/cityEnshi.png'
import yiChangBg from '@/assets/FullView/yichang.png'
import yiChangFontBg from '@/assets/FullView/cityYichang.png'
import suiZhouBg from '@/assets/FullView/suizhou.png'
import suiZhouFontBg from '@/assets/FullView/citySuizhou.png'
import jingMenBg from '@/assets/FullView/jingmen.png'
import jingMenFontBg from '@/assets/FullView/cityJingmen.png'
import jingZhouBg from '@/assets/FullView/jingzhou.png'
import jingZhouFontBg from '@/assets/FullView/cityJingzhou.png'
import xiaoGanBg from '@/assets/FullView/xiaogan.png'
import xiaoGanFontBg from '@/assets/FullView/cityXiaogan.png'
import huangGangBg from '@/assets/FullView/huanggang.png'
import huangGangFontBg from '@/assets/FullView/cityHuanggang.png'
import eZhouBg from '@/assets/FullView/ezhou.png'
import eZhouFontBg from '@/assets/FullView/cityEzhou.png'
import huangShiBg from '@/assets/FullView/huangshi.png'
import huangShiFontBg from '@/assets/FullView/cityHuangshi.png'
import xianNingBg from '@/assets/FullView/xianning.png'
import xianNingFontBg from '@/assets/FullView/cityXianning.png'
export default {
  name: 'FullView',
  components: { HuBeiLeft, HuBeiRight, HuBeiBottom, PeiDianLeft, PeiDianRight, PeiDianBottom, BothLeft, BothRight, BothBottom, FaDianLeft, FaDianBottom, FaDianRight },
  data() {
    return {
      //选中点 
      pointPlace: 0,
      // 是否放在关闭按钮上
      ifCloseOver: 0,
      // 地图戳的提示文字
      mention: 0,
      // 闪电是否展示
      ifFlashShow: false,
      // 各个板块的样式绑定
      HuBeiLeftClass: 'leftPlace',
      HuBeiRightClass: 'rightPlace',
      HuBeiBottomClass: 'bottomPlace',

      PeiDianLeftClass: 'peiDianLeftPlace',
      PeiDianRightClass: 'peiDianRightPlace',
      PeiDianBottomClass: 'bottomPlace',

      BothLeftClass: 'leftPlace',
      BothRightClass: 'rightPlace',
      BothBottomClass: 'bottomPlace',

      FaDianLeftClass: 'leftPlace',
      FaDianBottomClass: 'bottomPlace',
      FaDianRightClass: 'rightPlace',

      // 地图戳样式绑定
      pointerWuhanClass: 'pointerWuhan',
      pointerKiayuanClass: 'pointerKaiyuan',

      // 湖北省地图的样式绑定
      styleHubei: 'bgimgHubei0',
      StyleCityHubei: 'cityHubei0',
      StyleCityWuhan: 'cityWuhan0',
      styleWuhan: 'bgimgWuhan0',
      styleX: 0,
      styleY: 0,
      // 顶部时钟的数据绑定
      timeNow: '',
      // 安全运行的天数
      dayNow: 0,
      cityUrlList: {
        1001: {
            bgUrl: wuHanBg,
            fontBgUrl: wuHanFontBg
        },
        1006: {
          bgUrl: xiangYangBg,
          fontBgUrl: xiangYangFontBg
        },
        1002: {
            bgUrl: huangShiBg,
            fontBgUrl: huangShiFontBg
        },
        1003: {
          bgUrl: shiYanBg,
          fontBgUrl: shiYanFontBg
        },
        1004: {
            bgUrl: jingZhouBg,
            fontBgUrl: jingZhouFontBg
        },
        1005: {
          bgUrl: yiChangBg,
          fontBgUrl: yiChangFontBg
        },
        1007: {
            bgUrl: eZhouBg,
            fontBgUrl: eZhouFontBg
        },
        1008: {
          bgUrl: jingMenBg,
          fontBgUrl: jingMenFontBg
        },
        1009: {
            bgUrl: xiaoGanBg,
            fontBgUrl: xiaoGanFontBg
        },
        1010: {
          bgUrl: huangGangBg,
          fontBgUrl: huangGangFontBg
        },
        1011: {
            bgUrl: xianNingBg,
            fontBgUrl: xianNingFontBg
        },
        1012: {
          bgUrl: suiZhouBg,
          fontBgUrl: suiZhouFontBg
        },
        1013: {
          bgUrl: enShiBg,
          fontBgUrl: enShiFontBg
        }
      },
      currentCity: {},
      cityCustomerList: [],
      /** 计时器 */
      intervalId: null,
      HuBeiData: HuBeiData,
      customerData: customerData,
      /** 附近重叠点 */
      nearbyList: [],
    }
  },


  methods: {
    // 退出全景展示的方法
    quit() {
      // 退出全屏
      if (document.exitFullScreen) {
        document.exitFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (element.msExitFullscreen) {
        element.msExitFullscreen();
      }
      // 跳转到第一个有权限的路由
      this.$bus.$emit('goToTheFirstMenu')
    },
    // 获取屏幕比例，使echarts自适应
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = (clientWidth / 192);
      return res * fontSize;
    },


    // 点击了武汉市的地图戳###################################################################################################
    WuhanHandelClick(item, index) {
      this.currentCity = item
      this.cityCustomerList = this.cityCustomerInfo[index].customerList;
      this.getFullViewPanoramaCity()
    },

    // 点击了返回到湖北省的回退按钮############################################################################################
    getBackToHuBei() {
      this.getFullViewPanoramaProvinceData()
      this.pointPlace = 0
      // 关闭项
      this.styleWuhan = 'bgimgWuhan0';
      this.StyleCityWuhan = 'cityWuhan0'
      this.pointerKiayuanClass = 'pointerKaiyuan';
      this.PeiDianRightClass = 'peiDianRightPlace';
      this.PeiDianLeftClass = 'peiDianLeftPlace';
      this.PeiDianBottomClass = 'bottomPlace';
      this.BothLeftClass = 'leftPlace';
      this.BothRightClass = 'rightPlace';
      this.BothBottomClass = 'bottomPlace';
      this.FaDianLeftClass = 'leftPlace';
      this.FaDianBottomClass = 'bottomPlace';
      this.FaDianRightClass = 'rightPlace';

      // 展开项

      this.styleHubei = 'bgimgHubei';
      this.StyleCityHubei = 'cityHubei';
      setTimeout(() => {
        this.HuBeiLeftClass = 'leftPlaceShow';
        this.HuBeiRightClass = 'rightPlaceShow';
        this.HuBeiBottomClass = 'bottomPlaceShow';
        this.$refs.HuBeiRightRef.show()
        this.$refs.HuBeiLeftRef.show()
      }, 500)

      setTimeout(() => {
        this.pointerWuhanClass = 'pointerWuhanShow'
        this.ifFlashShow = true
        setTimeout(() => { this.ifFlashShow = false }, 3800)
      }, 1500)
    },


    // 点击了配电类的地图戳（凯源电力 东湖景园 白沙洲外国语学校）#################################################################################################
    peiDianHandelClick(item) {
      this.cityCustomerList.forEach((el, i) => {
        if (item.id == el.id) {
            el.zindex = 1001;
            this.pointPlace = i + 1
            this.mention = i + 1
        } else {
            el.zindex = 999;
        }
      })
      this.getFullViewCityInfo(item);
      // var dataHere = {}
      // if(val==1)  {dataHere = this.kaiyuanCityData;this.pointPlace = 1}
      // else if(val==2)  {dataHere = this.schoolCityData;this.pointPlace = 2}
      // else if(val==3)  {dataHere = this.eastLakeCityData;this.pointPlace = 3}
      // setTimeout(()=>{
      //   this.PeiDianLeftClass = 'peiDianLeftPlaceShow';
      //   this.PeiDianRightClass = 'peiDianRightPlaceShow';
      //   setTimeout(()=>{this.PeiDianBottomClass='bottomPlaceShow';},500)
      //   this.$refs.PeiDianLeftRef.show(dataHere)
      //   this.$refs.PeiDianRightRef.show(dataHere)
      //   this.$refs.PeiDianBottomRef.show(dataHere)
      // },500)
    },

    // 点击了配电和发电类的地图戳（财大）################################################################################################
    ZNUniversityHandelClick() {
      this.pointPlace = 4
      this.HuBeiLeftClass = 'leftPlace';
      this.HuBeiRightClass = 'rightPlace';
      this.HuBeiBottomClass = 'bottomPlace';
      this.PeiDianLeftClass = 'peiDianLeftPlace';
      this.PeiDianRightClass = 'peiDianRightPlace';
      this.PeiDianBottomClass = 'bottomPlace';
      this.BothLeftClass = 'leftPlace';
      this.BothRightClass = 'rightPlace';
      this.BothBottomClass = 'bottomPlace';
      this.FaDianLeftClass = 'leftPlace';
      this.FaDianBottomClass = 'bottomPlace';
      this.FaDianRightClass = 'rightPlace';
      setTimeout(() => {
        this.BothLeftClass = 'leftPlaceShow';
        this.BothRightClass = 'rightPlaceShow';
        setTimeout(() => { this.BothBottomClass = 'bottomPlaceShow'; }, 500)
        this.$refs.BothLeftRef.show(this.cityData)
        this.$refs.BothRightRef.show(this.cityData)
        this.$refs.BothBottomRef.show(this.cityData)
      }, 500)




    },
    /** 刷新数据  十分钟刷新一次*/
    refreshDataInterval() {
      if (this.intervalId !== null) {
        return false;
      }
      this.intervalId = setInterval(() => {
        this.refreshDataEvent()
      }, 600000)
    },
    /** 刷新数据事件 */
    async refreshDataEvent() {
      /** 根据选中样式判断刷新数据 */
      if (this.styleHubei === 'bgimgHubei') {
        this.getFullViewPanoramaProvinceData();
      } else if (this.styleWuhan === 'bgimgWuhan') {
        if (this.pointPlace == 0) {
          this.getFullViewPanoramaCity()
        } else {
          let item = this.cityCustomerList[this.pointPlace - 1]?.name;
          this.getFullViewCityInfo(item);
        }
      }
    },
    /** 请求湖北省数据 */
    getFullViewPanoramaProvinceData(city = '') {
      // 请求湖北省数据
      this.$store.dispatch('getFullViewPanoramaProvince', city).then(() => {
        // setTimeout(() => {
        //   this.$refs.HuBeiLeftRef.show(this.provinceData)
        // }, 1000)
        this.HuBeiData = Object.assign(this.HuBeiData, this.provinceData)

        if (!city) {
          // 获取城市客户单位信息 
          this.$store.dispatch('getFullViewPanoramaCityCustomerInfo').then(() => {
            this.cityCustomerInfo.forEach((item) => {
              if (this.cityUrlList[item.cityCode]) {
                item.bgUrl = this.cityUrlList[item.cityCode].bgUrl
                item.fontBgUrl = this.cityUrlList[item.cityCode].fontBgUrl
              }
            })
            // this.wuhanCustomerNum = this.cityCustomerInfo[0].cityCustomerNum;
            this.cityCustomerList = this.cityCustomerInfo[0].customerList;
          })
        }
      })
      //用电负荷
      this.$store.dispatch('getFullViewPanoramaElectricalLoad', city).then(() => {
        this.HuBeiData = Object.assign(this.HuBeiData, this.electricalLoadData)
      })
      //用电统计
      this.$store.dispatch('getFullViewPanoramaElectricalStat', city).then(() => {
        const datas = {
          electricPowerConsumption:this.electricalStatData.stat,
          electricPowerConsumptionTimes:this.electricalStatData.months,
        }
        this.HuBeiData = Object.assign(this.HuBeiData, datas)
      })
      this.$store.dispatch('getFullViewPanoramaProvinceCharge', city).then(() => {
        this.HuBeiData = Object.assign(this.HuBeiData, this.provinceChargeData)
      })
      this.$store.dispatch('getFullViewPanoramaProvinceGenerator', city).then(() => {
        this.HuBeiData = Object.assign(this.HuBeiData, this.provinceGeneratorData)
      })
      this.$store.dispatch('getFullViewPanoramaProvinceStatistics', city).then(() => {
        this.HuBeiData = Object.assign(this.HuBeiData, {generateElectricityS: this.provinceStatisticsData})
      })
      this.$refs.HuBeiLeftRef.showAnimation()
      this.$refs.HuBeiRightRef.showAnimation()
    },
    /** 获取城市级全景展示 */
    getFullViewPanoramaCity() {
      // 关闭项
      this.StyleCityHubei = 'cityHubei0';
      this.styleHubei = 'bgimgHubei0';
      this.pointerWuhanClass = 'pointerWuhan';
      this.ifFlashShow = false;
      // 展开项
      this.styleWuhan = 'bgimgWuhan';
      this.StyleCityWuhan = 'cityWuhan';
      this.getFullViewPanoramaProvinceData(this.currentCity.cityName)
      setTimeout(() => {
        this.pointerKiayuanClass = 'pointerKaiyuanShow'
      }, 1000)
      // 获取城市级全景展示 
      // this.$store.dispatch('getFullViewPanoramaCity', '武汉市').then(() => {
      //   // 关闭项
      //   this.styleHubei = 'bgimgHubei0';
      //   this.StyleCityHubei = 'cityHubei0';
      //   this.pointerWuhanClass = 'pointerWuhan';
      //   // 展开项
      //   this.styleWuhan = 'bgimgWuhan';
      //   this.StyleCityWuhan = 'cityWuhan';
      //   setTimeout(() => {
      //     this.pointerKiayuanClass = 'pointerKaiyuanShow'
      //     this.$refs.PeiDianLeftRef.show(this.$store.state.fullView.allCityData.data)
      //     this.$refs.PeiDianRightRef.show(this.$store.state.fullView.allCityData.data)
      //     this.$refs.PeiDianBottomRef.show(this.$store.state.fullView.allCityData.data)
      //   }, 1000)
      // })
    },
    /** 获取 配电和发电类 城市信息  */
    getFullViewCityInfo(item) {
      if (item.type == 1) {
        this.HuBeiLeftClass = 'leftPlace';
        this.HuBeiRightClass = 'rightPlace';
        this.HuBeiBottomClass = 'bottomPlace';
        this.PeiDianLeftClass = 'peiDianLeftPlace';
        this.PeiDianRightClass = 'peiDianRightPlace';
        this.PeiDianBottomClass = 'bottomPlace';
        this.BothLeftClass = 'leftPlace';
        this.BothRightClass = 'rightPlace';
        this.BothBottomClass = 'bottomPlace';
        this.FaDianLeftClass = 'leftPlace';
        this.FaDianBottomClass = 'bottomPlace';
        this.FaDianRightClass = 'rightPlace';
        this.PeiDianLeftClass = 'peiDianLeftPlaceShow';
        this.PeiDianRightClass = 'peiDianRightPlaceShow';
        setTimeout(() => { this.PeiDianBottomClass = 'bottomPlaceShow'; }, 500)
        this.$store.dispatch('getFullViewCity', item.id).then(() => {
          this.customerData = Object.assign(this.customerData, this.cityData)
        //   setTimeout(() => {
        //     this.$refs.PeiDianLeftRef.show(this.$store.state.fullView.cityData.data)
        //     this.$refs.PeiDianRightRef.show(this.$store.state.fullView.cityData.data)
        //     this.$refs.PeiDianBottomRef.show(this.$store.state.fullView.cityData.data)
        //   }, 1000)
        })
        //用电负荷
        this.$store.dispatch('getFullViewCityElecLoad', item.id).then(() => {
          this.customerData = Object.assign(this.customerData, this.cityData)
        })
        //用电统计
        this.$store.dispatch('getFullViewCityElecStat', item.id).then(() => {
          const datas = {
          electricPowerConsumption:this.cityElecStatData.stat,
          electricPowerConsumptionTimes:this.cityElecStatData.months,
        }
          this.customerData = Object.assign(this.customerData, datas)
        })
        this.$store.dispatch('getFullViewCityEnergy', item.id).then(() => {
          this.customerData = Object.assign(this.customerData, this.moreCityEnergyData)
        })
        this.$refs.PeiDianLeftRef.showAnimation()
        this.$refs.PeiDianRightRef.showAnimation()
      } else {
        this.HuBeiLeftClass = 'leftPlace';
        this.HuBeiRightClass = 'rightPlace';
        this.HuBeiBottomClass = 'bottomPlace';
        this.PeiDianLeftClass = 'peiDianLeftPlace';
        this.PeiDianRightClass = 'peiDianRightPlace';
        this.PeiDianBottomClass = 'bottomPlace';
        this.BothLeftClass = 'leftPlace';
        this.BothRightClass = 'rightPlace';
        this.BothBottomClass = 'bottomPlace';
        this.FaDianLeftClass = 'leftPlace';
        this.FaDianBottomClass = 'bottomPlace';
        this.FaDianRightClass = 'rightPlace';
        this.BothLeftClass = 'leftPlaceShow';
        this.BothRightClass = 'rightPlaceShow';
        setTimeout(() => { this.BothBottomClass = 'bottomPlaceShow'; }, 500)
        this.$store.dispatch('getFullViewCityAll', item.id).then(() => {
          this.customerData = Object.assign(this.customerData, this.cityData)
          // setTimeout(() => {
          //   this.$refs.BothLeftRef.show(this.$store.state.fullView.cityData.data)
          //   this.$refs.BothRightRef.show(this.$store.state.fullView.cityData.data)
          //   this.$refs.BothBottomRef.show(this.$store.state.fullView.cityData.data)
          // }, 1000)
        })
        //用电负荷
        this.$store.dispatch('getFullViewCityAllElecLoad', item.id).then(() => {
          this.customerData = Object.assign(this.customerData, this.cityElecLoadData)
        })
        //用电统计
        this.$store.dispatch('getFullViewCityAllElecStat', item.id).then(() => {
          const datas = {
          electricPowerConsumption:this.cityElecStatData.stat,
          electricPowerConsumptionTimes:this.cityElecStatData.months,
        }
          this.customerData = Object.assign(this.customerData, datas)
        })
        this.$store.dispatch('getFullViewCityAllEnergy', item.id).then(() => {
          this.customerData = Object.assign(this.customerData, this.cityEnergyData)
        })
        this.$store.dispatch('getGeneratorViewCityAll', item.id).then(() => {
          this.customerData = Object.assign(this.customerData, this.cityGeneratorData)
          // setTimeout(() => {
          //   this.$refs.BothLeftRef.show(this.$store.state.fullView.cityData.data)
          //   this.$refs.BothRightRef.show(this.$store.state.fullView.cityData.data)
          //   this.$refs.BothBottomRef.show(this.$store.state.fullView.cityData.data)
          // }, 1000)
        })
        this.$store.dispatch('getGeneratorViewCityAllInverter', item.id).then(() => {
          this.customerData = Object.assign(this.customerData, this.cityGeneratorInverterData)
        })
        this.$refs.BothLeftRef.showAnimation()
        this.$refs.BothRightRef.showAnimation()
      }
    },

    // 二级页点鼠标移入事件
    handleMouseEnter(item, index) {
        this.nearbyList = [];
        this.mention=index + 1;
        this.cityCustomerList.forEach((el) => {
            if (Math.abs(el.yaxis - item.yaxis) < 5 && Math.abs(el.xaxis - item.xaxis) < 5) {
                this.nearbyList.push(el)
            }
        })
    }



    // 注释AMKY



  },

  created() {
    // 获取时间(首次)
    var dateNow = new Date();
    this.timeNow = dateNow.getFullYear() + "-" + (dateNow.getMonth() + 1) + "-" + dateNow.getDate()
      + '  ' + (dateNow.getHours() < 10 ? '0' + dateNow.getHours() : dateNow.getHours()) + ':' + (dateNow.getMinutes() < 10 ? '0' + dateNow.getMinutes() : dateNow.getMinutes());
    this.dayNow = Math.floor((new Date() - new Date('6-08-2021')) / (24 * 3600 * 1000))
    // 获取时间（更新）
    setInterval(() => {
      var dateNow = new Date();
      this.timeNow = dateNow.getFullYear() + "-" + (dateNow.getMonth() + 1) + "-" + dateNow.getDate()
        + '  ' + (dateNow.getHours() < 10 ? '0' + dateNow.getHours() : dateNow.getHours()) + ':' + (dateNow.getMinutes() < 10 ? '0' + dateNow.getMinutes() : dateNow.getMinutes());

      this.dayNow = Math.floor((new Date() - new Date('6-08-2021')) / (24 * 3600 * 1000))
    }, 60000)



  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null;
    }
  },


  computed: {
    ...mapState({
      provinceData: (state) => { return state.fullView.provinceData.data },
      electricalLoadData: (state) => { return state.fullView.electricalLoadData.data },
      electricalStatData: (state) => { return state.fullView.electricalStatData.data },
      provinceChargeData: (state) => { return state.fullView.provinceChargeData.data },
      provinceGeneratorData: (state) => { return state.fullView.provinceGeneratorData.data },
      provinceStatisticsData: (state) => { return state.fullView.provinceStatisticsData.data },
      cityCustomerInfo: (state) => { return state.fullView.cityCustomerInfo.data },
      cityData: (state) => { return state.fullView.cityData.data },
      cityElecLoadData: (state) => { return state.fullView.cityElecLoadData.data },
      cityElecStatData: (state) => { return state.fullView.cityElecStatData.data },
      cityGeneratorData: (state) => { return state.fullView.cityGeneratorData.data },
      cityEnergyData: (state) => { return state.fullView.cityEnergyData.data },
      cityGeneratorInverterData: (state) => { return state.fullView.cityGeneratorInverterData.data },
      moreCityEnergyData: (state) => { return state.fullView.moreCityEnergyData.data },
      kaiyuanCityData: (state) => { return state.fullView.kaiyuanCityData.data },
      eastLakeCityData: (state) => { return state.fullView.eastLakeCityData.data },
      schoolCityData: (state) => { return state.fullView.schoolCityData.data },


    })
  },




  mounted() {
    // 强制全屏 W3C FireFox Chrome等 IE11####################################
    var docElm = document.documentElement;
    if (docElm.requestFullscreen) { docElm.requestFullscreen(); }
    else if (docElm.mozRequestFullScreen) { docElm.mozRequestFullScreen(); }
    else if (docElm.webkitRequestFullScreen) { docElm.webkitRequestFullScreen(); }
    else if (elem.msRequestFullscreen) { elem.msRequestFullscreen(); }

    // 两边开门特效
    this.HuBeiLeftClass = 'leftPlaceShow'
    this.HuBeiRightClass = 'rightPlaceShow'
    //底部出现 地图钉出现 特效出现
    setTimeout(() => {
      this.HuBeiBottomClass = 'bottomPlaceShow'
      this.pointerWuhanClass = 'pointerWuhanShow'
      this.ifFlashShow = true
      setTimeout(() => { this.ifFlashShow = false }, 3800)
    }, 1000)

    // 湖北省地图的特效
    this.styleHubei = 'bgimgHubei'
    this.StyleCityHubei = 'cityHubei'


    // 监听鼠标行为
    const fullView = document.getElementById('FullView')
    fullView.onmousemove = (e) => {
      this.styleX = (e.clientX - 960) / 50
      this.styleY = (e.clientY - 540) / 50
    }

    this.getFullViewPanoramaProvinceData()
    this.intervalId = null;
    this.refreshDataInterval()



  },



}
</script>

<style lang="less" scoped>
//  ----------------------------------------------------------------------------背景图部分 ----------------------------------------------------------------------
.FullView {
  height: 108rem;
  width: 192rem;
  position: absolute;
  top: -8.8rem;
  left: -18.4rem;
  background-color: #16294c;
  .bgimg {
    // height:108rem;
    // width: 192rem;
    height: 129rem;
    width: 230.4rem;

    position: absolute;
    top: 4.4rem;
    left: -4rem;
  }
  .bgimgTitle {
    height: 8rem;
    width: 192rem;
    position: absolute;
    top: 0;
    left: 0;
  }

  // 湖北省地图
  .bgimgHubei {
    width: 104rem;
    height: 74rem;
    position: absolute;
    top: 10.4rem;
    right: 46.3rem;
    opacity: 1;
    transition: all 1s;
  }
  .bgimgHubei0 {
    width: 0rem;
    height: 0rem;
    position: absolute;
    top: 54rem;
    right: 96rem;
    opacity: 0;
    transition: all 1s;
  }
  // 湖北省城市表

  .cityHubei {
    width: 104rem;
    height: 74rem;
    position: absolute;
    top: 10.4rem;
    right: 46.3rem;
    opacity: 1;
    transition: all 1s;
  }
  .cityHubei0 {
    width: 80.6rem;
    height: 44.7rem;
    position: absolute;
    top: 10.4rem;
    right: 46.3rem;
    opacity: 0;
    transition: all 0.3s;
  }
  // 武汉市地图
  .bgimgWuhan0 {
    // width: 0rem;
    // height: 0rem;
    // position: absolute;
    // top: 54rem;
    // right: 96rem;
    // opacity: 0;
    // transition: all 0.3s;
    // overflow: hidden;
    // transform: translate(-100%, 50%);
    opacity: 0;
  }

  .bgimgWuhan {
    // width: 76.4rem;
    // height: 88.1rem;
    // position: absolute;
    // top: 50%;
    // right: 50%;
    // opacity: 1;
    // transition: all 1s;
    // // overflow: hidden;
    // transform: translate(50%, -50%);
    position: absolute;
    top: 50%;
    right: 50%;
    opacity: 1;
    transition: opacity 5s;
    transform: translate(50%, -50%);
  }
  // 武汉市城市表
  .cityWuhan0 {
    // width: 66rem;
    // height: 74rem;
    position: absolute;
    top: 50%;
    right: 50%;
    opacity: 0;
    transform: translate(50%, -50%);
  }

  .cityWuhan {
    // width: 66rem;
    // height: 74rem;
    position: absolute;
    top: 50%;
    right: 50%;
    opacity: 1;
    transition: opacity 3s;
    transform: translate(50%, -50%);
  }

  .blockTitle {
    height: 5.1rem;
    width: 37.8rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .blockTitleWord {
    position: absolute;
    top: 1.4rem;
    left: 4rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1.6rem;
    color: #fff;
  }
}

//  ----------------------------------------------------------------------------顶栏部分 ----------------------------------------------------------------------
.topBar {
  height: 8rem;
  width: 50rem;
  position: absolute;
  top: 0;
  right: 0;
  .quitButton {
    height: 3.2rem;
    width: 3.2rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 4rem;
  }
  .quitButton2 {
    height: 2.6rem;
    width: 2.6rem;
    cursor: pointer;
    position: absolute;
    top: 1.3rem;
    right: 4.3rem;
  }
  .word1 {
    height: 3rem;
    width: 16.6rem;
    position: absolute;
    top: 0.8rem;
    right: 27.2rem;
    // background-color: beige;
    color: rgba(255, 255, 255, 0.85);
    line-height: 3rem;
    padding: 0 1rem 0 1rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    .insideWord {
      position: absolute;
      top: 0;
      right: 3rem;
      color: #51d1ff;
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
  .word2 {
    height: 3rem;
    width: 16.6rem;
    position: absolute;
    top: 0.8rem;
    right: 9rem;
    color: rgba(255, 255, 255, 0.85);
    line-height: 3rem;
    padding: 0 1rem 0 1rem;
    box-sizing: border-box;
    font-size: 1.6rem;
    text-align: center;
    font-weight: normal;
  }
  .Rollback {
    height: 3.2em;
    width: 3.2rem;
    position: absolute;
    top: 0.7rem;
    left: -139rem;
    cursor: pointer;
  }
}

//  ----------------------------------------------------------------------------湖北省左右下板块部分 ----------------------------------------------------------------------
// 左侧
.leftPlace {
  width: 45rem;
  height: 108rem;
  position: absolute;
  top: 8rem;
  left: -60rem;
  opacity: 0;
  transition: all 0.5s;
}
.leftPlaceShow {
  // width: 45rem;
  width: 60rem;
  height: 108rem;
  position: absolute;
  top: 8rem;
  left: 0;
  opacity: 1;
  transition: all 1s;
}
// 右侧
.rightPlace {
  width: 80rem;
  height: 108rem;
  position: absolute;
  top: 8rem;
  right: -45rem;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s;
  z-index: -1;
}

.rightPlaceShow {
  width: 80rem;
  height: 108rem;
  position: absolute;
  top: 8rem;
  right: 0;
  overflow: hidden;
  transition: all 1s;
  opacity: 1;
  z-index: 1;
}

// 底部
.bottomPlace {
  height: 42rem;
  width: 110rem;
  position: absolute;
  bottom: -100rem;
  left: 41rem;
  transition: all 0.5s;
}

.bottomPlaceShow {
  height: 42rem;
  width: 110rem;
  position: absolute;
  bottom: 0;
  left: 41rem;
  transition: all 1s;
}

//  ----------------------------------------------------------------------------配电类左右下板块部分 ----------------------------------------------------------------------
.peiDianLeftPlace {
  width: 45rem;
  height: 108rem;
  position: absolute;
  top: 8rem;
  left: -60rem;
  opacity: 0;
  transition: all 0.5s;
  z-index: -1;
}

.peiDianLeftPlaceShow {
  // width: 45rem;
  width: 60rem;
  height: 108rem;
  position: absolute;
  top: 8rem;
  left: 0;
  opacity: 1;
  transition: all 1s;
  z-index: 1;
}

.peiDianRightPlace {
  width: 80rem;
  height: 108rem;
  position: absolute;
  top: 8rem;
  right: -45rem;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s;
}

.peiDianRightPlaceShow {
  width: 80rem;
  height: 108rem;
  position: absolute;
  top: 8rem;
  right: 0;
  overflow: hidden;
  transition: all 1s;
  opacity: 1;
}

//  ----------------------------------------------------------------------------地图钉部分 ----------------------------------------------------------------------

// 钉子位置
.pointerWuhan {
  height: 0rem;
  width: 0rem;
  position: absolute;
  left: 116rem;
  top: 44rem;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
  overflow: hidden;
}

.pointerWuhanShow {
  height: 12.9rem;
  width: 8rem;
  position: absolute;
  left: 116rem;
  top: 44rem;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s;
  z-index: 2;
  .wordWuhanPoint {
    height: 1.7rem;
    width: 8rem;
    position: absolute;
    top: 3rem;
    left: 0;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #fff19a;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }
}

.pointerKaiyuan {
  height: 0rem;
  width: 0rem;
  position: absolute;
  left: 93rem;
  top: 29rem;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
  overflow: hidden;
}

.pointerKaiyuanShow {
  height: 12.9rem;
  width: 8rem;
  position: absolute;
  left: 93rem;
  top: 29rem;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s;
  z-index: 999;
  .wordWuhanPoint {
    // height: 1.7rem;
    // width: 8rem;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 1rem;
    border-radius: 0.4rem;
    white-space: nowrap;
    position: absolute;
    top: 2rem;
    left: 7rem;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #fff19a;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    .nearby {
        margin-top: 1rem;
        padding-top: 1rem;
        text-align: left;
        border-top: 1px dashed #aaa;
        .nearby-content {
            display: flex;
            flex-direction: column;
            line-height: 2.4rem;
            cursor: pointer;
        }
    }
  }

  .wordWuhanPoint2 {
    height: 1.7rem;
    width: 8rem;
    position: absolute;
    top: 3rem;
    left: 0;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #fff19a;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  //  :hover{
  //     &::after{
  //     content: "凯源电力";
  //     width: 30rem;
  //     height: 3rem;
  //     background-color: aqua;
  //     position: absolute;
  //     top: -5rem;
  //     left: -50%;

  //     // z-index: 0;
  //     // transform: translateZ(-1rem);
  //     // border: 0.1rem solid;
  //     // border-left: none;
  //     // border-top: none;
  //     }
  //  }
}

// 闪电动画效果
.flashStyle {
  height: 74rem;
  width: 104rem;
  position: absolute;
  top: 10.4rem;
  right: 46.3rem;
}
</style>
