
<template>
  <div class="userPageInSystem">
    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div>
        <el-input
          size="small"
          placeholder="请输入角色信息"
          v-model="roleName"
          @keyup.enter.native="search"
          clearable
          @clear="search()"
          style="width:22rem;"
        ></el-input>
        <button class="btn3" @click="search">查 询</button>
      </div>
      <button class="btn2" @click="ifDialogShow()">新增角色</button>
    </div>

    <!-- 中部表格位置############################################################################################################ -->
    <div class="userTablePlace">
       <div class="bread">
            系统管理 / 角色列表
       </div>
      <div class="table">
        <el-table
          :data="tableData"
          stripe:false
          height="57.8rem"
          border:false
          style="width: 100%"
          :header-cell-style="headerClass"
          :cell-style="cellStyle"
        >
          <el-table-column  label="序号"  min-width="4%">
            <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
          </el-table-column> 
          <el-table-column
            class="tab"
            prop="roleName"
            label="角色名称"
            min-width="8%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column prop="remark" label="角色描述" min-width="17%" :show-overflow-tooltip="true"></el-table-column>
          <!-- 操作部分 -->
          <el-table-column label="操作" min-width="10%">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>

          <!-- 权限分配 -->
          <el-table-column class="tab"  label="菜单权限配置" min-width="8%" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <el-radio @change="handelChangeRadio(tableData[scope.$index].roleId)" size="mini" v-model="radioValue" :label="tableData[scope.$index].roleId" border>权限分配</el-radio>
              </template>     

          </el-table-column>

        </el-table>
      </div>
      <!-- 分页器部分 -->
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalNumForPagenation"
        ></el-pagination>
      </div>
    </div>

    <!-- 右部表格位置############################################################################################################ -->
    <div class="rightTablePlace">
      <div class="innerPlace">
        <div class="confirmOutSider">
          <div class="confirm" @click="confirmSave" >确认保存</div>
        </div>
          
          <div v-for="(item,index) in menuData" :key='index' :class="item.style" @click="chooseCard(item,index)">
          <img class="imgFront" :src="item.iconSrc" alt="" />
          {{item.name}}
        </div>
        <div class="blank" style="height:20rem"></div>
      </div>
    </div>


    <CharacterEditDialog ref="dialogRef" @refreshDataList="getDataList" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import CharacterEditDialog from "./character/characterEdit";

export default {
  components: {
    CharacterEditDialog
  },
  name: "Character",
  mixins: [tableBg],
  data() {
    return {
      // 对话框显示隐藏
      dataForm: {id: ""},
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 双向绑定的搜索数据
      roleName: "",
      // 双向绑定的用户状态
      userState: "",
      // 用户状态列表
      userStateList: [
        { label: "冻结", value: "1" },
        { label: "正常", value: "2" }
      ],
      // 表格数据(假数据)
      tableData: [],
      // 菜单的id列表双向绑定
      menuIdList:[],
      // 选择的角色的id
      radioValue:"",

      // 右侧菜单数据
      menuData:[
        {name:"工作台",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon1.png'),ifChecked:false,menuId:7},
        {name:"全景展示",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon2.png'),ifChecked:false,menuId:8},
        {name:"配电监控",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon3.png'),ifChecked:false,menuId:9},
        {name:"电气消防",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon4.png'),ifChecked:false,menuId:10},
        {name:"发电管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon5.png'),ifChecked:false,menuId:11},
        {name:"能源管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon6.png'),ifChecked:false,menuId:12},
        {name:"充电运营",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon7.png'),ifChecked:false,menuId:13},
        {name:"运维检修",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon8.png'),ifChecked:false,menuId:14},
            {name:"巡检计划",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:23},
            {name:"巡检任务",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:24},
            {name:"我的巡检",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:38},
            {name:"工单管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:25},
            {name:"我的工单",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:39},
            {name:"工单设置",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:26},
            {name:"巡检设置",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:27},
            {name:"运维统计",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:41},
        {name:"客户管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon9.png'),ifChecked:false,menuId:16},
        {name:"设备管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon10.png'),ifChecked:false,menuId:17},
            {name:"配电设备",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:35},
            {name:"发电设备",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:36},
            {name:"仪表管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:37},
            {name:"配电用能",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:45},
        {name:"系统绘制",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon11.png'),ifChecked:false,menuId:18},
            {name:"图形管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:29},
            {name:"模板管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:31},
            // {name:"数据管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:32},
            {name:"元件管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:33},
            {name:"元件分组",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:34},
        {name:"系统管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon12.png'),ifChecked:false,menuId:1},
            {name:"用户管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:2},
            {name:"部门管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:3},
            {name:"角色列表",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:4},
            {name:"字典管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:6},
            {name:"位置管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:19},
            {name:"配电维度",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:44},
            {name:"通讯管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:28},
            {name:"告警管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:40},
            {name:"日志管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:46},
      ],
    };
  },
  computed: {
    ...mapState({
      userPageData: state => {
        return state.system.ifQueryRoleList.data.records;
      },
      ifDeleteRole: state => {
        return state.system.ifDeleteRole.errorCode;
      },
      menuList: state => {
        return state.system.menuList.data.menuIdList
      },
      ifPostRoleEdit: state => {
        return state.system.ifPostRoleEdit.errorCode;
      },
    })
  },
  mounted() {
    this.getDataList();

  },
  methods: {
    // 初始化右侧
    rightInit(){
      this.menuData=[
         {name:"工作台",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon1.png'),ifChecked:false,menuId:7},
        {name:"全景展示",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon2.png'),ifChecked:false,menuId:8},
        {name:"111",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon2.png'),ifChecked:false,menuId:111},
        {name:"配电监控",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon3.png'),ifChecked:false,menuId:9},
        {name:"电气消防",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon4.png'),ifChecked:false,menuId:10},
        {name:"发电管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon5.png'),ifChecked:false,menuId:11},
        {name:"能源管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon6.png'),ifChecked:false,menuId:12},
        {name:"充电运营",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon7.png'),ifChecked:false,menuId:13},
        {name:"运维检修",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon8.png'),ifChecked:false,menuId:14},
            {name:"巡检计划",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:23},
            {name:"巡检任务",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:24},
            {name:"我的巡检",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:38},
            {name:"工单管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:25},
            {name:"我的工单",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:39},
            {name:"工单设置",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:26},
            {name:"巡检设置",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:27},
            {name:"运维统计",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:41},
        {name:"客户管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon9.png'),ifChecked:false,menuId:16},
        {name:"设备管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon10.png'),ifChecked:false,menuId:17},
            {name:"配电设备",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:35},
            {name:"发电设备",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:36},
            {name:"仪表管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:37},
            {name:"配电用能",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:45},
        {name:"系统绘制",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon11.png'),ifChecked:false,menuId:18},
            {name:"图形管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:29},
            {name:"模板管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:31},
            // {name:"数据管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:32},
            {name:"元件管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:33},
            {name:"元件分组",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:34},
        {name:"系统管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/icon12.png'),ifChecked:false,menuId:1},
            {name:"用户管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:2},
            {name:"部门管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:3},
            {name:"角色列表",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:4},
            {name:"字典管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:6},
            {name:"位置管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:19},
            {name:"配电维度",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:44},
            {name:"通讯管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:28},
            {name:"告警管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:40},
            {name:"日志管理",style:"cardSingel",iconSrc: require('@/assets/menuIcon/blank.png'),ifChecked:false,menuId:46},

       
      ]

    },

    // 点击搜索或者回车
    search() {
      this.currentPage = "1";
      this.getDataList();
    },
    // 更改用户状态选择框的选择
    selectChanged() {
      this.getDataList();
    },
    getDataList() {
      this.$store.dispatch("querySystemrole", {
          current: this.currentPage,
          size: this.pageSize,
          roleName: this.roleName,
          remark: this.remark
        }).then(() => {
          // 做数据深拷贝
          this.tableData=[]
          for(let i=0;i<this.userPageData.length;i++){
            const tableSingle = {roleName:"",remark:""}
            tableSingle.roleName = this.userPageData[i].roleName;
            tableSingle.remark = this.userPageData[i].remark;
            tableSingle.roleId = this.userPageData[i].roleId;
            this.tableData.push(tableSingle)
          }
          // 默认选中第一个，渲染右侧数据
          this.handelChangeRadio(this.tableData[0].roleId)
          this.totalNumForPagenation = this.$store.state.system.ifQueryRoleList.data.total;
        });
    },
    //新增按钮
    ifDialogShow() {
      this.$refs.dialogRef.show();
    },
    //编辑
    handleEdit(index) {
      this.$refs.dialogRef.showForEdit(this.userPageData[index]);
    },
    // 点击了删除按钮
    handleDelete(index) {
      this.$confirm("您确定要执行删除操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store
          .dispatch("postSystemDeleteRole", this.userPageData[index].roleId)
          .then(() => {
            if (this.ifDeleteRole === 0) {
              this.$message({ message: "删除成功！", type: "success" });
              this.getDataList();
            } else if (this.ifDeleteRole === -1) {
              this.$message({
                message: "删除失败！请检查网络",
                type: "warning"
              });
            }
          });
      });
    },
    // 更改分页器页面
    handleSizeChange(val) {
      this.currentPage = "1";
      this.pageSize = val;
      this.getDataList();
    },
    // 更改分页器每页个数
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataList();
    },

    // 选择卡片
    chooseCard(item,index){ 
      // 点击后开启
      if(item.ifChecked===false){
        this.menuData[index].ifChecked=true;
        this.menuData[index].style = "cardSingel2";
        // 点击后关闭
        }else{
          this.menuData[index].ifChecked=false;
          this.menuData[index].style = "cardSingel";
        }

    },

    // 选择角色
    handelChangeRadio(roleId){
      this.radioValue = roleId
      this.rightInit()
      this.$store.dispatch('getSystemCharacterMenuList',roleId).then(()=>{
        this.menuIdList = this.menuList
        for(let i=0;i<this.menuData.length;i++){
          if(this.menuIdList.indexOf(this.menuData[i].menuId)!==-1){
            this.menuData[i].ifChecked = true
            this.menuData[i].style = "cardSingel2"
          }
        }
      })
    },

    // 确认保存
    confirmSave(){
      // 先捞出选中的数据组成一个列表
      this.menuIdList =[]
      for(let i=0;i<this.menuData.length;i++){
        if(this.menuData[i].ifChecked==true){this.menuIdList.push(this.menuData[i].menuId)}  
      }
      // 对列表进行判断是否有不符合要求的选择
      // 选择了父元素但是儿子为空的判断
      if(this.menuIdList.indexOf(14)>=0 && this.menuIdList.indexOf(23)<0 && this.menuIdList.indexOf(24)<0 && this.menuIdList.indexOf(38)<0 && this.menuIdList.indexOf(39)<0&& this.menuIdList.indexOf(25)<0 && this.menuIdList.indexOf(26)<0 && this.menuIdList.indexOf(27)<0 && this.menuIdList.indexOf(41)<0){this.$message.error("运维检修模块至少要选择一个二级菜单！")}
      else if(this.menuIdList.indexOf(17)>=0 && this.menuIdList.indexOf(35)<0 && this.menuIdList.indexOf(36)<0 && this.menuIdList.indexOf(37)<0){this.$message.error("设备模块至少要选择一个二级菜单！")}
      else if(this.menuIdList.indexOf(18)>=0 && this.menuIdList.indexOf(29)<0 && this.menuIdList.indexOf(31)<0 && this.menuIdList.indexOf(32)<0 && this.menuIdList.indexOf(33)<0 && this.menuIdList.indexOf(34)<0 ){this.$message.error("系统绘制模块至少要选择一个二级菜单！")}
      else if(this.menuIdList.indexOf(1)>=0 && this.menuIdList.indexOf(2)<0 && this.menuIdList.indexOf(3)<0 && this.menuIdList.indexOf(4)<0 && this.menuIdList.indexOf(6)<0 && this.menuIdList.indexOf(19)<0 && this.menuIdList.indexOf(40)<0&& this.menuIdList.indexOf(28)<0){this.$message.error("系统管理模块至少要选择一个二级菜单！")}
      else{
        // 选择了子元素但是父元素为空
        if(this.menuIdList.indexOf(14)<0 && (this.menuIdList.indexOf(23)>=0 || this.menuIdList.indexOf(24)>=0|| this.menuIdList.indexOf(39)>=0 || this.menuIdList.indexOf(38)>=0 || this.menuIdList.indexOf(25)>=0 || this.menuIdList.indexOf(26)>=0 || this.menuIdList.indexOf(27)>=0|| this.menuIdList.indexOf(41)>=0) ){this.menuIdList.push(14);this.$message.success("已补全您所选二级菜单对应的一级菜单")}
        else if(this.menuIdList.indexOf(17)<0 && (this.menuIdList.indexOf(35)>=0 || this.menuIdList.indexOf(36)>=0 || this.menuIdList.indexOf(37)>=0)){this.menuIdList.push(17);this.$message.success("已补全您所选二级菜单对应的一级菜单")}
        else if(this.menuIdList.indexOf(18)<0 && (this.menuIdList.indexOf(29)>=0 || this.menuIdList.indexOf(31)>=0 || this.menuIdList.indexOf(32)>=0 || this.menuIdList.indexOf(33)>=0 || this.menuIdList.indexOf(34)>=0) ){this.menuIdList.push(18);this.$message.success("已补全您所选二级菜单对应的一级菜单")}
        else if(this.menuIdList.indexOf(1)<0 && (this.menuIdList.indexOf(2)>=0 || this.menuIdList.indexOf(3)>=0 || this.menuIdList.indexOf(4)>=0 || this.menuIdList.indexOf(6)>=0 || this.menuIdList.indexOf(19)>=0|| this.menuIdList.indexOf(40)>=0 || this.menuIdList.indexOf(28)>=0) ){this.menuIdList.push(1);this.$message.success("已补全您所选二级菜单对应的一级菜单")}
        
        // 发送请求
        this.$store.dispatch('postSystemNewRoleEdit',{roleId:this.radioValue,menuIdList:this.menuIdList}).then(()=>{
          if(this.ifPostRoleEdit==0){this.$message.success("修改菜单权限成功！")}
          else{this.$message.error("修改菜单权限失败！请询问后台人员")}
        })  
      }
    },

  }
};
</script>

<style lang="less" scoped>
// 顶部样式##################################################################
.topBar {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  box-sizing: border-box;
  height: 8rem;
  width: 170.8rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  .searchInput {
    height: 3.2rem;
    width: 22.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
    background-color: #0c375e;
    border: 1px solid #004579;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    text-indent: 1.4rem;
    &:focus {
      border: 0.1rem solid #008080;
    }
  }
  
  button {
    height: 3.2rem;
    width: 7.4rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
  }
  // .btn_save{
  //   margin-left: 2rem;
  // }
  .btn2 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 159rem;
    width: 10.2rem;
  }
  .btn3 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 26rem;
  }
}

// 中部表格样式##################################################################
.userTablePlace {
  height: 86.4rem;
  width: 140.8rem;
  position: absolute;
  top: 10.4rem;
  left: 0;
  overflow: hidden;
  background-color: #0d3050;
  .bread{
      color: #fff;
      height: 5rem;
      width: 142.8rem;
      line-height: 5rem;
      color: #fff;
      font-size: 1.4rem;
      text-indent: 2.4rem;
      
    }
  .table {
    height: 57.2rem;
    width: 136rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}

// 右侧数据
.rightTablePlace{
  height: 86.4rem;
  width: 27.7rem;
  border-radius: 0.4rem;
  position: absolute;
  top: 10.4rem;
  left: 143rem;
  overflow: hidden; 
  background-color: #0d3050;
  .innerPlace{
    height: 86.4rem;
    width: 28.7rem;
    overflow: scroll;
    position: absolute;
    top: 0rem;
    left: 0.5rem;
    background-color: #0d3050;
  
     .imgFront {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 0.8rem;
      left: 1.6rem;
    }
    .confirmOutSider{
      height: 6rem;
      width: 27rem;
      background-color: #0d3050;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 100;
      border-bottom: #254561 0.1rem solid;
      // box-shadow: 0 0.2rem 0.5rem grey;
    }
    .confirm{
      height: 3rem;
      background-color: #027969;
      width: 9.9rem;
      position: absolute;
      top: 1.5em;
      left: 8.4rem;
      // margin: 1rem 8.4rem 4rem 8.4rem;
      border-radius: 0.2rem;
      cursor: pointer;
      color: #fff;
      font-size: 1.4rem;
      text-align: center;
      // text-indent: 9rem;
      line-height: 3rem;
      position:relative;
          &:hover{
        background-color: #09cca7;
      }
    }

    .cardSingel{
      height: 4rem;
      background-color: #0a4070;
      width: 23.9rem;
      margin: 1rem 1.4rem 0rem 1.4rem;
      border-radius: 0.2rem;
      cursor: pointer;
      color: #fff;
      font-size: 1.6rem;
      // text-align: center;
      text-indent: 9rem;
      line-height: 4rem;
      position:relative;
      &:hover{
        background-color: #084c8b;
      }
    }

    .cardSingel2{
      height: 4rem;
      background-color: #027969;
      width: 23.9rem;
      margin: 1rem 1.4rem 0rem 1.4rem;
      border-radius: 0.2rem;
      cursor: pointer;
      color: #fff;
      font-size: 1.6rem;
      // text-align: center;
      text-indent: 9rem;
      line-height: 4rem;
      position:relative;
      // &:hover{
      //   background-color: #09cca7;
      // }
    }





  }
    

   
 }



</style>





<style lang='less'>
// 公共样式！谨防代码污染！#########################################
.userPageInSystem {
  .table {
    height: 67.2rem;
    width: 128rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
.searchIpt .el-input__suffix,
.searchIpt .el-icon-circle-close,
.searchIpt .el-input-clear {
  line-height: 40px;
  right: 0;
  cursor: pointer;
  background: #399be6;
  border-radius: 0px 4px 4px 0px;
  color: #fff;
}
.searchIpt .icon,
.searchIpt .el-icon-circle-close,
.searchIpt .el-input-clear {
  width: 40px;
  height: 38px;
}
</style>