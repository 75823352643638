<template>
    <div class="page-main">
        <!-- 左侧树 -->
        <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="blockLeft page-main-tree">
            <TreeMonitoring />
        </div>
        <!-- 右侧内容区 -->
        <div :class="isTreeShow ? 'shrink-open_tree_content' : 'shrink-close_tree_content'"
              class="blockRight page-main-content">
            <div class="shrink-btn" @click="isTreeShow = !isTreeShow"></div>
            <div class="tab-title content-nav">
                <div :class="{ style1: tabIdx === 1 }" @click="tabIdx = 1">配电监控</div>
                <div :class="{ style1: tabIdx === 2 }" class="report_btn" @click="tabIdx = 2">报表统计</div>
            </div>
            <!-- 根据tabIdx切换显示【配电监控/报表统计】的视图 -->
            <templates v-if="tabIdx === 2 && treeInfo" class="table-main">
                <Report :treeInfo="treeInfo"/>
            </templates>
            <BlankPage v-else-if="!treeInfo || (treeInfo && treeInfo.type !== 4)" />
            <router-view v-else></router-view>
        </div>
    </div>
</template>

<script>
import TreeMonitoring from './components/TreeMonitoring.vue';
import Report from '@/components/Report.vue';
import BlankPage from "./components/BlankPage";

export default {
    name: 'Monitoring',
    components: {BlankPage, TreeMonitoring, Report },
    data () {
        return {
            isTreeShow: true,
            tabIdx: 1,
            treeInfo: null,
        }
    },
    methods: {
        // 点击树的节点
        changeTreeNode (data) {
            const { id, name, type, assortedName } = data;
            this.treeInfo = { id, name, type, assortedName };
            if (data.type === 4) {
                if (data.name === '10kV系统图') {
                    this.$router.push({ path: '/sysMap10kv', query: { id } })
                }
                else if (data.name === '400V系统图') {
                    this.$router.push({ path: '/sysMap400v', query: { id } })
                }
                else if (data.name === '直流屏') {
                    this.$router.push({ path: '/sysMapdCscreen', query: { id } })
                }
                else if (data.name === '变压器') {
                    this.$router.push({ path: '/monitoring/transformer', query: { id } })
                }
            } else {
                // 这个路由是错的，因为ID根本不对应10kV的ID
                this.$router.push({ path: '/sysMap10kv', query: { id } })
            }
        },
    },

}
</script>

<style lang="less" scoped>
.blockLeft {
    height: 96.8rem;
    overflow: hidden;
}
.blockRight {
    width: 142.8rem;
    height: 96.8rem;
    // position: absolute;
    // left: 28.4rem;
    // top: 0;
    overflow-y: auto;
}
.blockRight::-webkit-scrollbar {
    width: 0;
}
.content-nav.tab-title {
    display: flex;
    // background: #0d3050;
    background: rgba(0, 115, 222, 0.35);
    border-radius: 4px;
    padding: 1.6rem 2.4rem;
    border-bottom: none;
    // height: 64px;
    width: 100%;
    height: 6.4rem;
    box-sizing: border-box;
    margin-bottom: 1.6rem;
    > div {
        cursor: pointer;
        background: rgba(0, 115, 222, 0.1);
        border: 1px solid #004579;
        box-sizing: border-box;
        border-radius: 0px 2px 2px 0px;
        font-size: 14px;
        color: #ffffff;
        padding: 0 2.3rem;
        line-height: 3.2rem;
        height: 3.2rem;
        margin: 0;
    }
    > div.style1 {
        background: #027969;
        border-radius: 2px 0px 0px 2px;
        border-color: #027969;
    }
    .style1::before {
        position: static;
    }
    .report_btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .report_btn::before {
        content: "";
        width: 1.3rem;
        height: 1.6rem;
        background: url("../../assets/icons/Vector.png") no-repeat;
        background-size: 100% auto;
        margin-right: 0.6rem;
    }
}
.table-main {
    width: 100%;
}
</style>
