import { Graph } from '@antv/x6';
import { SVGSizeEnum, CustomEventTypeEnum, StoreKey } from "@/packages/x6/common/enums.js";
import { Channel } from "@/packages/x6/common/transmit.js";


Graph.registerNode(
	'svg-image',
	{
		inherit: 'rect',
		width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight,
		markup: [{
			tagName: 'rect',
			selector: 'body',
		}, {
			tagName: 'image',
		}],
		attrs: {
			body: {
				strokeWidth: 1,
				stroke: '#26C160',
				fill: '#26C160',
			},
			image: {
				width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight,
				x: 0,
				y: 0
			}
		},
	},
	true,
);

/**
 * x6实例化
 */
let _this;

export function initGraphPreView(vue) {
	_this = vue;
	const graph = new Graph({
		container: document.getElementById("container"),
		// width: 600,
		// height: 600,
		// 监听容器大小改变，并自动更新画布大小
		// https://x6.antv.vision/zh/docs/api/graph/graph#autoresize
		autoResize: true,
		// 键盘快捷键 Keyboard
		// https://x6.antv.vision/zh/docs/tutorial/basic/keyboard/#gatsby-focus-wrapper
		keyboard: {
			enabled: true,
			global: true,
		},
		// 画布平移
		// https://x6.antv.vision/zh/docs/tutorial/basic/graph/#%E7%94%BB%E5%B8%83%E5%B9%B3%E7%A7%BB
		panning: {
			enabled: false,
			eventTypes: ["leftMouseDown", "rightMouseDown", "mouseWheel"],
		},
		scroller: {
			enabled: true,
			className: "centerContain"
		},
		// 网格
		grid: {
			type: 'mesh', // 'dot' | 'fixedDot' | 'mesh'
			size: 10, // 网格大小 10px
			visible: false, // 渲染网格背景,
			args: {
				color: '#a0a0a0', // 网格线/点颜色
				thickness: 1,     // 网格线宽度/网格点大小
			},
		},
		// 可以通过 highlighting 选项来指定触发某种交互时的高亮样式
		// https://x6.antv.vision/zh/docs/api/graph/interaction/#highlighting
		highlighting: {
			// 当链接桩可以被链接时，在链接桩外围渲染一个 2px 宽的红色矩形框
			magnetAvailable: {
				name: "stroke",
				args: {
					padding: 4,
					attrs: {
						"stroke-width": 4,
						stroke: "skyblue",
					},
				},
			},
			// 连线过程中，自动吸附到链接桩时被使用
			magnetAdsorbed: {
				name: "stroke",
				args: {
					padding: 4,
					attrs: {
						"stroke-width": 8,
						stroke: "skyblue",
					},
				},
			},
		},
		// 滚轮缩放
		mousewheel: {
			enabled: true,
			modifiers: ["ctrl", "meta"],
		},
		// 迷你地图
		minimap: {
			enabled: true,
			// width: 250,
			// height: 250,
			container: document.getElementById("minimap"),
			padding: 40,
		},
		// 是否禁用浏览器默认右键菜单。
		preventDefaultContextMenu: true,
		// 定制节点和边的交互行为
		// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#%E5%AE%9A%E5%88%B6%E4%BA%A4%E4%BA%92%E8%A1%8C%E4%B8%BA
		/*interacting: function (cellView) {
			if (cellView.cell.getData()?.disableMove) {
				return {nodeMovable: false}
			}
			return true
		},*/
		interacting: {
			nodeMovable: (view, CellView) => {
				const cell = view.cell
				return cell.prop('movable') === true
			},
			magnetConnectable: true,
			edgeMovable: true,
			edgeLabelMovable: true,
			arrowheadMovable: true,
			vertexMovable: true,
			vertexAddable: true,
			vertexDeletable: true,
		},
		resizing: {
			enabled: true,
			minWidth: 30,
			minHeight: 30,
			maxWidth: Number.MAX_SAFE_INTEGER,
			maxHeight: Number.MAX_SAFE_INTEGER,
			orthogonal: true,
			restricted: true,
			autoScroll: true,
			preserveAspectRatio: true, // 缩放过程中是否保持节点的宽高比例，默认为 false。
			allowReverse: true,
		},
		rotating: {
			enabled: true,
			// grid: 15,
		},
		// 配置全局的连线规则
		// https://x6.antv.vision/zh/docs/api/graph/interaction
		connecting: {
			// 自动吸附
			snap: true,
			// 不允许连接到画布空白位置的点
			allowBlank: true,
			// 不允许创建循环连线
			allowLoop: false,
			// 不允许在相同的起始节点和终止之间创建多条边
			allowMulti: true,
			// 高亮显示所有可用的连接桩或节点
			// https://x6.antv.vision/zh/docs/tutorial/basic/interacting/#highlight
			highlight: true,
			// 当连接到节点时，通过 sourceAnchor 来指定源节点的锚点。
			sourceAnchor: {
				name: "center",
			},
			// 当连接到节点时，通过 targetAnchor 来指定目标节点的锚点。
			targetAnchor: "orth",
			// 指定连接点，默认值为 boundary。
			connectionPoint: "anchor",
			// 连接器将起点、路由返回的点、终点加工为 元素的 d 属性，决定了边渲染到画布后的样式，默认值为 normal。
			connector: {
				name: "normal", // rounded
				args: {
					radius: 20,
				},
			},
			// 路由将边的路径点 vertices 做进一步转换处理，并在必要时添加额外的点，然后返回处理后的点，默认值为 normal。
			// router: "manhattan",
		},
	});
	// 缓存实例化graph引用
	window[StoreKey.GRAPH] = graph;
	// graph.zoom(0.1)
	graph.centerContent()
	return graph
}

/**
 * 冻结画布
 */
export function freezeGraph(graph) {
	const cells = graph.getCells();
	if (cells.length) {
		cells.forEach(cell => {
			cell.removeTools();
			cell.setData({ disableMove: true })
		});
	}
	graph.freeze()
		// 单选框
		.disableSelection()
		// 多选框
		.disableMultipleSelection()
		// 清空选区
		.cleanSelection()
		// 剪切板
		.disableClipboard()
		// 历史记录
		.disableHistory()
		// 对齐线
		.disableSnapline()
		// 快捷键
		.disableKeyboard()
	Channel.dispatchEvent(CustomEventTypeEnum.FREEZE_GRAPH, true);
}