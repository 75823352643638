<template>
  <!-- 线图 -->
  <div class="dialog_echarts">
    <div id="powerLineData" class="charts"></div>
  </div>
</template>

<script>
export default {
  name: "EchartAreaMap",
  data() {
    return {
      ydata: "",
      series: "",
      unit: "",
      legendData: "",
      yMax: "",
    };
  },
  props: ["inverterId", "type", "date"],
  methods: {
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    initData() {
      this.series = [];
      let value = {
        inverterId: this.inverterId,
        type: this.type,
        date: this.date,
      };
      // console.log(value);
      this.$store.dispatch("getAlternateData", value).then(() => {
        if (this.$store.state.genManagement.getAlternateData.errorCode == 0) {
          this.ydata = this.$store.state.genManagement.getAlternateData.data;
          if (this.ydata.length == 3) {
            if (this.type == 1) {
              this.unit = "单位：V";
            } else if (this.type == 2) {
              this.unit = "单位：A";
            }
            this.legendData = [this.ydata[0].name, this.ydata[1].name, this.ydata[2].name];
            this.series = [
              {
                name: this.ydata[0].name,
                type: "line",
                showSymbol:false,
                smooth:true,
                itemStyle: { normal: { color: "#F6BD16" } },
                data: this.ydata[0].data,
              },
              {
                name: this.ydata[1].name,
                type: "line",
                showSymbol:false,
                smooth:true,
                itemStyle: { normal: { color: "#5AD8A6" } },
                data: this.ydata[1].data,
              },
              {
                name: this.ydata[2].name,
                type: "line",
                showSymbol:false,
                smooth:true,
                itemStyle: { normal: { color: "#E8684A" } },
                data: this.ydata[2].data,
              },
            ];
          } else if (this.ydata.length == 1) {
            if (this.type == 3) {
              this.unit = "单位：kW";
            } else if (this.type == 4) {
              this.unit = "";
            } else if (this.type == 5) {
              this.unit = "单位：Hz";
            }
            this.legendData = [this.ydata[0].name];
            this.series = [
              {
                name: this.ydata[0].name,
                type: "line",
                showSymbol:false,
                smooth:true,
                itemStyle: { normal: { color: "#F6BD16" } },
                data: this.ydata[0].data,
              },
            ];
          } else if (this.ydata.length == 0) {
            this.series = [];
          }
          this.drawLine1();
        } else {
          this.series = [];
          this.drawLine1();
        }
      });
    },
    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      //   let myChart = this.$echarts.init(this.$refs.myChart);
      //   if (myChart == null) {
      //     myChart = this.$echarts.init(this.$refs.myChart);
      //   }
      let myChart = this.$echarts.init(document.getElementById("powerLineData"));
      let yMax = 0;
      if (this.series.length > 0) {
        for (let i = 0; i < this.series.length; i++) {
          const item = this.series[i].data;
          for (let j = 0; j < item.length; j++) {
            if (item[j] > yMax) {
              yMax = item[j];
            }
          }
          this.yMax = this.ceilNumber(yMax);
        }
      } else {
        this.yMax = 10;
      }
      let option = {
        title: {
          // text: "本月用电趋势",
          top: "0px",
          left: "15px",
          textStyle: {
            color: "#37A5DD",
            fontSize: 14,
            lineHeight: 10,
            fontWeight: 400,
          },
          subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
        },
        // color: ["#3673E8", "#61B8FF"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          orient: "horizontal", //一排或者一列
          x: "center", //可设定图例在左、右、居中
          y: "bottom", //可设定图例在上、下、居中
          textStyle: {
            color: "#fff",
          },
          padding: [0, 0, 5, 0],
          data: this.legendData,
          icon: "circle", // 图例形状
        },
        grid: {
          x: 18,
          x2: 20,
          y2: 28,
          y: 30,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            // lineStyle: {
            //   color: "rgba(43, 113, 176, 0.45)",
            // },
          },
          axisLabel: {
            color: "#fff",
          },
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
            "24:00",
          ],
        },
        yAxis: [
          {
            min: 0,
            max: this.yMax,
            interval: this.yMax / 5,
            name: this.unit,
            nameTextStyle: {
              fontSize: 12,
              color: "rgba(230, 247, 255, 0.7)",
            },
            type: "value",
            axisLabel: {
              color: "#fff",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(230,247,255,0.2)",
              },
            },
          },
        ],
        series: this.series,
      };
      // 绘制图表
      myChart.setOption(option, true);
      myChart._dom.childNodes[0].childNodes[0].style.width = "87.5rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "42rem";
    },
  },
  watch: {
    date: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.date = newVal;
        this.initData();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.dialog_echarts {
  .charts {
    width: 87.5rem;
    height: 42rem;
  }
}
</style>
