import request from '@/utils/request';

//export const sysMapTenApi = params => {
//export function getDiagramsByScenarioCode (params){
//  return requestMap({
//    url: '/diagram/main/listByCode',
//    method: 'GET',
//    params
//  })
//}

export function listMeterByPlaceId(placeId){
  return request({
    url: `/place/meter/${placeId}`,
    method: 'get'
  })
}

export function sysMapListApi (pageSize, pageNum, nodeId) {
  return request({
    url: '/watch/page/direct/' + `${pageSize}` + '/' + `${pageNum}` + '/' + `${nodeId}`,
    method: 'get'
  })
}

export function getDeviceInfo (meterId) {
  return request({
    url: `/watch/layer/device/info/${meterId}`,
    method: 'get'
  })
}

export function getMaxLoadAndTime (meterId) {
  return request({
    url: `/watch/layer/device/info/maxgl/${meterId}`,
    method: 'get'
  })
}

export function getRealTimeDeviceData(val) {
  return request({
    url: `/watch/layer/device/${val.meterId}/${val.datetype}/${val.date}`,
    method: 'get'
  })
}

export function exportHarmonicRawData(val) {
  return request({
    url: `/watch/layer/harmonic/export/${val.meterId}/${val.datetype}/${val.date}`,
    method: 'get',
    responseType: 'blob',
  })
}