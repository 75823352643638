import request from '@/utils/request'

// 查询区域列表
export function listAllArea(query) {
	return request({
		url: '/diagram/area/allList',
		method: 'get',
		params: query
	})
}

// 查询数据列表
export function listData(query) {
	return request({
		url: '/diagram/data/list',
		method: 'get',
		params: query
	})
}