<template>
  <div class="computed3">
    <div class="comLeft">
      <el-table :data="tableData" style="width: 100%;height:100%;">
        <el-table-column prop="date" label="阶梯" width="66"></el-table-column>
        <el-table-column show-overflow-tooltip prop="name" label="时段" min-width="310">
          <template slot-scope="scope">
            <div class="back-inp" @click="changeDailog">
              <p class="inp">{{ scope.row.name }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="电价">
          <template slot-scope="scope">
            <input
              size="mini"
              v-model="scope.row.time"
              @blur="inputNumberOnblur3(scope.row.id, scope.$index)"
              placeholder="请输入内容"
              class="elecValue"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <InnerDailog3 v-if="showDailog" ref="innerEditDlg" @refreshDataList="updateDate" :radioValue="radioValue" />
  </div>
</template>

<script>
import { mapState } from "vuex";
// import indexVue from "../../../FireControl/index.vue";
import InnerDailog3 from "./innerDailog";
export default {
  components: {
    InnerDailog3,
  },
  name: "innerArea3",
  data() {
    return {
      showDailog: false,
      dataForm: {
        // 单一电价双向绑定(记得改为后台请求获得)
        elecPriceSingel1: "0.00",
        elecPriceSingel2: "0.02",
        measure1: "",
        measure2: "",
        measure3: "",
        jianData: "",
        fengData: "",
        pingData: "",
        guData: "",
      },
      tableData: [
        {
          id: "5",
          date: "尖",
          name: "9：00-12：00，  19：00-22：00",
          address: "上海市普陀区金沙江路",
          time: "111",
        },
        {
          id: "6",
          date: "峰",
          name: "8：00-9：00，    12：00-17：00",
          address: "上海市普陀区金沙江路",
          time: "111",
        },
        {
          id: "7",
          date: "平",
          name: "17：00-19：00， 22：00-23：00",
          address: "上海市普陀区金沙江路",
          time: "111",
        },
        {
          id: "8",
          date: "谷",
          name: "00：00-8：00",
          address: "上海市普陀区金沙江路",
          time: "111",
        },
      ],
      // 防抖预设值
      timeout1: null,
      timeout2: null,
      timeout3: null,
    };
  },
  props: ["radioValue"],
  mounted() {
    // this.getEnergyNum();
  },
  methods: {
    //获取对应的信息
    findInfo(key){
      return this.getGeneralSetting.find((item => item.name === key))
    },
    getEnergyNum() {
      // this.$store.dispatch("getGeneralSetting", {
      //     generator: "ENERGYMANAGEMENT",
      //     customerId: this.$store.state.storage.customerId,
      //   }).then(() => {
        this.dataForm = this.getGeneralSetting;
        this.tableData[0].time = this.findInfo('TIME1POWERPRICE').value;
        this.tableData[1].time = this.findInfo('TIME2POWERPRICE').value;
        this.tableData[2].time = this.findInfo('TIME3POWERPRICE').value;
        this.tableData[3].time = this.findInfo('TIME4POWERPRICE').value;
        this.tableData[0].id = this.findInfo('TIME1POWERPRICE').id;
        this.tableData[1].id = this.findInfo('TIME2POWERPRICE').id;
        this.tableData[2].id = this.findInfo('TIME3POWERPRICE').id;
        this.tableData[3].id = this.findInfo('TIME4POWERPRICE').id;
        //尖峰平谷时间划分
        let item = JSON.parse(this.findInfo('TIMESPLIT').value);
        let string1 = "";
        let string2 = "";
        let string3 = "";
        let string4 = "";
        let jianList = item.jian;
        for (let i = 0; i < jianList.length; i++) {
          string1 = string1 + jianList[i].time + " ,   ";
          this.tableData[0].name = string1;
        }
        let fengList = item.feng;
        for (let i = 0; i < fengList.length; i++) {
          string2 = string2 + fengList[i].time + " ,   ";
          this.tableData[1].name = string2;
        }
        let pingList = item.ping;
        for (let i = 0; i < pingList.length; i++) {
          string3 = string3 + pingList[i].time + " ,   ";
          this.tableData[2].name = string3;
        }
        let guList = item.gu;
        for (let i = 0; i < guList.length; i++) {
          string4 = string4 + guList[i].time + " ,   ";
          this.tableData[3].name = string4;
        }
      // });
    },
    inputNumberOnblur3(id, index) {
      // id值可以直接使用
      let idx = id;
      let value = {
        value: this.tableData[index].time,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.radioValue,
      };
      this.$store
        .dispatch("putGeneral", {
          id: idx,
          data: value,
        })
        .then(() => {
          if (this.putGeneral) {
            this.$message({ message: "编辑成功！", type: "success" });
            this.updateDate()
          } else {
            this.$message({ message: "编辑失败，请重试!", type: "warning" });
          }
        });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
    changeDailog() {
      this.showDailog = true;
      this.$nextTick(() => {
        this.$refs.innerEditDlg.init();
      });
    },
    //操作成功之后更新数据
    updateDate(){
      this.$store.dispatch("getGeneralSetting", {
          generator: "ENERGYMANAGEMENT",
          customerId: this.$store.state.storage.customerId,
        }).then(() => {
        });
    }
  },
  computed: {
    ...mapState({
      getGeneralSetting: (state) => {
        return state.elecManagement.getGeneralSetting.data;
      },
      putGeneral: (state) => {
        return state.elecManagement.putGeneral.data;
      },
    }),
  },
  watch: {
    // '$store.state.storage.customerId': {
    //   immediate: true,
    //   deep: true,
    //   handler(newVal, oldVal) {
    //     if (!newVal) {
    //       return
    //     }
    //     this.getEnergyNum();
    //   },
    // },
    '$store.state.elecManagement.getGeneralSetting.data': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return
        }
        this.getEnergyNum();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.computed3 {
  .comLeft {
    .word1 {
      height: 2.2rem;
      width: 4.2rem;
      position: absolute;
      top: 2.9rem;
      left: 2.4rem;
      font-size: 1.4rem;
      color: #fff;
    }
    .inputNumber {
      position: absolute;
      top: 2.4rem;
      left: 7.4rem;
      span {
        position: relative;
        left: 1.5rem;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.85);
      }
    }
    .back-inp {
      width: 46.2rem;
      height: 3.2rem;
      line-height: 3.2rem;
      background-color: rgba(0, 115, 222, 0.4);
      padding-left: 1rem;
      cursor: pointer;
      .inp {
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
  .comRigth {
    position: absolute;
    right: -50rem;
    top: -5.3rem;
    .con2 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .con-content {
      width: 39.9rem;
      height: 26rem;
      margin-top: 3rem;
      padding-top: 2rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      span {
        position: absolute;
        margin-left: 2rem;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
  .conBottomLeft {
    position: absolute;
    top: 32rem;
    .con3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .conputent {
      width: 31rem;
      height: 13.6rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      margin-top: 3rem;
      // position: relative;
      .con-img-inline {
        position: absolute;

        left: 50%;
        margin-left: -7rem;
        top: 8rem;
        .img1 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
        .img2 {
          width: 1.6rem;
          height: 1.5rem;
          margin-left: 1rem;
          position: relative;
          top: -0.8rem;
        }
        .img3 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
      }
      .con-ipt {
        position: absolute;
        top: 13rem;
        left: 3rem;
        .btn-inp {
          width: 13rem;
          // height: 3.2rem;
        }
        .ipt-left {
          font-size: 1.4rem;
          color: #ffffff;
          margin-right: 1.5rem;
        }
        .ipt-rigth {
          // position: absolute;
          // left: 23.5rem;
          // top: 0.5rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 1.5rem;
        }
      }
    }
  }
  .conBottomRigth {
    position: absolute;
    top: 32rem;
    left: 36rem;
    .con3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .conputent {
      width: 31rem;
      height: 13.6rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      margin-top: 3rem;
      // position: relative;
      .con-img-inline {
        position: absolute;

        left: 50%;
        margin-left: -7rem;
        top: 8rem;
        .img1 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
        .img2 {
          width: 1.6rem;
          height: 1.5rem;
          margin-left: 1rem;
          position: relative;
          top: -0.8rem;
        }
        .img3 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
      }
      .con-ipt {
        position: absolute;
        top: 13rem;
        left: 3rem;
        .btn-inp {
          width: 13rem;
          // height: 3.2rem;
        }
        .ipt-left {
          font-size: 1.4rem;
          color: #ffffff;
          margin-right: 1.5rem;
        }
        .ipt-rigth {
          // position: absolute;
          // left: 23.5rem;
          // top: 0.5rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 1.5rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.computed3 {
  .el-radio__label {
    font-size: 1.4rem;
    padding-left: 0.5rem;
  }
  .el-radio {
    margin-right: 1rem;
  }
  .el-table thead {
    color: #fff;
    font-weight: 500;
  }
  .el-table {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    font-size: 14px;
    color: #fff;
    tr {
      height: 5.1rem;
      .elecValue {
        width: 7.8rem;
        height: 2.8rem;
        background: rgba(2, 121, 105, 0.2);
        border: 0.1rem solid #027969;
        border-radius: 0.2rem;
        padding-left: 1rem;
        font-size: 1.4rem;
        color: #03d9bc;
      }
      .elecValue:focus {
        outline: none;
      }
      .elecValue:hover {
        border: 0.1rem solid #03d9bc;
      }
    }
  }
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #03d9bc;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #03d9bc;
    background: #0d3050;
  }

  .el-radio__inner {
    background-color: #0d3050;
  }

  .el-radio__inner::after {
    background-color: #03d9bc;
  }

  // 计数器样式
  .el-input-number__increase {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease {
    background-color: #0b3d6b;
  }
  .el-input-number .is-controls-right {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #0b3d6b;
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid #027969;
    border-left: 0.1rem solid #027969;
    box-shadow: none;
    border: 0.1rem solid #027969;
    box-sizing: border-box;
    border: none;
  }
  ::-webkit-scrollbar,
  .el-select-dropdown::-webkit-scrollbar {
    width: 0;
  }
  .el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 0.4rem 0 0;
    border-bottom: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }

  .el-input-number.is-controls-right .el-input-number__decrease {
    border-left: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }
  .el-table tbody tr {
    background: rgba(0, 115, 222, 0.1);
  }
  .el-table thead tr {
    background: rgba(52, 150, 241, 0.15);
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #0c2f4f;
  }
  .el-table .el-table__cell {
    padding: 0.5rem 0;
  }
}
</style>
