<template>
    <div class="ElecCharge page-main">
        <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="treePlace page-main-tree">
            <!--el树形控件  -->
            <TreeElecCharge ref="treeRef" />
        </div>

        <!-- 板块二/右侧 ##########################################################################-->
        <div :class="isTreeShow ? 'shrink-open_tree_content':'shrink-close_tree_content'" class="blockTwoRight page-main-content">
            <div class="shrink-btn" @click="isTreeShow = !isTreeShow">
            </div>
            <!-- 顶部菜单###################### -->
            <div class="topBar">
                <div class="btnList">
                    <button :class="item.classType" v-for="(item,index) in menuList" :key="index" @click="routerSwitchTo(item.router,$event)">{{item.name}}</button>
                </div>
            </div>

            <!-- 路由部分############################################ -->
            <div class="routerPlace">
                <router-view :isTreeShow="isTreeShow"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import TreeElecCharge from "./TreeElecCharge.vue";

export default {
    name: "ElecCharge",
    classNumber: 0,
    components: {
        TreeElecCharge
    },

    data () {
        return {
            isTreeShow: true,
            // 路由菜单的列表
            menuList: [
                { name: "运营总览", router: "chargeAll", classType: "btn2" },
                { name: "充电监测", router: "chargeMonitoring", classType: "btn1" },
                { name: "设备统计", router: "equipmentStatistics", classType: "btn1" },
                { name: "充电统计", router: "chargeStatistics", classType: "btn1" },
                { name: "订单统计", router: "orderStatistics", classType: "btn1" },
            ]
        };
    },

    methods: {
        // 点击tab进行路由跳转
        routerSwitchTo (val, event) {
            this.$router.push("/elecCharge/" + val);
            let brothers = event.target.parentNode.children;

            for (let i = 0; i < brothers.length; i++) {
                brothers[i].className = "btn1";
            }
            event.target.className = "btn2";
        }
    },

    watch: {

    }
};
</script>

<style lang="less" scoped>
// 树的样式#####################################################
// .treePlace {
//   overflow: hidden;
//   width: 26rem;
//   height: 96.8rem;
//   position: absolute;
//   left: 0;
//   top: 0;
// }

// 右侧样式#########################################################
// .blockTwoRight {
//   width: 143.6rem;
//   height: 96.8rem;
//   position: absolute;
//   left: 28.4rem;
//   top: 0;
//   .topBar {
//     display: flex;
//     align-items: center;
//     padding-left: 2rem;
//     box-sizing: border-box;
//     height: 6.4rem;
//     width: 143.6rem;
//     background-color: #0d3050;
//     border-radius: 0.4rem;
//     position: absolute;
//     top: 0;
//     left: 0;
//     overflow: hidden;
//     .btnList {
//       width: 51rem;
//       height: 3.2rem;
//       border: 0.1rem solid #004579;
//       border-radius: 0.2rem;
//       overflow: hidden;
//       button {
//         height: 3.2rem;
//         width: 10.2rem;
//         cursor: pointer;
//         border: none;
//         color: #fff;
//         font-size: 1.4rem;
//       }
//       .btn1 {
//         background-color: #0c375e;
//       }
//       .btn2 {
//         background-color: #027969;
//       }
//     }
//   }
//   //路由部分########################################################################################
//   .routerPlace {
//     height: 90rem;
//     width: 143.6rem;
//     top: 8.8rem;
//     left: 0;
//     // overflow: hidden;
//     background-color: #18191a;
//   }
// }
.ElecCharge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .treePlace {
        // flex-shrink: 0;
        // margin-right: 2rem;
        // width: 26rem;
        overflow: hidden;
        height: 96.8rem;
    }
    .blockTwoRight {
        // flex: 1;
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        // justify-content: flex-start;
        // overflow-x: hidden;
        height: 96.8rem;
        overflow: hidden;
        .topBar {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            width: 100%;
            height: 6.4rem;
            padding-left: 2rem;
            background-color: #0d3050;
            border-radius: 0.4rem;
            box-sizing: border-box;
            overflow: hidden;
            margin-bottom: 2.4rem;
            .btnList {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 3.2rem;
                border: 0.1rem solid #004579;
                border-radius: 0.2rem;
                box-sizing: border-box;
                overflow: hidden;
                .btn1,
                .btn2 {
                    flex: 1;
                    width: 10.2rem;
                    height: 100%;
                    font-size: 1.4rem;
                    color: #fff;
                    border: none;
                    cursor: pointer;
                }
                .btn1 {
                    background-color: #0c375e;
                }
                .btn2 {
                    background-color: #027969;
                }
            }
        }
        .routerPlace {
            position: unset !important;
            height: 86.4rem;
            width: 100%;
            background-color: #18191a;
        }
    }
}
</style>