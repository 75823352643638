<template>
  <div>
       <!-- ---------------------------------------------------------------------右侧板块一（消防单位统计） ---------------------------------------------------------------------->
     <div class="rightOne">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">消防单位统计</div>
      <!-- 图表 -->
      <div class="chartRightOne">
        <img src="@/assets/FullView/icon1.png"  class="iconStyle1" alt="">
        <img src="@/assets/FullView/icon2.png"  class="iconStyle2" alt="">
        <div class="wordPlace1">
          <div class="upper">客户单位</div>
          <div class="downer">{{myData.customerUnit2}} <span style="font-size:1.2rem;line-height:2.4rem;color:rgba(255,255,255,0.65);font-weight:400">个</span></div>
        </div>
        <div class="wordPlace2">
          <div class="upper">监测电表点</div>
          <div class="downer">{{myData.monitoringPoint}} <span style="font-size:1.2rem;line-height:2.4rem;color:rgba(255,255,255,0.65);font-weight:400">个</span></div>
        </div>
      </div>
    </div>

    <!-- ---------------------------------------------------------------------右侧板块二（配电单位统计） ---------------------------------------------------------------------->
     <div class="rightTwo">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">配电单位统计</div>
      <!-- 图表 -->
      <div class="chartRightTwo">
        <img src="@/assets/FullView/icon1.png"  class="iconStyle1" alt="">
        <img src="@/assets/FullView/icon3.png"  class="iconStyle2" alt="">
        <div class="wordPlace1">
          <div class="upper">客户单位</div>
          <div class="downer">{{myData.customerUnit}} <span style="font-size:1.2rem;line-height:2.4rem;color:rgba(255,255,255,0.65);font-weight:400">个</span></div>
        </div>
        <div class="wordPlace2">
          <div class="upper">监测变压器数</div>
          <div class="downer">{{myData.customerUnitTransformer}} <span style="font-size:1.2rem;line-height:2.4rem;color:rgba(255,255,255,0.65);font-weight:400">个</span></div>
        </div>
      </div>
    </div>

     <!-- ---------------------------------------------------------------------右侧板块三（发电统计） ---------------------------------------------------------------------->
     <div class="rightThree">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">发电统计</div>
      <!-- 图表 -->
      <div class="chartRightThree" ref="rightThreeRef"></div>
    </div>

    <!-- ---------------------------------------------------------------------右侧板块四（今日发电排名） ---------------------------------------------------------------------->
    <div class="leftThree">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">今日发电排名</div>
      <!-- 图表 -->
      <div class='chartLeftThree'>
          <div class="innerCard" v-for="(item,index) in optionLeftThree" :key="'card3'+index">
              <!-- 文字部分 -->
             <div class="wordPlace">
               <span class="left">No.{{index+1}} {{item.name}}</span>
               <span class="right">{{item.useEnergy}} kWh</span>
             </div>
             <!-- 进度条部分 -->
             <div class="barPlace">
               <div class="barGet" :style="barGetStyle[index]"></div>
               <div class="barLine" :style="barLineStyle[index]"></div>              
             </div>
          </div>

      </div>
    </div>


    <!-- ---------------------------------------------------------------------附加大板块 ---------------------------------------------------------------------->
     <div class="blockSup">
      <div :class="styleSup">
        <img class="supRightImg" src="@/assets/FullView/iconLarge1.png" alt="">
        <div class="wordUpper">客户单位</div>
        <div class="wordDowner">{{myData.customerUnitCount}}<span style="font-size:1.2rem;color: rgba(255,255,255,0.65)"> 个</span></div>
        <!-- <div class="word4Red">昨日：<span class="word4Inner">+1299 <i class="el-icon-top-right" style="font-size:1.2rem"/></span></div> -->
      </div>
      <div :class="styleSup2">
        <img class="supRightImg" src="@/assets/FullView/iconLarge2.png" alt="">
        <div class="wordUpper">变压器总容量</div>
        <div class="wordDowner">{{myData.transformerTotalCapacity}}<span style="font-size:1.2rem;color: rgba(255,255,255,0.65)"> kVA</span></div>
        <!-- <div class="word4Green">昨日：<span class="word4Inner">-1880 <i class="el-icon-bottom-right" style="font-size:1.2rem"/></span></div> -->
      </div>
      <div :class="styleSup3">
        <img class="supRightImg" src="@/assets/FullView/iconLarge4.png" alt="">
        <div class="wordUpper">总装机容量</div>
        <div class="wordDowner">{{myData.ladingTotalCapacity}}<span style="font-size:1.2rem;color: rgba(255,255,255,0.65)"> kW</span></div>
        <!-- <div class="word4Green">昨日：<span class="word4Inner">-120 <i class="el-icon-bottom-right" style="font-size:1.2rem"/></span></div> -->
       </div>
    </div>





  </div>
</template>

<script>
export default {
    name:"HuBeiRight",
    props: {
      myData: {
        type: Object,
        default: () => {
          return {}
        },
        require: true
      }
    },
    data(){return{
       // 页面数据综合
        // myData:[],
         // ############################################################################右侧第三个图数据 直方图 发电统计#############################################################################
        optionRightThree:{
            grid:{
                top:"15%",
                left:"12%",
                right:"3%",
                bottom:"12%"
            },
            xAxis: {
            type: 'category',
            // x轴刻度线
            axisTick: {show: false,},
            // x轴文字
            axisLabel: {
                interval:0,
                formatter: '{value}',
                textStyle: {
                    show:true,
                    fontSize:this.fontSize(1.2),
                    color: 'rgba(230, 247, 255, 0.5)',
                    fontWeight:100,
                },
            },
            // x轴基线
            axisLine: {
                lineStyle: {
                    color: '#bae7ff',
                }
            },
            data: [],
            },
            yAxis: {
            type: 'value',
            name: '（kWh）',
            // y轴顶部文字
            nameTextStyle:{
                color:"rgba(230, 247, 255, 0.5)", 
                fontSize:this.fontSize(1.2), 
                fontWeight:100,
                padding: [0, 0, 0, -35]
            },
            // y轴文字
            axisLabel: {
                formatter: '{value}',
                textStyle: {
                    show:true,
                    fontSize:this.fontSize(1.2),
                    color: 'rgba(230, 247, 255, 0.5)',
                    fontWeight:100,
                },
            },
            // 分割线样式
            splitLine:{
                lineStyle:{
                type:'dashed',
                color:"rgba(230, 247, 255, 0.2)"
                }
            }
            },
            series: [
            { 
                symbol:'none',
                barWidth:this.fontSize(1),
                color:'#19e6ea',
                // 设置渐变色
                itemStyle:{normal: {color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: '#36EAAF'},{offset: 1,color: '#4BB3D2'}]),}},
                data: [],
                type: 'bar'
            }
            ],
            tooltip: {
                extraCssText: "width:11rem;height:5.8rem",
                backgroundColor:'rgba(0,0,0,0.65)',
                borderColor:'rgba(0,0,0,0.65)',
                color:"#E6F7FF",
                trigger: 'axis',
                axisPointer: {
                type: 'line',
                crossStyle: {
                    color: '#999'
                }
                },
            formatter: function (params) {
                return '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">'+'时间：'+ params[0].name +'<br>' + '发电：' +'<span style="color:#36EAAF;">'+ params[0].data +'kWh'+'</span>'+'</span>';
            }    
            
            },
            

        },
        
        // ############################################################################右侧第四个图数据 自定义天梯图#############################################################################
        optionLeftThree:[],
        // 天梯图的样式(颜色柱)
        barGetStyle:[{width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},
                    {width:0,transition:'all 1s'},],
        // 天梯图的样式（白盖）
        barLineStyle:[{left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},
                    {left:0,transition:'all 1s'},],
  
        // 补充板块的样式
        styleSup:'blockSupInsider',
        styleSup2:'blockSupInsider2',
        styleSup3:'blockSupInsider3',
    }},

    watch: {
      myData: {
        handler: function(newVal, oldVal) {
          this.show(newVal)
        },
        deep: true,
        // immediate: true
      }
    },

    methods:{
        // 获取屏幕比例，使echarts自适应
        fontSize(res){
            let docEl = document.documentElement,
            clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
            if (!clientWidth) return;
            let fontSize = (clientWidth / 192);
            return res*fontSize;
        },

        // 渲染
        show(val){
        // this.myData = val
        // 渲染右边第三个图（直方图 发电统计） 
        this.optionRightThree.xAxis.data = val.generateElectricityS ? val.generateElectricityS.labels : []
        this.optionRightThree.series[0].data = val.generateElectricityS ? val.generateElectricityS.data : []
        var myChartRightThree = this.$echarts.init(this.$refs.rightThreeRef);
        myChartRightThree.setOption(this.optionRightThree);
        myChartRightThree._dom.childNodes[0].childNodes[0].style.height="23.5rem"
        myChartRightThree._dom.childNodes[0].childNodes[0].style.width="37.8rem"


        // 渲染右边第四个图（自定义天梯图） 数据渲染、有色柱表现渲染、白色盖子表现渲染
        this.optionLeftThree=val.generateElectricityRanking
        if(this.optionLeftThree.length<5){
          let num = 5-this.optionLeftThree.length;
            for(let i=1;i<=num;i++){
              this.optionLeftThree.push({name:'--',useEnergy:0})
            }
        }
        this.optionLeftThree.forEach((item, index) => {
            if (this.optionLeftThree[0].useEnergy !== 0) {
            this.barGetStyle[index] =  {width:(item.useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'}
            this.barLineStyle[index] =  {left:(item.useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'}
            } else {
            this.barGetStyle[index] =  {width:'0rem',transition:'all 1s'}
            this.barLineStyle[index] =  {left:'0rem',transition:'all 1s'}
            }
        })
        // this.barGetStyle =[{width:'34.4rem',transition:'all 1s'},
        // {width:(this.optionLeftThree[1].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        // {width:(this.optionLeftThree[2].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        // {width:(this.optionLeftThree[3].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        // {width:(this.optionLeftThree[4].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        // ],
        // this.barLineStyle =[{left:'34.4rem',transition:'all 1s'},
        // {left:(this.optionLeftThree[1].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        // {left:(this.optionLeftThree[2].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        // {left:(this.optionLeftThree[3].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        // {left:(this.optionLeftThree[4].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
        // ]
        
        // this.styleSup='blockSupInsider'
        // this.styleSup2='blockSupInsider2'
        // this.styleSup3='blockSupInsider3'

        // // 补充板块的展示动画
        // setTimeout(()=>{
        //   this.styleSup='blockSupInsiderShow'
        //   setTimeout(()=>{this.styleSup2='blockSupInsiderShow2'},100)
        //   setTimeout(()=>{this.styleSup3='blockSupInsiderShow3'},200)
        // },1000)
        },
        showAnimation() {
          this.styleSup='blockSupInsider'
          this.styleSup2='blockSupInsider2'
          this.styleSup3='blockSupInsider3'
          // 补充板块的展示动画
          setTimeout(()=>{
            this.styleSup='blockSupInsiderShow'
            setTimeout(()=>{this.styleSup2='blockSupInsiderShow2'},100)
            setTimeout(()=>{this.styleSup3='blockSupInsiderShow3'},200)
          },1000)
        }
    },

    mounted(){
      //  this.show()
    }
}
</script>

<style lang='less' scoped>

//  ----------------------------------------------------------------------------背景图部分 ----------------------------------------------------------------------
.FullView {
  height:108rem;
  width: 192rem;
  position: absolute;
  top: -8.8rem;
  left: -18.4rem;
  background-color: #16294C;
  .bgimg{
    // height:108rem;
    // width: 192rem;
    height: 129rem;
    width: 230.4rem;

    position: absolute;
    top: 4.4rem;
    left: 0rem;
  }
  .bgimgTitle{
    height: 8rem;
    width: 192rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bgimgHubei0{
    width: 10.82rem;
    height: 6.93rem;
    position: absolute;
    top: 46rem;
    right: 90rem;
    opacity: 0;
  }
  .bgimgHubei{
    width: 108.2rem;
    height: 69.3rem;
    position: absolute;
    top: 10.4rem;
    right: 42rem;
    opacity: 1;
    transition: all 1s;
  }
  .blockTitle{
    height: 5.1rem;
    width: 37.8rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .blockTitleWord{
    position: absolute;
    top: 1.4rem;
    left: 4rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1.6rem;
    color: #fff;
  }

}


//  ----------------------------------------------------------------------------右侧板块一 ----------------------------------------------------------------------
.rightOne{
  height: 13.3rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 2.4rem;
  .chartRightOne{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 8.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}


//  ----------------------------------------------------------------------------右侧板块二 ----------------------------------------------------------------------
.rightTwo{
  height: 13.3rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 17.7rem;
  .chartRightTwo{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 8.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}

//  ----------------------------------------------------------------------------右侧板块二 ----------------------------------------------------------------------
.rightThree{
  height: 28.6rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 32.6rem;
  .chartRightThree{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 23.5rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}

//  ----------------------------------------------------------------------------右侧板块四 ----------------------------------------------------------------------
.leftThree{
  height: 34.9rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 62.7rem;
  .chartLeftThree{
     background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 29.8rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
    padding-top: -0.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    box-sizing: border-box;
    .innerCard{
      height: 3.6rem;
      width: 100%;
      margin-top: 2.2rem;
      position: relative;
      top: 0;
      left: 0;
      .wordPlace{
        height: 2rem;
        color: #E6F7FF;
        font-size: 1.4rem;
        line-height: 2rem;
        .left{
          float: left;
        }
        .right{
          float: right;
        }
      }
      .barPlace{
        margin: 0.2rem 0;
        height: 1rem;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
      .barGet{
        height: 1rem;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #36EAAF 0%, #4BB3D2 100%); 
      }
      .barLine{
        width: 0.2rem;
        height: 1.4rem;
        background-color: #fff;
        position: absolute;
        top: -0.2rem;
      }
    }
  }
}


// 通用样式
.iconStyle1{
  width: 5.4rem;
  height: 5.1rem;
  position: absolute;
  left: 2.5rem;
  top: 1.623rem;
}
.iconStyle2{
  width: 5.4rem;
  height: 5.1rem;
  position: absolute;
  left: 19.6rem;
  top: 1.623rem;
}
.wordPlace1{
  height: 5rem;
  position: absolute;
  top: 1.6rem;
  left: 8.7rem;
  
}
.wordPlace2{
  height: 5rem;
  position: absolute;
  top: 1.6rem;
  left: 25.8rem;
}

.upper{
  height: 2rem;
  line-height: 2rem;
  color: #19dfe5;
  text-shadow: 0rem 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
}
.downer{
  height: 2.4rem;
  font-size: 2rem;
  color: #FBFBFB;
  text-shadow: 0rem 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
  line-height: 2.4rem;
  
}

// -----------------------------------------------------------------------------补充大板块-------------------------------------------
.blockSup{
  position: absolute;
  top: 0;
  right: 43.4rem;
  width: 30.5rem;
  height: 108rem;
  overflow: hidden;
}
// 封装的css 透明卡片
// 封装的css 透明卡片
.blockSupInsider{
  position: absolute;
  top: 4.2rem;
  right: -30rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 
}

.blockSupInsiderShow{
  position: absolute;
  top: 4.2rem;
  right: 0rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 
}

.blockSupInsider2{
  position: absolute;
  top: 13.8rem;
  right: -30rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 
}

.blockSupInsiderShow2{
  position: absolute;
  top: 13.8rem;
  right: 0rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 
}

.blockSupInsider3{
  position: absolute;
  top: 23.4rem;
  right: -30rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 opacity: 0
}

.blockSupInsiderShow3{
  position: absolute;
  top: 23.4rem;
  right: 0rem;
  width: 20rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 opacity: 1
}

// 右侧附加板块的卡片内部样式布局
.supRightImg{
  width: 8rem;
  height: 5.6rem;
  position: absolute;
  left: 1.7rem;
  top: 1.1rem;
}
.wordUpper{
  height: 2rem;
  color: #18FEFE;
  font-size: 1.4rem;
  line-height: 2rem;
  position: absolute;
  left: 10.1rem;
  top: 1.4rem;
  opacity: 0.85;
  text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
}
.wordDowner{
  position: absolute;
  top: 4rem;
  left: 10.1rem;
  height: 2.4rem;
  line-height: 2.4rem;
  color: #FBFBFB;
  font-size: 2rem;
  text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
}

.word4Red{
  position: absolute;
  left: 20.7rem;
  top: 4.6rem;
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #fe6c2f;
    }
  }


 .word4Green{
   position: absolute;
  left: 20.7rem;
  top: 4.6rem;
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #39e5d0;
    }
  }
</style>