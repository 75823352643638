<template>
  <div class="huBeiBottom">
    <!-- 最下面一排盒子########################################################################## -->
      <div class="bottom1">
        <!-- 盒子分割 -->
        <div class="box" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          
        </div>
        <!-- 盒子分割 -->
        <div class="box" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
         
        </div>

        <!-- 盒子分割 -->
        <div class="box" style="margin-right:25.6rem;height:11.6rem;background:none;position:relative;width:12.4rem">
         
        </div>
        
         <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/blueLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">日发电量(kWh)</div>
          <div class="word2">17</div>
          <div class="line" style="width:9.9rem"></div>
          <div class="word4Red">环比：<span class="word4Inner">+233 <i class="el-icon-top-right" style="font-size:1.2rem"/></span></div>
        </div>

         <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/blueLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">月发电量(元)</div>
          <div class="word2">17</div>
          <div class="line" style="width:9.9rem"></div>
          <div class="word4Red">环比：<span class="word4Inner">+233 <i class="el-icon-top-right" style="font-size:1.2rem"/></span></div>
        </div>

         <!-- 盒子分割 -->
        <div class="boxBlue" style="height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/blueLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">年发电量(元)</div>
          <div class="word2">17</div>
          <div class="line" style="width:9.9rem"></div>
          <div class="word4Red">环比：<span class="word4Inner">+233 <i class="el-icon-top-right" style="font-size:1.2rem"/></span></div>
        </div>

        
      </div>

      <!-- 第二排盒子####################################################################### -->
      <div class="bottom2">
          <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          
        </div>
        <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          
        </div>

        <!-- 盒子分割 -->
        <div class="boxBlue" style="margin-right:25.6rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          
        </div>

        <!-- 盒子分割 -->
        <div class="boxGreen" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/greenLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">日收益(元)</div>
          <div class="word2">12000</div>
          <div class="line" style="width:9.9rem"></div>
          <div class="word4Red">环比：<span class="word4Inner">+233 <i class="el-icon-top-right" style="font-size:1.2rem"/></span></div>
        </div>
        
        <!-- 盒子分割 -->
        <div class="boxGreen" style="margin-right:1.3rem;height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/greenLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">月收益(元)</div>
          <div class="word2">12000</div>
          <div class="line" style="width:9.9rem"></div>
          <div class="word4Red">环比：<span class="word4Inner">+233 <i class="el-icon-top-right" style="font-size:1.2rem"/></span></div>
        </div>


        <!-- 盒子分割 -->
        <div class="boxGreen" style="height:11.6rem;background:none;position:relative;width:12.4rem">
          <img src="@/assets/FullView/greenLarge.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:12.4rem">
          <div class="word1">年收益(元)</div>
          <div class="word2">12000</div>
          <div class="line" style="width:9.9rem"></div>
          <div class="word4Red">环比：<span class="word4Inner">+233 <i class="el-icon-top-right" style="font-size:1.2rem"/></span></div>
        </div>
      </div>


      <!-- 第三排##################################################### -->
      <div class="bottom3">
         <!-- 盒子分割 -->
        <div class="box" style="margin-right:1.2rem;background:none">
          
        </div>

         <!-- 盒子分割 -->
        <div class="box" style="margin-right:60.8rem;background:none">
          
        </div>

         <!-- 盒子分割 -->
        <div class="boxGreen" style="margin-right:1.2rem">
          <div class="word1">节约碳量(kg)</div>
          <div class="word2">1800</div>
          <div class="line"></div>
          <div class="word4Green">环比：<span class="word4Inner">-18 <i class="el-icon-bottom-right" style="font-size:1.2rem"/></span></div>
        </div>

         <!-- 盒子分割 -->
        <div class="boxGreen" >
          <div class="word1">节约煤量(kg)</div>
          <div class="word2">1800</div>
          <div class="line"></div>
          <div class="word4Green">环比：<span class="word4Inner">-18 <i class="el-icon-bottom-right" style="font-size:1.2rem"/></span></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'FaDianBottom',
}
</script>

<style lang='less' scoped>
.huBeiBottom{
  height: 42rem;
  width: 110rem;
  position: relative;
  bottom: 0;
  left: 0;
}

.bottom1{
    height: 11.6rem;
    width: 110rem;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
    
}


.bottom2{
    height: 11.6rem;
    width: 110rem;
    position: absolute;
    bottom: 12.8rem;
    left: 0;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
  
}

.bottom3{
    height: 10.9rem;
    width: 110rem;
    position: absolute;
    bottom: 26.8rem;
    left: 0;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
  
}


.box{
  height: 10.9rem;
  width: 10.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(255, 153, 0, 0.1);
  float: left;
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #FF9900;
    text-shadow: 0 0.1rem 0.3rem rgba(255, 153, 0, 0.65);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #FF9900;
    margin-bottom: 1rem;
  }
 
}
.boxBlue{
  height: 10.9rem;
  width: 10.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(24, 249, 250, 0.1);
  float: left;
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #18FEFE;
    text-shadow: 0 0.1rem 0.3rem rgba(0, 118, 203, 0.85);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #18FEFE;
    margin-bottom: 1rem;
  }
 
}
.boxGreen{
  height: 10.9rem;
  width: 10.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(54, 234, 175, 0.1);
  float: left;
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #36EAAF;
    text-shadow: 0 0.1rem 0.3rem rgba(54, 234, 175, 0.45);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #36EAAF;
    margin-bottom: 1rem;
  }
 
}




 .word4Red{
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #fe6c2f;
    }
  }

  .word4Green{
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #39e5d0;
    }
  }
</style>