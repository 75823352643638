<template>
    <div class="mapColumn">
        <!-- 办公楼柱状图 -->
        <div id="myChart11" class="c1"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";
export default {
    data () {
        return {
            barList1: [],
            dataForm: {
                customerId: "1448924021822775297",
            },
            xdata: [],
            flag: false,
            monthDay: [31, "", 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        };
    },
    props: ["barList"],
    computed: {
        ...mapState({
            queryTrendMouth: (state) => {
                return state.elecManagement.queryTrendMouth.data;
            },
        }),
    },
    mounted () {
        // console.log(this.barList);
        // 父组件里面的queryTrendMouth这个接口返回结果很慢，所以需要使用回调
        setTimeout(() => {
            this.flag = true;
            this.drawLine1();
        }, 1500);
    },
    methods: {
        dispose () {
            this.$echarts.dispose(this.$refs.myChart);
        },
        ceilNumber (number) {
            let bite = 0;
            if (number < 10) {
                return 10;
            }
            while (number >= 10) {
                number /= 10;
                bite += 1;
            }
            return Math.ceil(number) * Math.pow(10, bite);
        },
        getXdata (lens) {
            this.xdata = [];
            let date = new Date();
            let m = date.getMonth() + 1;
            let year = date.getFullYear();
            if (m < 10) {
                m = "0" + m;
            }
            let len = 30;
            if (m == 2) {
                len = this.isLeapYear(year) ? 29 : 28;
            } else {
                len = this.monthDay[m - 1];
            }
            for (let i = 1; i < len + 1; i++) {
                if (i < 10) {
                    i = "0" + i;
                }
                let item = m + "-" + i;
                this.xdata.push(item);
            }
        },
        isLeapYear (year) {
            return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
        },
        drawLine1 () {
            // console.log(this.barList);
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart11"));
            if (myChart == null) {
                myChart = this.$echarts.init(document.getElementById("myChart11"));
            }
            // 绘制图表
            let mapBarList = [];
            for (let i = 0; i < this.barList.length; i++) {
                let item = this.barList[i];
                mapBarList.push(item);
            }
            let yMax = 0;
            for (let i = 0; i < mapBarList.length; i++) {
                const item = mapBarList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            yMax = this.ceilNumber(yMax);
            let length = mapBarList.length;
            this.getXdata(length);
            myChart.setOption(
                {
                    title: {
                        itemGap: 10,
                        textStyle: {
                            color: "#37A5DD",
                            fontSize: 14,
                            lineHeight: 10,
                            fontWeight: 400,
                        },
                        subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
                    },
                    legend: {
                        bottom: 6,
                        textStyle: { color: "rgba(255, 255, 255, 0.85)" },
                        data: [this.$store.state.storage.buildingName],
                    },
                    tooltip: {},
                    grid: {
                        x: 18,
                        x2: 20,
                        y2: 28,
                        y: 70,
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        interval: 0,
                        axisLabel: {
                            textStyle: {
                                color: "#fff",
                            },
                        },
                        //x轴的数据格式
                        data: this.xdata,
                    },
                    yAxis: [
                        {
                            name: "kWh",
                            min: 0,
                            max: yMax,
                            interval: parseInt(yMax / 4),
                            axisLabel: {
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: "dashed",
                                    color: "rgba(230,247,255,0.2)",
                                },
                            },
                        },
                    ],
                    series: [
                        {
                            name: this.$store.state.storage.buildingName,
                            type: "bar",
                            barWidth: 10,
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: "#1EE7E7" },
                                        { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
                                    ]),
                                },
                            },
                            data: mapBarList,
                        },
                    ],
                },
                true
            );
            myChart._dom.childNodes[0].childNodes[0].style.width = "104rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "42rem";
        },
    },
    watch: {
        barList: {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) return;
                if (!this.flag) return;
                this.barList = newVal;
                this.drawLine1();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.mapColumn {
    .c1 {
        width: 104rem;
        height: 42rem;
        margin: 0 auto;
    }
}
</style>
