<template>
  <div class="PerformanceSOutSider">
    <div class="PerformanceS">
      <div class="topBlock">
        <div id="topBlock" style="width: 100%; height: 42rem;">
          <!--顶部  -->
          <div class="topBar">
            <div class="text">
              <div class="word1">绩效统计</div>
              <div class="word2">（{{ startTime }} ～ {{ endTime }}）</div>
            </div>
            <div class="btn">
              <!-- 下载的下拉框 -->
              <el-dropdown class="imgFuck" @command="downLoadPng">
                <span class="el-dropdown-link">
                  <img src="@/assets/repair/icon1.png" class="imgFuck" alt="" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="['pic', '绩效统计']">
                    图片下载
                  </el-dropdown-item>
                  <el-dropdown-item style="width:9rem" :command="['excel', '绩效统计']">
                    Excel下载
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <!-- 图和表 ########################################################################-->
          <div class="TopChartBar" ref="TopChartBarRef"></div>
        </div>

        <div class="TopChartBar2">
          <!-- 头部栏 -->
          <div class="topPlaceTable">
            <div class="inner" style="flex: 68;">序号</div>
            <div class="inner" style="flex: 150;">姓名</div>
            <div
              class="inner"
              style="
                flex: 646;
                position: relative;
                top: 0;
                left: 0;
                background-color: #0d3050;
                margin-right: 0;
              "
            >
              <div
                class="inner2"
                style="
                  height: 3rem;
                  width: 64.5rem;
                  margin-bottom: 0.1rem;
                  border-bottom: 0.1rem solid #2e6395;
                "
              >
                实际完成工单总数
              </div>
              <div class="inner2" style="width: 16rem;">换件工单</div>
              <div class="inner2" style="width: 16rem;">修件工单</div>
              <div class="inner2" style="width: 16rem;">调试工单</div>
              <div class="inner2" style="width: 16.2rem;">其他</div>
            </div>
            <div class="inner" style="flex: 398;">已完成巡检任务数</div>
            <div class="inner" style="flex: 398;">运维总数</div>
          </div>
          <!-- 中间表格 -->

          <div class="contentPlaceTable">
            <div
              v-for="(item, index) in topData"
              :key="'topData' + index"
              class="innerCard"
            >
              <div class="inner" style="flex: 68;">
                {{ index + 1 + (currentPageTop - 1) * 10 }}
              </div>
              <div class="inner" style="flex: 150;">{{ item.name }}</div>
              <div class="inner" style="flex: 160;">
                {{ item.replacementOfPartCount }}
              </div>
              <div class="inner" style="flex: 160;">{{ item.repairCount }}</div>
              <div class="inner" style="flex: 160;">{{ item.debugCount }}</div>
              <div class="inner" style="flex: 162;">{{ item.otherCount }}</div>
              <div class="inner" style="flex: 398;">
                {{ item.finishTaskCount }}
              </div>
              <div class="inner" style="flex: 398;">{{ item.allCount }}</div>
            </div>
          </div>
          <!-- 分页器 -->
          <div class="pagination">
            <el-pagination
              background
              @current-change="handleCurrentChangeTop"
              :current-page="currentPageTop"
              layout="total, prev, pager, next, jumper"
              :total="totalNumForPagenationTop"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import html2canvas from 'html2canvas'
import { exportPerformanceReport } from '@/api/repair'
export default {
  name: 'PerformanceS',
  props: {
    dateList: {
      type: Array,
      default: () => [],
    },
    dateType: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      //时间段
      startTime: new Date().getFullYear() + '-01-01',
      endTime: new Date().getFullYear() + '-12-31',
      // 日期选择器的双向绑定
      valueTimePicker: '',
      // 上方的分页器页数
      currentPageTop: 1,
      // 上方的数据总数
      totalNumForPagenationTop: 0,
      // ############################################################################上方直方图数据#############################################################################
      optiontopChartBar: {
        legend: {
          x: 'middle',
          y: 'bottom',
          left: this.fontSize(70),
          itemHeight: this.fontSize(1),
          itemWidth: this.fontSize(1),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: this.fontSize(1.2),
            itemGap: 0,
          },
        },
        grid: {
          top: 40,
          left: '2.8%',
          right: '1.8%',
          bottom: '16%',
        },
        xAxis: {
          type: 'category',
          // x轴刻度线
          axisTick: { show: false },
          // x轴文字
          axisLabel: {
            interval: 0,
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // x轴基线
          axisLine: {
            lineStyle: {
              color: '#bae7ff',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          name: '（单）',
          // y轴顶部文字
          nameTextStyle: {
            color: 'rgba(230, 247, 255, 0.5)',
            fontSize: this.fontSize(1.2),
            fontWeight: 100,
            padding: [0, 0, 0, -35],
          },
          // y轴文字
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // 分割线样式
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.2)',
            },
          },
        },
        series: [
          {
            name: '已完成巡检任务数',
            symbol: 'none',
            stack: 'Ad',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(68, 241, 182, 1)' },
                  { offset: 1, color: 'rgba(48, 219, 233, 1)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },

          {
            name: '已完成工单数',
            symbol: 'none',
            stack: 'Ad',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(49, 120, 239, 1) ' },
                  { offset: 1, color: 'rgba(48, 182, 239, 1)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
        ],
        tooltip: {
          extraCssText: 'width:20rem;height:10rem',
          backgroundColor: 'rgba(0,0,0,0.65)',
          borderColor: 'rgba(0,0,0,0.65)',
          color: '#E6F7FF',
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' +
              '工程师：' +
              params[0].name +
              '<br>' +
              '已完成巡检任务数： ' +
              '<span style="color:#32dde3;">' +
              params[0].data +
              '次' +
              '</span>' +
              '<br>' +
              '已完成工单数： ' +
              '<span style="color:#3187ef;">' +
              params[1].data +
              '个' +
              '</span>' +
              '</span>'
            )
          },
        },
      },

      // 表格的数据
      topData: [],
      topDataAll: [],

      // 时间选择器的数据
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },

  methods: {
    // 转化dom节点为图片下载
    downLoadPng(val) {
      if (val[0] == 'excel') {
        let params = {
          endTime: this.endTime,
          startTime: this.startTime,
          myPage: val[1],
        }
        // this.$store.dispatch('getExportFileRepair', params)
        exportPerformanceReport(params).then((response) => {
          var blob = new Blob([response], {
            type: 'application/vnd.ms-excel,charset=utf-8',
          }) //创建一个blob对象
          var a = document.createElement('a') //创建一个<a></a>标签
          a.href = URL.createObjectURL(blob) // response is a blob
          if (this.startTime) {
            a.download = `${this.startTime}-${this.endTime}${val[1]}.xls` //文件名称
          } else {
            a.download = `${val[1]}.xls` //文件名称
          }
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
      } else {
        var node = document.getElementById('topBlock')
        window.pageYoffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        var width = node.offsetWidth
        var height = node.offsetHeight
        var scale = 4
        html2canvas(node, {
          width: width,
          heigth: height,
          backgroundColor: '#0d3050',
          dpi: window.devicePixelRatio * 4,
          scale: scale,
          X: 0,
          Y: 0,
          scrollX: -3,
          scrollY: 0,
          useCORS: true,
          allowTaint: true,
        }).then((canvas) => {
          var url = canvas.toDataURL()
          var a = document.createElement('a')
          a.download = val[1] + this.startTime + '至' + this.endTime
          var event = new MouseEvent('click')
          a.href = url
          a.dispatchEvent(event)
        })
      }
    },

    // 获取屏幕比例，使echarts自适应
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
      if (!clientWidth) return
      let fontSize = clientWidth / 192
      return res * fontSize
    },

    // 渲染上方的直方图
    inItTopChartBar() {
      this.optiontopChartBar.xAxis.data = this.myData.name
      this.optiontopChartBar.series[0].data = this.myData.finishTaskCount
      this.optiontopChartBar.series[1].data = this.myData.finishCount

      var myChartTopChartBar = this.$echarts.init(this.$refs.TopChartBarRef)
      myChartTopChartBar.clear()
      myChartTopChartBar.setOption(this.optiontopChartBar)
      myChartTopChartBar._dom.childNodes[0].childNodes[0].style.height = '34rem'
      myChartTopChartBar._dom.childNodes[0].childNodes[0].style.width = '100%'
    },

    // 渲染上方表格
    inItTopChartTable() {
      this.topDataAll = []
      for (let i = 0; i < this.myData.name.length; i++) {
        var per = {
          name: this.myData.name[i],
          replacementOfPartCount: this.myData.replacementOfPartCount[i],
          repairCount: this.myData.repairCount[i],
          debugCount: this.myData.debugCount[i],
          otherCount: this.myData.otherCount[i],
          finishTaskCount: this.myData.finishTaskCount[i],
          allCount: this.myData.finishCount[i] + this.myData.finishTaskCount[i],
        }
        this.topDataAll.push(per)
      }
      this.topData = this.topDataAll.slice(0, 10)
      this.totalNumForPagenationTop = this.topDataAll.length
    },
    // 上方的分页器变动
    handleCurrentChangeTop(val) {
      this.currentPageTop = val
      this.topData = this.topDataAll.slice((val - 1) * 10, 10 + (val - 1) * 10)
    },

    // 更新页面数据
    refreshThePage() {
      this.$store
        .dispatch('getStatisticsPerformanceS', {
          startTime: this.startTime,
          endTime: this.endTime,
        })
        .then(() => {
          this.myData = this.performanceSData
          this.inItTopChartBar()
          this.inItTopChartTable()
        })
    },
  },
  watch: {
    dateList: {
      handler(val) {
        this.startTime = val[0] || this.startTime
        this.endTime = val[1] || this.endTime
        this.refreshThePage()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      performanceSData: (state) => {
        return state.repair.performanceSData.data
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.PerformanceSOutSider {
  height: 100%;
  width: 171.2rem;
  overflow: hidden;
}

.PerformanceS {
  height: 100%;
  width: 173rem;
  background-color: #18191a;
  overflow: scroll;
}
// ##############################################################上部分板块#############################
.topBlock {
  height: 100%;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  overflow: auto;
}
// ##############################################################下部分板块#############################
.bottomBlock {
  height: calc((100% - 1rem) / 2);
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
}

// ##############################################################公用样式#############################
.topBar {
  width: 100%;
  padding: 2.4rem 2.4rem 0 2.4rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    .word1 {
      font-size: 1.6rem;
      color: #37a5dd;
      line-height: 2.2rem;
      float: left;
    }
    .word2 {
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.45);
      line-height: 2.4rem;
      float: left;
    }
  }

  .btn {
    display: flex;
    justify-content: space-between;
    width: 3rem;
    img {
      height: 2.4rem;
      width: 2.4rem;
      cursor: pointer;
    }
  }
}

.TopChartBar {
  height: 34rem;
  width: 100%;
}
.TopChartBar2 {
  height: 56.5rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
}

// 空板块
.blank {
  position: absolute;
  top: 111rem;
  height: 25rem;
  width: 172rem;
  color: grey;
  font-size: 1.4rem;
  text-align: center;
  line-height: 10rem;
}

// 定制化表格的样式
.topPlaceTable {
  height: 6.5rem;
  width: 100%;
  display: flex;
  .inner {
    height: 6.5rem;
    background-color: #133f68;
    color: #fff;
    font-size: 1.4rem;
    line-height: 6.5rem;
    text-align: center;
    margin-right: 0.1rem;
    border-bottom: 0.1rem solid #2e6395;
    box-sizing: border-box;
  }
  .inner2 {
    background-color: #133f68;
    height: 3.2rem;
    line-height: 3.2rem;
    margin-right: 0.1rem;
    float: left;
    // background-color: aqua;
  }
}
.contentPlaceTable {
  height: 40rem;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;
  // background-color: aqua;
  .innerCard {
    height: 4rem;
    width: 100%;
    // border-bottom: red 0.1rem solid;
    box-sizing: border-box;
    display: flex;
  }
  .inner {
    height: 3.9rem;
    background-color: #0d3050;
    color: #fff;
    font-size: 1.4rem;
    line-height: 3.9rem;
    text-align: center;
    margin-right: 0.1rem;
    border-bottom: 0.1rem solid #244561;
    box-sizing: border-box;
  }
  .inner2 {
    position: absolute;
    top: 0rem;
    left: 2.8rem;
  }
  .inner3 {
    height: 0.8rem;
    width: 25rem;
    background-color: #244561;
    position: absolute;
    top: 1.6rem;
    left: 7rem;
  }
  .inner4 {
    height: 0.8rem;
    background: linear-gradient(270deg, #1890ff 0%, #1ee7e7 100%);
    position: absolute;
    top: 1.6rem;
    left: 7rem;
  }
}
.pagination {
  float: right;
  margin-top: 2.4rem;
  margin-right: 2.4rem;
}

// 定制化横向表格
.transverseTable {
  height: 28rem;
  width: 166.4rem;
  position: relative;
  top: 0;
  left: 0;
  margin-left: 2.4rem;
  display: flex;
  .titlePlace {
    height: 28rem;
    width: 17.6rem;
    background-color: #133f68;
    .innerTitle {
      height: 4rem;
      width: 17.6rem;
      border-bottom: 0.1rem solid #2e6395;
      box-sizing: border-box;
      color: #fff;
      font-size: 1.4rem;
      line-height: 4rem;
      text-align: left;
      text-indent: 1.6rem;
    }
  }
  .contentCard1 {
    background-color: #0c2d4b;
    height: 28rem;
    width: 14.8rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
  }
  .contentCard2 {
    background-color: #0d3050;
    height: 28rem;
    width: 14.8rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
  }
  .contentInner {
    height: 4rem;
    width: 14.8rem;
    border-bottom: 0.1rem solid #244561;
    box-sizing: border-box;
    color: #fff;
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
    position: relative;
    top: 0;
    left: 0;
  }

  .chart1 {
    height: 0.8rem;
    width: 8rem;
    background-color: #244561;
    position: absolute;
    top: 1.6rem;
    left: 5.3rem;
  }
  .chart2 {
    height: 0.8rem;
    background: linear-gradient(270deg, #1890ff 0%, #1ee7e7 100%);
    position: absolute;
    top: 1.6rem;
    left: 5.3rem;
  }
}

.timer {
  height: 3rem;
  width: 27rem;
  position: absolute;
  top: -6rem;
  right: 2.4rem;
  .myDatePicker {
    height: 3rem;
    width: 27rem;
  }
}
</style>
