<template>
    <div>
        <div v-if="loading" class="loading-mask">
          <el-spinner size="large"></el-spinner>
        </div>
        <div class="echartSingel">
            <!-- 分时统计线图的数据watch里面 -->
            <div class="dialog_echarts">
                <div ref="myChart" class="charts" id="myChart18"></div>
                <el-select v-model="typeValue" size="small" class="select-type" @change="handleChangeType">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="select-time">
                    <span class="time">时间：</span>
                    <el-date-picker class="seleWid" v-model="dateSpan" @change="selectDate" type="month" size="mini" placeholder="选择月"></el-date-picker>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "MymapNo2",
    // props:['dateSpan'],
    data () {
        return {
            // 按钮的样式
            btnTypeDay: "btnGreen",
            btnTypeMonth: "btnDark",
            btnTypeYear: "btnDark",
            dateSpan: "2021-12",
            dataForm: {
                localtionId: "",
                startTime: "",
            },
            showInterval: true,
            xdata: [],
            yMax: 0,
            typeValue: 0,
            options: [
                {
                    label: '工作时段(工作时间-休息时间)',
                    value: 0,
                },
                {
                    label: '尖峰平谷时段',
                    value: 1,
                }
            ],
            seriesData: [
                [
                    {
                        name: "上班时间",
                        type: "line",
                        smooth: true,
                        showSymbol: false,
                        itemStyle: {
                            color: "rgba(54, 115, 232, 1)",
                        },
                        data: [],
                    },
                    {
                        name: "休息时间",
                        type: "line",
                        smooth: true,
                        showSymbol: false,
                        itemStyle: {
                            color: "rgba(97, 184, 255, 1)",
                        },
                        data: [],
                    },
                ],
                [
                    {
                        name: "尖",
                        type: "line",
                        itemStyle: {
                            color: "rgba(54,115,232, 1)",
                        },
                        data: [],
                    },
                    {
                        name: "峰",
                        type: "line",
                        itemStyle: {
                            color: "rgba(97,184,255, 1)",
                        },
                        data: [],
                    },
                    {
                        name: "平",
                        type: "line",
                        itemStyle: {
                            color: "rgba(245,183,99, 1)",
                        },
                        data: [],
                    },
                    {
                        name: "谷",
                        type: "line",
                        itemStyle: {
                            color: "rgba(29,225,226, 1)",
                        },
                        data: [],
                    },
                ],
            ],
            loading: false,
        };
    },
    mounted () { },
    created () {
        this.getThisMonth();
    },
    methods: {
        init () {
            this.getSelectTime();
        },
        resizeChart () {
            setTimeout(() => {
                const myChart = this.$echarts.init(document.getElementById("myChart18"));
                const chartWidth = document.getElementById("myChart18").offsetWidth
                const chartHeight = document.getElementById("myChart18").offsetHeight
                myChart.resize({ width: chartWidth, height: chartHeight })
            }, 500)
        },
        selectDate (dateData) {
            let date = new Date(dateData);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = y + "-" + m;
            this.dateSpan = time;
            this.$store.commit("TimeAnalysisDate", this.dateSpan);
            this.getSelectTime();
            this.drawLine1();
        },
        // 销毁echarts
        dispose () {
            this.$echarts.dispose(this.$refs.myChart);
        },
        getThisMonth () {
            let date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = y + "-" + m;
            // this.dateSpan = time;
            this.dateSpan = this.$store.state.elecManagement.timeAnalysisDate;
        },
        getSelectTime () {
            this.loading = true
            this.$store
                .dispatch("getBarWorkorRest", {
                    id: this.dataForm.localtionId,
                    month: this.dateSpan,
                })
                .then(() => {
                    if (this.$store.state.elecManagement.getBarWorkorRest.errorCode == 0) {
                        if (
                            (this.$store.state.elecManagement.getBarWorkorRest.data !== null) &
                            (this.$store.state.elecManagement.getBarWorkorRest.data.length > 0)
                        ) {
                            this.barList1 = this.$store.state.elecManagement.getBarWorkorRest.data[0].data;
                            this.barList2 = this.$store.state.elecManagement.getBarWorkorRest.data[1].data;
                            this.barList3 = this.$store.state.elecManagement.getBarWorkorRest.data[2].data;
                            this.barList4 = this.$store.state.elecManagement.getBarWorkorRest.data[3].data;
                            this.barList5 = this.$store.state.elecManagement.getBarWorkorRest.data[4].data;
                            this.barList6 = this.$store.state.elecManagement.getBarWorkorRest.data[5].data;
                            this.yMax = 1;
                            this.drawLine1();
                        } else {
                            this.barList1 = [];
                            this.barList2 = [];
                            this.barList3 = [];
                            this.barList4 = [];
                            this.barList5 = [];
                            this.barList6 = [];
                            this.xdata = [
                                "00:00",
                                "01:00",
                                "02:00",
                                "03:00",
                                "04:00",
                                "05:00",
                                "06:00",
                                "07:00",
                                "08:00",
                                "09:00",
                                "10:00",
                                "11:00",
                                "12:00",
                                "13:00",
                                "14:00",
                                "15:00",
                                "16:00",
                                "17:00",
                                "18:00",
                                "19:00",
                                "20:00",
                                "21:00",
                                "22:00",
                                "23:00",
                            ];
                            this.yMax = 0;
                            this.drawLine1();
                        }
                    } else {
                        this.barList1 = [];
                        this.barList2 = [];
                        this.barList3 = [];
                        this.barList4 = [];
                        this.barList5 = [];
                        this.barList6 = [];
                        this.xdata = [
                            "00:00",
                            "01:00",
                            "02:00",
                            "03:00",
                            "04:00",
                            "05:00",
                            "06:00",
                            "07:00",
                            "08:00",
                            "09:00",
                            "10:00",
                            "11:00",
                            "12:00",
                            "13:00",
                            "14:00",
                            "15:00",
                            "16:00",
                            "17:00",
                            "18:00",
                            "19:00",
                            "20:00",
                            "21:00",
                            "22:00",
                            "23:00",
                        ];
                        this.yMax = 0;
                        this.drawLine1();
                    }
                }).finally(() => {
                  this.loading = false
                });
        },
        ceilNumber (number) {
            let bite = 0;
            if (number < 10) {
                return 10;
            }
            while (number >= 10) {
                number /= 10;
                bite += 1;
            }
            return Math.ceil(number) * Math.pow(10, bite);
        },
        getXdata (len) {
            this.xdata = [];
            let m = this.dateSpan.split("-")[1];
            for (let i = 1; i < len + 1; i++) {
                if (i < 10) {
                    i = "0" + i;
                }
                let item = m + "-" + i;
                this.xdata.push(item);
            }
        },
        handleChangeType (val) {
            this.drawLine1();
        },
        drawLine1 () {
            let myChart = this.$echarts.init(document.getElementById("myChart18"));
            if (myChart == null) {
                myChart = this.$echarts.init(document.getElementById("myChart18"));
            }
            let workList = [];
            let restList = [];
            let tipList = [];
            let peakList = [];
            let flatList = [];
            let valleyList = [];
            let value = "";
            let month = this.dateSpan.slice(5) + "-";
            value = month;
            workList = this.barList1;
            restList = this.barList2;
            tipList = this.barList3;
            peakList = this.barList4;
            flatList = this.barList5;
            valleyList = this.barList6;
            let yMax = 0;
            for (let i = 0; i < workList.length; i++) {
                const item = workList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            for (let i = 0; i < restList.length; i++) {
                const item = restList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            this.yMax = this.ceilNumber(yMax);
            let length = workList.length;
            if (length > 0) {
                this.getXdata(length);
            }
            this.seriesData[0][0].data = workList;
            this.seriesData[0][1].data = restList;
            this.seriesData[1][0].data = tipList;
            this.seriesData[1][1].data = peakList;
            this.seriesData[1][2].data = flatList;
            this.seriesData[1][3].data = valleyList;
            let option = {
                // color: ["#1EE7E7", "#1890FF"],
                color: ["#1EE7E7", "#1890FF", '#3673E8', '#61B8FF', '#F5B763', '#1de1e2'],
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    textStyle: {
                        color: "#fff",
                    },
                    orient: "horizontal",
                    x: "center", //可设定图例在左、右、居中
                    y: "bottom", //可设定图例在上、下、居中
                    padding: [0, 0, 25, 0],
                    data: ["上班时间", "休息时间", "尖", "峰", "平", "谷"],
                },
                grid: {
                    x: 25,
                    x2: 20,
                    y: 85,
                    y2: 50,
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "rgba(43, 113, 176, 0.45)",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                    },
                    data: this.xdata,
                },
                yAxis: [
                    {
                        name: "单位:kWh",
                        type: "value",
                        axisLabel: {
                            color: "#fff",
                        },
                        min: 0,
                        max: this.yMax == 0 ? 10 : this.yMax,
                        interval: this.yMax / 5,
                        smooth: true,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: "rgba(230,247,255,0.2)",
                            },
                        },
                    },
                ],
                series: this.seriesData[this.typeValue],
            };
            // 绘制图表
            myChart.setOption(option, true);
            myChart._dom.childNodes[0].childNodes[0].style.width = "118.5rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "43.6rem";
        },
    },
    computed: {
        ...mapState({
            getBarWorkorRest: (state) => {
                return state.elecManagement.getBarWorkorRest.data;
            },
        }),
    },
    watch: {
        "$store.state.storage.localtionId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.dataForm.localtionId = newVal;
                this.getThisMonth();
                this.getSelectTime();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.echartSingel {
    //   height: 42rem;
    width: 58rem;
    //   background-color: #0d3050;
    border-radius: 0.4rem;
    // position: relative;
    // 上半部分的title栏##################################################
    .tabBarPlace1 {
        height: 10rem;
        width: 58rem;
        position: absolute;
        top: 0;
        left: 0;
        .tabBarTop {
            height: 5rem;
            width: 58rem;
            position: absolute;
            top: 0;
            left: 0;
            .word {
                height: 2.2rem;
                font-size: 1.4rem;
                color: #fff;
                line-height: 2.2rem;
                position: absolute;
                top: 2.9rem;
                left: 2.4rem;
            }
            .btn1 {
                height: 3rem;
                width: 11.6rem;
                position: absolute;
                top: 2.4rem;
                left: 16.8rem;
                background-color: #0b375e;
                border: 0.1rem solid #004579;
                font-size: 1.4rem;
                color: #fff;
                line-height: 3rem;
                padding: 0;
                margin: 0;
                cursor: pointer;
            }
            .dateChoose {
                height: 3rem;
                width: 13.8rem;
                border-radius: 0.2rem;
                position: absolute;
                top: 2.4rem;
                left: 30rem;
                overflow: hidden;
                border: 0.1rem solid #004579;
                box-sizing: border-box;
                display: flex;
                flex-wrap: nowrap;
                color: #fff;
                .btnDark {
                    height: 3rem;
                    width: 4.6rem;
                    background-color: #0b375e;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3rem;
                    text-align: center;
                }
                .btnGreen {
                    height: 3rem;
                    width: 4.6rem;
                    background-color: #027969;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    line-height: 3rem;
                    text-align: center;
                }
            }

            .img1 {
                height: 2.4rem;
                width: 2.4rem;
                position: absolute;
                top: 2.8rem;
                left: 53.2rem;
                cursor: pointer;
            }
            .img2 {
                height: 2.4rem;
                width: 2.4rem;
                position: absolute;
                top: 2.8rem;
                left: 45.2rem;
                cursor: pointer;
            }
            .img3 {
                height: 2.4rem;
                width: 2.4rem;
                position: absolute;
                top: 2.8rem;
                left: 49.2rem;
                cursor: pointer;
            }
        }
    }
    .dialog_echarts {
        .charts {
            width: 100%;
            // width: 118.5rem;
            height: 43.6rem;
            position: absolute;
        }
        .select-time {
            position: absolute;
            top: 1rem;
            right: 14.5rem;
            .time {
                font-size: 1.4rem;
                color: #fff;
            }
            .seleWid {
                width: 15rem;
            }
        }
        .select-type {
            position: absolute;
            top: 1rem;
            right: 35rem;
        }
    }
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
</style>
