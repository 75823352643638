import { Lang } from "@antv/x6";
import { ActionType, CustomEventTypeEnum, StoreKey } from "./enums";
import ErrorClass from "./errorClass";
import { fromJSON, toJSON } from "./transform";
import { Channel } from "./transmit";
import { freezeGraph, unfreezeGraph } from "./trigger";

/**json格式化 */
export function fmtJSON (target) {
    try {
        if (Lang.isString(target)) return JSON.parse(target);
        if (Lang.isArray(target))
            return target.map((item) => {
                if (Lang.isObject(item)) return item;
                return JSON.parse(item);
            });
        else if (Lang.isObject(target)) return target;
        else {
            Channel.dispatchEvent(
                CustomEventTypeEnum.RUNTIME_ERR,
                ErrorClass.InvalidFormatData("nodes or edges error")
            );
            throw ErrorClass.InvalidFormatData("nodes or edges error");
        }
    } catch (error) {
        return {};
    }
}

/**文字溢出格式化 */
export function fmtLabelOverflow (label) {
    if (!Lang.isString(label)) return label;
    if (label.length <= 9) return label;
    const cutLabel = label.slice(0, 6) + "...";
    return cutLabel;
}

function getStore (key) {
    return window[key];
}

/**缓存 */
function linkedPointByStore (key, target) {
    window[key] = target;
}

/**画布节点链接 */
export function linkedGraph (graph) {
    linkedPointByStore(StoreKey.GRAPH, graph);
}

/**获取画布数据 */
export function getGraphJSON () {
    const graph = getStore(StoreKey.GRAPH);
    return toJSON(graph);
}

/**初始化画布默认数据 */
export function setDefaultGraphData (nodes, edges) {
    const graph = getStore(StoreKey.GRAPH);
    fromJSON(graph, nodes, edges);
}

/**冻结画布 */
export function disableGraph (bool) {
    const graph = getStore(StoreKey.GRAPH);
    if (bool) freezeGraph(graph);
    else unfreezeGraph(graph);
}

/**清理画布 */
export function graphClean () {
    const graph = getStore(StoreKey.GRAPH);
    const cells = graph.getCells();
    if (cells.length) {
        // 删除前移除所有包含工具
        cells.forEach((currentCell) => {
            currentCell.removeTools();
        });
        graph.removeCells(cells);
    }
}

/**监听单元事件双击回调 */
export function nodeDclick (cb) {
    Channel.eventListener(CustomEventTypeEnum.DOUBLE_NODE_CLICK, (detail) =>
        cb(detail)
    );
}

/**监听单元事件单击回调 */
export function nodeClick (cb) {
    Channel.eventListener(CustomEventTypeEnum.NODE_CLICK, (detail) => cb(detail));
}

/**运行时异常回调 */
export function runtimeError (cb) {
    Channel.eventListener(CustomEventTypeEnum.RUNTIME_ERR, (err) => cb(err));
}

/**修改Node节点 */
export function updateNode (data) {
    const graph = getStore(StoreKey.GRAPH);
    const cells = graph.getSelectedCells();
    if (Lang.isArray(cells) && cells.length === 1) {
        const cell = cells[0];
        const { label, ...otherParams } = data;
        // 设置label
        if (label) {
            const cutLabel = fmtLabelOverflow(label);
            cell.setData({
                tooltip: label,
                initialization: false,
            });
            cell.setAttrs({ label: { text: cutLabel } });
        }
        for (const key in otherParams) {
            if (Object.hasOwnProperty.call(otherParams, key)) {
                const value = otherParams[key];
                if (!Lang.isNil(value)) {
                    cell.setData({
                        [key]: value,
                        initialization: false,
                    });
                }
            }
        }
        // 清除选框
        graph.cleanSelection();
    }
}

/**
 * 图形校验
 * 判断是否有未连接的节点
 */
export function validate () {
    const graph = getStore(StoreKey.GRAPH);

    const errs = [];

    // 获取所有单元
    const cells = graph.getCells();
    if (!cells.length) errs.push("画布无可用节点");

    // 获取所有边
    const edges = graph.getEdges();
    const nodeSet = new Set(
        edges.reduce((a, v) => {
            a.push(v.target.cell);
            a.push(v.source.cell);
            return a;
        }, [])
    );

    // 获取所有节点
    const nodes = graph.getNodes();

    // 如果通过边获取到的 所有节点数量与 node节点不匹配,则证明存在未连接的节点
    if (nodeSet.size !== nodes.length) errs.push("存在未连线的节点");

    // 校验 是否包含 触发器 和 动作
    const { TRIGGER, ACTION } = ActionType;
    let startNodes = 0,
        endNodes = 0;
    for (const node of nodes) {
        const { initialization, tooltip, actionType } = node.getData();
        // 数据未修改过
        if (initialization) errs.push(`[${tooltip || ""}]节点数据不能为空`);
        switch (actionType) {
            case TRIGGER:
                startNodes += 1;
                break;
            case ACTION:
                endNodes += 1;
                break;
        }
    }

    // 通过 actionType 存在数量判断
    // 往往一个闭环操作至少包含一个触发器相关的节点和 执行动作相关的节点
    if (!startNodes || !endNodes) errs.push("流程链路未闭环");

    // errs: 所有捕获异常以Array形式顺序排列, 向外暴露
    return { ok: !errs.length, errs };
}

/**获取node基础数据 */
function getBaseNodes () {
    const graph = getStore(StoreKey.GRAPH);
    // 获取所有节点
    const nodes = graph.getNodes();
    return nodes.map((node) => {
        const { id, data } = node;
        return {
            id,
            data,
        };
    });
}

/**获取edge基础数据 */
function getBaseEdges () {
    const graph = getStore(StoreKey.GRAPH);
    // 获取所有边
    const edges = graph.getEdges();
    return edges.map((edge) => {
        return {
            id: edge.id,
            source: edge.source.cell,
            target: edge.target.cell,
        };
    });
}

/**
 * 获取所有已存在的node节点和edge边
 */
export function getAtoms (options) {
    let atoms;
    // 如果是空，则获取所有
    switch (options) {
        case "nodes":
            atoms = { nodes: getBaseNodes() };
            break;
        case "edges":
            atoms = { edges: getBaseEdges() };
            break;
        default:
            atoms = { nodes: getBaseNodes(), edges: getBaseEdges() };
            break;
    }
    return atoms;
}

/**
 * 修改元件颜色
 * ASK：主要是修改颜色，但不只是修改颜色。
 */
let _this;
export function setSvgAttrs (vue, node, attrs) {
    console.log('setSvgAttrs++++++++++++', node, attrs);
    _this = vue;
    if (node.store.data.data.actionType === "SVG_HTML") {
        node.attrs.props.strokeWidth = attrs.strokeWidth || node.attrs.props.strokeWidth;
        node.attrs.props.stroke = attrs.color || node.attrs.props.stroke;
        node.store.data.componentName = attrs.title || node.store.data.componentName;
        attrs.rotate && node.rotate(attrs.rotate, { absolute: true });
        attrs.x && attrs.y && node.position(parseInt(attrs.x), parseInt(attrs.y));
        node.size((attrs.width || node.store.data.size.width), (attrs.height || node.store.data.size.height));
        _this.$nextTick(() => {
            let svgObjectDom = document.querySelectorAll(`g[data-cell-id="${node.id}"] svg g`);
            //一般选中是两个dom，一个是图上的，一个是mimiMap的。
            svgObjectDom[0].setAttribute("stroke-width", node.attrs.props.strokeWidth);
            svgObjectDom[0].setAttribute("x2", node.store.data.size.width);
            svgObjectDom[0].setAttribute("y2", 19 + parseInt(node.attrs.props.strokeWidth));
            svgObjectDom[0].setAttribute("y1", 19 + parseInt(node.attrs.props.strokeWidth));
            //console.log('设置颜色svgObjectDom[0].getAttribute(“stroke”)', svgObjectDom[0].getAttribute('stroke'))
            if (node.attrs.props.stroke) {
                svgObjectDom[0].setAttribute("stroke", node.attrs.props.stroke);
                svgObjectDom[0].setAttribute("fill", node.attrs.props.stroke);
            }
        }, 1);
    }
    else if (node.store.data.data.actionType == "SVG_IMAGE") {
      node.attrs.props.strokeWidth = attrs.strokeWidth || node.attrs.props.strokeWidth;
      node.store.data.componentName = attrs.title || node.store.data.componentName;
      node.attrs.props.stroke = attrs.color || node.attrs.props.stroke;
      attrs.rotate && node.rotate(attrs.rotate, { absolute: true });
      attrs.x && attrs.y && node.position(parseInt(attrs.x), parseInt(attrs.y));
      node.size((attrs.width || node.store.data.size.width), (attrs.height || node.store.data.size.height));
      _this.$nextTick(() => {
        let svgObjectDom = document.querySelectorAll(`g[data-cell-id="${node.id}"] image`);
        svgObjectDom[0].setAttribute("stroke-width", node.attrs.props.strokeWidth);
        svgObjectDom[0].setAttribute("x2", node.store.data.size.width);
        svgObjectDom[0].setAttribute("y2", 19 + parseInt(node.attrs.props.strokeWidth));
        svgObjectDom[0].setAttribute("y1", 19 + parseInt(node.attrs.props.strokeWidth));
        console.log('设置颜色svgObjectDom[0].getAttribute("stroke")', svgObjectDom[0].getAttribute('stroke'))
        if (svgObjectDom[0].getAttribute("stroke") && svgObjectDom[0].getAttribute("stroke") !== "none") {
          svgObjectDom[0].setAttribute("stroke", node.attrs.props.stroke);
        }
        if (svgObjectDom[0].getAttribute("fill") && svgObjectDom[0].getAttribute("fill") !== "none") {
          svgObjectDom[0].setAttribute("fill", node.attrs.props.stroke);
        }

      }, 1);
    }
    else if (node.store.data.data.actionType == "SVG_FONT") {
        attrs.x && attrs.y && node.position(parseInt(attrs.x), parseInt(attrs.y));
        attrs.rotate && node.rotate(attrs.rotate, { absolute: true });
        node.size((attrs.width || node.store.data.size.width), (attrs.height || node.store.data.size.height));
        node.store.data.componentName = attrs.title || node.store.data.componentName;
        node.store.data.attrs.body.fontSize = attrs.fontSize || node.store.data.attrs.body.fontSize;
        node.store.data.attrs.body.fontWeight = attrs.fontWeight || node.store.data.attrs.body.fontWeight;
        node.store.data.attrs.body.color = attrs.color || node.store.data.attrs.body.color;
        node.store.data.attrs.body.content = attrs.content || node.store.data.attrs.body.content;
        node.store.data.attrs.body.fontFamily = attrs.fontFamily || node.store.data.attrs.body.fontFamily;
        _this.$nextTick(() => {
            let svgObjectDom = document.querySelectorAll(
                `g[data-cell-id="${node.id}"] foreignObject .wzbq`
            );
            svgObjectDom[0].style.color = node.store.data.attrs.body.color;
            svgObjectDom[0].children[0].style.color = node.store.data.attrs.body.color;
            svgObjectDom[0].children[0].style.width = node.store.data.size.width + 'px';
            svgObjectDom[0].children[0].style.height = node.store.data.size.height + 'px';
            svgObjectDom[0].children[0].style.fontSize = node.store.data.attrs.body.fontSize + "px";
            svgObjectDom[0].children[0].style.fontWeight = node.store.data.attrs.body.fontWeight;
            svgObjectDom[0].children[0].style.fontFamily = node.store.data.attrs.body.fontFamily;
            svgObjectDom[0].children[0].value = node.store.data.attrs.body.content;
        });
    }
    else if (node.store.data.data.actionType == "SVG_TAG") {
        node.attrs.prop["stroke-width"] = attrs.strokeWidth || node.attrs.prop["stroke-width"];
        node.attrs.prop["stroke-dasharray"] = attrs.strokeDasharray || node.attrs.prop["stroke-dasharray"];
        node.attrs.prop["stroke-linecap"] = attrs.strokeLinecap || node.attrs.prop["stroke-linecap"];
        node.store.data.componentName = attrs.title || node.store.data.componentName;
        node.attrs.prop.stroke = attrs.color || node.attrs.prop.stroke;
        node.attrs.prop.fill = attrs.color || node.attrs.prop.stroke;
        attrs.rotate && node.rotate(attrs.rotate, { absolute: true });
        attrs.x && attrs.y && node.position(parseInt(attrs.x), parseInt(attrs.y));
        attrs.height && node.size(attrs.height, 38 + node.attrs.prop["stroke-width"] * 2);
        if (node.store.data.template.indexOf("1_dllxy") != -1) {
            _this.$nextTick(() => {
                node.setMarkup([
                    {
                        tagName: "line",
                        selector: "line1",
                        groupSelector: "prop",
                        attrs: {
                            x1: "0",
                            y1: 19 + parseInt(node.attrs.prop["stroke-width"]),
                            x2: node.store.data.size.width,
                            y2: 19 + parseInt(node.attrs.prop["stroke-width"]),
                            stroke: node.attrs.prop.stroke,
                        },
                    },
                    {
                        tagName: "polygon",
                        selector: "polygon1",
                        groupSelector: "prop",
                        attrs: {
                            points:
                                node.store.data.size.width -
                                4 -
                                parseInt(node.attrs.prop["stroke-width"]) +
                                "," +
                                17 +
                                " " +
                                node.store.data.size.width +
                                "," +
                                (19 + parseInt(node.attrs.prop["stroke-width"])) +
                                " " +
                                (node.store.data.size.width -
                                    4 -
                                    parseInt(node.attrs.prop["stroke-width"])) +
                                "," +
                                (21 + 2 * parseInt(node.attrs.prop["stroke-width"])),
                            fill: node.attrs.prop.stroke,
                        },
                    },
                ]);
                node.prop({
                    attrs: {
                        line: {
                            stroke: node.attrs.prop.stroke,
                            strokeWidth: node.attrs.prop["stroke-width"]
                        }
                    }
                })
            });
        } else {
            _this.$nextTick(() => {
                let svgObjectDom = document.querySelectorAll(
                    `g[data-cell-id="${node.id}"] line`
                );
                svgObjectDom[0].setAttribute("stroke-width", node.attrs.prop["stroke-width"]);
                svgObjectDom[0].setAttribute("stroke-dasharray", node.attrs.prop["stroke-dasharray"]);
                svgObjectDom[0].setAttribute("stroke-linecap", node.attrs.prop["stroke-linecap"]);
                svgObjectDom[0].setAttribute("x2", node.store.data.size.width);
                svgObjectDom[0].setAttribute("y2", 19 + parseInt(node.attrs.prop["stroke-width"]));
                svgObjectDom[0].setAttribute("y1", 19 + parseInt(node.attrs.prop["stroke-width"]));
                if (
                    svgObjectDom[0].getAttribute("stroke") != undefined &&
                    svgObjectDom[0].getAttribute("stroke") != "none"
                ) {
                    svgObjectDom[0].setAttribute("stroke", node.attrs.prop.stroke);
                }
                if (
                    svgObjectDom[0].getAttribute("fill") != undefined &&
                    svgObjectDom[0].getAttribute("fill") != "none"
                ) {
                    svgObjectDom[0].setAttribute("fill", node.attrs.prop.stroke);
                }
            })
        }
    }
    else if (node.store.data.data.actionType == "SVG_TABLE") {
        node.position(parseInt(attrs.x), parseInt(attrs.y));
        node.rotate(attrs.rotate, { absolute: true });
        node.size(attrs.width, attrs.height);
        node.store.data.componentName = attrs.title;
        node.store.data.attrs.text.fontSize = attrs.fontSize;
        node.store.data.attrs.text.fontWeight = attrs.fontWeight;
        node.store.data.attrs.text.fontFamily = attrs.fontFamily;
        node.store.data.attrs.body.cols = attrs.cols;
        node.store.data.attrs.body.rows = attrs.rows;
    }
    else if (node.store.data.data.actionType == "SVG_KEYVLAUE") {
        node.position(parseInt(attrs.x), parseInt(attrs.y));
        node.rotate(attrs.rotate, { absolute: true });
        node.size(attrs.width, attrs.height);
        node.store.data.componentName = attrs.title;
        node.store.data.attrs.text.fontSize = attrs.fontSize;
        node.store.data.attrs.text.fontWeight = attrs.fontWeight;
        node.store.data.attrs.text.fontFamily = attrs.fontFamily;
        node.store.data.attrs.body.color = attrs.color;
        node.store.data.attrs.body.keys = attrs.keys;
        node.store.data.attrs.body.values = attrs.values;
        setTimeout(() => {
            let keyDom = document.querySelectorAll(
                `g[data-cell-id="${node.id}"] foreignObject #jzkey`
            );
            let valueDom = document.querySelectorAll(
                `g[data-cell-id="${node.id}"] foreignObject #jzvalue`
            );
            keyDom[0].innerHTML = attrs.keys;
            keyDom[0].style.fontSize = attrs.fontSize + "px";
            keyDom[0].style.color = attrs.color;
            keyDom[0].style.fontWeight = attrs.fontWeight;
            keyDom[0].style.fontFamily = attrs.fontFamily;
            valueDom[0].innerHTML = attrs.values;
            valueDom[0].style.fontSize = attrs.fontSize + "px";
            valueDom[0].style.color = attrs.color;
            valueDom[0].style.fontWeight = attrs.fontWeight;
            valueDom[0].style.fontFamily = attrs.fontFamily;
        }, 1)
    }
    else {
        node.attrs.props.strokeWidth = attrs.strokeWidth;
        node.store.data.componentName = attrs.title;
        node.attrs.props.stroke = attrs.color;
        node.position(parseInt(attrs.x), parseInt(attrs.y));
        node.size(attrs.height, attrs.height);
        setTimeout(() => {
            let svgObjectDom = document.querySelectorAll(
                `g[data-cell-id="${node.id}"] foreignObject g`
            );
            if (svgObjectDom.length != 0) {
                if (
                    svgObjectDom[0].getAttribute("stroke") != undefined &&
                    svgObjectDom[0].getAttribute("stroke") != "none"
                ) {
                    svgObjectDom[0].setAttribute("stroke", attrs.color);
                }
                if (
                    svgObjectDom[0].getAttribute("fill") != undefined &&
                    svgObjectDom[0].getAttribute("fill") != "none"
                ) {
                    svgObjectDom[0].setAttribute("fill", attrs.color);
                }
            }
        }, 1)
    }
}
