<template>
  <el-dialog title="更多属性" :visible.sync="isShowMore" width="45%" :close-on-click-modal="false" @close="handleCancle()">
    <div slot="title">
      <div class="equip_title">更多属性</div>
    </div>
    <div class="attr_content">
      <div class="attr_tabs">
        <div class="attr_tab_item" :class="whichTab==item?'active_tab':''" @click="whichTab=item" v-for="(item, index) in tabItems" :key="index">{{item}}</div>
      </div>
      <el-row :gutter="20" v-if="whichTab=='基本属性'">
          <div style="height:20rem;width:85rem;display:flex;flex-wrap:wrap;">
            <div class="detail_item" v-for="(item, index) in pageOneKey" :key="index">
              <span style="">{{index}}：</span>
              <!-- <span style="color:rgba(255,255,255,1);">{{pageOneData[item]}}</span> -->
              <el-tooltip class="item" effect="dark" :content="pageOneData[item]" placement="top">
                <span style="color:rgba(255,255,255,1);">{{pageOneData[item]}}</span>
              </el-tooltip>
            </div>
          </div>

         <div class="grid-content bg-purple" style="width:83rem">
            <p class="table_title" style="color:#37A5DD;font-size: 1.6rem;line-height: 2.2rem;font-weight:400">下属电表</p>
            <el-table :data="pageOneData.meters" style="width: 100%;background:#0C375E;"
              :header-cell-style="headerClass"
              :cell-style="cellStyle" ref="tb">
              <el-table-column prop="code" label="编号" min-width="10%"></el-table-column>
              <el-table-column prop="name" label="名称" :show-overflow-tooltip="true" min-width="10%"></el-table-column>
              <el-table-column prop="powerType" label="电表类型" min-width="10%">
                <template slot-scope="{row}">
                  <span class="color_green">{{metersShow[row.powerType]}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="wireModel" label="电缆型号" :show-overflow-tooltip="true" min-width="10%"></el-table-column>
              <el-table-column prop="wireLength" label="电缆长度(m)" :show-overflow-tooltip="true" min-width="10%"></el-table-column>
              <el-table-column  label="监测回路" min-width="22%">
                <template slot-scope="scope">
                  <!-- [scope.$index].circuits -->
                  <dev v-for="(item,index) in pageOneData.meters[scope.$index].circuits" :key="index" class="tagInside">
                     {{item.name}}
                  </dev>
                  <!-- <div class="tagInside" style="cursor:pointer" v-show="pageOneData.meters[scope.$index].circuits.length>3">...</div> -->
                 
                </template>
              </el-table-column>
            </el-table>
          </div>
       

      </el-row> 



      <el-row v-else>
        <p class="table_title" style="color:#37A5DD;font-size: 1.6rem;line-height: 2.2rem;font-weight:400">检修记录</p>
        <gx-table height='32rem' :columns="columns1" :tableData="tableData1" />
         <!-- 分页器 -->
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange1"
                :current-page="currentPage1"
                page-size="5"
                layout="total, prev, pager, next, jumper"
                :total="totalNumForPagenation1"
              >
              </el-pagination>
            </div>
        <p class="table_title" style="color:#37A5DD;font-size: 1.6rem;line-height: 2.2rem;font-weight:400;margin-top:5rem" >预防性试验执行记录</p>
        <gx-table height='32rem' :columns="columns1" :tableData="tableData2" />
         <!-- 分页器 -->
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange2"
                :current-page="currentPage2"
                page-size="5"
                layout="total, prev, pager, next, jumper"
                :total="totalNumForPagenation2"
              >
              </el-pagination>
            </div>
      </el-row>
    </div>
    <div slot="footer" class="dialog-footer">
      <button class="btn_save" @click="handleCancle()">确 认</button>
    </div>
  </el-dialog>
</template>

<script>
import GxTable from '@/components/GxTableNoCode.vue'
import { tableBg } from '@/mixin/tablebg'
export default {
  mixins: [tableBg],
  components: {
    GxTable
  },
  props: {
    showAttrs: {
      type: Boolean,
      default: false
    },
    moreDetail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 当前页
      currentPage1:1,
      currentPage2:1,
      // 全部数据
      totalNumForPagenation1:0,
      totalNumForPagenation2:0,
      // 当前客户的id
      deviceId:'',
      // 第一页数据的键 
      pageOneKey:{'编号':'code',
      '设备名称':'name',
      '型号':'specification',
      '投运日期':'useDate',
      '保修期至':'warrantyDate',
      '设备名称':'name',
      '客户单位':'ownerName',
      '设备类型':'typeName',
      '所属配电房':'placeName',
      '生产日期':'produceDate',
      '投运日期':'useDate',
      '保修截至':'warrantyDate',
      '厂家':'producer',
      '容量(kVA)':'capacity',
      '额定负载(kW)':'ratedLoad',
      '预防性试验执行周期':'maintainCycleName',
      },
      // 第一页的数据
      pageOneData:{},
      // 电表类型对照表
      metersShow:{"1":"空调用电","2":"照明用电","3":"动力用电","4":"特殊用电","5":"无功补偿","6":"有源滤网","7":"总用电"}, 
      isShowMore: this.showAttrs,
      whichTab: '基本属性',
      tabItems: ['基本属性','全生命周期属性'],
     
      columns1: [
        { prop: 'orderNo', label: '编号',width:120},
        { prop: 'title', label: '工单主题',width:120},
        { prop: 'handler', label: '处理人',width:80},
        { prop: 'finishTime', label: '处理时间' },
        { prop: 'content', label: '故障描述' },

      ],
      // 键值对照表
      dict1:{
        '1':'一个月',
        '2':'三个月',
        '3':'半年',
        '4':'一年',
        '5':'两年',
      },
      dict2:{
        '1':'配电柜',
        '2':'变压器',
        '3':'直流屏',
        '4':'其它',
      },



      tableData1: [],
      tableData2: []
    }
  },
  watch: {
    showAttrs(val) {
      this.isShowMore = val
    }
  },
  methods: {
    // 关闭弹框
    handleCancle() {
      this.$emit('handleCancle')
      this.whichTab="基本属性"
    },
    // 切换tab
    handleClick() {},
    // 被父组件触发且传值
    showForMore(data){
      this.pageOneData= data
      this.pageOneData['typeName'] = this.dict2[data.type]
      this.pageOneData['maintainCycleName'] = this.dict1[data.maintainCycle]
      this.handleCurrentChange1(1)
      this.handleCurrentChange2(1)
      this.deviceId = data.id
    },
    //分页器变化1
    handleCurrentChange1(val){
      this.currentPage1 = val
      this.$store.dispatch('getEquipmentMoreRepairRecords',{
        pageNum:this.currentPage1,
        pageSize:5,
        deviceId:this.deviceId,
      }).then(()=>{
        
        this.tableData1 = this.$store.state.equipment.repairRecords.data.records
        this.totalNumForPagenation1 = this.tableData1.length
        
      })
    },
     //分页器变化2
    handleCurrentChange2(val){
      this.currentPage2 = val
      this.$store.dispatch('getEquipmentpreventabilityRepairRecords',{
        pageNum:this.currentPage2,
        pageSize:5,
        deviceId:this.deviceId,
      }).then(()=>{
        this.tableData2 = this.$store.state.equipment.preventabilityRecords.data.records
        this.totalNumForPagenation2 = this.tableData2.length
        
      })
    },  

  }
}
</script>

<style lang="less" scoped>
.attr_content {
  color: #fff;
  font-size: 1.4rem;
  margin: 0 0 0 0.6rem;
  .attr_tabs {
    display: flex;
    border-bottom: 2px solid rgba(7, 82, 150, 0.25);
    .attr_tab_item {
      margin-right: 2.2rem;
      cursor: pointer;
      padding-bottom: 1rem;
      &:hover {
        color: #03D9BC;
      }
    }
    .active_tab {
      border-bottom: 2px solid #03D9BC;
      color: #03D9BC!important;
    }
  }
  .el-row {
    padding: 2rem 2rem 2rem 0;
    .grid_8_box {
      display: flex;
      margin-bottom: 3.2rem;
      div {
        width: 10rem;
        text-align: right;
      }
      p {
        flex: 1;
        color: rgba(255, 255, 255, 0.85);
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
    .table_title {
      margin-bottom: 0.6rem;
    }
    .color_green {
      color: #03D9BC;
    }
  }
}

.detail_item {
  height:2.2rem;
  width:27.3rem;
  margin-left:1rem;
  white-space:nowrap;
  color: rgba(255, 255, 255, 0.85);
  font-weight:300;
  overflow:hidden;
  text-overflow:ellipsis;
}

.tagInside{
  height: 3.2rem;
  border-radius: 0.2rem;
  border: 0.1rem dashed rgba(108, 184, 255, 0.3);
  background: rgba(52, 150, 241, 0.15);
  color: #2CD9FF;
  font-size: 1.4rem;
  
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  font-weight: 400;
  margin-right: 0.8rem;
  margin-top: 0.4rem;
  float: left;
  line-height: 2.2rem;
}
.pagination{
  float: right;
  margin-top: 1rem;
  margin-right: 2.4rem;
}

</style>