<template>
  <div style="width: 171.2rem; height: calc(100vh - 12rem); overflow: hidden;">
    <div style="width: 172rem; height: 100%; overflow-y: auto;">
      <div class="EngineerBench" style="width: 100%; height: 96.8rem;">
        <div class="height3 display" style="width: 171.2rem; height: 20rem;">
          <div
            class="bgc1 displayRow BasicInfo"
            style="
              height: 20rem;
              width: 56rem;
              border-radius: 0.4rem;
              box-sizing: border-box;
            "
          >
            <div class="white fontSize paddingB disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">账户基本信息</div>
            </div>
            <div class="col-flex-1 padding1 display">
              <div class="width3 displayRow">
                <div
                  class="personInfo"
                  style="display: flex; align-items: center;"
                >
                  <div class="iconBox">
                    <img class="titleIcon" :src="userInfo.img" alt="" />
                  </div>
                  <span class="white fontSize">你好 , {{ userInfo.name }}</span>
                </div>
                <div
                  class="white fontSize"
                  style="
                    display: inline-block;
                    padding: 0 1rem;
                    font-size: 1.6rem;
                    color: #37a5dd;
                  "
                >
                  <span class="personal">{{ userInfo.positionName }}</span>
                </div>
                <div
                  class="personal white fontSize"
                  style="width: 18.5rem; font-size: 1.6rem; color: #37a5dd;"
                >
                  手机号: {{ userInfo.mobile }}
                </div>
              </div>
              <div class="width3 displayRow">
                <div
                  class="FinishIconBox"
                  style="cursor: pointer;"
                  @click="seeMineOrders"
                >
                  <div class="finishIcon">
                    <img src="../../assets/WorkbenchIcon/icon1.png" alt="" />
                  </div>
                  <div class="GrandTotal white">
                    <div style="font-weight: 900; font-size: 2rem;">
                      {{ soIntegerSimpleGraphUnit[4].value }}
                    </div>
                    <div style="font-size: 1.4rem;">累计已完成工单数</div>
                  </div>
                </div>
                <div
                  class="FinishIconBox"
                  style="cursor: pointer;"
                  @click="seeMinePatrol"
                >
                  <div class="finishIcon">
                    <img src="../../assets/WorkbenchIcon/icon2.png" alt="" />
                  </div>
                  <div class="GrandTotal white">
                    <div style="font-weight: 900; font-size: 2rem;">
                      {{ planIntegerSimpleGraphUnit[2].value }}
                    </div>
                    <div style="font-size: 1.4rem;">累计已完成巡检数</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bgc1 displayRow myorder"
            style="
              height: 20rem;
              width: 56.6rem;
              border-radius: 0.4rem;
              box-sizing: border-box;
            "
          >
            <div class="white fontSize disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">我的工单</div>
            </div>
            <div class="col-flex-1" style="display: flex;">
              <div
                class="myOrderType"
                style="cursor: pointer;"
                @click="seeMineOrders"
              >
                <div class="pendingIcon">
                  <img
                    class="imgSize1"
                    src="../../assets/WorkbenchIcon/icon8.png"
                    alt=""
                  />
                </div>
                <div class="pendingNum">
                  {{ soIntegerSimpleGraphUnit[1].value }}
                </div>
                <div class="pendingCode">待处理</div>
              </div>
              <div
                class="myOrderType"
                style="cursor: pointer;"
                @click="seeMineOrders"
              >
                <div class="pendingIcon">
                  <img
                    class="imgSize2"
                    src="../../assets/WorkbenchIcon/icon3.png"
                    alt=""
                  />
                </div>
                <div class="pendingNum">
                  {{ soIntegerSimpleGraphUnit[2].value }}
                </div>
                <div class="pendingCode">处理中</div>
              </div>
              <div
                class="myOrderType"
                style="cursor: pointer;"
                @click="seeMineOrders"
              >
                <div class="pendingIcon">
                  <img
                    class="imgSize3"
                    src="../../assets/WorkbenchIcon/icon4.png"
                    alt=""
                  />
                </div>
                <div class="pendingNum">
                  {{ soIntegerSimpleGraphUnit[3].value }}
                </div>
                <div class="pendingCode">已暂停</div>
              </div>
            </div>
          </div>
          <div
            class="bgc1 displayRow myorder"
            style="
              height: 20rem;
              width: 55.4rem;
              border-radius: 0.4rem;
              box-sizing: border-box;
            "
          >
            <div class="white fontSize disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">我的巡检</div>
            </div>
            <div class="col-flex-1" style="display: flex;">
              <div
                class="myOrderType"
                style="cursor: pointer;"
                @click="seeMinePatrol"
              >
                <div class="pendingIcon">
                  <img
                    class="imgSize5"
                    src="../../assets/WorkbenchIcon/icon5.png"
                    alt=""
                  />
                </div>
                <div class="pendingNum">
                  {{ planIntegerSimpleGraphUnit[0].value }}
                </div>
                <div class="pendingCode">待巡检</div>
              </div>
              <div
                class="myOrderType"
                style="cursor: pointer;"
                @click="seeMinePatrol"
              >
                <div class="pendingIcon">
                  <img
                    class="imgSize6"
                    src="../../assets/WorkbenchIcon/icon6.png"
                    alt=""
                  />
                </div>
                <div class="pendingNum">
                  {{ planIntegerSimpleGraphUnit[1].value }}
                </div>
                <div class="pendingCode">巡检中</div>
              </div>
              <div
                class="myOrderType"
                style="cursor: pointer;"
                @click="seeMinePatrol"
              >
                <div class="pendingIcon">
                  <img
                    class="imgSize7"
                    src="../../assets/WorkbenchIcon/icon7.png"
                    alt=""
                  />
                </div>
                <div class="pendingNum">
                  {{ planIntegerSimpleGraphUnit[3].value }}
                </div>
                <div class="pendingCode">已超期</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bgc height2 display"
          style="
            margin-bottom: 2.4rem;
            height: 35.2rem;
            width: 171.4rem;
            border-radius: 0.4rem;
            box-sizing: border-box;
          "
        >
          <div
            class="bgc2 displayRow runTest"
            style="
              margin-top: 1.6rem;
              height: 34.2rem;
              width: 114.2rem;
              border-radius: 0.4rem;
              box-sizing: border-box;
            "
          >
            <div class="white fontSize disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">运行监测</div>
              <div
                class="fontSize1"
                style="color: #37a5dd; cursor: pointer;"
                @click="seeMoreRunning"
              >
                查看更多
              </div>
            </div>
            <div class="col-flex-1 runTestRoom disFlexlevel">
              <div
                class="runtestRoomInfo displayRow"
                style="border-radius: 0.4rem; overflow: hidden;"
                v-for="(x, idx) in runTestRoomData"
                :key="idx"
              >
                <div class="PowerRoom white fontSize1">{{ x.name }}</div>
                <div class="col-flex-1 runTestData">
                  <div class="roomIconBox">
                    <img
                      class="imgSize8"
                      src="../../assets/WorkbenchIcon/bigyuan.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="runTestRoomType display">
                    <div class="display" style="align-items: center;">
                      <div class="Dots DotsBgc1"></div>
                      <div class="white fontSize">正常</div>
                    </div>
                    <div class="white fontSize1">
                      {{ x.normalCount }}
                    </div>
                  </div>
                  <div class="runTestRoomType display">
                    <div class="display" style="align-items: center;">
                      <div class="Dots DotsBgc2"></div>
                      <div class="white fontSize">警告</div>
                    </div>
                    <div class="white fontSize1">
                      {{ x.warningCount }}
                    </div>
                  </div>
                  <div class="runTestRoomType display">
                    <div class="display" style="align-items: center;">
                      <div class="Dots DotsBgc3"></div>
                      <div class="white fontSize">异常</div>
                    </div>
                    <div class="white fontSize1">
                      {{ x.abnormalCount }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="runTestRoomData.length > 0"
                class="width31 card"
                @click="seeMoreRunning"
                id="myNewCard"
                style="
                  width: 9.8rem;
                  background-color: #0e2c47;
                  color: rgba(255, 255, 255, 0.65);
                  font-size: 3rem;
                  line-height: 24rem;
                  text-align: center;
                  cursor: pointer;
                  border-radius: 0.2rem;
                "
              >
                ···
              </div>
            </div>
          </div>
          <div
            class="bgc2 displayRow warnInfo"
            style="
              margin-top: 1.6rem;
              height: 34.2rem;
              width: 55.4rem;
              border-radius: 0.4rem;
              box-sizing: border-box;
            "
          >
            <div class="white fontSize disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">告警信息</div>
              <div
                class="fontSize1"
                style="color: #37a5dd; cursor: pointer;"
                @click="seeMoreWarning"
              >
                查看更多
              </div>
            </div>
            <div class="col-flex-1">
              <table style="color: rgba(255, 255, 255, 0.85);">
                <thead border="1">
                  <tr>
                    <th
                      style="
                        width: 15.5rem;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.85);
                        fontsize: 1.4rem;
                        font-weight: 400;
                      "
                    >
                      对象
                    </th>
                    <th
                      style="
                        width: 14rem;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.85);
                        fontsize: 1.4rem;
                        font-weight: 400;
                      "
                    >
                      告警信息
                    </th>
                    <th
                      style="
                        width: 20.2rem;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.85);
                        fontsize: 1.4rem;
                        font-weight: 400;
                      "
                    >
                      时间
                    </th>
                  </tr>
                </thead>
                <tr
                  v-for="(item, index) in warningData"
                  :key="index"
                  class="warning_tr ellipsis"
                >
                  <th>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.warningObject"
                      placement="top"
                    >
                      <div style="width: 13.9rem;">
                        {{ item.warningObject }}
                      </div>
                    </el-tooltip>
                  </th>
                  <th>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.warningInfo"
                      placement="top"
                    >
                      <div style="width: 12.5rem;">{{ item.warningInfo }}</div>
                    </el-tooltip>
                    <!-- <div style="width:12.5rem">{{ item.warningInfo }}</div> -->
                  </th>
                  <th>
                    <div style="width: 18.2rem;">{{ item.createTime }}</div>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div
          class="bgc2 height1 displayRow runTestEcharts"
          style="
            margin-top: 2.4rem;
            height: 38.8rem;
            width: 171.2rem;
            border-radius: 0.4rem;
            box-sizing: border-box;
          "
        >
          <div class="white fontSize disFlexlevel">
            <div style="color: #37a5dd; font-size: 1.6rem;">完成量统计</div>
            <div class="myTabBox">
              <div
                v-for="(item, index) in [
                  { label: '本周', value: '1' },
                  { label: '本月', value: '2' },
                  { label: '本年', value: '3' },
                ]"
                :key="index"
                :class="box1Choosen === item.value ? 'tabGreen' : 'tabGrey'"
                @click="
                  box1Choosen = item.value
                  completeStatistics()
                "
              >
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="col-flex-1" id="bigBox" ref="Finish"></div>
        </div>
      </div>

      <!-- <div style="width: 100%; height: 25rem;color: grey;font-size: 1.4rem;text-align: center;line-height: 10rem;">由光谷信息股份有限公司提供技术支持</div> -->
    </div>
  </div>
</template>

<script>
import {
  planIntegerSimpleGraph,
  soIntegerSimpleGraph,
  soIntegerGraph,
  planIntegerGraph,
  soPlanIntegerGraph,
  soPlanCounts,
} from '@/api/workBench.js'
export default {
  name: 'EngineerBench',
  data() {
    return {
      // 本周本月本年的选择tab
      box1Choosen: '1',

      index: 0,
      runTestRoomData: [],
      iconPath: require('../../assets/WorkbenchIcon/titleIcon.png'),

      // 本页的详细数据
      soIntegerSimpleGraphUnit: [],
      planIntegerSimpleGraphUnit: [],
      userInfo: {},

      // 完成量统计图数据############################################################################
      optionCompleted: {
        // backgroundColor: "#232d36",//统计图的背景颜色
        color: ['#5AD8A6', '#5B8FF9'],
        legend: {
          icon: 'rect',
          itemWidth: 12,
          itemHeight: 2,
          textStyle: {
            color: '#FFFFFF  ',
          },
        },
        grid: {
          top: '10%',
          left: '2%',
          right: '2%',
          bottom: '8%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',

            lineStyle: {
              type: 'solid',
              color: {
                type: 'linear ',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,255,255,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255,255,255,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,255,255,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },

        // x轴的数据
        xAxis: [
          {
            type: 'category',
            axisTick: {
              //y轴刻度线
              show: true,
              lineStyle: {
                color: '#005AB1',
                width: 1,
                height: 20,
              },
            },
            axisLine: {
              //x轴的样式修改
              lineStyle: {
                color: '#164878',
              },
            },
            axisLabel: {
              color: '#FFFFFF',
              width: 200,
              interval: 0,
              textStyle: {
                fontWeight: '1',
                // fontSize:14,
                color: 'rgba(255,255,255,0.85)',
              },
            },
            boundaryGap: false,
            data: ['暂无数据'],
          },
        ],

        yAxis: [
          {
            type: 'value',
            // 步长标记
            minInterval: 1,
            min: 0,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#164878',
                opacity: 1,
              },
            },
            axisLabel: {
              show: true,
              margin: 20,
              textStyle: {
                color: '#fff',
              },
            },
          },
        ],
        series: [
          {
            name: '已完成工单',
            type: 'line',
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: '#5B8FF9',
              },
            },
            itemStyle: {
              color: '#5B8FF9',
              borderWidth: 2,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(36, 67, 131, 1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(12, 47, 79, 0)',
                  },
                ]),
              },
            },
            data: [0], //data.values
          },
          {
            name: '已完成巡检',
            type: 'line',
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: '#5AD8A6',
              },
            },
            itemStyle: {
              color: '#5AD8A6',
              borderWidth: 2,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(90, 216, 166, 1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(12, 47, 79, 0)',
                  },
                ]),
              },
            },
            data: [0], //data.values
          },
        ],
      },

      // 告警信息################################################################################
      warningData: [],
    }
  },
  mounted() {
    Object.assign(this.userInfo, { ...this.$store.getters.getterPersonalData })
    // 完成统计
    this.completeStatistics()
    // 告警信息
    this.alarmInformation()
    // 运行监测
    this.operationalMonitoring()
    // 基本信息
    this.basicInfo()
  },
  methods: {
    // 基本信息
    basicInfo() {
      // 巡检累计
      planIntegerSimpleGraph().then((res) => {
        this.planIntegerSimpleGraphUnit = res.data
      })
      // 工单累计
      soIntegerSimpleGraph().then((res) => {
        this.soIntegerSimpleGraphUnit = res.data
      })
    },
    // 告警信息
    alarmInformation() {
      let value = {
        pageNum: 1,
        pageSize: 3,
        warningType: '',
        warningLevel: '',
        warningStatus: '',
        beginTime: '',
        endTime: '',
      }
      this.$store.dispatch('getWarningInfoList', value).then(() => {
        // console.log(this.$store.state.system.getWarningInfoList);
        if (this.$store.state.system.getWarningInfoList.errorCode == 0) {
          this.warningData = this.$store.state.system.getWarningInfoList.data.records
        } else {
          this.warningData = []
        }
      })
    },
    // 运行监测
    operationalMonitoring() {
      this.$store
        .dispatch('operationMonitoringData', {
          pageNum: 1,
          pageSize: this.userInfo.identity == '2' ? 4 : 2,
        })
        .then(() => {
          if (this.$store.state.system.operationMonitoringData.errorCode == 0) {
            this.runTestRoomData = this.$store.state.system.operationMonitoringData.data.records
          } else {
            this.runTestRoomData = []
          }
        })
    },

    // 完成统计
    completeStatistics() {
      soPlanIntegerGraph({
        type: this.box1Choosen,
      }).then((res) => {
        // 渲染完成量统计的折线图(本周)
        this.workAndinspection(res.data, this.optionCompleted, {
          gridBottom: 30,
          gridBottomActive: 60,
          zoomBottom: 10,
          end: 20,
        })
        this.initEChart(
          this.$refs.Finish,
          this.optionCompleted,
          '166.4rem',
          '29rem',
        )
      })
    },

    // 工单任务 和 巡检任务 和 完成统计 公用数据处理
    workAndinspection(data, obj, grid) {
      const ecahrtsData = [[]]
      const length = data[0].data.length

      for (let i = 0; i < data.length; i++) {
        ecahrtsData[0].push(data[i].name)
        for (let j = 0; j < length; j++) {
          if (ecahrtsData[j + 1]) {
            ecahrtsData[j + 1].push(data[i].data[j])
          } else {
            ecahrtsData[j + 1] = [data[i].data[j]]
          }
        }
      }

      obj.xAxis[0].data = ecahrtsData[0]

      for (let j = 0; j < length; j++) {
        obj.series[j].data = ecahrtsData[j + 1]
      }
      if (ecahrtsData[0].length > 10) {
        obj.grid.bottom = grid.gridBottomActive
        obj.dataZoom = {
          type: 'slider',
          show: true,
          start: 0,
          end: grid.end,
          xAxisIndex: [0],
          bottom: grid.zoomBottom,
          showDetail: false,
        }
      } else {
        obj.grid.bottom = grid.gridBottom
        delete obj.dataZoom
      }
    },

    // 初始化 echart
    initEChart(ref, obj, width, height) {
      var echart = this.$echarts.init(ref)
      echart.clear()
      echart.setOption(obj)
      echart._dom.childNodes[0].childNodes[0].style.width = width
      echart._dom.childNodes[0].childNodes[0].style.height = height
    },

    SelectedOn(idx) {
      this.index = idx
    },

    // 查看我的工单
    seeMineOrders() {
      this.$router.push('repair/orderManagementMine')
      this.$bus.$emit('goToTheMenu', 39)
    },

    // 查看我的巡检
    seeMinePatrol() {
      this.$router.push('repair/patrolTaskMine')
      this.$bus.$emit('goToTheMenu', 38)
    },

    // 产看更多告警信息
    seeMoreWarning() {
      this.$router.push('system/alarm')
      this.$bus.$emit('goToTheMenu', 40)
    },
    // 查看更多运行监测数据
    seeMoreRunning() {
      this.$router.push('monitoring')
      this.$bus.$emit('goToTheMenu', 9)
    },
  },
}
</script>

<style lang="less" scoped>
// .EngineerBench {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
.display {
  display: flex;
  justify-content: space-between;
}
.displayRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.col-flex-1 {
  flex: 1;
}
// .bgc{
//   background-color: rgba(0, 115, 222, 0.15);
// }
.bgc1 {
  background-color: rgba(0, 115, 222, 0.3) !important;
}
.bgc2 {
  background-color: rgba(0, 115, 222, 0.3) !important;
}
.height1 {
  height: 42%;
}
.height2 {
  height: 32%;
}
.height3 {
  height: 21%;
}
.width {
  width: 33% !important;
}
.width1 {
  width: 32.8% !important;
}
.width2 {
  width: 66.5% !important;
}
.width3 {
  width: 49% !important;
}
.white {
  color: #ffffff;
}
.fontSize {
  font-size: 1.6rem;
}
.fontSize1 {
  font-size: 1.4rem !important;
}
.paddingB {
  padding: 0 0 1.6rem 0;
}
.disFlexlevel {
  display: flex;
  justify-content: space-between;
}
.personInfo {
  .iconBox {
    width: 4rem;
    height: 4rem;
    margin-right: 0.8rem;
    img {
      widows: 100%;
      height: 100%;
    }
  }
}
.timeBox {
  width: 18rem;
  height: 3rem;
  align-items: center;
  text-align: center;
  div {
    flex: 1;
    background-color: rgba(0, 115, 222, 0.1);
    border: 0.1rem solid rgba(0, 115, 222, 0.1);
    cursor: pointer;
  }
}
.on {
  background-color: #027969 !important;
  border: 0.1rem solid #027969 !important;
}

.personal {
  background: rgba(23, 68, 109, 1);
  text-align: center;
  border-radius: 1.4rem;
  // margin-top: 1.9rem;
  height: 2.8rem;
  line-height: 2.8rem;
}
.BasicInfo {
  background: url('../../assets/WorkbenchIcon/basicIcon.png') top 7rem center
    no-repeat;
  background-size: 109.4rem 14.6rem;
  padding: 2rem;
  box-sizing: border-box;
}
.FinishIconBox {
  display: flex;
  align-items: center;
  .finishIcon {
    width: 3.6rem;
    height: 3.6rem;
    margin-right: 0.8rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .GrandTotal {
    font-size: 1.4rem;
  }
}
.myorder {
  padding: 2rem 2.4rem;
  box-sizing: border-box;
}
.myOrderType {
  width: 12.4rem;
  height: 12.4rem;
  background-color: rgba(0, 90, 177, 0.15) !important;
  margin-top: 1.6rem;
  padding: 1.5rem 1.772rem;
  box-sizing: border-box;
  &:nth-child(2) {
    margin: 0 2.4rem;
    margin-top: 1.6rem;
  }
}
.pendingNum {
  font-size: 2rem;
  color: #ffffff;
  margin-top: 2rem;
}
.imgSize1 {
  width: 2.728rem;
  height: 2.4rem;
}
.imgSize2 {
  width: 2.557rem;
  height: 2.557rem;
}
.imgSize3 {
  width: 2.8rem;
  height: 2.52rem;
}
.imgSize5 {
  width: 2.532rem;
  height: 2.57rem;
}
.imgSize6 {
  width: 2.511rem;
  height: 2.631rem;
}
.imgSize7 {
  width: 2.292rem;
  height: 2.576rem;
}
.imgSize8 {
  width: 5.5rem;
  height: 5.5rem;
}
.pendingCode {
  font-size: 1.4rem;
  color: #ffffff;
  margin-top: 0.1rem;
}
.runTest {
  padding: 2rem 2.4rem;
  box-sizing: border-box;
  .runTestRoom {
    margin-top: 2rem;
    // padding-right: 4.2rem;
    box-sizing: border-box;
    .runtestRoomInfo {
      width: 19%;
      .PowerRoom {
        padding: 0.5rem 0.9rem;
        box-sizing: border-box;
        background: linear-gradient(
          180deg,
          #005ab1 0%,
          #0d73d5 100%
        ) !important;
      }
    }
  }
}
.runTestData {
  background: rgba(15, 36, 55, 0.3);
  padding: 1.4rem 1.6rem 1.6rem 1.4rem;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: #0f2437 !important;
  }
  .roomIconBox {
    text-align: center;
  }
  .runTestRoomType {
    margin-top: 1.4rem;
  }
}
.Dots {
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 0.8rem;
}
.DotsBgc1 {
  background: #37a5dd;
}
.DotsBgc2 {
  background: #ffa82c !important;
}
.DotsBgc3 {
  background: #ff6565;
}
.runTestEcharts {
  padding: 2rem 2.4rem;
  box-sizing: border-box;
}
.warnInfo {
  padding: 2rem 2.4rem;
  box-sizing: border-box;
}
table {
  width: 100%;
  border: none !important;
  border-collapse: collapse; /* 为table设置这个属性 */
  margin-top: 1.6rem;
  thead > tr {
    background: #005ab1;
    color: #ffffff;
    font-size: 1.4rem;
    text-align: center;
  }
  thead {
    th {
      padding: 0.8rem 0;
    }
  }
  & > tr,
  th {
    border: 0.1rem solid #005ab1;
  }
  & > tr > th {
    padding: 0.7rem 0;
    color: #ffffff;
    font-size: 1.4rem;
  }
}
.routerPlace {
  overflow-y: scroll;
}

.myTabBox {
  height: 3rem;
  width: 18rem;
  background-color: #0b365d;
  border: 0.1rem solid #004579;
  font-size: 1.4rem;
  line-height: 3rem;

  .tabGreen {
    height: 3rem;
    width: 6rem;
    background-color: #027969;
    float: left;
    text-align: center;
    cursor: pointer;
  }
  .tabGrey {
    height: 3rem;
    width: 6rem;
    float: left;
    text-align: center;
    cursor: pointer;
  }
}

.titleIcon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 0.4rem;
}

.ellipsis {
  th {
    width: 15.5rem;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    font-weight: 400;
  }
  th > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
