<template>
    <div class="sysReport">
        <div class="report-header box-style">
            <el-form :inline="true" :model="formInline" class="demo-form-inline queryForm">
                <el-form-item label="场景：" v-if="treeInfo.type !== 4">
                    <el-select v-model="sbScene" collapse-tags placeholder="请选择">
                        <el-option v-for="option in sbSceneOptions" :key="option.id" :label="option.name" :value="option.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备名称：">
                    <el-select v-model="formInline.sbName" multiple collapse-tags placeholder="请选择" style="width: 236px" @change="handleChangeEquipment">
                        <el-option v-for="option in SBNameOptions" :key="option.id" :label="option.name" :value="option.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="监测类型：" v-if="reportType == 'fireControlE'">
                    <el-select v-model="formInline.JCType" placeholder="请选择" @change="handleChange">
                        <el-option v-for="option in JCTypeOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="间隔：">
                    <el-select v-model="formInline.interval" placeholder="请选择">
                        <el-option v-for="option in intervalOptions" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间范围：" v-if="formInline.interval !== '0'">
                    <template v-if="formInline.interval !== '6'">
                        <el-date-picker v-model="formInline.time" :key="pickerType" :type="pickerType" value-format="yyyy-MM-dd" range-separator="至" placeholder="选择日期" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </template>
                    <template v-else>
                        <el-date-picker v-model="formInline.time" type="monthrange" value-format="yyyy-MM" range-separator="至" placeholder="选择日期" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </template>
                </el-form-item>
            </el-form>
            <div class="btn-box">
                <span class="submit" @click="onSubmit">查询</span>
                <span class="export" @click="Export">导出</span>
                <span class="back" @click="onBack" v-if="isShowIcon"></span>
            </div>
        </div>
        <div class="report-cont box-style">
            <el-table class="reportTable" :data="reportData" :cell-style="cellStyle" style="position:relative;">
                <!-- <el-table-column
        type="index"
        label="序号"
        align="center"
        width="50">
      </el-table-column> -->
                <el-table-column label="序号" width="50" fixed>
                    <template slot-scope="scope">{{
            scope.$index + 1 + (currentPage - 1) * pageSize
          }}</template>
                </el-table-column>
                <el-table-column v-for="item in reportColumn" :key="item.prop" :width="item.width" :prop="item.prop" :fixed="item.fixed" :label="item.label" :show-overflow-tooltip="true">
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <el-pagination class="pagination-box" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="sizes,prev, pager, next" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg"
import { exportMonitoringReport, exportFireControlReport } from '@/api/repair'
export default {
    mixins: [tableBg],
    components: {},
    name: 'sysReport',
    data () {
        return {
            backPage: {},
            pickerType: 'daterange',
            currentPage: 1,
            pageSize: 10,
            total: 0,
            reportColumn: [],
            reportData: [],
            monitoringColumn: [
                //不固定列
                {
                    //配电监控公共部分
                    prop: 'deviceName',
                    label: '设备名称',
                    width: 200,
                    fixed: true
                },
                {
                    prop: 'meterName',
                    label: '仪表名称',
                    width: 150,
                    fixed: true
                },
                {
                    prop: 'customerName',
                    label: '客户单位',
                    width: 150,
                    fixed: true
                },
                // {
                //   prop: 'circuitName',
                //   label: '回路名称',
                //   width: ''
                // },
                {
                    prop: 'time',
                    label: '采集时间',
                    width: 165,
                    fixed: true
                },
            ],
            monitoringColumn2: [
                //固定列
                {
                    //配电监控公共部分
                    prop: 'deviceName',
                    label: '设备名称',
                    width: 200,
                    fixed: true,
                },
                {
                    prop: 'meterName',
                    label: '仪表名称',
                    width: 150,
                    fixed: true,
                },
                {
                    prop: 'customerName',
                    label: '客户单位',
                    width: 150,
                    fixed: true,
                },
                // {
                //   prop: 'circuitName',
                //   label: '回路名称',
                //   width: ''
                // },
                {
                    prop: 'time',
                    label: '采集时间',
                    width: 165,
                    fixed: true,
                },
            ],
            monitoringChart: [
                //10kv/400v
                // {
                //   prop: 'edrl',
                //   label: '额定容量 (kVA)',
                //   width: '120'
                // },
                {
                    prop: 'ua',
                    label: 'Ua (V)',
                    width: 100,
                },
                {
                    prop: 'ub',
                    label: 'Ub (V)',
                    width: 100,
                },
                {
                    prop: 'uc',
                    label: 'Uc (V)',
                    width: 100,
                },
                {
                    prop: 'ia',
                    label: 'Ia (A)',
                    width: 100,
                },
                {
                    prop: 'ib',
                    label: 'Ib (A)',
                    width: 100,
                },
                {
                    prop: 'ic',
                    label: 'Ic (A)',
                    width: 100,
                },
                {
                    prop: 'yggl',
                    label: '有功功率(kW）',
                    width: 150,
                },
                {
                    prop: 'wggl',
                    label: '无功功率(kVar）',
                    width: 150,
                },
                {
                    prop: 'zxygdn',
                    label: '正向有功电能(kWh)',
                    width: 150,
                },
                {
                    prop: 'fxygdn',
                    label: '反向有功电能(kWh)',
                    width: 150,
                },
            ],
            dCscreenColumn: [
                {
                    //直流屏
                    prop: 'kzmxdy',
                    label: '控制母线电压 (V）',
                    width: 160,
                },
                {
                    prop: 'dlmxdy',
                    label: '动力母线电压 (V)',
                    width: 160,
                },
                {
                    prop: 'cddy',
                    label: '充电电压 (V)',
                    width: 150,
                },
                {
                    prop: 'zdcdy',
                    label: '蓄电池电压 (V)',
                    width: 150,
                },
                {
                    prop: 'cdfczzscdl',
                    label: '充电浮充装罝输出电流 (A）',
                    width: 220,
                },
            ],
            //变压器
            transformerColumn: [
                // {
                //   prop: 'edrl',
                //   label: '额定容量 (kVA)',
                //   width: 150
                // }, {
                //   prop: 'fh',
                //   label: '负荷 (kW）',
                //   width: 120
                // }, {
                //   prop: 'ljydl',
                //   label: '累计用电量 (kWh',
                //   width: 150
                // }, {
                //   prop: 'szgl',
                //   label: '视在功率 (kVA)',
                //   width: 150
                // }, {
                //   prop: 'wda',
                //   label: '温度A (°C)',
                //   width: 100
                // }, {
                //   prop: 'wdb',
                //   label: '温度B (°C)',
                //   width: 100
                // }, {
                //   prop: 'wdc',
                //   label: '温度C (°C)',
                //   width: 100
                // },
                {
                    prop: 'fzl',
                    label: '负载率',
                    width: 100,
                },
                {
                    prop: 'dya',
                    label: 'A相电压',
                    width: 100,
                },
                {
                    prop: 'dyb',
                    label: 'B相电压',
                    width: 100,
                },
                {
                    prop: 'dyc',
                    label: 'C相电压',
                    width: 100,
                },
                {
                    prop: 'dla',
                    label: 'A相电流',
                    width: 100,
                },
                {
                    prop: 'dlb',
                    label: 'B相电流',
                    width: 100,
                },
                {
                    prop: 'dlc',
                    label: 'C相电流',
                    width: 100,
                },
                {
                    prop: 'wda',
                    label: 'A相温度',
                    width: 100,
                },
                {
                    prop: 'wdb',
                    label: 'B相温度',
                    width: 100,
                },
                {
                    prop: 'wdc',
                    label: 'C相温度',
                    width: 100,
                },
                {
                    prop: 'yggl',
                    label: '三相有功功率',
                    width: 120,
                },
                {
                    prop: 'szgl',
                    label: '三相视在功率',
                    width: 120,
                },
                {
                    prop: 'wggl',
                    label: '三相无功功率',
                    width: 120,
                },
                {
                    prop: 'zs',
                    label: '噪声',
                    width: 100,
                },
            ],
            fireControl: [
                {
                    //电气消防公共部分
                    prop: 'sbmc',
                    label: '设备名称',
                    // width: 200,
                },
                {
                    prop: 'khdw',
                    label: '客户单位',
                    // width: 150,
                },
                {
                    prop: 'hlmc',
                    label: '回路名称',
                    // width: 150,
                },
                {
                    prop: 'cjst',
                    label: '采集时间',
                    // width: 165,
                },
            ],
            //监测类型：电流
            EColumn1: [
                {
                    prop: 'ld',
                    label: '漏电 (mA）',
                    // width: '80',
                },
            ],
            //监测类型：温度
            EColumn2: [
                {
                    prop: 'wd1',
                    label: '1路温度 (°C)',
                    // width: 100,
                },
                {
                    prop: 'wd2',
                    label: '2路温度 (°C)',
                    // width: 100,
                },
            ],
            //消防电源监测
            FColumn: [
                {
                    prop: 'A',
                    label: 'A相电压 (V）',
                    // width: 100,
                },
                {
                    prop: 'B',
                    label: 'B相电压 (V）',
                    // width: 100,
                },
                {
                    prop: 'C',
                    label: 'C相电压 (V）',
                    // width: 100,
                },
            ],
            reportType: this.$route.query.type,
            customName: this.$route.query.name,
            roomName: '',
            SBNameOptions: [
                {
                    name: '全部',
                    id: 'all',
                },
                {
                    id: '1502177139066146817',
                    name: '四楼照明动力配电箱',
                },
                {
                    id: '1502177139066146818',
                    name: '四楼照明动力配电箱1',
                },
            ],
            sbSceneOptions: [
                { name: '10kV系统图', id: 'A' },
                { name: '400V系统图', id: 'B' },
                { name: '直流屏', id: 'C' },
                { name: '变压器', id: 'D' },
            ],
            intervalOptions: [
                {
                    label: '实时',
                    value: '1',
                },
                {
                    label: '1分钟',
                    value: '2',
                },
                {
                    label: '15分钟',
                    value: '3',
                },
                {
                    label: '时',
                    value: '4',
                },
                {
                    label: '天',
                    value: '5',
                },
                {
                    label: '月',
                    value: '6',
                },
            ],
            JCTypeOptions: [
                {
                    label: '电流',
                    value: 'dl',
                },
                {
                    label: '温度',
                    value: 'wd',
                },
            ],
            formInline: {
                JCType: 'dl',
                time: '',
                sbName: ['all'],
                interval: '1',
            },
            sbScene: 'A', //设备场景
        }
    },
    props: {
        treeInfo: {
            type: Object,
            default: () => {
                return { type: 4 }
            },
        },
    },
    beforeRouteEnter (to, from, next) {
        next((vm) => {
            vm.backPage = {
                path: from.path,
                query: from.query,
            }
        })
    },
    computed: {
        /** 是否显示返回图标 */
        isShowIcon () {
            return this.$route.path.includes('report')
        },
    },
    mounted () {
        console.log('reportType', this.reportType)
        this.roomName = this.reportType == 'fireControlE' ? '漏电火灾监测' : this.reportType == 'fireControlF' ? '消防电源监测' : this.roomName
        this.getReportColumn()
        // this.getDeviceList()
    },
    watch: {
        'formInline.interval': {
            handler (newVal, oldVal) {
                this.formInline.time = ''
                if (!['5', '6'].includes(newVal)) {
                    this.pickerType = 'date'
                } else {
                    this.pickerType = 'daterange'
                }
            },
            immediate: true,
        },
        /** 监听树选择信息 */
        treeInfo: {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (newVal) {
                    if (newVal.type === 4) {
                        switch (newVal.name) {
                            case '10kV系统图':
                            case '400V系统图':
                                this.reportType = 'monitoringChart'
                                break
                            case '变压器':
                                this.reportType = 'transformer'
                                break
                            case '直流屏':
                                this.reportType = 'dCscreen'
                                break
                        }
                    } else {
                        //默认场景为 10kV
                        this.reportType = 'monitoringChart'
                    }
                    this.getReportColumn()
                    this.getDeviceList()
                    this.reset();
                    this.currentPage = 1
                    this.customName = this.$route.path ==='/fireControl/report'? this.$route.query.name : newVal.assortedName ? newVal.assortedName : newVal.name;
                    this.roomName = ''
                }
            },
        },
    },
    methods: {
        /**
         * 获取设备名称信息
         */
        getDeviceList () {
            if (this?.treeInfo?.id || this.$route.query.id) {
                let params = { nodeId: this?.treeInfo?.id || this.$route.query.id }
                this.$store.dispatch('getDeviceList', params).then(() => {
                    this.SBNameOptions = this.$store.state.monitoring.deviceList.data
                    this.SBNameOptions.unshift({
                        name: '全部',
                        id: 'all',
                    })
                    this.getReportData()
                })
            }
        },
        /**获取报表信息 */
        getReportData () {
            if (this?.treeInfo?.id || this.$route.query.id) {
                let nodeId = this.$route.query.id + ''
                let deviceIds = []
                if (this.formInline.sbName[0] !== 'all') {
                    deviceIds = this.formInline.sbName
                } else {
                    this.SBNameOptions.forEach((item) => {
                        if (item.id !== 'all') {
                            deviceIds.push(item.id)
                        }
                    })
                }
                //选择树不是场景节点 则默认为10kV场景
                if (this.treeInfo?.type && this.treeInfo?.type !== 4) {
                    nodeId = `${this.treeInfo.id}-${this.sbScene}`
                }
                console.log('nodeId', nodeId)
                let params = {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    type: this.formInline.interval,
                    // nodeId: nodeId[0],
                    nodeId: nodeId,
                    date: !['5', '6'].includes(this.formInline.interval)
                        ? this.formInline.time
                        : '',
                    deviceIds: deviceIds.join(','),
                    startTime:
                        ['5', '6'].includes(this.formInline.interval) &&
                            this.formInline.time.length > 0
                            ? this.formInline.time[0]
                            : '',
                    endTime:
                        ['5', '6'].includes(this.formInline.interval) &&
                            this.formInline.time.length > 0
                            ? this.formInline.time[1]
                            : '',
                }
                this.$store.dispatch('getReportData', params).then(() => {
                    this.reportData = this.$store.state.monitoring.reportData.data.records
                    this.total = this.$store.state.monitoring.reportData.data.total
                })
            }
        },
        handleChangeEquipment (val) {
            console.log(val)
            if (val.indexOf('all') !== -1) {
                this.formInline.sbName = ['all']
            }
        },
        onSubmit () {
            this.currentPage = 1
            this.getReportData()
        },
        Export () {
            let nodeId = this.$route.query.id + ''
            if (this.treeInfo?.type && this.treeInfo?.type !== 4) {
                nodeId = `${this.treeInfo.id}-${this.sbScene}`
            }
            let deviceIds = []
            if (this.formInline.sbName[0] !== 'all') {
                deviceIds = this.formInline.sbName
            } else {
                this.SBNameOptions.forEach((item) => {
                    if (item.id !== 'all') {
                        deviceIds.push(item.id)
                    }
                })
            }
            let params = {
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                type: this.formInline.interval,
                // nodeId: nodeId[0],
                monitorType: this.formInline.JCType,
                nodeId: nodeId,
                date: !['5', '6'].includes(this.formInline.interval)
                    ? this.formInline.time
                    : '',
                deviceIds: deviceIds.join(','),
                startTime:
                    ['5', '6'].includes(this.formInline.interval) &&
                        this.formInline.time.length > 0
                        ? this.formInline.time[0]
                        : '',
                endTime:
                    ['5', '6'].includes(this.formInline.interval) &&
                        this.formInline.time.length > 0
                        ? this.formInline.time[1]
                        : '',
            }
            let reportName = `${this.customName}${this.roomName || ''}`
            console.log(this.customName, this.roomName)
            // this.$store.dispatch('exportReport', params)
            if (!['fireControlE', 'fireControlF'].includes(this.reportType)) {
                exportMonitoringReport(params).then((response) => {
                    var blob = new Blob([response], {
                        type: 'application/vnd.ms-excel,charset=utf-8',
                    }) //创建一个blob对象
                    var a = document.createElement('a') //创建一个<a></a>标签
                    a.href = URL.createObjectURL(blob) // response is a blob
                    a.download = `${reportName}报表统计.xls` //文件名称
                    a.style.display = 'none'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
            } else {
                params.reportType = this.reportType == 'fireControlE' ? 1 : 2
                exportFireControlReport(params).then((response) => {
                    var blob = new Blob([response], {
                        type: 'application/vnd.ms-excel,charset=utf-8',
                    }) //创建一个blob对象
                    var a = document.createElement('a') //创建一个<a></a>标签
                    a.href = URL.createObjectURL(blob) // response is a blob
                    a.download = `${reportName}报表统计.xls` //文件名称
                    a.style.display = 'none'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                })
            }
        },
        onBack () {
            if (this.backPage.path) {
                this.$router.push(this.backPage)
            }
        },
        handleChange (val) {
            console.log('选中', val)
            this.reportColumn =
                val == 'dl'
                    ? [...this.fireControl, ...this.EColumn1]
                    : [...this.fireControl, ...this.EColumn2]
        },
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.currentPage = 1
            this.getReportData()
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`)
            this.currentPage = val
            this.getReportData()
        },
        /** 获取报表列 */
        getReportColumn () {
            switch (this.reportType) {
                case 'fireControlE':
                    this.reportColumn = [...this.fireControl, ...this.EColumn1]
                    break
                case 'fireControlF':
                    this.reportColumn = [...this.fireControl, ...this.FColumn]
                    break
                case 'monitoringChart':
                    this.reportColumn = [
                        ...this.monitoringColumn,
                        ...this.monitoringChart,
                    ]
                    break
                case 'dCscreen':
                    this.reportColumn = [...this.monitoringColumn, ...this.dCscreenColumn]
                    break
                case 'transformer':
                    this.reportColumn = [
                        ...this.monitoringColumn,
                        ...this.transformerColumn,
                    ]
                    break
                default:
                    this.reportColumn = this.$data[this.reportType]
                    break
            }
        },
        reset(){
            this.formInline ={
                JCType: 'dl',
                time: '',
                sbName: ['all'],
                interval: '1',
            }
        }
    },
}
</script>

<style lang="less" scoped>
.sysReport {
    width: 100%;
    height: 100%;
    .box-style {
        background: #0d3050;
        border-radius: 4px;
        padding: 1.6rem 2.4rem;
        box-sizing: border-box;
    }
    .report-header {
        display: flex;
        justify-content: space-between;
        padding: 1.2rem 2.4rem;
        .btn-box {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            span {
                border-radius: 2px;
                height: 3.2rem;
                box-sizing: border-box;
                font-size: 1.4rem;
                color: #fff;
                text-align: center;
                line-height: 3rem;
            }
            .submit {
                width: 7.4rem;
                background: #133f68;
                border: 1px solid rgba(0, 115, 222, 0.1);
                margin-right: 0.8rem;
                cursor: pointer;
            }
            .export {
                width: 7.4rem;
                background: #027969;
                margin-right: 1.6rem;
                cursor: pointer;
            }
            .back {
                width: 3.2rem;
                background: url("../assets/icons/back.png") no-repeat center
                    #133f68;
                background-size: 1.8rem 1.5rem;
                cursor: pointer;
            }
        }
    }
    .report-cont {
        margin-top: 1.6rem;
        padding: 2.4rem;
        height: 80.2rem;
        overflow-y: auto;
        .reportTable {
            width: 100%;
            // min-height: 50rem;
            //   height: 67rem;
            font-size: 1.4rem;
            color: rgba(255, 255, 255, 0.85);
            position: static;
            ::v-deep tr {
                background: none;
            }
            ::v-deep.el-table__body-wrapper {
                height: calc(100% - 48px);
                overflow-y: auto;
            }
            ::v-deep.el-table__body tr:hover > td.el-table__cell {
                background: none;
            }
            ::v-deep thead {
                color: #fff;
            }
            ::v-deep .el-table__header {
                tr th {
                    border-bottom: 1px solid rgba(108, 184, 255, 0.3);
                    /* background: rgba(52, 150, 241, 0.15); */
                    background: #0c375d !important;
                    border-right: 1px solid #0d3050;
                }
                tr th:last-child {
                    border-right: none;
                }
            }
            td.el-table__cell {
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
            ::v-deep .el-table__fixed::before {
                background-color: transparent;
            }
        }
    }
    .demo-form-inline.queryForm {
        .el-form-item {
            margin-bottom: 0;
        }
        ::v-deep.el-form-item__label {
            color: #fff;
            font-size: 1.4rem;
            padding-left: 0;
        }
        ::v-deep.el-date-editor {
            .el-range-input {
                background: none;
            }
            .el-range-separator,
            .el-range__close-icon {
                line-height: 32px;
            }
        }
        ::v-deep.el-date-editor--daterange {
            width: 240px;
        }
        ::v-deep.el-date-editor--date {
            width: 168px;
        }
    }
    .pagination-box {
        float: right;
        margin-top: 2.4rem;
        text-align: right;
    }
}
</style>
