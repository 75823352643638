<template>
  <div style="width: 171.2rem; height: 96.8rem; overflow: hidden;">
    <div
      style="width: 172rem; height: 85rem; overflow-y: auto;"
      class="containerOuterSider"
    >
      <div
        class="container"
        style="width: 172rem; height: 96.8rem; overflow: hidden;"
      >
        <!-- 左边盒子 star-->
        <!-- 管理员工作平台 -->
        <div class="left-box" style="width: 70%;">
          <div
            class="disFlexlevel bgc1 col-flex-2 BasicInfo"
            style="height: 21.92rem; width: 120.4rem;"
          >
            <div class="disFlexlevel col-flex-1">
              <div class="col-flex-1 disFlexUpAndDown border-Radius">
                <!-- style="background-color:yellow" -->
                <div
                  class="col-flex-1"
                  style="height: 17.92rem; width: 23.4rem;"
                >
                  <div
                    class="white fontSize"
                    style="color: #37a5dd; font-size: 1.6rem;"
                  >
                    账户基本信息
                  </div>
                  <div class="fontSize titleLogo white fontSize marginTop1">
                    <img class="titleIcon" :src="myData.img" alt="" />
                    你好 ,
                    {{ myData.name }}
                  </div>
                  <div
                    class="personal white fontSize"
                    style="
                      display: inline-block;
                      padding: 0 1rem;
                      font-size: 1.6rem;
                      color: #37a5dd;
                    "
                  >
                    {{ myData.positionName }}
                  </div>
                  <div
                    class="personal white fontSize"
                    style="width: 18.5rem; font-size: 1.6rem; color: #37a5dd;"
                  >
                    手机号: {{ myData.mobile }}
                  </div>
                </div>
              </div>
              <div
                @click="seeMore"
                class="col-flex-1 PendingWorkOrder"
                style="height: 13.92rem; width: 23.4rem; cursor: pointer;"
              >
                <div class="pendingWorkBox">
                  <img src="../../assets/WorkbenchIcon/icon9.png" alt="" />
                </div>
                <div class="pendingWorkNum">
                  <div>{{ myData.soIntegerSimpleGraphUnit[0].value }}</div>
                  <div>待分配工单</div>
                </div>
              </div>
            </div>
            <div class="disFlexlevel col-flex-1 border-Radius">
              <div
                class="col-flex-1"
                ref="distribute"
                style="height: 17.9rem; width: 29.1rem;"
              ></div>
              <div
                class="col-flex-1"
                ref="Inspection"
                style="height: 17.9rem; width: 29.1rem;"
              ></div>
            </div>
          </div>
          <div class="col-flex-4 marginTop1 disFlexlevel">
            <div
              class="width49 bgc2 border-Radius disFlexUpAndDown"
              style="height: 35.8rem; width: 59.4rem;"
            >
              <!-- <div class="white fontSize paddingTl">工单任务</div> -->
              <div class="col-flex-1" ref="workOrderone"></div>
            </div>
            <div
              class="width49 bgc2 border-Radius disFlexUpAndDown"
              style="height: 35.8rem; width: 59.4rem;"
            >
              <!-- <div class="white fontSize paddingTl">巡检任务</div> -->
              <div class="col-flex-1" ref="InspectionTask"></div>
            </div>
          </div>
          <div
            class="bgc3 col-flex-4 marginTop1 border-Radius disFlexUpAndDown"
            style="box-sizing: border-box; width: 120.4rem; height: 35.841rem;"
          >
            <div class="white fontSize paddingB disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">完成量统计</div>
              <div class="myTabBox">
                <div
                  :class="box1Choosen === 1 ? 'tabGreen' : 'tabGrey'"
                  @click="
                    box1Choosen = 1
                    changeLineChart(1)
                  "
                >
                  本周
                </div>
                <div
                  :class="box1Choosen === 2 ? 'tabGreen' : 'tabGrey'"
                  @click="
                    box1Choosen = 2
                    changeLineChart(2)
                  "
                >
                  本月
                </div>
                <div
                  :class="box1Choosen === 3 ? 'tabGreen' : 'tabGrey'"
                  @click="
                    box1Choosen = 3
                    changeLineChart(3)
                  "
                >
                  本年
                </div>
              </div>
            </div>
            <div class="col-flex-1" ref="Finish"></div>
          </div>
        </div>
        <!-- 左边盒子 end -->
        <!-- 右边盒子 star -->
        <div
          class="right-box border-Radius disFlexUpAndDown"
          style="width: 29%;"
        >
          <div class="height10 bgc1 disFlexUpAndDown">
            <div class="white fontSize paddingB disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">告警信息</div>
              <div class="globalcolor fontSize1" @click="seeMoreWarning">
                查看更多
              </div>
            </div>
            <div class="col-flex-1 globalPadding">
              <div class="disFlexlevel bgc4 tab" style="text-align: center;">
                <div
                  class="white fontSize1"
                  style="
                    width: 15.5rem;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.85);
                    fontsize: 1.4rem;
                  "
                >
                  对象
                </div>
                <div
                  class="white fontSize1"
                  style="
                    width: 14rem;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.85);
                    fontsize: 1.4rem;
                  "
                >
                  告警信息
                </div>
                <div
                  class="white fontSize1"
                  style="
                    width: 20.2rem;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.85);
                    fontsize: 1.4rem;
                  "
                >
                  时间
                </div>
              </div>

              <div
                class="disFlexlevel tab ellipsis"
                style="text-align: center;"
              >
                <table class="table fontSize1 white" border="1">
                  <tr v-for="(item, index) in warningData" :key="index">
                    <th>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.warningObject"
                        placement="top"
                      >
                        <div style="width: 13.9rem;">
                          {{ item.warningObject }}
                        </div>
                      </el-tooltip>
                    </th>
                    <th>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.warningInfo"
                        placement="top"
                      >
                        <div style="width: 12.5rem;">
                          {{ item.warningInfo }}
                        </div>
                      </el-tooltip>
                      <!-- <div style="width:12.5rem">{{ item.warningInfo }}</div> -->
                    </th>
                    <th>
                      <div style="width: 18.2rem;">{{ item.createTime }}</div>
                    </th>
                  </tr>
                  <!-- <tr>
                  <th>2号假数据</th>
                  <th>过载</th>
                  <th>2021.11.29. 08:22</th>
                </tr>
                <tr>
                  <th>3号假数据</th>
                  <th>重载</th>
                  <th>2021.11.29. 08:22</th>
                </tr>
                <tr>
                  <th>4号假数据</th>
                  <th>过载</th>
                  <th>2021.11.29. 08:22</th>
                </tr> -->
                </table>
              </div>
            </div>
          </div>
          <div
            class="height11 bgc1"
            style="display: flex; flex-direction: column;"
          >
            <div class="white fontSize paddingB disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">工程师排名</div>
              <div class="myTabBox2">
                <div
                  :class="box2Choosen === 1 ? 'tabGreen' : 'tabGrey'"
                  @click="box2Choosen = 1"
                >
                  本周
                </div>
                <div
                  :class="box2Choosen === 2 ? 'tabGreen' : 'tabGrey'"
                  @click="box2Choosen = 2"
                >
                  本月
                </div>
              </div>
            </div>
            <div class="col-flex-1 globalPadding">
              <div class="disFlexlevel bgc4 tab" style="text-align: center;">
                <div
                  class="white fontSize1"
                  style="
                    width: 6.9rem;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.85);
                    fontsize: 1.4rem;
                  "
                >
                  排名
                </div>
                <div
                  class="white fontSize1"
                  style="
                    width: 12.5rem;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.85);
                    fontsize: 1.4rem;
                  "
                >
                  名称
                </div>
                <div
                  class="white fontSize1"
                  style="
                    width: 15.5rem;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.85);
                    fontsize: 1.4rem;
                  "
                >
                  工单量
                </div>
                <div
                  class="white fontSize1"
                  style="
                    width: 15.2rem;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.85);
                    fontsize: 1.4rem;
                  "
                >
                  巡检数
                </div>
              </div>
              <div class="disFlexlevel tab" style="text-align: center;">
                <table class="table fontSize1 white" border="1">
                  <tr
                    v-show="box2Choosen === 1 && index < 5"
                    v-for="(item, index) in myData.thisWeekSoPlanCounts"
                    :key="'a' + index"
                  >
                    <th style="width: 6.9rem;">{{ index + 1 }}</th>
                    <th style="width: 12.5rem;">{{ item.name }}</th>
                    <th style="width: 15.5rem;">{{ item.soCount }}</th>
                    <th style="width: 15.2rem;">{{ item.planCount }}</th>
                  </tr>

                  <tr
                    v-show="box2Choosen === 2 && index < 5"
                    v-for="(item, index) in myData.thisMonthSoPlanCounts"
                    :key="'b' + index"
                  >
                    <th style="width: 6.9rem;">{{ index + 1 }}</th>
                    <th style="width: 12.5rem;">{{ item.name }}</th>
                    <th style="width: 15.5rem;">{{ item.soCount }}</th>
                    <th style="width: 15.2rem;">{{ item.planCount }}</th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="height4 bgc1 disFlexUpAndDown">
            <div class="white fontSize paddingB disFlexlevel">
              <div style="color: #37a5dd; font-size: 1.6rem;">运行监测</div>
              <div class="globalcolor fontSize1" @click="seeMoreRunning">
                查看更多
              </div>
            </div>
            <div class="col-flex-1" style="overflow-x: hidden;">
              <div
                class="paddingC RunDetect disFlexlevel"
                style="width: 114%; height: 100%;"
              >
                <div
                  v-for="(item, index) in monitoringData"
                  :key="index"
                  class="width31 card"
                >
                  <div class="room">{{ item.name }}</div>
                  <div class="col-flex-1 roomList">
                    <div class="iconBox">
                      <img
                        src="../../assets/WorkbenchIcon/bigyuan.png"
                        alt=""
                      />
                    </div>
                    <div class="roomInfo">
                      <div
                        class="col-flex-1"
                        style="display: flex; justify-content: space-between;"
                      >
                        <div class="rommCode">
                          <div class="Dots DotsBgc1"></div>
                          <span>正常</span>
                        </div>
                        <div class="white fontSize1">
                          {{ item.normalCount }}
                        </div>
                      </div>
                    </div>
                    <div class="roomInfo">
                      <div
                        class="col-flex-1"
                        style="display: flex; justify-content: space-between;"
                      >
                        <div class="rommCode">
                          <div class="Dots DotsBgc2"></div>
                          <span>警告</span>
                        </div>
                        <div class="white fontSize1">
                          {{ item.warningCount }}
                        </div>
                      </div>
                    </div>
                    <div class="roomInfo">
                      <div
                        class="col-flex-1"
                        style="display: flex; justify-content: space-between;"
                      >
                        <div class="rommCode">
                          <div class="Dots DotsBgc3"></div>
                          <span>异常</span>
                        </div>
                        <div class="white fontSize1">
                          {{ item.abnormalCount }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="width31 card">
                <div class="room">配电房</div>
                <div class="col-flex-1 roomList">
                  <div class="iconBox">
                    <img src="../../assets/WorkbenchIcon/bigyuan.png" alt="" />
                  </div>
                  <div class="roomInfo">
                    <div
                      class="col-flex-1"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="rommCode">
                        <div class="Dots DotsBgc1"></div>
                        <span>正常</span>
                      </div>
                      <div class="white fontSize1">20</div>
                    </div>
                  </div>
                  <div class="roomInfo">
                    <div
                      class="col-flex-1"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="rommCode">
                        <div class="Dots DotsBgc2"></div>
                        <span>警告</span>
                      </div>
                      <div class="white fontSize1">20</div>
                    </div>
                  </div>
                  <div class="roomInfo">
                    <div
                      class="col-flex-1"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="rommCode">
                        <div class="Dots DotsBgc3"></div>
                        <span>异常</span>
                      </div>
                      <div class="white fontSize1">20</div>
                    </div>
                  </div>
                </div>
              </div> -->
                <div
                  class="width31 card"
                  @click="seeMoreRunning"
                  id="myNewCard"
                  style="
                    width: 9.8rem;
                    background-color: #0e2c47;
                    margin-right: 8.2rem;
                    color: rgba(255, 255, 255, 0.65);
                    font-size: 3rem;
                    line-height: 24rem;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 0.2rem;
                  "
                >
                  ···
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边盒子 end -->
      </div>
      <!-- <div class="yooo" style="width: 100%; height: 25rem;color: grey;font-size: 1.4rem;text-align: center;line-height: 10rem;">由光谷信息股份有限公司提供技术支持</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkBench',
  data() {
    return {
      iconPath: require('../../assets/WorkbenchIcon/titleIcon.png'),
      index: 0,
      // 本页的详细数据
      myData: {
        img: '',
        mobile: '',
        name: '',
        positionName: '',
        soIntegerSimpleGraphUnit: [
          { name: '待分配', value: 0 },
          { name: '待处理', value: 0 },
          { name: '处理中', value: 0 },
          { name: '已暂停', value: 0 },
        ],
        planIntegerSimpleGraphUnit: [
          { name: '待巡检', value: 0 },
          { name: '巡检中', value: 0 },
          { name: '已完成', value: 0 },
        ],
        thisWeekSoPlanCounts: [{ name: '无数据', soCount: 0, planCount: 0 }],
        thisMonthSoPlanCounts: [{ name: '无数据', soCount: 0, planCount: 0 }],
      },
      // 本周本月本年的选择tab
      box1Choosen: 1,
      // 本周本月的选择tab
      box2Choosen: 1,

      // 完成量统计图数据############################################################################
      optionCompleted: {
        // backgroundColor: "#232d36",//统计图的背景颜色
        color: ['#5AD8A6', '#5B8FF9'],
        legend: {
          icon: 'rect',
          itemWidth: 12,
          itemHeight: 2,
          textStyle: {
            color: '#FFFFFF  ',
          },
        },
        grid: {
          top: '10%',
          left: '5%',
          right: '3%',
          bottom: '15%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',

            lineStyle: {
              type: 'solid',
              color: {
                type: 'linear ',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,255,255,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255,255,255,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,255,255,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },

        // x轴的数据
        xAxis: [
          {
            type: 'category',
            axisTick: {
              //y轴刻度线
              show: true,
              lineStyle: {
                color: '#005AB1',
                width: 1,
                height: 20,
              },
            },
            axisLine: {
              //x轴的样式修改
              lineStyle: {
                color: '#164878',
              },
            },
            axisLabel: {
              color: '#FFFFFF',
              width: 200,
              interval: 0,
              textStyle: {
                fontWeight: '1',
                // fontSize:14,
                color: 'rgba(255,255,255,0.85)',
              },
            },
            boundaryGap: false,
            data: ['暂无数据'],
          },
        ],

        yAxis: [
          {
            type: 'value',
            // 步长标记
            minInterval: 1,
            min: 0,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#164878',
                opacity: 1,
              },
            },
            axisLabel: {
              show: true,
              margin: 20,
              textStyle: {
                color: '#fff',
              },
            },
          },
        ],
        series: [
          {
            name: '已完成工单',
            type: 'line',
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: '#5B8FF9',
              },
            },
            itemStyle: {
              color: '#5B8FF9',
              borderWidth: 2,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(36, 67, 131, 1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(12, 47, 79, 0)',
                  },
                ]),
              },
            },
            data: [0], //data.values
          },
          {
            name: '已完成巡检',
            type: 'line',
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: '#5AD8A6',
              },
            },
            itemStyle: {
              color: '#5AD8A6',
              borderWidth: 2,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(90, 216, 166, 1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(12, 47, 79, 0)',
                  },
                ]),
              },
            },
            data: [0], //data.values
          },
        ],
      },
      // 工单任务数据##############################################################################
      optionWorkOrder: {
        color: ['#5B8FF9', '#FFA82C', '#59CB74'],
        title: {
          text: '工单任务',
          textStyle: {
            color: '#37A5DD',
            fontSize: 16,
            fontWeight: 400,
          },
          top: '5%',
          left: '2%',
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: [
          {
            right: '2%',
            top: '5%',
            icon: 'react',
            itemWidth: 10,
            itemHeight: 8,
            textStyle: {
              color: '#FFFFFF',
            },
            data: ['待处理', '处理中', '已处理'],
          },
        ],
        grid: {
          left: '5%',
          right: '5%',
          bottom: '6%',
          top: '20%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false }, //去除x轴的刻度点
            axisLine: {
              lineStyle: {
                color: '#FFFFFF',
              },
            },
            axisLine: {
              //x轴的样式修改
              show: false,
              lineStyle: {
                color: '#FFFFFF',
              },
            },
            axisLabel: {
              interval: 0,
              // rotate:45,
              textStyle: {
                fontWeight: '1',
                fontSize: 14,
                color: 'rgba(255,255,255,0.85)',
              },
            },
            data: ['暂无数据'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            // 步长标记
            minInterval: 1,
            min: 0,
            splitLine: {
              lineStyle: {
                color: '#164878',
              },
            },
            axisLine: {
              //x轴的样式修改
              show: false,
              lineStyle: {
                color: '#FFFFFF',
              },
            },
          },
        ],
        series: [
          {
            type: 'bar',
            name: '待处理',
            barWidth: 12, //柱图宽度
            data: [0],
          },
          {
            type: 'bar',
            name: '处理中',
            barWidth: 12, //柱图宽度"待处理", "处理中", "已处理"
            data: [0],
          },
          {
            type: 'bar',
            barWidth: 12, //柱图宽度
            name: '已处理',
            data: [0],
          },
        ],
        dataZoom: [],
      },

      // 巡检任务数据##############################################################################
      optionTask: {
        color: ['#FF6565', '#5AD8A6', '#FFC500'],
        title: {
          text: '巡检任务',
          textStyle: {
            color: '#37A5DD',
            fontSize: 16,
            fontWeight: 400,
          },
          top: '5%',
          left: '2%',
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: [
          {
            icon: 'react',
            itemWidth: 9,
            itemHeight: 9,
            right: '2%',
            top: '5%',
            textStyle: {
              color: '#FFFFFF',
            },
            data: ['待巡检', '巡检中', '已完成'],
          },
        ],
        grid: {
          left: '5%',
          right: '5%',
          bottom: '6%',
          top: '18%',
          containLabel: true,
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,
              textStyle: {
                fontWeight: '1',
                fontSize: 14,
                color: 'rgba(255,255,255,0.85)',
              },
            },
            type: 'category',
            axisTick: { show: false }, //去除x轴的刻度点
            axisLine: {
              show: false,
              lineStyle: {
                color: '#FFFFFF',
              },
            },

            data: ['暂无数据'],
          },
        ],
        yAxis: [
          {
            type: 'value',
            // 步长标记
            minInterval: 1,
            min: 0,
            axisLine: {
              //x轴的样式修改
              lineStyle: {
                color: '#FFFFFF',
              },
            },
            splitLine: {
              lineStyle: {
                color: '#164878',
              },
            },
          },
        ],
        series: [
          {
            type: 'bar',
            name: '待巡检',
            barWidth: 12, //柱图宽度
            data: [0],
          },
          {
            type: 'bar',
            name: '巡检中',
            barWidth: 12, //柱图宽度"待处理", "处理中", "已完成"
            data: [0],
          },
          {
            type: 'bar',
            barWidth: 12, //柱图宽度
            name: '已完成',
            data: [0],
          },
        ],
        dataZoom: [],
      },

      // 累计工单数据##############################################################################
      optionDistribute: {
        tooltip: {
          show: false,
        },
        legend: {
          icon: 'circle', //形状  类型包括 circle，rect,line，roundRect，triangle，diamond，pin，arrow，none
          itemWidth: 15, // 设置宽度
          itemHeight: 8, // 设置高度
          itemGap: 10, // 设置间距
          align: 'left',
          show: true,
          x: 'right',
          y: ' center',
          padding: [50, 50, 0, 0],
          orient: 'vertical',
          data: ['待分配', '待处理', '处理中', '已暂停'],
          textStyle: {
            //文字样式
            color: '#fff',
            fontSize: '14',
          },
        },
        color: ['#F6BD16', '#5B8FF9', '#5AD8A6', '#5D7092'],
        series: [
          {
            type: 'pie',
            radius: ['50%', '65%'],
            center: ['30%', '50%'],

            label: {
              show: true,
              normal: {
                show: true,
                position: 'inner',
                formatter: '{c}',
                textStyle: {
                  color: '#FFFFFF',
                  fontSize: 14,
                },
              },
              emphasis: {
                //中间文字显示
                show: true,
              },
            },
            // 饼图1数据
            data: [
              { name: '待分配', value: 0 },
              { name: '待处理', value: 0 },
              { name: '处理中', value: 0 },
              { name: '已暂停', value: 0 },
            ],
            labelLine: {
              show: false,
            },
          },
          {
            name: '外边框',
            type: 'pie',
            center: ['30%', '50%'],
            radius: ['80%', '80%'],
            color: '#0C2F4F',
            clockWise: false, //顺时加载
            hoverAnimation: false, //鼠标移入变大
            label: {
              show: true,
              normal: {
                show: true,
                position: 'center',
                formatter: '累计工单',
                textStyle: {
                  color: '#FFFFFF',
                  fontSize: this.fontSize(1.6),
                },
              },
              emphasis: {
                //中间文字显示
                show: true,
              },
            },
            data: [
              {
                value: 5,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: '#076b91',
                  },
                },
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    borderColor: '#6d8295',
                  },
                },
              },
              {
                value: 5,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: '#076b91',
                  },
                },
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    borderColor: '#6d8295',
                  },
                },
              },
              {
                value: 5,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: '#076b91',
                  },
                },
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    borderColor: '#6d8295',
                  },
                },
              },
            ],
          },
          {
            name: '外边框',
            type: 'pie',
            clockWise: false, //顺时加载
            hoverAnimation: false, //鼠标移入变大
            center: ['30%', '50%'],
            radius: ['97%', '98%'],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 10,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: '#094368',
                  },
                },
              },
            ],
          },
        ],
      },

      // 累计巡检数据#############################################################################
      optionInspection: {
        tooltip: {
          show: false,
        },
        legend: {
          icon: 'circle', //形状  类型包括 circle，rect,line，roundRect，triangle，diamond，pin，arrow，none
          itemWidth: 15, // 设置宽度
          itemHeight: 8, // 设置高度
          itemGap: 10, // 设置间距
          align: 'left',
          show: true,
          x: 'right',
          y: ' center',
          padding: [65, 50, 0, 0],
          orient: 'vertical',
          data: ['待巡检', '巡检中', '已完成'],
          textStyle: {
            //文字样式
            color: '#fff',
            fontSize: '14',
          },
        },
        color: ['#FF6565', '#5AD8A6', '#FFC500'],
        series: [
          {
            type: 'pie',
            radius: ['50%', '65%'],
            center: ['30%', '50%'],

            label: {
              show: true,
              normal: {
                show: true,
                position: 'inner',
                formatter: '{c}',
                textStyle: {
                  color: '#FFFFFF',
                  fontSize: 14,
                },
              },
              emphasis: {
                //中间文字显示
                show: true,
              },
            },
            data: [
              //数据的值
              { value: 0, name: '待巡检' },
              { value: 0, name: '巡检中' },
              { value: 0, name: '已完成' },
            ],
            labelLine: {
              show: false,
            },
          },
          {
            name: '外边框',
            type: 'pie',
            center: ['30%', '50%'],
            radius: ['80%', '80%'],
            color: '#0C2F4F',
            clockWise: false, //顺时加载
            hoverAnimation: false, //鼠标移入变大
            label: {
              show: true,
              normal: {
                show: true,
                position: 'center',
                formatter: '累计巡检',
                textStyle: {
                  color: '#FFFFFF',
                  fontSize: this.fontSize(1.6),
                },
              },
              emphasis: {
                //中间文字显示
                show: true,
              },
            },
            data: [
              {
                value: 5,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: '#076b91',
                  },
                },
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    borderColor: '#6d8295',
                  },
                },
              },
              {
                value: 5,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: '#076b91',
                  },
                },
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    borderColor: '#6d8295',
                  },
                },
              },
              {
                value: 5,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: '#076b91',
                  },
                },
              },
              {
                value: 2,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 5,
                    borderColor: '#6d8295',
                  },
                },
              },
            ],
          },
          {
            name: '外边框',
            type: 'pie',
            clockWise: false, //顺时加载
            hoverAnimation: false, //鼠标移入变大
            center: ['30%', '50%'],
            radius: ['97%', '98%'],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 10,
                name: '',
                itemStyle: {
                  normal: {
                    borderWidth: 1,
                    borderColor: '#094368',
                  },
                },
              },
            ],
          },
        ],
      },

      // 告警信息################################################################################
      warningData: [],

      // 运行监测################################################################################
      monitoringData: [],
    }
  },
  created() {
    this.$store.dispatch('getWorkBenchData').then(() => {
      this.myData = this.$store.state.login.workBenchData.data

      // 渲染饼图1
      this.optionDistribute.series[0].data = this.myData.soIntegerSimpleGraphUnit.slice(
        0,
        4,
      )

      // 渲染饼图2
      this.optionInspection.series[0].data = this.myData.planIntegerSimpleGraphUnit.slice(
        0,
        3,
      )

      // 渲染工单任务柱状图(x轴，待处理，处理中，已处理)
      const ecahrtsData1 = [[], [], [], []]
      for (let i = 0; i < this.myData.soIntegerGraphUnit.length; i++) {
        ecahrtsData1[0].push(this.myData.soIntegerGraphUnit[i].name)
        ecahrtsData1[1].push(this.myData.soIntegerGraphUnit[i].data[0])
        ecahrtsData1[2].push(this.myData.soIntegerGraphUnit[i].data[1])
        ecahrtsData1[3].push(this.myData.soIntegerGraphUnit[i].data[2])
      }
      this.optionWorkOrder.xAxis[0].data = ecahrtsData1[0]
      this.optionWorkOrder.series[0].data = ecahrtsData1[1]
      this.optionWorkOrder.series[1].data = ecahrtsData1[2]
      this.optionWorkOrder.series[2].data = ecahrtsData1[3]

      // 渲染巡检任务柱状图
      const ecahrtsData2 = [[], [], [], []]
      for (let i = 0; i < this.myData.planIntegerGraphUnit.length; i++) {
        ecahrtsData2[0].push(this.myData.planIntegerGraphUnit[i].name)
        ecahrtsData2[1].push(this.myData.planIntegerGraphUnit[i].data[0])
        ecahrtsData2[2].push(this.myData.planIntegerGraphUnit[i].data[1])
        ecahrtsData2[3].push(this.myData.planIntegerGraphUnit[i].data[2])
      }
      this.optionTask.xAxis[0].data = ecahrtsData2[0]
      this.optionTask.series[0].data = ecahrtsData2[1]
      this.optionTask.series[1].data = ecahrtsData2[2]
      this.optionTask.series[2].data = ecahrtsData2[3]

      if (ecahrtsData1[0].length > 10) {
        this.optionWorkOrder.dataZoom = {
          type: 'slider',
          show: true,
          start: 0,
          end: 50,
          xAxisIndex: [0],
          bottom: this.fontSize(-2.3),
          showDetail: false,
        }
        this.optionTask.dataZoom = {
          type: 'slider',
          show: true,
          start: 0,
          end: 50,
          xAxisIndex: [0],
          bottom: this.fontSize(-2.3),
          showDetail: false,
        }
      }

      // 渲染完成量统计的折线图(本周)
      const ecahrtsData3 = [[], [], []]
      for (
        let i = 0;
        i < this.myData.thisWeekSoPlanIntegerGraphUnit.length;
        i++
      ) {
        ecahrtsData3[0].push(this.myData.thisWeekSoPlanIntegerGraphUnit[i].name)
        ecahrtsData3[1].push(
          this.myData.thisWeekSoPlanIntegerGraphUnit[i].data[0],
        )
        ecahrtsData3[2].push(
          this.myData.thisWeekSoPlanIntegerGraphUnit[i].data[1],
        )
      }
      this.optionCompleted.xAxis[0].data = ecahrtsData3[0]
      this.optionCompleted.series[0].data = ecahrtsData3[1]
      this.optionCompleted.series[1].data = ecahrtsData3[2]

      // 进行图表的初始化和渲染
      this.completed()
      this.workOrder()
      this.InspectionTask()
      this.distribute()
      this.Inspection()
    })

    // 告警信息
    let value = {
      pageNum: 1,
      pageSize: 3,
      warningType: '',
      warningLevel: '',
      warningStatus: '',
      beginTime: '',
      endTime: '',
    }
    this.$store.dispatch('getWarningInfoList', value).then(() => {
      // console.log(this.$store.state.system.getWarningInfoList);
      if (this.$store.state.system.getWarningInfoList.errorCode == 0) {
        this.warningData = this.$store.state.system.getWarningInfoList.data.records
      } else {
        this.warningData = []
      }
    })

    // 运行监测
    this.$store
      .dispatch('operationMonitoringData', { pageNum: 1, pageSize: 2 })
      .then(() => {
        if (this.$store.state.system.operationMonitoringData.errorCode == 0) {
          this.monitoringData = this.$store.state.system.operationMonitoringData.data.records
        } else {
          this.monitoringData = []
        }
      })
  },
  mounted() {},

  methods: {
    SelectedOn(idx) {
      this.index = idx
      console.log(this.$refs.moveOn)
    },
    // 折线图（完成量统计）
    completed() {
      var myChartCompleted = this.$echarts.init(this.$refs.Finish)
      myChartCompleted.setOption(this.optionCompleted)
      myChartCompleted._dom.childNodes[0].childNodes[0].style.height = '30.8rem'
      myChartCompleted._dom.childNodes[0].childNodes[0].style.width = '120.4rem'
    },
    // 柱状图(工单任务)
    workOrder() {
      var myChartWorkOrder = this.$echarts.init(this.$refs.workOrderone)
      myChartWorkOrder.setOption(this.optionWorkOrder)
      myChartWorkOrder._dom.childNodes[0].childNodes[0].style.height = '35.8rem'
      myChartWorkOrder._dom.childNodes[0].childNodes[0].style.width = '59.4rem'
    },
    // 柱状图（巡检任务）
    InspectionTask() {
      var myChartTask = this.$echarts.init(this.$refs.InspectionTask)
      myChartTask.setOption(this.optionTask)
      myChartTask._dom.childNodes[0].childNodes[0].style.height = '35.8rem'
      myChartTask._dom.childNodes[0].childNodes[0].style.width = '59.4rem'
    },
    // 饼状图（累计工单）
    distribute() {
      var mychartDistribute = this.$echarts.init(this.$refs.distribute)
      mychartDistribute.setOption(this.optionDistribute)
      mychartDistribute._dom.childNodes[0].childNodes[0].style.height =
        '17.9rem'
      mychartDistribute._dom.childNodes[0].childNodes[0].style.width = '29.1rem'
    },
    // 饼状图（累计巡检）
    Inspection() {
      var mychartInspection = this.$echarts.init(this.$refs.Inspection)
      mychartInspection.setOption(this.optionInspection)
      mychartInspection._dom.childNodes[0].childNodes[0].style.height =
        '17.9rem'
      mychartInspection._dom.childNodes[0].childNodes[0].style.width = '29.1rem'
    },
    // 获取屏幕比例，使echarts自适应
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
      if (!clientWidth) return
      let fontSize = clientWidth / 192
      return res * fontSize
    },

    // 切换到完成量统计(本周，本月，本年)
    changeLineChart(val) {
      if (val === 1) {
        // 渲染完成量统计的折线图(本周)
        const ecahrtsData3 = [[], [], []]
        for (
          let i = 0;
          i < this.myData.thisWeekSoPlanIntegerGraphUnit.length;
          i++
        ) {
          ecahrtsData3[0].push(
            this.myData.thisWeekSoPlanIntegerGraphUnit[i].name,
          )
          ecahrtsData3[1].push(
            this.myData.thisWeekSoPlanIntegerGraphUnit[i].data[0],
          )
          ecahrtsData3[2].push(
            this.myData.thisWeekSoPlanIntegerGraphUnit[i].data[1],
          )
        }
        this.optionCompleted.xAxis[0].data = ecahrtsData3[0]
        this.optionCompleted.series[0].data = ecahrtsData3[1]
        this.optionCompleted.series[1].data = ecahrtsData3[2]
        this.optionCompleted.xAxis[0].axisLabel.interval = 0
        this.completed()
      } else if (val === 2) {
        // 渲染完成量统计的折线图(本月)
        const ecahrtsData3 = [[], [], []]
        console.log(this.myData.thisMonthSoPlanIntegerGraphUnit)
        for (
          let i = 0;
          i < this.myData.thisMonthSoPlanIntegerGraphUnit.length;
          i++
        ) {
          ecahrtsData3[0].push(
            this.myData.thisMonthSoPlanIntegerGraphUnit[i].name,
          )
          ecahrtsData3[1].push(
            this.myData.thisMonthSoPlanIntegerGraphUnit[i].data[0],
          )
          ecahrtsData3[2].push(
            this.myData.thisMonthSoPlanIntegerGraphUnit[i].data[1],
          )
        }
        this.optionCompleted.xAxis[0].data = ecahrtsData3[0]
        this.optionCompleted.series[0].data = ecahrtsData3[1]
        this.optionCompleted.series[1].data = ecahrtsData3[2]
        this.optionCompleted.xAxis[0].axisLabel.interval = 2
        this.completed()
      } else if (val === 3) {
        // 渲染完成量统计的折线图(本年)
        const ecahrtsData3 = [[], [], []]
        for (
          let i = 0;
          i < this.myData.thisYearSoPlanIntegerGraphUnit.length;
          i++
        ) {
          ecahrtsData3[0].push(
            this.myData.thisYearSoPlanIntegerGraphUnit[i].name,
          )
          ecahrtsData3[1].push(
            this.myData.thisYearSoPlanIntegerGraphUnit[i].data[0],
          )
          ecahrtsData3[2].push(
            this.myData.thisYearSoPlanIntegerGraphUnit[i].data[1],
          )
        }
        this.optionCompleted.xAxis[0].data = ecahrtsData3[0]
        this.optionCompleted.series[0].data = ecahrtsData3[1]
        this.optionCompleted.series[1].data = ecahrtsData3[2]
        this.optionCompleted.xAxis[0].axisLabel.interval = 0
        this.completed()
      }
    },

    // 产看更多告警信息
    seeMoreWarning() {
      this.$router.push('system/alarm')
      this.$bus.$emit('goToTheMenu', 40)
    },
    // 查看更多运行监测数据
    seeMoreRunning() {
      this.$router.push('monitoring')
      this.$bus.$emit('goToTheMenu', 9)
    },
    // 查看待分配工单
    seeMore() {
      this.$router.push('repair/orderManagement')
      this.$bus.$emit('goToTheMenu', 25)
    },
  },
}
</script>

<style lang="less" scoped>
// img{
//   width: 171.2rem;
//   height: 96.8rem;
//   position: absolute;
//   top: 0;
//   left: 0;
// }
.container {
  // width: 172rem;
  // height: 94.5rem;
  display: flex;
  justify-content: space-between;
  // background-color: #17232e !important;
  box-sizing: border-box;
}
// 公共样式star

.col-flex-1 {
  flex: 1;
}
.col-flex-2 {
  flex: 2;
}
.col-flex-3 {
  flex: 3;
}
.col-flex-4 {
  flex: 4;
}
.col-flex-5 {
  flex: 5;
}
.col-flex-6 {
  flex: 6;
}
.col-flex-7 {
  flex: 7;
}
.col-flex-8 {
  flex: 8;
}
.col-flex-9 {
  flex: 9;
}
.col-flex-10 {
  flex: 10;
}
.marginTop1 {
  margin-top: 1.6rem;
}
.marginLeft1 {
  margin-right: 1.6rem;
}
.paddingTl {
  padding: 2rem 0 0 2.4rem;
}
.paddingB {
  padding: 2rem 2.4rem 0 2.4rem;
}
.paddingC {
  padding: 2.9rem 2.4rem 4.9rem 2.4rem;
}
.globalPadding {
  padding: 2rem 2.4rem;
}
.border {
  border: 0.1rem solid #005ab1;
}
.globalcolor {
  color: #37a5dd;
  cursor: pointer;
}
.bgc1 {
  background-color: rgba(12, 47, 79, 1) !important;
}
.bgc2 {
  background-color: rgba(12, 47, 79, 1);
}
.bgc3 {
  background-color: rgba(12, 47, 79, 1);
}
.bgc4 {
  background: #005ab1 !important;
}
.disFlexUpAndDown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.height {
  height: 30%;
}
.height10 {
  height: 27%;
}
.height11 {
  height: 33%;
}
.height4 {
  height: 37%;
}
.disFlexlevel {
  display: flex;
  justify-content: space-between;
}
.fontSize {
  font-size: 1.6rem;
}
.fontSize1 {
  font-size: 1.4rem;
}
.width49 {
  width: 49.3%;
}
.width31 {
  width: 31%;
  height: 100%;
}
.border-Radius {
  border-radius: 0.4rem;
}
.white {
  color: #ffffff;
}
.titleIcon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 0.4rem;
}
.titleLogo {
  display: flex;
  align-items: center;
}
// 公共样式end
.left-box {
  // padding-right: 1.6rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BasicInfo {
  background: url('../../assets/WorkbenchIcon/basicIcon.png') left -23rem top 9.5rem
    no-repeat;
  background-size: 109.4rem 14.6rem;
  padding: 2rem;
  box-sizing: border-box;
}
.personal {
  background: rgba(23, 68, 109, 1);
  text-align: center;
  border-radius: 1.4rem;
  margin-top: 1.9rem;
  height: 2.8rem;
  line-height: 2.8rem;
}
.j-echarts {
  width: 100%;
  height: 100%;
}
.timeBox {
  width: 18rem;
  height: 3rem;
  align-items: center;
  text-align: center;
  div {
    flex: 1;
    background-color: rgba(0, 115, 222, 0.1);
    border: 0.1rem solid rgba(0, 115, 222, 0.1);
    cursor: pointer;
  }
}
.on {
  background-color: #027969 !important;
  border: 0.1rem solid #027969 !important;
}
.tab {
  height: 3.5rem;
  line-height: 3.5rem;
}
.table {
  margin: 0;
  width: 100%;
  height: 100%;
  border: 0.1rem solid #005ab1;
  border-collapse: collapse; /* 为table设置这个属性 */
  tr {
    border: 0.1rem solid #005ab1;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    th {
      border: 0.1rem solid #005ab1;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.RunDetect {
  box-sizing: border-box;
}
.card {
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  .room {
    height: 2.4rem;
    line-height: 2.4rem;
    color: #fff;
    font-size: 1.4rem;
    padding-left: 0.9rem;
    background: linear-gradient(180deg, #005ab1 0%, #0d73d5 100%) !important;
  }
  .roomList {
    background: rgba(15, 36, 55, 0.35);
    padding: 1.6rem;
    box-sizing: border-box;
    &:hover {
      background: #0f2437 !important;
      cursor: pointer;
    }
  }
}
.iconBox {
  width: 5.7rem;
  height: 5.7rem;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
}
.roomInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.2rem;
}
.rommCode {
  display: flex;
  align-items: center;
  .Dots {
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 0.8rem;
    border-radius: 50%;
  }
  span {
    font-size: 1.4rem;
    color: #ffffff;
  }
}
// 背景颜色satr
.DotsBgc1 {
  background-color: #37a5dd;
}
.DotsBgc2 {
  background-color: #ffa82c;
}
.DotsBgc3 {
  background-color: #ff6565;
}
// 背景颜色end
.PendingWorkOrder {
  display: flex;
  align-items: center;
  margin: 2rem 5.7rem;
  box-sizing: border-box;
  background: rgba(0, 115, 222, 0.15) !important;
  .pendingWorkBox {
    width: 9.6rem;
    height: 7.6rem;
    margin-right: 2.421rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.pendingWorkNum {
  div:nth-child(1) {
    color: #f6bd16;
    font-family: D-DIN;
    font-style: normal;
    font-weight: bold;
    font-size: 3.8rem;
  }
  div:nth-child(2) {
    color: #fffffe;
    font-size: 1.6rem;
  }
}

#myNewCard {
  &:hover {
    background-color: #0f2437 !important;
  }
}

.myTabBox {
  height: 3rem;
  width: 18rem;
  background-color: #0b365d;
  border: 0.1rem solid #004579;
  font-size: 1.4rem;
  line-height: 3rem;

  .tabGreen {
    height: 3rem;
    width: 6rem;
    background-color: #027969;
    float: left;
    text-align: center;
    cursor: pointer;
  }
  .tabGrey {
    height: 3rem;
    width: 6rem;
    float: left;
    text-align: center;
    cursor: pointer;
  }
}

.myTabBox2 {
  height: 3rem;
  width: 12rem;
  background-color: #0b365d;
  border: 0.1rem solid #004579;
  font-size: 1.4rem;
  line-height: 3rem;

  .tabGreen {
    height: 3rem;
    width: 6rem;
    background-color: #027969;
    float: left;
    text-align: center;
    cursor: pointer;
  }
  .tabGrey {
    height: 3rem;
    width: 6rem;
    float: left;
    text-align: center;
    cursor: pointer;
  }
}

.ellipsis {
  tr {
    th > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>

<style lang="less">
.containerOuterSider {
  width: 171.2rem;
  height: 94.2rem;
  overflow: hidden;
}
</style>
