<template>
  <div class="OrderInHandMine">
    <!-- 面包屑 -->
    <div class="bread">
      运维检修 / 我的工单 / 处理中
    </div>
    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div>
        <el-input
          size="small"
          placeholder="请输入工单主题"
          v-model="searchValue"
          @keyup.enter.native="search"
          style="width: 22rem;"
          clearable
          @clear="search"
        ></el-input>

        <el-input
          size="small"
          placeholder="请输入工单编号"
          v-model="orderNo"
          @keyup.enter.native="search"
          style="width: 22rem; margin-left: 1.6rem;"
          clearable
          @clear="search"
        ></el-input>

        <el-select
          size="small"
          placeholder="工单来源"
          class="businessSelector"
          v-model="source"
          @change="selectChanged"
        >
          <el-option label="全部来源" value=" "></el-option>
          <el-option label="内部报修" value="内部报修"></el-option>
          <el-option label="巡检报修" value="巡检报修"></el-option>
          <el-option label="监测报修" value="监测报修"></el-option>
          <el-option label="预防性试验" value="预防性试验"></el-option>
        </el-select>

        <button class="btn_save" @click="search">查 询</button>
        <button class="btn_save" @click="exportExcel">导 出</button>
      </div>
      <button class="btn2" @click="ifDialogShow">新增工单</button>
    </div>

    <!-- 中部表格位置############################################################################################################ -->
    <div class="userTablePlace">
      <!-- tab栏 -->
      <div class="tabSwitcher"></div>
      <div class="table">
        <el-table
          :data="tableData"
          stripe:false
          height="57.8rem"
          border:false
          style="width: 100%;"
          :header-cell-style="headerClass"
          :cell-style="cellStyle"
        >
          <el-table-column
            class="tab"
            prop="orderNo"
            label="工单编号"
            min-width="14%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="title"
            label="主题"
            min-width="19%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="statusName"
            label="当前状态"
            min-width="15%"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div class="colorTag">
                {{ tableData[scope.$index].statusName }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            class="tab"
            prop="deptName"
            label="报修部门"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="reportUserName"
            label="建单人"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="handler"
            label="处理人"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="createTime"
            label="报修时间"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="planEndTime"
            label="计划结束时间"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="source"
            label="工单来源"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>

          <!-- 操作部分 -->
          <el-table-column label="操作" min-width="20%">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleDetail(scope.$index, scope.row)"
              >
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页器部分 -->
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalNumForPagenation"
        ></el-pagination>
      </div>
    </div>

    <!-- 弹层位置 -->
    <DialogAddOrder ref="DialogRef" />
    <DrawerForDetail ref="DrawerRef" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import DialogAddOrder from './components/DialogAddOrder.vue'
import DrawerForDetail from './components/DrawerForDetail.vue'
import { exportRepairOrder } from '@/api/repair'
// import DialogPatrolPlan from "../PatrolPlan/DialogPatrolPlan.vue"
export default {
  name: 'OrderInHandMine',
  mixins: [tableBg],
  components: { DialogAddOrder, DrawerForDetail },
  data() {
    return {
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 双向绑定的搜索数据
      searchValue: '',
      orderNo: '',
      handler: '',
      source: '',
      processingType: '',
      // 表格数据
      tableData: [],
    }
  },

  methods: {
    // 刷新页面
    refreshThePage() {
      this.$store
        .dispatch('getRepairOrderManagementPageDataMine', {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          keyword: this.searchValue,
          orderNo: this.orderNo,
          handler: this.handler,
          source: this.source,
          processingType: this.processingType,
          status: 2,
        })
        .then(() => {
          this.tableData = this.repairOrderManagementPageData
          this.totalNumForPagenation = this.repairOrderManagementTotal
          // 处理status改为中文
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].status === 0) {
              this.tableData[i].statusName = '待分配'
            } else if (this.tableData[i].status === 1) {
              this.tableData[i].statusName = '待处理'
            } else if (this.tableData[i].status === 2) {
              this.tableData[i].statusName = '处理中'
            } else if (this.tableData[i].status === 3) {
              this.tableData[i].statusName = '已完成'
            } else if (this.tableData[i].status === 4) {
              this.tableData[i].statusName = '已暂停'
            }
          }
        })
    },

    // 点击查看详情
    handleDetail(index) {
      this.$refs.DrawerRef.show(this.repairOrderManagementPageData[index], 1)
    },

    // 点击搜索或者回车
    search() {
      this.currentPage = '1'
      this.refreshThePage()
      this.$bus.$emit('getOrderManagementTabCountMine', {
        keyword: this.searchValue,
        orderNo: this.orderNo,
        source: this.source,
        handler: this.handler,
        processingType: this.processingType,
      })
    },
    selectChanged() {
      this.currentPage = '1'
      this.refreshThePage()
      this.$bus.$emit('getOrderManagementTabCountMine', {
        keyword: this.searchValue,
        orderNo: this.orderNo,
        source: this.source,
        handler: this.handler,
        processingType: this.processingType,
      })
    },
    //点击新增
    ifDialogShow() {
      this.$refs.DialogRef.show()
    },
    //点击了编辑按钮
    handleEdit(index) {
      this.$refs.DialogRef.showForEdit(this.repairPatrolTaskPageData[index].id)
    },
    // 点击了删除按钮
    handleDelete(index) {
      this.$prompt('请输入删除巡检任务的理由：', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then((reason) => {
        const value = {
          id: this.repairPatrolTaskPageData[index].id,
          remark: reason.value,
        }

        this.$store.dispatch('deleteRepairPatrolTask', value).then(() => {
          if (this.ifDeleteRepairPatrolTaskSuccess === 0) {
            this.$message({ message: '删除成功！', type: 'success' })
            this.refreshThePage()
          } else {
            this.$message({
              message: this.ifDeleteRepairPatrolTaskError,
              type: 'warning',
            })
          }
        })
      })
    },

    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = '1'
      this.pageSize = val
      this.refreshThePage()
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val
      this.refreshThePage()
    },
    // 导出
    exportExcel() {
      let params = {
        status: 2,
        keyword: this.searchValue,
        orderNo: this.orderNo,
        processingType: this.processingType,
        source: this.source,
      }
      exportRepairOrder(params).then((response) => {
        var blob = new Blob([response], {
          type: 'application/vnd.ms-excel,charset=utf-8',
        }) //创建一个blob对象
        var a = document.createElement('a') //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob) // response is a blob
        a.download = `${this.source || '全部来源'}我的工单-处理中.xls` //文件名称
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    },
  },

  mounted() {
    this.refreshThePage()
  },

  computed: {
    ...mapState({
      // 分页数据
      repairOrderManagementPageData: (state) => {
        return state.repair.repairOrderManagementPageDataMine.data.records
      },
      // 页面总数
      repairOrderManagementTotal: (state) => {
        return state.repair.repairOrderManagementPageDataMine.data.total
      },
      // 删除巡检任务是否成功
      //   ifDeleteRepairPatrolTaskSuccess:state => { return state.repair.ifDeleteRepairPatrolTaskSuccess.errorCode},
      //   ifDeleteRepairPatrolTaskError:state => { return state.repair.ifDeleteRepairPatrolTaskSuccess.message},
    }),
  },
}
</script>

<style lang="less" scoped>
// 面包屑
.bread {
  height: 5rem;
  width: 142.8rem;
  line-height: 5rem;
  color: #fff;
  font-size: 1.4rem;
  position: absolute;
  top: -2rem;
  left: 0;
}
// 顶部样式##################################################################

.topBar {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  box-sizing: border-box;
  height: 6.4rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  top: 3rem;
  left: 0;
  overflow: hidden;
  .searchInput {
    height: 3.2rem;
    width: 22.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
    background-color: #0c375e;
    border: 1px solid #004579;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    text-indent: 1.4rem;
    &:focus {
      border: 0.1rem solid #008080;
    }
  }

  button {
    height: 3.2rem;
    width: 7.4rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .btn1 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 26rem;
  }
  .btn2 {
    background-color: #027969;
    position: absolute;
    top: 1.7rem;
    left: 158.7rem;
    width: 10.2rem;
  }
  .btn_save {
    margin-left: 1.5rem;
  }
  .businessType {
    height: 3.2rem;
    width: 20.8rem;
    position: absolute;
    left: 36rem;
    top: 2.4rem;
  }
  .businessSelector {
    margin: 0 2rem;
  }
}

// 中部表格样式##################################################################

.userTablePlace {
  height: 86.4rem;
  width: 171.2rem;
  position: absolute;
  top: 10.4rem;
  left: 0;
  overflow: hidden;
  background-color: #0d3050;
  .tabSwitcher {
    height: 4rem;
    border-bottom: 0.2rem solid rgba(7, 82, 150, 0.25);
    margin: 0 2.4rem 2.4rem 2.4rem;
    .btnGreen {
      display: inline-block;
      height: 4rem;
      font-size: 1.4rem;
      color: #03d9bc;
      border-bottom: 0.2rem solid #03d9bc;
      line-height: 5rem;
      margin-right: 2.4rem;
      cursor: pointer;
    }
    .btnGrey {
      display: inline-block;
      height: 4rem;
      font-size: 1.4rem;
      color: rgba(255, 255, 255, 0.85);
      border-bottom: none;
      line-height: 5rem;
      margin-right: 2.4rem;
      cursor: pointer;
    }
  }
  .table {
    height: 57.2rem;
    width: 166.4rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}

// 颜色标签样式
.colorTag {
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(
    180deg,
    rgba(24, 144, 255, 0.2) 0%,
    rgba(24, 89, 255, 0.2) 100%
  );
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #89c6ff;
}
</style>

<style lang="less">
// 公共样式！谨防代码污染！#########################################
.OrderInHandMine {
  .table {
    height: 57.2rem;
    width: 148rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }

  // switch栏的样式设置
  .el-switch__label.is-active {
    color: #027969;
  }
  .el-switch__label {
    color: #0c375e;
  }
}
</style>
