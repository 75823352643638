<template>
  <div ref="rightDrawer" class="right-panel-container">
    <el-drawer class="drawer dialogBgc"
               size="23%"
               :visible.sync="show"
               :showClose="false"
               :append-to-body="true"
               :modal-append-to-body="false"
               :modal="false"
               style="height: 84%; top: 16%;"
    >
      <el-tabs id="edge-drawer-tab" v-model="activeName">
        <el-tab-pane label="属性" name="attribute">
          <el-form ref="form"
                   size="small"
                   label-width="80px"
                   label-position="left"
                   :data="items"
                   v-if="items != null"
          >
            <el-form-item label="名称:">
              <el-input placeholder="请输入名称" v-model="items.title" @input="changeInput"/>
            </el-form-item>
            <el-form-item label="线宽:">
              <el-input type="number" v-model="items.strokeWidth" :min="1" @input="changeInput"/>
            </el-form-item>
            <el-form-item label="颜色:" prop="color">
              <el-color-picker v-model="items.color" @change="changeSvgColor" show-alpha :predefine="predefineColors"/>
            </el-form-item>
            <el-form-item label="虚线长度:">
              <el-input type="number" v-model="items.strokeDasharray" value="0" :min="0" @input="changeInput"/>
            </el-form-item>
            <el-form-item label="箭头样式:">
              <el-select v-model="items.targetMarker" value="block" @change="changeSvg">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="箭头大小:">
              <el-input type="number"
                        v-model="items.targetMarkerSize"
                        value="6"
                        :min="0"
                        @input="changeInput"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div class="cfrm-cncl-btns" v-show="activeName == 'data'"
        style="
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%, 0%);
        "
      >
        <el-button type="primary" style="width: 200px" @click="sureSubmit"
          >确认
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { listAllArea, listData } from "@/api/diagram/area";

export default {
  name: "EdgeRightDrawer1",
  props: ["items"],
  data() {
    return {
      show: false,
      activeName: "attribute",
      color1: "#409EFF",
      openBind: false,
      //判断是否是第一个绑定
      isFirstBind: true,
      form: {
        diagramcode: null,
      },
      //状态点的数据
      dataStatus: {
        title: null,
      },
      // 控制点的数据
      dataControl: {
        title: null,
      },
      options: [{
        value: '',
        label: '无箭头'
      }, {
        value: 'block',
        label: '实心箭头'
      }, {
        value: 'classic',
        label: '经典箭头'
      }, {
        value: 'diamond',
        label: '菱形箭头'
      }, {
        value: 'cross',
        label: '交叉箭头'
      }, {
        value: 'circle',
        label: '圆形箭头'
      }, {
        value: 'circlePlus',
        label: '圆形和加号箭头'
      }, {
        value: 'ellipse',
        label: '椭圆箭头'
      }],
      gData: [
        {
          key: "所有",
          title: "所有",
          children: [
            {
              key: "901-1",
              title: "901-1",
              children: [
                {
                  key: "茶水间",
                  title: "茶水间",
                  children: [
                    {
                      key: "热水器",
                      title: "热水器",
                      children: [
                        {
                          key: "数字量DI",
                          title: "数字量DI",
                        },
                        {
                          key: "模拟量AI",
                          title: "模拟量AI",
                        },
                        {
                          key: "累积量ACC",
                          title: "累积量ACC",
                        },
                        {
                          key: "数字量输出DO",
                          title: "数字量输出DO",
                        },
                        {
                          key: "模拟量输出AO",
                          title: "模拟量输出AO",
                        },
                        {
                          key: "内存数字量DM",
                          title: "内存数字量DM",
                        },
                        {
                          key: "内存模拟量AM",
                          title: "内存模拟量AM",
                        },
                      ],
                    },
                    {
                      key: "饮水机",
                      title: "饮水机",
                    },
                    {
                      key: "空调",
                      title: "空调",
                    },
                  ],
                },
              ],
            },
            {
              key: "903-2",
              title: "903-2",
              children: [
                {
                  key: "电子材料仓库",
                  title: "电子材料仓库",
                  children: [
                    {
                      key: "热水器1",
                      title: "热水器",
                    },
                    {
                      key: "饮水机1",
                      title: "饮水机",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      tableData: [
        {
          moudleId: "8000001",
          moudleName: "电压",
          moudleWacth: "Modbus",
          moudleRtu: "控制系统采集器",
        },
        {
          moudleId: "8000002",
          moudleName: "电压",
          moudleWacth: "Modbus",
          moudleRtu: "控制系统采集器",
        },
        {
          moudleId: "8000003",
          moudleName: "电压",
          moudleWacth: "Modbus",
          moudleRtu: "控制系统采集器",
        },
        {
          moudleId: "8000004",
          moudleName: "电压",
          moudleWacth: "Modbus",
          moudleRtu: "控制系统采集器",
        },
      ],
      defaultProps: {
        children: "children",
        label: "name",
      },
      predefineColors: [
        'rgba(255, 69, 0, 1)',
        'rgba(255, 140, 0, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(144, 238, 144, 1)',
        'rgba(0, 206, 209, 1)',
        'rgba(30, 144, 255, 1)',
        'rgba(199, 21, 133, 1)',
        'rgba(255, 69, 0, 0.68)',
        'rgba(255, 120, 0, 1)',
        'rgba(250, 212, 0, 1)',
      ]
    };
  },
  computed: {},

  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  watch: {
    show: function (newVal, oldVal) {
      this.activeName = "attribute";
      this.dataStatus = {};
      this.dataControl = {};
      this.colorSetting();
    },
    items: function (newVal, oldVal) {
      this.dataStatus = {};
      this.dataControl = {};
      this.dataStatus.title = this._props.items.title;
      console.log(this._props.items, "this._props.items");
      // this.dataStatus = this._props.items.data.statusModel;
      // this.dataControl = this._props.items.data.controlModel;
    },
  },
  created() {
    this.colorSetting();
    listAllArea().then((response) => {
     this.gData = [];
     this.gData.push(response.data);
    });
    listData().then((response) => {
      this.tableData = [];
      this.tableData = response.rows;
    });
  },
  methods: {
    colorSetting(){
        let _this = this;
        if(JSON.parse(localStorage.getItem("predefineColorsArr"))){
            const predefineColorsArrLS = JSON.parse(localStorage.getItem("predefineColorsArr"));
            // _this.predefineColors=  JSON.parse(localStorage.getItem("predefineColorsArr"));//原逻辑为 自选颜色最多为10
            //现逻辑 要求 默认颜色显示一行（10） 自选颜色显示一行（最大为10）
            let thisC = _this.predefineColors.splice(0,10)
            _this.predefineColors = Array.from(new Set([...thisC, ...predefineColorsArrLS])); 
        }
    },
    changeInput(e) {
      this.$forceUpdate();
      this.changeSvg();
    },
    throughTabel(index) {
      if (index == 0) {
        this.isFirstBind = true;
      } else {
        this.isFirstBind = false;
      }
      this.openBind = true;
    },
    getList() {
      // console.log(this, "rightdrawer");
    },
    changeSvg() {
      this.$forceUpdate();
      console.log(this.items);
      this.$emit("changSvg", this.items);
    },

    bindInfo(row) {
      this.$confirm("是否确认绑定", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.openBind = false;
          if (this.isFirstBind == true) {
            console.log(this.dataStatus);
            this.dataStatus.moudleName = row.name;
            this.dataStatus.moudleId = row.id;
            this.dataStatus.moudleRtu = row.fromRTU;
            this.dataStatus.moudleWacth = row.fromYb;

            this.data.title = this._props.items.title;
          } else {
            this.dataControl.moudleName = row.name;
            this.dataControl.moudleId = row.id;
            this.dataControl.moudleRtu = row.fromRTU;
            this.dataControl.moudleWacth = row.fromYb;
            this.dataControl.title = this._props.items.title;
          }
        })
        .catch(() => {});
    },
    //确认提交
    sureSubmit() {
      let dataObj = {
        statusModel: {},
        controlModel: {},
      };

      dataObj.statusModel = this.dataStatus;
      dataObj.controlModel = this.dataControl;
      this.$emit("transmitDataObj", dataObj);
      console.log(this.dataStatus, this.dataControl);
    },
    changeSvgColor(preColor){
      this.changeSvg();
      this.$nextTick(()=>{
        if(!preColor){
          return false
          }
          //当前选择存在于默认和自选颜色中不操作
          if(this.predefineColors.includes(preColor)){
              return false
          }
          //默认颜色+自选颜色为最大数为20
          if(this.predefineColors.length>=20) {
              // this.predefineColors.shift()
                this.predefineColors.splice(10, 1);
          }
          this.predefineColors.push(preColor)
          const setLSPredefineColorsArr = this.predefineColors.slice(10);
          localStorage.setItem("predefineColorsArr", JSON.stringify(setLSPredefineColorsArr));
      })
    }
  },
};
</script>
<style lang="less" scoped>
#edge-drawer-tab{
  ::v-deep .el-tabs__nav{
    margin-left: 2rem;

    #tab-attribute,#tab-data{
      color: white;
      font-size: 1.5rem;
    }
    .el-tabs__item.is-top.is-active{
      color: #03d4b9 !important;
      font-weight: bold;
    }
    .el-tabs__active-bar.is-top{
      background-color: #03d4b9 !important;
    }
  }
  ::v-deep form.el-form{
    padding-left: 1rem;
    .el-form-item{
      margin: 2rem 0;
      padding-left: 1rem;
      padding-right: 2rem;
      label.el-form-item__label{
        font-size: 16px;
        color: #f9fafb;
      }
      div.el-form-item__content{
        .el-input-number,.el-select{
          width: 100%;
        }
        .el-input-number__decrease,.el-input-number__increase{
          border: none;
          background-color: #0a3d6c;
        }
        .el-input.is-disabled .el-input__inner{
          background-color: #0c375e;
          border: 1px solid #004579;
        }
      }
      div.el-color-picker__trigger{
        border: none;
      }

      .with-btn{
        display: flex;
        button{
          background-color: #027969;
        }
      }
    }
  }
}
</style>

