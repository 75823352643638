<template>
  <div class="DialogDictionaryPage">
    <el-dialog 
    @close="closeDialog('ruleForm')"
    :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <div v-if="getType == 2" slot="title">
        <div  class="equip_title">新增字典</div>
      </div>
      <div  v-else slot="title">
        <div class="equip_title">编辑字典</div>
      </div>
      <div style="max-height:45rem;overflow-y:auto;">
        <el-form :model="form" class="elForm" ref="ruleName" :rules="rules">
          <el-form-item label="所属标签" prop="tag">
            <el-input v-model="form.tag" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="字典键" prop="dictKey">
            <el-input v-model="form.dictKey" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="字典值" prop="dictValue">
            <el-input v-model="form.dictValue" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input v-model="form.description" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="form.sort" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
                
        </el-form>
      </div>
      <el-form>
        
       </el-form>
      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleName')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleName')">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import { validateInput, validateInteger } from "@/utils/validate"
export default {
    name:"DialogUser",
    mixins: [tableBg],
     data() {
      return {
        // 数据请求的类型（编辑=1、新登记=2）
        getType:0,
        //表单中placehoder的数据
        parentNamePlaceHoder:'',
        // 当前树的信息
        treeFullNodeData:{},
        // 展示判断
        dialogFormVisible: false,
        // 表单数据存放处
        form:{},
        rules: {
          tag: [validateInput],
          dictKey: [validateInput],
          dictValue:[validateInput],
          description: [validateInput],
          sort: [validateInteger],     
        },
      };
    },
    methods: {      
        // 关闭dialog的回调，el自带的
        closeDialog(ruleForm){
          this.formInit()
          this.$refs[ruleForm].resetFields()
        }, 
        handleCancle(ruleName) {
          this.$refs[ruleName].resetFields()
          this.dialogFormVisible = false
          this.formInit()
        },
         // from的归零
        formInit(){
          this.form ={}
        },

        //父组件点击触发新增
        show(){
          this.getType =2
          this.dialogFormVisible = true
        },
        
        // 父组件触发编辑
        showForEdit(val){
          this.getType =1
          this.dialogFormVisible = true
          this.form = val
        },
        // 提交发送请求（点击《确定》）
        postDialogData(ruleName){
          this.$refs[ruleName].validate(valid => {
            if (valid) {
              // 发送登记请求
              if(this.getType===2){
                this.$store.dispatch('postSystemDictionaryAddNewUser',this.form).then(()=>{
              if(this.ifDictionaryAdded===0){
                this.$message({message: '添加成功！',type: 'success'});
                this.$refs[ruleName].resetFields()
                this.dialogFormVisible = false;
                this.$parent.refreshThePage()
              }else if(this.ifDictionaryAdded===-1){
                this.$message({message:this.ifDictionaryAddedError ,type: 'warning'});
                }
              })           
              }
              else if(this.getType===1){
                //发送编辑请求
                this.$store.dispatch('putSystemDictionaryEdit',this.form).then(()=>{
                   if(this.ifDictionaryEdit===0){
                this.$message({message: '编辑成功！',type: 'success'});
                this.$refs[ruleName].resetFields()
                this.dialogFormVisible = false;
                this.$parent.refreshThePage()
              }else if(this.ifDictionaryEdit===-1){
                this.$message({message: this.ifDictionaryEditError ,type: 'warning'});
                }
                })
              }

              }else {
              this.$message.error('有必填项未填')
            }
          })

       }
    },

    //处理state数据
    computed:{
    ...mapState({
      ifDictionaryAdded:(state)=>{return state.system.ifDictionaryAdded.errorCode},
      ifDictionaryAddedError:(state)=>{return state.system.ifDictionaryAdded.message},
      ifDictionaryEdit:(state)=>{return state.system.ifDictionaryEdit.errorCode},
      ifDictionaryEditError:(state)=>{return state.system.ifDictionaryEdit.message},
    })
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2D79BE;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
</style>

<style lang="less">
.DialogDictionaryPage{
    .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }
// abb7c2
.el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem; 
}
.el-checkbox{
 color: #abb7c2;
}
.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #027969;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
}
  .el-input.is-disabled .el-input__inner {
    background-color: #0b3d6b;
    border-color: #004579;
    color: #C0C4CC;
    cursor: not-allowed;
  }

}


</style>


