<template>
  <div class="userPageInSystem">
    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div>
        <el-input
          size="small"
          placeholder="请输入操作人"
          v-model="searchValue"
          @keyup.enter.native="search"
          style="width:22rem;"
          clearable
          @clear="search"
        ></el-input>
        <el-select
          size="small"
          @change="selectChanged"
          placeholder="请选择日志状态"
          class="businessSelector"
          v-model="type"
        >
          <el-option
            v-for="(item, i) in logStateList"
            :label="item.label"
            :value="item.value"
            :key="i"
          ></el-option>
        </el-select>
        <label>操作时间：</label>
        <el-date-picker
          class="businessSelector"
          style="height:3.2rem;width:35rem"
          v-model="valueTimePicker"
          type="daterange"
          align="right"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          @change="timePickerChange"
        >
        </el-date-picker>
        <button class="btn_save" @click="search">查 询</button>
      </div>
    </div>

    <!-- 中部表格位置############################################################################################################ -->
    <div class="userTablePlace">
      <!-- 面包屑 -->
      <div class="bread">
        系统管理 / 日志管理
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          stripe:false
          height="57.8rem"
          border:false
          style="width: 100%"
          :header-cell-style="headerClass"
          :cell-style="cellStyle"
        >
          <el-table-column label="序号" min-width="5%">
            <template slot-scope="scope">{{
              scope.$index + 1 + (currentPage - 1) * pageSize
            }}</template>
          </el-table-column>
          <el-table-column
            class="tab"
            prop="title"
            label="操作内容"
            min-width="20%"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            class="tab"
            prop="requestMethod"
            label="请求方式"
            min-width="10%"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            class="tab"
            prop="operName"
            label="操作人员"
            min-width="10%"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            class="tab"
            prop="operIp"
            label="操作地址"
            min-width="15%"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            class="tab"
            prop="operLocation"
            label="操作地点"
            min-width="15%"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            class="tab"
            prop="statusName"
            label="状态"
            min-width="10%"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            class="tab"
            prop="operTime"
            label="操作日期"
            min-width="15%"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页器部分 -->
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalNumForPagenation"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { tableBg } from "@/mixin/tablebg"
export default {
  name: "LogManagement",
  mixins: [tableBg],
  data() {
    return {
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 双向绑定的搜索数据
      searchValue: "",
      // 双向绑定的日志状态
      type: "",
      // 双向绑定的操作时间
      valueTimePicker: "",
      // 日志状态列表
      logStateList: [
        { label: "异常", value: 1 },
        { label: "正常", value: 0 },
        { label: "全部", value: "" },
      ],
      // 表格数据
      tableData: [],
      // 时间选择器的数据
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit("pick", [start, end])
            },
          },
        ],
      },
    }
  },

  methods: {
    // 刷新页面
    refreshThePage() {
      let beginTime = ""
      let endTime = ""
      this.valueTimePicker
        ? (beginTime = this.valueTimePicker[0] + " 00:00:00")
        : ""
      this.valueTimePicker
        ? (endTime = this.valueTimePicker[1] + " 23:59:59")
        : ""
      this.$store
        .dispatch("getSysLogPageData", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          keyword: this.searchValue,
          type: this.type,
          startDate: beginTime,
          endDate: endTime,
        })
        .then(() => {
          this.tableData = []
          for (let i = 0; i < this.sysLogPageData.length; i++) {
            const tableSingle = {
              title: "",
              requestMethod: "",
              operName: "",
              operIp: "",
              operLocation: "",
              positionName: "",
              status: "",
              operTime: "",
              statusName: "",
            }
            tableSingle.title = this.sysLogPageData[i].title
            tableSingle.requestMethod = this.sysLogPageData[i].requestMethod
            tableSingle.operName = this.sysLogPageData[i].operName
            tableSingle.operIp = this.sysLogPageData[i].operIp
            tableSingle.operLocation = this.sysLogPageData[i].operLocation
            tableSingle.positionName = this.sysLogPageData[i].positionName
            tableSingle.status = this.sysLogPageData[i].status
            tableSingle.operTime = this.sysLogPageData[i].operTime
            if (this.sysLogPageData[i].status === 0) {
              tableSingle.statusName = "正常"
            } else {
              tableSingle.statusName = "异常"
            }
            this.tableData.push(tableSingle)
          }

          this.totalNumForPagenation = this.userTotal
        })
    },
    // 点击搜索或者回车
    search() {
      this.currentPage = 1
      this.refreshThePage()
    },
    // 触发了日期选择器
    timePickerChange() {
      // alert("触发了日期选择器");
      this.search();
    },
    // 更改日志状态选择框的选择
    selectChanged() {
      this.refreshThePage()
    },
    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = "1"
      this.pageSize = val
      this.refreshThePage()
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val
      this.refreshThePage()
    },
  },

  mounted() {
    this.refreshThePage()
  },

  computed: {
    ...mapState({
      sysLogPageData: (state) => {
        return state.system.sysLogPageData.data.records
      },
      userTotal: (state) => {
        return state.system.sysLogPageData.data.total
      },
      ifDeleteUserSuccess: (state) => {
        return state.system.ifDeleteUserSuccess.errorCode
      },
      ifResetPassword: (state) => {
        return state.system.ifResetPassword.errorCode
      },
    }),
  },
}
</script>

<style lang="less" scoped>
// 顶部样式##################################################################
.topBar {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  box-sizing: border-box;
  height: 8rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #fff;
  .searchInput {
    height: 3.2rem;
    width: 22.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
    background-color: #0c375e;
    border: 1px solid #004579;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    text-indent: 1.4rem;
    &:focus {
      border: 0.1rem solid #008080;
    }
  }

  button {
    height: 3.2rem;
    width: 7.4rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .btn1 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 26rem;
  }
  .btn2 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 158.7rem;
    width: 10.2rem;
  }
  .businessType {
    height: 3.2rem;
    width: 20.8rem;
    position: absolute;
    left: 36rem;
    top: 2.4rem;
  }
  .businessSelector {
    margin: 0 2rem;
  }
}

// 中部表格样式##################################################################
.userTablePlace {
  height: 86.4rem;
  width: 171.2rem;
  position: absolute;
  top: 10.4rem;
  left: 0;
  overflow: hidden;
  background-color: #0d3050;
  .bread {
    color: #fff;
    height: 5rem;
    width: 142.8rem;
    line-height: 5rem;
    color: #fff;
    font-size: 1.4rem;
    text-indent: 2.4rem;
  }
  .table {
    height: 57.2rem;
    width: 166.4rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
/deep/.el-dialog__footer {
  position: relative;
}
</style>

<style lang="less">
// 公共样式！谨防代码污染！#########################################
.userPageInSystem {
  .table {
    height: 57.2rem;
    width: 148rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
</style>
