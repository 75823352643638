<template>
	<div class="app-container">
		<el-form ref="queryForm" :model="queryParams" :inline="true">

		</el-form>
		<!-- toolbar -->
		<el-row class="bar" :gutter="10">
			<div class="search-box">
				<el-col :span="10" style="padding-left: 20px; width: 80%;" class="inputBgc">
					<el-input v-model="queryParams.diagramName"
							  placeholder="请输入关键字搜索"
							  clearable
							  @keyup.enter.native="handleQuery"
							  @input="resetQuery"
					>
						<i slot="suffix" class="el-icon-search" @click="handleQuery"></i>
					</el-input>
				</el-col>
			</div>
			<div class="buttons">
				<el-col :span="1.5" v-show="radio == 'queue'">
					<el-button class="btntheme" type="primary" v-show="ids.length" @click="addTemplate">设为模板</el-button>
				</el-col>
				<el-col :span="1.5" v-show="radio == 'queue'">
					<el-button class="btntheme" type="primary" v-show="ids.length" @click="handleDelete">批量删除</el-button>
				</el-col>
				<el-col :span="1.5" v-show="radio == 'queue'">
					<el-button class="btntheme" type="primary" v-show="ids.length" @click="handleExport">批量导出</el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button class="btnadd" type="primary" plain @click="handleAdd">新增</el-button>
				</el-col>
				<el-col :span="3.5" class="inputBgc">
					<el-radio-group v-model="radio"
									@change="agreeChange"
									text-color="#fff"
									fill="#fff"
									border="none"
					>
						<el-radio-button label="queue" style="border: none;">
							<img ref="image2"
								 :src="require('@/assets/icons/' + imgQueue + '.png')"
								 alt=""/>
						</el-radio-button>
						<el-radio-button label="photo" style="border: none;">
							<img ref="image1"
								 :src="require('@/assets/icons/' + imgPhoto + '.png')"
								 alt=""
							/>
						</el-radio-button>
					</el-radio-group>
				</el-col>
			</div>
		</el-row>
		<!-- card-view -->
		<el-row class="diagram-cards" :gutter="24" v-show="radio == 'photo'">
			<el-col :span="6" v-for="(item, index) in diagramList" :key="index">
				<div class="img-card">
					<div class="card-header">
						<span class="infotitle">{{ item.diagramName.length  &lt; 20 ? item.diagramName : item.diagramName.substring(0, 20) + '...'  }}</span>
            			<img :src="require('@/assets/icons/wacth.png')" height="30" alt="" @click="throughImg(item)">
					</div>
					<div class="card-content" @dblclick="goCircuitEdit(item.diagramId)">
						<img class="diagram-img" :src="VUE_APP_URL + item.coverUrl"  alt=""/>
					</div>
				</div>
			</el-col>
		</el-row>

		<!-- table-view -->
		<div class="table">
			<el-table v-show="radio == 'queue'"
					  class="bgc"
					  :data="diagramList"
					  @selection-change="handleSelectionChange"
					  :header-cell-style="{
					  	background: '#133F68',
					  	color:'#fff',
					  	fontFamily: 'PingFang SC',
					  	fontStyle: 'normal',
					  	fontWeight: '600',
					  	}"
			>
				<el-table-column type="selection" align="center" width="64"/>
				<el-table-column label="图形名称" prop="diagramName" align="left" width="400">
					<template slot-scope="scope">
						<a @click="goCircuitEdit(scope.row.diagramId)" style="cursor: pointer">{{ scope.row.diagramName }}</a>
					</template>
				</el-table-column>
				<el-table-column label="创建人" prop="creatorName" align="center" />
				<el-table-column label="创建时间" prop="createTime" align="center" />
				<el-table-column label="最后更新时间" prop="editTime" align="center" />
				<el-table-column label="操作" align="center" width="350">
					<template slot-scope="scope">
						<div style="display:flex; margin-left: -12px">
							<div class="caozuobtn" @click="goCircuitEdit(scope.row.diagramId)">设计</div>
							<div class="caozuobtn" @click="goBind(scope.row)">绑定</div>
							<div class="caozuobtn" @click="goUnbind(scope.row.diagramId)">解绑</div>
							<div class="caozuobtn" @click="handleUpdate(scope.row)">编辑</div>
							<div class="caozuobtn" @click="handleDelete(scope.row)">删除</div>
							<div class="caozuobtn w56" @click="addTemplate(scope.row)">设为模板</div>
							<div class="caozuobtn" @click="handleExport(scope.row)">导出</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页器 -->
		<el-pagination background
					   @size-change="handleSizeChange"
					   @current-change="handleCurrentChange"
					   :current-page="queryParams.pageNum"
					   :page-size="queryParams.pageSize"
					   :total="total"
					   :page-sizes="[10, 20, 40, 80]"
					   layout="total, sizes, prev, pager, next, jumper"
					   style="float: right; padding: 2rem;"/>

		<!-- 弹层：添加、修改 -->
		<el-dialog class="add-edit-dialog"
				v-dialogDrag
				:close-on-click-modal="false"
				:title="title"
				:visible.sync="open"
				width="600px"
				append-to-body
		>
			<el-form ref="form" :model="form" :rules="rules" label-width="100px">
				<el-form-item label="图形名称" prop="diagramName" class="inputBgc">
					<el-input v-model="form.diagramName" placeholder="请输入图形名称"/>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="text-align: right">
				<el-button @click="cancel" class="cancelBtn">取 消</el-button>
				<el-button @click="submitForm" class="sureBtn">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 弹层：绑定 -->
		<el-dialog title="绑定配电场景"
				   v-dialogDrag
				   append-to-body
				   width="400px"
				   class="dialog-bind"
				   :close-on-click-modal="false"
				   :visible.sync="isBind"
				   @close="clearBindForm"
		>
			<el-tabs id="tab-bind-scenario" v-model="activeName">
				<el-tab-pane label="配电房" name="first">
					<el-tree ref="bindForm" accordion
							 node-key="id"
							 :data="bindData"
							 :props="defaultProps"
							 :default-expanded-keys="[bindDiagramCode]"
							 :default-checked-keys="[bindDiagramCode]"
					/>
				</el-tab-pane>
				<el-tab-pane label="电气消防" name="second">
					<el-tree ref="bindForm1" accordion
							 node-key="id"
							 :data="bindData1"
							 :props="defaultProps"
							 :default-expanded-keys="[bindDiagramCode]"
							 :default-checked-keys="[bindDiagramCode]"
					/>
				</el-tab-pane>
			</el-tabs>
			<div slot="footer" class="dialog-footer">
				<el-button class="sureBtn" @click="submitBind()" >确 认</el-button>
			</div>
		</el-dialog>

		<!-- 弹层：卡片预览 -->
		<el-dialog v-dialogDrag
				   class="add-edit-dialog"
				   width="50%"
				   :title="title1.length &lt; 40 ? title1 : title1.substring(0, 40) + '...' "
				   :close-on-click-modal="false"
				   :visible.sync="openImg"
		>
			<img :src="VUE_APP_URL + src" alt="图片走丢了..." width="100%"/>
			<!--<div style="text-align: right;margin-top: 28px">
				<el-button @click="openImg = false" class="sureBtn" style="width: 6vw">确 定</el-button>
			</div>-->
		</el-dialog>
	</div>
</template>
<script>
	import {
			 pageDiagram,
		     getDiagramById,
		     delDiagram,
		     addDiagram,
		     updateDiagram,
		     unbindDiagram,
		     exportDiagram,
		     addTemplate,
	} from "@/api/diagram/main.js";
	import config from "@/config/index.js";
	import { distroom, hydrant } from "@/api/diagram/kaiyuan";

	export default {
		name: "Diagram",
		data() {
			return {
				activeName: "first",
				VUE_APP_URL: config.baseURL + 'diagram',

				// 遮罩层
				loading: true,
				// 导出遮罩层
				exportLoading: false,
				// 选中数组
				ids: [],
				bindFormId: null,
				bindDiagramId: null,
				// 总条数
				total: 0,
				diagramList: [],
				// 弹出层标题
				title: "",
				//图片预览标题
				title1: "",
				// 是否显示弹出层
				open: false,
				// 查询参数
				//列表切换
				radio: "queue",
				//搜索内容
				searchInfo: "",
				endPageNum: 0,
				openImg: false,
				isBind: false,
				src: "",
				imgQueue: "intable",
				imgPhoto: "outphoto",
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					diagramName: null,
				},
				// 表单参数
				form: {
          diagramId: null,
          diagramName: '',
        },
				// 表单校验
				rules: {
					diagramName: [
						{required: true, message: "接线图名称不能为空", trigger: "blur"},
					],
				},
				bindData: [],
				bindData1: [],
				bindDiagramCode: null,
				defaultProps: {
					children: "children",
					label: "name",
				},
			};
		},
		methods: {
			// 分页操作
			handleSizeChange(pageSize){
				this.queryParams.pageNum = 1;
				this.queryParams.pageSize = pageSize;
				this.getList();
			},
			handleCurrentChange(pageNum){
				this.queryParams.pageNum = pageNum;
				this.getList();
			},
			clearBindForm() {
				this.bindFormId = null;
				this.bindDiagramId = null;
				this.$refs.bindForm.setCurrentKey(null)
				this.$refs.bindForm1.setCurrentKey(null)
			},
			submitBind() {
				let node = this.$refs.bindForm.getCurrentNode();
				let node1 = this.$refs.bindForm1.getCurrentNode();
				console.log('点击了绑定确认按钮', node, node1);
				if (node == null&&node1 == null) {
					this.$message.error("请选择一个配电图进行绑定");
					return;
				}
				if ((!node||node.type != 4) && (!node1 || node1.type != 9)) {
					this.$message.error("您选择的不是一个配电图");
					return;
				}
				this.bindFormId = node ? node.id : node1.id;
				this.form = {
					diagramId: this.bindDiagramId,
					diagramCode: this.bindFormId
				};
				updateDiagram(this.form)
						.then((response) => {
							this.$message.success("绑定成功");
							this.isBind = false;
							this.form.diagramId = null;
							this.bindFormId = null;
							this.getList();
						})
						.catch((reason) => {
							this.form.diagramId = null;
							this.$message.error("绑定失败");
						});
			},
			goBind(diagram) {
				this.getBindList();
				this.getBindList1();
				this.bindFormId = null;
				this.bindDiagramId = null;
				this.isBind = true;
				this.bindDiagramId = diagram.diagramId;
				this.bindDiagramCode = diagram.diagramCode;
			},
			goUnbind(diagramId) {
				unbindDiagram(diagramId).then(res => {
					if (res.code === 200) {
						this.$message.success("解绑成功");
					} else {
						this.$message.error("解绑失败");
					}
				})
			},
			getBindList() {
				distroom().then((response) => {
					this.bindData = response.data;
					this.bindDataInit(this.bindData);
				});
			},
			//点击消防tree数据
			getBindList1() {
				hydrant().then((response) => {
					this.bindData1 = response.data;
					this.bindDataInit(this.bindData1);
				});
			},
			bindDataInit(parent) {
				for (let i = 0; i < parent; i++) {
					parent[i].label = parent[i].name;
					if (parent.children && parent.children.length > 0) {
						this.bindDataInit(parent.children);
					}
				}
			},
			getList() {
				// this.loading = true;
				pageDiagram(this.queryParams).then((response) => {
					console.log(response);
					this.diagramList = response.rows;
					for (const item of this.diagramList) {
						item.coverUrl = decodeURIComponent(item.coverUrl);
					}
					this.total = response.total;
					this.loading = false;
					this.endPageNum = response.rows.length;
				});
			},
			//时间转化
			transformTimestamp(timestamp) {
				if (timestamp) {
					let a = new Date(timestamp).getTime();
					const date = new Date(a);
					const Y = date.getFullYear() + "-";
					const M =
							(date.getMonth() + 1 < 10
									? "0" + (date.getMonth() + 1)
									: date.getMonth() + 1) + "-";
					const D =
							(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "  ";
					const h =
							(date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
							":";
					const m =
							date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
					// const s = date.getSeconds(); // 秒
					const dateString = Y + M + D + h + m;
					// console.log('dateString', dateString); // > dateString 2021-07-06 14:23
					return dateString;
				} else {
				}
			},

			// 取消按钮
			cancel() {
				this.open = false;
				this.reset();
			},

			reset() {
				// 表单重置
				this.form.diagramName = null;
				// 清除校验
				// this.$refs.form.clearValidate();
				console.log('this.$refs', this.$refs)
			},

			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.resetForm("queryForm");
				this.handleQuery();
			},
			// 多选框选中数据
			handleSelectionChange(selection) {
				this.ids = selection.map((item) => item.diagramId);
			},
			/** 新增按钮操作 */
			handleAdd() {
				this.reset();
				this.open = true;
				this.form.diagramId = null;
				this.title = "添加图形";
			},
			/** 修改按钮操作 */
			handleUpdate(row) {
				this.reset();
				const diagramId = row.diagramId || this.ids;
				getDiagramById(diagramId).then((response) => {
					this.form = response.data;
					this.open = true;
					this.title = "修改信息";
				});
			},
			throughImg(item) {
				console.log(item);
				this.openImg = true;
				this.title1 = item.diagramName;
				this.src = item.coverUrl;
			},
			agreeChange(val) {
				if (val == "photo") {
					this.imgQueue = "outtable";
					this.imgPhoto = "inphoto";
				} else {
					this.imgQueue = "intable";
					this.imgPhoto = "outphoto";
				}
			},
			/** 提交按钮 ,*/
			submitForm() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						if (this.form.diagramId != null) {
							updateDiagram(this.form)
									.then((response) => {
										this.$message.success("修改成功");
										this.open = false;
										this.form.diagramId = null;
										this.getList();
									})
									.catch((reason) => {
									});
						} else {
							// this.form.creatorid = this.$store.state.user.name;
							// this.form.diagramId = this.uuid(32);
							addDiagram(this.form)
									.then((response) => {
										this.$message.success("新增成功");
										this.form.diagramId = null;
                    this.form.diagramName = ''
										this.open = false;
										this.getList();
									})
									.catch((reason) => {
										this.form.diagramId = null;
                    this.form.diagramName = ''
									});
						}
					}
				});
			},
			addTemplate(row) {
				const diagramIds = row.diagramId || this.ids[0];
				console.log(diagramIds, "diagramIds");
				if(diagramIds) {
					addTemplate(diagramIds).then((response) => {
						this.$message.success("设置成功");
						this.open = false;
						this.getList();
					});
				} else {
					this.$message.error("请至少选择一个图形");
				}
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				const diagramIds = row.diagramId || this.ids;
				this.$confirm("是否确认删除", "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
						.then(function () {
							return delDiagram(diagramIds);
						})
						.then(() => {
							this.getList();
							this.$message.success("删除成功");
						})
						.catch(() => {
						});
			},

			/** 导出按钮操作 */
			handleExport(row) {
				console.log(row);
				if (row.diagramId) {
					exportDiagram(row.diagramId).then((response) => {
						var blob = new Blob([JSON.stringify(response)]); //创建一个blob对象
						var a = document.createElement("a"); //创建一个<a></a>标签
						a.href = URL.createObjectURL(blob); // response is a blob
						var date = new Date();
						var year = date.getFullYear(); //获取完整的年份(4位)
						var month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
						var day = date.getDate(); //获取当前月份(0-11,0代表1月)
						if (month < 10) {
							month = "0" + month;
						}
						if (day < 10) {
							day = "0" + day;
						}

						var second = date.getSeconds();
						var hour = date.getHours();
						var minute = date.getMinutes() - 1;
						var timestamp = year + month + day + hour + minute + second;
						a.download = row.diagramName + timestamp + ".ed"; //文件名称
						a.style.display = "none";
						document.body.appendChild(a);
						a.click();
						a.remove();
					});
				} else {
					for (let id of this.ids) {
						for (let diagram of this.diagramList) {
							if (diagram.diagramId == id) {
								exportDiagram(id).then((response) => {
									var blob = new Blob([JSON.stringify(response)]); //创建一个blob对象
									var a = document.createElement("a"); //创建一个<a></a>标签
									a.href = URL.createObjectURL(blob); // response is a blob
									var date = new Date();
									var year = date.getFullYear(); //获取完整的年份(4位)
									var month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
									var day = date.getDate(); //获取当前月份(0-11,0代表1月)
									if (month < 10) {
										month = "0" + month;
									}
									if (day < 10) {
										day = "0" + day;
									}

									var second = date.getSeconds();
									var hour = date.getHours();
									var minute = date.getMinutes() - 1;
									var timestamp = year + month + day + hour + minute + second;
									a.download = diagram.diagramName + timestamp + ".ed"; //文件名称
									a.style.display = "none";
									document.body.appendChild(a);
									a.click();
									a.remove();
								});
							}
						}
					}
				}
			},
			goCircuitEdit(diagramId) {
				this.$router.push({
					path: "/mapping/diagram-edit",
					query: {diagramId: diagramId},
				});
			},
		},
		created() {
			this.getList();
		},
	};
</script>
<style lang="less" scoped>
.app-container {
	.bar{
		display: flex;
		margin-left: 0 !important;
		margin-right: 0 !important;
		justify-content: space-between;
		line-height: 64px;
		.search-box{
			width: 55%;
			background-color: #0d3050;
			i{
				margin-right: 1rem;
			}
		}
		.buttons{
			display: flex;
			width: 100%;
			justify-content: flex-end;
			background-color: #0d3050;

			.btntheme{
				padding: 0 22px;
				height: 32px;
				font-size: 14px;
				border-radius: 2px;
				line-height: 12px;
				cursor: pointer;
				background-color: #0c375f;
				color: white;
				border: none;
			}

			.btnadd{
				padding: 0;
				color: #fff !important;
				border-color: rgba(0, 115, 222, 0.1) !important;
				border-radius: 2px;
				height: 32px;
				width: 8rem;
				background-color: #027969 !important;
			}

			::v-deep .inputBgc .el-radio-button__inner,
			.inputBgc .el-radio-button__orig-radio:checked + .el-radio-button__inner {
				background: rgba(0, 115, 222, 0.15) !important;
			}
			::v-deep .inputBgc .el-radio-button__inner{
				width: 38px;
				height: 38px;
				border-radius: 0 !important;
				box-shadow:none !important;
				padding: 0;
				margin-right: 5px;
			}
			img{
				height: 18px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 30%;

			}
		}
	}

	.table {
		margin-top: 18px;
		.bgc{
			height: 525px;
			overflow-y: scroll;
		}
		::v-deep .el-table__row{
			background-color: #0c375e;
			color: #dbe1e7;
			&:hover{
				background-color: #0c375e;
			}

		}
		::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
			background-color: #0c375e;
		}

		.caozuobtn {
			cursor: pointer;
			position: static;
			left: 0%;
			right: 89.06%;
			top: 0%;
			bottom: 0%;
			font-family: "PingFang SC";
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #03d9bc;
			mix-blend-mode: normal;
			flex: none;
			order: 0;
			flex-grow: 0;
			margin: 0px 12px;
		}
	}

	.diagram-cards{
		.img-card {
			cursor: pointer;
			background: rgba(0, 115, 222, 0.15);
			border-radius: 6px;
			margin: 1rem 0;

			.card-header{

				height: 3rem;

				span {
					height: 3rem;
					line-height: 3rem;
					font-size: 15px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #027969;
					float: left;
					text-align: left;
				}
				img{
					height: 3rem;
					float: right;
				}

			}
			.diagram-img{
				height: 190px;
				width: 400px;
			}
		}
	}


	::v-deep .el-radio-button__inner {
		border: none !important;
	}

	.el-radio-button--medium .el-radio-button__inner {
		padding: 10px !important;
		font-size: 14px !important;
	}

}
</style>
<style lang="less">
.dialog-bind{
	.el-dialog__header{
		height: 5.4rem;
		line-height: 5.4rem;
		padding-left: 2.4rem !important;
		font-size: 1.6rem;
		background: linear-gradient(225deg, rgba(15, 36, 55, 0.0001) 0.03%, rgba(0, 115, 222, 0.7) 99.9%);
		.el-dialog__title{
			color: white;
		}
	}
	.dialog-footer{
		display: flex;
		flex-direction: row-reverse;
		padding-right: 2rem;

		button{
			margin-left: 1rem;
			color: white;
			border: none;
			border-radius: 2px;

			&:first-child{
				background-color: #027969;
			}
			&:not(:first-child){
				background-color: #0b3a64;
				border: 1px solid #094377;
			}
		}
	}
}
.el-tabs__header{
	margin-left: 2rem;
}
#tab-first,#tab-second{
	color: white !important;
	font-size: 1.5rem;
	&.is-active{
		color: #03d4b9 !important;
		font-weight: bold;
	}
}
.el-tabs__active-bar.is-top{
	background-color: #03d4b9 !important;
}

.el-tree{
	background-color: #0c375e;
	color: #eceeef;

	.el-tree-node:focus > .el-tree-node__content {
		background-color: #09457c !important;
	}
	.el-tree-node.is-expanded.is-focusable.is-checked .is-leaf + .el-tree-node__label{
		color: #03d4b9 !important;
		font-weight: bold;
	}

}


</style>

