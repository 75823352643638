<template>
  <div class="CustomerPage">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <!-- 新增根节点############################################################################################################## -->
      <template v-if="type === 2 || editType === 5">
        <div v-if="getType == 2" slot="title">
          <div class="equip_title">新增根节点</div>
        </div>
        <div v-else slot="title">
          <div class="equip_title">编辑根节点</div>
        </div>
        <div style="max-height: 45rem; overflow-y: auto;">
          <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
            <el-form-item label="根节点名称" prop="name" style="width: 98%;">
              <el-input
                v-model="form.name"
                placeholder="请输入"
                size="small"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>

      <!-- 新增设备############################################################################################################## -->
      <template v-if="type === 5 || editType === 6">
        <div slot="title">
          <div class="equip_title">
            {{ getType == 2 ? '新增设备' : '编辑设备' }}
          </div>
        </div>
        <div style="max-height: 45rem; overflow-y: auto;">
          <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
            <el-form-item label="设备名称" prop="name" style="width: 98%;">
              <el-input
                v-model="form.name"
                placeholder="请输入"
                size="small"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>

      <!-- 新增回路############################################################################################################## -->
      <template v-if="type === 6 || editType === 7">
        <div slot="title">
          <div class="equip_title">
            {{ getType == 2 ? '新增回路' : '编辑回路' }}
          </div>
        </div>
        <div style="max-height: 45rem; overflow-y: auto;">
          <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
            <el-form-item label="回路名称" prop="name" style="width: 98%;">
              <el-input
                v-model="form.name"
                placeholder="请输入"
                size="small"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template
        v-if="
          type === 7 ||
          type === 8 ||
          type === 9 ||
          editType == 8 ||
          editType == 9 ||
          editType == 10
        "
      >
        <div slot="title">
          <div v-if="type === 7 || editType === 8" class="equip_title">
            {{ getType == 2 ? '新增二级回路' : '编辑二级回路' }}
          </div>
          <div v-if="type === 8 || editType == 9" class="equip_title">
            {{ getType == 2 ? '新增三级回路' : '编辑三级回路' }}
          </div>
          <div v-if="type === 9 || editType == 10" class="equip_title">
            {{ getType == 2 ? '新增四级回路' : '编辑四级回路' }}
          </div>
        </div>
        <div style="max-height: 45rem; overflow-y: auto;">
          <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
            <el-form-item label="回路名称" prop="name" style="width: 98%;">
              <el-input
                v-model="form.name"
                placeholder="请输入"
                size="small"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>

      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">
          取 消
        </button>
        <button class="btn_save" @click="postDialogData('ruleForm')">
          保 存
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import { validateInput, validateSelect } from '@/utils/validate'
export default {
  name: 'DialogPowerPosition',
  mixins: [tableBg],
  data() {
    return {
      // 编辑时候的类型
      editType: 0,
      // 登记的类型
      type: 0,
      // 数据请求的类型（编辑=1、新登记=2）
      getType: 0,
      //表单中placehoder的数据
      parentNamePlaceHoder: '',
      // 当前树的信息
      treeFullNodeData: {},
      // 展示判断
      dialogFormVisible: false,
      // 表单数据存放处
      form: {},
      rules: {
        name: [validateInput],
      },
    }
  },
  methods: {
    // 关闭dialog的回调，el自带的
    closeDialog(ruleForm) {
      this.formInit()
      this.$refs[ruleForm].resetFields()
    },
    handleCancle(ruleName) {
      this.formInit()
      this.dialogFormVisible = false
      this.$refs[ruleName].resetFields()
    },
    // from的归零
    formInit() {
      this.form = {}
    },

    //父组件点击触发新增
    show(valType, valData) {
      this.editType = 0
      this.type = valType
      this.treeFullNodeData = {
        ...valData,
      }
      this.parentNamePlaceHoder = valData.name
      this.form.parentId = valData.id
      this.getType = 2
      if (valType === 2) {
        this.form.type = 5
      } else if (valType === 5) {
        this.form.type = 6
      } else if (valType === 6) {
        this.form.type = 7
      } else if (valType === 7) {
        this.form.type = 8
      } else if (valType === 8) {
        this.form.type = 9
      } else if (valType === 9) {
        this.form.type = 10
      }
      this.dialogFormVisible = true
    },

    // 父组件触发编辑
    showForEdit(valData) {
      this.getType = 1
      this.dialogFormVisible = true
      this.form = {
        ...valData,
      }
      this.form.type = valData.type
      this.form.parentId = valData.parentId
      this.type = 0
      this.editType = valData.type
    },
    // 提交发送请求（点击《确定》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          // 发送登记请求
          if (this.getType === 2) {
            this.$store.dispatch('postSystemPeidianAdd', this.form).then(() => {
              if (this.ifPeidianAddSuccess === 0) {
                this.$message({ message: '添加成功！', type: 'success' })
                this.$refs[ruleName].resetFields()
                this.dialogFormVisible = false
                this.$parent.refreshThePage()
                this.$parent.refreshTheTree()
              } else if (this.ifPeidianAddSuccess === -1) {
                this.$message({
                  message: this.ifPositionAddError,
                  type: 'warning',
                })
              }
            })
          } else if (this.getType === 1) {
            //发送编辑请求
            this.$store.dispatch('putSystemPeidianEdit', this.form).then(() => {
              if (this.ifPeidianEditSuccess === 0) {
                this.$message({ message: '编辑成功！', type: 'success' })
                this.$refs[ruleName].resetFields()
                this.dialogFormVisible = false
                this.$parent.refreshThePage()
                this.$parent.refreshTheTree()
              } else if (this.ifPeidianEditSuccess === -1) {
                this.$message({
                  message: this.ifPositionEditError,
                  type: 'warning',
                })
              }
            })
          }
        } else {
          this.$message.error('有必填项未填')
        }
      })
    },
  },

  //处理state数据
  computed: {
    ...mapState({
      ifPeidianAddSuccess: (state) => {
        return state.system.ifPeidianAddSuccess.errorCode
      },
      ifPositionAddError: (state) => {
        return state.system.ifPeidianAddSuccess.message
      },
      ifPeidianEditSuccess: (state) => {
        return state.system.ifPeidianEditSuccess.errorCode
      },
      ifPositionEditError: (state) => {
        return state.system.ifPeidianEditSuccess.message
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}

// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
</style>

<style lang="less">
// 套壳！谨防代码污染！
.CustomerPage {
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }
  // abb7c2
  .el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem;
  }
  .el-checkbox {
    color: #abb7c2;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #027969;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
  }
}
</style>

<style lang="less">
.el-input.is-disabled .el-input__inner {
  background-color: #0b3d6b;
  border-color: #004579;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
