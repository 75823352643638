<template>
    <div>
        <div v-if="loading" class="loading-mask">
          <el-spinner size="large"></el-spinner>
        </div>
        <!-- 分时统计的柱状图 -->
        <!-- 上班的上班时间柱状图 -->
        <div id="myChart17" class="c1"></div>
        <el-select v-model="typeValue" size="small" class="select-type" @change="handleChangeType">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div class="select-time">
            <span class="time">时间：</span>
            <el-date-picker class="seleWid" v-model="dateSpan" @change="selectDate" type="month" size="mini" placeholder="选择月"></el-date-picker>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";
export default {
    // props:['dateSpan'],
    data () {
        return {
            dateSpan: "2021-12",
            dataForm: {
                localtionId: "",
                startTime: "",
            },
            barList1: [],
            barList2: [],
            barList3: [],
            barList4: [],
            barList5: [],
            barList6: [],
            xdata: [],
            yMax: 0,
            typeValue: 0,
            options: [
                {
                    label: '工作时段(工作时间-休息时间)',
                    value: 0,
                },
                {
                    label: '尖峰平谷时段',
                    value: 1,
                }
            ],
            seriesData: [
                [
                    {
                        name: "上班时间",
                        type: "bar",
                        barWidth: 5,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1EE7E7" },
                                { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
                            ]),
                        },
                        data: [],
                    },
                    {
                        name: "休息时间",
                        type: "bar",
                        barWidth: 5,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1890FF" },
                                { offset: 1, color: "rgba(24, 144, 255, 0.35)" },
                            ]),
                        },
                        data: [],
                    },
                ],
                [
                    {
                        name: "尖",
                        type: "bar",
                        barWidth: 5,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#3673E8" },
                                { offset: 1, color: "rgba(54,115,232, 0.35)" },
                            ]),
                        },
                        data: [],
                    },
                    {
                        name: "峰",
                        type: "bar",
                        barWidth: 5,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#61B8FF" },
                                { offset: 1, color: "rgba(97,184,255, 0.35)" },
                            ]),
                        },
                        data: [],
                    },
                    {
                        name: "平",
                        type: "bar",
                        barWidth: 5,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#F5B763" },
                                { offset: 1, color: "rgba(245,183,99, 0.35)" },
                            ]),
                        },
                        data: [],
                    },
                    {
                        name: "谷",
                        type: "bar",
                        barWidth: 5,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "#1de1e2" },
                                { offset: 1, color: "rgba(29,225,226, 0.35)" },
                            ]),
                        },
                        data: [],
                    },
                ],
            ],
            loading: false,
        };
    },
    created () {
        this.getThisMonth();
    },
    mounted () { },
    methods: {
        init () {
            this.getSelectTime();
        },
        resizeChart () {
            setTimeout(() => {
                const myChart = this.$echarts.init(document.getElementById("myChart17"));
                const chartWidth = document.getElementById("myChart17").offsetWidth
                const chartHeight = document.getElementById("myChart17").offsetHeight
                myChart.resize({ width: chartWidth, height: chartHeight })
            }, 500)
        },
        getThisMonth () {
            let date = new Date();
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = y + "-" + m;
            // this.dateSpan = time;
            this.dateSpan = this.$store.state.elecManagement.timeAnalysisDate;
        },
        selectDate (dateData) {
            let date = new Date(dateData);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            const time = y + "-" + m;
            this.dateSpan = time;
            this.$store.commit("TimeAnalysisDate", this.dateSpan);
            this.getSelectTime();
        },
        getSelectTime () {
            this.loading = true
            this.$store
                .dispatch("getBarWorkorRest", {
                    id: this.dataForm.localtionId,
                    month: this.dateSpan,
                })
                .then(() => {
                    if (this.$store.state.elecManagement.getBarWorkorRest.errorCode == 0) {
                        if (
                            (this.$store.state.elecManagement.getBarWorkorRest.data !== null) &
                            (this.$store.state.elecManagement.getBarWorkorRest.data.length > 0)
                        ) {
                            this.barList1 = this.$store.state.elecManagement.getBarWorkorRest.data[0].data;
                            this.barList2 = this.$store.state.elecManagement.getBarWorkorRest.data[1].data;
                            this.barList3 = this.$store.state.elecManagement.getBarWorkorRest.data[2].data;
                            this.barList4 = this.$store.state.elecManagement.getBarWorkorRest.data[3].data;
                            this.barList5 = this.$store.state.elecManagement.getBarWorkorRest.data[4].data;
                            this.barList6 = this.$store.state.elecManagement.getBarWorkorRest.data[5].data;
                            this.yMax = 1;
                            this.drawLine1();
                        } else {
                            this.barList1 = [];
                            this.barList2 = [];
                            this.barList3 = [];
                            this.barList4 = [];
                            this.barList5 = [];
                            this.barList6 = [];
                            this.xdata = [
                                "00:00",
                                "01:00",
                                "02:00",
                                "03:00",
                                "04:00",
                                "05:00",
                                "06:00",
                                "07:00",
                                "08:00",
                                "09:00",
                                "10:00",
                                "11:00",
                                "12:00",
                                "13:00",
                                "14:00",
                                "15:00",
                                "16:00",
                                "17:00",
                                "18:00",
                                "19:00",
                                "20:00",
                                "21:00",
                                "22:00",
                                "23:00",
                            ];
                            this.yMax = 0;
                            this.drawLine1();
                        }
                    } else {
                        this.barList1 = [];
                        this.barList2 = [];
                        this.barList3 = [];
                        this.barList4 = [];
                        this.barList5 = [];
                        this.barList6 = [];
                        this.xdata = [
                            "00:00",
                            "01:00",
                            "02:00",
                            "03:00",
                            "04:00",
                            "05:00",
                            "06:00",
                            "07:00",
                            "08:00",
                            "09:00",
                            "10:00",
                            "11:00",
                            "12:00",
                            "13:00",
                            "14:00",
                            "15:00",
                            "16:00",
                            "17:00",
                            "18:00",
                            "19:00",
                            "20:00",
                            "21:00",
                            "22:00",
                            "23:00",
                        ];
                        this.yMax = 0;
                        this.drawLine1();
                    }
                }).finally(() => {
                  this.loading = false
                });
        },
        ceilNumber (number) {
            let bite = 0;
            if (number < 10) {
                return 10;
            }
            while (number >= 10) {
                number /= 10;
                bite += 1;
            }
            return Math.ceil(number) * Math.pow(10, bite);
        },
        getXdata (len) {
            this.xdata = [];
            // let date = new Date();
            let m = this.dateSpan.split("-")[1];
            // if(m < 10){
            //   m = '0' + m;
            // }
            for (let i = 1; i < len + 1; i++) {
                if (i < 10) {
                    i = "0" + i;
                }
                let item = m + "-" + i;
                this.xdata.push(item);
            }
        },
        handleChangeType (val) {
            this.drawLine1();
        },
        drawLine1 () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart17"));
            let workList = [];
            let restList = [];
            let tipList = [];
            let peakList = [];
            let flatList = [];
            let valleyList = [];
            let value = "";
            let month = this.dateSpan.slice(5) + "-";
            value = month;
            workList = this.barList1;
            restList = this.barList2;
            tipList = this.barList3;
            peakList = this.barList4;
            flatList = this.barList5;
            valleyList = this.barList6;
            let yMax = 0;
            for (let i = 0; i < workList.length; i++) {
                const item = workList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            for (let i = 0; i < restList.length; i++) {
                const item = restList[i];
                if (item > yMax) {
                    yMax = item;
                }
            }
            this.yMax = this.ceilNumber(yMax);
            // console.log(workList);
            let length = workList.length;
            if (length > 0) {
                this.getXdata(length);
            }
            this.seriesData[0][0].data = workList;
            this.seriesData[0][1].data = restList;
            this.seriesData[1][0].data = tipList;
            this.seriesData[1][1].data = peakList;
            this.seriesData[1][2].data = flatList;
            this.seriesData[1][3].data = valleyList;
            // 绘制图表
            myChart.setOption(
                {
                    color: ["#1EE7E7", "#1890FF", '#3673E8', '#61B8FF', '#F5B763', '#1de1e2'],
                    legend: {
                        orient: "horizontal",
                        x: "center", //可设定图例在左、右、居中
                        y: "bottom", //可设定图例在上、下、居中
                        padding: [0, 0, 25, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                        textStyle: { color: "#fff" },
                        data: ["上班时间", "休息时间", "尖", "峰", "平", "谷"],
                    },
                    tooltip: {
                        trigger: "axis",
                    },
                    xAxis: {
                        type: "category",
                        interval: 0,
                        axisLabel: {
                            textStyle: {
                                color: "#fff",
                            },
                        },
                        // x轴数据
                        data: this.xdata,
                    },
                    yAxis: [
                        {
                            name: "单位:kWh",
                            min: 0,
                            max: this.yMax == 0 ? 10 : this.yMax,
                            interval: this.yMax / 5,
                            axisLabel: {
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: "dashed",
                                    color: "rgba(230,247,255,0.2)",
                                },
                            },
                        },
                    ],
                    grid: {
                        x: 25,
                        x2: 20,
                        y: 85,
                        y2: 50,
                        containLabel: true,
                    },
                    series: this.seriesData[this.typeValue]
                },
                true
            );
            // myChart._dom.childNodes[0].childNodes[0].style.width = "118.5rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "43.6rem";
        },
    },
    computed: {
        ...mapState({
            getBarWorkorRest: (state) => {
                return state.elecManagement.getBarWorkorRest.data;
            },
        }),
    },
    watch: {
        "$store.state.storage.localtionId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                // console.log("执行了=======");
                this.dataForm.localtionId = newVal;
                this.getThisMonth();
                this.getSelectTime();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.c1 {
    width: 100%;
    //   width: 118.5rem;
    height: 43.6rem;
}
.select-time {
    position: absolute;
    top: 1rem;
    right: 14.5rem;
    .time {
        font-size: 1.4rem;
        color: #fff;
    }
    .seleWid {
        width: 15rem;
    }
}
.select-type {
    position: absolute;
    top: 1rem;
    right: 35rem;
}
.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255, 255, 255, 0.8); /* 半透明白色背景，可根据需要调整透明度 */
  display: flex;
  justify-content: center;
  align-items: center; /* 居中对齐加载图标 */
  z-index: 9999; /* 确保遮罩层在最上层 */
}
/* 如果需要针对特定元素（如Table）定位，可以调整position为relative的父容器 */
.loading-mask-parent {
  position: relative;
}
</style>
