<template>
  <div class="dcBarChart">
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabList" :key="item.id" :label="item.name" :name="item.id"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 直流侧历史数据柱状图 -->
    <div id="powerHistory" class="c1"></div>
    <!-- <div class="monthEner">
      <p>本月用电趋势</p>
    </div>-->
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      barList: [],
      dataForm: {
        customerId: "1448924021822775297",
      },
      ydata: "",
      series: "",
      unit: "",
      legendData: "",
      tabList: [],
      activeName: "0",
      seriesData: "",
      yMax: 10,
    };
  },
  props: ["inverterId", "type", "date"],
  computed: {
    // ...mapState({
    //   queryTrendMouth: state => {
    //     return state.elecManagement.queryTrendMouth.data;
    //   }
    // })
  },
  mounted() {
    // this.initData();
  },
  methods: {
    init() {
      this.drawLine1();
    },
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    initData() {

      let value = {
        inverterId: this.inverterId,
        type: this.type,
        date:  this.date,
      };
      this.$store.dispatch("getStraitData", value).then(() => {
        if (this.$store.state.genManagement.getStraitData.errorCode == 0) {
          this.ydata = this.$store.state.genManagement.getStraitData.data;
          if (this.ydata !== null && this.ydata.length > 0) {
            if (this.ydata[0].data !== null && this.ydata[0].data.length > 0) {
              this.seriesData = this.ydata[this.activeName].data;
            } else {
              this.seriesData = [];
            }
          } else {
            this.seriesData = [];
          }
          this.tabList = [];
          for (let i = 0; i < this.ydata.length; i++) {
            let obj = {
              id: i.toString(),
              name: this.ydata[i].name,
            };
            this.tabList.push(obj);
          }
          // this.$nextTick(() => {
          //   this.activeName = this.tabList[0].id;
          // });
          if (this.type == 1) {
            this.unit = "单位：V";
          } else if (this.type == 2) {
            this.unit = "单位：A";
          } else if (this.type == 3) {
            this.unit = "单位：kW";
          }
          this.drawLine1();
        } else {
          this.seriesData = [];
          this.drawLine1();
        }
      });
    },
    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("powerHistory"));
      let yMax = 0;
      if (this.seriesData.length > 0) {
        for (let i = 0; i < this.seriesData.length; i++) {
          if (this.seriesData[i] > yMax) {
            yMax = this.seriesData[i];
          }
        }
        this.yMax = this.ceilNumber(yMax);
      } else {
        this.yMax = 10;
      }
      // 绘制图表
      myChart.setOption(
        {
          title: {
            itemGap: 10,
            textStyle: {
              color: "#37A5DD",
              fontSize: 14,
              lineHeight: 10,
              fontWeight: 400,
            },
            subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
          },
          legend: {
            orient: "horizontal", //一排或者一列
            x: "center", //可设定图例在左、右、居中
            y: "bottom", //可设定图例在上、下、居中
            icon: "circle", // 图例形状
            padding: [0, 0, 28, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
            // borderColor: "#fff",
            textStyle: { color: "rgba(255, 255, 255, 0.85)" },
            // data: ["办公楼"],
            // borderColor: 'rgba(178,34,34,0.8)'
          },
          tooltip: {},
          grid: {
            x: 10,
            x2: 20,
            y2: 15,
            y: 65,
            containLabel: true,
          },
          xAxis: {
            type: "category",
            interval: 0,
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            //x轴的数据格式
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
              "24:00",
            ],
          },
          yAxis: [
            {
              min: 0,
              max: this.yMax,
              interval: this.yMax / 5,
              name: this.unit,
              nameTextStyle: {
                fontSize: 12,
                color: "rgba(230, 247, 255, 0.7)",
                // align: "right",
              },
              axisLabel: {
                textStyle: {
                  color: "#fff",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(230,247,255,0.2)",
                },
              },
            },
          ],
          series: [
            {
              type: "bar",
              barWidth: 10,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#1EE7E7" },
                  { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
                ]),
              },
              data: this.seriesData,
            },
          ],
        },
        true
      );
      myChart._dom.childNodes[0].childNodes[0].style.width = "87.5rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "38rem";
    },
    handleClick(tab, event) {
      // console.log(this.activeName);
      let id = this.activeName;
      this.seriesData = this.ydata[id].data;
      this.drawLine1();
    },
  },
  watch: {
    date: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.date = newVal;
        this.initData();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.c1 {
  width: 87.5rem;
  height: 38rem;
  position: relative;
}
.monthEner {
  p {
    position: absolute;
    top: 2.5rem;
    left: 2rem;
    font-size: 1.6rem;
    color: #37a5dd;
  }
}
</style>
<style lang="less">
.dcBarChart {
  .el-tabs__header {
    margin: 0 0 0.1rem !important;
  }
}
</style>
