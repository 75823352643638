<template>
    <div class="ChargeMonitoringOutsider">
        <div class="ChargeMonitoring">
            <!-- 中部区域######################################################################################################################## -->
            <div class="middlePlace">
                <!--板块一（中间顶部卡片板块）-------------------------------------------------------------->
                <div class="blockOne">
                    <div v-for="(item,index) in blockOneData" :key='index' class="cardInside">
                        <div class="cardTop">
                            <div class="dot1" :style="{ backgroundColor: item.color }"></div>
                            <div class="word1" :style="{ color: item.color }">{{ item.title }}</div>
                        </div>
                        <div class="cardBody">
                            <div class="word1">汽车充电桩</div>
                            <div class="word2">{{ item.num1 }}</div>
                            <div class="word3">个</div>
                        </div>
                        <div class="cardBody">
                            <div class="word1">电动自行车充电桩</div>
                            <div class="word2">{{ item.num2 }}</div>
                            <div class="word3">个</div>
                        </div>
                    </div>
                </div>
                <!--板块二（中间下方的表格）--------------------------------------------------------------->
                <div class="blockTwo">
                    <!-- 顶部搜索框和选择框 -->
                    <div class="topBar">
                        <el-input size="small" placeholder="请输入充电桩编号" v-model="searchValue" @keyup.enter.native="search" style="width:16.8rem;" clearable @clear="search"></el-input>
                        <!-- 状态选择框 -->
                        <el-select size="small" @change="statusSelectChanged" placeholder="请选择在线状态" style="width:16.8rem;margin-left:1.6rem" v-model="statusData">
                            <el-option label="所有在线状态" value=""></el-option>
                            <el-option label="离线" value="0"></el-option>
                            <el-option label="在线" value="1"></el-option>
                        </el-select>
                        <!-- 类型选择框 -->
                        <el-select size="small" @change="typeSelectChanged" placeholder="请选择设备类型" style="width:16.8rem;margin-left:1.6rem" v-model="typeData">
                            <el-option label="所有设备类型" value=""></el-option>
                            <el-option label="电动自行车充电桩" value="0"></el-option>
                            <el-option label="汽车充电桩" value="1"></el-option>
                        </el-select>
                        <!-- 查询按钮 -->
                        <button class="btn_save" style="margin-left:1.6rem;border-radius:0.2rem" @click="search">查 询</button>
                        <!-- 右侧图标 -->
                        <img v-show="ifIconShow == 2 " src="@/assets/elecCharge/type2Grey.png" @click="chooseIcon2" class="img1" alt="">
                        <img v-show="ifIconShow == 1 " src="@/assets/elecCharge/type2Green.png" class="img1" alt="">
                        <img v-show="ifIconShow == 1 " src="@/assets/elecCharge/type1Grey.png" @click="chooseIcon1" class="img2" alt="">
                        <img v-show="ifIconShow == 2 " src="@/assets/elecCharge/type1Green.png" class="img2" alt="">
                    </div>

                    <!-- 中间表格和卡片区域 -->
                    <div v-show="ifIconShow==2" class="contentPlaceOne">
                        <PageInside1 ref="childOne" />
                    </div>
                    <div v-show="ifIconShow==1" class="contentPlaceTwo">
                        <PageInside2 ref="childTwo" />
                    </div>

                </div>
            </div>
            <!-- 右侧区域################################################################################################################################################## -->
            <div class="rightPlace">
                <!--板块三（右侧卡片区域）---------------------------------------------------------------->
                <div class="blockThree">
                    <div class="cardLarge1">
                        <img src="@/assets/elecCharge/iconXXXL.png" alt="">
                    </div>
                    <!-- 卡1 -->
                    <div class="cardMiddle cardMiddle1">
                        <div class="topPlace">充电桩数量</div>
                        <div class="mainPlace">
                            <div class="leftPlace2">
                                <div>汽车充电桩</div>
                                <div style="height:4.4rem;line-height:5.4rem;font-size:2.4rem;color:#fff">
                                    {{rightCardData[0].num1}}&nbsp;<span style="font-size:1.4rem;color:rgba(255, 255, 255 , 0.65)">个</span>
                                </div>
                            </div>
                            <div class="rightPlace2">
                                <div>电动自行车充电桩</div>
                                <div style="height:4.4rem;line-height:5.4rem;font-size:2.4rem;color:#fff">
                                    {{rightCardData[0].num2}}&nbsp;<span style="font-size:1.4rem;color:rgba(255, 255, 255 , 0.65)">个</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 卡2 -->
                    <div class="cardMiddle cardMiddle2">
                        <div class="topPlace">在线数量</div>
                        <div class="mainPlace">
                            <div class="leftPlace2">
                                <div>汽车充电桩</div>
                                <div style="height:4.4rem;line-height:5.4rem;font-size:2.4rem;color:#fff">
                                    {{rightCardData[1].num1}}&nbsp;<span style="font-size:1.4rem;color:rgba(255, 255, 255 , 0.65)">个</span>
                                </div>
                            </div>
                            <div class="rightPlace2">
                                <div>电动自行车充电桩</div>
                                <div style="height:4.4rem;line-height:5.4rem;font-size:2.4rem;color:#fff">
                                    {{rightCardData[1].num2}}&nbsp;<span style="font-size:1.4rem;color:rgba(255, 255, 255 , 0.65)">个</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 卡3 -->
                    <div class="cardMiddle cardMiddle3">
                        <div class="topPlace">失联数量</div>
                        <div class="mainPlace">
                            <div class="leftPlace2">
                                <div>汽车充电桩</div>
                                <div style="height:4.4rem;line-height:5.4rem;font-size:2.4rem;color:#fff">
                                    {{rightCardData[2].num1}}&nbsp;<span style="font-size:1.4rem;color:rgba(255, 255, 255 , 0.65)">个</span>
                                </div>
                            </div>
                            <div class="rightPlace2">
                                <div>电动自行车充电桩</div>
                                <div style="height:4.4rem;line-height:5.4rem;font-size:2.4rem;color:#fff">
                                    {{rightCardData[2].num2}}&nbsp;<span style="font-size:1.4rem;color:rgba(255, 255, 255 , 0.65)">个</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 底部空白 -->
            <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
        </div>
    </div>
</template>

<script>
import PageInside1 from "./ChargeMonitoring/PageInside1.vue"
import PageInside2 from "./ChargeMonitoring/PageInside2.vue"
export default {
    name: 'ChargeMonitoring',
    components: { PageInside1, PageInside2 },
    data () {
        return {
            // 驴充充的token令牌
            chargeToken: '',
            // 板块一卡片数据
            blockOneData: [
                { title: "充电桩回路", color: "rgba(255, 255, 255 , 0.85)", num1: 1, num2: 1 },
                { title: "充电中回路", color: "#17d8c7", num1: 2, num2: 2 },
                { title: "空闲回路", color: "#e6c26b", num1: 3, num2: 3 },
                { title: "故障回路", color: "#ff6e29", num1: 0, num2: 0 },
                { title: "离线回路", color: "#83a9d6", num1: 0, num2: 0 },
            ],
            // 右侧卡片数据
            rightCardData: [
                { num1: "0", num2: "0" },
                { num1: "0", num2: "0" },
                { num1: "0", num2: "0" },
            ],
            // 电站的id双向绑定，也是小区ID
            estateId: '',
            // 搜索的数据
            searchValue: "",
            // 下拉的双向绑定（状态,类型,电站）
            statusData: "",
            typeData: "",

            // 电站选择数据绑定
            stationData: "",
            stationList: [],
            // 图标的选择(默认显示卡片)
            ifIconShow: 1,
            // 地址
            address: "----",
        }
    },

    created () {
        // this.$bus.$emit('refreshTheChargeTree')
        // this.chargingPileCard()
        // this.getStationList()
        // 清除同名的所有时间总线，自己和其他兄弟模块的
        this.$bus.$off('chargeForTree')
    },

    mounted () {
        this.$bus.$on('chargeForTree', (val) => {
            if (val == []) { alert('该用户没有充电业务'); return }
            this.chargeToken = val
            this.chargingPileCard()
            this.$refs.childTwo.tokenIn(val)
            // if (this.ifIconShow == 1) { this.$refs.childOne.tokenIn(val) }
            // if (this.ifIconShow == 2) { this.$refs.childTwo.tokenIn(val) }
        })
    },

    beforeDestroy () {
        this.$bus.$emit('refreshTheChargeTree')
    },

    methods: {
        // 获取电站列表
        // getStationList(){
        //   this.$store.dispatch('getElecChargeStationList',{chargeToken:this.chargeToken}).then(()=>{
        //     this.stationList = []
        //     for(let i=0; i<this.$store.state.elecCharge.stationList.data.length;i++){
        //       const singel = {label:'',value:'',address:''};
        //       singel.label = this.$store.state.elecCharge.stationList.data[i].name
        //       singel.value = this.$store.state.elecCharge.stationList.data[i].estateId
        //       singel.address = this.$store.state.elecCharge.stationList.data[i].address
        //       this.stationList.push(singel)
        //     }
        //   })
        // },

        // 充电桩卡片渲染
        chargingPileCard () {
            this.$store.dispatch('getChargeMonitoringPageData', { chargeToken: this.chargeToken, estateId: this.estateId }).then(() => {
                const res = this.$store.state.elecCharge.chargeMonitoringPageData
                if (res.errorCode == 0) {
                    this.rightCardData[0].num1 = res.data.rechargeNum.automobileCharging
                    this.rightCardData[0].num2 = res.data.rechargeNum.cyclingCharging
                    this.rightCardData[1].num1 = res.data.onlineNum.automobileCharging
                    this.rightCardData[1].num2 = res.data.onlineNum.cyclingCharging
                    this.rightCardData[2].num1 = res.data.lossOfNum.automobileCharging
                    this.rightCardData[2].num2 = res.data.lossOfNum.cyclingCharging

                    this.blockOneData[0].num1 = res.data.loop.automobileCharging
                    this.blockOneData[0].num2 = res.data.loop.cyclingCharging
                    this.blockOneData[1].num1 = res.data.recharging.automobileCharging
                    this.blockOneData[1].num2 = res.data.recharging.cyclingCharging
                    this.blockOneData[2].num1 = res.data.leisure.automobileCharging
                    this.blockOneData[2].num2 = res.data.leisure.cyclingCharging
                } else {
                    this.$message(res.message)
                }
            })
        },
        // 点击搜索按钮
        search () {
            // if (this.ifIconShow == 1) {
            this.$refs.childOne.getList(this.chargeToken)
            // }
            // if (this.ifIconShow == 2) {
            this.$refs.childTwo.getCard(this.chargeToken)
            // }
        },
        // 更换选择（状态）
        statusSelectChanged () {
            // this.search()
        },
        // 更换选择（类型）
        typeSelectChanged () {
            // this.search()
        },
        // 更换选择（电站）
        stationSelectChanged (val) {
            this.estateId = val
            this.chargingPileCard()
            if (this.$refs.childOne) { this.$refs.childOne.stationIn(val) }
            if (this.$refs.childTwo) { this.$refs.childTwo.stationIn(val) }
            for (var i = 0; i < this.stationList.length; i++) {
                if (this.stationList[i].value == val) {
                    this.address = this.stationList[i].address
                }
            }
        },
        // 选择图标1
        chooseIcon1 () {
            this.ifIconShow = 2
            this.$refs.childOne.getList(this.chargeToken)
        },
        // 选择图标2
        chooseIcon2 () {
            this.ifIconShow = 1
            this.$refs.childTwo.getCard(this.chargeToken)
        }

    },

}
</script>

<style lang="less" scoped>
.ChargeMonitoringOutsider {
    // height: 90rem;
    // width: 143.6rem;
    // overflow: hidden;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    width: calc(100% + 0.6rem);
    height: calc(100vh - 18rem);
}

// 大局板块配置##############################################
.ChargeMonitoring {
    // height: 90.4rem;
    // width: calc(100% + 0.6rem);
    // overflow: hidden;
    // overflow-y: scroll;
    // display: flex;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.middlePlace {
    flex: 1 1 107.3rem;
    height: 88.8rem;
    margin-right: 1.6rem;
    overflow: hidden;
}
.rightPlace {
    flex: 1 1 34.7rem;
    height: 88.8rem;
    background-color: #0d3050;
    border-radius: 0.4rem;
}

.blank {
    width: 145rem;
    height: 25rem;
    color: grey;
    font-size: 1.4rem;
    text-align: center;
    line-height: 20rem;
    border-radius: 0.4rem;
}

// 板块一#################################################################
.blockOne {
    height: 15.8rem;
    width: 100%;
    //   width: 107.3rem;
    padding: 2rem 0 2rem 2.4rem;
    margin-bottom: 1.6rem;
    box-sizing: border-box;
    border-radius: 0.4rem;
    display: flex;
    background-color: #0d3050;
    .cardInside {
        flex: 1;
        height: 11.8rem;
        width: 100%;
        // width: 19.2rem;
        background-color: #0a365c;
        border-radius: 0.4rem;
        margin-right: 1.6rem;
    }
    .cardTop {
        height: 3.8rem;
        width: 16rem;
        margin: 0 1.6rem;
        border-bottom: 0.1rem solid #1f4a72;
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        .dot1 {
            height: 0.6rem;
            width: 0.6rem;
            margin-right: 0.6rem;
            position: absolute;
            top: 1.6rem;
            left: -0.3rem;
            background-color: #fff;
            border-radius: 50%;
        }
        .word1 {
            height: 2.2rem;
            margin-top: 0.8rem;
            line-height: 2.2rem;
            font-size: 1.6rem;
            // font-size: 3.2rem;

            position: absolute;
            top: 0;
            left: 0.9rem;
        }
    }
    .cardBody {
        height: 2rem;
        margin-top: 1.2rem;
        margin-left: 1.6rem;
        width: 16rem;

        font-size: 1.4rem;
        color: #fffffe;
        line-height: 2rem;
        white-space: nowrap;
        position: relative;
        .word1 {
            position: absolute;
            right: 4.8rem;
            top: 0;
            color: rgba(255, 255, 255, 0.65);
        }
        .word2 {
            position: absolute;
            right: 1.4rem;
            top: 0;
            font-weight: 900;
        }
        .word3 {
            position: absolute;
            right: 0;
            top: 0;
            color: rgba(255, 255, 255, 0.65);
        }
    }
}

// 版块二#################################################################
.blockTwo {
    height: 71.4rem;
    width: 100%;
    //   width: 107.3rem;
    border-radius: 0.4rem;
    background-color: #0d3050;
    .topBar {
        height: 7.2rem;
        width: 100%;
        // width: 107.3rem;
        padding: 2rem 2.4rem;
        box-sizing: border-box;
        position: relative;
        .img2 {
            height: 3.2rem;
            width: 3.2rem;
            position: absolute;
            top: 2rem;
            right: 2.4rem;
            cursor: pointer;
        }
        .img1 {
            height: 3.2rem;
            width: 3.2rem;
            position: absolute;
            top: 2rem;
            right: 6.4rem;
            cursor: pointer;
        }
    }
    .contentPlaceOne {
        width: 100%;
        height: 64.2rem;
        overflow: hidden;
        // width: 107.3rem;
    }
    .contentPlaceTwo {
        width: 100%;
        height: 64.2rem;
        // width: 107.3rem;
    }
}

// 板块三#################################################################
.blockThree {
    padding: 0 3.2rem;
    .cardLarge1 {
        height: 13rem;
        // width: 29.9rem;
        text-align: center;
        margin: 2.4rem;
        border-bottom: 0.1rem solid #0e3c65;
        border-radius: 0.4rem;
        img {
            height: 9.6rem;
            width: 9.6rem;
            //   margin-left: 9.4rem;
        }
        .wordPart {
            height: 4rem;
            width: 28.3rem;
            margin-top: 2.7rem;
            font-size: 1.4rem;
            color: rgba(255, 255, 255, 0.65);
            line-height: 2rem;
        }
    }

    .cardMiddle {
        // width: 28.3rem;
        height: 21rem;
        border-radius: 0.4rem;
        margin-bottom: 2.4rem;
        // margin-left: 3.2rem;
        text-align: center;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.65);
        line-height: 2rem;
        white-space: nowrap;
        background: #0a365c;
        .topPlace {
            position: unset !important;
            width: 100%;
            // width: 28.3rem;
            height: 4rem;
            background-color: green;
            border-radius: 0.4rem 0.4rem 0 0;
            font-size: 1.4rem;
            color: #ffffff;
            line-height: 4rem;
            text-align: center;
        }
        .mainPlace {
            display: flex;
            .leftPlace2 {
                flex: 1;
                height: 6.4rem;
                // width: 12.2rem;
                margin-top: 5rem;
                border-right: 0.1rem solid #1f4a72;
                float: left;
            }
            .rightPlace2 {
                flex: 1;
                height: 6.4rem;
                // width: 15.8rem;
                margin-top: 5rem;
                float: right;
            }
        }
    }
    .cardMiddle1 {
        background: linear-gradient(180deg, #07335a 0%, #0a5282 100%);
        .topPlace {
            background: linear-gradient(-90deg, #1890ff, #0d5495);
        }
    }
    .cardMiddle2 {
        .topPlace {
            background: linear-gradient(-90deg, #30af97, #30dbe9);
        }
    }
    .cardMiddle3 {
        .topPlace {
            background: linear-gradient(-90deg, #aa515e, #ff7a00);
        }
    }
}
</style>
