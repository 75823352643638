<!-- 配电监测400V-->
<template>
    <div class="sys_map_400 com_sys_map_tab">
        <gx-preview />
    </div>
</template>

<script>
import GxPreview from "@/components/GxPreview.vue";

export default {
    components: { GxPreview },
    name: "SysMap400v",
    data () {
        return {

        };
    },
};
</script>

<style lang='less' scoped>
.sys_map_400 {
    color: #fff;
    background: rgba(0, 115, 222, 0.25);
    width: 100%;
    height: 100%;
}
</style>
