<template>
  <div class="equipmentGenerate">
    <div class="blockTwo page-main">
      <!-- EquipMeters仪表管理11111111 -->
      <!-- 板块二/左侧 ##########################################################################-->
      <div
        :class="isTreeShow ? 'shrink-open_tree' : 'shrink-close_tree'"
        class="blockTwoLeft page-main-tree"
      >
        <!--el树形控件  -->
        <TreeOne ref="treeId" />
      </div>
      <div
        :class="
          isTreeShow ? 'shrink-open_tree_content' : 'shrink-close_tree_content'
        "
        class="blockTwoRight page-main-content"
      >
        <div class="shrink-btn" @click="isTreeShow = !isTreeShow"></div>
        <!-- nav -->
        <div class="search-box">
          <div class="search marginR displayinline">
            <span class="color fontSize marginR">设备名称：</span>
            <el-input
              v-model="keywords"
              class="eleInput color fontSize"
              size="small"
              clearable
              @clear="generatorPage()"
              @keyup.enter.native="queryData()"
              placeholder="请输入设备名称"
              @change="nameChange"
            ></el-input>
          </div>
          <div class="search marginR displayinline">
            <span class="color fontSize marginR">设备类型：</span>
            <el-select
              style="width: 16.8rem;"
              v-model="typeSearch"
              placeholder="请选择"
              @change="typeChange"
            >
              <el-option
                v-for="item in InputType"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
          </div>
          <button class="btn_save" @click="queryData">
            查询
          </button>
          <div class="btnBox">
            <button
              class="exportButton color1 fontSize marginR"
              :class="id < 10000 ? 'btn2Gray' : ''"
              :disabled="id < 10000"
              @click="importFile"
            >
              导入
            </button>
            <button
              class="exportButton color1 fontSize marginR"
              @click="exportFile"
            >
              导出
            </button>
            <button
              class="btn color fontSize"
              :class="id < 10000 ? 'djBgc' : 'djpink'"
              @click="register()"
            >
              登记
            </button>
          </div>
        </div>
        <div class="equipmentTablePlace">
          <div
            class="color1 fontSize"
            style="padding-left: 2.4rem; margin: 1.6rem 0;"
          >
            设备管理 / 发电设备 / {{ breakName }}
          </div>
          <div class="tableBox">
            <!-- 导航栏的部分 -->
            <div class="navigation color1">
              <div
                class="height borderBottom"
                style="font-size: 1.4rem;"
                :class="'width' + (idx + 1)"
                v-for="(x, idx) in navigation"
                :key="idx"
              >
                {{ x }}
              </div>
              <div class="width9 height borderBottom"><span style="font-size: 1.4rem;">操作</span></div>
            </div>
            <div
              style="font-size: 1.4rem; height: 58rem; overflow-y: scroll;"
              v-if="
                navigationList.records && navigationList.records.length != 0
              "
            >
              <div
                class="navigation color1 border-bototm"
                v-for="(x, index) in navigationList.records"
                :key="index"
              >
                <!-- 序号 -->
                <div class="height sh width1">
                  {{ index + 1 + (pageNum - 1) * pageSize }}
                </div>
                <!-- 编号 -->
                <div class="height sh width2">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="x.code == null ? '' : x.code.toString()"
                    placement="top"
                  >
                    <span>{{ x.code }}</span>
                  </el-tooltip>
                </div>
                <!-- 设备名称 -->
                <div class="height sh width3">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="x.name == null ? '' : x.name.toString()"
                    placement="top"
                  >
                    <span>{{ x.name }}</span>
                  </el-tooltip>
                </div>
                <!-- sn号 -->
                <div class="height sh width4">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="x.serialNum == null ? '' : x.serialNum.toString()"
                    placement="top"
                  >
                    <span>{{ x.serialNum }}</span>
                  </el-tooltip>
                </div>
                <!-- 型号 -->
                <div class="height sh width5">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      x.specification == null ? '' : x.specification.toString()
                    "
                    placement="top"
                  >
                    <!-- {{ x.type == 1 ? "逆变器" : "储能" }} -->
                    <span>{{ x.specification }}</span>
                  </el-tooltip>
                </div>
                <!-- 所属电站 -->
                <div class="height sh width6">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      x.powerStationName == null
                        ? ''
                        : x.powerStationName.toString()
                    "
                    placement="top"
                  >
                    <span>{{ x.powerStationName }}</span>
                  </el-tooltip>
                </div>
                <div class="height sh width7 pad1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      x.powerStationAddr == null
                        ? ''
                        : x.powerStationAddr.toString()
                    "
                    placement="top"
                  >
                    <span>{{ x.powerStationAddr }}</span>
                  </el-tooltip>
                </div>
                <div class="height sh width8 pad2">
                  {{
                    x.type == 1
                      ? '逆变器'
                      : x.type == 2
                      ? '环境监测仪'
                      : '并网电表'
                  }}
                </div>
                <div class="height sh width9 pad3">
                  <span
                    class="color2 fontSize marginR1 cursor"
                    @click="getMoreInfo(x)"
                  >
                    更多
                  </span>
                  <span
                    class="color2 fontSize marginR1 cursor"
                    @click="edit(x)"
                  >
                    编辑
                  </span>
                  <span class="color2 fontSize cursor" @click="deleteList(x)">
                    删除
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页器部分 -->
          <!-- 
            page-size 每一也买你显示的条数
            total 数据的总条数
            current-page 当前页数
            current-change 改变触发当前的页码
            pageSize 改变会触发当前页码
         -->
          <div
            class="pagination"
            style="float: right; margin-right: 2.8rem; margin-top: 1rem;"
            v-if="navigationList.records"
          >
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="navigationList.total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!-- 更多弹框 -->
      <popup
        ref="isPopup"
        :inverterInfoData="allMorelInfo"
        :typeData="typeData"
      ></popup>
      <!-- 编辑and登记弹框 -->
      <edit
        ref="isEdit"
        :id="id"
        :editData="editData"
        :registerOrEdit="registerOrEdit"
        :powerStationList="powerStationList"
      ></edit>
      <!-- 导入dialog弹层 -->
      <DialogImportFile @queryData="queryData" ref="dialogImportRef" />
    </div>
  </div>
</template>

<script>
import TreeOne from './components/TreeOne.vue'
import popup from './components/more.vue'
import edit from './components/edit.vue'
import { mapState } from 'vuex'
import DialogImportFile from '../../components/DialogImportFile.vue'
import { exportGeneratorReport } from '@/api/repair'
export default {
  name: 'EquipMeters',
  components: { TreeOne, popup, edit, DialogImportFile },
  data() {
    return {
      isTreeShow: true,
      input: '',
      // 每页设备数量
      pageSize: 10,
      pageNum: 1,
      breakName: '',
      navigation: [
        '序号',
        '编号',
        '设备名称',
        'SN号',
        '型号',
        '所属电站',
        '电站地址',
        '设备类型',
      ],
      navigationList: {},
      id: '',
      keywords: '', //不能删
      ownerId: '',
      typeSearch: '', //不能删
      allMorelInfo: {}, //不能删
      // <!-- 1逆变器 2环境监测仪 3并网电表 -->
      InputType: [
        {
          name: '全部',
          type: '',
        },
        {
          name: '逆变器',
          type: 1,
        },
        {
          name: '环境监测仪',
          type: 2,
        },
        {
          name: '并网电表',
          type: 3,
        },
      ], //设备类型在头部用来筛选的不能删除
      editData: {}, //传递过去的编辑
      registerOrEdit: '', //这是用来判断进入登记框还是编辑框
      powerStationList: '', // 根据客户id获取电站列表
      typeData: '',
    }
  },
  mounted() {},
  created() {
    this.id = ''
    this.generatorPage()
  },
  computed: {
    ...mapState({
      allUserInfo: (state) => {
        return state.electricity.allUserInfo
      },
    }),
  },
  methods: {
    typeChange() {
      this.queryData()
    },
    nameChange() {
      this.queryData()
    },
    //发送请求##### 导出表格(临时)
    exportFile() {
      let params = {
        keyword: this.keywords,
        ownerId: this.ownerId,
        type: this.typeSearch,
      }
      // this.$store.dispatch("exportGeneratorFile",params );
      exportGeneratorReport(params).then((response) => {
        var blob = new Blob([response], {
          type: 'application/vnd.ms-excel,charset=utf-8',
        }) //创建一个blob对象
        var a = document.createElement('a') //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob) // response is a blob
        a.download = `发电设备.xls` //文件名称
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    },

    //点击《导入》打开公用组件弹层
    importFile() {
      this.$refs.dialogImportRef.show({
        name: 'generator',
        type: this.typeSearch == '' ? 1 : this.typeSearch,
        ownerId: this.ownerId,
      })
    },
    //发送请求##### 被子组件调用，下载示例模板
    downLoad(name, type) {
      let value = {
        name: 'generator',
        type: type,
        ownerId: this.ownerId,
      }
      this.$store.dispatch('getDownloadTemplate', value)
    },
    // 删除发电设备
    deleteList(x) {
      this.$confirm('您确定要执行删除操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // console.log(x.id,'------------------');
          // return false
          this.$store.dispatch('delete', x.id).then(() => {
            console.log(this.$store.state.electricity.allDeleteList)
            this.generatorPage()
          })
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    changeBreakName(data) {
      this.breakName = data
    },
    // 用来获取DOM树节点
    changeTreeNode(e) {
      this.id = e.id || this.ownerId //DOM书上的节点
      // 通过树形结构的id来获取电站地址 ，这是树形结构上获取的id
      this.$store.dispatch('powerstation', e.id).then(() => {
        this.powerStationList = this.$store.state.electricity.allUserInfo.data
      })
      this.pageNum = 1
      this.pageSize = 10
      this.keywords = ''
      this.ownerId = e.id
      this.typeSearch = ''
      this.generatorPage()
    },
    // 不能删 star
    // 电站列表
    generatorPage() {
      this.$store
        .dispatch('generatorPage', {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          keyword: this.keywords,
          ownerId: this.ownerId,
          type: this.typeSearch,
        })
        .then(() => {
          this.navigationList = this.$store.state.electricity.allGeneratorPage.data
        })
    },
    queryData() {
      this.generatorPage()
    },
    handleSizeChange(val) {
      // 每个页面显示的条数
      this.pageSize = val
      this.generatorPage()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.generatorPage()
    },
    // 这个不能删除，新增更多
    getMoreInfo(e) {
      this.allMorelInfo = e
      this.typeData = e.type
      this.$refs.isPopup.isOpen()
      this.$refs.isPopup.getDownInfoList()
    },
    // 登记
    register() {
      if (this.id < 10000) return
      this.registerOrEdit = 1
      this.$refs.isEdit.clearObj()
      this.$refs.isEdit.open()
    },
    // 编辑
    edit(x) {
      // return
      this.editData = x
      this.registerOrEdit = 2
      this.$refs.isEdit.open()
      this.$refs.isEdit.echo()
      this.$refs.isEdit.getDownInfoList()
    },
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
// 板块二##############################################################
.blockTwo {
  height: 96.8rem;
  width: 171.2rem;
  position: relative;
  background-color: #18191a;
}

// 板块二左侧#####################################################
.blockTwoLeft {
  //   overflow: hidden;
  //   width: 26rem;
  //   height: 96.8rem;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
}

// 板块二右侧#####################################################
.blockTwoRight {
  //   width: 142.8rem;
  height: 96.8rem;
  //   position: absolute;
  //   left: 28.4rem;
  //   top: 0;
  // background-color: pink;
}
.equipmentTablePlace {
  height: 86.4rem;
  //   width: 142.8rem;
  width: 100%;
  //   position: absolute;
  //   top: 10.5rem;
  //   left: 28.6rem;
  overflow: hidden;
  background-color: #0d3050;
  // padding: 2.4rem;
  box-sizing: border-box;
}

// 公共样式satr
.color {
  // color: #ffffff;
  color: #c0c4cc;
}
.color1 {
  // color: #ffffff;
  color: #ffffff;
}
.color2 {
  color: #03d9bc;
}
.color3 {
  color: rgba(255, 255, 255, 0.85);
}
.color4 {
  color: #ff4d4f;
}
.fontSize {
  font-size: 1.4rem;
}
.fontSize1 {
  font-size: 1.6rem;
}
.eleInput {
  width: 16.8rem;
  height: 3.2rem;
}
.marginR {
  margin-right: 0.8rem !important;
}
.marginRc {
  margin-right: 0.4rem;
}
.marginTop {
  margin-top: 0.8rem;
}
.marginTop1 {
  margin-top: 2rem;
}
.marginR1 {
  margin-right: 1.2rem;
}
.cursor {
  cursor: pointer;
}
.displayinline {
  display: inline-block;
}
/deep/ .el-input__inner {
  height: 3.2rem !important;
}
/deep/.el-input__icon {
  line-height: 3.2rem;
}
// 公共样式end
// nav satr
.search-box {
  // display: flex;
  // align-items: center;
  padding: 0 2.4rem;
  box-sizing: border-box;
  height: 8rem !important;
  line-height: 8rem;
  background: #0d3050;
  border-radius: 0.4rem;
  width: 100%;
  margin-bottom: 2.4rem;
}
.btn {
  width: 7.4rem;
  height: 3.2rem;
  background-color: #027969;
  border-radius: 0.2rem;
  border: none;
  cursor: pointer;
}
.exportButton {
  width: 7.4rem;
  height: 3.2rem;
  border-radius: 0.2rem;
  border: 0.1rem solid #004579;
  background-color: rgba(0, 115, 222, 0.1);
  cursor: pointer;
}
.btnBox {
  float: right !important;
}
.el-select-dropdown__item {
  padding-left: 2rem !important;
}
.tableBox {
  overflow-y: scroll;
  // margin-top: 2.5rem;
  height: 59rem;
  padding: 0 2.4rem;
  // width: 136.1rem;
  // background-color: pink;
  display: flex;
  flex-direction: column;
}
.navigation {
  display: flex;
  justify-content: space-between;
  // color: rgba(255, 255, 255, 0.85);
  background-color: rgb(12, 55, 94);
}
.grey {
  color: rgba(255, 255, 255, 0.85);
}
.borderBottom {
  border-bottom: 0.1rem solid rgba(108, 184, 255, 0.3);
  background: rgba(52, 150, 241, 0.15);
  // margin-right: 0.1rem;
}
.border-bototm {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}
.height {
  height: 5.2rem;
  text-align: left;
  line-height: 5.2rem;
  box-sizing: border-box;
  padding-left: 0.8rem;
  // margin-right: 0.1rem;
}

.bgc1 {
  background: linear-gradient(90deg, #0d3050 0%, rgba(0, 10, 20, 0.7) 100%);
}
.bgc2 {
  background-color: #027969 !important;
}
// overflow: hidden;
// text-overflow: ellipsis;
// white-space: nowrap;
.width1 {
  width: 5%;
}
.width2 {
  width: 10%;
}
.width3 {
  width: 15%;
}
.width4 {
  width: 10%;
}
.width5 {
  width: 10%;
}
.width6 {
  width: 10%;
}
.width7 {
  width: 15%;
}
.width8 {
  width: 10%;
}
.width9 {
  width: 15%;
}
.pad1 {
  padding-left: 1.2rem;
}
.pad2 {
  padding-left: 1.2rem;
}
.pad3 {
  padding-left: 1.2rem;
}
// .width13 {
//   width: 10rem;
// }
// .width10 {
//   width: 10rem;
// }
// .width11 {
//   width: 12.1rem;
// }
// .width12 {
//   width: 12.6rem;
// }
// .width13 {
//   width: 10rem;
// }
// nav end
/deep/.el-drawer {
  width: 56.8rem !important;
  background: rgba(12, 55, 94, 1);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.content {
  // background-color: pink;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tab {
    background: linear-gradient(
      225deg,
      rgba(15, 36, 55, 0.0001) 0.03%,
      rgba(0, 115, 222, 0.7) 99.9%
    );
    border-radius: 0.4rem 0.4rem 0rem 0rem;
    padding: 1.6rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .imgSize {
      width: 1.31rem;
      height: 1.164rem;
    }
  }
  .subject {
    // flex: 1;
    padding: 0 3.2rem;
  }
}
.subjectSearch {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
}
.sujectINput {
  width: 100%;
  height: 3.2rem;
  border: 0.1rem solid #004579;
  border-radius: 0.2rem;
  outline: none;
  background-color: rgba(0, 115, 222, 0.1);
  color: rgba(255, 255, 255, 0.45);
  padding: 0.5rem 1.2rem;
  box-sizing: border-box;
}
.sujectINput:hover {
  border: 0.1rem solid rgba(0, 115, 222, 0.1) !important;
}
// /deep/.el-dialog__header {
//   display: none;
// }
/deep/.el-dialog__body {
  padding: 0 !important;
}
.cancel {
  width: 7.4rem;
  height: 3.2rem;
  background: rgba(0, 115, 222, 0.1);
  border: 0.1rem solid #004579;
  box-sizing: border-box;
  border-radius: 0.2rem;
  cursor: pointer;
}
/deep/.el-select {
  width: 100%;
}
.blockTwo {
  .el-dialog__header {
    // display: none;
    padding: 0 2.4rem !important;
  }
}
/deep/.el-dialog__body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/deep/.el-dialog__headerbtn {
  top: 1.7rem;
}
// /deep/.el-select-dropdown__item{
//   padding: 0 2rem !important;
// }
.btn2Gray {
  color: gray;
  cursor: not-allowed;
}
.djBgc {
  background-color: gray;
  color: #fff;
}
.djpink {
  background-color: #027969;
  color: #fff;
}
.sh {
  /*规定段落中的文本不进行换行：*/
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow属性表示规定当文本溢出包含元素时发生的事情;
            ellipsis表示显示省略符号来代表被修剪的文本。*/
  text-overflow: ellipsis;
}
</style>
<style lang="less" scoped>
.equipmentGenerate {
  // .dialogImportFile {
  /deep/ .el-dialog__body {
    padding: 30px 20px !important;
  }
  // }
}
</style>
