<template>
  <div>
       <!-- ---------------------------------------------------------------------右侧板块一（消防单位统计） ---------------------------------------------------------------------->
     <div class="rightOne">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">消防单位统计</div>
      <!-- 图表 -->
      <div class="chartRightOne">
        <img src="@/assets/FullView/icon2.png"  class="iconStyle1" alt="">
        <div class="wordPlace1">
          <div class="upper">监测电表点</div>
          <div class="downer">{{moreData[0]}} <span style="font-size:1.2rem;line-height:2.4rem;color:rgba(255,255,255,0.65);font-weight:400">个</span></div>
        </div>

      </div>
    </div>

    <!-- ---------------------------------------------------------------------右侧板块二（配电单位统计） ---------------------------------------------------------------------->
     <div class="rightTwo">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">配电单位统计</div>
      <!-- 图表 -->
      <div class="chartRightTwo">
        <img src="@/assets/FullView/icon3.png"  class="iconStyle1" alt="">
        <div class="wordPlace1">
          <div class="upper">监测变压器数</div>
          <div class="downer">{{moreData[1]}} <span style="font-size:1.2rem;line-height:2.4rem;color:rgba(255,255,255,0.65);font-weight:400">个</span></div>
        </div>
    
      </div>
    </div>

     <!-- ---------------------------------------------------------------------右侧板块三（分项统计） ---------------------------------------------------------------------->
     <div class="rightThree">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">分项统计</div>
      <!-- 图表 -->
      <div class="chartRightThree" ref="rightThreeRef"></div>
    </div>

    <!-- ---------------------------------------------------------------------右侧板块四（配电监控） ---------------------------------------------------------------------->
    <div class="leftThree">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">配电监控</div>
      <!-- 图表 -->
      <div class='chartLeftThree'>
          <!-- <div class="imgPeiDian1">
            <img src="@/assets/FullView/icon4.png" class="imgInner" alt="">
            <div class="wordInner" style="color:#FAB811">变压器A负载率</div>
            <div class="wordInner2">{{moreData[2]}} <span style="font-size:1.2rem;color:rgba(255,255,255,0.65)"> %</span></div>
          </div>
          <div class="imgPeiDian2">
            <img src="@/assets/FullView/icon3.png" class="imgInner" alt="">
            <div class="wordInner">变压器B负载率</div>
            <div class="wordInner2">{{moreData[3]}} <span style="font-size:1.2rem;color:rgba(255,255,255,0.65)"> %</span></div>
          </div>
          <div class="imgPeiDian3">
            <img src="@/assets/FullView/icon3.png" class="imgInner" alt="">
            <div class="wordInner">变压器C负载率</div>
            <div class="wordInner2">{{moreData[4]}} <span style="font-size:1.2rem;color:rgba(255,255,255,0.65)"> %</span></div>
          </div> -->
          <div v-for="(item, index) in wordInnerList" class="imgPeiDian" :key="index">
            <img src="@/assets/FullView/icon3.png" class="imgInner" alt="">
            <div class="wordInner">{{ item.name }}</div>
            <div class="wordInner2">{{ (item.transformerALoadRate * 100).toFixed(2) }} <span style="font-size:1.2rem;color:rgba(255,255,255,0.65)"> %</span></div>
          </div>
      </div>
    </div>






  </div>
</template>

<script>
export default {
    name:"PeiDianRight",
    props: {
      myData: {
        type: Object,
        default: () => {
          return {}
        },
        require: true
      }
    },
    data(){return{
         // ############################################################################右侧第三个图数据 直方图 发电统计#############################################################################
        optionRightThree:{
          tooltip: {
            extraCssText: "width:11rem;height:13rem",
                backgroundColor:'rgba(0,0,0,0.65)',
                borderColor:'rgba(0,0,0,0.65)',
                color:"#E6F7FF",
                trigger: 'axis',
                axisPointer: {
                type: 'line',
                crossStyle: {
                    color: '#999'
                }
                },
            formatter: function (params) {
                return '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">'+'时间：'+ params[0].name +'<br>' + '空调：' +'<span style="color:#188ffe;">'+ params[0].data +'kWh'+'</span>'
                +'<br>' + '照明插座：' +'<span style="color:#1cd0d3;">'+ params[1].data +'kWh'+'</span>'
                +'<br>' + '动力：' +'<span style="color:#e1e309;">'+ params[2].data +'kWh'+'</span>'
                +'<br>' + '特殊：' +'<span style="color:#ec7e41;">'+ params[3].data +'kWh'+'</span>'+'</span>';
            }    
          },
          legend: {
          itemHeight: this.fontSize(1),
          itemWidth: this.fontSize(1),
          textStyle: { 
            color: '#fff',
            fontSize:this.fontSize(1.2),
            itemGap:0,
          },

          },
          grid: {
            top:'9%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis:{
              type: 'value',
              splitLine:{
                lineStyle:{
                type:'dashed',
                color:"rgba(230, 247, 255, 0.2)"
                }
              },
              // x轴文字
              axisLabel: {
                  interval:0,
                  formatter: '{value}',
                  textStyle: {
                      show:true,
                      fontSize:this.fontSize(1.2),
                      color: 'rgba(230, 247, 255, 0.5)',
                      fontWeight:100,
                  },
              },
          },
          yAxis:{
          type: 'category',
          axisTick: {show: false,},
          data: [],
          // y轴文字
          axisLabel: {
              interval:0,
              formatter: '{value}',
              textStyle: {
                  show:true,
                  fontSize:this.fontSize(1.2),
                  color: 'rgba(230, 247, 255, 0.5)',
                  fontWeight:100,
              },
          },
          // y轴基线
          axisLine: {
              lineStyle: {
                  color: '#bae7ff',
              },
          },

         

          },
          series: [
            {
              name: '空调',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              barWidth:this.fontSize(0.6),
              // 设置渐变色
              itemStyle:{normal: {color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{offset: 0,color: 'rgba(24, 144, 255, 0.35)'},{offset: 1,color: '#1890FF'}]),}},
              data: []
            },
            {
              name: '照明插座',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              barWidth:this.fontSize(0.6),
              // 设置渐变色
              itemStyle:{normal: {color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{offset: 0,color: 'rgba(30, 231, 231, 0.35)'},{offset: 1,color: 'rgba(30, 231, 231, 1)'}]),}},
              data: []
            },
            {
              name: '动力',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              barWidth:this.fontSize(0.6),
              // 设置渐变色
              itemStyle:{normal: {color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{offset: 0,color: 'rgba(248, 248, 1, 0.35)'},{offset: 1,color: 'rgba(248, 248, 1,1)'}]),}},
              data: []
            },
            {
              name: '特殊',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
               barWidth:this.fontSize(0.6),
              // 设置渐变色
              itemStyle:{normal: {color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{offset: 0,color: 'rgba(255, 132, 63, 0.35)'},{offset: 1,color: 'rgba(255, 132, 63, 1)'}]),}},
              data: []
            },
        
           
          ]

        },
        
        //右上角数据和补充板块数据
        moreData:[0,0,0,0,0,0],
  
        // 配电监控
        wordInnerList: [],

        // 补充板块的样式
        styleSup:'blockSupInsider',
        styleSup2:'blockSupInsider2',
    }},
    watch: {
      myData: {
        handler: function(newVal, oldVal) {
          this.show(newVal)
        },
        deep: true,
        // immediate: true
      }
    },

    methods:{
        // 获取屏幕比例，使echarts自适应
        fontSize(res){
            let docEl = document.documentElement,
            clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
            if (!clientWidth) return;
            let fontSize = (clientWidth / 192);
            return res*fontSize;
        },


        // 展示数据
        show(val){
        // 渲染分项统计图
        this.optionRightThree.yAxis.data = val.months;
        this.optionRightThree.series[0].data = val.airConditioner;
        this.optionRightThree.series[1].data = val.lightSocket;
        this.optionRightThree.series[2].data = val.power;
        this.optionRightThree.series[3].data = val.special;
        var myChartRightThree = this.$echarts.init(this.$refs.rightThreeRef);
        myChartRightThree.setOption(this.optionRightThree);
        myChartRightThree._dom.childNodes[0].childNodes[0].style.height="36rem";
        myChartRightThree._dom.childNodes[0].childNodes[0].style.width="37.8rem";

        // 右侧 消防、配电数据
        this.moreData = [
          val.monitoringPoint,
          val.customerUnitTransformer,
          // val.transformerALoadRate,
          // val.transformerBLoadRate,
          // val.transformerCLoadRate,
          // val.transformerDLoadRate,
        ];
        
        if (Array.isArray(val.transformerLoadList)) {
          this.wordInnerList = val.transformerLoadList.slice(0, 4);
        } else {
          this.wordInnerList = [];
        }
        console.log(Array.isArray(val.transformerLoadList), this.wordInnerList)
        
       
        // 补充板块的展示动画
        // setTimeout(()=>{
        //   this.styleSup='blockSupInsiderShow'
        //   this.styleSup2='blockSupInsiderShow2'
        // },1000)
        },
        showAnimation() {
          this.styleSup='blockSupInsider'
          this.styleSup2='blockSupInsider2'
          // 补充板块的展示动画
          setTimeout(()=>{
            this.styleSup='blockSupInsiderShow'
          this.styleSup2='blockSupInsiderShow2'
          },1000)
        }
    },

    mounted(){}
}
</script>

<style lang='less' scoped>

//  ----------------------------------------------------------------------------背景图部分 ----------------------------------------------------------------------
.FullView {
  height:108rem;
  width: 192rem;
  position: absolute;
  top: -8.8rem;
  left: -18.4rem;
  background-color: #16294C;
  .bgimg{
    // height:108rem;
    // width: 192rem;
    height: 129rem;
    width: 230.4rem;

    position: absolute;
    top: 4.4rem;
    left: 0rem;
  }
  .bgimgTitle{
    height: 8rem;
    width: 192rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bgimgHubei0{
    width: 10.82rem;
    height: 6.93rem;
    position: absolute;
    top: 46rem;
    right: 90rem;
    opacity: 0;
  }
  .bgimgHubei{
    width: 108.2rem;
    height: 69.3rem;
    position: absolute;
    top: 10.4rem;
    right: 42rem;
    opacity: 1;
    transition: all 1s;
  }
  .blockTitle{
    height: 5.1rem;
    width: 37.8rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .blockTitleWord{
    position: absolute;
    top: 1.4rem;
    left: 4rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1.6rem;
    color: #fff;
  }

}


//  ----------------------------------------------------------------------------右侧板块一 ----------------------------------------------------------------------
.rightOne{
  height: 13.3rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 2.4rem;
  .chartRightOne{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 8.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}


//  ----------------------------------------------------------------------------右侧板块二 ----------------------------------------------------------------------
.rightTwo{
  height: 13.3rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 17.7rem;
  .chartRightTwo{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 8.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}

//  ----------------------------------------------------------------------------右侧板块二 ----------------------------------------------------------------------
.rightThree{
  height: 41.1rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 32.6rem;
  .chartRightThree{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 36rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}

//  ----------------------------------------------------------------------------右侧板块四 ----------------------------------------------------------------------
.leftThree{
  height: 22.3rem;
  width: 37.8rem;
  position: absolute;
  right: 3.2rem;
  top: 75.3rem;
  .chartLeftThree{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 17.2rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
    padding-top: -0.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    box-sizing: border-box;
    .innerCard{
      height: 3.6rem;
      width: 100%;
      margin-top: 2.2rem;
      position: relative;
      top: 0;
      left: 0;
      .wordPlace{
        height: 2rem;
        color: #E6F7FF;
        font-size: 1.4rem;
        line-height: 2rem;
        .left{
          float: left;
        }
        .right{
          float: right;
        }
      }
      .barPlace{
        margin: 0.2rem 0;
        height: 1rem;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
      .barGet{
        height: 1rem;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #36EAAF 0%, #4BB3D2 100%); 
      }
      .barLine{
        width: 0.2rem;
        height: 1.4rem;
        background-color: #fff;
        position: absolute;
        top: -0.2rem;
      }
    }

    .imgPeiDian{
      width: 50%;
      height: 5.1rem;
      position: relative;
      margin: 1rem 0;
      display: inline-block;
    }
    
    // .imgPeiDian1{
    //   width: 18rem;
    //   height: 5.1rem;
    //   position: absolute;
    //   top: 2rem;
    //   left: 1.6rem;
    // }

    // .imgPeiDian2{
    //   width: 18rem;
    //   height: 5.1rem;
    //   position: absolute;
    //   top: 2rem;
    //   left: 19.8rem;
    // }

    // .imgPeiDian3{
    //   width: 18rem;
    //   height: 5.1rem;
    //   position: absolute;
    //   top: 9.15rem;
    //   left: 1.6rem;
    // }

    // .imgPeiDian4{
    //   width: 18rem;
    //   height: 5.1rem;
    //   position: absolute;
    //   top: 9.15rem;
    //   left: 19.8rem;
    // }

    .imgInner{
      width: 5.4rem;
      height: 5.1rem;
      position: absolute;
      left: 0;
      top: 0;
    }

    .wordInner{
      height: 2rem;
      line-height: 2rem;
      font-size: 1.4rem;
      color: #18FEFE;
      position: absolute;
      left: 6.2rem;
      top: 0;   
    }

    .wordInner2{
      height: 2.4rem;
      line-height: 2.4rem;
      font-size: 2rem;
      color: #FBFBFB;
      position: absolute;
      left: 6.2rem;
      top: 2.6rem;   
      text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    }

  }
}


// 通用样式
.iconStyle1{
  width: 5.4rem;
  height: 5.1rem;
  position: absolute;
  left: 2.5rem;
  top: 1.623rem;
}
.iconStyle2{
  width: 5.4rem;
  height: 5.1rem;
  position: absolute;
  left: 19.6rem;
  top: 1.623rem;
}
.wordPlace1{
  height: 5rem;
  position: absolute;
  top: 1.6rem;
  left: 8.7rem;
  
}
.wordPlace2{
  height: 5rem;
  position: absolute;
  top: 1.6rem;
  left: 25.8rem;
}

.upper{
  height: 2rem;
  line-height: 2rem;
  color: #19dfe5;
  text-shadow: 0rem 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
}
.downer{
  height: 2.4rem;
  font-size: 2rem;
  color: #FBFBFB;
  text-shadow: 0rem 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
  line-height: 2.4rem;
  
}

// -----------------------------------------------------------------------------补充大板块-------------------------------------------
.blockSup{
  position: absolute;
  top: 0;
  right: 43.4rem;
  width: 30.5rem;
  height: 108rem;
  overflow: hidden;
}
// 封装的css 透明卡片
.blockSupInsider{
  position: absolute;
  top: 4.2rem;
  right: -30rem;
  width: 30rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 
}

.blockSupInsiderShow{
  position: absolute;
  top: 4.2rem;
  right: 0rem;
  width: 30rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 
}

.blockSupInsider2{
  position: absolute;
  top: 13.8rem;
  right: -30rem;
  width: 30rem;
  height: 8rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
 
}

.blockSupInsiderShow2{
  position: absolute;
  top: 13.8rem;
  right: 0rem;
  width: 30rem;
  height: 8rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(-110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(-120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(-170deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
 
}

// 右侧附加板块的卡片内部样式布局
.supRightImg{
  width: 8rem;
  height: 5.6rem;
  position: absolute;
  left: 1.7rem;
  top: 1.1rem;
}
.wordUpper{
  height: 2rem;
  color: #18FEFE;
  font-size: 1.4rem;
  line-height: 2rem;
  position: absolute;
  left: 10.1rem;
  top: 1.4rem;
  opacity: 0.85;
  text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
}
.wordDowner{
  position: absolute;
  top: 4rem;
  left: 10.1rem;
  height: 2.4rem;
  line-height: 2.4rem;
  color: #FBFBFB;
  font-size: 2rem;
  text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
}

.word4Red{
  position: absolute;
  left: 20.7rem;
  top: 4.6rem;
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #fe6c2f;
    }
  }


 .word4Green{
   position: absolute;
  left: 20.7rem;
  top: 4.6rem;
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    .word4Inner{
      color: #39e5d0;
    }
  }
</style>