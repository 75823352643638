<template>
  <div>
    <!-- <img src="@/assets/mixImgs/wait.png" alt=""> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Mapping'
}
</script>

<style lang="less" scoped>
  // img{
  //   width: 171.2rem;
  //   height: 96.8rem;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
</style>
