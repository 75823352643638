<template>
  <div>
    <!-- 电站管理的功率图 -->
    <div id="myChart23" class="c1"></div>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  data() {
    return {
      yMax: 100,
      amount: 0,
    };
  },
  props: ["amountObj"],
  computed: {},
  mounted() {},
  watch: {
    amountObj(newVal, oldVal) {
      console.log('this.amount', newVal)
      this.amount = newVal.amount;
      this.yMax = newVal.ymax;
      this.drawLine1();
    },
  },
  methods: {
    init() {
      this.drawLine1();
    },
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart23"));
      // if (this.amount == "" || this.amount == null || this.amount == undefined) {
      //   this.yMax = 100;
      // } else {
        this.yMax = this.ceilNumber(this.yMax);
      // }
      // 绘制图表
      myChart.setOption(
        {
          series: [
            {
              type: "gauge",
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: this.yMax,
              splitNumber: 4,
              axisLine: {
                lineStyle: {
                  width: 10,
                  color: [
                    [0.3, "#37a2da"],
                    [0.7, "#67e0e3"],
                    [1, "#fd666d"],
                  ],
                },
              },
              pointer: {
                icon:
                  "path://M9 84.6523C6.82239 84.6523 5.08686 82.832 5.19064 80.6569L9 0.821386L12.8094 80.6569C12.9131 82.832 11.1776 84.6523 9 84.6523V84.6523Z",
                length: "100%",
                width: 8,
                offsetCenter: [0, "5%"],
              },
              //刻度的颜色文字间距
              axisTick: {
                distance: -30,
                length: 8,
                lineStyle: {
                  color: "#fff",
                  width: 0,
                },
              },
              splitLine: {
                distance: 0,
                length: 0,
                lineStyle: {
                  color: "#fff",
                  width: 3,
                },
              },
              type: "gauge",
              axisLabel: {
                color: "auto",
                distance: -18,
                fontSize: 10,
              },
              //中间的文字
              detail: {
                width: "60%",
                lineHeight: 40,
                height: 40,
                offsetCenter: [0, "45%"],
                valueAnimation: true,
                formatter: function(value) {
                  return "{value|" + value.toFixed(1) + "}{unit| kW}";
                },
                rich: {
                  value: {
                    fontSize: 24,
                    fontWeight: 400,
                    color: "#fff",
                  },
                  unit: {
                    fontSize: 12,
                    color: "#fff",
                    padding: [0, 0, -6, 0],
                  },
                },
              },
              data: [
                {
                  value: this.amount,
                },
              ],
            },
          ],
        },
        true
      );
      myChart._dom.childNodes[0].childNodes[0].style.width = "23.6rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "20.6rem";
    },
  },
};
</script>

<style lang="less" scoped>
.c1 {
  width: 23.6rem;
  height: 20.6rem;
}
</style>
