<template>
  <div class="EngineerOutSider">
    <div class="Engineer">
      <!------------------------------------------------------------------ 上部分板块（工单概况） ---------------------------------->
      <div class="topBlock" id="topBlock">
        <!--顶部  -->
        <div class="topBar">
          <div class="text">
            <div class="word1">工程师工单统计</div>
            <div class="word2">（{{ startTime }} ～ {{ endTime }}）</div>
          </div>

          <!-- 下载的下拉框 -->

          <div class="btn">
            <img
              v-show="topIconShow == 4"
              src="@/assets/repair/icon4Green.png"
              class="img4"
              alt=""
            />
            <img
              v-show="topIconShow !== 4"
              @click="
                topIconShow = 4
                inItTopChartBar()
              "
              src="@/assets/repair/icon4.png"
              class="img4"
              alt=""
            />
            <img
              v-show="topIconShow == 3"
              src="@/assets/repair/icon3Green.png"
              class="img3"
              alt=""
            />
            <img
              v-show="topIconShow !== 3"
              @click="
                topIconShow = 3
                inItTopChartLine()
              "
              src="@/assets/repair/icon3.png"
              class="img3"
              alt=""
            />
            <img
              v-show="topIconShow == 2"
              src="@/assets/repair/icon2Green.png"
              class="img2"
              alt=""
            />
            <img
              v-show="topIconShow !== 2"
              @click="
                topIconShow = 2
                currentPageTop = 1
                inItTopChartTable()
              "
              src="@/assets/repair/icon2.png"
              class="img2"
              alt=""
            />
            <el-dropdown class="imgFuck" @command="downLoadPng">
              <span class="el-dropdown-link">
                <img
                  v-show="topIconShow !== 1"
                  src="@/assets/repair/icon1.png"
                  class="imgFuck"
                  alt=""
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="['top', 'pic', '工程师工单统计']">
                  图片下载
                </el-dropdown-item>
                <el-dropdown-item
                  style="width: 9rem;"
                  :command="['top', 'excel', '工程师工单统计']"
                >
                  Excel下载
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 图和表 ########################################################################-->
        <div
          class="TopChartBar top-chart"
          ref="TopChartBarRef"
          :style="topChartShow == 'bar' ? '' : 'visibility: hidden;'"
        ></div>
        <div
          class="TopChartBar top-chart"
          ref="TopChartLineRef"
          :style="topChartShow == 'line' ? '' : 'visibility: hidden;'"
        ></div>
        <div v-show="topChartShow == 'table'" class="TopChartBar">
          <!-- 头部栏 -->
          <div class="topPlaceTable">
            <div class="inner" style="flex: 68;">序号</div>
            <div class="inner" style="flex: 97;">姓名</div>
            <div class="inner" style="flex: 160;">接收工单总数</div>
            <div class="inner" style="flex: 160;">待处理工单总数</div>
            <div class="inner" style="flex: 160;">处理中工单总数</div>
            <div
              class="inner"
              style="
                flex: 646;
                position: relative;
                top: 0;
                left: 0;
                background-color: #0d3050;
                margin-right: 0;
              "
            >
              <div
                class="inner2"
                style="
                  height: 3rem;
                  width: 64.5rem;
                  margin-bottom: 0.1rem;
                  border-bottom: 0.1rem solid #2e6395;
                "
              >
                实际完成工单总数
              </div>
              <div class="inner2" style="width: 16rem;">换件工单</div>
              <div class="inner2" style="width: 16rem;">修件工单</div>
              <div class="inner2" style="width: 16rem;">调试工单</div>
              <div class="inner2" style="width: 16.2rem;">其他</div>
            </div>
            <div class="inner" style="flex: 367;">完成率</div>
          </div>
          <!-- 中间表格 -->

          <div class="contentPlaceTable">
            <div
              v-for="(item, index) in topData"
              :key="'topData' + index"
              class="innerCard"
            >
              <div class="inner" style="flex: 68;">
                {{ index + 1 + (currentPageTop - 1) * 5 }}
              </div>
              <div class="inner" style="flex: 97;">{{ item.name }}</div>
              <div class="inner" style="flex: 160;">
                {{ item.receptionCount }}
              </div>
              <div class="inner" style="flex: 160;">
                {{ item.pendingCount }}
              </div>
              <div class="inner" style="flex: 160;">
                {{ item.processedSoCount }}
              </div>
              <div class="inner" style="flex: 160;">
                {{ item.replacementOfPartCount }}
              </div>
              <div class="inner" style="flex: 160;">{{ item.repairCount }}</div>
              <div class="inner" style="flex: 160;">{{ item.debugCount }}</div>
              <div class="inner" style="flex: 160;">{{ item.otherCount }}</div>
              <div
                class="inner"
                style="flex: 367; position: relative; top: 0; left: 0;"
              >
                <div class="inner2">{{ (item.ratio * 100).toFixed(0) }}%</div>
                <div class="inner3"></div>
                <div
                  class="inner4"
                  :style="{
                    width: item.ratio * 25 + 'rem',
                    transition: 'all 1s',
                  }"
                ></div>
              </div>
            </div>
          </div>
          <!-- 分页器 -->
          <div class="pagination">
            <el-pagination
              background
              @current-change="handleCurrentChangeTop"
              :current-page="currentPageTop"
              :page-size="5"
              layout="total, prev, pager, next, jumper"
              :total="totalNumForPagenationTop"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!----------------------------------------------------------------- 下部分板块（巡检概况） -------------------------------->
      <div class="bottomBlock" id="bottomBlock">
        <div class="topBar">
          <div class="text">
            <div class="word1">工程师巡检统计</div>
            <div class="word2">（{{ startTime }} ～ {{ endTime }}）</div>
          </div>
          <div class="btn">
            <img
              v-show="bottomIconShow == 4"
              src="@/assets/repair/icon4Green.png"
              class="img4"
              alt=""
            />
            <img
              v-show="bottomIconShow !== 4"
              @click="
                bottomIconShow = 4
                inItBottomChartBar()
              "
              src="@/assets/repair/icon4.png"
              class="img4"
              alt=""
            />
            <img
              v-show="bottomIconShow == 3"
              src="@/assets/repair/icon3Green.png"
              class="img3"
              alt=""
            />
            <img
              v-show="bottomIconShow !== 3"
              @click="
                bottomIconShow = 3
                inItBottomChartLine()
              "
              src="@/assets/repair/icon3.png"
              class="img3"
              alt=""
            />
            <img
              v-show="bottomIconShow == 2"
              src="@/assets/repair/icon2Green.png"
              class="img2"
              alt=""
            />
            <img
              v-show="bottomIconShow !== 2"
              @click="
                bottomIconShow = 2
                currentPageBottom = 1
                inItBottomChartTable()
              "
              src="@/assets/repair/icon2.png"
              class="img2"
              alt=""
            />
            <!-- 下载的下拉框 -->
            <el-dropdown class="imgFuck" @command="downLoadPng">
              <span class="el-dropdown-link">
                <img
                  v-show="topIconShow !== 1"
                  src="@/assets/repair/icon1.png"
                  class="imgFuck"
                  alt=""
                />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="['bottom', 'pic', '工程师巡检统计']"
                >
                  图片下载
                </el-dropdown-item>
                <el-dropdown-item
                  :command="['bottom', 'excel', '工程师巡检统计']"
                >
                  Excel下载
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 图和表 ########################################################################-->
        <div
          class="TopChartBar bottom-chart"
          ref="BottomChartBarRef"
          :style="bottomChartShow == 'bar' ? '' : 'visibility: hidden;'"
        ></div>
        <div
          class="TopChartBar bottom-chart"
          ref="BottomChartLineRef"
          :style="bottomChartShow == 'line' ? '' : 'visibility: hidden;'"
        ></div>
        <div v-show="bottomChartShow == 'table'" class="TopChartBar">
          <div class="transverseTable">
            <div class="titlePlace">
              <div
                class="innerTitle"
                v-for="(item, index) in titleList"
                :key="'titleList' + index"
              >
                {{ item }}
              </div>
            </div>
            <div
              v-for="(item2, index2) in bottomData"
              :key="'num' + index2"
              :class="index2 % 2 == 0 ? 'contentCard1' : 'contentCard2'"
            >
              <div class="contentInner">
                {{ index2 + 1 + (currentPageBottom - 1) * 10 }}
              </div>
              <div class="contentInner">{{ item2.name }}</div>
              <div class="contentInner">{{ item2.planTaskCount }}</div>
              <div class="contentInner">{{ item2.finishTaskCount }}</div>
              <div class="contentInner">{{ item2.pendingTaskCount }}</div>
              <div class="contentInner">{{ item2.inspectingCount }}</div>
              <div class="contentInner">
                {{ (item2.ratio * 100).toFixed(0) }}%
                <div class="chart1"></div>
                <div
                  class="chart2"
                  :style="{
                    width: item2.ratio * 8 + 'rem',
                    transition: 'all 1s',
                  }"
                ></div>
              </div>
            </div>
          </div>
          <!-- 分页器 -->
          <div class="pagination">
            <el-pagination
              background
              @current-change="handleCurrentChangeBottom"
              :current-page="currentPageBottom"
              layout="total, prev, pager, next, jumper"
              :total="totalNumForPagenationBottom"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import html2canvas from 'html2canvas'
import {
  exportEngineerOrderReport,
  exportEngineerInspectionReport,
} from '@/api/repair'
export default {
  name: 'Engineer',
  props: {
    dateList: {
      type: Array,
      default: () => [],
    },
    dateType: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      // 请求获取的数据
      myData: [],
      //时间段
      //  startTime:(new Date().getFullYear()-1) + '-' +(new Date().getMonth()+1>9?new Date().getMonth()+1:'0'+(new Date().getMonth()+1)) +'-'+ (new Date().getDate()>9? new Date().getDate():('0'+new Date().getDate())),
      //   endTime:new Date().getFullYear() + '-' +(new Date().getMonth()+1>9?new Date().getMonth()+1:'0'+(new Date().getMonth()+1)) +'-'+ (new Date().getDate()>9? new Date().getDate():('0'+new Date().getDate())),
      startTime: new Date().getFullYear() + '-01-01',
      endTime: new Date().getFullYear() + '-12-31',

      // 日期选择器的双向绑定
      valueTimePicker: '',
      // 上方的icon状态
      topIconShow: 2,
      // 上方图状态
      topChartShow: 'table',
      // 下方的icon状态
      bottomIconShow: 3,
      // 下方图状态
      bottomChartShow: 'line',
      // 上方的分页器页数
      currentPageTop: 1,
      // 上方的数据总数
      totalNumForPagenationTop: 0,
      // 下方的分页器页数
      currentPageBottom: 1,
      // 下方的数据总数
      totalNumForPagenationBottom: 0,
      // 下方的标题列表
      titleList: [
        '序号',
        '姓名',
        '计划完成巡检任务总数',
        '实际完成巡检任务总数',
        '待巡检的任务总数',
        '巡检中任务总数',
        '完成率',
      ],

      // ############################################################################上方直方图数据#############################################################################
      optionTopChartBar: {
        legend: {
          x: 'middle',
          y: 'bottom',
          left: this.fontSize(70),
          itemHeight: this.fontSize(1),
          itemWidth: this.fontSize(1),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: this.fontSize(1.2),
            itemGap: 0,
          },
        },
        grid: {
          top: 40,
          left: '2.8%',
          right: '1.8%',
          bottom: '16%',
        },
        xAxis: {
          type: 'category',
          // x轴刻度线
          axisTick: { show: false },
          // x轴文字
          axisLabel: {
            interval: 0,
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // x轴基线
          axisLine: {
            lineStyle: {
              color: '#bae7ff',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          name: '（单）',
          // y轴顶部文字
          nameTextStyle: {
            color: 'rgba(230, 247, 255, 0.5)',
            fontSize: this.fontSize(1.2),
            fontWeight: 100,
            padding: [0, 0, 0, -35],
          },
          // y轴文字
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // 分割线样式
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.2)',
            },
          },
        },
        series: [
          {
            name: '接收工单总数',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 1)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '待处理工单总数',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 1)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '处理中工单总数',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 1)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '实际完成工单总数',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 1)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
        ],
        tooltip: {
          extraCssText: 'width:18rem;height:14.5rem',
          backgroundColor: 'rgba(0,0,0,0.65)',
          borderColor: 'rgba(0,0,0,0.65)',
          color: '#E6F7FF',
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' +
              '工程师：' +
              params[0].name +
              '<br>' +
              '接收工单总数： ' +
              '<span style="color:#1de1e2;">' +
              params[0].data +
              '个' +
              '</span>' +
              '<br>' +
              '待处理工单总数： ' +
              '<span style="color:#178dfb;">' +
              params[1].data +
              '个' +
              '</span>' +
              '<br>' +
              '处理中工单总数： ' +
              '<span style="color:#faa95f;">' +
              params[2].data +
              '个' +
              '</span>' +
              '<br>' +
              '实际完成工单总数： ' +
              '<span style="color:#066361;">' +
              params[3].data +
              '个' +
              '</span>' +
              '</span>'
            )
          },
        },
      },

      // ############################################################################上方折线图数据#############################################################################
      optionTopChartLine: {
        legend: {
          x: 'middle',
          y: 'bottom',
          icon: 'circle',
          left: this.fontSize(70),
          itemHeight: this.fontSize(1),
          itemWidth: this.fontSize(1),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: this.fontSize(1.2),
            itemGap: 0,
          },
        },
        grid: {
          top: 40,
          left: '2.8%',
          right: '1.8%',
          bottom: '16%',
        },
        xAxis: {
          type: 'category',
          // x轴刻度线
          axisTick: { show: false },
          // x轴文字
          axisLabel: {
            interval: 0,
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // x轴基线
          axisLine: {
            lineStyle: {
              color: '#bae7ff',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          name: '（单）',
          // y轴顶部文字
          nameTextStyle: {
            color: 'rgba(230, 247, 255, 0.5)',
            fontSize: this.fontSize(1.2),
            fontWeight: 100,
            padding: [0, 0, 0, -35],
          },
          // y轴文字
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // 分割线样式
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.2)',
            },
          },
        },
        series: [
          {
            name: '接收工单总数',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 1)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 0.2)' },
                  { offset: 0.7, color: 'rgba(30, 231, 231, 0)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0)' },
                ]),
              },
            },
          },
          {
            name: '待处理工单总数',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 1)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 0.2)' },
                  { offset: 0.7, color: 'rgba(24, 144, 255, 0)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0)' },
                ]),
              },
            },
          },
          {
            name: '处理中工单总数',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 1)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 0.2)' },
                  { offset: 0.7, color: 'rgba(255, 172, 95, 0)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0)' },
                ]),
              },
            },
          },
          {
            name: '实际完成工单总数',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 1)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 0.2)' },
                  { offset: 0.7, color: 'rgba(2, 121, 105, 0)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0)' },
                ]),
              },
            },
          },
        ],
        tooltip: {
          extraCssText: 'width:16rem;height:14.5rem',
          backgroundColor: 'rgba(0,0,0,0.65)',
          borderColor: 'rgba(0,0,0,0.65)',
          color: '#E6F7FF',
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' +
              '工程师：' +
              params[0].name +
              '<br>' +
              '接收工单总数： ' +
              '<span style="color:#1de1e2;">' +
              params[0].data +
              '个' +
              '</span>' +
              '<br>' +
              '待处理工单总数： ' +
              '<span style="color:#178dfb;">' +
              params[1].data +
              '个' +
              '</span>' +
              '<br>' +
              '处理中工单总数： ' +
              '<span style="color:#faa95f;">' +
              params[2].data +
              '个' +
              '</span>' +
              '<br>' +
              '实际完成工单总数： ' +
              '<span style="color:#066361;">' +
              params[3].data +
              '个' +
              '</span>' +
              '</span>'
            )
          },
        },
      },

      // ############################################################################下方直方图数据#############################################################################
      optionBottomChartBar: {
        legend: {
          x: 'middle',
          y: 'bottom',
          left: this.fontSize(70),
          itemHeight: this.fontSize(1),
          itemWidth: this.fontSize(1),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: this.fontSize(1.2),
            itemGap: 0,
          },
        },
        grid: {
          top: 40,
          left: '2.8%',
          right: '1.8%',
          bottom: '16%',
        },
        xAxis: {
          type: 'category',
          // x轴刻度线
          axisTick: { show: false },
          // x轴文字
          axisLabel: {
            interval: 0,
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // x轴基线
          axisLine: {
            lineStyle: {
              color: '#bae7ff',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          name: '（单）',
          // y轴顶部文字
          nameTextStyle: {
            color: 'rgba(230, 247, 255, 0.5)',
            fontSize: this.fontSize(1.2),
            fontWeight: 100,
            padding: [0, 0, 0, -35],
          },
          // y轴文字
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // 分割线样式
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.2)',
            },
          },
        },
        series: [
          {
            name: '计划完成巡检任务总数',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 1)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '实际完成巡检任务总数',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 1)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '待巡检的任务总数',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 1)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
          {
            name: '巡检中任务总数',
            symbol: 'none',
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 1)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'bar',
          },
        ],
        tooltip: {
          extraCssText: 'width:20rem;height:14.5rem',
          backgroundColor: 'rgba(0,0,0,0.65)',
          borderColor: 'rgba(0,0,0,0.65)',
          color: '#E6F7FF',
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' +
              '工程师：' +
              params[0].name +
              '<br>' +
              '计划完成巡检任务总数： ' +
              '<span style="color:#1de1e2;">' +
              params[0].data +
              '次' +
              '</span>' +
              '<br>' +
              '实际完成巡检任务总数： ' +
              '<span style="color:#178dfb;">' +
              params[1].data +
              '次' +
              '</span>' +
              '<br>' +
              '待巡检的任务总数： ' +
              '<span style="color:#faa95f;">' +
              params[2].data +
              '次' +
              '</span>' +
              '<br>' +
              '巡检中任务总数： ' +
              '<span style="color:#066361;">' +
              params[3].data +
              '次' +
              '</span>' +
              '</span>'
            )
          },
        },
      },

      // ############################################################################下方折线图数据#############################################################################
      optionBottomChartLine: {
        legend: {
          x: 'middle',
          y: 'bottom',
          icon: 'circle',
          left: this.fontSize(70),
          itemHeight: this.fontSize(1),
          itemWidth: this.fontSize(1),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: this.fontSize(1.2),
            itemGap: 0,
          },
        },
        grid: {
          top: 40,
          left: '2.8%',
          right: '1.8%',
          bottom: '16%',
        },
        xAxis: {
          type: 'category',
          // x轴刻度线
          axisTick: { show: false },
          // x轴文字
          axisLabel: {
            interval: 0,
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // x轴基线
          axisLine: {
            lineStyle: {
              color: '#bae7ff',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          name: '（单）',
          // y轴顶部文字
          nameTextStyle: {
            color: 'rgba(230, 247, 255, 0.5)',
            fontSize: this.fontSize(1.2),
            fontWeight: 100,
            padding: [0, 0, 0, -35],
          },
          // y轴文字
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              show: true,
              fontSize: this.fontSize(1.2),
              color: 'rgba(230, 247, 255, 0.5)',
              fontWeight: 100,
            },
          },
          // 分割线样式
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.2)',
            },
          },
        },
        series: [
          {
            name: '计划完成巡检任务总数',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 1)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(30, 231, 231, 0.2)' },
                  { offset: 0.7, color: 'rgba(30, 231, 231, 0)' },
                  { offset: 1, color: 'rgba(30, 231, 231, 0)' },
                ]),
              },
            },
          },
          {
            name: '实际完成巡检任务总数',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 1)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(24, 144, 255, 0.2)' },
                  { offset: 0.7, color: 'rgba(24, 144, 255, 0)' },
                  { offset: 1, color: 'rgba(24, 144, 255, 0)' },
                ]),
              },
            },
          },
          {
            name: '待巡检的任务总数',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 1)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255, 172, 95, 0.2)' },
                  { offset: 0.7, color: 'rgba(255, 172, 95, 0)' },
                  { offset: 1, color: 'rgba(255, 172, 95, 0)' },
                ]),
              },
            },
          },
          {
            name: '巡检中任务总数',
            symbol: 'none',
            smooth: true,
            barWidth: this.fontSize(1),
            color: '#19e6ea',
            // 设置渐变色
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 1)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0.35)' },
                ]),
              },
            },
            data: [],
            type: 'line',
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(2, 121, 105, 0.2)' },
                  { offset: 0.7, color: 'rgba(2, 121, 105, 0)' },
                  { offset: 1, color: 'rgba(2, 121, 105, 0)' },
                ]),
              },
            },
          },
        ],
        tooltip: {
          extraCssText: 'width:20rem;height:14.5rem',
          backgroundColor: 'rgba(0,0,0,0.65)',
          borderColor: 'rgba(0,0,0,0.65)',
          color: '#E6F7FF',
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">' +
              '工程师：' +
              params[0].name +
              '<br>' +
              '计划完成巡检任务总数： ' +
              '<span style="color:#1de1e2;">' +
              params[0].data +
              '次' +
              '</span>' +
              '<br>' +
              '实际完成巡检任务总数： ' +
              '<span style="color:#178dfb;">' +
              params[1].data +
              '次' +
              '</span>' +
              '<br>' +
              '待巡检的任务总数： ' +
              '<span style="color:#faa95f;">' +
              params[2].data +
              '次' +
              '</span>' +
              '<br>' +
              '巡检中任务总数： ' +
              '<span style="color:#066361;">' +
              params[3].data +
              '次' +
              '</span>' +
              '</span>'
            )
          },
        },
      },

      // 上半部分的数据
      topData: [],
      topDataAll: [],

      // 下半部分的数据
      bottomData: [],
      bottomDataAll: [],

      // 时间选择器的数据
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },

  methods: {
    // 转化dom节点为图片下载
    downLoadPng(val) {
      if (val[1] == 'excel') {
        let params = {
          endTime: this.endTime,
          startTime: this.startTime,
          myPage: val[2],
        }
        // this.$store.dispatch('getExportFileRepair', params)
        let func =
          val[2] == '工程师工单统计'
            ? exportEngineerOrderReport
            : exportEngineerInspectionReport
        func(params).then((response) => {
          var blob = new Blob([response], {
            type: 'application/vnd.ms-excel,charset=utf-8',
          }) //创建一个blob对象
          var a = document.createElement('a') //创建一个<a></a>标签
          a.href = URL.createObjectURL(blob) // response is a blob
          if (this.startTime) {
            a.download = `${this.startTime}-${this.endTime}${val[2]}.xls` //文件名称
          } else {
            a.download = `${val[2]}.xls` //文件名称
          }
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
      } else {
        if (val[0] == 'top') {
          var node = document.getElementById('topBlock')
        } else {
          var node = document.getElementById('bottomBlock')
        }
        window.pageYoffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        var width = node.offsetWidth
        var height = node.offsetHeight
        var scale = 4
        html2canvas(node, {
          width: width,
          heigth: height,
          backgroundColor: null,
          dpi: window.devicePixelRatio * 4,
          scale: scale,
          X: 0,
          Y: 0,
          scrollX: -3,
          scrollY: 0,
          useCORS: true,
          allowTaint: true,
        }).then((canvas) => {
          var url = canvas.toDataURL()
          var a = document.createElement('a')
          a.download =
            val[2] +
            this.startTime +
            '至' +
            this.endTime +
            (this.type == 1 ? '月类型' : '日类型')
          var event = new MouseEvent('click')
          a.href = url
          a.dispatchEvent(event)
        })
      }
    },

    // 获取屏幕比例，使echarts自适应
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
      if (!clientWidth) return
      let fontSize = clientWidth / 192
      return res * fontSize
    },

    // 渲染上方的直方图
    inItTopChartBar() {
      this.topChartShow = 'bar'

      this.optionTopChartBar.xAxis.data = this.myData.name
      this.optionTopChartBar.series[0].data = this.myData.receptionCount
      this.optionTopChartBar.series[1].data = this.myData.pendingCount
      this.optionTopChartBar.series[2].data = this.myData.processedSoCount
      this.optionTopChartBar.series[3].data = this.myData.finishCount

      var myChartTopChartBar = this.$echarts.init(this.$refs.TopChartBarRef)
      myChartTopChartBar.clear()
      myChartTopChartBar.setOption(this.optionTopChartBar)
      myChartTopChartBar._dom.childNodes[0].childNodes[0].style.height = '100%'
      myChartTopChartBar._dom.childNodes[0].childNodes[0].style.width =
        '100%'
    },

    // 渲染上方的折线图
    inItTopChartLine() {
      this.topChartShow = 'line'
      this.optionTopChartLine.xAxis.data = this.myData.name
      this.optionTopChartLine.series[0].data = this.myData.receptionCount
      this.optionTopChartLine.series[1].data = this.myData.pendingCount
      this.optionTopChartLine.series[2].data = this.myData.processedSoCount
      this.optionTopChartLine.series[3].data = this.myData.finishCount

      var myChartTopChartLine = this.$echarts.init(this.$refs.TopChartLineRef)
      myChartTopChartLine.clear()
      myChartTopChartLine.setOption(this.optionTopChartLine)
      myChartTopChartLine._dom.childNodes[0].childNodes[0].style.height =
        '100%'
      myChartTopChartLine._dom.childNodes[0].childNodes[0].style.width =
        '100%'
    },

    // 渲染上方表格
    inItTopChartTable() {
      this.topChartShow = 'table'
      this.topDataAll = []
      for (let i = 0; i < this.myData.name.length; i++) {
        var per = {
          name: this.myData.name[i],
          receptionCount: this.myData.receptionCount[i],
          pendingCount: this.myData.pendingCount[i],
          processedSoCount: this.myData.processedSoCount[i],
          replacementOfPartCount: this.myData.replacementOfPartCount[i],
          repairCount: this.myData.repairCount[i],
          debugCount: this.myData.debugCount[i],
          otherCount: this.myData.otherCount[i],
          ratio:
            this.myData.receptionCount[i] == 0
              ? 0
              : this.myData.finishCount[i] / this.myData.receptionCount[i],
        }
        this.topDataAll.push(per)
      }
      this.topData = this.topDataAll.slice(0, 5)
      console.log(this.topDataAll, 'this.topDataAll')
      this.totalNumForPagenationTop = this.topDataAll.length
    },

    // 上方的分页器变动
    handleCurrentChangeTop(val) {
      this.currentPageTop = val
      this.topData = this.topDataAll.slice((val - 1) * 5, 5 + (val - 1) * 5)
    },
    // 下方的分页器变动
    handleCurrentChangeBottom(val) {
      this.currentPageBottom = val
      this.bottomData = this.bottomDataAll.slice(
        (val - 1) * 10,
        10 + (val - 1) * 10,
      )
    },

    //渲染下方的直方图
    inItBottomChartBar() {
      this.bottomChartShow = 'bar'

      this.optionBottomChartBar.xAxis.data = this.myData.name
      this.optionBottomChartBar.series[0].data = this.myData.planTaskCount
      // this.optionBottomChartBar.series[1].data=this.myData.finishCount
      this.optionBottomChartBar.series[1].data = this.myData.finishTaskCount
      this.optionBottomChartBar.series[2].data = this.myData.pendingTaskCount
      this.optionBottomChartBar.series[3].data = this.myData.inspectingCount

      var myChartBottomChartBar = this.$echarts.init(
        this.$refs.BottomChartBarRef,
      )
      myChartBottomChartBar.clear()
      myChartBottomChartBar.setOption(this.optionBottomChartBar)
      myChartBottomChartBar._dom.childNodes[0].childNodes[0].style.height =
        '100%'
      myChartBottomChartBar._dom.childNodes[0].childNodes[0].style.width =
        '100%'
    },

    // 渲染下方的折线图
    inItBottomChartLine() {
      this.bottomChartShow = 'line'

      this.optionBottomChartLine.xAxis.data = this.myData.name
      this.optionBottomChartLine.series[0].data = this.myData.planTaskCount
      // this.optionBottomChartLine.series[1].data=this.myData.finishCount
      this.optionBottomChartLine.series[1].data = this.myData.finishTaskCount
      this.optionBottomChartLine.series[2].data = this.myData.pendingTaskCount
      this.optionBottomChartLine.series[3].data = this.myData.inspectingCount

      var myChartBottomChartLine = this.$echarts.init(
        this.$refs.BottomChartLineRef,
      )
      myChartBottomChartLine.clear()
      myChartBottomChartLine.setOption(this.optionBottomChartLine)
      myChartBottomChartLine._dom.childNodes[0].childNodes[0].style.height =
        '100%'
      myChartBottomChartLine._dom.childNodes[0].childNodes[0].style.width =
        '100%'
    },
    // 渲染下方的表格
    inItBottomChartTable() {
      this.bottomChartShow = 'table'
      this.bottomDataAll = []
      for (let i = 0; i < this.myData.name.length; i++) {
        console.log(
          'this.myData.finishTaskCount[i]',
          this.myData.finishTaskCount[i],
        )
        var per = {
          name: this.myData.name[i],
          planTaskCount: this.myData.planTaskCount[i],
          // finishTaskCount:this.myData.finishCount[i],
          finishTaskCount: this.myData.finishTaskCount[i],
          pendingTaskCount: this.myData.pendingTaskCount[i],
          inspectingCount: this.myData.inspectingCount[i],
          // ratio:(this.myData.planTaskCount[i]==0?0:(this.myData.finishCount[i]/this.myData.planTaskCount[i]))}
          ratio:
            this.myData.planTaskCount[i] == 0
              ? 0
              : this.myData.finishTaskCount[i] / this.myData.planTaskCount[i],
        }
        this.bottomDataAll.push(per)
      }
      this.bottomData = this.bottomDataAll.slice(0, 10)
      this.totalNumForPagenationBottom = this.bottomDataAll.length
    },

    // 更新页面数据
    refreshThePage() {
      this.$store
        .dispatch('getStatisticsEngineer', {
          startTime: this.startTime,
          endTime: this.endTime,
        })
        .then(() => {
          this.myData = this.engineerData
          if (this.topChartShow == 'table') {
            this.inItTopChartTable()
          } else if (this.topChartShow == 'bar') {
            this.inItTopChartBar()
          } else if (this.topChartShow == 'line') {
            this.inItTopChartLine()
          }
          if (this.bottomChartShow == 'table') {
            this.inItBottomChartTable()
          } else if (this.bottomChartShow == 'bar') {
            this.inItBottomChartBar()
          } else if (this.bottomChartShow == 'line') {
            this.inItBottomChartLine()
          }
        })
    },
  },
  watch: {
    dateList: {
      handler(val) {
        this.startTime = val[0] || this.startTime
        this.endTime = val[1] || this.endTime
        this.refreshThePage()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      engineerData: (state) => {
        return state.repair.engineerData.data
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.EngineerOutSider {
  height: 100%;
  width: 171.2rem;
  overflow: hidden;
}

.Engineer {
  height: 100%;
  width: 173rem;
  background-color: #18191a;
  overflow: scroll;
}
// ##############################################################上部分板块#############################
.topBlock {
  position: relative;
  // height: calc((100% - 1.8rem) / 2);
  height: 37rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
}
// ##############################################################下部分板块#############################
.bottomBlock {
  position: relative;
  // height: calc((100% - 1.8rem) / 2);
  height: 39rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  margin-top: 1.8rem;
}

// ##############################################################公用样式#############################
.topBar {
  height: 2.2rem;
  width: 166.4rem;
  padding: 2.4rem 2.4rem 0 2.4rem;
  display: flex;
  justify-content: space-between;
  align-content: center;

  .text {
    .word1 {
      font-size: 1.6rem;
      color: #37a5dd;
      line-height: 2.2rem;
      float: left;
    }
    .word2 {
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.45);
      line-height: 2.4rem;
      float: left;
    }
  }

  .btn {
    display: flex;
    justify-content: space-between;
    width: 12rem;
    img {
      height: 2.4rem;
      width: 2.4rem;
      cursor: pointer;
    }
  }
}

.TopChartBar {
  height: calc(100% - 5.6rem);
  width: 171.2rem;
}

.top-chart,
.bottom-chart {
  position: absolute;
}

// 空板块
.blank {
  height: 25rem;
  width: 172rem;
  color: grey;
  font-size: 1.4rem;
  text-align: center;
  line-height: 10rem;
}

// 定制化表格的样式
.topPlaceTable {
  height: 6.5rem;
  width: 166.4rem;
  margin-left: 2.4rem;
  // background-color: red;
  margin-top: 1rem;
  display: flex;
  .inner {
    height: 6.5rem;
    background-color: #133f68;
    color: #fff;
    font-size: 1.4rem;
    line-height: 6.5rem;
    text-align: center;
    margin-right: 0.1rem;
    border-bottom: 0.1rem solid #2e6395;
    box-sizing: border-box;
  }
  .inner2 {
    background-color: #133f68;
    height: 3.2rem;
    line-height: 3.2rem;
    margin-right: 0.1rem;
    float: left;
    // background-color: aqua;
  }
}
.contentPlaceTable {
  height: calc(100% - 12.1rem);
  width: 166.4rem;
  margin-left: 2.4rem;
  box-sizing: border-box;
  // background-color: aqua;
  .innerCard {
    height: 4rem;
    width: 166.4rem;
    // border-bottom: red 0.1rem solid;
    box-sizing: border-box;
    display: flex;
  }
  .inner {
    height: 3.9rem;
    background-color: #0d3050;
    color: #fff;
    font-size: 1.4rem;
    line-height: 3.9rem;
    text-align: center;
    margin-right: 0.1rem;
    border-bottom: 0.1rem solid #244561;
    box-sizing: border-box;
  }
  .inner2 {
    position: absolute;
    top: 0rem;
    left: 2.8rem;
  }
  .inner3 {
    height: 0.8rem;
    width: 25rem;
    background-color: #244561;
    position: absolute;
    top: 1.6rem;
    left: 8rem;
  }
  .inner4 {
    height: 0.8rem;
    background: linear-gradient(270deg, #1890ff 0%, #1ee7e7 100%);
    position: absolute;
    top: 1.6rem;
    left: 8rem;
  }
}
.pagination {
  float: right;
  margin-top: 1.5rem;
  margin-right: 2.4rem;
}

// 定制化横向表格
.transverseTable {
  height: 28rem;
  width: 166.4rem;
  margin: 1rem 0 0 2.4rem;
  display: flex;
  .titlePlace {
    height: 28rem;
    width: 17.6rem;
    background-color: #133f68;
    .innerTitle {
      height: 4rem;
      width: 17.6rem;
      border-bottom: 0.1rem solid #2e6395;
      box-sizing: border-box;
      color: #fff;
      font-size: 1.4rem;
      line-height: 4rem;
      text-align: left;
      text-indent: 1.6rem;
    }
  }
  .contentCard1 {
    background-color: #0c2d4b;
    height: 28rem;
    width: 14.8rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
  }
  .contentCard2 {
    background-color: #0d3050;
    height: 28rem;
    width: 14.8rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
  }
  .contentInner {
    height: 4rem;
    width: 14.8rem;
    border-bottom: 0.1rem solid #244561;
    box-sizing: border-box;
    color: #fff;
    font-size: 1.4rem;
    line-height: 4rem;
    text-align: left;
    text-indent: 1.6rem;
    position: relative;
    top: 0;
    left: 0;
  }

  .chart1 {
    height: 0.8rem;
    width: 8rem;
    background-color: #244561;
    position: absolute;
    top: 1.6rem;
    left: 6.3rem;
  }
  .chart2 {
    height: 0.8rem;
    background: linear-gradient(270deg, #1890ff 0%, #1ee7e7 100%);
    position: absolute;
    top: 1.6rem;
    left: 6.3rem;
  }
}

.timer {
  height: 3rem;
  width: 27rem;
  position: absolute;
  top: -6rem;
  right: 2.4rem;
  .myDatePicker {
    height: 3rem;
    width: 27rem;
  }
}
// /deep/ .el-input__icon:after {
//     content: '';
//     height: 100%;
//     width: 0;
//     display: none !important;
//     vertical-align: middle;
// }
</style>
