<template>
  <div class="DialogAddGroup">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div v-show="getType === 1" class="equip_title">新增小组</div>
        <div v-show="getType === 2" class="equip_title">编辑小组</div>
      </div>

      <el-form :model="form" class="elForm" :rules="rules" ref="ruleForm">
        <el-form-item label="小组名称" prop="name" style="width: 100%;">
          <el-input v-model="form.name" placeholder="请输入" size="small" clearable></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import { validateInput } from "@/utils/validate";

export default {
  name: "DialogAddGroup",
  mixins: [tableBg],
  data() {
    return {
      // 请求类型（编辑=2，登记=1）
      getType: 0,
      // 展示判断
      dialogFormVisible: false,

      // 表单数据存放处
      form: {
        id: "",
        name: "",
      },
      tableList: [],
      // 校验规则
      rules: {
        name: [validateInput],
      },
    };
  },

  methods: {
    // dialog关闭的回调
    closeDialog(ruleForm) {
      this.formInit();
      this.$refs[ruleForm].resetFields();
    },

    // 取消弹框
    handleCancle(ruleName) {
      this.$refs[ruleName].resetFields();
      this.dialogFormVisible = false;
      this.formInit();
    },

    // from归零
    formInit() {
      this.form = { id: "", name: "" };
    },

    //父组件点击触发
    show() {
      this.dialogFormVisible = true;
      this.getType = 1;
    },

    //父组件触发来进行编辑
    showForEdit(index,row) {
      // console.log(row);
      this.dialogFormVisible = true;
      this.getType = 2;
      this.form.id = row.id;
      this.form.name = row.name;
    },

    // 提交发送请求（一级dialog点击《保存》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          // 发送新增请求
          if (this.getType === 1) {
            let value = {
              name: this.form.name,
            };
            this.$store.dispatch("postWarningTeam", value).then(() => {
              if (this.$store.state.system.postWarningTeam.errorCode == 0) {
                this.$message({ message: "添加小组成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePageLeft();
                this.dialogFormVisible = false;
                this.formInit();
              } else {
                this.$message({
                  message: "添加小组失败，请重试！",
                  type: "warning",
                });
              }
            });
          } else if (this.getType === 2) {
            // 发送编辑请求
            let value = {
              id:this.form.id,
              name: this.form.name,
            };
            this.$store.dispatch("putWarningTeam", value).then(() => {
              if (this.$store.state.system.putWarningTeam.errorCode == 0) {
                this.$message({ message: "编辑小组成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePageLeft();
                this.dialogFormVisible = false;
                this.formInit();
              } else {
                this.$message({
                  message: "编辑小组失败，请重试！",
                  type: "warning",
                });
              }
            });
          }
        } else {
          this.$message.error("小组名称不能为空！");
        }
      });
    },
  },
  //处理state数据
  // computed: {
  //   ...mapState({
  //     ifAddGroupSuccess: (state) => {
  //       return state.repair.ifAddGroupSuccess.errorCode;
  //     },
  //     ifAddGroupError: (state) => {
  //       return state.repair.ifAddGroupSuccess.message;
  //     },
  //     ifEditGroupSuccess: (state) => {
  //       return state.repair.ifEditGroupSuccess.errorCode;
  //     },
  //     ifEditGroupError: (state) => {
  //       return state.repair.ifEditGroupSuccess.message;
  //     },
  //   }),
  // },
};
</script>

<style lang="less" scoped>
.DialogAddGroup {
  overflow: hidden;
  .el-dialog__body {
    height: 60rem;
  }
}

// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
// 树所在的区域###################
.treePlace {
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 80rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;
  z-index: 20000;

  // border: #071828 0.2rem solid;
}
.treePlaceGoAway {
  // opacity: 0;
  display: none;
  // position:relative;
  // top: -54rem;
  // left: 80rem;
  height: 0;
  width: 26rem;
  overflow: hidden;
  // display: none;
  transition: height 0.3s;
}

.btnForMeters {
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}
</style>

<style lang="less">
.DialogAddGroup {
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  .el-table::before {
    opacity: 0;
  }
}
</style>
