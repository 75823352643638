<template>
  <div class="innerDailog3">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <div class="dialog">
        <div class="jianElec">
          <p>尖：</p>
          <div class="timeSelect">
            <div
              v-for="(item, index) in this.secretData.secretList1"
              :key="index"
              :class="['jian1', jianIndex === index ? 'jian2' : '']"
              @mouseover="mouseover1(index)"
              @mouseout="mouseout1(index)"
            >
              <el-time-select
                v-model="item.elecPriceSingel"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
              <span>-</span>
              <el-time-select
                v-model="item.elecPriceSingel1"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
              <div class="delete" v-show="jianIndex === index" @click="deleteTime1(item.id)">
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </div>
            </div>
          </div>

          <div @click="changeAddSelect1" class="addTime">
            <span>+ 添加时间段</span>
          </div>
        </div>
        <div class="fengElec">
          <p>峰：</p>
          <div class="timeSelect">
            <div
              v-for="(item, index) in this.secretData.secretList2"
              :key="index"
              :class="['jian1', fengIndex === index ? 'jian2' : '']"
              @mouseover="mouseover2(index)"
              @mouseout="mouseout2(index)"
            >
              <el-time-select
                v-model="item.elecPriceSingel"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
              <span>-</span>
              <el-time-select
                v-model="item.elecPriceSingel1"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
              <div class="delete" v-show="fengIndex === index" @click="deleteTime2(item.id)">
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </div>
            </div>
          </div>

          <div @click="changeAddSelect2" class="addTime">
            <span>+ 添加时间段</span>
          </div>
        </div>
        <div class="pingElec">
          <p>平：</p>
          <div class="timeSelect">
            <div
              v-for="(item, index) in this.secretData.secretList3"
              :key="index"
              :class="['jian1', pingIndex === index ? 'jian2' : '']"
              @mouseover="mouseover3(index)"
              @mouseout="mouseout3(index)"
            >
              <el-time-select
                v-model="item.elecPriceSingel"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
              <span>-</span>
              <el-time-select
                v-model="item.elecPriceSingel1"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
              <div class="delete" v-show="pingIndex === index" @click="deleteTime3(item.id)">
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </div>
            </div>
          </div>

          <div @click="changeAddSelect3" class="addTime">
            <span>+ 添加时间段</span>
          </div>
        </div>
        <div class="guElec">
          <p>谷：</p>
          <div class="timeSelect">
            <div
              v-for="(item, index) in this.secretData.secretList4"
              :key="index"
              :class="['jian1', guIndex === index ? 'jian2' : '']"
              @mouseover="mouseover4(index)"
              @mouseout="mouseout4(index)"
            >
              <el-time-select
                v-model="item.elecPriceSingel"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
              <span>-</span>
              <el-time-select
                v-model="item.elecPriceSingel1"
                :picker-options="{
                  start: '00:00',
                  step: '01:00',
                  end: '23:00',
                }"
                placeholder="选择时间"
              >
              </el-time-select>
              <div class="delete" v-show="guIndex === index" @click="deleteTime4(item.id)">
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </div>
            </div>
          </div>

          <div @click="changeAddSelect4" class="addTime">
            <span>+ 添加时间段</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="dialogVisible = false">取 消</el-button>
        <el-button class="confirmButton" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "echarts";
export default {
  data() {
    return {
      dialogVisible: false,
      text: 0,

      secretData: {
        secretList1: [],
        secretList2: [],
        secretList3: [],
        secretList4: [],
      },

      dataForm: {
        elecPriceSingel: "8",
        id: 3,
        // elecPriceSingel1:""
      },
      value1: new Date(2016, 9, 10, 18, 40),
      value2: new Date(2016, 9, 10, 18, 40),
      jianIndex: "",
      fengIndex: "",
      pingIndex: "",
      guIndex: "",
      jianId: "",
      fengId: "",
      pingId: "",
      guId: "",
    };
  },
  props: ["radioValue"],
  mounted() {
    this.getInfoData();
  },
  methods: {
    init() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    getInfoData() {
      this.$store.dispatch("getGeneratorSetting",  {
          generator: "ENERGYGENERATOR",
          customerId: this.$store.state.storage.customerId,
        }).then(() => {
        this.dataForm = this.getGeneratorSetting;
        //转成json格式
        let item = JSON.parse(this.dataForm[8].value);
        // let item = JSON.parse(this.dataForm[19].value).value;
        console.log(item);

        //拿到数组
        let jianList = item.jian;
        this.jianId = jianList.length;
        //循环拿到时间裁剪时间格式
        for (let i = 0; i < jianList.length; i++) {
          let arr = jianList[i].time;
          let startTime = arr.split("-")[0];
          let endTime = arr.split("-")[1];
          this.secretData.secretList1.push({
            id: i,
            elecPriceSingel: startTime,
            elecPriceSingel1: endTime,
          });
        }

        let fengList = item.feng;
        this.fengId = fengList.length;
        //循环拿到时间裁剪时间格式
        for (let i = 0; i < fengList.length; i++) {
          let arr = fengList[i].time;
          let startTime = arr.split("-")[0];
          let endTime = arr.split("-")[1];
          this.secretData.secretList2.push({
            id: i,
            elecPriceSingel: startTime,
            elecPriceSingel1: endTime,
          });
        }

        let pingList = item.ping;
        this.pingId = pingList.length;
        //循环拿到时间裁剪时间格式
        for (let i = 0; i < pingList.length; i++) {
          let arr = pingList[i].time;
          let startTime = arr.split("-")[0];
          let endTime = arr.split("-")[1];
          this.secretData.secretList3.push({
            id: i,
            elecPriceSingel: startTime,
            elecPriceSingel1: endTime,
          });
        }

        let guList = item.gu;
        (this.guId = guList), length;
        //循环拿到时间裁剪时间格式
        for (let i = 0; i < guList.length; i++) {
          let arr = guList[i].time;
          let startTime = arr.split("-")[0];
          let endTime = arr.split("-")[1];
          this.secretData.secretList4.push({
            id: i,
            elecPriceSingel: startTime,
            elecPriceSingel1: endTime,
          });
        }
      });
    },
    submit() {
      let id = 50;
      let timeData = {
        jian: [],
        feng: [],
        ping: [],
        gu: [],
      };
      // console.log(this.secretData);
      for (let j = 0; j < this.secretData.secretList1.length; j++) {
        const item = this.secretData.secretList1[j];
        let obj = {
          time: item.elecPriceSingel + "-" + item.elecPriceSingel1,
        };
        timeData.jian.push(obj);
      }
      for (let j = 0; j < this.secretData.secretList2.length; j++) {
        const item = this.secretData.secretList2[j];
        let obj = {
          time: item.elecPriceSingel + "-" + item.elecPriceSingel1,
        };
        timeData.feng.push(obj);
      }
      for (let j = 0; j < this.secretData.secretList3.length; j++) {
        const item = this.secretData.secretList3[j];
        let obj = {
          time: item.elecPriceSingel + "-" + item.elecPriceSingel1,
        };
        timeData.ping.push(obj);
      }
      for (let j = 0; j < this.secretData.secretList4.length; j++) {
        const item = this.secretData.secretList4[j];
        let obj = {
          time: item.elecPriceSingel + "-" + item.elecPriceSingel1,
        };
        timeData.gu.push(obj);
      }
      let id1 = 55;
      let value1 = {
        value: this.radioValue,
      };

      let value = JSON.stringify(timeData);
      this.$store.dispatch("putGenerator", { id: id, data: { value } }).then(() => {
        if (this.putGenerator) {
          this.$message({ message: "编辑成功！", type: "success" });
          this.dialogVisible = false;
          this.$emit("refreshDataList");
        } else {
          this.$message({ message: "编辑失败，请重试！", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
    changeAddSelect1() {
      this.jianId += 1;
      this.secretData.secretList1.push({
        id: this.jianId,
        elecPriceSingel: "",
        elecPriceSinge11: "",
      });
    },
    changeAddSelect2() {
      this.fengId += 1;
      this.secretData.secretList2.push({
        id: this.fengId,
        elecPriceSingel: "",
        elecPriceSinge11: "",
      });
    },
    changeAddSelect3() {
      this.pingId += 1;
      this.secretData.secretList3.push({
        id: this.pingId,
        elecPriceSingel: "",
        elecPriceSinge11: "",
      });
    },
    changeAddSelect4() {
      this.guId += 1;
      this.secretData.secretList4.push({
        id: this.guId,
        elecPriceSingel: "",
        elecPriceSinge11: "",
      });
    },
    deleteTime1(idx) {
      for (let i = 0; i < this.secretData.secretList1.length; i++) {
        const item = this.secretData.secretList1[i];
        if (item.id == idx) {
          this.secretData.secretList1.splice(i, 1);
        }
      }
    },
    deleteTime2(idx) {
      for (let i = 0; i < this.secretData.secretList2.length; i++) {
        const item = this.secretData.secretList2[i];
        if (item.id == idx) {
          this.secretData.secretList2.splice(i, 1);
        }
      }
    },
    deleteTime3(idx) {
      for (let i = 0; i < this.secretData.secretList3.length; i++) {
        const item = this.secretData.secretList3[i];
        if (item.id == idx) {
          this.secretData.secretList3.splice(i, 1);
        }
      }
    },
    deleteTime4(idx) {
      for (let i = 0; i < this.secretData.secretList4.length; i++) {
        const item = this.secretData.secretList4[i];
        if (item.id == idx) {
          this.secretData.secretList4.splice(i, 1);
        }
      }
    },
    mouseover1(index) {
      this.jianIndex = index;
    },
    mouseout1(index) {
      this.jianIndex = "";
    },
    mouseover2(index) {
      this.fengIndex = index;
    },
    mouseout2(index) {
      this.fengIndex = "";
    },
    mouseover3(index) {
      this.pingIndex = index;
    },
    mouseout3(index) {
      this.pingIndex = "";
    },
    mouseover4(index) {
      this.guIndex = index;
    },
    mouseout4(index) {
      this.guIndex = "";
    },
  },
  computed: {
    ...mapState({
      getGeneratorSetting: (state) => {
        return state.genManagement.getGeneratorSetting.data;
      },
      putGenerator: (state) => {
        return state.genManagement.putGenerator.data;
      },
    }),
  },
};
</script>

<style lang="less" scoped>
.innerDailog3 {
  .dialog {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1.6rem;
    .jianElec {
      p {
        font-size: 1.6rem;
        color: #ffffff;
        margin-bottom: 1rem;
        margin-left: 1.5rem;
      }
      .timeSelect {
        padding-top: 1rem;
        .jian1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 28rem;
          padding: 0 3rem 0 1.5rem;
          height: 5rem;
          position: relative;
          .delete {
            position: absolute;
            right: 0.8rem;
            top: 1.4rem;
            color: #fff;
          }
        }
        .jian2 {
          background-color: rgba(13, 50, 83, 0.7);
          border-radius: 0.4rem;
        }
      }

      .addTime {
        cursor: pointer;
        margin-top: 1rem;
        margin-left: 1.5rem;
        width: 28rem;
        height: 3.6rem;
        line-height: 3.6rem;
        border: 0.1rem #2d79be dashed;
        text-align: center;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.45);
      }
    }
    .fengElec {
      p {
        font-size: 1.6rem;
        color: #ffffff;
        margin-bottom: 1rem;
        margin-left: 1.5rem;
      }
      .timeSelect {
        padding-top: 1rem;
        .jian1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 28rem;
          padding: 0 3rem 0 1.5rem;
          height: 5rem;
          position: relative;
          .delete {
            position: absolute;
            right: 0.8rem;
            top: 1.4rem;
            color: #fff;
          }
        }
        .jian2 {
          background-color: rgba(13, 50, 83, 0.7);
          border-radius: 0.4rem;
        }
      }
      .addTime {
        cursor: pointer;
        margin-top: 1rem;
        margin-left: 1.5rem;
        width: 28rem;
        height: 3.6rem;
        line-height: 3.6rem;
        border: 0.1rem #2d79be dashed;
        text-align: center;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.45);
      }
    }
    .pingElec {
      p {
        font-size: 1.6rem;
        color: #ffffff;
        margin-bottom: 1rem;
        margin-left: 1.5rem;
      }
      .timeSelect {
        padding-top: 1rem;
        .jian1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 28rem;
          padding: 0 3rem 0 1.5rem;
          height: 5rem;
          position: relative;
          .delete {
            position: absolute;
            right: 0.8rem;
            top: 1.4rem;
            color: #fff;
          }
        }
        .jian2 {
          background-color: rgba(13, 50, 83, 0.7);
          border-radius: 0.4rem;
        }
      }
      .addTime {
        cursor: pointer;
        margin-top: 1rem;
        margin-left: 1.5rem;
        width: 28rem;
        height: 3.6rem;
        line-height: 3.6rem;
        border: 0.1rem #2d79be dashed;
        text-align: center;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.45);
      }
    }
    .guElec {
      p {
        font-size: 1.6rem;
        color: #ffffff;
        margin-bottom: 1rem;
        margin-left: 1.5rem;
      }
      .timeSelect {
        padding-top: 1rem;
        .jian1 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 28rem;
          padding: 0 3rem 0 1.5rem;
          height: 5rem;
          position: relative;
          .delete {
            position: absolute;
            right: 0.8rem;
            top: 1.4rem;
            color: #fff;
          }
        }
        .jian2 {
          background-color: rgba(13, 50, 83, 0.7);
          border-radius: 0.4rem;
        }
      }
      .addTime {
        cursor: pointer;
        margin-top: 1rem;
        margin-left: 1.5rem;
        width: 28rem;
        height: 3.6rem;
        line-height: 3.6rem;
        border: 0.1rem #2d79be dashed;
        text-align: center;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.45);
      }
    }
  }
}
</style>
<style lang="less">
.innerDailog3 {
  .el-dialog__body {
    padding-left: 1.1rem !important;
  }
  .el-dialog__footer {
    padding-right: 4.2rem;
  }
  // 弹层标题
  .el-dialog .el-dialog__header {
    padding: 0;
    height: 5.4rem;
    line-height: 5.4rem;
    padding-left: 2.4rem;
    font-size: 1.6rem;
    background: linear-gradient(225deg, rgba(15, 36, 55, 0.0001) 0.03%, rgba(0, 115, 222, 0.7) 99.9%);
  }
  // 弹层字体
  .el-dialog__title {
    color: #fff;
  }
  // 按钮样式
  .confirmButton {
    background: #027969;
    color: #fff;
    padding: 0;
    width: 10.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    border: 1px solid #027969;
    cursor: pointer;
  }
  .cancelButton {
    background: #0c375e;
    color: #fff;
    padding: 0;
    width: 10.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    border: 0.1rem solid #004579;
    cursor: pointer;
    margin-left: 1.5rem;
  }
  // 计数器样式
  .el-date-editor {
    width: 13rem !important;
    height: 3.8rem !important;
  }
  .el-input--suffix .el-input__inner {
    padding-right: 0;
  }
  .el-input__icon {
    line-height: 3.8rem;
  }
  .el-input--suffix input {
    width: 13rem;
    height: 3.8rem !important;
  }
  .time-select-item:hover {
    background-color: #0c375e !important;
  }
  .el-input-number__increase {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease {
    background-color: #0b3d6b;
  }
  .el-input-number .is-controls-right {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #0b3d6b;
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid #027969;
    border-left: 0.1rem solid #027969;
    box-shadow: none;
    border: 0.1rem solid #027969;
    box-sizing: border-box;
    border: none;
  }
  ::-webkit-scrollbar,
  .el-select-dropdown::-webkit-scrollbar {
    width: 0;
  }
  .el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 0.4rem 0 0;
    border-bottom: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }

  .el-input-number.is-controls-right .el-input-number__decrease {
    border-left: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }
}
</style>
