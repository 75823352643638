<template>
    <div class="fire_control page-main">
        <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="treePlace page-main-tree">
            <TreeFireControl />
        </div>
        <!-- 右侧内容区################################################################ -->
        <div :class="isTreeShow ? 'shrink-open_tree_content':'shrink-close_tree_content'" class="blockRight page-main-content">
            <div class="shrink-btn" @click="isTreeShow = !isTreeShow">
            </div>
            <div v-if="diagramcode == ''" style="background: rgba(0, 115, 222, 0.35);height:96.8rem;width: 100%;"></div>
            <router-view v-else></router-view>
        </div>
    </div>
</template>

<script>
import TreeFireControl from './components/TreeFireControl.vue'
export default {
    name: 'FireControl',
    components: { TreeFireControl },
    data () {
        return {
            isTreeShow: true,
            diagramcode: ''
        }
    },
    methods: {
        // 点击更换树的节点
        changeTreeNode (data) {
            this.diagramcode = data.id
            this.$router.push({ path: '/fireControl', query: { id: data.id, name: data.name } })
        },
    },
}
</script>

<style lang="less" scoped>
.fire_control {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    //树的外壳
    .treePlace {
        // width: 26rem;
        height: 96.8rem;
        // padding-right: 2.4rem;
    }
    .blockRight {
        flex: 1;
        overflow: hidden;
        height: 96.8rem;
        /deep/ .report-cont {
            height: 75.5rem;
        }
    }
}
</style>
