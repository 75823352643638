<template>
    <!-- 线图 -->
    <div class="dialog_echarts">
        <div ref="myChart" class="charts" id="myChart27"></div>
    </div>
</template>

<script>
export default {
    name: "EchartAreaMap",
    props: {
        optionData: {
            type: Object,
            default: () => { },
        },
        chartsType: {
            type: String,
            default: () => "",
        },
    },
    data () {
        return {
            customerId: "",
            xdata: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
            ydata: "",
        };
    },
    methods: {
        // 销毁echarts
        dispose () {
            this.$echarts.dispose(document.getElementById("myChart27"));
        },
        initData () {
            this.customerId = this.$store.state.storage.customerId;
            this.$store.dispatch("getCurrentGeneration", this.customerId).then(() => {
                this.ydata = this.$store.state.genManagement.getCurrentGeneration.data.data;
                this.init();
            });
        },
        init () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart27"));
            if (myChart == null) {
                myChart = this.$echarts.init(document.getElementById("myChart27"));
            }
            let option = {
                title: {
                    text: "",
                    top: "0px",
                    left: "0px",
                    textStyle: {
                        color: "#37A5DD",
                        fontSize: 14,
                        lineHeight: 10,
                        fontWeight: 400,
                    },
                    subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
                },
                // color: ["#3673E8", "#61B8FF"],
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    show: false,
                    textStyle: {
                        color: "#fff",
                    },
                    bottom: 10,
                    data: this.optionData?.legendData,
                },
                grid: {
                    top: "25%",
                    left: "5.5%",
                    right: "5%",
                    bottom: "6%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "rgba(43, 113, 176, 0.45)",
                        },
                    },
                    axisLabel: {
                        color: "#fafafa",
                        fontSize: "10",
                    },
                    data: this.xdata,
                },
                yAxis: [
                    {
                        name: "单位:kWh",
                        type: "value",
                        axisLabel: {
                            color: "#fafafa",
                            fontSize: "10",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: "#1F4A72",
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "line",
                        smooth: true,
                        showSymbol: false,
                        data: this.ydata,
                    },
                ],
            };
            // 绘制图表
            myChart.setOption(option);
            //   myChart._dom.childNodes[0].childNodes[0].style.width = "44rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "36rem";
        },
    },
    mounted () {
        // this.init()
    },
    watch: {
        "$store.state.storage.customerId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.customerId = newVal;
                this.initData();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.dialog_echarts {
    .charts {
        margin: 0 auto !important;
        width: 44rem;
        height: 35rem;
    }
}
</style>
