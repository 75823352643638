<template>
    <div class="equipmentMeters">
        <div class="blockTwo page-main">
            <!-- EquipMeters仪表管理11111111 -->
            <!-- 板块二/左侧 ##########################################################################-->
            <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="blockTwoLeft page-main-tree">
                <!--el树形控件  -->
                <TreeOne />
            </div>
            <div :class="isTreeShow ? 'shrink-open_tree_content':'shrink-close_tree_content'" class="blockTwoRight page-main-content">
                <div class="shrink-btn" @click="isTreeShow = !isTreeShow"></div>
                <!-- nav -->
                <div class="search-box">
                    <div class="search marginR displayinline">
                        <span class="color fontSize marginR">仪表名称：</span>
                        <el-input class="eleInput color fontSize" size="small" placeholder="请输入仪表名称" v-model="keyWords" clearable @clear="allListPage()" @keyup.enter.native="queryData" @change="queryData"></el-input>
                    </div>
                    <button class="btn_save" @click="queryData">查询</button>
                    <div class="btnBox">
                        <button class="exportButton color1 fontSize marginR" :class="formData.ownerId < 10000 ? 'btn2Gray' : ''" :disabled="formData.ownerId < 10000" @click="importFile">
                            导入
                        </button>
                        <button class="exportButton color1 fontSize marginR" @click="exportFile">
                            导出
                        </button>
                        <button class="btn color fontSize" :class="formData.ownerId < 10000 ? 'djBgc' : 'djpink'" @click="openPopup">
                            登记
                        </button>
                    </div>
                </div>

                <!-- 表格部分 -->
                <div class="equipmentTablePlace">
                    <div class="color1 fontSize" style="padding-left: 2.4rem; margin: 1.6rem 0">
                        设备管理 / 仪表管理 / {{ breakName }}
                    </div>
                    <div class="table">
                        <el-table :data="tableData" stripe:false height="57.8rem" border:false style="width: 100%">
                            <el-table-column label="编号" prop="code"></el-table-column>
                            <el-table-column label="仪表名称" prop="name" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="客户单位" prop="ownerName" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="客户地址" prop="ownerAddr" :show-overflow-tooltip="true"></el-table-column>
                            <el-table-column label="根节点" prop="buildingName"></el-table-column>
                            <el-table-column label="设备" prop="floorName"></el-table-column>
                            <!-- 操作部分 -->
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页器部分 -->
                    <div class="pagination">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <!-- 弹框 star -->
            <el-dialog :visible.sync="dialogVisible" :title="dailogTitle" width="56.8rem" :close-on-click-modal="false" @close="handleClose">
                <el-form ref="ruleForm" :model="formData" :rules="rules" :inline="true" label-position="top" label-width="100px">
                    <el-form-item label="配电仪表" prop="meterId">
                        <el-select v-model="formData.meterId" placeholder="请选择">
                            <el-option v-for="item in meterList" :key="item.id" :label="item.value" :value="item.label" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="编号" prop="code">
                        <el-input v-model="formData.code" placeholder="请输入编号" />
                    </el-form-item>
                    <el-form-item label="仪表名称" prop="name">
                        <el-input v-model="formData.name" placeholder="请输入仪表名称" />
                    </el-form-item>
                    <el-form-item label="根节点" prop="buildingId">
                        <el-select v-model="formData.buildingId" placeholder="请选择" @change="handleChangeRoot">
                            <el-option v-for="item in rootList" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="设备" prop="floorId">
                        <el-select v-model="formData.floorId" placeholder="请选择" @change="handleChangeEquipment">
                            <el-option v-for="item in equipmentList" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="回路" prop="roomId">
                        <el-select v-model="formData.roomId" placeholder="请选择" @change="handleChangeLoop">
                            <el-option v-for="item in loopList" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="loopList2.length > 0" label="二级回路" prop="room2Id">
                        <el-select v-model="formData.room2Id" placeholder="请选择" @change="handleChangeLoop2">
                            <el-option v-for="item in loopList2" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="loopList3.length > 0" label="三级回路" prop="room3Id">
                        <el-select v-model="formData.room3Id" placeholder="请选择" @change="handleChangeLoop3">
                            <el-option v-for="item in loopList3" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="loopList4.length > 0" label="四级回路" prop="room4Id">
                        <el-select v-model="formData.room4Id" placeholder="请选择" @change="handleChangeLoop4">
                            <el-option v-for="item in loopList4" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="客户单位" prop="ownerName">
                        <el-input v-model="formData.ownerName" placeholder="请输入客户单位" readonly />
                    </el-form-item>
                    <el-form-item label="电能最值" prop="maxMetric">
                        <el-input-number v-model="formData.maxMetric" placeholder="请输入电能最值" size="mini" controls-position="right" :min="0"></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <button class="cancel color marginR" @click="dialogVisible = false">
                        取消
                    </button>
                    <button class="cancel color bgc2" @click="handleSave">保存</button>
                </div>
            </el-dialog>
            <!-- 弹框end -->
            <!-- 导入dialog弹层 -->
            <DialogImportFile @queryData="queryData" ref="dialogImportRef" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TreeOne from './components/TreeInstruments.vue'
import DialogImportFile from '../../components/DialogImportFile.vue'
import { exportPedianReport } from "@/api/repair"

export default {
    name: 'EquipMeters',
    components: { TreeOne, DialogImportFile },
    data () {
        return {
            isTreeShow: true,
            breakName: '',
            tableData: [],
            // 分页
            currentPage: 1, // 当前第几页
            pageSize: 10, // 当前显示多少条
            total: 0, // 总条数
            keyWords: '', //搜索关键字
            isEdit: false,
            dialogVisible: false,
            dailogTitle: '登记', //弹框左上角 登记 or 编辑
            formData: {
                sourceType: '1', // 数据来源 0:手动录入 1:配电仪表
                meterId: '', // 配电仪表
                code: '', // 编号
                name: '', // 名称
                type: '2',
                buildingId: '', //根节点id
                locationId: '', // 最后一层回路id
                locationName: '', // 根节点
                floorId: '', // 设备id
                floorName: '', // 设备
                roomId: '', // 回路id
                roomName: '', // 回路
                room2Id: '', //二级回路
                room2Name: '',
                room3Id: '', //三级回路
                room3Name: '',
                room4Id: '', //四级回路
                room4Name: '',
                ownerName: '', //客户单位名称
                ownerId: '', //客户id
                maxMetric: 0,
            },
            meterList: [], // 配电仪表下拉项
            rootList: [], // 根节点下拉项
            equipmentList: [], // 设备下拉项
            loopList: [], // 回路下拉项
            loopList2: [], // 二级回路下拉项
            loopList3: [], // 三级回路下拉项
            loopList4: [], // 四季回路下拉项
            rules: {
                meterId: [
                    { required: true, message: '请选择配电仪表', trigger: 'change' },
                ],
                code: [{ required: true, message: '请输入编号', trigger: 'blur' }],
                name: [{ required: true, message: '请输入仪表名称', trigger: 'blur' }],
                buildingId: [
                    { required: true, message: '请选择根节点', trigger: 'change' },
                ],
                ownerName: [
                    { required: true, message: '请输入客户单位', trigger: 'blur' },
                ],
            },
        }
    },
    //处理state数据
    computed: {
        ...mapState({
            peidianMeterPage: (state) => {
                return state.metrAdministration.peidianMeterPage
            },
        }),
    },
    created () {
        this.allListPage()
    },
    methods: {
        // 仪表管理列表
        allListPage () {
            let params = {
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                keyword: this.keyWords,
                ownerId: this.formData.ownerId,
            }
            this.$store.dispatch('getPeidianMeterPage', params).then(() => {
                this.tableData = this.peidianMeterPage.data.records
                this.total = this.peidianMeterPage.data.total
            })
        },
        // open 登记弹框
        openPopup () {
            if (this.formData.ownerId > 10000) {
                // 客户单位直接从树上传递过来，不用用户填写
                this.isEdit = false
                this.dailogTitle = '登记'
                this.dialogVisible = true
                this.getMeterList()
                this.getRootList()
                this.$nextTick(() => {
                    this.$refs.ruleForm.clearValidate()
                })
            }
        },
        // 获取配电仪表
        getMeterList () {
            this.$store.dispatch('getMeterList', this.formData.ownerId).then(() => {
                this.meterList = this.$store.state.metrAdministration.meterList.data
            })
        },
        // 获取根节点
        getRootList () {
            this.$store.dispatch('getRootList', this.formData.ownerId).then(() => {
                this.rootList = this.$store.state.metrAdministration.rootList.data
            })
        },
        // 根据节点id获取其下级列表
        getSubList (field, type) {
            let params = {
                id: this.formData[field],
                type,
            }
            this.$store.dispatch('getSubList', params).then(() => {
                if (type == 'equipment') {
                    this.equipmentList = this.$store.state.metrAdministration.equipmentList.data
                } else if (type == 'loop') {
                    this.loopList = this.$store.state.metrAdministration.loopList.data
                } else if (type == 'loop2') {
                    this.loopList2 = this.$store.state.metrAdministration.loopList2.data
                } else if (type == 'loop3') {
                    this.loopList3 = this.$store.state.metrAdministration.loopList3.data
                } else if (type == 'loop4') {
                    this.loopList4 = this.$store.state.metrAdministration.loopList4.data
                }
            })
        },
        handleChangeRoot (val) {
            this.rootList.forEach((item) => {
                if (item.id == val) {
                    this.formData.locationName = item.name
                }
            })
            this.getSubList('buildingId', 'equipment')
        },
        handleChangeEquipment (val) {
            this.formData.roomId = ''
            this.formData.room2Id = ''
            this.formData.room3Id = ''
            this.formData.room4Id = ''
            this.loopList = []
            this.loopList2 = []
            this.loopList3 = []
            this.loopList4 = []
            this.equipmentList.forEach((item) => {
                if (item.id == val) {
                    this.formData.floorName = item.name
                }
            })
            this.getSubList('floorId', 'loop')
        },
        handleChangeLoop (val) {
            console.log('选择回路', val)
            this.formData.room2Id = ''
            this.formData.room3Id = ''
            this.formData.room4Id = ''
            this.loopList2 = []
            this.loopList3 = []
            this.loopList4 = []
            this.loopList.forEach((item) => {
                if (item.id == val) {
                    this.formData.roomName = item.name
                }
            })
            //登记时不显示二级，三级，四级回路，编辑才显示
            // if (this.isEdit) {
            this.getSubList('roomId', 'loop2')
            // }
        },
        handleChangeLoop2 (val) {
            console.log('选择回路2', val)
            this.formData.room3Id = ''
            this.formData.room4Id = ''
            this.loopList3 = []
            this.loopList4 = []
            this.loopList2.forEach((item) => {
                if (item.id == val) {
                    this.formData.room2Name = item.name
                }
            })
            this.getSubList('room2Id', 'loop3')
        },
        handleChangeLoop3 (val) {
            console.log('选择回路3', val)
            this.formData.room4Id = ''
            this.loopList4 = []
            this.loopList3.forEach((item) => {
                if (item.id == val) {
                    this.formData.room3Name = item.name
                }
            })
            this.getSubList('room3Id', 'loop4')
        },
        handleChangeLoop4 (val) {
            console.log('选择回路4', val)
            this.loopList4.forEach((item) => {
                if (item.id == val) {
                    this.formData.room4Name = item.name
                }
            })
        },
        handleSave () {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let requestData = {}
                    if (this.isEdit) {
                        let locationId =
                            this.formData.room4Id ||
                            this.formData.room3Id ||
                            this.formData.room2Id ||
                            this.formData.roomId ||
                            this.formData.floorId ||
                            this.formData.buildingId
                        requestData = { ...this.formData, ...{ locationId: locationId } }
                        this.$store.dispatch('editMeter', requestData).then(() => {
                            if (this.$store.state.metrAdministration.editMeterInfo.data) {
                                this.$message({
                                    message: '编辑成功！',
                                    type: 'success',
                                })
                                this.handleClose()
                                this.allListPage()
                            } else {
                                this.$message({
                                    message: this.$store.state.metrAdministration.editMeterInfo
                                        .message,
                                    type: 'error',
                                })
                            }
                        })
                    } else {
                        let locationId =
                            this.formData.roomId ||
                            this.formData.floorId ||
                            this.formData.buildingId
                        requestData = { ...this.formData, ...{ locationId: locationId } }
                        this.$store.dispatch('addMeter', requestData).then(() => {
                            if (this.$store.state.metrAdministration.addMeterInfo.data) {
                                this.$message({
                                    message: '登记成功！',
                                    type: 'success',
                                })
                                this.handleClose()
                                this.allListPage()
                            } else {
                                this.$message({
                                    message: this.$store.state.metrAdministration.addMeterInfo
                                        .message,
                                    type: 'error',
                                })
                            }
                        })
                    }
                }
            })
        },
        handleClose () {
            this.dialogVisible = false
            this.formData = {
                sourceType: '1', // 数据来源 0:手动录入 1:配电仪表
                meterId: '', // 配电仪表
                code: '', // 编号
                name: '', // 名称
                type: '2',
                buildingId: '', //根节点id
                locationId: '', // 最后一层回路id
                locationName: '', // 根节点
                floorId: '', // 设备id
                floorName: '', // 设备
                roomId: '', // 回路id
                roomName: '', // 回路
                ownerName: this.formData.ownerName, //客户单位名称
                ownerId: this.formData.ownerId, //客户id
            }
            this.loopList = []
            this.loopList2 = []
            this.loopList3 = []
            this.loopList4 = []
        },
        handleEdit (index, row) {
            this.$store.dispatch('getMeterDetail', row.id).then(() => {
                if (this.$store.state.metrAdministration.meterDetail.errorCode == 0) {
                    if (this.$refs.ruleForm) {
                      this.$refs.ruleForm.clearValidate()
                    }
                    this.isEdit = true
                    this.formData = this.$store.state.metrAdministration.meterDetail.data
                    this.dailogTitle = '编辑'
                    this.dialogVisible = true
                    this.getMeterList()
                    this.getRootList()
                    this.getSubList('buildingId', 'equipment')
                    if (this.formData.floorId) {
                        this.getSubList('floorId', 'loop')
                    }
                    if (this.formData.roomId) {
                        this.getSubList('roomId', 'loop2')
                    }
                    if (this.formData.room2Id) {
                        this.getSubList('room2Id', 'loop3')
                    }
                    if (this.formData.room3Id) {
                        this.getSubList('room3Id', 'loop4')
                    }
                } else {
                    this.$message({
                        message: this.$store.state.metrAdministration.meterDetail.message,
                        type: 'error',
                    })
                }
            })
        },
        handleDelete (index, row) {
            this.$confirm('您确认要执行删除操作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$store.dispatch('deleteMeter', row.id).then(() => {
                        if (this.$store.state.metrAdministration.deleteMeterInfo.data) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            })
                            this.allListPage()
                        } else {
                            this.$message({
                                message: this.$store.state.metrAdministration.deleteMeterInfo
                                    .message,
                                type: 'error',
                            })
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        handleSizeChange (val) {
            this.pageSize = val
            this.allListPage()
        },
        handleCurrentChange (val) {
            this.currentPage = val
            this.allListPage()
        },
        changeBreakName (data) {
            this.breakName = data
        },
        queryData () {
            this.allListPage()
        },
        // 获取DOM树上的id,用来获取楼栋，楼层
        changeTreeNode (e) {
            this.formData.ownerName = e.name
            this.formData.ownerId = e.id
            this.currentPage = 1
            this.pageSize = 10
            this.keyWords = ''
            this.metrName = ''
            this.allListPage()
        },
        //发送请求##### 导出表格(临时)
        exportFile () {
            console.log('this.keyWords', this.keyWords)
            console.log('this.formData.ownerId', this.formData.ownerId)
            let value = {
                keyword: this.keyWords,
                ownerId: this.formData.ownerId,
            }
            // this.$store.dispatch('exportMeterPowerFile',value )
            exportPedianReport(value).then((response) => {
                var blob = new Blob([response], {
                    type: "application/vnd.ms-excel,charset=utf-8",
                }) //创建一个blob对象
                var a = document.createElement("a") //创建一个<a></a>标签
                a.href = URL.createObjectURL(blob) // response is a blob
                a.download = `配电用能.xls` //文件名称
                a.style.display = "none"
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        },

        //点击《导入》打开公用组件弹层
        importFile () {
            this.$refs.dialogImportRef.show({
                name: 'power',
                ownerId: this.formData.ownerId,
            })
        },
        //发送请求##### 被子组件调用，下载示例模板
        downLoad (Data) {
            let value = {
                // name: 'meter',
                name: Data.name,
                type: this.formData.type,
                // ownerId: this.formData.ownerId,
                ownerId: Data.ownerId,
            }
            console.log('value', value)
            this.$store.dispatch('getDownloadTemplate', value)
        },

        // 上传图片成功
        upLoadSuccess (val) {
            this.$message.success('上传图片成功！')
            // this.form.soReportImages += val.data
            // this.form.soReportImages += ","
            this.form.pictureUrl = val.data
        },

        // 文件传输失败
        upLoadError (val) {
            this.$message.error(val.message)
        },
    },
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}
// 板块二##############################################################
.blockTwo {
    //   height: 96.8rem;
    //   width: 171.2rem;
    //   position: relative;
    //   background-color: #18191a;
}

// 板块二左侧#####################################################
.blockTwoLeft {
    //   overflow: hidden;
    //   width: 26rem;
    //   height: 96.8rem;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
}

// 板块二右侧#####################################################
.blockTwoRight {
    //   width: 142.8rem;
    height: 96.8rem;
    //   position: absolute;
    //   left: 28.4rem;
    //   top: 0;
    // background-color: pink;
}
.equipmentTablePlace {
    height: 86.4rem;
    //   width: 142.8rem;
    width: 100%;
    //   position: absolute;
    //   top: 10.5rem;
    //   left: 28.6rem;
    overflow: hidden;
    background-color: #0d3050;
    // padding: 2.4rem;
    box-sizing: border-box;
}
.el-select-dropdown__item {
    padding-left: 2rem !important;
}

// 公共样式satr
.color {
    // color: #ffffff;
    color: #c0c4cc;
}
.color1 {
    // color: #ffffff;
    color: #ffffff;
}
.color2 {
    color: #03d9bc;
}
.color3 {
    color: rgba(255, 255, 255, 0.85);
}
.color4 {
    color: #ff4d4f;
}
.fontSize {
    font-size: 1.4rem;
}
.fontSize1 {
    font-size: 1.6rem;
}
.eleInput {
    width: 16.8rem;
    height: 3.2rem;
}
.marginR {
    margin-right: 0.8rem;
}
.marginRc {
    margin-right: 0.4rem;
}
.marginTop {
    margin-top: 0.8rem;
}
.marginTop1 {
    margin-top: 2rem;
}
.marginR1 {
    margin-right: 1.2rem;
}
.cursor {
    cursor: pointer;
}
.displayinline {
    display: inline-block;
}
/deep/ .el-input__inner {
    height: 3.2rem !important;
}
/deep/.el-input__icon {
    line-height: 3.2rem;
}
// 公共样式end
// nav satr
.search-box {
    padding: 0 2.4rem;
    height: 8rem !important;
    line-height: 8rem;
    box-sizing: border-box;
    background: #0d3050;
    border-radius: 0.4rem;
    width: 100%;
    margin-bottom: 2.4rem;
}
.btn {
    width: 7.4rem;
    height: 3.2rem;
    background-color: #027969;
    border-radius: 0.2rem;
    border: none;
    cursor: pointer;
}
.btn2Gray {
    color: gray;
    cursor: not-allowed;
}
.djBgc {
    background-color: gray;
    color: #fff;
}
.djpink {
    background-color: #027969;
    color: #fff;
}
.exportButton {
    width: 7.4rem;
    height: 3.2rem;
    border-radius: 0.2rem;
    border: 0.1rem solid #004579;
    background-color: rgba(0, 115, 222, 0.1);
    cursor: pointer;
}
.btnBox {
    float: right !important;
}
.tableBox {
    // margin-top: 2.5rem;
    height: 57rem;
    padding: 0 2.4rem !important;
    display: flex;
    flex-direction: column;
}
.navigation {
    display: flex;
    background-color: rgb(12, 55, 94);
    // justify-content: space-between;
    // color: rgba(255, 255, 255, 0.85);
}
.grey {
    color: rgba(255, 255, 255, 0.85);
}
.borderBottom {
    border-bottom: 0.1rem solid rgba(108, 184, 255, 0.3);
    background: rgba(52, 150, 241, 0.15);
    // margin-right: 0.1rem;
}
.border-bototm {
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}
.height {
    height: 5.2rem;
    text-align: left;
    line-height: 5.2rem;
    box-sizing: border-box;
    padding-left: 0.8rem;
    // margin-right: 0.1rem;
}

.bgc1 {
    background: linear-gradient(90deg, #0d3050 0%, rgba(0, 10, 20, 0.7) 100%);
}
.bgc2 {
    background-color: #027969 !important;
}
.width1 {
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.width2 {
    width: 22%;
}
.width3 {
    width: 15.8%;
}
.width4 {
    width: 20.4%;
}
.width5 {
    width: 25.4%;
}
.width6 {
    width: 21%;
}
.width7 {
    width: 13.3%;
}
.width8 {
    width: 10.6%;
}
.pad1 {
    padding-left: 1.2rem;
}

// nav end
/deep/.el-drawer {
    width: 56.8rem !important;
    background: rgba(12, 55, 94, 1);
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}
.content {
    // background-color: pink;
    height: 100%;
    display: flex;
    flex-direction: column;
    .tab {
        background: linear-gradient(
            225deg,
            rgba(15, 36, 55, 0.0001) 0.03%,
            rgba(0, 115, 222, 0.7) 99.9%
        );
        border-radius: 0.4rem 0.4rem 0rem 0rem;
        padding: 1.6rem 2.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .imgSize {
            width: 1.31rem;
            height: 1.164rem;
            cursor: pointer;
        }
    }
    .subject {
        // flex: 1;
        padding: 0 2.4rem;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.85);
    }
    .form_content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 3rem;
        .form-item {
            position: relative;
            margin-top: 2.5rem;
            margin-right: 1.5rem;
            width: calc((100% - 4.5rem) / 2);
        }
    }
}
.subjectSearch {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
}
.sujectINput {
    width: 100%;
    height: 3.2rem;
    border: 0.1rem solid #004579;
    border-radius: 0.2rem;
    outline: none;
    background-color: rgba(0, 115, 222, 0.1);
    color: rgba(255, 255, 255, 0.85);
    padding: 0.5rem 1.2rem;
    box-sizing: border-box;
}

// .sujectINput:hover {
//   border: 0.1rem solid rgba(0, 115, 222, 0.1) !important;
// }
/deep/.el-dialog__header {
    background: linear-gradient(
        225deg,
        rgba(15, 36, 55, 0.0001) 0.03%,
        rgba(0, 115, 222, 0.7) 99.9%
    );
    border-radius: 0.4rem 0.4rem 0rem 0rem;
    padding: 1.6rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-dialog__title {
        color: #fff !important;
    }
}
/deep/.el-dialog__body {
    padding: 1.5rem 3rem 3rem 3rem !important;
    .el-form--label-top .el-form-item__label {
        padding: 0;
        padding-top: 16px;
        line-height: 24px;
        font-size: 14px;
    }
    .el-form--inline .el-form-item {
        width: calc((100% - 2rem) / 2);
        margin-right: 2rem;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}
/deep/.el-table tr {
    background-color: transparent;
    color: #fff;
}

.cancel {
    width: 7.4rem;
    height: 3.2rem;
    background: rgba(0, 115, 222, 0.1);
    border: 0.1rem solid #004579;
    box-sizing: border-box;
    border-radius: 0.2rem;
    cursor: pointer;
}
/deep/.el-select {
    width: 100%;
}

// popup
// 做限制，防止代码污染
.dialogImportFile {
    // 弹层标题
    .el-dialog .el-dialog__header {
        padding: 0;
        height: 5.4rem;
        line-height: 5.4rem;
        padding-left: 2.4rem;
        font-size: 1.6rem;
        background: linear-gradient(
            225deg,
            rgba(15, 36, 55, 0.0001) 0.03%,
            rgba(0, 115, 222, 0.7) 99.9%
        );
    }
    // 弹层字体
    .el-dialog__title {
        color: #fff;
    }
    // 按钮样式
    .confirmButton {
        background: #027969;
        color: #fff;
        padding: 0;
        width: 10.2rem;
        height: 3.2rem;
        line-height: 3.2rem;
        border: 1px solid #027969;
        cursor: pointer;
    }
    .cancelButton {
        background: #0c375e;
        color: #fff;
        padding: 0;
        width: 10.2rem;
        height: 3.2rem;
        line-height: 3.2rem;
        border: 0.1rem solid #004579;
        cursor: pointer;
        margin-left: 1.5rem;
    }

    .el-select-dropdown__item {
        padding-left: 2rem !important;
    }

    .word {
        height: 2rem;
        line-height: 5rem;
        color: #aab9c6;
        font-size: 1.2rem;
    }
}
.sh {
    /*规定段落中的文本不进行换行：*/
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow属性表示规定当文本溢出包含元素时发生的事情;
            ellipsis表示显示省略符号来代表被修剪的文本。*/
    text-overflow: ellipsis;
}
.monitorLoop {
    margin: 0 2.4rem 0 2.4rem;
    .title {
        font-size: 1.4rem;
        color: hsla(0, 0%, 100%, 0.85);
        margin-top: 2rem;
        margin-bottom: 1.6rem;
        .required {
            margin-top: 1.8rem;
            color: #f56c6c;
            margin-right: 0.4rem;
        }
    }
    .loops {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 90%;
        div {
            display: inline-block;
            font-size: 1.4rem;
            color: #2cd9ff;
            margin-right: 0.8rem;
            margin-bottom: 0.8rem;
        }
        .loopItem {
            width: 14.5rem;
            height: 3.2rem;
            line-height: 3rem;
            background-color: rgba(52, 150, 241, 0.15);
            border: 0.1rem dashed rgba(108, 184, 255, 0.3);
            border-radius: 0.4rem;
            padding-left: 0.6rem;
            box-sizing: border-box;
            span {
                float: right;
                margin-right: 0.6rem;
                cursor: pointer;
            }
        }
        .addBtn {
            width: 13.5rem;
            height: 3.2rem;
        }
        .addLoop {
            width: 3.2rem;
            height: 3rem;
            line-height: 3rem;
            text-align: center;
            background-color: rgba(52, 150, 241, 0.15);
            border: 0.1rem dashed rgba(108, 184, 255, 0.3);
            border-radius: 0.4rem;
        }
    }
}
</style>
<style lang="less" scoped>
.equipmentMeters {
    .inputRed {
        border-color: #f56c6c;
        .el-input__inner {
            border-color: #f56c6c;
        }
    }
    .el-input__inner {
        background: rgba(0, 115, 222, 0.1) !important;
        &::placeholder {
            color: rgba(255, 255, 255, 0.45);
        }
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: rgba(255, 255, 255, 0.45);
        }
        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 适配火狐 */
            color: rgba(255, 255, 255, 0.45);
            /* 设置opacity  解决firefox不生效问题 */
            opacity: 1;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ 适配火狐 */
            color: rgba(255, 255, 255, 0.45);
            opacity: 1;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10+  适配ie*/
            color: rgba(255, 255, 255, 0.45);
        }
    }
    .loops {
        .el-input__inner {
            padding: 0 0.5rem !important;
        }
    }
    .dialogImportFile {
        /deep/ .el-dialog__body {
            padding: 30px 20px !important;
            color: #606266;
            font-size: 14px;
            word-break: break-all;
        }
        /deep/ .el-dialog .el-dialog__header {
            padding: 0;
            height: 5.4rem;
            line-height: 5.4rem;
            padding-left: 2.4rem;
            font-size: 1.6rem;
            background: linear-gradient(
                225deg,
                rgba(15, 36, 55, 0.0001) 0.03%,
                rgba(0, 115, 222, 0.7) 99.9%
            );
            display: block !important;
        }
    }
    /deep/.el-input-number{
        width:100%;
        .el-input-number__decrease{
            background: #0b3d6b;
            border-left: none;
        }
        .el-input-number__increase{
            border-left: none;
            border-bottom: none;
            background: #0b3d6b;
        }
        .el-input-number__decrease, .el-input-number__increase{
            color: #C0C4CC;
        }
        .el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
            color: #606266;
        }
    }
}
.pagination {
    margin-top: 10px;
    padding-right: 2rem;
    text-align: right;
}
.table {
    height: 57.2rem;
    //   width: 138rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    /deep/ .el-table td,
    .el-table th.is-leaf {
        border-bottom: 0.1rem solid #254561;
        background-color: rgb(12, 55, 94);
        color: rgb(219, 224, 229);
        font-family: "PingFang SC";
        font-size: 1.4rem;
    }
    /deep/.el-table--group:after,
    .el-table th.is-leaf {
        background: rgb(19, 63, 104) !important;
        color: rgb(255, 255, 255) !important;
    }
    /deep/.el-table--group:after,
    .el-table--border:after,
    .el-table:before {
        opacity: 0;
    }
    /deep/.el-table__body-wrapper {
        background-color: #0d3050;
    }
    /deep/.el-table__cell {
        background-color: #133f68;
    }
    /deep/.el-button--text {
        color: #03d9bc;
    }
    /deep/
        .el-table--enable-row-hover
        .el-table__body
        tr:hover
        > td.el-table__cell {
        background-color: rgb(12, 55, 94);
    }
}
</style>
