<template>
  <div class="EnergySetting">
    <!-- 顶栏位置##### -->
    <div class="topBar">
      <div :class="btn1" @click="partSwitch(0)">计算指标配置</div>
      <div :class="btn2" @click="partSwitch(1)">时段配置</div>
    </div>

    <!-- 中部显示位置########## -->
    <div class="middlePlace">
      <!-- 计算指标配置 #########################################################-->
      <div v-if="btn1 === 'btnGreen'" class="computedPart">
        <ComputedPart />
      </div>
      <!-- 时段配置########################################################################## -->
      <div v-else class="timePart">
        <TimePart />
      </div>
    </div>
  </div>
</template>

<script>
import ComputedPart from "./EnergySetting/ComputedPart";
import TimePart from "./EnergySetting/TimePart";
export default {
  name: "EnergySetting",
  components: { ComputedPart, TimePart },

  data() {
    return {
      // 样式初始值
      btn1: "btnGreen",
      btn2: "btnGray",
    };
  },

  methods: {
    // 切换三级tab
    partSwitch(val) {
      if (val) {
        this.btn1 = "btnGray";
        this.btn2 = "btnGreen";
      } else {
        this.btn1 = "btnGreen";
        this.btn2 = "btnGray";
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 整体区域##########################################################
.EnergySetting {
  height: 88rem;
//   width: 142.8rem;
  width: calc(100% + 0.6rem);
  background-color: #0d3050;
  position: relative;
  .topBar {
    height: 6.3rem;
    width: 138rem;
    border-bottom: 0.2rem rgba(7, 82, 150, 0.25) solid;
    position: absolute;
    top: 0;
    left: 2.4rem;
    font-size: 1.4rem;
    .btnGreen {
      height: 6.3rem;
      line-height: 9rem;
      display: inline-block;
      cursor: pointer;
      border-bottom: 0.2rem #03d9bc solid;
      margin-right: 2rem;
      color: #03d9bc;
    }
    .btnGray {
      height: 6.3rem;
      line-height: 9rem;
      display: inline-block;
      cursor: pointer;
      margin-right: 2rem;
      color: rgba(255, 255, 255, 0.85);
    }
  }
}
.middlePlace {
  height: 81.7rem;
  width: 142.8rem;
  position: absolute;
  top: 6.5rem;
  left: 0;

  // 时段配置区域
  .timePart {
    height: 81.7rem;
    width: 142.8rem;
    background-color: lightblue;
  }
}
</style>
