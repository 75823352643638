<template>
  <div class="DialogCustomerDetail">
    <el-dialog 
    @close="handelClose"
    :visible.sync="dialogFormVisible" width="60%" style="position:absolute;" :close-on-click-modal="false">
      <div slot="title">
        <div class="equip_title">打印二维码详情页</div>
      </div>
      
      <div class="bodyPlace">

        <!-- 顶栏######################################################################### -->
        <div v-show="!innerVisible" class="topPlace">
            <el-button @click="handelNotSelectAll" class="btn3" size="mini" :disabled="tableData.length==0">全不选</el-button>
            <el-button @click="handelSelectAll" class="btn2" size="mini" :disabled="tableData.length==0">全选</el-button>
            <el-button @click="handelDetailChoosen" class="btn1" size="mini" :disabled="tableData.length==0">打印选中的二维码</el-button>
        </div>
        <div v-show="innerVisible" class="topPlace">
            <el-button @click="handelGetBack" class="btn4" size="mini">返回重新选择</el-button>
            <el-button @click="handelPrintChoosen" class="btn1" size="mini">确认打印/下载</el-button>
        </div>

        <!-- 表格区###################################################################### -->
        <div v-if="!innerVisible" class="tablePlace">
          <div class="table">
              <el-table
                :data="tableData"
                stripe:false
                height="49rem"
                border:false
                style="width: 100%"
                :header-cell-style="headerClass"
                :cell-style="cellStyle"
              >

                <el-table-column
                  class="tab"
                  prop="check"
                  label="选择"
                  min-width="15%"
                  :show-overflow-tooltip="true"
                >
                <template slot-scope="scope">
                  <el-checkbox 
                  v-model="tableData[scope.$index].check" 
                  label="加入打印队列" 
                  size= mini
                  border
                  @change="selectionChange(scope.$index)"
                  ></el-checkbox>
                </template>
                </el-table-column>

                <el-table-column
                  class="tab"
                  prop="code"
                  label="编号"
                  min-width="9%"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  class="tab"
                  prop="name"
                  label="位置名称"
                  min-width="18%"
                  :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                  prop="typeName"
                  label="配电房类型"
                  min-width="17%"
                  :show-overflow-tooltip="true"
                ></el-table-column>

                <el-table-column
                  prop="type"
                  label="位置类型"
                  min-width="17%"
                  :show-overflow-tooltip="true"
                ></el-table-column>

              </el-table>
            </div>
        </div>

        <!-- 内置弹层 ####################################################################-->
        <div v-if="innerVisible" class="innerDialogPlace" ref="print">
            <!-- 盛放二维码的容器 -->
            <div v-if="choosenData[0]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[0].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[0].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[0].name}}                
              </div>
            </div>
            <div v-if="choosenData[1]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[1].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[1].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[1].name}}                
              </div>
            </div>
           <div v-if="choosenData[2]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[2].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[2].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[2].name}}                
              </div>
            </div>
            <div v-if="choosenData[3]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[3].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[3].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[3].name}}                
              </div>
            </div>
            <div v-if="choosenData[4]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[4].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[4].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[4].name}}                
              </div>
            </div>
            <div v-if="choosenData[5]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[5].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[5].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[5].name}}                
              </div>
            </div>
            <div v-if="choosenData[6]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[6].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[6].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[6].name}}                
              </div>
            </div>
            <div v-if="choosenData[7]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[7].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[7].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[7].name}}                
              </div>
            </div>
            <div v-if="choosenData[8]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[8].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[8].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[8].name}}                
              </div>
            </div>
            <div v-if="choosenData[9]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[9].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[9].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[9].name}}                
              </div>
            </div>
            <div v-if="choosenData[10]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[10].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[10].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[10].name}}                
              </div>
            </div>
            <div v-if="choosenData[11]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[11].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[11].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[11].name}}                
              </div>
            </div>
            <div v-if="choosenData[12]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[12].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[12].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[12].name}}                
              </div>
            </div>
            <div v-if="choosenData[13]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[13].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[13].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[13].name}}                
              </div>
            </div>
            <div v-if="choosenData[14]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[14].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[14].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[14].name}}                
              </div>
            </div>
            <div v-if="choosenData[15]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[15].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[15].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[15].name}}                
              </div>
            </div>
            <div v-if="choosenData[16]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[16].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[16].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[16].name}}                
              </div>
            </div>
            <div v-if="choosenData[17]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[17].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[17].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[17].name}}                
              </div>
            </div>
            <div v-if="choosenData[18]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[18].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[18].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[18].name}}                
              </div>
            </div>
            <div v-if="choosenData[19]" class="card">
              <div class="cardInside">
                  <div class="qrcode" id="yoyoyo" :ref="choosenData[19].id"></div>   
              </div>
              <div class="cardTitle">
                 {{choosenData[19].code}}                
              </div>
              <div class="cardTitle2">
                 {{choosenData[19].name}}                
              </div>
            </div>
            
         

            
            
        </div>
        
       
      </div>


    </el-dialog>
  </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg";
import {mapState} from 'vuex'
import QRCode from 'qrcodejs2'
export default {
  name:'DialogCustomerDetail',
  mixins: [tableBg],
  data(){return{
    // 配电房类型字典
    roomDict:{0:'配电房',1:'强电井',2:'室外配电柜',3:'其他'},
    // 是否显示弹层
    dialogFormVisible:false,
    // 内层弹层
    innerVisible:false,
    // 表格数据
    tableData:[],
    // 选中数据
    choosenData:[],
  }},


  methods:{
    // 父元素点击弹出弹层
    show(val){
      this.dialogFormVisible = true
      // 先获取下属配电房数据
      this.$store.dispatch('getOwnRoomData',val.id).then(()=>{
          this.tableData = []
          if(this.ownRoomData){
          for(let i=0;i<this.ownRoomData.length;i++){
            const tableSingel = {check:'',code:'',name:'',type:''}
            tableSingel.check = false
            tableSingel.code = this.ownRoomData[i].code
            tableSingel.name = this.ownRoomData[i].name
            // tableSingel.type =  this.ownRoomData[i].type
            tableSingel.id =  this.ownRoomData[i].id
            tableSingel.typeName = this.roomDict[this.ownRoomData[i].type]
            tableSingel.type = "配电房"
            tableSingel.typeId = 1
            this.tableData.push(tableSingel)
            }
          }
          // 再获取下属电站数据       
          this.$store.dispatch('getPowerStationData',val.id).then(()=>{
            if(this.powerStationData){   
            for(let i=0;i<this.powerStationData.length;i++){
              const tableSingel = {check:'',code:'',name:'',type:''}
              tableSingel.check = false
              tableSingel.code = this.powerStationData[i].code
              tableSingel.name = this.powerStationData[i].name
              tableSingel.id =  this.powerStationData[i].id
              tableSingel.typeName = "——"
              tableSingel.type = "电站"
              tableSingel.typeId = 2
              this.tableData.push(tableSingel)
            }
            }
          })
        
      })
    },

    // 全选
    handelSelectAll(){
      let selectedNum = 0
      for(let i=0;i<this.tableData.length;i++){
        if (this.tableData[i].check) {
          selectedNum++
        }
      }
      if (selectedNum > 9) {
        this.tableData[index].check = false
        this.$message({message: '最多只能选9条数据' ,type: 'warning'});
        return
      }
      for(let i=0;i<this.tableData.length;i++){
        if(!this.tableData[i].check && selectedNum < 9) {
          this.tableData[i].check = true
          selectedNum++
        }
      }
      // this.$message({message: '最多只能选9条数据' ,type: 'warning'});
    },
    // 全不选
    handelNotSelectAll(){
      for(let i=0;i<this.tableData.length;i++){this.tableData[i].check = false}
    },
    selectionChange(index) {
      let selectedNum = 0
      for(let i=0;i<this.tableData.length;i++){
        if (this.tableData[i].check) {
          selectedNum++
        }
      }
      if (selectedNum > 9) {
        this.tableData[index].check = false
        this.$message({message: '最多只能选9条数据' ,type: 'warning'});
      }
    },
    //预览选中的二维码 
    handelDetailChoosen(){
      this.choosenData = []
      for(let i=0;i<this.tableData.length;i++){
        if(this.tableData[i].check === true){
          this.choosenData.push(this.tableData[i])
        }
      }
      if(this.choosenData.length>20){this.$message.error('一次最多打印9个二维码！');return}
      if(this.choosenData.length===0){this.$message.error('请至少选择一个二维码');return}
      this.innerVisible = true 
      for(let i=0;i<this.choosenData.length;i++){
        setTimeout(()=>{ 
           this.creatQrCode({text:this.choosenData[i].id+"-"+this.choosenData[i].typeId,id:this.choosenData[i].id}) 
        },20)
      }
       
    },
    // 打印选中的二维码
    handelPrintChoosen(){
      this.$print(this.$refs.print)  
    },
    // 返回重新选择
    handelGetBack(){
      this.innerVisible = false
    },

    // 关闭弹窗
    handelClose(){
      this.innerVisible = false
    },

    // 生成二维码
    creatQrCode(val) {
        let yoyoyo = new QRCode(this.$refs[val.id], {
            text: val.text, 
            height:130,
            width:130,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })

    },

  },

   computed:{
    ...mapState({
      // 下属配电房数据
        ownRoomData: state => {return state.equipment.ownRoomData.data;},
      // 下属电站数据
        powerStationData: state => {return state.customer.powerStationData.data;},
    })
  },


  

}
</script>

<style lang="less" scoped>
.bodyPlace{
  position: absolute;
  top: 5.6rem;
  left: 0;
  background-color: #0d3050;
  width: 100%;
  .topPlace{
    height: 5rem;
    width: 100%;
    background-color: #0d3050;
    margin-bottom: 1rem;
    position: relative;
    .btn1{
      position: absolute;
      top: 1rem;
      right: 3rem;
      height: 3rem;
      background-color: #027969;
      border: #027969;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 1.4rem;
      padding-top: 6px;
    }
    .btn1.is-disabled{
      border: #8fd3ca;
      background: #8fd3ca;
    }
    .btn2{
      position: absolute;
      top: 1rem;
      right: 21rem;
      height: 3rem;
      background-color: #0c375e;
      border: 0.1rem solid #004579;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 1.4rem;
      box-sizing: border-box;
      padding-top: 6px;
    }
    .btn2.is-disabled{
      border: #91b7d9;
      background: #91b7d9;
    }
    .btn3{
      position: absolute;
      top: 1rem;
      right: 28.5rem;
      height: 3rem;
      background-color: #0c375e;
      border: 0.1rem solid #004579;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 1.4rem;
      box-sizing: border-box;
      padding-top: 6px;
    }
    .btn3.is-disabled{
      border: #91b7d9;
      background: #91b7d9;
    }
    .btn4{
      position: absolute;
      top: 1rem;
      right: 18rem;
      height: 3rem;
      background-color: #0c375e;
      border: 0.1rem solid #004579;
      cursor: pointer;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 1.4rem;
      box-sizing: border-box;
    }
}

.tablePlace{
  table{
  width: unset !important;
  overflow: scroll;
  }
}


}


// 内置弹层样式##########################################
.innerDialogPlace{
  position: absolute;
  top: 5.6rem;
  left: 0;
  background-color: #0d3050;
  width: 100%;
  height: 50rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: scroll;
  .card{
        border: #000 5px solid;
        // height: 250px;
        height: 235px;
        width: 200px;
        margin: 10px 5px 10px 5px;
        background-color: #027969;
        position: relative;
        top: 0;
        left: 0;
        .cardInside{
            position: absolute;
            left: 25px;
            top: 10px;
            height: 130px;
            width: 130px;
             // 二维码样式
            .qrcode{
                display: block;
                height: 150px;
                width: 150px;
                background-color: #fff;
                align-items: center;
                line-height: 150px;
                padding: 10px;
                box-sizing: border-box;
                img {
                    width: 130px;
                    height: 130px;                
                }
            }
        }
        .cardTitle{
          position: absolute;
          left: 25px;
          top: 170px;
          height: 30px;
          width: 150px;
          text-align: center;
          color: #000;
          font-size: 16px;
        }
        .cardTitle2{
           position: absolute;
          left: 25px;
          top: 193px;
          height: 30px;
          width: 150px;
          text-align: center;
          color: #000;
          font-size: 16px;
        }

     


  }


}



</style>



<style lang="less">
// 表格样式修改（全局）#############################################
.DialogCustomerDetail {
  // .el-table__body-wrapper .is-scrolling-none{}
  .table {
    height: 50rem;
    // width: 100%;
    margin: 0 2.4rem 0 2.4rem;
    box-sizing: border-box;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }


  .el-checkbox.is-bordered.is-checked {
    border-color: #027969;
}

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #027969;
      border-color: #027969;
  }


  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #027969;
}

  .el-checkbox.is-bordered{
    border : 0.1rem solid #606266;
  }
  .el-checkbox__inner{
    background-color: #0c375e;
    border : 0.1rem solid #606266;
  }
  .el-checkbox__inner:hover {
    border-color: #606266;
}
.el-checkbox__inner:hover {
    border-color: #606266;
}
// .innerDialogPlace{
//   .el-dialog-div{
//     height: 70vh;
//   }
// }
}
</style>