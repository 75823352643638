
<template>
    <div class="CommunicationOutSider  page-main">
        <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="leftBlock page-main-tree">
            <TreeCommunication ref="treeRef" />
        </div>
        <div :class="isTreeShow ? 'shrink-open_tree_content':'shrink-close_tree_content'" class="Communication page-main-content">
            <div class="shrink-btn" @click="isTreeShow = !isTreeShow">
            </div>
            <!-- 顶部栏位置########################################################################################################################################## -->
            <div class="topBar">
                <div>
                    <el-input size="small" placeholder="请输入通讯管理机名称" v-model="searchValue" @keyup.enter.native="search" style="width:22rem;" clearable @clear="search"></el-input>
                    <button class="btn_save" @click="search">查 询</button>
                </div>
                <button :class="customerId==''?'btn3':'btn2'" @click="ifDialogShow">导入管理机</button>
            </div>

            <!-- 中部表格位置############################################################################################################ -->
            <div class="tablePlace">
                <!-- 面包屑 -->
                <div class="bread">
                    系统管理 / 通讯管理
                </div>
                <div class="table">
                    <el-table :data="tableData" stripe:false height="57.8rem" border:false style="width: 100%" :header-cell-style="headerClass" :cell-style="cellStyle">

                        <!-- <el-table-column type="index" label="序号" min-width="30%" :show-overflow-tooltip="true"></el-table-column> -->
                        <el-table-column class="tab" prop="name" label="名称" min-width="20%" :show-overflow-tooltip="true"> </el-table-column>
                        <el-table-column class="tab" prop="ip" label=" IPv4地址" min-width="16%" :show-overflow-tooltip="true"> </el-table-column>
                        <el-table-column class="tab" prop="port" label="端口号" min-width="16%" :show-overflow-tooltip="true"> </el-table-column>
                        <el-table-column class="tab" prop="createTime" label="创建时间" min-width="22%" :show-overflow-tooltip="true"> </el-table-column>
                        <!-- 操作部分 -->
                        <el-table-column label="操作" min-width="14%">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页器部分 -->
                <div class="pagination">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalNumForPagenation">
                    </el-pagination>
                </div>
            </div>

            <!-- 新增用户弹层 -->
            <DialogCommunication ref="dialogRef" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import TreeCommunication from '../level2Components/TreeCommunication.vue'
import DialogCommunication from '../level2Components/DialogCommunication.vue'
export default {
    name: 'Communication',
    mixins: [tableBg],
    components: { DialogCommunication, TreeCommunication },
    data () {
        return {
            isTreeShow: true,
            // 双向绑定--总数
            totalNumForPagenation: 0,
            // 双向绑定--每页的个数
            pageSize: 10,
            // 双向绑定--当前页
            currentPage: 1,
            // 双向绑定的搜索数据
            searchValue: '',
            // 双向绑定的用户状态
            userState: '',

            // 表格数据
            tableData: [],
            // 客户id
            customerId: '',
            customerName: '',
        }
    },

    methods: {

        // 刷新页面
        refreshThePage () {
            this.$store.dispatch('getCommunicationPageData', { pageNum: this.currentPage, pageSize: this.pageSize, keyword: this.searchValue, customerId: this.customerId }).then(() => {
                // 做数据深拷贝
                this.tableData = []
                for (let i = 0; i < this.communicationPageData.length; i++) {
                    const tableSingle = { name: "", ip: "", port: "", createTime: "" }
                    tableSingle.name = this.communicationPageData[i].name;
                    tableSingle.ip = this.communicationPageData[i].ip;
                    tableSingle.port = this.communicationPageData[i].port;
                    tableSingle.createTime = this.communicationPageData[i].createTime;
                    this.tableData.push(tableSingle)
                }

                this.totalNumForPagenation = this.communicationPageDataTotal
            })
        },

        // 点击搜索或者回车
        search () {
            this.currentPage = '1'
            this.refreshThePage()
        },
        // 更改用户状态选择框的选择
        selectChanged () {
            this.refreshThePage()
        },
        //点击新增用户
        ifDialogShow () {
            if (this.customerId == '') { return; }
            this.$refs.dialogRef.show(this.customerId, this.customerName)
        },
        //点击了编辑按钮
        handleEdit (index) {
            this.$refs.dialogRef.showForEdit(this.dictionaryPageData[index])
        },
        // 点击了删除按钮
        handleDelete (index) {

            this.$confirm("您确定要执行删除操作吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$store.dispatch('deleteCommunication', this.communicationPageData[index].id).then(() => {
                    if (this.ifDeleteCommunicationSuccess === 0) {
                        this.$message({ message: "删除成功！", type: "success" });
                        this.refreshThePage();
                    } else if (this.ifDeleteCommunicationSuccess === -1) {
                        this.$message({ message: this.ifDeleteCommunicationError, type: "warning" });
                    }
                });
            });

        },
        // 点击树的节点
        chargeForTree (val) {
            this.currentPage = 1;
            this.customerId = val.id
            this.customerName = val.name
            this.refreshThePage()
        },

        // 更改分页器每页个数
        handleSizeChange (val) {
            this.currentPage = '1'
            this.pageSize = val
            this.refreshThePage()
        },
        // 更改分页器页面
        handleCurrentChange (val) {
            this.currentPage = val
            this.refreshThePage()
        }
    },


    mounted () {
        this.refreshThePage()
    },


    computed: {
        ...mapState({
            communicationPageData: (state) => { return state.system.communicationPageData.data.records },
            communicationPageDataTotal: (state) => { return state.system.communicationPageData.data.total },



            ifDeleteCommunicationSuccess: (state) => { return state.system.ifDeleteCommunicationSuccess.errorCode },
            ifDeleteCommunicationError: (state) => { return state.system.ifDeleteCommunicationSuccess.message },
            // ifResetPassword:(state)=>{return state.system.ifResetPassword.errorCode},
        })

    }
}
</script>

<style lang="less" scoped>
// 顶部样式##################################################################
.topBar {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    box-sizing: border-box;
    height: 8rem;
    // width: 171.2rem;
    width: 100%;
    background-color: #0d3050;
    border-radius: 0.4rem;
    // position: absolute;
    // top: 0;
    // left: 0;
    overflow: hidden;
    margin-bottom: 2.4rem;
    .searchInput {
        height: 3.2rem;
        width: 22.8rem;
        font-size: 1.4rem;
        position: absolute;
        left: 2.4rem;
        top: 2.4rem;
        background-color: #0c375e;
        border: 1px solid #004579;
        border-radius: 0.2rem;
        box-sizing: border-box;
        outline: 0;
        color: #fff;
        text-indent: 1.4rem;
        &:focus {
            border: 0.1rem solid #008080;
        }
    }

    button {
        height: 3.2rem;
        width: 7.4rem;
        border-radius: 0.2rem;
        border: none;
        color: #fff;
        font-size: 1.4rem;
        cursor: pointer;
    }
    .btn1 {
        background-color: #027969;
        position: absolute;
        top: 2.4rem;
        left: 26rem;
    }
    .btn2 {
        background-color: #027969;
        position: absolute;
        top: 2.4rem;
        // left: 130rem;
        right: 2rem;
        width: 10.2rem;
    }
    .btn3 {
        background-color: grey;
        position: absolute;
        top: 2.4rem;
        // left: 130rem;
        right: 2rem;
        width: 10.2rem;
    }
    .businessType {
        height: 3.2rem;
        width: 20.8rem;
        position: absolute;
        left: 36rem;
        top: 2.4rem;
    }
    .businessSelector {
        margin: 0 2rem;
    }
}

// 中部表格样式##################################################################
.tablePlace {
    height: 86.4rem;
    // width: 142.8rem;
    // position: absolute;
    // top: 10.4rem;
    // left: 0;
    width: 100%;
    overflow: hidden;
    background-color: #0d3050;
    border-radius: 0.4rem;
    .bread {
        color: #fff;
        height: 5rem;
        width: 142.8rem;
        line-height: 5rem;
        color: #fff;
        font-size: 1.4rem;
        text-indent: 2.4rem;
    }
    .table {
        height: 57.2rem;
        // width: 138rem;
        margin: 0 2.4rem 0 2.4rem;
        // overflow: hidden;
        // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
        .el-table td,
        .el-table th.is-leaf {
            border-bottom: 0.1rem solid #254561;
        }
        .el-table--border:after,
        .el-table--group:after,
        .el-table:before {
            opacity: 0;
        }
        .el-table__body-wrapper {
            background-color: #0d3050;
        }
        .el-table__cell {
            background-color: #0d3050;
        }
        .el-button--text {
            color: #03d9bc;
        }
    }
}
// 分页器样式
.pagination {
    float: right;
    margin-right: 5rem;
    margin-top: 1rem;
}
</style>


<style lang='less' scoped>
// 公共样式！谨防代码污染！#########################################
.CommunicationOutSider {
    .table {
        height: 57.2rem;
        // width: 138rem;
        // overflow: hidden;
        // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
        .el-table td,
        .el-table th.is-leaf {
            border-bottom: 0.1rem solid #254561;
        }
        .el-table--border:after,
        .el-table--group:after,
        .el-table:before {
            opacity: 0;
        }
        .el-table__body-wrapper {
            background-color: #0d3050;
        }
        .el-table__cell {
            background-color: #0d3050;
        }
        .el-button--text {
            color: #03d9bc;
        }
    }
    .btn_save {
        margin-left: 2rem;
    }
    // 左侧部分##########################################################
    .leftBlock {
        // width: 26rem;
        // height: 96.8rem;
        // overflow: hidden;
        // position: absolute;
        // left: 0;
        // top: 0;
        // background-color: aqua;
    }

    .Communication {
        // position: absolute;
        // left: 28rem;
        // top: 0;
        // width: 143rem;
    }
}
</style>