<template>
    <div class="step-bottom-left-size">
        <span class="title">分项能耗趋势</span>
        <div id="myChart2" class="c2"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "stepBottomLeft",
    data () {
        return {
            dataList1: [],
            dataList2: [],
            dataList3: [],
            dataList4: [],
            xdata: [],
            customerId: "",
            yMax: 0,
        };
    },
    mounted () {
        // this.queryBarList();
    },
    methods: {
        //楼层id的数据
        queryBarList () {
            this.customerId = this.$store.state.storage.customerId;
            this.$store.dispatch("getFourType", this.customerId).then(() => {
                let EchartsBarList = this.$store.state.elecManagement.getFourType.data;
                if (EchartsBarList !== null) {
                    if (EchartsBarList[0] == null) {
                        this.dataList1 = [];
                        this.dataList2 = [];
                        this.dataList3 = [];
                        this.dataList4 = [];
                        this.xdata = [
                            "00:00",
                            "01:00",
                            "02:00",
                            "03:00",
                            "04:00",
                            "05:00",
                            "06:00",
                            "07:00",
                            "08:00",
                            "09:00",
                            "10:00",
                            "11:00",
                            "12:00",
                            "13:00",
                            "14:00",
                            "15:00",
                            "16:00",
                            "17:00",
                            "18:00",
                            "19:00",
                            "20:00",
                            "21:00",
                            "22:00",
                            "23:00",
                        ];
                        this.yMax = 0;
                        this.drawLine1();
                    } else {
                        this.dataList1 = EchartsBarList[0].data;
                        this.dataList2 = EchartsBarList[1].data;
                        this.dataList3 = EchartsBarList[2].data;
                        this.dataList4 = EchartsBarList[3].data;
                        this.yMax = 1;
                        this.drawLine1();
                    }
                } else {
                    this.dataList1 = [];
                    this.dataList2 = [];
                    this.dataList3 = [];
                    this.dataList4 = [];
                    this.xdata = [
                        "00:00",
                        "01:00",
                        "02:00",
                        "03:00",
                        "04:00",
                        "05:00",
                        "06:00",
                        "07:00",
                        "08:00",
                        "09:00",
                        "10:00",
                        "11:00",
                        "12:00",
                        "13:00",
                        "14:00",
                        "15:00",
                        "16:00",
                        "17:00",
                        "18:00",
                        "19:00",
                        "20:00",
                        "21:00",
                        "22:00",
                        "23:00",
                    ];
                    this.yMax = 0;
                    this.drawLine1();
                }
            });
        },
        ceilNumber (number) {
            let bite = 0;
            if (number < 10) {
                return 10;
            }
            while (number >= 10) {
                number /= 10;
                bite += 1;
            }
            return Math.ceil(number) * Math.pow(10, bite);
        },
        getXdata (len) {
            this.xdata = [];
            let date = new Date();
            let m = date.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            for (let i = 1; i < len + 1; i++) {
                if (i < 10) {
                    i = "0" + i;
                }
                let item = m + "-" + i;
                this.xdata.push(item);
            }
        },
        drawLine1 () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart2"));
            // 绘制图表
            let barList1 = [];
            let barList2 = [];
            let barList3 = [];
            let barList4 = [];
            //data里面每一个的数据
            barList1 = this.dataList1;
            barList2 = this.dataList2;
            barList3 = this.dataList3;
            barList4 = this.dataList4;
            let yMax = 0;
            for (let i = 0; i < barList1.length; i++) {
                if (barList1[i] > yMax) {
                    yMax = barList1[i];
                }
            }
            for (let i = 0; i < barList2.length; i++) {
                if (barList2[i] > yMax) {
                    yMax = barList2[i];
                }
            }
            for (let i = 0; i < barList3.length; i++) {
                if (barList3[i] > yMax) {
                    yMax = barList3[i];
                }
            }
            for (let i = 0; i < barList4.length; i++) {
                if (barList4[i] > yMax) {
                    yMax = barList4[i];
                }
            }
            yMax = this.ceilNumber(yMax);
            let length = barList1.length;
            if (length > 0) {
                this.getXdata(length);
            }
            myChart.setOption(
                {
                    title: {},
                    tooltip: {
                        trigger: "axis",
                        showDelay: 0, // 显示延迟，添加显示延迟可以避免频繁切换，单位ms
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                        },
                    },
                    legend: {
                        data: ["照明", "暖通", "动力", "特殊"],
                        x: "center", //可设定图例在左、右、居中
                        y: "bottom", //可设定图例在上、下、居中
                        padding: [0, 0, 15, 20],
                        textStyle: {
                            color: "#ffffff",
                            fontSize: 1.4 + "rem",
                            fontWeight: "400",
                        },
                        icon: "circle",
                    },
                    grid: {
                        x: 20,
                        x2: 20,
                        y: 78,
                        y2: 45,
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            data: this.xdata,
                            type: "category",
                            axisLabel: {
                                textStyle: {
                                    color: "#ffffff",
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            min: 0,
                            max: this.yMax == 0 ? 10 : null,
                            // interval: parseInt(yMax / 4),
                            type: "value",
                            name: "单位:kWh",
                            axisLabel: {
                                textStyle: {
                                    color: "#fff",
                                },
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: "dashed",
                                    color: "rgba(230,247,255,0.2)",
                                },
                            },
                        },
                    ],
                    series: [
                        {
                            name: "照明",
                            type: "bar",
                            stack: "总量",
                            itemStyle: {
                                color: "#3BE7CA",
                            },
                            data: barList2,
                        },
                        {
                            name: "暖通",
                            type: "bar",
                            stack: "总量",
                            barWidth: 10, //柱图宽度
                            itemStyle: {
                                color: "#318DEF",
                            },
                            data: barList1,
                        },
                        {
                            name: "动力",
                            type: "bar",
                            stack: "总量",
                            barWidth: 10, //柱图宽度
                            itemStyle: {
                                color: "#E2C66D",
                            },
                            data: barList3,
                        },
                        {
                            name: "特殊",
                            type: "bar",
                            stack: "总量",
                            barWidth: 10, //柱图宽度
                            itemStyle: {
                                color: "#FF6842",
                            },
                            data: barList4,
                        },
                    ],
                },
                true
            );
            myChart._dom.childNodes[0].childNodes[0].style.width = "106.5rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "44.3rem";
        },
    },
    computed: {
        ...mapState({
            getFourType: (state) => {
                return state.elecManagement.getFourType.data;
            },
        }),
    },
    watch: {
        "$store.state.storage.customerId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                console.log('newVal',newVal)
                if (!newVal) {
                    return;
                }
                this.customerId = newVal;
                //监听用户id调用方法，不然报错id不存在
                this.queryBarList();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.step-bottom-left-size {
    position: relative;
    .title {
        position: absolute;
        top: 2rem;
        margin-left: 2.4rem;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
    }
    .c2 {
        width: 106.5rem;
        // width: 100%;
        height: 44.3rem;
        // display: flex;
        // justify-content: center;
        margin: 0 auto;
    }
}
</style>
<style></style>
