<template>
    <div class="ChargeStatistics">
        <!-- 顶部栏搜索位置############################################################## -->
        <div class="topBar">
            <!----------------------------------------------------充电桩名称输入框------------------------------------------------------------ -->
            <el-input size="small" placeholder="请输入充电桩名称" v-model="nameValue" @keyup.enter.native="search" style="width:16.8rem;margin:1rem 0 0 2rem" clearable @clear="search"></el-input>

            <!----------------------------------------------------查询按钮------------------------------------------------------------ -->
            <button class="btn_save" style="margin-left:1.6rem;border-radius:0.2rem" @click="search">
                查 询
            </button>

            <!----------------------------------------------------导出按钮------------------------------------------------------------ -->
            <button class="btn_save" style="margin:1rem 1.6rem;border-radius:0.2rem;float:right" @click="handleExport">
                导 出
            </button>
        </div>

        <!-- 中部表格位置################################################################# -->
        <div class="contentPlace">
            <!---------------------------------------------------- 表格部分--------------------------------------------------------------------------- -->
            <div class="table">
                <el-table :data="tableData" stripe:false border:false style="width: 100%" :header-cell-style="headerClass" :cell-style="cellStyle">
                    <!-- <el-table-column type="index" label="序号" width="50"></el-table-column> -->
                    <el-table-column label="序号" width="50">
                        <template slot-scope="scope">{{
              scope.$index + 1 + (currentPage - 1) * pageSize
            }}</template>
                    </el-table-column>
                    <el-table-column prop="provinceName" label="省份" min-width="10%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="cityName" label="地市" min-width="10%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="ownerName" label="客户单位" min-width="16%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="deviceName" label="充电桩名称" min-width="12%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="todayCount" label="累计次数" min-width="12%" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span>{{ scope.row.todayCount + "次" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="accumulative" label="累计耗电量" min-width="12%" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="totalPrices" label="消费总额" min-width="19%" :show-overflow-tooltip="true">
                    </el-table-column>
                </el-table>
            </div>
            <!----------------------------------------------------分页器部分--------------------------------------------------------------------------- -->
            <div class="pagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalNumForPagenation">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg"
import { exportChargingChargeReport } from "@/api/repair"

export default {
    name: "ChargeStatistics",
    mixins: [tableBg],
    data () {
        return {
            // 驴充充的token令牌
            chargeToken: "",
            // 搜索框双向绑定数据(充电桩名称)
            nameValue: "",

            // 双向绑定--总数
            totalNumForPagenation: 0,
            // 双向绑定--每页的个数
            pageSize: 10,
            // 双向绑定--当前页
            currentPage: 1,
            // 表格数据(假数据)
            tableData: [],
            customerName: '',
        }
    },
    watch: {
        "$store.state.storage.customerData": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.customerName = newVal.name;
            },
        },
    },
    created () {
        // this.getlist()
        // 清除同名的所有时间总线，自己和其他兄弟模块的
        this.$bus.$off("chargeForTree")
    },

    mounted () {
        this.$bus.$on("chargeForTree", (val) => {
            if (val == []) {
                alert("该用户没有充电业务")
                return
            }
            this.currentPage = 1
            this.chargeToken = val
            this.getlist()
        })
    },
    beforeDestroy () {
        this.$bus.$emit("refreshTheChargeTree")
    },

    methods: {
        // 点击了导出
        handleExport () {
            let chargeValue = ""
            if (this.chargeToken.length > 0) {
                for (let i = 0; i < this.chargeToken.length; i++) {
                    chargeValue = chargeValue + this.chargeToken[i] + ","
                }
                chargeValue = chargeValue.slice(0, chargeValue.length - 1)
            } else {
                chargeValue = ""
            }
            var value = {
                chargeToken: chargeValue,
                deviceName: this.nameValue,
                myPage: "ChargeStatistics",
            }
            // this.$store.dispatch("getExportFileCharge", value)
            exportChargingChargeReport(value).then((response) => {
                var blob = new Blob([response], {
                    type: "application/vnd.ms-excel,charset=utf-8",
                }) //创建一个blob对象
                var a = document.createElement("a") //创建一个<a></a>标签
                a.href = URL.createObjectURL(blob) // response is a blob
                a.download = `${this.customerName}充电统计.xls` //文件名称
                a.style.display = "none"
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        },
        // 列表渲染
        getlist () {
            this.$store
                .dispatch("getChargeStatisticsPageList", {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    deviceName: this.nameValue,
                    chargeToken: this.chargeToken,
                })
                .then(() => {
                    const res = this.$store.state.elecCharge.chargeStatisticsPageList
                    if (res.errorCode == 0) {
                        this.tableData = res.data.records
                        this.totalNumForPagenation = res.data.total
                    } else {
                        this.$message(res.message)
                    }
                })
        },

        // 搜索框点击搜索
        search () {
            // alert("进行了搜索操作")
            this.getlist()
        },

        // 更改分页器每页个数
        handleSizeChange (val) {
            this.pageSize = val
            this.getlist()
        },
        // 更改分页器页面
        handleCurrentChange (val) {
            this.currentPage = val
            this.getlist()
        },
    },
}
</script>

<style lang="less" scoped>
.topBar {
    height: 6rem;
    width: 100%;
    background-color: #0d3050;
    border-radius: 0 0 0.4rem 0.4rem;
    //   position: absolute;
    //   left: 0;
    //   top: -2.9rem;
}

.contentPlace {
    margin-top: 2.4rem;
    height: 80rem;
    width: 100%;
    background-color: #0d3050;
    border-radius: 0.4rem;
    //   position: absolute;
    //   top: 5rem;
    //   left: 0;
}
.table {
    padding: 2.4rem;
    width: 96.5%;
    // height: 58rem;
}
.pagination {
    // float: right;
    // margin-right: 2.4rem;
    position: absolute;
    right: 2.4rem;
    bottom: 3.5rem;
}
</style>
