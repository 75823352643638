<template>
  <!-- 线图 -->
  <div class="dialog_echarts">
    <div ref="myChart" class="charts"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "EchartAreaMap",
  props: ["lineList"],
  data() {
    return {
      lineList1: [],
      xdata: [],
      monthDay: [31, "", 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
    };
  },
  watch: {
    lineList: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.lineList = newVal;
        this.init();
      },
    },
  },
  methods: {
    // 销毁echarts
    dispose() {
      this.$echarts.dispose(this.$refs.myChart);
    },
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    getXdata(lens) {
      this.xdata = [];
      let date = new Date();
      let m = date.getMonth() + 1;
      let year = date.getFullYear();
      if (m < 10) {
        m = "0" + m;
      }

      // if (len > 0) {
      //   for (let i = 1; i < len + 1; i++) {
      //     if (i < 10) {
      //       i = "0" + i;
      //     }
      //     let item = m + "-" + i;
      //     this.xdata.push(item);
      //   }
      // } else {
      // let len = 30;
      let len = 30;
      if (m == 2) {
        len = this.isLeapYear(year) ? 29 : 28;
      } else {
        len = this.monthDay[m - 1];
      }
      for (let i = 1; i < len + 1; i++) {
        if (i < 10) {
          i = "0" + i;
        }
        let item = m + "-" + i;
        this.xdata.push(item);
      }
      // }
    },
    isLeapYear(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    },
    init() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.myChart);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.myChart);
      }

      let yMax = 0;
      for (let i = 0; i < this.lineList.length; i++) {
        const item = this.lineList[i];
        if (item > yMax) {
          yMax = item;
        }
      }
      yMax = this.ceilNumber(yMax);
      let length = this.lineList.length;
      this.getXdata(length);
      let option = {
        title: {
          text: "",
          top: "0px",
          left: "15px",
          textStyle: {
            color: "#37A5DD",
            fontSize: 14,
            lineHeight: 10,
            fontWeight: 400,
          },
          subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
        },
        color: ["#3673E8", "#61B8FF"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
          bottom: 6,
          data: this.optionData?.legendData,
        },
        grid: {
          x: 18,
          x2: 20,
          y2: 28,
          y: 70,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "rgba(43, 113, 176, 0.45)",
            },
          },
          axisLabel: {
            color: "#fff",
          },
          data: this.xdata,
        },
        yAxis: [
          {
            name: "kWh",
            min: 0,
            max: yMax,
            interval: parseInt(yMax / 4),
            type: "value",
            axisLabel: {
              color: "#fff",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(230,247,255,0.2)",
              },
            },
          },
        ],
        series: [
          {
            name: this.$store.state.storage.buildingName,
            type: "line",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#1EE7E7" },
                  { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
                ]),
              },
            },
            data: this.lineList,
            showSymbol: false,
            smooth: true,
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option, true);
      myChart._dom.childNodes[0].childNodes[0].style.width = "104rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "42rem";
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.dialog_echarts {
  .charts {
    width: 104rem;
    height: 42rem;
  }
}
</style>
