<template>
    <div class="reportRouter">
        <div class="enerTopRouter">
            <p :class="ruoter1" @click="changeTab(1)">区域用能</p>
            <p :class="ruoter2" @click="changeTab(2)">分项用能</p>
            <p :class="ruoter3" @click="changeTab(3)">配电用能</p>
        </div>
        <div class="routerLay">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReportStatistical",
    data () {
        return {
            ruoter1: "btnGreen",
            ruoter2: "btnGray",
            ruoter3: "btnGray",
        };
    },
    mounted () { },
    methods: {
        changeTab (index) {
            if (index === 1) {
                this.ruoter1 = "btnGreen";
                this.ruoter2 = "btnGray";
                this.ruoter3 = "btnGray";
                this.$router.push({
                    path: "/ElecManagement/StatisticalReport/regionEnergy",
                });
            } else if (index === 2) {
                this.ruoter1 = "btnGray";
                this.ruoter2 = "btnGreen";
                this.ruoter3 = "btnGray";
                this.$router.push({
                    path: "/ElecManagement/StatisticalReport/subitemEnergy",
                });
            } else if (index === 3) {
                this.ruoter1 = "btnGray";
                this.ruoter2 = "btnGray";
                this.ruoter3 = "btnGreen";
                this.$router.push({
                    path: "/ElecManagement/StatisticalReport/powerDistributionEnergy",
                });
            }
        },
    },
    watch: {},
};
</script>
<style lang="less" scoped>
.reportRouter {
    .enerTopRouter {
        // width: 142.8rem;
        width: 100%;
        height: 3.4rem;
        font-size: 1.4rem;
        background: #0c2f4f;
        display: flex;
        margin-top: -3.2rem;
        .btnGreen {
            color: #03d9bc;
            margin-left: 2.6rem;
            cursor: pointer;
            border-bottom: 0.2rem solid #03d9bc;
        }
        .btnGray {
            color: aliceblue;
            margin-left: 2.6rem;
            cursor: pointer;
        }
    }
    .routerLay {
        height: 86.4rem;
        // width: 142.8rem;
        width: calc(100% + 0.6rem);
        background-color: #18191a;
        // overflow-y: scroll;
    }
    /deep/ .el-table {
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        flex: 1;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        color: #fff;
    }
    /deep/ .el-form-item__label {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #fff;
        padding: 0 12px 0 0;
        box-sizing: border-box;
    }
}
</style>
