<template>
    <div class="fire_control">
        <div v-if="diagramCode == ''" style="background: rgba(0, 115, 222, 0.35);width:100%;height:100%;"></div>
        <div v-else class="contentPlace">
            <div class="tab-title content-nav">
                <div :class="{ style1: tabIdx === 'E' }" @click="choiceTab('E')">
                    漏电火灾监测
                </div>
                <div :class="{ style1: tabIdx === 'F' }" @click="choiceTab('F')">
                    消防电源监测
                </div>
            </div>
            <div class="content-box">
                <div class="content-outer">
                    <div class="tab-title chart-nav">
                        <div class="chart-btn-box">
                            <div v-for="chart in chartData" :key="chart.id" :class="{ style1: chartId === chart.id }" @click="choiceTab3(chart.id)">
                                {{ chart.diagramName }}
                            </div>
                        </div>
                        <span class="btn-report" @click="goReport">报表统计</span>
                    </div>
                    <div class="electricInfo" v-if="tabIdx == 'E'">
                        <div class="leftBox flex-col">
                            <!-- 这个是图例 -->
                            <div class="stateBox">
                                <div class="legend margin">
                                    <div class="stateList" v-for="(x, idx) in stateList" :key="idx">
                                        <div class="legendCode" :class="
                        x.code == 0
                          ? 'greenBac'
                          : x.code == 1
                          ? 'redBac'
                          : 'grey'
                      "></div>
                                        <span class="color1 fontSize">
                                            {{ x.code == 0 ? '正常' : x.code == 1 ? '异常' : '无效' }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="graph-box">
                                <single-preview :whichTab="tabIdx" :chartId="chartId" @initTable="initTable" @getChartTabData="getChartTabData" />
                            </div>
                        </div>
                        <div class="rightBox">
                            <!-- 标题部分star -->
                            <div class="title-list-info">
                                <div class="title-name" v-for="(x, index) in titleList" :key="index">
                                    {{ x }}
                                </div>
                            </div>
                            <!-- 标题部分end -->
                            <!-- list star -->
                            <div class="flex boxInfo">
                                <div class="list-info" v-for="(item, index) in listListInfo" :key="index">
                                    <div class="listNameInfo">{{ item.componentName }}</div>
                                    <div class="listNameInfo">{{ item.ld }}</div>
                                    <div class="listNameInfo">{{ item.oneWd }}</div>
                                    <div class="listNameInfo">{{ item.twoWd }}</div>
                                </div>
                            </div>
                            <!-- list end -->
                            <!-- 翻页 -->
                            <el-pagination v-if="tabIdx == 'E'" class="pagination-box" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[15, 30, 45, 60]" :page-size="pageSize" layout="sizes,prev, pager, next" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                    <div class="fireControlInfo" v-if="tabIdx == 'F'">
                        <div class="top-box">
                            <div class="son-left-box flex-col">
                                <div class="stateBox">
                                    <div class="legend margin">
                                        <div class="stateList" v-for="(x, idx) in stateList" :key="idx">
                                            <div class="legendCode" :class="
                          x.code == 0
                            ? 'greenBac'
                            : x.code == 1
                            ? 'redBac'
                            : 'grey'
                        "></div>
                                            <span class="color1 fontSize">
                                                {{
                          x.code == 0 ? '正常' : x.code == 1 ? '异常' : '无效'
                        }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="graph-box">
                                    <single-preview :whichTab="tabIdx" :chartId="chartId" @initTable="initTable" @getChartTabData="getChartTabData" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-title">
                            <div :class="{ style1: currentTab === 'sjjc' }" @click="choiceTab2('sjjc')">
                                数据监测
                            </div>
                            <div :class="{ style1: currentTab === 'sxdy' }" @click="choiceTab2('sxdy')">
                                实时三相电压
                            </div>
                        </div>
                        <div class="bottom-box" v-if="currentTab == 'sjjc'">
                            <div class="single-ul-box" :style="{
                  width:
                    sjjcData.length > 13
                      ? (sjjcData.length + 1) * 11.6 + 'rem'
                      : '100%',
                }">
                                <ul class="single-ul">
                                    <li v-for="(x, idx) in codeInfoArr" :key="idx">{{ x }}</li>
                                </ul>
                                <ul class="single-ul" v-for="(item, index) in sjjcData" :key="index">
                                    <li>{{ item.name }}</li>
                                    <li><i class="yd"></i></li>
                                    <li><i class="yd"></i></li>
                                    <li><i class="yd"></i></li>
                                    <li><i class="yd"></i></li>
                                    <li><i class="yd"></i></li>
                                    <li><i class="yd"></i></li>
                                </ul>
                            </div>
                        </div>
                        <div class="son-right-box" v-if="currentTab == 'sxdy'">
                            <div class="single-ul-box" :style="{
                  width:
                    SSDYData.length > 13
                      ? (SSDYData.length + 1) * 11.6 + 'rem'
                      : '100%',
                }">
                                <ul class="single-ul">
                                    <li v-for="(x, idx) in SSDYFirst" :key="idx">{{ x }}</li>
                                </ul>
                                <ul class="single-ul" v-for="(item, index) in SSDYData" :key="index">
                                    <li>{{ item.name }}</li>
                                    <li>{{ item.A }}</li>
                                    <li>{{ item.B }}</li>
                                    <li>{{ item.C }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="height:10rem;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SinglePreview from '@/components/SinglePreview.vue'
export default {
    name: 'FireMain',
    components: { SinglePreview },
    data () {
        return {
            total: 0,
            pageSize: 15,
            currentPage: 1,
            chartId: 0,
            totalLDJCData: [],
            chartData: [
                {
                    id: 0,
                    diagramName: '系统图1',
                },
                {
                    id: 1,
                    diagramName: '系统图2',
                },
                {
                    id: 2,
                    diagramName: '系统图3',
                },
                {
                    id: 3,
                    diagramName: '系统图4',
                },
                {
                    id: 4,
                    diagramName: '系统图5',
                },
            ],
            sjjcData: [
                {
                    name: '喷淋泵',
                },
                {
                    name: '消防水泵',
                },
            ],
            currentTab: 'sjjc',
            diagramCode: this.$route.query.id,
            customName: this.$route.query.name,
            tabTitleArr: ['漏电火灾', '消防设备电源检测'],
            titleList: ['柜号', '漏电(mA)', '1路温度(℃)', '2路温度(℃)'],
            listListInfo: [
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
                {
                    componentName: 'Alp',
                    ld: '0.5',
                    oneWd: '25',
                    twoWd: '10000',
                },
            ],
            SSDYFirst: ['实时数据', 'A相电压', 'B相电压', 'C相电压'],
            SSDYData: [
                {
                    name: '喷淋泵',
                    A: '240.53V',
                    B: '240.53V',
                    C: '240.53V',
                },
                {
                    name: '消防水泵',
                    A: '240.53V',
                    B: '240.53V',
                    C: '240.53V',
                },
                {
                    name: '1F应急照明',
                    A: '240.53V',
                    B: '240.53V',
                    C: '240.53V',
                },
            ],
            callThePolice: [
                '状态',
                '喷淋泵',
                '消防水泵',
                '1F应急照明',
                '消防电梯',
                '排烟风机',
                '报警系统',
                '2F应急照明',
                '喷淋泵',
                '防火卷帘',
                '楼宇/应急广播',
                '3F应急照明',
            ],
            tabIdx: 'E',
            codeInfoArr: ['状态', '过压', '欠压', '缺相', '断电', '错相', '过流'],
            /* 
              正常0 绿色
              异常1 红色
              无效2 灰色
            */
            codeList: [
                {
                    pl: 0,
                    xf: 0,
                    yj: 0,
                    xf: 0,
                    py: 0,
                    bj: 0,
                    zm: 0,
                    plb: 0,
                    fhjl: 0,
                    gb: 0,
                    pl: 0,
                    Fyj: 0,
                },
                {
                    pl: 0,
                    xf: 0,
                    yj: 0,
                    xf: 0,
                    py: 0,
                    bj: 0,
                    zm: 0,
                    plb: 0,
                    fhjl: 0,
                    gb: 0,
                    pl: 0,
                    Fyj: 0,
                },
                {
                    pl: 0,
                    xf: 0,
                    yj: 0,
                    xf: 0,
                    py: 0,
                    bj: 0,
                    zm: 0,
                    plb: 0,
                    fhjl: 0,
                    gb: 0,
                    pl: 0,
                    Fyj: 0,
                },
                {
                    pl: 0,
                    xf: 0,
                    yj: 0,
                    xf: 0,
                    py: 0,
                    bj: 0,
                    zm: 0,
                    plb: 0,
                    fhjl: 0,
                    gb: 0,
                    pl: 0,
                    Fyj: 0,
                },
                {
                    pl: 0,
                    xf: 0,
                    yj: 0,
                    xf: 0,
                    py: 0,
                    bj: 0,
                    zm: 0,
                    plb: 0,
                    fhjl: 0,
                    gb: 0,
                    pl: 0,
                    Fyj: 0,
                },
                {
                    pl: 0,
                    xf: 0,
                    yj: 0,
                    xf: 0,
                    py: 0,
                    bj: 0,
                    zm: 0,
                    plb: 0,
                    fhjl: 0,
                    gb: 0,
                    pl: 0,
                    Fyj: 0,
                },
            ],
            stateList: [
                {
                    code: 0,
                    name: '正常',
                },
                {
                    code: 1,
                    name: '异常',
                },
                {
                    code: 2,
                    name: '无效',
                },
            ],
        }
    },
    watch: {
        '$route': {
            handler (val) {
                if (val.query.id) {
                    this.diagramCode = val.query.id;
                    this.customName = val.query.name;
                    this.tabIdx = 'E'
                }
            },
            immediate: true
        }
    },
    methods: {
        //跳到统计报表
        goReport () {
            this.$router.push({
                path: '/fireControl/report', query: {
                    id: this.diagramCode,
                    name: this.customName,
                    type: 'fireControl' + this.tabIdx
                }
            })
        },
        //获取切换设计图菜单
        getChartTabData (data) {
            this.chartData = data;
        },
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            this.currentPage = 1;
            this.listListInfo = this.totalLDJCData.slice(0, val)
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`);
            this.currentPage = val;
            this.listListInfo = this.totalLDJCData.slice(this.pageSize * (val - 1), this.pageSize * val)
        },
        // 切换tab栏
        choiceTab (idx) {
            this.tabIdx = idx;
            this.chartId = 0;
        },
        choiceTab2 (idx) {
            this.currentTab = idx
        },
        choiceTab3 (idx) {
            this.chartId = idx
        },
        initTable (data) {
            // console.log('data-2222', data)
            if (data.length == 0) {
                this.listListInfo = []
                this.sjjcData = []
                this.SSDYData = []
                this.total = 0;
                this.currentPage = 1;
                this.pageSize = 15;
                return false
            }
            if (this.tabIdx == 'E') {
                this.total = data.length;
                this.currentPage = 1;
                this.pageSize = 15;
                this.totalLDJCData = data.map((item) => {
                    return {
                        componentName: item.name,
                        ld: '-',
                        oneWd: '-',
                        twoWd: '-',
                    }
                })
                this.listListInfo = this.totalLDJCData.length > 14 ? this.totalLDJCData.slice(0, 15) : [...this.totalLDJCData];
            } else if (this.tabIdx == 'F') {
                this.sjjcData = data.map((item) => {
                    return {
                        name: item.name,
                    }
                })
                this.SSDYData = data.map((item) => {
                    return {
                        name: item.name,
                        A: item.A相电压,
                        B: item.B相电压,
                        C: item.C相电压,
                    }
                })
            }
        },
    },
}
</script>

<style lang="less" scoped>
.fire_control {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    // 内容区
    .contentPlace {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        height: 96.8rem;
    }
    .pagination-box {
        text-align: right;
        padding: 1rem 0;
        height: 4.8rem;
        box-sizing: border-box;
    }
}
// 公共样式star
.flex {
    flex: 1;
    overflow-y: scroll;
}
.flex1 {
    flex: 1;
}
.displayColumn {
    display: flex;
    flex-direction: column;
}
.flex9 {
    flex: 9;
}
.color {
    color: #ffffff;
}
.color1 {
    color: rgba(255, 255, 255, 0.65);
}
.fontSize {
    font-size: 1.4rem;
}
.margin {
    margin: 3.2rem 4.9rem 0 0;
}
// 公共样式end
.tab-title {
    display: flex;
    padding-bottom: 0.8rem;
    border-bottom: 0.1rem solid #075296;
    div {
        color: #03d9bc;
        font-size: 1.4rem;
        cursor: pointer;
        position: relative;
    }
    div:nth-child(1) {
        margin-right: 2.4rem;
    }
}
.style1::before {
    content: "";
    position: absolute;
    bottom: -0.9rem;
    height: 2px;
    width: 100%;
    background-color: #03d9bc;
}
.content-box {
    padding-top: 1.6rem;
    flex: 1;
    // height: 86.4rem;
    box-sizing: border-box;
    overflow-y: auto;
    .content-outer {
        background: #0d3050;
        border-radius: 4px;
        padding: 2.4rem 2.4rem 1rem 2.4rem;
        min-height: 79.5rem;
        // height: 100%;
        // overflow-y: auto;
        box-sizing: border-box;
        // display: flex;
        // flex-direction: column;
    }
    .content-outer::-webkit-scrollbar {
        width: 0rem;
    }
}
.content-box::-webkit-scrollbar {
    width: 0;
}
.electricInfo {
    display: flex;
    justify-content: space-between;
    padding-top: 1.2rem;
    height: 73rem;
    // flex: 1;
    box-sizing: border-box;
    .leftBox {
        width: 52%;
        background: rgba(11, 44, 73, 1);
    }
    .rightBox {
        width: 45%;
        display: flex;
        flex-direction: column;
    }
}
.title-list-info {
    display: flex;
    justify-content: space-between;
}
.title-name {
    flex: 1;
    //   width: 14.8rem;
    height: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
    color: #ffffff;
    font-size: 1.4rem;
    background: rgba(52, 150, 241, 0.15);
    border-bottom: 0.1rem solid rgba(108, 184, 255, 0.3);
}
.boxInfo {
    overflow-y: scroll !important;
}
.list-info {
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.85);
    .listNameInfo {
        flex: 1;
        // width: 14.8rem;
        padding: 1.4rem 0;
        text-align: center;
        font-size: 1.4rem;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
    }
    .listNameInfo:nth-child(1) {
        color: #2cd9ff;
        font-size: 1.4rem;
    }
}
// right box
.fireControlInfo {
    display: flex;
    flex-direction: column;
    padding-top: 1.2rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
    .tab-title {
        margin: 2rem 0 1.4rem 0;
    }
}
.top-box {
    height: 46rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    width: 50%;
    .son-left-box {
        width: 100%;
        background-color: #0b2c49;
    }
}
.son-title-name {
    display: flex;
    justify-content: space-between;
}
.array-data-info {
    width: 9rem;
    height: 3.2rem;
    text-align: center;
    line-height: 3.2rem;
    color: #ffffff;
    font-size: 1.4rem;
    background: rgba(52, 150, 241, 0.15);
    border-bottom: 0.1rem solid #6cb8ff;
}

.bottom-box,
.son-right-box {
    height: 28rem;
    box-sizing: border-box;
    overflow: auto;
    .single-ul:last-child {
        padding-right: 0;
    }
    .single-ul-box {
        display: flex;
        justify-content: space-between;
    }
}

.single-ul {
    flex: 1;
    // width: 11.6rem;
    // padding-right: 0.6rem;
    box-sizing: border-box;
    li {
        height: 3.4rem;
        line-height: 3.4rem;
        text-align: center;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
    }
    li:first-child {
        background: rgba(52, 150, 241, 0.15);
        border-bottom: 0.1rem solid #6cb8ff;
    }
}
.son-right-box {
    .single-ul {
        li:nth-child(2) {
            color: #f5222d;
        }
        li:nth-child(3) {
            color: #52c41a;
        }
        li:nth-child(4) {
            color: #ffac30;
        }
    }
}
.allCodeList {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    text-align: center;
    div {
        width: 11rem;
        height: 3.2rem;
        line-height: 3.2rem;
        background: rgba(52, 150, 241, 0.15);
        border-bottom: 0.1rem solid #6cb8ff;
    }
}
.arr-data-info {
    padding: 0.6rem 2.4rem;
    color: #ffffff;
    font-size: 1.4rem;
    background: rgba(52, 150, 241, 0.15);
    border-bottom: 0.1rem solid rgba(108, 184, 255, 0.3);
}

.voltageNumber {
    display: flex;
    justify-content: space-between;
    div {
        width: 9rem;
        height: 4.7rem;
        text-align: center;
        line-height: 4.7rem;
        font-size: 1.4rem;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
    }
}
.dataList {
    display: flex;
    flex-direction: column;
    .specificData {
        display: flex;
        justify-content: space-between;
        div {
            width: 11rem;
            height: 3.2rem;
            width: 11rem;
            height: 3.2rem;
            text-align: center;
            line-height: 3.2rem;
            border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
        }
        div:nth-child(1) {
            width: 11rem;
            height: 3.2rem;
            text-align: center;
            line-height: 3.2rem;
            border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
        }
    }
}
.red {
    color: #f5222d;
}
.green {
    color: #52c41a;
}
.yellow {
    color: #ffac30;
}

.redBac {
    background-color: #f5222d;
}
.greenBac {
    background-color: #52c41a;
}
.yellowBac {
    background-color: #ffac30;
}
.grey {
    background-color: rgba(255, 255, 255, 0.45);
}
.globalDiplay {
    display: flex;
    flex-direction: column;
}
// 图例
.stateBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 4rem;
}
.stateList {
    display: flex;
    align-items: center;
}
.legend {
    width: 14rem;
    display: flex;
    justify-content: space-between;
}
.legendCode {
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 0.4rem;
}
.centerBox {
    display: flex;
    align-items: center;
    justify-content: center;
}
.yd {
    width: 1rem !important;
    height: 1rem !important;
    background-color: #52c41a;
    border-radius: 50%;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.graph-box {
    flex: 1;
    overflow: auto;
}
.content-nav.tab-title {
    background: #0d3050;
    border-radius: 4px;
    padding: 1.6rem 2.4rem;
    border-bottom: none;
    // height: 64px;
    height: 6.4rem;
    box-sizing: border-box;
    > div {
        background: rgba(0, 115, 222, 0.1);
        border: 1px solid #004579;
        box-sizing: border-box;
        border-radius: 0px 2px 2px 0px;
        font-size: 14px;
        color: #ffffff;
        padding: 0 2.3rem;
        line-height: 3.2rem;
        height: 3.2rem;
        margin: 0;
    }
    > div.style1 {
        background: #027969;
        border-radius: 2px 0px 0px 2px;
        border-color: #027969;
    }
    .style1::before {
        position: static;
    }
}
.chart-nav.tab-title {
    justify-content: space-between;
    height: 3.7rem;
    box-sizing: border-box;
    .chart-btn-box {
        display: flex;
        justify-content: flex-start;
        > div {
            color: rgba(255, 255, 255, 0.85);
            font-size: 14px;
            margin-right: 2.4rem;
            line-height: 2.2rem;
        }
        > div.style1 {
            color: #03d9bc;
        }
    }
}
</style>
