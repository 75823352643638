<template>
  <div class="app-container">
    <!-- queryForm -->
    <el-form ref="queryForm"
             class="bar"
             label-width="100px"
             :model="queryParams"
             :inline="true"
    >

      <el-form-item label="元件名称" prop="componentName">
        <el-input v-model="queryParams.componentName"
                  placeholder="输入元件名称搜索"
                  clearable
                  @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="元件分组" prop="groupId">
        <el-select v-model="queryParams.groupId"
                   filterable
                   placeholder="全部"
                   v-el-select-loadmore="handleLoadMore"
        >
          <el-option v-for="item in groupList"
                     :key="item.groupId"
                     :label="item.groupName"
                     :value="item.groupId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="启用状态" prop="isEnable">
        <el-select v-model="queryParams.isEnable"
                   placeholder="请选择启用状态"
                   clearable
        >
          <el-option label="启用" :value="1" />
          <el-option label="未启用" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item class="left-btns">
        <el-button type="primary" plain @click="handleQuery">查询</el-button>
        <el-button type="primary" plain @click="resetQuery">重置</el-button>
      </el-form-item>
      <el-form-item class="right-btns">
        <el-button type="primary" plain @click="handleAdd">添加元件</el-button>
        <el-button type="primary" plain :disabled="multiple" @click="handleDelete">批量删除</el-button>
      </el-form-item>
    </el-form>
    <!-- table -->
    <div class="table">
      <el-table v-loading="loading" lazy
                max-height="80vh"
                :data="componentList"
                row-key="componentId"
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                @selection-change="handleSelectionChange"
      >
        <el-table-column align="center" type="selection" width="55" :selectable="checkChildren"/>
        <el-table-column label="元件名称" prop="componentName" />
        <el-table-column label="元件图标" align="center" >
          <template slot-scope="{row}">
            <img v-if="row.sourceUrl" height="30" :src="envApi + row.sourceUrl"/>
          </template>
        </el-table-column>
        <el-table-column label="序号" align="center" prop="num" />
        <el-table-column label="是否启用" align="center" prop="isEnable">
          <template slot-scope="{row}">
            <div v-show="!row.children && isNaN(Number(row.componentId))">
              <span v-if="row.isEnable" style="color: #03d9bc; margin-right: 1rem;">启用</span>
              <span v-else style="color: #516a65; margin-right: 1rem;">停用</span>
              <el-switch v-model="row.isEnable"
                         active-color="#027969"
                         inactive-color="#6E798C"
                         :active-value="1"
                         :inactive-value="0"
                         @change="toggleEnable(row)"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="{ row }">
            <div class="caozuobtn-box" v-if="!row.children && isNaN(Number(row.componentId))" >
              <div class="caozuobtn" @click="handleUpdate(row.componentId)">编辑</div>
              <div class="caozuobtn" @click="handleDelete(row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加、修改元件 -->
    <el-dialog class="add-edit-dialog"
               v-dialogDrag append-to-body
               width="640px"
               top="30vh"
               :title="title"
               :visible.sync="open"
               :close-on-click-modal="false"
               @close="closeDialog"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="元件分组" prop="groupId">
          <el-select v-model="form.groupId"
                     class="select-group"
                     filterable
                     placeholder="请选择元件分组"
                     v-el-select-loadmore="handleLoadMore"
          >
            <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName" :value="item.groupId" leaf:true/>
          </el-select>
          <el-button class="btn-add-group" type="primary" plain @click="handleAddComponentGroup">添加元件分组</el-button>
        </el-form-item>
        <el-form-item label="元件名称" prop="componentName">
          <el-input v-model="form.componentName" placeholder="请输入元件名称" style="width: 480px"/>
        </el-form-item>
        <!--<el-form-item label="元件图标">
          <el-upload class="upload-demo" action="#" :limit="1"
                     accept=".SVG,.svg" list-type="picture-card"
                     :file-list="uploadFileList"
                     :auto-upload="false"
                     :on-change="uploadListChange"
                     :on-remove="handleRemove">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              请上传元件图标文件，当前只支持SVG格式的文件上传，请勿上传jpg、png等图片
            </div>
          </el-upload>
        </el-form-item>-->

        <el-form-item label="元件图标">
          <el-upload class="avatar-uploader" action="#" :on-change="uploadFileChange">
            <img v-if="imageUrl" height="100" :src="imageUrl" class="avatar">
            <div v-else>
              <i slot="default" class="el-icon-plus upload-area"></i>
              <div slot="tip" class="el-upload__tip">
                请上传元件图标文件，当前只支持SVG格式的文件上传，请勿上传jpg、png等图片
              </div>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="元件序号" prop="num">
          <el-input-number v-model="form.num" :min="0" style="width: 480px"/>
        </el-form-item>
        <!--
        <el-form-item label="元件模板" prop="template">
          <el-input v-model="form.template" placeholder="请输入元件模板" style="width: 480px"/>
        </el-form-item>
        -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="submitForm">确 定</el-button>
        <el-button @click="closeDialog">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 添加元件分组 -->
    <el-dialog title="添加元件分组"
               width="640px" :close-on-click-modal="false" :visible.sync="addComponentGroupOpen"
               v-dialogDrag style="margin-top: 26vh" append-to-body>
      <el-form ref="groupForm" :model="groupForm" :rules="rules" label-width="100px">
        <el-form-item label="分组名称" prop="groupName">
          <el-input v-model="groupForm.groupName" placeholder="请输入新增分组名称"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" plain @click="submitComponentGroupForm">确 定</el-button>
        <el-button @click="cancelAddComponentGroup">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listComponents,
  getComponentById,
  delComponent,
  addComponent,
  updateComponent,
} from "@/api/diagram/component";
import { addGroup, listGroup } from "@/api/diagram/group.js";
import config from "@/config/index.js";
import DiagramMixin from "../diagram-mixin";

export default {
  name: "Component",
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  mixins: [DiagramMixin],
  data() {
    return {
      imageUrl: '',
      // 查询参数
      queryParams: {
        componentName: null,
        groupId: null,
        isEnable: null,
      },
      uploadFile: null,
      fileBaseUrl: config.baseURL + 'component',
      componentList: [],
      groupList: [],
      addComponentGroupOpen: false,
      actionsUrl: "/common/upload?type=diagram",
      color: "#000000",
      //网站前缀
      envApi: config.baseURL + 'component',
      // 元件表单
      form: {
        componentId: null,
        componentName: null,
        groupId: null,
        num: null,
        sourceUrl: '',
        isPreset: false //非默认
      },
      groupForm: {},
      // 表单校验
      rules: {
        componentName: [{ required: true, message: "元件名称不能为空", trigger: "blur" }],
        groupName: [{ required: true, message: "分组名称不能为空", trigger: "blur" }],
        //sourceUrl: [{ required: true, message: "请上传元件SVG文件", trigger: "blur" }],
      },
    };
  },
  watch: {
    'form.groupId'(groupId){
      if(!this.form.componentId){//新增
        const group = this.componentList.find(g => g.componentId == groupId);
        this.form.num = group?.children ? group.children.length + 1 : 1;
      }
    }
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.componentId);
      this.multiple = !selection.length;
    },
    handleQuery(){
      this.queryParams.pageNum = 1;
      this.getList();
      this.expandFirstRow();
    },
    closeDialog(){
      this.$refs.form.clearValidate();
      this.open = false;
      this.imageUrl = '';
      this.addComponentGroupOpen = false;
    },
    resetForm(){
      this.form.componentId = null;
      this.form.componentName = null;
      this.form.sourceUrl = '';
    },
    expandFirstRow() {
      this.$nextTick(() => {
        document.getElementsByClassName('el-table__expand-icon')[0].click()
      })
    },
    getList() {
      this.loading = true;
      listComponents(this.queryParams)
              .then(res => {
                  this.componentList = res.data;
                  // 如果没有电线，就更新localStorage
                  // todo: 需要改进，localStorage更新的策略
                  // if(!localStorage.getItem("1718989c9cd9409b8f0e5b23562180e1")){
                  //   this.componentList.forEach(comp => {
                  //     if(comp.sourceUrl && comp.length > 200){
                  //       //localStorage.setItem(comp.componentId, `data:image/svg+xml;base64,${comp.sourceUrl}`);
                  //       localStorage.setItem(comp.componentId, comp.sourceUrl);
                  //     }
                  //   });
                  // }
                  this.loading = false;
              })
              .then(() => this.expandFirstRow());
    },
    getGroupList(){
      listGroup().then(res => this.groupList = res.data);
    },
    checkChildren(row) {
      return !row.children;
    },
    handleAdd() {
      this.resetForm();
      this.open = true;
      this.title = "添加元件";
      if(!this.form.groupId){
        this.form.groupId = this.groupList[0].groupId;
      }
    },
    handleUpdate(componentId) {
      this.resetForm();
      this.title = "修改元件";
      getComponentById(componentId).then(res => {
        if(res.data.isPreset){
          this.$message.error("不能编辑系统预设元件！");
        }else{
          this.form = res.data;
          if(res.data.initData){
            const initData = eval("(" + res.data.initData + ")");
            this.form.width = initData?.width;
            this.form.height = initData?.height;
          }
          //图片回显
          this.imageUrl = this.fileBaseUrl + res.data.sourceUrl;
          this.open = true;
        }
      });
    },
    toggleEnable(row) {
      const hintwords = row.isEnable ? '启用' : '不启用';
      this.$confirm("是否" + hintwords + "【"+ row.componentName + "】元件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updateComponent({componentId: row.componentId, isEnable: row.isEnable}).then(res => {
          if(res.code === 200){
            this.$message({ message: "修改成功！", type: "success" });
          }
        })
      }).catch(() => {
        row.isEnable = row.isEnable ? 0 : 1;
      })
    },
    handleDelete(row) {
      const componentIds = this.ids || row.componentId;
      this.$confirm("是否确认删除选中的元件?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(function () {
        return delComponent(componentIds);
      })
      .then(() => {
        this.getList();
        this.$message({ message: "删除成功！", type: "success" });
      })
      .catch(() => {});
    },
    handleLoadMore() {
      this.queryParams.pageNum++;
      listGroup(this.queryParams).then((response) => {
        if (response.rows?.length) {
          for (let item of response.rows) {
            this.groupList.push(item);
          }
        }
      });
    },
    // 上传文件
    uploadFileChange(file){
      this.uploadFile = file.raw;
      this.imageUrl = URL.createObjectURL(this.uploadFile);
    },
    takeUrl(val) {
      this.form.sourceUrl = val.fileName;
    },
    submitForm() {
      if(!this.imageUrl){
        this.$message.error("请上传元件图标");
      }
      this.$refs["form"].validate(valid => {
        if (valid) {
          // 先处理数据，在分新增和修改处理
          const formData = new FormData();
          formData.append("componentId", this.form.componentId);
          formData.append("componentName", this.form.componentName);
          formData.append("groupId", this.form.groupId);
          formData.append("num", this.form.num);
          if(!this.form.componentId){ //新增
            formData.append('file', this.uploadFile);
            addComponent(formData).then(res => {
              this.$message({ message: "新增成功！", type: "success" });
              this.open = false;
              this.queryParams.pageNum = 1;
              this.getList();
            })
          } else{ //修改
            if(this.uploadFile){
              formData.append('file', this.uploadFile);
            }
            updateComponent(formData).then((response) => {
              this.$message({ message: "修改成功！", type: "success" });
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    // 元件分组
    handleAddComponentGroup() {
      this.addComponentGroupOpen = true;
    },
    cancelAddComponentGroup() {
      this.addComponentGroupOpen = false;
    },
    submitComponentGroupForm() {
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          addGroup(this.groupForm).then((response) => {
            this.$message({ message: "新增成功！", type: "success" });
            this.addComponentGroupOpen = false;
            this.getList();
          });
        }
      });
    },
  },
  created() {
    this.getList();
    this.getGroupList();
  },
}
</script>
<style lang="less" scoped>
.app-container {
  .bar{
    height: 64px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    line-height: 64px;
    background-color: #0d3050;
    font-size: 14px;

    ::v-deep .el-form-item__label{
      color: #abb8cf !important;
      font-weight: bold;
      font-size: 15px;
    }

    ::v-deep .el-input{
      width: 24rem;
      margin-top: 12px;
    }

    button{
      height: 32px;
      width: 80px;
      border-radius: 2px;
      padding: 0;
      line-height: 32px;
      color: #fefefd;
      border: none;
      margin-top: 16px;

      &:first-child{
        background-color: #027969;
      }
      &:not(:first-child){
        background-color: #0c3761;
      }
    }

    .right-btns{
      float: right;
      padding-right: 1rem;
    }

  }

  .table {
    margin-top: 18px;
    .el-table{
      overflow-y: scroll;
    }
    ::v-deep .el-table__row{
      background-color: #0c375e;
      color: #dbe1e7;
      &:hover{
        background-color: #0c375e;
      }
    }
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
      background-color: #0c375e;
    }

    .caozuobtn-box{
      display: flex;
      justify-content: center;
    }
    .caozuobtn {
      cursor: pointer;
      float: left;
      font-size: 14px;
      line-height: 20px;
      color: #03d9bc;
      margin:0 1rem;
    }
    ::v-deep.el-icon-arrow-right{
      color: #03d9bc;
      font-weight: bold;
    }
  }
}
</style>
<style lang="less">
.add-edit-dialog{
  .el-form{
    .select-group{
      width: 35rem;
      margin-right: 1rem;
    }
    .btn-add-group{
      background-color: #0b3d6b;
      border: none;
      color: white;
    }
    .upload-area{
      padding: 5rem 23rem;
      border: 1px dashed gray;
      border-radius: 2px;
    }
  }
}
</style>


