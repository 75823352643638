<template>
  <div class="enerstion-bottom">
    <div class="fire_control1">
      <div class="fire">
        <div class="tree">
          <TreeComParison />
        </div>
      </div>

      <div class="time-analyze-bottom">
        <div class="timeyze">
          <div class="table">
            <div class="table-top" id="myPNGplace">
              <span class="title">分时统计</span>
              <div v-if="lineChartShow">
                <MapColumn ref="lineCharts" />
              </div>
              <div v-else>
                <MapLineDom ref="lineCharts" />
              </div>
              <div class="table-time">
                <div>
                  <img
                    v-show="barChartShow"
                    @click="changeBar()"
                    src="@/assets/ElecManagement/BarChart.png"
                    alt
                  />
                  <img
                    v-show="barChartShowGreen"
                    src="@/assets/ElecManagement/BarChartGreen.png"
                    alt
                  />
                </div>
                <div>
                  <img
                    v-show="lineChartShow"
                    @click="changeLine()"
                    src="@/assets/ElecManagement/LineChart.png"
                    alt
                  />
                  <img
                    v-show="lineChartShowGreen"
                    src="@/assets/ElecManagement/LineChartGreen.png"
                    alt
                  />
                </div>
                <div>
                  <!-- 下载的下拉框 -->
                  <el-dropdown
                    @command="downLoadPng"
                    :disabled="
                      this.$store.state.storage.localtionId &&
                      this.$store.state.storage.localtionId != 0
                        ? false
                        : true
                    "
                  >
                    <span class="el-dropdown-link">
                      <img src="@/assets/ElecManagement/Download.png" alt />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="['pic', '能源管理-时段分析-分时统计']"
                        >图片下载</el-dropdown-item
                      >
                      <el-dropdown-item :command="['excel', '分时统计']"
                        >Excel下载</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <div class="weekTab">
              <span class="title">周末周中能耗分析</span>
              <div>
                <MapWeek ref="MapWeek" />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import MapLineDom from "./TimeAnalyze/mapLineDom";
import MapColumn from "./TimeAnalyze/mapColumn";
import MapWeek from "./TimeAnalyze/mapWeek";
import TreeComParison from "../ElecManagement/ComparisonAnalyze/TreeComparisonAnalyze";
import html2canvas from "html2canvas";
import { exportEnergyTimeReport } from "@/api/repair";

export default {
  name: "TimeAnalyze",
  components: { MapLineDom, MapWeek, MapColumn, TreeComParison },
  props: {
    isTreeShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value1: "",
      dataForm: {
        text: "",
        time: "",
        localtionId: "",
      },
      customerName: "",
      options: [
        {
          value: "1",
          label: "2020",
        },
        {
          value: "2",
          label: "2019",
        },
        {
          value: "3",
          label: "2018",
        },
        {
          value: "4",
          label: "2017",
        },
      ],
      value: "2021年",
      btnTypeDay: "btnGreen",
      btnTypeMonth: "btnDark",
      lineChartShow: true,
      lineChartShowGreen: false,
      barChartShow: false,
      barChartShowGreen: true,
      whichTab: 0,
      showInterval: true,
      dateSpan: "2021-12",
    };
  },
  created() {
    this.getThisMonth();
  },
  computed: {},
  watch: {
    isTreeShow(val) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.lineCharts.resizeChart();
          this.$refs.MapWeek.resizeChart();
        });
      }, 0);
    },
    "$store.state.storage.customerData": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return;
        }
        this.customerName = newVal.name;
      },
    },
  },
  mounted() {},
  methods: {
    changeBar() {
      this.showInterval = false;
      this.barChartShowGreen = true;
      this.barChartShow = false;
      this.lineChartShow = true;
      this.lineChartShowGreen = false;
      setTimeout(() => {
        this.showInterval = false;
        this.$nextTick(() => {
          this.$refs.lineCharts.init();
        });
      }, 0);
    },
    changeLine() {
      this.showInterval = false;
      this.barChartShowGreen = false;
      this.barChartShow = true;
      this.lineChartShow = false;
      this.lineChartShowGreen = true;
      setTimeout(() => {
        this.showInterval = true;
        this.$nextTick(() => {
          this.$refs.lineCharts.init();
        });
      }, 0);
    },
    changeTab(time) {
      this.dateSpan = time;
    },
    getThisMonth() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m;
      this.dateSpan = time;
      this.$store.commit("TimeAnalysisDate", this.dateSpan);
    },

    // 转化dom节点为图片下载
    downLoadPng(val) {
      let month = this.$store.state.elecManagement.timeAnalysisDate;
      if (val[0] == "excel") {
        let locationId = this.$store.state.storage.localtionId;
        let value = {
          locationId: locationId,
          month: month,
          type: this.$refs.lineCharts.typeValue / 1 + 1,
        };
        // this.$store.dispatch("exportInterval", value);
        exportEnergyTimeReport(value).then((response) => {
          var blob = new Blob([response], {
            type: "application/vnd.ms-excel,charset=utf-8",
          }); //创建一个blob对象
          var a = document.createElement("a"); //创建一个<a></a>标签
          a.href = URL.createObjectURL(blob); // response is a blob
          a.download = `${month}${this.customerName}时段分析-分时统计.xls`; //文件名称
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
      } else {
        var node = document.getElementById("myPNGplace");
        window.pageYoffset = 0;
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        var width = node.offsetWidth;
        var height = node.offsetHeight;
        var scale = 4;
        html2canvas(node, {
          width: width,
          heigth: height,
          backgroundColor: "#113455",
          dpi: window.devicePixelRatio * 4,
          scale: scale,
          X: 0,
          Y: 0,
          scrollX: -3,
          scrollY: 0,
          useCORS: true,
          allowTaint: true,
        }).then((canvas) => {
          var url = canvas.toDataURL();
          var a = document.createElement("a");
          a.download = `${month}${this.customerName}${val[1]}`;
          var event = new MouseEvent("click");
          a.href = url;
          a.dispatchEvent(event);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.enerstion-bottom{
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  width: calc(100% + 0.6rem);
  height: calc(100vh - 9.2rem);
  .fire_control1 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .fire {
          width: 22rem;
          height: 88rem;
          border-radius: 4px;
          float: left;
          .tree {
              height: 88rem;
              width: 22rem;
              overflow: hidden;
              position: absolute;
              z-index: 100;
              top: 0;
              left: 0;
          }
      }
      .table {
          width: 100%;
          // width: 118.5rem;
          // height: 77.8rem;
          // overflow-y: auto;
          // float: right;
          .table-top {
              width: 100%;
              // width: 118.5rem;
              height: 42rem;
              background: rgba(0, 115, 222, 0.3);
              position: relative;
              .title {
                  position: absolute;
                  left: 2.4rem;
                  top: 2rem;
                  color: #37a5dd;
                  font-size: 1.6rem;
              }
              .table-time {
                  position: absolute;
                  right: 2.4rem;
                  top: 1.8rem;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  width: 11rem;
                  z-index: 1000;
                  .select-time {
                      padding-top: 0.8rem;
                      padding-right: 1.5rem;
                  }
                  img {
                      width: 2.05rem;
                      height: 1.86rem;
                      cursor: pointer;
                  }
                  .time {
                      width: 120px;
                  }
              }
          }
          .weekTab {
              width: 100%;
              // width: 118.5rem;
              height: 42rem;
              margin-top: 2.4rem;
              background: rgba(0, 115, 222, 0.3);
              position: relative;
              .title {
                  position: absolute;
                  left: 2.4rem;
                  top: 2rem;
                  color: #37a5dd;
                  font-size: 1.6rem;
              }
              .c2 {
                  width: 118.5rem;
                  height: 42rem;
              }
              .table-bottom {
                  position: absolute;
                  right: 1.5rem;
                  top: 0rem;
                  float: right;
                  display: flex;
                  .weekTime {
                      .selectTime {
                          width: 12rem;
                          height: 3.6rem;
                      }
                  }
                  .dateChoose {
                      height: 3.8rem;
                      width: 19rem;
                      border-radius: 0.2rem;
                      overflow: hidden;
                      border: 0.1rem solid #004579;
                      box-sizing: border-box;
                      display: flex;
                      flex-wrap: nowrap;
                      color: #fff;
                      .btnDark {
                          width: 9.6rem;
                          height: 3.8rem;
                          background-color: #0b375e;
                          font-size: 1.4rem;
                          padding: 0;
                          margin: 0;
                          cursor: pointer;
                          line-height: 3.6rem;
                          text-align: center;
                      }
                      .btnGreen {
                          width: 9.6rem;
                          height: 3.8rem;
                          background-color: #027969;
                          font-size: 1.4rem;
                          padding: 0;
                          margin: 0;
                          cursor: pointer;
                          line-height: 3.6rem;
                          text-align: center;
                      }
                  }
              }
          }
      }
  }
}
.time-analyze-bottom {
    flex: 1;
    margin-left: 1.5rem;
    //   position: absolute;
    overflow: hidden;
    //   width: 143.8rem;
    height: 96.8rem;
}
.timeyze {
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
    // width: 142.8rem;
    // height: 75rem;
}
.blank {
    position: relative;
    top: 0rem;
    height: 20rem;
    width: 162.8rem;
    color: grey;
    font-size: 1.4rem;
    text-align: center;
    line-height: 10rem;
}
/deep/.el-dropdown-link[disabled] {
    img {
        cursor: not-allowed !important;
    }
}
</style>
<style lang="less">
.fire_control1 {
    .el-date-editor .el-range-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        outline: 0;
        padding: 0;
        width: 39%;
        color: #fff;
    }
    ::-webkit-scrollbar-thumb,
    .el-select-dropdown::-webkit-scrollbar-thumb {
        background: #0c2f4f;
    }
}
</style>
