<template>
  <div class="DialogAddStandard">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div v-show="getType===1" class="equip_title">指派</div>
        <div v-show="getType===2" class="equip_title">重派</div>
      </div>

      
      <el-form :model="form" class="elForm" :rules="rules" ref="ruleForm" >
          <el-form-item label="执行类型" prop="type" style="width: 98%;">
            <el-select v-model="form.type"  style="width: 98%" size="small">
              <el-option label="巡检小组" value="0"></el-option>
              <el-option label="巡检人" value="1"></el-option>         
            </el-select>
          </el-form-item>

          <el-form-item v-show="form.type==='0'" label="巡检小组" prop="executorId" style="width: 98%;">
            <el-select v-model="form.executorId"  style="width: 98%" size="small" >
              <el-option v-for="(item,index) in patrolGroupList" :key="index" :label="item.label" :value="item.value"></el-option>  
            </el-select>
          </el-form-item>


          <el-form-item v-show="form.type==='1'" label="巡检人" prop="executorId" style="width: 98%;">
            <el-select v-model="form.executorId"  style="width: 98%" size="small">
              <el-option v-for="(item,index) in patrolPersonList" :key="index" :label="item.label" :value="item.value"></el-option> 
            </el-select>
          </el-form-item>





      </el-form>

      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
      </div>

    
   
    </el-dialog>

  
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import { validateInput,validateSelect} from "@/utils/validate";

export default {
  name: "DialogAddStandard",
  mixins: [tableBg],
  data() {
    return {
      // 巡检小组的list
      patrolGroupList:[],
      // 巡检人的list
      patrolPersonList:[],

      // 类型列表
      typeData:[],
      // 请求类型（编辑=2，登记=1）
      getType: 0,
      // 展示判断
      dialogFormVisible: false,
  
      // 表单数据存放处
      form: {type:"",executorId:"",soId:"",userId:"3"},
      tableList: [],
      // 校验规则
      rules: {
        type: [validateInput],
        executorId:[validateSelect]                  
      },
      
    
    };
  },

  methods: {
    // dialog关闭的回调
    closeDialog(ruleForm) {
      this.formInit();
      this.$refs[ruleForm].resetFields();
   
    },

    // 取消弹框
    handleCancle(ruleName) {
      this.$refs[ruleName].resetFields();
      this.dialogFormVisible = false;
      this.formInit();
    },

    // from归零
    formInit() {
      this.form = {type:"",executorId:"",soId:"",userId:"3"};
    },


   
    //父组件点击触发
    show(val) {
      this.dialogFormVisible = true
      this.getType = 1
      this.form.soId = val
     // 巡检小组的下拉信息处理
          this.patrolGroupList = []     
          this.$store.dispatch('getRepairPatrolPlanGroupAll').then(()=>{
            for(let i=0;i<this.patrolPlanGroupAll.length;i++){
              const patrolGroupSingel = {value:'',label:''}
              patrolGroupSingel.value = this.patrolPlanGroupAll[i].id
              patrolGroupSingel.label = this.patrolPlanGroupAll[i].name
              this.patrolGroupList.push(patrolGroupSingel)
            }})
           // 巡检人的下拉信息处理
          this.patrolPersonList = []
          this.$store.dispatch('getRepairPatrolPlanPersonAll').then(()=>{
            for(let i=0;i<this.patrolPlanPersonAll.length;i++){
              const patrolPersonSingel = {value:'',label:''}
              patrolPersonSingel.value = this.patrolPlanPersonAll[i].id
              patrolPersonSingel.label = this.patrolPlanPersonAll[i].name
              this.patrolPersonList.push(patrolPersonSingel)
            }})
   
    },

    //父组件触发来进行编辑
    showForEdit(val) {
 
      this.dialogFormVisible = true
      this.getType = 1
      this.form.soId = val.id
      this.form.executorId = val.executorId
      this.form.type = ""+val.executorType
    
     // 巡检小组的下拉信息处理
          this.patrolGroupList = []     
          this.$store.dispatch('getRepairPatrolPlanGroupAll').then(()=>{
            for(let i=0;i<this.patrolPlanGroupAll.length;i++){
              const patrolGroupSingel = {value:'',label:''}
              patrolGroupSingel.value = this.patrolPlanGroupAll[i].id
              patrolGroupSingel.label = this.patrolPlanGroupAll[i].name
              this.patrolGroupList.push(patrolGroupSingel)
            }})
           // 巡检人的下拉信息处理
          this.patrolPersonList = []
          this.$store.dispatch('getRepairPatrolPlanPersonAll').then(()=>{
            for(let i=0;i<this.patrolPlanPersonAll.length;i++){
              const patrolPersonSingel = {value:'',label:''}
              patrolPersonSingel.value = this.patrolPlanPersonAll[i].id
              patrolPersonSingel.label = this.patrolPlanPersonAll[i].name
              this.patrolPersonList.push(patrolPersonSingel)
            }})
   

    },


    // 提交发送请求（一级dialog点击《保存》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate(valid => {
        if (valid) {
          // 发送新增请求
          if (this.getType === 1) {
            this.$store.dispatch("getRepairAssign", this.form).then(() => {
              if (this.ifGetRepairAssignSuccess === 0) {
                this.$message({ message: "指派成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePage();
                this.$bus.$emit('RepairOrderManagementRefreshTabCount')
                this.dialogFormVisible = false;
                this.formInit()
              } else if (this.ifGetRepairAssignSuccess === -1) {
                this.$message({
                  message: this.ifGetRepairAssignError,
                  type: "warning"
                });
              }
            });
          } else if (this.getType === 2) {
            // 发送编辑请求
            this.$store.dispatch("putRepairWorkStandard", this.form).then(() => {
              if (this.ifEditWorkStandardSuccess === 0) {
                this.$message({ message: "编辑成功！", type: "success" });
                this.dialogFormVisible = false;
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePage();          
                this.formInit()
              } else if (this.ifEditWorkStandardSuccess === -1) {
                this.$message({
                  message: this.ifEditWorkStandardError,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$message.error("有必填项未填");
        }
       
      });
    }
  },
  //处理state数据
  computed: {
    ...mapState({
      // 获取下拉列表(巡检小组和巡检人)
      patrolPlanGroupAll:(state)=>{return state.repair.patrolPlanGroupAll.data},
      patrolPlanPersonAll:(state)=>{return state.repair.patrolPlanPersonAll.data},
      // 指派
      ifGetRepairAssignSuccess: state => {return state.repair.ifGetRepairAssignSuccess.errorCode;},
      ifGetRepairAssignError:state => {return state.repair.ifGetRepairAssignSuccess.message;},
      // 新增
      // ifAaddPageDataSuccess: state => {return state.repair.ifAaddPageDataSuccess.errorCode;},
      // ifAaddPageDataError:state => {return state.repair.ifAaddPageDataSuccess.message;},

      
    })
  },

 
};




</script>

<style lang="less" scoped>
.DialogAddStandard{
  overflow: hidden;
  .el-dialog__body{
    height: 60rem;
  }
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
// 树所在的区域###################
.treePlace{
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 80rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  
  
  // border: #071828 0.2rem solid;
}
.treePlaceGoAway{
  // opacity: 0;
  display: none;
  // position:relative;
  // top: -54rem;
  // left: 80rem;
  height: 0;
  width: 26rem;
  overflow: hidden;
  // display: none;
  transition: height 0.3s;  
}

.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}


</style>




<style lang="less">
.DialogAddStandard{
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  .el-table::before {
      opacity: 0;
  }

  
}

</style>



