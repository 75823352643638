<template>
  <div class="DialogCommunication">
    <el-dialog 
    @close="closeDialog('ruleForm')"
    :visible.sync="dialogFormVisible" width="20%" :close-on-click-modal="false">
      <div  slot="title">
        <div  class="equip_title">导入通讯管理机</div>
      </div>
      <!-- 表单位置 -->
      <div style="max-height:45rem;overflow-y:auto;">
        <el-form :model="form" class="elForm" ref="ruleName" :rules="rules">
          <el-form-item label="所属客户" style="width:98%">
            <el-input v-model="customerName" :placeholder="customerName" size="small" disabled clearable></el-input>
          </el-form-item>
          <el-form-item label="通讯管理机名称" prop="name" style="width:98%">
            <el-input v-model="form.name" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="IPv4地址" prop="ip" style="width:98%">
            <el-input v-model="form.ip" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="端口号" prop="port" style="width:98%">
            <el-input v-model="form.port" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          
        </el-form>
      </div>
      <!-- 导入文件位置 -->
         <el-upload
            class="upload-demo"
            :headers="myToken"
            :action="postAddress"
            :on-success = 'handleSuccess'
            :on-error = "handelError"
             multiple
            :file-list = "uploadList"
            >
            <el-button @click="reallyBeforeUpload" size="small" class="confirmButton" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只有填写了上面三条数据才能上传</div>
        </el-upload>


      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleName')">退 出</button>
        <!-- <button class="btn_save" @click="postDialogData('ruleName')">保 存</button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getCookie} from "@/utils/util.js"
import { tableBg } from '@/mixin/tablebg'
import { validateInput } from "@/utils/validate"
import config from "@/config/index.js"
export default {
    name:"DialogDictionaryPage",
    mixins: [tableBg],
     data() {
      return {
        // 客户id
        customerId:'',
        customerName:'',
       // 上传列表
        uploadList:[],
        // 请求地址
        postAddress:'',
        //表单中placehoder的数据
        parentNamePlaceHoder:'',
        // 当前树的信息
        treeFullNodeData:{},
        // 展示判断
        dialogFormVisible: false,
        // 表单数据存放处
        form:{},
        rules: {
          name: [validateInput],
          ip: [validateInput],
          port:[validateInput],
 
        },

        //令牌
        myToken:{Authorization: "没有拿到token"},
      };
    },
    methods: {    
      // 比钩子更前
      reallyBeforeUpload(){
        if(this.form.ip===false||this.form.name===false||this.form.port===false){this.$message.error("请先填写名称、IP和端口号！")}
        this.postAddress = config.baseURL+'comm-machine/import?ip='+this.form.ip+'&name='+this.form.name+'&port='+this.form.port+'&customerId='+this.customerId
      },
      
      //请求成功
      handleSuccess(res){
        if(res.errorCode===0){
          this.$message.success("上传成功!,您可以选择继续上传或者退出")
          this.formInit()
          this.$parent.refreshThePage()  
        }else{
          this.$message.error(res.message)
          this.uploadList=[]
        }
              
      },
        // 请求失败
      handelError(res){
        this.$message.error('发送请求失败:'+res+'请求发送错误')
      },


        // 关闭dialog的回调，el自带的
        closeDialog(ruleForm){
          this.formInit()
          this.$refs[ruleForm].resetFields()
        }, 
        handleCancle(ruleName) {
          this.$refs[ruleName].resetFields()
          this.dialogFormVisible = false
          this.formInit()
        },
         // from的归零
        formInit(){
          this.form ={}
        },

        //父组件点击触发新增
        show(val1,val2){
          this.customerId = val1
          this.customerName = val2
          this.dialogFormVisible = true
        },
        
       
    },



    mounted(){
       this.myToken={Authorization: "Bearer "+getCookie("token")}
    }

   
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}
.confirmButton{
  background: #027969;
  color: #fff;
  padding: 0;
  width: 10.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  border: 1px solid #027969;
  cursor: pointer;
}

// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2D79BE;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
</style>

<style lang="less">
.DialogCommunication{
    .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }
// abb7c2
.el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem; 
}
.el-checkbox{
 color: #abb7c2;
}
.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #027969;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
}
  .el-input.is-disabled .el-input__inner {
    background-color: #0b3d6b;
    border-color: #004579;
    color: #C0C4CC;
    cursor: not-allowed;
  }

}


</style>


