<template>
    <div class="enerRouter">
        <div class="enerTopRouter">
            <p :class="ruoter1" @click="changeTab(1)">区域用电</p>
            <p :class="ruoter2" @click="changeTab(2)">分项用电</p>
        </div>
        <div class="routerLay">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "EnergyStatistical",
    data () {
        return {
            ruoter1: "btnGreen",
            ruoter2: "btnGray",
        };
    },
    mounted () { },
    methods: {
        changeTab (index) {
            if (index === 1) {
                this.ruoter1 = "btnGreen";
                this.ruoter2 = "btnGray";
                this.$router.push({
                    path: "/elecManagement/energyStatistical/areaElectric",
                });
            } else if (index === 2) {
                this.ruoter1 = "btnGray";
                this.ruoter2 = "btnGreen";
                this.$router.push({
                    path: "/elecManagement/energyStatistical/stepElectric",
                });
            }
        },
    },
    watch: {},
};
</script>

<style lang="less" scoped>
.enerRouter {
    .enerTopRouter {
        // width: 142.8rem;
        width: 100%;
        height: 3.4rem;
        background: #0c2f4f;
        display: flex;
        margin-top: -3.2rem;
        font-size: 1.4rem;
        .btnGreen {
            color: #03d9bc;
            margin-left: 2.6rem;
            cursor: pointer;
            border-bottom: 0.2rem solid #03d9bc;
        }
        .btnGray {
            color: aliceblue;
            margin-left: 2.6rem;
            cursor: pointer;
        }
    }
    .routerLay {
        height: 86.4rem;
        // width: 142.8rem;
        width: calc(100% + 0.6rem);
        // position: absolute;
        // right: 0;
        // overflow: hidden;
        background-color: #18191a;
    }
}
</style>
