<template>
    <div class="allTreeContainerForTreeOne">
        <!--el树形控件--输入框  -->
        <div class="inputContainer">
            <el-input size="small" placeholder="请输入搜索关键字" v-model="filterText" style="width:22.5rem;" clearable></el-input>
        </div>
        <!--el树形控件--树  -->
        <div class="treeContainer">
            <el-tree :indent="0" :check-strictly="true" :check-on-click-node="true" :expand-on-click-node="false" @node-click="chooseTree" highlight-current class="filter-tree elTree tree-line" :data="treeData" :props="defaultProps" default-expand-all :filter-node-method="filterNode" :render-content="renderContent" ref="tree">
            </el-tree>

        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'TreeCustomer',
    // 监听过滤树的搜索
    watch: {
        filterText (val) {
            this.$refs.tree.filter(val);
        }
    },

    methods: {
        filterNode (value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        // 点击树的分支
        chooseTree (data) {
            this.$parent.changeTreeNode(data)
        },
        //兄弟组件或者父组件要求刷新树
        refreshTheTree () {
            this.$store.dispatch('getCustomerTree').then(() => {
                this.treeData = this.customerTree
            })
        },

        // 树的提示语
        renderContent (h, { node, data, store }) {
            if (node.label.length > 13) {
                return (<div style="font-size: 1.4rem;">
                    <el-tooltip class="item" effect="dark" placement="right">
                        <div slot="content" >{node.label}</div>
                        <p style="width:19rem; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;">{node.label}</p>
                    </el-tooltip>
                </div>);
            } else { return (<div style="font-size: 1.4rem;"><p>{node.label}</p></div>) }
        },


    },

    data () {
        return {
            filterText: '',
            // 树的内容
            treeData: [],
            // 树的props参数，已经与后端对接，勿动
            defaultProps: { children: 'children', label: 'name' }
        };
    },


    //处理state数据
    computed: {
        ...mapState({
            equipmentPageData: (state) => {
                return state.equipment.equipmentPageData.data
            },
            ifDeleted: (state) => {
                return state.equipment.ifDeleted.errorCode
            },
            customerTree: (state) => {
                return state.equipment.customerTree.data
            }
        })
    },


    //发送请求,获取客户树
    created () {
        this.$store.dispatch('getCustomerTree').then(() => {
            this.treeData = this.customerTree
        })
    },

}
</script>

<style lang="less" scoped>
.allTreeContainerForTreeOne {
    height: 96.8rem;
    width: 26rem;
    background-color: #0d3050;
    border-radius: 0.4rem;
    overflow: hidden;
    // 输入框位置
    .inputContainer {
        height: 6.8rem;
        width: 26rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .elInput {
            height: 3.2rem;
            width: 22.8rem;
            position: absolute;
            top: 2.4rem;
            left: 1.6rem;
            font-size: 1.4rem;
            background-color: #0c375e;
            border: 0.1rem solid #004579;
            box-sizing: border-box;
            border-radius: 0.2rem;
            text-indent: 1.2rem;
            color: #fff;
            outline: 0;
            &:focus {
                border: 0.1rem solid #008080;
            }
        }
    }
    // 树的位置
    .treeContainer {
        height: 90rem;
        width: 100%;
        overflow-y: auto;
        position: unset;
        .blank {
            height: 25rem;
            width: 1rem;
        }
        .elTree {
            background-color: #0d3050;
            color: rgba(255, 255, 255, 0.85);

            .el-tree-node__label {
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-family: PingFang SC;
                height: 2.2rem;
                // 禁止选中文字
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Chrome/Safari/Opera */
                -khtml-user-select: none; /* Konqueror */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;
            }
            .el-tree-node__content:hover {
                background-color: #204b73;
            }
            .el-tree-node:focus > .el-tree-node__content {
                // background-color: #18191a;
                background-image: linear-gradient(to right, #0d3050, #18191a);
                // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
                // box-sizing: border-box;
            }
        }
    }
    // 持续高亮(鼠标离开树以后)
    .el-tree--highlight-current
        .el-tree-node.is-current
        > .el-tree-node__content {
        background-image: linear-gradient(to right, #0d3050, #18191a);
        // border-left: 0.5rem solid rgba(255, 255, 255, 0.85);
        // box-sizing: border-box;
        padding-left: 0;
    }

    // 树的虚线
    // .tree-line{
    //   .el-tree-node {
    //     position: relative;
    //     padding-left: 16px; // 缩进量
    //   }
    //   .el-tree-node__children {
    //     padding-left: 16px; // 缩进量
    //   }

    //   // 竖线
    //   .el-tree-node::before {
    //     content: "";
    //     height: 100%;
    //     width: 1px;
    //     position: absolute;
    //     left: -3px;
    //     top: -26px;
    //     border-width: 1px;
    //     border-left: 1px dashed #52627C;
    //   }
    //   // 当前层最后一个节点的竖线高度固定
    //   .el-tree-node:last-child::before {
    //     height: 38px; // 可以自己调节到合适数值
    //   }

    //   // 横线
    //   .el-tree-node::after {
    //     content: "";
    //     width: 24px;
    //     height: 20px;
    //     position: absolute;
    //     left: -3px;
    //     top: 12px;
    //     border-width: 1px;
    //     border-top: 1px dashed #52627C;
    //   }

    //   // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
    //   & > .el-tree-node::after {
    //     border-top: none;
    //   }
    //   & > .el-tree-node::before {
    //     border-left: none;
    //   }

    //   // 展开关闭的icon
    //   .el-tree-node__expand-icon{
    //     font-size: 16px;
    //     // 叶子节点（无子节点）
    //     &.is-leaf{
    //       color: transparent;
    //       // display: none; // 也可以去掉
    //     }
    //   }
    // }

    // .allTreeContainerForTreeOne .tree-line .el-tree-node{
    //   padding-left: 0;
    //   overflow: hidden;
    // }
}
</style>
