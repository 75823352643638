<template>
  <div class="DialogSystemDepartment">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="departmentDailog"
      :close-on-click-modal="false"
    >
      <div v-if="getType == 2" slot="title">
        <div class="equip_title">新增部门</div>
      </div>
      <div v-else slot="title">
        <div class="equip_title">编辑部门</div>
      </div>
      <div style="max-height: 45rem; overflow-y: auto;">
        <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
          <el-form-item label="编号" prop="deptNo">
            <el-input
              v-model="form.deptNo"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="部门名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item v-if="ifTreeChoosen === true" label="上级部门名称">
            <el-input
              disabled
              :placeholder="fatherNodeName"
              size="small"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item v-else label="请选择部门所在客户" prop="parentId">
            <button
              class="btnForInnerTree"
              size="small"
              @click.prevent="DialogTreeShow()"
            >
              {{ placeholderOfbtn }}
            </button>
          </el-form-item>

          <el-form-item label="部门电话" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="启用状态" prop="status">
            <el-select
              v-model="form.status"
              placeholder="请选择状态"
              style="width: 100%;"
              size="small"
            >
              <el-option label="冻结" value="0"></el-option>
              <el-option label="正常" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-form></el-form>
      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">
          取 消
        </button>
        <button class="btn_save" @click="postDialogData('ruleForm')">
          保 存
        </button>
      </div>
    </el-dialog>
    <!-- 二级弹层组件 -->
    <div v-if="ifTreePlaceShow" class="treePlace">
      <DialogForTreeInDepartment ref="DialogForTreeRef" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import { validateInput, validateSelect, validatePhone } from '@/utils/validate'
import DialogForTreeInDepartment from './DialogForTreeInDepartment.vue'
export default {
  name: 'DialogDepartment',
  mixins: [tableBg],
  components: { DialogForTreeInDepartment },
  data() {
    return {
      // 按钮的双向绑定
      placeholderOfbtn: '请选择上级客户',
      // 是否显示树
      ifTreePlaceShow: false,
      // 是否已经选择了树节点
      ifTreeChoosen: true,
      // 上级节点名称
      fatherNodeName: '',
      // 数据请求的类型（编辑=1、新登记=2）
      getType: 0,
      //表单中placehoder的数据
      parentNamePlaceHoder: '',
      // 当前树的信息
      treeFullNodeData: {},
      // 展示判断
      dialogFormVisible: false,
      // 表单数据存放处
      form: {
        children: '',
        createTime: '',
        customerId: '',
        deleted: '',
        deptNo: '',
        id: '',
        industryInvolved: '',
        location: '',
        name: '',
        parentId: '',
        parentName: '',
        phone: '',
        principalName: '',
        projectDutyPerson: '',
        remark: '',
        status: '',
        type: '',
        updateTime: '',
      },
      rules: {
        deptNo: [validateInput],
        parentId: [validateSelect],
        name: [validateInput],
        phone: [validatePhone],
        status: [validateInput],
      },
    }
  },
  methods: {
    // 触发二级弹层中的树
    DialogTreeShow() {
      this.ifTreePlaceShow = true
      setTimeout(() => {
        this.$bus.$emit('SystemDepartmentLevel2TreeShow')
      }, 20)
    },
    //树的确认和取消
    treeConfirm(val) {
      if (val) {
        this.placeholderOfbtn = val.name
        this.form.parentId = val.id
        this.ifTreePlaceShow = false
      } else {
        this.ifTreePlaceShow = false
      }
    },
    // 关闭dialog的回调，el自带的
    closeDialog(ruleForm) {
      this.formInit()
      this.$refs[ruleForm].resetFields()
      this.ifTreePlaceShow = false
      this.placeholderOfbtn = '请选择上级客户'
      this.form.parentId = ''
    },
    handleCancle(ruleName) {
      this.$refs[ruleName]?.resetFields()
      this.formInit()
      this.placeholderOfbtn = '请选择上级客户'
      this.form.parentId = ''
      this.ifTreePlaceShow = false
      this.dialogFormVisible = false
    },
    // from的归零
    formInit() {
      this.form = {
        children: '',
        createTime: '',
        customerId: '',
        deleted: '',
        deptNo: '',
        id: '',
        industryInvolved: '',
        location: '',
        name: '',
        parentId: '',
        parentName: '',
        phone: '',
        principalName: '',
        projectDutyPerson: '',
        remark: '',
        status: '',
        type: '',
        updateTime: '',
      }
      this.fatherNodeName = ''
    },
    //父组件点击触发新增
    show(val) {
      this.getType = 2
      this.dialogFormVisible = true
      if (val) {
        this.ifTreeChoosen = true
        this.fatherNodeName = val.name
        this.form.parentId = val.id
        this.form.type = '1'
      } else {
        this.ifTreeChoosen = false
        this.form.type = '0'
      }
    },

    // 父组件触发编辑
    showForEdit(val) {
      this.ifTreeChoosen = true
      this.getType = 1
      this.dialogFormVisible = true
      // 深拷贝
      this.form.id = val.id
      this.form.children = val.children
      this.form.createTime = val.createTime
      this.form.customerId = val.customerId
      this.form.deleted = val.deleted
      this.form.deptNo = val.deptNo
      this.form.industryInvolved = val.industryInvolved
      this.form.location = val.location
      this.form.name = val.name
      this.form.parentId = val.parentId
      this.form.parentName = val.parentName
      this.form.phone = val.phone
      this.form.principalName = val.principalName
      this.form.projectDutyPerson = val.projectDutyPerson
      this.form.remark = val.remark
      this.form.status = val.status
      this.form.type = val.type
      this.form.updateTime = val.updateTime
      this.fatherNodeName = val.parentName
    },
    // 提交发送请求（点击《确定》）
    postDialogData(ruleName) {
      this.ifTreePlaceShow = false
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          // 发送登记请求
          if (this.getType === 2) {
            this.$store
              .dispatch('postSystemDepartmentAddNewUser', this.form)
              .then(() => {
                if (this.ifDepartmentAddSuccess === 0) {
                  this.$message({ message: '添加成功！', type: 'success' })
                  this.$refs[ruleName].resetFields()
                  this.dialogFormVisible = false
                  this.$parent.refreshThePage()
                  this.$parent.refreshTheTree()
                } else if (this.ifDepartmentAddSuccess === -1) {
                  this.$message({
                    message: this.ifDepartmentAddError,
                    type: 'warning',
                  })
                }
              })
          } else if (this.getType === 1) {
            //发送编辑请求
            this.$store
              .dispatch('putSystemDepartmentEdit', this.form)
              .then(() => {
                if (this.ifDepartmentEditSuccess === 0) {
                  this.$message({ message: '编辑成功！', type: 'success' })
                  this.$refs[ruleName].resetFields()
                  this.dialogFormVisible = false
                  this.$parent.refreshThePage()
                  this.$parent.refreshTheTree()
                } else if (this.ifDepartmentEditSuccess === -1) {
                  this.$message({
                    message: this.ifDepartmentEditError,
                    type: 'warning',
                  })
                }
              })
          }
        } else {
          this.$message.error('有必填项未填')
        }
      })
    },
  },

  created() {
    this.$bus.$off('SystemDepartmentLevel2TreeConfirm')
  },
  mounted() {
    this.$bus.$on('SystemDepartmentLevel2TreeConfirm', this.treeConfirm)
  },

  //处理state数据
  computed: {
    ...mapState({
      ifDepartmentAddSuccess: (state) => {
        return state.system.ifDepartmentAddSuccess.errorCode
      },
      ifDepartmentAddError: (state) => {
        return state.system.ifDepartmentAddSuccess.message
      },
      ifDepartmentEditSuccess: (state) => {
        return state.system.ifDepartmentEditSuccess.errorCode
      },
      ifDepartmentEditError: (state) => {
        return state.system.ifDepartmentEditSuccess.message
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}

// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}

// 触发树的按钮
.btnForInnerTree {
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}
// 树的外壳盒子
.treePlace {
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 100rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  background-color: red;
  border-radius: 0.4rem;
  transition: height 0.3s;
  z-index: 20000;
}
</style>

<style lang="less">
// 套壳！谨防代码污染！
.CustomerPage {
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }
  // abb7c2
  .el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem;
  }
  .el-checkbox {
    color: #abb7c2;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #027969;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
  }
}
</style>

<style lang="less">
.DialogSystemDepartment {
  .el-input.is-disabled .el-input__inner {
    background-color: #0b3d6b;
    border-color: #004579;
    color: #c0c4cc;
    cursor: not-allowed;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.3rem;
  }

  .el-dialog__body {
    height: 25.6rem;
  }
}
</style>
