<template>
  <div class="SystemUserDialogPage">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="allDialog"
      :close-on-click-modal="false"
    >
      <div v-if="getType == 2" slot="title">
        <div class="equip_title">新增用户</div>
      </div>
      <div v-else slot="title">
        <div class="equip_title">编辑用户</div>
      </div>
      <div style="max-height:45rem; overflow-y:auto!important;">
        <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
          <el-form-item label="编号" prop="userNo">
            <el-input
              v-model="form.userNo"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="username">
            <el-input
              v-model="form.username"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="默认密码" prop="password">
            <el-input
              disabled
              placeholder="ky1234"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="姓别" prop="sex">
            <!-- <el-input v-model="form.sex" placeholder="请输入" size="small" clearable></el-input> -->
            <el-select
              v-model="form.sex"
              placeholder="请选择性别"
              style="width: 100%"
              size="small"
            >
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="部门" prop="deptId">
            <!-- <el-select v-model="form.deptId" placeholder="请选择部门" style="width: 100%" size="small">
              <el-option
                v-for="(item,index) in deptData"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select> -->
            <select-tree
              ref="selectTree"
              :placeholder="'请选择部门'"
              :treeData="deptData"
              :currentNode="currentNode"
              :currentNodeName="currentNodeName"
              @nodeClick="handleNodeClick"
            />
          </el-form-item>

          <el-form-item label="职位" prop="positionId">
            <el-select
              v-model="form.positionId"
              placeholder="请选择职位"
              style="width: 100%"
              size="small"
            >
              <el-option
                v-for="(item, index) in posData"
                :key="index"
                :label="item.dictValue"
                :value="item.dictKey"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              placeholder="请选择状态"
              style="width: 100%"
              size="small"
            >
              <el-option label="冻结" value="0"></el-option>
              <el-option label="正常" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="角色" prop="aaaaaaaaaaaaa" style="width: 98%">
            <!-- v-model="" @change="" -->
            <el-checkbox
              v-model="modelData[index]"
              v-for="(item, index) in roleData"
              :key="index"
              >{{ item.roleName }}</el-checkbox
            >
          </el-form-item>
        </el-form>
      </div>
      <el-form></el-form>
      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">
          取 消
        </button>
        <button class="btn_save" @click="postDialogData('ruleForm')">
          保 存
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import { validateInput, validateSelect, validatePhone } from '@/utils/validate'
import SelectTree from '../../../components/SelectTree.vue'
export default {
  components: { SelectTree },
  name: 'DialogUser',
  mixins: [tableBg],
  data() {
    return {
      // 数据请求的类型（编辑=1、新登记=2）
      getType: 0,
      //表单中placehoder的数据
      parentNamePlaceHoder: '',
      // 当前树的信息
      treeFullNodeData: {},
      // 展示判断
      dialogFormVisible: false,
      // 表单数据存放处
      form: {
        userNo: '',
        name: '',
        sex: '',
        deptId: '',
        positionId: '',
        status: '',
        mobile: '',
        roleIdList: [],
      },
      deptData: [
        {
          id: '',
          name: '',
        },
      ],
      currentNode: '',
      currentNodeName: "",
      posData: [
        {
          id: '',
          dictValue: '',
        },
      ],
      // 角色列表(展示)
      roleData: [],
      // 双向绑定列表
      modelData: [],

      rules: {
        userNo: [validateInput],
        name: [validateInput],
        username: [validateInput],
        sex: [validateInput],
        mobile: [validatePhone],
        id: [validateInput],
        positionId: [validateInput],
        status: [validateInput],
        deptId: [validateSelect],
      },
    }
  },
  mounted() {
    this.getDeptList()
    this.getPosList()
  },
  methods: {
    // 关闭dialog的回调，el自带的
    closeDialog(ruleForm) {
      this.formInit()
      this.$refs[ruleForm].resetFields()
    },
    handleCancle(ruleName) {
      this.$refs[ruleName].resetFields()
      this.dialogFormVisible = false
      this.formInit()
    },
    // from的归零
    formInit() {
      this.form = {
        userNo: '',
        username: '',
        name: '',
        sex: '',
        deptId: '',
        positionId: '',
        status: '',
        mobile: '',
        roleIdList: [],
      }
      this.modelData = []
      this.roleData = []
      this.$refs.selectTree.cleanData()
      console.log('this.form', this.form)
    },
    getDeptList() {
      // this.$store.dispatch("getDictionaryDepartment", {}).then(() => {
      //   let arr = this.$store.state.system.ifDictionaryDepartment.data;
      //   this.deptData = arr;
      // });
      this.$store.dispatch('getSystemDepartmentTree', {}).then(() => {
        this.deptData = this.recursion(
          this.$store.state.system.departmentTree.data
        )
      })
    },
    recursion(arr) {
      arr.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.disabled = true
          this.recursion(item.children)
        }
      })
      return arr
    },
    getPosList() {
      this.$store.dispatch('getDictionaryPosData', {}).then(() => {
        let arr = this.$store.state.system.dictionaryPosData.data.records
        this.posData = arr
      })
    },

    // 获取角色列表
    getDataList() {
      this.$store
        .dispatch('querySystemrole', {
          current: 1,
          size: 1000,
          roleName: '',
          remark: '',
        })
        .then(() => {
          this.modelData = []
          this.roleData = this.userPageData
          // 在登记情况下，将双向绑定列表设置为全false
          if (this.getType === 2) {
            for (let i = 0; i < this.roleData.length; i++) {
              this.modelData.push(false)
            }
            //编辑情况下，先做数据回显(roleId在form中就压true)
          } else if (this.getType === 1) {
            for (let i = 0; i < this.roleData.length; i++) {
              if (this.form.roleIdList.indexOf(this.roleData[i].roleId) == -1) {
                this.modelData.push(false)
              } else {
                this.modelData.push(true)
              }
            }
          }
        })
    },
    //父组件点击触发新增
    show() {
      this.getType = 2
      this.currentNode = ''
      this.currentNodeName = ""
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
      this.getDataList()
    },

    // 父组件触发编辑
    showForEdit(val) {
      this.getType = 1
      this.dialogFormVisible = true
      this.form.userNo = val.userNo
      this.form.username = val.username
      this.form.password = val.password
      this.form.name = val.name
      this.form.sex = val.sex
      this.form.deptId = val.deptId
      this.form.positionId = val.positionId
      this.form.status = val.status
      this.form.mobile = val.mobile
      this.form['userId'] = val.userId
      this.currentNode = val.deptId
      this.currentNodeName = val.deptName
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })

      this.$store.dispatch('getUserRoleInfo', val.userId).then(() => {
        this.form.roleIdList = this.userRoleInfoData
        this.getDataList()
      })
    },
    // 提交发送请求（点击《确定》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        // 数据预处理
        this.form.roleIdList = []
        for (let i = 0; i < this.modelData.length; i++) {
          if (this.modelData[i] == true) {
            this.form.roleIdList.push(this.roleData[i].roleId)
          }
        }

        if (valid) {
          if (this.form.roleIdList.length < 1) {
            this.$message({
              message: '请选择角色',
              type: 'error',
            })
            return false
          }
          // 发送登记请求###############################################
          if (this.getType === 2) {
            this.$store
              .dispatch('postSystemUserAddNewUser', this.form)
              .then(() => {
                if (this.ifAddUserSuccess === 0) {
                  this.$message({ message: '添加成功！', type: 'success' })
                  this.$refs[ruleName].resetFields()
                  this.dialogFormVisible = false
                  this.$parent.refreshThePage()
                  this.formInit()
                } else if (this.ifAddUserSuccess === -1) {
                  this.$message({
                    message: this.ifAddUserError,
                    type: 'error',
                  })
                }
              })
          } else if (this.getType === 1) {
            //发送编辑请求######################################
            this.$store.dispatch('putSystemUserEdit', this.form).then(() => {
              if (this.ifSuccessEdit === 0) {
                this.$message({ message: '编辑成功！', type: 'success' })
                this.$refs[ruleName].resetFields()
                this.dialogFormVisible = false
                this.$parent.refreshThePage()
                this.formInit()
              } else if (this.ifSuccessEdit === -1) {
                this.$message({
                  message: this.ifErrorEdit,
                  type: 'warning',
                })
              }
            })
          }
        } else {
          this.$message.error('有必填项未填')
        }
      })
    },
    handleNodeClick(val) {
      this.form.deptId = val.id
    },
  },

  //处理state数据
  computed: {
    ...mapState({
      ifAddUserSuccess: (state) => {
        return state.system.ifAddUserSuccess.errorCode
      },
      ifAddUserError: (state) => {
        return state.system.ifAddUserSuccess.message
      },
      ifSuccessEdit: (state) => {
        return state.system.ifSuccessEdit.errorCode
      },
      ifErrorEdit: (state) => {
        return state.system.ifSuccessEdit.message
      },
      ifDictionaryDepartment: (state) => {
        return state.system.ifDictionaryDepartment.data
      },
      // 角色列表
      userPageData: (state) => {
        return state.system.ifQueryRoleList.data.records
      },
      // 某个用户的详细信息
      userRoleInfoData: (state) => {
        return state.system.userRoleInfoData.data.roleIdList
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}

// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}

.allDialog {
  /deep/.el-dialog__body > div {
    overflow: inherit !important;
  }
}
</style>

<style lang="less">
// 套壳！谨防代码污染！
.CustomerPage {
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  // abb7c2
  .el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem;
  }
  .el-checkbox {
    color: #abb7c2;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #027969;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
  }
}
</style>

<style lang="less">
.SystemUserDialogPage {
  .el-input.is-disabled .el-input__inner {
    background-color: #0b3d6b;
    border-color: #004579;
    color: #c0c4cc;
    cursor: not-allowed;
  }

  // //滚动条的滑块
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.3rem;
  }

  .el-dialog__body {
    height: 35.4rem;
  }
}
</style>
