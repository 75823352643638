<template>
  <div class="PatrolTask">
    <!-- <router-view></router-view> -->
    <!-- tab栏 -->
    <!-- <div class="tabSwitcher">          
        <div :class="tabStyle[0]" @click="switchTab(0,'/repair/patrolTaskMine/waitForPatrolMine')">待巡检({{tabCount.waitInspect}})</div>
        <div :class="tabStyle[1]" @click="switchTab(1,'/repair/patrolTaskMine/outOfTimeMine')">已超期({{tabCount.expired}})</div>
        <div :class="tabStyle[2]" @click="switchTab(2,'/repair/patrolTaskMine/onPatrolMine')">巡检中({{tabCount.inspecting}})</div>
        <div :class="tabStyle[3]" @click="switchTab(3,'/repair/patrolTaskMine/finishedMine')">已完成({{tabCount.finished}})</div>
    </div> -->

    <!-- 面包屑 -->
    <div class="bread">
      运维检修 / 我的巡检 /
      {{ this.tabList.find((a) => a.value == tabvalue).label }}
    </div>
    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div>
        <el-input
          size="small"
          placeholder="请输入巡检名称"
          v-model="formState.keyword"
          @keyup.enter.native="search"
          style="width: 22rem;"
          clearable
          @clear="search"
        ></el-input>

        <el-input
          size="small"
          placeholder="请输入巡检位置"
          v-model="formState.address"
          @keyup.enter.native="search"
          style="width: 22rem; margin-left: 1.6rem;"
          clearable
          @clear="search"
        ></el-input>
        <el-select
          v-if="this.tabList.find((a) => a.value == tabvalue).label == '已完成'"
          size="small"
          placeholder="配电房是否异常"
          class="businessSelector"
          v-model="formState.exception"
          clearable
          @change="selectChanged"
        >
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>

        <el-select
          size="small"
          placeholder="巡检周期"
          class="businessSelector"
          v-model="formState.intervalType"
          @change="selectChanged"
        >
          <el-option label="全部" value=" "></el-option>
          <el-option label="每天" value="1"></el-option>
          <el-option label="每周" value="2"></el-option>
          <el-option label="每月" value="3"></el-option>
        </el-select>

        <button class="btn_save" @click="search">查 询</button>
        <button class="btn_save" @click="exportExcel">导 出</button>
      </div>
      <button class="btn2" @click="ifDialogShow">新增巡检</button>
    </div>

    <!-- 中部表格位置############################################################################################################ -->
    <div class="userTablePlace">
      <!-- tab栏 -->
      <div class="tabSwitcher">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="tabvalue == item.value ? 'btnGreen' : 'btnGrey'"
          @click="switchTab(item.value)"
        >
          {{ item.label + `（${tabCount[item.key]}）` }}
        </div>
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          stripe:false
          height="57.8rem"
          border:false
          style="width: 100%;"
          :header-cell-style="headerClass"
          :cell-style="cellStyle"
        >
          <!-- <el-table-column type="index" label="序号" min-width="19%"></el-table-column> -->
          <el-table-column label="序号" min-width="5%">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column
            class="tab"
            prop="name"
            label="任务名称"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="address"
            label="巡检位置"
            min-width="25%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="executorName"
            label="巡检小组/巡检人"
            min-width="12%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="planTime"
            label="计划巡检时间"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="planEndTime"
            label="计划结束巡检时间"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="statusName"
            label="当前状态"
            min-width="15%"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div class="colorTag">
                {{ tableData[scope.$index].statusName }}
              </div>
            </template>
          </el-table-column>
          <template v-if="this.tabList.find((a) => a.value == tabvalue).label == '已完成'">
            <el-table-column
              class="tab"
              prop="exception"
              label="配电房是否异常"
              min-width="15%"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.exception == 0 ? '否' : scope.row.exception == 1 ? '是' : '' }}</div>
              </template>
            </el-table-column>
            <el-table-column
              class="tab"
              prop="startTime"
              label="实际开始巡检时间"
              min-width="15%"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              class="tab"
              prop="endTime"
              label="实际结束巡检时间"
              min-width="15%"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              class="tab"
              prop="inspectTime"
              label="实际巡检耗时"
              min-width="15%"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
          </template>

          <!-- 操作部分 -->
          <el-table-column label="操作" min-width="14%">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleDetail(scope.$index, scope.row)"
              >
                查看详情
              </el-button>
              <!-- <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
              <!-- <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页器部分 -->
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalNumForPagenation"
        ></el-pagination>
      </div>
    </div>

    <!-- 弹层位置 -->
    <DialogForEdit ref="DialogRef2" />
    <DialogPatrolPlan ref="DialogRef" />
    <DrawerForDetail ref="DrawerRef" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import DialogForEdit from './PatrolPlan/DialogForEdit.vue'
import DialogPatrolPlan from './PatrolPlan/DialogPatrolPlan.vue'
import DrawerForDetail from './PatrolTask/components/DrawerForDetail.vue'
import { exportRepairPatrolTask } from '@/api/repair'
export default {
  name: 'PatrolTask',
  mixins: [tableBg],
  components: { DialogForEdit, DialogPatrolPlan, DrawerForDetail },
  data() {
    return {
      // 顶栏数量
      tabCount: {
        all: 0,
        waitInspect: 0,
        expired: 0,
        inspecting: 0,
        finished: 0,
      },
      formState: {
        keyword: '',
        address: '',
        intervalType: '',
        exception: '',
      },
      tabvalue: '0',
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 页签
      tabList: [
        // {
        //   label: '待分配',
        //   value: '0',
        //   key: 'waitInspect',
        // },
        {
          label: '待巡检',
          value: '0',
          key: 'waitInspect',
        },
        {
          label: '已超期',
          value: '4',
          key: 'expired',
        },
        {
          label: '巡检中',
          value: '1',
          key: 'inspecting',
        },
        {
          label: '已完成',
          value: '2',
          key: 'finished',
        },
        // {
        //   label: '全部',
        //   value: '5',
        //   key: 'all',
        // },
      ],
      // 表格数据
      tableData: [],
    }
  },

  methods: {
    // 切换tab栏
    switchTab(val) {
      this.tabvalue = val
      // Object.keys(this.formState).forEach((item) => {
      //   this.formState[item] = ''
      // })
      if (val != '2') {
        this.formState.exception = ''
      }
      this.currentPage = 1
      this.getTabCount(this.formState)
      this.refreshThePage()
    },
    getTabCount() {
      this.$store
        .dispatch('getRepairPatrolTaskTabCountMine', {
          ...this.formState,
          status: '',
        })
        .then(() => {
          this.tabCount = this.repairPatrolTaskTabCountMine
        })
    },
    // 查看详情
    handleDetail(index) {
      let taskData = this.repairPatrolTaskPageData[index]
      // 如果结果不为空，并且状态为已完成时，查询对应巡检结果
      if(taskData != null && taskData.status === 2) {
        this.$store.dispatch("getRepairPatrolTaskResult", taskData.id)
            .then(() => {
              let result = this.repairPatrolTaskResult;
              if (result != null) {
                const map = new Map(result.map(item => [item.standardItemId, item]));
                // 遍历taskData，对巡检条目明细赋值结果，使用foreach遍历
                taskData.inspectionStandard.itemList.forEach((item) => {
                  // 遍历result，找到对应的巡检条目明细
                  item.detailList.forEach((detail) => {
                    if (map.has(detail.id)) {
                      detail.content = map.get(detail.id).content;
                      detail.remark = map.get(detail.id).remark;
                    }
                    if (detail.parentNode != null && detail.parentNode === 1) {
                      detail.detailList.forEach((detail2) => {
                        if (map.has(detail2.id)) {
                          detail2.content = map.get(detail2.id).content;
                          detail2.remark = map.get(detail2.id).remark;
                        }
                      });
                    }
                  })
                });
              }
            });
      }
      this.$refs.DrawerRef.show(taskData)
    },
    // 刷新页面
    refreshThePage() {
      this.$store
        .dispatch('getRepairPatrolTaskPageDataMine', {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          ...this.formState,
          status: this.tabvalue,
        })
        .then(() => {
          this.tableData = this.repairPatrolTaskPageData
          this.totalNumForPagenation = this.repairPatrolTaskPageTotal
        })
    },

    // 点击搜索或者回车
    search() {
      this.currentPage = 1
      this.refreshThePage()
      this.getTabCount()
    },
    // 选择下拉框
    selectChanged() {
      this.currentPage = 1
      this.refreshThePage()
      this.getTabCount()
    },
    //点击新增
    ifDialogShow() {
      this.$refs.DialogRef.show()
    },
    //点击了编辑按钮
    handleEdit(index) {
      this.$refs.DialogRef2.show(this.repairPatrolTaskPageData[index])
    },
    // 点击了删除按钮
    handleDelete(index) {
      this.$prompt('请输入删除巡检任务的理由：', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then((reason) => {
        const value = {
          id: this.repairPatrolTaskPageData[index].id,
          remark: reason.value,
        }

        this.$store.dispatch('deleteRepairPatrolTask', value).then(() => {
          if (this.ifDeleteRepairPatrolTaskSuccess === 0) {
            this.$message({ message: '删除成功！', type: 'success' })
            this.refreshThePage()
            this.getTabCount()
          } else {
            this.$message({
              message: this.ifDeleteRepairPatrolTaskError,
              type: 'warning',
            })
          }
        })
      })
    },

    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.refreshThePage()
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val
      this.refreshThePage()
    },
    // 导出
    exportExcel() {
      let params = {
        status: this.tabvalue,
        ...this.formState,
      }
      exportRepairPatrolTask(params).then((response) => {
        var blob = new Blob([response], {
          type: 'application/vnd.ms-excel,charset=utf-8',
        }) //创建一个blob对象
        var a = document.createElement('a') //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob) // response is a blob
        a.download = '我的巡检-待巡检.xls' //文件名称
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    },
  },

  mounted() {
    this.getTabCount()
    this.refreshThePage()
  },

  computed: {
    ...mapState({
      // tab栏的数量
      repairPatrolTaskTabCountMine: (state) => {
        return state.repair.repairPatrolTaskTabCountMine.data
      },
      // 分页数据
      repairPatrolTaskPageData: (state) => {
        return state.repair.repairPatrolTaskPageDataMine.data.records
      },
      // 页面总数
      repairPatrolTaskPageTotal: (state) => {
        return state.repair.repairPatrolTaskPageDataMine.data.total
      },
      // 删除巡检任务是否成功
      ifDeleteRepairPatrolTaskSuccess: (state) => {
        return state.repair.ifDeleteRepairPatrolTaskSuccess.errorCode
      },
      ifDeleteRepairPatrolTaskError: (state) => {
        return state.repair.ifDeleteRepairPatrolTaskSuccess.message
      },
      // 巡检结果数据
      repairPatrolTaskResult: state => {
        return state.repair.repairPatrolTaskResult.data
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.tabSwitcher {
  border-bottom: 0.2rem solid rgba(7, 82, 150, 0.25);
  margin-bottom: 2.4rem;
  .btnGreen {
    display: inline-block;
    height: 4rem;
    font-size: 1.4rem;
    color: #03d9bc;
    border-bottom: 0.2rem solid #03d9bc;
    line-height: 5rem;
    margin-right: 2.4rem;
    cursor: pointer;
  }
  .btnGrey {
    display: inline-block;
    height: 4rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    border-bottom: none;
    line-height: 5rem;
    margin-right: 2.4rem;
    cursor: pointer;
  }
}

// 面包屑
.bread {
  height: 5rem;
  width: 142.8rem;
  line-height: 5rem;
  color: #fff;
  font-size: 1.4rem;
  margin-top: -2rem;
}
// 顶部样式##################################################################

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  box-sizing: border-box;
  height: 6.4rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  overflow: hidden;
  .searchInput {
    height: 3.2rem;
    width: 22.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
    background-color: #0c375e;
    border: 1px solid #004579;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    text-indent: 1.4rem;
    &:focus {
      border: 0.1rem solid #008080;
    }
  }

  button {
    height: 3.2rem;
    width: 7.4rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .btn1 {
    background-color: #027969;
  }
  .btn2 {
    width: 10rem;
    background-color: #027969;
  }
  .btn_save {
    margin-left: 1.5rem;
  }
  .businessType {
    height: 3.2rem;
    width: 20.8rem;
  }
  .businessSelector {
    margin: 0 2rem;
  }
}

// 中部表格样式##################################################################

.userTablePlace {
  height: 86.4rem;
  width: 171.2rem;
  border-radius: 5px;
  padding: 0 2rem;
  margin: 2rem auto;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #0d3050;
  .table {
    width: 100%;
    height: 57.2rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    ::v-deep(.el-table) {
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: 0.1rem solid #254561;
      }
      .el-table--border:after,
      .el-table--group:after,
      .el-table:before {
        opacity: 0;
      }
      .el-table__body-wrapper {
        background-color: #0d3050;
      }
      .el-table__cell {
        background-color: #0d3050;
      }
    }
    ::v-deep(.el-button) {
      .el-button--text {
        color: #03d9bc;
      }
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}

// 颜色标签样式
.colorTag {
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(
    180deg,
    rgba(255, 172, 95, 0.1) 0%,
    rgba(205, 216, 118, 0.1) 100%
  );
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #ffac5f;
}

// 公共样式！谨防代码污染！#########################################
// .OrderOutOfTime {
//   .table {
//     // height: 57.2rem;
//     // width: 148rem;
//     // margin: 0 2.4rem 0 2.4rem;
//     // overflow: hidden;
//     // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
//     .el-table td,
//     .el-table th.is-leaf {
//       border-bottom: 0.1rem solid #254561;
//     }
//     .el-table--border:after,
//     .el-table--group:after,
//     .el-table:before {
//       opacity: 0;
//     }
//     .el-table__body-wrapper {
//       background-color: #0d3050;
//     }
//     .el-table__cell {
//       background-color: #0d3050;
//     }
//     .el-button--text {
//       color: #03d9bc;
//     }
//   }

//   // switch栏的样式设置
//   .el-switch__label.is-active {
//     color: #027969;
//   }
//   .el-switch__label {
//     color: #0c375e;
//   }
// }
</style>
