<template>
  <div>
    <!-- 直流侧历史数据柱状图 -->
    <div id="powerData" class="c1"></div>
    <!-- <div class="monthEner">
      <p>本月用电趋势</p>
    </div>-->
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  data() {
    return {
      barList: [],
      dataForm: {
        customerId: "1448924021822775297",
      },
      ydata: "",
      series: "",
      unit: "",
      legendData: "",
      yMax: "",
    };
  },
  props: ["inverterId", "type", "date"],
  computed: {
    // ...mapState({
    //   queryTrendMouth: state => {
    //     return state.elecManagement.queryTrendMouth.data;
    //   }
    // })
  },
  mounted() {
    // this.initData();
  },
  methods: {
    init() {
      this.drawLine1();
    },
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    initData() {
      this.series = [];
      let value = {
        inverterId: this.inverterId,
        type: this.type,
        date: this.date,
      };
      // console.log(value);
      this.$store.dispatch("getAlternateData", value).then(() => {
        if (this.$store.state.genManagement.getAlternateData.errorCode == 0) {
          this.ydata = this.$store.state.genManagement.getAlternateData.data;
          if (this.ydata.length == 3) {
            if (this.type == 1) {
              this.unit = "单位：V";
            } else if (this.type == 2) {
              this.unit = "单位：A";
            }
            this.legendData = [this.ydata[0].name, this.ydata[1].name, this.ydata[2].name];
            this.series = [
              {
                name: this.ydata[0].name,
                type: "bar",
                barWidth: 5,
                itemStyle: { normal: { color: "#F6BD16" } },
                data: this.ydata[0].data,
              },
              {
                name: this.ydata[1].name,
                type: "bar",
                barWidth: 5,
                itemStyle: { normal: { color: "#5AD8A6" } },
                data: this.ydata[1].data,
              },
              {
                name: this.ydata[2].name,
                type: "bar",
                barWidth: 5,
                itemStyle: { normal: { color: "#E8684A" } },
                data: this.ydata[2].data,
              },
            ];
          } else if (this.ydata.length == 1) {
            if (this.type == 3) {
              this.unit = "单位：kW";
            } else if (this.type == 4) {
              this.unit = "";
            } else if (this.type == 5) {
              this.unit = "单位：Hz";
            }
            this.legendData = [this.ydata[0].name];
            this.series = [
              {
                name: this.ydata[0].name,
                type: "bar",
                barWidth: 5,
                itemStyle: { normal: { color: "#F6BD16" } },
                data: this.ydata[0].data,
              },
            ];
          } else if (this.ydata.length == 0) {
            this.series = [];
          }
          this.drawLine1();
        } else {
          this.series = [];
          this.drawLine1();
        }
      });
    },
    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("powerData"));
      let yMax = 0;
      if (this.series.length > 0) {
        for (let i = 0; i < this.series.length; i++) {
          const item = this.series[i].data;
          for (let j = 0; j < item.length; j++) {
            if (item[j] > yMax) {
              yMax = item[j];
            }
          }
          this.yMax = this.ceilNumber(yMax);
        }
      } else {
        this.yMax = 10;
      }
      myChart.setOption(
        {
          title: {
            itemGap: 10,
            textStyle: {
              color: "#37A5DD",
              fontSize: 14,
              lineHeight: 10,
              fontWeight: 400,
            },
            subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
          },
          legend: {
            orient: "horizontal", //一排或者一列
            x: "center", //可设定图例在左、右、居中
            y: "bottom", //可设定图例在上、下、居中
            icon: "circle", // 图例形状
            padding: [0, 0, 5, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
            // borderColor: "#fff",
            textStyle: { color: "rgba(255, 255, 255, 0.85)" },
            data: this.legendData,
            // borderColor: 'rgba(178,34,34,0.8)'
          },
          grid: {
            x: 18,
            x2: 20,
            y2: 28,
            y: 30,
            containLabel: true,
          },
          tooltip: {},
          xAxis: {
            type: "category",
            interval: 0,
            axisLabel: {
              textStyle: {
                color: "#fff",
              },
            },
            //x轴的数据格式
            data: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
              "24:00",
            ],
          },
          yAxis: [
            {
              min: 0,
              max: this.yMax,
              interval: this.yMax / 5,
              name: this.unit,
              nameTextStyle: {
                fontSize: 12,
                color: "rgba(230, 247, 255, 0.7)",
                // align: "right",
              },
              axisLabel: {
                textStyle: {
                  color: "#fff",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(230,247,255,0.2)",
                },
              },
            },
          ],
          series: this.series,
        },
        true
      );
      myChart._dom.childNodes[0].childNodes[0].style.width = "87.5rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "42rem";
    },
  },
  watch: {
    date: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.date = newVal;
        this.initData();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.c1 {
  width: 87.5rem;
  height: 42rem;
  position: relative;
}
.monthEner {
  p {
    position: absolute;
    top: 2.5rem;
    left: 2rem;
    font-size: 1.6rem;
    color: #37a5dd;
  }
}
</style>
