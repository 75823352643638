import { render, staticRenderFns } from "./PageForStandard.vue?vue&type=template&id=4e8453fa&scoped=true&"
import script from "./PageForStandard.vue?vue&type=script&lang=js&"
export * from "./PageForStandard.vue?vue&type=script&lang=js&"
import style0 from "./PageForStandard.vue?vue&type=style&index=0&id=4e8453fa&lang=less&scoped=true&"
import style1 from "./PageForStandard.vue?vue&type=style&index=1&lang=less&"
import style2 from "./PageForStandard.vue?vue&type=style&index=2&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8453fa",
  null
  
)

export default component.exports