<template>
    <div class="reportRouter">
        <div class="enerTopRouter">
            <p :class="ruoter1" @click="changeTab(1)">电站报表</p>
            <p :class="ruoter2" @click="changeTab(2)">逆变器报表</p>
            <p v-if="showCNBB" :class="ruoter3" @click="changeTab(3)">储能报表</p>
        </div>
        <div class="routerLay">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReportStatistical',
    data () {
        return {
            showCNBB: false, //显示出能报表
            ruoter1: 'btnGreen',
            ruoter2: 'btnGray',
            ruoter3: 'btnGray',
        }
    },
    mounted () { },
    methods: {
        //获取储能报表数据
        initBatteryData () {
            // debugger
            let date = new Date()
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()
            if (month < 10) {
                month = '0' + month
            }
            if (day < 10) {
                day = '0' + day
            }
            date = year + '-' + month + '-' + day
            let value = {
                customerId: this.$store.state.storage.customerId,
                datetype: 1,
                date: date,
                batteryIds: '',
                pageNum: 1,
                pageSize: 10
            }
            this.$store.dispatch('getBatteryTable', value).then(() => {
                let tableData = this.$store.state.genManagement.getBatteryTable.data.records
                console.log('111111', tableData)
                if (tableData.length > 0) {
                    this.showCNBB = true
                } else {
                    this.showCNBB = false
                }
            })
        },
        changeTab (index) {
            if (index === 1) {
                this.ruoter1 = 'btnGreen'
                this.ruoter2 = 'btnGray'
                this.ruoter3 = 'btnGray'
                this.$router.push({
                    path: '/ElecGenerate/reportStatistical/generalPower',
                })
            } else if (index === 2) {
                this.ruoter1 = 'btnGray'
                this.ruoter2 = 'btnGreen'
                this.ruoter3 = 'btnGray'
                this.$router.push({
                    path: '/ElecGenerate/reportStatistical/inverter',
                })
            } else if (index === 3) {
                this.ruoter1 = 'btnGray'
                this.ruoter2 = 'btnGray'
                this.ruoter3 = 'btnGreen'
                this.$router.push({
                    path: '/ElecGenerate/reportStatistical/storage',
                })
            }
        },
    },
    watch: {
        '$store.state.storage.customerId': {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return
                }
                this.initBatteryData()
            },
        },
    },
    created () {
        // this.initBatteryData()
    },
}
</script>

<style lang="less" scoped>
.reportRouter {
    .enerTopRouter {
        margin-top: -3.2rem;
        width: 100%;
        font-size: 1.4rem;
        height: 3.4rem;
        // position: fixed;
        background: #0c2f4f;
        display: flex;
        // top: 15.3rem;
        .btnGreen {
            color: #03d9bc;
            margin-left: 2.6rem;
            cursor: pointer;
            border-bottom: 0.2rem solid #03d9bc;
        }
        .btnGray {
            color: aliceblue;
            margin-left: 2.6rem;
            cursor: pointer;
        }
    }
    .routerLay {
        height: 86.4rem;
        // width: 142.8rem;
        background-color: #18191a;
    }
}
</style>
<style lang="less">
.reportRouter {
    .el-table {
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        flex: 1;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        color: #fff;
    }
    .el-form-item__label {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #fff;
        padding: 0 12px 0 0;
        box-sizing: border-box;
    }
}
</style>
