<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Repair',
}
</script>

<style lang="less" scoped>
  
</style>

