export default {
    data(){
       return {
           // 遮罩层
           loading: true,
           // 选中数组
           ids: [],
           // 非多个禁用
           multiple: true,
           // table总条数
           total: 0,
           // 弹出层标题
           title: "",
           // 是否显示弹出层
           open: false,
       }
    },
    methods: {
        // 分页操作
        handleSizeChange(pageSize){
            this.queryParams.pageNum = 1;
            this.queryParams.pageSize = pageSize;
            this.getList();
        },
        handleCurrentChange(pageNum){
            this.queryParams.pageNum = pageNum;
            this.getList();
        },
        // 重置表单
        resetForm() {
            Object.keys(this.form).forEach(key => this.form[key] = '');
        },
        // 表单取消
        cancel() {
            this.open = false;
            this.resetForm();
        },
        // 搜索
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        // 重置
        resetQuery() {
            Object.keys(key => this.queryParams[key] = '');
            this.queryParams.pageNum = 1;
            this.getList();
        },

    }
}