<template>
  <div id="calender">
    <div class="calendar-time">
      <div class="txt-c p-10">
        <div class="text-time">
          <img
            class="text1"
            @click="prev"
            src="@/assets/energyStatistical/left.png"
            alt
          />
          <span>{{ this.year }}</span
          >年 <span class="month">{{ this.month }}</span> 月
          <img
            class="text2"
            @click="next"
            src="@/assets/energyStatistical/rigth.png"
            alt
          />
        </div>
      </div>
      <div class="text-week">
        <div class="weekDay flex jc-sb p-5 day">
          <p v-for="item in weekList" :key="item.id">{{ item }}</p>
        </div>
        <div class="weekDay flex p-5 day day-time">
          <p class="day-heigth" v-for="item in spaceDay" :key="item.id"></p>

          <p
            class="day-heigth"
            v-for="(item, idx) in calendarMonth || 30"
            @click="setDay(idx)"
            :class="
              idx == activeDay ||
              (todayItem == idx + 1 && year == thisYear && month == thisMonth)
                ? 'active'
                : ''
            "
            :key="item.id"
          >
            {{ item.id }}
            <img
              v-show="item.flag == 3"
              class="day-color"
              src="@/assets/energyStatistical/cal-red.png"
              alt
            />
            <img
              v-show="item.flag == 2"
              class="day-color"
              src="@/assets/energyStatistical/cal-yellow.png"
              alt
            />
            <img
              v-show="item.flag == 1"
              class="day-color"
              src="@/assets/energyStatistical/cal-green.png"
              alt
            />
            <img v-show="item.flag == 4" class="day-color" src="" alt />
          </p>
        </div>
      </div>
    </div>
    <div class="cal-num">
      <div class="cla-num-left">
        <div class="cla-num-left-icon1">
          <p class="icon-p1">{{ dataForm.lessNum || '-' }}</p>
        </div>
        <p class="icon-text">能耗小于参考值</p>
        <div class="cla-num-left-icon1">
          <p class="icon-p2">{{ dataForm.nearNum || '-' }}</p>
        </div>
        <p class="icon-text">能耗接近参考值</p>
        <div class="cla-num-left-icon1">
          <p class="icon-p3">{{ dataForm.greaterNum || '-' }}</p>
        </div>
        <p class="icon-text">能耗大于参考值</p>
      </div>
      <div class="cla-num-rigth">
        <!-- <el-tooltip class="rigth-center" effect="dark" placement="top">
          <div slot="content">
            <p>工作日：上一年本月的加权平均值（20%）+前一周的加权平均值（20%）+前一天的加权平均值（60%），周末的除外</p>
            <p>周末：上一年本月的加权平均值（20%）+前一周的加权平均值（20%）+前一天的加权平均值（60%），工作日的除外</p>
          </div>
          <div class="rigth-center">
            <div class="rigth-icon1"></div>
            <div class="rigth-num">
              <h3>{{ lookValue }}kWh</h3>
              <p>参考值</p>
            </div>
          </div>
        </el-tooltip> -->
        <div class="rigth-center">
          <div class="rigth-icon1"></div>
          <div class="rigth-num">
            <h3>{{ lookValue }}kWh</h3>
            <p>参考值</p>
          </div>
        </div>
        <div class="rigth-center">
          <div class="rigth-icon2"></div>
          <div class="rigth-num">
            <h3>{{ todayValue }}kWh</h3>
            <p>当日累计</p>
          </div>
        </div>
        <div class="rigth-center">
          <div class="rigth-icon3"></div>
          <div class="rigth-num">
            <h3>{{ dataForm.monthTotal }}kWh</h3>
            <p>当月累计</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { breakText } from '@antv/x6/es/util/dom/text'
import { mapState } from 'vuex'
export default {
  name: 'calender',
  data() {
    return {
      year: '', // 年份
      month: '', // 月份
      day: '', // 天数
      current: '', // 当前时间
      weekList: ['一', '二', '三', '四', '五', '六', '日'],
      monthDay: [31, '', 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      activeDay: -1, // 选中的日期
      spaceDay: '', // 每个月第一天是星期几
      February: '', // 判断2月份的天数
      selectTime: '',
      todayValue: '',
      lookValue: '',
      dataForm: {
        customerId: '',
      },
      showRed: false,
      showYellow: false,
      showGreen: true,
      todayItem: '',
      thisMonth: '',
      thisYear: '',
      calendarMonth: [],
      flag: false,
    }
  },
  created() {
    this.current = new Date()
    this.getTheCurrentDate()
    this.getMonthFisrtDay()
    this.year = this.current.getFullYear()
    this.February = this.isLeapYear(this.year) ? 29 : 28
    this.monthDay.splice(1, 1, this.February)
    this.todayItem = this.current.getDate()
    this.thisMonth = this.current.getMonth() + 1
    this.thisYear = this.current.getFullYear()
    this.selectTime = this.thisYear + '-' + this.thisMonth
  },
  mounted() {
    this.flag = true
    setTimeout(() => {
      this.queryDayData()
    }, 800)
  },
  methods: {
    // 判断是否是闰年
    isLeapYear(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
    },
    // 选取特定天数
    setDay(idx) {
      this.todayItem = ''
      this.activeDay = idx
      this.day = idx + 1
      this.selectTime = this.year + '-' + this.month
      this.todayValue = this.dataForm.dailyTotal[idx].aggregate
      this.lookValue = this.dataForm.dailyTotal[idx].refValue
    },
    // 获取特定月的数据
    queryDayData() {
      this.dataForm.customerId = this.$store.state.storage.customerId
      this.calendarMonth = []
      this.$store
        .dispatch('getTodayCal', {
          id: this.dataForm.customerId,
          month: this.selectTime,
        })
        .then(() => {
          this.dataForm = this.getTodayCal
          console.log('this.dataForm', this.dataForm)
          this.calendarMonth = []
          for (let i = 0; i < this.monthDay[this.month - 1]; i++) {
            let obj = {
              id: i + 1,
              flag: 0,
            }
            for (let j = 0; j < this.dataForm.dailyTotal.length; j++) {
              if (i == j) {
                if (this.dataForm.dailyTotal[j] !== null) {
                  if (
                    this.dataForm.dailyTotal[j].aggregate <
                    this.dataForm.dailyTotal[j].refValue
                  ) {
                    obj.flag = 1
                    break
                  } else if (
                    this.dataForm.dailyTotal[j].aggregate ==
                    this.dataForm.dailyTotal[j].refValue
                  ) {
                    obj.flag = 2
                    break
                  } else if (
                    this.dataForm.dailyTotal[j].aggregate >
                    this.dataForm.dailyTotal[j].refValue
                  ) {
                    obj.flag = 3
                    break
                  }
                } else {
                  obj.flag = 4
                  break
                }
              }
            }
            this.calendarMonth.push(obj)
          }

          // 数据只包含今天以前的数据
          let arr = this.dataForm.dailyTotal
          for (let i = 0; i < arr.length; i++) {
            if (i == this.day - 1) {
              if (arr[i] !== null) {
                this.todayValue = arr[i].aggregate
                this.lookValue = arr[i].refValue
              } else {
                this.todayValue = 0
                this.lookValue = 0
              }
            }
          }
        })
    },
    // 判断月份的第一天是星期几
    getMonthFisrtDay() {
      var firstDayOfCurrentMonth = new Date(this.year, this.month - 1, 1) // 某年某月的第一天
      if (firstDayOfCurrentMonth.getDay() == 0) {
        this.spaceDay = 6
      } else {
        this.spaceDay = firstDayOfCurrentMonth.getDay() - 1
      }
    },
    // 获取当前的日期
    getTheCurrentDate() {
      this.year = this.current.getFullYear()
      this.month = this.current.getMonth() + 1
      this.day = this.current.getDate()
    },
    prev() {
      this.activeDay = -1
      if (this.month == 1) {
        this.year--
        this.month = 12
      } else {
        this.month--
      }
      // this.activeDay = 0;
      this.selectTime = this.year + '-' + this.month
      this.getMonthFisrtDay()
      this.queryDayData()
    },
    next() {
      this.activeDay = -1
      if (this.month == 12) {
        this.year++
        this.month = 1
      } else {
        this.month++
      }
      // this.activeDay = 0;
      this.selectTime = this.year + '-' + this.month
      this.getMonthFisrtDay()
      this.queryDayData()
    },
  },
  computed: {
    ...mapState({
      getTodayCal: (state) => {
        return state.elecManagement.getTodayCal.data
      },
      customerId: (state) => {
        return state.storage.setCustomerId.data
      },
    }),
  },
  watch: {
    month() {
      if (this.month > 12 || this.month < 1) {
        return
      }
      this.getMonthFisrtDay()
    },
    year() {
      this.getMonthFisrtDay()
    },
    '$store.state.storage.customerId': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return
        }
        if (!this.flag) return
        this.dataForm.customerId = newVal
        this.queryDayData()
      },
    },
  },
}
</script>

<style lang="less" scoped>
#calender {
  padding: 0.5rem;
  .calendar-time {
    height: 30rem;
  }
  .txt-c {
    position: relative;
    top: 2.5rem;
    height: 1.2rem;
    background-color: #005ab1;
    text-align: center;
    color: #fff;
  }
  .p-10 {
    padding: 1rem;
    .text-time {
      // margin-top: -0.5rem;
      line-height: 1.2rem;
      .text1 {
        float: left;
        margin-top: 0.1rem;
        cursor: pointer;
      }
      .text2 {
        cursor: pointer;
        float: right;
        margin-top: 0.1rem;
      }
    }
    .text-week {
      height: 26.7rem;
    }
  }
  .p-5 {
    padding: 1rem;
  }
  .flex {
    display: flex;
    color: #fff;
    cursor: pointer;
  }
  .jc-sb {
    justify-content: space-between;
  }
  input {
    width: 50px;
    &.month {
      width: 30px;
    }
  }
  .day {
    flex-wrap: wrap;
    margin-top: 2rem;
    p {
      width: 14.28%;
      /*flex: 0 0 0 ;*/
      text-align: center;
      position: relative;
      z-index: 100;
      &.active {
        color: #fff;
      }
      &.active:before {
        content: '';
        height: 2.5rem;
        width: 2.5rem;
        position: absolute;
        z-index: -1;
        left: -0.3rem;
        top: -0.5rem;
        transform: translateX(50%);
        border-radius: 50%;
        background: #ffaf00;
        color: #fff;
      }
    }
  }
  .day-time {
    position: relative;
    top: -2rem;
    .day-heigth {
      margin-top: 1.6rem;
      .day-color {
        position: absolute;
        left: 1.9rem;
        top: 2rem;
      }
    }
  }
  .cal-num {
    display: flex;
    margin-top: 3rem;
    .cla-num-left {
      width: 13rem;
      height: 19.6rem;
      background: #103b65;
      border-radius: 4px;
      margin-left: 0.8rem;
      .cla-num-left-icon1 {
        .icon-p1 {
          width: 2.4rem;
          height: 2.4rem;
          line-height: 2.4rem;
          font-size: 1.2rem;
          text-align: center;
          border-radius: 50%;
          color: #00b578;
          border: 0.1rem#00b578 solid;
          position: relative;
          left: 5rem;
          margin-top: 1.9rem;
        }
        .icon-p2 {
          color: #ffaf00;
          width: 2.4rem;
          height: 2.4rem;
          line-height: 2.4rem;
          font-size: 1.2rem;
          text-align: center;
          // color: #ff6565;
          border-radius: 50%;
          border: 0.1rem#ffaf00 solid;
          position: relative;
          left: 5rem;
          margin-top: 0.9rem;
        }
        .icon-p3 {
          width: 2.4rem;
          height: 2.4rem;
          line-height: 2.4rem;
          font-size: 1.2rem;
          text-align: center;
          // color: #ff6565;
          border-radius: 50%;
          color: #ff6565;
          border: 0.1rem #ff6565 solid;
          position: relative;
          left: 5rem;
          margin-top: 0.9rem;
        }
      }
      .icon-text {
        text-align: center;
        margin-top: 0.2rem;
        font-size: 1.4rem;
        color: #ffffff;
      }
    }
    .cla-num-rigth {
      width: 18rem;
      height: 19.6rem;
      margin-left: 0.6rem;
      background: #103b65;
      border-radius: 4px;
      .rigth-center {
        display: flex;
        .rigth-icon1 {
          width: 4.5rem;
          height: 4.6rem;
          border-radius: 50%;
          background: url(../../../assets/energy/cal1.png) no-repeat center;
          background-color: #005ab1;
          background-size: 1.5rem 1.52rem;
          float: left;
          margin-left: 1rem;
          margin-top: 1.5rem;
        }
        .rigth-icon2 {
          width: 4.5rem;
          height: 4.6rem;
          border-radius: 50%;
          background: url(../../../assets/energy/cal2.png) no-repeat center;
          background-color: #005ab1;
          background-size: 1.5rem 1.52rem;
          float: left;
          margin-left: 1rem;
          margin-top: 1.5rem;
        }
        .rigth-icon3 {
          width: 4.5rem;
          height: 4.6rem;
          border-radius: 50%;
          background: url(../../../assets/energy/cal3.png) no-repeat center;
          background-color: #005ab1;
          background-size: 1.5rem 1.52rem;
          float: left;
          margin-left: 1rem;
          margin-top: 1.5rem;
        }
        .rigth-num {
          float: left;
          margin-left: 2rem;
          margin-top: 1.5rem;
          h3 {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: #ffffff;
          }
          p {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 2rem;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
