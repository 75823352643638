import request from '@/utils/request';

export function pageGroup(query) {
  return request({
    url: '/diagram/group/page',
    method: 'get',
    params: query
  })
}

export function listGroup() {
  return request({
    url: '/diagram/group/list',
    method: 'get',
  })
}

export function getGroupById(groupid) {
  return request({
    url: '/diagram/group/' + groupid,
    method: 'get'
  })
}

export function addGroup(data) {
  return request({
    url: '/diagram/group',
    method: 'post',
    data: data
  })
}

export function updateGroup(data) {
  return request({
    url: '/diagram/group',
    method: 'put',
    data: data
  })
}

export function delGroup(groupid) {
  return request({
    url: '/diagram/group/' + groupid,
    method: 'delete'
  })
}
