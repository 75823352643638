/**
 * 基类Cell形状
 * @enum
 */

export const SVGSizeEnum = {
	width: '40px',
	height: '40px',
	iWidth: 40,
	iHeight: 40
}

export const FONTSizeEnum = {
	width: '100px',
	height: '30px',
	iWidth: 60,
	iHeight: 30
}
export const TABLESizeEnum = {
	width: '100px',
	height: '30px',
	iWidth: 145,
	iHeight: 40
}

export const ShapeEnum = {

	// Edge边线
	SVG_EDGE: 'svg_edge',
	// SVGhtml
	SVG_HTML: 'svg_html',
	// SVG标签
	SVG_TAG: 'svg_tag',
	// SVG图片
	SVG_IMAGE: 'svg_image',
	/**@type {String} 矩形 */
	RECT: 'rect',
	/**@type {String} 圆形 */
	CIRCLE: 'circle',
	/**@type {String} 椭圆 */
	ELLIPSE: 'ellipse',
	/**@type {String} 多边形 */
	POLYGON: 'polygon',
	/**@type {String} 菱形 x6不存在此类型 */
	DIAMOND: 'diamond'
}

/**
 * Node类型
 * @enum
 */
export const NodeTypeEnum = {
	/**@type {String} 开始节点 */
	BEGIN: 'begin-node',
	/**@type {String} 条件节点 */
	CONDITION: 'condition-node',
	/**@type {String} 尾部节点 */
	TAIL: 'tail-node',
}

/**
 * 工具类型
 * @enum
 */
export const ToolTypeEnum = {
	/**@type {String} 删除元素 */
	REMOVE_BUTTON: 'custom-remove-button'
}

/**
 * 事件分发类型
 * @enum
 */
export const CustomEventTypeEnum = {
	/**@type {String} 单元点击触发 */
	CELL_CLICK: '__antv_x6_custom_event_type_cell_click__',
	/**@type {String} 节点点击触发 */
	NODE_CLICK: '__antv_x6_custom_event_type_node_click__',
	/**@type {String} 双击节点触发 */
	DOUBLE_NODE_CLICK: '__antv_x6_custom_event_type_cell_double_click__',
	/**@type {String} 帮助信息 */
	HELP: '__antv_x6_custom_event_type_help__',
	/**@type {String} tooltips 回调 */
	TOOLTIPS_CALLBACK: '__antv_x6_custom_event_type_tooltips_callback__',
	/**@type {String} 冻结画布 */
	FREEZE_GRAPH: '__antv_x6_custom_event_type_freeze_graph__',
	/**@type {String} 运行时异常 */
	RUNTIME_ERR: '__antv_x6_custom_event_type_runtime_err__',
}

/**
 * 选择状态
 * @enum
 */
export const SelectStateEnum = {
	/**@type {String} 已选中 */
	SELECTED: 'selected',
	/**@type {String} 未选中 */
	UN_SELECTED: 'unselected'
}

/**
 * 配置项
 * @enum
 */
export const Config = {
	/**@type {String} 可操作Node区 插槽 */
	PANEL_AREA_SLOT: 'panel_area_slot',
	/**@type {String} Tooltips 插槽 */
	TOOLTIPS_SLOT: 'tooltips_slot',
}

/**
 * 节点触发类型
 */
export const ActionType = {
	// Edge边线
	SVG_EDGE: 'SVG_EDGE',
	SVG_TABLE: 'SVG_TABLE',
	SVG_KEYVLAUE:'SVG_KEYVLAUE',
	SVG_FONT: 'SVG_FONT',
	// SVGhtml
	SVG_HTML: 'SVG_HTML',
	// SVG标签
	SVG_TAG: 'SVG_TAG',
	// SVG图片
	SVG_IMAGE: 'SVG_IMAGE',
	/**@type {String} 触发器 */
	TRIGGER: 'TRIGGER',
	/**@type {String} 条件 */
	CONDITION: 'CONDITION',
	/**@type {String} 动作 */
	ACTION: 'ACTION',
}

/**
 * 缓存
 * @enum
 */
export const StoreKey = {
	GRAPH: '__antv_x6_store_key_graph__'
}

