<template>
    <div>
        <!-- 板块二（主内容）######################################################################## -->
        <div class="blockTwo page-main">
            <!-- 板块二/左侧 ##########################################################################-->
            <div :class="isTreeShow ? 'shrink-open_tree':'shrink-close_tree'" class="blockTwoLeft page-main-tree">
                <!--el树形控件  -->
                <TreePeiDian />
            </div>
            <!-- 板块二/右侧 ##########################################################################-->
            <div :class="isTreeShow ? 'shrink-open_tree_content':'shrink-close_tree_content'" class="blockTwoRight page-main-content">
                <div class="shrink-btn" @click="isTreeShow = !isTreeShow"></div>
                <div class="topBar">
                    <div>
                        <el-input size="small" placeholder="请输入设备名称" v-model="searchValue" @keyup.enter.native="search" style="width:22rem;margin-right:2rem;" clearable @clear="search"></el-input>
                        <button class="btn_save" @click="search">查 询</button>
                    </div>
                    <button class="btn2" :class="no4class == 'btn4Gray' ? 'btn2Gray' : ''" :disabled="no4class == 'btn4Gray'" @click="importFile">导入</button>
                    <button class="btn3" @click="exportFile">导出</button>
                    <button :class="no4class" @click="handleAdd">登记</button>
                </div>
                <div class="equipmentTablePlace">
                    <!-- 面包屑部分 -->
                    <div class="bread">
                        {{this.breadName}}
                    </div>
                    <!-- 表格部分-->
                    <div class="table">
                        <el-table :data="tableData" stripe:false height="59rem" border:false style="width: 100%" :header-cell-style="headerClass" :cell-style="cellStyle">
                            <el-table-column label="序号" min-width="6%">
                                <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
                            </el-table-column>
                            <el-table-column class="tab" prop="num" label="编号" min-width="10%" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column class="tab" prop="name" label="设备名称" min-width="18%" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column prop="company" label="客户单位" min-width="17%" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column prop="placeName" label="所属配电房" min-width="14%" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column prop="model" label="型号" min-width="15%" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column prop="date" label="投运日期" min-width="13%" :show-overflow-tooltip="true">
                            </el-table-column>
                            <!-- 操作部分 -->
                            <el-table-column label="操作" min-width="13%">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text" @click="handleMore(scope.$index,scope.row)">更多</el-button>
                                    <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页器部分 -->
                    <div class="pagination">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40, 80]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="equipmentTotalNum">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 板块三(登记表单)################################################################ -->
        <div class="diaLogPlace">
            <DialogOne ref="dialogRef" />
        </div>
        <!-- 更多属性弹框 -->
        <more-info-dialog :showAttrs="showAttrs" :moreDetail="moreDetail" @handleCancle="showAttrs=false" ref="moreRef" />
        <!-- 导入dialog弹层 -->
        <DialogImportFile @queryData="search" ref="dialogImportRef" />

    </div>
</template>

<script>
import { mapState } from "vuex";
import TreePeiDian from "./components/TreePeiDian.vue";
import DialogOne from "./components/DialogOne.vue";
import MoreInfoDialog from './components/moreInfoDialog.vue';
import DialogImportFile from '../../components/DialogImportFile.vue'
import { tableBg } from '@/mixin/tablebg'
import { exportDeviceReport } from "@/api/repair"

export default {
    mixins: [tableBg],
    name: "EquipDistribution",
    components: {
        TreePeiDian,
        DialogOne,
        MoreInfoDialog,
        DialogImportFile,
    },
    data () {
        return {
            isTreeShow: true,
            // 面包屑内容
            breadName: "设备管理 / 配电设备 / ",
            // 搜索栏内容
            searchValue: "",
            // 分页器数据,当前页面
            currentPage: 1,
            // 表格数据(里面放对象)
            tableData: [],
            // 设备总个数
            equipmentTotalNum: 0,
            // 每页设备数量
            pageSize: 10,
            //树的节点ID
            treeNode: "",
            // 树的节点的完整数据
            treeFullNodeData: {},
            // 登记按钮的样式
            no4class: "btn4Gray",
            showAttrs: false, // 是否显示更多属性弹框
            moreDetail: null
        };
    },
    methods: {
        // 更多弹框
        handleMore (index, row) {
            this.$store.dispatch('getEquipmentOwnData', this.equipmentPageData.records[index].id).then(() => {
                this.$refs.moreRef.showForMore(this.equipmentOwnData)
                this.showAttrs = true
                this.moreDetail = row
            })

        },
        // 显示弹窗
        handleAdd () {
            if (this.no4class === 'btn4Gray') {
                // this.$message.error('请先在左侧选择登记的单位');
            } else {
                this.$refs.dialogRef.show(this.treeFullNodeData)
            }
        },
        //刷新页面（供子组件调用）
        refreshThePage () {
            this.getMyData(
                this.currentPage,
                this.pageSize,
                this.searchValue,
                this.treeNode
            );
        },
        //发送请求#####分页器改变条数/改变页面
        handleSizeChange (val) {
            this.pageSize = val
            this.getMyData('1', val, this.searchValue, this.treeNode);
        },
        handleCurrentChange (val) {
            this.currentPage = val
            this.getMyData(val, this.pageSize, this.searchValue, this.treeNode);
        },
        //发送请求##### 搜索
        search () {
            this.getMyData(
                '1',
                this.pageSize,
                this.searchValue,
                this.treeNode
            );
        },
        //发送请求##### 获取分页数据
        getMyData (page, numPerPage, search, ownerId) {
            this.$store.dispatch("getEquipmentPageData", {
                page: page,
                numPerPage: numPerPage,
                search: search,
                ownerId: ownerId,
            }).then(() => {
                // 进行数据遍历渲染
                this.tableData = [];
                this.equipmentPageData.records.forEach((item) => {
                    const newEquipment = {};
                    newEquipment.num = item.code;
                    newEquipment.name = item.name;
                    newEquipment.company = item.ownerName;
                    newEquipment.address = item.location;
                    newEquipment.model = item.specification;
                    newEquipment.date = item.useDate;
                    newEquipment.placeName = item.placeName
                    this.tableData.push(newEquipment);
                });
                // 对分页器进行渲染
                this.equipmentTotalNum = this.equipmentPageData.total;
            });
        },
        //发送请求##### 导出表格(临时)
        exportFile () {
            let params = {
                keyword: this.searchValue,
                ownerId: this.treeNode,
            }
            // this.$store.dispatch("getExportFile",params);
            exportDeviceReport(params).then((response) => {
                var blob = new Blob([response], {
                    type: "application/vnd.ms-excel,charset=utf-8",
                }) //创建一个blob对象
                var a = document.createElement("a") //创建一个<a></a>标签
                a.href = URL.createObjectURL(blob) // response is a blob
                a.download = `配电设备.xls` //文件名称
                a.style.display = "none"
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
        },

        //点击《导入》打开公用组件弹层
        importFile () {
            this.$refs.dialogImportRef.show({
                name: "Equipment",
                ownerId: this.treeNode,
            })
        },
        //发送请求##### 被子组件调用，下载示例模板
        downLoad () {
            this.$store.dispatch('getDownloadTemplate', "Equipment")
        },

        // 更改树的节点
        changeTreeNode (val) {
            this.currentPage = 1;
            this.treeNode = val.id
            this.treeFullNodeData = val
            this.getMyData(this.currentPage, this.pageSize, this.searchValue, this.treeNode)
            if (this.treeNode <= 10000) {
                this.no4class = 'btn4Gray'
            } else {
                this.no4class = 'btn4'
            }

            // 进行树的级别判断，渲染面包屑（尽可能减少请求次数，最多五级）
            if (val.level === 0) {
                this.breadName = '设备管理 / 配电设备 / ' + val.name
            } else if (val.level === 1) {
                this.breadName = '设备管理 / 配电设备 / ' + val.city + ' / ' + val.name
            } else if (val.level === 2) {
                this.$store.dispatch('getCustomerOwnData', val.parentId).then(() => {
                    this.breadName = '设备管理 / 配电设备 / ' + val.city + ' / ' + this.customerOwnData.name + ' / ' + val.name
                })
            } else if (val.level === 3) {
                this.$store.dispatch('getCustomerOwnData', val.parentId).then(() => {
                    const fatherOne = this.customerOwnData.name
                    const newFatherId = this.customerOwnData.parentId
                    this.$store.dispatch('getCustomerOwnData', newFatherId).then(() => {
                        this.breadName = '设备管理 / 配电设备 / ' + val.city + ' / ' + this.customerOwnData.name + ' / ' + fatherOne + ' / ' + val.name
                    })
                })
            } else if (val.level === 4) {
                this.$store.dispatch('getCustomerOwnData', val.parentId).then(() => {
                    const fatherOne = this.customerOwnData.name
                    const newFatherId = this.customerOwnData.parentId
                    this.$store.dispatch('getCustomerOwnData', newFatherId).then(() => {
                        const fatherTwo = this.customerOwnData.name
                        const newFatherId2 = this.customerOwnData.parentId
                        this.$store.dispatch('getCustomerOwnData', newFatherId2).then(() => {
                            this.breadName = '设备管理 / 配电设备 / ' + val.city + ' / ' + fatherTwo + ' / ' + this.customerOwnData.name + ' / ' + fatherOne + ' / ' + val.name
                        })
                    })
                })
            }
        },

        // 删除表格中的节点，其中val为它在该页面的顺序(点击《删除》按钮)
        handleDelete (index) {
            const daleteId = this.equipmentPageData.records[index].id;
            this.$confirm("您确定要执行删除操作吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$store.dispatch("deleteItemInTable", daleteId).then(() => {
                    if (this.ifDeleted === 0) {
                        this.$message({ message: "删除成功！", type: "success" });
                        this.refreshThePage();
                    } else if (this.ifDeleted === -1) {
                        this.$message({ message: "删除失败！请检查网络", type: "warning" });
                    }
                });
            });
        },

        // 对该项进行详情编辑,其中val为它在该页面的顺序(点击《编辑》按钮)
        handleEdit (index) {
            this.$store.dispatch('getEquipmentOwnData', this.equipmentPageData.records[index].id).then(() => {
                this.$refs.dialogRef.showForEdit(this.equipmentOwnData)
            })
        },
    },

    //处理state数据
    computed: {
        ...mapState({
            equipmentPageData: (state) => { return state.equipment.equipmentPageData.data; },
            ifDeleted: (state) => { return state.equipment.ifDeleted.errorCode; },
            customerOwnData: state => { return state.customer.customerOwnData.data; },
            equipmentOwnData: state => { return state.equipment.equipmentOwnData.data; }

        }),
    },

    // 发送请求##### 加载页面
    mounted () {
        this.getMyData(
            this.currentPage,
            this.pageSize,
            this.searchValue,
            this.treeNode
        );
    },
};
</script>

<style lang="less" scoped>
// 板块二##############################################################
.blockTwo {
    //   height: 96.8rem;
    //   width: 171.2rem;
    //   position: relative;
    //   background-color: #18191a;
}

// 板块二左侧#####################################################
.blockTwoLeft {
    //   overflow: hidden;
    //   width: 26rem;
    //   height: 96.8rem;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
}

// 板块二右侧#####################################################
.blockTwoRight {
    //   width: 142.8rem;
    height: 96.8rem;
    //   position: absolute;
    //   left: 28.4rem;
    //   top: 0;
    .topBar {
        display: flex;
        align-items: center;
        padding-left: 2rem;
        box-sizing: border-box;
        height: 8rem;
        // width: 142.8rem;
        width: 100%;
        background-color: #0d3050;
        border-radius: 0.4rem;
        // position: absolute;
        // top: 0;
        // left: 0;
        overflow: hidden;
        margin-bottom: 2.4rem;
        input {
            height: 3.2rem;
            width: 22.8rem;
            font-size: 1.4rem;
            position: absolute;
            left: 2.4rem;
            top: 2.4rem;
            background-color: #0c375e;
            border: 1px solid #004579;
            border-radius: 0.2rem;
            box-sizing: border-box;
            outline: 0;
            color: #fff;
            text-indent: 1.4rem;
            &:focus {
                border: 0.1rem solid #008080;
            }
        }

        button {
            height: 3.2rem;
            width: 7.4rem;
            border-radius: 0.2rem;
            border: none;
            color: #fff;
            font-size: 1.4rem;
            cursor: pointer;
        }
        .btn1 {
            background-color: #027969;
            position: absolute;
            top: 2.4rem;
            left: 26rem;
        }
        .btn2 {
            background-color: #0c375e;
            position: absolute;
            top: 2.4rem;
            // left: 116.6rem;
            right: 18.4rem;
            border: 0.1rem solid #004579;
        }
        .btn2Gray {
            color: gray;
            cursor: not-allowed;
        }
        .btn3 {
            background-color: #0c375e;
            position: absolute;
            top: 2.4rem;
            //   left: 124.8rem;
            right: 10.2rem;
            border: 0.1rem solid #004579;
        }
        .btn4 {
            background-color: #027969;
            position: absolute;
            top: 2.4rem;
            //   left: 133rem;
            right: 2rem;
        }
        .btn4Gray {
            background-color: gray;
            position: absolute;
            top: 2.4rem;
            //   left: 133rem;
            right: 2rem;
        }
    }
    // 注意：table内部的样式有一部分是以回调函数的形式进行更改的，在methods当中，一部分在内联样式，还有一部分在css
    .equipmentTablePlace {
        height: 86.4rem;
        // width: 142.8rem;
        width: 100%;
        // position: absolute;
        // top: 10.4rem;
        left: 0;
        overflow: hidden;
        background-color: #0d3050;
        .bread {
            height: 5rem;
            // width: 142.8rem;
            line-height: 5rem;
            color: #fff;
            // 临时
            font-size: 1.4rem;
            text-indent: 2.4rem;
        }
        .table {
            height: 59rem;
            // width: 138rem;
            margin: 0 2.4rem 0 2.4rem;
            // overflow: hidden;
            // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
            .el-table td,
            .el-table th.is-leaf {
                border-bottom: 0.1rem solid #254561;
            }
            .el-table--border:after,
            .el-table--group:after,
            .el-table:before {
                opacity: 0;
            }
            .el-table__body-wrapper {
                background-color: #0d3050;
            }
            .el-button--text {
                color: #03d9bc;
            }
        }
    }
}
.pagination {
    float: right;
    margin-right: 5rem;
    margin-top: 1rem;
}

// 板块三(登记表单)####################################################
.diaLogPlace {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
}
</style>


<style lang="less" scoped>
// 滚动条样式修改（全局）#############################################
//滚动条的宽度
// ::-webkit-scrollbar {
//   width: 0.6rem;
//   background-color: #0d3050;
// }
// //滚动条的滑块
// ::-webkit-scrollbar-thumb {
//   background-color: #027969;
//   border-radius: 0.3rem;
// }
.equipmentTablePlace {
    height: 86.4rem;
    // width: 142.8rem;
    // position: absolute;
    top: 10.4rem;
    left: 0;
    overflow: hidden;
    background-color: #0d3050;
    .bread {
        height: 5rem;
        width: 142.8rem;
        line-height: 5rem;
        color: #fff;
        // 临时
        font-size: 1.4rem;
        text-indent: 2.4rem;
    }
    .table {
        height: 59rem;
        // width: 138rem;
        margin: 0 2.4rem 0 2.4rem;
        // overflow: hidden;
        // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
        .el-table td,
        .el-table th.is-leaf {
            border-bottom: 0.1rem solid #254561;
        }
        .el-table--border:after,
        .el-table--group:after,
        .el-table:before {
            opacity: 0;
        }
        .el-table__body-wrapper {
            background-color: #0d3050;
        }
        .el-button--text {
            color: #03d9bc;
        }
    }
}
</style>