<template>
    <div class="enerstion-bottom">
        <div class="enersta">
            <div class="map-centent">
                <div v-if="flagShowRoom" @click="changeShowRoom" class="energyMap" :style="bg">
                    <div class="mapRound">
                        <div class="map-text">
                            <p class="text1">
                              <el-tooltip v-if="customerName.length > 8" effect="dark" :content="this.$parent.$refs.treeRef.currentNodeName" placement="top">
                                <span class="ellipsis">{{customerName}}</span>
                              </el-tooltip>
                              <span v-if="customerName.length <= 8">{{customerName}}</span>
                              今日用电
                            </p>
                            <div v-if="mainLoading">
                              <p style="color: #ffffff; font-size: 1.4rem">加载中，请稍后</p>
                            </div>
                            <div v-if="!mainLoading">
                              <div v-for="item in powerList" :key="item.id" class="disline">
                                  <span class="day-power">{{ item.name }}</span>
                                  <p class="text2">
                                      {{ item.value }}
                                      <span>kWh</span>
                                  </p>
                              </div>
                              <div v-if="powerList.length === 0">
                                <div class="disline">
                                  <span class="day-power">今日电量</span>
                                  <p class="text2">
                                    0
                                    <span>kWh</span>
                                  </p>
                                </div>
                                <div class="disline">
                                  <span class="day-power">本月电量</span>
                                  <p class="text2">
                                    0
                                    <span>kWh</span>
                                  </p>
                                </div>
                                <div class="disline">
                                  <span class="day-power">本年电量</span>
                                  <p class="text2">
                                    0
                                    <span>kWh</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                        </div>
                        <img class="mapPunctu1" src="@/assets/energy/funRound.png" alt />
                        <img class="mapPunctu2" src="@/assets/energy/funBottom.png" alt />
                    </div>
                </div>
                <div v-else class="energyRoom">
                    <div class="mapHeader">
                        <img class="map-back" @click="back" src="@/assets/energy/mapBack.png" alt />
                        <img class="map-header" src="@/assets/energy/mapHeader.png" alt />
                        <p>
                          <el-select
                            class="map-select"
                            v-model="energyName"
                            @change="energyNameChange"
                          >
                            <el-option
                                v-for="item in energyNameList"
                                :key="item.buildingId"
                                :label="item.buildingName"
                                :value="item.buildingId"
                            ></el-option>
                          </el-select>
                        </p>

                    </div>
                    <div class="mapBtnList">
                        <p v-for="(item, index) in floorList" :key="index" :class="floorIndex == index ? 'btnGreen' : 'btnGray'" @click="changeColor(index)">
                          {{locationName(item.name)}}
                        </p>
                    </div>
                    <div class="mapRoom" v-if="floorList && floorList.length > 0">
                        <div v-show="room1" class="room1">
                            <!-- <div v-show="room1" class="room1"> -->
                            <div class="roomRound">
                                <img class="mapPunctu1" src="@/assets/energy/funRound1.png" alt />
                            </div>
                            <div class="roomText" v-if="!loading">
                                <p class="text1">{{ floorName }}</p>
                                <div class="disline">
                                    <span class="day-power">今日电量:</span>
                                    <p class="text2">
                                        {{ floorData.data }}
                                        <span>kWh</span>
                                    </p>
                                </div>
                                <div class="disline">
                                    <span class="day-power">本月电量:</span>
                                    <p class="text2">
                                        {{ floorData.month }}
                                        <span>kWh</span>
                                    </p>
                                </div>
                                <div class="disline">
                                    <span class="day-power">本年电量:</span>
                                    <p class="text2">
                                        {{ floorData.year }}
                                        <span>kWh</span>
                                    </p>
                                </div>
                            </div>
                            <div class="roomText" v-if="loading">
                              <p class="text1">{{ floorName }}</p>
                              <div class="disline">
                                <span class="day-power">数据加载中,请稍等...</span>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "energyMap",
    data () {
        return {
            bg: {
                background: ""
            },
            buildingName: '',
            dataForm: {
                customerId: "",
                floorId: "",
            },
            router: "btnGreen",
            floorList: [
                {
                    id: "1",
                    name: "1F",
                    router: "btnGreen",
                },
                {
                    id: "2",
                    name: "2F",
                    router: "btnGray",
                },
                {
                    id: "3",
                    name: "3F",
                    router: "btnGray",
                },
            ],
            floorHeight: 48,
            room1: true,
            room2: false,
            room3: false,
            room4: false,
            room5: false,
            room6: false,
            powerList: [],
            flagShowRoom: true,
            floorData: {
                today: "",
                month: "",
                year: "",
            },
            floorIndex: 0,
            floorName: "01层",
            energyName: "",
            energyNameList: [],
            // 声明map
            node2Map: new Map(),
            loading: false,
            latestFloorId:"",
            mainLoading: false,
        };
    },
    mounted () { },
    created () { },
    methods: {
        // 能耗地图第一页数据
        getMapData () {
            this.dataForm.customerId = this.$store.state.storage.customerId;
            this.mainLoading = true
            this.$store.dispatch("getEnerPower", this.dataForm.customerId).then(() => {
                if (this.$store.state.elecManagement.getEnerPower.errorCode == 0) {
                    let arr = this.$store.state.elecManagement.getEnerPower.data;
                    if (arr.length > 0) {
                        this.powerList = arr[0].byusage;
                    } else {
                        this.powerList = [];
                    }
                } else {
                    this.powerList = [];
                }
            }).finally(() => {
              this.mainLoading = false
            })
        },
        //拿到楼层id
        queryFloatId () {
            this.dataForm.customerId = this.$store.state.storage.customerId;
            this.$store.dispatch("getFloatId", this.dataForm.customerId).then(() => {
                if (this.$store.state.elecManagement.getFloatId.errorCode == 0) {
                    let arr = this.$store.state.elecManagement.getFloatId.data;

                    // 顶部1级节点下拉框
                    if ((arr !== null) && (arr.length > 0)) {
                      this.energyName = this.getFloatId[0].buildingName;
                    }
                    if (arr.length > 0) {
                      this.energyNameList = this.getFloatId;
                      // 遍历this.getFloatId，将数据根据buildingId进行分组
                      this.energyNameList.forEach((item) => {
                        if (!this.node2Map.has(item.buildingId)) {
                          this.node2Map.set(item.buildingId, []);
                        }
                        this.node2Map.get(item.buildingId).push(item.floors);
                      });
                    }

                    if (arr[0].buildingName) {
                        this.buildingName = arr[0].buildingName;
                    }
                    if (arr[0].floors.length == 0) {
                        this.floorList = [];
                        this.dataForm.floorId = 0;
                    } else {
                        let item = arr[0].floors;
                        this.floorName = item[0].name;
                        item.forEach((value, index) => {
                            value["router"] = "btnGray";
                        });
                        this.floorList = item;
                        this.floorList[0].router = "btnGreen";
                        this.dataForm.floorId = item[0].id;
                    }
                } else {
                    this.floorList = [];
                    this.dataForm.floorId = 0;
                    this.buildingName = this.$store.state.storage.buildingName;
                    this.energyName = ''
                    this.energyNameList = []
                }
            });
        },
        //拿到具体楼层数据
        queryFloorData () {
          if (!this.loading) {
            this.loading = true
            this.latestFloorId = this.dataForm.floorId;
            this.$store.dispatch("getPowerMapFloor", this.dataForm.floorId).then(() => {
              if (this.$store.state.elecManagement.getPowerMapFloor.errorCode == 0) {
                if (this.$store.state.elecManagement.getPowerMapFloor.data == null) {
                  this.floorData.data = "";
                  this.floorData.month = "";
                  this.floorData.year = "";
                } else {
                  this.floorData.data = this.getPowerMapFloor[0].value;
                  this.floorData.month = this.getPowerMapFloor[1].value;
                  this.floorData.year = this.getPowerMapFloor[2].value;
                }
              } else {
                this.floorData.data = "";
                this.floorData.month = "";
                this.floorData.year = "";
              }
              if (this.latestFloorId !== this.dataForm.floorId) {
                setTimeout(() => {
                  this.queryFloorData()
                }, 500)
              }
            }).finally(() => {
              this.loading = false
            });
          }
        },
        changeShowRoom () {
            this.flagShowRoom = false;
            this.queryFloorData();
        },
        changeColor (index) {
            this.floorIndex = index;
            this.dataForm.floorId = this.floorList[index].id;
            this.floorName = this.floorList[index].name;
            // let num = this.floorName.replace("层", "") - 1;
            let num = index;//直接使用索引当楼层
            if (this.floorIndex < 7) {
                this.floorHeight = 48 - num * 8;
            } else {
                this.floorHeight = 48 - (index - 1) * 8;
            }
            this.queryFloorData();
        },
        back () {
            this.flagShowRoom = true;
        },
        energyNameChange(e) {
          if (this.node2Map.get(e)[0] && this.node2Map.get(e)[0].length > 0) {
            this.node2Map.get(e)[0].forEach((value, index) => {
              value["router"] = "btnGray";
            })
            this.floorList = this.node2Map.get(e)[0]
            this.floorList[0].router = "btnGreen"
            this.changeColor(0)
          } else {
            this.floorList = []
          }
        },
        locationName(name) {
          let locationName = name;
          if (name && name.length > 10) {
            locationName = locationName.substring(0, 10) + "..."
          }
          return locationName
        },
    },
    computed: {
        ...mapState({
            getEnerPower: (state) => {
                return state.elecManagement.getEnerPower.data;
            },
            getFloatId: (state) => {
                return state.elecManagement.getFloatId.data;
            },
            getPowerMapFloor: (state) => {
                return state.elecManagement.getPowerMapFloor.data;
            },
            customerPicture: (state) => {
                return state.storage.customerPicture;
            },
        }),
      customerName() {
        let customerName = this.$parent.$refs.treeRef.currentNodeName
        if (customerName && customerName.length > 10) {
          customerName = customerName.substring(0, 10) + "..."
        }
        return customerName
      },
    },
    watch: {
        "$store.state.storage.customerId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                //路有变化 清空 选择楼层
                this.floorIndex = 0;
                if (!newVal) {
                    return;
                }
                this.dataForm.customerId = newVal;
                this.floorName = "";
                this.floorHeight = 48;
                this.getMapData();
                this.queryFloatId();
                setTimeout(() => {
                    this.queryFloorData();
                }, 500);
            },
        },
        "$store.state.storage.customerPicture": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                this.bg.backgroundImage = newVal ? `URL(${newVal})` : "";
            },
        },
    },
};
</script>

<style lang="less" scoped>
.enerstion-bottom {
    overflow: hidden;
    //   width: 142.8rem;
    width: 100%;
    // height: 88.8rem;
    // margin-top: 1.5rem;
}
.enersta {
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    //   width: 143.8rem;
    width: calc(100% + 0.6rem);
    height: 86.4rem;
    // height: 86rem;
}
.blank {
    position: relative;
    top: 5rem;
    height: 20rem;
    width: 142.8rem;
    color: grey;
    font-size: 1.4rem;
    text-align: center;
    line-height: 10rem;
}
.map-centent {
    width: 100%;
    height: 100%;
    .energyMap {
        height: 100%;
        background: url(../../assets/energy/eneMapBigBack.png) no-repeat center;
        background-size: 100% 100%;
        .mapRound {
            .mapPunctu1 {
                width: 24.7rem;
                height: 15rem;
                position: absolute;
                left: 68.8rem;
                top: 11rem;
            }
            .mapPunctu2 {
                height: 5.3rem;
                position: absolute;
                left: 79rem;
                top: 25rem;
            }
            .map-text {
                position: absolute;
                left: 71rem;
                top: 12.5rem;
                z-index: 100;
                .text1 {
                    font-size: 1.4rem;
                    color: #51d1ff;
                    margin-bottom: 1rem;
                }
                .disline {
                    display: flex;
                    position: relative;
                    margin-bottom: 0.8rem;
                    .day-power {
                        // margin-left: -2rem;
                        color: #ffffff;
                        font-size: 1.4rem;
                        // position: absolute;
                        // left: -2rem;
                    }
                }
                .text2 {
                    margin-left: 2rem;
                    font-size: 1.6rem;
                    color: #23fdcf;
                    span {
                        font-size: 1.6rem;

                        color: #ffffff;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .energyRoom {
        height: 100%;
        background: url(../../assets/energy/mapRoomBack.png) no-repeat center;
        background-size: 100% 100%;
        .mapHeader {
            .map-back {
                cursor: pointer;
                width: 6rem;
                cursor: pointer;
                z-index: 100;
                position: absolute;
                left: 5rem;
                top: 3rem;
            }
            .map-header {
                position: absolute;
                left: -17rem;
                top: 5rem;
            }
            p {
                font-size: 3.2rem;
                text-align: center;
                letter-spacing: 0.05em;
                color: #ffffff;
                position: relative;
                top: 2.5rem;
            }
        }
        .mapBtnList {
            position: absolute;
            bottom: 4rem;
            left: 3rem;
            p {
                cursor: pointer;
                margin-top: 2.4rem;
                text-align: center;
                width: 7.9rem;
                height: 2.8rem;
                line-height: 2.8rem;
                color: #fff;
                background: #027969;
            }
            .btnGreen {
                background: #00ae97;
            }
            .btnGray {
                background: #027969;
            }
        }
        .mapRoom {
            .room1 {
                width: 33rem;
                height: 26rem;
                position: absolute;
                right: 1.0rem;
                top: 1.0rem;
                .roomRound {
                    position: absolute;
                    position: relative;
                    left: 0.1rem;
                    top: 0.1rem;
                    .mapPunctu1 {
                        width: 24.7rem;
                        height: 14.3rem;
                        position: absolute;
                        left: 7.5rem;
                        top: 0.2rem;
                    }
                    .mapPunctu2 {
                        width: 7.5rem;
                        height: 10.8rem;
                        position: absolute;
                        left: 0.1rem;
                        top: 7.2rem;
                    }
                }
                .roomText {
                    position: absolute;
                    left: 8.9rem;
                    top: 1rem;
                    z-index: 100;
                    .text1 {
                        font-size: 1.4rem;
                        color: #51d1ff;
                        margin-bottom: 1rem;
                    }
                    .disline {
                        display: flex;
                        position: relative;
                        margin-bottom: 0.8rem;
                        .day-power {
                            color: #ffffff;
                            font-size: 1.4rem;
                        }
                    }
                    .text2 {
                        margin-left: 2rem;
                        font-size: 1.6rem;
                        color: #23fdcf;
                        span {
                            font-size: 1.6rem;
                            color: #ffffff;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}
.rotateRoom {
    .mapPunctu2 {
        transform: rotateX(180deg);
    }
    .mapPunctu1 {
        top: 12.2rem !important;
    }
    .roomText {
        top: 13rem !important;
    }
}

</style>
<style scoped>
.map-select >>> .el-input__inner {
  border: none !important;
  background-color: transparent ;
}
</style>