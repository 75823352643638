<template>
  <div class="acDailog">
    <el-dialog
      title="交流侧历史数据"
      :visible.sync="dialogVisible"
      width="90.8rem"
      :before-close="handleClose"
    >
      <el-form
        :inline="true"
        :model="dataFrom"
        class="demo-form-inline"
        id="myPNGplace"
      >
        <el-row>
          <el-col :span="18">
            <el-form-item label="参数:">
              <el-select
                class="selectPower"
                size="mini"
                v-model="type"
                placeholder=""
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="日期:">
              <el-date-picker
                size="mini"
                class="selectTime"
                v-model="newDate"
                type="date"
                :clearable="false"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col>
                  <div class="selectBtn">
                    <el-button @click="prevDayAc">
                      上一日
                    </el-button>
                    <el-button @click="nextDayAc">
                      下一日
                    </el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <div class="searchBtn">
                <el-button @click="queryEvents()">查询</el-button>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <div class="table-time">
              <div>
                <img
                  v-show="barChartShow"
                  @click="changeBar()"
                  src="@/assets/ElecManagement/BarChart.png"
                  alt
                />
                <img
                  v-show="barChartShowGreen"
                  src="@/assets/ElecManagement/BarChartGreen.png"
                  alt
                />
              </div>
              <div>
                <img
                  v-show="lineChartShow"
                  @click="changeLine()"
                  src="@/assets/ElecManagement/LineChart.png"
                  alt
                />
                <img
                  v-show="lineChartShowGreen"
                  src="@/assets/ElecManagement/LineChartGreen.png"
                  alt
                />
              </div>
              <div>
                <!-- 下载的下拉框 -->
                <el-dropdown @command="downLoadPng">
                  <span class="el-dropdown-link">
                    <img src="@/assets/ElecManagement/Download.png" alt />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="['pic', '交流侧历史数据']">
                      图片下载
                    </el-dropdown-item>
                    <el-dropdown-item
                      style="width: 10rem;"
                      :command="['excel', '交流侧历史数据']"
                    >
                      Excel下载
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <div v-if="lineChartShow">
          <AcBarEcharts
            ref="barListECharts"
            :inverterId="inverterId"
            :type="type"
            :date="newDate"
          />
        </div>
        <div v-else>
          <AcLineEcharts
            ref="lineListEcharts"
            :inverterId="inverterId"
            :type="type"
            :date="newDate"
          />
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="dialogVisible = false">取 消</el-button>
        <el-button class="confirmButton" type="primary" @click="subilt">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import AcBarEcharts from './acBarEcharts'
import AcLineEcharts from './acLineEcharts'
import { exchangeReport } from '@/api/repair.js'
export default {
  components: {
    AcBarEcharts,
    AcLineEcharts,
  },
  props: ['inverterId'],
  data() {
    return {
      dialogVisible: false,
      activeName: '1',
      lineChartShow: true,
      lineChartShowGreen: false,
      barChartShow: false,
      barChartShowGreen: true,
      selectTime: '',
      dataFrom: {
        region: '',
      },
      typeList: [
        {
          id: 1,
          value: '电压',
        },
        {
          id: 2,
          value: '电流',
        },
        {
          id: 3,
          value: '总有功功率',
        },
        {
          id: 4,
          value: '功率因素',
        },
        {
          id: 5,
          value: '频率',
        },
      ],
      type: 1,
      newDate: '',
      interverID: '',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    queryEvents() {
      if (this.lineChartShow) {
        this.$refs.barListECharts?.initData()
      } else {
        this.$refs.lineListEcharts?.initData()
      }
    },
    // 转化dom节点为图片下载
    downLoadPng(val) {
      if (val[0] == 'excel') {
        exchangeReport({
          inverterId: this.inverterId,
          type: this.type,
          date: this.newDate,
        }).then((response) => {
          var blob = new Blob([response], {
            type: 'application/vnd.ms-excel,charset=utf-8',
          }) //创建一个blob对象
          var a = document.createElement('a') //创建一个<a></a>标签
          a.href = URL.createObjectURL(blob) // response is a blob
          let str = this.typeList.find((a) => a.id == this.type).value
          a.download = `${this.newDate}-${str}-交流侧历史数据.xls` //文件名称
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
        return
      } else {
        var node = this.lineChartShow
          ? this.$refs.barListECharts.$el
          : this.$refs.lineListEcharts.$el
        window.pageYoffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        var width = node.offsetWidth
        var height = node.offsetHeight
        var scale = 4
        html2canvas(node, {
          width: width,
          heigth: height,
          backgroundColor: '#113455',
          dpi: window.devicePixelRatio * 4,
          scale: scale,
          X: 0,
          Y: 0,
          scrollX: 0,
          scrollY: 0,
          useCORS: true,
          allowTaint: true,
        }).then((canvas) => {
          var url = canvas.toDataURL()
          var a = document.createElement('a')
          a.download = val[1]
          var event = new MouseEvent('click')
          a.href = url
          a.dispatchEvent(event)
        })
      }
    },
    init() {
      this.dialogVisible = true
      this.initData()
      // this.queryEvents()
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('upVisible', false)
    },
    initData(time) {
      let date = time || new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      this.newDate = year + '-' + month + '-' + day
    },
    changeBar() {
      this.showInterval = false
      this.barChartShowGreen = true
      this.barChartShow = false
      this.lineChartShow = true
      this.lineChartShowGreen = false
      // setTimeout(() => {
      //   this.showInterval = false
      //   this.$nextTick(() => {
      //     this.$refs.barListECharts.init()
      //   })
      // }, 0)
    },
    changeLine() {
      this.showInterval = false
      this.barChartShowGreen = false
      this.barChartShow = true
      this.lineChartShow = false
      this.lineChartShowGreen = true
      // setTimeout(() => {
      //   this.showInterval = true
      //   this.$nextTick(() => {
      //     this.$refs.lineListEcharts.init()
      //   })
      // }, 0)
    },
    prevDayAc() {
      let date = new Date(this.newDate)
      let day = date.setDate(new Date(this.newDate).getDate() - 1)
      this.initData(new Date(day))
    },
    nextDayAc() {
      let date = new Date(this.newDate)
      let day = date.setDate(new Date(this.newDate).getDate() + 1)
      this.initData(new Date(day))
    },
  },
  computed: {},
}
</script>

<style lang="less" scoped>
.acDailog {
  font-size: 1.4rem;
  height: 52.3rem;
  .selectPower {
    width: 14rem;
  }
  .selectTime {
    width: 14rem;
  }
  .selectBtn {
    button {
      color: #fff;
      margin-left: 1rem;
      position: relative;
      top: 0.2rem;
      cursor: pointer;
      padding: 0.6rem 1.2rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
  .searchBtn {
    position: relative;
    top: 0.2rem;
    button {
      color: #fff;
      margin-left: 1rem;
      cursor: pointer;
      padding: 0.6rem 1.2rem;
      background: #027969;
      border: none;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
  .table-time {
    position: absolute;
    right: 0rem;
    top: 0rem;
    display: flex;
    .select-time {
      padding-top: 0.8rem;
      padding-right: 1.5rem;
    }
    img {
      width: 2.05rem;
      height: 1.86rem;
      padding-right: 1rem;
      padding-top: 1.2rem;
      margin-left: 0.2rem;
      cursor: pointer;
    }
    .time {
      width: 12rem;
    }
  }
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 0;
}
</style>
<style lang="less">
.acDailog {
  .el-input__inner {
    width: 14rem;
  }
  .el-form-item__label {
    font-size: 1.4rem;
  }
  // 弹层标题
  .el-dialog .el-dialog__header {
    padding: 0;
    height: 5.4rem;
    line-height: 5.4rem;
    padding-left: 2.4rem;
    font-size: 1.6rem;
    background: linear-gradient(
      225deg,
      rgba(15, 36, 55, 0.0001) 0.03%,
      rgba(0, 115, 222, 0.7) 99.9%
    );
  }
  // 弹层字体
  .el-dialog__title {
    color: #fff;
  }
  // 按钮样式
  .confirmButton {
    background: #027969;
    color: #fff;
    padding: 0;
    width: 10.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    border: 1px solid #027969;
    cursor: pointer;
  }
  .cancelButton {
    background: #0c375e;
    color: #fff;
    padding: 0;
    width: 10.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    border: 0.1rem solid #004579;
    cursor: pointer;
    margin-left: 1.5rem;
  }
  // 计数器样式
  .el-input-number__increase {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease {
    background-color: #0b3d6b;
  }
  .el-input-number .is-controls-right {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #0b3d6b;
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid #027969;
    border-left: 0.1rem solid #027969;
    box-shadow: none;
    border: 0.1rem solid #027969;
    box-sizing: border-box;
    border: none;
  }
  ::-webkit-scrollbar,
  .el-select-dropdown::-webkit-scrollbar {
    width: 0;
  }
  .el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 0.4rem 0 0;
    border-bottom: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }

  .el-input-number.is-controls-right .el-input-number__decrease {
    border-left: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }
  .el-tabs__item:hover {
    color: #03d9bc;
    cursor: pointer;
  }
  .el-tabs__item.is-active {
    color: #03d9bc;
  }
  .el-tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #03d9bc;
    z-index: 1;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
  .el-tabs__item {
    padding: 0 20px;
    height: 40px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    position: relative;
  }
  .el-tabs__nav-wrap::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(7, 82, 150, 0.25);
    z-index: 1;
  }
}
</style>
