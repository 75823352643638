import request from '@/utils/request';

// 查询数据源字典列表
export function distroom () {
    return request({
        url: '/tree/distroom',
        method: 'get',
        ContentType: 'application/json;charset=utf-8'
    })
}

// 查询数据源字典列表  电气消防
export function hydrant() {
    return Promise.resolve();
}

// 查询数据源字典列表
export function placeDevice (placeId) {
    return request({
        url: `/place/device/${placeId}`,
        method: 'get',
        ContentType: 'application/json;charset=utf-8'
    })
}
// 查询数据源字典列表
export function pointDevice (deviceId) {
    return request({
        url: `/device/point/deviceid/${deviceId}`,
        method: 'get',
        ContentType: 'application/json;charset=utf-8'
    })
}
// 查询数据源字典列表
export function watchDevice (deviceIds) {
    return request({
        url: `/watch/bymeters?meterIds=${deviceIds}`,
        method: 'get',
        ContentType: 'application/json;charset=utf-8'
    })
}

export function sysMapListApi (pageSize, pageNum, nodeId) {
    return request({
        url: `/watch/page/direct/${pageSize}/${pageNum}/${nodeId}`,
        method: 'get'
    })
}

/** 根据设备id获取变压器实时指标数据 */
export function sysPagePress (params) {
    return request({
        url: `/watch/page/press`,
        method: 'GET',
        params
    })
  }
