<template>
    <!-- 配电监控的弹层 -->
    <el-dialog title="" width="1100px"
               :visible.sync="showDialog" :fullscreen="isFullScreen"
               :close-on-click-modal="false" @close="closeDialog">
        <div slot="title">
            <div class="equip_title">
                {{ title }} <i :class="isFullScreen ? 'el-icon-zoom-out' : 'el-icon-zoom-in'" style="opacity: 0.5;" @click="resizeDialog"></i>
            </div>
        </div>
        <div class="detail_box">
            <div ref="box1" class="box_ul">
                <div class="box_li" v-for="(item, i) in boxList" :key="i"
                     :class="item.prop == 'loadAndTime' ? 'long-item' : item.prop == 'switchStatus' ? 'hide-item' : ''"
                     :style="switchShow ? 'display:flex;' : ''">
                    <div>{{ item.name }}</div>
                    <p v-if="item.prop != 'switchStatus'">{{item.value}}</p>
                    <el-switch v-else v-model="item.value"
                               inline-prompt
                               active-text="开" inactive-text="关"
                               :active-value="1" :inactive-value="0"
                               @change="handleChangeSwitch">

                    </el-switch>
                </div>
                <el-button v-if="switchShow" class="log-btn" type="primary" size="mini" @click="getLogTableData()">操作记录</el-button>
            </div>
            <!-- tab和graph -->
            <div ref="box2" class="box_charts">
                <div class="attr_tabs">
                    <div class="attr_tab_item" :class="tab1 === 0 ? 'active_tab' : ''" @click="changeTabOne(0)">三相电压</div>
                    <div class="attr_tab_item" :class="tab1 === 1 ? 'active_tab' : ''" @click="changeTabOne(1)">三相电流</div>
                    <div class="attr_tab_item" :class="tab1 === 2 ? 'active_tab' : ''" @click="changeTabOne(2)">三相有功功率</div>
                    <div class="attr_tab_item" :class="tab1 === 3 ? 'active_tab' : ''" @click="changeTabOne(3)">三相无功功率</div>
                    <!--<div class="attr_tab_item" :class="tab1 === 4 ? 'active_tab' : ''" @click="changeTabOne(4)">谐波分析</div>-->
                    <div class="attr_tab_item" :class="tab1 === 4 ? 'active_tab' : ''" @click="changeTabOne(4)"
                         v-if="chartsData['电流总谐波畸变率'].length && chartsData['电流总谐波畸变率'].length > 0">谐波分析</div>
                </div>
                <div class="date">
                    <!-- 日 / 时 -->
                    <el-time-picker v-show="tab2 === 4" size="mini" :is-range="true" range-separator="-"
                                    v-model="period" format="HH" value-format="HH" :picker-options="{format: 'HH'}"
                                    popper-class="time-picker-content"
                                    @change="renderEchartsAfterRequestData"/>
                    <el-date-picker type="date" size="mini" class="styleWid"
                                    v-show="tab2 === 1 || tab2 === 4"
                                    v-model="generalDay" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                    placeholder="选择日期" range-separator="至"
                                    :clearable="false"
                                    @change="renderEchartsAfterRequestData"/>
                    <!-- 月 -->
                    <el-date-picker type="month" size="mini" class="styleWid"
                                    v-show="tab2 === 2"
                                    v-model="generalDay" format="yyyy-MM" value-format="yyyy-MM"
                                    :clearable="false"
                                    @change="renderEchartsAfterRequestData"/>
                    <!-- 年 -->
                    <el-date-picker type="year" size="mini" class="styleWid"
                                    v-show="tab2 === 3"
                                    v-model="generalDay" format="yyyy" value-format="yyyy"
                                    :clearable="false"
                                    @change="renderEchartsAfterRequestData"/>
                </div>
                <div class="dateChoose">
                    <div :class="tab2 === 4 ? 'btnGreen' : 'btnDark'" @click="changeTabTwo(4)">时</div>
                    <div :class="tab2 === 1 ? 'btnGreen' : 'btnDark'" @click="changeTabTwo(1)">日</div>
                    <div :class="tab2 === 2 ? 'btnGreen' : 'btnDark'" @click="changeTabTwo(2)">月</div>
                    <div :class="tab2 === 3 ? 'btnGreen' : 'btnDark'" @click="changeTabTwo(3)" v-show="tab1 !== 4">年</div>
                </div>
                <!-- echarts封装 -->
                <div ref="myChart" class="charts" />
            </div>
            <!-- 谐波的查询条件tab3 -->
            <el-row ref="box3" class="harmonic-wave" :style="{ marginTop: isFullScreen ? '20rem': '2rem'}" 
                    v-if="tab1 === 4" :gutter="10">
                <el-col :span="12">
                    <el-radio-group v-model="tab3" size="mini" style="float: right;" @input="changeHarmonicData">
                        <el-radio-button label="电流">电流</el-radio-button>
                        <el-radio-button label="电压">电压</el-radio-button>
                    </el-radio-group>
                </el-col>
                <el-col :span="12">
                    <el-select v-model="harRate" size="mini" @change="changeHarmonicData">
                        <el-option label="THD" :value="1"/>
                        <el-option v-for="n in 30" :key="n" :label="n+1" :value="n+1"/>
                    </el-select>
                    <span class="text" v-if="harRate === 1">总谐波畸变率（%）趋势</span>
                    <span class="text" v-else>次谐波含量（%）趋势</span>
                    <span class="download" @click="downloadHarmonicData" v-show="tab2 === 1 || tab2 === 4">下载谐波采样数据</span>
                </el-col>
            </el-row>
        </div>

        <el-dialog title="操作记录" :visible.sync="logDialogVisible" width="500px" :close-on-click-modal="false" append-to-body @close="handleCloseLogDialog">
            <div slot="title">
                <div class="equip_title">操作记录</div>
            </div>
            <el-table :data="logTableData" class="logTable">
                <el-table-column prop="nodeName" label="属性名" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="nodeValue" label="属性值" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.nodeValue == 0 ? '关' : scope.row.nodeValue == 1 ? '开' : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="操作结果" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ statusMap.get(scope.row.status) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userName" label="操作用户" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="createTime" label="操作时间" :show-overflow-tooltip="true"></el-table-column>
            </el-table>
            <el-pagination class="pagination-box" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="logTablePageForm.currentPage" :page-sizes="[15, 30, 45, 60]" :page-size="logTablePageForm.pageSize" layout="sizes,prev, pager, next" :total="logTablePageForm.total">
            </el-pagination>
        </el-dialog>
    </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { getDeviceInfo, getMaxLoadAndTime, getRealTimeDeviceData, exportHarmonicRawData } from "@/api/monitoring";
import debounce from 'lodash/debounce';

/********************************************
 * 弹层显示/隐藏：重新请求数据（√），重新渲染（√）
 * 放大/缩小：重新请求数据（×），重新渲染（√）
 * tab1变化：重新请求数据（×），重新渲染（√）
 * tab2变化：重新请求数据（√），重新渲染（√）
 * tab3变化：重新请求数据（×），重新渲染（√）
 **********************************************/

export default {
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        pointId: {
            type: String,
            default: '',
            require: true
        }
    },
    data () {
        return {
            harRate: 1, //谐波次数
            isFullScreen: false,
            chartsWidth: 0,
            chartsHeight: 0,
            myChart: null,
            tab1: 0, // 电参量tab
            tab2: 4, // 时间tab
            tab3: '电流', // 谐波切换电压电流的tab
            title: '',
            generalDay: '',
            period: null, //时
            boxList: [
                { name: '名称：', value: '', prop: 'deviceName' },
                { name: '位置信息：', value: '', prop: 'placeName' },
                { name: '仪表名称：', value: '', prop: 'meterName' },
                { name: '型号：', value: '', prop: 'specification' },
                { name: '所属单位：', value: '', prop: 'ownerName' },
                { name: '额定容量：', value: '', prop: 'ratedLoad' },
                { name: '当前最大负荷和时间：', value: '', prop: 'loadAndTime' },
                { name: '开关状态：', value: null, prop: 'switchStatus' },
            ],
            legendData: [],
            seriesData: [], // 当前graph的数据
            chartsData: {   // meter请求到的所有数据
                'A相电压': [],
                'B相电压': [],
                'C相电压': [],
                'A相电流': [],
                'B相电流': [],
                'C相电流': [],
                'A相有功功率': [],
                'B相有功功率': [],
                'C相有功功率': [],
                '三相有功功率': [],
                'A相无功功率': [],
                'B相无功功率': [],
                'C相无功功率': [],
                '三相无功功率': [],
                '功率因数': [],
                '视在功率': [],
                //谐波
                '电流总谐波畸变率': [],
                '电流2次谐波含有率': [],
                '电流3次谐波含有率': [],
                '电流4次谐波含有率': [],
                '电流5次谐波含有率': [],
                '电流6次谐波含有率': [],
                '电流7次谐波含有率': [],
                '电流8次谐波含有率': [],
                '电流9次谐波含有率': [],
                '电流10次谐波含有率': [],
                '电流11次谐波含有率': [],
                '电流12次谐波含有率': [],
                '电流13次谐波含有率': [],
                '电流14次谐波含有率': [],
                '电流15次谐波含有率': [],
                '电流16次谐波含有率': [],
                '电流17次谐波含有率': [],
                '电流18次谐波含有率': [],
                '电流19次谐波含有率': [],
                '电流20次谐波含有率': [],
                '电流21次谐波含有率': [],
                '电流22次谐波含有率': [],
                '电流23次谐波含有率': [],
                '电流24次谐波含有率': [],
                '电流25次谐波含有率': [],
                '电流26次谐波含有率': [],
                '电流27次谐波含有率': [],
                '电流28次谐波含有率': [],
                '电流29次谐波含有率': [],
                '电流30次谐波含有率': [],
                '电流31次谐波含有率': [],
                '电压总谐波畸变率': [],
                '电压2次谐波含有率': [],
                '电压3次谐波含有率': [],
                '电压4次谐波含有率': [],
                '电压5次谐波含有率': [],
                '电压6次谐波含有率': [],
                '电压7次谐波含有率': [],
                '电压8次谐波含有率': [],
                '电压9次谐波含有率': [],
                '电压10次谐波含有率': [],
                '电压11次谐波含有率': [],
                '电压12次谐波含有率': [],
                '电压13次谐波含有率': [],
                '电压14次谐波含有率': [],
                '电压15次谐波含有率': [],
                '电压16次谐波含有率': [],
                '电压17次谐波含有率': [],
                '电压18次谐波含有率': [],
                '电压19次谐波含有率': [],
                '电压20次谐波含有率': [],
                '电压21次谐波含有率': [],
                '电压22次谐波含有率': [],
                '电压23次谐波含有率': [],
                '电压24次谐波含有率': [],
                '电压25次谐波含有率': [],
                '电压26次谐波含有率': [],
                '电压27次谐波含有率': [],
                '电压28次谐波含有率': [],
                '电压29次谐波含有率': [],
                '电压30次谐波含有率': [],
                '电压31次谐波含有率': [],
            },
            xAxisData: [],// x轴数据，来自后端
            timer: null, // 什么定时器？？
            switchShow: false,
            logDialogVisible: false,
            logTableData: [],
            logTablePageForm: {
                pageSize: 10,
                currentPage: 1,
                total: 0
            },
            statusMap: new Map([
                [0, '发送成功'],
                [1, '操作成功'],
                [2, '操作失败'],
                [9, '发送失败']
            ])
        }
    },
    watch: {
        isFullScreen(val){
            this.initChartsWidthHeight(val);
        },
        // 监视弹层的展示，由于对el-dialog封装了一层，dialog的钩子函数不好使了
        showDialog (val) {
            if(val){
                this.tab1 = 0;
                this.tab2 = 4;
                this.harRate = 1;
                const now = new Date();
                this.period = [this.formatTwo(now.getHours() - 1), this.formatTwo(now.getHours())];
                this.generalDay = now.getFullYear() + '-' + this.formatTwo(now.getMonth() + 1) + '-' + this.formatTwo(now.getDate());
                this.getDeviceInfo();
                this.renderEchartsAfterRequestData();
                this.getMaxLoadAndTime();
            }else{
                clearInterval(this.timer);
                this.boxList = this.$options.data().boxList;
                this.switchShow = false;
                this.isFullScreen = false;
            }
        },
        //note：tab1和tab2不适合用watch，因为不是所有的值变化都需要请求数据，比如关闭弹层时，要将tab2置为初始值
        //note：这里我列出来，只是提醒你不要朝这个方向改。
    },
    computed: {
        ...mapState({
            deviceSwitch: state => state.monitoring.deviceSwitch,
            logTableRes: state => state.monitoring.logTableData,
        })
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        initChartsWidthHeight(isFullScreen){
            if(isFullScreen){
                this.chartsWidth = this.$el.clientWidth * 0.98;
                this.chartsHeight = this.$el.clientHeight * 0.68;
            }else{
                this.chartsWidth = 1060;
                this.chartsHeight = this.$el.clientHeight * 0.49;
            }
        },
        formatTwo(num){
            if(Number.isNaN(num)){
                return '00';
            }else{
                return num < 10 ? '0' + num : '' + num;
            }
        },
        resizeDialog(){
            this.isFullScreen = !this.isFullScreen;
            this.$nextTick(() => {
                this.renderEcharts().resize({ width: this.chartsWidth, height: this.chartsHeight } );
            });
        },
        // 从后端获取charts数据
        async requestFreshData() {
            this.chartsData = {   // meter请求到的所有数据
                'A相电压': [],
                'B相电压': [],
                'C相电压': [],
                'A相电流': [],
                'B相电流': [],
                'C相电流': [],
                'A相有功功率': [],
                'B相有功功率': [],
                'C相有功功率': [],
                '三相有功功率': [],
                'A相无功功率': [],
                'B相无功功率': [],
                'C相无功功率': [],
                '三相无功功率': [],
                '功率因数': [],
                '视在功率': [],
                //谐波
                '电流总谐波畸变率': [],
                '电流2次谐波含有率': [],
                '电流3次谐波含有率': [],
                '电流4次谐波含有率': [],
                '电流5次谐波含有率': [],
                '电流6次谐波含有率': [],
                '电流7次谐波含有率': [],
                '电流8次谐波含有率': [],
                '电流9次谐波含有率': [],
                '电流10次谐波含有率': [],
                '电流11次谐波含有率': [],
                '电流12次谐波含有率': [],
                '电流13次谐波含有率': [],
                '电流14次谐波含有率': [],
                '电流15次谐波含有率': [],
                '电流16次谐波含有率': [],
                '电流17次谐波含有率': [],
                '电流18次谐波含有率': [],
                '电流19次谐波含有率': [],
                '电流20次谐波含有率': [],
                '电流21次谐波含有率': [],
                '电流22次谐波含有率': [],
                '电流23次谐波含有率': [],
                '电流24次谐波含有率': [],
                '电流25次谐波含有率': [],
                '电流26次谐波含有率': [],
                '电流27次谐波含有率': [],
                '电流28次谐波含有率': [],
                '电流29次谐波含有率': [],
                '电流30次谐波含有率': [],
                '电流31次谐波含有率': [],
                '电压总谐波畸变率': [],
                '电压2次谐波含有率': [],
                '电压3次谐波含有率': [],
                '电压4次谐波含有率': [],
                '电压5次谐波含有率': [],
                '电压6次谐波含有率': [],
                '电压7次谐波含有率': [],
                '电压8次谐波含有率': [],
                '电压9次谐波含有率': [],
                '电压10次谐波含有率': [],
                '电压11次谐波含有率': [],
                '电压12次谐波含有率': [],
                '电压13次谐波含有率': [],
                '电压14次谐波含有率': [],
                '电压15次谐波含有率': [],
                '电压16次谐波含有率': [],
                '电压17次谐波含有率': [],
                '电压18次谐波含有率': [],
                '电压19次谐波含有率': [],
                '电压20次谐波含有率': [],
                '电压21次谐波含有率': [],
                '电压22次谐波含有率': [],
                '电压23次谐波含有率': [],
                '电压24次谐波含有率': [],
                '电压25次谐波含有率': [],
                '电压26次谐波含有率': [],
                '电压27次谐波含有率': [],
                '电压28次谐波含有率': [],
                '电压29次谐波含有率': [],
                '电压30次谐波含有率': [],
                '电压31次谐波含有率': [],
            };
            const params = {
                meterId: this.pointId,
                datetype: this.tab2,
                date: this.tab2 === 4 ? `${this.generalDay} ${this.period[0]}-${this.period[1]}` : this.generalDay
            };
            const res = await getRealTimeDeviceData(params);
            if(res.errorCode === 0 && res.data.graphData.length > 0){
                let datas = res.data.graphData;
                datas.forEach(item => {
                    this.chartsData[item.name].length = 0;
                    item.data.forEach(val => {
                        this.chartsData[item.name].push(val);
                    });
                });
                this.xAxisData = datas[0].xdata;
                //console.log('成功请求到数据', this.chartsData)
            }
        },
        // 设备信息
        async getDeviceInfo() {
            let res = await getDeviceInfo(this.pointId);
            if(res.errorCode === 0 && res.data){
                const deviceInfo = res.data;
                this.boxList.forEach(item => {
                    this.title = deviceInfo.deviceName;
                    item.value = deviceInfo[item.prop];
                    if (item.prop === 'switchStatus' && item.value !== null) {
                        this.switchShow = true;
                        const that = this;
                        this.timer = setInterval(function() {
                            that.getDeviceSwitch();
                        }, 10000)
                    } else {
                        this.switchShow = false;
                        clearInterval(this.timer);
                    }
                })
            }
        },
        // 当前最大负荷和时间
        async getMaxLoadAndTime(){
            let res = await getMaxLoadAndTime(this.pointId);
            if(res.errorCode === 0 && res.data){
                const data = res.data;
                if (data?.maxGl && data?.createTime) {
                    this.boxList.find(item => item.prop === 'loadAndTime').value = `${data.maxGl}kW ${data.createTime}`;
                }
            }
        },
        closeDialog() {
            clearInterval(this.timer);
            this.$emit('closeDialog')
            this.tab1 = 0;
            this.tab2 = 4;
            this.tab3 = '电流';
            this.generalDay = '';
            this.boxList = this.$options.data().boxList;
            this.switchShow = false;
            this.isFullScreen = false;
            if(this.myChart){
                this.myChart.dispose();
            }
        },
        changeTabOne (index) {
            this.tab1 = index;
            this.initChartsWidthHeight(this.isFullScreen);
            this.renderEcharts();
        },
        changeTabTwo (index) {
            if (this.tab2 === index) {
                return;
            }
            this.tab2 = index;
            let date = new Date();
            const year = date.getFullYear(),
                  month = date.getMonth() + 1,
                  day = date.getDate();
            if (index === 1) {
                this.generalDay = year + '-' + this.formatTwo(month) + '-' + this.formatTwo(day);
            } else if (index === 2) {
                this.generalDay = year + '-' + this.formatTwo(month);
            } else if (index === 3) {
                this.generalDay = year + '';
            } else if (index === 4) {
                this.generalDay = year + '-' + this.formatTwo(month) + '-' + this.formatTwo(day);
                this.period = this.period ? this.period : [this.formatTwo(date.getHours()), this.formatTwo(date.getHours())];
            }
            this.renderEchartsAfterRequestData();
        },
        changeHarmonicData () {
            this.renderEcharts();
        },
        handleChangeSwitch (val) {
            this.$prompt('请输入密码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'password',
                inputPattern: /^.+$/,
                inputErrorMessage: '请输入密码'
            }).then(({ value }) => {
                const params = {
                    meterId: this.pointId,
                    password: value,
                    status: val
                }
                this.$store.dispatch('setDeviceSwitch', params).then(() => {
                    // console.log(this.deviceSwitch)
                    if (this.deviceSwitch.errorCode == 0) {
                        this.$message.success('设置成功')
                    } else {
                        this.boxList.forEach(item => {
                            if (item.prop == 'switchStatus') {
                                item.value = item.value == 1 ? 0 : 1
                            }
                        })
                        this.$message.error(this.deviceSwitch.message)
                    }
                })
            }).catch(() => {
                this.boxList.forEach(item => {
                    if (item.prop == 'switchStatus') {
                        item.value = item.value == 1 ? 0 : 1
                    }
                })
            })

        },
        getDeviceSwitch () {
            const params = {
                meterId: this.pointId
            }
            this.$store.dispatch('getDeviceSwitch', params).then(() => {
                if (this.deviceSwitch.data.errorCode == 0) {
                    this.boxList.forEach(item => {
                        if (item.prop == 'switchStatus') {
                            item.value = this.deviceSwitch.data.switchStatus
                        }
                    })
                }
            })
        },
        handleCloseLogDialog () {
            this.logDialogVisible = false,
                this.logTablePageForm = this.$options.data().logTablePageForm
        },
        getLogTableData () {
            this.logDialogVisible = true;
            const params = {
                meterId: this.pointId,
                pageNum: this.logTablePageForm.currentPage,
                pageSize: this.logTablePageForm.pageSize,
                name: '',
                startTime: '',
                status: ''
            };
            this.$store.dispatch('getLogTableData', params).then(() => {
                if (this.logTableRes.errorCode === 0) {
                    this.logTableData = this.logTableRes.data.records;
                    this.logTablePageForm.total = this.logTableRes.data.total;
                }
            })
        },
        handleSizeChange (val) {
            this.logTablePageForm.pageSize = val
            this.logTablePageForm.currentPage = 1
            this.getLogTableData()
        },
        handleCurrentChange (val) {
            this.logTablePageForm.currentPage = val
            this.getLogTableData()
        },
        // 渲染echarts
        renderEcharts() {
            console.log('echarts重新渲染！')
            if(this.myChart){
                this.myChart.dispose();
            }
            let chart = this.$echarts.init(this.$refs.myChart, null, {width: this.chartsWidth, height: this.chartsHeight});
            this.buildLegendAndSeries();
            this.seriesData.forEach(s => s.data = this.chartsData[s.name]);
            chart.setOption(this.buildChartsOption());
            this.myChart = chart;
            setTimeout(() => {
                this.$nextTick(() => {
                    if(this.isFullScreen){
                        this.myChart.resize({width: this.chartsWidth, height: this.chartsHeight});
                    }
                });
            });
            return chart;
        },
        buildChartsOption(){
            return {
                color: ['#3673E8','#61B8FF','#F5B763','#1de1e2'],
                tooltip: {
                    trigger: 'axis',
                    /*formatter: params => {
                        console.log(params)
                    },*/
                },
                legend: {
                    textStyle: {
                        color: '#fff'
                    },
                    bottom: 20,
                    data: this.legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '20%',
                    containLabel: true
                },
                xAxis: {
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(43, 113, 176, 0.45)'
                        }
                    },
                    axisLabel: {
                        color: '#fff'
                    },
                    data: this.xAxisData
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#fff',
                        // y坐标带单位
                        formatter: val => {
                            if(this.tab1 === 0){
                                if(val < 50){
                                    return val + " kV";
                                }else{
                                    return val + " V";
                                }
                            }
                            else if(this.tab1 === 1){
                                return val + " A";
                            }
                            else if(this.tab1 === 2){
                                return val + " kW";
                            }
                            else if(this.tab1 === 3){
                                return val + " kW";
                            }
                            else if(this.tab1 === 4){
                                return val + " %";
                            }
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dash',
                            color: 'rgba(0, 0, 0, 0.15)'
                        }
                    },
                },
                series: this.seriesData,
                dataZoom: [
                    { type: 'slider', show: true, height: 20, bottom: 50, }
                ],
            }
        },
        // 请求数据后重新渲染echarts
        renderEchartsAfterRequestData(){
            this.$nextTick(() => {
                let chart = this.$echarts.init(this.$refs.myChart);
                this.buildLegendAndSeries();
                chart.showLoading({
                    text: '数据加载中，请稍候...',
                    color: '#ffc96c',
                    textColor: '#ffc96c',
                    maskColor: 'rgba(12, 55, 94, 0.56)'
                });
                this.requestFreshData()
                    .then(() => {
                        chart.hideLoading();
                        this.seriesData.forEach(s => s.data = this.chartsData[s.name]);
                        chart.setOption(this.buildChartsOption());
                        this.myChart = chart;
                    })
                    .then(() => {
                        if(this.isFullScreen){
                            this.myChart.resize({width: this.chartsWidth, height: this.chartsHeight});
                        }
                    });
            });
        },
        // 构建echarts option数据
        buildLegendAndSeries () {
            const markPointData = [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
            ];
            if (this.tab1 === 0) {
                this.legendData = ['A相电压', 'B相电压', 'C相电压'];
                this.seriesData = [
                    {
                        name: this.legendData[0],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        data: []
                    },
                    {
                        name: this.legendData[1],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(97, 184, 255, 0.2)" },
                                { offset: 1, color: "rgba(97, 184, 255, 0)" }
                            ])
                        },
                        data: []
                    },
                    {
                        name: this.legendData[2],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(245, 183, 99, 0.2)" },
                                { offset: 1, color: "rgba(245, 183, 99, 0)" }
                            ])
                        },
                        data: []
                    }
                ];
            }
            else if (this.tab1 === 1) {
                this.legendData = ['A相电流', 'B相电流', 'C相电流'];
                this.seriesData = [
                    {
                        name: this.legendData[0],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        data: []
                    },
                    {
                        name: this.legendData[1],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(97, 184, 255, 0.2)" },
                                { offset: 1, color: "rgba(97, 184, 255, 0)" }
                            ])
                        },
                        data: []
                    },
                    {
                        name: this.legendData[2],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(245, 183, 99, 0.2)" },
                                { offset: 1, color: "rgba(245, 183, 99, 0)" }
                            ])
                        },
                        data: []
                    }
                ]
            }
            else if (this.tab1 === 2) {
                this.legendData = ['A相有功功率', 'B相有功功率', 'C相有功功率', '三相有功功率'];
                this.seriesData = [
                    {
                        name: this.legendData[0],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        markPoint: { data: markPointData },
                        data: []
                    },
                    {
                        name: this.legendData[1],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        markPoint: { data: markPointData },
                        data: []
                    },
                    {
                        name: this.legendData[2],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        markPoint: { data: markPointData },
                        data: []
                    },
                    {
                        name: this.legendData[3],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        markPoint: { data: markPointData },
                        data: []
                    },
                ];
            }
            else if (this.tab1 === 3) {
                this.legendData = ['A相无功功率', 'B相无功功率', 'C相无功功率', '三相无功功率'];
                this.seriesData = [
                    {
                        name: this.legendData[0],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        markPoint: { data: markPointData },
                        data: []
                    },
                    {
                        name: this.legendData[1],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        markPoint: { data: markPointData },
                        data: []
                    },
                    {
                        name: this.legendData[2],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        markPoint: { data: markPointData },
                        data: []
                    },
                    {
                        name: this.legendData[3],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(54, 115, 232, 0.2)" },
                                { offset: 1, color: "rgba(54, 115, 232, 0)" }
                            ])
                        },
                        markPoint: { data: markPointData },
                        data: []
                    },
                ];
            }
            else if (this.tab1 === 4) {
                this.legendData = [];
                this.seriesData = [{
                    name: this.tab3 + '总谐波畸变率',
                    type: 'line',
                    smooth: true,
                    areaStyle: {
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: "rgba(255,43,10,0.93)" },
                            { offset: 1, color: "rgba(54, 115, 232, 0)" }
                        ])
                    },
                    markPoint: { data: markPointData },
                    data: []
                }];
                let name = this.harRate === 1 ? this.tab3 + '总谐波畸变率' : this.tab3 + this.harRate + '次谐波含有率';
                this.seriesData.forEach(item => item.name = name);
            }
        },
        downloadHarmonicData: debounce(function(){
            const params = {
                meterId: this.pointId,
                datetype: this.tab2,
                date: this.tab2 === 4 ? `${this.generalDay} ${this.period[0]}-${this.period[1]}` : this.generalDay
            };
            exportHarmonicRawData(params).then(res => {
                const url = URL.createObjectURL(new Blob([res], {
                    type: 'application/vnd.ms-excel,charset=utf-8'
                }));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.download = this.title + '-谐波采样数据.xls';
                a.href = url;
                a.click();
                URL.revokeObjectURL(a.href);
            });
        }, 2000),
    },
};
</script>
<style lang="less">
.time-picker-content {
    .el-time-spinner {
        .el-time-spinner__wrapper:nth-child(2) {
            display: none;
        }
    }
}
.harmonic-wave{
    .el-select{
        width: 8rem;
    }
    .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        background-color: #027969 !important;
        border: none;
    }
    .el-radio-button__inner{
        background: #084b89 !important;
        border: none;
    }
    .el-radio-button:first-child .el-radio-button__inner{
        border-left: none;
    }
    .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        box-shadow: none;
    }
    span.text{
        color: #ffffff;
        margin-left: 1rem;
    }
    span.download{
        float: right;
        color: #50c6df;
        margin-right: 3rem;
        cursor: pointer;
    }

}
</style>
<style lang="less" scoped>
.detail_box {
    .box_ul {
        color: rgba(255, 255, 255, 0.85);
        display: flex;
        flex-wrap: wrap;
        .box_li {
            width: 25%;
            display: flex;
            margin-bottom: 2.4rem;
            font-size: 1.4rem;
            div {
                width: 9rem;
                text-align: right;
            }
            p {
                flex: 1;
            }
            &.long-item {
                margin-left: -10px;
                width: 50%;
                div {
                    width: 17rem;
                }
            }
            &.hide-item {
                display: none;
            }
        }
        .log-btn {
            margin-bottom: 2.4rem;
            &:hover,
            &:active,
            &:focus {
                border: 1px solid #027969;
            }
        }
    }
    .box_charts {
        position: relative;
        color: #fff;
        height: 50vh;
        .charts{
            width: 1060px;
            height: 49vh;
        }
    }
}
.date {
    position: absolute;
    top: 0;
    right: 18.8rem;
    display: flex;
    flex-direction: row-reverse;
    width: 34rem;
    /deep/.el-date-editor.el-input {
        // width: 100%;
    }
    .periodPicker {
        margin-left: 1rem;
        /deep/.el-input__inner {
            height: 2.8rem;
            line-height: 2.8rem;
        }
        /deep/.el-input__icon {
            line-height: 2.8rem;
        }
    }
}
.dateChoose {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0.2rem;
    // border: 0.1rem solid #004579;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
    .btnDark {
        height: 3rem;
        width: 4.6rem;
        background-color: #0b375e;
        font-size: 1.4rem;
        padding: 0;
        margin: 0;
        cursor: pointer;
        line-height: 3rem;
        text-align: center;
    }
    .btnGreen {
        height: 3rem;
        width: 4.6rem;
        background-color: #027969;
        font-size: 1.4rem;
        padding: 0;
        margin: 0;
        cursor: pointer;
        line-height: 3rem;
        text-align: center;
    }
}
.logTable {
    width: 100%;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    position: static;
    ::v-deep tr {
        background: none;
    }
    ::v-deep.el-table__body-wrapper {
        height: calc(100% - 48px);
        overflow-y: auto;
    }
    ::v-deep.el-table__body tr:hover > td.el-table__cell {
        background: none;
    }
    ::v-deep thead {
        color: #fff;
    }
    ::v-deep.el-table--enable-row-hover
        .el-table__body
        tr:hover
        > td.el-table__cell {
        background: none;
    }
    ::v-deep .el-table__header {
        tr th {
            border-bottom: 1px solid rgba(108, 184, 255, 0.3);
            /* background: rgba(52, 150, 241, 0.15); */
            background: #0c375d !important;
            border-right: 1px solid #0d3050;
        }
        tr th:last-child {
            border-right: none;
        }
    }
    td.el-table__cell {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}
.pagination-box {
    // float: right;
    margin-top: 2.4rem;
    text-align: right;
}
</style>
<style lang="less">

.styleWid {
    .el-input__inner {
        padding-right: 10px !important;
    }
}
.el-button--primary {
    color: #fff;
    border-color: #027969;
    background-color: #027969;
}
.el-switch__label {
    color: #fff;
}
</style>