<template>
  <div>
    

    <!-- --------------------------------------------------------------------  补充大板块 ---------------------------------------------------------------------->
    <div class="blockSup">
      <div :class="styleSup" >
        <img src="@/assets/FullView/charge.png" class="picLeft" alt="">
        <div class="wordLeft">15.02</div>
      </div>
      
      <div :class="styleSup2">
        <div class="iconInner">
          <img src="@/assets/FullView/icon5.png" class="iconInnerImg" alt="">
          <div class="iconInnerWord">辐照度</div>
        </div>
        <div class="iconInnerWord2">258.0 <span class="innerSpan">W/m²</span></div>
      </div>


      <div :class="styleSup3">
        <div class="iconInner">
          <img src="@/assets/FullView/icon6.png" class="iconInnerImg" alt="">
          <div class="iconInnerWord">温湿度</div>
        </div>
        <div class="iconInnerWord2">19.2 <span class="innerSpan">℃</span></div>
        <div class="iconInnerWord3">56.44 <span class="innerSpan">%RH</span></div>
      </div>

      <div :class="styleSup4">
        <div class="iconInner">
          <img src="@/assets/FullView/icon7.png" class="iconInnerImg" alt="">
          <div class="iconInnerWord">风速</div>
        </div>
        <div class="iconInnerWord2">258.0 <span class="innerSpan">m/s</span></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
    name:"FaDianLeft",
    data(){return{
  
  
  // 补充板块的样式
  styleSup:'blockSupInsider',
  styleSup2:'blockSupInsider2',
  styleSup3:'blockSupInsider3',
  styleSup4:'blockSupInsider4',

    }},


    mounted(){
    

    },


    methods:{
        // 获取屏幕比例，使echarts自适应
        fontSize(res){
            let docEl = document.documentElement,
            clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
            if (!clientWidth) return;
            let fontSize = (clientWidth / 192);
            return res*fontSize;
        },

        // 渲染
        show(){
          this.styleSup='blockSupInsider'
          this.styleSup2='blockSupInsider2'
          this.styleSup3='blockSupInsider3'
          this.styleSup4='blockSupInsider4'

          // 补充板块的展示动画
          setTimeout(()=>{
            this.styleSup='blockSupInsiderShow'
            setTimeout(()=>{this.styleSup2='blockSupInsiderShow2'},100)
            setTimeout(()=>{this.styleSup3='blockSupInsiderShow3'},200)
            setTimeout(()=>{this.styleSup4='blockSupInsiderShow4'},300)
          },1000)

        },
    }
}
</script>

<style lang='less' scoped>
//  ----------------------------------------------------------------------------背景图部分 ----------------------------------------------------------------------
.FullView {
  height:108rem;
  width: 192rem;
  position: absolute;
  top: -8.8rem;
  left: -18.4rem;
  background-color: #16294C;
  .bgimg{
    // height:108rem;
    // width: 192rem;
    height: 129rem;
    width: 230.4rem;

    position: absolute;
    top: 4.4rem;
    left: 0rem;
  }
  .bgimgTitle{
    height: 8rem;
    width: 192rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bgimgHubei0{
    width: 10.82rem;
    height: 6.93rem;
    position: absolute;
    top: 46rem;
    right: 90rem;
    opacity: 0;
  }
  .bgimgHubei{
    width: 108.2rem;
    height: 69.3rem;
    position: absolute;
    top: 10.4rem;
    right: 42rem;
    opacity: 1;
    transition: all 1s;
  }
  .blockTitle{
    height: 5.1rem;
    width: 37.8rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .blockTitleWord{
    position: absolute;
    top: 1.4rem;
    left: 4rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1.6rem;
    color: #fff;
  }

}


//  ----------------------------------------------------------------------------左侧板块一 ----------------------------------------------------------------------
.leftOne{
  height: 28.6rem;
  width: 37.8rem;
  position: absolute;
  left: 3.2rem;
  top: 2.4rem;
  .chartLeftOne{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 23.5rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}


//  ----------------------------------------------------------------------------左侧板块二 ----------------------------------------------------------------------
.leftTwo{
  height: 28.6rem;
  width: 37.8rem;
  position: absolute;
  left: 3.2rem;
  top: 32.6rem;
  .chartLeftTwo{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 23.5rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}

//  ----------------------------------------------------------------------------左侧板块三 ----------------------------------------------------------------------
.leftThree{
  height: 34.9rem;
  width: 37.8rem;
  position: absolute;
  left: 3.2rem;
  top: 62.7rem;
  .chartLeftThree{
     background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 29.8rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
    padding-top: -0.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    box-sizing: border-box;
    .innerCard{
      height: 3.6rem;
      width: 100%;
      margin-top: 2.2rem;
      position: relative;
      top: 0;
      left: 0;
      .wordPlace{
        height: 2rem;
        color: #E6F7FF;
        font-size: 1.4rem;
        line-height: 2rem;
        .left{
          float: left;
        }
        .right{
          float: right;
        }
      }
      .barPlace{
        margin: 0.2rem 0;
        height: 1rem;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
      .barGet{
        height: 1rem;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #F18F1C 0%, #EAE342 100%); 
      }
      .barLine{
        width: 0.2rem;
        height: 1.4rem;
        background-color: #fff;
        position: absolute;
        top: -0.2rem;
      }
    }
  }
}

// -----------------------------------------------------------------------------补充大板块-------------------------------------------
.blockSup{
  position: absolute;
  top: 0;
  left: 2.4rem;
  width: 13.8rem;
  height: 108rem;
  overflow: hidden;
}

// 封装的css 透明卡片##############################################################
.blockSupInsider{
  position: absolute;
  top: 2.4rem;
  left: -14rem;
  width: 13.2rem;
  height: 15.5rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  &::after{
    content: "";
    width: 13.2rem;
    height: 15.5rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-left: none;
    border-top: none;
  }
}

.blockSupInsiderShow{
  position: absolute;
  top: 2.4rem;
  left: 0rem;
  width: 13.2rem;
  height: 15.5rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
  &::after{
    content: "";
    width: 13.2rem;
    height: 15.5rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;

    border-left: none;
    border-top: none;
  }

}


// 封装的css 透明卡片2##############################################################
.blockSupInsider2{
  position: absolute;
  top: 20.3rem;
  left: -14rem;
  width: 13.2rem;
  height: 8.4rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  &::after{
    content: "";
    width: 13.2rem;
    height: 8.4rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-left: none;
    border-top: none;
  }
}

.blockSupInsiderShow2{
  position: absolute;
  top: 20.3rem;
  left: 0rem;
  width: 13.2rem;
  height: 8.4rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
  &::after{
    content: "";
    width: 13.2rem;
    height: 8.4rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;

    border-left: none;
    border-top: none;
  }

}



// 封装的css 透明卡片3##############################################################
.blockSupInsider3{
  position: absolute;
  top: 30.7rem;
  left: -14rem;
  width: 13.2rem;
  height: 12.1rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  &::after{
    content: "";
    width: 13.2rem;
    height: 12.1rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-left: none;
    border-top: none;
  }
}

.blockSupInsiderShow3{
  position: absolute;
  top: 30.7rem;
  left: 0rem;
  width: 13.2rem;
  height: 12.1rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
  &::after{
    content: "";
    width: 13.2rem;
    height: 12.1rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;

    border-left: none;
    border-top: none;
  }

}

// 封装的css 透明卡片4##############################################################
.blockSupInsider4{
  position: absolute;
  top: 44.8rem;
  left: -14rem;
  width: 13.2rem;
  height: 8.4rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  &::after{
    content: "";
    width: 13.2rem;
    height: 8.4rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-left: none;
    border-top: none;
  }
}

.blockSupInsiderShow4{
  position: absolute;
  top: 44.8rem;
  left: 0rem;
  width: 13.2rem;
  height: 8.4rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
  &::after{
    content: "";
    width: 13.2rem;
    height: 8.4rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;

    border-left: none;
    border-top: none;
  }

}






// 卡片内部的公用样式
.iconInner{
  height: 2.4rem;
  width: 14rem;
  position: absolute;
  top: 1.5rem;
  left: 1.6rem;
  .iconInnerImg{
    height: 2.4rem;
    width: 2.4rem;
    float: left;
  }
  .iconInnerWord{
    height: 2.4rem;
    line-height: 2.4rem;
    font-size: 1.6rem;
    color: #18FEFE;
    text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
    float: left;
    margin-left: 0.5rem;
    font-weight: 500;
  }
}
.iconInnerWord2{
    height: 2.9rem;
    line-height: 2.9rem;
    font-size: 2.4rem;
    color: #FBFBFB;
    text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
    font-weight: 500;
    position: absolute;
    top: 4.2rem;
    left: 1.9rem;
    .innerSpan{
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.65);
      font-weight: 100;
      text-shadow: none;
      
    }
  }
.iconInnerWord3{
    height: 2.9rem;
    line-height: 2.9rem;
    font-size: 2.4rem;
    color: #FBFBFB;
    text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
    font-weight: 500;
    position: absolute;
    top: 7.8rem;
    left: 1.9rem;
    .innerSpan{
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.65);
      font-weight: 100;
      text-shadow: none;
      
    }
  }





.picLeft{
  height: 11rem;
  width: 10.7rem;
  position: absolute;
  left: 1.6rem;
  top: 2.4rem;
}
.wordLeft{
  height: 2.9rem;
  width: 13.2rem;
  position: absolute;
  top: 5.3rem;
  left: 0rem;
  color:#FBFBFB;
  font-size: 2.4rem;
  text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
  text-align: center;
}
</style>