<template>
  <div class="DialogForChoosePerson">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div class="equip_title">选择报修人</div>
      </div>
      <!-- 表格内容区域 #################################################################################### -->
      <div class="contentPlace">
         <!-- 顶部栏位置#################### -->
    <div class="topBar">
      <div>
        <el-input
          size="small"
          placeholder="请输入用户名"
          v-model="searchValue"
          @keyup.enter.native="search"
          style="width:22rem;"
          clearable
          @clear="search"
        ></el-input>
     
        <button class="btn_save" @click="search">查 询</button>
      </div>
  
    </div>

    <!-- 中部表格位置########################## -->
    <div class="userTablePlace">
          <div class="table">
              <el-table :data="tableData" stripe:false height="57.8rem" 
                        border:false style="width: 100%"
                        :header-cell-style="headerClass"                    
                        :cell-style="cellStyle">
              
                <el-table-column class="tab" prop="userNo" label="编号" min-width="9%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="name" label="姓名" min-width="10%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="username" label="账号" min-width="13%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="sex" label="性别" min-width="8%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="mobile" label="手机号" min-width="11%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="deptName" label="部门" min-width="11%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="positionName" label="职位" min-width="12%"  :show-overflow-tooltip="true"> </el-table-column>
                 <!-- 操作部分 -->
                <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="chooseFinish(tableData[scope.$index])">选中</el-button>                   
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页器部分 -->
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumForPagenation"
              >
              </el-pagination>
            </div>
    </div>


      </div>

    
   
    </el-dialog>

  
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";

export default {
  name: "DialogForChoosePerson",
  mixins: [tableBg],
  data() {
    return {
      // 小组id
      groupId:'',
      // 展示判断
      dialogFormVisible: false,
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 双向绑定的搜索数据
      searchValue: "",  
      // 双向绑定的用户状态
      userState: "",
      // 用户状态列表
      userStateList: [
        { label: "冻结", value: "0" },
        { label: "正常", value: "1" },
        { label: "全部", value: " " }
      ],
      // 表格数据
      tableData: [],
      // 选中列表
      chooseList:[],
  
    };
  },

  methods: {
    // dialog关闭的回调
    closeDialog() {
     this.tableData=[]
     this.chooseList=[]
    },

    // 取消弹框
    handleCancle() {   
      this.dialogFormVisible = false;
      this.tableData=[]
      this.chooseList=[]
    },

  
   
    //父组件点击触发
    show(val) {
      this.dialogFormVisible = true
      this.groupId = val
      this.refreshThePage()
    },

  
    // 选择报修人
    chooseFinish(val){
      this.$parent.getMyData(val)
      this.dialogFormVisible = false
    },
 
    


    // FORM
     // 刷新页面
    refreshThePage() {
      this.$store
        .dispatch("getUserPageData", {
          current: this.currentPage,
          size: this.pageSize,
          username: this.searchValue,
          status: this.userState
        })
        .then(() => {
          this.tableData = this.userPageData;
          this.totalNumForPagenation = this.userTotal;
          // 制造false
          for(let i=0;i<this.tableData.length;i++){
            this.tableData[i].ifChecked = false
          }
        });
    },
    // 点击搜索或者回车
    search() {
      this.currentPage = 1;
      this.tableData=[]
      this.refreshThePage();
    },
    
    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.tableData=[]
      this.refreshThePage();
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val;
      this.tableData=[]
      this.refreshThePage();
   
    },

    //选中和取消选中
    changeCheckBox(valNew,index){
      if(valNew){
        this.chooseList.push(this.tableData[index].userId)        
      }else{
        this.chooseList.splice(this.chooseList.findIndex(item => item ===this.tableData[index].userId),1)
      }
      
      
    }


  },
  //处理state数据
  computed: {
    ...mapState({
      userPageData: state => {
        return state.system.userPageData.data.records;
      },
      userTotal: state => {
        return state.system.userPageData.data.total;
      },
      ifAddMemberToGroupSuccess: state => {return state.repair.ifAddMemberToGroupSuccess.errorCode;},
      ifAddMemberToGroupError: state => {return state.repair.ifAddMemberToGroupSuccess.message;},
    })
  }

 
};




</script>

<style lang="less" scoped>
.btn_save{
  margin-left: 1rem;
}
.chooseBtn{
  background-color: #027969;
  color: #dbe0e5;
  border: none;
  cursor: pointer;
  border-radius: 0.2rem;
}
.DialogForChoosePerson{
  overflow: hidden;
  .el-dialog__body{
    height: 60rem;
  }

.contentPlace{
  height: 65rem;
  width: 153rem;
  position: relative;
  top: 0;
  left: 0;
}


  // 顶部样式##################################################################
.topBar {
  display: flex;
  align-items: center;
  // padding-left: 2rem;
  box-sizing: border-box;
  height: 8rem;
  width: 153rem;
  // background-color: #0d3050;
  border-radius: 0.4rem;
  position: absolute;
  top: -4rem;
  left: 0;
  overflow: hidden;
  .searchInput {
    height: 3.2rem;
    width: 22.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
    background-color: #0c375e;
    border: 1px solid #004579;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    text-indent: 1.4rem;
    &:focus {
      border: 0.1rem solid #008080;
    }
  }

  button {
    height: 3.2rem;
    width: 7.4rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .btn1 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 26rem;
  }
  .btn2 {
    background-color: #027969;
    position: absolute;
    top: 2.4rem;
    left: 158.7rem;
    width: 10.2rem;
  }
  .businessType {
    height: 3.2rem;
    width: 20.8rem;
    position: absolute;
    left: 36rem;
    top: 2.4rem;
  }
  .businessSelector {
    margin: 0 2rem;
  }
}

// 中部表格样式##################################################################
.userTablePlace {
  height: 65rem;
  width: 148.2rem;
  position: absolute;
  top: 3rem;
  left: -2rem;
  overflow: hidden;
  // background-color: #0d3050;
  .bread {
    color: #fff;
    height: 5rem;
    width: 142.8rem;
    line-height: 5rem;
    color: #fff;
    font-size: 1.4rem;
    text-indent: 2.4rem;
  }
  .table {
    height: 57.2rem;
    width: 166.4rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0c375e;
    }
    .el-table__cell {
      background-color: #0c375e;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
// 树所在的区域###################
.treePlace{
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 80rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  
  
  // border: #071828 0.2rem solid;
}
.treePlaceGoAway{
  // opacity: 0;
  display: none;
  // position:relative;
  // top: -54rem;
  // left: 80rem;
  height: 0;
  width: 26rem;
  overflow: hidden;
  // display: none;
  transition: height 0.3s;  
}

.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}
</style>
<style lang="less">
.EquipmentDialogOne{
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  .el-table::before {
      opacity: 0;
  }
}

</style>



<style lang='less'>
// 公共样式！谨防代码污染！#########################################
.DialogForChoosePerson {
  .table {
    height: 57.2rem;
    width: 148rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0c375e;
    }
    .el-table__cell {
      background-color: #0c375e;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }

  .el-table, .el-table__expanded-cell {
    background-color: #0b3d6b;
}


.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #027969;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
}
.el-checkbox.is-bordered.is-checked {
    border-color: #027969;
}


}
</style>