<template>
  <div class="PatrolGroupSetting">
      <!-- 穿梭框左侧################################################################################################################ -->
      <div class="leftPart">
        <!-- 顶部 -->
          <div class="topPlace">
              <button class="btn" @click="addGroup">新增小组</button>
      
          </div>
          <!-- 表格部分 -->
          <div class="table">
              <el-table :data="tableLeftData" stripe:false height="57.8rem" 
                        border:false style="width: 100%"
                        :header-cell-style="headerClass"                    
                        :cell-style="cellStyle">
                <el-table-column class="tab" label="选择" min-width="19%">
                  <template slot-scope="scope">
                    <el-radio @change="handelChangeRadio(tableLeftData[scope.$index].id)" size="mini" v-model="radioValue" :label="tableLeftData[scope.$index].id" border>查看小组</el-radio>
                  </template>           
                </el-table-column>
                <el-table-column class="tab" prop="name" label="组名称" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>

                <!-- 操作部分 -->
                <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleEditLeft(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" @click="handleDeleteLeft(scope.$index, scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
          </div>
            <!-- 分页器部分 -->
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChangeLeft"
                @current-change="handleCurrentChangeLeft"
                :current-page="currentPageLeft"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageSizeLeft"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumForLeftPagenation"
              >
              </el-pagination>
            </div>
      </div>
      <!-- 线 -->
      <div class="middleLine"></div>
      <!-- 穿梭框右侧 ####################################################################################################-->
      <div class="rightPart">
        <!-- 顶部 -->
          <div class="topPlace">
              <button @click="addMember" class="btn">添加组员</button>
          </div>
         <!-- 表格部分 -->
          <div class="table">
              <el-table :data="tableRightData" stripe:false height="57.8rem" 
                        border:false style="width: 100%"
                        :header-cell-style="headerClass"                    
                        :cell-style="cellStyle">
                <el-table-column class="tab" label="设为组长" min-width="19%">
                  <template slot-scope="scope">
                    <el-radio @change="handelChangeRadioRight(tableRightData[scope.$index])" size="mini" v-model="radioValueRight" :label="tableRightData[scope.$index].userId" border>设为组长</el-radio>
                  </template>           
                </el-table-column>
                <el-table-column class="tab" prop="name" label="姓名" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column class="tab" prop="username" label="用户名" min-width="20%"  :show-overflow-tooltip="true"> </el-table-column>
                <!-- 操作部分 -->
                <el-table-column label="操作" min-width="14%">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleDeleteRight(scope.$index, scope.row)">移出小组</el-button>
                  </template>
                </el-table-column>
              </el-table>
          </div>
            <!-- 分页器部分 -->
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChangeRight"
                @current-change="handleCurrentChangeRight"
                :current-page="currentPageRight"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="pageSizeRight"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNumForRightPagenation"
              >
              </el-pagination>
            </div>
      </div>

      <!-- 弹层位置########################################################################################################## -->
      <DialogAddGroup ref="addGroupRef"/>
      <DialogForAddMembers ref="addMemberRef"/>
  </div>
</template>

<script>
import DialogForAddMembers from './components/DialogForAddMembers.vue'
import DialogAddGroup from './components/DialogAddGroup.vue'
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
export default {
    name:'PatrolGroupSetting',
    mixins: [tableBg],
    components:{DialogAddGroup,DialogForAddMembers},
    data(){return{
      // 选择框的选择数据（左侧）
      radioValue:'',
      // 选择的小组id
      choosenId:'',
      //的分页数据（左侧）
      tableLeftData:[],
      // 的总数（左侧）
      totalNumForLeftPagenation:0,
      // 双向绑定--每页的个数（左侧）
      pageSizeLeft: 10,
      // 双向绑定--当前页（左侧）
      currentPageLeft: 1,



      //分页数据（右侧）
      tableRightData:[],
      // 的总数（右侧）
      totalNumForRightPagenation:0,
      // 双向绑定--每页的个数（右侧）
      pageSizeRight: 10,
      // 双向绑定--当前页（右侧）
      currentPageRight: 1,
      // 右侧radio绑定数据
      radioValueRight:'',
    }},


    methods:{
        // 刷新页面(左侧)
      refreshThePageLeft() {
        this.$store.dispatch("getRepairPatrolGroupSettingLeftPageData", {
            pageNum: this.currentPageLeft,
            pageSize: this.pageSizeLeft,
          }).then(() => {  
            this.tableLeftData = this.repairPatrolGroupSettingLeftPageData;
            this.choosenId = this.tableLeftData[0].id
            this.radioValue = this.tableLeftData[0].id
            this.totalNumForLeftPagenation = this.repairPatrolGroupSettingLeftTotal;   
            this.refreshThePageRight()
          });
      },
        // 更改分页器每页个数(左侧)
      handleSizeChangeLeft(val) {
        this.currentPageLeft = "1";
        this.pageSizeLeft = val;
        this.refreshThePageLeft();
      },
      // 更改分页器页面（左侧）
      handleCurrentChangeLeft(val) {
        this.currentPageLeft = val;
        this.refreshThePageLeft();
      },
      //添加小组（左侧按钮）
      addGroup(){
        this.$refs.addGroupRef.show()
      },
      //编辑小组（左侧按钮）
      handleEditLeft(index){
        this.$refs.addGroupRef.showForEdit(this.repairPatrolGroupSettingLeftPageData[index])
      },
      // 删除小组（左侧按钮）
      handleDeleteLeft(index){
      const deleteId = this.repairPatrolGroupSettingLeftPageData[index].id;
      this.$confirm("您确定要执行删除操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("deleteRepairPatrolGroupSettingLeftDeleteGroup", deleteId).then(() => {
          if (this.ifDeleteGroupSuccess === 0) {
            this.$message({ message: "删除成功！", type: "success" });
            this.refreshThePageLeft();
          } else if (this.ifDeleteGroupSuccess === -1) {
            this.$message({ message: this.ifDeleteGroupError, type: "warning" });
          }
        });
      });
      },
      // 切换radio
      handelChangeRadio(val){
        this.choosenId = val
        this.refreshThePageRight()
      },

//上左下右 ###################################################################################################################################

        // 刷新页面(右侧)
      refreshThePageRight() {
        this.$store.dispatch("getRepairPatrolGroupSettingRightPageData", {
            pageNum: this.currentPageRight,
            pageSize: this.pageSizeRight,
            id:this.choosenId,
            }).then(() => {   
            this.tableRightData = this.repairPatrolGroupSettingRightPageData;
            this.totalNumForRightPagenation = this.repairPatrolGroupSettingRightTotal;   
            // 渲染组长的表现型
            for(let i=0;i<this.tableRightData.length;i++){
              if(this.tableRightData[i].satrap===1){this.radioValueRight=this.tableRightData[i].userId}
            }
          });
          
      },
        // 更改分页器每页个数(右侧)
      handleSizeChangeRight(val) {
        this.currentPageRight = "1";
        this.pageSizeRight = val;
        this.refreshThePageRight();
      },
      // 更改分页器页面（右侧）
      handleCurrentChangeRight(val) {
        this.currentPageRight = val;
        this.refreshThePageRight();
      },
      // 新增巡检人员（右侧）
      addMember(){
        if (this.choosenId) {
          this.$refs.addMemberRef.show(this.choosenId)
        } else {
          this.$message({ message: '请先选择一个小组，若无小组请先添加小组', type: "warning" });
        }
      },
      // 修改组长人选
      handelChangeRadioRight(val){


      this.$confirm("执行此操作后旧组长和新组长将需要重新登录系统，确认更改组长吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
        }).then(() => {
        for(let i=0;i<this.tableRightData.length;i++){
          // 如果已经有组长，取消他的组长
          if(this.tableRightData[i].satrap){
            this.tableRightData[i].satrap = 0
            this.$store.dispatch('putRepairPatrolGroupSettingRight',this.tableRightData[i])
          }
        }
        // 修改自己为组长
        const leader = val
        leader.satrap = 1
        this.$store.dispatch('putRepairPatrolGroupSettingRight',leader).then(()=>{
            if (this.putRepairPatrolGroupSettingRightSuccess === 0) {
            this.$message({ message: "已修改组长为 "+val.name, type: "success" });
            this.refreshThePageRight();
          } else if (this.putRepairPatrolGroupSettingRightSuccess === -1) {
            this.$message({ message: this.putRepairPatrolGroupSettingRightError, type: "warning" });
          }
        })
       });

      this.refreshThePageRight()
      },
      // 将成员移出小组
      handleDeleteRight(index){
 
      this.$confirm("确定要将"+this.tableRightData[index].name+"移出小组吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store
          .dispatch("deleteRepairPatrolSettingMemberOut", this.tableRightData[index].id)
          .then(() => {
            if (this.ifDeleteMemberToGroupSuccess === 0) {
              this.$message({ message: "删除成功！", type: "success" });
              this.refreshThePageRight();
            } else if (this.ifDeleteMemberToGroupSuccess === -1) {
              this.$message({
                message: this.ifDeleteMemberToGroupError,
                type: "warning"
              });
            }
          });
      });
      }

    },

    mounted(){
      this.refreshThePageLeft()
      
    },

     computed: {
    ...mapState({
      // 分页数据(左侧)
      repairPatrolGroupSettingLeftPageData: state => { return state.repair.repairPatrolGroupSettingLeftPageData.data.records;},
      // 页面总数（左侧）
      repairPatrolGroupSettingLeftTotal: state => { return state.repair.repairPatrolGroupSettingLeftPageData.data.total;},
      // 分页数据(右侧)
      repairPatrolGroupSettingRightPageData: state => { return state.repair.repairPatrolGroupSettingRightPageData.data.records;},
      // 页面总数（右侧）
      repairPatrolGroupSettingRightTotal: state => { return state.repair.repairPatrolGroupSettingRightPageData.data.total;},
      // 删除巡小组是否成功（左侧）
      ifDeleteGroupSuccess:state => { return state.repair.ifDeleteGroupSuccess.errorCode},
      ifDeleteGroupError:state => { return state.repair.ifDeleteGroupSuccess.message},
      // 设置自己为巡检小组的组长是否成功
      putRepairPatrolGroupSettingRightSuccess:state => { return state.repair.putRepairPatrolGroupSettingRightSuccess.errorCode},
      putRepairPatrolGroupSettingRightError:state => { return state.repair.putRepairPatrolGroupSettingRightSuccess.message},
      // 是否移出成员成功
      ifDeleteMemberToGroupSuccess:state => { return state.repair.ifDeleteMemberToGroupSuccess.errorCode},
      ifDeleteMemberToGroupError:state => { return state.repair.ifDeleteMemberToGroupSuccess.message},
      // ifDeleteRepairPatrolTaskSuccess:state => { return state.repair.ifDeleteRepairPatrolTaskSuccess.errorCode},
      // ifDeleteRepairPatrolTaskError:state => { return state.repair.ifDeleteRepairPatrolTaskSuccess.message},
     
    })
  }
}
</script>

<style lang='less' scoped>
// 穿梭框左侧#####################################################################
  .leftPart{
    height: 80.4rem;
    width: 52rem;
    position: absolute;
    top: 0;
    left: 0;
  // 顶栏位置
  .topPlace{
    height: 7.2rem;
    width: 52rem;
    position: relative;
    top: 0;
    left: 0;

    .btn{
      height: 3.2rem;
      width: 10.2rem;
      color: #fff;
      font-size: 1.4rem;
      border-radius: 0.2rem;
      background-color: #027969;
      line-height: 3.2rem;
      border: none;
      position: absolute;
      right: 2.4rem;
      top: 2.4rem;
      cursor: pointer;
    }
  }
  // 表格位置
 .table {
    height: 57.2rem;
    width: 47.2rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }

// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
  }
  .middleLine{
    position: absolute;
    top: 0;
    left: 52rem;
    height: 57.1rem;
    margin-top: 7.2rem;
    margin-bottom: 16.1rem;
    width: 0;
    border:0.1rem solid rgba(22, 111, 191, 0.2);
  }

// 穿梭框右侧#####################################################################
  .rightPart{
    height: 80.4rem;
    width: 119rem;

    position: absolute;
    top: 0;
    left:52.2rem;
      // 顶栏位置
  .topPlace{
    height: 7.2rem;
    width: 119rem;
    position: relative;
    top: 0;
    left: 0;

    .btn{
      height: 3.2rem;
      width: 10.2rem;
      color: #fff;
      font-size: 1.4rem;
      border-radius: 0.2rem;
      background-color: #027969;
      line-height: 3.2rem;
      border: none;
      position: absolute;
      right: 2.4rem;
      top: 2.4rem;
      cursor: pointer;
    }
  }
  // 表格位置
 .table {
    height: 57.2rem;
    width: 114.2rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }

// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
  }

</style>


<style lang='less'>
.PatrolGroupSetting{
.el-radio.is-bordered.is-checked {
    border-color: #027969;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #027969;
}

.el-radio__input.is-checked .el-radio__inner {
    border-color: #027969;
    background: #027969;
}

.el-radio__inner:hover {
    border-color: #027969;
    background-color: #027969;
}

.el-radio.is-bordered {
    border: 1px solid #566e84;
    
}

.el-radio__inner {
    border: 1px solid #dbe0e5;
    background-color: #0c375e;
    
}
.el-radio__inner::after {
    
    background-color: #027969;
   
}
.el-radio {
    color: #dbe0e5;
    font-weight: 500;
    cursor: pointer;
    margin-right: 3rem;
}
.el-radio__inner {
    border: 0.1rem solid #dbe0e5;
    border-radius: 100%;
    width: 0.6rem;
    height: 0.6rem;
    background-color: #dbe0e5;
    cursor: pointer;
    box-sizing: border-box;

}
.el-radio--mini.is-bordered .el-radio__inner {
    height: 0.6rem;
    width: 0.6rem;
}
.el-radio, .el-radio__input {
    line-height: 1.6rem;
    white-space: nowrap;
    outline: 0;
}

.PatrolGroupSetting .el-radio__inner {
    border-color: #027969;
    background-color: #dbe0e5;
}
.PatrolGroupSetting .el-radio__inner:hover {
    border-color: #027969;
    background-color: #0c375e;
}
.el-radio--mini.is-bordered {
    padding: 0.6rem 1.5rem 0 1rem;
    border-radius: 0.3rem;
    height: 2.8rem;
    width: 9rem;
    margin-top: 0.6rem;
}
.el-radio--mini.is-bordered .el-radio__label, .el-radio--small.is-bordered .el-radio__label {
    font-size: 1.2rem;
}

.el-radio__label {
    // font-size: 1.4rem;
    padding-left: 1rem;
}

.PatrolGroupSetting .el-radio, .PatrolGroupSetting .el-radio__input {
    line-height: 1.6rem;
    white-space: nowrap;
    outline: 0;
}
.el-radio, .el-radio__inner, .el-radio__input {
    position: absolute;
    display: inline-block;
    top: 0.55rem;
    left: 0.4rem;
}
.PatrolGroupSetting .el-radio__label {
    padding-left: 1.6rem;
    
}
.PatrolGroupSetting .el-radio, .PatrolGroupSetting .el-radio__input {
    line-height: 1.3rem;
    white-space: nowrap;
    outline: 0;
}

.PatrolGroupSetting .el-radio--mini.is-bordered .el-radio__label, .PatrolGroupSetting .el-radio--small.is-bordered .el-radio__label {
    font-size: 1.2rem;
    position: absolute;
    top: 0.5rem;
    left: 1.5rem;
}
.PatrolGroupSetting .el-radio, .PatrolGroupSetting .el-radio__input {
    line-height: 1.3rem;
    white-space: nowrap;
    outline: 0;
    font-size: 1.2rem;
    position: absolute;
    top: 0.5rem;
    left: 1.5rem;
}
.el-radio__label{
   font-size: 1.2rem;
    position: absolute !important;
    top: 0.5rem !important;
    left: 1rem !important;
}

}
</style>