<template>
  <div class="reportRouter">
    <div class="enerTopRouter">
      <p :class="ruoter1" @click="changeTab(1)">当前告警</p>
      <p :class="ruoter2" @click="changeTab(2)">历史告警</p>
    </div>
    <div class="routerLay">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruoter1: "btnGreen",
      ruoter2: "btnGray",
    };
  },
  mounted() {},
  methods: {
    changeTab(index) {
      if (index === 1) {
        this.ruoter1 = "btnGreen";
        this.ruoter2 = "btnGray";
        this.$router.push({
          path: "/ElecGenerate/alarmWaring/myWaring"
        });
      } else if (index === 2) {
        this.ruoter1 = "btnGray";
        this.ruoter2 = "btnGreen";
        this.$router.push({
          path: "/ElecGenerate/alarmWaring/currentWaring"
        });
      }
    }
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.reportRouter {
  .enerTopRouter {
    width: 142.8rem;
    height: 3.4rem;
    position: fixed;
    background: #0c2f4f;
    display: flex;
    top: 15.3rem;
    .btnGreen {
      color: #03d9bc;
      margin-left: 2.6rem;
      cursor: pointer;
      border-bottom: 0.2rem solid #03d9bc;
    }
    .btnGray {
      color: aliceblue;
      margin-left: 2.6rem;
      cursor: pointer;
    }
  }
  .routerLay {
    height: 86.4rem;
    width: 142.8rem;
    position: absolute;
    right: 0;
    // overflow: hidden;
    background-color: #18191a;
  }
}
</style>
