import { render, staticRenderFns } from "./DialogForEdit.vue?vue&type=template&id=289f5d71&scoped=true&"
import script from "./DialogForEdit.vue?vue&type=script&lang=js&"
export * from "./DialogForEdit.vue?vue&type=script&lang=js&"
import style0 from "./DialogForEdit.vue?vue&type=style&index=0&id=289f5d71&lang=less&scoped=true&"
import style1 from "./DialogForEdit.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289f5d71",
  null
  
)

export default component.exports