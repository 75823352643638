<template>
  <div class="OrderStatistics">
    <!-- 顶部栏搜索位置############################################################## -->
    <div class="topBar">
      <!------------------------------------------------------支付渠道选择框------------------------------------------------------------ -->
      <el-select
        size="small"
        @change="paywaySelectChanged"
        placeholder="请选择支付渠道"
        style="width:16.8rem;margin:1rem 0 0 2rem"
        v-model="payChannel"
      >
        <el-option label="所有支付渠道" value=""></el-option>
        <el-option label="直连" value="0"></el-option>
        <el-option label="微信服务商-日结" value="2"></el-option>
        <!-- <el-option
                v-for="(item, i) in paywayList"
                :label="item.label"
                :value="item.value"
                :key="i"
              ></el-option> -->
      </el-select>

      <!-- --------------------------------------------------支付方式选择框------------------------------------------------------------ -->
      <el-select
        size="small"
        @change="paystatusSelectChanged"
        placeholder="请选择支付类型"
        style="width:16.8rem;margin:1rem 0 0 2rem"
        v-model="payType"
      >
        <el-option label="所有支付类型" value=""></el-option>
        <el-option label="刷卡" value="1"></el-option>
        <el-option label="网络支付" value="2"></el-option>
        <el-option label="网络投币" value="3"></el-option>
        <el-option label="线下投币" value="4"></el-option>
        <!-- <el-option
                v-for="(item, i) in paystatusList"
                :label="item.label"
                :value="item.value"
                :key="i"
              ></el-option> -->
      </el-select>

      <!---------------------------------------------------充电状态选择框------------------------------------------------------------ -->
      <el-select
        size="small"
        @change="chargeSelectChanged"
        placeholder="请选择充电状态"
        style="width:16.8rem;margin:1rem 0 0 2rem"
        v-model="status"
      >
        <el-option label="所有充电状态" value=""></el-option>
        <el-option label="未付款" value="0"></el-option>
        <el-option label="付款未充电(道闸付款未开门）" value="1"></el-option>
        <el-option label="充电中" value="2"></el-option>
        <el-option label="正常充电结束(道闸开门成功）" value="3"></el-option>
        <el-option label="异常结束" value="4"></el-option>
        <el-option label="叠加结束" value="5"></el-option>
      </el-select>

      <!----------------------------------------------------订单编号输入框------------------------------------------------------------ -->
      <el-input
        size="small"
        placeholder="请输入订单编号"
        v-model="orderNum"
        @keyup.enter.native="search"
        style="width:16.8rem;margin:1rem 0 0 2rem"
        clearable
        @clear="search"
      ></el-input>

      <!----------------------------------------------------设备编号输入框------------------------------------------------------------ -->
      <el-input
        size="small"
        placeholder="请输入设备编号"
        v-model="deviceId"
        @keyup.enter.native="search"
        style="width:16.8rem;margin:1rem 0 0 2rem"
        clearable
        @clear="search"
      ></el-input>

      <!----------------------------------------------------小区ID输入框------------------------------------------------------------ -->
      <!-- <el-input size="small" placeholder="请输入小区ID" v-model="estateId" @keyup.enter.native="search"
              style="width:16.8rem;margin:1rem 0 0 2rem" clearable @clear="search"
            ></el-input> -->

      <!----------------------------------------------------查询按钮------------------------------------------------------------ -->
      <button
        class="btn_save"
        style="margin-left:1.6rem;border-radius:0.2rem"
        @click="search"
      >
        查 询
      </button>
      <!----------------------------------------------------导出按钮------------------------------------------------------------ -->
      <button
        class="btn_save"
        style="margin:1rem 1.6rem;border-radius:0.2rem;float:right"
        @click="handleExport"
      >
        导 出
      </button>
    </div>

    <!-- 中部表格位置################################################################# -->
    <div class="contentPlace">
      <!---------------------------------------------------- 表格部分--------------------------------------------------------------------------- -->
      <div class="table">
        <el-table
          :data="tableData"
          stripe:false
          border:false
          style="width: 100%;"
          :header-cell-style="headerClass"
          :cell-style="cellStyle"
        >
          <el-table-column
            fixed
            prop="estateId"
            label="小区ID"
            width="80"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            fixed
            prop="orderNum"
            label="订单编号"
            width="200"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="ownerName"
            label="公众号"
            width="230"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="payChannel"
            label="支付渠道"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.payChannel == 0">直连</span>
              <span v-show="scope.row.payChannel == 2">微信服务商-日结</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="userId"
            label="用户编号"
            width="80"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column prop="data4" label="电卡编号" min-width="15%" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column
            prop="deviceName"
            label="设备名称"
            width="200"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="deviceId"
            label="设备编号"
            width="80"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="portNum"
            label="插座"
            width="50"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="payPrice"
            label="消费金额"
            width="80"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="payType"
            label="支付类型"
            width="80"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.payType == 1">刷卡</span>
              <span v-show="scope.row.payType == 2">网络支付</span>
              <span v-show="scope.row.payType == 3">网络投币</span>
              <span v-show="scope.row.payType == 4">线下投币</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="payWay"
            label="支付方式"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.payWay == 1">微信充值余额</span>
              <span v-show="scope.row.payWay == 2">微信赠送余额</span>
              <span v-show="scope.row.payWay == 3">支付宝充值余额</span>
              <span v-show="scope.row.payWay == 4">支付宝赠送余额</span>
              <span v-show="scope.row.payWay == 5">月卡支付</span>
              <span v-show="scope.row.payWay == 6">微信</span>
              <span v-show="scope.row.payWay == 7">支付宝</span>
              <span v-show="scope.row.payWay == 8">投币支付</span>
              <span v-show="scope.row.payWay == 9">商户余额(微信充值)</span>
              <span v-show="scope.row.payWay == 10">商户余额(微信赠送)</span>
              <span v-show="scope.row.payWay == 11">商户余额(支付宝充值)</span>
              <span v-show="scope.row.payWay == 12">商户余额(支付宝赠送)</span>
              <span v-show="scope.row.payWay == 13">优惠卷</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="isCount"
            label="交易状态"
            width="260"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.isCount == 0"
                >正常订单（会进入统计) = 扣款订单</span
              >
              <span v-show="scope.row.isCount == 1"
                >不统计订单（不统计) = 不统计订单
              </span>
              <span v-show="scope.row.isCount == 2"
                >退款订单（不进入统计) = 退款订单</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="充电状态"
            width="230"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.status == 0">未付款</span>
              <span v-show="scope.row.status == 1"
                >付款未充电（道闸付款未开门）</span
              >
              <span v-show="scope.row.status == 2">充电中</span>
              <span v-show="scope.row.status == 3"
                >正常充电结束（道闸开门成功）</span
              >
              <span v-show="scope.row.status == 4">异常结束</span>
              <span v-show="scope.row.status == 5">叠加结束</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="workTime"
            label="工作时长"
            width="80"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{
                Number((scope.row.workTime / 60).toFixed(1)) + "h"
              }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="data12" label="订单类型" min-width="11%" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column
            prop="actualTime"
            label="实际时长"
            width="80"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{
                Number((scope.row.actualTime / 60).toFixed(1)) + "h"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="actualElectricQuantity"
            label="实际耗电"
            width="80"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="inTime"
            label="创建时间"
            width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            label="更新时间"
            width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="endType"
            label="结束原因"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.endType == 1">计时结束</span>
              <span v-show="scope.row.endType == 2">充满结束</span>
              <span v-show="scope.row.endType == 3">手动结束</span>
              <span v-show="scope.row.endType == 4">功率过大</span>
              <span v-show="scope.row.endType == 5">空载结束</span>
              <span v-show="scope.row.endType == 6">中途异常拔掉插座</span>
              <span v-show="scope.row.endType == 7">保险丝故障</span>
              <span v-show="scope.row.endType == 8">未连接电枪</span>
              <span v-show="scope.row.endType == 9">叠加结束</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!----------------------------------------------------分页器部分--------------------------------------------------------------------------- -->
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalNumForPagenation"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { tableBg } from "@/mixin/tablebg"
import { exportChargingOrderReport } from "@/api/repair"

export default {
  name: "OrderStatistics",
  mixins: [tableBg],
  data() {
    return {
      // 驴充充的token令牌
      chargeToken: "",
      // 搜索框双向绑定
      deviceId: "", // 设备编号
      estateId: "", // 小区编号
      orderNum: "", // 订单编号
      payChannel: "", // 支付渠道
      payType: "", // 支付方式
      status: "", // 充电状态

      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 表格数据
      tableData: [],
      customerName: ''
    }
  },
  watch: {
      "$store.state.storage.customerData": {
          immediate: true,
          deep: true,
          handler (newVal, oldVal) {
              if (!newVal) {
                  return;
              }
              this.customerName = newVal.name;
          },
      },
  },
  created() {
    // this.getList()
    // 清除同名的所有时间总线，自己和其他兄弟模块的
    this.$bus.$off("chargeForTree")
  },

  mounted() {
    this.$bus.$on("chargeForTree", (val) => {
      if (val == []) {
        alert("该用户没有充电业务")
        return
      }
      this.currentPage = 1
      this.chargeToken = val
      this.getList()
    })
  },
  beforeDestroy() {
    this.$bus.$emit("refreshTheChargeTree")
  },

  methods: {
    // 点击了导出
    handleExport() {
      let chargeValue = ""
      if (this.chargeToken.length > 0) {
        for (let i = 0; i < this.chargeToken.length; i++) {
          chargeValue = chargeValue + this.chargeToken[i] + ","
        }
        chargeValue = chargeValue.slice(0, chargeValue.length - 1)
      } else {
        chargeValue = ""
      }
      var value = {
        chargeToken: chargeValue,
        deviceId: this.deviceId,
        orderNum: this.orderNum,
        payChannel: this.payChannel,
        payType: this.payType,
        status: this.status,
        myPage: "OrderStatistics",
      }
      // this.$store.dispatch("getExportFileCharge", value)
      exportChargingOrderReport(value).then((response) => {
        var blob = new Blob([response], {
          type: "application/vnd.ms-excel,charset=utf-8",
        }) //创建一个blob对象
        var a = document.createElement("a") //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob) // response is a blob
        a.download = `${this.customerName}订单统计.xls` //文件名称
        a.style.display = "none"
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    },
    // 表格渲染
    getList() {
      this.$store
        .dispatch("getOrderStatisticsPageList", {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          deviceId: this.deviceId,
          estateId: this.estateId,
          orderNum: this.orderNum,
          payChannel: this.payChannel,
          payType: this.payType,
          status: this.status,
          chargeToken: this.chargeToken,
        })
        .then(() => {
          const res = this.$store.state.elecCharge.orderStatisticsPageList
          if (res.errorCode == 0) {
            this.totalNumForPagenation = res.data.total
            this.tableData = res.data.records
          } else {
            this.$message(res.message)
          }
        })
    },
    // 搜索框点击搜索
    search() {
      this.currentPage = 1
      this.getList()
    },

    // 切换搜索框内容(支付渠道)
    paywaySelectChanged() {
      this.currentPage = 1
      // alert('切换搜索框内容(支付渠道)')
      this.getList()
    },
    // 切换搜索框内容(交易状态)
    paystatusSelectChanged() {
      this.currentPage = 1
      // alert('切换搜索框内容(交易状态)')
      this.getList()
    },
    // 切换搜索框内容(充电状态)
    chargeSelectChanged() {
      this.currentPage = 1
      // alert('切换搜索框内容(充电状态)')
      this.getList()
    },

    // 更改分页器每页个数
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
  },
}
</script>

<style lang="less" scoped>
.topBar {
  height: 6rem;
  width: 100%;
  background-color: #0d3050;
  border-radius: 0 0 0.4rem 0.4rem;
//   position: absolute;
//   left: 0;
//   top: -2.9rem;
}

.contentPlace {
  margin-top: 2.4rem;
  height: 80rem;
  width: 100%;
  background-color: #0d3050;
  border-radius: 0.4rem;
//   position: absolute;
//   top: 5rem;
//   left: 0;
}
.table {
  padding: 2.4rem 2.4rem;
  width: 97%;
  // height: 58rem;
  /deep/.el-table__fixed {
    height: 44.5rem;
  }
  /deep/.el-table__fixed::before {
    height: 0;
    // z-index: 999;
  }
  // .el-table__fixed-right::before
  // .el-table__fixed::before
}
.pagination {
  // float: right;
  // margin-right: 2.4rem;
    position: absolute;
    right: 2.4rem;
    bottom: 3.5rem;
}
</style>
