<!-- 登记设备modal -->
<template>
  <div class="EquipmentDialogOne">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="65%"
      :close-on-click-modal="false"
    >
      <div v-if="getType===1" slot="title">
        <div class="equip_title">登记</div>
      </div>
      <div v-else slot="title">
        <div class="equip_title">编辑</div>
      </div>

      <div style="overflow-y:auto;">
        <el-form :model="form" class="elForm" :rules="rules" ref="ruleForm">
          <el-form-item label="编号" prop="code">
            <el-input v-model="form.code" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="设备名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>

          <el-form-item label="设备类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择" style="width: 98%" size="small">
              <el-option label="配电柜" value="1"></el-option>
              <el-option label="变压器" value="2"></el-option>
              <el-option label="直流屏" value="3"></el-option>
              <el-option label="其它" value="4"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属配电房" prop="placeId">
            <el-select v-model="form.placeId" placeholder="请选择" style="width: 100%" size="small">
              <el-option
                v-for="(item,index) in ownRoomData"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="客户单位" prop="name">
            <el-input
              disabled
              v-model="companyFakeForShow"
              class="disabled_input"
              size="small"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="型号" prop="specification">
            <el-input v-model="form.specification" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="生产日期" prop="produceDate">
            <el-date-picker
              value-format="yyyy-MM-dd" 
              size="small"
              type="date"
              placeholder="选择日期"
              v-model="form.produceDate"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="投运日期" prop="useDate">
            <el-date-picker
            value-format="yyyy-MM-dd" 
              size="small"
              type="date"
              placeholder="选择日期"
              v-model="form.useDate"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="保修截至" prop="warrantyDate">
            <el-date-picker
              value-format="yyyy-MM-dd" 
              size="small" 
              type="date"
              placeholder="选择日期"
              v-model="form.warrantyDate"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="厂家" prop="producer">
            <el-input v-model="form.producer" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="容量(kVA)" prop="capacity">
            <el-input v-model="form.capacity" placeholder="请输入" size="small" clearable @blur="checkNumber('capacity')"></el-input>
          </el-form-item>
          <el-form-item label="额定负载(kW)" prop="ratedLoad">
            <el-input v-model="form.ratedLoad" placeholder="请输入" size="small" clearable @blur="checkNumber('ratedLoad')"></el-input>
          </el-form-item>
          <el-form-item v-show="isBianyaqi" label="绑定电表（高压侧）" prop="highMeterId">
            <el-select v-model="form.highMeterId" filterable placeholder="请选择高压侧电表绑定" style="width: 100%" size="small">
              <el-option v-for="(item,index) in meterOptionList" :key="index" :label="`${item.name}(${item.code})`" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="isBianyaqi" label="绑定电表（低压侧）" prop="lowMeterId">
            <el-select v-model="form.lowMeterId" filterable placeholder="点击绑定低压侧电表" style="width: 100%" size="small">
              <el-option v-for="(item,index) in meterOptionList" :key="index" :label="`${item.name}(${item.code})`" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预防性试验执行周期" prop="maintainCycle">
            <el-select v-model="form.maintainCycle" placeholder="请选择" style="width: 100%" size="small">
              <el-option label="一个月" value="1"></el-option>
              <el-option label="三个月" value="2"></el-option>
              <el-option label="半年" value="3"></el-option>
              <el-option label="一年" value="4"></el-option>
              <el-option label="两年" value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="isBianyaqi || isZhiliuping" label="上传图片" prop="pictureUrl">
            <el-upload
              class="picture-uploader"
              :headers="myToken"
              :action="baseURL+'file/upload'"
              :show-file-list="false"
              :on-success="upLoadSuccess"
              :on-error="upLoadError"
              list-type="picture-card"
            >
              <div v-if="pictureUrl" class="picture-container" @mouseenter="maskVisible = true" @mouseleave="maskVisible = false">
                <img :src="pictureUrl">
                <div v-show="maskVisible" class="picture-mask">
                  <!-- 预览大图 -->
                  <span @click.stop="handlePictureCardPreview">预览</span>
                  <!-- 删除图片 -->
                  <span @click.stop="handleRemove">删除</span>
                </div>
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item></el-form-item>
          <!-- 列表################################################################## -->
          <el-form-item label="下属电表" style="width: 100%;" class="no_line_height">
            <el-table
              :data="form.meters"
              style="width: 100%;background:#0C375E;"
              :header-cell-style="headerClass"
              :cell-style="cellStyle"
              ref="tb"
            >
              <el-table-column prop="code" label="编号" min-width="8%">
                <template slot-scope="scope">
                  <el-input
                    placeholder="请输入"
                    v-model="form.meters[scope.$index].code"
                    size="small"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="名称" min-width="10%">
                <template slot-scope="scope">
                  <el-input
                    placeholder="请输入"
                    v-model="form.meters[scope.$index].name"
                    size="small"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="powerType" label="电表类型" min-width="10%">
                <template slot-scope="scope">
                  <el-select
                    v-model="form.meters[scope.$index].powerType"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="(item,i) in dbType"
                      :key="i"
                      :label="item.label" 
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
             
              <el-table-column prop="wireModel" label="电缆型号" min-width="10%">
                <template slot-scope="scope">
                  <el-input
                    placeholder="请输入"
                    v-model="form.meters[scope.$index].wireModel"
                    size="small"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="wireLength" label="电缆长度(M)" min-width="10%">
                <template slot-scope="scope">
                  <el-input
                    placeholder="请输入"
                    v-model="form.meters[scope.$index].wireLength"
                    size="small"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="maxMetric" label="电能最值" min-width="15%">
                <template slot-scope="scope">
                  <el-input-number
                    placeholder="请输入"
                    v-model="form.meters[scope.$index].maxMetric"
                    size="mini"
                    controls-position="right" 
                    :min="0"
                  ></el-input-number>
                </template>
              </el-table-column>
               <el-table-column  label="监测回路" min-width="40%">
                <template slot-scope="scope">
                  <el-tag closable type="success" class="tag" @close = "closeTag(index,scope.$index)"
                  v-for="(item,index) in form.meters[scope.$index].circuits" :key="index" v-show="index<=2">
                      <input type="text" class="tagInputStyle" v-model="item.name">
                  </el-tag>
                  <el-tag  type="success" @click="(form.meters[scope.$index].circuits&&form.meters[scope.$index].circuits.length>=3)?addTag2(scope.$index):addTag(scope.$index)" class="tag2" >
                      {{(form.meters[scope.$index].circuits&&form.meters[scope.$index].circuits.length>=3)?"更多":"+"}}
                  </el-tag>
                </template>
              </el-table-column>

              <!-- 操作部分-->
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" @click="handleDelete(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <div class="btn_add" @click="addRow()">➕ 添加一行数据</div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
      </div>

      <!-- 树的位置############### -->
      <div :class="ifDialogTreeOutsiderShow">
        <DialogForTree  ref="treeRef"/>
      </div>
    </el-dialog>

    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogForPictureVisible">
      <img width="100%" :src="pictureUrl" alt="">
    </el-dialog>

     <!-- 弹层位置 -->
      <el-dialog 
        :visible.sync="dialogTagIfShow" width="60%">
        <div  slot="title">
          <div  class="equip_title">更多</div>
        </div>
        <el-tag closable type="success" class="tag" @close="closeTag(index,myIndex)"
              v-for="(item,index) in form.meters[this.myIndex].circuits" :key="index" >
              <input type="text" class="tagInputStyle" v-model="item.name">
        </el-tag>
        <el-tag  type="success" @click="addTag3()" class="tag2" >
            {{"+"}}
        </el-tag>
      
      <div slot="footer" class="dialog-footer">
        <button class="btn_save end_btn" @click="postDialogDataDialog()">结束编辑</button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCookie } from "@/utils/util.js"
import config from '@/config/index.js'
import { tableBg } from "@/mixin/tablebg";
import { validateInput, validateSelect, validateDate,validateNumber} from "@/utils/validate";
import { listMeterByPlaceId } from '@/api/monitoring.js';
import DialogForTree from './DialogForTree.vue';

export default {
  name: "DialogOne",
  components:{DialogForTree},
  mixins: [tableBg],
  data() {
    return {
      // 配电房下面的电表集合
      meterOptionList: [],
      // 打开弹层的index
      myIndex:0,
      // tag弹层是否展示
      dialogTagIfShow:false,
      // 在电表中的顺序
      noOftheMeter:0,
      // 二级Dialog的外壳是否显示
      ifDialogTreeOutsiderShow:'treePlaceGoAway',
      // 请求类型（编辑=2，登记=1）
      getType: 0,
      // 表格内客户单位的展示数据
      companyFakeForShow: "",
      // 当前树的信息
      treeFullNodeData: {},
      // 展示判断
      dialogTableVisible: false,
      dialogFormVisible: false,
      tablePlusShow: false,
      // 内置dialog数据双向绑定处
      formInTable: { name: "", powerType: "" },
      // 下属电表的按钮的双向绑定内容
      buttonContent:'请选择电表位置',
      // 下属电表的真实数据ID
      buttonContentId:"",
      // 表单数据存放处
        form: {
        code: "",
        name: "",
        ownerId: "",
        location: "",
        specification: "",
        useDate: "",
        warrantyDate: "",
        maintainCycle: "",
        meters: [{maxMetric:0,circuits:[{name:""}]}],
        type: "",
        placeId: "",
        produceDate: "",
        producer: "",
        capacity: "",
        ratedLoad: "",
        pictureUrl: '',
        highMeterId: '',
        lowMeterId: ''
      },
      pictureUrl: '',
      maskVisible: false,
      dialogForPictureVisible: false,
      baseURL: '',
      myToken: { Authorization: "没有拿到token" },
      tableList: [],
      rules: {
        code: [validateInput],
        name: [validateInput],
        type: [validateInput],
        placeId: [validateSelect],
        // specification: [validateInput],
        produceDate: [validateDate],
        useDate: [validateDate],
        warrantyDate: [validateDate],
        // producer: [validateInput],
        // capacity: [validateNumber],
        // ratedLoad: [validateNumber],
        maintainCycle: [validateSelect],
        meters: [{ required: true, message: "不能为空" }],
       
      },
      // 设备类型字典存放
      dbType: [
        { label: "空调用电", value: "1" },
        { label: "照明用电", value: "2" },
        { label: "动力用电", value: "3" },
        { label: "特殊用电", value: "4" },
        { label: "无功补偿", value: "5" },
        { label: "有源滤波", value: "6" },
        { label: "总用电", value: "7" },
      ],
    
    };
  },
  mounted() {
    this.baseURL = config.baseURL
    this.myToken={Authorization: "Bearer "+getCookie("token")}
  },
  methods: {
    // 结束编辑
    postDialogDataDialog(){
      this.dialogTagIfShow = false
    },
    // 新增tag
    addTag(index){
      if(this.form.meters[index].circuits){
      const tagSingel = {name:""}
      this.form.meters[index].circuits.push(tagSingel)
      }else{
        const tagSingel = {name:""}
        this.form.meters[index]['circuits']=[]
        this.form.meters[index].circuits.push(tagSingel)
      }
     
    },
    // 弹层中新增tag
    addTag3(){
      const tagSingel = {name:""}
      this.form.meters[this.myIndex].circuits.push(tagSingel)
      
    },
    // 展示tag弹层
    addTag2(index){
      this.dialogTagIfShow = true
      this.myIndex = index
    },
    // 删除一个Tag
    closeTag(indexInside,indexOutside){
      this.form.meters[indexOutside].circuits.splice(indexInside, 1);
    },
    // 让二级Dialog树出现
    DialogTreeShow(val){
      this.noOftheMeter = val
      this.ifDialogTreeOutsiderShow = 'treePlace'
    },
    // dialog关闭的回调
    closeDialog(ruleForm) {
      this.formInit();
      this.$refs[ruleForm].resetFields();
      this.ifDialogTreeOutsiderShow= 'treePlaceGoAway'
    
    },
    // 取消弹框
    handleCancle(ruleName) {
      this.$refs[ruleName].resetFields();
      this.dialogFormVisible = false;
      this.formInit();
    },
    // from归零
    formInit() {
      this.form = {
        code: "",
        name: "",
        ownerId: "",
        location: "",
        specification: "",
        useDate: "",
        warrantyDate: "",
        maintainCycle: "",
        meters: [{circuits:[{name:""}]}],
        type: "",
        placeId: "",
        produceDate: "",
        producer: "",
        capacity: "",
        ratedLoad: "",
        pictureUrl: ''
      };
      this.pictureUrl = '';
    },
    // 添加一行数据
    addRow() {
      // const obj = { name: "", type: "" ,locationName:"请选择位置",locationId:"" };
      const obj = {code:'', name: "", powerType: "",wireModel:'',wireLength:'',maxMetric: 0,locationName:"请选择位置",circuits:[{name:''}]};
      this.form.meters.push(obj);
    },
    handleDelete(index) {
      this.form.meters.splice(index, 1);
    },
    //父组件点击触发，显示弹窗、给当前节点数据，修改form表单中的客户单位ID
    show(val) {
      this.$store.dispatch("getOwnRoomData", val.id).then(() => {
        this.dialogFormVisible = true;
        this.getType = 1;
        this.treeFullNodeData = val;
        this.companyFakeForShow = val.name;
        this.form.ownerId = val.id;
      });
      this.$store.dispatch("getEquipmentBuildings", val.id).then(() => {
        this.buildings = [];
        for (let i = 0; i < this.equipmentBuildings.length; i++) {
          const singelBuilding = { label: "", value: "" };

          singelBuilding.value = this.equipmentBuildings[i].id;
          singelBuilding.label = this.equipmentBuildings[i].name;
          this.buildings.push(singelBuilding);
        }
      });
      this.$store.dispatch('getEquipmentDialogTree',val.id)
      
      
    },
    //父组件触发来进行编辑
    showForEdit(val) {
      this.$store.dispatch("getOwnRoomData", val.ownerId).then(() => {
        this.getType = 2;
        // this.form = val;
        this.companyFakeForShow = val.ownerName;
        this.dialogFormVisible = true;
      });
      this.$store.dispatch("getEquipmentBuildings", val.ownerId).then(() => {
        this.buildings = [];     
        for (let i = 0; i < this.equipmentBuildings.length; i++){
          const singelBuilding = { label: "", value: "" };
          singelBuilding.value = this.equipmentBuildings[i].id;
          singelBuilding.label = this.equipmentBuildings[i].name;
          this.buildings.push(singelBuilding);
        }     
      });
      this.$store.dispatch('getEquipmentOwnData',val.id).then(()=>{
      if(this.equipmentOwnData.meters){
        this.pictureUrl = this.equipmentOwnData.pictureUrl
        this.form=this.equipmentOwnData
        let temp = this.equipmentOwnData.pictureUrl.split('/');
        this.form.pictureUrl = temp[temp.length - 1];
      }else{
        this.pictureUrl = this.equipmentOwnData.pictureUrl
        this.form=this.equipmentOwnData
        let temp = this.equipmentOwnData.pictureUrl.split('/');
        this.form.pictureUrl = temp[temp.length - 1];
        this.form.meters=[{circuits:[{name:""}]}]
      }
       
      })
    },
    //在弹层中选择大楼下拉 并触发楼层列表的请求(更换大楼)
    buildingsChanged(val) {
      this.$store.dispatch("getEquipmentFloor", val).then(() => {
        this.floors = [];

        for (let i = 0; i < this.equipmentFloor.length; i++) {
          const singelFloor = { label: "", value: "" };
          singelFloor.value = this.equipmentFloor[i].id;
          singelFloor.label = this.equipmentFloor[i].name;
          this.floors.push(singelFloor);
        }

        // this.floors = newArr
      });
    },
    // 在弹层中选择楼层下拉 并触发房间列表的请求（更换房间）
    floorChanged(val) {
      this.$store.dispatch("getEquipmentFloor", val).then(() => {
        this.rooms = [];
        for (let i = 0; i < this.equipmentFloor.length; i++) {
          const singelRoom = { label: "", value: "" };
          singelRoom.value = this.equipmentFloor[i].id;
          singelRoom.label = this.equipmentFloor[i].name;
          this.rooms.push(singelRoom);
        }
      });
    },
    // 往大Dialog的表格中添加项目（二级dialog点击《确定》）
    addNewSonEquipment() {
      const newSon = {};
      newSon.code = this.form.meters.length + 1;
      newSon.name = this.formInTable.name;
      newSon.type = this.formInTable.type;
      this.form.meters.push(newSon);
      this.tablePlusShow = false;
      this.formInTable = { name: "", type: "" };
    },
    // 提交发送请求（一级dialog点击《保存》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate(valid => {
        if (valid) {
          // 发送新增请求
          if (this.getType === 1) { //MDZZ
            this.$store.dispatch("postDialogData", this.form).then(() => {
              if (this.ifSuccess === 0) {
                this.$message({ message: "添加成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePage();
                this.dialogFormVisible = false;
                this.formInit()
              } else if (this.ifSuccess === -1) {
                this.$message({
                  message: this.ifError,
                  type: "warning"
                });
              }
            });
          } else if (this.getType === 2) {
            // 发送编辑请求
            this.$store.dispatch("putDialogData", this.form).then(() => {
              if (this.ifSuccessEdit === 0) {
                this.$message({ message: "编辑成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePage();
                this.dialogFormVisible = false;
                this.formInit()
              } else if (this.ifSuccessEdit === -1) {
                this.$message({
                  message: this.ifErrorEdit,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$message.error("有必填项未填");
        }
       
      });
    },
    // 上传图片成功
    upLoadSuccess(val, file){
      this.$message.success("上传图片成功！")
      this.pictureUrl = file.url
      this.form.pictureUrl = val.data
    },
    // 文件传输失败
    upLoadError(val){
      this.$message.error(val.message)
    },
    // 删除图片
    handleRemove() {
      this.form.pictureUrl = ''
      this.pictureUrl = ''
    },
    // 预览图片
    handlePictureCardPreview() {
      this.dialogForPictureVisible = true;
    },
    checkNumber(field) {
      let num = Number(this.form[field])
      if (isNaN(num) || typeof num != 'number') {
        this.form[field] = ''
      }
    },
  },
  //处理state数据
  computed: {
    ...mapState({
      ifSuccess: state => {
        return state.equipment.ifSuccess.errorCode;
      },
      ifError:state => {
        return state.equipment.ifSuccess.message;
      },
      ifSuccessEdit: state => {
        return state.equipment.ifSuccessEdit.errorCode;
      },
      ifErrorEdit: state => {
        return state.equipment.ifSuccessEdit.message;
      },
      ownRoomData: state => {
        return state.equipment.ownRoomData.data;
      },
      equipmentBuildings: state => {
        return state.equipment.equipmentBuildings.data;
      },
      equipmentFloor: state => {
        return state.equipment.equipmentFloor.data;
      },
      equipmentOwnData: state =>{
         return state.equipment.equipmentOwnData.data;
      }
    }),
    isBianyaqi(){
      return Number(this.form.type) === 2;
    },
    isZhiliuping(){
      return Number(this.form.type) === 3;
    }
  },

  watch:{
    // 监听二级弹框树的节点变化
    "$store.state.equipment.dialogTreeChoosenDataForMyFather.time"(){
      const myData = this.$store.state.equipment.dialogTreeChoosenDataForMyFather.data
      if(myData==='cancel'){
        this.ifDialogTreeOutsiderShow='treePlaceGoAway'
      }else{
        this.ifDialogTreeOutsiderShow='treePlaceGoAway'
        // this.form.meters[this.noOftheMeter].locationId = myData.id
        // this.form.meters[this.noOftheMeter].locationName = myData.name
       
      }

      
    },
    // 监视所选的配电房，变化绑定电表的option
    'form.placeId': {
      handler(newVal){
        if(newVal && this.isBianyaqi){
          // 发送ajax
          listMeterByPlaceId(newVal).then(res => {
            if(res.errorCode === 0){
              this.meterOptionList = res.data;
            }
          });
        }
      }
    }
  },
};

</script>

<style lang="less" scoped>
  .EquipmentDialogOne{
    overflow: hidden;
    .el-dialog__body{
      height: 60rem;
    }
  }


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
// 树所在的区域###################
.treePlace{
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 80rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  
  
  // border: #071828 0.2rem solid;
}
.treePlaceGoAway{
  // opacity: 0;
  display: none;
  // position:relative;
  // top: -54rem;
  // left: 80rem;
  height: 0;
  width: 26rem;
  overflow: hidden;
  // display: none;
  transition: height 0.3s;  
}

.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}


.tagInputStyle{
  height: 2.7rem;
  background-color: #124573;
  border: none;
  width: 8rem;
  color: #2CD9FF;
  &:focus{
    border: none;
  //  background-color: green;
   outline: none;
  }
}

.end_btn {
  width: 10.2rem !important;
}
</style>
<style lang="less">
.EquipmentDialogOne{
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  .el-table::before {
      opacity: 0;
  }
  .el-input-number{
        width:100%;
        .el-input-number__decrease{
            background: #0b3d6b;
            border-left: none;
        }
        .el-input-number__increase{
            border-left: none;
            border-bottom: none;
            background: #0b3d6b;
        }
        .el-input-number__decrease, .el-input-number__increase{
            color: #C0C4CC;
        }
        .el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
            color: #606266;
        }
    }

  .tag{
    margin-right: 0.8rem;
    background-color: #124573;
    color: #2CD9FF;
    border: 0.1rem dashed rgba(108, 184, 255, 0.3);
  }
  .tag2{
    margin-right: 0.8rem;
    background-color: #124573;
    color: #2CD9FF;
    border: 0.1rem dashed rgba(108, 184, 255, 0.3);
    cursor: pointer;
  }
  .el-tag.el-tag--success .el-tag__close{
    color: #2CD9FF;
    background-color: #124573;
    &:hover{
      color: #124573;
    background-color: #2CD9FF;
    }
  }
}

.picture-uploader {
  margin-top: 40px;
  .el-upload--picture-card {
    position: relative;
    color: rgba(255, 255, 255, 0.8);
    background-color: #0c375e;
    border: 0.1rem solid #004579;
    .picture-container {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .picture-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 8;
        span:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.bind-meter.el-input{
  .el-input__inner:hover{
    background-color: #094882;
    cursor: pointer;
    border: 1px solid #0658a3;
  }
  .el-input__inner::placeholder{
    color: deepskyblue;
  }
}

</style>




