import cellHover from "./cellHover";
import cellRemove from "./cellRemove";
import cellSelect from "./cellSelect";
import connectEdge from "./connectEdge";
import nodeClick from "./nodeClick";

// 统一注册 事件系统
export default function (graph,_this) {
	cellHover(graph)
	cellRemove(graph)
	cellSelect(graph,_this)
	connectEdge(graph)
	nodeClick(graph)
}
