<template>
  <div class="PatrolSetting">
    <!-- 面包屑 -->
    <div class="bread">运维检修 / 运维统计 / {{ routerName }}</div>

    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div class="btnOutSider">
        <button
          v-for="(item, index) in btnTypeList"
          :key="index"
          :class="item.value == btnValue ? 'btnGreen' : 'btnGrey'"
          @click="switchTab(item.value, item.comments)"
        >
          {{ item.label }}
        </button>
      </div>

      <div class="timer">
        <!-- 月历 -->
        <div class="timer1">
          <el-date-picker
            v-if="btnValue == '0'"
            class="myDatePicker"
            v-model="valueMonthPicker"
            type="monthrange"
            range-separator="-"
            value-format="yyyy-MM"
            :clearable="false"
            @change="
              (val) => {
                dateList = val
                dateType = 1
              }
            "
            start-placeholder="开始月份"
            end-placeholder="结束月份"
          ></el-date-picker>
        </div>

        <!-- 日历 -->
        <div class="timer1">
          <el-date-picker
            class="myDatePicker"
            v-model="valueTimePicker"
            type="daterange"
            align="right"
            unlink-panels
            :clearable="false"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="
              (val) => {
                dateList = val
                dateType = 0
              }
            "
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
      </div>
    </div>
    <!-- 路由位置 -->
    <div class="routerPlace">
      <!-- <router-view></router-view> -->
      <component
        :is="componentName"
        :dateList="dateList"
        :type="dateType"
      ></component>
    </div>
  </div>
</template>
 
<script>
import Situation from './Statistics/Situation.vue'
import Engineer from './Statistics/Engineer.vue'
import PerformanceS from './Statistics/PerformanceS.vue'
import Restime from './Statistics/Restime.vue'
export default {
  name: 'PatrolSetting',
  components: {
    Situation,
    Engineer,
    PerformanceS,
    Restime,
  },
  data() {
    return {
      // 按钮样式列表
      btnValue: '0',
      btnTypeList: [
        {
          label: '统计概况',
          value: '0',
          comments: 'Situation',
        },
        {
          label: '工程师统计',
          value: '1',
          comments: 'Engineer',
        },
        {
          label: '绩效统计',
          value: '2',
          comments: 'PerformanceS',
        },
        {
          label: '响应时长统计',
          value: '3',
          comments: 'Restime',
        },
      ],
      // 路由面包屑的双向绑定
      routerName: '统计概况',
      componentName: 'Situation',
      valueMonthPicker: [],
      valueTimePicker: [],
      dateList: [],
      dateType: 1,
      // 时间选择器的数据
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  mounted() {
    this.valueMonthPicker = [
      new Date().getFullYear() + '-01',
      new Date().getFullYear() + '-12',
    ]
    this.dateList = [...this.valueMonthPicker]
  },
  methods: {
    // 切换tab栏
    switchTab(val, name) {
      this.btnValue = val
      this.componentName = name
      this.dateList = []
      this.valueMonthPicker = []
      this.valueTimePicker = []
      if (val === '0') {
        this.routerName = '统计概况'
        let start = new Date().getFullYear() + '-01'
        let end = new Date().getFullYear() + '-12'
        this.valueMonthPicker = [start, end]
        this.dateList.push(...this.valueMonthPicker)
        this.dateType = 1
      } else if (val === '1') {
        this.routerName = '工程师统计'
      } else if (val === '2') {
        this.routerName = '绩效统计'
      } else if (val === '3') {
        this.routerName = '响应时长统计'
      }
    },
  },
}
</script>

<style lang="less" scoped>
// 面包屑
.bread {
  height: 5rem;
  width: 142.8rem;
  line-height: 5rem;
  color: #fff;
  font-size: 1.4rem;
  margin-top: -2rem;
}
// 顶部栏位置
.topBar {
  align-items: center;
  padding: 0 2rem;
  box-sizing: border-box;
  height: 6.4rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
  .btnOutSider {
    height: 3.2rem;
    // width: 54.8rem;
    border-radius: 0.2rem;
    overflow: hidden;
    border: 0.1rem solid #004579;
    box-sizing: border-box;
    .btnGreen {
      height: 3.2rem;
      color: #fff;
      font-size: 1.4rem;
      background-color: #027969;
      border: none;
      line-height: 3.2rem;
      padding: 0 1.5rem 0 1.5rem;
      cursor: pointer;
    }
    .btnGrey {
      height: 3.2rem;
      color: #fff;
      font-size: 1.4rem;
      background-color: #0c375e;
      border: none;
      line-height: 3.2rem;
      padding: 0 1.5rem 0 1.5rem;
      cursor: pointer;
    }
  }
  .timer {
    display: flex;
    justify-content: space-between;
    .timer1 {
      height: 3rem;
      width: 27rem;
      .myDatePicker {
        height: 3rem;
        width: 27rem;
      }
    }

    .timer1:last-child {
      margin-left: 2rem;
    }
  }
}

// 路由位置
.routerPlace {
  height: calc(100vh - 20rem);
  width: 100%;
  //   background-color: #0d3050;
  position: absolute;
  top: 11rem;
  left: 0;
}
</style>
