<template>
  <!-- 线图 -->
  <div class="dialog_echarts">
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabList" :key="item.id" :label="item.name" :name="item.id"></el-tab-pane>
      </el-tabs>
    </div>
    <div id="powerLineHistory" class="charts"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "EchartAreaMap",
  data() {
    return {
      ydata: "",
      series: "",
      unit: "",
      legendData: "",
      tabList: [],
      activeName: "0",
      seriesData: "",
      yMax: 10,
    };
  },
  props: ["inverterId", "type", "date"],
  mounted() {
    // this.initData();
  },
  methods: {
    ceilNumber(number) {
      let bite = 0;
      if (number < 10) {
        return 10;
      }
      while (number >= 10) {
        number /= 10;
        bite += 1;
      }
      return Math.ceil(number) * Math.pow(10, bite);
    },
    initData() {
      let value = {
        inverterId: this.inverterId,
        type: this.type,
        date: this.date,
      };
      this.$store.dispatch("getStraitData", value).then(() => {
        if (this.$store.state.genManagement.getStraitData.errorCode == 0) {
          this.ydata = this.$store.state.genManagement.getStraitData.data;
          if ((this.ydata !== null) & (this.ydata.length > 0)) {
            if ((this.ydata[0].data != null) && (this.ydata[0].data.length > 0)) {
              this.seriesData = this.ydata[this.activeName].data;
            } else {
              this.seriesData = [];
            }
          } else {
            this.seriesData = [];
          }
          this.tabList = [];
          for (let i = 0; i < this.ydata.length; i++) {
            let obj = {
              id: i.toString(),
              name: this.ydata[i].name,
            };
            this.tabList.push(obj);
          }
          // this.$nextTick(() => {
          //   this.activeName = this.tabList[0].id;
          // });
          if (this.type == 1) {
            this.unit = "单位：V";
          } else if (this.type == 2) {
            this.unit = "单位：A";
          } else if (this.type == 3) {
            this.unit = "单位：kW";
          }
          this.drawLine1();
        } else {
          this.seriesData = [];
          this.drawLine1();
        }
      });
    },
    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      //   let myChart = this.$echarts.init(this.$refs.myChart);
      //   if (myChart == null) {
      //     myChart = this.$echarts.init(this.$refs.myChart);
      //   }
      let myChart = this.$echarts.init(document.getElementById("powerLineHistory"));
      let yMax = 0;
      if (this.seriesData.length > 0) {
        for (let i = 0; i < this.seriesData.length; i++) {
          if (this.seriesData[i] > yMax) {
            yMax = this.seriesData[i];
          }
        }
        this.yMax = this.ceilNumber(yMax);
      } else {
        this.yMax = 10;
      }
      let option = {
        title: {
          // text: "本月用电趋势",
          top: "0px",
          left: "15px",
          textStyle: {
            color: "#37A5DD",
            fontSize: 14,
            lineHeight: 10,
            fontWeight: 400,
          },
          subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
        },
        color: ["#3673E8", "#61B8FF"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
          bottom: 10,
          // data: ["上班时间"],
          icon: "circle", // 图例形状
        },
        grid: {
          x: 10,
          x2: 30,
          y2: 15,
          y: 65,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            // lineStyle: {
            //   color: "rgba(43, 113, 176, 0.45)",
            // },
          },
          axisLabel: {
            color: "#fff",
          },
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
            "24:00",
          ],
        },
        yAxis: [
          {
            // name: "单位：V",
            min: 0,
            max: this.yMax,
            interval: this.yMax / 5,
            name: this.unit,
            nameTextStyle: {
              fontSize: 12,
              color: "rgba(230, 247, 255, 0.7)",
              // align: "right",
            },
            type: "value",
            axisLabel: {
              color: "#fff",
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(230,247,255,0.2)",
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#1EE7E7" },
                { offset: 1, color: "rgba(30, 231, 231, 0.35)" },
              ]),
            },
            data: this.seriesData,
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option, true);
      myChart._dom.childNodes[0].childNodes[0].style.width = "87.5rem";
      myChart._dom.childNodes[0].childNodes[0].style.height = "38rem";
    },
    handleClick(tab, event) {
      // console.log(this.activeName);
      let id = this.activeName;
      this.seriesData = this.ydata[id].data;
      this.drawLine1();
    },
  },
  watch: {
    date: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.date = newVal;
        this.initData();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.dialog_echarts {
  .charts {
    width: 87.5rem;
    height: 38rem;
  }
}
</style>
