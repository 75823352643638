<template>
  <div class="computed2">
    <div class="comLeft">
      <table class="H5Table">
        <thead>
          <tr>
            <th>
              <div>阶梯</div>
            </th>
            <th>
              <div>用量</div>
            </th>
            <th>
              <div>电价</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>一级</td>
            <td class="volumn">
              <span>≤</span>
              <el-input
                size="mini"
                class="inp1"
                v-model="input1"
                @blur="inputNumBlur1"
                placeholder="请输入内容"
              ></el-input>
              <span>kWh</span>
            </td>
            <td>
              <input size="mini" class="inp5" v-model="LEVEL1POWERPRICE" @blur="inputNumBlur3" placeholder="请输入内容" />
            </td>
          </tr>
          <tr>
            <td>二级</td>
            <td class="volumn">
              <span>{{ input1 }}kWh -</span>
              <el-input
                size="mini"
                class="inp2"
                v-model="input2"
                @blur="inputNumBlur2"
                placeholder="请输入内容"
              ></el-input>
              <span>kWh</span>
            </td>
            <td>
              <input size="mini" class="inp5" v-model="LEVEL2POWERPRICE" @blur="inputNumBlur4" placeholder="请输入内容" />
            </td>
          </tr>
          <tr>
            <td>三级</td>
            <td>
              <p class="text3">≥ {{ input2 }}kWh</p>
            </td>
            <td>
              <input size="mini" class="inp5" v-model="LEVEL3POWERPRICE" @blur="inputNumBlur5" placeholder="请输入内容" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  name: "innerArea2",
  data() {
    return {
      input1: "400",//阶梯电价的度数划分 1
      input2: "800",//阶梯电价的度数划分 2
      LEVEL1POWERPRICE: "400",
      LEVEL2POWERPRICE: "800",
      LEVEL3POWERPRICE: "400",
      tableData: [
        {
          id: "2",
          date: "一级",
          name: "400",
          address: "上海市普陀区金沙江路",
          time: "111",
        },
        {
          id: "3",
          date: "二级",
          name: "800",
          address: "上海市普陀区金沙江路",
          time: "111",
        },
        {
          id: "4",
          date: "三级",
          name: "800",
          address: "上海市普陀区金沙江路",
          time: "111",
        },
      ],
      dataForm: {
        // 单一电价双向绑定(记得改为后台请求获得)
        elecPriceSingel: "0.00",
        elecPriceSingel2: "0.02",
        measure1: "",
        measure2: "",
        measure3: "",
      },

      // 防抖预设值
      timeout1: null,
      timeout2: null,
      timeout3: null,
    };
  },
  props: ["radioValue"],
  mounted() {
    // this.getEnergyNum();
  },
  methods: {
     //获取对应的信息
    findInfo(key){
      return this.getGeneratorSetting.find((item => item.name === key))
    },
    queryPowerData() {
      this.tableData[0].name = "≤" + this.tableData[0].name + "kWh";
      this.tableData[1].name = "400kWh - " + this.tableData[0].name + "kWh";
    },
    getEnergyNum() {
      this.$store.dispatch("getGeneratorSetting", {
          generator: "ENERGYGENERATOR",
          customerId: this.$store.state.storage.customerId,
        }).then(() => {
        this.elecPiceList = this.getGeneratorSetting;
        this.LEVEL1POWERPRICE = this.findInfo('LEVEL1POWERPRICE').value;
        this.LEVEL2POWERPRICE = this.findInfo('LEVEL2POWERPRICE').value;
        this.LEVEL3POWERPRICE = this.findInfo('LEVEL3POWERPRICE').value;
        //阶梯电价的度数划分
        let amount = JSON.parse(this.findInfo('LEVELSPLIT').value);
        // let amount = JSON.parse(this.elecPiceList[9].value);
        this.input1 = amount.level1;
        this.input2 = amount.level2.split("-")[1];
      });
    },
    inputNumBlur1() {
      let id = this.findInfo('LEVELSPLIT').id;//阶梯电价的度数划分
      let level2 = this.input1 + "-" + this.input2;
      if(this.input1 > this.input2 || this.input1 == this.input2){
        this.$message({
          message:'一级用量不能大于或等于二级用量，请重试！',
          type:'warning'
        })
        this.input1 = "";
        return;
      }
      let value = JSON.stringify({
        level1: this.input1,
        level2: level2,
        level3: this.input2,
      });
      value = {
        value: value,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.radioValue,
      };
      this.$store.dispatch("putGenerator", { id: id, data: value }).then(() => {
        if (this.putGenerator) {
          this.$message({ message: "编辑成功！", type: "success" });
        } else {
          this.$message({ message: "编辑失败，请重试!", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
    inputNumBlur2() {
      let id = this.findInfo('LEVELSPLIT').id;//阶梯电价的度数划分;
      let level2 = this.input1 + "-" + this.input2;
      if(this.input1 > this.input2 || this.input1 == this.input2){
        this.$message({
          message:'二级用量不能小于或等于一级用量，请重试！',
          type:'warning'
        })
        this.input2 = "";
        return;
      }
      let value = JSON.stringify({
        level1: this.input1,
        level2: level2,
        level3: this.input2,
      });
      value = {
        value: value,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.radioValue,
      };
      this.$store.dispatch("putGenerator", { id: id, data: value }).then(() => {
        if (this.putGenerator) {
          this.$message({ message: "编辑成功！", type: "success" });
        } else {
          this.$message({ message: "编辑失败，请重试!", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
    inputNumBlur3() {
      let id = this.findInfo('LEVEL1POWERPRICE').id;
      let value = {
        value: this.LEVEL1POWERPRICE,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.radioValue,
      };
      this.$store.dispatch("putGenerator", { id: id, data: value }).then(() => {
        if (this.putGenerator) {
          this.$message({ message: "编辑成功！", type: "success" });
        } else {
          this.$message({ message: "编辑失败，请重试!", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
    inputNumBlur4() {
      let id = this.findInfo('LEVEL2POWERPRICE').id;
      let value = {
        value: this.LEVEL2POWERPRICE,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.radioValue,
      };
      this.$store.dispatch("putGenerator", { id: id, data: value }).then(() => {
        if (this.putGenerator) {
          this.$message({ message: "编辑成功！", type: "success" });
        } else {
          this.$message({ message: "编辑失败，请重试!", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
    inputNumBlur5() {
      let id = this.findInfo('LEVEL3POWERPRICE').id;;
      let value = {
        value: this.LEVEL3POWERPRICE,
      };
      let id1 = this.findInfo('POWERPRICE_STRATEGY').id;
      let value1 = {
        value: this.radioValue,
      };
      this.$store.dispatch("putGenerator", { id: id, data: value }).then(() => {
        if (this.putGenerator) {
          this.$message({ message: "编辑成功！", type: "success" });
        } else {
          this.$message({ message: "编辑失败，请重试!", type: "warning" });
        }
      });
      this.$store
        .dispatch("putGeneral", {
          id: id1,
          data: value1,
        })
        .then(() => {
          // console.log('保存radioValue');
        });
    },
  },
  computed: {
    ...mapState({
      getGeneratorSetting: (state) => {
        return state.genManagement.getGeneratorSetting.data;
      },
      putGenerator: (state) => {
        return state.genManagement.putGenerator.data;
      },
    }),
  },
  watch: {
    '$store.state.storage.customerId': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal) {
          return
        }
        this.getEnergyNum();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.computed2 {
  .comLeft {
    .volumn {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 5.1rem;
    }
    .inp1 {
      width: 7rem;
      margin: 0 0.8rem;
    }
    .inp2 {
      width: 7rem;
      margin: 0 0.8rem;
    }
    .inp3 {
      width: 7rem;
    }
    .inp4 {
      width: 7rem;
    }
    .inp5 {
      width: 14rem;
      height: 3.2rem;
      line-height: 3.2rem;
      padding-left: 1rem;
      background: rgba(2, 121, 15, 0.2);
      border-radius: 0.2rem;
      border: 0.1rem solid #027969;
      font-size: 1.4rem;
      color: #03d9bc;
    }
    .inp5:focus {
      outline: none;
    }
    .inp5:hover {
      border: 0.1rem solid #03d9bc;
    }
  }
  .comRigth {
    position: absolute;
    right: -50rem;
    top: -5.3rem;
    .con2 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .con-content {
      width: 39.9rem;
      height: 26rem;
      margin-top: 3rem;
      padding-top: 2rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      span {
        position: absolute;
        margin-left: 2rem;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
  .conBottomLeft {
    position: absolute;
    top: 32rem;
    .con3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .conputent {
      width: 31rem;
      height: 13.6rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      margin-top: 3rem;
      // position: relative;
      .con-img-inline {
        position: absolute;

        left: 50%;
        margin-left: -7rem;
        top: 8rem;
        .img1 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
        .img2 {
          width: 1.6rem;
          height: 1.5rem;
          margin-left: 1rem;
          position: relative;
          top: -0.8rem;
        }
        .img3 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
      }
      .con-ipt {
        position: absolute;
        top: 13rem;
        left: 3rem;
        .btn-inp {
          width: 13rem;
          // height: 3.2rem;
        }
        .ipt-left {
          font-size: 1.4rem;
          color: #ffffff;
          margin-right: 1.5rem;
        }
        .ipt-rigth {
          // position: absolute;
          // left: 23.5rem;
          // top: 0.5rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 1.5rem;
        }
      }
    }
  }
  .conBottomRigth {
    position: absolute;
    top: 32rem;
    left: 36rem;
    .con3 {
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #ffffff;
    }
    .conputent {
      width: 31rem;
      height: 13.6rem;
      background: rgba(0, 115, 222, 0.1);
      border: 1px solid #004579;
      box-sizing: border-box;
      margin-top: 3rem;
      // position: relative;
      .con-img-inline {
        position: absolute;
        left: 50%;
        margin-left: -7rem;
        top: 8rem;
        .img1 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
        .img2 {
          width: 1.6rem;
          height: 1.5rem;
          margin-left: 1rem;
          position: relative;
          top: -0.8rem;
        }
        .img3 {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1rem;
        }
      }
      .con-ipt {
        position: absolute;
        top: 13rem;
        left: 3rem;
        .btn-inp {
          width: 13rem;
          // height: 3.2rem;
        }
        .ipt-left {
          font-size: 1.4rem;
          color: #ffffff;
          margin-right: 1.5rem;
        }
        .ipt-rigth {
          // position: absolute;
          // left: 23.5rem;
          // top: 0.5rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 0.85);
          margin-left: 1.5rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.computed2 {
  height: 26rem;
  .H5Table {
    width: 66.2rem;

    padding-bottom: 0.5rem;
    color: #fff;
    border-collapse: collapse;
    tr {
      height: 5.1rem;
      td {
        .el-input__inner {
          height: 3.4rem;
          line-height: 3.4rem;
        }
      }
    }
  }
  .H5Table td,
  .H5Table th {
    text-align: left;
  }
  .H5Table th:first-child {
    width: 16rem;
    padding-left: 1.6rem;
  }
  .H5Table tbody tr td:first-child {
    padding-left: 1.6rem;
  }
  .H5Table th:nth-child(2) {
    width: 31.7rem;
  }

  .H5Table thead {
    font-size: 1.4rem;
    background: rgba(52, 150, 241, 0.15);
    border-bottom: solid 1px rgba(108, 184, 255, 0.3);
  }
  .H5Table tbody tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0;
  }
  .H5Table tbody tr:last-child {
    border-bottom: none;
  }

  .inputNumber {
    .el-input {
      width: 60px;
    }
  }
  .el-radio__label {
    font-size: 1.4rem;
    padding-left: 0.5rem;
  }
  .el-radio {
    margin-right: 1rem;
  }
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #03d9bc;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #03d9bc;
    background: #0d3050;
  }

  .el-radio__inner {
    background-color: #0d3050;
  }

  .el-radio__inner::after {
    background-color: #03d9bc;
  }

  // 计数器样式
  .el-input-number__increase {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease {
    background-color: #0b3d6b;
  }
  .el-input-number .is-controls-right {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #0b3d6b;
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid #027969;
    border-left: 0.1rem solid #027969;
    box-shadow: none;
    border: 0.1rem solid #027969;
    box-sizing: border-box;
    border: none;
  }
  ::-webkit-scrollbar,
  .el-select-dropdown::-webkit-scrollbar {
    width: 0;
  }
  .el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 0.4rem 0 0;
    border-bottom: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }

  .el-input-number.is-controls-right .el-input-number__decrease {
    border-left: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }
  .el-table tr {
    background: rgba(0, 115, 222, 0.1);
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: #0c2f4f;
  }
  .el-table .el-table__cell {
    padding: 1rem 0;
  }
}
</style>
