<template>
  <div class="enerstion-bottom">
    <div class="enersta">
      <div class="enerRouter">
        <div class="enerTopRouter">
          <p :class="ruoter1" @click="changeTab(1)">电站状态</p>
          <p :class="ruoter2" @click="changeTab(2)">逆变器状态</p>
        </div>
        <div class="routerLay">
          <router-view></router-view>
        </div>
      </div>
      <!-- <div class="blank">由光谷信息股份有限公司提供技术支持</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "EnergyStatistical",
  data() {
    return {
      ruoter1: "btnGreen",
      ruoter2: "btnGray",
    };
  },
  mounted() {},
  methods: {
    changeTab(index) {
      if (index === 1) {
        this.ruoter1 = "btnGreen";
        this.ruoter2 = "btnGray";
        this.$router.push({
          path: "/ElecGenerate/MonitoringGenerate/monEnerState",
        });
      } else if (index === 2) {
        this.ruoter1 = "btnGray";
        this.ruoter2 = "btnGreen";
        this.$router.push({
          path: "/ElecGenerate/MonitoringGenerate/monVerterState",
        });
      }
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.enerstion-bottom {
  //   overflow: hidden;
  //   width: 142.8rem;
  //   height: 88.8rem;
  // margin-top: 1.5rem;
  // height: 75rem;
  // overflow: scroll;
  // overflow-x: hidden;
}
.enersta {
  // position: relative;
  // overflow: scroll;
  // overflow-x: hidden;
  width: calc(100% + 0.6rem);
  height: calc(100vh - 19.2rem);
}
.blank {
  position: relative;
  top: 85rem;
  height: 20rem;
  width: 142.8rem;
  color: grey;
  font-size: 1.4rem;
  text-align: center;
  line-height: 10rem;
}
.enerRouter {
  .enerTopRouter {
    margin-top: -3.2rem;
    width: 100%;
    height: 3rem;
    // position: fixed;
    background: #0c2f4f;
    display: flex;
    .btnGreen {
      color: #03d9bc;
      margin-left: 2.6rem;
      cursor: pointer;
      border-bottom: 0.2rem solid #03d9bc;
    }
    .btnGray {
      color: aliceblue;
      margin-left: 2.6rem;
      cursor: pointer;
    }
    font-size: 1.4rem;
  }
  .routerLay {
    position: relative;
    margin-top: 2rem;
    width: 100%;
    height: 86.4rem;
    // width: 142.8rem;
    // position: absolute;
    // right: 0;
    // overflow: hidden;
    background-color: #18191a;
    font-size: 1.4rem;
  }
}
</style>
