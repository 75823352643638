<template>
    <!-- 1逆变器 2环境监测仪 3并网电表 -->
    <el-dialog :title="registerOrEdit == 1 ? '登记' : '编辑'" :visible.sync="centerDialogVisible" :width="dialogWidth" left class="dialogRegister" :close-on-click-modal="false" @close="handleClose">
        <div class="registerBox">
            <!-- 顶部选择框部分 -->
            <div class="registerTop">
                <div class="item">
                    <span class="title"><span class="required">*</span>数据来源</span>
                    <el-select v-model="electricity.sourceType" placeholder="请选择" @change="handleChangeSourceType" :class="showRed12 ? 'inputRed' : ''">
                        <el-option label="手动录入" :value="0" />
                        <el-option label="配电仪表" :value="1" />
                    </el-select>
                    <span class="bottom" v-if="showRed12">不能为空</span>
                </div>
                <div class="item" v-show="electricity.sourceType == 1">
                    <span class="title"><span class="required">*</span>配电仪表</span>
                    <el-select placeholder="请选择" v-model="electricity.meterId" @change="changeMeterId" :class="showRed13 ? 'inputRed' : ''">
                        <el-option v-for="item in meterList" :key="item.id" :label="item.value" :value="item.label">
                        </el-option>
                    </el-select> <span class="bottom" v-if="showRed13">不能为空</span>
                </div>
                <div class="item">
                    <span class="title"><span class="required">*</span>编号</span>
                    <el-input placeholder="请输入编号" v-model="electricity.code" @blur="inputBlur(1)" :class="showRed1 ? 'inputRed' : ''"></el-input>
                    <span class="bottom" v-if="showRed1">不能为空</span>
                </div>
                <div class="item">
                    <span class="title"><span class="required">*</span>型号</span>
                    <el-input placeholder="请输入" v-model="electricity.specification" @blur="inputBlur(2)" :class="showRed2 ? 'inputRed' : ''"></el-input>
                    <span class="bottom" v-if="showRed2">不能为空</span>
                </div>
                <div class="item">
                    <span class="title">SN号</span>
                    <el-input placeholder="请输入" v-model="electricity.serialNum"></el-input>
                </div>
                <div class="item">
                    <span class="title"><span class="required">*</span>设备名称</span>
                    <el-input placeholder="请输入设备名称" v-model="electricity.name" @blur="inputBlur(3)" :class="showRed3 ? 'inputRed' : ''"></el-input>
                    <span class="bottom" v-if="showRed3">不能为空</span>
                </div>
                <div class="item">
                    <span class="title"><span class="required">*</span>设备类型</span>
                    <el-select v-model="electricity.type" placeholder="请输入" @change="changeElecType" :class="showRed4 ? 'inputRed' : ''">
                        <el-option v-for="item in equipmentTypeData" :key="item.type" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                    <span class="bottom" v-if="showRed4">不能为空</span>
                </div>
                <div class="item">
                    <!-- 接口是：客户的电站-根据客户ID获取电站列表 -->
                    <span class="title"><span class="required">*</span>所属电站</span>
                    <el-select v-model="electricity.powerStationName" placeholder="请选择" @change="changeElecPowerStation" :class="showRed5 ? 'inputRed' : ''">
                        <el-option v-for="item in powerstation" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                    <span class="bottom" v-if="showRed5">不能为空</span>
                </div>
                <div class="item" v-show="registerViewType == 1">
                    <span class="title"><span class="required">*</span>额定交流功率(kW)</span>
                    <el-input placeholder="请输入" v-model="electricity.ratedPower" @blur="inputBlur(6)" :class="showRed6 ? 'inputRed' : ''"></el-input>
                    <span class="bottom" v-if="showRed6">不能为空</span>
                    <span class="bottom" v-if="showRed10">请输入数字</span>
                </div>
                <div class="item">
                    <span class="title">电站地址</span>
                    <!-- 值是当选择所属电站之后自动带出来，不用填写 -->
                    <el-input placeholder="请输入" v-model="electricity.powerStationAddr" readonly></el-input>
                </div>
                <div class="item" v-show="registerViewType == 3">
                    <span class="title">电能最值</span>
                    <el-input-number v-model="electricity.maxMetric" placeholder="请输入电能最值" size="mini" controls-position="right" :min="0"></el-input-number>
                </div>
                <div class="item" v-show="registerViewType == 1">
                    <span class="title">设备IP地址</span>
                    <el-input placeholder="请输入" v-model="electricity.ipAddr"></el-input>
                </div>
                <div class="item" v-show="registerViewType == 1">
                    <span class="title"><span class="required">*</span>厂家</span>
                    <el-input placeholder="请输入" v-model="electricity.producer" @blur="inputBlur(7)" :class="showRed7 ? 'inputRed' : ''"></el-input>
                    <span class="bottom" v-if="showRed7">不能为空</span>
                </div>
                <div class="item" v-show="registerViewType == 1">
                    <span class="title">软件版本号</span>
                    <el-input placeholder="请输入" v-model="electricity.softwareVersion"></el-input>
                </div>
                <div class="item" v-show="registerViewType == 1">
                    <span class="title">电网类型</span>
                    <el-input placeholder="请输入" v-model="electricity.elecType"></el-input>
                </div>
                <div class="item" v-show="registerViewType == 1">
                    <span class="title">电能最值</span>
                    <el-input-number placeholder="请输入电能最值" v-model="electricity.maxMetric" size="mini" controls-position="right" :min="0"></el-input-number>
                </div>
                <div class="item" v-show="registerViewType == 1">
                    <span class="title"><span class="required">*</span>组串容量(kW)</span>
                    <el-input placeholder="请输入" v-model="electricity.fullCapacity" @blur="inputBlur(8)" :class="showRed8 ? 'inputRed' : ''"></el-input>
                    <span class="bottom" v-if="showRed8">不能为空</span>
                    <span class="bottom" v-if="showRed11">请输入数字</span>
                </div>
            </div>
            <!-- 下属储能列表 -->
            <div class="subordinateBox" v-show="registerViewType == 1">
                <div class="title">
                    <!-- <span class="required">*</span> -->
                    下属储能：
                </div>
                <!-- 表格区域 -->
                <div class="subordinateTable">
                    <div class="headers">
                        <span class="item1">编号</span>
                        <span class="item2">名称</span>
                        <span class="item3">SN号</span>
                        <span class="item4">型号</span>
                        <span class="item5">额定容量(kVA)</span>
                        <span class="item6">操作</span>
                    </div>
                    <div class="content">
                        <div class="line" v-for="item in subordinateEnergyList" :key="item.id">
                            <span class="item1">{{ item.code }}</span>
                            <span class="item2">{{ item.name }}</span>
                            <span class="item3">{{ item.serialNum }}</span>
                            <span class="item4">{{ item.specification }}</span>
                            <span class="item5">{{ item.capacity }}</span>
                            <span class="item6" @click="deleteSubordinateEnergyLine(item.id)">删除</span>
                        </div>
                    </div>
                </div>
                <!-- 添加框 -->
                <div class="addBtns" v-show="addEnergyShow">
                    <div class="input1">
                        <el-input v-model="addOneSubordinateEnergy.code"></el-input>
                    </div>
                    <div class="input2">
                        <el-input v-model="addOneSubordinateEnergy.name"></el-input>
                    </div>
                    <div class="input3">
                        <el-input v-model="addOneSubordinateEnergy.serialNum"></el-input>
                    </div>
                    <div class="input4">
                        <el-input v-model="addOneSubordinateEnergy.specification"></el-input>
                    </div>
                    <div class="input5">
                        <el-input v-model="addOneSubordinateEnergy.capacity"></el-input>
                    </div>
                    <div class="input6" @click="deleteAddSubordinateEnergyLine"><span>删除</span></div>
                </div>
                <!-- 添加按钮 -->
                <div class="addOneLine" @click="addSubordinateEnergyLine">
                    <img src="@/assets/icons/Union.png" alt="" />
                    添加一行数据
                </div>
            </div>
            <!-- 下属PV -->
            <div class="subordinatePV" v-show="registerViewType == 1">
                <div class="title">
                    <!-- <span class="required">*</span> -->
                    下属PV
                </div>
                <!-- 表格区域 -->
                <div class="subordinateTable">
                    <div class="headers">
                        <span class="item1">序号</span>
                        <span class="item2">名称</span>
                        <span class="item3">额定功率(kW)</span>
                        <span class="item4">操作</span>
                    </div>
                    <div class="content">
                        <div class="line" v-for="item in subordinatePvList" :key="item.indx">
                            <span class="item1">{{ item.indx }}</span>
                            <span class="item2">{{ item.name }}</span>
                            <span class="item3">{{ item.capacity }}</span>
                            <span class="item4" @click="deleteSubordinatePvLine(item.indx)">删除</span>
                        </div>
                    </div>
                </div>
                <!-- 添加框 -->
                <div class="addBtns" v-show="addPvShow">
                    <!-- <div class="input1"><el-input v-model="addOneSubordinatePv.id"></el-input></div> -->
                    <div class="input1">{{ subordinatePvList.length + 1 }}</div>
                    <div class="input2">
                        <el-input v-model="addOneSubordinatePv.name"></el-input>
                    </div>
                    <div class="input3">
                        <el-input v-model="addOneSubordinatePv.capacity"></el-input>
                    </div>
                    <div class="input4"><span @click="deleteAddSubordinatePvLine">删除</span></div>
                </div>
                <!-- 添加按钮 -->
                <div class="addOneLine" @click="addSubordinatePvLine">
                    <img src="@/assets/icons/Union.png" alt="" />
                    添加一行数据
                </div>
            </div>
            <!-- 监测回路部分 -->
            <div class="monitorLoop" v-show="registerViewType == 3">
                <div class="title">
                    <!-- <span class="required">*</span> -->
                    监测回路
                </div>
                <div class="loops">
                    <div class="loopItem" v-for="(item, index) in loopList" :key="item.id">
                        {{ index + 1 }}. {{ item.name }}<span @click="deleteLoop(item.id)">X</span>
                    </div>
                    <div class="addBtn" v-show="addLoopShow">
                        <el-input v-model="addOneLoop"></el-input>
                    </div>
                    <div class="addLoop" @click="addLoop">
                        <img src="@/assets/icons/add.png" alt="" />
                    </div>
                </div>
                <!-- <div class="bottom" v-if="showRed9" style="color:#f56c6c;font-size:1.2rem;">
          不能为空
        </div> -->
            </div>
            <!-- 保存、取消按钮 -->
            <div class="btnBox margnTop">
                <button class="bgc2" @click="cancel">取消</button>
                <!-- 登记保存 -->
                <button class="bgc1" v-if="registerOrEdit == 1" @click="registerData">
                    保存
                </button>
                <!-- 编辑保存 -->
                <button class="bgc1" v-else @click="edit">保存</button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { number } from 'echarts';
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            // powerstation: (state) => {
            //   return state.electricity.allUserInfo;
            // },
            allRegisterInfo: (state) => {
                return state.electricity.allFromInfo;
            },
        }),
    },
    props: ["id", "editData", "registerOrEdit", "downIdx", "powerStationList"],
    data () {
        return {
            dialogWidth: "60%",
            centerDialogVisible: false,
            options: [],
            value: "",
            tabList: ["编号", "名称", "SN号", "型号", "额定容量", "操作"],
            addData: [],
            // <!-- 1逆变器 2环境监测仪 3并网电表 -->
            equipmentTypeData: [
                {
                    name: "逆变器",
                    type: 1,
                },
                {
                    name: "环境监测仪",
                    type: 2,
                },
                {
                    name: "并网电表",
                    type: 3,
                },
            ],
            quuimentType: 1,
            // 需要提交的from表单
            electricity: {
                code: "", //编号
                specification: "", //型号
                serialNum: "", //SN号
                name: "", //设备名称
                type: "逆变器", //设备类型
                powerStationName: "", //所属电站
                ratedPower: "", //额定交流功率
                powerStationAddr: "", //电站地址
                ipAddr: "", //设备ip地址
                producer: "", //厂家
                softwareVersion: "", // 软件版本号
                ownerId: "",
                powerStationId: "",
                inverter: "", // 所属逆变器
                elecType: "", // 电网类型
                fullCapacity: "", // 组串容量
                sourceType: 0,
                meterId: '',
                maxMetric: 0
            },
            // 1是逆变器  2是环境监测仪  3是并网电表
            registerViewType: 1,
            // 下属储能
            subordinateEnergyList: [
                {
                    id: 1, // 可用于操作、删除等功能，传递参数时不带id
                    capacity: 50, // 容量
                    code: 1, // 编号
                    name: "储能888号", // 名称
                    serialNum: "SN666", //SN号
                    specification: "X555", //型号
                },
            ],
            // 储能列表长度
            subordinateEnergyListLen: 0,
            // 储能添加一行的数据
            addOneSubordinateEnergy: {
                id: 0, // 可用于操作、删除等功能，传递参数时不带id
                capacity: "", // 容量
                code: "", // 编号
                name: "", // 名称
                serialNum: "", //SN号
                specification: "", //型号
            },
            addEnergyShow: false,
            // 下属PV
            subordinatePvList: [
                {
                    indx: 0,
                    name: "PV1", // 名称
                    capacity: "50", // 额定功率
                },
            ],
            // PV列表长度
            subordinatePvListLen: 0,
            addOneSubordinatePv: {
                indx: 0,
                name: "", // 名称
                capacity: "", // 额定功率
            },
            addPvShow: false,
            // 监测回路列表
            loopList: [
                {
                    id: 1,
                    name: "1. 回路1",
                },
            ],
            // 添加的回路
            addOneLoop: "",
            addLoopShow: false,
            // 回路列表的长度
            loopsLen: 0,
            // 所属电站列表
            powerstation: [],
            // 所属逆变器列表
            // inverterList: [],
            tempId: "",
            id1: "",
            inverterId: "",
            showRed1: false,
            showRed2: false,
            showRed3: false,
            showRed4: false,
            showRed5: false,
            showRed6: false,
            showRed7: false,
            showRed8: false,
            showRed9: false,
            showRed10: false,
            showRed11: false,
            showRed12: false,
            showRed13: false,
            meterList: [],
        };
    },
    created () {
        // this.electricity.type = '逆变器';
    },
    mounted () { },
    methods: {
        //来源类型事件
        handleChangeSourceType (val) {
            if (val == '1') {
                this.getMeterList();
            }
            if (this.electricity?.sourceType === "") {
                this.showRed12 = true;
                return;
            } else {
                this.showRed12 = false;
            }
        },
        //来源类型事件
        changeMeterId (val) {
            if (this.electricity?.meterId == "") {
                this.showRed13 = true;
                return;
            } else {
                this.showRed13 = false;
            }
        },
        // 获取配电仪表
        getMeterList () {
            this.$store.dispatch("getMeterList", this.id).then(() => {
                console.log(this.$store.state)
                this.meterList = this.$store.state.metrAdministration.meterList.data;
            });
        },
        close1 () { },
        initBlur () {
            this.showRed1 = false;
            this.showRed2 = false;
            this.showRed3 = false;
            this.showRed4 = false;
            this.showRed5 = false;
            this.showRed6 = false;
            this.showRed7 = false;
            this.showRed8 = false;
            this.showRed9 = false;
            this.showRed10 = false;
            this.showRed11 = false;
            this.showRed12 = false;
            this.showRed13 = false;
        },
        inputBlur (index) {
            let reg = /^\d+(\.\d+)?$/; //非负浮点数
            if (index == 1) {
                if (this.electricity.code == "") {
                    this.showRed1 = true;
                } else {
                    this.showRed1 = false;
                }
            } else if (index == 2) {
                if (this.electricity.specification == "") {
                    this.showRed2 = true;
                } else {
                    this.showRed2 = false;
                }
            } else if (index == 3) {
                if (this.electricity.name == "") {
                    this.showRed3 = true;
                } else {
                    this.showRed3 = false;
                }
            } else if (index == 6) {
                if (this.electricity.ratedPower == "") {
                    this.showRed10 = false;
                    this.showRed6 = true;
                } else {
                    this.showRed6 = false;
                    if (reg.test(this.electricity.ratedPower)) {
                        this.showRed10 = false;
                    } else {
                        this.showRed10 = true;
                    }
                }
            } else if (index == 7) {
                if (this.electricity.producer == "") {
                    this.showRed7 = true;
                } else {
                    this.showRed7 = false;
                }
            } else if (index == 8) {
                if (this.electricity.fullCapacity == "") {
                    this.showRed11 = false;
                    this.showRed8 = true;
                } else {
                    this.showRed8 = false;
                    if (reg.test(this.electricity.fullCapacity)) {
                        this.showRed11 = false;
                    } else {
                        this.showRed11 = true;
                    }
                }
            }
        },
        clearObj () {
            this.$nextTick(() => {
                if (this.registerOrEdit === 1) {
                    for (let k in this.electricity) {
                        this.electricity[k] = "";
                    }
                    this.addData.forEach((item, index) => {
                        for (let k in item) {
                            item[k] = "";
                        }
                    });
                }
                this.electricity.sourceType = 0;
            });
        },
        open () {
            this.$nextTick(() => {
                this.centerDialogVisible = true;
                this.subordinateEnergyList = [];
                this.subordinatePvList = [];
                this.addEnergyShow = false;
                this.addOneSubordinateEnergy = this.$options.data().addOneSubordinateEnergy;
                this.addPvShow = false;
                this.addOneSubordinatePv = this.$options.data().addOneSubordinatePv
                if (this.registerOrEdit == 1) {
                    this.subordinateEnergyList = [];
                    this.subordinatePvList = [];
                    this.loopList = [];
                    this.subordinateEnergyListLen = this.subordinateEnergyList.length;
                    this.subordinatePvListLen = this.subordinatePvList.length;
                    this.loopsLen = this.loopList.length;
                    this.electricity.type = "逆变器";
                    this.registerViewType = 1;
                    this.powerstation = this.powerStationList;
                } else if (this.registerOrEdit == 2) {
                    let id = this.editData.ownerId;
                    this.$store.dispatch("powerstation", id).then(() => {
                        this.powerstation = this.$store.state.electricity.allUserInfo.data;
                    });
                    if (this.editData.type == 1) {
                        this.$nextTick(() => {
                            this.registerViewType = 1;
                            this.electricity.type = "逆变器";
                        });
                    } else if (this.editData.type == 2) {
                        this.$nextTick(() => {
                            this.registerViewType = 2;
                            this.electricity.type = "环境监测仪";
                        });
                    } else if (this.editData.type == 3) {
                        this.$nextTick(() => {
                            this.registerViewType = 3;
                            this.electricity.type = "并网电表";
                        });
                    }
                }
            });
        },
        close () {
            for (let key in this.subordinatePvList) {
                this.subordinatePvList[key] = "";
            }
            for (let key in this.subordinateEnergyList) {
                this.subordinateEnergyList[key] = "";
            }
            this.loopList = [];
            this.addOneLoop = "";
            this.addLoopShow = false;
            this.centerDialogVisible = false;
        },
        cancel () {
            for (let key in this.subordinatePvList) {
                this.subordinatePvList[key] = "";
            }
            for (let key in this.subordinateEnergyList) {
                this.subordinateEnergyList[key] = "";
            }
            this.loopList = [];
            this.addOneLoop = "";
            this.addLoopShow = false;
            this.centerDialogVisible = false;
            this.initBlur();
        },
        handleClose () {
            for (let key in this.subordinatePvList) {
                this.subordinatePvList[key] = "";
            }
            for (let key in this.subordinateEnergyList) {
                this.subordinateEnergyList[key] = "";
            }
            this.loopList = [];
            this.addOneLoop = "";
            this.addLoopShow = false;
            this.centerDialogVisible = false;
            this.initBlur();
        },
        add () {
            let newAddData = {
                code: "",
                name: "",
                serialNum: "",
                specification: "",
                capacity: "",
            };
            this.addData.push(newAddData);
            this.electricity["batteries"] = this.addData;
        },
        deleteData (idx) {
            this.addData.splice(idx, 1);
            this.electricity.batteries = this.addData;
            // <!-- 1逆变器 2环境监测仪 3并网电表 -->
            this.$store.dispatch("ediTgenerator", this.electricity).then(() => {
                this.$parent.generatorPage();
            });
        },
        allStationName (e) {
            this.electricity.ownerId = this.id;
            this.powerstation.data.forEach((item, index) => {
                if (e == item.id) {
                    this.electricity.powerStationName = item.name;
                    this.electricity.powerStationAddr = item.address;
                    this.electricity.powerStationId = item.id;
                }
            });
        },
        // 登记提交数据
        registerData () {
            let reg = /^\d+(\.\d+)?$/; //非负浮点数
            // 数据来源
            if (this.electricity.sourceType === '') {
                this.showRed12 = true;
                return;
            } else if (this.electricity.sourceType === 1 && !this.electricity.meterId) {
                this.showRed13 = true;
                return;
            }
            if (this.electricity.type == "逆变器") {
                if (!this.electricity.code) {
                    this.showRed1 = true;
                    return;
                }
                if (!this.electricity.specification) {
                    this.showRed2 = true;
                    return;
                }
                if (!this.electricity.name) {
                    this.showRed3 = true;
                    return;
                }
                if (!this.electricity.type) {
                    this.showRed4 = true;
                    return;
                }
                if (!this.electricity.powerStationName) {
                    this.showRed5 = true;
                    return;
                }
                if (!this.electricity.ratedPower) {
                    this.showRed6 = true;
                    return;
                }
                if (!this.electricity.producer) {
                    this.showRed7 = true;
                    return;
                }
                if (!this.electricity.fullCapacity) {
                    this.showRed8 = true;
                    return;
                }
                if (!reg.test(this.electricity.ratedPower)) {
                    this.showRed10 = true;
                    return;
                }
                if (!reg.test(this.electricity.fullCapacity)) {
                    this.showRed11 = true;
                    return;
                }
                if (this.addOneSubordinatePv.name) {
                    let obj = {
                        indx: this.subordinatePvList.length + 1,
                        name: this.addOneSubordinatePv.name,
                        capacity: this.addOneSubordinatePv.capacity,
                    };
                    this.subordinatePvList.push(obj);
                    this.addOneSubordinatePv.name = "";
                    this.addOneSubordinatePv.capacity = "";
                }
                if (this.addOneSubordinateEnergy.name) {
                    let obj = {
                        id: this.subordinateEnergyListLen + 1,
                        name: this.addOneSubordinateEnergy.name,
                        capacity: this.addOneSubordinateEnergy.capacity,
                        code: this.addOneSubordinateEnergy.code,
                        serialNum: this.addOneSubordinateEnergy.serialNum,
                        specification: this.addOneSubordinateEnergy.specification,
                    };
                    this.subordinateEnergyList.push(obj);
                    this.addOneSubordinateEnergy.name = "";
                    this.addOneSubordinateEnergy.capacity = "";
                    this.addOneSubordinateEnergy.code = "";
                    this.addOneSubordinateEnergy.serialNum = "";
                    this.addOneSubordinateEnergy.specification = "";
                }
                let batteries = [];
                for (let i = 0; i < this.subordinateEnergyList.length; i++) {
                    delete this.subordinateEnergyList[i].id;
                }
                batteries = this.subordinateEnergyList;
                let pvs = [];
                pvs = this.subordinatePvList;
                let value = {
                    code: this.electricity.code,
                    specification: this.electricity.specification,
                    serialNum: this.electricity.serialNum,
                    name: this.electricity.name,
                    type: 1,
                    powerStationId: this.electricity.powerStationName,
                    ratedPower: this.electricity.ratedPower,
                    ipAddr: this.electricity.ipAddr,
                    producer: this.electricity.producer,
                    softwareVersion: this.electricity.softwareVersion,
                    gridType: this.electricity.elecType,
                    fullCapacity: this.electricity.fullCapacity,
                    batteries: batteries,
                    pvs: pvs,
                    ownerId: this.id,
                    sourceType: this.electricity.sourceType,
                    meterId: this.electricity.meterId,
                    maxMetric: this.electricity.maxMetric,
                };
                this.$store.dispatch("generator", value).then(() => {
                    if (this.allRegisterInfo.data) {
                        this.$parent.generatorPage();
                        this.centerDialogVisible = false;
                        this.addEnergyShow = false;
                        this.addPvShow = false;
                        for (let key in this.subordinatePvList) {
                            this.subordinatePvList[key] = "";
                        }
                        for (let key in this.subordinateEnergyList) {
                            this.subordinateEnergyList[key] = "";
                        }
                        this.initBlur();
                        this.$message({
                            message: "登记成功~",
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: this.allRegisterInfo.message,
                        });
                    }
                });
            } else if (this.electricity.type == "环境监测仪") {
                if (!this.electricity.code) {
                    this.showRed1 = true;
                    return;
                }
                if (!this.electricity.specification) {
                    this.showRed2 = true;
                    return;
                }
                if (!this.electricity.name) {
                    this.showRed3 = true;
                    return;
                }
                if (!this.electricity.type) {
                    this.showRed4 = true;
                    return;
                }
                if (!this.electricity.powerStationName) {
                    this.showRed5 = true;
                    return;
                }
                let value = {
                    code: this.electricity.code,
                    specification: this.electricity.specification,
                    name: this.electricity.name,
                    serialNum: this.electricity.serialNum,
                    type: 2,
                    powerStationId: this.electricity.powerStationName,
                    ownerId: this.id,
                    inverterId: this.electricity.inverter,
                    sourceType: this.electricity.sourceType,
                    meterId: this.electricity.meterId,
                    maxMetric: this.electricity.maxMetric,
                };
                this.$store.dispatch("generator", value).then(() => {
                    if (this.allRegisterInfo.data) {
                        this.$parent.generatorPage();
                        this.centerDialogVisible = false;
                        this.initBlur();
                        this.$message({
                            message: "登记成功~",
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: this.allRegisterInfo.message,
                        });
                    }
                });
            } else if (this.electricity.type == "并网电表") {
                if (!this.electricity.code) {
                    this.showRed1 = true;
                    return;
                }
                if (!this.electricity.specification) {
                    this.showRed2 = true;
                    return;
                }
                if (!this.electricity.name) {
                    this.showRed3 = true;
                    return;
                }
                if (!this.electricity.type) {
                    this.showRed4 = true;
                    return;
                }
                if (!this.electricity.powerStationName) {
                    this.showRed5 = true;
                    return;
                }
                if (this.addOneLoop) {
                    let obj = {
                        id: this.loopsLen + 1,
                        name: this.addOneLoop,
                    };
                    this.loopList.push(obj);
                    this.addOneLoop = "";
                }
                let circuits = [];
                for (let i = 0; i < this.loopList.length; i++) {
                    let obj = {
                        name: this.loopList[i].name,
                    };
                    circuits.push(obj);
                }
                let value = {
                    code: this.electricity.code,
                    specification: this.electricity.specification,
                    name: this.electricity.name,
                    type: 3,
                    powerStationId: this.electricity.powerStationName,
                    circuits: circuits,
                    ownerId: this.id,
                    sourceType: this.electricity.sourceType,
                    meterId: this.electricity.meterId,
                    maxMetric: this.electricity.maxMetric,
                };
                this.$store.dispatch("generator", value).then(() => {
                    if (this.allRegisterInfo.data) {
                        this.$parent.generatorPage();
                        this.centerDialogVisible = false;
                        this.addLoopShow = false;
                        this.initBlur();
                        this.$message({
                            message: "登记成功~",
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: this.allRegisterInfo.message,
                        });
                    }
                });
            }
        },
        // 这个是编辑提交
        echo () {
            this.getMeterList()
            this.$nextTick(() => {
                // 这个是点击编辑回显的时候的逻辑
                let x = this.editData;
                let y = this.electricity;
                let d = this.equipmentTypeData;
                this.clearEditData(y);
                Object.keys(x).forEach((key) => {
                    y[key] = x[key];
                    if (key === "type") {
                        d.forEach((item, index) => {
                            if (item.type == x[key]) {
                                y.type = item.type;
                            }
                        });
                    }
                });
            });
        },
        clearEditData (obj) {
            for (let k in obj) {
                obj[k] = "";
            }
        },
        edit () {
            let reg = /^\d+(\.\d+)?$/; //非负浮点数
            // 数据来源
            if (this.electricity.sourceType === '') {
                this.showRed12 = true;
                return;
            } else if (this.electricity.sourceType == 1 && !this.electricity.meterId) {
                this.showRed13 = true;
                return;
            }
            if (this.electricity.type == "逆变器") {
                if (!this.electricity.code) {
                    this.showRed1 = true;
                    return;
                }
                if (!this.electricity.specification) {
                    this.showRed2 = true;
                    return;
                }
                if (!this.electricity.name) {
                    this.showRed3 = true;
                    return;
                }
                if (!this.electricity.type) {
                    this.showRed4 = true;
                    return;
                }
                if (!this.electricity.powerStationName) {
                    this.showRed5 = true;
                    return;
                }
                if (!this.electricity.ratedPower) {
                    this.showRed6 = true;
                    return;
                }
                if (!this.electricity.producer) {
                    this.showRed7 = true;
                    return;
                }
                if (!this.electricity.fullCapacity) {
                    this.showRed8 = true;
                    return;
                }
                if (!reg.test(this.electricity.ratedPower)) {
                    this.showRed10 = true;
                    return;
                }
                if (!reg.test(this.electricity.fullCapacity)) {
                    this.showRed11 = true;
                    return;
                }
                if (this.addOneSubordinatePv.name) {
                    let obj = {
                        indx: this.subordinatePvList.length + 1,
                        name: this.addOneSubordinatePv.name,
                        capacity: this.addOneSubordinatePv.capacity,
                    };
                    this.subordinatePvList.push(obj);
                    this.addOneSubordinatePv.name = "";
                    this.addOneSubordinatePv.capacity = "";
                }
                if (this.addOneSubordinateEnergy.name) {
                    let obj = {
                        id: this.subordinateEnergyListLen + 1,
                        name: this.addOneSubordinateEnergy.name,
                        capacity: this.addOneSubordinateEnergy.capacity,
                        code: this.addOneSubordinateEnergy.code,
                        serialNum: this.addOneSubordinateEnergy.serialNum,
                        specification: this.addOneSubordinateEnergy.specification,
                    };
                    this.subordinateEnergyList.push(obj);
                    this.addOneSubordinateEnergy.name = "";
                    this.addOneSubordinateEnergy.capacity = "";
                    this.addOneSubordinateEnergy.code = "";
                    this.addOneSubordinateEnergy.serialNum = "";
                    this.addOneSubordinateEnergy.specification = "";
                }
                let batteries = [];
                for (let i = 0; i < this.subordinateEnergyList.length; i++) {
                    delete this.subordinateEnergyList[i].id;
                }
                batteries = this.subordinateEnergyList;
                let pvs = [];
                pvs = this.subordinatePvList;
                if (typeof this.electricity.powerStationName !== "number") {
                    console.log(this.tempId)
                    this.electricity.powerStationName = this.tempId;
                }
                let value = {
                    code: this.electricity.code,
                    specification: this.electricity.specification,
                    serialNum: this.electricity.serialNum,
                    name: this.electricity.name,
                    type: 1,
                    powerStationId: this.electricity.powerStationName,
                    ratedPower: this.electricity.ratedPower,
                    ipAddr: this.electricity.ipAddr,
                    producer: this.electricity.producer,
                    softwareVersion: this.electricity.softwareVersion,
                    gridType: this.electricity.elecType,
                    fullCapacity: this.electricity.fullCapacity,
                    batteries: batteries,
                    pvs: pvs,
                    ownerId: this.id,
                    id: this.id1,
                    sourceType: this.electricity.sourceType,
                    meterId: this.electricity.meterId,
                    maxMetric: this.electricity.maxMetric,
                };
                this.$store.dispatch("ediTgenerator", value).then(() => {
                    let result = this.$store.state.electricity.ediTgenerator;
                    if (result.data) {
                        this.$parent.generatorPage();
                        this.centerDialogVisible = false;
                        this.addEnergyShow = false;
                        this.addPvShow = false;
                        for (let key in this.subordinatePvList) {
                            this.subordinatePvList[key] = "";
                        }
                        for (let key in this.subordinateEnergyList) {
                            this.subordinateEnergyList[key] = "";
                        }
                        this.initBlur();
                        this.$message({
                            message: "编辑成功~",
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: result.message,
                        });
                    }
                });
            } else if (this.electricity.type == "环境监测仪") {
                if (!this.electricity.code) {
                    this.showRed1 = true;
                    return;
                }
                if (!this.electricity.specification) {
                    this.showRed2 = true;
                    return;
                }
                if (!this.electricity.name) {
                    this.showRed3 = true;
                    return;
                }
                if (!this.electricity.type) {
                    this.showRed4 = true;
                    return;
                }
                if (!this.electricity.powerStationName) {
                    this.showRed5 = true;
                    return;
                }
                if (typeof this.electricity.powerStationName !== "number") {
                    this.electricity.powerStationName = this.tempId;
                }
                let value = {
                    code: this.electricity.code,
                    specification: this.electricity.specification,
                    name: this.electricity.name,
                    serialNum: this.electricity.serialNum,
                    type: 2,
                    powerStationId: this.electricity.powerStationName,
                    ownerId: this.id,
                    inverterId: typeof this.electricity.inverter !== "number" ? this.inverterId : this.electricity.inverter,
                    id: this.id1,
                    sourceType: this.electricity.sourceType,
                    meterId: this.electricity.meterId,
                    maxMetric: this.electricity.maxMetric,
                };
                this.$store.dispatch("ediTgenerator", value).then(() => {
                    let result = this.$store.state.electricity.ediTgenerator;
                    if (result.data) {
                        this.$parent.generatorPage();
                        this.centerDialogVisible = false;
                        this.initBlur();
                        this.$message({
                            message: "编辑成功~",
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: result.message,
                        });
                    }
                });
            } else if (this.electricity.type == "并网电表") {
                if (!this.electricity.code) {
                    this.showRed1 = true;
                    return;
                }
                if (!this.electricity.specification) {
                    this.showRed2 = true;
                    return;
                }
                if (!this.electricity.name) {
                    this.showRed3 = true;
                    return;
                }
                if (!this.electricity.type) {
                    this.showRed4 = true;
                    return;
                }
                if (!this.electricity.powerStationName) {
                    this.showRed5 = true;
                    return;
                }
                // if (!this.loopList.length && !this.addOneLoop) {
                //   this.showRed9 = true;
                //   return;
                // }
                if (this.addOneLoop) {
                    let obj = {
                        id: this.loopsLen + 1,
                        name: this.addOneLoop,
                    };
                    this.loopList.push(obj);
                    this.addOneLoop = "";
                }
                let circuits = [];
                for (let i = 0; i < this.loopList.length; i++) {
                    if (parseInt(this.loopList[i].id) > 1000) {
                        let obj = {
                            id: this.loopList[i].id,
                            name: this.loopList[i].name,
                        };
                        circuits.push(obj);
                    } else {
                        let obj = {
                            name: this.loopList[i].name,
                        };
                        circuits.push(obj);
                    }
                }
                if (typeof this.electricity.powerStationName !== "number") {
                    this.electricity.powerStationName = this.tempId;
                }
                let value = {
                    code: this.electricity.code,
                    specification: this.electricity.specification,
                    name: this.electricity.name,
                    type: 3,
                    powerStationId: this.electricity.powerStationName,
                    serialNum: this.electricity.serialNum,
                    circuits: circuits,
                    ownerId: this.id,
                    id: this.id1,
                    sourceType: this.electricity.sourceType,
                    meterId: this.electricity.meterId,
                    maxMetric: this.electricity.maxMetric,
                };
                // console.log(value);
                this.$store.dispatch("ediTgenerator", value).then(() => {
                    let result = this.$store.state.electricity.ediTgenerator;
                    if (result.data) {
                        this.$parent.generatorPage();
                        this.centerDialogVisible = false;
                        this.addLoopShow = false;
                        this.initBlur();
                        this.$message({
                            message: "编辑成功~",
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: result.message,
                        });
                    }
                });
            }
        },
        // 这个是获取列表的
        getDownInfoList () {
            this.$nextTick(() => {
                this.$store.dispatch("downkaiyuanGenerator", this.editData.id).then(() => {
                    console.log("111",this.$store.state.electricity.alldownlist.data);
                    this.addData = this.$store.state.electricity.alldownlist.data.batteries;
                    this.loopList = this.$store.state.electricity.alldownlist.data.circuits;
                    this.subordinateEnergyList = this.$store.state.electricity.alldownlist.data.batteries;
                    this.subordinatePvList = this.$store.state.electricity.alldownlist.data.pvs;
                    // console.log(this.$store.state.electricity.alldownlist.data);
                    this.loopsLen = this.$store.state.electricity.alldownlist.data.circuits.length;
                    this.id = this.$store.state.electricity.alldownlist.data.ownerId;
                    this.tempId = this.$store.state.electricity.alldownlist.data.powerStationId;
                    this.id1 = this.$store.state.electricity.alldownlist.data.id;
                    this.electricity.inverter = this.$store.state.electricity.alldownlist.data.inverterName;
                    this.inverterId = this.$store.state.electricity.alldownlist.data.inverterId;
                    this.electricity.elecType = this.$store.state.electricity.alldownlist.data.gridType;
                    this.electricity.fullCapacity = this.$store.state.electricity.alldownlist.data.fullCapacity;
                    this.electricity.sourceType = this.$store.state.electricity.alldownlist.data.sourceType;
                    this.electricity.meterId = this.$store.state.electricity.alldownlist.data.meterId.toString();
                });
                // this.$store.dispatch("getInverters", this.tempId).then(() => {
                //   this.inverterList = this.$store.state.electricity.inverterList.data;
                // });
            });
        },
        changeElecType () {
            if (this.electricity.type == "") {
                this.showRed4 = true;
                return;
            } else {
                this.showRed4 = false;
            }
            if (this.electricity.type == "逆变器") {
                this.registerViewType = 1;
            } else if (this.electricity.type == "环境监测仪") {
                this.registerViewType = 2;
            } else if (this.electricity.type == "并网电表") {
                this.registerViewType = 3;
            }
        },
        changeElecPowerStation (val) {
            // this.electricity.powerStationName 的值设定为了 id值，但下拉框显示的是name
            // console.log(this.electricity.powerStationName);
            this.tempId = val
            if (this.electricity.powerStationName == "") {
                this.showRed5 = true;
                return;
            } else {
                this.showRed5 = false;
            }
            // 根据电站id获取逆变器列表
            // let id = this.electricity.powerStationName;
            // console.log(id);
            // this.$store.dispatch("getInverters", id).then(() => {
            //   this.inverterList = this.$store.state.electricity.inverterList.data;
            // });

            // 根据电站id更新电站地址
            for (let i = 0; i < this.powerstation.length; i++) {
                const item = this.powerstation[i];
                if (item.id == this.electricity.powerStationName) {
                    this.electricity.powerStationAddr = item.address;
                }
            }
        },
        changeElecInverter () { },
        deleteSubordinateEnergyLine (idx) {
            for (let i = 0; i < this.subordinateEnergyList.length; i++) {
                if (idx === this.subordinateEnergyList[i].id) {
                    this.subordinateEnergyList.splice(i, 1);
                }
            }
        },
        deleteAddSubordinateEnergyLine () {
            this.addEnergyShow = false;
            this.addOneSubordinateEnergy.name = "";
            this.addOneSubordinateEnergy.capacity = "";
            this.addOneSubordinateEnergy.code = "";
            this.addOneSubordinateEnergy.serialNum = "";
            this.addOneSubordinateEnergy.specification = "";
        },
        addSubordinateEnergyLine () {
            if (!this.addEnergyShow) {
                this.addEnergyShow = true;
            } else {
                if (this.addOneSubordinateEnergy.name) {
                    // 此时的this.addOneSubordinatePv 为空，是因为el-input事件没有绑定事件，将值赋给 this.addOneSubordinatePv。
                    // console.log(this.addOneSubordinatePv);
                    let id = this.subordinateEnergyList.length + 1;
                    let obj = {
                        id: id,
                        name: this.addOneSubordinateEnergy.name,
                        capacity: this.addOneSubordinateEnergy.capacity,
                        code: this.addOneSubordinateEnergy.code,
                        serialNum: this.addOneSubordinateEnergy.serialNum,
                        specification: this.addOneSubordinateEnergy.specification,
                    };
                    this.subordinateEnergyList.push(obj);
                    this.addOneSubordinateEnergy.name = "";
                    this.addOneSubordinateEnergy.capacity = "";
                    this.addOneSubordinateEnergy.code = "";
                    this.addOneSubordinateEnergy.serialNum = "";
                    this.addOneSubordinateEnergy.specification = "";
                } else {
                    this.$message({
                        message: "名称不能为空",
                        type: "warning",
                    });
                }
            }
        },
        deleteSubordinatePvLine (idx) {
            // console.log(this.subordinatePvList);
            // console.log(idx);
            for (let i = 0; i < this.subordinatePvList.length; i++) {
                if (idx === this.subordinatePvList[i].indx) {
                    this.subordinatePvList.splice(i, 1);
                }
            }
            this.subordinatePvList.forEach((item, index) => {
                item.indx = index + 1
            })
        },
        deleteAddSubordinatePvLine () {
            this.addPvShow = false;
            this.addOneSubordinatePv.name = "";
            this.addOneSubordinatePv.capacity = "";
        },
        addSubordinatePvLine () {
            this.subordinatePvListLen = this.subordinatePvList.length;
            if (!this.addPvShow) {
                this.addPvShow = true;
            } else {
                if (this.addOneSubordinatePv.name) {
                    // 此时的this.addOneSubordinatePv 为空，是因为el-input事件没有绑定事件，将值赋给 this.addOneSubordinatePv。
                    // console.log(this.addOneSubordinatePv);
                    let id = this.subordinatePvListLen + 1;
                    let obj = {
                        indx: id,
                        name: this.addOneSubordinatePv.name,
                        capacity: this.addOneSubordinatePv.capacity,
                    };
                    this.subordinatePvList.push(obj);
                    this.subordinatePvListLen = this.subordinatePvList.length;
                    this.addOneSubordinatePv.name = "";
                    this.addOneSubordinatePv.capacity = "";
                } else {
                    this.$message({
                        message: "PV名称不能为空",
                        type: "warning",
                    });
                }
            }
        },
        addLoop () {
            this.addLoopShow = true;
            if (this.addOneLoop) {
                let id = this.loopsLen + 1;
                let obj = {
                    id: id,
                    name: this.addOneLoop,
                };
                this.loopList.push(obj);
                this.addOneLoop = "";
            }
        },
        deleteLoop (idx) {
            for (let i = 0; i < this.loopList.length; i++) {
                if (this.loopList[i].id == idx) {
                    this.loopList.splice(i, 1);
                }
            }
        },
    },
    watch: {
        registerViewType: {
            immediate: true,
            handler (newVal, oldVal) {
                if (!newVal) return;
                if (newVal == 1) {
                    this.$nextTick(() => {
                        this.dialogWidth = "118.8rem";
                    });
                } else if (newVal == 2 || newVal == 3) {
                    this.$nextTick(() => {
                        this.dialogWidth = "80rem";
                    });
                }
            },
        },
    },
};
</script>
<style lang="less" scoped>
html,
body {
    height: 100%;
}
/deep/.el-dialog__header {
    display: block !important;
    background: linear-gradient(
        225deg,
        rgba(15, 36, 55, 0.0001) 0.03%,
        rgba(0, 115, 222, 0.7) 99.9%
    ) !important;
    border-radius: 0.4rem 0.4rem 0rem 0rem !important;
    // padding: 1.6rem 2.4rem !important;
    height: 5.4rem;
    line-height: 5.4rem;
    padding-left: 2.4rem !important;
    .el-dialog__title {
        font-size: 1.4rem !important;
        color: #fff;
    }
}
/deep/.el-dialog__body {
    //   height: 500px;
    max-height: 63rem;
    min-height: 50rem;
    display: flex;
    background-color: #0c375e !important;
}
.subordinateBox {
    margin: 0 2.4rem 0 2.4rem;
    .title {
        margin: 0 0 2.2rem 0;
        font-size: 1.4rem;
        color: hsla(0, 0%, 100%, 0.85);
        .required {
            margin-top: 1.8rem;
            color: #f56c6c;
            margin-right: 0.4rem;
        }
    }
    .headers {
        height: 5.2rem;
        line-height: 5.2rem;
        width: 100%;
        background-color: rgba(52, 150, 241, 0.15);
        font-size: 1.4rem;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        span {
            display: inline-block;
            padding-left: 1rem;
        }
        .item1 {
            width: 15.1rem;
        }
        .item2 {
            width: 25.8rem;
        }
        .item3 {
            width: 19rem;
        }
        .item4 {
            width: 18rem;
        }
        .item5 {
            width: 18rem;
        }
        .item6 {
            width: 8rem;
        }
    }
    .content {
        .line {
            height: 5.2rem;
            line-height: 5.2rem;
            width: 100%;
            font-size: 1.4rem;
            color: #fff;
            border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            span {
                display: inline-block;
                padding-left: 1rem;
            }
            .item1 {
                width: 15.1rem;
            }
            .item2 {
                width: 25.8rem;
            }
            .item3 {
                width: 19rem;
            }
            .item4 {
                width: 18rem;
            }
            .item5 {
                width: 18rem;
            }
            .item6 {
                width: 8rem;
                color: #03d9bc;
                cursor: pointer;
            }
        }
    }
    .addBtns {
        height: 5.2rem;
        line-height: 5.2rem;
        width: 100%;
        font-size: 1.4rem;
        color: #fff;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        div {
            display: inline-block;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        .input1 {
            width: 15.1rem;
        }
        .input2 {
            width: 25.8rem;
        }
        .input3 {
            width: 19rem;
        }
        .input4 {
            width: 18rem;
        }
        .input5 {
            width: 18rem;
        }
        .input6 {
            width: 7.5rem;
            padding-left: 1rem;
            color: #03d9bc;
            cursor: pointer;
        }
    }
    .addOneLine {
        position: relative;
        text-align: center;
        width: 100%;
        height: 4.8rem;
        line-height: 4.8rem;
        border: 0.1rem dashed #2d79be;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.45);
        margin-top: 1rem;
        box-sizing: border-box;
        padding-left: 3rem;
        cursor: pointer;
        img {
            position: absolute;
            top: 50%;
            left: 44%;
            transform: translateY(-50%);
            width: 1.229rem;
            height: 1.286rem;
        }
    }
}
.subordinatePV {
    margin: 0 2.4rem 0 2.4rem;
    .title {
        margin: 0 0 2.2rem 0;
        font-size: 1.4rem;
        color: hsla(0, 0%, 100%, 0.85);
        margin-top: 2rem;
        .required {
            margin-top: 1.8rem;
            color: #f56c6c;
            margin-right: 0.4rem;
        }
    }
    .headers {
        height: 5.2rem;
        line-height: 5.2rem;
        width: 100%;
        background-color: rgba(52, 150, 241, 0.15);
        font-size: 1.4rem;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        span {
            display: inline-block;
            padding-left: 1rem;
        }
        .item1 {
            width: 10rem;
        }
        .item2 {
            width: 36rem;
        }
        .item3 {
            width: 41.7rem;
        }
        .item4 {
            width: 12rem;
        }
    }
    .content {
        .line {
            height: 5.2rem;
            line-height: 5.2rem;
            width: 100%;
            font-size: 1.4rem;
            color: #fff;
            border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            span {
                display: inline-block;
                padding-left: 1rem;
            }
            .item1 {
                width: 10rem;
            }
            .item2 {
                width: 36rem;
            }
            .item3 {
                width: 41.7rem;
            }
            .item4 {
                width: 12rem;
                color: #03d9bc;
                cursor: pointer;
            }
        }
    }
    .addBtns {
        height: 5.2rem;
        line-height: 5.2rem;
        width: 100%;
        font-size: 1.4rem;
        color: #fff;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        div {
            display: inline-block;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        .input1 {
            width: 10rem;
        }
        .input2 {
            width: 36rem;
        }
        .input3 {
            width: 41.7rem;
        }
        .input4 {
            width: 12rem;
            padding-left: 1rem;
            color: #03d9bc;
            cursor: pointer;
        }
    }
    .addOneLine {
        position: relative;
        text-align: center;
        width: 100%;
        height: 4.8rem;
        line-height: 4.8rem;
        border: 0.1rem dashed #2d79be;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.45);
        margin-top: 1rem;
        box-sizing: border-box;
        padding-left: 3rem;
        cursor: pointer;
        img {
            position: absolute;
            top: 50%;
            left: 44%;
            transform: translateY(-50%);
            width: 1.229rem;
            height: 1.286rem;
        }
    }
}
.monitorLoop {
    margin: 0 2.4rem 0 2.4rem;
    .title {
        font-size: 1.4rem;
        color: hsla(0, 0%, 100%, 0.85);
        margin-top: 2rem;
        margin-bottom: 1.6rem;
        .required {
            margin-top: 1.8rem;
            color: #f56c6c;
            margin-right: 0.4rem;
        }
    }
    .loops {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 90%;
        div {
            display: inline-block;
            font-size: 1.4rem;
            color: #2cd9ff;
            margin-right: 0.8rem;
            margin-bottom: 0.8rem;
        }
        .loopItem {
            width: 13.5rem;
            height: 3.2rem;
            line-height: 3rem;
            background-color: rgba(52, 150, 241, 0.15);
            border: 0.1rem dashed rgba(108, 184, 255, 0.3);
            border-radius: 0.4rem;
            padding-left: 0.6rem;
            box-sizing: border-box;
            span {
                float: right;
                margin-right: 0.6rem;
            }
        }
        .addBtn {
            width: 13.5rem;
            height: 3.2rem;
        }
        .addLoop {
            width: 3.2rem;
            height: 3rem;
            line-height: 3rem;
            text-align: center;
            background-color: rgba(52, 150, 241, 0.15);
            border: 0.1rem dashed rgba(108, 184, 255, 0.3);
            border-radius: 0.4rem;
        }
    }
}

// global style star
.displayFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.width30 {
    width: 32%;
}
.width40 {
    width: 33%;
}
.col-flex-1 {
    flex: 1;
}
.col-flex-2 {
    flex: 2;
}
.col-flex-3 {
    flex: 4;
}
.col-flex-4 {
    flex: 4;
}
.col-flex-5 {
    flex: 5;
}
.col-flex-6 {
    flex: 6;
}
.col-flex-7 {
    flex: 7;
}
.col-flex-8 {
    flex: 8;
}
.col-flex-9 {
    flex: 9;
}
.fontSize {
    font-size: 1.6rem;
}
.fontSize2 {
    font-size: 1.8rem;
}
.fontSize3 {
    font-size: 1.4rem;
}
.fontWeight {
    font-weight: 800;
}
.white {
    color: #fff;
}
.margnTop {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-right: 2.4rem;
}
.margnTop1 {
    margin-top: 5rem;
}
.margnTop2 {
    margin-top: 3rem;
}
.paddingRight {
    padding-right: 10rem;
}
.padding {
    padding: 0 3rem;
}
// global style end
.registerBox {
    // flex: 1;
    // padding: 2rem;
    box-sizing: border-box;
    overflow-y: scroll;
    width: 100%;
    .registerTop {
        margin: 2rem 2.4rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: flex-start;
            margin-bottom: 2.5rem;
            position: relative;
            .title {
                font-size: 1.4rem;
                color: hsla(0, 0%, 100%, 0.85);
                margin-bottom: 0.8rem;
                .required {
                    margin-top: 1.8rem;
                    color: #f56c6c;
                    margin-right: 0.8rem;
                }
            }
            .bottom {
                color: #f56c6c;
                position: absolute;
                top: 6.5rem;
                font-size: 1.2rem;
                left: 0;
            }
            /deep/.el-input-number{
                width:100%;
                .el-input-number__decrease{
                    background: #0b3d6b;
                    border-left: none;
                }
                .el-input-number__increase{
                    border-left: none;
                    border-bottom: none;
                    background: #0b3d6b;
                }
                .el-input-number__decrease, .el-input-number__increase{
                    color: #C0C4CC;
                }
                .el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
                    color: #606266;
                }
            }
        }
    }
}
.infoSearchBox {
    display: flex;
    justify-content: space-between;
}
// 下属储能列表star
.subordinateBox {
    .subordinateTitle {
    }
}
.dis {
    display: flex;
    flex-direction: column;
}
// 下属储能列表end
/deep/.el-empty__bottom {
    width: 100%;
}
.add {
    width: 100%;
    border: 0.1rem dashed #2d79be;
    height: 4.2rem;
    line-height: 4.2rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.45);
    width: 100%;
    cursor: pointer;
    &:hover {
        border: 0.1rem dashed #fff;
    }
}
.width1 {
    width: 90%;
}
.btnBox {
    button {
        width: 7.4rem;
        height: 3.2rem;
        border: none;
        color: #ffffff;
    }
}
.btnBox {
    display: flex;
    justify-content: flex-end;
}
.bgc1 {
    background: #027969;
    cursor: pointer;
}
.bgc2 {
    background: rgba(0, 115, 222, 0.1);
    margin-right: 0.8rem;
    border: 0.1rem solid #004579;
    box-sizing: border-box;
    border-radius: 0.2rem;
    cursor: pointer;
}
.star {
    color: #ff4d4f;
    font-size: 1.4rem;
    margin-right: 0.4rem;
}
</style>
<style lang="less">
.dialogRegister {
    .registerTop {
        .inputRed {
            .el-input__inner {
                border-color: #f56c6c !important;
            }
        }
        .el-input__inner {
            width: 36rem !important;
            background-color: rgba(0, 115, 222, 0.1);
        }
    }
    .subordinateBox {
        .el-input__inner {
            background-color: rgba(2, 121, 105, 0.2) !important;
            border-color: #027969 !important;
            padding-left: 0.5rem !important;
        }
    }
    .subordinatePV {
        .el-input__inner {
            background-color: rgba(2, 121, 105, 0.2) !important;
            border-color: #027969 !important;
            padding-left: 0.5rem !important;
        }
        .input1 {
            .el-input__inner {
                width: 9.5rem !important;
            }
        }
        .input2 {
            .el-input__inner {
                width: 35.5rem !important;
            }
        }
        .input3 {
            .el-input__inner {
                width: 41.2rem !important;
            }
        }
    }
    .monitorLoop {
        .el-input__inner {
            background-color: rgba(52, 150, 241, 0.15);
            border: 0.1rem dashed rgba(108, 184, 255, 0.3);
            padding-left: 0.5rem !important;
            width: 13.5rem !important;
        }
    }
}
</style>
