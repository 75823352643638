<template>
  <div>
    <component :is="componentId"></component>
  </div>
</template>

<script>
import NomalBench from '@/pages/WorkBench/NomalBench.vue'
import EngineerBench from '@/pages/WorkBench/EngineerBench.vue'

export default {
  name: 'WorkBench',
  components: {
    EngineerBench,
    NomalBench,
  },
  data() {
    return {
      componentId: '',
    }
  },
  mounted() {
    this.componentId =
      this.getterPersonalData.identity == '2'
        ? 'EngineerBench'
        : 'NomalBench'
  },
  computed: {
    getterPersonalData () {
      return this.$store.getters.getterPersonalData
    }
  }
}
</script>

<style></style>
