<template>
  <div class="TimeSetting">  
    <div class="table">
      <el-table
        :data="tableData"
        :stripe="false"
        height="57.8rem" 
        :border="false"
        style="width: 100%"
        :header-cell-style="headerClass"                    
        :cell-style="cellStyle"
      >       
        <el-table-column type="index" label="序号" min-width="15%"></el-table-column> 
        <el-table-column class="tab" prop="customerName" label="客户单位" min-width="30%" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column class="tab" prop="value" label="提前时间" min-width="30%"  :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ `${scope.row.value.split(':')[0]}天${scope.row.value.split(':')[1]}时${scope.row.value.split(':')[2]}分` }}</span>
          </template>
        </el-table-column>

        <!-- 操作部分 -->
        <el-table-column label="操作" min-width="15%">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 弹窗 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      width="30%"
      title="编辑"
      :close-on-click-modal="false"
    >
      <div class="lineOne">
        提前：
        <el-input-number
          v-model='dayData'
          size="small"             
          :precision="0"
          :step="1"
          controls-position="right"             
          :min="0"
          :max="364"
        ></el-input-number>
        <span> 天 </span>
        <el-input-number
          v-model='hourData'
          size="small"             
          :precision="0"
          :step="1"
          controls-position="right"             
          :min="0"
          :max="23"
        ></el-input-number>
        <span> 时 </span>
        <el-input-number
          v-model='minuteData'
          size="small"             
          :precision="0"
          :step="1"
          controls-position="right"             
          :min="0"
          :max="59"
        ></el-input-number>
        <span> 分 </span>
      </div>

      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="dialogFormVisible = false">取 消</button>
        <button class="btn_save" @click="confirm">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
export default {
    name:'TimeSetting',
    mixins: [tableBg],
    data(){
      return{
        tableData: [],
        dialogFormVisible: false,
        dayData:0,
        hourData:0,
        minuteData:0,
        myId:'',
      }
    },
    methods:{
      getTableData() {
        this.$store.dispatch('getGeneralSettingTable').then(()=>{
          this.tableData = this.generalSettingTableData
        })
      },
      handleEdit(row) {
        this.myId = row.id
        this.dayData = row.value.split(':')[0]
        this.hourData  = row.value.split(':')[1]
        this.minuteData  = row.value.split(':')[2]
        this.dialogFormVisible = true
      },
      confirm(){
        if(this.dayData === '' || this.hourData === '' || this.minuteData === ''){this.$message.error('请不要上传空数字')}
        else{
          const line = "" + this.dayData + ":" + this.hourData + ":" + this.minuteData
          const all = {
            id: this.myId,
            dictValue: line
          }
          this.$store.dispatch('putRepairPatrolSettingDict',all).then(()=>{
            if(this.ifRepairPatrolSettingDictSuccess === 0) {
              this.$message.success("修改成功!")
              this.dialogFormVisible = false
              this.getTableData();
            }else{
              this.$message.error(this.ifRepairPatrolSettingDictError)
            }
          })
        }
      }
    },

    mounted(){
      this.getTableData()
      // this.$store.dispatch('getRepairPatrolSettingDict','SYSTEM_CONFIG_PLAN_TIME').then(()=>{
      //   this.myId = this.repairPatrolSettingDict.id
      //   this.dayData = this.repairPatrolSettingDict.value.split(':')[0]
      //   this.hourData  = this.repairPatrolSettingDict.value.split(':')[1]
      //   this.minuteData  = this.repairPatrolSettingDict.value.split(':')[2]
      // })
    },

     computed: {
      ...mapState({
        // 获取字典，日，分，时数据
        generalSettingTableData: state => { return state.repair.generalSettingTableData.data;},
        repairPatrolSettingDict: state => { return state.repair.repairPatrolSettingDict.data[0];},
        // 是否修改成功
        ifRepairPatrolSettingDictSuccess:state => { return state.repair.ifRepairPatrolSettingDictSuccess.errorCode;},
        ifRepairPatrolSettingDictError:state => { return state.repair.ifRepairPatrolSettingDictSuccess.message;},
      })
    }


}
</script>

<style lang='less' scoped>
    .inputNumber {
      // position: absolute;
      // top: 2.4rem;
      // left: 7.4rem;
      span {
        float: left;
        // position: relative;
        // left: 1.5rem;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  // .contentPart{
  //   position: absolute;
  //   top: 4.4rem;
  //   left: 2.5rem;
  //   height: 15rem;
  //   width: 85rem;
    .lineOne{
      height: 3.2rem;
      color: #fff;
      width: 100%;
      line-height:3.2rem;
      .input1{
        width: 9rem;
        height: 3.2rem;
        box-sizing: border-box;   
        border: none;
        &:hover{
          border: none;
        }
        
      }
    }

    .lineTwo{
      height: 3.2rem;
      color: #fff;
      width: 100%;
      margin-top: 3.2rem;

      line-height:3.2rem;
      .input1{
        width: 9rem;
        height: 3.2rem;
        box-sizing: border-box;
        
      }
    }

    .btn{
      height: 3.2rem;
      width: 10.2rem;
      background-color: #027969;
      border: none;
      border-radius: 0.2rem;
      margin-top: 4.8rem;
      color:#ffffe7;
      cursor: pointer;
    }
  // }
  
  /deep/.el-dialog__header {
    background: linear-gradient(
      225deg,
      rgba(15, 36, 55, 0.0001) 0.03%,
      rgba(0, 115, 222, 0.7) 99.9%
    );
    border-radius: 0.4rem 0.4rem 0rem 0rem;
    padding: 1.6rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-dialog__title {
      color: #fff !important;
    }
  }
  
  /deep/.el-dialog__header {
    background: linear-gradient(
      225deg,
      rgba(15, 36, 55, 0.0001) 0.03%,
      rgba(0, 115, 222, 0.7) 99.9%
    );
    border-radius: 0.4rem 0.4rem 0rem 0rem;
    padding: 1.6rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-dialog__title {
      color: #fff !important;
    }
  }
</style>

<style lang='less'>
.TimeSetting{
  .table {
    margin: 2.4rem;
  }
   // 计数器样式
  .el-input-number__increase {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease {
    background-color: #0b3d6b;
  }
  .el-input-number .is-controls-right {
    background-color: #0b3d6b;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #0b3d6b;
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid #027969;
    border-left: 0.1rem solid #027969;
    box-shadow: none;
    border: 0.1rem solid #027969;
    box-sizing: border-box;
    border: none;
  }
  ::-webkit-scrollbar,
  .el-select-dropdown::-webkit-scrollbar {
    width: 0;
  }
  .el-input-number.is-controls-right .el-input-number__increase {
    border-radius: 0 0.4rem 0 0;
    border-bottom: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }

  .el-input-number.is-controls-right .el-input-number__decrease {
    border-left: none;
    box-sizing: border-box;
    border-left: 0.1rem solid #004579;
  }
}

</style>