<template>
  <div class="app-container">
    <el-form ref="queryForm"
             :model="queryParams"
             :inline="true"
    >
    </el-form>
    <el-row class="bar" :gutter="10">
      <el-col :span="6" class="inputBgc">
        <el-input class="search-box" v-model="queryParams.diagramName"
                  placeholder="请输入关键字搜索"
                  clearable
                  @keyup.enter.native="handleQuery"
        >
          <i slot="suffix" class="el-icon-search" @click="handleQuery"></i>
        </el-input>
      </el-col>
      <el-col :span="4" :offset="14">
        <el-button class="btnadd btntheme"
                   :disabled="delDisabled"
                   @click="handleDelete"
                   ref="delBtn"
        >
          批量删除
        </el-button>
        <el-radio-group class="inputBgc"
                        v-model="radio"
                        @change="agreeChange"
                        text-color="#fff"
                        fill="#fff"
                        border="none"
        >
          <el-radio-button label="queue" style="border: none">
            <img ref="image2" :src="require('@/assets/icons/' + imgQueue + '.png')" alt=""/>
          </el-radio-button>
          <el-radio-button label="photo" style="border: none">
            <img ref="image1" :src="require('@/assets/icons/' + imgPhoto + '.png')" alt=""/>
          </el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>

    <!-- card-view -->
    <el-row class="diagram-cards" :gutter="24" v-show="radio == 'photo'">
      <el-col :span="6" v-for="(item, index) in templatesList" :key="index">
        <div class="img-card">
          <div class="card-header">
            <span>{{ item.diagramName.length  &lt; 20 ? item.diagramName : item.diagramName.substring(0, 20) + '...'  }}</span>
            <img :src="require('@/assets/icons/wacth.png')" height="30" alt="" @click="throughImg(item)">
          </div>
          <div class="card-content" @dblclick="goCircuitEdit(item.diagramId)">
            <img class="diagram-img" :src="VUE_APP_URL + item.coverUrl"  alt=""/>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- table-view -->
    <div class="table" v-show="radio == 'queue'">
      <el-table :data="templatesList"
                class="bgc"
                @selection-change="handleSelectionChange"
                :header-cell-style="{
                  background: '#133F68',
                  color:'#fff',
                  fontFamily: 'PingFang SC',
                  fontStyle: 'normal',
                  fontWeight: '600'
                }"
      >
        <el-table-column type="selection" width="64" align="center"/>
        <el-table-column label="模板名称" prop="diagramName" align="center">
          <template slot-scope="scope">
            <a @click="goCircuitEdit(scope.row.diagramId)"  style="cursor: pointer">
              {{ scope.row.diagramName }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" :formatter="dateFormat" align="center"/>
        <el-table-column label="最后更新时间" prop="editTime" align="center" />
        <el-table-column label="操作" class-name="small-padding fixed-width" align="center">
          <template slot-scope="scope">
            <div class="caozuobtn-box">
              <div class="caozuobtn" @click="handleDelete(scope.row)">删除</div>
              <div class="caozuobtn" @click="handleUpdate(scope.row)">编辑</div>
              <div class="caozuobtn" @click="handleExport(scope.row)">导出</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryParams.pageNum"
                     :page-size="queryParams.pageSize"
                     :total="total"
                     :page-sizes="[10, 20, 40, 80]"
                     layout="total, sizes, prev, pager, next, jumper"
                     style="float: right; padding: 2rem;"/>

      <!-- 弹层：添加、修改 -->
      <el-dialog class="add-edit-dialog"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :title="title"
                 :visible.sync="open"
                 width="600px"
                 append-to-body
      >
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <el-form-item label="模板名称" prop="diagramName" class="inputBgc">
            <el-input v-model="form.diagramName" placeholder="请输入模板名称"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button @click="cancel" class="cancelBtn">取 消</el-button>
          <el-button @click="submitForm" class="sureBtn">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 弹层：卡片预览 -->
    <el-dialog
        class="add-edit-dialog"
        width="50%"
        :title="title1.length &lt; 40 ? title1 : title1.substring(0, 40) + '...' "
        :close-on-click-modal="false"
        :visible.sync="openImg"
    >
      <img :src="VUE_APP_URL + src" alt="图片走丢了..." width="100%"/>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config/index.js";
import {
  pageTemplates,
  getTemplates,
  delTemplates,
  addTemplates,
  updateTemplates,
  exportTemplates,
} from "@/api/diagram/templates";
import DiagramMixin from "../diagram-mixin";

export default {
  name: "Templates",
  mixins: [DiagramMixin],
  data() {
    return {
      //网站前缀
      envApi: config.baseURL + 'component',
      VUE_APP_URL: config.baseURL + 'diagram',
      defaultProps: {
        children: "children",
        label: "title",
      },
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 大屏可视化项目表格数据
      templatesList: [],
      //列表切换
      radio: "queue",
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      openImg: false,
      imgQueue: "intable",
      imgPhoto: "outphoto",
      title1: "",
      show: true,
      activeName: "attribute",
      color1: "#409EFF",
      openBind: false,
      delDisabled: true,

      queryParams: {
        pageNum: 1,
        pageSize: 10,
        diagramName: null,
        diagramCode: null,
        diagramType: null,
        width: null,
        height: null,
        coverUrl: null,
        publishStatus: null,
        creatorId: null,
        createTime: null,
        editorId: null,
        editTime: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        diagramName: [
          { required: true, message: "模板名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getList() {
      this.loading = true;
      pageTemplates(this.queryParams).then((response) => {
        this.templatesList = response.rows;
        for (const item of this.templatesList) {
          item.editTime = this.transformTimestamp(item.editTime);
        }
        this.total = response.total;
        this.loading = false;
      });
    },
    //时间转化
    transformTimestamp(timestamp) {
      if (timestamp != null) {
        let a = new Date(timestamp).getTime();
        const date = new Date(a);
        const Y = date.getFullYear() + "-";
        const M =
                (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-";
        const D =
                (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "  ";
        const h =
                (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
                ":";
        const m =
                date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        // const s = date.getSeconds(); // 秒
        const dateString = Y + M + D + h + m;
        // console.log('dateString', dateString); // > dateString 2021-07-06 14:23
        return dateString;
      } else {
        return "";
      }
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        diagramId: null,
        diagramName: null,
        diagramCode: null,
        diagramType: null,
        width: null,
        height: null,
        coverUrl: null,
        publishStatus: "0",
        remark: null,
        creatorId: null,
        createTime: null,
        editorId: null,
        editTime: null,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.diagramName = "";
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.diagramId);
      this.delDisabled = this.ids.length <= 0;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加大屏可视化项目";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const diagramId = row.diagramId || this.ids;
      getTemplates(diagramId).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = "修改信息";
      });
    },
    throughImg(item) {
      this.openImg = true;
      this.title1 = item.diagramName;
      this.src = item.coverUrl;
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.diagramId != null) {
            updateTemplates(this.form).then((response) => {
              this.$message.success("修改成功");
              this.open = false;
              this.reset()
              this.getList();
            });
          } else {
            addTemplates(this.form).then((response) => {
              this.$message.success("新增成功");
              this.open = false;
              this.reset()
              this.getList();
            });
          }
        }
      });
    },
    agreeChange(val) {
      if (val === "photo") {
        this.imgQueue = "outtable";
        this.imgPhoto = "inphoto";
        this.delDisabled = true
      } else {
        this.imgQueue = "intable";
        this.imgPhoto = "outphoto";
        this.delDisabled = this.ids.length <= 0;
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const diagramIds = row.diagramId || this.ids;
      this.$confirm("是否确认删除", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
              .then(function () {
                return delTemplates(diagramIds);
              })
              .then(() => {
                this.getList();
                this.msgSuccess("删除成功");
              })
              .catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport(row) {
      if (row.diagramId) {
        exportTemplates(row.diagramId).then((response) => {
          var blob = new Blob([JSON.stringify(response)]); //创建一个blob对象
          var a = document.createElement("a"); //创建一个<a></a>标签
          a.href = URL.createObjectURL(blob); // response is a blob
          var date = new Date();
          var year = date.getFullYear(); //获取完整的年份(4位)
          var month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
          var day = date.getDate(); //获取当前月份(0-11,0代表1月)
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }

          var second = date.getSeconds();
          var hour = date.getHours();
          var minute = date.getMinutes() - 1;
          var timestamp = year + month + day + hour + minute + second;
          a.download = row.diagramName + timestamp + ".ed"; //文件名称
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
      } else {
        for (let id of this.ids) {
          for (let diagram of this.diagramList) {
            if (diagram.diagramId == id) {
              exportDiagram(id).then((response) => {
                var blob = new Blob([JSON.stringify(response)]); //创建一个blob对象
                var a = document.createElement("a"); //创建一个<a></a>标签
                a.href = URL.createObjectURL(blob); // response is a blob
                var date = new Date();
                var year = date.getFullYear(); //获取完整的年份(4位)
                var month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
                var day = date.getDate(); //获取当前月份(0-11,0代表1月)
                if (month < 10) {
                  month = "0" + month;
                }
                if (day < 10) {
                  day = "0" + day;
                }

                var second = date.getSeconds();
                var hour = date.getHours();
                var minute = date.getMinutes() - 1;
                var timestamp = year + month + day + hour + minute + second;
                a.download = diagram.diagramName + timestamp + ".ed"; //文件名称
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                a.remove();
              });
            }
          }
        }
      }
    },
    goCircuitEdit(diagramId) {
      this.$router.push({
        path: "/mapping/diagram-edit",
        query: { diagramId: diagramId, type: true },
      });
    },
    dateFormat: function (row, column) {
      let data = row[column.property];
      if (data === null) {
        return "";
      }
      let dt = new Date(data);
      // console.log("dt"+dt);
      return (
              dt.getFullYear() +
              "-" +
              (dt.getMonth() + 1) +
              "-" +
              dt.getDate() +
              " " +
              dt.getHours() +
              ":" +
              dt.getMinutes() +
              ":" +
              dt.getSeconds()
      );
    },
    // 分页操作
    handleSizeChange(pageSize){
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = pageSize;
      this.getList();
    },
    handleCurrentChange(pageNum){
      this.queryParams.pageNum = pageNum;
      this.getList();
    }
  },
  created() {
    this.getList();
  },
}
</script>
<style lang="less" scoped>
.app-container {
  .bar{
    margin-left: 0 !important;
    margin-right: 0 !important;
    line-height: 64px;
    background-color: #0d3050;

    .search-box{
      padding-left: 2rem;
      i{
        margin-right: 1rem;
      }
    }

    .btntheme{
      padding: 0 22px;
      height: 32px;
      font-size: 14px;
      border-radius: 2px;
      background-color: #027969;
      color: white;
      border: none;
      margin-right: 1rem;
      margin-left: 20%;
    }

    ::v-deep .inputBgc .el-radio-button__inner,
    .inputBgc .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: rgba(0, 115, 222, 0.15) !important;
    }
    ::v-deep .inputBgc .el-radio-button__inner{
      width: 38px;
      height: 38px;
      border-radius: 0 !important;
      box-shadow:none !important;
      padding: 0;
      margin-right: 5px;
    }
    img{
      height: 18px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30%;

    }
  }

  .img {
    border: 1px solid #ccc;
    padding: 10px 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px #888888;
  }

  .table {
    margin-top: 18px;
    .bgc{
      height: 525px;
      overflow-y: scroll;
    }
    ::v-deep .el-table__row{
      background-color: #0c375e;
      color: #dbe1e7;
      &:hover{
        background-color: #0c375e;
      }

    }
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
      background-color: #0c375e;
    }

    .caozuobtn-box{
      display: flex;
      justify-content: center;
    }

    .caozuobtn {
      cursor: pointer;
      position: static;
      left: 0%;
      right: 89.06%;
      top: 0%;
      bottom: 0%;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #03d9bc;
      mix-blend-mode: normal;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 12px;
    }
  }

  .diagram-cards{
    .img-card {
      cursor: pointer;
      background: rgba(0, 115, 222, 0.15);
      border-radius: 6px;
      margin: 1rem 0;

      .card-header{

        height: 3rem;

        span {
          height: 3rem;
          line-height: 3rem;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #027969;
          float: left;
          text-align: left;
        }
        img{
          height: 3rem;
          float: right;
        }

      }
      .diagram-img{
        height: 190px;
        width: 400px;
      }
    }
  }

  .el-drawer__open .el-drawer.rtl {
    width: 20%;
  }

  ::v-deep .el-radio-button__inner {
    border: none !important;
  }

  .el-radio-button--medium .el-radio-button__inner {
    padding: 10px !important;
    font-size: 14px !important;
  }
}
</style>
