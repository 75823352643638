<template>
  <div class="DrawerForDetail">
      <el-drawer
        :visible.sync="drawer"
        :with-header="false"
        direction=ltr
        size=66%    
        >

        <!-- 左侧区域######################################### -->
        <div class="leftPart">
            <div class="title">
                巡检名称 : {{formData.name}}
            </div>
            <!-- 板块一############### -->
            <div class="cardOne">
                <div class="wordTypeOne">巡检信息 :</div>
                <div class="wordTypeTwo">巡检周期 : <span class="wordTypeFour">{{formData.executorType}}</span></div>
                <div class="wordTypeTwo">计划巡检时间 : <span class="wordTypeFour">{{formData.planTime}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计划结束巡检时间 : <span class="wordTypeFour">{{formData.planEndTime}}</span></div>
                <div class="wordTypeTwo">巡检人/小组 : <span class="wordTypeFour">{{formData.executorName}}</span></div>
                <div class="wordTypeTwo">状态 : <span class="wordTypeFour">{{formData.statusName}}</span></div>

                <div class="wordTypeOne">作业标准 :</div>
                <div class="wordTypeTwo">作业标准 :<span class="wordTypeFour">{{formData.inspectionStandardName}}</span></div>
                <div class="table-container">
                    <el-table
                        class="expand-table"
                        :data="tableData"
                        style="width: 100%"
                    >
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-form label-position="left" class="demo-table-expand">
                                    <el-form-item v-for="(item, index) in props.row.detailList" :key="index" :label="item.name">
                                        <span>{{ item.content }}</span>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column label="条目名称" prop="name"></el-table-column>
                    </el-table>
                </div>
                

                <div class="wordTypeOne">巡检任务 :</div>
                <div class="wordTypeTwo">巡检位置 : <span class="wordTypeFour">{{formData.address}}</span></div>
                <div class="wordTypeTwo">巡检开始时间 : <span class="wordTypeFour">{{formData.startTime}}</span></div>
             
                <div class="box">
                    <div class="wordTypeFive">备注 : </div>
                    <span class="wordTypeSix">{{formData.remark}}</span>
                </div>


                 <div class="wordTypeOne">巡检结果 :</div>

                 <div class="wordTypeTwo">结束巡检时间 :  <span class="wordTypeFour">{{formData.endTime}}</span></div>
                 <div class="box">
                    <div class="wordTypeFive">情况记录 : </div>
                    <span class="wordTypeSix">{{formData.caseRecord}}</span>
                </div>

                <div class="box">
                    <div class="wordTypeFive">巡检图片 : </div> 
                    <img @click="handleCheckDetail(item)" v-for="(item,index) in imgList1" :key=index class="myImg" :src="item" alt="图片不在该服务器">         
                </div>
            </div>
           
        

        </div>
        <!-- 中间区域 -->
        <div class="middlePart"></div>
        <!-- 右侧区域############################################## -->
        <div class="rightPart">
            <div class="inner">
                <el-timeline>
                    <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :icon="activity.icon"
                    :type="activity.type"
                    :color="activity.color"
                    :size="activity.size"
                    :timestamp="activity.timestamp">
                    {{activity.content}}
                    </el-timeline-item>
                </el-timeline>
            </div>



        </div>
        
      </el-drawer>
      <!-- 预览图片的弹层 -->
    <el-dialog :visible.sync="dialogForPictureVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>
export default {
    name:"DrawerForDetail",
    data(){return {
        // 图片列表
        imgList1:[],
        // 控制抽屉是否展开
        drawer: false,
        // 表格数据
        formData:[],
        tableData: [],
        // 流程图数据
        activities: [],
        // 预览图片的弹层是否显示
        dialogForPictureVisible:false,
        // 大图弹层的图片地址
        dialogImageUrl:"",
      }},

    methods:{
        //控制是否展示抽屉
       show(val){          
           this.drawer = true
           this.formData = val
           this.tableData = val.inspectionStandard.itemList
           if(val.imgList){this.imgList1= val.imgList.split(",")} else {this.imgList1=[]}
           //渲染流程图  
           this.activities=[]
           for(let i=0;i<val.taskLogs.length;i++){
               const singel = {content:"",timestamp:"",color:"#0078E8"}
               singel.content = val.taskLogs[i].stageDetails
               singel.timestamp = val.taskLogs[i].createTime
               this.activities.push(singel)
           }
           this.activities[0].color="#1EE7E7"
       },

        // 查看大图
        handleCheckDetail(val){
            this.dialogForPictureVisible = true
            this.dialogImageUrl = val
        },
    },


}
</script>

<style lang="less" scoped>
// 公共样式
.wordTypeOne{
    height: 4rem;
    width: 85rem;
    line-height: 5.5rem;
    font-size: 1.6rem;
    color: #37A5DD;
    font-weight: 900;
    margin-left: 3.2rem;
}
.wordTypeTwo{
    height: 3.6rem;
    width: 85rem;
    line-height: 3.6rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem;
}
.wordTypeThree{
    height: 10rem;
    width: 85rem;
    line-height: 2rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem;

}
.wordTypeFour{ 
    font-weight: 100;
    line-height: 2rem;
}
.wordTypeFive{
    height: 9rem;
    width: 7rem;
    line-height: 9rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 3.2rem; 
    
}
.wordTypeSix{
    height: 9rem;
    width: 80rem;
    font-weight: 100;
    line-height: 2.2rem;   
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    overflow: hidden;
    display: table-cell;
    vertical-align:middle;
    background-color: #0e2b46;
    border-radius: 0.4rem;
    padding: 0 0.5rem 0 0.5rem;
    box-sizing: border-box;
}
.myImg{
    height: 9rem;
    width: 15rem;
    border-radius: 0.4rem;
    overflow: hidden;
    margin-right: 2rem;
}
.box{
    height: 9rem;
    width: 90rem;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
 
}


// 左侧区域###############################################################
.leftPart{
    width: 90.3rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .title{
        height: 2.5rem;
        width: 100%;
        position: absolute;
        top: 2.5rem;
        left: 0;
        line-height: 2.5rem;
        font-size: 1.6rem;
        color: #37A5DD;
        font-weight: 900;
        margin-left: 3.2rem;
        
    }
    .cardOne{
        height: calc(100% - 5rem);
        width: 100%;
        position: absolute;
        top: 5rem;
        left: 0;
        overflow-y: auto;
    }
    .cardTwo{
        height: 42rem;
        width: 100%;
        position: absolute;
        top: 47rem;
        left: 0;
   
    }
}
// 右侧区域
.rightPart{
    width: 37.4rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 90.4rem;
    overflow-y: auto;
    .inner{
        position: absolute;
        left: 2rem;
        top: 8rem;
        // overflow: scroll;
        height: 90rem;

    }
}


// 中间区域
.middlePart{
    width: 0.1rem;
    height: 94.8rem;
    position: absolute;
    top: 0;
    left: 90.3rem;
    background-color: rgba(22, 111, 191, 0.2);
}




</style>


<style lang="less">
.DrawerForDetail{

// 抽屉底色
.el-drawer {
    background-color: #0c375d;  
}

.equip_title {
  height: 5.4rem;
  line-height: 5.4rem;
  padding-left: 2.4rem;
  font-size: 1.6rem;
  background: linear-gradient(225deg, rgba(15, 36, 55, 0.0001) 0.03%, rgba(0, 115, 222, 0.7) 99.9%);
}

// 流程图样式


.el-timeline-item__content {
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
}

.el-timeline {
    margin: 0;
    font-size: 1.4rem;
    list-style: none;
}

.el-timeline-item__timestamp .is-bottom{
    font-size: 1.2rem;
}


.DrawerForDetail .el-timeline-item__wrapper {
    font-size: 2rem;
  
}
.el-timeline-item__wrapper {
    position: relative;
    padding-left: 2.8rem;
    top: -0.3rem;

}

.table-container {
    padding-left: 3.2rem;
    .el-form-item {
        padding-left: 38px;
    }
    .el-table__expand-icon {
        color: #fff;
    }
}







}



</style>