import { render, staticRenderFns } from "./generalPower.vue?vue&type=template&id=eb16acc0&scoped=true&"
import script from "./generalPower.vue?vue&type=script&lang=js&"
export * from "./generalPower.vue?vue&type=script&lang=js&"
import style0 from "./generalPower.vue?vue&type=style&index=0&id=eb16acc0&lang=less&scoped=true&"
import style1 from "./generalPower.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb16acc0",
  null
  
)

export default component.exports