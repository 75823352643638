<template>
  <div class="alarmManagement">
    <!-- 表头部分############################################################################################ -->
    <div class="bread">
      <!-- 下拉框1 -->
      <label>告警类型：</label>
      <el-select
        size="small"
        @change="selectChanged"
        placeholder="请选择告警类型"
        v-model="warningType"
        style="width:16.8rem;margin:2.2rem 0 0 0"
      >
        <el-option v-for="(item, i) in SelectListOne" :label="item.label" :value="item.value" :key="i"></el-option>
      </el-select>

      <!-- 下拉框2 -->
      <label>告警级别：</label>
      <el-select
        size="small"
        @change="selectChanged"
        placeholder="请选择告警级别"
        v-model="warningLevel"
        style="width:16.8rem;margin:2.2rem 0 0 0"
      >
        <el-option v-for="(item, i) in SelectListTwo" :label="item.label" :value="item.value" :key="i"></el-option>
      </el-select>

      <!-- 时间选择器 -->
      <label>告警时间：</label>
      <el-date-picker
        style="margin:2.2rem 0 0 0;height:3.2rem;width:35rem"
        v-model="valueTimePicker"
        type="daterange"
        align="right"
        unlink-panels
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="timePickerChange"
        :picker-options="pickerOptions"
      >
      </el-date-picker>

      <!-- 下拉框3 -->
      <label>处理状态：</label>
      <el-select
        size="small"
        @change="selectChanged"
        placeholder="请选择处理状态"
        v-model="warningStatus"
        style="width:16.8rem;margin:2.2rem 0 0 0"
      >
        <el-option v-for="(item, i) in SelectListThree" :label="item.label" :value="item.value" :key="i"></el-option>
      </el-select>

      <button class="btnSearch" @click="search">查 询</button>
      <button class="btnAdd" @click="handelExport">导 出</button>
    </div>

    <!-- 表格部分################################################################################################## -->
    <div class="table">
      <el-table
        :data="tableData"
        stripe:false
        height="57.8rem"
        border:false
        style="width: 100%"
        :header-cell-style="headerClass"
        :cell-style="cellStyle"
      >
        <el-table-column label="序号" min-width="9%">
          <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
        </el-table-column>
        <el-table-column class="tab" prop="warningType" label="告警类型" min-width="14%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.warningType == 1">配电告警</span>
            <span v-if="scope.row.warningType == 2">发电告警</span>
            <span v-if="scope.row.warningType == 3">能耗告警</span>
            <span v-if="scope.row.warningType == 4">电气消防告警</span>
            <span v-if="scope.row.warningType == 5">充电告警</span>
          </template>
        </el-table-column>
        <el-table-column
          class="tab"
          prop="warningObject"
          label="告警对象"
          min-width="50%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column class="tab" prop="warningInfo" label="告警信息" min-width="22%" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column class="tab" prop="noticeUserName" label="通知人" min-width="25%" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column class="tab" prop="warningLevel" label="告警级别" min-width="22%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.warningLevel == 1" style="color:#03D9BC;">通知</span>
            <span v-if="scope.row.warningLevel == 2" style="color:#2CD9FF;">一般警告</span>
            <span v-if="scope.row.warningLevel == 3" style="color:#FF5F5F;">紧急警告</span>
            <span v-if="scope.row.warningLevel == 4" style="color:#FFAC5F;">严重警告</span>
          </template>
        </el-table-column>
        <el-table-column class="tab" prop="createTime" label="告警时间" min-width="22%" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column class="tab" prop="handleUserName" label="处理人" min-width="14%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.handleUserName == 0 || scope.row.handleUserName == null">-</span>
            <span v-else>{{ scope.row.handleUserName }}</span>
          </template>
        </el-table-column>
        <el-table-column class="tab" prop="handleStatus" label="处理状态" min-width="14%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.handleStatus == 1" class="handleStatus1">待处理</span>
            <span v-if="scope.row.handleStatus == 2" class="handleStatus2">已处理</span>
            <span v-if="scope.row.handleStatus == 3" class="handleStatus3">已忽略</span>
            <span v-if="scope.row.handleStatus == 0 || scope.row.handleStatus == null">-</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器部分 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNumForPagenation"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import { exportWarningReport } from "@/api/repair"
export default {
  name: "AlarmSetting",
  mixins: [tableBg],
  data() {
    return {
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,

      // 时间选择器的数据
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 表格数据
      tableData: [
        {
          data1: "配电告警",
          data2: "1号变压器",
          data3: "一号配电房的16号变压器过压",
          data4: "老王",
          data5: "严重告警",
          data6: "2021-02-19",
          data7: "祝大海",
          data8: "已完成",
        },
        {
          data1: "配电告警",
          data2: "1号变压器",
          data3: "一号配电房的16号变压器过压",
          data4: "老王",
          data5: "严重告警",
          data6: "2021-02-19",
          data7: "祝大海",
          data8: "已完成",
        },
      ],

      // 下拉选择数据
      SelectListOne: [
        { label: "全部", value: 0 },
        { label: "配电告警", value: 1 },
        { label: "发电告警", value: 2 },
        { label: "能耗告警", value: 3 },
        { label: "电气消防告警", value: 4 },
        { label: "充电告警", value: 5 },
      ],

      SelectListTwo: [
        { label: "全部", value: 0 },
        { label: "通知", value: 1 },
        { label: "一般告警", value: 2 },
        { label: "紧急告警", value: 3 },
        { label: "严重告警", value: 4 },
      ],

      SelectListThree: [
        { label: "全部", value: 0 },
        { label: "待处理", value: 1 },
        { label: "已处理", value: 2 },
        { label: "已忽略", value: 3 },
      ],

      // 选择框的双向绑定数据
      warningType: "",
      warningLevel: "",
      warningStatus: "",
      // 日期选择器双向绑定的数据
      valueTimePicker: "",
    };
  },

  methods: {
    // 刷新页面
    initWarningInfoList() {
      let beginTime = "";
      let endTime = "";
      if (this.valueTimePicker !== "" && this.valueTimePicker != null) {
        beginTime = this.valueTimePicker[0];
        endTime = this.valueTimePicker[1];
        let day = beginTime.getDate();
        let month = beginTime.getMonth() + 1;
        let year = beginTime.getFullYear();
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        beginTime = year + "-" + month + "-" + day;
        let day1 = endTime.getDate();
        let month1 = endTime.getMonth() + 1;
        let year1 = endTime.getFullYear();
        if (day1 < 10) {
          day1 = "0" + day1;
        }
        if (month1 < 10) {
          month1 = "0" + month1;
        }
        endTime = year1 + "-" + month1 + "-" + day1;
      }
      let value = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        warningType: this.warningType,
        warningLevel: this.warningLevel,
        warningStatus: this.warningStatus,
        beginTime: beginTime,
        endTime: endTime,
      };
      this.$store.dispatch("getWarningInfoList", value).then(() => {
        // console.log(this.$store.state.system.getWarningInfoList);
        if (this.$store.state.system.getWarningInfoList.errorCode == 0) {
          this.tableData = this.$store.state.system.getWarningInfoList.data.records;
          this.totalNumForPagenation = this.$store.state.system.getWarningInfoList.data.total;
        } else {
          this.tableData = [];
        }
      });
    },

    // 点击搜索或者回车
    search() {
      this.currentPage = 1;
      this.initWarningInfoList();
    },
    // 更改用户状态选择框的选择
    selectChanged() {
      this.search();
    },
    // 触发了日期选择器
    timePickerChange() {
      // alert("触发了日期选择器");
      this.search();
    },

    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = "1";
      this.pageSize = val;
      this.initWarningInfoList();
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initWarningInfoList();
    },

    // 点击了导出
    handelExport() {
      let beginTime = "";
      let endTime = "";
      if (this.valueTimePicker !== "") {
        beginTime = this.valueTimePicker[0];
        endTime = this.valueTimePicker[1];
        let day = beginTime.getDate();
        let month = beginTime.getMonth() + 1;
        let year = beginTime.getFullYear();
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        beginTime = year + "-" + month + "-" + day;
        let day1 = endTime.getDate();
        let month1 = endTime.getMonth() + 1;
        let year1 = endTime.getFullYear();
        if (day1 < 10) {
          day1 = "0" + day1;
        }
        if (month1 < 10) {
          month1 = "0" + month1;
        }
        endTime = year1 + "-" + month1 + "-" + day1;
      }
      let value = {
        warningType: this.warningType,
        warningLevel: this.warningLevel,
        warningStatus: this.warningStatus,
        beginTime: beginTime,
        endTime: endTime,
      };
      let timeRange = beginTime && endTime ? `${beginTime}-${endTime}` : ''
      // this.$store.dispatch("downloadWarningInfoList", value);
      exportWarningReport(value).then((response) => {
          var blob = new Blob([response], {
            type: "application/vnd.ms-excel,charset=utf-8",
          }) //创建一个blob对象
          var a = document.createElement("a") //创建一个<a></a>标签
          a.href = URL.createObjectURL(blob) // response is a blob
          a.download = `${timeRange}告警信息.xls` //文件名称
          a.style.display = "none"
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
    },
  },
  created() {
    this.warningType = 0;
    this.warningLevel = 0;
    this.warningStatus = 0;
    this.initWarningInfoList();
  },
  mounted() {},

  computed: {
    ...mapState({
      // communicationPageData:(state) =>{ return state.system.communicationPageData.data.records},
      // communicationPageDataTotal:(state) =>{ return state.system.communicationPageData.data.total},
      // ifDeleteCommunicationSuccess:(state)=>{return state.system.ifDeleteCommunicationSuccess.errorCode},
      // ifDeleteCommunicationError:(state)=>{return state.system.ifDeleteCommunicationSuccess.message},
      // ifResetPassword:(state)=>{return state.system.ifResetPassword.errorCode},
    }),
  },
};
</script>

<style lang="less" scoped>
.alarmManagement {
  .bread {
    label {
      margin-left: 2.4rem;
    }
  }
  .table {
    .handleStatus1 {
      color: #ffac5f;
      width: 6.6rem;
      height: 2.8rem;
      line-height: 2.8rem;
      display: inline-block;
      text-align: center;
      border-radius: 1.4rem;
      background: linear-gradient(180deg, rgba(255, 172, 95, 0.1) 0%, rgba(205, 216, 118, 0.1) 100%);
    }
    .handleStatus2 {
      color: #03d9bc;
      width: 6.6rem;
      height: 2.8rem;
      line-height: 2.8rem;
      display: inline-block;
      text-align: center;
      border-radius: 1.4rem;
      background: linear-gradient(180deg, rgba(30, 231, 231, 0.15) 0%, rgba(30, 207, 231, 0.15) 100%);
    }
    .handleStatus3 {
      color: #ffffff;
      width: 6.6rem;
      height: 2.8rem;
      line-height: 2.8rem;
      display: inline-block;
      text-align: center;
      border-radius: 1.4rem;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(228, 252, 255, 0.1) 100%);
    }
  }
}
// 中部表格样式##################################################################
.tablePlace {
  height: 86.4rem;
  width: 171.2rem;
  position: absolute;
  top: 10.4rem;
  left: 0;
  overflow: hidden;
  background-color: #0d3050;
  .bread {
    color: #fff;
    height: 7.2rem;
    width: 142.8rem;
  }
  .btnSearch {
    height: 3.2rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
    background-color: #027969;
    position: absolute;
    top: 2.2rem;
    left: 121rem;
    width: 7.4rem;
  }
  .btnAdd {
    height: 3.2rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
    background-color: #027969;
    position: absolute;
    top: 2.2rem;
    right: 2.4rem;
    width: 7.4rem;
  }

  .table {
    height: 57.2rem;
    width: 166.4rem;
    margin: 0 2.4rem 0 2.4rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 0.1rem solid #254561;
    }
    .el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      opacity: 0;
    }
    .el-table__body-wrapper {
      background-color: #0d3050;
    }
    .el-table__cell {
      background-color: #0d3050;
    }
    .el-button--text {
      color: #03d9bc;
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}
/deep/ .el-date-editor .el-range-separator {
  padding: 0 0.5rem;
  line-height: 3.2rem;
  width: 5%;
  color: #303133;
}
</style>
<style lang="less">
.alarmManagement {
  .el-date-editor .el-range__close-icon {
    line-height: 2.6rem;
  }
}
</style>
