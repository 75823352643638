<template>
  <div class="CustomerPage">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <!-- 新增大楼############################################################################################################## -->
<!--      <template v-if="type === 2 || editType === 5">-->
<!--        <div v-if="getType == 2" slot="title">-->
<!--          <div class="equip_title">新增大楼</div>-->
<!--        </div>-->
<!--        <div v-else slot="title">-->
<!--          <div class="equip_title">编辑大楼</div>-->
<!--        </div>-->
<!--        <div style="max-height: 45rem; overflow-y: auto;">-->
<!--          <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">-->
<!--            <el-form-item label="大楼名称" prop="name" style="width: 98%;">-->
<!--              <el-input-->
<!--                v-model="form.name"-->
<!--                placeholder="请输入"-->
<!--                size="small"-->
<!--                clearable-->
<!--              ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </div>-->
<!--      </template>-->

<!--      &lt;!&ndash; 新增楼层############################################################################################################## &ndash;&gt;-->
<!--      <template v-if="type === 5 || editType === 6">-->
<!--        <div v-if="getType == 5" slot="title">-->
<!--          <div class="equip_title">新增楼层</div>-->
<!--        </div>-->
<!--        <div v-else slot="title">-->
<!--          <div class="equip_title">编辑楼层</div>-->
<!--        </div>-->
<!--        <div style="max-height: 45rem; overflow-y: auto;">-->
<!--          <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">-->
<!--            <el-form-item label="楼层名称" prop="name" style="width: 98%;">-->
<!--              <el-input-->
<!--                v-model="form.name"-->
<!--                placeholder="请输入"-->
<!--                size="small"-->
<!--                clearable-->
<!--              ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </div>-->
<!--      </template>-->

<!--      &lt;!&ndash; 新增楼层############################################################################################################## &ndash;&gt;-->
<!--      <template v-if="type === 6 || editType === 7">-->
<!--        <div v-if="type === 6" slot="title">-->
<!--          <div class="equip_title">新增房间</div>-->
<!--        </div>-->
<!--        <div v-else slot="title">-->
<!--          <div class="equip_title">编辑房间</div>-->
<!--        </div>-->
<!--        <div style="max-height: 45rem; overflow-y: auto;">-->
<!--          <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">-->
<!--            <el-form-item label="房间名称" prop="name" style="width: 98%;">-->
<!--              <el-input-->
<!--                v-model="form.name"-->
<!--                placeholder="请输入"-->
<!--                size="small"-->
<!--                clearable-->
<!--              ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </div>-->
<!--      </template>-->

      <!-- 新增位置 -->
      <template>
        <div v-if="addFlag" slot="title">
          <div class="equip_title">新增位置</div>
        </div>
        <div v-else slot="title">
          <div class="equip_title">编辑位置</div>
        </div>
        <div style="max-height: 45rem; overflow-y: auto;">
          <el-form :model="form" class="elForm" ref="ruleForm" :rules="rules">
            <el-form-item label="上级位置" prop="parentName" style="width: 98%;">
              <el-input
                  v-model="form.parentName"
                  size="small"
                  disabled
                  style="color: red"
              ></el-input>
            </el-form-item>
            <el-form-item label="位置名称" prop="name" style="width: 98%;">
              <el-input
                  v-model="form.name"
                  placeholder="请输入"
                  size="small"
                  clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>

      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">
          取 消
        </button>
        <button class="btn_save" @click="postDialogData('ruleForm')">
          保 存
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import { validateInput, validateSelect } from '@/utils/validate'
export default {
  name: 'DialogPosition',
  mixins: [tableBg],
  data() {
    return {
      addFlag: false,
      // 编辑时候的类型
      editType: 0,
      // 登记的类型
      type: 0,
      // 数据请求的类型（编辑=1、新登记=2）
      getType: 0,
      //表单中placehoder的数据
      parentNamePlaceHoder: '',
      // 当前树的信息
      treeFullNodeData: {},
      // 展示判断
      dialogFormVisible: false,
      // 表单数据存放处
      form: {},
      rules: {
        name: [validateInput],
      },
    }
  },
  methods: {
    // 关闭dialog的回调，el自带的
    closeDialog(ruleForm) {
      this.formInit()
      this.$refs[ruleForm].resetFields()
    },
    handleCancle(ruleName) {
      this.formInit()
      this.dialogFormVisible = false
      this.$refs[ruleName].resetFields()
    },
    // from的归零
    formInit() {
      this.form = {}
    },

    //父组件点击触发新增
    show(valType, valData) {
      this.addFlag = true
      this.editType = 0
      this.type = valType
      this.treeFullNodeData = {
        ...valData,
      }
      this.parentNamePlaceHoder = valData.name
      this.form.parentId = valData.id
      this.form.parentName = valData.name
      this.getType = 2
      if (valType === 2) {
        this.form.type = 5
      } else if (valType === 5) {
        this.form.type = 6
      } else if (valType === 6) {
        this.form.type = 7
      } else {
        this.form.type = 7
      }
      this.dialogFormVisible = true
    },

    // 父组件触发编辑
    showForEdit(valData, parentName) {
      this.addFlag = false
      this.getType = 1
      this.dialogFormVisible = true
      this.form = {
        ...valData,
      }
      this.form.type = valData.type
      this.form.parentId = valData.parentId
      this.type = 0
      this.editType = valData.type
      this.form.parentName = parentName
    },
    // 提交发送请求（点击《确定》）
    postDialogData(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          // 发送登记请求
          if (this.getType === 2) {
            this.$store
              .dispatch('postSystemPositionAdd', this.form)
              .then(() => {
                if (this.ifPositionAddSuccess === 0) {
                  this.$message({ message: '添加成功！', type: 'success' })
                  this.$refs[ruleName].resetFields()
                  this.dialogFormVisible = false
                  this.$parent.refreshThePage()
                  this.$parent.refreshTheTree()
                } else if (this.ifPositionAddSuccess === -1) {
                  this.$message({
                    message: this.ifPositionAddError,
                    type: 'warning',
                  })
                }
              })
          } else if (this.getType === 1) {
            //发送编辑请求
            this.$store
              .dispatch('putSystemPositionEdit', this.form)
              .then(() => {
                if (this.ifPositionEditSuccess === 0) {
                  this.$message({ message: '编辑成功！', type: 'success' })
                  this.$refs[ruleName].resetFields()
                  this.dialogFormVisible = false
                  this.$parent.refreshThePage()
                  this.$parent.refreshTheTree()
                } else if (this.ifPositionEditSuccess === -1) {
                  this.$message({
                    message: this.ifPositionEditError,
                    type: 'warning',
                  })
                }
              })
          }
        } else {
          this.$message.error('有必填项未填')
        }
      })
    },
  },

  //处理state数据
  computed: {
    ...mapState({
      ifPositionAddSuccess: (state) => {
        return state.system.ifPositionAddSuccess.errorCode
      },
      ifPositionAddError: (state) => {
        return state.system.ifPositionAddSuccess.message
      },
      ifPositionEditSuccess: (state) => {
        return state.system.ifPositionEditSuccess.errorCode
      },
      ifPositionEditError: (state) => {
        return state.system.ifPositionEditSuccess.message
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}

// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
</style>

<style lang="less">
// 套壳！谨防代码污染！
.CustomerPage {
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }
  // abb7c2
  .el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem;
  }
  .el-checkbox {
    color: #abb7c2;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #027969;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
  }
}
</style>

<style lang="less">
.el-input.is-disabled .el-input__inner {
  background-color: #0b3d6b;
  border-color: #004579;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
