import { Graph } from '@antv/x6'

// const html_3_1_xl = ``;
// Graph.registerHTMLComponent('3_1_xl', html_3_1_xl);
const html_3_1_dllxz = ``;
Graph.registerHTMLComponent('3_1_dllxz', html_3_1_dllxz);
const html_3_1_dgtable = `
<table style='font-size:12px'>
<tbody>
<tr>
<td>Uab</td>
<td><span class="Uab">0.00</span></td>
</tr>
<tr>
<td>Ubc</td>
<td><span class="Ubc">0.00</span></td>
</tr>
<tr>
<td>Uca</td>
<td><span class="Uca">0.00</span></td>
</tr>
<tr style="color: rgb(199, 199, 94);">
<td>Ia</td>
<td><span class="Ia">0.00</span></td>
</tr>
<tr style="color: rgb(178, 218, 178);">
<td>Ib</td>
<td><span class="Ib">0.00</span></td>
</tr>
<tr style="color: rgb(121, 5, 5);">
<td>Ic</td>
<td><span class="Ic">0.00</span></td>
</tr>
<tr>
<td>P</td>
<td><span class="P">0.00</span></td>
</tr>
<tr>
<td>Q</td>
<td><span class="Q">0.00</span></td>
</tr>
<tr>
<td>PF</td>
<td><span class="PF">0.000</span></td>
</tr>
<tr>
<td>F</td>
<td><span class="F">0.000</span></td>
</tr>
<tr>
<td>KWH</td>
<td><span class="KWH">0.00</span></td>
</tr>
</tbody>
</table>`;
Graph.registerHTMLComponent('3_1_dgtable', html_3_1_dgtable);
const html_3_1_dbtable = `<table style='font-size:12px'>
<tbody>
<tr style="color: rgb(199, 199, 94);">
<td>Ia</td>
<td><span class="Ia">0.00</span></td>
</tr>
<tr style="color: rgb(178, 218, 178);">
<td>Ib</td>
<td><span class="Ib">0.00</span></td>
</tr>
<tr style="color: rgb(121, 5, 5);">
<td>Ic</td>
<td><span class="Ic">0.00</span></td>
</tr>
</tbody>
</table>`;
Graph.registerHTMLComponent('3_1_dbtable', html_3_1_dbtable);
// const html_3_1_dllxy = ``;
// Graph.registerHTMLComponent('3_1_dllxy', html_3_1_dllxy);
const html_3_1_table = `
<table id="tableText" border="1" ondblclick="toEdit(this,true)"  style="border-collapse:collapse;";>
<tr>
<td style="width:100px;height:20px;border:1px solid orange;text-align: center;">
<input  style="width: 100%;border: none;outline: none;text-align: center;background-color: rgba(0,0,0,0);font-size: 10px;"value="1行1列">
</td>
<td style="width:100px;height:20px;border:1px solid orange;text-align: center;">
<input style="width: 100%;border: none;outline: none;text-align: center;background-color: rgba(0,0,0,0);font-size: 10px;" value="1行2列">
</td>
</tr>
<tr>
<td style="width:100px;height:20px;border:1px solid orange;text-align: center;">
<input  style="width: 100%;border: none;outline: none;text-align: center;background-color: rgba(0,0,0,0);font-size: 10px;"value="2行1列">
</td>
<td style="width:100px;height:20px;border:1px solid orange;text-align: center;">
<input style="width: 100%;border: none;outline: none;text-align: center;background-color: rgba(0,0,0,0);font-size: 10px;"value="2行2列">
</td>
</table>
`;
Graph.registerHTMLComponent('3_1_table', html_3_1_table);
const html_3_1_sbzt = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill="#780000" d="M15,5C9.5,5,5,9.5,5,15c0,5.5,4.5,10,10,10s10-4.5,10-10C25,9.5,20.5,5,15,5z"/>
<rect opacity="0" fill="#780000" width="30" height="30"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_1_sbzt', html_3_1_sbzt);
const html_3_1_dlqzt = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 viewBox="0 0 30 30" xml:space="preserve">
<g>
    <rect fill="#780000" x="7.5" y="7.5" width="15" height="15"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_1_dlqzt', html_3_1_dlqzt);
const html_3_1_jzsj = `
	<div class="jzsj" style="width: 100px;display: flex"><span id="jzkey" style="width: 50%;font-size: 12px">数据项</span><span id="jzvalue" style="width: 50%;font-size: 12px">数据值</span></div>
`;
Graph.registerHTMLComponent('3_1_jzsj', html_3_1_jzsj);
const html_3_1_wzbq = `
	<div class="wzbq" ondblclick="toEdit(this,true)"><input  class='wzbqInput' style="width: 60px;height:30px;border: none;outline: none;text-align: left;background-color: rgba(0,0,0,0);font-size: 10px;color:#780000 "value="文字标签"></div>
`;
Graph.registerHTMLComponent('3_1_wzbq', html_3_1_wzbq);
const html_3_1_dlq = `<svg version='1.1' id='html_3_1_dlq' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30' xml:space='preserve'>
<g>
	<rect x='11.2' y='4.7' width='7.5' height='20.7' stroke-width='1' stroke='#780000' fill='none'/>
	<line x1='15' y1='0' x2='15' y2='4.7' stroke-width='1' stroke='#780000' fill='#780000'/>
	<line x1='15' y1='25.3' x2='15' y2='30' stroke-width='1' stroke='#780000' fill='#780000'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_dlq', html_3_1_dlq);
const html_3_1_sckg = `<svg version='1.1' id='html_3_1_sckg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
 viewBox='0 0 30 30' xml:space='preserve'>
<g>
<polyline points='17.5,27 15.1,29.3 12.5,27' stroke-width='1' stroke='#780000' fill='none'/>
<polyline points='17.5,23.9 15.1,26.3 12.5,23.9' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='19.7' x2='15' y2='29.3' stroke-width='1' stroke='#780000' fill='none'/>
<polyline points='12.5,3 14.9,0.7 17.5,3' stroke-width='1' stroke='#780000' fill='none'/>
<polyline points='12.5,6.1 14.9,3.7 17.5,6.1' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='0.7' x2='15' y2='9.9' stroke-width='1' stroke='#780000' fill='none'/>
<rect x='11.7' y='9.7' class='st0' width='6.6' height='10.2' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_sckg', html_3_1_sckg);
const html_3_1_blq = `<svg version='1.1' id='html_3_1_blq' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
 viewBox='0 0 30 30' xml:space='preserve'>
<g>
<rect x='11' y='5.4' width='8' height='19.9' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='25.3' x2='15' y2='30' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='0' x2='15' y2='14.4' stroke-width='1' stroke='#780000' fill='none'/>
<polygon points='13.3,11.1 15,17.7 16.7,11.1' stroke-width='1' stroke='#780000' fill='#780000'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_blq', html_3_1_blq);
const html_3_1_glkg = `<svg version='1.1' id='html_3_1_glkg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
 viewBox='0 0 30 30' xml:space='preserve'>
<g>
<line class='st0' x1='15' y1='0' x2='15' y2='8' stroke-width='1' stroke='#780000'/>
<line class='st0' x1='10' y1='8' x2='19.8' y2='8' stroke-width='1' stroke='#780000'/>
<polyline class='st0' points='10.6,9.7 15,15 15,30' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_glkg', html_3_1_glkg);
const html_3_1_jd = `<svg version='1.1' id='html_3_1_jd' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30'
 xml:space='preserve'>
<g transform=''>
<ellipse cx='15' cy='19.6' rx='9.76' ry='9.9' stroke-width='1' stroke='#780000' style='fill:none;'/>
<line x1='15' y1='0' x2='15' y2='21.18' stroke-width='1' stroke='#780000'/>
<line x1='9.81' y1='21.18' x2='20.19' y2='21.18' stroke-width='1' stroke='#780000'/>
<line x1='11.4' y1='23.01' x2='18.6' y2='23.01' stroke-width='1' stroke='#780000'/>
<line x1='12.72' y1='24.82' x2='17.28' y2='24.82' stroke-width='1' stroke='#780000'/>
<rect width='30' height='30' opacity='0' stroke-width='1' stroke='#780000'></rect>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_jd', html_3_1_jd);
const html_3_1_jdbh = `<svg version='1.1' id='html_3_1_jdbh' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30'
 style='enable-background:new 0 0 40 40;' xml:space='preserve'>
<g>
<line x1='0' y1='10.3' x2='30' y2='10.3' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='2.65' y1='13.5' x2='27.35' y2='13.5' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='6.18' y1='16.7' x2='23.82' y2='16.7' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='7.94' y1='19.91' x2='22.06' y2='19.91' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='8.83' y1='23.11' x2='21.17' y2='23.11' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='12.36' y1='26.31' x2='17.65' y2='26.31' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='0' x2='15' y2='10.3' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_jdbh', html_3_1_jdbh);
const html_3_1_bzdr = `<svg version='1.1' id='html_3_1_bzdr' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
viewBox='0 0 30 30' xml:space='preserve'>
<g>
<line x1='15' y1='0' x2='15' y2='12.7' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='8.6' y1='12.2' x2='21.4' y2='12.2' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='30' x2='15' y2='17.3' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='21.4' y1='17.8' x2='8.6' y2='17.8' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_bzdr', html_3_1_bzdr);
const html_3_1_jlfdj = `<svg version='1.1' id='html_3_1_jlfdj' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
viewBox='0 0 30 30' xml:space='preserve'>
<g>
<circle cx='15' cy='17.4' r='12.1' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='0' x2='15' y2='5.3' stroke-width='1' stroke='#780000'/>
<path fill='#780000' d='M15.3,15.9v-1l3.5,0V18c-0.5,0.4-1.1,0.8-1.7,1c-0.6,0.2-1.2,0.3-1.8,0.3c-0.8,0-1.6-0.2-2.2-0.5 c-0.7-0.4-1.2-0.9-1.5-1.5c-0.3-0.7-0.5-1.4-0.5-2.2c0-0.8,0.2-1.6,0.5-2.3c0.3-0.7,0.8-1.2,1.5-1.6c0.6-0.3,1.4-0.5,2.2-0.5 c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.1,0.8c0.3,0.3,0.5,0.8,0.6,1.4l-1,0.3c-0.1-0.4-0.3-0.8-0.5-1c-0.2-0.2-0.5-0.4-0.8-0.6 c-0.3-0.1-0.7-0.2-1.1-0.2c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.2-0.7,0.4-0.9,0.6c-0.2,0.2-0.4,0.5-0.5,0.8c-0.2,0.5-0.3,1.1-0.3,1.7 c0,0.7,0.1,1.4,0.4,1.9c0.3,0.5,0.6,0.9,1.1,1.1c0.5,0.2,1,0.4,1.6,0.4c0.5,0,0.9-0.1,1.4-0.3c0.5-0.2,0.8-0.4,1-0.6v-1.6H15.3z'/>
<path d='M10.6,23c1.2-1.2,3.2-1.2,4.4,0' stroke-width='1' stroke='#780000' fill='none'/>
<path d='M19.4,23c-1.2,1.2-3.2,1.2-4.4,0' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_jlfdj', html_3_1_jlfdj);
const html_3_1_zlfdj = `<svg version='1.1' id='html_3_1_zlfdj' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
viewBox='0 0 30 30' xml:space='preserve'>
<g>
<circle cx='15' cy='17.4' r='12.1' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='0' x2='15' y2='5.3' stroke-width='1' stroke='#780000' fill='none'/>
<path fill='#780000' d='M15.3,15.9v-1l3.5,0V18c-0.5,0.4-1.1,0.8-1.7,1c-0.6,0.2-1.2,0.3-1.8,0.3c-0.8,0-1.6-0.2-2.2-0.5 c-0.7-0.4-1.2-0.9-1.5-1.5c-0.3-0.7-0.5-1.4-0.5-2.2c0-0.8,0.2-1.6,0.5-2.3c0.3-0.7,0.8-1.2,1.5-1.6c0.6-0.3,1.4-0.5,2.2-0.5 c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.1,0.8c0.3,0.3,0.5,0.8,0.6,1.4l-1,0.3c-0.1-0.4-0.3-0.8-0.5-1c-0.2-0.2-0.5-0.4-0.8-0.6 c-0.3-0.1-0.7-0.2-1.1-0.2c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.2-0.7,0.4-0.9,0.6c-0.2,0.2-0.4,0.5-0.5,0.8c-0.2,0.5-0.3,1.1-0.3,1.7 c0,0.7,0.1,1.4,0.4,1.9c0.3,0.5,0.6,0.9,1.1,1.1c0.5,0.2,1,0.4,1.6,0.4c0.5,0,0.9-0.1,1.4-0.3c0.5-0.2,0.8-0.4,1-0.6v-1.6H15.3z'/>
<line x1='10.7' y1='22.1' x2='19.3' y2='22.1' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='10.7' y1='23.9' x2='19.3' y2='23.9' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_zlfdj', html_3_1_zlfdj);
const html_3_1_xhd = `<svg version='1.1' id='html_3_1_xhd' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
 viewBox='0 0 30 30' xml:space='preserve'>
<g>
<circle cx='15' cy='15' r='14.5' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='8.7' y1='8.7' x2='21.3' y2='21.3' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='21.3' y1='8.7' x2='8.7' y2='21.3' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_xhd', html_3_1_xhd);
const html_3_1_srbzq = `<svg version='1.1' id='html_3_1_srbzq' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
viewBox='0 0 30 30' xml:space='preserve'>
<g>
<ellipse cx='15' cy='9.6' rx='6.5' ry='6.7' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='0' x2='15' y2='2.9' stroke-width='1' stroke='#780000' fill='none'/>
<ellipse cx='15' cy='20.4' rx='6.5' ry='6.7' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='30' x2='15' y2='27.1' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_srbzq', html_3_1_srbzq);
const html_3_1_srzbyq = `<svg version='1.1' id='html_3_1_srzbyq' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
viewBox='0 0 30 30' xml:space='preserve'>
<g>
<ellipse cx='15' cy='10.3' rx='7.2' ry='7.2' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='15' y1='0' x2='15' y2='3.1' stroke-width='1' stroke='#780000' fill='none'/>
<ellipse cx='7.7' cy='19.7' rx='7.2' ry='7.2' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='7.7' y1='30' x2='7.7' y2='26.9' stroke-width='1' stroke='#780000' fill='none'/>
<ellipse cx='22.3' cy='19.7' rx='7.2' ry='7.2' stroke-width='1' stroke='#780000' fill='none'/>
<line x1='23' y1='30' x2='23' y2='26.9' stroke-width='1' stroke='#780000' fill='none'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_srzbyq', html_3_1_srzbyq);
//新增元器件
const html_3_1_dk = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15,0 15,15.1 3.5,15.1 	"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M3.6,15.1C3.6,8.8,8.7,3.7,15,3.7s11.4,5.1,11.4,11.4S21.3,26.6,15,26.6V30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_dk', html_3_1_dk);
const html_3_1_dldgq = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M16.9,9.6h1.4c1.2,0,2.1,1.1,2.1,2.4l0,0c0,1.3-1,2.4-2.1,2.4h-1.4"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M16.9,14.4h1.4c1.2,0,2.1,1.1,2.1,2.4l0,0c0,1.3-1,2.4-2.1,2.4h-1.4"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M15,0v4.8h3.3c1.2,0,2.1,1.1,2.1,2.4l0,0c0,1.3-1,2.4-2.1,2.4h-1.4"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M15,30v-6h3.3c1.2,0,2.1-1.1,2.1-2.4l0,0c0-1.3-1-2.4-2.1-2.4h-1.4"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_dldgq', html_3_1_dldgq);
const html_3_1_dxdlhgq = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" rx="7" ry="7.2"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_dxdlhgq', html_3_1_dxdlhgq);
const html_3_1_jcqh = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11.4,14.5 15,20.9 15,30 "/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="15"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M14.8,14.5c-1.6,0-3-1.8-3-4.2s1.3-4.4,2.9-4.4"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_jcqh', html_3_1_jcqh);
const html_3_1_jcqk = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="10.1,14.6 15,21.6 15,30 "/>
<path fill="#780000" d="M14.5,0.1v4.2c-1.5,0.1-2.7,1.9-2.7,4.1s1.2,3.9,2.7,4.1v0h1V0.1H14.5z M12.8,8.4c0-1.6,0.8-2.9,1.7-3.1v6.1
C13.6,11.3,12.8,10,12.8,8.4z"/>
</svg>`;
Graph.registerHTMLComponent('3_1_jcqk', html_3_1_jcqk);
const html_3_1_lxdlhgq = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="6.9" cy="15" r="6.4"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.9" y1="1.6" x2="6.9" y2="28.4"/>
</g>
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="23.1" cy="15" r="6.4"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="23.1" y1="1.6" x2="23.1" y2="28.4"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_lxdlhgq', html_3_1_lxdlhgq);
const html_3_1_scbhdlq = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="24.6" x2="15" y2="24.6"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="30" x2="15" y2="24.6"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="5.4" x2="15" y2="5.4"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="5.4"/>
</g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="10.8,10.5 15,8.1 19.2,10.5 	"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="19.2,22.6 15,25 10.8,22.7 	"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="19.2,20.3 15,22.7 10.8,20.4 	"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="10.8,7.8 15,5.4 19.2,7.8 	"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_scbhdlq', html_3_1_scbhdlq);
const html_3_1_schdlq = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="2"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="24.3" x2="15" y2="24.3"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="10.3" x2="15" y2="5"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="13.2" y1="8.8" x2="16.8" y2="12.1"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="16.8" y1="8.8" x2="13.2" y2="12.1"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="26" x2="15" y2="30"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="19.3,11.7 15,18.3 15,24.5 "/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="18.2,24.5 15,26.7 11.8,24.5 "/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="18.2,22.5 15,24.7 11.8,22.5 "/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11.8,6.5 15,4.3 18.2,6.5 "/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11.8,4 15,1.7 18.2,4 "/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_schdlq', html_3_1_schdlq);
const html_3_1_srzbyq1 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="9.8" rx="6.8" ry="6.9"/>
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11.5,6.6 15,9 18.5,6.6 		"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="9" x2="15" y2="13"/>
</g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="3.1" x2="15" y2="0"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="20.7" cy="20" rx="6.8" ry="6.9"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="17.2,17.6 20.7,20 24.2,17.6 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="20.7" y1="20" x2="20.7" y2="24"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="20.7" y1="30" x2="20.7" y2="26.9"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="9.3" cy="20" rx="6.8" ry="6.9"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="5.8,17.6 9.3,20 12.8,17.6 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="9.3" y1="20" x2="9.3" y2="24"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="9.3" y1="30" x2="9.3" y2="26.9"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_srzbyq1', html_3_1_srzbyq1);
const html_3_1_srzbyq2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15.1" cy="9.8" rx="6.8" ry="6.9"/>
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11.5,6.6 15.1,9 18.6,6.6 		"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15.1" y1="9" x2="15.1" y2="13"/>
</g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15.1" y1="3.1" x2="15.1" y2="0"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="21" cy="20" rx="6.8" ry="6.9"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="17.5,17.6 21,20 24.6,17.6 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="21" y1="20" x2="21" y2="24"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="21" y1="30" x2="21" y2="26.9"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="9" cy="20" rx="6.8" ry="6.9"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="9" y1="30" x2="9" y2="26.9"/>
<polygon fill="none" stroke="#780000" stroke-miterlimit="10" points="8.8,18 5.9,23.8 12,23.8 	"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_srzbyq2', html_3_1_srzbyq2);
const html_3_1_srzbyq3 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="21" cy="10.1" rx="6.8" ry="6.9"/>
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="17.5,6.9 21,9.3 24.5,6.9 		"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="21" y1="9.3" x2="21" y2="13.4"/>
</g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="21" y1="3.4" x2="21" y2="0"/>
</g>
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="19.9" rx="6.8" ry="6.9"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11.5,17.5 15,20 18.5,17.5 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="20" x2="15" y2="24"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="30" x2="15" y2="26.8"/>
</g>
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="9.1" cy="10.1" rx="6.8" ry="6.9"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="9.1" y1="0" x2="9.1" y2="3.2"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M6.2,8.6c0.2,0,4.1-1.2,4.1-1.2v4l-4.1-0.8"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_srzbyq3', html_3_1_srzbyq3);
const html_3_1_srzdyhgq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="10.7" rx="7.5" ry="7.5"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="3.5" x2="15" y2="0"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="21.3" cy="22" rx="7.5" ry="7.5"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="8.7" cy="22" rx="7.5" ry="7.5"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_srzdyhgq', html_3_1_srzdyhgq);
const html_3_1_surzbyq1 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="10.1" rx="6" ry="6.2"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="19.9" rx="6" ry="6.2"/>
<polygon fill="none" stroke="#780000" stroke-miterlimit="10" points="14.9,6.4 11.9,11.4 18.1,11.4 "/>
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11.9,18.2 15,20.4 18.1,18.2 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="20.4" x2="15" y2="24.9"/>
</g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="30" x2="15" y2="26.1"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="3.8" x2="15" y2="0"/>
</svg>`;
Graph.registerHTMLComponent('3_1_surzbyq1', html_3_1_surzbyq1);
const html_3_1_surzdyhgq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="22.1" rx="7.2" ry="7.4"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="10.5" rx="7.2" ry="7.4"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="3.4" x2="15" y2="0"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_surzdyhgq', html_3_1_surzdyhgq);
const html_3_1_sxdlhgq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<g>
<circle fill="none" stroke="#780000" stroke-miterlimit="10" cx="4.6" cy="15" r="4.1"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="4.6" y1="6.4" x2="4.6" y2="23.6"/>
</g>
<g>
<circle fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="15" r="4.1"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="6.4" x2="15" y2="23.6"/>
</g>
<g>
<circle fill="none" stroke="#780000" stroke-miterlimit="10" cx="25.4" cy="15" r="4.1"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="25.4" y1="6.4" x2="25.4" y2="23.6"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_sxdlhgq', html_3_1_sxdlhgq);
const html_3_1_wgbcq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="11.6" y1="20.1" x2="15.3" y2="26.6"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="13.6,23.5 3.9,28.6 3.9,18.7 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="8" y1="18.7" x2="0" y2="18.7"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="7.9" y1="12.9" x2="0" y2="12.9"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="3.9,12.9 3.9,1.4 13.4,6.7 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="11.6" y1="10.1" x2="15.3" y2="3.5"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="17.1" y1="13.3" x2="21.3" y2="6.6"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="19.1,9.8 29,15.5 19.9,20.2 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="17.1" y1="16.9" x2="21.3" y2="23.4"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_1_wgbcq', html_3_1_wgbcq);


const html_3_2_cgq = `<svg version='1.1' id='html_3_2_cgq' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30' xml:space='preserve'>
<g>
<ellipse class='st0' cx='7.95' cy='15' rx='7.45' ry='7.48' stroke-width='1' stroke='#780000' style='fill:none;'/>
<line class='st0' x1='0.5' y1='15' x2='30' y2='15' stroke-width='1' stroke='#780000'/>
<polyline class='st0' points='7.95,22.48 15.41,15 7.95,7.52' stroke-width='1' stroke='#780000' style='fill:none;'/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_cgq', html_3_2_cgq);
const html_3_2_dl = `<svg version="1.1" id="html_3_2_dl" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="15" x2="15.2" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M15.2,29.5c7.9,0,14.3-6.49,14.3-14.5S23.1,0.5,15.2,0.5V29.5z" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dl', html_3_2_dl);
const html_3_2_bxs = `<svg version="1.1" id="html_3_2_bxs" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse cx="15" cy="3.67" rx="3.2" ry="3.17" stroke-width="1" stroke="#780000" fill="none"/>
<ellipse cx="15" cy="26.33" rx="3.2" ry="3.17" stroke-width="1" stroke="#780000" fill="none"/>
<path  stroke-width="1" stroke="#780000" fill="none" d="M17.63,5.58L17.9,5.9c0.75,0.88,1.16,2,1.16,3.15v1.15c0,1.67-0.86,3.22-2.28,4.12l-3.57,2.24 c-1.42,0.89-2.28,2.45-2.28,4.12v0c0,1.3,0.53,2.55,1.46,3.46l0.21,0.21"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_bxs', html_3_2_bxs);
const html_3_2_dljhq = `<svg version="1.1" id="html_3_2_dljhq" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path d="M19.87,0.5c4.06,0,7.35,3.25,7.35,7.25S23.93,15,20,15" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M19.87,29.5c4.06,0,7.35-3.25,7.35-7.25S23.93,15,19.87,15H0" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dljhq', html_3_2_dljhq);
const html_3_2_dhs = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" x1="0" y1="15" x2="1.9" y2="15"/>
<path fill="none" stroke="#780000" d="M4.6,17.8c-1.6,0-2.8-1.3-2.8-2.9s1.3-2.9,2.8-2.9s2.8,1.3,2.8,2.9h7"/>
<polygon stroke="#780000" points="21.3,16.5 21.3,13.3 18.7,15 \t"/>
<polygon stroke="#780000" points="13.3,13.1 13.3,16.5 15.8,15 \t"/>
<polyline fill="none" stroke="#780000" points="19.4,15 27.8,15 27.8,20.6 \t"/>
<line fill="none" stroke="#780000" x1="24.9" y1="20.9" x2="30" y2="20.9"/>
<line fill="none" stroke="#780000" x1="25.3" y1="22.5" x2="29.5" y2="22.5"/>
<line fill="none" stroke="#780000" x1="25.7" y1="24.1" x2="29.1" y2="24.1"/>
<rect x="9.4" y="9.8" fill="none" stroke="#780000" width="15.4" height="9.9"/>
<path fill="none" stroke="#780000" d="M8.9,18.4L8.9,18.4c-0.3,0-0.5-0.2-0.5-0.5v-6.3c0-0.3,0.2-0.5,0.5-0.5l0,0c0.3,0,0.5,0.2,0.5,0.5v6.3
C9.4,18.2,9.2,18.4,8.9,18.4z"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_2_dhs', html_3_2_dhs);
const html_3_2_dwjdzq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" points="0.4,17.9 3,12.1 8,23.7 13,12.1 17,24.6 22.1,13 27.1,24.6 29.5,17.9 \t"/>
<line fill="none" stroke="#780000" x1="14.9" y1="3.9" x2="14.9" y2="12.1"/>
<polyline fill="none" stroke="#780000" points="13.2,8.4 14.9,12.1 16.9,8.4 \t"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_2_dwjdzq', html_3_2_dwjdzq);
const html_3_2_bxsobsolete = `<svg version="1.1" id="html_3_2_bxsobsolete" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="15" x2="4.59" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="25.41" y1="15" x2="30" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="6.41" y1="13.07" x2="23.32" y2="16.93" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="23.32" y1="13.07" x2="6.59" y2="16.93" stroke-width="1" stroke="#780000" fill="none"/>
<ellipse cx="6.41" cy="15" rx="2.08" ry="1.93" stroke-width="1" stroke="#780000" fill="none"/>
<ellipse cx="23.32" cy="15" rx="2.08" ry="1.93" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_bxsobsolete', html_3_2_bxsobsolete);
const html_3_2_drq4 = `<svg version="1.1" id="html_3_2_drq4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="21.17" x2="11.7" y2="21.17" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="11.46" y1="14.03" x2="11.46" y2="28.31" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="30" y1="21.17" x2="18.3" y2="21.17" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="18.54" y1="28.31" x2="18.54" y2="14.03" stroke-width="1" stroke="#780000" fill="none"/>
<line class="st1" x1="15" y1="14.03" x2="15" y2="28.31" stroke-width="3" stroke="#780000" fill="none"/>
<line x1="15" y1="1.69" x2="15" y2="28.31" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_drq4', html_3_2_drq4);
const html_3_2_bdgq = `<svg version="1.1" id="html_3_2_bdgq" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="0.5" x2="20.19" y2="0.5" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="0" y1="29.5" x2="20.19" y2="29.5" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M19.76,15c3.83,0,6.93,3.25,6.93,7.25s-3.1,7.25-6.93,7.25" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M19.76,0.5c3.83,0,6.93,3.25,6.93,7.25S23.59,15,19.76,15" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_bdgq', html_3_2_bdgq);
const html_3_2_cl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill="#780000" d="M2.6,0v30h24.8V0H2.6z M26.4,29H3.6V1h22.8V29z"/>
<path fill="#780000" d="M14.2,19c0.4,0,0.8-0.1,1.2-0.3c0.4-0.2,0.7-0.4,1-0.8c0.4,0.5,0.8,0.9,1.2,1.1l0.6-0.8c-0.5-0.3-0.9-0.7-1.2-1.2c0.3-0.4,0.5-1,0.7-1.7l-1-0.2c-0.1,0.4-0.2,0.8-0.4,1.1L15,14.5c1-0.5,1.5-1.2,1.5-1.9c0-0.5-0.2-0.9-0.5-1.2s-0.8-0.5-1.3-0.5c-0.6,0-1,0.2-1.4,0.5c-0.4,0.3-0.6,0.8-0.6,1.2c0,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.4,0.6,0.7,1c-0.6,0.3-1.1,0.6-1.4,1c-0.3,0.4-0.5,0.8-0.5,1.3c0,0.5,0.2,1,0.6,1.5C12.8,18.8,13.4,19,14.2,19z M13.9,12c0.2-0.2,0.4-0.3,0.6-0.3c0.3,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.4,0.4-0.8,0.6l-0.4-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.3-0.1-0.4C13.7,12.3,13.8,12.1,13.9,12z M13,15.9c0.2-0.3,0.5-0.6,1-0.9l1.8,2.2c-0.2,0.3-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.3-0.9,0.3c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.4-1C12.8,16.4,12.8,16.2,13,15.9z"
/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_cl', html_3_2_cl);
const html_3_2_cgq2 = `<svg version="1.1" id="html_3_2_cgq2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline points="6.63,0 6.63,17.31 23.37,17.31 23.37,0" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="13.84" y1="11.74" x2="13.84" y2="21.38" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="16.31" y1="11.74" x2="16.31" y2="21.38" stroke-width="1" stroke="#780000" fill="none"/>
<polyline points="12.67,14.67 19.51,21.71 19.51,30" stroke-width="1" stroke="#780000" fill="none"/>
<polygon points="8.08,10.34 11.8,15.77 13.84,13.5" stroke-width="1" stroke="#780000" fill="#780000"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_cgq2', html_3_2_cgq2);
const html_3_2_cx = `<svg version="1.1" id="html_3_2_cx" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="12.31" y1="0" x2="12.31" y2="30" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="17.69" y1="0" x2="17.69" y2="30" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_cx', html_3_2_cx);
const html_3_2_d = `<svg version="1.1" id="html_3_2_d" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path  stroke-width="1" stroke="#780000" fill="none" d="M26.03,22.82V10.53C26.03,4.99,21.09,0.5,15,0.5h0C8.91,0.5,3.97,4.99,3.97,10.53v12.29H26.03z"/>
<path  stroke-width="1" stroke="#780000" fill="none" d="M9.43,30V12.48c0-2.92,2.49-5.28,5.57-5.28h0c3.07,0,5.57,2.37,5.57,5.28V30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_d', html_3_2_d);
const html_3_2_d2 = `<svg version="1.1" id="html_3_2_d2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle cx="15" cy="15" r="14.5" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="5.54" y1="4.7" x2="25.32" y2="25.19" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="25.67" y1="5.06" x2="5.18" y2="24.83" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_d2', html_3_2_d2);
const html_3_2_d3 = `<svg version="1.1" id="html_3_2_d3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse cx="15" cy="18.72" rx="11.08" ry="10.78" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="7.77" y1="11.06" x2="22.88" y2="26.29" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="23.15" y1="11.32" x2="7.5" y2="26.03" stroke-width="1" stroke="#780000" fill="none"/>
<polyline points="5.25,6.68 9.45,6.68 9.45,0.5 19.71,0.5 19.71,6.68 24.75,6.68" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_d3', html_3_2_d3);
const html_3_2_d4 = `<svg version="1.1" id="html_3_2_d4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle cx="15" cy="15" r="14.5" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_d4', html_3_2_d4);
const html_3_2_bxs2 = `<svg version="1.1" id="html_3_2_bxs2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="3" y="0.5" width="24" height="6.17" stroke-width="1" stroke="#780000" fill="none"/>
<rect x="3" y="23.33" width="24" height="6.17" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="8.67" y1="6.67" x2="8.67" y2="23.33" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="22.11" y1="6.67" x2="22.11" y2="23.33" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_bxs2', html_3_2_bxs2);
const html_3_2_apbzhkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill="#780000" d="M0,0v30h30V0H0z M29,29H1V1h28V29z"/>
<path fill="#780000" d="M6.7,10.5h3l0.7,1.7H12L9,4.7H7.4l-2.9,7.5h1.6L6.7,10.5z M8.2,6.4l1,2.8H7.2L8.2,6.4z"/>
<path fill="#780000" d="M22.8,21.3c-0.4,0-0.8-0.1-1-0.3c-0.3-0.2-0.4-0.6-0.5-1L19.9,20c0.1,0.8,0.4,1.4,0.9,1.8c0.5,0.4,1.2,0.6,2.1,0.6c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.7-0.4,1-0.8c0.2-0.4,0.3-0.7,0.3-1.1c0-0.4-0.1-0.8-0.3-1.1s-0.5-0.5-0.8-0.7c-0.3-0.2-0.9-0.3-1.5-0.5c-0.7-0.2-1.1-0.3-1.3-0.5c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.6-0.3,1-0.3c0.4,0,0.7,0.1,0.9,0.2S24,17,24,17.3l1.4-0.1c0-0.7-0.3-1.2-0.7-1.6c-0.5-0.4-1.1-0.6-2-0.6c-0.5,0-1,0.1-1.4,0.2s-0.7,0.4-0.9,0.7s-0.3,0.7-0.3,1c0,0.6,0.2,1,0.7,1.4c0.3,0.3,0.9,0.5,1.6,0.7c0.6,0.1,1,0.3,1.2,0.3c0.2,0.1,0.4,0.2,0.5,0.3s0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.4,0.7C23.6,21.2,23.3,21.3,22.8,21.3z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_apbzhkg', html_3_2_apbzhkg);
const html_3_2_drq = `<svg version="1.1" id="html_3_2_drq" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="15.25" y1="0" x2="15.25" y2="13.57" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="15.25" y1="16.43" x2="15.25" y2="30" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="9.14" y1="13.57" x2="21.36" y2="13.57" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="9.14" y1="16.43" x2="21.36" y2="16.43" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="19.94" y1="11.13" x2="8.73" y2="20.74" stroke-width="1" stroke="#780000" fill="none"/>
<polygon points="21.59,9.69 16.49,11.16 19.51,14.62 " stroke-width="1"  fill="#780000"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_drq', html_3_2_drq);
const html_3_2_dhgdhq = `<svg version="1.1" id="html_3_2_dhgdhq" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="14.95" x2="30" y2="14.95" stroke-width="1" stroke="#780000" fill="none"/>
<polyline stroke-width="1" stroke="#780000" fill="none" points="10.98,21.23 10.98,17.02 8.53,17.02 8.53,8.68 21.74,8.68 21.74,17.15 19.38,17.15 19.38,21.45"/>
<line stroke-width="1" stroke="#780000" fill="none" x1="15.25" y1="17.38" x2="15.25" y2="18.96"/>
<polygon stroke-width="1" stroke="#780000" fill="none" points="13.5,18.96 17,18.96 15.25,21.82"/>
<path stroke-width="1" stroke="#780000" fill="none" d="M8.74,13.41l2.23-1.87l1.66,2.99l2.04-2.99c0,0,1.57,2.98,1.57,2.99s1.85-2.99,1.85-2.99l1.63,2.99 l2.03-1.97"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dhgdhq', html_3_2_dhgdhq);
const html_3_2_dj = `<svg version="1.1" id="html_3_2_dj" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon  stroke-width="1"  fill="#780000" points="10.45,14.2 0.53,14.2 0.53,15.8 10.45,15.8 10.45,30 12.06,30 12.06,0 10.45,0 "/>
<polygon stroke-width="1"  fill="#780000" points="18.49,14.2 18.49,7.49 16.88,7.49 16.88,22.51 18.49,22.51 18.49,15.8 29.47,15.8 29.47,14.2 "/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dj', html_3_2_dj);
const html_3_2_dgq = `<svg version="1.1" id="html_3_2_dgq" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0.5" y1="19.89" x2="0.5" y2="14.18" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="29.5" y1="19.89" x2="29.5" y2="14.18" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M0.5,14.18c0-1.97,1.62-3.57,3.62-3.57s3.62,1.6,3.62,3.57" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M7.75,14.18c0-1.97,1.62-3.57,3.62-3.57S15,12.2,15,14.18" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M15,14.18c0-1.97,1.62-3.57,3.62-3.57s3.62,1.6,3.62,3.57" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M22.25,14.18c0-1.97,1.62-3.57,3.62-3.57s3.62,1.6,3.62,3.57" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dgq', html_3_2_dgq);
const html_3_2_drq3 = `<svg version="1.1" id="html_3_2_drq3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="19.88" y1="11.09" x2="9.23" y2="21.79" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="17.42" y1="8.22" x2="22.52" y2="13.16" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="0.02" y1="15.43" x2="13.18" y2="15.43" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="13.47" y1="9.47" x2="13.47" y2="21.39" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="29.98" y1="15.43" x2="16.82" y2="15.43" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="16.53" y1="21.39" x2="16.53" y2="9.47" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_drq3', html_3_2_drq3);
const html_3_2_dlb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path  fill="#780000" d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M15,29C7.3,29,1,22.7,1,15S7.3,1,15,1s14,6.3,14,14S22.7,29,15,29z"/>
<path  fill="#780000" d="M14.3,10.7L11,19.3h1.2l0.9-2.6h3.6l1,2.6H19l-3.5-8.6H14.3z M13.5,15.8l0.9-2.5c0.2-0.5,0.4-1.1,0.5-1.6c0.1,0.5,0.3,1.1,0.6,1.8l0.9,2.4H13.5z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dlb', html_3_2_dlb);
const html_3_2_bxsIEC = `<svg version="1.1" id="html_3_2_bxsIEC" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="5.42" y="12.1" width="19.15" height="5.79" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="0" y1="15" x2="5.42" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="24.58" y1="15" x2="30" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="7.5" y1="12.14" x2="7.5" y2="17.86" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="22.8" y1="12.14" x2="22.8" y2="17.86" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_bxsIEC', html_3_2_bxsIEC);
const html_3_2_bzdhg = `<svg version="1.1" id="html_3_2_bzdhg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="15" x2="7.49" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="22.51" y1="15" x2="30" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<ellipse cx="15" cy="15" rx="7.51" ry="7.37" stroke-width="1" stroke="#780000" fill="none"/>
<ellipse cx="8.29" cy="15" rx="0.92" ry="0.9" stroke-width="1" stroke="#780000" fill="none"/>
<ellipse cx="21.59" cy="15" rx="0.92" ry="0.9" stroke-width="1" stroke="#780000" fill="none"/>
<polyline points="10.75,12.66 12.41,11.33 18.28,18.04 20.39,16.11" stroke-width="1" stroke="#780000" fill="none"/>
<polygon points="10.91,11.04 9.65,13.79 12.47,12.81" stroke-width="1" stroke="#780000" fill="#780000"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_bzdhg', html_3_2_bzdhg);
const html_3_2_dybhq = `<svg version="1.1" id="html_3_2_dybhq" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="15.22" x2="6.92" y2="15.22" stroke-width="1" stroke="#780000" fill="none"/>
<circle cx="9.59" cy="15" r="2.82" stroke-width="1"  fill="#780000" />
<line x1="30" y1="14.78" x2="23.08" y2="14.78" stroke-width="1" stroke="#780000" fill="none"/>
<circle  cx="20.41" cy="15" r="2.82" stroke-width="1"  fill="#780000" />
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dybhq', html_3_2_dybhq);
const html_3_2_dybhq2 = `<svg version="1.1" id="html_3_2_dybhq2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="15" x2="9.51" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<rect x="9.22" y="10.8" width="3.89" height="8.4" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="30" y1="15" x2="20.49" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<rect  stroke-width="1" stroke="#780000" fill="none" x="16.9" y="10.8" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 37.6806 30)" width="3.89" height="8.4"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dybhq2', html_3_2_dybhq2);
const html_3_2_dc = `<svg version="1.1" id="html_3_2_dc" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="15" x2="7.32" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="7.32" y1="12.07" x2="7.32" y2="17.93" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="10.54" y1="9.88" x2="10.54" y2="20.12" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="13.76" y1="12.8" x2="13.76" y2="17.2" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="16.98" y1="9.88" x2="16.98" y2="20.12" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="20.2" y1="12.44" x2="20.2" y2="17.56" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="23.42" y1="9.88" x2="23.42" y2="20.12" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="23.42" y1="15" x2="30" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dc', html_3_2_dc);
const html_3_2_bxsIEEE = `<svg version="1.1" id="html_3_2_bxsIEEE" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="5.64" y="12.21" width="18.73" height="5.58" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="0" y1="15" x2="30" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_bxsIEEE', html_3_2_bxsIEEE);
const html_3_2_ddj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill="#780000" d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M15,29C7.3,29,1,22.7,1,15S7.3,1,15,1s14,6.3,14,14S22.7,29,15,29z"/>
<path fill="#780000" d="M15.5,16.7c-0.2,0.6-0.4,1.1-0.5,1.4c-0.1-0.3-0.2-0.7-0.4-1.3l-2-6.1h-1.7v8.6H12V12l2.5,7.3h1l2.5-7.2v7.2h1.1v-8.6h-1.5L15.5,16.7z"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_2_ddj', html_3_2_ddj);
const html_3_2_dy = `<svg version="1.1" id="html_3_2_dy" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle cx="15" cy="15" r="14.5" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="3.61" y1="15" x2="10.86" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="17.6" y1="15" x2="25.88" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="21.74" y1="10.86" x2="21.74" y2="19.14" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dy', html_3_2_dy);
const html_3_2_drq2 = `<svg version="1.1" id="html_3_2_drq2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="0" y1="15" x2="13.18" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="13.47" y1="9.03" x2="13.47" y2="20.97" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="30" y1="15" x2="16.82" y2="15" stroke-width="1" stroke="#780000" fill="none"/>
<line x1="16.53" y1="20.97" x2="16.53" y2="9.03" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_drq2', html_3_2_drq2);
const html_3_2_dybyq = `<svg version="1.1" id="html_3_2_dybyq" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path d="M22.25,10.27c0,1.94-1.62,3.52-3.62,3.52S15,12.22,15,10.27" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M7.75,10.27c0,1.94,1.62,3.52,3.62,3.52S15,12.22,15,10.27V3.52" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M0.5,10.27c0,1.94,1.62,3.52,3.62,3.52s3.62-1.58,3.62-3.52" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M22.25,10.27c0,1.94,1.62,3.52,3.62,3.52s3.62-1.58,3.62-3.52" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M7.75,19.34c0-1.94,1.62-3.52,3.62-3.52S15,17.4,15,19.34" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M22.25,19.34c0-1.94-1.62-3.52-3.62-3.52S15,17.4,15,19.34v7.13" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M29.5,19.34c0-1.94-1.62-3.52-3.62-3.52s-3.62,1.58-3.62,3.52" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M7.75,19.34c0-1.94-1.62-3.52-3.62-3.52S0.5,17.4,0.5,19.34" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dybyq', html_3_2_dybyq);
const html_3_2_dds = `<svg version="1.1" id="html_3_2_dds" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line x1="14.94" y1="0" x2="14.94" y2="22.9" stroke-width="1" stroke="#780000" fill="none"/>
<polygon points="8.63,22.9 21.35,22.9 14.94,29.29" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dds', html_3_2_dds);
const html_3_2_bxs3 = `<svg version="1.1" id="html_3_2_bxs3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path d="M15,15c0-2.26-1.89-4.1-4.21-4.1S6.57,12.74,6.57,15H0" stroke-width="1" stroke="#780000" fill="none"/>
<path d="M15,15c0,2.26,1.89,4.1,4.21,4.1s4.21-1.84,4.21-4.1H30" stroke-width="1" stroke="#780000" fill="none"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_bxs3', html_3_2_bxs3);
const html_3_2_dyb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill="#780000" d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M15,29C7.3,29,1,22.7,1,15S7.3,1,15,1s14,6.3,14,14S22.7,29,15,29z"/>
<path fill="#780000" d="M15.4,16.9c-0.2,0.5-0.3,0.9-0.5,1.4c-0.1-0.4-0.3-0.9-0.4-1.4l-2.2-6.2h-1.2l3.3,8.6h1.2l3.4-8.6h-1.2L15.4,16.9z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dyb', html_3_2_dyb);


const html_3_2_dlq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<line  x1="12.44" y1="0" x2="12.44" y2="7.94" fill="none" stroke="#780000" stroke-miterlimit="10" />
<line  x1="12.44" y1="22.06" x2="12.44" y2="30" fill="none" stroke="#780000" stroke-miterlimit="10"/>
<path  d="M13.58,7.68L13.58,7.68c2.19,0,3.97,2.01,3.97,4.49v5.66c0,2.48-1.78,4.49-3.97,4.49h0" fill="none" stroke="#780000" stroke-miterlimit="10"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dlq', html_3_2_dlq);
const html_3_2_dzq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="0.45,14.45 3.45,8.2 8.47,20.71 13.48,8.2 17.49,21.76 22.51,9.25 27.51,21.76 29.52,14.45 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_dzq', html_3_2_dzq);
const html_3_2_ejg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<line  fill="none" stroke="#780000" stroke-miterlimit="10"  x1="15" y1="0" x2="15" y2="10.41"/>
<line  fill="none" stroke="#780000" stroke-miterlimit="10"  x1="15" y1="19.9" x2="15" y2="30"/>
<line  fill="none" stroke="#780000" stroke-miterlimit="10"  x1="9.25" y1="19.9" x2="20.75" y2="19.9"/>
<path  fill="none" stroke="#780000" stroke-miterlimit="10"  d="M9.26,10.75c0.69,0,11.49,0,11.49,0l-5.69,9.15L9.26,10.75z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ejg', html_3_2_ejg);
const html_3_2_ejgLED = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="10"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="19.7" x2="15" y2="30"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="10.2" y1="19.7" x2="19.8" y2="19.7"/>
<polygon fill="none" stroke="#780000"  stroke-miterlimit="10" points="10.2,10 19.8,10 15,19.7 \t"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="11.1" y1="12.7" x2="7.3" y2="16.6"/>
<polygon fill="#780000"  points="6.8,14.7 5.2,18.5 9,17.1 \t\t"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="12.7" y1="15.4" x2="8.1" y2="20"/>
<polygon fill="#780000"  points="7.6,18 6,21.9 9.8,20.5 \t\t"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ejgLED', html_3_2_ejgLED);
const html_3_2_ejgLED2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="19.8" y1="9.3" x2="23.5" y2="5.5"/>
<polygon fill="#780000" stroke="#780000" points="24,7.4 25.5,3.7 21.9,5 \t\t"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15.2" y1="9.3" x2="19.6" y2="4.8"/>
<polygon fill="#780000" stroke="#780000" points="20.1,6.7 21.6,3 18,4.3 \t\t"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="30" y2="15"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="19.8" y1="10.1" x2="19.8" y2="19.9"/>
<polygon  fill="#780000"  points="9.6,9.6 9.6,20.4 20.3,15 \t"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ejgLED2', html_3_2_ejgLED2);
const html_3_2_fdj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill="#780000"  d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M15,29C7.3,29,1,22.7,1,15S7.3,1,15,1s14,6.3,14,14S22.7,29,15,29z"/>
<path fill="#780000"  d="M15.3,15.9h2.5v1.6c-0.2,0.2-0.6,0.4-1.1,0.6c-0.5,0.2-0.9,0.3-1.4,0.3c-0.6,0-1.1-0.1-1.6-0.4c-0.5-0.2-0.9-0.6-1.1-1.1s-0.4-1.2-0.4-1.9c0-0.6,0.1-1.2,0.3-1.7c0.1-0.3,0.3-0.6,0.5-0.8c0.2-0.3,0.5-0.5,0.9-0.6c0.4-0.2,0.8-0.2,1.3-0.2c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.6,0.3,0.8,0.6c0.2,0.2,0.4,0.6,0.5,1l1-0.3c-0.1-0.6-0.4-1-0.6-1.4s-0.7-0.6-1.2-0.8c-0.5-0.2-1.1-0.3-1.7-0.3c-0.9,0-1.6,0.2-2.3,0.5c-0.7,0.4-1.2,0.9-1.5,1.6C11.2,13.4,11,14.2,11,15c0,0.8,0.2,1.6,0.5,2.3c0.4,0.7,0.9,1.2,1.6,1.6c0.7,0.4,1.5,0.5,2.3,0.5c0.6,0,1.2-0.1,1.8-0.3c0.6-0.2,1.2-0.6,1.7-1v-3.2l-3.6,0V15.9z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_fdj', html_3_2_fdj);
const html_3_2_fmq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="14.31" x2="9.82" y2="14.31"/>
<rect x="9.82" y="7.33" fill="none" stroke="#780000" stroke-miterlimit="10" width="15.16" height="15.33"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="29.54" y1="7.33" x2="24.98" y2="17.09"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_fmq', html_3_2_fmq);
const html_3_2_ftbzhkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill="#780000"  d="M0,0v30h30V0H0z M29,29H1V1h28V29z"/>
<polygon fill="#780000"  points="8.8,12.2 11.5,4.7 9.9,4.7 8,10.2 6.1,4.7 4.5,4.7 7.2,12.2 	"/>
<path fill="#780000"  d="M22.8,21.3c-0.4,0-0.8-0.1-1-0.3c-0.3-0.2-0.4-0.6-0.5-1L19.9,20c0.1,0.8,0.4,1.4,0.9,1.8c0.5,0.4,1.2,0.6,2.1,0.6c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.7-0.4,1-0.8c0.2-0.4,0.3-0.7,0.3-1.1c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.9-0.3-1.5-0.5c-0.7-0.2-1.1-0.3-1.3-0.5c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.6-0.3,1-0.3c0.4,0,0.7,0.1,0.9,0.2S24,17,24,17.3l1.4-0.1c0-0.7-0.3-1.2-0.7-1.6c-0.5-0.4-1.1-0.6-2-0.6c-0.5,0-1,0.1-1.4,0.2c-0.4,0.2-0.7,0.4-0.9,0.7c-0.2,0.3-0.3,0.7-0.3,1c0,0.6,0.2,1,0.7,1.4c0.3,0.3,0.9,0.5,1.6,0.7c0.6,0.1,1,0.3,1.1,0.3c0.2,0.1,0.4,0.2,0.5,0.3s0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.4,0.7C23.6,21.2,23.3,21.3,22.8,21.3z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ftbzhkg', html_3_2_ftbzhkg);
const html_3_2_gyljdhg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="2.3" cy="15.07" rx="1.8" ry="1.83"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="27.7" cy="15.07" rx="1.8" ry="1.83"/>
<path  fill="none" stroke="#780000" stroke-miterlimit="10" d="M2.88,13.24c0-2.02,1.61-3.66,3.6-3.66c1.66,0,3.06,1.14,3.48,2.68c0.25,0.93,1.81,5.75,1.81,5.75c0.5,1.43,1.84,2.39,3.34,2.39h0c1.42,0,2.69-0.85,3.26-2.17c0,0,2.03-5.14,2.25-5.97c0.42-1.55,1.82-2.68,3.48-2.68c1.99,0,3.6,1.64,3.6,3.66"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_gyljdhg', html_3_2_gyljdhg);
const html_3_2_gzljd = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill="#780000"  cx="15" cy="17.66" r="12.34"/>
<line stroke="#780000" fill="none" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="8.1"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_gzljd', html_3_2_gzljd);
const html_3_2_hldy = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10'  cx="15" cy="15" rx="4.94" ry="5.08"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="9.92"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="20.08" x2="15" y2="30"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="12.2" x2="15" y2="16"/>
<polygon fill="#780000" points="13.79,16 16.21,16 14.94,17.68 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_hldy', html_3_2_hldy);
const html_3_2_hltx = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M11.71,23.5c-1.85-0.53-3.6-1.52-5.06-2.98c-4.61-4.58-4.61-12.01,0-16.59s12.09-4.58,16.7,0s4.61,12.01,0,16.59c-1.34,1.34-2.93,2.28-4.62,2.84"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="11.79" y1="21.6" x2="11.79" y2="30"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.79" y1="21.6" x2="18.79" y2="30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_hltx', html_3_2_hltx);
const html_3_2_hxtx = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M18.45,30v-8.71c0-0.25,0.1-0.49,0.28-0.67l8.42-8.23c0.39-0.38,0.38-1.01-0.03-1.37L15.65,0.75c-0.37-0.33-0.94-0.33-1.31,0L2.88,11.01c-0.41,0.37-0.42,0.99-0.03,1.37l8.42,8.23c0.18,0.18,0.28,0.42,0.28,0.67V30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_hxtx', html_3_2_hxtx);
const html_3_2_hz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='#780000' d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M15,29C7.3,29,1,22.7,1,15S7.3,1,15,1s14,6.3,14,14S22.7,29,15,29z"/>
<polygon fill='#780000'  points="13.6,15.5 9.1,15.5 9.1,12 8,12 8,20.5 9.1,20.5 9.1,16.5 13.6,16.5 13.6,20.5 14.7,20.5 14.7,12 13.6,12 	"/>
<path fill='#780000' d="M17.7,18.9l4.8-5.9v-1h-6.1v1h4.8c-0.3,0.3-0.6,0.6-0.9,1l-4.4,5.5v1.1h6.8v-1h-5.5L17.7,18.9z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_hz', html_3_2_hz);
const html_3_2_jcmc = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.34,21.67 15.24,6.76 15.24,23.24 29.51,6.76 29.51,23.24 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jcmc', html_3_2_jcmc);
const html_3_2_jd = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="19.6" rx="9.76" ry="9.9"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="21.18"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="9.81" y1="21.18" x2="20.19" y2="21.18"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="11.4" y1="23.01" x2="18.6" y2="23.01"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.72" y1="24.82" x2="17.28" y2="24.82"/>	"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jd', html_3_2_jd);
const html_3_2_jdbh = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="10.3" x2="30" y2="10.3"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="2.65" y1="13.5" x2="27.35" y2="13.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.18" y1="16.7" x2="23.82" y2="16.7"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.94" y1="19.91" x2="22.06" y2="19.91"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.83" y1="23.11" x2="21.17" y2="23.11"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.36" y1="26.31" x2="17.65" y2="26.31"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.01" y1="3.19" x2="15.01" y2="10.3"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jdbh', html_3_2_jdbh);
const html_3_2_jk = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="14.9" y1="0" x2="14.9" y2="22"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="14.9,29.7 21.6,22 14.9,22 8,22 1.5,29.7 \t"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="8.1" y1="29.7" x2="14.9" y2="22"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_2_jk', html_3_2_jk);
const html_3_2_jldy = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="14.89" cy="15" rx="4.94" ry="5.08"/>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M11.67,15c0-0.95,0.75-1.73,1.68-1.73s1.68,0.77,1.68,1.73"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M18.4,15c0,0.95-0.75,1.73-1.68,1.73s-1.68-0.77-1.68-1.73"/>
</g>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.89" y1="0" x2="14.89" y2="9.92"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.89" y1="20.08" x2="14.89" y2="30"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="20.06" y1="10.08" x2="20.06" y2="19.92"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jldy', html_3_2_jldy);
const html_3_2_jrq = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="10.51" y="9.66" fill='none' stroke='#780000' stroke-miterlimit='10' width="8.97" height="19.84"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.51" y1="13.85" x2="19.49" y2="13.85"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.51" y1="17.67" x2="19.49" y2="17.67"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.51" y1="21.5" x2="19.49" y2="21.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.51" y1="25.32" x2="19.49" y2="25.32"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="9.66"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jrq', html_3_2_jrq);
const html_3_2_jsq = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<polygon fill="#780000" stroke="#780000" points="13.17,13.99 11.22,15.77 13.17,17.55 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="12.85,15.77 21.03,15.77 21.03,12.87 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jsq', html_3_2_jsq);
const html_3_2_jt = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="0.5" y="9.66" fill='none' stroke='#780000' stroke-miterlimit='10' width="29" height="10.67"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="3.27" y1="4.76" x2="26.73" y2="4.76"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="3.27" y1="25.24" x2="26.73" y2="25.24"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jt', html_3_2_jt);
const html_3_2_jtdzq = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="0.5" y="8.5" fill='none' stroke='#780000' stroke-miterlimit='10' width="29" height="13"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jtdzq', html_3_2_jtdzq);
const html_3_2_jtmc = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="14.68" x2="6.12" y2="14.68"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="23.88" y1="14.68" x2="30" y2="14.68"/>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M6.06,15.27v-3.07c0-1.3,1.03-2.35,2.3-2.35h0c1.27,0,2.3,1.05,2.3,2.35v3.07"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15.26,14.73v3.07c0,1.3-1.03,2.35-2.3,2.35h0c-1.27,0-2.3-1.05-2.3-2.35v-3.07"/>
</g>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15.26,15.27v-3.07c0-1.3,1.03-2.35,2.3-2.35h0c1.27,0,2.3,1.05,2.3,2.35v3.07"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M24.45,14.73v3.07c0,1.3-1.03,2.35-2.3,2.35h0c-1.27,0-2.3-1.05-2.3-2.35v-3.07"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jtmc', html_3_2_jtmc);
const html_3_2_jxh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill="#780000" d="M0,0v30h30V0H0z M29,29H1V1h28V29z"/>
<path fill="#780000" d="M10.7,20.3c0.5,0,0.9-0.1,1.3-0.3s0.7-0.5,0.8-0.9s0.3-1,0.3-1.7v-5.9H12v5.9c0,0.5,0,0.9-0.1,1.1s-0.2,0.4-0.4,0.5c-0.2,0.1-0.5,0.2-0.7,0.2c-0.4,0-0.7-0.1-0.9-0.4s-0.3-0.7-0.4-1.3l-1,0.1c0,0.9,0.2,1.5,0.6,1.9C9.4,20.1,9.9,20.3,10.7,20.3z"/>
<path fill="#780000" d="M19.6,20.1c0.4-0.1,0.7-0.2,0.9-0.4s0.5-0.5,0.6-0.8s0.3-0.7,0.3-1.1c0-0.5-0.1-0.9-0.4-1.3s-0.7-0.6-1.2-0.8c0.4-0.2,0.7-0.5,0.9-0.8s0.3-0.7,0.3-1c0-0.4-0.1-0.8-0.3-1.1s-0.5-0.6-0.9-0.8s-0.9-0.3-1.6-0.3h-3.2v8.6h3.3C18.7,20.2,19.2,20.1,19.6,20.1z M16.1,12.6h1.7c0.6,0,1.1,0,1.3,0.1s0.4,0.2,0.6,0.4s0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.2,0.8S19.3,15,19,15.1c-0.2,0.1-0.6,0.1-1.1,0.1h-1.9V12.6z M16.1,16.2h2c0.5,0,1,0,1.3,0.1s0.5,0.3,0.7,0.5s0.2,0.5,0.2,0.8c0,0.3-0.1,0.5-0.2,0.7s-0.3,0.4-0.4,0.5s-0.4,0.2-0.7,0.2c-0.1,0-0.4,0-0.8,0h-2.1V16.2z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jxh', html_3_2_jxh);
const html_3_2_jyhs = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="23.31,0.5 15.21,0.5 15.21,29.5 6.69,29.5 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_jyhs', html_3_2_jyhs);
const html_3_2_kbdzq = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.44,13.12 3.45,7.31 8.46,18.93 13.48,7.31 17.49,19.89 22.5,8.28 27.52,19.89 29.52,13.12 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="25.22" y1="6.3" x2="1.05" y2="27.11"/>
<polygon fill="#780000" stroke="#780000"   points="29,3 21.09,6.3 24.64,10.13 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_kbdzq', html_3_2_kbdzq);
const html_3_2_kg = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="14.93" x2="12.55" y2="14.93"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.55" y1="9.38" x2="12.55" y2="20.62"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="15.07" x2="17.45" y2="15.07"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17.45" y1="20.62" x2="17.45" y2="9.38"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_kg', html_3_2_kg);
const html_3_2_kyq = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="29.5" y1="0.5" x2="29.5" y2="29.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.19" y1="8.26" x2="13.19" y2="21.74"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="16.61" y1="8.26" x2="16.61" y2="21.74"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.62" y1="15" x2="13.19" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.63" y1="15" x2="16.61" y2="15"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_2_kyq', html_3_2_kyq);
const html_3_2_kyq2 = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M3.8,29.5c8.26,0,14.96-6.49,14.96-14.5S12.06,0.5,3.8,0.5V29.5z"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="26.7" y1="15" x2="18.76" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_kyq2', html_3_2_kyq2);
const html_3_2_lxdy = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.5" y1="15" x2="29.5" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_lxdy', html_3_2_lxdy);
const html_3_2_mc = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="0,15 10.1,15 10.1,4.6 19.6,4.6 19.6,15 30,15 \t"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_mc', html_3_2_mc);
const html_3_2_nhd = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.16" cy="15" rx="11.61" ry="11.58"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="30" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.84" y1="10.97" x2="12.84" y2="19.78"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17.48" y1="10.97" x2="17.48" y2="19.78"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="9.92" cy="18.08" rx="1.47" ry="1.47"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_nhd', html_3_2_nhd);
const html_3_2_pdb = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="0.5" y="8.07" fill='none' stroke='#780000' stroke-miterlimit='10' width="29" height="13.86"/>
<polygon fill="#780000" stroke="#780000" points="29.5,8.07 0.5,21.93 29.5,21.93 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_pdb', html_3_2_pdb);
const html_3_2_ptzj = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="12.57,11.45 18.4,11.45 12.57,18.98 18.4,18.98 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ptzj', html_3_2_ptzj);
const html_3_2_qzzh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M23.22,9.85c0,2.27-1.84,4.12-4.11,4.12S15,12.13,15,9.85"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M6.78,9.85c0,2.27,1.84,4.12,4.11,4.12S15,12.13,15,9.85V0"/>
</g>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M6.78,20.15c0-2.27,1.84-4.12,4.11-4.12S15,17.87,15,20.15"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M23.22,20.15c0-2.27-1.84-4.12-4.11-4.12S15,17.87,15,20.15V30"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_qzzh', html_3_2_qzzh);
const html_3_2_rdd = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-linejoin='round' stroke-miterlimit='10' points="0.5,-0.08 0.5,13.25 5.33,27.35 10.17,13.25 15,27.35 19.83,13.25 24.67,27.35 29.5,13.25 29.5,-0.08 			"/>
<ellipse  fill="#780000" cx="10.17" cy="11.82" rx="2.58" ry="2.57"/>
<ellipse  fill="#780000" cx="19.32" cy="11.82" rx="2.58" ry="2.57"/>
<ellipse  fill="#780000" cx="5.63" cy="27.35" rx="2.58" ry="2.57"/>
<ellipse  fill="#780000" cx="15.06" cy="27.35" rx="2.58" ry="2.57"/>
<ellipse  fill="#780000" cx="24.48" cy="27.35" rx="2.58" ry="2.57"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_rdd', html_3_2_rdd);
const html_3_2_rdo = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="7.78,0 7.78,16.87 15.25,25.45 22.83,17.27 22.83,0 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="3.64" y1="3.48" x2="8.28" y2="3.48"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.83" y1="3.48" x2="27" y2="3.48"/>
<rect fill="#780000" x="13.13" y="24.65" width="4.34" height="5.35"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_rdo', html_3_2_rdo);
const html_3_2_ryj = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0,15h7.27c0-2.05,1.73-3.71,3.86-3.71S15,12.95,15,15s-1.73,3.71-3.86,3.71"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M30,15h-7.27c0,2.05-1.73,3.71-3.86,3.71S15,17.05,15,15s1.73-3.71,3.86-3.71"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ryj', html_3_2_ryj);
const html_3_2_sbq = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M5.57,15c0-2.72,2.21-4.93,4.93-4.93s4.93,2.21,4.93,4.93"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M25.31,15c0,2.72-2.21,4.93-4.93,4.93s-4.93-2.21-4.93-4.93"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_sbq', html_3_2_sbq);
const html_3_2_sjq = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.74,0 14.74,6.73 19.26,9.05 11.37,10.92 18.43,12.8 11.37,15.21 18.18,18.78 10.95,20.94 14.74,22.35 14.74,30 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.7" y1="15" x2="23.3" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_sjq', html_3_2_sjq);
const html_3_2_syt = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="0.68,15 6.55,8.48 29.5,8.48 29.5,21.52 6.65,21.52 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.14" y1="15" x2="18.86" y2="15"/>
<polygon fill="#780000" points="18.86,13.06 21.85,15 18.86,16.94 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_syt', html_3_2_syt);
const html_3_2_tiex = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect fill="#780000" x="12.21" width="5.17" height="30"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="20.55" y1="4.18" x2="9.45" y2="25.92"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_tiex', html_3_2_tiex);
const html_3_2_tx = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line stroke='#780000' stroke-miterlimit='10' x1="14.94" y1="0.28" x2="14.94" y2="30"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="9.93,0.28 14.94,7.9 20.08,0.28 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_tx', html_3_2_tx);
const html_3_2_tx2 = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="5.9,0.5 24.11,0.5 15.08,12.03 			"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15.08,0.5c0,0-0.35,28.8,0,29.15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_tx2', html_3_2_tx2);
const html_3_2_tzf = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="12.6" cy="8.91" rx="2.84" ry="2.83"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="12.6" cy="8.91" rx="8.45" ry="8.41"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="26.35" y1="8.91" x2="15.44" y2="8.91"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.6" y1="16.64" x2="12.6" y2="25.43"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.66" y1="25.75" x2="18.97" y2="25.75"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="9.39" y1="25.75" x2="7.21" y2="29.76"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.14" y1="25.75" x2="10.97" y2="29.76"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="16.9" y1="25.75" x2="14.72" y2="29.76"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_tzf', html_3_2_tzf);
const html_3_2_wdj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill="#780000"  d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M15,29C7.3,29,1,22.7,1,15S7.3,1,15,1s14,6.3,14,14S22.7,29,15,29z"/>
<polygon fill="#780000" points="11.7,13 14.6,13 14.6,20.5 15.7,20.5 15.7,13 18.5,13 18.5,12 11.7,12 	"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_2_wdj', html_3_2_wdj);
const html_3_2_wgj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill="#780000"  d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M15,29C7.3,29,1,22.7,1,15S7.3,1,15,1s14,6.3,14,14S22.7,29,15,29z"/>
<path fill="#780000" d="M8.3,17C8.2,17.3,8.1,17.7,8,18c-0.1-0.3-0.2-0.7-0.4-1.1l-1.7-4.8h-1l2.6,6.7h0.9l2.6-6.7h-0.9L8.3,17z"/>
<path fill="#780000" d="M16.4,12.4c-0.5-0.3-1-0.4-1.7-0.4c-0.9,0-1.7,0.3-2.3,0.9s-0.9,1.5-0.9,2.6c0,0.6,0.1,1.1,0.4,1.7c0.3,0.5,0.6,0.9,1.1,1.2c0.5,0.3,1.1,0.5,1.7,0.5c0.6,0,1.1-0.1,1.6-0.4c0.5-0.3,0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.8c0-0.7-0.1-1.3-0.4-1.8C17.3,13.1,16.9,12.7,16.4,12.4z M16.4,17.4c-0.4,0.5-1,0.7-1.6,0.7c-0.7,0-1.2-0.2-1.6-0.7c-0.4-0.5-0.6-1.1-0.6-1.9c0-1,0.2-1.7,0.7-2.1c0.5-0.4,1-0.6,1.6-0.6c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.6,0.5,0.8,0.9c0.2,0.4,0.3,0.9,0.3,1.4C17,16.3,16.8,17,16.4,17.4z"/>
<path fill="#780000"  d="M23.8,17c-0.2-0.4-0.5-0.7-0.7-0.9c-0.1-0.1-0.3-0.2-0.5-0.3c0.6-0.1,1.1-0.3,1.4-0.6c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.1-0.7-0.3-1c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.8-0.2-1.4-0.2h-3v6.7H20v-3h1c0.2,0,0.4,0,0.5,0c0.1,0,0.3,0.1,0.4,0.2s0.3,0.2,0.4,0.4c0.2,0.2,0.4,0.5,0.6,0.9l0.9,1.4H25L23.8,17z M21.9,15.1H20v-2.2h2.1c0.5,0,0.9,0.1,1.1,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4C22.6,15,22.3,15.1,21.9,15.1z"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_2_wgj', html_3_2_wgj);
const html_3_2_xdc = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line stroke='#780000' stroke-miterlimit='10' x1="9.39" y1="8.1" x2="9.39" y2="21.9"/>
<line stroke='#780000' stroke-miterlimit='10' x1="20.61" y1="0" x2="20.61" y2="30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_xdc', html_3_2_xdc);
const html_3_2_xhjd = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="1.46,13.29 28.54,13.29 15,24 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="5.36" x2="15" y2="13.29"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_xhjd', html_3_2_xhjd);
const html_3_2_ybb = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="0.5" y="8.07" fill='none' stroke='#780000' stroke-miterlimit='10' width="29" height="13.86"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.5" y1="8.07" x2="29.5" y2="21.93"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="29.5" y1="8.07" x2="0.5" y2="21.93"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ybb', html_3_2_ybb);
const html_3_2_yct = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="5.2,0.5 15,0.5 15,29.5 5.2,29.5 \t"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_yct', html_3_2_yct);
const html_3_2_ycyj = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="4.52" y="7.1" fill='none' stroke='#780000' stroke-miterlimit='10' width="20.96" height="15.81"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="10.64" x2="4.52" y2="10.64"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="19.17" x2="4.52" y2="19.17"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="25.48" y1="10.64" x2="30" y2="10.64"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="25.48" y1="19.17" x2="30" y2="19.17"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.92" y1="13.01" x2="6.92" y2="16.99"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="23.46" y1="13.01" x2="23.46" y2="16.99"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.92" y1="15" x2="23.46" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ycyj', html_3_2_ycyj);
const html_3_2_ygd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0.05,13.53c0.19,0,7.04,0,7.04,0l2.08,1.51L7.1,16.47H0.05"/>
<path d="M10.13,15c0,0.42-0.35,0.76-0.78,0.76s-0.78-0.3-0.78-0.72s0.35-0.8,0.78-0.8S10.13,14.58,10.13,15z"/>
</g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M29.95,16.47c-0.19,0-7.04,0-7.04,0L20.94,15l1.97-1.47h7.04"/>
<ellipse fill="#780000" stroke="#780000" cx="20.65" cy="15" rx="0.78" ry="0.76"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M20.9,18.15H8.92c-1.74,0-3.15-1.41-3.15-3.15v0c0-1.74,1.41-3.15,3.15-3.15H20.9c1.74,0,3.15,1.41,3.15,3.15v0C24.06,16.74,22.64,18.15,20.9,18.15z"/>
<ellipse fill="#780000" stroke="#780000" cx="14.91" cy="12.88" rx="0.95" ry="0.93"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ygd', html_3_2_ygd);
const html_3_2_yj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="3.1" y="0.5" fill='none' stroke='#780000' stroke-miterlimit='10' width="23.8" height="29"/>
<text transform="matrix(0.9937 0 0 1 11.0508 19.8369)" font-family='ArialMT' font-size='10.975px' >&amp;</text>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_yj', html_3_2_yj);
const html_3_2_ykz = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="0.5" y="0.5" fill='none' stroke='#780000' stroke-miterlimit='10' width="29" height="29"/>
<polygon fill="#780000" stroke="#780000" points="0.5,0.5 0.5,29.5 29.5,29.5 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ykz', html_3_2_ykz);
const html_3_2_ysdwj = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.61,15.06 3.59,9.32 8.54,20.8 13.5,9.32 17.46,21.76 22.41,10.27 27.37,21.76 29.35,15.06 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="25.69" y1="4.34" x2="1.55" y2="29.65"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.28" y1="0.36" x2="29.35" y2="7.21"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.37" y1="1.24" x2="15.37" y2="9.32"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.62,5.69 15.37,9.32 17.35,5.69 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ysdwj', html_3_2_ysdwj);
const html_3_2_ysdzq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.57,15.06 3.55,9.3 8.52,20.82 13.49,9.3 17.47,21.78 22.44,10.26 27.41,21.78 29.39,15.06 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="25.73" y1="4.31" x2="1.51" y2="29.7"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.3" y1="0.32" x2="29.39" y2="7.18"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ysdzq', html_3_2_ysdzq);
const html_3_2_ysq = `<svg version="1.1" id="图层_1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="1.46" y1="11.91" x2="10.78" y2="11.91"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="1.46" y1="18.43" x2="10.78" y2="18.43"/>
<rect x="10.2" y="8.41" fill='none' stroke='#780000' stroke-miterlimit='10' width="10.3" height="14.72"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="28.04,1.19 20.5,8.41 20.5,23.13 28.04,28.97 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_ysq', html_3_2_ysq);
const html_3_2_zhkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="7.77" y="0.5" fill='none' stroke='#780000' stroke-miterlimit='10' width="14.46" height="29"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.12" cy="7.64" rx="3.76" ry="3.82"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.12" cy="15.29" rx="3.76" ry="3.82"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.12" cy="22.94" rx="3.76" ry="3.82"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_zhkg', html_3_2_zhkg);
const html_3_2_zjxh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="1.15,15 15.28,8.25 28.87,15 15.01,21.75 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_zjxh', html_3_2_zjxh);
const html_3_2_zldy = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.36" cy="14.98" rx="5.02" ry="5.08"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.36" y1="0" x2="15.36" y2="9.92"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.36" y1="20.08" x2="15.36" y2="30"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.36" y1="11.08" x2="15.36" y2="13.73"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="16.67" y1="12.4" x2="14.05" y2="12.4"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.37" y1="17.83" x2="16.35" y2="17.83"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="9.08" y1="11.12" x2="9.08" y2="18.84"/>
<path d="M8.72,16.19L8.72,16.19c-0.38,0-0.68-0.35-0.68-0.78v-0.85c0-0.43,0.3-0.78,0.68-0.78h0"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M9.71,15.89L9.71,15.89c-0.35,0-0.63-0.28-0.63-0.63V14.7c0-0.35,0.28-0.63,0.63-0.63h0c0.35,0,0.63,0.28,0.63,0.63v0.56C10.33,15.61,10.05,15.89,9.71,15.89z"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_zldy', html_3_2_zldy);
const html_3_2_zmdp = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" rx="11.45" ry="11.6"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M30,15h-5.75h-0.38c0-4.96-3.97-8.98-8.87-8.98S6.13,10.04,6.13,15H5.39H0"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_zmdp', html_3_2_zmdp);
const html_3_2_zsq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<g>
<path fill="#780000" d="M8.23,17.33l1,0.32c-0.15,0.56-0.41,0.97-0.77,1.24c-0.36,0.27-0.81,0.41-1.36,0.41c-0.68,0-1.24-0.23-1.67-0.7c-0.44-0.46-0.66-1.1-0.66-1.9c0-0.85,0.22-1.51,0.66-1.98c0.44-0.47,1.02-0.71,1.73-0.71c0.63,0,1.13,0.18,1.52,0.56c0.23,0.22,0.41,0.53,0.52,0.94l-1.02,0.24c-0.06-0.26-0.19-0.47-0.38-0.63c-0.19-0.15-0.43-0.23-0.7-0.23c-0.38,0-0.69,0.14-0.92,0.41c-0.24,0.27-0.35,0.71-0.35,1.32c0,0.65,0.12,1.11,0.35,1.38c0.23,0.27,0.53,0.41,0.91,0.41c0.27,0,0.51-0.09,0.71-0.26C8,17.97,8.14,17.7,8.23,17.33z"/>
<path fill="#780000" d="M9.9,16.68c0-0.52,0.08-0.96,0.23-1.31c0.12-0.26,0.28-0.49,0.48-0.7c0.2-0.21,0.42-0.36,0.66-0.46c0.32-0.13,0.69-0.2,1.1-0.2c0.75,0,1.36,0.23,1.81,0.7c0.45,0.47,0.68,1.12,0.68,1.95c0,0.83-0.22,1.47-0.67,1.94c-0.45,0.47-1.05,0.7-1.8,0.7c-0.76,0-1.36-0.23-1.81-0.7C10.13,18.13,9.9,17.49,9.9,16.68z M10.97,16.65c0,0.58,0.13,1.02,0.4,1.32c0.27,0.3,0.61,0.45,1.02,0.45c0.41,0,0.75-0.15,1.01-0.45c0.26-0.3,0.4-0.74,0.4-1.33c0-0.59-0.13-1.02-0.38-1.31c-0.26-0.29-0.6-0.43-1.02-0.43c-0.43,0-0.77,0.15-1.03,0.44C11.1,15.62,10.97,16.06,10.97,16.65z"/>
<path fill="#780000"  d="M15.41,17.54l1-0.1c0.06,0.34,0.18,0.58,0.37,0.74c0.18,0.16,0.43,0.24,0.75,0.24c0.33,0,0.58-0.07,0.75-0.21c0.17-0.14,0.25-0.3,0.25-0.49c0-0.12-0.04-0.22-0.11-0.31c-0.07-0.08-0.2-0.16-0.37-0.22c-0.12-0.04-0.4-0.12-0.83-0.22c-0.55-0.14-0.94-0.31-1.16-0.51c-0.31-0.28-0.47-0.62-0.47-1.03c0-0.26,0.07-0.5,0.22-0.73c0.15-0.23,0.36-0.4,0.64-0.52c0.28-0.12,0.61-0.18,1.01-0.18c0.64,0,1.12,0.14,1.45,0.42c0.32,0.28,0.49,0.66,0.51,1.13l-1.03,0.05c-0.04-0.26-0.14-0.45-0.28-0.57c-0.15-0.12-0.36-0.17-0.65-0.17c-0.3,0-0.53,0.06-0.7,0.18c-0.11,0.08-0.16,0.19-0.16,0.32c0,0.12,0.05,0.22,0.15,0.31c0.13,0.11,0.45,0.22,0.95,0.34c0.5,0.12,0.87,0.24,1.11,0.37c0.24,0.13,0.43,0.3,0.56,0.52c0.14,0.22,0.2,0.49,0.2,0.81c0,0.29-0.08,0.57-0.24,0.82c-0.16,0.25-0.39,0.45-0.69,0.57c-0.3,0.13-0.67,0.19-1.11,0.19c-0.65,0-1.14-0.15-1.49-0.45C15.68,18.55,15.47,18.11,15.41,17.54z"/>
</g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M22.33,19c-1.3,0-2.35-1.05-2.35-2.35c0-1.3,1.05-2.35,2.35-2.35"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M22.84,14.3c1.3,0,2.35,1.05,2.35,2.35c0,1.3-1.05,2.35-2.35,2.35"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.53" y1="13.81" x2="22.53" y2="21.2"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_2_zsq', html_3_2_zsq);


const html_3_3_2dtdl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.78" y1="5.1" x2="10.61" y2="5.1"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.78" y1="25.19" x2="10.61" y2="25.19"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.39" y1="5.1" x2="29.22" y2="5.1"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.39" y1="25.19" x2="29.22" y2="25.19"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15.15,29.5L15.15,29.5c-2.51,0-4.54-2.03-4.54-4.54V5.04c0-2.51,2.03-4.54,4.54-4.54h0c2.51,0,4.54,2.03,4.54,4.54v19.93C19.68,27.47,17.65,29.5,15.15,29.5z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_2dtdl', html_3_3_2dtdl);
const html_3_3_bq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="9.71" y1="8.23" x2="20.29" y2="8.23"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="8.23" x2="15" y2="22.59"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_bq', html_3_3_bq);
const html_3_3_csd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='#780000' cx="15" cy="3.29" r="2.01"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_csd', html_3_3_csd);
const html_3_3_cslj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="14.41" x2="30" y2="14.41"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_3_cslj', html_3_3_cslj);
const html_3_3_cslj2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15.06" x2="30" y2="15.06"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.6" y1="13.13" x2="8.89" y2="16.87"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.85" y1="13.13" x2="18.14" y2="16.87"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_cslj2', html_3_3_cslj2);
const html_3_3_cslj3 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15.06" x2="30" y2="15.06"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.6" y1="13.13" x2="8.89" y2="16.87"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.22" y1="13.13" x2="13.52" y2="16.87"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.85" y1="13.13" x2="18.14" y2="16.87"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_3_cslj3', html_3_3_cslj3);
const html_3_3_cslj4 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="30" y2="15"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="9.2,10 17.4,10 13.6,13.1 21.4,13.1 \t"/>
<polygon fill="none" stroke="#780000" stroke-miterlimit="10" points="20,12.1 23.5,13.3 20,14.1 \t"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_cslj4', html_3_3_cslj4);
const html_3_3_dbxl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="30" y2="15"/>
<path fill="#780000" d="M16.8,9.7c-0.1-0.2-0.1-0.5-0.2-0.6C16.5,9,16.4,8.9,16,8.9h-0.7v3.4c0,0.5,0.1,0.6,0.7,0.7v0.2h-2V13 c0.6,0,0.7-0.1,0.7-0.7V8.9h-0.6c-0.4,0-0.5,0-0.6,0.2c-0.1,0.1-0.1,0.3-0.2,0.6H13c0-0.4,0.1-0.9,0.1-1.3h0.2 c0.1,0.2,0.2,0.2,0.4,0.2h2.8c0.2,0,0.2,0,0.4-0.2H17c0,0.3,0,0.8,0.1,1.2L16.8,9.7z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dbxl', html_3_3_dbxl);
const html_3_3_dhxl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="30" y2="15"/>
<path fill="#780000" d="M16.5,9.7c-0.1-0.2-0.1-0.4-0.2-0.5C16.1,9,16,8.9,15.6,8.9h-0.8c-0.2,0-0.2,0-0.2,0.2v1.6h0.9c0.5,0,0.5-0.1,0.7-0.6 h0.2v1.5h-0.2C16.1,11.2,16,11,15.5,11h-0.9v1.3c0,0.6,0.1,0.6,0.7,0.7v0.2h-1.9V13c0.6,0,0.6-0.1,0.6-0.7V9.5 c0-0.6-0.1-0.6-0.6-0.7V8.6h2.2c0.6,0,0.9,0,1,0c0,0.2,0,0.7,0.1,1.1L16.5,9.7z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dhxl', html_3_3_dhxl);
const html_3_3_dlz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="5.1" x2="10.61" y2="5.1"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="25.19" x2="10.61" y2="25.19"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.39" y1="5.1" x2="30" y2="5.1"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="10.12" x2="10.61" y2="10.12"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.39" y1="10.12" x2="30" y2="10.12"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15.14" x2="10.61" y2="15.14"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.39" y1="15.14" x2="30" y2="15.14"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="20.17" x2="10.61" y2="20.17"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.39" y1="20.17" x2="30" y2="20.17"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.39" y1="25.19" x2="30" y2="25.19"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15.15,29.5L15.15,29.5c-2.51,0-4.54-2.03-4.54-4.54V5.04c0-2.51,2.03-4.54,4.54-4.54h0c2.51,0,4.54,2.03,4.54,4.54v19.93C19.68,27.47,17.65,29.5,15.15,29.5z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dlz', html_3_3_dlz);
const html_3_3_dlzd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15.03" x2="11.83" y2="15.03"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17.48" y1="15.03" x2="30" y2="15.03"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="11.83,11.56 11.83,18.45 17.48,15.03 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dlzd', html_3_3_dlzd);
const html_3_3_dx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0,0.56 29.5,0.56 29.5,29.94 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dx', html_3_3_dx);
const html_3_3_dx2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="30" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dx2', html_3_3_dx2);
const html_3_3_dxdl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="11.4" y1="8.5" x2="18.9" y2="8.5"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="12.2" y1="10.7" x2="18" y2="10.7"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="13.3" y1="12.8" x2="17" y2="12.8"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0.1" y1="15" x2="30.1" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dxdl', html_3_3_dxdl);
const html_3_3_dxdlan = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="11.71" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.75" y1="15" x2="30" y2="15"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.14" cy="15" rx="3.61" ry="3.46"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dxdlan', html_3_3_dxdlan);
const html_3_3_dxzx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="13.19" x2="30" y2="13.19"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="16.81" x2="30" y2="16.81"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dxzx', html_3_3_dxzx);
const html_3_3_dxzxz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="24.68,0 24.68,24.43 0,24.43 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="30,0 30,29.5 0,29.5 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dxzxz', html_3_3_dxzxz);
const html_3_3_dz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" rx="5.5" ry="5.52"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_dz', html_3_3_dz);
const html_3_3_dzb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill='#780000' d="M0,0v29.9h30V0H0z M29,28.9H1V1h28V28.9z"/>
<polygon fill='#780000' points="9.1,11.7 10.1,11.7 10.1,5.3 12.4,5.3 12.4,4.5 6.8,4.5 6.8,5.3 9.1,5.3 	"/>
<path fill='#780000' d="M12.6,11.1c0.4,0.5,1,0.7,1.8,0.7c0.6,0,1.1-0.1,1.5-0.4c0.4-0.3,0.7-0.7,0.8-1.2L15.7,10c-0.1,0.4-0.3,0.6-0.5,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.3h3.9c0-0.1,0-0.2,0-0.2c0-0.9-0.2-1.5-0.7-2c-0.4-0.5-1-0.7-1.7-0.7c-0.7,0-1.3,0.2-1.7,0.7c-0.4,0.5-0.7,1.2-0.7,2C11.9,10,12.1,10.6,12.6,11.1z M13.3,7.5c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.8,0.2,1.1,0.5c0.2,0.2,0.3,0.5,0.3,1h-2.9C12.9,8.1,13,7.8,13.3,7.5z"/>
<path fill='#780000' d="M18.2,11.7l1.4-2.1l0.3,0.4l1.1,1.6H22l-1.9-2.8l1.8-2.5h-1L20,7.7c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1-0.2-0.2-0.4-0.4-0.6l-0.8-1.2h-1.1L19.1,9l-1.9,2.7H18.2z"/>
<path fill='#780000' d="M22.9,10.2c0,0.5,0,0.9,0.1,1c0.1,0.2,0.2,0.3,0.4,0.4s0.4,0.2,0.7,0.2c0.2,0,0.4,0,0.7-0.1l-0.1-0.8c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.2c0-0.1,0-0.2,0-0.5V7.2h0.9V6.5h-0.9V4.7l-0.9,0.5v1.3h-0.6v0.7h0.6V10.2z"/>
<polygon fill='#780000' points="9.1,26.7 10.1,26.7 10.1,20.4 12.4,20.4 12.4,19.5 6.8,19.5 6.8,20.4 9.1,20.4 	"/>
<path fill='#780000' d="M14.3,21.4c-0.7,0-1.3,0.2-1.7,0.7c-0.4,0.5-0.7,1.2-0.7,2c0,0.8,0.2,1.5,0.7,2c0.4,0.5,1,0.7,1.8,0.7c0.6,0,1.1-0.1,1.5-0.4c0.4-0.3,0.7-0.7,0.8-1.2L15.7,25c-0.1,0.4-0.3,0.6-0.5,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.3h3.9c0-0.1,0-0.2,0-0.2c0-0.9-0.2-1.5-0.7-2C15.6,21.6,15,21.4,14.3,21.4zM12.8,23.6c0-0.5,0.2-0.8,0.5-1.1c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.8,0.2,1.1,0.5c0.2,0.2,0.3,0.5,0.3,1H12.8z"/>
<path fill='#780000' d="M19.6,24.7l0.3,0.4l1.1,1.6H22L20.1,24l1.8-2.5h-1L20,22.7c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1-0.2-0.2-0.4-0.4-0.6l-0.8-1.2h-1.1l1.8,2.5l-1.9,2.7h1.1L19.6,24.7z"/>
<path fill='#780000' d="M22.9,25.2c0,0.5,0,0.9,0.1,1c0.1,0.2,0.2,0.3,0.4,0.4s0.4,0.2,0.7,0.2c0.2,0,0.4,0,0.7-0.1l-0.1-0.8c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.2s0-0.2,0-0.5v-3.1h0.9v-0.7h-0.9v-1.8l-0.9,0.5v1.3h-0.6v0.7h0.6V25.2z"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_3_dzb', html_3_3_dzb);
const html_3_3_dzlb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<path fill='#780000' d="M0,7.4v15.2h30V7.4H0z M29,21.6H1V8.4h28V21.6z"/>
<polygon fill='#780000' points="8.6,18.4 9.6,18.4 9.6,12.3 11.8,12.3 11.8,11.5 6.4,11.5 6.4,12.3 8.6,12.3 	"/>
<path fill='#780000' d="M11.9,17.8c0.4,0.4,1,0.7,1.7,0.7c0.6,0,1-0.1,1.4-0.4c0.4-0.3,0.6-0.7,0.8-1.2L15,16.8c-0.1,0.4-0.3,0.6-0.5,0.8c-0.2,0.2-0.5,0.2-0.8,0.2c-0.4,0-0.8-0.1-1-0.4c-0.3-0.3-0.4-0.7-0.5-1.2h3.7c0-0.1,0-0.2,0-0.2c0-0.8-0.2-1.4-0.6-1.9c-0.4-0.4-1-0.7-1.6-0.7c-0.7,0-1.2,0.2-1.7,0.7s-0.6,1.1-0.6,1.9C11.3,16.7,11.5,17.4,11.9,17.8z M12.6,14.4c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.8,0.2,1,0.5c0.2,0.2,0.3,0.5,0.3,0.9h-2.8C12.2,15,12.4,14.6,12.6,14.4z"/>
<path fill='#780000' d="M17.3,18.4l1.3-2l0.3,0.4l1,1.5h1l-1.8-2.6l1.7-2.3h-1L19,14.6c-0.1,0.2-0.2,0.3-0.4,0.5c-0.1-0.1-0.2-0.3-0.3-0.6l-0.8-1.2h-1l1.7,2.4l-1.8,2.6H17.3z"/>
<path fill='#780000' d="M21.8,16.9c0,0.5,0,0.8,0.1,1c0.1,0.2,0.2,0.3,0.4,0.4s0.4,0.1,0.7,0.1c0.2,0,0.4,0,0.6-0.1l-0.1-0.7c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.5v-2.9h0.8v-0.7h-0.8v-1.7l-0.8,0.5v1.2h-0.6v0.7h0.6V16.9z"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_3_dzlb', html_3_3_dzlb);
const html_3_3_fldsb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="29.03" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.06" y1="11.06" x2="6.06" y2="18.94"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M25.14,6.88c2.63,1.77,4.36,4.77,4.36,8.18c0,3.33-1.66,6.28-4.2,8.07"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_fldsb', html_3_3_fldsb);
const html_3_3_fzdl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="30" y2="15"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M10.9,15.7v-0.5c0-1.1,0.9-1.9,2-1.9l0,0c1.1,0,2,0.9,2,1.9v0.5"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M15,15.7v-0.5c0-1.1,0.9-1.9,2-1.9l0,0c1.1,0,2,0.9,2,1.9v0.5"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_fzdl', html_3_3_fzdl);
const html_3_3_gjwk = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="24.47,4.09 24.47,28.89 4.92,28.89 4.92,4.09 			"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0.35,7.06c3.68-3.67,8.76-5.94,14.38-5.94c5.88,0,11.19,2.49,14.9,6.48"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_gjwk', html_3_3_gjwk);
const html_3_3_gq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="10.37" y2="15"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="4.71"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.71" y1="15" x2="30" y2="15"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.3" y1="13.43" x2="11.19" y2="17.55"/>
<polygon fill='#780000' points="14.4,12.53 16.2,12.55 16.17,14.3 				"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17.2" y1="15.33" x2="13.18" y2="19.35"/>
<polygon fill='#780000' points="16.3,14.43 18.1,14.45 18.07,16.2 				"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_gq', html_3_3_gq);
const html_3_3_hddl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="30" y2="15"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M11.3,13.2l0.5,0.6c0.1,0.1,0.3,0.2,0.5,0.2H14c0.2,0,0.4-0.1,0.5-0.2l0,0c0.3-0.3,0.8-0.3,1.1,0l0,0 c0.1,0.1,0.3,0.2,0.5,0.2h1.4c0.2,0,0.4-0.1,0.5-0.2l0.6-0.6"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_hddl', html_3_3_hddl);
const html_3_3_jhd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='#780000' cx="15" cy="15" r="5"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_jhd', html_3_3_jhd);
const html_3_3_jhdjcd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="14.9" x2="30" y2="14.9"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="19.1" y1="0" x2="19.1" y2="14.9"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="10.9" y1="25.6" x2="10.9" y2="14.5"/>
<circle fill="#780000" cx="10.9" cy="14.6" r="2"/>
<circle fill="#780000" cx="19.1" cy="14.6" r="2"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_jhdjcd', html_3_3_jhdjcd);
const html_3_3_jxq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="3.55" x2="15" y2="26.78"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="6.17" x2="21.69" y2="6.17"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="23.27" x2="21.69" y2="23.27"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.54" y1="15" x2="15" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_jxq', html_3_3_jxq);
const html_3_3_lfxy = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon points="22.16,19.45 30,15.09 22.16,10.55 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="23.03" y1="15.09" x2="0" y2="15.09"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_lfxy', html_3_3_lfxy);
const html_3_3_lfxz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon points="7.84,10.55 0,14.91 7.84,19.45 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.97" y1="14.91" x2="30" y2="14.91"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_lfxz', html_3_3_lfxz);
const html_3_3_sixxl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.42" y1="6.19" x2="0.46" y2="23.81"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.79" y1="6.19" x2="7.84" y2="23.81"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.16" y1="6.19" x2="15.21" y2="23.81"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="29.54" y1="6.19" x2="22.58" y2="23.81"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_sixxl', html_3_3_sixxl);
const html_3_3_spxl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="30" y2="15"/>
<path fill="#780000" d="M17.4,8.8c-0.5,0.1-0.6,0.1-0.8,0.8c-0.2,0.5-1.2,2.8-1.5,3.7h-0.2c-0.4-1.1-1-2.8-1.4-3.8c-0.2-0.5-0.3-0.6-0.7-0.6V8.6 h1.8v0.2C13.9,8.9,13.9,9,14,9.2c0.2,0.5,0.7,1.9,1.1,3.1c0.4-0.9,0.8-2.1,1.2-2.9c0.1-0.4,0.1-0.5-0.5-0.5V8.6h1.6L17.4,8.8 L17.4,8.8z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_spxl', html_3_3_spxl);
const html_3_3_sxdjsb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="4.19" cy="15" rx="3.69" ry="3.54"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" rx="3.69" ry="3.54"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="25.81" cy="15" rx="3.69" ry="3.54"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_sxdjsb', html_3_3_sxdjsb);
const html_3_3_sxxl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.9" y1="0.18" x2="1.69" y2="29.82"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="28.31" y1="0.18" x2="16.1" y2="29.82"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_sxxl', html_3_3_sxxl);
const html_3_3_xnlx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<polygon fill='#780000' points="23.96,9.41 13.78,15 23.96,20.59 				"/>
<polygon fill='#780000' points="6.04,20.59 16.22,15 6.04,9.41 				"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="15" x2="0" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_xnlx', html_3_3_xnlx);
const html_3_3_xwlx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='#780000' points="22.38,19.32 30,15.09 22.38,10.68 			"/>
<polygon fill='#780000' points="7.62,10.68 0,14.91 7.62,19.32 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.38" y1="15" x2="1.82" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_xwlx', html_3_3_xwlx);
const html_3_3_yxz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="7.82" cy="14.83" rx="7.32" ry="7.17"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.97,10.96 19.38,7.66 30,7.66 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.13,13.3 19.62,11.2 30,11.2 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.97,19.04 19.38,22.34 30,22.34 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.13,16.68 19.62,18.8 30,18.8 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.13" y1="14.83" x2="30" y2="14.83"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_yxz', html_3_3_yxz);
const html_3_3_zbzx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0,2.03 25.97,2.03 25.97,21.28 28.78,21.28 22.13,27.73 16.73,21.28 19.84,21.28 19.84,7.15 0,7.15 					"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_zbzx', html_3_3_zbzx);
const html_3_3_zbzx2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="2.03,-0.01 2.03,25.97 21.28,25.97 21.28,28.77 27.73,22.12 21.28,16.72 21.28,19.84 7.15,19.84 			7.15,-0.01 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_zbzx2', html_3_3_zbzx2);
const html_3_3_zxkd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.21,0.26 15.21,20.34 10.04,20.34 19.42,29.06 28.72,20.52 22.7,20.52 22.7,0.26 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="28.72" y1="2.99" x2="10.04" y2="18.93"/>
<text fill='#780000' transform="matrix(1.0055 0 0 1 0 17.4629)" font-family='ArialMT' font-size='6.4421px'>30</text>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_zxkd', html_3_3_zxkd);
const html_3_3_zzx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="12.12,0 12.12,23.05 8.16,23.05 14.48,29.33 21.72,22.94 17.57,22.94 17.57,0 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_3_zzx', html_3_3_zzx);


const html_3_4_edjlkgyj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="11.19" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="11.19" y1="7.8" x2="11.19" y2="22.2"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="15" x2="18.81" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.81" y1="22.2" x2="18.81" y2="7.8"/>
</g>
<polygon fill='#780000'  points="11.19,11.82 18.81,7.8 18.81,14.96 			"/>
<polygon fill='#780000'  points="18.81,18.1 11.19,22.12 11.19,14.96 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_edjlkgyj', html_3_4_edjlkgyj);
const html_3_4_ejg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="15" x2="0" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.88" y1="10.93" x2="18.88" y2="19.07"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="11.08,10.43 11.08,19.57 18.88,14.98 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_ejg', html_3_4_ejg);
const html_3_4_ejg2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="15" x2="0" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.88" y1="10.95" x2="18.88" y2="19.05"/>
<polygon fill='#780000' stroke='#780000' points="11.08,10.46 11.08,19.54 18.88,14.98 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_ejg2', html_3_4_ejg2);
const html_3_4_gualscxyjtgn = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.64" cy="14.08" rx="12.5" ry="12.47"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.22,11.06 20.17,11.06 20.17,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.22,17.9 20.17,17.9 20.17,30 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.36,10.38 11.44,10.38 11.44,6.62 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.36,19.26 11.44,19.26 11.44,22.74 			"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="16.23,12.75 13.77,14.31 16.23,15.88 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="16.4,14.31 19.34,14.31 20.17,14.31 20.17,17.9 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.22" y1="6.62" x2="13.22" y2="22.74"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_gualscxyjtgn', html_3_4_gualscxyjtgn);
const html_3_4_gualscxyjtgp = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.64" cy="14.08" rx="12.5" ry="12.47"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.22,11.06 20.17,11.06 20.17,0 		"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.22,17.9 20.17,17.9 20.17,30 		"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.36,10.38 11.44,10.38 11.44,6.62 		"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.36,19.26 11.44,19.26 11.44,22.74 		"/>
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="17.28,15.88 19.74,14.31 17.28,12.75 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17.55" y1="14.31" x2="13.63" y2="14.31"/>
</g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="19.34,14.31 20.17,14.31 20.17,17.9 		"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.22" y1="6.62" x2="13.22" y2="22.74"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_gualscxyjtgp', html_3_4_gualscxyjtgp);
const html_3_4_jfetnpn = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.69" cy="14.72" rx="12.53" ry="12.49"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.56" y1="6.47" x2="12.56" y2="23.53"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="12.56,10.48 19.53,10.48 19.53,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="9.16,18.29 20.2,18.29 20.2,30 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="1.28" y1="18.29" x2="6.35" y2="18.29"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="9.44,18.29 6.35,16.04 6.35,20.33 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jfetnpn', html_3_4_jfetnpn);
const html_3_4_jfetpnp = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.67" cy="15" rx="12.53" ry="12.49"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.59" y1="6.47" x2="12.59" y2="23.53"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="12.59,10.48 19.56,10.48 19.56,0 				"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="9.92,18.29 20.23,18.29 20.23,30 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="1.31" y1="18.29" x2="7.83" y2="18.29"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="6.81,18.39 9.9,20.41 9.9,16.12 				"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jfetpnp', html_3_4_jfetpnp);
const html_3_4_jsyhwbdtcxyjtg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0,20.71 14.32,20.71 14.32,9.55 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="16.48" y1="7.92" x2="16.48" y2="22.38"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="16.48,9.55 23.2,9.55 23.2,0.5 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="16.48,20.71 23.2,20.71 23.2,29.5 			"/>
<polygon fill='#780000' points="18.97,15.13 23.95,17.16 23.95,13.15 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17" y1="15.13" x2="30" y2="15.13"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jsyhwbdtcxyjtg', html_3_4_jsyhwbdtcxyjtg);
const html_3_4_jtgnpn = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.2" cy="14.72" rx="12.52" ry="12.49"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.77" y1="14.72" x2="13.07" y2="14.72"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.07" y1="6.47" x2="13.07" y2="23.53"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.07,10.48 25,5.83 25,0 				"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.07,19.52 25.08,23.53 25.08,30 				"/>
</g>
<polygon fill='#780000' points="22.15,24.76 25.08,23.66 23.09,21.13 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jtgnpn', html_3_4_jtgnpn);
const html_3_4_jtgnpn2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="2.59" y1="14.72" x2="14.9" y2="14.72"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.9" y1="6.47" x2="14.9" y2="23.53"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.9,10.48 26.83,5.83 26.83,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.9,19.52 26.91,23.53 26.91,30 			"/>
<polygon fill='#780000' points="25.06,20.9 23.77,24.57 26.91,23.53 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jtgnpn2', html_3_4_jtgnpn2);
const html_3_4_jtgnpncx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="10" cy="18.05" rx="8.12" ry="8.11"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="18.05" x2="7.97" y2="18.05"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.97" y1="12.69" x2="7.97" y2="23.77"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="7.97,15.3 15.7,12.28 15.7,8.49 				"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="7.97,21.16 15.76,23.77 15.76,27.97 				"/>
</g>
<polygon fill='#780000' points="13.52,24.25 15.46,23.65 14.27,21.94 			"/>
<g>
<polygon fill='#780000' points="19.07,5.34 17.84,11.19 23.02,9.23 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="26.17" y1="2.38" x2="20.43" y2="8.27"/>
</g>
<g>
<polygon fill='#780000' points="22.55,10.45 21.31,16.3 26.49,14.34 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="29.64" y1="7.49" x2="23.9" y2="13.37"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jtgnpncx', html_3_4_jtgnpncx);
const html_3_4_jtgpnp = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.2" cy="14.72" rx="12.52" ry="12.49"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.77" y1="14.72" x2="13.07" y2="14.72"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.07" y1="6.47" x2="13.07" y2="23.53"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.07,10.48 25,5.83 25,0 				"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.86,19.87 25.08,23.53 25.08,30 				"/>
</g>
<polygon fill='#780000' points="15.63,17.99 13.07,19.79 15.63,21.74 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jtgpnp', html_3_4_jtgpnp);
const html_3_4_jtgpnp2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="2.59" y1="14.72" x2="14.9" y2="14.72"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.9" y1="6.47" x2="14.9" y2="23.53"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.9,10.48 26.83,5.83 26.83,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.9,19.52 26.91,23.53 26.91,30 			"/>
<polygon fill='#780000' points="17.46,18.54 14.9,20.33 17.46,22.28 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jtgpnp2', html_3_4_jtgpnp2);
const html_3_4_jtgpnpcx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="10" cy="18.05" rx="8.12" ry="8.11"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="18.05" x2="7.97" y2="18.05"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.97" y1="12.69" x2="7.97" y2="23.77"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="7.97,15.3 15.7,12.28 15.7,8.49 				"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="7.97,21.16 15.76,23.77 15.76,27.97 				"/>
</g>
<polygon fill='#780000' points="9.63,20.53 7.97,21.69 9.63,22.96 			"/>
<g>
<polygon fill='#780000' points="19.07,5.34 17.84,11.19 23.02,9.23 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="26.17" y1="2.38" x2="20.43" y2="8.27"/>
</g>
<g>
<polygon fill='#780000' points="22.55,10.45 21.31,16.3 26.49,14.34 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="29.64" y1="7.49" x2="23.9" y2="13.37"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_jtgpnpcx', html_3_4_jtgpnpcx);
const html_3_4_mosfentpxjj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.64" cy="14.08" rx="12.5" ry="12.47"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.96" y1="20.61" x2="12.96" y2="7.54"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.92" y1="20.61" x2="14.92" y2="7.54"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.74,11.06 20.17,11.06 20.17,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.74,17.9 20.17,17.9 20.17,30 			"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.36" y1="14.03" x2="12.75" y2="14.03"/>
<polygon fill='#780000' points="12.75,12.1 9.72,14.03 12.75,15.96 				"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_mosfentpxjj', html_3_4_mosfentpxjj);
const html_3_4_mosfet = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.08" cy="14.08" rx="12.5" ry="12.47"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.92,21.39 13.35,21.39 13.35,8.32 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.35,11.06 19.61,11.06 19.61,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.35,17.9 19.61,17.9 19.61,30 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.59" y1="14.57" x2="16.08" y2="14.57"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="16.08,12.67 16.08,15.99 19.41,14.08 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.61" y1="17.9" x2="19.61" y2="14.08"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_mosfet', html_3_4_mosfet);
const html_3_4_mosfetgytdn = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.08" cy="14.08" rx="12.5" ry="12.47"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.92,21.39 13.35,21.39 13.35,8.32 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.42,11.06 21.67,11.06 21.67,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.42,17.9 21.67,17.9 21.67,30 			"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="18.49,16.2 18.49,12.88 15.99,14.54 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="19.07,14.54 21.67,14.54 21.67,17.9 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.42" y1="8.32" x2="15.42" y2="12.69"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.42" y1="13.39" x2="15.42" y2="16.31"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.42" y1="17.02" x2="15.42" y2="21.39"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_mosfetgytdn', html_3_4_mosfetgytdn);
const html_3_4_mosfetgytdp = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.08" cy="14.08" rx="12.5" ry="12.47"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.92,21.39 13.35,21.39 13.35,8.32 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.42,11.06 21.67,11.06 21.67,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.42,17.9 21.67,17.9 21.67,30 			"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="16.56,12.88 16.56,16.2 19.07,14.54 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="19.07,14.54 21.67,14.54 21.67,17.9 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.42" y1="8.32" x2="15.42" y2="12.69"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.42" y1="13.39" x2="15.42" y2="16.31"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.42" y1="17.02" x2="15.42" y2="21.39"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_mosfetgytdp', html_3_4_mosfetgytdp);
const html_3_4_mosfetn = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.08" cy="14.08" rx="12.5" ry="12.47"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.92,21.39 13.35,21.39 13.35,8.32 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.42,11.06 21.67,11.06 21.67,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.42,17.9 21.67,17.9 21.67,30 			"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="18.49,16.2 18.49,12.88 15.99,14.54 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="19.07,14.54 21.67,14.54 21.67,17.9 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.42" y1="8.32" x2="15.42" y2="21.93"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_mosfetn', html_3_4_mosfetn);
const html_3_4_mosfetnxjj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="16.16" cy="14.08" rx="12.5" ry="12.47"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.44" y1="20.61" x2="13.44" y2="7.54"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.44,11.06 19.69,11.06 19.69,0 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.44,17.9 19.69,17.9 19.69,30 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.84" y1="14.08" x2="13.23" y2="14.08"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_mosfetnxjj', html_3_4_mosfetnxjj);
const html_3_4_scejg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" rx="11.46" ry="11.75"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="30" y2="15"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="10.69,21 10.69,8.43 19.71,15 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_4_scejg', html_3_4_scejg);


const html_3_5_3jwglkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6" y1="0" x2="6" y2="11.89"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="1.94,12.53 6,18.38 6,30 					"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="3.97" y1="15.46" x2="7.48" y2="15.46"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.59" y1="15.46" x2="17.96" y2="15.46"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.02,12.53 16.89,18.11 16.89,30 					"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.41" y1="15.46" x2="21.9" y2="15.46"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="16.89" y1="0" x2="16.89" y2="11.89"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="27.97" y1="0" x2="27.97" y2="11.89"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="24.37,12.53 27.97,17.84 27.97,30 					"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="23.1" y1="15.46" x2="26.52" y2="15.46"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.51" y1="15.46" x2="11.07" y2="15.46"/>
</g>
<line fill='#780000' stroke='#780000' stroke-miterlimit='10' x1="3.97" y1="11.89" x2="7.83" y2="11.89"/>
<line fill='#780000' stroke='#780000' stroke-miterlimit='10' x1="14.96" y1="11.89" x2="18.82" y2="11.89"/>
<line fill='#780000' stroke='#780000' stroke-miterlimit='10' x1="26.04" y1="11.89" x2="29.9" y2="11.89"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_3jwglkg', html_3_5_3jwglkg);
const html_3_5_3jwkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6" y1="0" x2="6" y2="11.89"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="1.94,12.53 6,18.38 6,30 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="3.97" y1="15.46" x2="7.48" y2="15.46"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.59" y1="15.46" x2="17.96" y2="15.46"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="13.02,12.53 16.89,18.11 16.89,30 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.41" y1="15.46" x2="21.9" y2="15.46"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="16.89" y1="0" x2="16.89" y2="11.89"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="27.97" y1="0" x2="27.97" y2="11.89"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="24.37,12.53 27.97,17.84 27.97,30 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="23.1" y1="15.46" x2="26.52" y2="15.46"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.51" y1="15.46" x2="11.07" y2="15.46"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_3jwkg', html_3_5_3jwkg);
const html_3_5_3jwkg2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-width='0.6' stroke-miterlimit='10' points="11.04,7.59 13.37,7.69 12.3,9.84 			"/>
<polygon fill='none' stroke='#780000' stroke-width='0.6' stroke-miterlimit='10' points="6.97,19.48 5.97,21.57 4.4,19.75 			"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M5.69,19.52c-0.05-0.43-0.08-0.88-0.08-1.33c0-4.28,2.54-7.97,6.2-9.65"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="17.37" cy="9.84" rx="1.23" ry="1.22"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="9.35" cy="24.33" rx="1.33" ry="1.32"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17.37" y1="8.31" x2="17.37" y2="2.35"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M10.39,23.72c3.78-2.82,6.38-7.14,6.89-12.08l0.09-0.85"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0,27.15c3.05,0,5.92-0.78,8.41-2.16"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="26.13" y1="23" x2="29.68" y2="25.93"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="25.42" cy="22.52" r="1.32"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_3jwkg2', html_3_5_3jwkg2);
const html_3_5_3jwxzkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0.01,13.46c0.25,0,3.63,0,3.63,0v7.84h4.04"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="3.64" y1="17.38" x2="7.04" y2="17.38"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.03" y1="17.38" x2="11.07" y2="17.38"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.26" y1="17.88" x2="13.43" y2="19.6"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.39" y1="15.13" x2="11.74" y2="17.11"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.99" y1="13.08" x2="9.81" y2="14.27"/>
<path d="M12.36,12.93l1.9-2.46c0.11-0.14,0.2-0.29,0.26-0.44c0.06-0.15,0.09-0.29,0.09-0.42V9.6c0-0.21-0.06-0.38-0.19-0.49c-0.13-0.12-0.31-0.17-0.54-0.17c-0.22,0-0.4,0.06-0.54,0.19s-0.22,0.31-0.25,0.54v0h-0.79v0c0.04-0.3,0.14-0.56,0.28-0.78c0.14-0.21,0.32-0.38,0.54-0.49c0.22-0.11,0.47-0.17,0.76-0.17c0.32,0,0.6,0.05,0.83,0.16c0.23,0.11,0.4,0.27,0.52,0.47s0.18,0.46,0.18,0.75v0c0,0.19-0.04,0.38-0.12,0.59s-0.19,0.4-0.33,0.59l-1.58,2.08h2.06v0.71h-3.07V12.93z"/>
<path d="M18.31,13.44c-0.23-0.11-0.42-0.26-0.56-0.47c-0.14-0.21-0.23-0.45-0.27-0.74h0.78c0.02,0.15,0.07,0.27,0.15,0.37c0.07,0.1,0.17,0.17,0.29,0.22s0.26,0.07,0.42,0.07c0.25,0,0.45-0.07,0.59-0.2c0.14-0.13,0.21-0.32,0.21-0.55v-0.16c0-0.26-0.07-0.47-0.2-0.61c-0.13-0.14-0.32-0.22-0.56-0.22h-0.39v-0.71h0.39c0.21,0,0.38-0.06,0.49-0.19s0.18-0.3,0.18-0.52V9.57c0-0.21-0.06-0.37-0.19-0.48c-0.12-0.11-0.3-0.17-0.53-0.17c-0.13,0-0.25,0.02-0.35,0.07c-0.1,0.05-0.19,0.12-0.26,0.22c-0.07,0.1-0.12,0.22-0.16,0.37h-0.78c0.05-0.29,0.14-0.54,0.28-0.74c0.14-0.21,0.31-0.36,0.53-0.47c0.21-0.11,0.46-0.16,0.73-0.16c0.47,0,0.83,0.12,1.09,0.36c0.26,0.24,0.39,0.58,0.39,1.02v0.09c0,0.26-0.07,0.5-0.22,0.69c-0.15,0.2-0.36,0.34-0.63,0.43c0.3,0.06,0.53,0.21,0.69,0.43s0.25,0.5,0.25,0.84v0.09c0,0.31-0.06,0.57-0.18,0.79s-0.3,0.38-0.53,0.5c-0.23,0.11-0.51,0.17-0.84,0.17C18.81,13.6,18.54,13.55,18.31,13.44z"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.68" y1="13.21" x2="14.68" y2="21.3"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.36" y1="13.46" x2="17.57" y2="16.12"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.8,18.74 16.86,17.18 20.04,17.18 			"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M21.49,17.21c0.99,0.03,8.5-0.03,8.5-0.03"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="29.5" y1="14.06" x2="29.5" y2="20.36"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="24.29" y1="8.48" x2="24.29" y2="13.33"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="29.5" y1="8.48" x2="29.5" y2="13.33"/>
<path d="M9.13,8.21L7.5,9.6v1.38c0.09-0.05,1.26-0.87,1.26-0.87v3.47h1.05V8.21H9.13z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_3jwxzkg', html_3_5_3jwxzkg);
const html_3_5_4jwkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-width='0.6' stroke-miterlimit='10' points="3.09,10.9 4.23,12.79 5.49,10.9 			"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="10.19,2.12 10.5,4.31 12.41,3.06 			"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M4.31,10.84c0.26-3.53,2.64-6.46,5.87-7.54"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.82" y1="0" x2="14.82" y2="5.17"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="14.82" cy="6.37" r="1.14"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M21.56,8.58c-1.8,1.57-4.16,2.53-6.74,2.53c-2.53,0-4.84-0.92-6.63-2.43"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M8.19,21.03c1.8-1.57,4.16-2.53,6.74-2.53c2.53,0,4.84,0.92,6.63,2.43"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.01" y1="11.07" x2="14.01" y2="18.52"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.74" y1="11.07" x2="15.74" y2="18.52"/>
</g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="14.87" cy="23.36" r="1.28"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.87" y1="30" x2="14.87" y2="24.64"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.89" y1="14.81" x2="21.1" y2="14.81"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="14.81" x2="5.97" y2="14.81"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="7.43" cy="14.8" r="1.46"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="14.79" x2="24.03" y2="14.79"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="22.57" cy="14.8" r="1.46"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_4jwkg', html_3_5_4jwkg);
const html_3_5_ajbh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="2.67" x2="15" y2="20.08"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="9.04" y1="20.08" x2="20.96" y2="20.08"/>
</g>
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="9.16" cy="25.04" rx="1.77" ry="1.79"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="25.04" x2="7.4" y2="25.04"/>
</g>
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.84" cy="25.04" rx="1.77" ry="1.79"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="25.04" x2="22.6" y2="25.04"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_ajbh', html_3_5_ajbh);
const html_3_5_ajdlq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="3.3" x2="15" y2="20.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="9.82" y1="20.5" x2="20.18" y2="20.5"/>
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="24.38" x2="7.62" y2="24.38"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="9.14" cy="24.38" rx="1.81" ry="1.82"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="24.38" x2="22.38" y2="24.38"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.86" cy="24.38" rx="1.81" ry="1.82"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="9.14" y1="26.2" x2="20.86" y2="26.2"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_ajdlq', html_3_5_ajdlq);
const html_3_5_ajedl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="-0.16" y1="18.61" x2="8.73" y2="18.61"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="9.62" cy="18.61" r="1.41"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30.16" y1="18.61" x2="21.27" y2="18.61"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.38" cy="18.61" r="1.41"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="9.62" y1="20.03" x2="20.38" y2="20.03"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="-0.16" y1="28.09" x2="8.73" y2="28.09"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="9.62" cy="28.09" r="1.41"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30.16" y1="28.09" x2="21.27" y2="28.09"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.38" cy="28.09" r="1.41"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="20.03"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_ajedl', html_3_5_ajedl);
const html_3_5_aqlszz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0,15c2.04-0.02,12.96,0.01,12.96,0.01"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="15" x2="17.32" y2="15"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="10.89,11.57 19.25,11.57 15.07,18.01 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_aqlszz', html_3_5_aqlszz);
const html_3_5_bcyw = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="10.8"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="19.6,13.9 15,19 15,30 		"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="12.4" rx="1.8" ry="1.8"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_bcyw', html_3_5_bcyw);
const html_3_5_bhcd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="12.6"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="10.7,12.2 15,18.1 15,30"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_bhcd', html_3_5_bhcd);
const html_3_5_chkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M21.45,19.19H8.73c-2.32,0-4.19-1.88-4.19-4.19v0c0-2.32,1.88-4.19,4.19-4.19h12.72c2.32,0,4.19,1.88,4.19,4.19v0C25.64,17.32,23.77,19.19,21.45,19.19z"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0,15 10.55,15 18.65,12.27 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.94" y1="14.88" x2="30" y2="14.88"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_chkg', html_3_5_chkg);
const html_3_5_dlq1p = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="11.8"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="10.9,12.3 15,17.7 15,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="13.5" y1="10.3" x2="16.4" y2="13"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="16.4" y1="10.3" x2="13.5" y2="12.8"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_dlq1p', html_3_5_dlq1p);
const html_3_5_dlqcd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<style type="text/css">
</style>
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="15,0 15,13.9 19.3,13.9 	"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="18.8,11.9 15,18.1 15,30 	"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_dlqcd', html_3_5_dlqcd);
const html_3_5_dlxzq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.69" y1="4.16" x2="30" y2="4.16"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.56" y1="13.91" x2="17.08" y2="5.21"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15.06" x2="5.06" y2="15.06"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="18.23" cy="4.16" r="1.55"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.43" y1="11.39" x2="30" y2="11.39"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.87" cy="11.39" r="1.55"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.43" y1="18.61" x2="30" y2="18.61"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.87" cy="18.61" r="1.55"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.55" y1="25.84" x2="30" y2="25.84"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="17.08" cy="25.84" r="1.55"/>
</g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="6.24" cy="14.73" r="1.55"/>
</g>
<g>
<path fill='none' stroke='#780000' stroke-width='0.6' stroke-miterlimit='10' d="M16.38,10.62l-1.31-0.01c0.01-1.54-0.39-2.71-1.18-3.49C12.75,6,11.13,6.03,11.11,6.03l-0.04-1.31c0.09,0,2.18-0.05,3.72,1.45C15.86,7.21,16.39,8.71,16.38,10.62z"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_dlxzq', html_3_5_dlxzq);
const html_3_5_dpdt = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="7.55" cy="11.06" r="1.54"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="14.29" cy="11.06" r="1.54"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="14.29" cy="19.73" r="1.54"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="11.06" x2="6.01" y2="11.06"/>
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="7.55" cy="19.73" r="1.54"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="19.73" x2="6.01" y2="19.73"/>
</g>
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="22.45" cy="19.73" r="1.54"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="19.73" x2="23.99" y2="19.73"/>
</g>
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="22.45" cy="11.06" r="1.54"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="11.06" x2="23.99" y2="11.06"/>
</g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15.83,11.06"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M20.94,11.06"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.33" y1="9.74" x2="18.33" y2="11.98"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.79" y1="11.06" x2="20.87" y2="8.68"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="20.87" y1="17.31" x2="15.79" y2="19.69"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.33" y1="12.94" x2="18.33" y2="16.17"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_dpdt', html_3_5_dpdt);
const html_3_5_dpst = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="2.27" cy="10" r="1.77"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="2.27" cy="23.66" r="1.77"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="27.73" cy="10" r="1.77"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="27.73" cy="23.66" r="1.77"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="25.48" y1="4.56" x2="4.03" y2="9.54"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="25.48" y1="18.26" x2="4.03" y2="23.24"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.75" y1="7.05" x2="14.75" y2="11.97"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.75" y1="16.26" x2="14.75" y2="20.75"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_dpst', html_3_5_dpst);
const html_3_5_eldl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="3.9,20.4 6.4,18.3 4.5,15.9 6.9,14.1 8.8,16.5 10.9,14.8 	"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="12,12.7 15,17.1 15,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="13.2"/>
<polygon fill="#780000" points="7.6,18.8 5.9,21.8 9.6,21.8 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="12.5" y1="17.1" x2="8" y2="20.5"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_eldl', html_3_5_eldl);
const html_3_5_glq1p = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="12.3"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11,12.3 15,17.7 15,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="13.2" y1="12.1" x2="16.7" y2="12.1"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_glq1p', html_3_5_glq1p);
const html_3_5_jbhcd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="11.9" x2="15" y2="0"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="12.1,8.6 15,12.3 17.6,8.6 	"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="10.4,11.9 15,17.4 15,30 	"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_jbhcd', html_3_5_jbhcd);
const html_3_5_jdq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="0.5" y="9.84" fill='none' stroke='#780000' stroke-miterlimit='10' width="29" height="10.31"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.92" y1="9.84" x2="10.92" y2="20.16"/>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M2.69,12.66c0-0.81,0.64-1.46,1.42-1.46h0c0.79,0,1.42,0.65,1.42,1.46v2.43"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M8.39,17.34c0,0.81-0.64,1.46-1.42,1.46h0c-0.79,0-1.42-0.65-1.42-1.46v-2.43"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_jdq', html_3_5_jdq);
const html_3_5_jdqcd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="12.47" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.47" y1="9.22" x2="12.47" y2="20.78"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="15" x2="17.53" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17.53" y1="20.78" x2="17.53" y2="9.22"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_jdqcd', html_3_5_jdqcd);
const html_3_5_jdqxq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="14.84" cy="15" rx="6.16" ry="6.1"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="8.68" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.32" y1="15" x2="30" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_jdqxq', html_3_5_jdqxq);
const html_3_5_jjkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="9.72" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="20.24" y1="15" x2="30" y2="15"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="15.27,25.37 4.73,15 15.27,4.63 25.8,15 			"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="9.72,15 10.59,15 19.86,18.2 17.75,20.28 			"/>
<circle fill='#780000' cx="10.11" cy="15" r="1.14"/>
<circle fill='#780000' cx="19.86" cy="15" r="1.14"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_jjkg', html_3_5_jjkg);
const html_3_5_kg1p = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="12.3"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="10.9,12.3 14.9,17.7 14.9,30 	"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_kg1p', html_3_5_kg1p);
const html_3_5_kgcdq1p = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="11.1"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11,12.3 15,17.7 15,30 	"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="12.7" rx="1.6" ry="1.6"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="12.1" y1="10.4" x2="18" y2="10.4"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_kgcdq1p', html_3_5_kgcdq1p);
const html_3_5_kgdlq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="30"/>
<g>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15,19.5c-1.25,0-2.26-1.01-2.26-2.25c0-1.24,1.01-2.25,2.26-2.25"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15,10.5c1.25,0,2.26,1.01,2.26,2.25S16.25,15,15,15"/>
</g>
<ellipse fill='#780000FFF' stroke='#780000' stroke-miterlimit='10' cx="15" cy="10.5" rx="1.49" ry="1.48"/>
<ellipse fill='#780000FFF' stroke='#780000' stroke-miterlimit='10' cx="15" cy="19.5" rx="1.49" ry="1.48"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_kgdlq', html_3_5_kgdlq);
const html_3_5_ljxzkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="9.72" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="20.24" y1="15" x2="30" y2="15"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="15.27,25.37 4.73,15 15.27,4.63 25.8,15 			"/>
<circle fill='#780000' cx="9.72" cy="15" r="1.41"/>
<circle fill='#780000' cx="20.24" cy="15" r="1.41"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="17.67,9.76 15.27,15 9.72,15 19.35,8.65 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_ljxzkg', html_3_5_ljxzkg);
const html_3_5_qdddl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0.1" y1="15" x2="9.1" y2="15"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="20.2" y1="15" x2="30" y2="15"/>
<circle fill="none" stroke="#780000" stroke-width="0.6" stroke-miterlimit="10" cx="19.2" cy="15" r="0.9"/>
<circle fill="none" stroke="#780000" stroke-width="0.6" stroke-miterlimit="10" cx="10" cy="15" r="0.9"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="14,15.8 14,22.1 16.8,22.1 14,19.7 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="10.9" y1="15" x2="20.2" y2="18.2"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_qdddl', html_3_5_qdddl);
const html_3_5_qlqd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="12"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="12.5,12 15,17.8 15,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="10.8" y1="15.2" x2="13.8" y2="15.2"/>
<path fill='#780000' d="M7.5,13.4v3.7H3.4v-3.7H7.5 M8.1,12.8H7.5H3.4H2.8v0.6v3.7v0.6h0.6h4.1h0.6v-0.6v-3.7V12.8L8.1,12.8z"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="7.5" y1="15.2" x2="9.7" y2="15.2"/>
<path fill="none" stroke="#780000" stroke-width="0.6" stroke-miterlimit="10" d="M6.9,14H4.7c-0.2,0-0.4,0.2-0.4,0.4v1.7c0,0.2,0.2,0.4,0.4,0.4h1.2c0.2,0,0.4-0.2,0.4-0.4v-1.8"/>
<path fill="none" stroke="#780000" stroke-width="0.3" stroke-miterlimit="10" d="M5.2,16.2c0-0.5,0.4-0.9,0.9-0.9"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_qlqd', html_3_5_qlqd);
const html_3_5_sdkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="8.6" y1="13.1" x2="8.6" y2="18.7"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="10,11.8 15,18 15,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="12.9"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="8.6" y1="15.9" x2="9.6" y2="15.9"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" stroke-dasharray="1.8102,1.8102" x1="11.4" y1="15.9" x2="17.8" y2="15.9"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="18.7" y1="15.9" x2="19.7" y2="15.9"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_sdkg', html_3_5_sdkg);
const html_3_5_sdkg1p = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0.5" y1="11.9" x2="0.5" y2="18.8"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="3.4,15 5.2,15 6.8,18.8 8.4,15 10.3,15.1 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0.5" y1="15" x2="2.1" y2="15"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11.3,15 13.6,15 15,17.4 15,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="13"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="12.1" y1="12.2" x2="13.6" y2="15"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_sdkg1p', html_3_5_sdkg1p);
const html_3_5_sjwglkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.94" y1="0" x2="10.94" y2="11.71"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="24.36" y1="0" x2="24.36" y2="11.71"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="5.51,11.71 10.94,17.73 10.94,30 					"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="18.94,11.71 24.36,17.73 24.36,30 					"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.61" y1="15.16" x2="12.5" y2="15.16"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M19.44,15.11c2.04-0.01,2.82,0.05,2.82,0.05"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.56" y1="15" x2="17.32" y2="15"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.22" y1="11.71" x2="12.94" y2="11.71"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22" y1="11.71" x2="26.72" y2="11.71"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_sjwglkg', html_3_5_sjwglkg);
const html_3_5_sjwkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.94" y1="0" x2="10.94" y2="11.71"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="24.36" y1="0" x2="24.36" y2="11.71"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="5.51,11.71 10.94,17.73 10.94,30 				"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="18.94,11.71 24.36,17.73 24.36,30 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.61" y1="15.16" x2="12.5" y2="15.16"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M19.44,15.11c2.04-0.01,2.82,0.05,2.82,0.05"/>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.56" y1="15" x2="17.32" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_sjwkg', html_3_5_sjwkg);
const html_3_5_sjwkg2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="14.89" x2="5.37" y2="14.89"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="6.36" cy="14.89" rx="1.16" ry="1.17"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.21" cy="6.16" rx="1.16" ry="1.17"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M7.53,14.7H8.5c3.71,0,6.71-3.01,6.71-6.72V7.12"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.21" y1="0" x2="15.21" y2="4.99"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="15" x2="24.94" y2="15"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="23.88" cy="15" rx="1.05" ry="1.05"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.1" y1="30" x2="15.1" y2="24.93"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.1" cy="23.87" rx="1.05" ry="1.05"/>
</g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M15.59,22.97c0-4.23,3.42-7.66,7.65-7.66"/>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M19.28,4.51h1.36c3.03,0,5.56,2.16,6.14,5.03v0.34"/>
<g>
<path d="M27.64,9.89l-0.63,1.24l-1.24-0.64L27.64,9.89 M28.83,8.87l-1.38,0.45l-1.88,0.61l-1.38,0.45l1.29,0.66l1.24,0.64l0.53,0.27l0.27-0.54l0.63-1.24L28.83,8.87L28.83,8.87z"/>
</g>
<g>
<path d="M18.99,3.51l0.05,1.98l-1.01-0.96L18.99,3.51 M19.55,2.04l-1,1.06l-0.97,1.02l-0.41,0.44l0.43,0.41l1.01,0.96l1.05,0.99l-0.03-1.44L19.59,3.5L19.55,2.04L19.55,2.04z"/>
</g>
</g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.04" y1="11.76" x2="19.04" y2="16.57"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.81" y1="13" x2="17.8" y2="17.81"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_sjwkg2', html_3_5_sjwkg2);
const html_3_5_sjwzhkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="8.65" x2="9.12" y2="8.65"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="9.97" cy="8.65" rx="1.38" ry="1.38"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="21.32" x2="9.12" y2="21.32"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="9.97" cy="21.32" rx="1.38" ry="1.38"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="11.75" y1="8.03" x2="18.84" y2="5.88"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="11.75" y1="21.02" x2="18.84" y2="18.87"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.07" cy="5.58" rx="1.5" ry="1.5"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.07" cy="11.86" rx="1.5" ry="1.5"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.07" cy="18.14" rx="1.5" ry="1.5"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.07" cy="24.42" rx="1.5" ry="1.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.57" y1="5.58" x2="30" y2="5.58"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.57" y1="11.86" x2="30" y2="11.86"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.57" y1="18.14" x2="30" y2="18.14"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.57" y1="24.42" x2="30" y2="24.42"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.89" y1="7.08" x2="14.89" y2="19.94"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_sjwzhkg', html_3_5_sjwzhkg);
const html_3_5_spdt = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="27.83" cy="8.14" rx="1.67" ry="1.67"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="27.83" cy="21.86" rx="1.67" ry="1.67"/>
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="2.17" cy="15" rx="1.67" ry="1.67"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="26.67" y1="15" x2="3.85" y2="15"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_spdt', html_3_5_spdt);
const html_3_5_spst = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="2.8" cy="15.3" rx="2.3" ry="2.2"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="27.2" cy="15.3" rx="2.3" ry="2.2"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="4.7" y1="14.1" x2="23.7" y2="5.6"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_spst', html_3_5_spst);
const html_3_5_sxcd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.68" y1="0" x2="8.68" y2="12.15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.32" y1="0" x2="21.32" y2="12.15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.76" y1="10.63" x2="14.76" y2="22.56"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.76" y1="25.85" x2="14.76" y2="30"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="14.76" cy="24.39" rx="2.03" ry="2.02"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_sxcd', html_3_5_sxcd);
const html_3_5_sydldl = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="5.8" y1="17.7" x2="8.6" y2="21.2"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="3.7" y1="22.2" x2="7.2" y2="19.5"/>
<polygon points="9.3,14.9 8.3,18.6 12.1,18.5 		"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="10" y1="17.3" x2="13.2" y2="14.8"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="12.1,12.8 15,17.3 15,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="13.1"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_sydldl', html_3_5_sydldl);
const html_3_5_sydlq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="9.2" y2="15"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M13.2,19.5"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="19.1" y1="10.3" x2="11.8" y2="14.6"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="30" y1="15" x2="20.5" y2="15"/>
<ellipse fill="none" stroke="#780000" stroke-width="0.6" stroke-miterlimit="10" cx="10.5" cy="15" rx="1.3" ry="1.3"/>
<ellipse fill="none" stroke="#780000" stroke-width="0.6" stroke-miterlimit="10" cx="19.2" cy="15" rx="1.3" ry="1.3"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="16.8,21.1 15,18.7 13.2,21.1 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="12.7" x2="15" y2="18.7"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_sydlq', html_3_5_sydlq);
const html_3_5_sydlq2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="15,0 15,12.3 19,12.3 	"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="15,30 15,17.8 18.3,10.5 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="20.9" y1="14.4" x2="16.7" y2="14.4"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="20.9" y1="16" x2="15.8" y2="16"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M22.7,18.6c-1.1-0.8-1.7-2.1-1.7-3.6c0-1.5,0.7-2.8,1.8-3.6"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_sydlq2', html_3_5_sydlq2);
const html_3_5_sykg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="3.21" cy="3.67" rx="2.52" ry="2.57"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="27.16" cy="3.67" rx="2.52" ry="2.57"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.18" y1="0.08" x2="15.18" y2="23.9"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="15.18" cy="26.85" rx="2.52" ry="2.57"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M21.24,17.01H9.13c-1.31,0-2.38-1.07-2.38-2.38v0c0-1.31,1.07-2.38,2.38-2.38h12.11c1.31,0,2.38,1.07,2.38,2.38v0C23.62,15.94,22.55,17.01,21.24,17.01z"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_sykg', html_3_5_sykg);
const html_3_5_sykg2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="11.76" y1="0.12" x2="11.76" y2="8.76"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.24" y1="0.12" x2="18.24" y2="8.76"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="11.76" y1="21.24" x2="11.76" y2="29.88"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.24" y1="21.24" x2="18.24" y2="29.88"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M17.11,16.47h-4.23c-0.81,0-1.47-0.66-1.47-1.47v0c0-0.81,0.66-1.47,1.47-1.47h4.23c0.81,0,1.47,0.66,1.47,1.47v0C18.59,15.81,17.93,16.47,17.11,16.47z"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="9.08" x2="15" y2="20.92"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_sykg2', html_3_5_sykg2);
const html_3_5_thcd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="10.5,12.9 15,16.6 15,30 	"/>
<polygon fill="#780000" points="14.5,0 14.5,11.2 13,12.2 15.5,14 15.5,12 15.5,10.5 15.5,0 	"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_thcd', html_3_5_thcd);
const html_3_5_thfw2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="6.8" y2="15"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="8.1" cy="15" rx="1.6" ry="1.6"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M9.7,14.5c0.7-0.5,12.2-5.3,12.2-5.3"/>
<path fill="#780000" d="M25.5,14.5l-2.6-3.6l-3.5,4.6H30v-1H25.5z M22.9,12.6l1.3,1.9h-2.5L22.9,12.6z"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_thfw2', html_3_5_thfw2);
const html_3_5_thfw3 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="6.8" y2="15"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="8.1" cy="15" rx="1.6" ry="1.6"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M9.7,15.4c0.7,0.5,12.2,5.3,12.2,5.3"/>
<path fill="#780000" d="M30,15.5v-1H19.4l3.5,4.6l2.6-3.6C25.5,15.5,30,15.5,30,15.5z M21.7,15.5h2.5l-1.3,1.9L21.7,15.5z"/>
</g>
</svg>
`;
Graph.registerHTMLComponent('3_5_thfw3', html_3_5_thfw3);
const html_3_5_twq = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<path  fill="none" stroke="#780000" stroke-linejoin="round" stroke-miterlimit="10" d="M0.2,15C0.4,15,9,15,9,15l9.6-8.3"/>
<line fill="none" stroke="#780000" stroke-linejoin="round" stroke-miterlimit="10" x1="20.9" y1="15" x2="29.8" y2="15"/>
<g>
<path fill="#780000" d="M23,7.3l0.2,0.9c-0.4,0.1-0.7,0.1-1,0.1c-0.5,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.5-0.3-0.5-0.5S20.4,7,20.4,6.4V4.3h-1V3.5h1V2
h1.3v1.5H23v0.8h-1.3v2.1c0,0.3,0,0.5,0.1,0.6s0.1,0.1,0.2,0.2s0.2,0.1,0.5,0.1C22.5,7.3,22.7,7.3,23,7.3z"/>
</g>
<circle fill="none" stroke="#780000" stroke-width="0.5" stroke-miterlimit="10" cx="24.3" cy="1.5" r="1.2"/>
</svg>
`;
Graph.registerHTMLComponent('3_5_twq', html_3_5_twq);
const html_3_5_wdkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="12,11.9 14.9,17.4 14.9,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="14.9" y1="0" x2="14.9" y2="11.9"/>
<ellipse fill="none" stroke="#780000" stroke-width="0.8" stroke-miterlimit="10" cx="10.4" cy="15" rx="1" ry="1.4"/>
<line fill="none" stroke="#780000" stroke-width="0.8" stroke-miterlimit="10" x1="9.4" y1="15" x2="11.5" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_wdkg', html_3_5_wdkg);
const html_3_5_wdqd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="7.9" y2="15"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="8.9" cy="15" rx="1.2" ry="1.2"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="20.5" cy="15" rx="1.2" ry="1.2"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="10.1" y1="15" x2="20.5" y2="13.8"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="21.7" y1="15" x2="30" y2="15"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="12.9,14.7 12.9,20.5 15,20.5 15,23.1 12.9,23.1 12.9,26.3 	"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_wdqd', html_3_5_wdqd);
const html_3_5_xzkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="11,12.1 14.9,18.1 14.9,30 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="14.9" y1="0" x2="14.9" y2="12.5"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="12.1,13.7 10.6,14.8 14.9,18.1 	"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_xzkg', html_3_5_xzkg);
const html_3_5_xzkgnc = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" cx="9.2" cy="15" rx="1.6" ry="1.6"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="18.1" cy="15" rx="1.6" ry="1.6"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="9.2" y2="15"/>
<path fill="none" stroke="#780000" stroke-miterlimit="10" d="M9.2,15"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="19.6" y1="15" x2="30" y2="15"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="9.2,15 12.1,11.1 16.6,11.6 		"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="9.2" y1="15" x2="18.3" y2="10.8"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_xzkgnc', html_3_5_xzkgnc);
const html_3_5_xzkgno = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<ellipse fill="none" stroke="#780000" cx="9.2" cy="14.9" rx="1.6" ry="1.6"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="18.1" cy="14.9" rx="1.6" ry="1.6"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="0,14.9 9.2,14.9 12.1,18.9 16.8,18.3 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="9.2" y1="14.9" x2="18.3" y2="19.2"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="19.6" y1="14.9" x2="30" y2="14.9"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_xzkgno', html_3_5_xzkgno);
const html_3_5_xzqkg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.69" y1="4.16" x2="30" y2="4.16"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.56" y1="13.91" x2="17.08" y2="5.21"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15.06" x2="5.06" y2="15.06"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="18.23" cy="4.16" r="1.55"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.43" y1="11.39" x2="30" y2="11.39"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.87" cy="11.39" r="1.55"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="22.43" y1="18.61" x2="30" y2="18.61"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="20.87" cy="18.61" r="1.55"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="18.55" y1="25.84" x2="30" y2="25.84"/>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="17.08" cy="25.84" r="1.55"/>
</g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="6.24" cy="14.73" r="1.55"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_xzqkg', html_3_5_xzqkg);
const html_3_5_ylqd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="7.9" y2="15"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="8.9" cy="15" rx="1.2" ry="1.2"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="20.5" cy="15" rx="1.2" ry="1.2"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="10.1" y1="15" x2="20.5" y2="13.8"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="21.7" y1="15" x2="30" y2="15"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="12.9" y1="14.7" x2="12.9" y2="21.9"/>
<polygon fill="none" stroke="#780000" stroke-linejoin="round" stroke-miterlimit="10" points="12.9,21.5 10.2,24.2 15.4,24.2 	"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_ylqd', html_3_5_ylqd);
const html_3_5_ymqd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="14.2" x2="15" y2="25.3"/>
<ellipse fill="none" stroke="#780000" stroke-miterlimit="10" cx="15" cy="27.2" rx="2.1" ry="2.1"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="0" y1="15" x2="8.2" y2="15"/>
<path  fill="#780000"  d="M9.2,14c0.6,0,1,0.5,1,1s-0.4,1-1,1s-1-0.5-1-1S8.7,14,9.2,14 M9.2,13.4c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6
s1.6-0.7,1.6-1.6S10.1,13.4,9.2,13.4L9.2,13.4z"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="30" y1="15" x2="21.8" y2="15"/>
<path fill="#780000" d="M20.8,14c0.6,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1S20.2,14,20.8,14 M20.8,13.4c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6
s1.6-0.7,1.6-1.6S21.7,13.4,20.8,13.4L20.8,13.4z"/>
</g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="10.4" y1="14.9" x2="20.8" y2="13.7"/>
</svg>`;
Graph.registerHTMLComponent('3_5_ymqd', html_3_5_ymqd);
const html_3_5_ymqd2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0" x2="15" y2="12"/>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="12.5,12 15,17.8 15,30 	"/>
<rect x="3.4" y="13.4" fill="none" stroke="#780000" stroke-miterlimit="10" width="4.1" height="3.7"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="7.5" y1="15.2" x2="13.9" y2="15.2"/>
<path fill="none" stroke="#780000" stroke-width="0.4" stroke-miterlimit="10" d="M6.8,15.8L6.8,15.8c-0.8,0.8-2,0.8-2.8,0l0,0"/>
<path fill="none" stroke="#780000" stroke-width="0.4" stroke-miterlimit="10" d="M4,15.4L4,15.4c0.8-0.8,2-0.8,2.8,0l0,0"/>
<line fill="none" stroke="#780000" stroke-width="0.4" stroke-miterlimit="10" x1="5.4" y1="13.6" x2="5.4" y2="14.7"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_ymqd2', html_3_5_ymqd2);
const html_3_5_yskg = `<svg version="1.1" id="图层_1"   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30"  xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="16.99" x2="7.68" y2="16.99"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="8.79" cy="16.99" rx="1.19" ry="1.19"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="10.45" cy="13.01" rx="1.19" ry="1.19"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="11.64,13.01 15.24,13.01 15.24,14.68 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.49" y1="11.82" x2="9.97" y2="16.99"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="21.85" cy="16.83" rx="1.11" ry="1.11"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="30" y1="16.83" x2="22.96" y2="16.83"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_yskg', html_3_5_yskg);
const html_3_5_zhcd = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="12.56,0 12.56,13.95 15.45,13.95 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14,11.7 17.44,17.64 17.44,30 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="17.44" y1="0" x2="17.44" y2="12.11"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_5_zhcd', html_3_5_zhcd);


const html_3_6_3gflrz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line stroke='#780000' stroke-miterlimit='10' x1="2.64" y1="30" x2="2.64" y2="0"/>
<line stroke='#780000' stroke-miterlimit='10' x1="4.73" y1="30" x2="4.73" y2="0"/>
<line stroke='#780000' stroke-miterlimit='10' x1="6.83" y1="30" x2="6.83" y2="0"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M10.59,8.84c0,0,3.45,12.59,8.56,1.65c0.38-0.82,0.9-1.57,1.54-2.19c1.54-1.47,4.26-2.71,6.7,3.09"/>
<g>
<g>
<path fill='#780000'  d="M8.36,7.04c0.12-1.07,0.87-2.39,3.03-2.39c2,0,2.89,1.14,2.89,2.36c0,1.05-0.6,1.54-1.07,1.76v0.04c0.56,0.21,1.4,0.75,1.4,2.09c0,1.26-0.76,2.74-3.24,2.74c-2.31,0-3.1-1.4-3.19-2.55h1.83c0.12,0.59,0.52,1.11,1.41,1.11c0.87,0,1.3-0.56,1.3-1.33c0-0.8-0.41-1.29-1.42-1.29h-0.78V8.14h0.68c0.86,0,1.21-0.29,1.21-1.01c0-0.6-0.35-1-1.1-1c-0.88,0-1.09,0.49-1.18,0.92H8.36z"/>
</g>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_3gflrz', html_3_6_3gflrz);
const html_3_6_cl1 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<rect x="0.5" y="8.09" fill='none' stroke='#780000' stroke-miterlimit='10' width="29" height="13.82"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="5.63" y1="8.09" x2="0.5" y2="21.91"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="16.92" y1="8.09" x2="11.79" y2="21.91"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="28.2" y1="8.09" x2="23.08" y2="21.91"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_cl1', html_3_6_cl1);
const html_3_6_dx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="0.1" x2="15" y2="30"/>
<text transform="matrix(1.0294 0 0 1 20.1771 9.5654)" font-family='Arial-ItalicMT'  font-size="11.1494px" fill="#780000">m</text>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_dx', html_3_6_dx);
const html_3_6_dxxh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="22.28" y2="15"/>
<polygon points="22.28,9.1 22.28,20.9 30,14.83 			" />
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_dxxh', html_3_6_dxxh);
const html_3_6_fb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.5,15.95 0.5,0.55 16.18,0.55 16.18,29.45 29.5,29.45 29.5,15 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_fb', html_3_6_fb);
const html_3_6_fjx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="30" y2="15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_fjx', html_3_6_fjx);
const html_3_6_fs = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M6.08,12.5c1.12-1.1,2.94-1.1,4.06,0s1.12,2.89,0,4"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M10.21,16.43c-1.1,1.12-1.07,2.91,0.07,4c1.14,1.08,2.96,1.05,4.06-0.07"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M14.31,20.38c1.14-1.09,2.96-1.06,4.06,0.07c1.1,1.12,1.07,2.91-0.07,4l3.36,2.97"/>
<polygon fill='#780000' points="21.5,23.49 17.77,27.5 24.27,30 				"/>
</g>
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M6.08,1.33c1.12-1.1,2.94-1.1,4.06,0s1.12,2.89,0,4"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M10.21,5.26c-1.1,1.12-1.07,2.91,0.07,4s2.96,1.05,4.06-0.07"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M14.31,9.2c1.14-1.09,2.96-1.06,4.06,0.07c1.1,1.12,1.07,2.91-0.07,4l3.36,2.97"/>
<polygon fill='#780000' points="21.5,12.32 17.77,16.33 24.27,18.82 				"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_fs', html_3_6_fs);
const html_3_6_jcb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0.13,23.09c0.49-0.14,15.6-16.24,15.6-16.24v16.24l13.78-15.7v15.7"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_jcb', html_3_6_jcb);
const html_3_6_jcb2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.41,22.63 10.34,7.37 10.34,22.63 19.75,7.37 19.75,22.63 29.51,7.37 29.51,23.37 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_jcb2', html_3_6_jcb2);
const html_3_6_jfb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.47,14.62 5.36,0.5 11.88,0.5 18.4,29.5 27.14,29.5 29.51,13.41 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_jfb', html_3_6_jfb);
const html_3_6_jld = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='#780000'  d="M16.24,14.37C6.1,8.16,0.07,16.28,0,16.35c0.23-0.25,6.55-5.23,15.05-0.03c1.97,1.2,3.94,1.65,5.8,1.65c5.33,0,8.91-4.57,9.15-4.78C29.93,13.25,22.43,18.16,16.24,14.37z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_jld', html_3_6_jld);
const html_3_6_jyhs2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="3.25,0.5 13.81,0.5 13.81,29.5 26.75,29.5 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_jyhs2', html_3_6_jyhs2);
const html_3_6_lxjcs = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="10.34,0.44 14.78,3 19.55,0.44 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="14.9" y1="3" x2="14.9" y2="24.77"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.61" y1="24.77" x2="21.27" y2="24.77"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.67" y1="27.4" x2="19.21" y2="27.4"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="12.36" y1="29.5" x2="17.52" y2="29.5"/>
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="5.95,8.72 10.67,10.83 14.9,8.72 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.67" y1="10.83" x2="10.67" y2="17.67"/>
</g>
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.08,8.72 19.79,10.83 24.03,8.72 				"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="19.79" y1="10.83" x2="19.79" y2="17.67"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_lxjcs', html_3_6_lxjcs);
const html_3_6_lxljx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="0.56,14.59 7.38,0.83 22.72,0.83 29.44,14.59 22.52,29.17 7.38,29.17 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_lxljx', html_3_6_lxljx);
const html_3_6_lxsix = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="30"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0,15c0.46,0,30,0,30,0"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_lxsix', html_3_6_lxsix);
const html_3_6_lxssjx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="14.7,0.95 1.09,22.01 28.87,22.01 			"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="15.3,29.05 28.91,7.99 1.13,7.99 			"/>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="14.7,0.95 1.09,22.01 28.87,22.01 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_lxssjx', html_3_6_lxssjx);
const html_3_6_lxsx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.5" y1="0.32" x2="0.5" y2="26.54"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M3.38,29.18c0.41,0,26.62,0,26.62,0"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_lxsx', html_3_6_lxsx);
const html_3_6_lxsxx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="8.18" y1="0.4" x2="21.82" y2="20.05"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.65" y1="0.28" x2="8.35" y2="20.16"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="6.86" y1="10.22" x2="23.14" y2="10.22"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="10.22" x2="15" y2="23.42"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.19" y1="23.42" x2="19.81" y2="23.42"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="11.63" y1="26.54" x2="18.37" y2="26.54"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="13.07" y1="29.5" x2="16.93" y2="29.5"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_lxsxx', html_3_6_lxsxx);
const html_3_6_mc2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="0,15 10,15 10,4.2 20.1,4.2 20.1,15 30,15 	"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_mc2', html_3_6_mc2);
const html_3_6_mnxh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='#780000'  d="M26.29,0.74h-3.71l-0.98,5.75H11.51l0.99-5.75H8.79L7.8,6.49H1.15v3.23h6.02l-2,10.72H0v3.4h4.55l-1.15,5.42H7.1l0.99-5.42h10.27l-1.15,5.42h3.54l1.15-5.42h7.13v-3.4h-6.34l2-10.72H30V6.49h-4.69L26.29,0.74z M18.96,20.44H8.7l2.18-10.72h10.08L18.96,20.44z"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_mnxh', html_3_6_mnxh);
const html_3_6_ryfxxh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="3.86" y1="15" x2="26.14" y2="15"/>
<polygon fill='#780000'  points="22.28,9.1 22.28,20.9 30,14.83 			"/>
<polygon fill='#780000'  points="7.72,20.9 7.72,9.1 0,15.17 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_ryfxxh', html_3_6_ryfxxh);
const html_3_6_sx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.35" y1="0.82" x2="29.65" y2="29.18"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="29.17" y1="0.34" x2="0.83" y2="29.66"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sx', html_3_6_sx);
const html_3_6_sxsjlj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="14.26,1.27 0.79,29.34 29.17,29.34 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxsjlj', html_3_6_sxsjlj);
const html_3_6_sxsjlj2 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="15.22,0.22 8.02,14.98 21.92,14.98 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.22" y1="15.43" x2="15.22" y2="25.14"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="7.67" y1="25.14" x2="22.78" y2="25.14"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="10.41" y1="27.32" x2="20.03" y2="27.32"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M11.99,29.5c0.24,0,6.47,0,6.47,0"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxsjlj2', html_3_6_sxsjlj2);
const html_3_6_sxsjlj3 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="8.86,0.78 0.67,28.87 29.31,28.87 20.49,0.78 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxsjlj3', html_3_6_sxsjlj3);
const html_3_6_sxsjlj4 = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="15,1.15 5.46,20.5 24.54,20.5 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="20.5" x2="15" y2="29.99"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxsjlj4', html_3_6_sxsjlj4);
const html_3_6_sxsx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15.3" y1="0" x2="15.3" y2="30"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="1.96,21.92 15.3,13.19 28.03,21.92 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxsx', html_3_6_sxsx);
const html_3_6_sxtx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.66" y1="0.5" x2="29.34" y2="0.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0.5" x2="15" y2="30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxtx', html_3_6_sxtx);
const html_3_6_sxvx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="0.44,0.7 15,28.46 29.56,0.7 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxvx', html_3_6_sxvx);
const html_3_6_sxxh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="30" y2="15"/>
<polygon fill='#780000'  points="6.28,8.34 6.28,21.66 15,15 				"/>
<polygon fill='#780000' points="23.72,21.66 23.72,8.34 15,15 				"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxxh', html_3_6_sxxh);
const html_3_6_sxxxlj = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<g>
<polyline fill="none" stroke="#780000" stroke-miterlimit="10" points="15,0 15,15.5 0.3,29.6 	"/>
<line fill="none" stroke="#780000" stroke-miterlimit="10" x1="15" y1="15.5" x2="29.7" y2="29.6"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxxxlj', html_3_6_sxxxlj);
const html_3_6_sxzzrz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="1.1,0.45 10.91,5.05 10.91,14.67 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="1.87,30 1.87,19.36 10.91,14.67 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="28.83,14.05 19.87,20.1 10.91,14.67 			"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_sxzzrz', html_3_6_sxzzrz);
const html_3_6_szxh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M3.76,30V16.12c0-3.38,1.38-6.63,3.84-9.07l5.71-5.65c1.27-1.26,3.41-1.2,4.6,0.13l5.05,5.64c2.12,2.37,3.28,5.37,3.28,8.48V30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_szxh', html_3_6_szxh);
const html_3_6_tsljx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.5" y1="15" x2="29.5" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0.5" x2="15" y2="29.5"/>
</g>
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="5.31" y1="4.21" x2="24.69" y2="25.79"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="25.79" y1="5.31" x2="4.21" y2="24.69"/>
</g>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_tsljx', html_3_6_tsljx);
const html_3_6_tzfh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<circle fill='none' stroke='#780000' stroke-miterlimit='10' cx="15" cy="15" r="14.5"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0.5" y1="29.5" x2="29.5" y2="29.5"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_tzfh', html_3_6_tzfh);
const html_3_6_wdxg = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-width='1.5' stroke-miterlimit='10' x1="0.4" y1="11.26" x2="16.41" y2="11.26"/>
<path fill='none' stroke='#780000' stroke-width='1.5' stroke-miterlimit='10' d="M12.03,3.98l-7.8,20.7c-0.83,2.19,0.72,4.57,2.96,4.57h4.83"/>
<ellipse fill='none' stroke='#780000' stroke-miterlimit='10' cx="24.87" cy="4.93" rx="4.23" ry="4.43"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_wdxg', html_3_6_wdxg);
const html_3_6_yz = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="14.44,0.36 22.52,8.06 29.33,14.55 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="21.89" y1="7.46" x2="0.69" y2="29.65"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_yz', html_3_6_yz);
const html_3_6_zjjfh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='#780000'     points="20.59,2.5 15.02,19.55 9.39,2.5 0,2.5 0,27.5 5.83,27.5 5.83,7.82 11.97,27.5 18.01,27.5 24.18,7.82 24.18,27.5 30,27.5 30,2.5 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_zjjfh', html_3_6_zjjfh);
const html_3_6_zjt = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='none' stroke='#780000' stroke-miterlimit='10' points="1.29,18.66 8.91,10.79 21.69,10.79 28.87,18.66 			"/>
<polyline fill='none' stroke='#780000' stroke-miterlimit='10' points="8.91,10.79 15,18.1 21.69,10.79 			"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="3.47" x2="15" y2="11.14"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M2.31,21.01c3.64-0.05,26.04,0.01,26.04,0.01"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="26.53" x2="15" y2="20.99"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_zjt', html_3_6_zjt);
const html_3_6_zjx = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="0" y1="15" x2="30" y2="15"/>
<line fill='none' stroke='#780000' stroke-miterlimit='10' x1="15" y1="0" x2="15" y2="30"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_zjx', html_3_6_zjx);
const html_3_6_zxb = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M0.5,15c0-4.39,3.25-7.95,7.25-7.95S15,10.61,15,15"/>
<path fill='none' stroke='#780000' stroke-miterlimit='10' d="M29.5,15c0,4.39-3.25,7.95-7.25,7.95S15,19.39,15,15"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_zxb', html_3_6_zxb);
const html_3_6_zxfh = `<svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xml:space="preserve">
<g>
<polygon fill='#780000' points="0,-0.24 0,30.24 5.43,30.24 5.43,6.83 23.41,30.24 30,30.24 30,-0.24 24.57,-0.24 24.57,23.71 6.34,-0.24 		"/>
</g>
</svg>`;
Graph.registerHTMLComponent('3_6_zxfh', html_3_6_zxfh);
export const DqyqjNodes3 = new Map();
DqyqjNodes3.set('3_1_xl', {
    // width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight,
    markup: [{
        tagName: 'line',
        selector: 'line1',
        groupSelector: 'prop',
        attrs: {
            x1: '0', y1: '20', x2: '40', y2: '20',
            fill: 'none'
        },
    }],
    attrs: {
        prop: {
            stroke: '#780000',
            'stroke-width': 1,
        }
    },
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});

DqyqjNodes3.set('3_1_dllxy', {
    // width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight,
    markup: [{
        tagName: 'line',
        selector: 'line1',
        groupSelector: 'prop',
        attrs: {
            x1: '0', y1: '20', x2: '40', y2: '20',
            fill: 'none',

        },
    }, {
        tagName: 'polygon',
        selector: 'polygon1',
        groupSelector: 'prop',
        attrs: {
            points: '35,17 40,20 35,23',
            fill: '#780000'
        },
    },],
    attrs: {
        prop: {
            stroke: '#780000',
            'stroke-width': 1,
        },
    },
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
/*DqyqjNodes3.set('3_1_dllxz', {
		width: SVGSizeEnum.iWidth, height: SVGSizeEnum.iHeight,
		markup: [{
				tagName: 'polygon',
				selector: 'polygon1',
				groupSelector: 'prop',
				attrs: {
						points: '8.5,20.3 0,15 8.5,9.7',
						fill: '#780000'
				},
		}, {
				tagName: 'line',
				selector: 'line1',
				groupSelector: 'prop',
				attrs: {
						x1: '8.5', y1: '15', x2: '30', y2: '15',
						fill: 'none'
				},
		}],
		attrs: {
				prop: {
						stroke: '#780000',
						'stroke-width': 1,
				}
		},
		ports: {
				groups: {
						right: {
								position: 'right',
								attrs: {
										circle: {
												r: 2,
												magnet: true,
												stroke: '#5b8ffa',
												strokeWidth: 1,
												fill: '#780000',
												style: {
														visibility: 'hidden',
												},
										},
								},
						},
						left: {
								position: 'left',
								attrs: {
										circle: {
												r: 2,
												magnet: true,
												stroke: '#5b8ffa',
												strokeWidth: 1,
												fill: '#780000',
												style: {
														visibility: 'hidden',
												},
										},
								},
						},
				},
				items: [{
						group: 'right',
				}, {
						group: 'left',
				}]
		}
});
*/
DqyqjNodes3.set('3_1_dlq', {
    html: '3_1_dlq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_table', {
    html: '3_1_table',
    attrs: {
        body: {
            // style:{
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",
            rows: 2,
            cols: 2,
            // }
        },
        text: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",
            fontFamily: "微软雅黑",

        },
        label: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000"
        },

    },
    tableData: [{
        cols: [{
            id: "a7d64ee9-936d-4409-a640-ec334ea3a2d7",
            val: "1行1列"
        }, {
            id: "fe89d639-5a81-4f85-bfaf-d818c13950f8",
            val: "1行2列"
        }]
    }, {
        cols: [{
            id: "7eb3195e-9f9a-4006-8682-74c5307bd245",
            val: "2行1列"
        }, {
            id: "1fc0bb52-089c-47f6-8137-3534e08f3b0b",
            val: "2行2列"
        }]
    }],
});
DqyqjNodes3.set('3_1_dgtable', {
    html: '3_1_dgtable',
    attrs: {
        body: {
            // style:{
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",
            rows: 11,
            cols: 2,
            tableData: {
                Uab: 0.00,
                Ubc: 0.00,
                Uca: 0.00,
                Ia: 0.00,
                Ib: 0.00,
                Ic: 0.00,
                P: 0.00,
                Q: 0.00,
                PF: 0.00,
                F: 0.00,
                KWH: 0.00,
            },
            // }
        },
        text: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",

        },
        label: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000"
        },

    },
    tableData: {
        Uab: 0.00,
        Ubc: 0.00,
        Uca: 0.00,
        Ia: 0.00,
        Ib: 0.00,
        Ic: 0.00,
        P: 0.00,
        Q: 0.00,
        PF: 0.00,
        F: 0.00,
        KWH: 0.00,
    },
});
DqyqjNodes3.set('3_1_dbtable', {
    html: '3_1_dbtable',
    attrs: {
        body: {
            // style:{
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",
            rows: 3,
            cols: 2,
            tableData: {
                Ia: 0.00,
                Ib: 0.00,
                Ic: 0.00,
            },
            // }
        },
        text: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",

        },
        label: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000"
        },

    },
    tableData: {
        Ia: 0.00,
        Ib: 0.00,
        Ic: 0.00,
    },
});
DqyqjNodes3.set('3_1_sbzt', {
    html: '3_1_sbzt',
    values: 1,
});
DqyqjNodes3.set('3_1_dlqzt', {
    html: '3_1_dlqzt',
    values: 1,
});
DqyqjNodes3.set('3_1_jzsj', {
    html: '3_1_jzsj',
    attrs: {
        body: {
            // style:{
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",
            content: "键值数据",
            fontFamily: "Microsoft YaHei",
            keys: '数据项',
            values: '数据值',
            // }
        },
        text: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",
            fontFamily: "Microsoft YaHei",
        }, label: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000"
        }
    }
});
DqyqjNodes3.set('3_1_wzbq', {
    html: '3_1_wzbq',
    attrs: {
        body: {
            // style:{
            fontSize: 12,
            fontWeight: 400,
            color: "#780000",
            content: "文字标签",
            fontFamily: "Microsoft YaHei"
            // }
        },
        text: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000"
        }, label: {
            fontSize: 12,
            fontWeight: 400,
            color: "#780000"
        }
    }
});

DqyqjNodes3.set('3_1_sckg', {
    html: '3_1_sckg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibilpropsity: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_blq', {
    html: '3_1_blq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        },]
    }
});
DqyqjNodes3.set('3_1_glkg', {
    html: '3_1_glkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_jd', {
    html: '3_1_jd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_1_jdbh', {
    html: '3_1_jdbh',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_1_bzdr', {
    html: '3_1_bzdr',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_jlfdj', {
    html: '3_1_jlfdj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_1_zlfdj', {
    html: '3_1_zlfdj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            }
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_1_xhd', {
    html: '3_1_xhd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_1_srbzq', {
    html: '3_1_srbzq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_srzbyq', {
    html: '3_1_srzbyq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
        }, {
            id: 'port2',
            group: 'bottom',
            args: {
                x: 10,
                y: 40,
            },
        }, {
            id: 'port3',
            group: 'bottom',
            args: {
                x: 31,
                y: 40,
            },
        }]
    }
});
//新增元器件
DqyqjNodes3.set('3_1_dk', {
    html: '3_1_dk',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
        }, {
            id: 'port2',
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_dldgq', {
    html: '3_1_dldgq',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
            args: {
                x: 20,
                y: 0,
            },
        }, {
            id: 'port2',
            group: 'bottom',
            args: {
                x: 20,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_1_dxdlhgq', {
    html: '3_1_dxdlhgq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
        }, {
            id: 'port2',
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_jcqh', {
    html: '3_1_jcqh',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
            args: {
                x: 20,
                y: 0,
            },
        }, {
            id: 'port2',
            group: 'bottom',
            args: {
                x: 20,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_1_jcqk', {
    html: '3_1_jcqk',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
            args: {
                x: 20,
                y: 0,
            },
        }, {
            id: 'port2',
            group: 'bottom',
            args: {
                x: 20,
                y: 40,
            },
        }]
    }
});

DqyqjNodes3.set('3_1_lxdlhgq', {
    html: '3_1_lxdlhgq',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
            args: {
                x: 9,
                y: 0,
            },
        }, {
            id: 'port2',
            group: 'bottom',
            args: {
                x: 9,
                y: 40,
            },
        }, {
            id: 'port3',
            group: 'top',
            args: {
                x: 31,
                y: 0,
            },
        }, {
            id: 'port4',
            group: 'bottom',
            args: {
                x: 31,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_1_scbhdlq', {
    html: '3_1_scbhdlq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
        }, {
            id: 'port2',
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_schdlq', {
    html: '3_1_schdlq',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
            args: {
                x: 20,
                y: 0,
            },
        }, {
            id: 'port2',
            group: 'bottom',
            args: {
                x: 20,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_1_srzbyq1', {
    html: '3_1_srzbyq1',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
        }, {
            id: 'port2',
            group: 'bottom',
            args: {
                x: 12,
                y: 40,
            },
        }, {
            id: 'port3',
            group: 'bottom',
            args: {
                x: 28,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_1_srzbyq2', {
    html: '3_1_srzbyq2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
        }, {
            id: 'port2',
            group: 'bottom',
            args: {
                x: 12,
                y: 40,
            },
        }, {
            id: 'port3',
            group: 'bottom',
            args: {
                x: 28,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_1_srzbyq3', {
    html: '3_1_srzbyq3',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
            args: {
                x: 12,
                y: 0,
            },
        }, {
            id: 'port2',
            group: 'top',
            args: {
                x: 28,
                y: 0,
            },
        }, {
            id: 'port3',
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_srzdyhgq', {
    html: '3_1_srzdyhgq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            }
        },
        items: [{
            id: 'port1',
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_1_surzbyq1', {
    html: '3_1_surzbyq1',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
        }, {
            id: 'port3',
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_1_surzdyhgq', {
    html: '3_1_surzdyhgq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
        }]
    }

});
DqyqjNodes3.set('3_1_sxdlhgq', {
    html: '3_1_sxdlhgq',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'top',
            args: {
                x: 20,
                y: 9,
            },
        }, {
            id: 'port3',
            group: 'bottom',
            args: {
                x: 20,
                y: 31,
            },
        }]
    }
});
DqyqjNodes3.set('3_1_wgbcq', {
    html: '3_1_wgbcq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
})
;

DqyqjNodes3.set('3_2_apbzhkg', {
    html: '3_2_apbzhkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_bxs', {
    html: '3_2_bxs',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});

DqyqjNodes3.set('3_2_bdgq', {
    html: '3_2_bdgq',
    ports: {
        groups: {
            left: {
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
                position: {
                    name: 'absolute',
                },
            },
        },
        items: [{
            id: 'port1',
            group: 'left',
            args: {
                x: 0,
                y: 0,
            },
        }, {
            id: 'port2',
            group: 'left',
            args: {
                x: 0,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_bxs2', {
    html: '3_2_bxs2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_bxs3', {
    html: '3_2_bxs3',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_bxsIEC', {
    html: '3_2_bxsIEC',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_bxsIEEE', {
    html: '3_2_bxsIEEE',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_bxsobsolete', {
    html: '3_2_bxsobsolete',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_bzdhg', {
    html: '3_2_bzdhg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_cgq', {
    html: '3_2_cgq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_2_cgq2', {
    html: '3_2_cgq2',
    ports: {
        groups: {

            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },

        },
        items: [{
            group: 'bottom',
            args: {
                x: 26,
                y: 40
            }
        }]
    }
});
DqyqjNodes3.set('3_2_cl', {
    html: '3_2_cl',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
            args: {
                x: 38,
                y: 20,
            },

        }, {
            group: 'bottom',
        }, {
            group: 'left',
            args: {
                x: 2,
                y: 20,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_cx', {
    html: '3_2_cx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_d', {
    html: '3_2_d',
    ports: {
        groups: {
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
            args: {
                x: 13,
                y: 40,
            },
        }, {
            group: 'bottom',
            args: {
                x: 28,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_d2', {
    html: '3_2_d2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_d3', {
    html: '3_2_d3',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_d4', {
    html: '3_2_d4',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dc', {
    html: '3_2_dc',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});


DqyqjNodes3.set('3_2_ddj', {
    html: '3_2_ddj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dds', {
    html: '3_2_dds',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        },]
    }
});
DqyqjNodes3.set('3_2_dgq', {
    html: '3_2_dgq',
    ports: {
        groups: {
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },

            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },

            },

        },
        items: [{
            group: 'right',
            args: {
                x: 1,
                y: 28,
            }
        }, {
            group: 'left',
            args: {
                x: 39,
                y: 28,
            }
        }]
    }
});
DqyqjNodes3.set('3_2_dhgdhq', {
    html: '3_2_dhgdhq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dhs', {
    html: '3_2_dhs',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dj', {
    html: '3_2_dj',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dl', {
    html: '3_2_dl',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dlb', {
    html: '3_2_dlb',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dljhq', {
    html: '3_2_dljhq',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_drq', {
    html: '3_2_drq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_drq2', {
    html: '3_2_drq2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_drq3', {
    html: '3_2_drq3',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_drq4', {
    html: '3_2_drq4',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',

        }, {
            group: 'right',
            args: {
                x: 0,
                y: 28,
            },
        }, {
            group: 'left',
            args: {
                x: 40,
                y: 28,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_dwjdzq', {
    html: '3_2_dwjdzq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});

DqyqjNodes3.set('3_2_dy', {
    html: '3_2_dy',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dyb', {
    html: '3_2_dyb',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dybhq', {
    html: '3_2_dybhq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});

DqyqjNodes3.set('3_2_dybyq', {
    html: '3_2_dybyq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_dybhq2', {
    html: '3_2_dybhq2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_dlq', {
    html: '3_2_dlq',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
            args: {
                x: 16,
                y: 0,
            },
        }, {
            group: 'bottom',
            args: {
                x: 16,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_dzq', {
    html: '3_2_dzq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_ejg', {
    html: '3_2_ejg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_ejgLED', {
    html: '3_2_ejgLED',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
            args: {
                x: 23,
                y: 0,
            },
        }, {
            group: 'bottom',
            args: {
                x: 23,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_ejgLED2', {
    html: '3_2_ejgLED2',
    ports: {
        groups: {
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
            args: {
                x: 0,
                y: 24,
            },
        }, {
            group: 'left',
            args: {
                x: 40,
                y: 24,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_fdj', {
    html: '3_2_fdj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_fmq', {
    html: '3_2_fmq',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_ftbzhkg', {
    html: '3_2_ftbzhkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_gyljdhg', {
    html: '3_2_gyljdhg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_gzljd', {
    html: '3_2_gzljd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            }

        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_2_hldy', {
    html: '3_2_hldy',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_hltx', {
    html: '3_2_hltx',
    ports: {
        groups: {
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
            args: {
                x: 15,
                y: 40,
            },
        }, {
            group: 'bottom',
            args: {
                x: 25,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_hxtx', {
    html: '3_2_hxtx',
    ports: {
        groups: {
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
            args: {
                x: 15,
                y: 40,
            },
        }, {
            group: 'bottom',
            args: {
                x: 25,
                y: 40,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_jcmc', {
    html: '3_2_jcmc',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
            args: {
                x: 0,
                y: 25,
            },
        }, {
            group: 'left',
            args: {
                x: 0,
                y: 30,
            },
        },]
    }
});
DqyqjNodes3.set('3_2_hz', {
    html: '3_2_hz',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_jd', {
    html: '3_2_jd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_2_jdbh', {
    html: '3_2_jdbh',
    ports: {
        groups: {
            top: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
            args: {
                x: 20,
                y: 4,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_jk', {
    html: '3_2_jk',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_2_jldy', {
    html: '3_2_jldy',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_jrq', {
    html: '3_2_jrq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_2_jsq', {
    html: '3_2_jsq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_jt', {
    html: '3_2_jt',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_jtdzq', {
    html: '3_2_jtdzq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_jtmc', {
    html: '3_2_jtmc',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_jxh', {
    html: '3_2_jxh',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_jyhs', {
    html: '3_2_jyhs',
    ports: {
        groups: {
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
            args: {
                x: 30,
                y: 1,
            },
        }, {
            group: 'left',
            args: {
                x: 10,
                y: 39,
            },
        }]
    }
});
DqyqjNodes3.set('3_2_kbdzq', {
    html: '3_2_kbdzq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_kg', {
    html: '3_2_kg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_kyq', {
    html: '3_2_kyq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_kyq2', {
    html: '3_2_kyq2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },

        },
        items: [{
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_2_lxdy', {
    html: '3_2_lxdy',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_mc', {
    html: '3_2_mc',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_nhd', {
    html: '3_2_nhd',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_pdb', {
    html: '3_2_pdb',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_ptzj', {
    html: '3_2_ptzj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_qzzh', {
    html: '3_2_qzzh',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },

        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_rdd', {
    html: '3_2_rdd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },

        },
        items: [{
            group: 'top',
        }, {
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_2_rdo', {
    html: '3_2_rdo',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },

        },
        items: [{
            group: 'top',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_ryj', {
    html: '3_2_ryj',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        },]
    }
});
DqyqjNodes3.set('3_2_sbq', {
    html: '3_2_sbq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_sjq', {
    html: '3_2_sjq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_syt', {
    html: '3_2_syt',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_tiex', {
    html: '3_2_tiex',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_tx', {
    html: '3_2_tx',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_tx2', {
    html: '3_2_tx2',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_tzf', {
    html: '3_2_tzf',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_2_wdj', {
    html: '3_2_wdj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_wgj', {
    html: '3_2_wgj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_xdc', {
    html: '3_2_xdc',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_xhjd', {
    html: '3_2_xhjd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_ybb', {
    html: '3_2_ybb',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_yct', {
    html: '3_2_yct',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_ycyj', {
    html: '3_2_ycyj',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_ygd', {
    html: '3_2_ygd',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_yj', {
    html: '3_2_yj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_ykz', {
    html: '3_2_ykz',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_ysdwj', {
    html: '3_2_ysdwj',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_ysdzq', {
    html: '3_2_ysdzq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_ysq', {
    html: '3_2_ysq',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }, {
            group: 'left',
        }, {
            group: 'right',
        }, {
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_2_zhkg', {
    html: '3_2_zhkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_zjxh', {
    html: '3_2_zjxh',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_zldy', {
    html: '3_2_zldy',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },

        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_2_zmdp', {
    html: '3_2_zmdp',
    ports: {
        groups: {

            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_2_zsq', {
    html: '3_2_zsq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_2dtdl', {
    html: '3_3_2dtdl',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_bq', {
    html: '3_3_bq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_csd', {
    html: '3_3_csd',
    ports: {
        groups: {

            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },

        },
        items: [{
            group: 'bottom',
        },]
    }
});
DqyqjNodes3.set('3_3_cslj', {
    html: '3_3_cslj',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_cslj2', {
    html: '3_3_cslj2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_cslj3', {
    html: '3_3_cslj3',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_cslj4', {
    html: '3_3_cslj4',
    ports: {
        groups: {
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
            args: {
                x: 40,
                y: 23,
            },
        }, {
            group: 'left',
            args: {
                x: 0,
                y: 23,
            },
        }]
    }
});
DqyqjNodes3.set('3_3_dbxl', {
    html: '3_3_dbxl',
    ports: {
        groups: {
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
            args: {
                x: 40,
                y: 24.5,
            },
        }, {
            group: 'left',
            args: {
                x: 0,
                y: 24.5,
            },
        }]
    }
});
DqyqjNodes3.set('3_3_dhxl', {
    html: '3_3_dhxl',
    ports: {
        groups: {
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
            args: {
                x: 40,
                y: 24.5,
            },
        }, {
            group: 'left',
            args: {
                x: 0,
                y: 24.5,
            },
        }]
    }
});
DqyqjNodes3.set('3_3_dlz', {
    html: '3_3_dlz',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_3_dlzd', {
    html: '3_3_dlzd',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_dx', {
    html: '3_3_dx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_3_dx2', {
    html: '3_3_dx2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_dxdl', {
    html: '3_3_dxdl',
    ports: {
        groups: {
            right: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: {
                    name: 'absolute',
                },
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
            args: {
                x: 40,
                y: 23,
            },
        }, {
            group: 'left',
            args: {
                x: 0,
                y: 23,
            },
        }]
    }
});
DqyqjNodes3.set('3_3_dxdlan', {
    html: '3_3_dxdlan',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_dxzx', {
    html: '3_3_dxzx',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_dxzxz', {
    html: '3_3_dxzxz',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_dz', {
    html: '3_3_dz',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_dzb', {
    html: '3_3_dzb',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_dzlb', {
    html: '3_3_dzlb',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_fldsb', {
    html: '3_3_fldsb',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_fzdl', {
    html: '3_3_fzdl',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_gjwk', {
    html: '3_3_gjwk',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_3_gq', {
    html: '3_3_gq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_hddl', {
    html: '3_3_hddl',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_jhd', {
    html: '3_3_jhd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_jhdjcd', {
    html: '3_3_jhdjcd',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_jxq', {
    html: '3_3_jxq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_lfxy', {
    html: '3_3_lfxy',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_lfxz', {
    html: '3_3_lfxz',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_sixxl', {
    html: '3_3_sixxl',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_sixxl', {
    html: '3_3_sixxl',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_spxl', {
    html: '3_3_spxl',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_sxdjsb', {
    html: '3_3_sxdjsb',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_sxxl', {
    html: '3_3_sxxl',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_3_xnlx', {
    html: '3_3_xnlx',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_xwlx', {
    html: '3_3_xwlx',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_yxz', {
    html: '3_3_yxz',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_zbzx', {
    html: '3_3_zbzx',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_3_zbzx2', {
    html: '3_3_zbzx2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_3_zxkd', {
    html: '3_3_zxkd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_3_zzx', {
    html: '3_3_zzx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_4_edjlkgyj', {
    html: '3_4_edjlkgyj',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_ejg', {
    html: '3_4_ejg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_ejg2', {
    html: '3_4_ejg2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_gualscxyjtgn', {
    html: '3_4_gualscxyjtgn',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_gualscxyjtgp', {
    html: '3_4_gualscxyjtgp',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_jfetnpn', {
    html: '3_4_jfetnpn',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_jfetpnp', {
    html: '3_4_jfetpnp',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_jsyhwbdtcxyjtg', {
    html: '3_4_jsyhwbdtcxyjtg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_jtgnpn', {
    html: '3_4_jtgnpn',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});

DqyqjNodes3.set('3_4_jtgnpn2', {
    html: '3_4_jtgnpn2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});

DqyqjNodes3.set('3_4_jtgnpncx', {
    html: '3_4_jtgnpncx',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_jtgpnp', {
    html: '3_4_jtgpnp',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_jtgpnp2', {
    html: '3_4_jtgpnp2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_jtgpnpcx', {
    html: '3_4_jtgpnpcx',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_mosfentpxjj', {
    html: '3_4_mosfentpxjj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_mosfet', {
    html: '3_4_mosfet',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_mosfetgytdn', {
    html: '3_4_mosfetgytdn',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_mosfetgytdp', {
    html: '3_4_mosfetgytdp',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_mosfetn', {
    html: '3_4_mosfetn',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_mosfetnxjj', {
    html: '3_4_mosfetnxjj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_4_scejg', {
    html: '3_4_scejg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_3jwglkg', {
    html: '3_5_3jwglkg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_3jwkg', {
    html: '3_5_3jwkg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_3jwkg2', {
    html: '3_5_3jwkg2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_3jwxzkg', {
    html: '3_5_3jwxzkg',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_4jwkg', {
    html: '3_5_4jwkg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_ajbh', {
    html: '3_5_ajbh',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_ajdlq', {
    html: '3_5_ajdlq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_ajedl', {
    html: '3_5_ajedl',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_aqlszz', {
    html: '3_5_aqlszz',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_bcyw', {
    html: '3_5_bcyw',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_bhcd', {
    html: '3_5_bhcd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_chkg', {
    html: '3_5_chkg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_dlq1p', {
    html: '3_5_dlq1p',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_dlqcd', {
    html: '3_5_dlqcd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_dlxzq', {
    html: '3_5_dlxzq',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            }
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_dpdt', {
    html: '3_5_dpdt',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_dpst', {
    html: '3_5_dpst',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_eldl', {
    html: '3_5_eldl',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_glq1p', {
    html: '3_5_glq1p',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_jbhcd', {
    html: '3_5_jbhcd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_jdq', {
    html: '3_5_jdq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_jdqcd', {
    html: '3_5_jdqcd',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_jdqxq', {
    html: '3_5_jdqxq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_jjkg', {
    html: '3_5_jjkg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_kg1p', {
    html: '3_5_kg1p',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_kgcdq1p', {
    html: '3_5_kgcdq1p',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_kgdlq', {
    html: '3_5_kgdlq',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_ljxzkg', {
    html: '3_5_ljxzkg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_qdddl', {
    html: '3_5_qdddl',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_qlqd', {
    html: '3_5_qlqd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_sdkg', {
    html: '3_5_sdkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_sdkg1p', {
    html: '3_5_sdkg1p',
    ports: {
        groups: {

            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_sjwglkg', {
    html: '3_5_sjwglkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_sjwkg', {
    html: '3_5_sjwkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_sjwkg2', {
    html: '3_5_sjwkg2',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_sjwzhkg', {
    html: '3_5_sjwzhkg',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_spdt', {
    html: '3_5_spdt',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }, {
            group: 'right',
        }, {
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_5_spst', {
    html: '3_5_spst',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }, {
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_5_sxcd', {
    html: '3_5_sxcd',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }, {
            group: 'top',
        }, {
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_sydldl', {
    html: '3_5_sydldl',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }, {
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_sydlq', {
    html: '3_5_sydlq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_sydlq2', {
    html: '3_5_sydlq2',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }, {
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_sykg', {
    html: '3_5_sykg',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }, {
            group: 'top',
        }, {
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_sykg2', {
    html: '3_5_sykg2',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }, {
            group: 'bottom',
        }, {
            group: 'top',
        }, {
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_thcd', {
    html: '3_5_thcd',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }, {
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_5_thfw2', {
    html: '3_5_thfw2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_thfw3', {
    html: '3_5_thfw3',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_twq', {
    html: '3_5_twq',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_wdkg', {
    html: '3_5_wdkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_wdqd', {
    html: '3_5_wdqd',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_xzkg', {
    html: '3_5_xzkg',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_xzkgnc', {
    html: '3_5_xzkgnc',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_xzkgno', {
    html: '3_5_xzkgno',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_xzqkg', {
    html: '3_5_xzqkg',
    ports: {
        groups: {
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_ylqd', {
    html: '3_5_ylqd',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_ymqd', {
    html: '3_5_ymqd',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_ymqd2', {
    html: '3_5_ymqd2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_5_yskg', {
    html: '3_5_yskg',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_5_zhcd', {
    html: '3_5_zhcd',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_3gflrz', {
    html: '3_6_3gflrz',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_6_cl1', {
    html: '3_6_cl1',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_dx', {
    html: '3_6_dx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#780000',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_dxxh', {
    html: '3_6_dxxh',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_fb', {
    html: '3_6_fb',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_fjx', {
    html: '3_6_fjx',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_fs', {
    html: '3_6_fs',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_jcb', {
    html: '3_6_jcb',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_jcb2', {
    html: '3_6_jcb2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_jfb', {
    html: '3_6_jfb',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_jld', {
    html: '3_6_jld',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_jyhs2', {
    html: '3_6_jyhs2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_lxjcs', {
    html: '3_6_lxjcs',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_lxljx', {
    html: '3_6_lxljx',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_lxsix', {
    html: '3_6_lxsix',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_lxssjx', {
    html: '3_6_lxssjx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_lxsx', {
    html: '3_6_lxsx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_6_lxsxx', {
    html: '3_6_lxsxx',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },

        },
        items: [{
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_mc2', {
    html: '3_6_mc2',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_mnxh', {
    html: '3_6_mnxh',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_ryfxxh', {
    html: '3_6_ryfxxh',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_sx', {
    html: '3_6_sx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_sxsjlj', {
    html: '3_6_sxsjlj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_6_sxsjlj2', {
    html: '3_6_sxsjlj2',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_sxsjlj3', {
    html: '3_6_sxsjlj3',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_6_sxsjlj4', {
    html: '3_6_sxsjlj4',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_sxsx', {
    html: '3_6_sxsx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_sxtx', {
    html: '3_6_sxtx',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_sxvx', {
    html: '3_6_sxvx',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_sxxh', {
    html: '3_6_sxxh',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_sxxxlj', {
    html: '3_6_sxxxlj',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_6_sxzzrz', {
    html: '3_6_sxzzrz',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }]
    }
});
DqyqjNodes3.set('3_6_szxh', {
    html: '3_6_szxh',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_6_tsljx', {
    html: '3_6_tsljx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_tzfh', {
    html: '3_6_tzfh',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_6_wdxg', {
    html: '3_6_wdxg',
    ports: {
        groups: {
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_yz', {
    html: '3_6_yz',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }]
    }
});
DqyqjNodes3.set('3_6_zjjfh', {
    html: '3_6_zjjfh',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_zjt', {
    html: '3_6_zjt',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_zjx', {
    html: '3_6_zjx',
    ports: {
        groups: {
            top: {
                position: 'top',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            bottom: {
                position: 'bottom',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }, {
            group: 'top',
        }, {
            group: 'bottom',
        }]
    }
});
DqyqjNodes3.set('3_6_zxb', {
    html: '3_6_zxb',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
DqyqjNodes3.set('3_6_zxfh', {
    html: '3_6_zxfh',
    ports: {
        groups: {
            right: {
                position: 'right',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
            left: {
                position: 'left',
                attrs: {
                    circle: {
                        r: 2,
                        magnet: true,
                        stroke: '#5b8ffa',
                        strokeWidth: 1,
                        fill: '#780000',
                        style: {
                            visibility: 'hidden',
                        },
                    },
                },
            },
        },
        items: [{
            group: 'right',
        }, {
            group: 'left',
        }]
    }
});
