<template>
  <div class="DialogAddOrder">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="60%"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div v-show="getType===1" class="equip_title">新增工单</div>
        <div v-show="getType===2" class="equip_title">编辑工单</div>
      </div>

      <el-form :model="form" class="elForm" :rules="rules" ref="ruleForm" >
          <el-form-item label="报修人"  style="width: 23%;">
             <button class="btnForMeters" size="small"   @click.prevent='choosePerson'>{{card.person}}</button>
          </el-form-item>

          <el-form-item label="所在部门" style="width: 23%;" >
            <el-input disabled  class="disabled_input" style="text-align:center" :placeholder="card.department" size="small" ></el-input>
          </el-form-item>

          <el-form-item label="报修人电话"  style="width: 23%;">
            <el-input disabled  class="disabled_input"  style="text-align:center" :placeholder="card.personTel" size="small" ></el-input>
          </el-form-item>
          <el-form-item label="部门电话"  style="width: 23%;">
            <el-input disabled  class="disabled_input"  style="text-align:center" :placeholder="card.deptTel" size="small" ></el-input>
          </el-form-item>

          <el-form-item label="报修设备"  style="width: 23%;">
             <button class="btnForMeters" size="small"  @click.prevent='chooseEquipment'>{{card.equipment}}</button>
          </el-form-item>

           <el-form-item label="所属配电房/电站" style="width: 23%;" >
            <el-input disabled  class="disabled_input" style="text-align:center" :placeholder="card.placeName" size="small" ></el-input>
          </el-form-item>

           <el-form-item label="所属客户" style="width: 23%;" >
            <el-input disabled  class="disabled_input" style="text-align:center" :placeholder="card.company" size="small" ></el-input>
          </el-form-item>

           <el-form-item label="设备型号" style="width: 23%;" >
            <el-input disabled  class="disabled_input" style="text-align:center" :placeholder="card.model" size="small" ></el-input>
          </el-form-item>
          
           <el-form-item label="故障主题" prop="title" style="width: 100%;">
            <el-input v-model="form.title" placeholder="请简要概括" size="small"  clearable></el-input>
          </el-form-item>

          <el-form-item label="报修地址" prop="address" style="width: 100%;">
            <el-input v-model="form.address" placeholder="请输入地址" size="small"  clearable></el-input>
          </el-form-item>

          <el-form-item label="故障描述" prop="content" style="width: 100%;">
            <el-input type="textarea" v-model="form.content" placeholder="请详细描述故障细节" size="small"  clearable></el-input>
          </el-form-item>
<!-- 上传图片####################################################################################################################### -->
          <el-form-item label="上传图片" prop="remark" style="width: 100%;"></el-form-item>
            <el-upload
              :file-list="fileList"
              :headers="myToken"
              :action="baseURL+'file/upload'"
              :on-success="upLoadSuccess"
              :on-error="upLoadError"
              :on-exceed="handelExceed"
              disabled:false
              :auto-upload="true"
              list-type="picture-card"
              :limit="3"
              >
                <i slot="default" class="el-icon-plus"></i>
                
                <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">

                    <!-- 预览大图 -->
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                       <i>预览</i>
                    </span>
                    <!-- 删除图片 -->
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                       <i>删除</i>
                    </span>

                  </span>
                </div>
            </el-upload>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleForm')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleForm')">保 存</button>
      </div>

    
   
    </el-dialog>
    <el-dialog :visible.sync="dialogForPictureVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <DialogForChoosePerson ref="DialogRef"/>
    <DialogForChooseEquipment ref="EquipmentRef"/>
  </div>
</template>

<script>
import config from '@/config/index.js'
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import { validateInput} from "@/utils/validate";
import DialogForChoosePerson from "./DialogForChoosePerson.vue"
import DialogForChooseEquipment from "./DialogForChooseEquipment.vue"
import {getCookie} from "@/utils/util.js"
// import {getCookie} from '@/utils/util.js'
export default {
  name: "DialogAddOrder",
  mixins: [tableBg],
  components:{DialogForChoosePerson,DialogForChooseEquipment},
  data() {
    return {
      // 图片列表
      fileList:[],
      // 图片名称列表
      fileNameList:[],
      // 是否展示预览大图
      dialogForPictureVisible:false,
      // 是否禁用图片的添加
      disabled: false,
      // 图片的双向绑定
      dialogImageUrl: '',
      // 是否从告警页面过来
      alarm:false,
      // baseURL
      baseURL:'',
      // 类型列表 
      typeData:[],
      // 请求类型（编辑=2，登记=1）
      getType: 0,
      // 展示判断
      dialogFormVisible: false,
  
      // 表单数据存放处
      form: {id:"",reportUserId:"",title:"",address:"",content:"",deptId:"",soReportImages:"",deviceId:"",deviceName:"",locationDeviceType:"",placeName:"",company:"",model:""},
      tableList: [],
      // 校验规则
      rules: {   
        reportUserId:[validateInput],
        title:[validateInput],
        address:[validateInput],
        content:[validateInput],
        deptId:[validateInput],
      },
      // 报修人姓名双向绑定处
      card:{
        person:"请选择报修人",
        department:"",
        personTel:"",
        deptTel:"",
        equipment:"请选择报修设备",
        placeName:"",
        company:"",
        model:"",
      },
      //令牌
      myToken:{Authorization: "没有拿到token"},
      
      
    
    };
  },

  methods: {
    // 子弹层关闭后获取数据
    getMyData(val){
        // 展示用数据
        this.card.person = val.name
        this.card.department = val.deptName
        this.card.personTel = val.mobile
        this.card.deptTel = val.deptMobile
        // 对接用数据
        this.form.reportUserId = val.userId
        this.form.deptId = val.deptId 
    },
    // 获取保修位置的子弹层关闭后获取的数据
    myEquipmentData(val){
      // 表现层的渲染
      this.card.equipment  = val.name
      this.card.placeName = val.placeName 
      this.card.company = val.company
      this.card.model = val.model
      // 数据层的对接
      this.form.deviceId = val.id
      this.form.deviceName = val.name
      this.form.placeName = val.placeName
      this.form.company = val.company 
      this.form.model = val.model
      this.form.locationDeviceType = val.locationDeviceType
      // console.log(this.form)
    },

    // dialog关闭的回调
    closeDialog(ruleForm) {
      this.formInit();
      this.$refs[ruleForm].resetFields();
      
    },

    // 取消弹框
    handleCancle(ruleName) {
      this.$refs[ruleName].resetFields();
      this.dialogFormVisible = false;
      this.formInit();
    },

    // from归零
    formInit() {
      this.fileList=[]
      this.form = {id:"",reportUserId:"",title:"",address:"",content:"",deptId:"",soReportImages:"",deviceId:"",deviceName:"",locationDeviceType:"",placeName:"",company:"",model:""};
      this.card={person:"请选择报修人",department:"",personTel:"",deptTel:"",equipment:"请选择报修设备", placeName:"",company:"",model:"",}
    },
   
    //父组件点击触发
    show() {
      this.dialogFormVisible = true
      this.getType = 1
      // getCookie('id')
    },

    // 从告警触发
    showForAlarm(val){
      this.dialogFormVisible = true
      this.getType = 1
      this.form.address = val
      this.alarm = true
    },

    //父组件触发来进行编辑
    showForEdit(val) {
      this.dialogFormVisible = true
      this.getType = 2
      // 渲染表面数据
      this.card.person = val.reportUserName
      this.card.department = val.deptName
      this.card.personTel = val.repairPhone
      this.card.deptTel = val.deptPhone
      this.card.equipment  = val.deviceName
      this.card.placeName = val.placeName 
      this.card.company = val.company
      this.card.model = val.model
      // 交互数据
      this.form.reportUserId = val.reportUserId
      this.form.title = val.title
      this.form.address = val.address
      this.form.content = val.content
      this.form.deptId = val.deptId
      this.form.id = val.id
      this.form.soReportImages = val.soReportImages
      this.form.deviceId = val.deviceId
      this.form.deviceName = val.deviceName
      this.form.placeName = val.placeName
      this.form.company = val.company
      this.form.model = val.model

      // 图片列表的数据回显
      this.fileList=[]
      this.fileNameList=[]
      if(val.soReportImages){
        const midList = val.soReportImages.split(",")
        
        // this.fileNameList = midList
          for(let i=0;i<midList.length;i++){
          const fileSingel = {url:midList[i]}
          this.fileList.push(fileSingel)   
          }  

        // 去除多余的http
        for(let i=0;i<midList.length;i++){
          const midSingel = midList[i].split('info/')[1]
         
          this.fileNameList.push(midSingel)
        }  
      }
     
      
    },


    // 提交发送请求（一级dialog点击《保存》）
    postDialogData(ruleName) {
      // 对form的预处理(切割图片地址串，去除最后一个逗号)
      this.form.soReportImages = ""
      for(let i=0;i<this.fileNameList.length;i++){
         this.form.soReportImages += this.fileNameList[i]
         if(i!==this.fileNameList.length-1){this.form.soReportImages += ","}        
      }
      // this.form.soReportImages = this.form.soReportImages.substring(0,this.form.soReportImages.length-1)
      this.$refs[ruleName].validate(valid => {
        if (valid) {
          // 发送新增请求
          if (this.getType === 1) {
            this.$store.dispatch("postRepairOrderManagementAddOrder", this.form).then(() => {
              if (this.ifpostRepairOrderManagementAddOrderSuccess === 0) {
                this.$message({ message: "添加成功！", type: "success" });
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePage();
                this.$bus.$emit('RepairOrderManagementRefreshTabCount')
                this.dialogFormVisible = false;
                this.formInit()
                // 是否刷新告警页面
                if(this.alarm){this.$bus.$emit('elecGenerateAlarmWarningRefreshThePage')}
              } else if (this.ifpostRepairOrderManagementAddOrderSuccess === -1) {
                this.$message({
                  message: this.ifpostRepairOrderManagementAddOrderError,
                  type: "warning"
                });
              }
            });
          } else if (this.getType === 2) {
            // 发送编辑请求
            this.$store.dispatch("putRepairOrderManagementEditOrder", this.form).then(() => {
              if (this.ifEditRepairOrderManagementOrderSuccess === 0) {
                this.$message({ message: "编辑成功！", type: "success" });
                this.dialogFormVisible = false;
                this.$refs[ruleName].resetFields();
                this.$parent.refreshThePage();          
                this.formInit()
              } else if (this.ifEditRepairOrderManagementOrderSuccess === -1) {
                this.$message({
                  message: this.ifEditRepairOrderManagementOrderError,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$message.error("有必填项未填");
        }
       
      });
    },

    // 选择报修人
    choosePerson(){
      this.$refs.DialogRef.show()
    },

    // 选择报修设备
    chooseEquipment(){
      this.$refs.EquipmentRef.show()
    },

    // 删除图片
    handleRemove(file) {
      for(let i=0;i<this.fileList.length;i++){
        if(this.fileList[i].uid===file.uid){
          this.fileList.splice(i,1)
          this.fileNameList.splice(i,1)
          }
      }
      
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogForPictureVisible = true;
    },

    // 上传图片成功
    upLoadSuccess(val){
      this.$message.success("上传图片成功！")
      // this.form.soReportImages += val.data
      // this.form.soReportImages += ","
      this.fileNameList.push(val.data)
    },

    // 文件传输失败
    upLoadError(val){
      this.$message.error(val.message)
    },

    // 超出三张图片时的钩子
    handelExceed(){
      this.$message.error("最多只能上传三张图片！")
    },


  },
  //处理state数据
  computed: {
    ...mapState({
      // 获取下拉列表
      // repairWorkStandardTypeAll:state=>{return state.repair.repairWorkStandardTypeAll.data},

      // 新增
      ifpostRepairOrderManagementAddOrderSuccess: state => {return state.repair.ifpostRepairOrderManagementAddOrderSuccess.errorCode;},
      ifpostRepairOrderManagementAddOrderError:state => {return state.repair.ifpostRepairOrderManagementAddOrderSuccess.message;},

      // 编辑
      ifEditRepairOrderManagementOrderSuccess: state => {return state.repair.ifEditRepairOrderManagementOrderSuccess.errorCode;},
      ifEditRepairOrderManagementOrderError:state => {return state.repair.ifEditRepairOrderManagementOrderSuccess.message;},
      
    })
  },

  created(){
    this.$bus.$off('repairOrderManagementShowForAlarm')
  },
  mounted(){
    this.baseURL = config.baseURL
    this.$bus.$on('repairOrderManagementShowForAlarm',this.showForAlarm)
    this.myToken={Authorization: "Bearer "+getCookie("token")}
  },

  



  
};




</script>

<style lang="less" scoped>
.DialogAddOrder{
  overflow: hidden;
  .el-dialog__body{
    height: 60rem;
  }
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
// 树所在的区域###################
.treePlace{
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 80rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  
  
  // border: #071828 0.2rem solid;
}
.treePlaceGoAway{
  // opacity: 0;
  display: none;
  // position:relative;
  // top: -54rem;
  // left: 80rem;
  height: 0;
  width: 26rem;
  overflow: hidden;
  // display: none;
  transition: height 0.3s;  
}

.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}
.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}

</style>




<style lang="less">
.DialogAddOrder{
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  .el-table::before {
      opacity: 0;
  }
.el-textarea__inner {
  color: #c0c4cc;
  background-color: #0b3d6b;
  border: 0.1rem solid #004579;
}
.el-textarea__inner:hover {
    border-color: #0b3d6b;
}

.el-textarea__inner {
    color: #fff;
    background-color: #0b3d6b;
    border: 1px solid #0b3d6b;

}

.el-upload--picture-card {
    background-color: #0b3d6a;
    border: 2px dashed #115D96;
    
}
  
}

</style>



