export default (graph) => {
	graph.on('edge:connected', (args) => {
		const edge = args.edge;
		const node = args.currentCell;
		const elem = args.currentMagnet;
		if (elem) {
			const portId = elem.getAttribute('port');

			// 触发 port 重新渲染
			// node.setPortProp(portId, 'connected', true);
			edge.zIndex = -1;
			// 更新连线样式
			edge.attr({
				router: {
					name: 'orth', // 'orth',// 拐线，
					args: {
						// padding: 1
					}
				},
				line: {
					strokeDasharray: "",
					stroke: "#000",
					strokeWidth: 1,
					// targetMarker: 'classic',
					// 修改无箭头
					targetMarker: null,/*{
					name: "block",
					width: 1,x
					height: 1
				}*/
				},
			});
			let pathObjectDom = document.querySelectorAll(`g[data-cell-id="${edge.id}"] path`);
			let pathData = pathObjectDom[0].getAttribute("d");

			edge.attr({
				path: {
					isVerticalLine: true,
					d: pathData
				}
			});

/*			for (var i = 0; i < pathObjectDom.length; i++) {
				pathObjectDom[i].setAttribute("d");
			}*/

			edge.appendLabel({
				attrs: {
					label: {
						text: ""
					}
				}
			});
		}
	});
}
