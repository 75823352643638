<template>
    <div class="mon-ener-state">
        <div class="title1">
            <span class="text1">当前电站:</span>
            <span class="text2">{{ powerData2[0].value!="0"?powerData2[0].value:"" }}</span>
            <el-select size="mini" class="search" v-model="inverterName" placeholder="请选择电站">
                <el-option v-for="item in optionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </div>
        <div class="scroll-box">
            <div class="scroll-box-top">
                <div class="ener-state">
                    <div class="top">
                        <span class="title">实时发电功率</span>
                    </div>

                    <div class="chart">
                        <MapEcharts1 ref="lineCharts1" class="chart1" :powerObj="{power, ymax}" />
                    </div>
                </div>
                <div class="ener-info1">
                    <span class="title">基本信息</span>
                    <div class="info-content">
                        <div class="infoLeft">
                            <span>电站地址:</span>
                            <span>电站状态:</span>
                            <span>组串总容量:</span>
                            <span>并网时间:</span>
                            <!-- <span>经纬度:</span> -->
                        </div>
                        <div class="infoRight">
                            <span>{{ powerData2[1].value }}</span>
                            <span class="info-text">· {{ powerData2[2].value }}</span>
                            <span>{{ powerData2[3].value }} kWp</span>
                            <span>{{ powerData2[4].value }}</span>
                            <!-- <span>114° 22' 48" E 30° 28' 43" N</span> -->
                        </div>
                    </div>
                </div>
                <div class="ener-info2">
                    <div class="header">
                        <span class="title">气象信息</span>
                        <span class="time">(更新时间:{{ weatherData[5].value }})</span>
                    </div>
                    <div class="waterInfo">
                        <div class="water-imglist">
                            <div class="certent weather01">
                                <div class="left">
                                    <!--<img src="@/assets/elecGenerate/waterInfo1-1.png" alt />-->
                                </div>
                                <div class="right">
                                    <div class="top">
                                        <span class="number">{{ weatherData[1].value }}</span>
                                        <span class="center-text"> W/m2</span>
                                    </div>
                                    <div class="bottom">
                                        <span>辐照度</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certent weather02">
                                <div class="left">
                                    <!--<img src="@/assets/elecGenerate/waterInfo2-3.png" alt />-->
                                </div>
                                <div class="right">
                                    <div class="top">
                                        <span class="number">{{ weatherData[2].value }}</span>
                                        <span class="center-text"> ℃ / </span>
                                        <span class="number">{{ weatherData[3].value }}</span>
                                        <span class="center-text"> %RH</span>
                                    </div>
                                    <div class="bottom">
                                        <span>环境温湿度</span>
                                    </div>
                                </div>
                            </div>
                            <div class="certent">
                                <div class="left weather03">
                                    <img class="base" src="@/assets/elecGenerate/waterInfoBase.png" alt />
                                    <img ref="fan" class="fan" src="@/assets/elecGenerate/waterInfoFan.png" alt />
                                </div>
                                <div class="right">
                                    <div class="top">
                                        <span class="number">{{ weatherData[4].value }}</span>
                                        <span class="center-text"> m/s</span>
                                    </div>
                                    <div class="bottom">
                                        <span>风速</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-list">
                <div class="info1">
                    <span class="title">发电信息</span>
                    <div class="enerList">
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/enerInfo1.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData[0].value }}kW·h</div>
                                <div class="bottom">
                                    日发电量
                                </div>
                            </div>
                            <div class="right1">
                                <div class="top">同比<span>{{ powerData[6].value }}%</span>
                                    <img v-if="powerData[6].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[6].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                                <div class="top">环比<span>{{ powerData[9].value }}%</span>
                                    <img v-if="powerData[9].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[9].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/enerInfo2.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData[1].value }}kW·h</div>
                                <div class="bottom">
                                    月发电量
                                </div>
                            </div>
                            <div class="right1">
                                <div class="top">同比<span>{{ powerData[7].value }}%</span>
                                    <img v-if="powerData[7].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[7].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                                <div class="top">环比<span>{{ powerData[10].value }}%</span>
                                    <img v-if="powerData[10].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[10].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/enerInfo3.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData[2].value }}kW·h</div>
                                <div class="bottom">
                                    年发电量
                                </div>
                            </div>
                            <div class="right1">
                                <!-- <div class="top">同比<span>{{ powerData[8].value }}%</span>
                                    <img v-if="powerData[8].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[8].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div> -->
                                <div class="top">环比<span>{{ powerData[11].value }}%</span>
                                    <img v-if="powerData[11].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[11].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info2">
                    <span class="title">收益信息</span>
                    <div class="enerList">
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/moneyInfo1.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData[3].value }}元</div>
                                <div class="bottom">
                                    日收益
                                </div>
                            </div>
                            <div class="right1">
                                <div class="top">同比<span>{{ powerData[12].value }}%</span>
                                    <img v-if="powerData[12].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[12].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                                <div class="top">环比<span>{{ powerData[15].value }}%</span>
                                    <img v-if="powerData[15].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[15].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/moneyInfo2.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData[4].value }}元</div>
                                <div class="bottom">
                                    月收益
                                </div>
                            </div>
                            <div class="right1">
                                <div class="top">同比<span>{{ powerData[13].value }}%</span>
                                    <img v-if="powerData[13].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[13].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                                <div class="top">环比<span>{{ powerData[16].value }}%</span>
                                    <img v-if="powerData[16].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[16].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/moneyInfo3.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData[5].value }}元</div>
                                <div class="bottom">
                                    年收益
                                </div>
                            </div>
                            <div class="right1">
                                <!-- <div class="top">同比<span>{{ powerData[14].value }}%</span>
                                    <img v-if="powerData[14].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[14].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div> -->
                                <div class="top">环比<span>{{ powerData[17].value }}%</span>
                                    <img v-if="powerData[17].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData[17].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info3">
                    <span class="title">上网电量</span>
                    <div class="enerList">
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/intel1.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData1[0].value }}kW·h</div>
                                <div class="bottom">
                                    日上网量
                                </div>
                            </div>
                            <div class="right1">
                                <div class="top">同比<span>{{ powerData1[6].value }}%</span>
                                    <img v-if="powerData1[6].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[6].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                                <div class="top">环比<span>{{ powerData1[9].value }}%</span>
                                    <img v-if="powerData1[9].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[9].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/intel2.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData1[1].value }}kW·h</div>
                                <div class="bottom">
                                    月上网量
                                </div>
                            </div>
                            <div class="right1">
                                <div class="top">同比<span>{{ powerData1[7].value }}%</span>
                                    <img v-if="powerData1[7].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[7].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                                <div class="top">环比<span>{{ powerData1[10].value }}%</span>
                                    <img v-if="powerData1[10].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[10].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/intel3.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData1[2].value }}kW·h</div>
                                <div class="bottom">
                                    年上网量
                                </div>
                            </div>
                            <div class="right1">
                                <!-- <div class="top">同比<span>{{ powerData1[8].value }}%</span>
                                    <img v-if="powerData1[8].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[8].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div> -->
                                <div class="top">环比<span>{{ powerData1[11].value }}%</span>
                                    <img v-if="powerData1[11].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[11].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info4">
                    <span class="title">购买电量</span>
                    <div class="enerList">
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/buyEner1.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData1[3].value }}kW·h</div>
                                <div class="bottom">
                                    日购买电量
                                </div>
                            </div>
                            <div class="right1">
                                <div class="top">同比<span>{{ powerData1[12].value }}%</span>
                                    <img v-if="powerData1[12].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[12].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                                <div class="top">环比<span>{{ powerData1[15].value }}%</span>
                                    <img v-if="powerData1[15].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[15].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="certent">
                            <div class="left"><img src="@/assets/elecGenerate/buyEner2.png" alt /></div>
                            <div class="right">
                                <div class="top">{{ powerData1[4].value }}kW·h</div>
                                <div class="bottom">
                                    月购买电量
                                </div>
                            </div>
                            <div class="right1">
                                <div class="top">同比<span>{{ powerData1[13].value }}%</span>
                                    <img v-if="powerData1[13].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[13].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                                <div class="top">环比<span>{{ powerData1[16].value }}%</span>
                                    <img v-if="powerData1[16].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[16].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                        <div class="certent">
                            <div class="left">
                                <img src="@/assets/elecGenerate/buyEner3.png" alt />
                            </div>
                            <div class="right">
                                <div class="top">{{ powerData1[5].value }}kW·h</div>
                                <div class="bottom">
                                    年购买电量
                                </div>
                            </div>
                            <div class="right1">
                                <!-- <div class="top">同比<span>{{ powerData1[14].value }}%</span>
                                    <img v-if="powerData1[14].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[14].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div> -->
                                <div class="top">环比<span>{{ powerData1[17].value }}%</span>
                                    <img v-if="powerData1[17].value > 0" class="imgBehind" src="@/assets/ElecManagement/arrowUp.png" alt />
                                    <img v-if="powerData1[17].value < 0" class="imgBehind" src="@/assets/ElecManagement/arrowDown.png" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="trend">
                <span class="title">功率及发电量趋势</span>
                <div class="chart">
                    <div class="tabBarPlace1">
                        <div class="tabBarTop">
                            <!-- <el-input placeholder="自定义日期" class="date"></el-input> -->
                            <div class="date">
                                <!-- 日 -->
                                <el-date-picker class="styleWid" size="mini" v-show="datePickerIndex == 0" v-model="generalDay" range-separator="至" type="date" placeholder="选择日期"></el-date-picker>
                                <!-- 月 -->
                                <el-date-picker v-show="datePickerIndex == 1" class="styleWid" size="mini" v-model="generalDay" type="month" placeholder="选择月"></el-date-picker>
                                <!-- 年 -->
                                <el-date-picker v-show="datePickerIndex == 2" class="styleWid" size="mini" v-model="generalDay" type="year" placeholder="选择年"></el-date-picker>
                            </div>
                            <div class="dateChoose">
                                <!-- <div :class="btnTypeBranch" @click="changeTab(0)">分</div>
              <div :class="btnTypeTime" @click="changeTab(1)">时</div> -->
                                <div :class="btnTypeDay" @click="changeTab(0)">日</div>
                                <div :class="btnTypeMonth" @click="changeTab(1)">月</div>
                                <div :class="btnTypeYear" @click="changeTab(2)">年</div>
                            </div>
                        </div>
                    </div>
                    <div class="trend-content">
                        <!-- <MapEcharts2 ref="lineCharts" :optionData="optionData" /> -->
                        <MapEcharts2 ref="lineCharts" :whichTab="whichTab" :generalDay="generalDay" :inverterName="inverterName" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MapEcharts1 from "./mapEcharts1";
import MapEcharts2 from "./mapEcharts2";
export default {
    components: {
        MapEcharts1,
        MapEcharts2,
    },
    data () {
        return {
            // stationId: "",
            customerId: "",
            searchText: "",
            optionData: {},
            powerData: [
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
            ],
            powerData1: [
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
            ],
            powerData2: [
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
            ],
            weatherData: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
            power: 0,
            ymax: 100,
            showInterval: true,
            whichTab: 0,
            inverterName: "0",
            btnTypeBranch: "btnGreen",
            btnTypeTime: "btnDark",
            btnTypeDay: "btnDark",
            btnTypeMonth: "btnDark",
            btnTypeYear: "btnDark",
            optionList: [],
            datePickerIndex: 0,
            generalDay: "",
            thisDay: "",
            thisMonth: "",
            thisYear: "",
            xdata: "",
            ydata: "",
            timer: null,
            windSpeed: 0
        };
    },
    created () {
        this.getThisDayMonthYear();
        this.changeTab(0);
    },
    mounted () {
        setTimeout(() => {
            this.$nextTick(() => {
                this.$refs.lineCharts1.init();
                this.$refs.lineCharts.init();
            });
        }, 500);
    },
    beforeDestroy() {
        this.timer = null
        clearInterval(this.timer)
    },
    methods: {
        changeTab (index) {
            this.whichTab = index;
            this.showInterval = false;
            this.datePickerIndex = index;
            if (index == 0) {
                this.btnTypeDay = "btnGreen";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnDark";
                this.generalDay = this.thisYear + "-" + this.thisMonth + "-" + this.thisDay;
            } else if (index == 1) {
                this.btnTypeDay = "btnDark";
                this.btnTypeMonth = "btnGreen";
                this.btnTypeYear = "btnDark";
                this.generalDay = this.thisYear + "-" + this.thisMonth;
            } else if (index == 2) {
                this.btnTypeDay = "btnDark";
                this.btnTypeMonth = "btnDark";
                this.btnTypeYear = "btnGreen";
                this.generalDay = new Date();
            }
            setTimeout(() => {
                this.showInterval = true;
                if (this.$refs.lineCharts) {
                    this.$nextTick(() => {
                        this.$refs.lineCharts.init();
                    });
                }
            }, 0);
        },
        getPowerStations () {
            this.customerId = this.$store.state.storage.customerId;
            let data = {
                customerId: this.customerId,
            };
            this.$store.dispatch("getPowerStations", data).then(() => {
                console.log("电站状态",this.$store.state.equipment.getPowerStations);
                if (this.$store.state.equipment.getPowerStations.errorCode == 0) {
                    this.optionList = this.$store.state.equipment.getPowerStations.data;
                    if ((this.optionList !== null) & (this.optionList.length > 0)) {
                        this.inverterName = this.optionList[0].id;
                        this.initPowerData();
                        this.changeTab(0);
                    } else {
                        this.inverterName = "";
                        // this.initPowerData();
                        this.changeTab(0);
                        this.clearData()
                    }
                } else {
                    this.optionList = [];
                    this.inverterName = "";
                    // this.initPowerData();
                    this.changeTab(0);
                    this.clearData()
                }
            });
        },
        getThisDayMonthYear () {
            let date = new Date();
            this.thisDay = date.getDate();
            this.thisMonth = date.getMonth() + 1;
            this.thisYear = date.getFullYear();
            if (this.thisDay < 10) {
                this.thisDay = "0" + this.thisDay;
            }
            if (this.thisMonth < 10) {
                this.thisMonth = "0" + this.thisMonth;
            }
        },
        // 发电监测：电站状态（发电信息、收益信息、上网电量、购买电量）
        initPowerData () {
            this.$store.dispatch("getPowerStationBasicInfo", this.inverterName).then(() => {
                if (this.$store.state.genManagement.getPowerStationBasicInfo.errorCode == 0) {
                    this.powerData2 = this.$store.state.genManagement.getPowerStationBasicInfo.data;
                    this.ymax = this.powerData2[3].value
                } else {
                    this.powerData2 = [
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                    ];
                }
            });
            this.$store.dispatch("getPowerStationInfo", this.inverterName).then(() => {
                if (this.$store.state.genManagement.getPowerStationInfo.errorCode == 0) {
                    this.powerData = this.$store.state.genManagement.getPowerStationInfo.data;
                } else {
                    this.powerData = [
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                    ];
                }
                this.initWeatherData();
                const that = this
                this.timer = setInterval(function() {
                    if (that.timer) {
                        that.initWeatherData();
                    }
                }, 60000)
            });
            this.$store.dispatch("getPowerStationElectricInfo", this.inverterName).then(() => {
                if (this.$store.state.genManagement.getPowerStationElectricInfo.errorCode == 0) {
                    this.powerData1 = this.$store.state.genManagement.getPowerStationElectricInfo.data;
                } else {
                    this.powerData1 = [
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                        { value: 0 },
                    ];
                }
            });
        },
        initWeatherData () {
            this.$store.dispatch("getRealpowerWeather", this.inverterName).then(() => {
                if (this.$store.state.genManagement.getRealpowerWeather.errorCode == 0) {
                    this.weatherData = this.$store.state.genManagement.getRealpowerWeather.data;
                    this.power = this.weatherData[0].value;
                    this.windSpeed = this.weatherData[4].value;
                } else {
                    this.weatherData = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
                    this.power = 0;
                }
            });
        },
        clearData(){
            this.powerData2 = [
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
            ];
            this.powerData = [
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
            ];
            this.powerData1 = [
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
                { value: 0 },
            ];
            this.weatherData = [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
            this.power = 0;
            this.xdata = ""
            this.ydata = ""
        }
    },
    watch: {
        "$store.state.storage.customerId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.customerId = newVal;
                this.getPowerStations();
            },
        },
        inverterName: {
            // immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) return;
                this.inverterName = newVal;
                this.initPowerData();
            },
        },
        windSpeed(val){
            if(val > 0){
                this.$refs.fan.style.animationDuration = 5/val + 's';
            }else{
                this.$refs.fan.style.animationDuration = '60s';
            }

        }
    },
};
</script>

<style lang="less" scoped>
.weather01{
    .left{
        width: 96px;
        height: 80px;
        background: round url("~@/assets/elecGenerate/waterInfo1-1.png");
        animation: sunup 1s linear infinite ;
    }
    @keyframes sunup{
        0%{
            background: round url("~@/assets/elecGenerate/waterInfo1-1.png");
        }
        100%{
            background: round url("~@/assets/elecGenerate/waterInfo1-2.png");
        }
    }

}
.weather02{
    .left{
        width: 96px;
        height: 80px;
        background: round url("~@/assets/elecGenerate/waterInfo2-1.png");
        animation: heatup 2s linear infinite ;
    }

    @keyframes heatup{
        0%{
            background: round url("~@/assets/elecGenerate/waterInfo2-1.png");
        }
        50%{
            background: round url("~@/assets/elecGenerate/waterInfo2-2.png");
        }
        100%{
            background: round url("~@/assets/elecGenerate/waterInfo2-3.png");
        }
    }

}
.weather03{
    position: relative;
    .base{
        width: 96px;
        position: relative;
    }
    .fan{
        width: 64px;
        height: 64px;
        position: absolute;
        left: 15%;
        top: -15%;
        animation: fan-spin 60s infinite linear;
    }

    @keyframes fan-spin{
        from{
            width: 64px;
            height: 64px;
            transform: rotate(0);
        }
        to{
            width: 64px;
            height: 64px;
            transform: rotate(360deg);
        }
    }

}
.mon-ener-state {
    .title1 {
        margin-top: 1.5rem;
        .text1 {
            color: rgba(255, 255, 255, 0.85);
            opacity: 0.85;
        }
        .text2 {
            margin-left: 1.5rem;
            color: rgba(255, 255, 255, 0.85);
        }
        .search {
            width: 22.8rem;
            float: right;
            position: relative;
            right: 1rem;
        }
    }
    .scroll-box {
        width: 100%;
        height: calc(100vh - 24.9rem);
        margin-top: 2.4rem;
        overflow: hidden;
        overflow-y: scroll;
        .scroll-box-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.5rem;
        }
    }
    .ener-state {
        flex: 1 1 24.5%;
        margin-right: 1.5rem;
        // width: 34.7rem;
        height: 23.6rem;
        // margin-top: 2.6rem;
        background: #0d3050;
        .top {
            padding: 2rem 0 0 2.4rem;
            .title {
                font-size: 1.6rem;
                color: #37a5dd;
                float: left;
            }
        }
        .chart {
            width: 100%;
            height: 100%;
            margin-top: 3rem;
            .chart1 {
                margin-left: 43%;
                transform: translateX(-50%);
            }
        }
    }
    .ener-info1 {
        flex: 1 1 24.5%;
        margin-right: 1.5rem;
        // width: 34.7rem;
        height: 23.6rem;
        background: #0d3050;
        // position: absolute;
        // top: 5.7rem;
        // left: 35.9rem;
        padding: 2rem 0 0 2.4rem;
        box-sizing: border-box;
        overflow: hidden;
        .title {
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            color: #37a5dd;
        }
        .info-content {
            font-size: 1.2rem;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 2rem;
            .infoLeft {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-end;
                margin-right: 0.8rem;
                span {
                    margin-bottom: 1.2rem;
                }
            }
            .infoRight {
                display: flex;
                flex-direction: column;
                // justify-content: space-evenly;
                align-items: flex-start;
                span {
                    margin-bottom: 1.2rem;
                }
            }
            .info-text {
                color: #1ab292;
            }
        }
    }
    .ener-info2 {
        flex: 1 1 50%;
        // width: 71rem;
        height: 23.6rem;
        background: #0d3050;
        // position: absolute;
        // top: 5.7rem;
        // right: 0;
        padding: 2rem 2.4rem 0 2.4rem;
        box-sizing: border-box;
        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .title {
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                color: #37a5dd;
            }
            .time {
                font-size: 1.4rem;
                color: #ffffff;
            }
        }
        .waterInfo {
            .water-imglist {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                margin: 6rem 1rem 0 0;
                img {
                    width: 9.4rem;
                    height: 8rem;
                }
                .certent {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100px;
                        .top {
                            color: #ffffff;
                            margin-bottom: 0.5rem;
                            .number {
                                font-size: 2rem;
                            }
                            .center-text {
                                font-size: 1.2rem;
                                opacity: 0.45;
                            }
                        }
                        .bottom {
                            margin-bottom: 1rem;
                            span {
                                font-size: 1.4rem;
                                color: #00a1db;
                            }
                        }
                    }
                }
            }
        }
    }
    .info-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        .info1 {
            flex: 1 1 25%;
            margin-right: 1.5rem;
            // width: 34.7rem;
            height: 23.6rem;
            // position: absolute;
            // top: 30.7rem;
            // left: 0rem;
            background: #0d3050;
            padding: 2rem 0 0 2.4rem;
            box-sizing: border-box;
            .title {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: #37a5dd;
            }
            .enerList {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin-top: 0.5rem;
                height: 18rem;
                .certent {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .left {
                        margin-right: 0.6rem;
                        img {
                            width: 3.6rem;
                            height: 3.6rem;
                        }
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: #fff;
                        margin-bottom: 0.5rem;
                        width:50%;
                        .top {
                            font-size: 1.6rem;
                        }
                        .bottom {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .info2 {
            flex: 1 1 25%;
            margin-right: 1.5rem;
            // width: 34.7rem;
            height: 23.6rem;
            background: #0d3050;
            // position: absolute;
            // top: 30.7rem;
            // left: 36rem;
            padding: 2rem 0 0 2.4rem;
            box-sizing: border-box;
            .title {
                position: relative;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: #37a5dd;
            }
            .enerList {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin-top: 0.5rem;
                height: 18rem;
                .certent {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .left {
                        margin-right: 0.6rem;
                        img {
                            width: 3.6rem;
                            height: 3.6rem;
                        }
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: #fff;
                        margin-bottom: 0.5rem;
                        width: 50%;
                        .top {
                            font-size: 1.6rem;
                        }
                        .bottom {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .info3 {
            flex: 1 1 25%;
            margin-right: 1.5rem;
            // width: 34.7rem;
            height: 23.6rem;
            background: #0d3050;
            // position: absolute;
            // top: 30.7rem;
            // right: 36.2rem;
            padding: 2rem 0 0 2.4rem;
            box-sizing: border-box;
            .title {
                position: relative;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: #37a5dd;
            }
            .enerList {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin-top: 0.5rem;
                height: 18rem;
                .certent {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .left {
                        margin-right: 0.6rem;
                        img {
                            width: 3.6rem;
                            height: 3.6rem;
                        }
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: #fff;
                        margin-bottom: 0.5rem;
                        width: 50%;
                        .top {
                            font-size: 1.6rem;
                        }
                        .bottom {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .info4 {
            flex: 1 1 25%;
            // width: 34.7rem;
            height: 23.6rem;
            background: #0d3050;
            // position: absolute;
            // top: 30.7rem;
            // right: 0.3rem;
            padding: 2rem 0 0 2.4rem;
            box-sizing: border-box;
            .title {
                position: relative;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: #37a5dd;
            }
            .enerList {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin-top: 0.5rem;
                height: 18rem;
                .certent {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .left {
                        margin-right: 0.6rem;
                        img {
                            width: 3.6rem;
                            height: 3.6rem;
                        }
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: #fff;
                        margin-bottom: 0.5rem;
                        width: 50%;
                        .top {
                            font-size: 1.6rem;
                        }
                        .bottom {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .imgBehind{
            width:8px;
            height: 12px;
        }
        .right1{
            color: #fff;
            width: 50%;
            span{
                margin: 0px 5px;
            }
        }
    }
    .trend {
        position: relative;
        width: 100%;
        // width: 143.6rem;
        height: 31rem;
        background: #0d3050;
        // position: absolute;
        // top: 55.6rem;
        padding: 2rem 0 0 0;
        box-sizing: border-box;
        .title {
            font-size: 1.6rem;
            color: #37a5dd;
            margin-left: 2.4rem;
        }
        .chart {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // display: flex;
            // flex-direction: row;
            // justify-content: flex-start;
            // align-items: flex-start;
            // margin: 1.6rem 2.4rem 0 2.4rem;
            .trend-content {
                width: 96%;
                height: 90%;
            }
            .tabBarPlace1 {
                width: 5rem;
                .tabBarTop {
                    // height: 10rem;
                    position: absolute;
                    top: 1.6rem;
                    right: 2.4rem;
                    z-index: 99;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    .date {
                        width: 18rem;
                        margin-right: 1rem;
                    }

                    .dateChoose {
                        // height: 15.3rem;
                        // width: 4.7rem;
                        border-radius: 0.2rem;
                        // border: 0.1rem solid #004579;
                        box-sizing: border-box;
                        color: #fff;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        z-index: 100;
                        .btnDark {
                            height: 3rem;
                            width: 4.6rem;
                            background-color: #0b375e;
                            font-size: 1.4rem;
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            line-height: 3rem;
                            text-align: center;
                        }
                        .btnGreen {
                            height: 3rem;
                            width: 4.6rem;
                            background-color: #027969;
                            font-size: 1.4rem;
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            line-height: 3rem;
                            text-align: center;
                        }
                    }
                    .img1 {
                        height: 2.4rem;
                        width: 2.4rem;
                        position: absolute;
                        top: 2.8rem;
                        left: 53.2rem;
                        cursor: pointer;
                    }
                }
            }
            .trend-content {
                position: absolute;
                left: 0;
                top: 2rem;
            }
        }
    }
}
</style>
<style lang="less">
.tabBarTop {
    .el-input__inner {
        height: 3rem !important;
        line-height: 3rem !important;
        width: 18rem !important;
    }
    .el-input__icon {
        height: 3rem !important;
        line-height: 3rem !important;
    }
    .el-date-editor {
        width: 18rem !important;
    }
}
</style>
