var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepElectric"},_vm._l((_vm.strList),function(item,index){return _c('div',{key:index,staticClass:"consumption"},[_c('span',{staticClass:"title"},[_vm._v("本月区域总用电")]),_c('div',{staticClass:"conlist1"},[_c('p',[_vm._v(_vm._s(item.name))]),_c('h4',[_vm._v(" "+_vm._s(item.value)+" "),_c('span',[_vm._v("kWh")])])]),_c('div',{staticClass:"conlist2"},[_c('img',{staticClass:"conleftimg",attrs:{"src":require("@/assets/energyStatistical/con-bg-left.png"),"alt":""}}),_c('img',{staticClass:"conlefticon",attrs:{"src":require("@/assets/energyStatistical/con-bg-icon.png"),"alt":""}}),_c('div',{staticClass:"list1-con"},[_c('p',[_vm._v("环比")]),_c('span',[_vm._v(_vm._s(item.huanbiValue))])]),_c('div',{staticClass:"con-border"}),_c('div',{staticClass:"list2-con"},[_c('span',{class:item.huanbiRatio == 0 || item.huanbiRatio == '0.00%' || item.huanbiRatio == '-'
            ? 'ratioNone'
            : parseFloat(item.huanbiRatio) > 0
            ? 'ratioRise'
            : 'ratioDown'},[_vm._v(" "+_vm._s(item.huanbiRatio == 0 || item.huanbiRatio == "0.00%" ? "-" : item.huanbiRatio)),(item.huanbiRatio > 0 || item.huanbiRatio < 0)?_c('span',[_vm._v("%")]):_vm._e(),(item.huanbiRatio == 0 || item.huanbiRatio == '0.00%' || item.huanbiRatio == '-')?_c('img',{attrs:{"src":"","alt":""}}):_vm._e(),(parseFloat(item.huanbiRatio) > 0)?_c('img',{attrs:{"src":require("@/assets/energyStatistical/rise.png"),"alt":""}}):_vm._e(),(parseFloat(item.huanbiRatio) < 0)?_c('img',{attrs:{"src":require("@/assets/energyStatistical/down.png"),"alt":""}}):_vm._e()])])]),_c('div',{staticClass:"conlist3"},[_c('img',{staticClass:"conleftimg",attrs:{"src":require("@/assets/energyStatistical/con-bg-left1.png"),"alt":""}}),_c('img',{staticClass:"conlefticon",attrs:{"src":require("@/assets/energyStatistical/con-bg-icon1.png"),"alt":""}}),_c('div',{staticClass:"list1-con"},[_c('p',[_vm._v("同比")]),_c('span',[_vm._v(_vm._s(item.tongbiValue))])]),_c('div',{staticClass:"con-border"}),_c('div',{staticClass:"list2-con"},[_c('span',{class:item.tongbiRatio == 0 || item.tongbiRatio == '0.00%' || item.tongbiRatio == '-'
            ? 'ratioNone'
            : parseFloat(item.tongbiRatio) > 0
            ? 'ratioRise'
            : 'ratioDown'},[_vm._v(" "+_vm._s(item.tongbiRatio == 0 || item.tongbiRatio == "0.00%" ? "-" : item.tongbiRatio)),(item.tongbiRatio > 0 || item.tongbiRatio < 0)?_c('span',[_vm._v("%")]):_vm._e(),(item.tongbiRatio == 0 || item.tongbiRatio == '0.00%' || item.tongbiRatio == '-')?_c('img',{attrs:{"src":"","alt":""}}):_vm._e(),(parseFloat(item.tongbiRatio) > 0)?_c('img',{attrs:{"src":require("@/assets/energyStatistical/rise.png"),"alt":""}}):_vm._e(),(parseFloat(item.tongbiRatio) < 0)?_c('img',{attrs:{"src":require("@/assets/energyStatistical/down.png"),"alt":""}}):_vm._e()])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }