<template>
  <div class="equipmentMeters">
    <div class="blockTwo page-main">
      <!-- EquipMeters仪表管理11111111 -->
      <!-- 板块二/左侧 ##########################################################################-->
      <div
        :class="isTreeShow ? 'shrink-open_tree' : 'shrink-close_tree'"
        class="blockTwoLeft page-main-tree"
      >
        <!--el树形控件  -->
        <TreeOne />
      </div>
      <div
        :class="
          isTreeShow ? 'shrink-open_tree_content' : 'shrink-close_tree_content'
        "
        class="blockTwoRight page-main-content"
      >
        <div class="shrink-btn" @click="isTreeShow = !isTreeShow"></div>
        <!-- nav -->
        <div class="search-box">
          <div class="search marginR displayinline">
            <span class="color fontSize marginR">仪表名称：</span>
            <el-input
              class="eleInput color fontSize"
              size="small"
              placeholder="请输入仪表名称"
              v-model="keyWords"
              clearable
              @clear="allListPage()"
              @keyup.enter.native="queryData"
              @change="nameChange"
            ></el-input>
          </div>
          <div class="search marginR displayinline">
            <span class="color fontSize marginR">仪表类型：</span>
            <el-select
              style="width: 16.8rem;"
              placeholder="请选择"
              v-model="metrName"
              @change="typeChange"
            >
              <el-option
                v-for="item in metrType"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              ></el-option>
            </el-select>
          </div>
          <button class="btn_save" @click="queryData">查询</button>
          <div class="btnBox">
            <button
              class="exportButton color1 fontSize marginR"
              :class="buildingId < 10000 ? 'btn2Gray' : ''"
              :disabled="buildingId < 10000"
              @click="importFile"
            >
              导入
            </button>
            <button
              class="exportButton color1 fontSize marginR"
              @click="exportFile"
            >
              导出
            </button>
            <button
              class="btn color fontSize"
              :class="buildingId < 10000 ? 'djBgc' : 'djpink'"
              @click="openPopup"
            >
              登记
            </button>
          </div>
        </div>

        <!-- 表格部分 -->
        <div class="equipmentTablePlace">
          <div
            class="color1 fontSize"
            style="padding-left: 2.4rem; margin: 1.6rem 0;"
          >
            设备管理 / 仪表管理 / {{ breakName }}
          </div>
          <div class="tableBox">
            <!-- 导航栏的部分 -->
            <div class="navigation color1">
              <div
                class="height borderBottom"
                style="font-size: 1.4rem;"
                :class="'width' + (idx + 1)"
                v-for="(x, idx) in navigation"
                :key="idx"
              >
                {{ x }}
              </div>
              <div class="width9 height borderBottom"><span style="font-size: 1.4rem;">操作</span></div>
            </div>
            <div style="font-size: 1.4rem; height: 58rem; overflow-y: scroll;">
              <!-- v-if="navigationList.data.records != undefined" -->
              <div
                class="navigation color1 border-bototm"
                v-for="(x, index) in navigationList.data.records"
                :key="index"
              >
                <div class="height sh width1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="x.code == null ? '' : x.code.toString()"
                    placement="top"
                  >
                    <span>{{ x.code }}</span>
                  </el-tooltip>
                </div>
                <div class="height sh width2">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="x.name == null ? '' : x.name.toString()"
                    placement="top"
                  >
                    <span>{{ x.name }}</span>
                  </el-tooltip>
                </div>
                <div class="height sh width3">
                  {{ x.type == 2 ? '电能表' : '电气消防仪表' }}
                </div>
                <div class="height sh width4">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="x.ownerName == null ? '' : x.ownerName.toString()"
                    placement="top"
                  >
                    <span>{{ x.ownerName }}</span>
                  </el-tooltip>
                </div>
                <div class="height sh width5 pad1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="x.ownerAddr == null ? '' : x.ownerAddr.toString()"
                    placement="top"
                  >
                    <span>{{ x.ownerAddr }}</span>
                  </el-tooltip>
                </div>
                <div class="height sh width6 pad1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      x.locationFullName == null ? '' : x.locationFullName.toString()
                    "
                    placement="top"
                  >
                    <span>{{ x.locationFullName }}</span>
                  </el-tooltip>
                </div>
<!--                <div class="height sh width7 pad1">-->
<!--                  <el-tooltip-->
<!--                    class="item"-->
<!--                    effect="dark"-->
<!--                    :content="x.floorName == null ? '' : x.floorName.toString()"-->
<!--                    placement="top"-->
<!--                  >-->
<!--                    <span>{{ x.floorName }}</span>-->
<!--                  </el-tooltip>-->
<!--                </div>-->
                <div class="height sh width7 pad1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="x.installationSite == null ? '' : x.installationSite.toString()"
                    placement="top"
                  >
                    <span>{{ x.installationSite }}</span>
                  </el-tooltip>
                </div>
                <div class="height sh width9 pad1">
                  <span
                    class="color2 fontSize marginR1 cursor"
                    @click="editMetr(x)"
                  >
                    编辑
                  </span>
                  <span class="color2 fontSize cursor" @click="deleteList(x)">
                    删除
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页器部分 -->
          <div
            class="pagination"
            style="float: right; margin-right: 2.4rem; margin-top: 1rem;"
            v-if="metrListInfo.data && navigationList.data.records"
          >
            <!-- 
             @size-change="handleSizeChange" 每一页面条数
             @current-change="handleCurrentChange" 当前页面
           -->
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="pageSize"
              :current-page.sync="pageNum"
              layout="total, sizes, prev, pager, next, jumper"
              :total="metrListInfo.data.total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!-- 弹框 star -->
      <!-- title="登记" -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="56.8rem"
        :close-on-click-modal="false"
        @close="handleClose"
      >
        <div class="content">
          <div class="tab">
            <span class="color1 fontSize1">{{ signInfo }}</span>
            <button
              type="button"
              @click="dialogClose"
              aria-label="Close"
              class="el-dialog__headerbtn"
            >
              <i class="el-dialog__close el-icon el-icon-close"></i>
            </button>
          </div>
          <div class="form_content">
            <div class="form-item">
              <div class="color3">
                <i class="color4 marginRc">*</i>
                数据来源
              </div>
              <div class="marginR">
                <el-select
                  v-model="submitBuildingInfo.sourceType"
                  placeholder="请选择"
                  @change="handleChangeSourceType"
                >
                  <el-option label="手动录入" value="0" />
                  <el-option label="配电仪表" value="1" />
                </el-select>
              </div>
              <div
                class="bottom"
                style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
                v-if="showRed9"
              >
                不能为空
              </div>
            </div>
            <div
              class="form-item"
              v-show="submitBuildingInfo.sourceType == '1'"
            >
              <div class="color3">
                <i class="color4 marginRc">*</i>
                配电仪表
              </div>
              <div class="marginR">
                <el-select
                  placeholder="请选择"
                  v-model="submitBuildingInfo.meterId"
                  @change="showRed10 = false"
                >
                  <el-option
                    v-for="item in meterList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div
                class="bottom"
                style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
                v-if="showRed10"
              >
                不能为空
              </div>
            </div>
            <div class="form-item">
              <div class="color3">
                <i class="color4 marginRc">*</i>
                编号
              </div>
              <div class="marginR">
                <input
                  :class="{
                    sujectINput: true,
                    marginTop: true,
                    inputRed: showRed1,
                  }"
                  type="text"
                  v-model="submitBuildingInfo.code"
                  placeholder="请输入编号"
                  @blur="inputBlur(1)"
                />
              </div>
              <div
                class="bottom"
                style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
                v-if="showRed1"
              >
                不能为空
              </div>
            </div>
            <div class="form-item">
              <div class="color3">
                <i class="color4 marginRc">*</i>
                仪表名称
              </div>
              <div class="marginTop">
                <input
                  :class="{ sujectINput: true, inputRed: showRed2 }"
                  type="text"
                  v-model="submitBuildingInfo.name"
                  placeholder="请输入仪表名称"
                  @blur="inputBlur(2)"
                />
              </div>
              <div
                class="bottom"
                v-if="showRed2"
                style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
              >
                不能为空
              </div>
            </div>
            <div class="form-item">
              <div class="color3">
                <i class="color4 marginRc">*</i>
                仪表类型
              </div>
              <div class="marginTop">
                <el-select
                  placeholder="请选择仪表类型"
                  v-model="submitBuildingInfo.type"
                  value-key="type"
                  @change="changeType"
                  :class="showRed3 ? 'inputRed' : ''"
                >
                  <el-option
                    v-for="item in popupMetrType"
                    :key="item.type"
                    :label="item.name"
                    :value="item.type"
                  ></el-option>
                </el-select>
              </div>
              <div
                class="bottom"
                v-if="showRed3"
                style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
              >
                不能为空
              </div>
            </div>
            <div
              class="form-item"
              v-show="
                submitBuildingInfo.type == 2 ||
                submitBuildingInfo.type == '电能表'
              "
            >
              <div class="color3">
                <i class="color4 marginRc">*</i>
                用电类型
              </div>
              <div class="marginTop">
                <el-select
                  v-model="submitBuildingInfo.powerType"
                  placeholder="请选择"
                  value-key="label"
                  @change="editelectricype"
                  :class="showRed4 ? 'inputRed' : ''"
                >
                  <el-option
                    v-for="item in powertypeList"
                    :key="item.label"
                    :label="item.value"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </div>
              <div
                class="bottom"
                v-if="showRed4"
                style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
              >
                不能为空
              </div>
            </div>
            <!-- <div class="form-item"
              v-show="
                submitBuildingInfo.type == 3 ||
                  submitBuildingInfo.type == '电气消防电表'
              "
            >
              <div class="color3">
                <i class="color4 marginRc">*</i>客户单位
              </div>
              <div class="marginR">
                <input
                  class="sujectINput marginTop"
                  v-model="submitBuildingInfo.ownerName"
                  type="text"
                  placeholder="请输入客户单位"
                  readonly
                />
              </div>
              <div
                class="bottom"
                v-if="showRed5"
                style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
              >
                不能为空
              </div>
            </div> -->

            <!--
            <div
              class="form-item"
              v-show="
                submitBuildingInfo.type == 2 ||
                submitBuildingInfo.type == '电能表'
              "
            >
              <div class="color3">
                <i class="color4 marginRc">*</i>
                所属楼栋
              </div>
              <div class="marginTop">
                <el-select
                  @change="getLayer"
                  @blur="getLayer1"
                  placeholder="请选择"
                  v-model="submitBuildingInfo.buildingName"
                  value-key="id"
                  :class="showRed6 ? 'inputRed' : ''"
                >
                  <el-option
                    v-for="item in allBuilding"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div
                class="bottom"
                v-if="showRed6"
                style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
              >
                不能为空
              </div>
            </div>
            <div
              class="form-item"
              v-show="
                submitBuildingInfo.type == 2 ||
                submitBuildingInfo.type == '电能表'
              "
            >
              <div class="color3">所属楼层</div>
              <div class="marginTop">
                <el-select
                  placeholder="请选择"
                  v-model="submitBuildingInfo.floorName"
                  @change="getRoomlist"
                  value-key="id"
                >
                  <el-option
                    v-for="item in buildingLayerList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div
              class="form-item"
              v-show="
                submitBuildingInfo.type == 2 ||
                submitBuildingInfo.type == '电能表'
              "
            >
              <div class="color3">所属房间</div>
              <div class="marginTop">
                <el-select
                  placeholder="请选择"
                  @change="getLocationId"
                  v-model="submitBuildingInfo.roomName"
                >
                  <el-option
                    v-for="item in allRoomInfoList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            -->

            <div
                class="form-item"
                v-show="
                submitBuildingInfo.type == 2 ||
                submitBuildingInfo.type == '电能表'
              "
            >
              <div class="color3">
                <i class="color4 marginRc">*</i>
                所属位置
              </div>
              <div class="marginTop">
                <TreeLocation ref="teeLocationRef" :options="options" :value="locationValue" @getValue="getSelectedValue"></TreeLocation>
              </div>
              <div
                  class="bottom"
                  v-if="showRed6"
                  style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
              >
                不能为空
              </div>
            </div>

            <div class="form-item" v-show="submitBuildingInfo.type">
              <div class="color3">
                <i class="color4 marginRc">*</i>
                客户单位
              </div>
              <div class="marginR">
                <input
                  class="sujectINput marginTop"
                  v-model="submitBuildingInfo.ownerName"
                  type="text"
                  placeholder="请输入客户单位"
                  readonly
                />
              </div>
              <div
                class="bottom"
                v-if="showRed7"
                style="
                  color: #f56c6c;
                  font-size: 1.2rem;
                  top: 6.2rem;
                  position: absolute;
                "
              >
                不能为空
              </div>
            </div>
            <div
              class="form-item">
              <div class="color3">电能最值</div>
              <div class="marginTop">
                <el-input-number
                  placeholder="请输入电能最值"
                  v-model="submitBuildingInfo.maxMetric"
                  size="mini"
                  controls-position="right"
                  :min="0"
                >
                </el-input-number>
              </div>
            </div>
            <div class="form-item">
              <div class="color3">
                安装位置
              </div>
              <div class="marginR">
                <input
                  :class="{
                    sujectINput: true,
                    marginTop: true,
                  }"
                  type="text"
                  v-model="submitBuildingInfo.installationSite"
                  placeholder="请输入安装位置"
                />
              </div>
            </div>
          </div>
          <!-- <div class="subject">
            <div class="subjectSearch">
              <div style="width: 48%;position:relative;">
                <div class="color3"><i class="color4 marginRc">*</i>数据来源</div>
                <div class="marginR">
                  <el-select v-model="value" placeholder="请选择">
                    <el-option label="手动录入" value="0" />
                    <el-option label="配电仪表" value="1" />
                  </el-select>
                </div>
                <div
                  class="bottom"
                  style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
                  v-if="showRed1"
                >
                  不能为空
                </div>
              </div>
              <div style="width: 48%;position:relative;">
                <div class="color3"><i class="color4 marginRc">*</i>编号</div>
                <div class="marginR">
                  <input
                    :class="{
                      sujectINput: true,
                      marginTop: true,
                      inputRed: showRed1,
                    }"
                    type="text"
                    v-model="submitBuildingInfo.code"
                    placeholder="请输入编号"
                    @blur="inputBlur(1)"
                  />
                </div>
                <div
                  class="bottom"
                  style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
                  v-if="showRed1"
                >
                  不能为空
                </div>
              </div>
              <div style="width: 48%;position:relative;">
                <div class="color3">
                  <i class="color4 marginRc">*</i>仪表名称
                </div>
                <div class="marginTop">
                  <input
                    :class="{ sujectINput: true, inputRed: showRed2 }"
                    type="text"
                    v-model="submitBuildingInfo.name"
                    placeholder="请输入仪表名称"
                    @blur="inputBlur(2)"
                  />
                </div>
                <div
                  class="bottom"
                  v-if="showRed2"
                  style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
                >
                  不能为空
                </div>
              </div>
            </div>
          </div> -->
          <!-- {{submitBuildingInfo.type}} -->
          <!-- <div class="subject">
            <div class="subjectSearch">
              <div style="width: 47%;position:relative;">
                <div class="color3">
                  <i class="color4 marginRc">*</i>仪表类型
                </div>
                <div class="marginTop">
                  <el-select
                    placeholder="请选择仪表类型"
                    v-model="submitBuildingInfo.type"
                    value-key="type"
                    @change="changeType"
                    :class="showRed3 ? 'inputRed' : ''"
                  >
                    <el-option
                      v-for="item in popupMetrType"
                      :key="item.type"
                      :label="item.name"
                      :value="item.type"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="bottom"
                  v-if="showRed3"
                  style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
                >
                  不能为空
                </div>
              </div>
              <div
                style="width: 48%;position:relative;"
                v-show="
                  submitBuildingInfo.type == 2 ||
                    submitBuildingInfo.type == '电能表'
                "
              >
                <div class="color3">
                  <i class="color4 marginRc">*</i>用电类型
                </div>
                <div class="marginTop">
                  <el-select
                    v-model="submitBuildingInfo.powerType"
                    placeholder="请选择"
                    value-key="label"
                    @change="editelectricype"
                    :class="showRed4 ? 'inputRed' : ''"
                  >
                    <el-option
                      v-for="item in powertypeList"
                      :key="item.label"
                      :label="item.value"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="bottom"
                  v-if="showRed4"
                  style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
                >
                  不能为空
                </div>
              </div>
              <div
                style="width: 48%;position:relative;"
                v-show="
                  submitBuildingInfo.type == 3 ||
                    submitBuildingInfo.type == '电气消防电表'
                "
              >
                <div class="color3">
                  <i class="color4 marginRc">*</i>客户单位
                </div>
                <div class="marginR">
                  <input
                    class="sujectINput marginTop"
                    v-model="submitBuildingInfo.ownerName"
                    type="text"
                    placeholder="请输入客户单位"
                    readonly
                  />
                </div>
                <div
                  class="bottom"
                  v-if="showRed5"
                  style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
                >
                  不能为空
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div
            class="subject"
            v-show="
              submitBuildingInfo.type == 2 ||
                submitBuildingInfo.type == '电能表'
            "
          >
            <div class="subjectSearch">
              <div style="width: 48%;position:relative;">
                <div class="color3">
                  <i class="color4 marginRc">*</i>所属楼栋
                </div>
                <div class="marginTop">
                  <el-select
                    @change="getLayer"
                    @blur="getLayer1"
                    placeholder="请选择"
                    v-model="submitBuildingInfo.buildingName"
                    value-key="id"
                    :class="showRed6 ? 'inputRed' : ''"
                  >
                    <el-option
                      v-for="item in allBuilding"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="bottom"
                  v-if="showRed6"
                  style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
                >
                  不能为空
                </div>
              </div>

              <div style="width: 48%">
                <div class="color3">所属楼层</div>
                <div class="marginTop">
                  <el-select
                    placeholder="请选择"
                    v-model="submitBuildingInfo.floorName"
                    @change="getRoomlist"
                    value-key="id"
                  >
                    <el-option
                      v-for="item in buildingLayerList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div
            class="subject"
            v-show="
              submitBuildingInfo.type == 2 ||
                submitBuildingInfo.type == '电能表'
            "
          >
            <div class="subjectSearch">
              <div style="width: 48%">
                <div class="color3">所属房间</div>
                <div class="marginTop">
                  <el-select
                    placeholder="请选择"
                    @change="getLocationId"
                    v-model="submitBuildingInfo.roomName"
                  >
                    <el-option
                      v-for="item in allRoomInfoList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div style="width: 48%;position:relative;">
                <div class="color3">
                  <i class="color4 marginRc">*</i>客户单位
                </div>
                <div class="marginR">
                  <input
                    class="sujectINput marginTop"
                    v-model="submitBuildingInfo.ownerName"
                    type="text"
                    placeholder="请输入客户单位"
                    readonly
                  />
                </div>
                <div
                  class="bottom"
                  v-if="showRed7"
                  style="color:#f56c6c;font-size:1.2rem;top:6.2rem;position:absolute;"
                >
                  不能为空
                </div>
              </div>
            </div>
          </div> -->
          <!-- 监测回路部分 -->
          <div class="monitorLoop">
            <div class="title">
              <span class="required">*</span>
              监测回路
            </div>
            <div class="loops">
              <div
                class="loopItem"
                v-for="(item, index) in loopList"
                :key="item.id"
              >
                <span
                  style="
                    width: 11rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    float: left;
                  "
                >
                  {{ index + 1 }}. {{ item.name }}
                </span>
                <span @click="deleteLoop(item.id)">x</span>
              </div>
              <div class="addBtn" v-show="addLoopShow">
                <el-input v-model="addOneLoop"></el-input>
              </div>
              <div class="addLoop" @click="addLoop">
                <img src="@/assets/icons/add.png" alt="" />
              </div>
            </div>
            <div
              class="bottom"
              v-if="showRed8"
              style="color: #f56c6c; font-size: 1.2rem; top: 2rem;"
            >
              不能为空
            </div>
          </div>
          <div style="padding: 3.2rem 2.4rem;">
            <div class="marginTop1" style="float: right;">
              <button class="cancel color marginR" @click="cancelEdit">
                取消
              </button>
              <button
                class="cancel color bgc2"
                v-if="signInfo == '登记'"
                @click="editMertInfoList"
              >
                保存
              </button>
              <button class="cancel color bgc2" @click="saveEditInfo" v-else>
                保存
              </button>
            </div>
          </div>
          <!-- END -->
        </div>
      </el-dialog>
      <!-- 弹框end -->
      <!-- 导入dialog弹层 -->
      <DialogImportFile @queryData="queryData" ref="dialogImportRef" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TreeOne from './components/TreeInstruments.vue'
import TreeLocation from './components/TreeLocation.vue'
import DialogImportFile from '../../components/DialogImportFile.vue'
import { exportEnergyMeterReport } from '@/api/repair'
export default {
  name: 'EquipMeters',
  components: { TreeOne, DialogImportFile, TreeLocation },
  computed: {
    ...mapState({
      metrListInfo: (state) => {
        return state.metrAdministration.allMetrList
      },
      deleteBackInfo: (state) => {
        return state.metrAdministration.deleteInfo
      },
      PowerElectricType: (state) => {
        return state.metrAdministration.metrType
      },
      allBigBuildingList: (state) => {
        return state.metrAdministration.bigBuildingList
      },
      layerList: (state) => {
        return state.metrAdministration.allLayerList
      },
      allRoomList: (state) => {
        return state.metrAdministration.allRommList
      },
      allEditInfo: (state) => {
        return state.metrAdministration.editInfo
      },
      customerTreeAllNode: (state) => {
        return state.equipment.customerTreeAllNode.data;
      },
    }),
  },
  data() {
    return {
      // 开启/关 多选/单选
      options: [],
      locationValue: {
        // id: "1792367097525751810",
        // name: '1F'
      },
      isTreeShow: true,
      option: [],
      dialogVisible: false,
      breakName: '',
      powertypeList: [],
      metrType: [
        {
          name: '全部',
          type: '',
        },
        {
          name: '电能表',
          type: 2,
        },
        {
          name: '电气消防电表',
          type: 3,
        },
      ],
      popupMetrType: [
        {
          name: '电能表',
          type: 2,
        },
        {
          name: '电气消防电表',
          type: 3,
        },
      ],
      metrName: '',
      // value: "",
      navigation: [
        '编号',
        '仪表名称',
        '仪表类型',
        '客户单位',
        '客户地址',
        '所属位置',
        // '所属层室',
        '安装位置',
      ],
      navigationList: { data: { records: [] } },
      // 分页
      pageNum: 1, // 当前第几页
      pageSize: 10, //当前显示多少条
      keyWords: '', //搜索关键字
      signInfo: '', //弹框左上角 登记 or 编辑
      list: [],
      submitBuildingInfo: {
        sourceType: '0',
        meterId: '',
        code: '', //编号
        name: '', //名称
        type: '', //仪表类型
        powerType: '', //用电类型
        // buildingName: '', //所属楼栋
        // floorName: '', //所属层室id
        // roomName: '', // 所属房间id
        locationName: '',// 所属位置名称
        locationId: '', //关联计量位置
        ownerName: '', //客户单位名称
        ownerId: '', //客户id
        maxMetric: 0, //电能最值
        installationSite: "" //安装位置
      },
      buildingId: '',
      meterList: [],
      allBuilding: [],
      buildingLayerList: [], //所属楼层
      allRoomInfoList: [],
      customerName: '',
      anticipateEditInfo: '', //获取当前信息列表的接口
      // 监测回路列表
      loopList: [
        {
          id: 1,
          name: '1. 回路1',
        },
      ],
      // 添加的回路
      addOneLoop: '',
      addLoopShow: false,
      // 回路列表的长度
      loopsLen: 0,
      editId: '',
      editOwnerId: '',
      showRed1: false,
      showRed2: false,
      showRed3: false,
      showRed4: false,
      showRed5: false,
      showRed6: false,
      showRed7: false,
      showRed8: false,
      showRed9: false,
      showRed10: false,
    }
  },
  created() {
    this.allListPage()
    this.getPowerInfo()
  },
  methods: {
    getSelectedValue(value) {
      this.submitBuildingInfo.locationName = value.name
      this.submitBuildingInfo.locationId = value.id
    },
    initBlur() {
      this.showRed1 = false
      this.showRed2 = false
      this.showRed3 = false
      this.showRed4 = false
      this.showRed5 = false
      this.showRed6 = false
      this.showRed7 = false
      this.showRed8 = false
    },
    inputBlur(index) {
      if (index == 1) {
        if (this.submitBuildingInfo.code == '') {
          this.showRed1 = true
        } else {
          this.showRed1 = false
        }
      } else if (index == 2) {
        if (this.submitBuildingInfo.name == '') {
          this.showRed2 = true
        } else {
          this.showRed2 = false
        }
      }
    },
    changeType() {
      if (this.submitBuildingInfo.type == '') {
        this.showRed3 = true
      } else {
        this.showRed3 = false
        // this.submitBuildingInfo.buildingName = ''
        // this.submitBuildingInfo.floorName = ''
        // this.submitBuildingInfo.roomName = ''
        this.submitBuildingInfo.locationName = ''
        this.buildingLayerList = []
        this.allRoomInfoList = []
      }
    },
    typeChange() {
      this.queryData()
    },
    nameChange() {
      this.queryData()
    },
    close() {},
    // 获取用电类型
    getPowerInfo() {
      this.$store.dispatch('powertype').then(() => {
        this.powertypeList = this.PowerElectricType.data
      })
    },
    // 获取对应客户下的客户数
    getTreeLocationByCustomerId(val) {
      this.$store.dispatch("getCustomerTreeByCustomerId", val).then(() => {
        this.options = this.customerTreeAllNode;
      })
    },
    // 获取配电仪表
    getMeterList() {
      this.$store
        .dispatch('getMeterList', this.submitBuildingInfo.ownerId)
        .then(() => {
          // console.log(this.$store.state)
          this.meterList = this.$store.state.metrAdministration.meterList.data
        })
    },
    changeBreakName(data) {
      this.breakName = data
    },
    // open 登记弹框
    openPopup() {
      // console.log(this.buildingId);
      if (this.buildingId > 10000) {
        // 客户单位直接从树上传递过来，不用用户填写
        this.submitBuildingInfo.ownerName = this.customerName
        this.loopList = []
        this.signInfo = '登记'
        this.dialogVisible = true
        this.loopsLen = 0
        this.$store.dispatch('locationBuilding', this.buildingId).then(() => {
          this.allBuilding = this.$store.state.metrAdministration.bigBuildingList.data
          // console.log(this.allBuilding);
        })
      }
    },
    //发送请求##### 导出表格(临时)
    exportFile() {
      let params = {
        keyword: this.keyWords,
        type: this.metrName,
        ownerId: this.submitBuildingInfo.ownerId,
      }
      // this.$store.dispatch("exportMeterFile", params)
      exportEnergyMeterReport(params).then((response) => {
        var blob = new Blob([response], {
          type: 'application/vnd.ms-excel,charset=utf-8',
        }) //创建一个blob对象
        var a = document.createElement('a') //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob) // response is a blob
        a.download = `仪表管理.xls` //文件名称
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    },

    //点击《导入》打开公用组件弹层
    importFile() {
      this.$refs.dialogImportRef.show({
        name: 'meter',
        type: this.metrName == '' ? 2 : this.metrName,
        ownerId: this.submitBuildingInfo.ownerId,
      })
    },
    //发送请求##### 被子组件调用，下载示例模板
    downLoad(name, type) {
      let value = {
        name: 'meter',
        type: type,
        ownerId: this.submitBuildingInfo.ownerId,
      }

      this.$store.dispatch('getDownloadTemplate', value)
    },

    // 删除列表
    deleteList(x) {
      this.$confirm('您确认要执行删除操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$store.dispatch('delete', x.id).then(() => {
            let tips = this.deleteBackInfo.message
            this.$message({
              type: 'success',
              message: tips == null ? '删除完成' : tips,
            })
            this.allListPage()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 仪表管理列表
    allListPage() {
      this.$store
        .dispatch('metrPage', {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          keyword: this.keyWords,
          type: this.metrName,
          ownerId: this.submitBuildingInfo.ownerId,
        })
        .then(() => {
          this.navigationList = this.metrListInfo
          console.log("111",this.navigationList);
        })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.allListPage()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.allListPage()
    },
    queryData() {
      this.allListPage()
    },
    // 获取DOM树上的id,用来获取楼栋，楼层
    changeTreeNode(e) {
      this.customerName = e.name
      this.buildingId = e.id
      this.submitBuildingInfo.ownerId = e.id
      this.pageNum = 1
      this.pageSize = 10
      this.keyWords = ''
      this.metrName = ''
      this.allListPage()
      this.getTreeLocationByCustomerId(e.id)
    },
    getLayer(e) {
      this.submitBuildingInfo.locationId = e
      this.$store.dispatch('locationSub', e).then(() => {
        this.buildingLayerList = this.layerList.data
      })
      this.showRed6 = false
    },
    getLayer1(e) {
      // console.log(e);
    },
    initBuildingFloorRoom(buildingId, floorId, roomId) {
      if (buildingId && !floorId && !roomId) {
        this.$store
          .dispatch('locationBuilding', this.submitBuildingInfo.ownerId)
          .then(() => {
            this.allBuilding = this.$store.state.metrAdministration.bigBuildingList.data
            this.submitBuildingInfo.buildingName = buildingId
          })
      } else if (buildingId && floorId && !roomId) {
        this.$store
          .dispatch('locationBuilding', this.submitBuildingInfo.ownerId)
          .then(() => {
            this.allBuilding = this.$store.state.metrAdministration.bigBuildingList.data
            this.submitBuildingInfo.buildingName = buildingId
          })
        setTimeout(() => {
          //this.$store.dispatch("locationSub", this.submitBuildingInfo.ownerId).then(() => {
          this.$store.dispatch('locationSub', buildingId).then(() => {
            this.buildingLayerList = this.layerList.data
            this.submitBuildingInfo.floorName = floorId
          })
        }, 500)
      } else if (buildingId && floorId && roomId) {
        this.$store
          .dispatch('locationBuilding', this.submitBuildingInfo.ownerId)
          .then(() => {
            this.allBuilding = this.$store.state.metrAdministration.bigBuildingList.data
            this.submitBuildingInfo.buildingName = buildingId
          })
        setTimeout(() => {
          this.$store.dispatch('locationSub', buildingId).then(() => {
            this.buildingLayerList = this.layerList.data
            this.submitBuildingInfo.floorName = floorId
          })
        }, 500)
        setTimeout(() => {
          this.$store.dispatch('getRoomList', floorId).then(() => {
            this.allRoomInfoList = this.allRoomList.data
            this.submitBuildingInfo.roomName = roomId
          })
        }, 1000)
      } else {
        this.$store
          .dispatch('locationBuilding', this.submitBuildingInfo.ownerId)
          .then(() => {
            this.allBuilding = this.$store.state.metrAdministration.bigBuildingList.data
          })
      }
    },
    // 编辑列表
    editMetr(x) {
      this.$store.dispatch('getOneMeter', x).then(() => {
        let x = this.$store.state.metrAdministration.getOneMeter.data
        // console.log(x);
        // this.buildingId = x.ownerId;
        this.anticipateEditInfo = x
        this.loopList = []
        // console.log(x.id); //这个是列表返回的信息
        /* 
                  signInfo 等于编辑的时候，才显示
              */
        this.signInfo = '编辑'
        // 这一部分是回显的时用的数据star
        this.powertypeList.forEach((item, index) => {
          if (x.powerType == item.label) {
            this.submitBuildingInfo.powerType = item.value
          }
        })
        this.submitBuildingInfo.sourceType = x.sourceType
        this.submitBuildingInfo.meterId = x.meterId
        this.submitBuildingInfo.code = x.code
        this.submitBuildingInfo.name = x.name
        this.submitBuildingInfo.type = x.type == 2 ? '电能表' : '电气消防电表'
        this.submitBuildingInfo.ownerName = x.ownerName
        this.submitBuildingInfo.powerType = x.powerType
        this.submitBuildingInfo.ownerId = x.ownerId
        this.submitBuildingInfo.maxMetric = x.maxMetric
        this.submitBuildingInfo.installationSite = x.installationSite
        this.editId = x.id
        this.editOwnerId = x.ownerId
        // 所属楼栋、所属层室、所属房间不能用这种方法来获取，需要单独写一个方法来获取。
        // this.submitBuildingInfo.buildingName = x.buildingName;
        // this.submitBuildingInfo.floorName = x.floorName;

        // 根据当前客户ID查询位置树
        this.$store.dispatch("getCustomerTreeByCustomerId", x.ownerId).then(() => {
          this.options = this.customerTreeAllNode;
          this.submitBuildingInfo.locationName = x.locationName
          this.submitBuildingInfo.locationId = x.locationId
          this.$refs.teeLocationRef.valueName = x.locationName
        })

        this.getMeterList()
        // this.initBuildingFloorRoom(x.buildingId, x.floorId, x.roomId)
        this.loopList = x.circuits
        this.loopsLen = this.loopList.length
        // if (x.circuits !== null) {
        //   for (let i = 0; i < x.circuits.length; i++) {
        //     let obj = {
        //       id: i + 1,
        //       name: x.circuits[i].name,
        //     };
        //     this.loopList.push(obj);
        //   }
        //   this.loopsLen = this.loopList.length;
        // }
        // 这一部分是回显的时用的数据end

        this.dialogVisible = true
      })
    },
    saveEditInfo() {
      if (!this.submitBuildingInfo.sourceType) {
        this.showRed9 = true
        return
      } else if (
        this.submitBuildingInfo.sourceType == 1 &&
        !this.submitBuildingInfo.meterId
      ) {
        this.showRed10 = true
        return
      }
      if (!this.submitBuildingInfo.code) {
        this.showRed1 = true
        return
      }
      if (!this.submitBuildingInfo.name) {
        this.showRed2 = true
        return
      }
      if (!this.submitBuildingInfo.type) {
        this.showRed3 = true
        return
      }
      if (
        this.submitBuildingInfo.type == 3 ||
        this.submitBuildingInfo.type == '电气消防电表'
      ) {
        // 校验数据
        if (!this.submitBuildingInfo.ownerName) {
          this.showRed5 = true
          return
        }
        if (!this.loopList.length && !this.addOneLoop) {
          this.showRed8 = true
          return
        }
        if (this.addOneLoop) {
          let obj = {
            id: this.loopsLen + 1,
            name: this.addOneLoop,
          }
          this.loopList.push(obj)
          this.addOneLoop = ''
        }
        let circuits = []
        for (let i = 0; i < this.loopList.length; i++) {
          if (parseInt(this.loopList[i].id) > 1000) {
            let obj = {
              id: this.loopList[i].id,
              name: this.loopList[i].name,
            }
            circuits.push(obj)
          } else {
            let obj = {
              name: this.loopList[i].name,
            }
            circuits.push(obj)
          }
        }
        let obj = {
          sourceType: this.submitBuildingInfo.sourceType,
          meterId: this.submitBuildingInfo.meterId,
          code: this.submitBuildingInfo.code,
          name: this.submitBuildingInfo.name,
          type: this.submitBuildingInfo.type == '电能表' ? 2 : 3,
          circuits: circuits,
          id: this.editId,
          ownerId: this.editOwnerId,
          maxMetric: this.submitBuildingInfo.maxMetric,
          installationSite: this.submitBuildingInfo.installationSite
        }
        // 保存数据
        this.$store.dispatch('editMetr', obj).then(() => {
          if (this.$store.state.metrAdministration.editInfo.data) {
            this.$message({
              message: '编辑成功！',
              type: 'success',
            })
            this.addLoopShow = false
            this.dialogVisible = false
            this.initBlur()
            this.allListPage()
            this.clearSubmitBuildingInfo(this.submitBuildingInfo)
          } else {
            this.$message({
              message: this.$store.state.metrAdministration.editInfo.message,
              type: 'warning',
            })
          }
        })
      } else if (
        this.submitBuildingInfo.type == 2 ||
        this.submitBuildingInfo.type == '电能表'
      ) {
        // 校验数据
        if (!this.submitBuildingInfo.ownerName) {
          this.showRed5 = true
          return
        }
        if (!this.submitBuildingInfo.locationName) {
          this.showRed6 = true
          return
        }
        if (!this.submitBuildingInfo.powerType) {
          this.showRed4 = true
          return
        }
        if (!this.loopList.length && !this.addOneLoop) {
          this.showRed8 = true
          return
        }
        if (this.addOneLoop) {
          let obj = {
            id: this.loopsLen + 1,
            name: this.addOneLoop,
          }
          this.loopList.push(obj)
          this.addOneLoop = ''
        }
        let circuits = []
        for (let i = 0; i < this.loopList.length; i++) {
          if (parseInt(this.loopList[i].id) > 1000) {
            let obj = {
              id: this.loopList[i].id,
              name: this.loopList[i].name,
            }
            circuits.push(obj)
          } else {
            let obj = {
              name: this.loopList[i].name,
            }
            circuits.push(obj)
          }
        }
        // 获取locationId的规则
        let locationId = this.submitBuildingInfo.locationId
        let obj = {
          sourceType: this.submitBuildingInfo.sourceType,
          meterId: this.submitBuildingInfo.meterId,
          code: this.submitBuildingInfo.code,
          name: this.submitBuildingInfo.name,
          type:
            this.submitBuildingInfo.type == '电能表' ||
            this.submitBuildingInfo.type == 2
              ? 2
              : 3,
          powerType: this.submitBuildingInfo.powerType,
          locationId: locationId,
          circuits: circuits,
          id: this.editId,
          ownerId: this.editOwnerId,
          maxMetric: this.submitBuildingInfo.maxMetric,
          installationSite: this.submitBuildingInfo.installationSite
        }
        // 保存数据
        this.$store.dispatch('editMetr', obj).then(() => {
          if (this.$store.state.metrAdministration.editInfo.data) {
            this.$message({
              message: '编辑成功！',
              type: 'success',
            })
            this.addLoopShow = false
            this.dialogVisible = false
            this.initBlur()
            this.allListPage()
            this.clearSubmitBuildingInfo(this.submitBuildingInfo)
          } else {
            this.$message({
              message: this.$store.state.metrAdministration.editInfo.message,
              type: 'warning',
            })
          }
        })
      }
    },
    editelectricype(lable) {
      // console.log(this.submitBuildingInfo.powerType);
      if (this.submitBuildingInfo.powerType == '') {
        this.showRed4 = true
      } else {
        this.showRed4 = false
      }
    },
    getRoomlist(x) {
      this.submitBuildingInfo.locationId = x
      this.submitBuildingInfo.roomName = ''
      this.allRoomInfoList = []
      this.$store.dispatch('getRoomList', x).then(() => {
        this.allRoomInfoList = this.allRoomList.data
      })
    },
    getLocationId(e) {
      this.submitBuildingInfo.locationId = e
    },
    editMertInfoList() {
      this.initBlur()
      if (!this.submitBuildingInfo.sourceType) {
        this.showRed9 = true
        return
      } else if (
        this.submitBuildingInfo.sourceType == 1 &&
        !this.submitBuildingInfo.meterId
      ) {
        this.showRed10 = true
        return
      }
      if (!this.submitBuildingInfo.code) {
        this.showRed1 = true
        return
      }
      if (!this.submitBuildingInfo.name) {
        this.showRed2 = true
        return
      }
      if (!this.submitBuildingInfo.type) {
        this.showRed3 = true
        return
      }
      if (this.submitBuildingInfo.type == 3) {
        // 校验数据
        if (!this.submitBuildingInfo.ownerName) {
          this.showRed5 = true
          return
        }
        if (!this.loopList.length && !this.addOneLoop) {
          this.showRed8 = true
          return
        }
        if (this.addOneLoop) {
          let obj = {
            id: this.loopsLen + 1,
            name: this.addOneLoop,
          }
          this.loopList.push(obj)
          this.addOneLoop = ''
        }
        let circuits = []
        for (let i = 0; i < this.loopList.length; i++) {
          let obj = {
            name: this.loopList[i].name,
          }
          circuits.push(obj)
        }
        let obj = {
          sourceType: this.submitBuildingInfo.sourceType,
          meterId: this.submitBuildingInfo.meterId,
          code: this.submitBuildingInfo.code,
          name: this.submitBuildingInfo.name,
          type: this.submitBuildingInfo.type,
          ownerId: this.submitBuildingInfo.ownerId,
          circuits: circuits,
          maxMetric: this.submitBuildingInfo.maxMetric,
          installationSite: this.submitBuildingInfo.installationSite
        }
        // 保存数据
        this.$store.dispatch('eneryMeter', obj).then(() => {
          if (this.$store.state.metrAdministration.postMeter.data) {
            this.$message({
              message: '登记成功！',
              type: 'success',
            })
            this.addLoopShow = false
            this.dialogVisible = false
            this.initBlur()
            this.allListPage()
            this.clearSubmitBuildingInfo(this.submitBuildingInfo)
          } else {
            this.$message({
              message: this.$store.state.metrAdministration.postMeter.message,
              type: 'warning',
            })
          }
        })
      } else if (this.submitBuildingInfo.type == 2) {
        // 校验数据
        if (!this.submitBuildingInfo.ownerName) {
          this.showRed5 = true
          return
        }
        if (!this.submitBuildingInfo.locationName) {
          this.showRed6 = true
          return
        }
        if (!this.submitBuildingInfo.powerType) {
          this.showRed4 = true
          return
        }
        if (!this.loopList.length && !this.addOneLoop) {
          this.showRed8 = true
          return
        }
        if (this.addOneLoop) {
          let obj = {
            id: this.loopsLen + 1,
            name: this.addOneLoop,
          }
          this.loopList.push(obj)
          this.addOneLoop = ''
        }
        let circuits = []
        for (let i = 0; i < this.loopList.length; i++) {
          let obj = {
            name: this.loopList[i].name,
          }
          circuits.push(obj)
        }
        // 获取locationId的规则
        let locationId = this.submitBuildingInfo.locationId
        let obj = {
          sourceType: this.submitBuildingInfo.sourceType,
          meterId: this.submitBuildingInfo.meterId,
          code: this.submitBuildingInfo.code,
          name: this.submitBuildingInfo.name,
          type: this.submitBuildingInfo.type,
          ownerId: this.submitBuildingInfo.ownerId,
          powerType: this.submitBuildingInfo.powerType,
          locationId: locationId,
          circuits: circuits,
          maxMetric: this.submitBuildingInfo.maxMetric,
          installationSite: this.submitBuildingInfo.installationSite
        }
        // 保存数据
        this.$store.dispatch('eneryMeter', obj).then(() => {
          if (this.$store.state.metrAdministration.postMeter.data) {
            this.$message({
              message: '登记成功！',
              type: 'success',
            })
            this.addLoopShow = false
            this.dialogVisible = false
            this.initBlur()
            this.allListPage()
            this.clearSubmitBuildingInfo(this.submitBuildingInfo)
          } else {
            this.$message({
              message: this.$store.state.metrAdministration.postMeter.message,
              type: 'warning',
            })
          }
        })
      }
    },
    clearSubmitBuildingInfo(obj) {
      for (let key in obj) {
        if (key !== 'ownerId') {
          obj[key] = ''
        }
      }
      this.loopList = []
      this.addOneLoop = ''
      this.submitBuildingInfo.type == 2
      this.$refs.teeLocationRef.valueName = ''
    },
    cancelEdit() {
      this.addLoopShow = false
      this.dialogVisible = false
      this.clearSubmitBuildingInfo(this.submitBuildingInfo)
      this.initBlur()
    },
    handleClose() {
      this.addLoopShow = false
      this.dialogVisible = false
      this.clearSubmitBuildingInfo(this.submitBuildingInfo)
      this.initBlur()
    },
    addLoop() {
      this.addLoopShow = true
      if (this.addOneLoop) {
        let id = this.loopsLen + 1
        let obj = {
          id: id,
          name: this.addOneLoop,
        }
        this.loopList.push(obj)
        this.addOneLoop = ''
      }
    },
    deleteLoop(idx) {
      for (let i = 0; i < this.loopList.length; i++) {
        if (this.loopList[i].id == idx) {
          this.loopList.splice(i, 1)
        }
      }
    },
    dialogClose() {
      this.clearSubmitBuildingInfo(this.submitBuildingInfo)
      this.loopList = []
      this.addLoopShow = false
      this.addOneLoop = ''
      this.dialogVisible = false
    },
    handleChangeSourceType(val) {
      if (val == '1') {
        this.getMeterList()
      }
      this.showRed9 = false
    },
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
// 板块二##############################################################
.blockTwo {
  //   height: 96.8rem;
  //   width: 171.2rem;
  //   position: relative;
  //   background-color: #18191a;
}

// 板块二左侧#####################################################
.blockTwoLeft {
  //   overflow: hidden;
  //   width: 26rem;
  //   height: 96.8rem;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
}

// 板块二右侧#####################################################
.blockTwoRight {
  //   width: 142.8rem;
  height: 96.8rem;
  //   position: absolute;
  //   left: 28.4rem;
  //   top: 0;
  // background-color: pink;
}
.equipmentTablePlace {
  height: 86.4rem;
  //   width: 142.8rem;
  width: 100%;
  //   position: absolute;
  //   top: 10.5rem;
  //   left: 28.6rem;
  overflow: hidden;
  background-color: #0d3050;
  // padding: 2.4rem;
  box-sizing: border-box;
}
.el-select-dropdown__item {
  padding-left: 2rem !important;
}

// 公共样式satr
.color {
  // color: #ffffff;
  color: #c0c4cc;
}
.color1 {
  // color: #ffffff;
  color: #ffffff;
}
.color2 {
  color: #03d9bc;
}
.color3 {
  color: rgba(255, 255, 255, 0.85);
}
.color4 {
  color: #ff4d4f;
}
.fontSize {
  font-size: 1.4rem;
}
.fontSize1 {
  font-size: 1.6rem;
}
.eleInput {
  width: 16.8rem;
  height: 3.2rem;
}
.marginR {
  margin-right: 0.8rem;
}
.marginRc {
  margin-right: 0.4rem;
}
.marginTop {
  margin-top: 0.8rem;
}
.marginTop1 {
  margin-top: 2rem;
}
.marginR1 {
  margin-right: 1.2rem;
}
.cursor {
  cursor: pointer;
}
.displayinline {
  display: inline-block;
}
/deep/ .el-input__inner {
  height: 3.2rem !important;
}
/deep/.el-input__icon {
  line-height: 3.2rem;
}
// 公共样式end
// nav satr
.search-box {
  padding: 0 2.4rem;
  height: 8rem !important;
  line-height: 8rem;
  box-sizing: border-box;
  background: #0d3050;
  border-radius: 0.4rem;
  width: 100%;
  margin-bottom: 2.4rem;
}
.btn {
  width: 7.4rem;
  height: 3.2rem;
  background-color: #027969;
  border-radius: 0.2rem;
  border: none;
  cursor: pointer;
}
.btn2Gray {
  color: gray;
  cursor: not-allowed;
}
.djBgc {
  background-color: gray;
  color: #fff;
}
.djpink {
  background-color: #027969;
  color: #fff;
}
.exportButton {
  width: 7.4rem;
  height: 3.2rem;
  border-radius: 0.2rem;
  border: 0.1rem solid #004579;
  background-color: rgba(0, 115, 222, 0.1);
  cursor: pointer;
}
.btnBox {
  float: right !important;
}
.tableBox {
  // margin-top: 2.5rem;
  height: 59rem;
  padding: 0 2.4rem !important;
  display: flex;
  flex-direction: column;
}
.navigation {
  display: flex;
  background-color: rgb(12, 55, 94);
  // justify-content: space-between;
  // color: rgba(255, 255, 255, 0.85);
}
.grey {
  color: rgba(255, 255, 255, 0.85);
}
.borderBottom {
  border-bottom: 0.1rem solid rgba(108, 184, 255, 0.3);
  background: rgba(52, 150, 241, 0.15);
  // margin-right: 0.1rem;
}
.border-bototm {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
}
.height {
  height: 5.2rem;
  text-align: left;
  line-height: 5.2rem;
  box-sizing: border-box;
  padding-left: 0.8rem;
  // margin-right: 0.1rem;
}

.bgc1 {
  background: linear-gradient(90deg, #0d3050 0%, rgba(0, 10, 20, 0.7) 100%);
}
.bgc2 {
  background-color: #027969 !important;
}
.width1 {
  width: 10%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.width2 {
  width: 22%;
}
.width3 {
  width: 12.8%;
}
.width4 {
  width: 14.4%;
}
.width5 {
  width: 25.4%;
}
.width6 {
  width: 26.3%;
}
.width7 {
  width: 13.6%;
}
.width8 {
  width: 10.6%;
}
.width9 {
  width: 10.6%;
}
.pad1 {
  padding-left: 1.2rem;
}

// nav end
/deep/.el-drawer {
  width: 56.8rem !important;
  background: rgba(12, 55, 94, 1);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.content {
  // background-color: pink;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tab {
    background: linear-gradient(
      225deg,
      rgba(15, 36, 55, 0.0001) 0.03%,
      rgba(0, 115, 222, 0.7) 99.9%
    );
    border-radius: 0.4rem 0.4rem 0rem 0rem;
    padding: 1.6rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .imgSize {
      width: 1.31rem;
      height: 1.164rem;
      cursor: pointer;
    }
  }
  .subject {
    // flex: 1;
    padding: 0 2.4rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
  }
  .form_content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 3rem;
    .form-item {
      position: relative;
      margin-top: 2.5rem;
      margin-right: 1.5rem;
      width: calc((100% - 4.5rem) / 2);
    }
  }
}
.subjectSearch {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
}
.sujectINput {
  width: 100%;
  height: 3.2rem;
  border: 0.1rem solid #004579;
  border-radius: 0.2rem;
  outline: none;
  background-color: rgba(0, 115, 222, 0.1);
  color: rgba(255, 255, 255, 0.85);
  padding: 0.5rem 1.2rem;
  box-sizing: border-box;
}

// .sujectINput:hover {
//   border: 0.1rem solid rgba(0, 115, 222, 0.1) !important;
// }
/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__body {
  padding: 0 !important;
}
/deep/.el-dialog__headerbtn {
  top: 1.7rem;
}
.cancel {
  width: 7.4rem;
  height: 3.2rem;
  background: rgba(0, 115, 222, 0.1);
  border: 0.1rem solid #004579;
  box-sizing: border-box;
  border-radius: 0.2rem;
  cursor: pointer;
}
/deep/.el-select {
  width: 100%;
}

// popup
// 做限制，防止代码污染
.dialogImportFile {
  // 弹层标题
  .el-dialog .el-dialog__header {
    padding: 0;
    height: 5.4rem;
    line-height: 5.4rem;
    padding-left: 2.4rem;
    font-size: 1.6rem;
    background: linear-gradient(
      225deg,
      rgba(15, 36, 55, 0.0001) 0.03%,
      rgba(0, 115, 222, 0.7) 99.9%
    );
  }
  // 弹层字体
  .el-dialog__title {
    color: #fff;
  }
  // 按钮样式
  .confirmButton {
    background: #027969;
    color: #fff;
    padding: 0;
    width: 10.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    border: 1px solid #027969;
    cursor: pointer;
  }
  .cancelButton {
    background: #0c375e;
    color: #fff;
    padding: 0;
    width: 10.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    border: 0.1rem solid #004579;
    cursor: pointer;
    margin-left: 1.5rem;
  }

  .el-select-dropdown__item {
    padding-left: 2rem !important;
  }

  .word {
    height: 2rem;
    line-height: 5rem;
    color: #aab9c6;
    font-size: 1.2rem;
  }
}
.sh {
  /*规定段落中的文本不进行换行：*/
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow属性表示规定当文本溢出包含元素时发生的事情;
            ellipsis表示显示省略符号来代表被修剪的文本。*/
  text-overflow: ellipsis;
}
.monitorLoop {
  margin: 0 2.4rem 0 2.4rem;
  .title {
    font-size: 1.4rem;
    color: hsla(0, 0%, 100%, 0.85);
    margin-top: 2rem;
    margin-bottom: 1.6rem;
    .required {
      margin-top: 1.8rem;
      color: #f56c6c;
      margin-right: 0.4rem;
    }
  }
  .loops {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 90%;
    div {
      display: inline-block;
      font-size: 1.4rem;
      color: #2cd9ff;
      margin-right: 0.8rem;
      margin-bottom: 0.8rem;
    }
    .loopItem {
      width: 14.5rem;
      height: 3.2rem;
      line-height: 3rem;
      background-color: rgba(52, 150, 241, 0.15);
      border: 0.1rem dashed rgba(108, 184, 255, 0.3);
      border-radius: 0.4rem;
      padding-left: 0.6rem;
      box-sizing: border-box;
      span {
        float: right;
        margin-right: 0.6rem;
        cursor: pointer;
      }
    }
    .addBtn {
      width: 13.5rem;
      height: 3.2rem;
    }
    .addLoop {
      width: 3.2rem;
      height: 3rem;
      line-height: 3rem;
      text-align: center;
      background-color: rgba(52, 150, 241, 0.15);
      border: 0.1rem dashed rgba(108, 184, 255, 0.3);
      border-radius: 0.4rem;
    }
  }
}
</style>
<style lang="less" scoped>
.equipmentMeters {
  .inputRed {
    border-color: #f56c6c;
    .el-input__inner {
      border-color: #f56c6c;
    }
  }
  .el-input__inner {
    background: rgba(0, 115, 222, 0.1) !important;
    &::placeholder {
      color: rgba(255, 255, 255, 0.45);
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.45);
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 适配火狐 */
      color: rgba(255, 255, 255, 0.45);
      /* 设置opacity  解决firefox不生效问题 */
      opacity: 1;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ 适配火狐 */
      color: rgba(255, 255, 255, 0.45);
      opacity: 1;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+  适配ie*/
      color: rgba(255, 255, 255, 0.45);
    }
  }
  .loops {
    .el-input__inner {
      padding: 0 0.5rem !important;
    }
  }
  .dialogImportFile {
    /deep/ .el-dialog__body {
      padding: 30px 20px !important;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    /deep/ .el-dialog .el-dialog__header {
      padding: 0;
      height: 5.4rem;
      line-height: 5.4rem;
      padding-left: 2.4rem;
      font-size: 1.6rem;
      background: linear-gradient(
        225deg,
        rgba(15, 36, 55, 0.0001) 0.03%,
        rgba(0, 115, 222, 0.7) 99.9%
      );
      display: block !important;
    }
  }
  /deep/.el-input-number{
        width:100%;
        .el-input-number__decrease{
            background: #0b3d6b;
            border-left: none;
        }
        .el-input-number__increase{
            border-left: none;
            border-bottom: none;
            background: #0b3d6b;
        }
        .el-input-number__decrease, .el-input-number__increase{
            color: #C0C4CC;
        }
        .el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
            color: #606266;
        }
    }
}
</style>
