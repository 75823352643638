<template>
    <div>
        <!-- 上班的上班时间柱状图 -->
        <div id="myChart26" class="c1"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    data () {
        return {
            customerId: "",
            xdata: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
            ydata: "",
        };
    },
    created () {
        console.log('******')
        // this.initData();
    },
    computed: {},
    mounted () { },
    methods: {
        init () {
            this.drawLine1();
        },
        initData () {
            this.customerId = this.$store.state.storage.customerId;
            this.$store.dispatch("getCurrentGeneration", this.customerId).then(() => {
                this.ydata = this.$store.state.genManagement.getCurrentGeneration.data.data;
                this.drawLine1();
            });
        },
        drawLine1 () {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("myChart26"));
            // 绘制图表
            myChart.setOption({
                title: {
                    text: "",
                    itemGap: 10,
                    top: "8px",
                    left: "0px",
                    textStyle: {
                        color: "#37A5DD",
                        fontSize: 14,
                        lineHeight: 10,
                        fontWeight: 400,
                    },
                    subtextStyle: { color: "rgba(230, 247, 255, 0.7)", fontSize: 12 },
                },
                legend: {
                    orient: "horizontal", //一排或者一列
                    x: "center", //可设定图例在左、右、居中
                    y: "bottom", //可设定图例在上、下、居中
                    padding: [0, 0, 20, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                    textStyle: { color: "rgba(255, 255, 255, 0.85)" },
                },
                tooltip: {},
                grid: {
                    top: "25%",
                    left: "5.5%",
                    right: "5%",
                    bottom: "6%",
                    containLabel: true,
                },
                // dataset: {
                //   source: [
                //     ["10.1", 43.3],
                //     ["10.2", 83.1],
                //     ["10.3", 86.4],
                //     ["10.4", 72.4],
                //     ["10.5", 143.3],
                //     ["10.6", 183.1],
                //     ["10.7", 86.4],
                //     ["10.8", 172.4],
                //     ["10.9", 86.4],
                //     ["10.10", 72.4],
                //   ],
                // },
                xAxis: {
                    type: "category",
                    interval: 0,
                    axisLabel: {
                        color: "#fafafa",
                        fontSize: "10",
                    },
                    data: this.xdata
                },
                yAxis: [
                    {
                        name: "单位:kWh",
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: "#1F4A72",
                            },
                        },
                        axisLabel: {
                            color: "#fafafa",
                            fontSize: "10",
                        },
                    },
                ],
                series: [
                    {
                        type: "bar",
                        barWidth: 10,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "#ffd357",
                                    },
                                    {
                                        offset: 1,
                                        color: "#ffa82c",
                                    },
                                ]),
                            },
                        },
                        data: this.ydata
                    },
                ],
            });
            //   myChart._dom.childNodes[0].childNodes[0].style.width = "44rem";
            myChart._dom.childNodes[0].childNodes[0].style.height = "36rem";
        },

    },
    watch: {
        "$store.state.storage.customerId": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                this.customerId = newVal;
                this.initData();
            },
        },
    },
};
</script>

<style lang="less" scoped>
.c1 {
    margin: 0 auto !important;
    width: 44rem;
    height: 35rem;
}
</style>
