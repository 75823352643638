<template>
  <div class="huBeiBottom">
    <!-- 最下面一排盒子########################################################################## -->
      <div class="bottom1">
        <!-- 盒子分割 -->
        <div class="box" style="margin-right:1.2rem">
          <div class="word1">碳排放量(kg)</div>
          <div class="word2">{{myData.carbonEmissions.useEnergy}}</div>
          <div class="line"></div>
          <div :class="(myData.carbonEmissions.useEnergy-myData.carbonEmissions.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.carbonEmissions.yesterdayEnergy != 0 ? (((myData.carbonEmissions.useEnergy-myData.carbonEmissions.yesterdayEnergy)/myData.carbonEmissions.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.carbonEmissions.useEnergy-myData.carbonEmissions.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.carbonEmissions.useEnergy-myData.carbonEmissions.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.carbonEmissions.useEnergy-myData.carbonEmissions.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
            </span></div>
        </div>

        <!-- 盒子分割 -->
        <div class="box" style="margin-right:5rem">
          <div class="word1">标准煤(kg)</div>
          <div class="word2">{{myData.standardCoals.useEnergy}}</div>
          <div class="line"></div>
          <div :class="(myData.standardCoals.useEnergy-myData.standardCoals.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.standardCoals.yesterdayEnergy != 0 ? (((myData.standardCoals.useEnergy-myData.standardCoals.yesterdayEnergy)/myData.standardCoals.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.standardCoals.useEnergy-myData.standardCoals.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.standardCoals.useEnergy-myData.standardCoals.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.standardCoals.useEnergy-myData.standardCoals.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
            </span></div>
          </div>

        <!-- 盒子分割 -->
        <div class="boxGreen" style="margin-right:1.2rem"> 
          <div class="word1">充电站(个)</div>
          <div class="word2">{{myData.rechargeStationNumber.useEnergy}}</div>
          <div class="line"></div>
           <div :class="(myData.rechargeStationNumber.useEnergy-myData.rechargeStationNumber.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.rechargeStationNumber.yesterdayEnergy != 0 ? (((myData.rechargeStationNumber.useEnergy-myData.rechargeStationNumber.yesterdayEnergy)/myData.rechargeStationNumber.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.rechargeStationNumber.useEnergy-myData.rechargeStationNumber.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.rechargeStationNumber.useEnergy-myData.rechargeStationNumber.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.rechargeStationNumber.useEnergy-myData.rechargeStationNumber.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
            </span></div>
          </div>

        <!-- 盒子分割 --> 
        <div class="boxGreen" style="margin-right:1.2rem;width:13.9rem">
          <div class="word1">总充电服务次数(次)</div>
          <div class="word2">{{myData.rechargeServiceCount.useEnergy}}</div>
          <div class="line" style="width:11.5rem"></div>
          <div :class="(myData.rechargeServiceCount.useEnergy-myData.rechargeServiceCount.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.rechargeServiceCount.yesterdayEnergy != 0 ? (((myData.rechargeServiceCount.useEnergy-myData.rechargeServiceCount.yesterdayEnergy)/myData.rechargeServiceCount.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.rechargeServiceCount.useEnergy-myData.rechargeServiceCount.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.rechargeServiceCount.useEnergy-myData.rechargeServiceCount.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.rechargeServiceCount.useEnergy-myData.rechargeServiceCount.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
            </span></div> 
          </div>
        

        <!-- 盒子分割 --> 
        <div class="boxGreen" style="margin-right:5rem;width:12.3rem">
          <div class="word1">充电桩个数(个)</div>
          <div class="word2">{{myData.rechargePileNumber.useEnergy}}</div>
          <div class="line" style="width:9.9rem"></div>
          <div :class="(myData.rechargePileNumber.useEnergy-myData.rechargePileNumber.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.rechargePileNumber.yesterdayEnergy != 0 ? (((myData.rechargePileNumber.useEnergy-myData.rechargePileNumber.yesterdayEnergy)/myData.rechargePileNumber.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.rechargePileNumber.useEnergy-myData.rechargePileNumber.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.rechargePileNumber.useEnergy-myData.rechargePileNumber.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.rechargePileNumber.useEnergy-myData.rechargePileNumber.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
            </span></div> 
          </div>

    
          <!-- 盒子分割 --> 
        <div class="boxBlue" style="margin-right:1.2rem">
          <div class="word1">发电站(个)</div>
          <div class="word2">{{myData.generateStationNumber.useEnergy}}</div>
          <div class="line"></div>
          <div :class="(myData.generateStationNumber.useEnergy-myData.generateStationNumber.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.generateStationNumber.yesterdayEnergy != 0 ? (((myData.generateStationNumber.useEnergy-myData.generateStationNumber.yesterdayEnergy)/myData.generateStationNumber.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.generateStationNumber.useEnergy-myData.generateStationNumber.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.generateStationNumber.useEnergy-myData.generateStationNumber.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.generateStationNumber.useEnergy-myData.generateStationNumber.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
          </span></div> 
          </div>

         <!-- 盒子分割 --> 
        <div class="boxBlue" style="margin-right:1.2rem">
          <div class="word1">节约碳量(kg)</div>
          <div class="word2">{{myData.economizeCharcoal.useEnergy}}</div>
          <div class="line"></div>
          <div :class="(myData.economizeCharcoal.useEnergy-myData.economizeCharcoal.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.economizeCharcoal.yesterdayEnergy != 0 ? (((myData.economizeCharcoal.useEnergy-myData.economizeCharcoal.yesterdayEnergy)/myData.economizeCharcoal.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.economizeCharcoal.useEnergy-myData.economizeCharcoal.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.economizeCharcoal.useEnergy-myData.economizeCharcoal.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.economizeCharcoal.useEnergy-myData.economizeCharcoal.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
          </span></div>  
          </div>

         <!-- 盒子分割 --> 
        <div class="boxBlue">
          <div class="word1">节约煤量(kg)</div>
          <div class="word2">{{myData.economizeCoal.useEnergy}}</div>
          <div class="line"></div>
          <div :class="(myData.economizeCoal.useEnergy-myData.economizeCoal.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.economizeCoal.yesterdayEnergy != 0 ? (((myData.economizeCoal.useEnergy-myData.economizeCoal.yesterdayEnergy)/myData.economizeCoal.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.economizeCoal.useEnergy-myData.economizeCoal.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.economizeCoal.useEnergy-myData.economizeCoal.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.economizeCoal.useEnergy-myData.economizeCoal.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
          </span></div>  
          </div>


        
      </div>

      <!-- 第二排盒子####################################################################### -->
      <div class="bottom2">
          <!-- 盒子分割 --> 
          <!-- <img src="@/assets/FullView/red.png" alt="" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:10.5rem;opacity:0.5"> -->
        <div class="box" style="margin-right:16.7rem;height:11.6rem;background:none;position:relative">
          <img src="@/assets/FullView/red.png" alt="" class="cardImgChange" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:10.5rem">
          <div class="word1">用电量(kWh)</div>
          <div class="word2">{{myData.electricPowers.useEnergy}}</div>
          <div class="line"></div>
          <div :class="(myData.electricPowers.useEnergy-myData.electricPowers.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.electricPowers.yesterdayEnergy != 0 ? (((myData.electricPowers.useEnergy-myData.electricPowers.yesterdayEnergy)/myData.electricPowers.yesterdayEnergy)*100).toFixed(2) : 0}}
            <i v-show="(myData.electricPowers.useEnergy-myData.electricPowers.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.electricPowers.useEnergy-myData.electricPowers.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.electricPowers.useEnergy-myData.electricPowers.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
          </span></div>  
        </div>

          <!-- 盒子分割 -->
        <div class="boxGreen" style="margin-right:33.6rem;height:11.6rem;background:none;position:relative">
          <img src="@/assets/FullView/green.png" alt=""  class="cardImgChange" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:10.5rem">
          <div class="word1">充电量(kWh)</div>
          <div class="word2">{{myData.rechargePowers.useEnergy}}</div>
          <div class="line"></div>
          <div :class="(myData.rechargePowers.useEnergy-myData.rechargePowers.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.rechargePowers.yesterdayEnergy != 0 ? (((myData.rechargePowers.useEnergy-myData.rechargePowers.yesterdayEnergy)/myData.rechargePowers.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.rechargePowers.useEnergy-myData.rechargePowers.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.rechargePowers.useEnergy-myData.rechargePowers.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.rechargePowers.useEnergy-myData.rechargePowers.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
          </span></div>  
        </div>


         <!-- 盒子分割 -->
        <div class="boxBlue" style="height:11.6rem;background:none;position:relative">
          <img src="@/assets/FullView/blue.png" alt="" class="cardImgChange" style="position:absolute;top:-0.6rem;left:0;height:11.6rem;width:10.5rem">
          <div class="word1">发电量(kWh)</div>
          <div class="word2">{{myData.generateElectricityQuantity.useEnergy}}</div>
          <div class="line"></div>
          <div :class="(myData.generateElectricityQuantity.useEnergy-myData.generateElectricityQuantity.yesterdayEnergy)>=0?'word4Red':'word4Green'">环比：<span class="word4Inner">{{myData.generateElectricityQuantity.yesterdayEnergy != 0 ? (((myData.generateElectricityQuantity.useEnergy-myData.generateElectricityQuantity.yesterdayEnergy)/myData.generateElectricityQuantity.yesterdayEnergy)*100).toFixed(2) : 0}}% 
            <i v-show="(myData.generateElectricityQuantity.useEnergy-myData.generateElectricityQuantity.yesterdayEnergy)>0" class="el-icon-top-right" style="font-size:1.2rem"/>
            <i v-show="(myData.generateElectricityQuantity.useEnergy-myData.generateElectricityQuantity.yesterdayEnergy)<0" class="el-icon-bottom-right" style="font-size:1.2rem"/>
            <i v-show="(myData.generateElectricityQuantity.useEnergy-myData.generateElectricityQuantity.yesterdayEnergy)==0"  style="font-size:1.2rem">--</i>
          </span></div>  
         </div>

      </div>

  </div>
</template>

<script>
export default {
  
  name:'HuBeiBottom',
  props: {
    myData: {
      type: Object,
      default: () => {
        return {
          carbonEmissions:{useEnergy:"",yesterdayEnergy:"",}, 
          standardCoals:{useEnergy:"",yesterdayEnergy:"",}, 
          rechargeStationNumber:{useEnergy:"",yesterdayEnergy:"",},
          rechargeServiceCount:{useEnergy:"",yesterdayEnergy:"",}, 
          rechargePileNumber:{useEnergy:"",yesterdayEnergy:"",}, 
          generateStationNumber:{useEnergy:"",yesterdayEnergy:"",}, 
          economizeCharcoal:{useEnergy:"",yesterdayEnergy:"",}, 
          economizeCoal:{useEnergy:"",yesterdayEnergy:"",}, 
          electricPowers:{useEnergy:"",yesterdayEnergy:"",}, 
          rechargePowers:{useEnergy:"",yesterdayEnergy:"",}, 
          generateElectricityQuantity:{useEnergy:"",yesterdayEnergy:"",},
        }
      },
      require: true
    }
  },
  // data(){return{
  //   myData:{
  //     carbonEmissions:{useEnergy:"",yesterdayEnergy:"",}, 
  //     standardCoals:{useEnergy:"",yesterdayEnergy:"",}, 
  //     rechargeStationNumber:{useEnergy:"",yesterdayEnergy:"",},
  //     rechargeServiceCount:{useEnergy:"",yesterdayEnergy:"",}, 
  //     rechargePileNumber:{useEnergy:"",yesterdayEnergy:"",}, 
  //     generateStationNumber:{useEnergy:"",yesterdayEnergy:"",}, 
  //     economizeCharcoal:{useEnergy:"",yesterdayEnergy:"",}, 
  //     economizeCoal:{useEnergy:"",yesterdayEnergy:"",}, 
  //     electricPowers:{useEnergy:"",yesterdayEnergy:"",}, 
  //     rechargePowers:{useEnergy:"",yesterdayEnergy:"",}, 
  //     generateElectricityQuantity:{useEnergy:"",yesterdayEnergy:"",},
  //   },
  // }},
  // methods:{
  //   // 被父元素触发执行
  //   show(val){
  //     console.log(val)
  //     this.myData=val;
  //   }
  // }
}
</script>

<style lang='less' scoped>
.huBeiBottom{
  height: 42rem;
  width: 110rem;
  position: relative;
  bottom: 0;
  left: 0;
}

.bottom1{
    height: 10.9rem;
    width: 110rem;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
    
}


.bottom2{
    height: 11.6rem;
    width: 110rem;
    position: absolute;
    bottom: 12.1rem;
    left: 0;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
  
}



.box{
  height: 10.9rem;
  width: 10.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(255, 153, 0, 0.1);
  float: left;
    &:hover{
    background-color: rgba(255, 153, 0, 0.13);
  }
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #FF9900;
    text-shadow: 0 0.1rem 0.3rem rgba(255, 153, 0, 0.65);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #FF9900;
    margin-bottom: 1rem;
  }
 
}
.boxBlue{
  height: 10.9rem;
  width: 10.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(24, 249, 250, 0.1);
  float: left;
  &:hover{
    background-color: rgba(24, 249, 250, 0.13);
  }
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #18FEFE;
    text-shadow: 0 0.1rem 0.3rem rgba(0, 118, 203, 0.85);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #18FEFE;
    margin-bottom: 1rem;
  }
 
}
.boxGreen{
  height: 10.9rem;
  width: 10.5rem;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  box-sizing: border-box;
  background: rgba(54, 234, 175, 0.1);
  float: left;
  // cursor: pointer;
  &:hover{
    background-color: rgba(54, 234, 175, 0.13);
  }
  .word1{
    height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #36EAAF;
    text-shadow: 0 0.1rem 0.3rem rgba(54, 234, 175, 0.45);
    margin-bottom: 1rem;
  }
  .word2{
    height: 2.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #FBFBFB;
    text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
    margin-bottom: 0.6rem;
  }
  .line{
    height: 0;
    width: 8.1rem;
    border-top: 0.1rem solid #36EAAF;
    margin-bottom: 1rem;
  }
 
}




 .word4Red{
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    white-space: nowrap;
    .word4Inner{
      color: #fe6c2f;
    }
  }

  .word4Green{
    height: 1.7rem;
    font-size: 1.2rem;
    color: rgba(255,255,255,0.65);
    text-shadow: 0px 0.34rem 0.63rem rgba(0, 80, 203, 0.61);
    white-space: nowrap;
    .word4Inner{
      color: #39e5d0;
    }
  }


  .cardImgChange{
    opacity: 0.8;
    &:hover{
      opacity: 1;
    }
  }
</style>