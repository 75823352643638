<template>
  <div>
       <!-- ---------------------------------------------------------------------左侧板块一（用电负荷曲线图） ---------------------------------------------------------------------->
    <div class="leftOne">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">用电负荷曲线图</div>
      <!-- 图表 -->
      <div class="chartLeftOne" ref="leftOneRef"></div>
    </div>

    <!-- ---------------------------------------------------------------------左侧板块二（用电统计） ---------------------------------------------------------------------->
    <div class="leftTwo">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">用电统计</div>
      <!-- 图表 -->
      <div class="chartLeftTwo" ref="leftTwoRef"></div>
    </div>
    <!-- ---------------------------------------------------------------------左侧板块三（今日能耗排名） ---------------------------------------------------------------------->
    <div class="leftThree">
      <!-- 顶部 -->
      <img class="blockTitle" src="@/assets/FullView/blockTitle.png" alt="">
      <div class="blockTitleWord">今日能耗排名</div>
      <!-- 图表 -->
      <div class='chartLeftThree'>
          <div class="innerCard" v-for="(item,index) in optionLeftThree" :key="'card3'+index">
              <!-- 文字部分 -->
             <div class="wordPlace">
               <span class="left">No.{{index+1}} {{item.name}}</span>
               <span class="right">{{item.useEnergy}} kWh</span>
             </div>
             <!-- 进度条部分 -->
             <div class="barPlace">
               <div class="barGet" :style="barGetStyle[index]"></div>
               <div class="barLine" :style="barLineStyle[index]"></div>              
             </div>
          </div>

      </div>
    </div>

    <!-- --------------------------------------------------------------------  补充大板块 ---------------------------------------------------------------------->
    <div class="blockSup">
      <div :class="styleSup" >
        <img src="@/assets/FullView/charge.png" class="picLeft" alt="">
        <div class="wordLeft">{{more[0]}} </div>
      </div>
      
      <div :class="styleSup2">
        <div class="iconInner">
          <img src="@/assets/FullView/icon5.png" class="iconInnerImg" alt="">
          <div class="iconInnerWord">辐照度</div>
        </div>
        <div class="iconInnerWord2"> {{more[1]}} <span class="innerSpan">W/m²</span></div>
      </div>


      <div :class="styleSup3">
        <div class="iconInner">
          <img src="@/assets/FullView/icon6.png" class="iconInnerImg" alt="">
          <div class="iconInnerWord">温湿度</div>
        </div>
        <div class="iconInnerWord2"> {{more[2]}} <span class="innerSpan">℃</span></div>
        <div class="iconInnerWord3"> {{more[3]}} <span class="innerSpan">%RH</span></div>
      </div>

      <div :class="styleSup4">
        <div class="iconInner">
          <img src="@/assets/FullView/icon7.png" class="iconInnerImg" alt="">
          <div class="iconInnerWord">风速</div>
        </div>
        <div class="iconInnerWord2"> {{more[4]}} <span class="innerSpan">m/s</span></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
    name:"HuBeiLeft",
    props: {
      myData: {
        type: Object,
        default: () => {
          return {}
        },
        require: true
      }
    },
    data(){return{
  // ############################################################################左侧第一个图数据 折线图#############################################################################
  optionLeftOne:{
    grid:{
        top:"15%",
        left:"10%",
        right:"3%",
        bottom:"12%"
    },
    xAxis: {
      type: 'category',
      // x轴刻度线
      axisTick: {show: false,},
      // x轴文字
      axisLabel: {
          formatter: '{value}',
          textStyle: {
            show:true,
            fontSize:this.fontSize(1.2),
            color: 'rgba(230, 247, 255, 0.5)',
            fontWeight:100,
          },
      },
      // x轴基线
      axisLine: {
        lineStyle: {
            color: '#bae7ff',
        }
      },
      data: [],
    },
    yAxis: {
      type: 'value',
      name: '（kW）',
      // y轴顶部文字
      nameTextStyle:{
        color:"rgba(230, 247, 255, 0.5)", 
        fontSize:this.fontSize(1.2), 
        fontWeight:100,
        padding: [0, 0, 0, -35]
      },
      // y轴文字
      axisLabel: {
          formatter: '{value}',
          textStyle: {
            show:true,
            fontSize:this.fontSize(1.2),
            color: 'rgba(230, 247, 255, 0.5)',
            fontWeight:100,
          },
      },
      // 分割线样式
      splitLine:{
        lineStyle:{
          type:'dashed',
          color:"rgba(230, 247, 255, 0.2)"
        }
      }
    },
    series: [
      { 
        
        symbol:'none',
        color:'#19e6ea',
        data: [],
        type: 'line'
      }
    ],
    tooltip: {
        extraCssText: "width:11rem;height:5.8rem",
        backgroundColor:'rgba(0,0,0,0.65)',
        borderColor:'rgba(0,0,0,0.65)',
        color:"#E6F7FF",
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          crossStyle: {
            color: '#999'
          }
        },
     formatter: function (params) {
        return '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">'+'时间：'+ params[0].name +'<br>' + '功率：' +'<span style="color:#18FEFE;">'+ params[0].data +'kW'+'</span>'+'</span>';
    }    
    
    },
  },
  // ############################################################################左侧第二个图数据 直方图#############################################################################
  optionLeftTwo:{
    grid:{
        top:"15%",
        left:"15%",
        right:"3%",
        bottom:"12%"
    },
    xAxis: {
      type: 'category',
      // x轴刻度线
      axisTick: {show: false,},
      // x轴文字
      axisLabel: {
          interval:0,
          formatter: '{value}',
          textStyle: {
            show:true,
            fontSize:this.fontSize(1.2),
            color: 'rgba(230, 247, 255, 0.5)',
            fontWeight:100,
          },
      },
      // x轴基线
      axisLine: {
        lineStyle: {
            color: '#bae7ff',
        }
      },
      data: [],
    },
    yAxis: {
      type: 'value',
      name: '（kWh）',
      // y轴顶部文字
      nameTextStyle:{
        color:"rgba(230, 247, 255, 0.5)", 
        fontSize:this.fontSize(1.2), 
        fontWeight:100,
        padding: [0, 0, 0, -35]
      },
      // y轴文字
      axisLabel: {
          formatter: '{value}',
          textStyle: {
            show:true,
            fontSize:this.fontSize(1.2),
            color: 'rgba(230, 247, 255, 0.5)',
            fontWeight:100,
          },
      },
      // 分割线样式
      splitLine:{
        lineStyle:{
          type:'dashed',
          color:"rgba(230, 247, 255, 0.2)"
        }
      }
    },
    series: [
      { 
        symbol:'none',
        barWidth:this.fontSize(1),
        color:'#19e6ea',
         // 设置渐变色
        itemStyle:{normal: {color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{offset: 0,color: '#F18F1C'},{offset: 1,color: '#EAE342'}]),}},
        data: [],
        type: 'bar'
      }
    ],
    tooltip: {
        extraCssText: "padding:0 1.5rem;height:5.8rem",
        backgroundColor:'rgba(0,0,0,0.65)',
        borderColor:'rgba(0,0,0,0.65)',
        color:"#E6F7FF",
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          crossStyle: {
            color: '#999'
          }
        },
     formatter: function (params) {
        return '<span style="color:#E6F7FF;line-height:2.8rem;font-size:1.2rem">'+'时间：'+ params[0].name +'<br>' + '用电：' +'<span style="color:#EAE342;">'+ params[0].data +'kWh'+'</span>'+'</span>';
    }    
    
    },
    

  },
  // ############################################################################左侧第三个图数据 自定义天梯图#############################################################################
  optionLeftThree:[
    {name:'--',useEnergy:0},
    {name:'--',useEnergy:0},
    {name:'--',useEnergy:0},
    {name:'--',useEnergy:0},
    {name:'--',useEnergy:0},
  ],

  //附加板块的数据
  more:[],

  // 天梯图的样式(颜色柱)
  barGetStyle:[{width:0,transition:'all 1s'},
              {width:0,transition:'all 1s'},
              {width:0,transition:'all 1s'},
              {width:0,transition:'all 1s'},
              {width:0,transition:'all 1s'},],
  // 天梯图的样式（白盖）
  barLineStyle:[{left:0,transition:'all 1s'},
              {left:0,transition:'all 1s'},
              {left:0,transition:'all 1s'},
              {left:0,transition:'all 1s'},
              {left:0,transition:'all 1s'},],
  
  
  // 补充板块的样式
  styleSup:'blockSupInsider',
  styleSup2:'blockSupInsider2',
  styleSup3:'blockSupInsider3',
  styleSup4:'blockSupInsider4',

    }},
    watch: {
      myData: {
        handler: function(newVal, oldVal) {
          this.show(newVal)
        },
        deep: true,
        // immediate: true
      }
    },


    mounted(){
    

    },


    methods:{
        // 获取屏幕比例，使echarts自适应
        fontSize(res){
            let docEl = document.documentElement,
            clientWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
            if (!clientWidth) return;
            let fontSize = (clientWidth / 192);
            return res*fontSize;
        },

        // 渲染 electricPowerConsumptionTimes electricPowerConsumption
        show(val){
          console.log(val, 'BothLeft')
          this.optionLeftOne.xAxis.data = val.times
          this.optionLeftOne.series[0].data = val.loadPower
          // 渲染左边第一个图（折线图）
          var myChartLeftOne = this.$echarts.init(this.$refs.leftOneRef);
          myChartLeftOne.setOption(this.optionLeftOne);
          myChartLeftOne._dom.childNodes[0].childNodes[0].style.height="23.5rem"
          myChartLeftOne._dom.childNodes[0].childNodes[0].style.width="37.8rem"

          this.optionLeftTwo.xAxis.data = val.electricPowerConsumptionTimes
          this.optionLeftTwo.series[0].data = val.electricPowerConsumption
          // 渲染左边第二个图（直方图）
          var myChartLeftTwo = this.$echarts.init(this.$refs.leftTwoRef);
          myChartLeftTwo.setOption(this.optionLeftTwo);
          myChartLeftTwo._dom.childNodes[0].childNodes[0].style.height="23.5rem"
          myChartLeftTwo._dom.childNodes[0].childNodes[0].style.width="37.8rem"
          

        
          // 渲染左边第三个图（自定义天梯图） 数据渲染、有色柱表现渲染、白色盖子表现渲染
          this.optionLeftThree=val.todayEnergyCount
          // 如果后台给的数据不足5个，就进行补充满5个
        if(val.todayEnergyCount && val.todayEnergyCount.length<5){
          let num = 5-val.todayEnergyCount.length
          for(let i=0;i<num;i++){
            this.optionLeftThree.push({name:'--',useEnergy:0},)
          }
        }
        if (this.optionLeftThree) {
          this.optionLeftThree.forEach((item, index) => {
            if (this.optionLeftThree[0].useEnergy !== 0) {
              this.barGetStyle[index] =  {width:(item.useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'}
              this.barLineStyle[index] =  {left:(item.useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'}
            } else {
              this.barGetStyle[index] =  {width:'0rem',transition:'all 1s'}
              this.barLineStyle[index] =  {left:'0rem',transition:'all 1s'}
            }
          })
          // this.barGetStyle =[
          //   // {width:'34.4rem',transition:'all 1s'},
          //   {width:(this.optionLeftThree[0].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          //   {width:(this.optionLeftThree[1].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          //   {width:(this.optionLeftThree[2].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          //   {width:(this.optionLeftThree[3].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          //   {width:(this.optionLeftThree[4].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          // ],
          // this.barLineStyle =[
          //   // {left:'34.4rem',transition:'all 1s'},
          //   {left:(this.optionLeftThree[0].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          //   {left:(this.optionLeftThree[1].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          //   {left:(this.optionLeftThree[2].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          //   {left:(this.optionLeftThree[3].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          //   {left:(this.optionLeftThree[4].useEnergy/this.optionLeftThree[0].useEnergy*34.4)+'rem',transition:'all 1s'},
          // ]
        }
          // this.styleSup='blockSupInsider'
          // this.styleSup2='blockSupInsider2'
          // this.styleSup3='blockSupInsider3'
          // this.styleSup4='blockSupInsider4'

          // 左边附加板块的数据
          this.more = [val.currentGenerated,val.fzd,val.hjwd,val.hjsd,val.fs]





          // // 补充板块的展示动画
          // setTimeout(()=>{
          //   this.styleSup='blockSupInsiderShow'
          //   setTimeout(()=>{this.styleSup2='blockSupInsiderShow2'},100)
          //   setTimeout(()=>{this.styleSup3='blockSupInsiderShow3'},200)
          //   setTimeout(()=>{this.styleSup4='blockSupInsiderShow4'},300)
          // },1000)

        },
        showAnimation() {
          this.styleSup='blockSupInsider'
          this.styleSup2='blockSupInsider2'
          this.styleSup3='blockSupInsider3'
          this.styleSup4='blockSupInsider4'
          // 补充板块的展示动画
          setTimeout(()=>{
            this.styleSup='blockSupInsiderShow'
            setTimeout(()=>{this.styleSup2='blockSupInsiderShow2'},100)
            setTimeout(()=>{this.styleSup3='blockSupInsiderShow3'},200)
            setTimeout(()=>{this.styleSup4='blockSupInsiderShow4'},300)
          },1000)
        }
    }
}
</script>

<style lang='less' scoped>
//  ----------------------------------------------------------------------------背景图部分 ----------------------------------------------------------------------
.FullView {
  height:108rem;
  width: 192rem;
  position: absolute;
  top: -8.8rem;
  left: -18.4rem;
  background-color: #16294C;
  .bgimg{
    // height:108rem;
    // width: 192rem;
    height: 129rem;
    width: 230.4rem;

    position: absolute;
    top: 4.4rem;
    left: 0rem;
  }
  .bgimgTitle{
    height: 8rem;
    width: 192rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bgimgHubei0{
    width: 10.82rem;
    height: 6.93rem;
    position: absolute;
    top: 46rem;
    right: 90rem;
    opacity: 0;
  }
  .bgimgHubei{
    width: 108.2rem;
    height: 69.3rem;
    position: absolute;
    top: 10.4rem;
    right: 42rem;
    opacity: 1;
    transition: all 1s;
  }
  .blockTitle{
    height: 5.1rem;
    width: 37.8rem;
    position: absolute;
    top: 0;
    left: 0;
  }
  .blockTitleWord{
    position: absolute;
    top: 1.4rem;
    left: 4rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1.6rem;
    color: #fff;
  }

}


//  ----------------------------------------------------------------------------左侧板块一 ----------------------------------------------------------------------
.leftOne{
  height: 28.6rem;
  width: 37.8rem;
  position: absolute;
  left: 3.2rem;
  top: 2.4rem;
  .chartLeftOne{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 23.5rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}


//  ----------------------------------------------------------------------------左侧板块二 ----------------------------------------------------------------------
.leftTwo{
  height: 28.6rem;
  width: 37.8rem;
  position: absolute;
  left: 3.2rem;
  top: 32.6rem;
  .chartLeftTwo{
    background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 23.5rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
  }
}

//  ----------------------------------------------------------------------------左侧板块三 ----------------------------------------------------------------------
.leftThree{
  height: 34.9rem;
  width: 37.8rem;
  position: absolute;
  left: 3.2rem;
  top: 62.7rem;
  .chartLeftThree{
     background: linear-gradient(90deg, rgba(3, 42, 94, 0.6) 0%, rgba(2, 10, 53, 0.2) 100%);
    height: 29.8rem;
    width: 37.8rem;
    position: absolute;
    top: 5.1rem;
    left: 0;
    padding-top: -0.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    box-sizing: border-box;
    .innerCard{
      height: 3.6rem;
      width: 100%;
      margin-top: 2.2rem;
      position: relative;
      top: 0;
      left: 0;
      .wordPlace{
        height: 2rem;
        color: #E6F7FF;
        font-size: 1.4rem;
        line-height: 2rem;
        .left{
          float: left;
        }
        .right{
          float: right;
        }
      }
      .barPlace{
        margin: 0.2rem 0;
        height: 1rem;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
      .barGet{
        height: 1rem;
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #F18F1C 0%, #EAE342 100%); 
      }
      .barLine{
        width: 0.2rem;
        height: 1.4rem;
        background-color: #fff;
        position: absolute;
        top: -0.2rem;
      }
    }
  }
}

// -----------------------------------------------------------------------------补充大板块-------------------------------------------
.blockSup{
  position: absolute;
  top: 0;
  left: 43.4rem;
  width: 13.8rem;
  height: 108rem;
  overflow: hidden;
}

// 封装的css 透明卡片##############################################################
.blockSupInsider{
  position: absolute;
  top: 2.4rem;
  left: -14rem;
  width: 13.2rem;
  height: 15.5rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  &::after{
    content: "";
    width: 13.2rem;
    height: 15.5rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-left: none;
    border-top: none;
  }
}

.blockSupInsiderShow{
  position: absolute;
  top: 2.4rem;
  left: 0rem;
  width: 13.2rem;
  height: 15.5rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
  &::after{
    content: "";
    width: 13.2rem;
    height: 15.5rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;

    border-left: none;
    border-top: none;
  }

}


// 封装的css 透明卡片2##############################################################
.blockSupInsider2{
  position: absolute;
  top: 20.3rem;
  left: -14rem;
  width: 13.2rem;
  height: 8.4rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  &::after{
    content: "";
    width: 13.2rem;
    height: 8.4rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-left: none;
    border-top: none;
  }
}

.blockSupInsiderShow2{
  position: absolute;
  top: 20.3rem;
  left: 0rem;
  width: 13.2rem;
  height: 8.4rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
  &::after{
    content: "";
    width: 13.2rem;
    height: 8.4rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;

    border-left: none;
    border-top: none;
  }

}



// 封装的css 透明卡片3##############################################################
.blockSupInsider3{
  position: absolute;
  top: 30.7rem;
  left: -14rem;
  width: 13.2rem;
  height: 12.1rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  &::after{
    content: "";
    width: 13.2rem;
    height: 12.1rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-left: none;
    border-top: none;
  }
}

.blockSupInsiderShow3{
  position: absolute;
  top: 30.7rem;
  left: 0rem;
  width: 13.2rem;
  height: 12.1rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
  &::after{
    content: "";
    width: 13.2rem;
    height: 12.1rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;

    border-left: none;
    border-top: none;
  }

}

// 封装的css 透明卡片4##############################################################
.blockSupInsider4{
  position: absolute;
  top: 44.8rem;
  left: -14rem;
  width: 13.2rem;
  height: 8.4rem;
  transition: all 0.5s;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  &::after{
    content: "";
    width: 13.2rem;
    height: 8.4rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-left: none;
    border-top: none;
  }
}

.blockSupInsiderShow4{
  position: absolute;
  top: 44.8rem;
  left: 0rem;
  width: 13.2rem;
  height: 8.4rem;
  transition: all 0.5s;
  border: 0.2rem solid ;
  background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.2) 100%);
  border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #0aa0ef 100%;) 30 30;
  border-left: none;
  border-top: none;
  box-sizing: border-box;
  transform-style: preserve-3d;
  &::after{
    content: "";
    width: 13.2rem;
    height: 8.4rem;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 0;
    transform: translateZ(-1rem);
    border: 0.1rem solid ;
    background: linear-gradient(110deg, rgba(32, 128, 255, 0) 0.66%, rgba(32, 128, 255, 0.08) 100%);
    border-image: -webkit-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: -moz-linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;
    border-image: linear-gradient(120deg ,rgba(32, 128, 255, 0) 50%, #286b98 100%;) 30 30;

    border-left: none;
    border-top: none;
  }

}






// 卡片内部的公用样式
.iconInner{
  height: 2.4rem;
  width: 14rem;
  position: absolute;
  top: 1.5rem;
  left: 1.6rem;
  .iconInnerImg{
    height: 2.4rem;
    width: 2.4rem;
    float: left;
  }
  .iconInnerWord{
    height: 2.4rem;
    line-height: 2.4rem;
    font-size: 1.6rem;
    color: #18FEFE;
    text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
    float: left;
    margin-left: 0.5rem;
    font-weight: 500;
  }
}
.iconInnerWord2{
    height: 2.9rem;
    line-height: 2.9rem;
    font-size: 2.4rem;
    color: #FBFBFB;
    text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
    font-weight: 500;
    position: absolute;
    top: 4.2rem;
    left: 1.9rem;
    .innerSpan{
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.65);
      font-weight: 100;
      text-shadow: none;
      
    }
  }
.iconInnerWord3{
    height: 2.9rem;
    line-height: 2.9rem;
    font-size: 2.4rem;
    color: #FBFBFB;
    text-shadow: 0 0.2rem 0.4rem rgba(0, 118, 203, 0.85);
    font-weight: 500;
    position: absolute;
    top: 7.8rem;
    left: 1.9rem;
    .innerSpan{
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.65);
      font-weight: 100;
      text-shadow: none;
      
    }
  }





.picLeft{
  height: 11rem;
  width: 10.7rem;
  position: absolute;
  left: 1.6rem;
  top: 2.4rem;
}
.wordLeft{
  height: 2.9rem;
  width: 13.2rem;
  position: absolute;
  top: 5.3rem;
  left: 0rem;
  color:#FBFBFB;
  font-size: 2.4rem;
  text-shadow: 0 0.1rem 0.4rem rgba(255, 255, 255, 0.45);
  text-align: center;
}
</style>