<template>
  <div class="PatrolTask">
    <div class="bread">
      运维检修 / 我的工单 /
      {{ this.tabList.find((a) => a.value == tabvalue).label }}
    </div>

    <!-- 顶部栏位置########################################################################################################################################## -->
    <div class="topBar">
      <div>
        <el-input
          size="small"
          placeholder="请输入工单主题"
          v-model="formState.keyword"
          @keyup.enter.native="search"
          style="width: 22rem;"
          clearable
          @clear="search"
        ></el-input>

        <el-input
          size="small"
          placeholder="请输入工单编号"
          v-model="formState.orderNo"
          @keyup.enter.native="search"
          style="width: 22rem; margin-left: 1.6rem;"
          clearable
          @clear="search"
        ></el-input>

        <el-select
          size="small"
          placeholder="工单来源"
          class="businessSelector"
          v-model="formState.source"
          @change="selectChanged"
        >
          <el-option label="全部来源" value=" "></el-option>
          <el-option label="内部报修" value="内部报修"></el-option>
          <el-option label="巡检报修" value="巡检报修"></el-option>
          <el-option label="监测报修" value="监测报修"></el-option>
          <el-option label="预防性试验" value="预防性试验"></el-option>
        </el-select>

        <el-select
          size="small"
          placeholder="处理类型"
          class="businessSelector"
          style="margin-left: 0rem;"
          v-model="formState.processingType"
          @change="selectChanged"
        >
          <el-option label="全部类型" value=""></el-option>
          <el-option label="调试工单" value="1"></el-option>
          <el-option label="换件工单" value="2"></el-option>
          <el-option label="修件工单" value="3"></el-option>
          <el-option label="其他" value="4"></el-option>
        </el-select>
        <button class="btn_save" @click="search">查 询</button>
        <button class="btn_save" @click="exportExcel">导 出</button>
      </div>
      <button class="btn2" @click="ifDialogShow">新增工单</button>
    </div>

    <!-- 中部表格位置############################################################################################################ -->
    <div class="userTablePlace">
      <!-- tab栏 -->
      <div class="tabSwitcher">
        <!-- <div :class="tabStyle[0]" @click="switchTab(0,'/repair/orderManagement/orderWaitForDistribution')">待分配({{tabCount.waitInspect}})</div> -->
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="tabvalue == item.value ? 'btnGreen' : 'btnGrey'"
          @click="switchTab(item.value)"
        >
          {{ item.label + `（${tabCount[item.key]}）` }}
        </div>
        <!-- <div :class="tabStyle[5]" @click="switchTab(5,'/repair/orderManagement/orderAll')">全部({{tabCount.all}})</div> -->
      </div>

      <div class="table">
        <el-table
          :data="tableData"
          stripe:false
          height="57.8rem"
          border:false
          style="width: 100%;"
          :header-cell-style="headerClass"
          :cell-style="cellStyle"
        >
          <el-table-column
            class="tab"
            prop="orderNo"
            label="工单编号"
            min-width="14%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="title"
            label="主题"
            min-width="19%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="statusName"
            label="当前状态"
            min-width="15%"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div :class="'color' + tableData[scope.$index].status">
                {{ tableData[scope.$index].statusName }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            class="tab"
            prop="deptName"
            label="报修部门"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="reportUserName"
            label="建单人"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="handler"
            label="处理人"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="createTime"
            label="报修时间"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            class="tab"
            prop="planEndTime"
            label="计划结束时间"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            v-if="tabvalue == '3'"
            class="tab"
            prop="resTime"
            label="响应时长(天)"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>

          <el-table-column
            class="tab"
            prop="source"
            label="工单来源"
            min-width="15%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            v-if="tabvalue == '3'"
            class="tab"
            prop="processingType"
            label="处理类型"
            min-width="15%"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
                <span>{{ processingTypeMap.get(scope.row.processingType) }}</span>
            </template>
          </el-table-column>

          <!-- 操作部分 -->
          <el-table-column label="操作" min-width="20%">
            <template slot-scope="scope">
              <!-- <el-button size="mini" type="text" @click="handleAssign(scope.$index, scope.row)">重派</el-button> -->
              <el-button
                size="mini"
                type="text"
                @click="handleDetail(scope.$index, scope.row)"
              >
                查看
              </el-button>
              <!-- <el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页器部分 -->
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalNumForPagenation"
        ></el-pagination>
      </div>
    </div>

    <!-- 弹层位置 -->
    <DialogAddOrder ref="DialogRef" />
    <DialogAssign ref="AssignRef" />
    <DrawerForDetail ref="DrawerRef" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import DialogAssign from './OrderManagementMine/components/DialogAssign.vue'
import DialogAddOrder from './OrderManagementMine/components/DialogAddOrder.vue'
import DrawerForDetail from './OrderManagementMine/components/DrawerForDetail.vue'
import { exportRepairOrder } from '@/api/repair'
export default {
  name: 'PatrolTask',
  mixins: [tableBg],
  components: { DialogAddOrder, DialogAssign, DrawerForDetail },
  data() {
    return {
      // 双向绑定--总数
      totalNumForPagenation: 0,
      // 双向绑定--每页的个数
      pageSize: 10,
      // 双向绑定--当前页
      currentPage: 1,
      // 顶栏数量
      tabCount: {
        all: 0,
        handling: 0,
        finished: 0,
        waitHandler: 0,
        stopped: 0,
        waitInspect: 0,
      },
      tabvalue: '1',
      tabList: [
        // {
        //   label: '待分配',
        //   value: '0',
        //   key: 'waitInspect',
        // },
        {
          label: '待处理',
          value: '1',
          key: 'waitHandler',
        },
        {
          label: '处理中',
          value: '2',
          key: 'handling',
        },
        {
          label: '已完成',
          value: '3',
          key: 'finished',
        },
        {
          label: '已暂停',
          value: '4',
          key: 'stopped',
        },
        // {
        //   label: '全部',
        //   value: '5',
        //   key: 'all',
        // },
      ],
      tabvalueMap: new Map([
        ['0', '待分配'],
        ['1', '待处理'],
        ['2', '处理中'],
        ['3', '已完成'],
        ['4', '已暂停'],
        ['5', '全部'],
      ]),
      processingTypeMap: new Map([
        ['1', '调试工单'],
        ['2', '换件工单'],
        ['3', '修件工单'],
        ['4', '其他'],
      ]),
      formState: {
        // 双向绑定的搜索数据
        keyword: '',
        orderNo: '',
        handler: '',
        source: '',
        processingType: '',
      },
      authApi: {
        '0': {
          tabCountApi: '',
          tableApi: '',
        },
        '1': {
          tabCountApi: 'getOrderManagementTabCount',
          tableApi: 'getRepairOrderManagementPageData',
          deleteApi: 'deleteRepairOrderManagementOrder',
        },
        '2': {
          tabCountApi: '',
          tableApi: '',
        },
      },
      tableData: [],
    }
  },

  methods: {
    // 切换tab栏
    switchTab(val) {
      this.tabvalue = val
      // Object.keys(this.formState).forEach((item) => {
      //   this.formState[item] = ''
      // })
      this.currentPage = 1
      this.refreshTabCount()
      this.refreshThePage()
    },

    // 刷新tabCount
    refreshTabCount() {
      this.$store
        .dispatch('getOrderManagementTabCountMine', {
          ...this.formState,
        })
        .then(() => {
          this.tabCount = this.orderManagementTabCount
        })
    },

    // 刷新页面
    refreshThePage() {
      this.$store
        .dispatch('getRepairOrderManagementPageDataMine', {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          ...this.formState,
          status: this.tabvalue,
        })
        .then(() => {
          this.tableData = this.repairOrderManagementPageData.map((item) => {
            return {
              ...item,
              statusName: this.tabList.find((a) => a.value == item.status)
                .label,
            }
          })
          this.totalNumForPagenation = this.repairOrderManagementTotal
        })
    },

    // 进行重派
    handleAssign(index) {
      this.$refs.AssignRef.showForEdit(this.tableData[index])
    },

    // 点击搜索或者回车
    search() {
      this.currentPage = 1
      this.refreshThePage()
      this.refreshTabCount()
    },

    selectChanged() {
      this.currentPage = 1
      this.refreshThePage()
      this.refreshTabCount()
    },
    //点击新增
    ifDialogShow() {
      this.$refs.DialogRef.show()
    },
    //点击了编辑按钮
    handleEdit(index) {
      this.$refs.DialogRef.showForEdit(
        this.repairOrderManagementPageData[index],
      )
    },
    // 点击了删除按钮
    handleDelete(index) {
      this.$confirm('你确定要删除该工单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store
          .dispatch(
            'deleteRepairOrderManagementOrder',
            this.repairOrderManagementPageData[index].id,
          )
          .then(() => {
            if (this.ifDeleteRepairOrderManagementOrderSuccess === 0) {
              this.$message({ message: '删除成功！', type: 'success' })
              this.refreshThePage()
              this.refreshTabCount()
            } else {
              this.$message({
                message: this.ifDeleteRepairOrderManagementOrderError,
                type: 'warning',
              })
            }
          })
      })
    },
    // 更改分页器每页个数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.refreshThePage()
    },
    // 更改分页器页面
    handleCurrentChange(val) {
      this.currentPage = val
      this.refreshThePage()
    },
    // 点击查看详情
    handleDetail(index) {
      if (
        this.repairOrderManagementPageData[index].status === 0 ||
        this.repairOrderManagementPageData[index].status === 1
      ) {
        this.$refs.DrawerRef.show(this.repairOrderManagementPageData[index], 1)
      } else {
        this.$refs.DrawerRef.show(this.repairOrderManagementPageData[index], 2)
      }
    },
    // 导出
    exportExcel() {
      let params = {
        status: this.tabvalue,
        ...this.formState,
      }
      exportRepairOrder(params).then((response) => {
        var blob = new Blob([response], {
          type: 'application/vnd.ms-excel,charset=utf-8',
        }) //创建一个blob对象
        var a = document.createElement('a') //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob) // response is a blob
        a.download = `${this.source || '全部来源'}我的工单-${this.tabvalueMap.get(this.tabvalue)}.xls` //文件名称
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    },
  },
  mounted() {
    this.refreshTabCount()
    this.refreshThePage()
  },

  computed: {
    ...mapState({
      // 分页数据
      repairOrderManagementPageData: (state) => {
        return state.repair.repairOrderManagementPageDataMine.data.records
      },
      // 页面总数
      repairOrderManagementTotal: (state) => {
        return state.repair.repairOrderManagementPageDataMine.data.total
      },
      // tab栏的数量
      orderManagementTabCount: (state) => {
        return state.repair.orderManagementTabCountMine.data
      },
    }),
  },
}
</script>

<style lang="less" scoped>
.tabSwitcher {
  border-bottom: 0.2rem solid rgba(7, 82, 150, 0.25);
  margin-bottom: 2.4rem;
  .btnGreen {
    display: inline-block;
    height: 4rem;
    font-size: 1.4rem;
    color: #03d9bc;
    border-bottom: 0.2rem solid #03d9bc;
    line-height: 5rem;
    margin-right: 2.4rem;
    cursor: pointer;
  }
  .btnGrey {
    display: inline-block;
    height: 4rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.85);
    border-bottom: none;
    line-height: 5rem;
    margin-right: 2.4rem;
    cursor: pointer;
  }
}

// 面包屑
.bread {
  height: 5rem;
  width: 142.8rem;
  line-height: 5rem;
  color: #fff;
  font-size: 1.4rem;
  margin-top: -2rem;
}
// 顶部样式##################################################################

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  box-sizing: border-box;
  height: 6.4rem;
  width: 171.2rem;
  background-color: #0d3050;
  border-radius: 0.4rem;
  overflow: hidden;
  .searchInput {
    height: 3.2rem;
    width: 22.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 2.4rem;
    top: 2.4rem;
    background-color: #0c375e;
    border: 1px solid #004579;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
    color: #fff;
    text-indent: 1.4rem;
    &:focus {
      border: 0.1rem solid #008080;
    }
  }

  button {
    height: 3.2rem;
    width: 7.4rem;
    border-radius: 0.2rem;
    border: none;
    color: #fff;
    font-size: 1.4rem;
    cursor: pointer;
  }
  .btn1 {
    background-color: #027969;
  }
  .btn2 {
    width: 10rem;
    background-color: #027969;
  }
  .btn_save {
    margin-left: 1.5rem;
  }
  .businessType {
    height: 3.2rem;
    width: 20.8rem;
  }
  .businessSelector {
    margin: 0 2rem;
  }
}

// 中部表格样式##################################################################

.userTablePlace {
  height: 86.4rem;
  width: 171.2rem;
  border-radius: 5px;
  padding: 0 2rem;
  margin: 2rem auto;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #0d3050;
  .table {
    width: 100%;
    height: 57.2rem;
    // overflow: hidden;
    // 表格边框颜色--底部边框颜色--表格空白处颜色--表头右侧空白处颜色--文字按钮颜色
    ::v-deep(.el-table) {
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: 0.1rem solid #254561;
      }
      .el-table--border:after,
      .el-table--group:after,
      .el-table:before {
        opacity: 0;
      }
      .el-table__body-wrapper {
        background-color: #0d3050;
      }
      .el-table__cell {
        background-color: #0d3050;
      }
    }
    ::v-deep(.el-button) {
      .el-button--text {
        color: #03d9bc;
      }
    }
  }
}
// 分页器样式
.pagination {
  float: right;
  margin-right: 5rem;
  margin-top: 1rem;
}

// 颜色标签样式
.color0 {
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(
    180deg,
    rgba(255, 95, 95, 0.1) 0%,
    rgba(255, 122, 0, 0.1) 100%
  );
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #ff5f5f;
}
.color1 {
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(
    180deg,
    rgba(255, 172, 95, 0.1) 0%,
    rgba(205, 216, 118, 0.1) 100%
  );
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #ffac5f;
}

.color2 {
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(
    180deg,
    rgba(24, 144, 255, 0.2) 0%,
    rgba(24, 89, 255, 0.2) 100%
  );
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #89c6ff;
}
.color3 {
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(
    180deg,
    rgba(30, 231, 231, 0.15) 0%,
    rgba(30, 207, 231, 0.15) 100%
  );
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #03d9bc;
}
.color4 {
  height: 2.8rem;
  border-radius: 1.4rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(228, 252, 255, 0.1) 100%
  );
  width: 6.6rem;
  text-align: center;
  line-height: 2.8rem;
  color: #fff;
}
</style>
