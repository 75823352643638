<template>
    <div class="step-bottom-rigth-size">
        <span class="title">分项能耗占比</span>
        <div class="step-center">
            <p>总值/kWh</p>
            <h4>
                {{ constNum }}
            </h4>
        </div>
        <div id="myChartsRuild" class="c5"></div>
        <div class="table">
            <div class="item" v-for="(item, index) in strData" :key="index">
                <span :class="['badge' + index, 'badge']"></span>
                <span class="name">{{ item.name }}</span>
                <span class="value">{{ item.value | formatNum }}<span class="param">kWh</span></span>
                <span class="ratio">{{ item.ratio }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "stepBottomRigth",
    data () {
        return {
            stepArrList: [],
            strData: [],
            constNum: "",
            dataForm: {
                customerId: "",
                //环比率
                huanbiRatio: "",
                //环比值
                huanbiValue: "",
                //名称
                name: "",
                //同比比率
                tongbiRatio: "",
                //同比值
                tongbiValue: "",
                //当前值
                value: "",
            },
        };
    },
    mounted () {
        // this.queryEnerData();
    },
    watch: {
        "getBuildCard": {
            immediate: true,
            deep: true,
            handler (newVal, oldVal) {
                if (!newVal) {
                    return;
                }
                // this.dataForm.customerId = newVal;
                //监听用户id调用方法，不然报错id不存在
                this.queryEnerData();
            },
        },
    },
    filters: {
        formatNum: function (value) {
            if (!value) return "0";
            //   return (value / 10000).toFixed(2);
            return value
        },
    },
    methods: {
        //楼层的id固定id，获取数据
        queryEnerData () {
            this.dataForm.customerId = this.$store.state.storage.customerId;
            // this.$store.dispatch("getBuildCard", this.dataForm.customerId).then(() => {
                if (this.$store.state.elecManagement.getBuildCard.errorCode == 0) {
                    this.strData = this.$store.state.elecManagement.getBuildCard.data;
                    //算出总值
                    let sum = this.strData[0].value + this.strData[1].value + this.strData[2].value + this.strData[3].value;
                    if (sum > 0) {
                        this.strData[0].ratio = ((this.strData[0].value * 100) / sum).toFixed(2);
                        this.strData[1].ratio = ((this.strData[1].value * 100) / sum).toFixed(2);
                        this.strData[2].ratio = ((this.strData[2].value * 100) / sum).toFixed(2);
                        this.strData[3].ratio = ((this.strData[3].value * 100) / sum).toFixed(2);
                        // for (let i = 0; i < this.strData.length; i++) {
                        //     const item = this.strData[i].ratio;
                        //     this.strData[i].ratio = parseInt(item) + "%";
                        // }
                    } else {
                        this.strData[0].ratio = "0%";
                        this.strData[1].ratio = "0%";
                        this.strData[2].ratio = "0%";
                        this.strData[3].ratio = "0%";
                    }

                    //小数取整四舍五入
                    this.constNum = sum.toFixed(2);
                    //调用echarts的方法
                    this.drawLine1();
                } else {
                    this.strData = [
                        {
                            name: "照明系统用电",
                            value: 0,
                            ratio: '0%'
                        },
                        {
                            name: "暖通空调用电",
                            value: 0,
                            ratio: '0%'
                        },
                        {
                            name: "动力用电",
                            value: 0,
                            ratio: '0%'
                        },
                        {
                            name: "特殊用电",
                            value: 0,
                            ratio: '0%'
                        },
                    ];
                    this.constNum = 0;
                    this.drawLine1();
                }
            // });
        },
        drawLine1 () {
            let myChart = this.$echarts.init(document.getElementById("myChartsRuild"));
            let circular = [];

            if (this.strData.length > 0) {
                for (let i = 0; i < this.strData.length; i++) {
                    let item = this.strData[i];
                    circular.push({
                        name: item.name,
                        value: item.value,
                    });
                }
            } else {
                circular = [
                    {
                        name: "照明系统用电",
                        value: 0
                    },
                    {
                        name: "暖通空调用电",
                        value: 0
                    },
                    {
                        name: "动力用电",
                        value: 0
                    },
                    {
                        name: "特殊用电",
                        value: 0
                    },
                ]
            }
            let option = {
                tooltip: {
                    trigger: "item",
                },
                grid: {
                    x: 5,
                    x2: 5,
                    y: 15,
                    y2: 5,
                    containLabel: true,
                },
                series: [
                    {
                        type: "pie",
                        radius: ["50%", "40%"],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {},
                        labelLine: {
                            show: false,
                        },
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#0c2f4f",
                            },
                        },
                        data: circular,
                    },
                ],
            };
            myChart.setOption(option);
            // myChart._dom.childNodes[0].childNodes[0].style.width = "100%";
            myChart._dom.childNodes[0].childNodes[0].style.height = "34rem";
        },
    },
    computed: {
        ...mapState({
            getBuildCard: (state) => {
                return state.elecManagement.getBuildCard.data;
            },
        }),
    },
};
</script>

<style lang="less" scoped>
.step-bottom-rigth-size {
    position: relative;
    width: 100%;
    .title {
        position: relative;
        top: 2rem;
        margin-left: 2.4rem;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #37a5dd;
    }
    .step-center {
        position: relative;
        top: 10rem;
        left: 0;
        text-align: center;
        p {
            font-size: 1.6rem;
            color: #ffffff;
        }
        h4 {
            font-size: 2.5rem;
            color: #ffffff;
            font-weight: 400;
            span {
                font-size: 1.2rem;
                color: #ffffff;
                font-weight: normal;
            }
        }
    }
    .table-left {
        position: relative;
        top: 8rem;
        left: 1rem;
        p {
            font-size: 1.2rem;
            color: #ffffff;
            line-height: 3.1rem;
        }
    }
    .step-num {
        position: relative;
        left: 13rem;
        top: 20.7rem;
        display: flex;
        p {
            font-size: 1.2rem;
            color: #ffffff;
            line-height: 3.2rem;
        }
        span {
            font-size: 1.2rem;
            color: rgba(255, 255, 255, 0.85);
        }
        .step-ener {
            h5 {
                position: absolute;
                // right: ;
                right: 14rem;

                font-size: 1.2rem;
                color: #ffffff;
                line-height: 3rem;
            }
        }
    }

    .c5 {
        width: 34rem;
        // width: 100%;
        height: 34rem;
        // position: absolute;
        // left: 0;
        // display: flex;
        // justify-content: center;
        margin: 0 auto;
        margin-top: -10rem;
    }
    .table {
        position: relative;
        width: 100%;
        height: 15rem;
        /* left: 2rem; */
        /* top: 27rem; */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        margin-top: -5rem;
        .item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            .badge {
                display: inline-block;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 49%;
                margin-right: 0.8rem;
            }
            .badge0 {
                background-color: #5fd5ec;
            }
            .badge1 {
                background-color: #3673e8;
            }
            .badge2 {
                background-color: #ffcf5f;
            }
            .badge3 {
                background-color: #ee6f7c;
            }
            .name {
                display: inline-block;
                width: 12rem;
                font-size: 1.2rem;
            }
            .value {
                width: 10rem;
                display: inline-block;
                font-size: 1.4rem;
                text-align: right;
                margin-right: 0.8rem;
            }
            .ratio {
                display: inline-block;
                width: 5rem;
                font-size: 1.2rem;
                text-align: right;
            }
        }
    }
}
</style>
<style lang="less"></style>
